import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { Hidden } from "@mui/material";
import { LoginStyles } from "./LoginStyles";
import Box from "@mui/material/Box";
import CustomInput from "../../components/input/Input";
import Text from "../../components/customText/Text";
import {
    isValid,
    validate_emptyField,
    //  validates_password, 
    validate_input_fields
} from "../../components/Validation";
import { LoadingButton } from "@mui/lab";
import Logo from '../../assets/logo.svg';
import CommonApi from "../../apis/CommonApi";
import { addErrorMsg } from "../../utils/utils";
import LocalStorage from "../../utils/LocalStorage";
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import { domain } from '../../config/Domain';
import { createPasswordDefaultConfig } from "../../config/pageConfig/publicConfig";


function CreatePassword() {
    const classes = LoginStyles(); // eslint-disable-next-line
    const [formData, setFormData] = useState({
        password: "",
        confirmpassword: "",
    });
    const [error, setError] = useState({}); // eslint-disable-next-line
    const [isVisible, setVisible] = useState(false); //For password hide and show  
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState(false);
    const [eye, setEye] = useState({
        password: false,
        confirmpassword: false
    });
    const [createPasswordConfig, setCreatePasswordConfig] = useState(createPasswordDefaultConfig);

    const lowerCase = new RegExp(createPasswordConfig?.password?.lowerCaseReg?.slice(1, -1));
    const upperCase = new RegExp(createPasswordConfig?.password?.upperCaseReg?.slice(1, -1));
    const digit = new RegExp(createPasswordConfig?.password?.digitReg?.slice(1, -1));
    const specialCharacter = new RegExp(createPasswordConfig?.password?.specialCharacterReg?.slice(1, -1));

    const [errorShow, setErrorShow] = useState(false);
    const changeHandler = (e) => {
        setErrorShow(true)
        setFormData(
            {
                ...formData,
                [e.target.name]: e.target.value,
            },
            handleValidate(e)
        );
    };


    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "email":
                error.email = validate_emptyField(input.value);
                break;
            case "password":
                error.password = validate_input_fields(input.value, createPasswordConfig?.password);
                error.confirmpassword = (formData.confirmpassword != "" && createPasswordConfig?.confirm_password?.required) ? input.value == formData.confirmpassword ? '' : createPasswordConfig?.confirm_password?.required ? createPasswordConfig?.confirm_password?.misMatchMsg : '' : '';
                break;
            case "confirmpassword":
                error.confirmpassword = (formData.password == input.value && createPasswordConfig?.confirm_password?.required) ? '' : createPasswordConfig?.confirm_password?.required ? createPasswordConfig?.confirm_password?.misMatchMsg : '';
                break;
            default:
                break;
        }
        let err = { ...error };
        setError(err);
    };
    /*email validation */
    const emailValidations = () => {
        let { password, confirmpassword } = formData;
        let errors = {};
        errors.password = (createPasswordConfig?.password?.isVisible && (!createPasswordConfig?.password?.isDisabled)) ? validate_input_fields(password, createPasswordConfig?.password) : '';
        errors.confirmpassword = (password == confirmpassword) ? (createPasswordConfig?.confirm_password?.isVisible && !createPasswordConfig?.confirm_password?.isDisabled) ? 
        validate_input_fields(confirmpassword, createPasswordConfig?.confirm_password) : '' 
        : createPasswordConfig?.confirm_password?.required && createPasswordConfig?.confirm_password?.isVisible && !createPasswordConfig?.confirm_password?.isDisabled ? 
        createPasswordConfig?.confirm_password?.misMatchMsg : '';

        return errors;
    };
    const submit = (e) => {
        let errors = emailValidations();

        e.preventDefault()
        const token = location.search.split('=')
        let data = {}
        if (location.state && location.state.data) {
            data = {
                request_id: LocalStorage.uid(),
                subdomain_name: location.state.data.subdomain_name,
                email_id: location.state.data.email,
                temp_access_token: location.state.data.token,
                confirm_password: formData.confirmpassword,
                password: formData.password
            }
        }
        else {
            data = {
                request_id: LocalStorage.uid(),
                subdomain_name: domain,
                temp_access_token: token[1],
                confirm_password: formData.confirmpassword,
                password: formData.password
            }
        }

        setLoader(true)
        if (isValid(errors)) {
            CommonApi.changePassword(data).then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        setLoader(false)
                        navigate('login')
                    }
                    else {
                        setLoader(false)
                        addErrorMsg(response.data.message)
                    }
                }
            )
        }
        else {
            setLoader(false)
            let err = { error };
            err = errors;
            setError(err);
        }
    }

    const ImagesList = [
        Image1,
        Image2,
        // Image3,
        Image4
    ];
    // eslint-disable-next-line
    const [currentImage, setCurrentImage] = useState(0);


    useEffect(() => {
        const data = {
            page_name: "create-password",
            page_type: "create"
        }

        async function getPageData() {
            const response = await CommonApi.fetchPageConfig(data)
            if (response.status === 200) {
                setCreatePasswordConfig(response?.data?.data?.page_data)
            }
        }
        getPageData()
    }, [])

    useEffect(() => {
        // Set an interval to update the current image every 3 seconds
        const intervalId = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % ImagesList.length);
        }, 3000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let tokenData = location.search.split('=')
        if (tokenData[1] != undefined) {
            CommonApi.checkTokenAlready(tokenData[1]).then((res) => {
                if (res.data.statusCode == 1003) {

                }
                else {
                    navigate('/login')
                }
            })
        }

        // eslint-disable-next-line
    }, [])


    return (
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} sx={{ height: '100vh' }}>
            <Hidden mdDown>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ height: '100vh', overflow: 'hidden' }}>
                    <img
                        src={ImagesList[currentImage]}
                        alt={`img-${currentImage}`}
                        style={{
                            width: '100%',  // Ensures the image takes the full width of its container
                            height: '100%', // Ensures the image's height adjusts based on the parent (grid item)
                            objectFit: 'cover', // Maintain aspect ratio and fill the grid item without distortion
                        }}
                    />
                </Grid>
            </Hidden>


            <Grid item container xl={6} lg={6} md={6} sm={12} xs={12} justifyContent={'center'} alignContent={'center'} >
                <Grid item lg={7} md={7} sm={7} xs={10} height={'95%'} justifyContent={'center'} alignContent={'center'}>
                    <form onSubmit={(e) => { submit(e) }}>
                        <Box className={classes.boxOne}>
                            <Grid container>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1}>
                                        <img src={Logo} alt='logo' />
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1}>
                                        <Text popupHead1 sx={{ color: '#090914 !important' }}>Create Password</Text>
                                    </Box>
                                </Grid>
                                {createPasswordConfig?.password?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
                                    <Box p={1}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: "password",
                                                value: formData.password,
                                                type: eye.password ? 'text' : 'password',
                                                inputProps: { maxlength: createPasswordConfig?.password?.maxLength }
                                            }}
                                            eyeIcon={eye.password}
                                            eyeCloseIcon={eye.password == false}
                                            eyeHandleChange={() => {
                                                setEye({
                                                    ...eye,
                                                    password: !eye.password
                                                })
                                            }}
                                            iconText
                                            handleChange={changeHandler}
                                            labelText={createPasswordConfig?.password?.required ? createPasswordConfig?.password?.label : `${createPasswordConfig?.password?.label} (Optional)`}
                                            placeholder={createPasswordConfig?.password?.placeholder}
                                            disabled={createPasswordConfig?.password?.isDisabled}
                                            error={error.password}
                                        />
                                        {error.password ? <Text red>{error.password}</Text> : ""}
                                    </Box>

                                </Grid>}
                                {createPasswordConfig?.confirm_password?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1}>
                                        <CustomInput
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: "confirmpassword",
                                                value: formData.confirmpassword,
                                                type: eye.confirmpassword ? 'text' : 'password',
                                                inputProps: { maxLength: createPasswordConfig?.confirm_password?.maxLength }
                                            }}
                                            eyeIcon={eye.confirmpassword}
                                            eyeCloseIcon={eye.confirmpassword == false}
                                            iconText
                                            eyeHandleChange={() => {
                                                setEye({
                                                    ...eye,
                                                    confirmpassword: !eye.confirmpassword
                                                })
                                            }}
                                            handleChange={changeHandler}
                                            labelText={createPasswordConfig?.confirm_password?.required ? createPasswordConfig?.confirm_password?.label : `${createPasswordConfig?.confirm_password?.label} (Optional)`}
                                            placeholder={createPasswordConfig?.confirm_password?.placeholder}
                                            disabled={createPasswordConfig?.confirm_password?.isDisabled}
                                            error={error.confirmpassword}
                                        />
                                        {error.confirmpassword ? <Text red>{error.confirmpassword}</Text> : ""}
                                    </Box>
                                </Grid>}
                                {
                                    createPasswordConfig?.password?.isVisible && createPasswordConfig?.password?.required && errorShow ?
                                        <Grid item sm={12} xs={12} p={1} pl={2}>
                                            <Text mediumLabel sx={{ color: '#111827 !important', paddingBottom: '5px' }}>Password Requirements:</Text>
                                            <Text mediumLabel sx={{ color: upperCase.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}> <span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: upperCase.test(formData.password) ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span> At least {createPasswordConfig?.password?.upperCaseTxt} upper case {upperCase.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: lowerCase.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: lowerCase.test(formData.password) ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span> At least {createPasswordConfig?.password?.lowerCaseTxt} lower case {lowerCase.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: specialCharacter.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: specialCharacter.test(formData.password) ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span>At least {createPasswordConfig?.password?.specialCharacterTxt} special character {specialCharacter.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: formData.password.length > 7 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: formData.password.length > 7 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span>The password length min of {createPasswordConfig?.password?.passwordLengthTxt} characters. {formData.password.length > 7 ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: digit.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: digit.test(formData.password) ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '8px 8px 2px 0px'
                                            }}> </span>At least {createPasswordConfig?.password?.digitTxt} number {digit.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        </Grid>
                                        : ''
                                }
                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    <Box className={classes.buttonStyles}>
                                        <LoadingButton
                                            className={classes.loginButton}
                                            loading={loader}
                                            type='submit'
                                            variant="contained"
                                            onClick={() => { submit() }}
                                            fullWidth
                                        >
                                            Create
                                        </LoadingButton>
                                    </Box>


                                </Grid>

                            </Grid>
                        </Box>
                    </form>
                </Grid>

                <Grid item container xs={12} sm={12} lg={12} md={12} justifyContent={'center'} alignContent={'center'} height={'5%'}>
                    <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}>
                        <a href={'https://www.japfu.ai/contact'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Us</a> </Text>

                </Grid>
            </Grid>



        </Grid>

    );
}

export default CreatePassword;


