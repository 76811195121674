import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid, Skeleton } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PayRateConfigurationForm from './PayRateConfigurationForm';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import LocalStorage from '../../../../utils/LocalStorage';
import CalenderIcon from '../../../../assets/svg/calendar.svg';
import PayrollApi from "../../../../apis/admin/payroll/ExpenseManagement";
import { getFormattedLabel } from '../../../../utils/utils';


function PayRateConfiguration(props) {

  const placementID = props.id ? props.id : "";
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [ActiveData, setActiveData] = useState([]);
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const [checkDates, setCheckDates] = useState([]);
  const [upcomingDates, setUpcomingDates] = useState([]);
  const [state, setState] = useState({
    pay_type: "",
    annual_pay: "",
    payroll_pay: "",
    // payroll_cycle: "",
    pay_config_type: '',
    ot_pay_config_type: '',
    ot_pay_config_value: "",
    pay_rate: "",
    pay_details: [],
    pay_details_deleted_ids: [],
    payroll_config_id: ""
  }) // eslint-disable-next-line
  const [oldPayRatePayload, setOldPayRatePayload] = useState({
    request_id: LocalStorage.uid(),
    id: placementID,
    page: 1,
    limit: 1000

  })

  const payRateDetails = props.payRateDetailsConfig
  useEffect(() => {
    getPayrollCheckDates();
    getPlacementPayRateData(placementID)// eslint-disable-next-line
  }, []);

  const getPlacementPayRateData = (id) => {
    setLoading(true);
    getPlacementOldPayRateData(oldPayRatePayload);
    PlacementApi.getPlacementIndex("payrate-details", id).then((res) => {
      if (res.data.statusCode === 1003) {
        // if (res.data.data.pay_rate_details.pay_details.length > 0) {
        //   res.data.data.pay_rate_details.pay_details = res.data.data.pay_rate_details.pay_details.reverse();
        // }
        res.data.data.pay_rate_details['pay_details_deleted_ids'] = [];
        setState({ ...res.data.data.pay_rate_details })
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const getPlacementOldPayRateData = (payLoad) => {
    setLoading(true);
    PlacementApi.getOldPlacementPayrates(payLoad).then((res) => {
      if (res.data.statusCode === 1003) {
        setOldData(res?.data?.data?.old_payrate);
        setActiveData(res?.data?.data?.active_payrate)
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const getPayrollCheckDates = () => {
    setLoading(true);
    PayrollApi.payPeriodDropdown().then((res) => {
      if (res.data.statusCode === 1003) {
        if (res.data.data.length > 0) {
          setUpcomingDates(res.data.data);
          const newArray = res.data.data.filter(data => data.id !== 0).map(data => ({ id: data.id, value: data.check_date }));
          setCheckDates(newArray);
        }
      }
    })
  }

  return (
    <Grid container >
      {
        edit ?
          <Box p={{ lg: 2, md: 2, sm: 1, xs: 1 }} m={{ lg: 1, md: 1, sm: 0, xs: 0 }} mt={{sm:"10px !important",xs:"0px !important"}} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "65vh", overflow: "auto !important" }}>
            <PayRateConfigurationForm upcomingDates={upcomingDates} checkDates={checkDates} employeeType={props.employeeType} placementID={placementID} getPlacementPayRateData={getPlacementPayRateData} state={state} setState={setState} edit={edit} setEdit={setEdit} setformEdit={props.setformEdit} payRateDetails={payRateDetails} />
          </Box>
          :
          <Grid container >
            <Box p={{ lg: 2, md: 2, sm: 2, xs: 1 }} m={{ lg: 1, md: 1, sm: 0, xs: 0 }} width={"100%"} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: (oldData.length > 0 && state?.pay_type !== 2) ? "40vh" : "fit-content", overflow: "auto !important" }}>
              <Grid container p={{ lg: 3, md: 3, sm: 1.5, xs: 1 }} alignItems={"center"}>
                <Grid item lg={6} md={6} sm={6} xs={9}>
                  <Text largeBlue>{state?.payroll_config_status == 0 ? "Upcoming Pay Rate Configuration " : "Active Pay Rate Configuration"}</Text>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={3} textAlign={"end"}>
                  {
                    props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                      <>
                        {!loading && <img onClick={() => { setEdit(true); props.setformEdit(true); }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} />}
                      </>
                      : ""
                  }
                </Grid>
                {payRateDetails?.pay_type?.isVisible &&
                <Grid item lg={(state?.pay_type == 2 || state?.payroll_config_status == 0) ? 3 : 12} md={(state?.pay_type == 2 || state?.payroll_config_status == 0) ? 4 : 12} sm={(state?.pay_type == 2 || state?.payroll_config_status == 0) ? 4 : 12} xs={12} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                      :
                      <>
                        {getFormattedLabel(payRateDetails?.pay_type)}
                        <Text mediumBlack14 pt={0.8}>{state.pay_type == 2 ? "Salary" : state.pay_type === 1 ? "Hourly" : '--'}</Text>
                      </>
                  }
                </Grid>}
                {
                  state.pay_type == 2 ?
                    <>
                      {payRateDetails?.annual_pay?.isVisible &&
                      <Grid item lg={4} md={4} sm={6} xs={12} pt={4}>
                        {
                          loading ?
                            <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                            :
                            <>
                              {getFormattedLabel(payRateDetails?.annual_pay)}
                              <Text mediumBlack14 pt={0.8}>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {state.annual_pay ? state.annual_pay : "--"}</Text>
                            </>
                        }
                      </Grid>}

                      {state?.payroll_config_status == 0 && payRateDetails?.payroll_configuration_id &&
                        <Grid item lg={3} md={4} sm={6} xs={12} pt={4}>
                          {
                            loading ?
                              <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                              :
                              <>
                                {getFormattedLabel(payRateDetails?.payroll_configuration_id)}
                                <Text mediumBlack14 pt={0.8}>{state.check_date ? state.check_date : "--"}</Text>
                              </>
                          }
                        </Grid>
                      }
                    </> :
                    <>
                      {state?.payroll_config_status == 0 && payRateDetails?.payroll_configuration_id?.isVisible &&
                        <Grid item lg={3} md={4} sm={6} xs={12} pt={4}>
                          {
                            loading ?
                              <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                              :
                              <>
                                {getFormattedLabel(payRateDetails?.payroll_configuration_id)}
                                <Text mediumBlack14 pt={0.8}>{state.check_date ? state.check_date : "--"}</Text>
                              </>
                          }
                        </Grid>
                      }
                      <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                        {
                          loading ?
                            <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                            :
                            <>
                              <Text smallGrey400>Pay Configuration Type</Text>
                              <Text mediumBlack14 pt={0.8}>{state.pay_config_type == 1 ? "Percentage" : state.pay_config_type === 2 ? "Value" : '--'}</Text>
                            </>
                        }
                      </Grid>
                      {
                        state.pay_details.length > 0 && state.pay_details.map((item, index) => (
                          <>
                            {payRateDetails?.from_hour?.isVisible &&
                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                              <Text smallGrey400 title={payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label : ""}>{payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label.substring(0,45) + "..." : payRateDetails?.from_hour?.label}</Text>
                              <Text mediumBlack14 pt={0.8}>{item.from_hour}</Text>
                            </Grid>}
                            {payRateDetails?.to_hour?.isVisible &&
                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                              {getFormattedLabel(payRateDetails?.to_hour)}
                              <Text mediumBlack14 pt={0.8}>{item.to_hour ? item.to_hour : "Remainder"}</Text>
                            </Grid>}
                            {state.pay_config_type == 1 ?
                            <>
                             {payRateDetails?.value?.value1?.isVisible &&
                              <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                              {getFormattedLabel(payRateDetails?.value?.value1)}
                               <Text mediumBlack14 pt={0.8}>{item.value} {state.pay_config_type == 1 && "%"}</Text>
                            </Grid>}
                            </>
                          :
                           <>
                           {payRateDetails?.value?.value2?.isVisible &&
                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                            {getFormattedLabel(payRateDetails?.value?.value2)}
                            <Text mediumBlack14 pt={0.8}>{state.pay_config_type == 2 && LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.value}</Text>
                          </Grid>}
                          </>
                             }
                            
                          </>
                        ))
                      }
                      {
                        props.employeeType == 2 ?
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                              <Text largeBlue >OT Pay Rate Config Type</Text>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                              {
                                loading ?
                                  <Skeleton variant="circular" width={20} height={20} animation="wave" />
                                  :

                                  <FormControl>
                                    <RadioGroup
                                      name="controlled-radio-buttons-group"
                                      value={state.ot_pay_config_type != "" ? true : false}
                                      disable={true}
                                    >
                                      <FormControlLabel disabled value="true" control={<Radio />} label={<Text smallGrey400 sx={{ font: "14px Quicksand !important" }}>{state.ot_pay_config_type != "" && (state.ot_pay_config_type == 1 ? "Same as Payrate" : state.ot_pay_config_type == 2 ? "Fixed Value" : state.ot_pay_config_type == 3 ? "Variable" : "")}</Text>} />
                                    </RadioGroup>
                                  </FormControl>
                              }
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12} pt={3}>
                              {
                                loading ?
                                  <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                  :
                                  <>
                                    {
                                      state.ot_pay_config_type !== 1 &&
                                      <>
                                       {state.ot_pay_config_type == 3 ?
                                       <>
                                        {payRateDetails?.ot_pay_config_value?.value3?.isVisible &&
                                        <>
                                        {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value3)}
                                        <Text mediumBlack14 pt={0.8}>{state.ot_pay_config_value ?  state.ot_pay_config_value : "--"}</Text>
                                        </>
                                        }
                                       </>
                                      :
                                      <>
                                       {payRateDetails?.ot_pay_config_value?.value2?.isVisible &&
                                        <>
                                        {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value2)}
                                        <Text mediumBlack14 pt={0.8}>{state.ot_pay_config_value ?`${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${state.ot_pay_config_value}` : "--"}</Text>
                                        </>}
                                        </>
                                      }
                                        
                                      </>
                                    }
                                  </>
                              }
                            </Grid>
                          </>
                          : ""
                      }
                    </>
                }
              </Grid>
            </Box>
            {
              ActiveData.length > 0 &&
              <Box p={{ lg: 2, md: 2, sm: 1, xs: 1 }} m={{ lg: 1, md: 1, sm: 0, xs: 0 }} mt={{sm:"10px !important",xs:"10px !important"}} width={"100%"} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "fit-content", overflow: "auto !important" }}>
                <Grid px={{ lg: 2, md: 2, sm: 2, xs: 1 }} container alignItems={"center"}>
                  <Grid item lg={12} md={12} sm={12} xs={12} py={2}>
                    <Text largeBlue>Active Pay Rate Configuration</Text>
                  </Grid>
                  <Box sx={{ width: "100%", height: ActiveData[0].pay_type == 2 ? "fit-content" : "40vh", overflow: "auto !important" }}>
                    {
                      ActiveData.map((item, index) => (
                        <>
                          <Grid container alignItems={"center"} py={2}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} alignItems={"center"} gap="5px">
                              <img src={CalenderIcon} alt="CalenderIcon" style={{ width: "15px", height: "15px" }} />
                              <Text smallGrey400>From :&nbsp;</Text>
                              <Text mediumBlack14>{item.from_date ? item.from_date : "--"}</Text>
                              <Text smallGrey400>Till :&nbsp;</Text>
                              <Text mediumBlack14>{item.to_date ? item.to_date : "--"}</Text>
                            </Grid>
                            {payRateDetails?.pay_type?.isVisible &&
                            <Grid item lg={item.pay_type == 2 ? 4 : 12} md={item.pay_type == 2 ? 4 : 12} sm={item.pay_type == 2 ? 4 : 12} xs={12} pt={2}>
                              {getFormattedLabel(payRateDetails?.pay_type)}
                              <Text mediumBlack14 pt={0.8}>{item.pay_type == 2 ? "Salary" : item.pay_type === 1 ? "Hourly" : '--'}</Text>
                            </Grid>}
                            {
                              item.pay_type == 2 && payRateDetails?.annual_pay?.isVisible ?
                                <Grid item lg={3.5} md={4} sm={6} xs={12} pt={4}>
                                  {getFormattedLabel(payRateDetails?.annual_pay)}
                                  <Text mediumBlack14 pt={0.8}>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.annual_pay ? item.annual_pay : "--"}</Text>
                                </Grid>
                                :
                                <>
                                  <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                    <Text smallGrey400>Pay Configuration Type</Text>
                                    <Text mediumBlack14 pt={0.8}>{item.pay_config_type == 1 ? "Percentage" : item.pay_config_type === 2 ? "Value" : '--'}</Text>
                                  </Grid>
                                  {
                                    item.pay_details.length > 0 && item.pay_details.map((item, index) => (
                                      <>
                                        {payRateDetails?.from_hour?.isVisible &&
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                          <Text smallGrey400 title={payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label : ""}>{payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label.substring(0,45) + "..." : payRateDetails?.from_hour?.label}</Text>
                                          <Text mediumBlack14 pt={0.8}>{item.from_hour}</Text>
                                        </Grid>}
                                        {payRateDetails?.to_hour?.isVisible &&
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                          {getFormattedLabel(payRateDetails?.to_hour)}
                                          <Text mediumBlack14 pt={0.8}>{item.to_hour ? item.to_hour : "Remainder"}</Text>
                                        </Grid>}
                                        {item.pay_config_type == 1 ?
                                        <>
                                        {payRateDetails?.value?.value1?.isVisible &&
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                            {getFormattedLabel(payRateDetails?.value?.value1)}
                                            <Text mediumBlack14 pt={0.8}>{item.value} {item.pay_config_type == 1 && "%"}</Text>
                                      </Grid>}
                                      </>
                                      :
                                      <>
                                      {payRateDetails?.value?.value2?.isVisible &&
                                      <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                              {getFormattedLabel(payRateDetails?.value?.value2)}
                                              <Text mediumBlack14 pt={0.8}>{item.pay_config_type == 2 && LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.value}</Text>
                                        </Grid>}
                                        </>
                                        }
                                        
                                      </>
                                    ))
                                  }
                                  {
                                    props.employeeType == 2 ?
                                      <>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                          <Text largeBlack>OT Pay Rate Config Type</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>

                                          <FormControl>
                                            <RadioGroup
                                              name="controlled-radio-buttons-group"
                                              value={item.ot_pay_config_type != "" ? true : false}
                                              disable={true}
                                            >
                                              <FormControlLabel disabled value="true" control={<Radio />} label={<Text smallGrey400 sx={{ font: "14px Quicksand !important" }}>{item.ot_pay_config_type != "" && (item.ot_pay_config_type == 1 ? "Same as Payrate" : item.ot_pay_config_type == 2 ? "Fixed Value" : item.ot_pay_config_type == 3 ? "Variable" : "")}</Text>} />
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={3}>

                                          {
                                            item.ot_pay_config_type !== 1 &&
                                            <>
                                            {item.ot_pay_config_type == 3 ?
                                            <>
                                            {payRateDetails?.ot_pay_config_value?.value3?.isVisible && 
                                            <>
                                            {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value3)}
                                            <Text mediumBlack14 pt={0.8}>{item.ot_pay_config_value ?  item.ot_pay_config_value : "--"}</Text>
                                            </>}
                                            </>
                                             :
                                             <>
                                             {payRateDetails?.ot_pay_config_value?.value2 &&
                                             <>
                                             {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value2)}
                                              <Text mediumBlack14 pt={0.8}>{item.ot_pay_config_value ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${item.ot_pay_config_value}` :  "--"}</Text>
                                              </>}
                                              </>
                                              }
                                              
                                            </>
                                          }
                                        </Grid>
                                      </>
                                      : ""
                                  }
                                </>
                            }
                          </Grid>
                        </>
                      ))
                    }
                  </Box>
                </Grid>
              </Box>
            }

            {
              oldData.length > 0 &&
              <Box p={{ lg: 2, md: 2, sm: 2, xs: 1 }} m={{ lg: 1, md: 1, sm: 0, xs: 0 }} mt={{sm:"10px !important",xs:"10px !important"}} width={"100%"} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "fit-content", overflow: "auto !important" }}>
                <Grid px={{ lg: 2, md: 2, sm: 1, xs: 1 }} container alignItems={"center"}>
                  <Grid item lg={12} md={12} sm={12} xs={12} py={2}>
                    <Text largeBlue>Old Pay Rate Configuration</Text>
                  </Grid>
                  <Box sx={{ width: "100%", height: "40vh", overflow: "auto !important" }}>
                    {
                      oldData.map((item, index) => (
                        <>
                          <Grid container alignItems={"center"} py={2}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} alignItems={"center"} gap="5px">
                              <img src={CalenderIcon} alt="CalenderIcon" style={{ width: "15px", height: "15px" }} />
                              <Text smallGrey400>From :&nbsp;</Text>
                              <Text mediumBlack14>{item.from_date ? item.from_date : "--"}</Text>
                              <Text smallGrey400>Till :&nbsp;</Text>
                              <Text mediumBlack14>{item.to_date ? item.to_date : "--"}</Text>
                            </Grid>
                            {payRateDetails?.pay_type?.isVisible &&
                            <Grid item lg={item.pay_type == 2 ? 4 : 12} md={item.pay_type == 2 ? 4 : 12} sm={item.pay_type == 2 ? 4 : 12} xs={12} pt={2}>
                              {getFormattedLabel(payRateDetails?.pay_type)}
                              <Text mediumBlack14 pt={0.8}>{item.pay_type == 2 ? "Salary" : item.pay_type === 1 ? "Hourly" : '--'}</Text>
                            </Grid>}
                            {
                              item.pay_type == 2 && payRateDetails?.annual_pay?.isVisible  ?
                                <Grid item lg={3.5} md={4} sm={4} xs={12} pt={4}>
                                  {getFormattedLabel(payRateDetails?.annual_pay)}
                                  <Text mediumBlack14 pt={0.8}>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.annual_pay ? item.annual_pay : "--"}</Text>
                                </Grid>
                                :
                                <>
                                  <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                    <Text smallGrey400>Pay Configuration Type</Text>
                                    <Text mediumBlack14 pt={0.8}>{item.pay_config_type == 1 ? "Percentage" : item.pay_config_type === 2 ? "Value" : '--'}</Text>
                                  </Grid>
                                  {
                                    item.pay_details.length > 0 && item.pay_details.map((item, index) => (
                                      <>
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                         <Text smallGrey400 title={payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label : ""}>{payRateDetails?.from_hour?.label?.length > 45 ? payRateDetails?.from_hour?.label.substring(0,45) + "..." : payRateDetails?.from_hour?.label}</Text>
                                          <Text mediumBlack14 pt={0.8}>{item.from_hour}</Text>
                                        </Grid>
                                        {payRateDetails?.to_hour?.isVisible &&
                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                          {getFormattedLabel(payRateDetails?.to_hour)}
                                          <Text mediumBlack14 pt={0.8}>{item.to_hour ? item.to_hour : "Remainder"}</Text>
                                        </Grid>}
                                        {item.pay_config_type == 1 ?
                                        <>
                                         {payRateDetails?.value?.value1?.isVisible &&
                                         <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                            {getFormattedLabel(payRateDetails?.value?.value1)}
                                             <Text mediumBlack14 pt={0.8}>{item.value} {item.pay_config_type == 1 && "%"}</Text>
                                          </Grid>
                                       }
                                       </>
                                       :
                                       <>
                                       {payRateDetails?.value?.value2 &&
                                       <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                           {getFormattedLabel(payRateDetails?.value?.value2)}
                                           <Text mediumBlack14 pt={0.8}>{item.pay_config_type == 2 && LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.value}</Text>
                                     </Grid>}
                                     </>
                                      }
                                       
                                      </>
                                    ))
                                  }
                                  {
                                    props.employeeType == 2 ?
                                      <>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                          <Text largeBlack>OT Pay Rate Config Type</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>

                                          <FormControl>
                                            <RadioGroup
                                              name="controlled-radio-buttons-group"
                                              value={item.ot_pay_config_type != "" ? true : false}
                                              disable={true}
                                            >
                                              <FormControlLabel disabled value="true" control={<Radio />} label={<Text smallGrey400 sx={{ font: "14px Quicksand !important" }}>{item.ot_pay_config_type != "" && (item.ot_pay_config_type == 1 ? "Same as Payrate" : item.ot_pay_config_type == 2 ? "Fixed Value" : item.ot_pay_config_type == 3 ? "Variable" : "")}</Text>} />
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12} pt={3}>

                                          {
                                            item.ot_pay_config_type !== 1 &&
                                            <>
                                            {item.ot_pay_config_type == 3 ?
                                            <>
                                             {payRateDetails?.ot_pay_config_value?.value3?.isVisible &&
                                             <>
                                              {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value3)}
                                              <Text mediumBlack14 pt={0.8}>{item.ot_pay_config_value ? item.ot_pay_config_value : "--"}</Text>
                                              </>}
                                            </>
                                            :
                                            <>
                                            {payRateDetails?.ot_pay_config_value?.value2?.isVisible &&
                                            <>
                                              {getFormattedLabel(payRateDetails?.ot_pay_config_value?.value2)}
                                              <Text mediumBlack14 pt={0.8}>{item.ot_pay_config_value ?  `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${item.ot_pay_config_value}` : "--"}</Text>
                                              </>}
                                              </>
                                            }
                                              
                                            </>
                                          }
                                        </Grid>
                                      </>
                                      : ""
                                  }
                                </>
                            }
                          </Grid>
                          {index != (oldData.length - 1) && <Divider />}
                        </>
                      ))
                    }
                  </Box>
                </Grid>
              </Box>
            }

          </Grid>
      }
    </Grid>

  )
}

export default PayRateConfiguration