import BaseApi from '../../BaseApi'
import APIURL from "../../../config/development";
import LocalStorage from '../../../utils/LocalStorage';

class PauseResumeStopRecurrencesClientApi {
    
    listing(companyId,status,params) {
        let {search,limit,page}=params
        return BaseApi.getWithParams(APIURL.API_URL + `invoice/recurring-invoice/listing?request_id=${LocalStorage.uid()}&company_id=${companyId}&status=${status}&search=${search}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }
    updateApi(data) {
        return BaseApi.putWithToken(APIURL.API_URL + `invoice/recurring-invoice/status`, data, LocalStorage.getAccessToken())
    }
    
}
// eslint-disable-next-line
export default new PauseResumeStopRecurrencesClientApi();