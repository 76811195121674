import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Grid } from '@mui/material';
import PassportView from './PassportView';
import I94View from './I94View';
import WorkView from './WorkView';

export default function WorkAuth(props) {
    const documentConfig = props.documentApiConfig
    const passportConfig = documentConfig?.workAuthorization?.passport
    const i94Config = documentConfig?.workAuthorization?.i94
    const visaConfig = documentConfig?.workAuthorization?.visa
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listi94, setListi94] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listWork, setListWork] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [current, setCurrent] = useState("education");// eslint-disable-next-line
    const [employee, setEmployee] = useState(props.employee)

    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        // getI94();
        // getVisa();
        // getPassport(); 
        // eslint-disable-next-line
    }, [props])

// eslint-disable-next-line
    const openInNewTab = (documentUrl) => {
        const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <Grid container>
            <Grid container p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
               <PassportView formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} passportConfig={passportConfig}/>
            </Grid>
            <Grid container mt={2} p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                <I94View formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} i94Config={i94Config}/>
            </Grid>
            <Grid container mt={2} p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                <WorkView formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} visaConfig={visaConfig}/> 
            </Grid>
        </Grid>

    );
}