import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Backdrop, TextField, Chip, Autocomplete } from '@mui/material';
import Button from '../../../../components/customButton/Button';
import LocalStorage from '../../../../utils/LocalStorage';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg'
import noDataFound from '../../../../assets/svg/NoDataFoundIcon.svg'
import { blue } from '../../../../theme';
import download from '../../../../assets/employee/filedownload.svg';
import CommonApi from '../../../../apis/CommonApi';
import Dropzone from 'react-dropzone';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import { BlackToolTip, addErrorMsg, addSuccessMsg, openDocumentInNewtab } from '../../../../utils/utils'
import LoadingScreen from './LoadingScreen';
import OnBoardApi from '../../../../apis/admin/employees/OnBoardApi';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../assets/svg/placementPop.svg'
import Userplus from '../../../../assets/svg/plus.svg';
import changesSent from '../../../../assets/svg/changesSent.svg'


export default function OtherDocument(props) {
    const otherDocumentsConfig = props.otherDocumentsConfig;
    const [list, setList] = useState({
        documents: []
    });// eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [loading, setloading] = useState(false);
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);// eslint-disable-next-line
    const [deleteId, setDeleteId] = useState('')
    const [state, setState] = useState({
        id: '',
        employee_id: props.employee.id,
        documents: [],
        documents_deleted_ids: [],
        valid_from: '',
        valid_till: '',
        clear_all: false
    });
    const [clearPopup, setclearPopup] = useState(false);
    const [clearPopup1, setclearPopup1] = useState(false);
    const [clearPopup2, setclearPopup2] = useState(false);
    const [clearPopup3, setclearPopup3] = useState(false);
    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition)
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition !== 'work' && props.documentPosition !== 'education') {
            getEducationIndex();
        }
        // eslint-disable-next-line
    }, [props])

    const getEducationIndex = () => {
        setloading(true)
        OnBoardApi.documentIndexConsultant(LocalStorage.uid(), props.employee.id, LocalStorage.getAccessToken()).then((response) => {
            setloading(false)
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        let data = response.data.data
                        let b = data.find((i) => i.document_type_id == 4)
                        if (b !== undefined) {
                            setList({ ...b });
                            if (b.documents.length === 0) {
                                let data = b
                                data.documents = [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        document_name: ""
                                    }
                                ]
                                setState({ ...data })
                            }
                            else {
                                setState(b)
                            }
                        } else {
                            setList({
                                documents: []
                            });
                        }
                    }
                    else {
                        setState({
                            ...state,
                            id: '',
                            documents: [],
                            documents_deleted_ids: [],
                            valid_from: '',
                            valid_till: '',
                            clear_all: false
                        })
                        setList({
                            ...state,
                            id: '',
                            documents: [],
                            documents_deleted_ids: [],
                            valid_from: '',
                            valid_till: '',
                            clear_all: false
                        })
                    }
                } else {
                    addErrorMsg(response.data.message);
                    setloading(false)
                }
            }, 400)
        });
    }


    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const handleDrop = (e) => {
        state.clear_all = false
        if (e.length < otherDocumentsConfig?.other_documents?.totalFiles + 1) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => otherDocumentsConfig?.other_documents?.format?.includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < otherDocumentsConfig?.other_documents?.size) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("other-document", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {
                                if (state.documents.length < 10) {
                                    state.documents.push({
                                        id: '',
                                        new_document_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                        slug: ''
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                    state.clear_all = false
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg(otherDocumentsConfig?.other_documents?.totalFilesMsg)
                                }
                                setState({ ...state })
                            } else {
                                setOpenBackDrop(false);
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg(otherDocumentsConfig?.other_documents?.sizeMsg);
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg(otherDocumentsConfig?.other_documents?.formatMsg)
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg(otherDocumentsConfig?.other_documents?.totalFilesMsg)
        }
        setState({ ...state })
    }

    const submitForm = (args) => {
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid();
            state['employee_id'] = props.employee.id;
            state['document_type_id'] = 4
            if (state.documents.length === 0) {
                state.clear_all = true
            }
            state.entity = 'other-documents'
            if (args) {
                setclearPopup2(true)
            }
            else {
                OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Changes Saved`);
                        setclearPopup1(true)
                        setformOpen(false);
                        getEducationIndex();
                        props.setformEdit(false)
                    } else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        else {
            if (state.documents.length > 0) {
                state['request_id'] = LocalStorage.uid()
                state['employee_id'] = props.employee.id
                state['document_type_id'] = 4
                state.entity = 'other-documents'
                if (args) {
                    setclearPopup2(true)
                }
                else {
                    OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`Details Added`);
                            setformOpen(false);
                            setclearPopup1(true)
                            getEducationIndex()
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            }
            else {
                setformOpen(false)
            }
        }
    }

    const removeDeletedItem = (item) => {
        let data = state
        if (data.documents_deleted_ids) { }
        else {
            data.documents_deleted_ids = []
        }
        if (item.id != '') {
            data.documents_deleted_ids.push(item.id)
        }
        data.documents.splice(item.index, 1)
        if (data.documents.length === 0) {
            data.clear_all = true
        }
        setState({ ...data })
        setDeletePopup(false)
    }

    const clearAll = () => {
        setclearPopup(false)
        if (state.documents.length > 0 && state.documents[0].id != '') {
            let err = []
            state.documents.forEach((i) => { err.push(i.id) })
            state.documents_deleted_ids = err
        }
        setState({
            ...state,
            clear_all: true,
            documents: [],
        })
    }

    const editDetails = () => {
        if (props.employee.pending_requests.other_documents) {
            setclearPopup3(true)
        }
        else {
            setformOpen(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container >
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={10}>
                            <Box p={1} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }} pt={2}>
                                <Text largeBlue>
                                    Other Documents
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                employee && employee.is_off_boarding_initiated === false && (list.documents && list.documents.length > 0) ?
                                    <Grid item lg={1} md={1} sm={1} xs={2}>
                                        <Box p={1} pr={0}>
                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                list.documents && list.documents.length > 0 ?
                                    list.documents.map((item, index) => (
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                <Text mediumLabel>
                                                    Other Document - {index + 1}
                                                </Text>
                                                <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item, item.document_url) }}>
                                                    <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                    <Text mediumBlue>
                                                        <BlackToolTip arrow placement='top' title={item.document_name}>
                                                            {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                        </BlackToolTip>
                                                    </Text>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    ))
                                    :
                                    <Grid container justifyContent={'center'} alignContent={'center'}>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>You can Upload other document for this employee</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={1}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>by clicking on the below button.</Text>
                                        </Grid>
                                        {
                                            employee.is_off_boarding_initiated === false &&
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={3}>
                                                <Button finishFilledQuicksandLargeWidth onClick={() => { editDetails() }}> <img src={Userplus} alt='plus' /> Add Documents</Button>
                                            </Grid>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{
                                                background: '#F0F7FF',
                                                border: `1px dashed #0C75EB`,
                                                padding: '30px',
                                                borderRadius: '12px'
                                            }}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Grid container spacing={1} textAlign='center'>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'-8px'}>
                                                            <img src={download} alt='download' />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                                            <Box display='flex' flexDirection={{ lg: 'row', md: "row", sm: "row", xs: "column" }} alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                <Text largeBlack>Drag & Drop Documents</Text>
                                                                <Text smallGrey>or</Text>
                                                                <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                                <Text largeBlack>To Upload</Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                            <Text smallGreyFont>{otherDocumentsConfig?.other_documents?.label?.format}</Text>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                        <div style={{ width: '100%', paddingTop: state.documents.length > 0 ? '12px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center", }}>
                                            <Autocomplete
                                                clearIcon={false}
                                                options={[]}
                                                freeSolo
                                                multiple
                                                onKeyPress={(e) => { e.preventDefault() }}
                                                value={state.documents}
                                                renderTags={(value, props) =>
                                                    value.map((option, index) => (
                                                        <Chip label={option.document_name} {...props({ index })}
                                                            onClick={() => openInNewTab(option, option.document_url)}
                                                            onDelete={() => { removeDeletedItem({ id: option.id, index: index }) }}
                                                            sx={{ color: '#0C75EB !important', font: '12px Quicksand !important', fontWeight: '600 !important' }}
                                                        // className={classes.chipLabel}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => <TextField smallLabel label={otherDocumentsConfig?.uploaded_docs?.label.length > 80 ? otherDocumentsConfig?.uploaded_docs?.label.slice(0,80) + "...": otherDocumentsConfig?.uploaded_docs?.label + "(optional)"} title={otherDocumentsConfig?.uploaded_docs?.label?.length > 80 ? otherDocumentsConfig?.uploaded_docs?.label : ''} sx={{ font: "14px Quicksand !important" }} {...params}/>}
                                                sx={{
                                                    width: '100%',
                                                    "& .MuiInputBase-root": {
                                                        cursor: "pointer",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        cursor: "pointer",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none"
                                                    },
                                                    "& .MuiAutocomplete-endAdornment": {
                                                        display: "none"
                                                    },
                                                    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                        transform: "translate(10px, 16px) scale(1);"
                                                    },
                                                    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                        color: "#737373",
                                                        fontSize: "14px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 400,
                                                    },
                                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                        color: "#737373",
                                                        fontSize: "15px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 500,
                                                    },
                                                    "&.Mui-focused .MuiInputLabel-outlined": {
                                                        color: "#737373",
                                                        fontSize: "10px",
                                                        fontFamily: "Quicksand !important",
                                                        fontWeight: 400,
                                                        transform: state.documents.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineQuicksand onClick={() => { setformOpen(false); getEducationIndex(); props.setformEdit(false) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'}>
                                            <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>
                                            <Button smallBlue onClick={() => { submitForm('popup') }}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }

                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openBacKLoader}
                        // onClick={handleClose}
                        >
                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                        </Backdrop>
                    </Grid>
            }
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setDeletePopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"}>
                        <Text offBoardBodyFont>Do you wish to delete this document</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setDeletePopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { removeDeletedItem(deleteId) }} >
                                Yes,delete
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup2} setOpenPopup={setclearPopup2} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to make this changes?</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup2(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { submitForm(); setclearPopup2(false); }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup1} setOpenPopup={setclearPopup1} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changesSent} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Changes Sent For Approval</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>"Thank you for updating your details. Your changes</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>have been submitted for approval to your employer. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Once approved, the new details will be displayed here. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Click 'OK' to proceed."</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup1(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}