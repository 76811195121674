import React from 'react';
import { Grid, Box, } from '@mui/material';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';

const DeleteDeductionPopup = ({
  openPopup,
  setOpenPopup,
  deleteAlertImage,
  titleText,
  descriptionText,
  cancelButtonText,
  ApproveButtonText,
  handleCancel,
  handleApprove,
}) => {
  return (
    <ReusablePopup openPopup={openPopup} setOpenPopup={setOpenPopup} white iconHide fixedWidth>
      <Grid container p={2} spacing={2} textAlign="center">
        <Grid item xs={12}>
          <img src={deleteAlertImage} alt="delete" />
        </Grid>
        <Grid item xs={12}>
          <Text veryLargeLabel>{titleText}</Text>
        </Grid>
        <Grid item xs={12}>
          <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px !important' }}>
            {descriptionText}
          </Text>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
            <Button blueboldCancel sx={{ color: '#525252 !important', fontSize: '13px !important', fontWeight: '600 !important', border: '1px solid #C7CCD3 !important', minWidth: 'fit-content !important', }} onClick={() => handleCancel()} > {cancelButtonText}</Button>
            <Button sx={{ minWidth: 'fit-content !important', fontSize: '13px !important', height: "44px !important" }} submitButtonEverify onClick={() => handleApprove()}>{ApproveButtonText}</Button>
          </Box>
        </Grid>
      </Grid>
    </ReusablePopup>
  );
};

export default DeleteDeductionPopup;
