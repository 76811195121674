import React, { useState } from "react";
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    Paper,
    Stack,
} from "@mui/material";
import Text from "../../../components/customText/Text";
import partiallyPaidIcon from '../../../assets/svg/invoice_partially_paid_icon.svg';
import blueInfoIcon from '../../../assets/svg/placement/blueinfo.svg';
import InvoiceAccordian from "./invoice_accordian";
import { NoDataFound, } from "../../../utils/utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));
const InvoicePdfScreen = (props) => {
    const { invoicePdfData, callRefetch } = props
    // State to toggle visibility
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            {invoicePdfData?.payment_details && invoicePdfData?.payment_details?.length > 0 && <InvoiceAccordian invoicePdfData={invoicePdfData} isOpen={isOpen} setIsOpen={setIsOpen} callRefetch={callRefetch} />}
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 2,
                    height: ["Partially Paid", "Paid",].includes(invoicePdfData?.status) ? '55vh' : '70vh',
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar for WebKit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer/Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                }}
            >
                <Box sx={{
                    padding: 4,
                }}>
                    {invoicePdfData?.approved_by ? invoicePdfData?.status != "Rejected" && invoicePdfData?.approval_level > 0 && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                                p={1}
                                sx={{
                                    backgroundColor: '#F8F4E3',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}
                            >
                                <Box pt={1}>
                                    <img src={partiallyPaidIcon} alt="Approval Icon" />
                                </Box>
                                <Text
                                    offBoardingText
                                    sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                >
                                    {`Level ${invoicePdfData?.approval_level} Approved done by  ${invoicePdfData?.approved_by}` || '--'}
                                </Text>
                                <Text
                                    greysmallLabel
                                    sx={{ width: '50%', marginLeft: 'auto', textAlign: 'end' }}
                                >
                                    {invoicePdfData?.approved_on || '--'}
                                </Text>
                            </Box>
                        </Grid>
                    ) : ''}

                    {invoicePdfData?.status == "Rejected" && invoicePdfData.rejected_by ? <Grid item lg={12} md={12} sm={12} xs={12} mt={3} mb={3}>
                        <Box p={1} sx={{ backgroundColor: '#F8EBEB', borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box pt={1}>
                                <img src={partiallyPaidIcon} alt={partiallyPaidIcon} />
                            </Box>
                            <Box >
                                <Text blackHeader1 >
                                    {invoicePdfData.rejected_by ? `${invoicePdfData.rejected_by}  Rejected this invoice` : '--'}
                                </Text>
                                <Text BlackExtraDark sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word', fontSize: '12px !important' }} mt={.5}>
                                    {invoicePdfData.reason_for_rejection ? `${invoicePdfData.reason_for_rejection}` : '--'}
                                </Text>
                            </Box>
                            <Text greysmallLabel sx={{
                                width: '50%',
                                marginLeft: 'auto',
                                textAlign: 'end'
                            }}>
                                {invoicePdfData.rejected_on ? invoicePdfData.rejected_on : '---'}
                            </Text>

                        </Box>
                    </Grid> : ''}
                    {invoicePdfData ? <Box sx={{
                        border: '1px solid #FAFAFA',
                        borderRadius: '12px',
                        padding: 3,
                    }}
                        mb={3}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <img
                                            src={invoicePdfData.from_details && invoicePdfData.from_details[0]?.logo_url ? `${invoicePdfData.from_details[0]?.logo_url}?w=40&h=40&fit=crop` : ''}
                                            alt="Logo"
                                            style={{ width: 50, height: 50, borderRadius: "50%" }}
                                        />
                                    </Box>
                                    <Text blackHeader1 textAlign="right">
                                        Invoice No
                                        <br />
                                        <Text black18px sx={{ fontSize: '12px !important' }}>{invoicePdfData.invoice_id}</Text>
                                    </Text>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} justifyContent="space-between">
                                    {/* From Section */}
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Box>
                                            <Text blackHeader1>
                                                From: {invoicePdfData.from_details?.[0]?.organization_name}
                                            </Text>
                                            <Text
                                                mt={1}
                                                largeLabel
                                                sx={{
                                                    fontWeight: '400 !important',
                                                    fontSize: "13px !important",
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                }}
                                            >
                                                {invoicePdfData.from_details?.[0]?.total_address}
                                            </Text>
                                        </Box>
                                    </Grid>

                                    {/* To Section (Aligned to the end) */}
                                    <Grid item xs={12} sm={6} md={6} lg={4} display="flex" justifyContent={{ lg: "flex-end" }}>
                                        <Box textAlign={{ xs: "left", sm: "right" }}>
                                            <Text blackHeader1>
                                                To: {invoicePdfData.to_details ? invoicePdfData.to_details[0]?.name : ""}
                                            </Text>
                                            <Text
                                                mt={1}
                                                largeLabel
                                                sx={{
                                                    fontWeight: '400 !important',
                                                    fontSize: "13px !important",
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {invoicePdfData.to_details?.[0]?.total_address}
                                            </Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>


                        <Divider sx={{ marginY: 2 }} />

                        <Grid
                            container
                            spacing={2}
                            justifyContent="space-evenly"
                            alignItems="center"
                            mb={2.5}
                        >
                            <Grid item xs={12} sm="auto">
                                <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                    Invoice Date: {invoicePdfData.invoice_date}
                                </Text>
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <Divider
                                    sx={{
                                        margin: "0px !important",
                                        borderColor: "#171717 !important",
                                        borderWidth: "1px !important",
                                        height: { xs: '1px', sm: '20px' },
                                        width: { xs: '100%', sm: 'auto' },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                    Terms: {invoicePdfData.net_pay_terms} Days
                                </Text>
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <Divider
                                    sx={{
                                        margin: "0px !important",
                                        borderColor: "#171717 !important",
                                        borderWidth: "1px !important",
                                        height: { xs: '1px', sm: '20px' },
                                        width: { xs: '100%', sm: 'auto' },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                    Due Date: {invoicePdfData.due_date}
                                </Text>
                            </Grid>
                        </Grid>


                        <Divider sx={{ marginY: 2 }} />
                        {invoicePdfData.item_details && invoicePdfData.item_details.length > 0 ? (
                            <Box sx={{ width: "100%", overflow: "auto" }} pt={2}>
                                <TableContainer component={Paper} sx={{ border: '1px solid #E2E5E6', borderRadius: '10px', minWidth: 550, }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '8%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>S.No</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '30%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Details</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '10%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Hours</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '10%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Rate</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '13%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Hours</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '13%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Rate</Text></TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '12%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Amount</Text></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoicePdfData.item_details && invoicePdfData.item_details?.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '8%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', }}>
                                                            {index + 1}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '35%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', fontWeight: '700 !important' }}>
                                                            {row.item_name ? row.item_name : '---'}
                                                        </Text>

                                                        <Text mediumLabel>{row.description ? row.description : "---"}</Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '8%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            {row.hours}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            {row.bill_rate}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            {row.ot_hours}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            {row.ot_bill_rate}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                        <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            {row.amount}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>) : ''}

                        <Box mt={3} display="flex" justifyContent="flex-end" mb={4}>
                            <Box>
                                <Box display="flex" justifyContent="space-between" gap={3}>
                                    <Text greysmallLabel>Sub Total</Text>
                                    <Text black400 sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.sub_total_amount ? invoicePdfData.sub_total_amount : '---'}</Text>
                                </Box>
                                {invoicePdfData.discount_amount && invoicePdfData.discount_amount != 0.00 && (
                                    <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                        <Text greysmallLabel>Discount</Text>
                                        <Text veryLargeLabel sx={{ fontSize: '12px !important', color: '#EA5B45 !important' }}>
                                            - $ {invoicePdfData.discount_amount}
                                        </Text>
                                    </Box>
                                )}
                                {(invoicePdfData.adjustment_amount && invoicePdfData.adjustment_amount != 0.00) ? <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                    <Stack display='flex' direction='row' gap='4px' justifyContent='center' alignItems='center'>
                                        <Text greysmallLabel>Adjustments</Text>
                                        {invoicePdfData?.adjustment_comment !== null && invoicePdfData?.adjustment_comment !== '' ?
                                            <HtmlTooltip
                                                placement="bottom"
                                                arrow
                                                sx={{ marginBottom: '10px !important' }}
                                                title={invoicePdfData?.adjustment_comment}
                                            >
                                                <img src={blueInfoIcon} alt="comments" width={'20px'} height={'20px'} />
                                            </HtmlTooltip>
                                            : null
                                        }
                                    </Stack>
                                    <Text veryLargeLabel sx={{ fontSize: '12px !important', color: invoicePdfData.adjustment_type == 2 ? '#EA5B45 !important' : '' }}>{invoicePdfData.adjustment_type == 2 && '-'} $ {invoicePdfData.adjustment_amount ? invoicePdfData.adjustment_amount : '---'}</Text>
                                </Box> : ''}
                                <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                    <Text greysmallLabel>Total</Text>
                                    <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.total_amount ? invoicePdfData.total_amount : '---'}</Text>
                                </Box>
                                {invoicePdfData.payment_made == '0' ? '' : <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                    <Text greysmallLabel>Payment Made</Text>
                                    <Text veryLargeLabel sx={{ fontSize: '12px !important', color: '#EA5B45 !important' }}> $ {invoicePdfData.payment_made ? `- ${invoicePdfData.payment_made}` : '---'}</Text>
                                </Box>}
                                <Divider sx={{ marginY: 1 }} />
                                <Box display="flex" justifyContent="space-between" gap={3}>
                                    <Text greysmallLabel>Balance Due:</Text>
                                    <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.balance_amount ? invoicePdfData.balance_amount : '---'}</Text>
                                </Box>
                            </Box>

                        </Box>
                        {/* <Box mt={3} display="flex" justifyContent="flex-start" mb={3}>
                            <Box>
                                <Box>
                                    <Text popupHead1 sx={{ fontSize: '14px !important' }}>Documents:</Text>
                                    {invoicePdfData.documents && invoicePdfData.documents?.length > 0 ? invoicePdfData.documents?.map((items, index) => (
                                        <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(items, items.document_url) }} key={index}>
                                            <Text mediumBlue>
                                                <BlackToolTip arrow placement='top' title={items?.name}>
                                                    {items.document_name?.slice(0, 16)} {items.document_name?.length > 16 ? '...' : ''}
                                                </BlackToolTip>
                                            </Text>
                                        </Stack>
                                    )) : '---'}
                                </Box>
                            </Box>
                        </Box> */}
                    </Box> : NoDataFound()}
                    {invoicePdfData.terms_and_conditions ? <Box
                        sx={{
                            border: '1px solid #FAFAFA',
                            borderRadius: '12px',
                            padding: 3,
                        }}
                        mb={3}
                    >
                        <Text mb={4} veryLargeLabel sx={{ fontSize: '16px !important' }}>
                            Terms & Conditions
                        </Text>
                        <Box sx={{ marginBottom: 2 }}>
                            <pre>
                                <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px important', }}>{invoicePdfData?.terms_and_conditions}</Text>
                            </pre>
                        </Box>
                    </Box> : ''}
                    {/* <Box sx={{
                        border: '1px solid #FAFAFA',
                        borderRadius: '12px',
                    }}
                        mb={3}>
                        <Text veryLargeLabel sx={{ fontSize: '15px !important' }} m={2}>Attachments</Text>
                        <Box >
                            {data.weekly.map((value, index) => (
                                <TimeSheetTable
                                    data={value}
                                    index={index}
                                // onChange={tableHandleChange}
                                // status={stateId.state !== null && stateId.state.status}
                                // otHoursHandleChange={otHoursHandleChange}
                                // isEdit={!edit}
                                />
                            ))}
                        </Box>
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default InvoicePdfScreen;
