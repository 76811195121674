import { Box, Grid } from '@mui/material'
import React from 'react'
import Text from '../../../../components/customText/Text'
import Date from '../../../../components/datePicker/Date'
import CustomButton from '../../../../components/customButton/Button';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import OffBoardImg from "../../../../assets/svg/OffBoardPop.svg"
import Input from '../../../../components/input/Input';
import moment from 'moment';
import { dateFormat } from '../../../../utils/utils';

function UserDisable(props) {
    const { lastWorkingDate, dateChange, error, handleClosePopUp, minDate, loader, handleSubmit, changeHandler, offBoardingReason, offboardingPopupDetailsConfig } = props;

    const toDay = moment().format(dateFormat());

    return (
        <>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <img src={OffBoardImg} alt="OffBoard" />
            </Box>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <Text my={1} popupHead1 sx={{ fontSize: "18px !important" }}> Are You Sure?</Text>
            </Box>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <Text my={1} popupHead2 sx={{ color: "#525252 !important", fontSize: "14px !important" }}>The following changes will be made after initiating the offboarding</Text>
            </Box>

            <Box my={2} sx={{ background: '#F8F8F8', borderRadius: '8px' }} p={{ lg: '20px', md: '20px', sm: '20px', xs: '5px' }} alignItems='center'>
                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                    <Box sx={{ height: '5px', width: '5px', background: 'black', borderRadius: '50%' }}></Box>
                    <Text smallBlack sx={{ fontSize: "14px !important" }} >The offboarding will be initiated and the progress will shown one week before the last working day.</Text>
                </Box>
            </Box>
            <Grid my={1} container lg={12} md={12} sm={12} xs={12} justifyContent='start'>
                {offboardingPopupDetailsConfig?.lastWorkingDate?.isVisible &&
                <Grid item lg={8} md={8} sm={8} xs={12}>
                    {
                        minDate.status === "On-Boarding" ?
                            <Date
                                name='lastWorkingDate'
                                value={lastWorkingDate}
                                labelText={offboardingPopupDetailsConfig?.lastWorkingDate?.required ? offboardingPopupDetailsConfig?.lastWorkingDate?.label : offboardingPopupDetailsConfig?.lastWorkingDate?.label + ' (Optional)'}
                                height='54px'
                                minDate={toDay}
                                maxDate={moment(minDate.date_of_joining, dateFormat()).subtract(1, 'days')}
                                onChange={(value => dateChange(value, 'lastWorkingDate'))}
                                disabled={offboardingPopupDetailsConfig?.lastWorkingDate?.isDisabled}
                                error={error ? error.date : ''}
                            />
                            :
                            <Date
                                name='lastWorkingDate'
                                value={lastWorkingDate}
                                labelText={offboardingPopupDetailsConfig?.lastWorkingDate?.required ? offboardingPopupDetailsConfig?.lastWorkingDate?.label : offboardingPopupDetailsConfig?.lastWorkingDate?.label + ' (Optional)'}
                                height='54px'
                                minDate={minDate.min_last_working_day}
                                onChange={(value => dateChange(value, 'lastWorkingDate'))}
                                disabled={offboardingPopupDetailsConfig?.lastWorkingDate?.isDisabled}
                                error={error ? error.date : ''}
                            />
                    }
                    {error && <Text red>{error ? error.date : ''}</Text>}
                </Grid>}
            </Grid>
            {offboardingPopupDetailsConfig?.description?.isVisible &&
            <Grid item lg={6} md={4} sm={4} xs={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    multiline={true}
                    rows={offboardingPopupDetailsConfig?.description?.rows}
                    inputProps={{
                        name: 'description',
                        value: offBoardingReason,
                        inputProps: { maxLength: offboardingPopupDetailsConfig?.description?.maxLength }
                    }}
                    handleChange={changeHandler}
                    disabled={offboardingPopupDetailsConfig?.description?.isDisabled}
                    // clientInput
                    commentsField
                    labelText={offboardingPopupDetailsConfig?.description?.required ? offboardingPopupDetailsConfig?.description?.label : offboardingPopupDetailsConfig?.description?.label + ' (Optional)'}
                    placeholder={offboardingPopupDetailsConfig?.description?.placeholder}
                    error={error.description}
                />
                {<Text red>{error.description}</Text>}
            </Grid>}
            <Box mt={3} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                <CustomButton disabled={loader} cancelOutlineRed onClick={() => handleClosePopUp()}>
                    Cancel
                </CustomButton>
                <LoaderButton loading={loader} finishFilledQuicksand onClick={handleSubmit}>Continue</LoaderButton>
            </Box>
        </>
    )
}

export default UserDisable