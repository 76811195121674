import { Avatar, Box, CircularProgress, Grid, Breadcrumbs, IconButton, Skeleton, Stack, Typography, Badge } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Button from '../../components/customButton/Button'
import DashboardStyles from '../../views/admin/timesheets/newTimeSheets/DashboardStyles';
import TimeSheetStyles from '../../views/admin/timesheets/newTimeSheets/TimeSheetStyles';
import TimeSheetTable from '../../views/admin/timesheets/newTimeSheets/TimeSheetTable';
import Input from "../../components/input/Input";
import Datepicker from '../../components/datePicker/Date';
import Dropzone from "react-dropzone";
import { blue } from "../../theme";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';

import Text from '../../components/customText/Text';
import AddIcon from '@mui/icons-material/Add';
import LocalStorage from '../../utils/LocalStorage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import Search from '../../assets/svg/search1.svg';
import NewDownloadcloud from '../../assets/svg/download-cloud-new.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import infoCircle from '../../assets/images/info-circle.png';
import Component87 from '../../assets/svg/Component87.svg';
import documentSVG from '../../assets/svg/document.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { NoDataFound, capitalizeAndAddSpace, BlackToolTip, NoPermission, } from '../../utils/utils';
import Pending from '../../assets/employee/Orange-PendingIcon.svg';  // eslint-disable-next-line
import CircleIcon from '@mui/icons-material/Circle';   // eslint-disable-next-line
import rightArrow from '../../assets/timeSheets/white-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { employee_URL } from '../../config/development';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

export default function TourTimesheet({ setStartTour,destroyTour, setDestroyTour }) {

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const classes = DashboardStyles();
    const timesheetClasses = TimeSheetStyles();
    const pagination = { total: "5" }
    const navigate = useNavigate();
    const timesheets = [
        {
            timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
            employee_id: "e78b84a9-9243-45fc-a5aa-8a4bc1f13eae",
            employee_name: "ABC EFG",
            employee_reference_id: "CON-142",
            employee_e_verified: 4,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "88.89",
            placement_id: "4730caf3-409b-4209-951c-9091772f133f",
            placement_reference_id: "PLS-160",
            timesheet_reference_id: "TS-1602",
            timesheet_cycle: "Weekly",
            start_date: "10/29/2024",
            end_date: "11/02/2024",
            client_name: "Client One",
            client_reference_id: "CLT-3",
            client_logo_url: "",
            client_profile_progress: 100,
            status: "Draft",
            billable_hours: "0.00",
            ot_hours: "0.00",
            documents: [],
            total_hours: "0.00",
            submitted_on: "10/29/2024",
            approved_on: null,
            drafted_on: "10/29/2024"
        },
        {
            timesheet_id: "4242dae2-05c2-44e2-82c7-6545ea03a19a",
            employee_id: "120850bc-6a4b-4891-bae7-29b739558f17",
            employee_name: "HIJ KLM",
            employee_reference_id: "CON-140",
            employee_e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "55.56",
            placement_id: "b46a0810-0e10-47bd-b5cc-761b86a35979",
            placement_reference_id: "PLS-159",
            timesheet_reference_id: "TS-1601",
            timesheet_cycle: "Monthly",
            start_date: "10/28/2024",
            end_date: "10/31/2024",
            client_name: "Client One",
            client_reference_id: "CLT-35",
            client_logo_url: "",
            client_profile_progress: 100,
            status: "Draft",
            billable_hours: "0.00",
            ot_hours: "0.00",
            documents: [],
            total_hours: "0.00",
            submitted_on: "10/28/2024",
            approved_on: null,
            drafted_on: "10/28/2024"
        },
        {
            timesheet_id: "030587be-b239-4537-a8ab-461376556aee",
            employee_id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
            employee_name: "MNO OPQ",
            employee_reference_id: "CON-2",
            employee_e_verified: 4,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "100.00",
            placement_id: "f8df2fe6-2102-411b-8b80-b8dc351bdec3",
            placement_reference_id: "PLS-158",
            timesheet_reference_id: "TS-1600",
            timesheet_cycle: "Weekly",
            start_date: "10/21/2024",
            end_date: "10/27/2024",
            client_name: "Client Two",
            client_reference_id: "CLT-36",
            client_logo_url: "",
            client_profile_progress: 100,
            status: "Draft",
            billable_hours: "0.00",
            ot_hours: "0.00",
            documents: [],
            total_hours: "0.00",
            submitted_on: "10/22/2024",
            approved_on: null,
            drafted_on: "10/22/2024"
        },
        {
            timesheet_id: "5bd03a65-032f-4c02-9df6-9778fbbaac95",
            employee_id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
            employee_name: "RST UVW",
            employee_reference_id: "CON-2",
            employee_e_verified: 4,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "100.00",
            placement_id: "f8df2fe6-2102-411b-8b80-b8dc351bdec3",
            placement_reference_id: "PLS-158",
            timesheet_reference_id: "TS-1599",
            timesheet_cycle: "Weekly",
            start_date: "10/14/2024",
            end_date: "10/20/2024",
            client_name: "Client Two",
            client_reference_id: "CLT-36",
            client_logo_url: "",
            client_profile_progress: 100,
            status: "Draft",
            billable_hours: "0.00",
            ot_hours: "0.00",
            documents: [],
            total_hours: "0.00",
            submitted_on: "10/22/2024",
            approved_on: null,
            drafted_on: "10/22/2024"
        },
        {
            timesheet_id: "58ebc1e2-a0ff-440b-9870-83161e015366",
            employee_id: "12e91aa4-69e4-48c2-b0b7-bb8f8f234bec",
            employee_name: "XYZ UVW",
            employee_reference_id: "CON-2",
            employee_e_verified: 4,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            gender: "Male",
            profile_progress: "100.00",
            placement_id: "f8df2fe6-2102-411b-8b80-b8dc351bdec3",
            placement_reference_id: "PLS-158",
            timesheet_reference_id: "TS-1598",
            timesheet_cycle: "Weekly",
            start_date: "10/07/2024",
            end_date: "10/13/2024",
            client_name: "Client Two",
            client_reference_id: "CLT-36",
            client_logo_url: "",
            client_profile_progress: 100,
            status: "Draft",
            billable_hours: "0.00",
            ot_hours: "0.00",
            documents: [],
            total_hours: "0.00",
            submitted_on: "10/22/2024",
            approved_on: null,
            drafted_on: "10/22/2024"
        },
    ]
    const values = {
        id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
        timesheet_reference_id: "TS-1602",
        employee_id: "e78b84a9-9243-45fc-a5aa-8a4bc1f13eae",
        employee_name: "Abc Def",
        emp_reference_id: "CON-142",
        employee_e_verified: 4,
        profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
        gender: "Male",
        placement_id: "4730caf3-409b-4209-951c-9091772f133f",
        placement_reference_id: "PLS-160",
        status: "Draft",
        client_id: "5f6a3409-b195-4a7e-8922-4782ec04aee8",
        client_name: "Client One",
        client_reference_id: "CLT-3",
        client_logo_url: "",
        client_profile_progress: 100,
        end_client_id: null,
        end_client_name: null,
        start_date: "10/29/2024",
        end_date: "11/02/2024",
        comments: null,
        total_ot_hours: "00:00",
        total_billable_hours: "00:00",
        total_hours: "00:00",
        approval_level: 1,
        timesheet_start_day: "Sunday",
        timesheet_start_date: "10/29/2024",
        timesheet_cycle: "Weekly",
        submitted_on: null,
        approved_on: null,
        drafted_on: "10/29/2024",
        timesheet: [
            {
                id: "3709b753-9c0e-4119-a3b0-1a5bddde042c",
                date: "2024-10-29",
                ot_hours: "00:00",
                total_hours: "08:00",
                timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                billable_hours: "08:00"
            },
            {
                id: "963d42d0-97d6-49c2-96bd-6ac643f21f6e",
                date: "2024-10-30",
                ot_hours: "00:00",
                total_hours: "08:00",
                timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                billable_hours: "08:00"
            },
            {
                id: "ad5f461f-1ef2-4141-abd0-055b83bc3ead",
                date: "2024-10-31",
                ot_hours: "00:00",
                total_hours: "08:00",
                timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                billable_hours: "08:00"
            },
            {
                id: "9e8ed713-ee89-40fd-8e5f-200577c414c7",
                date: "2024-11-01",
                ot_hours: "00:00",
                total_hours: "08:00",
                timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                billable_hours: "08:00"
            },
            {
                id: "ff01d0fb-9974-4c00-95dd-08267ecea894",
                date: "2024-11-02",
                ot_hours: "00:00",
                total_hours: "00:00",
                timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                billable_hours: "00:00"
            }
        ],
        documents: [],
        reason_rejection: null,
        is_timesheet_attachment_mandatory: false,
        is_submit: true,
        is_approver: false,
        is_editable: true,
        is_delete: true,
        label: "Level Draft",
        documents_deleted_ids: [],
        weekly: [
            {
                billable_hours: "32:00",
                ot_hours: "00:00",
                total_hours: "32:00",
                timesheet: [
                    {
                        id: "3709b753-9c0e-4119-a3b0-1a5bddde042c",
                        date: "2024-10-29",
                        ot_hours: "00:00",
                        total_hours: "08:00",
                        timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                        billable_hours: "08:00"
                    },
                    {
                        id: "963d42d0-97d6-49c2-96bd-6ac643f21f6e",
                        date: "2024-10-30",
                        ot_hours: "00:00",
                        total_hours: "08:00",
                        timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                        billable_hours: "08:00"
                    },
                    {
                        id: "ad5f461f-1ef2-4141-abd0-055b83bc3ead",
                        date: "2024-10-31",
                        ot_hours: "00:00",
                        total_hours: "08:00",
                        timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                        billable_hours: "08:00"
                    },
                    {
                        id: "9e8ed713-ee89-40fd-8e5f-200577c414c7",
                        date: "2024-11-01",
                        ot_hours: "00:00",
                        total_hours: "08:00",
                        timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                        billable_hours: "08:00"
                    },
                    {
                        id: "ff01d0fb-9974-4c00-95dd-08267ecea894",
                        date: "2024-11-02",
                        ot_hours: "00:00",
                        total_hours: "00:00",
                        timesheet_id: "65303b3a-4ce2-49ca-ac96-2ec35de95a5b",
                        billable_hours: "00:00"
                    }
                ]
            }
        ]
    };
    const error = {};

    const loading = false;
    const [timesheetStatus, settimesheetStatus] = useState('timesheet');

    const timeSheetsCount = { drafted: "", pending_approval: "", approved: "", rejected: "" }
    const filterData = {
        limit: 10,
        page: 1,
        search: "",
        status: 'Draft',
        placement_ids: [],
        timesheet_cycles: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        employee_id: "",
        sort_column: "ts.created_at",
        sort_order: "desc"
    };


    function formatValue(value) {
        var decimalPart = parseFloat(value) % 1;
        if (decimalPart === 0) {
            return Math.floor(value);
        }
        //  else if (decimalPart === 0.5) {
        //     // return value.toFixed(2);
        //     return value;           
        // } 
        else {
            return value; // For other cases, return the original value
        }
    }

    const steps = [{
        element: '#addtimesheet', popover: {
            title: 'Add Timesheet', description:
                'Easily create and manage new timesheets. Enter relevant details and assign them to the appropriate employees or projects to ensure accurate time tracking and management.', side: "left",
            align: 'start'
        }
    }, {
        element: '#tstabs', popover: {
            title: 'TS Category Tabs', description:
                'Manage timesheets according to their status for smooth and efficient workforce management.', side: "right",
            align: 'start'
        }
    }, {
        element: '#summary', popover: {
            title: 'Summary', description:
                'Review a comprehensive summary of timesheets. Get an overview of total hours worked, submitted timesheets, and any discrepancies that need attention.', side: "left",
            align: 'start'
        }
    }, {
        element: '#viewts', popover: {
            title: 'View Timesheet', description:
                'Access detailed information for individual timesheets. Check and update timesheet entries, review hours logged, and make necessary adjustments.', side: "left",
            align: 'start'
        }
    }, {
        element: '#tsbox', popover: {
            title: 'TS Box', description:
                'Detailed view of timesheet entries. Check individual time logs, review hours worked per project or task, and verify accuracy. Use this view to ensure that all timesheet data is correctly recorded and processed.', side: "top",
            align: 'start'
        }
    }
        // , {
        // element: '#aicredits', popover: {
        //     title: 'AI Capture', description:
        //         'Click here to automatically extract and process your data efficiently.', side: "left",
        //     align: 'start'
        // }
    // }
    ]
    useEffect(() => {
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 0,
            stageRadius: 0,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 15,
            onPopoverRender: (popover, opts) => {//creates the popover with required design 
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                console.log('opts', opts)
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    });
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        event.stopPropagation();
                        if (currentStep == 4) {
                            settimesheetStatus('timesheet');
                            driverObj.movePrevious();
                        }
                        else {
                            driverObj.movePrevious();
                        }
                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    LocalStorage.setStartTour({ 'timesheets': false })
                    updateTourGuideStatus('TIMESHEETS');
                    setStartTour();
                    driverObj.destroy();
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    LocalStorage.setStartTour({ 'timesheets': false })
                    updateTourGuideStatus('TIMESHEETS');
                    setStartTour();
                    driverObj.destroy();
                    if (LocalStorage.getUserData().org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    if (currentStep == 3) {
                        settimesheetStatus('edittimesheet');
                        setTimeout(() => { driverObj.moveNext(); }, 300);
                    }
                    else {
                        driverObj.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });
        if (destroyTour) {
            driverObj.destroy();
            setDestroyTour(false); // Reset the destroyTour state
        }else{
        setTimeout(() => {
            driverObj.drive();
        }, 500);}

        return () => {
            driverObj.destroy();
        };
        // eslint-disable-next-line
    }, [destroyTour, setDestroyTour]);
    return (
        <>
            {timesheetStatus == 'timesheet' &&
                <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>
                    <Box sx={{ width: "90%" }} >
                        {
                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                <Box style={{ padding: "20px 10px 10px 10px" }}>
                                    <Grid container>
                                        <Grid item lg={7} md={7} >
                                            <Box id='tstabs' sx={{ width: '545px' }}>
                                                <Box className={classes.btnBox} >
                                                    <Button saveBtn endIcon={(filterData.status == "Draft" || timeSheetsCount.drafted == "" || timeSheetsCount.drafted == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.drafted}</Text></Box>} className={filterData.status == "Draft" ? classes.activeButton : classes.inActiveButton}>Drafted</Button>
                                                    <Button saveBtn endIcon={(filterData.status == "Pending Approval" || timeSheetsCount.pending_approval == "" || timeSheetsCount.pending_approval == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.pending_approval}</Text></Box>} className={filterData.status == "Pending Approval" ? classes.pendingActiveButton : classes.pendingInActiveButton} sx={{ marginLeft: "8px" }}>Pending Approval</Button>
                                                    <Button saveBtn endIcon={(filterData.status == "Approved" || timeSheetsCount.approved == "" || timeSheetsCount.approved == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.approved}</Text></Box>} className={filterData.status == "Approved" ? classes.approvedActiveButton : classes.approvedInActiveButton} sx={{ marginLeft: "8px" }}>Approved</Button>
                                                    <Button saveBtn endIcon={(filterData.status == "Rejected" || timeSheetsCount.rejected == "" || timeSheetsCount.rejected == "0") ? "" : <Box className={classes.countInfoBox}><Text smallWhite className={classes.smallWhite}>{timeSheetsCount.rejected}</Text></Box>} className={filterData.status == "Rejected" ? classes.rejectedActiveButton : classes.rejectedInActiveButton} sx={{ marginLeft: "8px" }}>Rejected</Button>
                                                </Box></Box>
                                        </Grid>
                                        <Grid item lg={5} md={5} container alignItems={"center"} justifyContent={"end"} columnGap={2}>
                                            <button title='Activity' type="button" className={classes.borderButton} ><AccessTimeIcon sx={{ height: "20px", width: "22px" }} /></button>
                                            <Box id='summary' sx={{ padding: '5px' }}>
                                                <Button outlineBlue sx={{ width: "104px !important" }} >Summary</Button>

                                            </Box>
                                            <Box id="addtimesheet" sx={{ padding: '5px', marginLeft: '-10px' }}>
                                                <Button sx={{
                                                    padding: "0px 15px !important", height: "40px !important", width: "174px !important",
                                                    "@media (min-width: 800px) and (max-width: 1400px)": {
                                                        width: "144px !important"
                                                    }
                                                }} addButton startIcon={<AddIcon />} id=''>Add Timesheet</Button>
                                            </Box>


                                        </Grid>
                                        <Grid item lg={7} md={7} pt={3}>
                                            <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "269px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                <input
                                                    type="text"
                                                    value={filterData.search}
                                                    className={classes.searchInput}

                                                    placeholder="Search by Name / Timesheet ID"
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                    {
                                                        filterData.search !== "" ?
                                                            <ClearIcon sx={{ cursor: "pointer" }} />
                                                            :
                                                            <img src={Search} alt="Search" />
                                                    }
                                                </Box>
                                            </div>
                                        </Grid>
                                        <Grid item lg={5} md={5} pt={3} container alignItems={"center"} justifyContent={"end"} columnGap={4}>
                                            <button title="Export" type="button" className={classes.borderButton1} ><img src={NewDownloadcloud} alt="Userplus" /></button>
                                            <button title="Filter" type="button" className={classes.borderButton1}>
                                                <Badge badgeContent={filterData.timesheet_cycles.length + filterData.placement_ids.length + ((filterData.start_date_from !== "" && filterData.start_date_to !== "") && 1) + ((filterData.end_date_from !== "" && filterData.end_date_to !== "") && 1)} color="error">
                                                    <FilterListIcon sx={{ color: filterData.timesheet_cycles.length || filterData.placement_ids.length || (filterData.start_date_from !== "" && filterData.start_date_to !== "") || (filterData.end_date_from !== "" && filterData.end_date_to !== "") ? "#2BEA2B" : "#737373" }} />
                                                </Badge>
                                            </button>
                                            <Text offBoardBodyFont>
                                                Count
                                                <HtmlTooltip
                                                    placement="bottom"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Typography className={classes.profileTooltipText}>
                                                                    Total Timesheets count is <br />based on the filters applied
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                </HtmlTooltip>
                                                {` - ${pagination ? pagination.total ? pagination.total : "" : ""} ${filterData.status}`}</Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems='center' padding={"0px 40px"} pt={2} sx={{ borderBottom: "1px solid #F3EFEF !important" }}>

                                        <Grid container item lg={4.5} md={4} justifyContent={"start"} alignItems={"center"}>
                                            <Text mediumBlack>Employee Name</Text>
                                            <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} >
                                                <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "emp.display_name" || filterData.sort_column == "ts.created_at") ? "#2BEA2B" : "" }} />
                                            </IconButton>

                                        </Grid>
                                        {filterData.status === "Draft" && <Grid item lg={2} md={2}><Text mediumBlack ml={"-5px"}>Timesheet Cycle</Text></Grid>}
                                        <Grid item lg={2.1} md={2.5}><Text mediumBlack ml={"-8px"}>From Period - To Period</Text></Grid>
                                        {filterData.status !== "Draft" && <Grid item lg={1} md={1}><Text mediumBlack ml={"-8px"}>Hours</Text></Grid>}
                                        <Grid item lg={2} md={2}><Text mediumBlack ml={"-10px"}>Placement</Text></Grid>
                                        {filterData.status !== "Draft" && <Grid item lg={1} md={1}><Text mediumBlack>Attachment</Text></Grid>}
                                        <Grid item lg={1} md={1} textAlign='center'><Text mediumBlack>Action</Text></Grid>
                                    </Grid>

                                    {
                                        loading ?
                                            [1, 2, 3, 4].map((item) => (
                                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Box>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            )) :
                                            <>
                                                {
                                                    timesheets.length > 0 ?
                                                        <Box sx={{
                                                            maxHeight: "59.4vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start', marginTop: '5px',
                                                            paddingLeft: '5px', paddingRight: '5px',
                                                            "@media (min-height: 850px)": {
                                                                maxHeight: '68.9vh'
                                                            }
                                                        }} >
                                                            {
                                                                timesheets.map((place, key) => (
                                                                    <Box sx={{
                                                                        width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", '&:hover': {
                                                                            borderRadius: '8px',
                                                                            transition: "all .3s ease",
                                                                            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                                                                        },
                                                                        margin: "4px 0px !important",
                                                                    }} mt={2}>
                                                                        <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                                            <Grid item lg={4.5} md={4} textAlign='start' pt={1}>
                                                                                <Box display='flex' alignItems='center' gap={2}>
                                                                                    <Box sx={{ position: 'relative', display: 'flex' }}>
                                                                                        <CircularProgress variant="determinate" value={place.profile_progress} size="3.8rem" sx={{ backgroundColor: "#ffffff", color: '#ffffff', borderRadius: "100%", }} />
                                                                                        <Box
                                                                                            sx={{
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                                bottom: 0,
                                                                                                right: 0,
                                                                                                position: 'absolute',
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>
                                                                                                <HtmlTooltip
                                                                                                    placement="bottom"
                                                                                                    arrow
                                                                                                    title={
                                                                                                        <React.Fragment>
                                                                                                            <Box>
                                                                                                                <Typography className={classes.profileTooltipText}>
                                                                                                                    {`Profile Completion - ${place.profile_progress}%`}
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                >
                                                                                                    <Avatar
                                                                                                        alt={place.employee_name}
                                                                                                        src={place.profile_picture_url}
                                                                                                        sx={{ width: 50, height: 50, }}
                                                                                                    />
                                                                                                </HtmlTooltip>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Text mediumBoldBlack noWrap>
                                                                                            {place.employee_name === "" ? "--" :
                                                                                                capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                        {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                    </HtmlTooltip>
                                                                                                    :
                                                                                                    capitalizeAndAddSpace(place.employee_name)
                                                                                            }
                                                                                            <HtmlTooltip title={<Text smallWhite>{place.employee_e_verified == 1 || place.employee_e_verified == 4 ? "E-verified" : "E-verification is pending"}</Text>} placement="right" arrow >
                                                                                                {(place.employee_e_verified == 1 || place.employee_e_verified == 4 || place.employee_e_verified == 2) && <img src={place.employee_e_verified == 1 || place.employee_e_verified == 4 ? Component87 : place.employee_e_verified == 2 ? Pending : ""} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />}
                                                                                            </HtmlTooltip>
                                                                                        </Text>

                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.placement_reference_id ? place.placement_reference_id : "--"} &#128900; {place.timesheet_reference_id ? place.timesheet_reference_id : "--"}</Text>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                            {filterData.status === "Draft" && <Grid item lg={2.1} md={2.1} textAlign='start'> <Text smallBoldBlack noWrap >{place.timesheet_cycle}</Text></Grid>}
                                                                            <Grid item lg={2.1} md={2.5} textAlign='start' ml={"-8px"}> <Text smallBoldBlack noWrap >{place.start_date} - {place.end_date}</Text></Grid>
                                                                            {filterData.status !== "Draft" && <Grid container item lg={1} md={1} textAlign='start'> <Text smallBoldBlack noWrap >{formatValue(place.billable_hours)}</Text>
                                                                                {place.ot_hours && place.ot_hours !== "0.00" ? <p style={{ color: "#15803D", fontStyle: "Quicksand", fontSize: "12px", fontWeight: 600, paddingTop: "-10px" }}>&nbsp;+{formatValue(place.ot_hours)}</p> : ""}
                                                                            </Grid>}
                                                                            <Grid item lg={2} md={2} textAlign='start'> <Text smallBoldBlack noWrap >{place.placement_reference_id} ({place.client_name.length > 16 ?
                                                                                <HtmlTooltip title={place.client_name} placement="right" arrow>
                                                                                    {place.client_name.slice(0, 16) + (place.client_name.length > 16 ? "..." : "")}
                                                                                </HtmlTooltip>
                                                                                :
                                                                                place.client_name})</Text></Grid>
                                                                            {filterData.status !== "Draft" &&
                                                                                <Grid item lg={1} md={1} textAlign='center'>
                                                                                    {
                                                                                        place.documents.length > 0 ?
                                                                                            <BlackToolTip
                                                                                                title={
                                                                                                    <Box p={.5}>
                                                                                                        {
                                                                                                            place.documents.map((docs, index) => (
                                                                                                                <Text smallWhite sx={{ cursor: "pointer !important", padding: '2px' }}> {docs.document_name ? docs.document_name : ""}</Text>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Box>
                                                                                                }
                                                                                                placement={"bottom-start"}
                                                                                                arrow
                                                                                            >
                                                                                                <img src={documentSVG} alt="documentSVG" style={{ height: "21px", width: "21px" }} />
                                                                                            </BlackToolTip>
                                                                                            : "--"}
                                                                                </Grid>}
                                                                            <Grid item lg={1} md={1} textAlign='center'>
                                                                                {
                                                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                                                                        <Text defaultmediumText className={classes.linkText} noWrap id='viewts' p={1}>View</Text>
                                                                                        :
                                                                                        <Text sx={{ color: "#737373 !important" }} defaultmediumText className={classes.linkText} id='viewts' p={1} noWrap>View</Text>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box >
                                                                ))
                                                            }
                                                        </Box>
                                                        :
                                                        timesheets.length == 0 ?
                                                            <Box>
                                                                {NoDataFound(`${filterData.status === "Draft" ? "No timesheets has been Drafted" : filterData.status === "Pending Approval" ? "No timesheet approvals are pending" : filterData.status === "Approved" ? "No timesheets has been created/Approved" : "No timesheets has been Rejected"}`, 'No Timesheets Here Yet')}
                                                            </Box>
                                                            : ''
                                                }
                                            </>
                                    }

                                </Box>
                                :
                                <>
                                    {NoPermission()}
                                </>
                        }
                    </Box>



                </Box >}
            {timesheetStatus == 'edittimesheet' && <Box sx={{ width: "100%" }}>

                <Box p={1} sx={{ marginLeft: "76px" }}>
                    <Box mx={3} display='flex' justifyContent='space-between'>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography className={timesheetClasses.breadcrumbsLink}>Timesheet</Typography>
                            <Typography className={timesheetClasses.breadcrumbsName}>
                                View Timesheet
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    {
                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                            <Box m={3}>

                                <Box my={2} p={2} className={timesheetClasses.employeViewContainer}>
                                    <Box className={timesheetClasses.employeViewBox}>
                                        <Grid container spacing={0} alignItems={"center"}>
                                            <Grid item lg={4} md={4} sm={12} xs={12} >
                                                <Box px={1} className={timesheetClasses.flexAlineCenter} >
                                                    <Box>
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            // src={AvatarSvg}
                                                            src={values.profile_picture_url}
                                                            sx={{ width: '80px', height: '80px', }}
                                                        />
                                                    </Box>
                                                    <Box mx={2}>
                                                        <Text mediumBoldBlack noWrap>
                                                            {values.employee_name === "" ? "--" :
                                                                capitalizeAndAddSpace(values.employee_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(values.employee_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(values.employee_name).slice(0, 16) + (capitalizeAndAddSpace(values.employee_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(values.employee_name)
                                                            }
                                                            <HtmlTooltip title={<Text smallWhite>{values.employee_e_verified == 1 || values.employee_e_verified == 4 ? "E-verified" : "E-verification is pending"}</Text>} valuesment="right" arrow >
                                                                {(values.employee_e_verified == 1 || values.employee_e_verified == 4 || values.employee_e_verified == 2) && <img src={values.employee_e_verified == 1 || values.employee_e_verified == 4 ? Component87 : values.employee_e_verified == 2 ? Pending : ""} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />}
                                                            </HtmlTooltip>
                                                        </Text>

                                                        <Text className={timesheetClasses.labales} greyLabel noWrap style={{ margin: '4px 0' }}>  {values.placement_reference_id ? values.placement_reference_id : "--"} &#128900; {values.timesheet_reference_id ? values.timesheet_reference_id : "--"}</Text>
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={3} md={3} sm={12} xs={12} >
                                                <Text className={timesheetClasses.labales} greyLabel noWrap>Approval Status</Text>

                                                <Box className={timesheetClasses.statusBox} mt="5px">
                                                    <Text mediumBoldWhite>Drafted Timesheet</Text>
                                                </Box>



                                            </Grid>
                                            <Grid item lg={3} md={3} sm={12} xs={12} >
                                                {
                                                    values.status === "Draft" ?
                                                        <>
                                                            <Text className={timesheetClasses.labales} mediumLabel noWrap>Timesheet Cycle</Text>
                                                            <Text mediumViewAmt mt="5px">{values.timesheet_cycle ? values.timesheet_cycle : "--"}</Text>
                                                        </> :
                                                        <>
                                                            <Text className={timesheetClasses.labales} mediumLabel noWrap>Total Logged  Hours</Text>
                                                            <Text mediumViewAmt mt="5px">{values.total_hours ? values.total_hours : "--"}</Text>
                                                        </>
                                                }

                                            </Grid>
                                            <Grid item lg={2} md={2} sm={12} xs={12} >
                                                {
                                                    values.status === "Draft" ?
                                                        <>
                                                            <Text className={timesheetClasses.labales} mediumLabel noWrap>Drafted On</Text>
                                                            <Text mediumViewAmt mt="5px">{values.drafted_on ? values.drafted_on : "--"}</Text>
                                                        </> :
                                                        <>
                                                            <Text className={timesheetClasses.labales} mediumLabel noWrap>Submitted Date</Text>
                                                            <Text mediumViewAmt mt="5px">{values.submitted_on ? values.submitted_on : "--"}</Text>
                                                        </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>



                                <Box p={4} sx={{
                                    border: "1px solid #EAECF0", borderRadius: '12px', height: "123px",
                                    "@media (min-width:1200px) and (max-width:1400px)": {
                                        height: "160px !important",
                                    },
                                }}>
                                    <Grid container spacing={1} columnGap={4}>
                                        <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'client_name',
                                                    value: values.client_name,
                                                    type: 'text'
                                                }}
                                                disabled={true}
                                                // 
                                                clientInput
                                                labelText={'Client Name'}
                                            />
                                        </Grid>
                                        <Grid item lg={3.5} md={4} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'end_client_name',
                                                    value: values.end_client_name,
                                                    type: 'text'
                                                }}
                                                disabled={true}
                                                // 
                                                clientInput
                                                labelText={'End Client (Optional)'}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} sm={6} xs={12}>
                                            <Datepicker
                                                labelText={"Start Date"}
                                                name={"start_date"}
                                                value={values.start_date}

                                                error={error.start_date}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} sm={6} xs={12}>
                                            <Datepicker
                                                labelText={"End Date"}
                                                name={"end_date"}
                                                value={values.end_date}
                                                minDate={values.start_date}

                                                disabled={true}
                                            />

                                        </Grid>
                                    </Grid>
                                </Box>



                                <Box mt={2} p={4} sx={{ border: "1px solid #EAECF0", borderRadius: '12px', height: "50vh", overflow: "auto" }}>

                                    <Grid item lg={12} md={12}>

                                        <Box className={timesheetClasses.dropzoneMainBox}>
                                            <Box className={timesheetClasses.dropBox}>
                                                <Dropzone >
                                                    {() => (
                                                        <section className={timesheetClasses.dropZone}>
                                                            <div>


                                                                <Grid container spacing={1} textAlign='center'>
                                                                    <Grid item lg={12} textAlign='center'>
                                                                        <Box display='flex' pt={2.5} flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                            <Text largeBlack>Drag & Drop Timesheets</Text>
                                                                            <Text smallGrey>or</Text>

                                                                            <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>

                                                                            <Text largeBlack>To Upload</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item lg={12} mt={1}>
                                                                        <Text smallGreyFont>Document Size 25MB, Format - JPEG,PNG,Word, Excel</Text>
                                                                    </Grid>
                                                                </Grid>

                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Box>
                                        </Box>

                                    </Grid>

                                    <Grid item lg={12} md={12} pt={2}>
                                        {
                                            values.documents.length > 0 ?
                                                <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: "#ffffff" }}>
                                                    <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#0C75EB !important" }}>Uploaded Documents</Text>
                                                    <Grid item container lg={12} md={12} columnGap={1.5}>
                                                        {
                                                            values.documents.map((item, index) => (
                                                                <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>

                                                                    <Text mediumBlue sx={{ cursor: "pointer !important", fontWeight: `${600} !important` }}>
                                                                        {item.document_name.length > 16 ?
                                                                            <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                                {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                            </BlackToolTip>
                                                                            :
                                                                            <>
                                                                                {item.document_name}
                                                                            </>}
                                                                    </Text>

                                                                </Box>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Box> :
                                                <Box sx={{ border: error.documents ? "1px solid #ec4444" : "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "15px 10px", background: "#ffffff" }}>
                                                    <Text smallLabel sx={{ font: "14px Quicksand !important", color: error.documents ? "#ec4444 !important" : "#737373 !important" }}>Uploaded Documents</Text>
                                                    <Grid item container lg={12} columnGap={1.5}>
                                                    </Grid>
                                                </Box>
                                        }

                                    </Grid>
                                    {/* {values.status == "Draft" &&
                                        <Grid container paddingTop={'30px'}>
                                            <Grid container columnGap={0.6} item lg={6} md={6} sm={12} xs={12} mt={2} justifyContent={"start"} alignItems={"center"}>

                                                <>
                                                    <CircleIcon sx={{ color: "#FAC94B", fontSize: "14px", marginTop: "2px" }} />
                                                    <Text BlackExtraDark>AI Fetched Hours</Text>

                                                </>
                                            </Grid>
                                            <Grid container item lg={6} md={6} sm={12} xs={12} mt={2} justifyContent={"end"} alignItems={"center"} >
                                                <Box id='aicredits' p={0.5} >
                                                    <Button CreditsButton  >
                                                        <Box display='flex' flexDirection='row' alignItems='center'  >
                                                            <Box textAlign='start'>
                                                                <Text largeWhite20>{50}&nbsp;<span style={{ font: '12px Quicksand', fontWeight: '400' }}>Left</span></Text>
                                                                <Text mediumWhite400>AI Timesheets</Text>
                                                            </Box>
                                                            <Box sx={{ width: '50px' }}></Box>
                                                            <img src={rightArrow} alt="arrow" style={{ padding: '2px 0px 0px 2px' }} />
                                                        </Box>
                                                    </Button>
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    } */}

                                    <Box id='tsbox' paddingBottom={1} paddingLeft={2} paddingRight={2}>
                                        {values.weekly.map((value, index) => (
                                            <TimeSheetTable
                                                data={value}
                                                index={index}
                                                status={'Draft'}
                                                ocrTimesheetData={[]}
                                                capture={false}
                                                isEdit={!true}
                                                formStatus={"view"}
                                            />
                                        ))}
                                    </Box>



                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'reason_rejection',
                                                value: values.reason_rejection ? values.reason_rejection : "",
                                                inputProps: { maxLength: 150 }
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={'Reason for Rejection'}
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'comments',
                                                value: values.comments,
                                                inputProps: { maxLength: 150 }
                                            }}
                                            disabled={true}

                                            clientInput
                                            labelText={'Comments (Optional)'}
                                        />
                                    </Grid>

                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"end"} pt={3}>
                                            <Button saveNcontinue className={timesheetClasses.btnStyles} loading={loading} >Done</Button>
                                        </Grid>
                                    </Grid>

                                </Box>

                            </Box> :
                            <Grid container>
                                {NoPermission()}
                            </Grid>
                    }
                </Box>

            </Box >}
        </>
    )
}

