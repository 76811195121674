import { Box, Breadcrumbs, Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text'
import AppIntegrationsStyles from './AppIntegrationsStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/customButton/Button';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import { NoPermission, addErrorMsg, addLoader, removeLoader } from '../../../utils/utils';
import LocalStorage from '../../../utils/LocalStorage';
import warning from '../../../assets/svg/blue-info-zoho.svg';
import Search from '../../../components/select/Select';

function SelectedApp() {

    const classes = AppIntegrationsStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const appName = location && location.state && location.state.appName
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [region, setRegion] = useState('.com');
    const [error, setError] = useState('');
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    useEffect(() => {
        if (LocalStorage.getAppIntegrationId()) {
            getAppData(LocalStorage.getAppIntegrationId());
        }  // eslint-disable-next-line
    }, [])



    const getAppData = (param) => {
        setLoading(true);
        InstalledAppsApi.getSingleApp(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setData(res.data.data[0]);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }
    const [btnLoader,setBtnLoader] = useState(false);
    const handleConfigure = () => {
        if (appName == 'Intuit Quick Books') {
            addLoader(true);
            setBtnLoader(true);
            InstalledAppsApi.getQuickbooksLink().then((res) => {
                removeLoader();
                setBtnLoader(false);
                if (res.data.statusCode === 1003) {
                    window.open(res.data.data);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            if (region == '') {
                setError('This field is required');
            } else {
                if (data.redirect_to == "organization") {
                    navigate("/app-integrations/selected-app/configure", { state: { id: data.id, from: "select" } });
                } else {
                    addLoader(true);
                    setBtnLoader(true);
                    InstalledAppsApi.getConfigureLink(region).then((res) => {
                        setBtnLoader(false);
                        removeLoader();
                        if (res.data.statusCode === 1003) {
                            window.open(res.data.data);
                            // window.open(`${newDomain}/app-integrations/selected-app/configure`);
                        } else {
                            addErrorMsg(res.data.message)
                        }
                    })
                }
            }
        }
    }

    const handleChange = (e) => {
        setRegion(e.target.value);
        setError('');
    }

    return (
        <Box className={classes.mainBox1} sx={{px:{xs:"7px",sm:"40px"},pl:{sm:"40px",md:"80px",lg:"120px"}, pr:{sm:"40px",md:"80px",lg:"100px"},pb:{xs:"78px", sm:'0px'}}}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                    <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                    <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>All Apps</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>{appName == 'Intuit Quick Books' ? 'Quick books' : 'Zoho books'}</Text>
                </Breadcrumbs>
            </Box>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true))) ?
                    <Grid container alignItems='center'>
                        <Grid item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={40} width={150} />
                                    :
                                    <img src={data.logo_url} alt="logo" height={"75px"} width={appName == 'Intuit Quick Books' ? '238px' : "198px"} />
                            }
                        </Grid>
                        <Grid item lg={6} xl={6} md={6} sm={6} xs={12} textAlign='end'>
                            <a rel="noopener noreferrer" style={{ color: "#0C75EB" }} href={data && data.help_documentation_url && data.help_documentation_url} target="_blank" className={classes.helpText}>Help Documentation</a>
                        </Grid>
                        <Grid item lg={12} my={2}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={40} width={150} />
                                    :
                                    <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: {xs:"80px", md:"60px"}, width: '100%', borderRadius: '8px', background: '#F0F5FF', textAlign: 'center', alignItems: 'center', paddingLeft: '20px', paddingRight:{xs:"10px", sm:"0px"} }}>
                                        <Box pt={'4px'}>
                                            <img src={warning} alt='warning' />
                                        </Box>
                                        <Box  >
                                            <Text mediumOverView sx={{ xs:{paddingRight:"10px !important"}}}>
                                                {
                                                    appName == 'Intuit Quick Books' ?
                                                        'App data integrations are subjected to your Quick books Country, Plan and the corresponding API limits. Please refer Help documentation for more info' :
                                                        'App data integrations are subjected to your Zoho Books plan and the corresponding API limits. Please refer Help documentation for more info'
                                                }
                                            </Text>
                                        </Box>
                                    </Box>
                            }
                        </Grid>
                        {
                            appName == 'Intuit Quick Books' ? '' :
                                <Grid item lg={3} md={4} sm={5} xs={12} my={2}>
                                    {
                                        loading ?
                                            <Skeleton variant="rounded" height={40} width={150} /> :
                                            <Search
                                                name='region'
                                                value={region}
                                                onChange={(e) => { handleChange(e) }}
                                                options={[{ id: '.com', value: 'United States of America' }, { id: '.in', value: 'India' }]}
                                                label='Select Country'
                                                error={error == '' ? '' : true}
                                                helperText={<span className={classes.helperTextError}>{error ? error : ''}</span>}
                                            />
                                    }
                                </Grid>
                        }
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={25} />
                                    :
                                    <Text mediumOverView sx={{ font: "15px Quicksand !important" }}>{data && data.configuration_subject ? data.configuration_subject : ""}</Text>
                            }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                            {
                                loading ?
                                    [1, 2, 3, 4].map((item) => (
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                            <Skeleton variant="rounded" height={25} width={500} />
                                        </Grid>
                                    )) :
                                    <>
                                        {
                                            data && data.configuration_steps && data.configuration_steps.map((item, index) => (
                                                <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                    <Text mediumOverView sx={{ font: "15px Quicksand !important" }}>{index + 1}. {item}</Text>
                                                </Grid>
                                            ))
                                        }
                                    </>
                            }
                        </Grid>
                        {
                            loading ?
                                <Skeleton variant="rounded" height={25} />
                                :
                                <Grid container item lg={12} md={12} sm={12} xs={12} pt={4} alignItems="center">
                                    <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
                                        <Button disabled={btnLoader} outlineBlue 
                                            sx={{ font: '18px Quicksand !important', height: '54px !important', fontWeight: `${600} !important`,
                                                "@media (max-width: 768px) ":
                                                {
                                                    width: "fit-content !important",
                                                    fontSize: "11px !important",
                                                    height: "34px !important"
                                                }
                                                }} onClick={() => navigate('/app-integrations')}>Back</Button>
                                    </Grid>
                                    <Grid item lg={6} xl={6} md={6} sm={6} xs={6} textAlign={"end"}>
                                        <Button  saveBtn className={classes.configBtn} sx={{sm:{maxWidth:"fit-content", height:"36px !important"}}} onClick={() => { handleConfigure() }}>Continue</Button>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </Box >
    )
}

export default SelectedApp