import { makeStyles } from "@mui/styles";
import { blue, btnBgGreen, btnTxtBlack, lightGreyText, warning } from "../../../../theme";

const VendorProfileStyles = makeStyles(() => ({

    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
    },
    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "22px !important",
        textAlign: "center"
    },
    profileId: {
        fontFamily: "Quicksand !important",
        color: "#737373 !important",
        fontSize: "14px !important",
        textAlign: "center"
    },
    eVerifyBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#4ABE43 !important",
        border: "1px solid #4ABE43 !important",
        height: "35px",
        width: "140px",
        padding: "0px 18px !important",
        borderRadius: "8px !important"
    },
    moreBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#9D9E9F !important",
        border: "1px solid #9D9E9F !important",
        height: "35px !important",
        minWidth: "35px !important",
        padding: "5px !important",
        borderRadius: "8px !important"
    },

    listItems: {
        fontFamily: "Quicksand !important",
        minHeight: "32px",
        margin: "6px 0px !important",
        color: '#092333',
        borderRadius: "4px !important",
        fontSize: "14px !important",
        transition: "all .3s ease",
        '&:hover': {
            backgroundColor: '#F5F6F6 !important',
        },
    },

    listItemsActive: {
        color: '#FFFFFF !important',
        backgroundColor: '#0C75EB !important',
        transition: "all .3s ease",
        '&:hover': {
            backgroundColor: '#0C75EB !important',
            color: '#FFFFFF !important',
        },
    },

    avatarBox: {
        display: "flex",
        borderRadius: "50%",
        border: "2px solid #037847",
    },
    avatar: {
        width: "100px !important",
        height: "100px !important",
        margin: "2px"
    },

    tabTitle: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        textTransform: "capitalize !important",
        fontSize: "15px !important",
        fontWeight: 500,
        margin: "0px 12px !important",
        padding: "20px !important"
    },

    activeTabTitle: {
        color: "#0C75EB !important",
    },

    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },

    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
    },

    sideTooltip: {
        height: "34px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },

    sideTooltipText: {
        fontSize: "14px",
        fontFamily: "Quicksand",
        fontWeight: "400",
        color: "#FFFFFF",
    },
    viewText: {
        borderBottom: '1px solid #EAECF0 !important',
        font: '13px Quicksand !important',
        height: '37px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    inactive: {
        font: '13px Quicksand !important',
        height: '37px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    activeText: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        opacity: 1,
        fontWeight: `${500} !important`,
        textTransform: 'capitalize !important',
    },
    tabText: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `#707070 !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
    },

    statuText: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
    },
    red: {
        color: `red !important`,
    },
    green: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGreen.shade1} !important`,
        opacity: 1
    },
    orange: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${warning} !important`,
        opacity: 1
    },
    greyText: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${lightGreyText} !important`,
        opacity: 1
    },
    contentScroll: {
        height: '58vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: '10px',
        width: '100%'
    },
    optional: {
        font: '14px Quicksand !important',
        color: 'rgb(194 188 188) !important',
        marginLeft: '4px !important'
    }, 
    BarChartTitle: {
        color: `${btnTxtBlack.shade4} !important`,
        font: "15px Quicksand !important",
        fontWeight: `600 !important`,
    },
    helperTextError: {
        color: '#e72929 !important',
        font: '11px Quicksand !important',
        marginLeft: '-5px !important'
    },
    boxShadow: {
        boxShadow: ' 0px 2px 24px 0px #919EAB1F !important',
        borderRadius: "8px !important",
    },
    cursor: {
        cursor: 'pointer !important'
    },
    divider: {
        border: '1px solid #E2E5E6 !important', width: '100% !important', margin: '15px 0px !important'
    },
    editSkeleton:{
        height: '40px !important', width: '30px !important', background: '#F6F6F6 !important'
    },
    popOverForm:{
        width: 'fit-content !important', height: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' 
    }

}))

export default VendorProfileStyles;
