import { Box, Divider, Grid,Avatar } from '@mui/material'
import React, { useState } from 'react'
import GeneratePayrollStyles from '../GeneratePayrollStyles';
import Text from '../../../../../components/customText/Text';

import { BlackToolTip, capitalizeAndAddSpace, NoDataFound, openDocumentInNewtab } from '../../../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../../../components/customButton/Button';


function Timesheet({ summary, currentSalaryRow, timesheetListProp, closePopUp, setUpdateStandardPayPopUp,btnLoader }) {

  const classes = GeneratePayrollStyles();
  // eslint-disable-next-line
  const [clickedTimesheetId, setClickedTimesheetID] = useState({});

  const handleEachTimesheetId = (item) => {
    setClickedTimesheetID(item);
    openInNewTab(item, item.document_url);
  }

  const openInNewTab = (args, documentUrl) => {
    if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    else {
      openDocumentInNewtab('timesheet_documents', args.document_path)
    }
  }



  return ( 
    <div>
      <Box px={3} >
        <Divider />

        <Box sx={{ height: "63vh", overflowY: "auto" }}>
          {timesheetListProp?.length > 0 ? timesheetListProp?.map((data, index) => (
            <Box key={index} className={classes.tmPrimaryBox} my={2}>
              <Box px={1} py={2} sx={{ background: "#D1E1FF", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                <Grid container >
                  <Grid item lg={5} md={5} sm={5} xs={12} textAlign='start'>
                    <Box display='flex' alignItems='center' gap={2}>
                      

                      <Avatar
                        alt={data.client_name ? capitalizeAndAddSpace(data.client_name[0]) : ''}
                        src={data.client_logo == null || data.client_logo == '' ? data.client_name[0] : data.client_logo}
                        sx={{ width: 50, height: 50, background: data.client_logo ? '' : '#e5b256 !important' }}
                      />
                      
                      <Box>
                        <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                          {
                            data?.client_name ? data?.client_name.length > 16 ?
                              <BlackToolTip title={capitalizeAndAddSpace(data?.client_name)} placement="top" arrow>
                                {capitalizeAndAddSpace(data?.client_name).slice(0, 16) + capitalizeAndAddSpace(data?.client_name.length > 16 ? "..." : "")}
                              </BlackToolTip>
                              : capitalizeAndAddSpace(data?.client_name) : '--'
                          }
                        </Text>
                        <Text largeGrey14>{data?.client_id}</Text>
                      </Box>
                      
                    </Box>
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                    <Box display='flex' alignItems='center' justifyContent="end" height={"100%"} gap={2}>
                      <Text largeBlue sx={{ cursor: "pointer" }}>View Attachment</Text>
                      <VerticalAlignBottomIcon sx={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>*/}
                </Grid>
              </Box>
              <Divider />
              <Box px={1} py={2} sx={{ background: "#F4F8FF", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                <Grid container spacing={3} p={1} >
                  <Grid container item md={12} sm={12} xs={12} display={'flex'}  >
                    <Grid item md={2} sm={2} xs={2}><Text grey16>Timesheet Id</Text></Grid>
                    <Grid item container md={10} sm={10} xs={10} textAlign={"end"} flexDirection={'row'} justifyContent={'end'}  >
                      {data?.timesheet_documents.map((eachItem, index) => <>
                        <Text key={index} blue14 sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {eachItem?.timesheet_id}

                        </Text>&nbsp;

                        {eachItem?.documents?.length > 0 ?

                          <>

                            {' ('}&nbsp;
                            {eachItem?.documents
                              .map((doc, docIndex) =>
                                <>
                                  <Text blue14 sx={{ textDecoration: 'underline', cursor: 'pointer !important', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                    onClick={() => handleEachTimesheetId(doc)}>{doc?.document_name} </Text>
                                  {docIndex < eachItem?.documents.length - 1 && ', '}</>)}
                            &nbsp;{' )'}

                            {index < data?.timesheet_documents.length - 1 && ', '} </> : ''}

                      </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={6} sm={6} xs={6}><Text grey16>Total Hours</Text></Grid>
                  <Grid item md={6} sm={6} xs={6} textAlign={"end"}><Text black18px>{data?.total_hours}</Text></Grid>
                  <Grid item md={6} sm={6} xs={6}><Text grey16>Period</Text></Grid>
                  <Grid item md={6} sm={6} xs={6} textAlign={"end"}><Text black18px>{data?.start_date} - {data?.end_date}</Text></Grid>
                </Grid>
              </Box>

            </Box>
          )) : <Box>{NoDataFound('No timesheets found for this payroll period')}</Box>}
        </Box>
      </Box>
      <Box sx={{ background: "#EAECF0" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"} p={1} px={3} gap={2} justifyContent={"end"}>
          <Button disabled={btnLoader} cancelBtn sx={{ width: "50px !important", height: '40px !important' }} onClick={closePopUp}>Cancel</Button>
          {!summary ? <Button save14 sx={{ width: "50px !important" }} onClick={setUpdateStandardPayPopUp}>Finalize</Button> : <Button save14 sx={{ width: "50px !important" }} onClick={closePopUp}>Done</Button>}
        </Grid>
      </Box>

    </div>
  )
}

export default Timesheet
