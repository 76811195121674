import { Avatar, Box, Checkbox, Divider, Grid } from "@mui/material"
import Text from "../../../components/customText/Text";
import Button from '../../../components/customButton/Button';
import PersonIcon from '../../../assets/svg/personicon.svg';
import MailIcon from '../../../assets/svg/mailicon.svg';
import ViewInvoiceStyles from "./ViewInvoiceStyles";
import Input from "../../../components/input/Input";
import Date from "../../../components/datePicker/Date";
import Select from "../../../components/select/Select";
import Dropzone from "react-dropzone";
import download from '../../../assets/svg/clients/download-Arrow.svg';
import { useEffect, useState } from "react";
import { addErrorMsg, addSuccessMsg, addWarningMsg, BlackToolTip, capitalizeAndAddSpace, dateFormat } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { isValid, onCharactersAndNumbersWithSpaceChange, onNumberWithTwoDecimalOnlyChange, validate_emptyField } from "../../../components/Validation";
import moment from 'moment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CommonApi from "../../../apis/CommonApi";
import InvoiceCreateAPI from "../../../apis/admin/invoice/InvoiceHistoryCilentAPI";
import { ErrorMessages } from "../../../utils/ErrorMessages";


const RecordPaymentDrawer = ({ recordPaymentDrawer, setRecordPaymentDrawer, apix, callRefetch }) => {
    const classes = ViewInvoiceStyles();
    const currency = LocalStorage.getUserData().currency_symbol ? LocalStorage.getUserData().currency_symbol : "$";

    const [error, setError] = useState({});
    // eslint-disable-next-line
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [paymentModeOptions, setPaymentModeOptions] = useState([]);
    const [depositToOptions, setDepositToOptions] = useState([]);

    const [state, setState] = useState({
        amount_recieved: '',
        payment_date: '',
        payment_mode: '',
        deposit_to: '',
        reference_id: '',
        note: '',
        email_check: false,
        attachments: []
    })

    useEffect(() => {
        if (!recordPaymentDrawer) {
            setState({
                amount_recieved: '',
                payment_date: '',
                payment_mode: '',
                deposit_to: '',
                reference_id: '',
                note: '',
                email_check: false,
                attachments: []


            })
            setError({});
        } else {
            InvoiceCreateAPI.dropdownApi().then((res) => {
                if (res.data.statusCode === 1003) {
                    setPaymentModeOptions(res.data.data.payment_modes);
                    setDepositToOptions(res.data.data.deposit_types);
                }
            })
        }
    }, [recordPaymentDrawer])

    const deleteChip = (index) => {
        state.attachments.splice(index, 1)
        setState({ ...state });
    }

    const handleDrop = (e) => {
        if (e.length < 6) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'png', 'jpg', 'jpeg'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("invoice_documents", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {

                                if (state.attachments?.length < 5) {

                                    state.attachments.push({
                                        id: '',
                                        new_document_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 5 files at a time. Please remove excess files and try again')
                                }
                                setState({ ...state })
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or Images only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 5 files at a time. Please remove excess files and try again')
        }
    }

    const dateChange = (e, name) => {

        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    // const checkPaymentDateError = (payment_date) => {
    //     if (payment_date && payment_date !== '' && payment_date != 'Invalid date') {

    //         error.payment_date = validate_emptyField(payment_date)

    //     } else {
    //         error.payment_date = 'Please select a valid date'
    //     }
    //     setError({ ...error })
    // }

    const checkAmountRecieved = (amount_recieved) => {
        if (Number(amount_recieved) > Number(apix.balance_amount)) {//check the key from the backend and throw error for '50000'
            error.amount_recieved = `The total amount due is ${apix.balance_amount}. Please ensure that the payment amount does not exceed the outstanding balance.`
        }
        setError({ ...error })
    }

    const handleChange = (e) => {
        if (e.target.name == 'email_check') {
            state[e.target.name] = e.target.checked == true ? 1 : 0
            setState({ ...state })
        } else {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value,
                },
                handleValidate(e)
            );
        }

    }

    const handleInputChange = (e, name, type) => {


        var value = e.target.value.trim();
        value = value.replace("$", "");
        if (value.length > 10) {
            const num = parseFloat(value);
            const isFloat = value.includes('.') || num % 1 !== 0;
            if (isFloat) {
                state[e.target.name] = value;
            } else {
                if (value.length === 11) {
                    if (value.slice(-1) === ".") {
                        state[e.target.name] = value;
                    } else {
                        return false;
                    }
                }
            }
        }
        else {
            state[e.target.name] = value;
        }
        setState({ ...state }, handleValidate(e));

    };

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "amount_recieved":
                error.amount_recieved = input.value == '-' ? 'Please enter valid number' : validate_emptyField(input.value);
                break;
            case "payment_date":
                error.payment_date = validate_emptyField(input.value);
                break;
            case "payment_mode":
                error.payment_mode = validate_emptyField(input.value);
                break;
            case "deposit_to":
                error.deposit_to = validate_emptyField(input.value);
                break;
            case "reference_id":
                error.reference_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    };


    const validateAll = () => {
        const { reference_id, deposit_to, payment_mode, payment_date, amount_recieved } = state;
        const today = moment(moment().format(dateFormat()), dateFormat());


        error.reference_id = validate_emptyField(reference_id);
        error.deposit_to = validate_emptyField(deposit_to);
        error.payment_mode = validate_emptyField(payment_mode);
        error.payment_date = payment_date !== ''
            ? moment(payment_date, dateFormat()).isAfter(today)
                ? 'Payment date must be past or current date.' : payment_date == 'Invalid date'
                    ? ErrorMessages.validDate : validate_emptyField(payment_date) : validate_emptyField(payment_date)



        // error.payment_date = validate_emptyField(payment_date);

        error.amount_recieved = amount_recieved == '-' ? 'Please enter valid number' : validate_emptyField(amount_recieved);
        // checkPaymentDateError(payment_date);
        checkAmountRecieved(amount_recieved);
        setError({ ...error })
        return error;
    }
    const handleSaveEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            const paymentMode = paymentModeOptions.find((eachItem) => eachItem.id == state.payment_mode);
            const depositTo = depositToOptions.find((eachItem) => eachItem.id == state.deposit_to);

            const data = {
                request_id: LocalStorage.uid(),
                invoice_id: apix.id, //integrate with other
                amount: state.amount_recieved,
                payment_date: state.payment_date,
                payment_mode: paymentMode ? paymentMode.value : '',
                deposit_to: depositTo ? depositTo.value : '',
                reference_id: state.reference_id,
                note: state.note,
                documents: state.attachments,
                send_email: state.email_check == 1 ? true : false
            }
            InvoiceCreateAPI.recordPayment(data).then((response) => {
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                    setRecordPaymentDrawer();
                    callRefetch();
                }
                else {
                    addErrorMsg(response.data.message)
                }
            })
            // call api
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');

        }
    }


    return (
        <div>
            <Box width={{ lg: '608px', md: "608px", sm: "508px", xs: "310px" }} height={'100vh'} p={4} sx={{ position: 'relative' }}>
                <Grid container gap={2} sx={{ overflowY: 'hidden' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text mediumBlack14>Payment for {apix?.reference_id}</Text>
                    </Grid>
                    <Grid item container className={classes.recordDetails} gap={0.5} lg={12} md={12} sm={12} xs={12}>
                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12}>
                            <Grid container lg={6} md={6} sm={6} xs={12} >
                                <Box display='flex' alignItems='center' gap={2} >
                                    <Avatar
                                        alt={'name'}
                                        src={apix?.to_details?.[0]?.logo_url}
                                        sx={{ width: 56, height: 56 }}
                                    />
                                    <Box>
                                        <Text mediumBlack14 mb={1}>
                                            {apix?.to_details?.[0]?.name && apix?.to_details?.[0]?.name.length > 16 ?
                                                <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.name)} placement="top" arrow>
                                                    {capitalizeAndAddSpace(apix?.to_details?.[0]?.name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.name.length > 16 ? "..." : ""))}
                                                </BlackToolTip>
                                                : apix?.to_details?.[0]?.name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.name) : '--'
                                            }
                                        </Text>
                                        <Text mediumLabel >{apix?.to_details?.[0]?.client_id ? apix?.to_details?.[0]?.client_id : '--'}</Text>

                                    </Box>
                                </Box>
                            </Grid>

                            <Grid container lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <Box display='flex' flexDirection={'column'} alignItems='start' >
                                    <Text mediumBlack14 mb={1} marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }}>Invoice Amount</Text>

                                    <Text mediumLabel marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }} > {`${currency} ${apix?.total_amount ? apix?.total_amount : '--'}`}</Text>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>

                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12} >
                            <Grid item container lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
                                <img src={PersonIcon} alt='person' style={{ height: '16px', width: '16px', marginRight: '8px' }} />

                                <Text mediumLabel >{apix?.to_details?.[0]?.poc_name && apix?.to_details?.[0]?.poc_name.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name) : '--'
                                }</Text>

                            </Grid>
                            <Grid item container alignItems={"center"} lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <img src={MailIcon} alt='person' style={{ height: '16px', width: '16px' }} />


                                <Text mediumLabel sx={{ marginLeft: '12px' }}>{apix?.to_details?.[0]?.poc_email && apix?.to_details?.[0]?.poc_email.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_email ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email) : '--'
                                }</Text>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} >
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>

                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'amount_recieved',
                                        value: state.amount_recieved,
                                        inputProps: { maxLength: 14 },
                                    }}
                                    onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                    handleChange={(e) => handleInputChange(e, 'amount_recieved', 'min')}
                                    clientInput
                                    labelText={'Amount Received'}
                                    error={error.amount_recieved}
                                />
                                {
                                    error.amount_recieved ?
                                        <Text red>{error.amount_recieved ? error.amount_recieved : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Date
                                    labelText={'Payment Date'}
                                    name='payment_date'
                                    height='54px'
                                    value={state.payment_date}
                                    onChange={(value => dateChange(value, 'payment_date'))}
                                    maxDate={moment().format(dateFormat())}
                                    error={error.payment_date ? error.payment_date : ''}
                                />
                                {
                                    error.payment_date ?
                                        <Text red>{error.payment_date ? error.payment_date : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Select
                                    name='payment_mode'
                                    value={state.payment_mode}
                                    onChange={handleChange}
                                    options={paymentModeOptions}
                                    label={'Payment Mode'}
                                    error={error.payment_mode ? error.payment_mode : ''}
                                />
                                {
                                    error.payment_mode ?
                                        <Text red>{error.payment_mode ? error.payment_mode : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Select
                                    name='deposit_to'
                                    value={state.deposit_to}
                                    onChange={handleChange}
                                    options={depositToOptions}
                                    label={'Deposit To'}
                                    error={error.deposit_to ? error.deposit_to : ''}
                                />
                                {
                                    error.deposit_to ?
                                        <Text red>{error.deposit_to ? error.deposit_to : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box pt={1}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'reference_id',
                                        value: state.reference_id,
                                        inputProps: { maxLength: 50 }
                                    }}
                                    clientInput
                                    handleChange={handleChange}
                                    labelText={'Reference ID'}
                                    error={error.reference_id}
                                />
                                {
                                    error.reference_id ?
                                        <Text red>{error.reference_id ? error.reference_id : ''}</Text> : ''
                                }
                            </Box>

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>

                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'note',
                                    value: state.note,
                                    inputProps: { maxLength: 255 }
                                }}
                                onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                labelText={'Note (Optional)'}
                                handleChange={handleChange}
                                rows={4}
                                multiline={true}
                                // descriptionFormControl1
                                // descriptionInput
                                commentsField
                                error={error.note ? <Text red>{error.note ? error.note : ''}</Text> : ''}
                            />
                            {error.note ? <Text red>{error.note ? error.note : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>
                        <Grid item container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <Text mediumBlack14>Attachments</Text>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className={classes.dropZone}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Grid container spacing={1} textAlign='center' >
                                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                            <Text largeBlack>Drag & Drop Documents</Text>
                                                            <Text smallGrey>or</Text>
                                                            <Text smallBlue1 className={classes.clickHere} sx={{ cursor: 'pointer' }}>Click Here</Text>
                                                            <Text largeBlack>To Upload</Text>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'8px'}>
                                                        <img src={download} alt='download' />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                        <Text smallGreyFont>You can upload a maximum of 5 files, 5MB each</Text>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {
                                    state?.attachments?.length > 0 ?
                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                            <Text smallLabel sx={{ font: "12px Quicksand !important" }}>{`Uploaded Documents (Optional)`}</Text>
                                            <Grid item container lg={12} md={12} columnGap={1.5}>
                                                {
                                                    state?.attachments.map((item, index) => (
                                                        <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                            <Text onClick={() => window.open(item.document_url)} sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important`, cursor: "pointer !important" }}>
                                                                {item.document_name.length > 16 ?
                                                                    <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                    </BlackToolTip>
                                                                    :
                                                                    <>
                                                                        {item.document_name}
                                                                    </>}
                                                            </Text>
                                                            <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { deleteChip(index) }} />
                                                        </Box>
                                                    ))
                                                }
                                            </Grid>
                                        </Box> :
                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                            <Text smallLabel sx={{ font: "14px Quicksand !important" }}>{`Uploaded Documents (Optional)`}</Text>
                                            <Grid item container lg={12} columnGap={1.5}>
                                            </Grid>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>
                        <Grid item container alignItems={'center'} lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Checkbox name='email_check' value={state.email_check} checked={state.email_check === 1 ? true : false}
                                onChange={handleChange}
                                sx={{ padding: '0px !important', marginRight: '8px' }} icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />} />
                            <Text mediumLabel>Email a "thank you" note for this payment.</Text>
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} mt={1} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }} spacing={2} mb={2}>
                            <Grid item >
                                <Button blackCancelBtn onClick={setRecordPaymentDrawer}>Cancel</Button>
                            </Grid>
                            <Grid item >
                                <Button blueButton onClick={handleSaveEdit}>Record Payment</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>

    )
}

export default RecordPaymentDrawer