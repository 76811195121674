export const manualOnboardDefaultConfig = {

    "generalDetails": {

        "basicDetails": {

            "first_name": {
                "label": "First Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid First name",
                "isVisible": true,
                "isDisabled": false
            },
            "middle_name": {
                "label": "Middle Name",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Middle Name",
                "isVisible": true,
                "isDisabled": false
            },
            "last_name": {
                "label": "Last Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Last Name",
                "isVisible": true,
                "isDisabled": false
            },
            "dob": {
                "label": "Date of Birth",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "ageLimit": 18,
                "ageLimitMsg": "Minimum age should be greater than 18",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "gender": {
                "placeholder": "",
                "label": "Gender",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "gender_description": {
                "label": "Gender Description",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "minLength": 3,
                "minLengthMsg": "Invalid Charaters",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z\\s-]*$/",
                "patternMsg": "Invalid Charaters",
                "isVisible": true,
                "isDisabled": false
            },
            "blood_group": {
                "placeholder": "",
                "label": "Blood Group",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "marital_status": {
                "placeholder": "",
                "label": "Marital Status",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }

        },

        "contactDetails": {
            "contact_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_contact_number": {
                "placeholder": "",
                "label": "Alternate Mobile Number",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Alternate Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "email_id": {
                "placeholder": "",
                "label": "Email ID",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_email_id": {
                "placeholder": "",
                "label": "Alternate Email ID",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            }
        },

        "currentAddressDetails": {
            "address_line_one": {
                "placeholder": "",
                "label": "Address Line 1",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_two": {
                "placeholder": "",
                "label": "Address Line 2",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "location": {
                'zip_code': {
                    "label": "Zipcode / Pincode",
                    "zipcodeLabel": "Zipcode",
                    "pincodeLabel": "Pincode",
                    "placeholder": "",
                    "maxLength": 6,
                    "zipcodeLength": 5,
                    "pincodeLenght": 6,
                    "pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "city": {
                    "label": "City",
                    "placeholder": "",
                    "maxLength": 50,
                    "pattern": "/^[a-zA-Z0-9 ]+$/",
                    "patternMsg": "Please enter a valid city",
                    "required": true,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "state": {
                    "label": "State",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "country": {
                    "label": "Country",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },

        "employmentDetails": {

            "employment_type": {
                "placeholder": "",
                "label": "Employment Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "employment_category": {
                "placeholder": "",
                "label": "Employment Category",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "vendor": {
                "placeholder": "",
                "label": "Vendor",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_joining": {
                "label": "Joining Date",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "ageLimit": 18,
                "ageLimitMsg": "Minimum age should be greater than 18",
                "errorMsg": "Please select a date after your birth date.",
                "errorMsg1": "Joining date shouldn’t be the past date.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },

            "ssn": {
                "label": "SSN",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "SSN Number already exists",
                "maxLength": null,
                "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                "patternMsg": "Invalid SSN, please check the format",
                "isVisible": true,
                "isDisabled": false
            },
            "is_us_citizen": {
                "placeholder": "",
                "label": "Is the Employee a US Citizen",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "visa_type": {
                "placeholder": "",
                "label": "Visa Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "opening_balance": {
                "label": "Opening Balance",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "standard_wage": {
                "label": "Standard Wage",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_health_insurance": {
                "label": "Do you want to enable health insurance for this employee.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "insurance_amount": {
                "label": "Insurance Amount",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "/^(?:\\d{1,10}|\\d{1,10}\\.\\d{0,2})$/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "make_standard_pay_as_salary": {
                "label": "Make Standard Wage the Default Salary",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_login": {
                "label": "Enable User Access",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_payroll": {
                "label": "Enable Payroll & Balancesheet",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
        },

        "emergencyContactDetails": {

            "name": {
                "label": "Name",
                "placeholder": "",
                "maxLength": 120,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Name",
                "isVisible": true,
                "isDisabled": false
            },
            "mobile_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "relation": {
                "placeholder": "",
                "label": "Relation",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_one": {
                "placeholder": "",
                "label": "Address Line 1",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_two": {
                "placeholder": "",
                "label": "Address Line 2 Optional",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "zip_code": {
                "label": "Zipcode / Pincode",
                "zipcodeLabel": "Zipcode",
                "pincodeLabel": "Pincode",
                "placeholder": "",
                "maxLength": 6,
                "zipcodeLength": 5,
                "pincodeLenght": 6,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "city": {
                "label": "City",
                "placeholder": "",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z0-9 ]+$/",
                "patternMsg": "Please enter a valid city",
                "isVisible": true,
                "isDisabled": false
            },
            "state": {
                "label": "State",
                "placeholder": "",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "country": {
                "label": "Country",
                "placeholder": "",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        }
    },
    "documentsDetails": {
        "workAuthorization": {
            "passport": {
                "passportUpload": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only ",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Passport Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "passportNumber": {
                    "label": "Passport Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Passport Number is required",
                    "maxLength": 14,
                    "minLength": 8,
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "minLengthMsg": "Passport Number must be between 8 and 14 characters in length",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "issued_country_id": {
                    "label": "Issued Country",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Issued Country is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "i94": {
                "new_document": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "I-94 Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "I-94 Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "I-94 Number is required",
                    "maxLength": 11,
                    "maxLengthMsg": "i94 Number must be 11 characters in length",
                    "pattern": "/^(?!(\\d)\\1+$)(?:\\d{11}|\\d{9}[A-Za-z]\\d)$/",
                    "patternMsg": "Please enter a valid I-94 Number",
                    "alreadyExistsMsg": "I-94 Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Recent Date of Entry",
                    "required": false,
                    "requiredMsg": "Recent Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "expiry_type": {
                    "label": "Admit Until Date",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Admit Until Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Admit Until Date",
                    "required": false,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "visa": {
                "visa_type_id": {
                    "label": "Visa Type",
                    "placeholder": "",
                    "required": true,
                    "pattern": "",
                    "patternMsg": "",
                    "requiredMsg": "Visa Type is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "visa_document": {
                    "format": [
                        "pdf",
                        "doc",
                        "docx"
                    ],
                    "formatMsg": "Please upload files in PDF,Docs only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 10,
                    "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                    "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                    "label": {
                        "name": "*Add visa documents like EAD, I983, I-129, I-797, GC, GC EAD, etc",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                },
                "valid_from": {
                    "label": "Date of Issue",
                    "required": false,
                    "requiredMsg": "Date of Issue is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date of Expiry",
                    "required": false,
                    "requiredMsg": "Date of Expiry is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },
        "educationDetails": {
            "education_documents": {
                "format": [
                    "pdf",
                    "doc",
                    "docx"
                ],
                "formatMsg": "Please upload files in PDF,Docs only",
                "size": 25,
                "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                "totalFiles": 10,
                "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                "label": {
                    "name": "Add your educational documents like Grade card, certificates, transcripts, etc",
                    "format": "Document Size 25MB, Format - PDF, Docs"
                }
            },
            "uploaded_docs": {
                "label": "Uploaded Documents"
            }
        },
        "personalDocuments": {
            "drivingLicense": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Upload Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "document_type_id": {
                    "label": "Document Type",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Document Type is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "document_number": {
                    "label": "Document Number",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Document Number is required",
                    "maxLength": 16,
                    "minLength": 4,
                    "minLengthMsg": "Document Number must be between 4 to 16 alphanumeric characters",
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "ssn": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "SSN Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "SSN Number",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "SSN Number is required",
                    "maxLength": 11,
                    "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                    "patternMsg": "Invalid SSN, please check the format",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "otherDocuments": {
                "other_documents": {
                    "format": [
                        "msword",
                        "doc",
                        "vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "ms-doc",
                        "pdf"
                    ],
                    "formatMsg": "Upload document Format PDF/Docs",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 5,
                    "totalFilesMsg": "Upload 5 Documents Only",
                    "label": {
                        "name": "Add any other documentation like Countersigned Offer Letter, Other Personal and Visa related documents.",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                }
            }
        }
    },
    "bankDetails": {
        "i9_document": {
            "label": "I-9 Documents",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/i-9.png",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "W4_document": {
            "label": "W-4 Form",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/w4.jpg",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "void_cheque": {
            "label": "Void Cheque",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "bank_name": {
            "label": "Bank Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 2,
            "minLengthMsg": "Bank name should be greater than 2 characters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z]+( [a-zA-Z]+)*$/",
            "patternMsg": "Please enter a valid bank name",
            "isVisible": true,
            "isDisabled": false
        },
        "account_type": {
            "label": "Account Type",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "account_number": {
            "label": "Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account Number is already exists",
            "errorMsg1": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_account_number": {
            "label": "Confirm Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "routing_number": {
            "label": "Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_routing_number": {
            "label": "Confirm Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_type": {
            "label": "Choose Deposit Configuration",
            "info": "Choose your bank deposit configuration and division",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_value": {
            "label1": "Deposit Percentage",
            "label2": "Deposit Value",
            "info1": "Enter the deposit percentage that you want to have in this bank",
            "info2": "Enter the deposit value that you want to have in this bank",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "errorMsg": "Routing number not matching",
            "pattern": "/^\\d+(\\.\\d+)?$/",
            "patternMsg": "Please enter only numbers",
            "isVisible": true,
            "isDisabled": false
        }
    },

    "skills": {
        "skill_id": {
            "placeholder": "",
            "label": "Skill Name",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "expertise": {
            "placeholder": "",
            "label": "Level Of Expertise",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "certification": {
            "placeholder": "",
            "label": "Certification Link/URL",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 225,
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "certification_date": {
            "label": "Admit Until Date",
            "required": false,
            "requiredMsg": "This field is required",
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "experience_years": {
            "placeholder": "",
            "label": "Years of Experience",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "documents": {
            "label": "Upload Certificate",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        }
    },

    "offboardingPopupDetails": {
        "lastWorkingDate": {
            "label": "Last Working Day",
            "placeholder": null,
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "description": {
            "label": "Reason For Offboarding",
            "placeholder": "Type Something",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 255,
            "rows": 3,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}


export const employeeInviteViaLinkDefaultConfig = {

    "uploadDocuments": {
        "overAllFilesLimit": 10,
        "overAllFilesLimitMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
        "counter_signed_offer_letter": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "passport": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "i94": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "signed_ssn": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },

        "work_authorization": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "all_previous_i20s": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "education_documents": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 10,
            "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "copy_of_void_cheque": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },
        "drivers_license": {
            "format": ["pdf", "jpeg", "jpg", "png"],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents",
        },

    },
    "i9W4Documents": {
        "i9": {
            'required':false,
            "label": "Form I-9",
            "format": [
                "png",
                "jpg",
                "jpeg",
                "pdf"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "w4": {
            'required':false,
            "label": "Form W-4",
            "format": [
                "png",
                "jpg",
                "jpeg",
                "pdf"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        }
    },
    "emergencyContactDetails": {

        "name": {
            "label": "Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 120,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Name",
            "isVisible": true,
            "isDisabled": false
        },
        "mobile_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        },
        "relationship_id": {
            "placeholder": "",
            "label": "Relation",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "address_1": {
            "placeholder": "",
            "label": "Address Line 1",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 225,
            "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
            "patternMsg": "Please enter a valid address",
            "isVisible": true,
            "isDisabled": false
        },
        "address_2": {
            "placeholder": "",
            "label": "Address Line 2",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 225,
            "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
            "patternMsg": "Please enter a valid address",
            "isVisible": true,
            "isDisabled": false
        },
        "zip_code": {
            "label": "Zipcode / Pincode",
            "zipcodeLabel": "Zipcode",
            "pincodeLabel": "Pincode",
            "placeholder": "",
            "maxLength": 6,
            "zipcodeLength": 5,
            "pincodeLenght": 6,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "city": {
            "label": "City",
            "placeholder": "",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z0-9 ]+$/",
            "patternMsg": "Please enter a valid city",
            "isVisible": true,
            "isDisabled": false
        },
        "state_id": {
            "label": "State",
            "placeholder": "",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "country_id": {
            "label": "Country",
            "placeholder": "",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}

export const employeeInviteViaLinkReUploadDocumentDefaultConfig = {
    "uploadDocuments": {
        "overAllFilesLimit": 10,
        "overAllFilesLimitMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
        "counter_signed_offer_letter": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "passport": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "i94": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "signed_ssn": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "work_authorization": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "all_previous_i20s": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "education_documents": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 10,
            "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "copy_of_void_cheque": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 5,
            "totalFilesExcedMsg": "You can upload a maximum of 5 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "drivers_license": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 file at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "i9_document": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        },
        "w4_document": {
            "format": [
                "pdf",
                "jpeg",
                "jpg",
                "png"
            ],
            "formatMsg": "Please upload files in PDF or Image format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 1,
            "totalFilesExcedMsg": "You can upload a maximum of 1 files at a time. Please remove excess files and try again",
            "dropAreaLabel": "Document Size 25 MB, Format - PDF, IMG (Jpeg, PNG)",
            "fieldLabel": "Uploaded Documents"
        }
    }
}


export const viaLinkOnboardingDefaultConfig = {
    "basicDetails": {
        "first_name": {
            "label": "First Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid First name",
            "isVisible": true,
            "isDisabled": false
        },
        "middle_name": {
            "label": "Middle Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Middle Name",
            "isVisible": true,
            "isDisabled": false
        },
        "last_name": {
            "label": "Last Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Last Name",
            "isVisible": true,
            "isDisabled": false
        },
        "email_id": {
            "placeholder": "",
            "label": "Email ID",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
            "patternMsg": "Please enter a valid Email Id",
            "existingEmailMsg": "Email ID already exists",
            "isVisible": true,
            "isDisabled": false
        },
        "mobile_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "maxLength": 12,
            "required": false,
            "existingMobileMsg": "Mobile Number already exists",
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        },
        "dob": {
            "label": "Date of Birth",
            "required": false,
            "requiredMsg": "This field is required",
            "ageLimit": 18,
            "ageLimitMsg": "Minimum age should be greater than 18",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "employee_category_id": {
            "placeholder": "",
            "label": "Employment Category",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender": {
            "placeholder": "",
            "label": "Gender",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender_description": {
            "label": "Gender Description",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "minLength": 3,
            "minLengthMsg": "Invalid Charaters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z\\s-]*$/",
            "patternMsg": "Invalid Charaters",
            "isVisible": true,
            "isDisabled": false
        },
        "date_of_joining": {
            "label": "Date of Joining",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "offer_letter_id": {
            "format": [
                "pdf"
            ],
            "formatMsg": "Please upload files in PDF format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "label": "Upload Offer Letter",
            "mandatoryMsg": "*Offer letter is mandatory please upload it",
            "ReInviteMandatoryMsg": "Please upload the latest offer letter",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },

    }
}


export const employeeVerifyDefaultConfig = {

    "uploadedDocumentsDetails": {
        "passport": {
            "document_number": {
                "label": "Passport Number",
                "placeholder": "",
                "required": true,
                "requiredMsg": "Passport Number is required",
                "maxLength": 14,
                "minLength": 8,
                "pattern": "/^[a-zA-Z0-9]+$/",
                "patternMsg": "",
                "minLengthMsg": "Passport Number must be between 8 and 14 characters in length",
                "alreadyExistsMsg": "Passport Number is already exists",
                "isVisible": true,
                "isDisabled": false
            },
            "issued_country": {
                "label": "Issued Country",
                "placeholder": "",
                "required": false,
                "requiredMsg": "Issued Country is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_issue": {
                "label": "Date Of Issue",
                "placeholder": null,
                "required": false,
                "requiredMsg": "Date Of Issue is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_expiry": {
                "label": "Date Of Expiry",
                "placeholder": null,
                "required": false,
                "requiredMsg": "Date Of Expiry is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "i94": {
            "document_number": {
                "label": "I-94 Number",
                "placeholder": "",
                "required": true,
                "requiredMsg": "I-94 Number is required",
                "maxLength": 11,
                "maxLengthMsg": "i94 Number must be 11 characters in length",
                "pattern": "/^(?!(\\d)\\1+$)(?:\\d{11}|\\d{9}[A-Za-z]\\d)$/",
                "patternMsg": "Please enter a valid I-94 Number",
                "alreadyExistsMsg": "I-94 Number is already exists",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_issue": {
                "label": "Recent Date of Entry",
                "required": false,
                "requiredMsg": "Recent Date is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false,
                "invalidRecentDateMsg": "Recent Date of Entry should not be a future date"
            },
            "expiry_type": {
                "label": "Admit Until Date",
                "placeholder": "",
                "required": false,
                "requiredMsg": "Admit Until Date is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_expiry": {
                "label": "Admit Until Date",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false,
                "invalidAdmitUntilDate": "The Admit Until Date must be a future date"
            }
        },
        "ssn": {
            "document_number": {
                "label": "SSN Number",
                "placeholder": "",
                "required": true,
                "requiredMsg": "SSN Number is required",
                "maxLength": 11,
                "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                "patternMsg": "Invalid SSN, please check the format",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "visa": {
            "visa_type_id": {
                "label": "Visa Type",
                "placeholder": "",
                "required": true,
                "pattern": "",
                "patternMsg": "",
                "requiredMsg": "Visa Type is required",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_issue": {
                "label": "Date of Issue",
                "required": false,
                "requiredMsg": "Date of Issue is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_expiry": {
                "label": "Date of Expiry",
                "required": false,
                "requiredMsg": "Date of Expiry is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "drivingLicense": {
            "document_number": {
                "label": "Document Number",
                "placeholder": "",
                "required": true,
                "requiredMsg": "Document Number is required",
                "maxLength": 16,
                "minLength": 4,
                "minLengthMsg": "Document Number must be between 4 to 16 alphanumeric characters",
                "pattern": "/^[a-zA-Z0-9]+$/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "valid_from": {
                "label": "Date Of Issue",
                "required": false,
                "requiredMsg": "Date Of Issue is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "valid_till": {
                "label": "Date Of Expiry",
                "required": false,
                "requiredMsg": "Date Of Expiry is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "voidCheque": {
            "account_number": {
                "label": "Account Number",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "minLength": 6,
                "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
                "maxLength": 17,
                "errorMsg": "Account Number is already exists",
                "errorMsg1": "Account number not matching",
                "pattern": "/[0-9]/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "routing_number": {
                "label": "Routing Number",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "minLength": 9,
                "minLengthMsg": "Please enter a valid 9 digit routing number",
                "maxLength": 9,
                "errorMsg": "Routing number not matching",
                "pattern": "/[0-9]/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "bank_name": {
                "label": "Bank Name",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "minLength": 2,
                "minLengthMsg": "Bank name should be greater than 2 characters",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z]+( [a-zA-Z]+)*$/",
                "patternMsg": "Please enter a valid bank name",
                "isVisible": true,
                "isDisabled": false
            },
            "account_type": {
                "label": "Account Type",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }

        },
        "reason_for_rejection": {
            "label": "Add reason for rejection",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "minLength": 5,
            "minLengthMsg": "Please enter a valid reason",
            "maxLength": 490,
            "rows": 2,
            "pattern": "/^[a-zA-Z0-9]+$/",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }

    },
    "emergencyContactDetails": {

        "name": {
            "label": "Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 120,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Name",
            "isVisible": true,
            "isDisabled": false
        },
        "mobile_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        },
        "relationship_id": {
            "placeholder": "",
            "label": "Relation",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "address_1": {
            "placeholder": "",
            "label": "Address Line 1",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 225,
            "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
            "patternMsg": "Please enter a valid address",
            "isVisible": true,
            "isDisabled": false
        },
        "address_2": {
            "placeholder": "",
            "label": "Address Line 2",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 225,
            "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
            "patternMsg": "Please enter a valid address",
            "isVisible": true,
            "isDisabled": false
        },
        "zip_code": {
            "label": "Zipcode / Pincode",
            "zipcodeLabel": "Zipcode",
            "pincodeLabel": "Pincode",
            "placeholder": "",
            "maxLength": 6,
            "zipcodeLength": 5,
            "pincodeLenght": 6,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "city": {
            "label": "City",
            "placeholder": "",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z0-9 ]+$/",
            "patternMsg": "Please enter a valid city",
            "isVisible": true,
            "isDisabled": false
        },
        "state_id": {
            "label": "State",
            "placeholder": "",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "country_id": {
            "label": "Country",
            "placeholder": "",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}

export const employeeOffBoardingDefaultConfig = {
    "placement": {
        "placement_end_date": {
            "label": "Placement End Date",
            "placeholder": null,
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    },
    "revocationLetter": {
        "email_sent_to": {
            "placeholder": "",
            "label": "Email Sent To",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "date": {
            "label": "Date",
            "placeholder": null,
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "revocation_sent_document": {
            "format": ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'],
            "formatMsg": "Please upload files in pdf or png or jpg or jpeg or doc format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "label": "Revocation Letter Sent",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "revocation_raised_document": {
            "format": ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'],
            "formatMsg": "Please upload files in pdf or png or jpg or jpeg or doc format only. ",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "label": "Revocation Letter Raised",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
    },
    "deleteMail": {
        "email_deleted_on": {
            "label": "E-Mail ID Deleted on",
            "placeholder": null,
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "invite_via_link": {
            "format": ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'document'],
            "formatMsg": "Please upload files in pdf or png or jpg or jpeg or doc format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "label": "Proof Of Document",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}

export const employeeRehire = {
    "additionalDetails": {
        "rejoin_date": {
            "label": "Start Date",
            "placeholder": null,
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "vendor_id": {
            "label": "Vendor Type",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
        }

    },
    "workAuthorization": {
        "is_usc": {
            "label": "Is the Employee a US Citizen?",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
        },
        "visa_type_id": {
            "label": "Visa type",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
        },
        "visa_document": {
            "format": [
                "pdf",
                "doc",
                "docx"
            ],
            "formatMsg": "Please upload files in PDF or DOC format only.",
            "size": 25,
            "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "totalFiles": 10,
            "totalFilesMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
            "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
            "label": {
                "name": "*Add visa documents like EAD, I983, I-129, I-797,GC, GC EAD, etc",
                "format": "Document Size 25MB, Format - PDF, Docs"
            }
        },
        "uploaded_docs": {
            "label": "Uploaded Documents"
        },
        "valid_from": {
            "label": "Date of Issue",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "valid_till": {
            "label": "Date of Expiry",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }

    }
}

export const userProfileDefaultConfig = {

    "generalDetails": {

        "basicDetails": {

            "first_name": {
                "label": "First Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid First name",
                "isVisible": true,
                "isDisabled": false
            },
            "middle_name": {
                "label": "Middle Name",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Middle Name",
                "isVisible": true,
                "isDisabled": false
            },
            "last_name": {
                "label": "Last Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Last Name",
                "isVisible": true,
                "isDisabled": false
            },
            "dob": {
                "label": "Date of Birth",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "ageLimit": 18,
                "ageLimitMsg": "Minimum age should be greater than 18",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "gender": {
                "placeholder": "",
                "label": "Gender",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "gender_description": {
                "label": "Gender Description",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "minLength": 3,
                "minLengthMsg": "Invalid Charaters",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z\\s-]*$/",
                "patternMsg": "Invalid Charaters",
                "isVisible": true,
                "isDisabled": false
            },
            "blood_group": {
                "placeholder": "",
                "label": "Blood Group",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "marital_status": {
                "placeholder": "",
                "label": "Marital Status",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "W4_document": {
                "label": "W-4 Form",
                "required": true,
                "requiredMsg": "This field is required",
                "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/w4.jpg",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false,
                "fileSizeLimit": 25,
                "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
            }

        },

        "contactDetails": {
            "contact_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_contact_number": {
                "placeholder": "",
                "label": "Alternate Mobile Number",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Alternate Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "email_id": {
                "placeholder": "",
                "label": "Email ID",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_email_id": {
                "placeholder": "",
                "label": "Alternate Email ID",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            }
        },

        "currentAddressDetails": {
            "address_line_one": {
                "placeholder": "",
                "label": "Address Line 1",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_two": {
                "placeholder": "",
                "label": "Address Line 2",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "location": {
                "zip_code": {
                    "label": "Zipcode / Pincode",
                    "zipcodeLabel": "Zipcode",
                    "pincodeLabel": "Pincode",
                    "placeholder": "",
                    "maxLength": 6,
                    "zipcodeLength": 5,
                    "pincodeLenght": 6,
                    "pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "city": {
                    "label": "City",
                    "placeholder": "",
                    "maxLength": 50,
                    "pattern": "/^[a-zA-Z0-9 ]+$/",
                    "patternMsg": "Please enter a valid city",
                    "required": true,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "state": {
                    "label": "State",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "country": {
                    "label": "Country",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },

        "employmentDetails": {

            "employment_type": {
                "placeholder": "",
                "label": "Employment Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "employment_category": {
                "placeholder": "",
                "label": "Employment Category",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "vendor": {
                "placeholder": "",
                "label": "Vendor",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "date_of_joining": {
                "label": "Joining Date",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "ageLimit": 18,
                "ageLimitMsg": "Minimum age should be greater than 18",
                "errorMsg": "Please select a date after your birth date.",
                "errorMsg1": "Joining date shouldn’t be the past date.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },

            "ssn": {
                "label": "SSN",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "SSN Number already exists",
                "maxLength": null,
                "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                "patternMsg": "Invalid SSN, please check the format",
                "isVisible": true,
                "isDisabled": false
            },
            "is_us_citizen": {
                "placeholder": "",
                "label": "Is the Employee a US Citizen",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "visa_type": {
                "placeholder": "",
                "label": "Visa Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "opening_balance": {
                "label": "Opening Balance",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "standard_wage": {
                "label": "Standard Wage",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_health_insurance": {
                "label": "Do you want to enable health insurance for this employee.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "insurance_amount": {
                "label": "Insurance Amount",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "/^(?:\\d{1,10}|\\d{1,10}\\.\\d{0,2})$/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "make_standard_pay_as_salary": {
                "label": "Make Standard Wage the Default Salary",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_login": {
                "label": "Enable User Access",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "enable_payroll": {
                "label": "Enable Payroll & Balancesheet",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
        },

        "emergencyContactDetails": {

            "name": {
                "label": "Name",
                "placeholder": "",
                "maxLength": 120,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Name",
                "isVisible": true,
                "isDisabled": false
            },
            "mobile_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "relation": {
                "placeholder": "",
                "label": "Relation",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_one": {
                "placeholder": "",
                "label": "Address Line 1",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_two": {
                "placeholder": "",
                "label": "Address Line 2 Optional",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "zip_code": {
                "label": "Zipcode / Pincode",
                "zipcodeLabel": "Zipcode",
                "pincodeLabel": "Pincode",
                "placeholder": "",
                "maxLength": 6,
                "zipcodeLength": 5,
                "pincodeLenght": 6,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "city": {
                "label": "City",
                "placeholder": "",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z0-9 ]+$/",
                "patternMsg": "Please enter a valid city",
                "isVisible": true,
                "isDisabled": false
            },
            "state": {
                "label": "State",
                "placeholder": "",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "country": {
                "label": "Country",
                "placeholder": "",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "openingBalance": {
            "opening_balance": {
                "label": "Opening Balance",
                "placeholder": "",
                "maxLength": 40,
                "pattern": "",
                "patternMsg": "Please enter a valid Opening Balance",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": true
            },
            "new_opening_balance": {
                "label": "New Opening Balance",
                "placeholder": "",
                "maxLength": 40,
                "pattern": "",
                "patternMsg": "Please enter a valid New Opening Balance",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": false
            },
            "balance_impact": {
                "label": "Balance Impact",
                "placeholder": "",
                "maxLength": 40,
                "pattern": "",
                "patternMsg": "Please enter a valid Balance Impact",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": true
            },
            "modification": {
                "label": "Reason for modifications",
                "placeholder": "",
                "maxLength": 255,
                "pattern": "",
                "patternMsg": "Please enter a valid Reason for modifications",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": false
            },
            "current_balance": {
                "label": "Current Balance",
                "placeholder": "",
                "maxLength": 40,
                "pattern": "",
                "patternMsg": "Please enter a valid Current Balance",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": true
            },
            "new_current_balance": {
                "label": "New Current Balance",
                "placeholder": "",
                "maxLength": 40,
                "pattern": "",
                "patternMsg": "Please enter a valid New Opening Balance",
                "required": true,
                "requiredMsg": "This field is required",
                "isVisible": true,
                "isDisabled": false
            }
        }
    },
    "documentsDetails": {
        "workAuthorization": {
            "passport": {
                "passportUpload": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only ",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Upload Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "passportNumber": {
                    "label": "Passport Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Passport Number is required",
                    "maxLength": 14,
                    "minLength": 8,
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "minLengthMsg": "Passport Number must be between 8 and 14 characters in length",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "issued_country_id": {
                    "label": "Issued Country",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Issued Country is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "i94": {
                "new_document": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "I-94 Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "I-94 Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "I-94 Number is required",
                    "maxLength": 11,
                    "maxLengthMsg": "i94 Number must be 11 characters in length",
                    "pattern": "/^(?!(\\d)\\1+$)(?:\\d{11}|\\d{9}[A-Za-z]\\d)$/",
                    "patternMsg": "Please enter a valid I-94 Number",
                    "alreadyExistsMsg": "I-94 Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Recent Date of Entry",
                    "required": false,
                    "requiredMsg": "Recent Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "expiry_type": {
                    "label": "Admit Until Date",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Admit Until Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Admit Until Date",
                    "required": false,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "visa": {
                "visa_type_id": {
                    "label": "Visa Type",
                    "placeholder": "",
                    "required": true,
                    "pattern": "",
                    "patternMsg": "",
                    "requiredMsg": "Visa Type is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "visa_document": {
                    "format": [
                        "pdf",
                        "doc",
                        "docx"
                    ],
                    "formatMsg": "Please upload files in PDF,Docs only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 10,
                    "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                    "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                    "label": {
                        "name": "*Add visa documents like EAD, I983, I-129, I-797, GC, GC EAD, etc",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                },
                "valid_from": {
                    "label": "Date of Issue",
                    "required": false,
                    "requiredMsg": "Date of Issue is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date of Expiry",
                    "required": false,
                    "requiredMsg": "Date of Expiry is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },
        "educationDetails": {
            "education_documents": {
                "format": [
                    "pdf",
                    "doc",
                    "docx"
                ],
                "formatMsg": "Please upload files in PDF,Docs only",
                "size": 25,
                "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                "totalFiles": 10,
                "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                "label": {
                    "name": "Add your educational documents like Grade card, certificates, transcripts, etc",
                    "format": "Document Size 25MB, Format - PDF, Docs"
                }
            },
            "uploaded_docs": {
                "label": "Uploaded Documents"
            }
        },
        "personalDocuments": {
            "drivingLicense": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Upload Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "document_type_id": {
                    "label": "Document Type",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Document Type is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "document_number": {
                    "label": "Document Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Document Number is required",
                    "maxLength": 16,
                    "minLength": 4,
                    "minLengthMsg": "Document Number must be between 4 to 16 alphanumeric characters",
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "ssn": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "SSN Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "SSN Number",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "SSN Number is required",
                    "maxLength": 11,
                    "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                    "patternMsg": "Invalid SSN, please check the format",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "otherDocuments": {
                "other_documents": {
                    "format": [
                        "msword",
                        "doc",
                        "vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "ms-doc",
                        "pdf"
                    ],
                    "formatMsg": "Upload document Format PDF/Docs",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 5,
                    "totalFilesMsg": "Upload 5 Documents Only",
                    "label": {
                        "name": "Add any other documentation like Countersigned Offer Letter, Other Personal and Visa related documents.",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                }
            }
        }
    },
    "bankDetails": {
        "i9_document": {
            "label": "I-9 Documents",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/i-9.png",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "W4_document": {
            "label": "W-4 Form",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/w4.jpg",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "void_cheque": {
            "label": "Void Cheque",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "bank_name": {
            "label": "Bank Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 2,
            "minLengthMsg": "Bank name should be greater than 2 characters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z]+( [a-zA-Z]+)*$/",
            "patternMsg": "Please enter a valid bank name",
            "isVisible": true,
            "isDisabled": false
        },
        "account_type": {
            "label": "Account Type",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "account_number": {
            "label": "Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account Number is already exists",
            "errorMsg1": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_account_number": {
            "label": "Confirm Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "routing_number": {
            "label": "Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_routing_number": {
            "label": "Confirm Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_type": {
            "label": "Choose Deposit Configuration",
            "info": "Choose your bank deposit configuration and division",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_value": {
            "label1": "Deposit Percentage",
            "label2": "Deposit Value",
            "info1": "Enter the deposit percentage that you want to have in this bank",
            "info2": "Enter the deposit value that you want to have in this bank",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "errorMsg": "Routing number not matching",
            "pattern": "/^\\d+(\\.\\d+)?$/",
            "patternMsg": "Please enter only numbers",
            "isVisible": true,
            "isDisabled": false
        }
    },

    "skills": {
        "skill_id": {
            "placeholder": "",
            "label": "Skill Name",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "expertise": {
            "placeholder": "",
            "label": "Level Of Expertise",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "certification": {
            "placeholder": "",
            "label": "Certification Link/URL",
            "required": false,
            "requiredMsg": "This field is required",
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "certification_date": {
            "label": "Admit Until Date",
            "required": false,
            "requiredMsg": "This field is required",
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "experience_years": {
            "placeholder": "",
            "label": "Years of Experience",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "documents": {
            "label": "Upload Certificate",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        }
    },

    "offboardingPopupDetails": {
        "lastWorkingDate": {
            "label": "Last Working Day",
            "placeholder": null,
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "description": {
            "label": "Reason For Offboarding",
            "placeholder": "Type Something",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 255,
            "rows": 3,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}

export const myProfileDefaultConfig = {
    "myDetails": {
        "first_name": {
            "label": "First Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid First name",
            "isVisible": true,
            "isDisabled": false
        },
        "middle_name": {
            "label": "Middle Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Middle Name",
            "isVisible": true,
            "isDisabled": false
        },
        "last_name": {
            "label": "Last Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Last Name",
            "isVisible": true,
            "isDisabled": false
        },
        "gender": {
            "placeholder": "",
            "label": "Gender",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender_description": {
            "label": "Gender Description",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "minLength": 3,
            "minLengthMsg": "Invalid Charaters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z\\s-]*$/",
            "patternMsg": "Invalid Charaters",
            "isVisible": true,
            "isDisabled": false
        },
        "contact_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        }
    },
    "organizationDetails": {
        "organization_name": {
            "label": "Organization Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 60,
            "pattern": "/^[A-Za-z0-9 &@£$€¥-]{2,60}$/",
            "patternMsg": "Please enter a valid Organization name",
            "isVisible": true,
            "isDisabled": false
        },
        "currency_type": {
            "placeholder": "",
            "label": "Currency",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "time_zone_format": {
            "placeholder": "",
            "label": "Time Zone",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "logo_url": {
            "label": "Organizational Logo",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file",
            "format": ["png", "jpg", "jpeg"],
            "formatMsg": "Please upload files in PDF,PNG OR JPEG only "
        }
    },
    "internalemployee": {
        "placeholder": "",
        "label": "Internal Employee",
        "required": true,
        "requiredMsg": "This field is required",
        "pattern": "",
        "patternMsg": "",
        "isVisible": true,
        "isDisabled": false
    },
    "newUser": {
        "first_name": {
            "label": "First Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid First name",
            "isVisible": true,
            "isDisabled": false
        },
        "middle_name": {
            "label": "Middle Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Middle Name",
            "isVisible": true,
            "isDisabled": false
        },
        "last_name": {
            "label": "Last Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Last Name",
            "isVisible": true,
            "isDisabled": false
        },
        "gender": {
            "placeholder": "",
            "label": "Gender",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender_description": {
            "label": "Gender Description",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "minLength": 3,
            "minLengthMsg": "Invalid Charaters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z\\s-]*$/",
            "patternMsg": "Invalid Charaters",
            "isVisible": true,
            "isDisabled": false
        },
        "contact_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        },
        "email_id": {
            "placeholder": "",
            "label": "Email ID",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
            "patternMsg": "Please enter a valid Email Id",
            "isVisible": true,
            "isDisabled": false
        }
    }


}
