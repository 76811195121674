import React from 'react'
import { onNumberOnlyChange } from '../../../components/Validation'
import Input from '../../../components/input/Input'
import { Box, Grid } from '@mui/material'
import Text from '../../../components/customText/Text'
import { editPlacementDefaultConfig } from '../../../config/pageConfig/PlacementModuleConfig'
export default function Worklocation(props) {
    const { state, error, changeHandlerAddress, work_location_type, columnSize,classes, placementDetails } = props

    const officeDetails = placementDetails ? placementDetails?.office : editPlacementDefaultConfig?.placement_details?.office;
    const remoteDetails = placementDetails ? placementDetails?.remote : editPlacementDefaultConfig?.placement_details?.remote;

    return (
        <>
            <Grid container columnSpacing={4}>
                {(work_location_type == 'In-Office' || work_location_type == 'Hybrid') &&
                    <Grid item container columnSpacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box display='flex' flexDirection='row' gap={2}>
                                <Text blackHeader1 className={classes.blackHeader1}>Please enter the office address</Text>
                            </Box>
                        </Grid>
                        {officeDetails?.address_line_one?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_one',
                                    value: state.address.office.address_line_one,
                                    inputProps: { maxLength: officeDetails?.address_line_one?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={officeDetails?.address_line_one?.required ? officeDetails?.address_line_one?.label : officeDetails?.address_line_one?.label + ' (Optional)'}
                                placeholder={officeDetails?.address_line_one?.placeholder}
                                disabled={officeDetails?.address_line_one?.isDisabled}
                                error={error.office_address_line_one}
                            />
                            <Text red>{error.office_address_line_one ? error.office_address_line_one : ""}</Text>
                        </Grid>}
                        {officeDetails?.address_line_two?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_two',
                                    value: state.address.office.address_line_two,
                                    inputProps: { maxLength: officeDetails?.address_line_two?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={officeDetails?.address_line_two?.required ? officeDetails?.address_line_two?.label : officeDetails?.address_line_two?.label + ' (Optional)'}
                                placeholder={officeDetails?.address_line_two?.placeholder}
                                disabled={officeDetails?.address_line_two?.isDisabled}
                                error={error.office_address_line_two}
                            />
                            <Text red>{error.office_address_line_two ? error.office_address_line_two : ""}</Text>
                        </Grid>}
                        {officeDetails?.zip_code?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'zip_code',
                                    value: state.address.office.zip_code,
                                    inputProps: { maxLength: officeDetails?.zip_code?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                onKeyPress={onNumberOnlyChange}
                                clientInput
                                labelText={officeDetails?.zip_code?.label}
                                placeholder={officeDetails?.zip_code?.placeholder}
                                disabled={officeDetails?.zip_code?.isDisabled}
                                error={error.office_zip_code}
                            />
                            <Text red>{error.office_zip_code ? error.office_zip_code : ""}</Text>
                        </Grid>}
                        {officeDetails?.city?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'city',
                                    value: state.address.office.city,
                                    inputProps: { maxLength: officeDetails?.city?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'office')}
                                clientInput
                                labelText={officeDetails?.city?.required ? officeDetails?.city?.label : officeDetails?.city?.label + ' (Optional)'}
                                placeholder={officeDetails?.city?.placeholder}
                                disabled={officeDetails?.city?.isDisabled}
                                error={error.office_city}
                            />
                            <Text red>{error.office_city ? error.office_city : ""}</Text>
                        </Grid>}
                        {officeDetails?.state?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'state_name',
                                    value: state.address.office.state_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText={officeDetails?.state?.label}
                                placeholder={officeDetails?.state?.placeholder}
                            />
                        </Grid>}
                        {officeDetails?.country?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'country_name',
                                    value: state.address.office.country_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText={officeDetails?.country?.label}
                                placeholder={officeDetails?.country?.placeholder}
                            />
                        </Grid>}

                    </Grid>
                }
                {(work_location_type == 'Remote' || work_location_type == 'Hybrid') &&
                    <Grid item container columnSpacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box display='flex' flexDirection='row' gap={2}>
                                <Text blackHeader1 className={classes.blackHeader1}>Please enter the remote address</Text>
                            </Box>
                        </Grid>
                        {remoteDetails?.address_line_one?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_one',
                                    value: state.address.remote.address_line_one,
                                    inputProps: { maxLength: remoteDetails?.address_line_one?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={remoteDetails?.address_line_one?.required ? remoteDetails?.address_line_one?.label : remoteDetails?.address_line_one?.label + ' (Optional)'}
                                placeholder={remoteDetails?.address_line_one?.placeholder}
                                disabled={remoteDetails?.address_line_one?.isDisabled}
                                error={error.remote_address_line_one}
                            />
                            <Text red>{error.remote_address_line_one ? error.remote_address_line_one : ""}</Text>
                        </Grid>}
                        {remoteDetails?.address_line_two?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'address_line_two',
                                    value: state.address.remote.address_line_two,
                                    inputProps: { maxLength: remoteDetails?.address_line_two?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={remoteDetails?.address_line_two?.required ? remoteDetails?.address_line_two?.label : remoteDetails?.address_line_two?.label + ' (Optional)'}
                                placeholder={remoteDetails?.address_line_two?.placeholder}
                                disabled={remoteDetails?.address_line_two?.isDisabled}
                                error={error.remote_address_line_two}
                            />
                            <Text red>{error.remote_address_line_two ? error.remote_address_line_two : ""}</Text>
                        </Grid>}
                        {remoteDetails?.zip_code?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'zip_code',
                                    value: state.address.remote.zip_code,
                                    inputProps: { maxLength: remoteDetails?.zip_code?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                onKeyPress={onNumberOnlyChange}
                                clientInput
                                labelText={remoteDetails?.zip_code?.label}
                                placeholder={remoteDetails?.zip_code?.placeholder}
                                disabled={remoteDetails?.zip_code?.isDisabled}
                                error={error.remote_zip_code}
                            />
                            <Text red>{error.remote_zip_code ? error.remote_zip_code : ""}</Text>
                        </Grid>}
                        {remoteDetails?.city?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'city',
                                    value: state.address.remote.city,
                                    inputProps: { maxLength: remoteDetails?.city?.maxLength }
                                }}
                                handleChange={(e) => changeHandlerAddress(e, 'remote')}
                                clientInput
                                labelText={remoteDetails?.city?.required ? remoteDetails?.city?.label : remoteDetails?.city?.label + ' (Optional)'}
                                placeholder={remoteDetails?.city?.placeholder}
                                disabled={remoteDetails?.city?.isDisabled}
                                error={error.remote_city}
                            />
                            <Text red>{error.remote_city ? error.remote_city : ""}</Text>
                        </Grid>}
                        {remoteDetails?.state?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'state_name',
                                    value: state.address.remote.state_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText={remoteDetails?.state?.label}
                                placeholder={remoteDetails?.state?.placeholder}
                            />
                        </Grid>}
                        {remoteDetails?.country?.isVisible &&
                        <Grid item lg={columnSize} md={columnSize} sm={columnSize} xs={12} pt={4}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'country_name',
                                    value: state.address.remote.country_name || '',
                                }}
                                disabled={true}
                                clientInput
                                labelText={remoteDetails?.country?.label}
                                placeholder={remoteDetails?.country?.placeholder}
                            />
                        </Grid>}


                    </Grid>
                }
            </Grid>
        </>
    )
}