import React, { useEffect, useState } from 'react'
import { Avatar, Box, Divider, Grid, Hidden, Menu, MenuItem, TableCell, TableBody, TableHead, TableContainer, Table, TableRow, Paper } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
// import FinancialPerformance from '../dashboard/FinancialPerformance';
import Text from '../../../components/customText/Text';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import billed from '../../../assets/svg/dashboard/billed.svg'
import pay from '../../../assets/svg/payGreen.svg'
import margin from '../../../assets/svg/marginYellow.svg'
// import { BlackToolTip } from '../../../utils/utils';
import DashboardStyles from "../../admin/DasboardStyles";
// import DetailsDashboard from '../dashboard/DetailsDashboard';
import ReactApexChart from 'react-apexcharts';
import Varient from '../../../assets/Variant3.svg'
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import Button from '../../../components/customButton/Button'
import closeIcon from '../../../assets/svg/payroll/close-Icon.svg'
import moment from 'moment';
import { addLoader, addLoaderPlanExpired, addWarningMsg, capitalizeAndAddSpace, dateFormat, getCurrencySymbol, NoDataFound, removeExpiredLoader, removeLoader, rolePermission } from '../../../utils/utils';
import Date from '../../../components/datePicker/Date';
import { styled } from "@mui/material/styles";
import LocalStorage from '../../../utils/LocalStorage';
import CommonApi from '../../../apis/CommonApi';
import TourGuideConfigApi from '../../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import TourInvoice from '../../../components/tourGuideDetails/TourInvoice';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterStorage } from '../../../utils/FilterStorage';
import { domain } from '../../../config/Domain';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));


function InvoiceDashboard() {
    const [leadingPopup, setLeadingPopup] = useState(false);
    const [overduePopup, setOverduePopup] = useState(false)
    const [enableDate, setEnableDates] = useState(false);
    const [year, setYear] = useState(4);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const dateFromLocalStorage = LocalStorage.getOrgStartDate() ? LocalStorage.getOrgStartDate() : '';
    const startDate = moment().subtract(1, 'years').startOf('month').format(dateFormat());
    const [state, setState] = useState({
        start_date: startDate,
        end_date: moment().format(dateFormat()),
        analyticsEndDate: "",
        analyticsStartDate: ""
    });

    const [startTour, setStartTour] = useState(false);
    const [destroyTour, setDestroyTour] = useState(false); // State to trigger tour destruction
    useEffect(() => {
        balanceAmountFunc();
        clientDetailsFunc();
        amountGraphFunc();
        incomeGraphFunc();
        getPlanCheck();
        const handleResize = () => {
            setStartTour(false); 
            LocalStorage.setStartTour({ 'invoice': false });
            setDestroyTour(true);

        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // eslint-disable-next-line
    }, [state]);
    const navigate = useNavigate();
    const location = useLocation();

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                    filterStorage.clearAllFilters();
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (LocalStorage.getUserData().admin_login && (rolePermission !== "" &&
                        (rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)))
                    ) {
                        getTourGuideDetails();
                    } else {
                        LocalStorage.setStartTour({ 'invoice': false });
                    }
                }
            }
        })
    }

    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().invoice == true) || (response.data.statusCode == 1003 && response.data.data?.INVOICE == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'invoice': true });
            setStartTour(true);
        }

    }



    const classes = DashboardStyles();

    const payrollComparison = [
        { id: 1, value: `${moment().year()} YTD` },
        { id: 2, value: 'Last 3 Months' },
        { id: 3, value: 'Last 6 Months' },
        { id: 4, value: 'Last Year' },
        { id: 5, value: 'Lifetime Summary' },
        { id: 6, value: 'Custom' }
    ]


    const clearDate = () => {
        setEnableDates(false);
        const startDate = moment().subtract(1, 'years').startOf('month').format(dateFormat());
        setState((prevState) => ({
            ...prevState,
            start_date: startDate,
            end_date: moment().format(dateFormat()),
            analyticsStartDate: '',
            analyticsEndDate: ''
        }));
        setYear(4);
    };

    const handleChange = (e) => {
        let value = e.target.value;
        setYear(value);

        let startDate, endDate;

        switch (value) {
            case 1:
                // YTD: Start from the beginning of the current year
                startDate = moment().startOf('year').format(dateFormat());
                endDate = moment().format(dateFormat());
                break;
            case 2:
                // 3 Months: Start 3 months ago
                startDate = moment().subtract(3, 'months').startOf('month').format(dateFormat());
                endDate = moment().format(dateFormat());
                break;
            case 3:
                // 6 Months: Start 6 months ago
                startDate = moment().subtract(6, 'months').startOf('month').format(dateFormat());
                endDate = moment().format(dateFormat());
                break;
            case 4:
                // Last Year
                startDate = moment().subtract(1, 'years').startOf('month').format(dateFormat());
                endDate = moment().format(dateFormat());
                break;
            case 5:
                // Lifetime Summary (No date change needed) org start date to start  date
                startDate = dateFromLocalStorage ? dateFromLocalStorage : moment().subtract(50, 'years').startOf('month').format(dateFormat());
                // startDate = moment().subtract(1, 'years').startOf('month').format(dateFormat())
                endDate = moment().format(dateFormat());
                break;
            case 6:
                // Custom: Show date picker for user input limits org start date to current date
                setAnchorEl(true);
                setOpen(true);
                return;
            default:
                return;
        }

        setState((prevState) => ({
            ...prevState,
            start_date: startDate,
            end_date: endDate
        }));

    };

    const handleClose = () => {
        const startDate = moment().subtract(1, 'years').startOf('month').format(dateFormat());
        setState((prevState) => ({
            ...prevState,
            start_date: startDate,
            end_date: moment().format(dateFormat()),
            analyticsStartDate: '',
            analyticsEndDate: ''
        }));
        setAnchorEl(null);
        setOpen(false);
        setYear(4);
    };

    const applyFilter = () => {
        if (state.analyticsStartDate !== '' && state.analyticsEndDate !== '') {

            setAnchorEl(null);
            setOpen(false);
            if (year === 6 && state.analyticsStartDate !== '' && state.analyticsEndDate !== '') {
                setEnableDates(true);
                setState((prevState) => ({
                    ...prevState,
                    start_date: state.analyticsStartDate,
                    end_date: state.analyticsEndDate,
                    analyticsStartDate: '',
                    analyticsEndDate: ''
                }));
            }

        } else {
            addWarningMsg('Please select Start and End Date');
        }
    };

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d; // eslint-disable-line
        setState((prevState) => ({
            ...prevState,
            [name]: date === '' ? '' : moment(date).format(dateFormat())
        }));
    };

    const [balanceAmount, setBalanceAmount] = useState({});
    const [clientDetails, setClientDetails] = useState({})
    const [amountGraph, setAmountGraph] = useState({})
    const [incomeGraph, SetIncomeGraph] = useState({})


    const balanceAmountFunc = () => {
        let data = {
            request_id: LocalStorage.uid(),
            start_date: state.start_date,
            end_date: state.end_date
        }
        CommonApi.InvoiceDashboardBalanceAmount(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setBalanceAmount(res.data.data)
            }
        }
        )
    }
    const clientDetailsFunc = () => {
        let data = {
            request_id: LocalStorage.uid(),
            start_date: state.start_date,
            end_date: state.end_date
        }
        CommonApi.InvoiceDashboardClientData(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDetails(res.data.data)

            }
        })
    }
    const amountGraphFunc = () => {
        let data = {
            request_id: LocalStorage.uid(),
            start_date: state.start_date,
            end_date: state.end_date
        }
        CommonApi.InvoiceDashboardIncomeGraph(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setAmountGraph(res.data.data)
            }
        })
    }

    const incomeGraphFunc = () => {
        let data = {
            request_id: LocalStorage.uid(),
            start_date: state.start_date,
            end_date: state.end_date
        }
        CommonApi.InvoiceDashboardExpectedIncometGraph(data).then((res) => {
            if (res.data.statusCode === 1003) {
                SetIncomeGraph(res.data.data)
            }
        })
    }


    const amountGraphOptions = {
        series: amountGraph.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: true, 
                type: 'x', 
                range: {
                    min: state.start_date, // Example: Set a min date
                    max: state.end_date // Restrict max date to today
                }
            },
            toolbar: {
                show: true,
                tools: {
                    pan: false
                }
            },
            width: "100%", 
        },
        stroke: {
            show: true,
            colors: ['#0C75EB', '#16A34A'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#0C75EB', '#16A34A'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'category',
            categories: amountGraph?.labels || [],
            labels: {
                hideOverlappingLabels: false,
                showDuplicates: false,
                style: {
                    fontSize: '12px'
                }
            },
            tickAmount: 10,
            tickPlacement: 'on',
            min: state.start_date, // Set the starting date of your data range
            max: state.end_date// Limit to today
        },
        yaxis: {
            type: 'numeric',
            labels: {
                formatter: function (val) {
                    return "$ " + val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val.toFixed(3);
                },
                title: {
                    formatter: function () {
                        return ''; // Hide the title in the tooltip
                    }
                }
            }
        },
    };
    
    const incomeGraphOptions = {
        series: incomeGraph.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: true,  // Enable zooming
                type: 'x',      // Enable zoom along the x-axis
                autoScaleYaxis: true ,// Automatically adjust the y-axis when zooming
                pan: {
                    enabled: false,  // Enable panning
                    type: 'x'  // Enable panning along the x-axis
                }
            },
            toolbar: {
                show: true,  // Show toolbar for zooming/panning controls
                tools:{
                    pan: false
                 }  // Default to pan mode
            },
            width: "100%",  // Ensure the chart is full width
        },
        stroke: {
            show: true,
            colors: ['#0C75EB', '#14C9C9'],
            width: 2
        },
        fill: {
            type: 'solid',
            colors: ['#0C75EB', '#14C9C9'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'category',  // Set the x-axis to use categories
            categories: incomeGraph?.labels || [],  // Display the first 3 letters of each label   
            hideOverlappingLabels: true,
            tickAmount: 10,
            tickPlacement: 'on',
            labels: {
                style: {
                    fontSize: '12px',
                },
            }
        },
        yaxis: {
            type: 'numeric',
            labels: {
                formatter: function (val) {
                    return "$ " + val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val.toFixed(3);
                },
                title: {
                    formatter: function () {
                        return ''; // Hide the title in the tooltip
                    }
                }
            }
        },
      
    };


    return (
        <>
            {startTour ? <TourInvoice startTour={startTour} destroyTour={destroyTour} setDestroyTour={setDestroyTour} setStartTour={() => setStartTour(false)} /> :
                <>
                    {LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)) ?
                        <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} py={4} gap={4} sx={{ pl: { xs: "32px", sm: "32px", md: "90px", lg: '120px' }, pr: { xs: "0px", sm: "45px", md: "45px", lg: '45px' }, overflow: "auto", '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none', }}>

                            <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center' rowGap={2} >
                                <Grid item lg={enableDate ? 6 : 6} md={enableDate ? 6 : 6} sm={6} xs={6}>

                                    <Text smallLabelblack16>Invoice Dashboard</Text>
                                    </Grid>
                                <Grid item container xs={12} lg={6} md={6} sm={6} justifyContent={'end'}>

                                    {

                                        enableDate ?
                                            <Grid item lg={enableDate ? 4 : 2} md={enableDate ? 4 : 3} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                <Box display='flex' flexDirection='row' gap={1} sx={{
                                                    border: '1px solid #C7CCD3',
                                                    height: '36px',
                                                    width: 'max-content',
                                                    borderRadius: '8px',
                                                    padding: '0px 6px',
                                                    alignItems: 'center !important',
                                                    cursor: 'pointer !important',
                                                    justifyContent: "end"
                                                }}>
                                                    <Box><Text offBoardBodyFont>{state.start_date} - {state.end_date}</Text></Box>
                                                    <Box sx={{ alignItems: 'center', textAlign: "center", display: 'flex', justifyContent: 'center' }}><img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => { clearDate() }} /></Box>
                                                </Box>
                                            </Grid> :
                                            <Grid item lg={4} md={5.5} sm={7} xs={8} textAlign={"end"}>
                                                <CustomSelect
                                                    name='year'
                                                    value={year}
                                                    viewDrop
                                                    scrollTrue={true}
                                                    options={payrollComparison}
                                                    onChange={(e) => handleChange(e)}
                                                    labelId={'demo-simple-select-filled-label'}
                                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                                />
                                                <Menu
                                                    id="demo-simple-select-filled-label"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={() => handleClose()}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'demo-simple-select-filled-label',
                                                    }}
                                                    style={{ width: '100%' }}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuItem disableRipple={true} sx={{ backgroundColor: 'transparent !important' }}>
                                                        <Grid item xs={12}>
                                                            <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                                <Box>
                                                                    <Date
                                                                        labelText='Start Date'
                                                                        name='analyticsStartDate'
                                                                        value={state.analyticsStartDate}
                                                                        onChange={(value => dateChange(value, 'analyticsStartDate'))}
                                                                        height='45px'
                                                                        maxDate={moment().format(dateFormat())}
                                                                    //minDate={moment(LocalStorage.getOrgStartDate()).format(dateFormat())}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    -
                                                                </Box>
                                                                <Box>
                                                                    <Date
                                                                        labelText='End Date'
                                                                        name='analyticsEndDate'
                                                                        value={state.analyticsEndDate}
                                                                        minDate={moment(state.analyticsStartDate, dateFormat()).format(dateFormat())}//can be org start date
                                                                        onChange={(value => dateChange(value, 'analyticsEndDate'))}
                                                                        height='45px'
                                                                        disabled={state.analyticsStartDate == ""}
                                                                    // maxDate={} can be the today date
                                                                    //this should be disabled as filters can be disabled until start date is fixed
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <Grid item xs={12}>
                                                                <Box my={2}>
                                                                    <Divider />
                                                                </Box>
                                                                <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                                                    <Button payrollHistoryCancel onClick={handleClose}>Cancel</Button>
                                                                    <Button blueButton onClick={applyFilter}>Apply Filter</Button>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>


                                    }</Grid>
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} columnSpacing={4} rowSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{ pt: `0px !important`, pl: `0px !important` }}>


                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box alignItems='center' className={classes.bluebg}>
                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                            <Grid item lg={2} md={2} xs={3}>
                                                <img loading="eager" src={billed} alt='clients' width='48' height='48' />
                                            </Grid>
                                            <Grid item lg={10} md={10} xs={9}>
                                                <Text mediumBoldBlack>Total Amount Raised</Text>
                                                <Text boldblack22>{getCurrencySymbol()} {balanceAmount?.totalAmounts?.amount_raised ? balanceAmount?.totalAmounts?.amount_raised : '0.00'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <Box alignItems='center' className={classes.greenbg}>
                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                            <Grid item lg={2} md={2} xs={3}>
                                                <img loading="eager" src={pay} alt='clients' width='48' height='48' />
                                            </Grid>
                                            <Grid item lg={10} md={10} xs={9}>
                                                <Text mediumBoldBlack>Total Amount Received</Text>
                                                <Text boldblack22>{getCurrencySymbol()} {balanceAmount?.totalAmounts?.amount_received ? balanceAmount?.totalAmounts?.amount_received : '0.00'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box alignItems='center' className={classes.yellowbg}>
                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                            <Grid item lg={2} md={2} xs={3}>
                                                <img loading="eager" src={margin} alt='clients' width='48' height='48' />
                                            </Grid>
                                            <Grid item lg={10} md={10} xs={9}>
                                                <Text mediumBoldBlack>Total Amount Due</Text>
                                                <Text boldblack22>{getCurrencySymbol()} {balanceAmount?.totalAmounts?.amount_due ? balanceAmount?.totalAmounts?.amount_due : '0.00'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item container xs={12} lg={12} md={12} spacing={2} columnSpacing={4} sx={{ pt: `0px !important`, pl: `0px !important` }}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: { xs: '10px', sm: '30px' }, height: '100%'}}>
                                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                                            <Grid item container lg={9} md={9} sm={6} xs={6} alignItems="center">
                                                <Text largeBlack20>Unpaid Invoices</Text>
                                            </Grid>

                                            <Grid item lg={3} md={3} sm={6} xs={6} container justifyContent="flex-end">
                                                <Text smallBlue1 sx={{ cursor: 'pointer' }} onClick={() => navigate('/addInvoice')}>+ Add Invoice</Text>
                                            </Grid>
                                            <Grid item container xs={12} lg={12} md={12} rowGap={2} alignItems="center" pt={2}>
                                                <Grid item xs={6} lg={6} md={6} sm={6}>
                                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                                                        <Box sx={{ background: '#3772C6', height: '10px', width: '10px', borderRadius: '50%' }} />
                                                        <Text mediumBlack600>Invoices Due</Text>
                                                    </Box>
                                                    <Text boldblack22>{getCurrencySymbol()} {balanceAmount?.totalAmounts?.unpaid_invoice_due_amount ? balanceAmount?.totalAmounts?.unpaid_invoice_due_amount : '0.00'}</Text>
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6} sm={6} justifyContent="flex-end">
                                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                                                        <Box sx={{ background: '#FF8C42', height: '10px', width: '10px', borderRadius: '50%' }} />
                                                        <Text mediumBlack600>Invoices Overdue</Text>
                                                    </Box>
                                                    <Text boldblack22>{getCurrencySymbol()} {balanceAmount?.totalAmounts?.unpaid_invoice_overdue_amount ? balanceAmount?.totalAmounts?.unpaid_invoice_overdue_amount : '0.00'}</Text>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} lg={12} md={12} pt={2} pr={2}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: 'row',
                                                        width: "100%", // Full width of the container
                                                        height: "50px", // Height of the container
                                                    }}
                                                >
                                                    <Box width={`${Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_due_percentage))}%`} display={'flex'} flexDirection={'row'}>



                                                        {Array.from({ length: Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_due_percentage)) }).map((_, i) => (

                                                            <Box
                                                                key={i}
                                                                sx={{
                                                                    width: `${100 / Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_due_percentage))}%`, // Use a line width or fallback
                                                                    height: "100%",
                                                                    marginRight: `${2}px`,
                                                                    borderRadius: '5px',
                                                                    background: "#3772C6", // Optional: Customize color per box
                                                                }}
                                                            />
                                                        ))}
                                                    </Box>
                                                    <Box width={`${Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_overdue_percentage))}%`} display={'flex'} flexDirection={'row'}>

                                                        {Array.from({ length: Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_overdue_percentage)) }).map((_, i) => (


                                                            <Box
                                                                key={i}
                                                                sx={{
                                                                    width: `${100 / Math.floor(Number(balanceAmount?.totalAmounts?.unpaid_overdue_percentage))}%`, // Use a line width or fallback
                                                                    height: "100%",
                                                                    marginRight: `${2}px`,
                                                                    borderRadius: '5px',

                                                                    background: "#FF8C42", // Optional: Customize color per box
                                                                }}
                                                            />
                                                        ))}
                                                    </Box>

                                                </Box>
                                            </Grid>
                                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} pr={2}>
                                                <Grid item lg={11} md={9} xs={9} >
                                                    <Text mediumLabel>Overdue Breakdown</Text>
                                                </Grid>
                                                <Grid item container lg={1} md={3} xs={3} justifyContent="flex-end">
                                                    <Text mediumLabel>Amount</Text>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} lg={12} md={12} spacing={2} mt={'1px'} p="0px 12px">
                                                {balanceAmount?.overdueBreakdown?.map((data, index) => (
                                                    <Box key={index} sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', mt: '16px' }}>
                                                        <Grid container spacing={2} alignItems="center" sx={{cursor: 'pointer'}} onClick={() =>navigate(`/invoice`, {state:{period: `${index + 1}`}})(data.overdue_range)}>
                                                            <Grid item xs={6} lg={9} md={3}>
                                                                <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}>{data.overdue_range}</Text>
                                                            </Grid>
                                                            <Grid item xs={6} lg={3} md={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Text greyColor16>{getCurrencySymbol()} {data.total_amount}</Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: { xs: '5px', sm: '30px' }, height: '100%' }}>
                                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                                            <Grid item container lg={9} md={9} sm={6} xs={6} alignItems="center" justifyContent="flex-start" sx={{ pb: { xs: "24px", sm: '0px' } }}>
                                                <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                                    Leading clients by payments received
                                                    <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Box>
                                                                    <Typography className={classes.profileTooltipText}>
                                                                        This information is based on the invoiced amount
                                                                    </Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <img src={Varient} alt="Varient" style={{ paddingLeft: '5px', cursor: "pointer" }} />
                                                    </HtmlTooltip>
                                                </Text>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={6} xs={6} container justifyContent="flex-end">
                                                <Text smallBlue1 sx={{ cursor: 'pointer' }} onClick={() => setLeadingPopup(true)} >View All</Text>
                                            </Grid>
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item container lg={12} md={12} sm={12} xs={12} p={2}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center">
                                                    <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                        <Text mediumLabel>S.no</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={3.5} sm={5} xs={3} container>
                                                        <Text mediumLabel>Client</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={2.5} sm={3} xs={5} container>
                                                        <Text mediumLabel>Placement</Text>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={3} xs={2} container justifyContent="flex-end">
                                                        <Text mediumLabel>Payment Received</Text>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ width: '100%', margin: '20px 0px 20px 0px', borderColor: '#EAECF0' }} />

                                                {clientDetails?.leadingClients?.length > 0 ? (
                                                    clientDetails.leadingClients.slice(0, 5)?.map((eachClient, index) => (
                                                        <React.Fragment key={index}>
                                                            <Grid container spacing={2} alignItems="center" pl={1}>
                                                                <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                                    <Text black400>{index + 1}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={3.5} sm={5} xs={3} container gap={1} alignItems="center">
                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                        <Avatar src={eachClient?.client_logo == ''
                                                                            || eachClient?.client_logo == null ?
                                                                            capitalizeAndAddSpace(eachClient?.client_name[0]) :
                                                                            eachClient?.client_logo} alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                            sx={{
                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                            }} />
                                                                            <HtmlTooltip
                                                                                placement="bottom"
                                                                                arrow
                                                                                title={
                                                                                    <React.Fragment>
                                                                                    <Box>
                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                        {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    </React.Fragment>
                                                                                }
                                                                                >
                                                                                <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                    }}
                                                                                />
                                                                            </HtmlTooltip>
                                                                    </Box>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.client_name}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={2.5} sm={3} xs={5} container>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.total_placements}</Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={2} container justifyContent="flex-end">
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{getCurrencySymbol()} {eachClient?.total_paid_amount}</Text>
                                                                </Grid>
                                                            </Grid>
                                                            {(clientDetails?.leadingClients?.length > 5 && index !== 4) || (clientDetails?.leadingClients?.length <= 5 && index !== clientDetails?.leadingClients?.length - 1) ? (
                                                                <Divider sx={{ width: '100%', margin: '20px 0px 20px 0px', borderColor: '#EAECF0' }} />
                                                            ) : null}

                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    NoDataFound()
                                                )}
                                            </Grid>
                                        </Hidden>
                                        <Hidden smUp>
                                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center">
                                                    <TableContainer component={Paper} sx={{ padding: '0' }}>
                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">S.no</TableCell>
                                                                    <TableCell align="left">Client</TableCell>
                                                                    <TableCell align="right">Placement</TableCell>
                                                                    <TableCell align="right">Payment Received</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {clientDetails?.leadingClients?.length > 0 ? (
                                                                    clientDetails.leadingClients.slice(0, 5)?.map((eachClient, index) => (
                                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell align="left">{index + 1}</TableCell>
                                                                            <TableCell align="left">
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                                        <Avatar
                                                                                            src={eachClient?.client_logo === '' || eachClient?.client_logo === null
                                                                                                ? capitalizeAndAddSpace(eachClient?.client_name[0])
                                                                                                : eachClient?.client_logo}
                                                                                            alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                                            sx={{
                                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                                            }}
                                                                                        />
                                                                                        <HtmlTooltip
                                                                                            placement="bottom"
                                                                                            arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                <Box>
                                                                                                    <Typography className={classes.profileTooltipText}>
                                                                                                    {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            >
                                                                                            <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                                }}
                                                                                            />
                                                                                        </HtmlTooltip>
                                                                                    </Box>
                                                                                    <Typography sx={{ marginLeft: '8px' }}>{eachClient?.client_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align="right">{eachClient?.total_placements}</TableCell>
                                                                            <TableCell align="right">{getCurrencySymbol()} {eachClient?.total_paid_amount}</TableCell>

                                                                        </TableRow>
                                                                    ))
                                                                ) : (
                                                                    NoDataFound()
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Hidden>

                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F' }}>
                                <Text largeBlack>Invoices & Payment Summary</Text>
                                <ReactApexChart
                                    key={JSON.stringify(amountGraph.series)}
                                    options={amountGraphOptions}
                                    series={amountGraph.series}
                                    type="area"
                                    height="300px"
                                />
                                <Grid item container xs={12} lg={12} md={12} pb={2} alignItems="center" justifyContent="flex-end">
                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center" mr={4}>
                                        <Box sx={{ background: '#0C75EB', height: '10px', width: '10px', borderRadius: '50%' }} />
                                        <Text employeeAdded>Invoices Raised </Text>
                                    </Box>
                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center" mr={2}>
                                        <Box sx={{ background: '#16A34A', height: '10px', width: '10px', borderRadius: '50%' }} />
                                        <Text employeeAdded>Payments Received </Text>
                                    </Box>


                                </Grid>
                            </Grid>

                            <Grid item container xs={12} lg={12} md={12} spacing={2} maxHeight={'100%'} columnSpacing={4} sx={{ pt: `0px !important`, pl: `0px !important` }}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', height: '100%', padding:{ xs: '5px', sm: '30px' },}}>
                                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                <Grid item container lg={9} md={9} sm={6} xs={6} alignItems="center" justifyContent="flex-start" sx={{ pb: { xs: "24px", sm: '0px' } }}>
                                                    <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                                        Client Overdue Accounts
                                                        <HtmlTooltip
                                                            placement="bottom"
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Box>
                                                                        <Typography className={classes.profileTooltipText}>
                                                                            This information is based on the Overdue invoices amount                                                    </Typography>
                                                                    </Box>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <img src={Varient} alt="Varient" style={{ paddingLeft: '5px', cursor: "pointer" }} />
                                                        </HtmlTooltip>
                                                    </Text>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={6} xs={6} container justifyContent="flex-end">
                                                    <Text smallBlue1 sx={{ cursor: 'pointer' }} onClick={() => setOverduePopup(true)} >View All</Text>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item container lg={12} md={12} sm={12} xs={12} p={2}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} pl={1} spacing={2} alignItems="center">
                                                    <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                        <Text mediumLabel>S.no</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={3.5} sm={5} xs={3} container>
                                                        <Text mediumLabel>Client</Text>
                                                    </Grid>
                                                    <Grid item lg={4} md={2.5} sm={3} xs={5} container>
                                                        <Text mediumLabel>Placement</Text>
                                                    </Grid>
                                                    <Grid item lg={3} md={4} sm={3} xs={2} container justifyContent="flex-end">
                                                        <Text mediumLabel>Overdue Amount</Text>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ width: '100%', margin: '20px 0px 20px 0px', borderColor: '#EAECF0' }} />
                                                {clientDetails?.overdueClients?.length > 0 ? (
                                                    clientDetails.overdueClients.slice(0, 5)?.map((eachClient, index) => (
                                                        <React.Fragment key={index}>
                                                            <Grid container spacing={2} alignItems="center" pl={1}>
                                                                <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                                    <Text black400>{index + 1}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={3.5} sm={5} xs={3} container gap={1} alignItems="center">
                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                        <Avatar src={eachClient?.client_logo == ''
                                                                            || eachClient?.client_logo == null ?
                                                                            capitalizeAndAddSpace(eachClient?.client_name[0]) :
                                                                            eachClient?.client_logo} alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                            sx={{
                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                            }} />
                                                                            <HtmlTooltip
                                                                                placement="bottom"
                                                                                arrow
                                                                                title={
                                                                                    <React.Fragment>
                                                                                    <Box>
                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                        {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    </React.Fragment>
                                                                                }
                                                                                >
                                                                                <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                    }}
                                                                                />
                                                                            </HtmlTooltip>
                                                                    </Box>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.client_name}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={2.5} sm={3} xs={5} container>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.total_placements}</Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={2} container justifyContent="flex-end">
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{getCurrencySymbol()} {eachClient?.total_overdue_amount}</Text>
                                                                </Grid>
                                                            </Grid>

                                                            {/* Only render Divider if this is not the last item */}
                                                            {(clientDetails?.overdueClients?.length > 5 && index !== 4) || (clientDetails?.overdueClients?.length <= 5 && index !== clientDetails?.overdueClients?.length - 1) ? (
                                                                <Divider sx={{ width: '100%', margin: '20px 0px 20px 0px', borderColor: '#EAECF0' }} />
                                                            ) : null}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    NoDataFound()
                                                )}
                                            </Grid>
                                        </Hidden>
                                        <Hidden smUp>
                                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center">
                                                    <TableContainer component={Paper} sx={{ padding: '0' }}>
                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">S.no</TableCell>
                                                                    <TableCell align="left">Client</TableCell>
                                                                    <TableCell align="right">Placement</TableCell>
                                                                    <TableCell align="right">Overdue Amount</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {clientDetails?.overdueClients?.length > 0 ? (
                                                                    clientDetails.overdueClients.slice(0, 5)?.map((eachClient, index) => (
                                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell align="left">{index + 1}</TableCell>
                                                                            <TableCell align="left">
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                                        <Avatar
                                                                                            src={eachClient?.client_logo === '' || eachClient?.client_logo === null
                                                                                                ? capitalizeAndAddSpace(eachClient?.client_name[0])
                                                                                                : eachClient?.client_logo}
                                                                                            alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                                            sx={{
                                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                                            }}
                                                                                        />
                                                                                        <HtmlTooltip
                                                                                            placement="bottom"
                                                                                            arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                <Box>
                                                                                                    <Typography className={classes.profileTooltipText}>
                                                                                                    {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            >
                                                                                            <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                                }}
                                                                                            />
                                                                                        </HtmlTooltip>
                                                                             </Box>
                                                                                    <Typography sx={{ marginLeft: '8px' }}>{eachClient?.client_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align="right">{eachClient?.total_placements}</TableCell>
                                                                            <TableCell align="right">{getCurrencySymbol()} {eachClient?.total_overdue_amount}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                ) : (
                                                                    NoDataFound()
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6} sm={12}>
                                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '30px', height: '100%' }}>
                                        <Grid item container lg={12} md={12} sm={12} xs={12} pb={'55px'}>
                                            <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                                Expected Income vs Actual Income
                                                <HtmlTooltip
                                                    placement="bottom"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Typography className={classes.profileTooltipText}>
                                                                    This chart displays the expected vs. actual cash flow for the selected time period, helping you track deviations and assess financial performance.
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img src={Varient} alt="Varient" style={{ paddingLeft: '5px', cursor: "pointer" }} />
                                                </HtmlTooltip>
                                            </Text>
                                        </Grid>
                                        <ReactApexChart
                                            key={JSON.stringify(incomeGraph.series)}
                                            options={incomeGraphOptions}
                                            series={incomeGraph.series}
                                            type="line"
                                            height="345px"
                                        />
                                        <Grid item container xs={12} lg={12} md={12} alignItems="center" justifyContent="flex-end" >

                                            <Box display="flex" flexDirection="row" gap={1} alignItems="center" mr={2}>
                                                <Box sx={{ background: '#0C75EB', height: '10px', width: '10px', borderRadius: '50%' }} />
                                                <Text employeeAdded>Expected Income</Text>
                                            </Box>
                                            <Box display="flex" flexDirection="row" gap={1} alignItems="center" mr={1}>
                                                <Box sx={{ background: '#14C9C9', height: '10px', width: '10px', borderRadius: '50%' }} />
                                                <Text employeeAdded>Actual Income</Text>
                                            </Box>


                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            <ReusablePopup openPopup={leadingPopup} setOpenPopup={setLeadingPopup} white iconHide>
                                <Box sx={{ borderRadius: '8px', padding: '15px' }}>
                                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                                        <Grid item container lg={9} md={9} sm={6} xs={12} alignItems="center" justifyContent="flex-start" sx={{ pb: { xs: '24px', sm: '0px' } }}>
                                            <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                                Leading clients by payments received
                                                <HtmlTooltip
                                                    placement="bottom"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Typography className={classes.profileTooltipText}>
                                                                    This information is based on the invoiced amount
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img src={Varient} alt="Varient" style={{ paddingLeft: '5px', cursor: 'pointer' }} />
                                                </HtmlTooltip>
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    {/* Data Table in Popup for larger screens */}
                                    <Hidden smDown>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} p={2}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} pl={1} spacing={2} alignItems="center">
                                                <Grid item lg={1.5} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                    <Text mediumLabel>S.no</Text>
                                                </Grid>
                                                <Grid item lg={3.5} md={4} sm={5} xs={3} container>
                                                    <Text mediumLabel>Client</Text>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={5} container>
                                                    <Text mediumLabel>Placement</Text>
                                                </Grid>
                                                <Grid item lg={4} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                                    <Text mediumLabel>Payment Received</Text>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ width: '100%', margin: '20px 0px', borderColor: '#EAECF0' }} />

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ overflowY: 'scroll' ,height: '300px'}}>
                                                {clientDetails?.leadingClients?.length > 0 ? (
                                                    clientDetails.leadingClients.map((eachClient, index) => (
                                                        <React.Fragment key={index}>
                                                            <Grid container spacing={2} alignItems="center" pl={1}>
                                                                <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                                    <Text black400>{index + 1}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={3.5} sm={5} xs={3} container gap={1} alignItems="center">
                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                        <Avatar
                                                                            src={eachClient?.client_logo || capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                            alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                            sx={{
                                                                                height: '35px',
                                                                                width: '35px',
                                                                                borderRadius: '50%',
                                                                                background: eachClient?.client_logo ? '' : '#e5b256',
                                                                            }}
                                                                        />
                                                                        <HtmlTooltip
                                                                            placement="bottom"
                                                                            arrow
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <Box>
                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                            {eachClient?.client_status === "Active" ? "Active client" : "Inactive client"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </React.Fragment>
                                                                            }
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    backgroundColor: eachClient?.client_status === "Active" ? "#16A34A" : "#494747",
                                                                                    bottom: "-1px",
                                                                                    right: '0px',
                                                                                    border: '2px solid white',
                                                                                    borderRadius: '50%',
                                                                                    width: '13px',
                                                                                    height: '13px',
                                                                                    display: 'block',
                                                                                    position: 'absolute',
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </HtmlTooltip>
                                                                    </Box>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.client_name}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={4} sm={3} xs={5} container>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>
                                                                        {eachClient?.total_placements}
                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>
                                                                        {getCurrencySymbol()} {eachClient?.total_paid_amount}
                                                                    </Text>
                                                                </Grid>
                                                            </Grid>

                                                            {/* Only render Divider if this is not the last item */}
                                                            {index !== clientDetails?.leadingClients?.length - 1 && (
                                                                <Divider sx={{ width: '100%', margin: '20px 0px', borderColor: '#EAECF0' }} />
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    NoDataFound()
                                                )}
                                            </Grid>

                                            <Grid item xs={12} lg={12} md={12} mt={2} textAlign="end" pr={1}>
                                                <Button saveBtn400 onClick={() => setLeadingPopup(false)}>Done</Button>
                                            </Grid>
                                        </Grid>

                                    </Hidden>

                                    {/* Data Table in Popup for smaller screens */}
                                    <Hidden smUp>
                                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center">
                                                <TableContainer component={Paper} sx={{ padding: '0' }}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">S.no</TableCell>
                                                                <TableCell align="left">Client</TableCell>
                                                                <TableCell align="right">Placement</TableCell>
                                                                <TableCell align="right">Payment Received</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {clientDetails?.leadingClients?.length > 0 ? (
                                                                clientDetails.leadingClients.map((eachClient, index) => (
                                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                        <TableCell align="left">{index + 1}</TableCell>
                                                                        <TableCell align="left">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                                        <Avatar
                                                                                            src={eachClient?.client_logo === '' || eachClient?.client_logo === null
                                                                                                ? capitalizeAndAddSpace(eachClient?.client_name[0])
                                                                                                : eachClient?.client_logo}
                                                                                            alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                                            sx={{
                                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                                            }}
                                                                                        />
                                                                                        <HtmlTooltip
                                                                                            placement="bottom"
                                                                                            arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                <Box>
                                                                                                    <Typography className={classes.profileTooltipText}>
                                                                                                    {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            >
                                                                                            <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                                }}
                                                                                            />
                                                                                        </HtmlTooltip>
                                                                                    </Box>
                                                                                <Typography sx={{ marginLeft: '8px' }}>{eachClient?.client_name}</Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right">{eachClient?.total_placements}</TableCell>
                                                                        <TableCell align="right">{getCurrencySymbol()} {eachClient?.total_paid_amount}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                NoDataFound()
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} mt={2} textAlign="end" pr={1}>
                                            <Button saveBtn400 onClick={() => setLeadingPopup(false)}>Done</Button>
                                        </Grid>
                                    </Hidden>
                                </Box>
                            </ReusablePopup>
 
                            <ReusablePopup openPopup={overduePopup} setOpenPopup={setOverduePopup} white iconHide>
                                <Box sx={{ borderRadius: '8px', padding: '15px' }}>
                                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                                        <Grid item container lg={9} md={9} sm={6} xs={12} alignItems="center" justifyContent="flex-start" sx={{ pb: { xs: '24px', sm: '0px' } }}>
                                            <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                                Client Overdue Accounts
                                                <HtmlTooltip
                                                    placement="bottom"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Typography className={classes.profileTooltipText}>
                                                                    This information is based on the overdue invoices amount
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img src={Varient} alt="Varient" style={{ paddingLeft: '5px', cursor: "pointer" }} />
                                                </HtmlTooltip>
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    {/* Desktop view */}
                                    <Hidden smDown>
                                        <Grid item container lg={12} md={12} sm={12} xs={12} p={2}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} pl={1} spacing={2} alignItems="center">
                                                <Grid item lg={1.5} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                    <Text mediumLabel>S.no</Text>
                                                </Grid>
                                                <Grid item lg={3.5} md={4} sm={5} xs={3} container>
                                                    <Text mediumLabel>Client</Text>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={5} container>
                                                    <Text mediumLabel>Placement</Text>
                                                </Grid>
                                                <Grid item lg={4} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                                    <Text mediumLabel>Overdue Amount</Text>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ width: '100%', margin: '20px 0px', borderColor: '#EAECF0' }} />
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ overflowY: 'scroll' ,height: '300px'}}>

                                            {clientDetails?.overdueClients?.length > 0 ? (
                                                clientDetails.overdueClients.map((eachClient, index) => (
                                                    <React.Fragment key={index}>
                                                        <Grid container spacing={2} alignItems="center" pl={1}>
                                                            <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                                                <Text black400>{index + 1}</Text>
                                                            </Grid>
                                                            <Grid item lg={4} md={3.5} sm={5} xs={3} container gap={1} alignItems="center">
                                                                    <Box sx={{ position: 'relative', width: "35px", height: "35px" }}>
                                                                        <Avatar src={eachClient?.client_logo == ''
                                                                            || eachClient?.client_logo == null ?
                                                                            capitalizeAndAddSpace(eachClient?.client_name[0]) :
                                                                            eachClient?.client_logo} alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                            sx={{
                                                                                height: '35px', width: '35px', borderRadius: '50% !important',
                                                                                background: eachClient?.client_logo ? '' : '#e5b256 !important'
                                                                            }} />
                                                                            <HtmlTooltip
                                                                                placement="bottom"
                                                                                arrow
                                                                                title={
                                                                                    <React.Fragment>
                                                                                    <Box>
                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                        {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    </React.Fragment>
                                                                                }
                                                                                >
                                                                                <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                    }}
                                                                                />
                                                                            </HtmlTooltip>
                                                                    </Box>
                                                                    <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>{eachClient?.client_name}</Text>
                                                                </Grid>
                                                            <Grid item lg={4} md={4} sm={3} xs={5} container>
                                                                <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>
                                                                    {eachClient?.total_placements}
                                                                </Text>
                                                            </Grid>
                                                            <Grid item lg={3} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                                                <Text offBoardHeadFont sx={{ paddingBottom: '0px !important' }}>
                                                                {getCurrencySymbol()} {eachClient?.total_overdue_amount}
                                                                </Text>
                                                            </Grid>
                                                        </Grid>

                                                        {/* Only render Divider if this is not the last item */}
                                                        {index !== clientDetails?.overdueClients?.length - 1 && (
                                                            <Divider sx={{ width: '100%', margin: '20px 0px', borderColor: '#EAECF0' }} />
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Grid container item justifyContent="center" height={'31.5vh'}>
                                                    {NoDataFound()}
                                                </Grid>
                                            )}

                                        </Grid>

                                        </Grid>

                                        <Grid item xs={12} lg={12} md={12} mt={2} textAlign="end" pr={1}>
                                            <Button saveBtn400 onClick={() => setOverduePopup(false)}>Done</Button>
                                        </Grid>
                                    </Hidden>

                                    {/* Mobile view */}
                                    <Hidden smUp>
                                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center">
                                                <TableContainer component={Paper} sx={{ padding: '0' }}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">S.no</TableCell>
                                                                <TableCell align="left">Client</TableCell>
                                                                <TableCell align="right">Placement</TableCell>
                                                                <TableCell align="right">Overdue Amount</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {clientDetails?.overdueClients?.length > 0 ? (
                                                                clientDetails.overdueClients.map((eachClient, index) => (
                                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                        <TableCell align="left">{index + 1}</TableCell>
                                                                        <TableCell align="left">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Box sx={{ position: 'relative', width: '35px', height: '35px' }}>
                                                                                    <Avatar
                                                                                        src={eachClient?.client_logo || capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                                        alt={capitalizeAndAddSpace(eachClient?.client_name[0])}
                                                                                        sx={{
                                                                                            height: '35px',
                                                                                            width: '35px',
                                                                                            borderRadius: '50% !important',
                                                                                            background: eachClient?.client_logo ? '' : '#e5b256 !important',
                                                                                        }}
                                                                                    />
                                                                                    <HtmlTooltip
                                                                                placement="bottom"
                                                                                arrow
                                                                                title={
                                                                                    <React.Fragment>
                                                                                    <Box>
                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                        {eachClient?.client_status == "Active" ? "Active client" : "Inactive client"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    </React.Fragment>
                                                                                }
                                                                                >
                                                                                <Box sx={{backgroundColor: eachClient?.client_status == "Active" ? "#16A34A" : "#494747", bottom: "-1px", right: '0px', border: '2px solid white', borderRadius: '50%', width: '13px', height: '13px', display: 'block', position: 'absolute', cursor: "pointer",  // typo fix: 'cousor' -> 'cursor'
                                                                                    }}
                                                                                />
                                                                            </HtmlTooltip>
                                                                                </Box>
                                                                                <Typography sx={{ marginLeft: '8px' }}>
                                                                                    {eachClient?.client_name}
                                                                                </Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right">{eachClient?.total_placements}</TableCell>
                                                                        <TableCell align="right">{getCurrencySymbol()} {eachClient?.total_overdue_amount}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                NoDataFound()
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} lg={12} md={12} mt={2} textAlign="end" pr={1}>
                                            <Button saveBtn400 onClick={() => setOverduePopup(false)}>Done</Button>
                                        </Grid>
                                    </Hidden>
                                </Box>
                            </ReusablePopup>



                        </Grid>
                        : NoDataFound()
                    }</>}
        </>

    )
}

export default InvoiceDashboard
