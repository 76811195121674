import { Box, Grid } from "@mui/material";
import Input from "../../components/input/Input";
import Text from "../../components/customText/Text";
import { onNumberOnlyChange } from "../../components/Validation";
import { manualOnboardDefaultConfig } from "../../config/pageConfig/EmployerModuleConfig";


export default function Zipcode(props) {

    const { state, error, handleChange, columnLength, isEditable, org, apiConfig } = props

    const config = apiConfig ? apiConfig : manualOnboardDefaultConfig?.generalDetails?.currentAddressDetails?.location
    return (
        <>
            {config?.zip_code?.isVisible &&
                <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                    <Box p={org ? 0 : 1} pt={org ? 0 : 2}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'zip_code',
                                value: state.zip_code,
                                inputProps: { maxLength: config?.zip_code?.maxLength }
                            }}
                            handleChange={handleChange}
                            clientInput
                            onKeyPress={onNumberOnlyChange}
                            disabled={isEditable ? isEditable : config?.zip_code?.isDisabled}
                            error={error.zip_code}
                            labelText={(state?.zip_code?.length == config?.zip_code?.zipcodeLength ? config?.zip_code?.zipcodeLabel : state?.zip_code?.length == config?.zip_code?.pincodeLenght ? config?.zip_code?.pincodeLabel : config?.zip_code?.label) + (config?.zip_code?.required ? '' : '')}
                            placeholder={config?.zip_code?.placeholder}
                        />
                        {error.zip_code && <Text red>{error.zip_code ? error.zip_code : ''}</Text>}
                    </Box>
                </Grid>}
            {config?.city?.isVisible &&
                <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                    <Box p={org ? 0 : 1} pt={org ? 0 : 2}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'city',
                                value: state.city || '',
                                inputProps: { maxLength: config?.city?.maxLength }
                            }}
                            handleChange={handleChange}
                            clientInput
                            error={error.city}
                            disabled={isEditable ? isEditable : config?.city?.isDisabled}
                            labelText={config?.city?.required ? config?.city?.label : (config?.city?.hasOwnProperty('required') ? config?.city?.label + ' (Optional)' : config?.city?.label)}
                            placeholder={config?.city?.placeholder}
                        />
                        {error.city && <Text red>{error.city ? error.city : ''}</Text>}
                    </Box>
                </Grid>}
            {config?.state?.isVisible &&
                <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                    <Box p={org ? 0 : 1} pt={org ? 0 : 2}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'state_name',
                                value: state.state_name || '',
                            }}
                            disabled={true || config?.state?.isDisabled}
                            clientInput
                            labelText={config?.state?.label}
                            placeholder={config?.state?.placeholder}
                        />
                    </Box>
                </Grid>}
            {
                config?.country?.isVisible &&
                <Grid item lg={columnLength} md={columnLength} sm={6} xs={12}>
                    <Box p={org ? 0 : 1} pt={org ? 0 : 2}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'country_name',
                                value: state.country_name || '',
                            }}
                            disabled={true || config?.country?.isDisabled}
                            clientInput
                            labelText={config?.country?.label}
                            placeholder={config?.country?.placeholder}
                        />
                    </Box>
                </Grid>}
        </>
    )
}