import React, { useState } from "react";
import {
  Box,
  IconButton,
  Avatar,
  Checkbox,
  Collapse,
  Divider,
  Dialog,
  Slide,
  Grid,
  Stack
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  CheckCircle,
  WatchLater,
} from "@mui/icons-material";
import Text from "../../../components/customText/Text";
import TimesheetApi from "../../../apis/admin/timesheets/TimesheetApi";
import CustomCalendars from "../../../components/customCalendar/CustomCalendars";
import moment from "moment";
import { dateFormat } from "../../../utils/utils";
import dayjs from 'dayjs';
import { styled } from "@mui/material/styles";
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import InfoIcon from '../../../assets/svg/Information.svg';
import Search from '../../../assets/svg/search1.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";

const EmployeeCard = (props) => {  // eslint-disable-next-line
  const { state, setState, clientDetailsAPI, employeeDetails } = props
  const navigate = useNavigate()
  const [openIndexes, setOpenIndexes] = useState(null);
  const [open, setOpen] = useState(false);
  const [calendarDetails, setCalendarDetails] = useState('');
  const [startDate, setstartDate] = useState(dayjs().format(dateFormat()));
  const [data, setData] = useState('');
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
      borderRadius: "16px",
      width: "auto"
    }
  }));// eslint-disable-next-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });// eslint-disable-next-line

  const handleToggle = (index) => {
    if (index == openIndexes) {
      setOpenIndexes(null);
    }
    else {
      setOpenIndexes(index);
    }
  };

  const timeSheetSummaryOpen = (filterData, id) => {
    const date = moment(filterData.start_date, "MMM DD 'YY");
    const start_date = moment(filterData.start_date, "MMM DD 'YY").format(dateFormat());
    const end_date = moment(filterData.end_date, "MMM DD 'YY").format(dateFormat());
    const month = date.format("M"); // Ensure it's 1-based
    const year = date.format("YYYY");
    // const weekOfMonth = date.week() - moment(date).startOf('month').week() + 1;
    var payloadData = {
      from_date: start_date,
      to_date: end_date,
      id: id,
      year: year,
      month: month,
      weekly: false,
      // weekStart: weekOfMonth == 1 ? 0 : weekOfMonth == 2 ? 8 : weekOfMonth == 3 ? 16 : weekOfMonth == 4 ? 24 : weekOfMonth == 5 ? 32 : weekOfMonth == 6 ? 40 : 48,
      // weekEnd: weekOfMonth == 1 ? 7 : weekOfMonth == 2 ? 15 : weekOfMonth == 3 ? 23 : weekOfMonth == 4 ? 31 : weekOfMonth == 5 ? 39 : weekOfMonth == 6 ? 47 : 55,
      weekStart: 48,
      weekEnd: 55,
      hours: filterData.hours
    };

    setData(payloadData)
    setstartDate(dayjs(filterData.start_date, "MMM DD 'YY"));

    TimesheetApi.timesheetcalendarView(payloadData).then((response) => {
      if (response.data.statusCode == 1003) {
        setOpen(true);
        setCalendarDetails(response.data.data[0].timesheet);
      }
    });
  };

  const calculateValue = (arr, keyName) => {
    return arr.reduce((total, item) => total + item[keyName], 0);
  };

  const handleTable = (e, details, type) => {
    let tableData = [...state.item_details]
    let findData = tableData.find((i) => i.id == details.id)
    if (type == 'selectAll') {
      if (e.target.checked) {
        let arr = tableData.filter((i) => i.employee_id == '') || []
        employeeDetails.forEach((item, index) => {
          var newObj = {
            id: item.id,
            item_id: '',
            employee_id: item.employee_id,
            placement_id: item.placement_id,
            name: item.employee_name,
            profile_picture_url: item.profile_picture_url,
            description: `${item.from_date} - ${item.to_date}`,
            ot_hours: item.timesheets.length > 0 ? calculateValue(item.timesheets, 'ot_hours') : 0,
            hours: item.timesheets.length > 0 ? calculateValue(item.timesheets, 'billable_hours') : 0,
            bill_rate: item.timesheets.length > 0 ? item.timesheets[0].bill_rate : 0,
            ot_bill_rate: item.timesheets.length > 0 ? item.timesheets[0].ot_bill_rate : 0,
            amount: item.timesheets.length > 0 ? calculateValue(item.timesheets, 'total_amount') : 0,
            timesheet_ids: item.timesheets.length > 0 ? item.timesheets.map(items => items.timesheet_id) : [],
            timesheet_hour_ids: item.timesheets.length > 0 ? item.timesheets.map(items => items.timesheet_hour_ids).flat() : []
          }
          arr.push(newObj)
        })
        setState({
          ...state,
          item_details: arr
        })
      }
      else {
        let arr = tableData.filter((i) => i.employee_id == '') || []
        setState({
          ...state,
          item_details: arr
        })
      }
    }
    else {
      if (findData) {
        tableData = tableData.filter((i) => i.id !== details.id);
        setState({
          ...state,
          item_details: tableData
        })
      }
      else {
        var newObj = {
          id: details.id,
          item_id: '',
          employee_id: details.employee_id,
          placement_id: details.placement_id,
          name: details.employee_name,
          profile_picture_url: details.profile_picture_url,
          description: `${details.from_date} - ${details.to_date}`,
          ot_hours: details.timesheets.length > 0 ? calculateValue(details.timesheets, 'ot_hours') : 0,
          hours: details.timesheets.length > 0 ? calculateValue(details.timesheets, 'billable_hours') : 0,
          bill_rate: details.timesheets.length > 0 ? details.timesheets[0].bill_rate : 0,
          ot_bill_rate: details.timesheets.length > 0 ? details.timesheets[0].ot_bill_rate : 0,
          amount: details.timesheets.length > 0 ? calculateValue(details.timesheets, 'total_amount') : 0,
          timesheet_ids: details.timesheets.length > 0 ? details.timesheets.map(item => item.timesheet_id) : [],
          timesheet_hour_ids: details.timesheets.length > 0 ? details.timesheets.map(items => items.timesheet_hour_ids).flat() : []
        }
        tableData.push(newObj)
        setState({
          ...state,
          item_details: tableData
        })
      }
    }

  }

  const changeItemDetails = (e, timesheet, user, index) => {
    let tableData = [...state.item_details]
    let findData = tableData.find((i) => i.id == user.id)
    if (e.target.checked) {
      if (!findData) {
        var newObj = {
          id: user.id,
          item_id: '',
          employee_id: user.employee_id,
          placement_id: user.placement_id,
          name: user.employee_name,
          profile_picture_url: user.profile_picture_url,
          description: `${user.from_date} - ${user.to_date}`,
          ot_hours: timesheet.ot_hours,
          hours: timesheet.billable_hours,
          bill_rate: timesheet.bill_rate,
          ot_bill_rate: timesheet.ot_bill_rate,
          amount: timesheet.total_amount,
          timesheet_ids: [timesheet.timesheet_id],
          timesheet_hour_ids: timesheet.timesheet_hour_ids
        }
        tableData.push(newObj)
        setState({
          ...state,
          item_details: tableData
        })
      }
      else {
        let tableData = state.item_details.map(item => {
          if (item.id === user.id) {
            // Modify the matching item
            let array = item.timesheet_ids.length > 0 ? [...item.timesheet_ids] : []
            let arrayTimesheet = item.timesheet_hour_ids.length > 0 ? [...item.timesheet_hour_ids,...timesheet.timesheet_hour_ids] : []
            array.push(timesheet.timesheet_id)
            let updatedItem = {
              ...item,
              timesheet_ids: array,
              timesheet_hour_ids: arrayTimesheet,
              amount: Math.max(0, item.amount + ((timesheet.billable_hours * item.bill_rate) + (timesheet.ot_hours * item.ot_bill_rate))),
              ot_hours: Math.max(0, item.ot_hours + timesheet.ot_hours),
              hours: Math.max(0, item.hours + timesheet.billable_hours),
            };
            return updatedItem;
          }
          return item;
        });
        setState({
          ...state,
          item_details: tableData
        })
      }
    }
    else {
      if (findData) {
        if (user.timesheets.length == 1 || findData.timesheet_ids.length == 1) {
          let filterData = tableData.filter((i) => i.id != user.id)
          setState({
            ...state,
            item_details: filterData
          })
        }
        else {
          let tableData = state.item_details.map(item => {
            if (item.id === user.id) {
              // Modify the matching item
              let updatedItem = {
                ...item,
                timesheet_ids: item.timesheet_ids.filter(id => id !== timesheet.timesheet_id),
                timesheet_hour_ids: item.timesheet_hour_ids.length > 0 ? item.timesheet_hour_ids.filter(i => !timesheet.timesheet_hour_ids.includes(i)) : [],
                amount: Math.max(0, item.amount - ((timesheet.billable_hours * item.bill_rate) + (timesheet.ot_hours * item.ot_bill_rate))),
                ot_hours: Math.max(0, item.ot_hours - timesheet.ot_hours),
                hours: Math.max(0, item.hours - timesheet.billable_hours),
              };
              return updatedItem;
            }
            return item;
          });
          setState({
            ...state,
            item_details: tableData
          })
        }
      }
      else {
        let tableData = state.item_details.map(item => {
          if (item.id === user.id) {
            // Modify the matching item
            let timesheetArray = [...timesheet.timesheet_hour_ids]
            let array = item.timesheet_hour_ids.filter(i => !timesheetArray.includes(i)) || []
            let updatedItem = {
              ...item,
              timesheet_ids: item.timesheet_ids.filter(id => id !== timesheet.timesheet_id),
              timesheet_hour_ids: array,
              amount: Math.max(0, item.amount - ((timesheet.billable_hours * item.bill_rate) + (timesheet.ot_hours * item.ot_bill_rate))),
              ot_hours: Math.max(0, item.ot_hours - timesheet.ot_hours),
              hours: Math.max(0, item.hours - timesheet.billable_hours),
            };
            return updatedItem;
          }
          return item;
        });
        setState({
          ...state,
          item_details: tableData
        })
      }

    }
  };

  const checkDetails = (user) => {

    let find = state.item_details.find(item => item.id == user.id)

    if (find) {
      if (find.timesheet_ids.length == user.timesheets.length) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }

  }


  return (
    <Grid container>
      <Grid item xs={12} container alignContent={'center'}>
        <Stack direction={'row'} gap={1} sx={{ background: '#EFF6F1', borderRadius: '5px' }} p={2}>
          <Box pt={1.5}>
            <img src={InfoIcon} alt='img' height={'20px'} width={'20px'} />
          </Box>
          <Text greensmall>
            <strong>Japfu Smart Assist:</strong> Create an invoice for one employee, and we'll automatically draft similar invoices for others working for this client. On completion, these pre-filled drafts will be waiting in your draft section for quick review and final configuration. Make sure to complete the configuration in the selected employee's invoice before saving this invoice.
          </Text>
        </Stack>
      </Grid>
      <Grid item xs={12} container justifyContent={'space-between'} pt={2} pb={1}>
        <Text black14>Employees Timesheets Pending for Invoice</Text>
        {employeeDetails.length == 0 ? '' :
          <Box display={'flex'} flexDirection={'row'}>
            <Checkbox
              onChange={(e) => { handleTable(e, employeeDetails, 'selectAll') }}
              checked={(state.item_details.length > 0 && state.item_details.filter((i) => i.employee_id != '').length) == employeeDetails.length}
              icon={<CheckBorderIcon />}
              checkedIcon={<CheckedIcon />}
            />
            <Text black14 sx={{ pt: '8px' }}>Select All</Text>
          </Box>}
      </Grid>
      <Grid item xs={12}>
        <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%' }}>
          <input
            style={{
              border: "none",
              padding: "0px 0px 0px 10px",
              width: "100%",
              height: "100%",
              background: "transparent",
              color: "rgba(38, 38, 38, 1)",
              fontFamily: "Quicksand",
              fontSize: "12px",
              fontWeight: "400",
              transition: "all .3s ease",
              '&::-webkit-input-placeholder': {
                color: "rgba(199, 204, 211, 1)",
              },
              '&:focus': {
                outline: "none"
              }
            }}
            name="searchInvoice"
            onChange={(e) => { setState({ ...state, search: e.target.value }) }}
            placeholder='Search Employees'
            value={state.search}
          />
          <Box sx={{ paddingRight: "15px !important" }}>
            {
              state.search == '' ?
                <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => setState({ ...state, search: '' })} />
            }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt={1}>
          {
            state.from_date == '' || state.to_date == '' ? <Text black14>Please select an invoice period to view the corresponding employees and their timesheets.</Text> :
              employeeDetails.length == 0 ? <Text black14> No employee was found working for this client during the specified period. Please confirm that the employee was assigned to the client for the stated invoice period.</Text> : employeeDetails.map((user, index) => (
                <Box key={index} sx={{ borderBottom: "1px solid #ddd", borderRadius: 2, mb: 2 }}>
                  {/* Header */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 2,
                    }}
                  >
                    <Box sx={{
                      display: "flex", alignItems: "center",
                      wordBreak: 'break-all',
                      whiteSpace: 'normal'
                    }}>
                      <Checkbox onChange={(e) => { handleTable(e, user) }}
                        checked={checkDetails(user)}
                        // checked={state.item_details.find(item => item.id == user.id).timesheet_ids.length == user.timesheet.length}
                        icon={<CheckBorderIcon />}
                        checkedIcon={<CheckedIcon />}
                      />
                      <Avatar
                        sx={{ width: 40, height: 40, mr: 2 }}
                        alt={user.employee_name}
                        src={user.profile_picture_url}
                      />
                      <Text black14 sx={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal'
                      }} >{user.employee_name}</Text>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Text cardTitle sx={{ color: '#262626 !important' }}>{user.from_date} - {user.to_date}</Text>
                      <IconButton onClick={() => handleToggle(index)}>
                        {index == openIndexes ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Collapsible Content */}
                  <Collapse in={index == openIndexes} timeout="auto" unmountOnExit>
                    <Divider />
                    <Box sx={{ p: 2, pl: 6 }}>
                      {user.timesheets.length > 0 ? (
                        user.timesheets.map((timesheet, tsIndex) => (
                          <Box
                            key={tsIndex}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 2,
                            }}
                          >
                            <Box gap={1} display={'flex'} flexDirection={'row'} pt={0.5}>
                              <Checkbox
                                onChange={(e) => { changeItemDetails(e, timesheet, user, index) }}
                                icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />}
                                checked={state.item_details.some(item =>
                                  item.timesheet_ids && item.timesheet_ids.includes(timesheet.timesheet_id)
                                )} />
                              <Text
                                analyticsViewAll
                                sx={{ pt: 1.3, cursor: 'pointer' }}
                                onClick={() => { timeSheetSummaryOpen(timesheet, user.placement_id) }}
                              >
                                {timesheet.reference_id}
                              </Text>
                            </Box>
                            <Text black14>
                              {timesheet.start_date} - {timesheet.end_date}
                            </Text>
                            <Text black14 sx={{ mx: 2 }}>{timesheet.total_hours} hrs</Text>
                            <Text black14 sx={{ mx: 2 }}>$ {timesheet.total_amount}</Text>
                            {timesheet.status === "Approved" ? (
                              <CheckCircle sx={{ color: "green" }} />
                            ) : (
                              <WatchLater sx={{ color: "orange" }} />
                            )}
                          </Box>
                        ))
                      ) : (
                        <Text black14>No timesheets available</Text>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              ))}
          <BootstrapDialog
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            onClose={() => { setOpen(false) }}
            maxWidth={"lg"}
            width={'100%'}
          >
            <Box p={3}>
              <Grid container pb={3} pt={3}>
                <Grid item xs={6}>
                  <Text black14>Time Sheets</Text>
                </Grid>
                <Grid item container xs={6} justifyContent={'flex-end'}>
                  <Text blue14 onClick={() => { navigate('/timesheet') }} sx={{ cursor: 'pointer' }}>Open Time Sheets Module</Text>
                </Grid>
              </Grid>

              {
                data != '' ?
                  <CustomCalendars calendarDetails={calendarDetails} location={data} value={startDate} show={true} />
                  : ''
              }
            </Box>

          </BootstrapDialog>
        </Box>
      </Grid>

    </Grid>

  );
};

export default EmployeeCard;
