import React, { useEffect, useState } from 'react'
import {  Grid, Skeleton, Stack } from '@mui/material';
import Text from '../../../components/customText/Text';
import { BlackToolTip } from '../../../utils/utils';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';
import FileSvg from '../../../assets/svg/File.svg'
import { AddressSection, createAddressJson, placement_worklocation_address } from '../../admin/placements/PlacementCommon';

function PlacementDetails(props) {

  const placementID = props.id ? props.id : "";

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    job_title_id: "",
    address_line_one: "",
    address_line_two: "",
    city: "",
    state_id: "",
    country_id: "",
    zip_code: "",
    work_email_id: "",
    recruiter_id: "",
    start_date: "",
    end_date: "",
    address: placement_worklocation_address,
    supporting_documents: [],
    delete_supporting_document_ids: [],
  })
  const officeJson = createAddressJson(state.address.office);
  const remoteJson = createAddressJson(state.address.remote);

  useEffect(() => {
    getPlacementData(placementID)// eslint-disable-next-line
  }, []);

  const getPlacementData = (id) => {
    setLoading(true);
    PlacementApi.getPlacementIndexConsultant("placement-details", id).then((res) => {
      if (res.data.statusCode === 1003) {
        // if (res.data.data.placement_details.country_id) {
        //   statesList(res.data.data.placement_details.country_id);
        // }
        // res.data.data.placement_details['delete_supporting_document_ids'] = [];
        setState({ ...res.data.data.placement_details });
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const openInNewTab = (documentUrl) => {
    const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
        <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 2 }} spacing={2} alignItems={"center"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Text largeBlue>Placement Details</Text>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
            {
              loading ?
                <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                :
                <>
                  <Text mediumGreyHeader1>Job Title</Text>
                  <Text mediumBlack14 pt={0.8} >
                    {state.job_title_name === "" ? "--" :
                      state.job_title_name && state.job_title_name.length > 16 ?
                        <BlackToolTip title={state.job_title_name} placement="right" arrow>
                          {state.job_title_name.slice(0, 16) + (state.job_title_name.length > 16 ? "..." : "")}
                        </BlackToolTip>
                        :
                        state.job_title_name
                    }
                  </Text>
                </>
            }
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
            {
              loading ?
                <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                :
                <>
                  <Text mediumGreyHeader1>Work Email Id (Optional)</Text>
                  <Text mediumBlack14 pt={0.8} >
                    {state.work_email_id === "" ? "--" : state.work_email_id && state.work_email_id.length > 40 ?
                      <BlackToolTip title={state.work_email_id} placement="right" arrow>
                        {state.work_email_id.slice(0, 40) + (state.work_email_id.length > 40 ? "..." : "")}
                      </BlackToolTip>
                      :
                      state.work_email_id
                    }
                  </Text>
                </>
            }
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} pt={2}>
            {
              loading ?
                <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                :
                <>
                  <Text mediumGreyHeader1>Recruiter Name (Optional)</Text>
                  <Text mediumBlack14 pt={0.8} >
                    {state.recruiter_name === "" ? "--" : state.recruiter_name && state.recruiter_name.length > 16 ?
                      <BlackToolTip title={state.recruiter_name} placement="right" arrow>
                        {state.recruiter_name.slice(0, 16) + (state.recruiter_name.length > 16 ? "..." : "")}
                      </BlackToolTip>
                      :
                      state.recruiter_name
                    }
                  </Text>
                </>
            }
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
            {
              loading ?
                <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                :
                <>
                  {
                    state.end_date &&
                    <>
                      <Text mediumGreyHeader1>End Date</Text>
                      <Text mediumBlack14 pt={0.8} >{state.end_date ? state.end_date : "--"}</Text>
                    </>
                  }
                </>
            }
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
            <Text largeBlue>Work Location</Text>
            <Text mediumBlack14 pt={0.8} >{state?.work_location_type ? state?.work_location_type : "--"}</Text>
          </Grid>
          {(state.work_location_type === 'In-Office' || state.work_location_type === 'Hybrid') && (
            <AddressSection
              addressHeader="Office address"
              addressType={state.work_location_type}
              addressData={officeJson}
              zipCodeLabel={state.address.office.country_id == 2 ? "Zip Code" : "Pin Code"}
              zipCodeValue={state.address.office.zip_code}
              loading={loading}
            />
          )}

          {(state.work_location_type === 'Remote' || state.work_location_type === 'Hybrid') && (
            <AddressSection
              addressHeader="Remote address"
              addressType={state.work_location_type}
              addressData={remoteJson}
              zipCodeLabel={state.address.remote.country_id == 2 ? "Zip Code" : "Pin Code"}
              zipCodeValue={state.address.remote.zip_code}
              loading={loading}
            />
          )}
          <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
            <Text largeBlue>Supporting Documents</Text>
          </Grid>
          {
            loading ?
              [1, 2, 3].map((item) => (
                <Grid item lg={3} md={3} sm={3} pt={2}>
                  <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                </Grid>
              ))
              :
              <>
                {
                  state.supporting_documents.length > 0 && state.supporting_documents.map((item, index) => (
                    <Grid item lg={4} md={4} sm={4} pt={4}>
                      <Text mediumGreyHeader1>Supporting Document -{index + 1} (Optional) </Text>
                      <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item.document_url) }}>
                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                        <Text mediumBlue sx={{ font: "14px Quicksand !important", fontWeight: `${600} !important`, paddingLeft: "5px" }} >
                          <BlackToolTip arrow placement='top' title={item.document_name}>
                            {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                          </BlackToolTip>
                        </Text>

                      </Stack>
                    </Grid>
                  ))
                }
              </>
          }
        </Grid>
  )
}

export default PlacementDetails