import APIURL from "../../config/development";
import LocalStorage from "../../utils/LocalStorage";
import BaseApi from "../BaseApi";

class InvoiceAPI {
    getClientDetails(data) {
        return BaseApi.getWithParams(APIURL.API_URL + `invoice/client-details?request_id=${LocalStorage.uid()}&client_id=${data}`, LocalStorage.getAccessToken());
    }
    getEmployeeTimesheet(data) {
        return BaseApi.getWithParams(APIURL.API_URL + `invoice/employee-timesheets?request_id=${LocalStorage.uid()}&client_id=${data.client_id}&from_date=${data.from_date}&to_date=${data.to_date}&search=${data.search}`, LocalStorage.getAccessToken());
    }
    getActivity(data) {
        return BaseApi.getWithParams(APIURL.API_URL + `invoice/activity-listing?request_id=${LocalStorage.uid()}&client_id=${data.client_id}`, LocalStorage.getAccessToken());
    }
    storeInvoice(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + 'invoice/store', data, LocalStorage.getAccessToken())
    }
    updateInvoice(data) {
        return BaseApi.putWithToken(APIURL.employee_URL + 'invoice/update', data, LocalStorage.getAccessToken())
    }
    invoicePdfViewAPi(params) {
        let { invoice_id, request_id } = params
        return BaseApi.getWithParams(APIURL.employee_URL + `invoice/index?request_id=${request_id}&invoice_id=${invoice_id}`, LocalStorage.getAccessToken());
    }
    getTemplate(id){
        return BaseApi.getWithParams(APIURL.API_URL + `invoice/get-template?request_id=${LocalStorage.uid()}&invoice_id=${id}&slug=send-mail`, LocalStorage.getAccessToken());
    }
    invoiceDownload(data) {
        let { invoice_id, request_id } = data
        return BaseApi.getDownloadPdf(APIURL.employee_URL + `invoice/download?request_id=${request_id}&invoice_id=${invoice_id}`, LocalStorage.getAccessToken());
    }
    invoicePostTemplateApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `invoice/send-email`, data, LocalStorage.getAccessToken());
    }
    updateNetPay(data){
         return BaseApi.putWithToken(APIURL.org_URL + `invoice/update/client/net-payterms`, data, LocalStorage.getAccessToken())
    }
    updateClientTimesheet(data){
        return BaseApi.putWithToken(APIURL.org_URL + `invoice/update/client/attach-timesheet`, data, LocalStorage.getAccessToken())
   }
}

export default new InvoiceAPI()