import { makeStyles } from "@mui/styles";
import { blue, btnBorder, red } from "../../theme";
import { useTheme } from "@mui/material";

const InputStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: 'transparent !important',
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: 'grey !important',
      borderWidth: '1px !important',
    },
    '&:after': {
      borderColor: '#FFB400',
    },
  },
  underlineError: {
    '&:after': {
      borderColor: 'darkred',
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: 'darkgreen',
    },
  },
  labelRoot: {
    // color: 'black',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  feedback: {
    position: 'absolute',
    top: '18px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  marginTop: {
    marginTop: '16px',
  },

  formControl: {
    margin: '0px',
    position: 'relative',
    verticalAlign: 'unset',
    "& .MuiFilledInput-root": {
      borderRadius: '8px !important'
    },
    "& .MuiTypography-root": {
      font: '14px Quicksand !important',
      fontWeight: `${400} !important`,
      // color: '#737373 !important',
      padding: '0px 0px 2px 0px !important',
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: `${blue} !important`
      },
    },
    "&:focus": {
      "& .MuiTypography-root": {
        color: `${blue} !important`
      }
    },
    "& .MuiFormLabel-root": {
      // color: '#737373 !important',
      font: '14px Quicksand !important',
      marginTop: '2px',
      fontWeight: `${400} !important`,
    },

    "& .MuiFormControl-root": {
      margin: '0px !important'
    }
  },

  formInput: {
    font: '14px Quicksand !important',
    background: "#f4f4f4 !important",
    opacity: 1,
    borderRadius: '9px',
    height: '22px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    "&:focus": {
      border: `1px solid ${blue}!important`
    }
  },

  fontSize: {
    font: " normal normal normal 12px/30px Quicksand !important",
    letterSpacing: "0px !important",
    color: "#707070",
    opacity: "1",
  }, 
  clientInput: {
    font: '14px Quicksand !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid ${btnBorder.grey} !important`,
    fontWeight: `${600} !important`,
    // "&.MuiInputBase-input": {
    //   padding: '25.41px 12px 10px 12px !important',
    //   height: '17px',
    //   color: '#262626 !important',
    //   font: '14px Quicksand !important',
    //   fontWeight: `${400} !important`,     
    // },    
    "& .MuiFormLabel-asterisk": {
      color: "red !important"
    },
    "&:focus": {
      border: `1px solid ${blue} !important`,
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: `${blue} !important`
      },
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
    "& .Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiFormHelperText-root": {
      border: `1px solid ${red} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "orange !important"
    }, "& .MuiFormHelperText-root.Mui-error": {
      border: `1px solid ${red} !important`,
    },
    [useTheme().breakpoints.down('xl')]: {
      font: '12px Quicksand !important',
      fontWeight: `${600} !important`,
    },
  },
  clientInputReadOnly: {
    font: '12px Quicksand !important',
    background: "#FAFAFA !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid ${btnBorder.grey} !important`,
    fontWeight: `${600} !important`,
    "& .MuiFilledInput-root": {
      background: "white !important",
      font: '14px Quicksand !important',
      fontWeight: `${600} !important`,
    },
    "&.Mui-focused": {
      backgroundColor: "white !important"
    },
    "& .MuiFormLabel-root": {
      // color: '#737373 !important',
      font: '14px Quicksand !important',
      marginTop: '2px',
      fontWeight: `${600} !important`,
    },
    "&:focus": {
      border: `1px solid ${blue} !important`,
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: `${blue} !important`
      },
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
    "& .Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiFormHelperText-root": {
      border: `1px solid ${red} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "orange !important"
    }, "& .MuiFormHelperText-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }
  },
  borderOrange: {
    font: '15px Quicksand !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid #F59E0B !important`,
    "&.MuiInputBase-input": {
      padding: '25.41px 12px 10px 12px !important',
      height: '17px',
      color: '#262626 !important',
      font: '14px Quicksand !important',
      fontWeight: `${400} !important`,
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '2px solid #F59E0B !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  },
  clientInput1: {
    font: '15px Quicksand !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    "&.MuiInputBase-input": {
      padding: '25.41px 12px 10px 12px !important',
      height: '17px',
      color: '#262626 !important',
      font: '14px Quicksand !important',
      fontWeight: `${400} !important`,
    },
    "&:focus": {
      border: `1px solid ${blue} !important`
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  },

  descriptionFormControl1: {
    "& .MuiFilledInput-root": {
      borderRadius: '8px !important',
      background: 'white !important',
      border: `1px solid ${btnBorder.grey} !important`,
      padding: '22px 12px '
    },
    "&.focus": {
      border: `1px solid ${blue} !important`,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: "#FAFAFA !important",
      border: '1px solid #FAFAFA !important',
    },
  },

  descriptionInput: {
    font: '14px Quicksand !important',
    backgroundColor: "#FFFFFF !important",
    fontWeight: `${600} !important`,
    opacity: 1,
    '&::placeholder': {
      color: '#525252 !important',
      font: '14px Quicksand !important',
      fontWeight: `${400} !important`,
      opacity: '1 !important'
    },
    "&.focus": {
      border: `1px solid ${blue} !important`,
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
  }, 

  helperText: {
    color: 'red !important'
  },
  smallWhiteInput: {
    width: '100%',
    font: '14px Quicksand !important',
    padding: "5px 10px !important",
    height: '25px !important',
    opacity: 1,
    textAlign: 'center',
    // borderRadius: '3px !important',
    "&:focus": {
      // border: '1px solid #4285F4 !important'
    }
  },
  smallWhiteBg: {
    width: '100%',
    font: '21px Quicksand !important',
    padding: "5px 10px !important",
    height: '25px !important',
    opacity: 1,
    background: '#FFFFFF !important',
    color: '#15803D !important',
    fontWeight: `${600} !important`,
  },
  red: {
    color: `${red} !important`,
    font: '11px Quicksand !important',
  }, 
  selectBesideInput: {
    font: '14px Quicksand !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid ${btnBorder.grey} !important`,
    fontWeight: `${600} !important`,
    height: '22px !important',
    // "&.MuiInputBase-input": {
    //   padding: '25.41px 12px 10px 12px !important',
    //   height: '17px',
    //   color: '#262626 !important',
    //   font: '14px Quicksand !important',
    //   fontWeight: `${400} !important`,     
    // },    
    "&:focus": {
      border: `1px solid ${blue} !important`,
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: `${blue} !important`
      },
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
    "& .Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiFormHelperText-root": {
      border: `1px solid ${red} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "orange !important"
    }, "& .MuiFormHelperText-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }
  },

  clientInputCenter: {
    font: '14px Quicksand !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid ${btnBorder.grey} !important`,
    fontWeight: `${600} !important`,
    "&.MuiInputBase-input": {
      padding: '8px 12px 8px 12px !important',
      height: '37px',
      color: '#262626 !important',
      font: '14px Quicksand !important',
      fontWeight: `${600} !important`,     
    },    
    "& .MuiFormLabel-asterisk": {
      color: "red !important"
    },
    "&:focus": {
      border: `1px solid ${blue} !important`,
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: `${blue} !important`
      },
    },
    "&:disabled": {
      background: "#FAFAFA !important",
      borderRadius: '8px !important',
      border: '1px solid #FAFAFA !important',
      '-webkit-text-fill-color': "#525252 !important",
    },
    "& .Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiFormHelperText-root": {
      border: `1px solid ${red} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      border: `1px solid ${red} !important`,
    }, "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "orange !important"
    }, "& .MuiFormHelperText-root.Mui-error": {
      border: `1px solid ${red} !important`,
    },
    [useTheme().breakpoints.down('xl')]: {
      font: '12px Quicksand !important',
      fontWeight: `${600} !important`,
    },
  },
}));

export default InputStyles
