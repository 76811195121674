import { makeStyles } from "@mui/styles";
import { btnBgGrey } from "../../../theme";
import { useTheme } from "@mui/material";

const MainStyles = makeStyles(() => ({
  mainBox: {
    marginTop: "10px",
    padding: "0px 96px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "#FDFDFD",
    "@media (min-width: 961px) and (max-width: 1050px)": {
      padding: "0px 50px",
    },
    "@media (min-width: 100px) and (max-width: 960px)": {
      padding: "0px 10px",
    },
  },
  mainPanel: {
    marginTop: "10px",
    justifyContent: "center",
  },
  listItems: {
    minHeight: "57px",
    margin: "6px 0px 16px 0px !important",
    borderRadius: "8px !important",
    font: "14px Quicksand !important",
    fontWeight: `${700} ! important`,
    color: "#171717",
    transition: "all .3s ease",
    backgroundColor: "#FBFBFB !important",
    "&:hover": {
      backgroundColor: "#FBFBFB !important",
    },
    [useTheme().breakpoints.down('xl')]: {
      font: '12px Quicksand !important',
      fontWeight: `${700} !important`,
      minHeight: "37px",
    }
  }, 
  listItemsActive: {
    color: "#FFFFFF !important",
    backgroundColor: "#0C75EB !important",
    transition: "all .3s ease",
    "&:hover": {
      backgroundColor: "#0C75EB !important",
      color: "#FFFFFF !important",
    },
  },
  mainListBox: {
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #919EAB1F",
    padding: "30px 32px",
    marginTop: "16px",
    "@media (min-width: 500px) and (max-width: 960px)": {
      padding: "20px 10px",
    },
    "@media (min-width: 100px) and (max-width: 499px)": {
      padding: "10px",
    },

  },
  prefTitle: {
    height: "19px !important",
  },
  activeItemBox: {
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #919EAB1F",
    padding: "30px 32px 15px 32px",
    "@media (min-width: 500px) and (max-width: 960px)": {
      padding: "20px",
    },
    "@media (min-width: 100px) and (max-width: 499px)": {
      padding: "10px",
    },
  },
  activeItemBox2: {
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #919EAB1F",
    padding: "30px 32px 40px 32px",
    "@media (min-width: 500px) and (max-width: 960px)": {
      padding: "20px",
    },
    "@media (min-width: 100px) and (max-width: 499px)": {
      padding: "10px",
    },
  },
  activeItemBox3: {
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #919EAB1F",
    padding: "30px 32px 41px 32px",
    "@media (min-width: 500px) and (max-width: 960px)": {
      padding: "20px",
    },
    "@media (min-width: 100px) and (max-width: 499px)": {
      padding: "10px",
    },
  },
  listContainer: {
    overflowY: "auto",
    margin: "20px 0px 0px 0px",
    "&::-webkit-scrollbar": {
      display: "none",
    }
  },
  descriptionBoxStyle: {
    backgroundColor: "#FBFBFB !important",
    padding: "12px 2px",
    borderRadius: "8px",
    marginBottom: "16px",

  }, 
  activeBoxHeading: {
    marginBottom: "28px",
  },
  dropBox1: {
    width: "100%",
    height: "126px",
    display: "inline-block",
    borderRadius: "8px",
  },
  dropZone: {
    background: "#F0F7FF",
    border: `dashed 2px #0C75EB`,
    padding: "20px",
    borderRadius: "12px",
    height: "126px",
  },
  chipContainerMain: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
    borderRadius: "8px",
    margin: "26px 0px",
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "69px !important",
    width: "100% !important",
    border: "1px solid #C7CCD3",
    borderRadius: "8px",
    padding: "10px 8px"
  }, 
  mainDiv: {
    border: "1px solid #C7CCD3",
    padding: "8px",
    width: "auto",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    margin: "10px 0px",
  },
  closeIcon: {
    height: "16px",
    width: "16px",
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#737373",
    marginBottom: "11px",
  },
  EmployeesSearchInput: {
    border: "none",
    padding: "0px 0px 0px 10px",
    width: "215px",
    height: "100%",
    background: "transparent",
    color: "rgba(38, 38, 38, 1)",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: "600",
    transition: "all .3s ease",
    "&::-webkit-input-placeholder": {
      color: "rgba(199, 204, 211, 1)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  breadcrumbsLink: {
    fontFamily: "Quicksand !important",
    color: "#849199 !important",
    fontSize: "14px !important",
    textDecoration: "none !important",
    cursor: "pointer !important",
  },
  topView: {
    display: "flex",
    justifyContent: "space-between",
  },
  breadcrumbsName: {
    fontFamily: "Quicksand !important",
    color: "#092333 !important",
    fontSize: "14px !important",
  },

  //organization config

  textarea: {
    width: "100% !important",
    font: "14px Quicksand !important",
    fontWeight: "400 !important",
    color: `${btnBgGrey.shade6} !important`   
  },  
  optional: {
    font: "14px Quicksand !important",
    color: "rgb(194 188 188) !important",
    marginLeft: "0px !important",
  },
  searchField: {
    height: "40px",
    border: "1.5px solid rgba(199, 204, 211, 1)",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  globalSearchInput: {
    border: "none",
    padding: "0px 0px 0px 10px",
    width: "100%",
    height: "100%",
    background: "transparent",
    color: "rgba(38, 38, 38, 1)",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: "600",
    transition: "all .3s ease",
    "&::-webkit-input-placeholder": {
      color: "rgba(199, 204, 211, 1)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  searchIcon: {
    all: "unset",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "Quicksand",
    width: "45px",
    height: "38px",
    border: "none",
    color: "black !important",
    borderRadius: "6px",
  }, 
  customEditorStyle: {
    border: "none !important", // Use a contrasting color
    marginTop: "-10px",
    marginLeft: "-8px",
    width:'98%'
  },
  disabledEditor: {
    pointerEvents: 'none',
    background: "#FAFAFA"
  },
  toolBar: {
    display: "none !important",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
  },
  flexContainerWithSpacing: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    justifyItems: "center",
    marginTop: "20px",
    "@media (min-width: 100px) and (max-width: 499px)": {
      display: "block",
  },
  },
  centeredFlexContainerWithGap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: "290px",
    gap: "12px",
  },
  cancelButtonInPlacements: {
    height: "42px !important",
    width: "100px !important",
    borderRadius: "8px !important",
    font: "14px Quicksand !important",
    fontWeight: `${600} !important`,
  },
  saveButtonInPlacements: {
    height: "42px !important",
    width: "90px !important",
    borderRadius: "8px !important",
    font: "14px Quicksand !important",
    fontWeight: `${600} !important`,
  },
  infoIconProperties: {
    marginLeft: "3px",
    marginBottom: "-4px",
    cursor: "pointer",
  },
  placementHeadingWeight: {
    fontWeight: "400 !important",
  },
  iconInfo:{
    display:'flex',
    alignItems: 'center',
    padding:"10px",
    paddingTop:"18px"
  },
  descriptionText:{
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  }
}));

export default MainStyles;
