import { Avatar, Box, Divider, Grid, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Text from '../../../../components/customText/Text'
import Input from '../../../../components/input/Input'
import { isValid, onCurrencyWithTwoDecimalOnlyChangeCurr, onCharactersAndNumbersWithSpaceChange, validate_alphaNumeWithoutSpace, validate_Char, validate_emptyField, validate_charWithSpace } from '../../../../components/Validation'
import Select from '../../../../components/select/Select'
import moment from 'moment'
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, BlackToolTip, capitalize, dateFormat, getCurrencySymbol, NoDataFound, removeLoader } from '../../../../utils/utils'
import LocalStorage from '../../../../utils/LocalStorage'
import FileInput from '../../../../components/muiFileInput/FileInput'
import Search from '../../../../components/select/Select';
import CommonApi from '../../../../apis/CommonApi'
import Button from '../../../../components/customButton/Button'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup'
import AddReimbursementType from '../../addSelectForms/AddReimbursementType'

import reimbursementIcon from '../../../../assets/svg/reimbursementIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit_2.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/deletered.svg';
import Date from '../../../../components/datePicker/Date'
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi'
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles'
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import DeleteDeductionPopup from '../expenseManagement/DeductionPopup'
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import ExpenseManagement from '../../../../apis/admin/payroll/ExpenseManagement'


function ReimbursementsDrawer({ summary, remDrawer, closeReimbursement, getAllEmployees, reimbursementDeductionProp, reimbursementTypeDropDown, reimbursementPayPeriodDropDown, clickedEmpId }) {

    const classes = PlacementDashboardStyles();
    // eslint-disable-next-line
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const [error, setError] = useState({})// eslint-disable-next-line
    const [typeOpen, setTypeOpen] = useState(false)
    const [enableForm, setEnableForm] = useState(false)
    const [state, setState] = useState({
        name: "",
        expense_type_id: "",
        date_of_expense: "",
        pay_date:"",
        added_to: 1,
        raised_amount: "",
        payment_mode: 1,
        pay_period_id: "",
        remarks: "",
        transaction_id: "",
        payee_name: "",
        bank_name: "",
        bank_account_digits: "",
        documents: [
            {
                document_name: '',
                document_url: '',
                new_document_id: ''
            }
        ],
        employee_id: clickedEmpId,
        expense_effect_on: 1,
        date_of_joining: reimbursementDeductionProp?.date_of_joining,
        deduct_from: '',



    })
    const [deleteDeduction, setDeleteDeduction] = useState(false);
    const [deleteSelectedId, setDeleteSelectedId] = useState(null);


    const [isEditing, setIsEditing] = useState(false);


    const [reimbursementsTypeOptions, setReimbursmentTypeOptions] = useState(reimbursementTypeDropDown);
    const [deductState, setDeductState] = useState({
       
    })

    useEffect(() => {
        if (!remDrawer) {
            handleDisableForm();
            setIsEditing(false);

        } else {
            setState((prevState) => ({ ...prevState, date_of_joining: reimbursementDeductionProp?.date_of_joining }))
            setReimbursmentTypeOptions(reimbursementTypeDropDown);
            getDeductStatus();
        }
        // eslint-disable-next-line
    }, [remDrawer])

    const getDeductStatus = () => {
            ExpenseManagement.getdeductBalanceStatus().then((res) => {
                if (res.data.statusCode === 1003) {
                    setDeductState(res.data.data);
                    if (res.data.data.off_cycle_reimbursement == false) {
                        state.payment_mode = 1
                        setState({ ...state });
                    }
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        }

    const handleApproveDeduction = () => {
        const data = {
            id: deleteSelectedId,
            request_id: LocalStorage.uid(),
        }
        ExpenseManagement.deleteTransaction(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                setDeleteDeduction(false);
                closeReimbursement()
            }
            else {
                addErrorMsg(res.data.message);
            }
        })
    }


    const handleCancelDeduction = () => {
        setDeleteDeduction(false);
    }


    const handleChange = (e) => {
        if (e.target.name == 'raised_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value;
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value;
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value;
            }
            setState({ ...state });
        }
        else if (e.target.name == 'deduct_from') {
            if (deductState.off_cycle_reimbursement == false) {
                state.payment_mode = 1
            }
            state[e.target.name] = e.target.value
            state['expense_effect_on'] = ''
            if (e.target.value == 1) {
                state['expense_effect_on'] = 1
            }            
            setState({ ...state })
        }
        else if (e.target.name == "expense_effect_on") {
            state.payment_mode = ''
            state.pay_period_id = ''
            if (deductState.off_cycle_reimbursement == false) {
                state.payment_mode = 1
            }
            state[e.target.name] =  e.target.value
            setState({ ...state })
        }
        else {
            state[e.target.name] = e.target.name === 'name' || e.target.name === 'payee_name' ? capitalize(e.target.value) : e.target.value
            setState({ ...state })
        }
        handleValidate(e)
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value)
                break;
            case "expense_type_id":
                error.expense_type_id = validate_emptyField(input.value)
                break;
            case "date_of_expense":
                error.date_of_expense = validate_emptyField(input.value)
                break;
            case "pay_date":
                error.pay_date = validate_emptyField(input.value)
                break;
            case "expense_effect_on":
                error.expense_effect_on = validate_emptyField(input.value)
                break;
            case "raised_amount":
                error.raised_amount = validate_emptyField(input.value);
                if (error.raised_amount == '' && input.value == '$0') {
                    error.raised_amount = 'The raise amount should be greater than 0'
                }
                if (input.value == '$') {
                    error.raised_amount = 'This field is required'
                }
                break;
            case "payment_mode":
                error.payment_mode = validate_emptyField(input.value)
                break;

            case "payee_name":
                error.payee_name = validate_Char(input.value)
                break;
            case "bank_name":
                error.bank_name = validate_alphaNumeWithoutSpace(input.value)
                break;
            case "pay_period_id":
                error.pay_period_id = validate_emptyField(input.value)
                break;
                case "deduct_from":
                error.deduct_from = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setError({ ...error })
    }

    const validateAll = () => {
        const { name, expense_type_id, date_of_expense, raised_amount,deduct_from, pay_period_id,pay_date ,expense_effect_on,payment_mode} = state
        let errors = {}
        const dateOfJoining = moment(moment(state.date_of_joining, dateFormat()).format(dateFormat()), dateFormat());
        const today = moment(moment().format(dateFormat()), dateFormat());
        const givenDate = moment(date_of_expense, dateFormat());

        errors.name = validate_charWithSpace(name);
        errors.expense_type_id = validate_emptyField(expense_type_id);
        errors.date_of_expense = date_of_expense == '' ? 'This field is required' : givenDate.isBetween(dateOfJoining, today, null, '[]') ? validate_emptyField(date_of_expense) : 'Invalid date of Expense, Date must be between Date of Joining and Current date.';
        if (isEditing == false) errors.raised_amount = validate_emptyField(raised_amount) == "" ? parseInt(raised_amount) <= 0 ? "The raise amount should be greater than 0" : "" : validate_emptyField(raised_amount);
        errors.deduct_from =  deductState.deduct_balance ? validate_emptyField(deduct_from) : '';

        errors.expense_effect_on = state.deduct_from == '' ? '' : validate_emptyField(expense_effect_on);
        errors.payment_mode =  state.expense_effect_on == 1 ? validate_emptyField(payment_mode) : '';

        errors.pay_period_id = state.expense_effect_on == 1 && state.payment_mode == 1 ? validate_emptyField(pay_period_id) : '';
        errors.pay_date = state.payment_mode == 2 ? validate_emptyField(pay_date) : '';
        
        setError({ ...errors })
        return errors;
    }
    const uploadDocs = (e) => {

        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < Number(25)) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("expense-management", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            state.documents[0].new_document_id = res.data.data.id
                            state.documents[0].document_name = e.target.files[0].name
                            state.documents[0].document_url = res.data.data.document_url
                            setState({ ...state })
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG or PDF format only.");
        }
    }

    const ReimbursementsTypes = () => {
        GeneratePayrollApi.ReimbursementTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursmentTypeOptions(res.data.data);
            }
        })
    };

    const handleChangeDate = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
        handleValidate(event);
    }

    const handleDeleteOpen = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].document_name = ''
        state.documents[0].document_path = ''
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    const handleEnableForm = (text) => {
        setEnableForm(true);
        if (text == 'Add') {
            setIsEditing(false);
        } else {
            setIsEditing(true)
        }

    }

    const handleDisableForm = () => {
        setEnableForm(false);
        setState({
            name: "",
            expense_type_id: "",
            date_of_expense: "",
            added_to: 1,
            raised_amount: "",
            payment_mode: 1,
            pay_period_id: "",
            remarks: "",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            bank_account_digits: "",
            pay_date:"",
            documents: [
                {
                    document_name: '',
                    document_url: '',
                    new_document_id: ''
                }
            ],
            expense_effect_on: 1,
            deduct_from:""

        });
        setError({});
        setIsEditing(false)

    }

    const [submitLoader, setSubmitLoader] = useState(false);
    const handleSubmit = () => {
        const errors = validateAll();
        console.log("errors",errors)

        if (isValid(errors)) {
            state['entity_type'] = 1;
            state['employee_id'] = clickedEmpId;
            state['id'] = state?.id ? state?.id : "";
            state['deduct_balance'] = deductState.deduct_balance
            state['off_cycle_reimbursement'] = deductState.off_cycle_reimbursement
            setSubmitLoader(true);
            GeneratePayrollApi.SaveDeductionOrReimbursement(state).then((res) => {
                setSubmitLoader(false);
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(res.data.message);
                    closeReimbursement();
                    getAllEmployees();
                    setIsEditing(false)

                }
                else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }

    }

    const handleEdit = (item) => {
        setState((prevState) => ({
            ...prevState,
            name: item?.name,
            expense_type_id: item?.expense_type_id,
            date_of_expense: item?.date_of_expense,
            added_to: item?.added_to,
            raised_amount: item?.raised_amount,
            documents: [{
                ...item?.expense_documents[0],
                new_document_id: '',
                document_key: "expense-management",
                aws_s3_status: 1
            }],
            payment_mode: item?.payment_mode,
            pay_period_id: item?.pay_period_id,
            remarks: item?.remarks,
            transaction_id: item?.transaction_id,
            payee_name: item?.payee_name,
            bank_name: item?.bank_name,
            bank_account_digits: item?.bank_account_digits,
            expense_effect_on: item?.expense_effect_on,
            id: item?.id,
            pay_date:item?.pay_date,
            deduct_from: item?.deduct_from,


        }));
        handleEnableForm('Edit');

    }

    const [showEachReimbursement, setShowReimbursement] = useState(false);
    const [eachReimbursement, setEachReimbursement] = useState({})

    const handleEachReimbursement = (summaryValue, item) => {
        if (summaryValue) {
            setShowReimbursement(true);
            setEachReimbursement(item);
        }
    }


    return (
        <div>
            <Box width={{ lg: '800px', md: "800px", sm: "700px", xs: "370px" }} height={'100vh'} p={3} sx={{ position: 'relative' }}>
                <Grid container item lg={12} md={12} xs={12} sm={12} alignItems={"center"} justifyContent={"space-between"} py={2}>
                    <Text black18px>{!summary ? isEditing ? 'Edit' : 'Add' : isEditing ? 'Edit' : 'Added'} Reimbursement</Text>
                    {
                        ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                            <>{!summary ? <>{!enableForm ? <Button save14 onClick={() => handleEnableForm('Add')}>Add New</Button> : ""}</> : ''}</> : ''
                    }


                </Grid>
                {enableForm ?
                    <Grid container spacing={3} columnSpacing={4}>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'name',
                                    value: state.name,
                                    inputProps: { maxLength: 50 }
                                }}
                                clientInput
                                labelText={'Name'}
                                handleChange={handleChange}
                                onKeyPress={onCharactersAndNumbersWithSpaceChange}

                                error={error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}

                            />
                            {error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Search
                                name='expense_type_id'
                                value={state.expense_type_id}
                                options={reimbursementsTypeOptions}
                                label='Type'
                                onChange={(e) => handleChange(e, 'expense_type_id')}
                                buttonHandler={() => setTypeOpen(true)}
                                buttonName='New Type'
                                showButton
                                error={error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}

                            />
                            {error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Date
                                labelText={"Date of Expense"}
                                name='date_of_expense'
                                value={state.date_of_expense}
                                onChange={(e) => handleChangeDate(e, "date_of_expense")}
                                maxDate={moment().format(dateFormat())}
                                minDate={moment(state.date_of_joining, dateFormat()).format(dateFormat())}

                                error={error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}

                            />
                            {error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}

                        </Grid>
                        
                        {
                            deductState.deduct_balance == false ? '' :
                                <Grid item xs={6}>
                                        <Select
                                            name='deduct_from'
                                            label={'Deduct from'}
                                            value={state.deduct_from}
                                            options={[{
                                                id: 1,
                                                value: 'Employee Balance'
                                            },
                                            {
                                                id: 2,
                                                value: 'Company Balance'
                                            }]}
                                            onChange={handleChange}
                                            error={error.deduct_from ? <Text red>{error.deduct_from ? error.deduct_from : ''}</Text> : ''}
                                        />
                                        {error.deduct_from ? <Text red>{error.deduct_from ? error.deduct_from : ''}</Text> : ''}
                                   
                                </Grid>
                        }
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Select
                                name='expense_effect_on'
                                value={state.expense_effect_on}
                                onChange={handleChange}
                                options={[{ id: 1, value: "Payroll" }, { id: 2, value: "Balance sheet" }]}
                                label={'Added to'}
                                disabled={state.deduct_from == 1}

                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'raised_amount',
                                    value: currency + "" + state.raised_amount,
                                }}
                                clientInput
                                onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                                labelText={'Reimbursement Amount'}
                                handleChange={(e) => { handleChange(e) }}
                                
                                error={error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}

                            />
                            {error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12} >
                            <FileInput
                                name='new_document_id'
                                FileName={state.documents[0].document_name}
                                handleChange={(e) => uploadDocs(e)}
                                label={'Supporting Document (Optional)'}
                                handleDelete={() => handleDeleteOpen()}
                                actionState={state.documents && state.documents.length > 0 && state.documents[0].document_name ? 1 : ''}
                                documentUrl={state.documents && state.documents.length > 0 && state.documents[0].document_url}
                                state={state.documents[0]}

                            />
                            <Text smallGrey10>File size should not exceed 25mb</Text>
                        </Grid>

                    


                    {state.expense_effect_on == 1 && 
                        <Grid item container spacing={3} columnSpacing={4}>
                            <Grid item lg={6} md={6} sm={6} xs={12} >
                                <Select
                                    name='payment_mode'
                                    value={state.payment_mode}
                                    onChange={handleChange}
                                    options={[{ id: 1, value: "Payroll Cycle" }, { id: 2, value: "Off-payroll Cycle" }]}
                                    label={'Payment Mode'}
                                    disabled={deductState.off_cycle_reimbursement == false}
                                />


                            </Grid> 
                        {state.payment_mode === 1 ?

                            <Grid item lg={6} md={6} sm={6} xs={12} >
                                <Select
                                    name='pay_period_id'
                                    value={state.pay_period_id}
                                    onChange={handleChange}
                                    options={reimbursementPayPeriodDropDown}
                                    label={'Pay Period'}
                                    error={error.pay_period_id ? <Text red>{error.pay_period_id ? error.pay_period_id : ''}</Text> : ''}

                                />
                                {error.pay_period_id ? <Text red>{error.pay_period_id ? error.pay_period_id : ''}</Text> : ''}

                            </Grid> : <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Date
                                    labelText={'Pay Date'}
                                    name='pay_date'
                                    value={state.pay_date}
                                    onChange={(e) => handleChangeDate(e, "pay_date")}
                                    height='54px'
                                    error={error.pay_date ? <Text red>{error.pay_date ? error.pay_date : ''}</Text> : ''}
                                />
                                {error.pay_date ? <Text red>{error.pay_date ? error.pay_date : ''}</Text> : ''}

                            </Grid>}
                        {state.payment_mode === 2 &&
                            <>
                                <Grid item lg={12} md={12} sm={12} xs={12}><Text smallBlack>Transaction Details</Text></Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'transaction_id',
                                            value: state.transaction_id,
                                            inputProps: { maxLength: 50 }
                                        }}
                                        clientInput
                                        labelText={'Transaction ID'}
                                        handleChange={handleChange}
                                        error={error.transaction_id ? <Text red>{error.transaction_id ? error.transaction_id : ''}</Text> : ''}

                                    />
                                    {error.transaction_id ? <Text red>{error.transaction_id ? error.transaction_id : ''}</Text> : ''}

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'payee_name',
                                            value: state.payee_name,
                                            inputProps: { maxLength: 50 }
                                        }}
                                        clientInput
                                        labelText={'Payee Name'}
                                        handleChange={handleChange}
                                        error={error.payee_name ? <Text red>{error.payee_name ? error.payee_name : ''}</Text> : ''}

                                    />
                                    {error.payee_name ? <Text red>{error.payee_name ? error.payee_name : ''}</Text> : ''}

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'bank_name',
                                            value: state.bank_name,
                                            inputProps: { maxLength: 50 }
                                        }}
                                        clientInput
                                        labelText={'Bank Name'}
                                        handleChange={handleChange}
                                        error={error.bank_name ? <Text red>{error.bank_name ? error.bank_name : ''}</Text> : ''}

                                    />
                                    {error.bank_name ? <Text red>{error.bank_name ? error.bank_name : ''}</Text> : ''}

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'bank_account_digits',
                                            value: state.bank_account_digits,
                                            inputProps: { maxLength: 4 }
                                        }}
                                        clientInput
                                        labelText={'Last Four Digits of Bank Account'}
                                        handleChange={handleChange}
                                        error={error.bank_account_digits ? <Text red>{error.bank_account_digits ? error.bank_account_digits : ''}</Text> : ''}

                                    />
                                    {error.bank_account_digits ? <Text red>{error.bank_account_digits ? error.bank_account_digits : ''}</Text> : ''}

                                </Grid>
                            </>
                        }
                    </Grid>}
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'remarks',
                                    value: state.remarks,
                                    inputProps: { maxLength: 255 }
                                }}
                                clientInput
                                labelText={'Remarks (Optional)'}
                                handleChange={handleChange}
                                error={error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}

                            />
                            {error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} justifyContent={"end"} gap={3}>
                            <Button disabled={submitLoader} onClick={handleDisableForm} cancelBtn sx={{ minWidth: "60px !important", height: '40px !important' }}>Cancel</Button>
                            <Button disabled={submitLoader} saveBtn sx={{ minWidth: "60px !important" }} onClick={handleSubmit}>Save</Button>
                        </Grid>
                    </Grid>
                    : <>
                        {showEachReimbursement ? <>
                            <Box sx={{ minHeight: '80vh', overflowY: 'scroll' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' >
                                            <Text mediumGreyHeader>Name</Text>
                                            <Text mediumBlack14>
                                                {
                                                    eachReimbursement.name && eachReimbursement.name.length > 16 ?
                                                        <BlackToolTip title={eachReimbursement.name} placement="right" arrow>
                                                            {eachReimbursement.name.slice(0, 16) + (eachReimbursement.name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : eachReimbursement.name
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Type</Text>
                                            <Text mediumBlack14>{eachReimbursement.expense_type_id ? eachReimbursement.expense_type_id : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Date of Expense</Text>
                                            <Text mediumBlack14>{eachReimbursement.date_of_expense ? eachReimbursement.date_of_expense : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Add To</Text>
                                            <Text mediumBlack14>{eachReimbursement.added_to ? eachReimbursement.added_to : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Reimbursement Amount</Text>
                                            <Text mediumBlack14>{eachReimbursement.approved_amount ? `$ ${eachReimbursement.approved_amount}` : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Supporting Document</Text>
                                            <Text mediumBlack14>{eachReimbursement?.documents?.length > 0 ? eachReimbursement?.documents[0]?.document_name : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Payment Mode</Text>
                                            <Text mediumBlack14>{eachReimbursement.payment_mode ? eachReimbursement.payment_mode : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Pay Date</Text>
                                            <Text mediumBlack14>{eachReimbursement.pay_date ? eachReimbursement.pay_date : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} m={'10px 0px'}>
                                        <Text smallBlack400>Transaction Details</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Transaction ID</Text>
                                            <Text mediumBlack14>{eachReimbursement.transaction_id ? eachReimbursement.transaction_id : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Payee Name</Text>
                                            <Text mediumBlack14>{eachReimbursement.payee_name ? eachReimbursement.payee_name : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Bank Name</Text>
                                            <Text mediumBlack14>{eachReimbursement.bank_name ? eachReimbursement.bank_name : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Last Four Digits of Bank Account</Text>
                                            <Text mediumBlack14>{eachReimbursement.last_digit_of_bank_account ? eachReimbursement.last_digit_of_bank_account : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display='flex' flexDirection='column'>
                                            <Text mediumGreyHeader>Remarks</Text>
                                            <Text
                                                className={classes.remarksText}
                                                sx={{
                                                    wordBreak: 'break-all',
                                                    whiteSpace: 'normal'
                                                }}
                                            >
                                                {eachReimbursement.remarks ? eachReimbursement.remarks : '-'}
                                            </Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={1} justifyContent='end' textAlign='end'>
                                <Button saveBtn400 onClick={() => setShowReimbursement(false)}>Done</Button>
                            </Box>
                        </> : <>
                            {
                                reimbursementDeductionProp?.reimbursements?.length == 0 ?
                                    NoDataFound() : ''
                            }
                            <Box sx={{ overflow: 'auto', height: '72vh' }}>
                                {reimbursementDeductionProp?.reimbursements?.map((item, index) => (
                                    <>
                                        <Box key={index} px={1} py={3} onClick={() => handleEachReimbursement(summary, item)} sx={{ cursor: 'pointer' }}>
                                            <Grid container >
                                                <Grid item lg={6} md={6} sm={6} xs={12} textAlign='start'>
                                                    <Box display='flex' alignItems='center' gap={2}>
                                                        <Avatar
                                                            src={reimbursementIcon}
                                                            alt={"reimbursementIcon"}
                                                            small
                                                        />
                                                        <Box>
                                                            <Text mediumBoldBlack noWrap style={{ margin: '4px 0' }}>

                                                                {
                                                                    item.name && item.name.length > 16 ?
                                                                        <BlackToolTip title={item.name} placement="right" arrow>
                                                                            {item.name.slice(0, 16) + (item.name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : item.name
                                                                }


                                                            </Text>
                                                            <Text largeGrey14>{item.type && item.type.length > 16 ?
                                                                <BlackToolTip title={item.type} placement="right" arrow>
                                                                    {item.type.slice(0, 16) + (item.type.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                : item.type} &nbsp;&#9679;&nbsp;{item?.added_to}</Text>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                                                    <Box display='flex' alignItems='center' justifyContent="end" height={"100%"} gap={2}>
                                                        <Text mediumBoldBlack >{getCurrencySymbol()} {item?.approved_amount}</Text>


                                                        {
                                                            ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                                                                <>{!summary ? <>{!summary ? <> <IconButton onClick={() => handleEdit(item)}> <EditIcon /> </IconButton> <IconButton onClick={() => {
                                                                    setDeleteDeduction(true); setDeleteSelectedId(item.id)
                                                                }}> <DeleteIcon /> </IconButton>  </> : ''}</> : ''}</> : ''
                                                        }



                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {index < reimbursementDeductionProp?.reimbursements?.length - 1 && <Divider />}

                                    </>
                                ))}</Box>
                            {(reimbursementDeductionProp?.reimbursements?.length > 0) && <div style={{ position: 'absolute', bottom: '10px', width: '95%' }}>
                                <Divider />
                                <Box display='flex' flexDirection='row' justifyContent='space-between' my={2}>
                                    <Text largeBlack>Total Reimbursements </Text>
                                    <Text largeBlack>$ {reimbursementDeductionProp?.reimbursement_cards?.total_reimbursed_amount?.amount}</Text>
                                </Box>
                                <Divider />
                                <Box mt={1} justifyContent='end' textAlign='end'>
                                    <Button saveBtn400 onClick={() => closeReimbursement()}>Done</Button>
                                </Box>
                            </div>
                            }
                        </>}
                    </>

                }
            </Box>

            <ReusablePopup iconHide openPopup={typeOpen} setOpenPopup={setTypeOpen} white fullWidth>
                <AddReimbursementType getAllEmployees={getAllEmployees} open={typeOpen} closePopup={setTypeOpen} payTypeApi={ReimbursementsTypes} />
            </ReusablePopup>

            <DeleteDeductionPopup
                openPopup={deleteDeduction}
                setOpenPopup={setDeleteDeduction}
                deleteAlertImage={deleteAlert}
                titleText={"Are you sure?"}
                descriptionText={
                    "It looks like you're deleting a reimbursement. Are you sure do you want to proceed."}
                cancelButtonText={"Cancel"}
                ApproveButtonText={"Yes, Delete"}
                handleCancel={handleCancelDeduction}
                handleApprove={handleApproveDeduction}
            />
        </div >
    )
}

export default ReimbursementsDrawer
