import { Box, Card, CardContent, CardHeader, Checkbox, Divider, Grid, Step, Stepper, Typography } from '@mui/material';
import React from 'react';
import Text from '../../../../components/customText/Text';
import { useState } from 'react';
import { AddEmpCont, AddEmpSubStepper, AddEmployeeStepper, BrownMnColorlibStepLabel, BrownMnCustomisedConnector } from '../../../../theme';
import Input from '../../../../components/input/Input';
import OnboardStyles from './OnboardStyles';
import Button from '../../../../components/customButton/Button';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LocalStorage from '../../../../utils/LocalStorage';
import { isValid, validate_emptyField, validate_usContact, validates_emailId, isValidMulti, validate_ssn_mandatory, onNumberOnlyChange, onNumberWithTwoDecimalOnlyChangeCurrency, onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency, validate_input_fields, validate_zipcode_input } from '../../../../components/Validation';
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, capitalizeWords, checkZipcode, dateFormat, findVisa, getCountryDetails, removeLoader } from '../../../../utils/utils';
import CommonApi from '../../../../apis/CommonApi';
import { useEffect } from 'react';
import minus from '../../../../assets/client/minus-circle.svg';
import Documents from './documents/Documents';
import { useLocation, useNavigate } from 'react-router-dom';
// import BankDetails from './bankDetails/BankDetails';
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import Date from '../../../../components/datePicker/Date';
import OnBoardApi from '../../../../apis/admin/employees/OnBoardApi';
import moment from 'moment';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ReusableDelete from '../../../../components/reuablePopup/ReusableDelete';
import Select from '../../../../components/select/Select';
import EmployeeVerifyApi from '../../../../apis/admin/employees/EmployeeVerifyApi';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import NewBankDetails from './bankDetails/NewBankDetails';
import { useZipcodeAPICall, useZipcodeAPICallMulti } from '../../ZipcodeCommon';
import Zipcode from '../../Zipcode';
import ToggleSwitch from '../../../../components/toggle/CustomToggle';
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import { manualOnboardDefaultConfig } from '../../../../config/pageConfig/EmployerModuleConfig';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={{ lg: 3, md: 3, sm: 3, xs: 1 }}>
          <Text>{children}</Text>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function OnboardEmployee() {


  const [generalDetailsConfig, setGeneralDetailsConfig] = useState(manualOnboardDefaultConfig?.generalDetails?.basicDetails);
  const [contactDetailsConfig, setContactDetailsConfig] = useState(manualOnboardDefaultConfig?.generalDetails?.contactDetails);
  const [currentAddressDetailsConfig, setCurrentAddressDetailsConfig] = useState(manualOnboardDefaultConfig?.generalDetails?.currentAddressDetails);
  const [employmentDetailsConfig, setEmploymentDetailsConfig] = useState(manualOnboardDefaultConfig?.generalDetails?.employmentDetails);
  const [emergencyContactDetailsConfig, setEmergencyContactDetailsConfig] = useState(manualOnboardDefaultConfig?.generalDetails?.emergencyContactDetails);
  const [documentsDetailsConfig, setDocumentsDetailsConfig] = useState(manualOnboardDefaultConfig?.documentsDetails)


  const classes = OnboardStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation()
  const mainStepsList = ['General Details', 'Documents', 'Bank Details'];
  const [mainStep, setMainStep] = useState(0);
  const [subStepper, setSubstepper] = useState(1);
  const [docStepper, setDocStepper] = useState(0);
  const subStepperList = ['', '', '', '', '', ''];
  const contactSubStepperList = ['', '', '', ''];
  const [value, setValue] = useState(0);
  const genderList = require('../../../../utils/jsons/Gender.json');
  const bloodGroups = require('../../../../utils/jsons/BloodGroup.json');// eslint-disable-next-line    
  const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
  const [employmentType, setEmploymentType] = useState([]);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [openPopup, setOpenpopup] = useState(false);
  const [category, setCategory] = useState([]);
  const [relation, setRelation] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [state, setState] = useState({
    request_id: LocalStorage.uid(),
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    dob: '',
    blood_group: '',
    marital_status_id: '',
    contact_number: '',
    email_id: '',
    role_id: '',
    alternate_email_id: '',
    date_of_joining: '',
    employment_type_id: '',
    company_id: '',
    reference_id: '',
    alternate_contact_number: '',
    gender_description: '',
    emergency_contact: [
      {
        relationship_id: '',
        name: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        state_id: '',
        country_id: '',
        email_id: ''
      },
      {
        relationship_id: '',
        name: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        state_id: '',
        country_id: '',
        email_id: ''
      }
    ],
    address_line_one: '',
    address_line_two: '',
    city: '',
    state_id: '',
    country_id: '',
    zip_code: '',
    employment_category_id: '',
    ssn: '',
    is_usc: '',
    visa_type_id: '',
    enable_login: 0,
    drafted_stage: "",
    health_insurance_amount: '',
    enable_health_insurance: 0,
    opening_balance: 0,
    enable_payroll: false,
    make_standard_pay_as_salary: false,
    standard_pay_amount: 0
  })
  const [eye, setEye] = useState({
    ssn: false,
  });
  // eslint-disable-next-line
  const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
  const [error, setError] = useState({});
  const [maritalList, setMaritalList] = useState([]);

  const [contactError, setContactError] = useState([
    {
      relationship_id: '',
      name: '',
      contact_number: '',
      address_1: '',
      address_2: '',
      city: '',
      zip_code: '',
      state_id: '',
      country_id: '',
      email_id: ''
    },
    {
      relationship_id: '',
      name: '',
      contact_number: '',
      address_1: '',
      address_2: '',
      city: '',
      zip_code: '',
      state_id: '',
      country_id: '',
      email_id: ''
    }
  ]);
  const [visaList, setVisaList] = useState([]);

  const IsEmpUSA = [
    {
      id: 1,
      value: 'Yes'
    },
    {
      id: 0,
      value: 'No'
    }
  ]
  const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';

  useEffect(() => {
    const data = {
      page_name: "onboard-manual",
      page_type: "create",
      with_token: true
    }
    async function getPageData() {
      const response = await CommonApi.fetchPageConfig(data)
      if (response.status === 200) {
        setGeneralDetailsConfig(response?.data?.data?.page_data?.generalDetails?.basicDetails);
        setContactDetailsConfig(response?.data?.data?.page_data?.generalDetails?.contactDetails);
        setCurrentAddressDetailsConfig(response?.data?.data?.page_data?.generalDetails?.currentAddressDetails);
        setEmploymentDetailsConfig(response?.data?.data?.page_data?.generalDetails?.employmentDetails);
        setEmergencyContactDetailsConfig(response?.data?.data?.page_data?.generalDetails?.emergencyContactDetails);
        setDocumentsDetailsConfig(response?.data?.data?.page_data?.documentsDetails)
      }
    }
    getPageData()
  }, [])

  useEffect(() => {
    if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) {
      employmentList();
      relationList();
      visaListApi();
      vendorListApi();
      maritalListApi();
      let id = LocalStorage.getEmployeeId();
      if (id !== null) {
        setTimeout(() => {
          callAPI(id)
        })
      }
    }
    // eslint-disable-next-line
  }, [])


  const [inviteData, setInviteData] = useState(null);

  useEffect(() => {
    if (LocalStorage.getRedirectedModule() !== null && (LocalStorage.getRedirectedModule() == 'employee > verify' || LocalStorage.getRedirectedModule() == 'employee > onboarders > add')) {
      getInviteViaLink(LocalStorage.getNotificationId())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) {
      if (LocalStorage.getEmployeeVerifyId()) {
        getInviteViaLink(LocalStorage.getEmployeeVerifyId())
      }
    }// eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (location.state && location.state.id) {
      getInviteViaLink(location.state.id)
    }// eslint-disable-next-line
  }, [])


  const callAPI = (id) => {
    EmployeesApi.getEmployee(id).then((response) => {
      // setTimeout(() => {
      if (response.data.statusCode == 1003) {
        categoryList(response.data.data.employment_details.employment_type_id);
        if (response.data.data.emergency_contacts !== null) {
          state.emergency_contact = response.data.data.emergency_contacts
        }
        // if (response.data.data.employment_details.department_id) {
        //   employeeTeamList(response.data.data.employment_details.department_id);
        // }
        state.id = response.data.data.id
        state.first_name = response.data.data.basic_details.first_name
        state.last_name = response.data.data.basic_details.last_name
        state.blood_group = response.data.data.basic_details.blood_group
        state.dob = response.data.data.basic_details.dob
        state.gender = response.data.data.basic_details.gender
        state.middle_name = response.data.data.basic_details.middle_name
        state.marital_status_id = response.data.data.basic_details.marital_status_id
        state.alternate_contact_number = response.data.data.contact_details.alternate_contact_number
        state.alternate_email_id = response.data.data.contact_details.alternate_email_id
        state.contact_number = response.data.data.contact_details.contact_number
        state.email_id = response.data.data.contact_details.email_id
        state.address_line_one = response.data.data.current_address.address_line_one
        state.address_line_two = response.data.data.current_address.address_line_two
        state.city = response.data.data.current_address.city
        state.country_id = response.data.data.current_address.country_id
        state.country_name = response.data.data.current_address.country_name
        state.state_id = response.data.data.current_address.state_id
        state.state_name = response.data.data.current_address.state_name
        state.zip_code = response.data.data.current_address.zip_code
        state.date_of_joining = response.data.data.employment_details.date_of_joining
        // state.department_id = response.data.data.employment_details.department_id
        // state.department_name = response.data.data.employment_details.department_name
        state.employment_category_id = response.data.data.employment_details.employment_category_id
        state.employment_type_id = response.data.data.employment_details.employment_type_id
        state.company_id = response.data.data.company_id || ''
        state.enable_login = response.data.data.employment_details.enable_login
        state.reference_id = response.data.data.employment_details.reference_id
        state.role_id = response.data.data.employment_details.role_id
        state.ssn = response.data.data.employment_details.ssn
        // state.team_id = response.data.data.employment_details.team_id
        state.visa_type_id = response.data.data.employment_details.visa_type_id
        state.is_usc = response.data.data.employment_details.is_usc
        if (response.data.data.emergency_contacts.length == 0) {
          state.emergency_contact = [
            {
              relationship_id: '',
              name: '',
              contact_number: '',
              address_1: '',
              address_2: '',
              city: '',
              zip_code: '',
              state_id: '',
              country_id: '',
              email_id: ''
            },
            {
              relationship_id: '',
              name: '',
              contact_number: '',
              address_1: '',
              address_2: '',
              city: '',
              zip_code: '',
              state_id: '',
              country_id: '',
              email_id: ''
            }
          ]
        }
        setState({ ...state })
      } else {
        addErrorMsg(response.data.message);
      }
      // })

    });
  }

  const getInviteViaLink = (id) => {
    EmployeeVerifyApi.getVerifyEmployeeIndex(id).then((res) => {
      if (res.data.statusCode === 1003) {
        setInviteData(res.data.data);
        categoryList(res.data.data.employment_type_id);
        if (res.data.data.emergency_contacts.length > 0 && res.data.data.emergency_contacts !== null) {
          state.emergency_contact = res.data.data.emergency_contacts
          // state.emergency_contact[0].contact_number = res.data.data.emergency_contact[0].mobile_number
          // state.emergency_contact[1].contact_number = res.data.data.emergency_contact[1].mobile_number
        }
        state.id = ''
        state.first_name = res.data.data.first_name
        state.last_name = res.data.data.last_name
        state.dob = res.data.data.dob
        state.gender = res.data.data.gender
        state.middle_name = res.data.data.middle_name
        state.contact_number = res.data.data.mobile_number
        state.email_id = res.data.data.email_id
        state.date_of_joining = res.data.data.date_of_joining
        state.employment_category_id = res.data.data.employee_category_id
        state.employment_type_id = res.data.data.employment_type_id;
        state.ssn = res.data.data.upload_documents.signed_ssn.data.length > 0 ? res.data.data.upload_documents.signed_ssn.data[0].document_number || '' : ''
        state.gender_description = res.data.data.gender_description
        setState({ ...state })
      }
      //  else {
      //   addErrorMsg(res.data.message);
      // }
    })
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const addContact = (action, index) => {
    let obj = {
      relationship_id: '',
      name: '',
      contact_number: '',
      address_1: '',
      address_2: '',
      city: '',
      zip_code: '',
      state_id: '',
      country_id: '',
      email_id: ''
    };
    if (action == "Add") {
      state.emergency_contact.push(obj);
    } else if (action == 'Remove') {
      if (state.emergency_contact[deleteIndex].id) {
        var deleteObj = {
          request_id: LocalStorage.uid(),
          id: state.emergency_contact[deleteIndex].id
        }
        EmployeesApi.deleteEmployeeEmergencyContact(deleteObj, state.emergency_contact[deleteIndex].id).then((res) => {
          if (res.data.statusCode === 1003) {
            state.emergency_contact.splice(deleteIndex, 1);
            if (deleteIndex <= state.emergency_contact.length) {
              contactError.splice(deleteIndex, 1)
            }
            setDeleteIndex(null);
            setDeleteOpen(false);
            addSuccessMsg(res.data.message);
          } else {
            addErrorMsg(res.data.message)
          }
        })
      } else {
        if (deleteIndex != null) {
          state.emergency_contact.splice(deleteIndex, 1);
          if (deleteIndex <= state.emergency_contact.length) {
            contactError.splice(deleteIndex, 1)
          }
          setDeleteIndex(null);
          setDeleteOpen(false);
        }
      }

    }
    setState({ ...state });
  }

  const dateChange = (e, name) => {
    let date = e == null ? '' : e.$d // eslint-disable-next-line
    let event = {
      target: {
        name: name,
        value: date
      }
    }
    setState({
      ...state,
      [name]: date == '' ? '' : moment(date).format(dateFormat())
    })
    handleValidate(event)
  }

  const fetchZipcodeData = useZipcodeAPICall({ setState, setError });

  const { zipcodeAPICallMulti } = useZipcodeAPICallMulti(state, setState, contactError, setContactError);


  const changeHandler = (e, index, args) => {

    if (args == 'contact') {

      if (e.target.name == 'country_id') {
        state.emergency_contact[index]['state_id'] = ''
        state.emergency_contact[index]['city'] = ''
        state.emergency_contact[index]['zip_code'] = ''
        state.emergency_contact[index][e.target.name] = e.target.value
        setState(state, contactHandleValiadates(e.target, index));
      }

      else if (e.target.name == 'contact_number') {
        convertContactFormat(e, index);
        // setState(state, contactHandleValiadates(e.target, index));
      }


      else if (e.target.name == 'name') {
        state.emergency_contact[index][e.target.name] = capitalize(e.target.value)
        setState(state, contactHandleValiadates(e.target, index));
      }

      else {
        state.emergency_contact[index][e.target.name] = e.target.value
        setState(state, contactHandleValiadates(e.target, index));
      }


    }

    if (args != 'contact' && e.target.name == 'country_id') {
      state.state_id = '';
      state.zip_code = '';
      state.city = '';
      setState({
        ...state,
        [e.target.name]: e.target.value
      }, handleValidate(e));

    }
    if (args != 'contact' && e.target.name === "opening_balance") {
      let value1 = e.target.value.trim();
      if (value1 == currency || value1 == "") {
        state[e.target.name] = "";
        setState({ ...state }, handleValidate(e));
        return;
      }
      value1 = value1.replace(currency, "");
      const regex = /^([^\d.-]+)?[-]?\d{1,10}(\.\d{0,3})?$/;
      const numericValue = parseFloat(value1.replace(/[^\d.-]/g, ''));
      if (value1 == "-") {
        state[e.target.name] = value1;
      }
      else if (regex.test(value1) && !isNaN(numericValue) && numericValue >= -9999999999.99 && numericValue <= 9999999999.99) {
        state[e.target.name] = value1;
      } else {
        return false;
      }

      setState({ ...state }, handleValidate(e));
    }
    else if (args != 'contact' && (e.target.name === "health_insurance_amount" || e.target.name === "standard_pay_amount")) {
      let value1 = e.target.value.trim();
      if (value1 == currency || value1 == "") {
        state[e.target.name] = "";
        setState({ ...state }, handleValidate(e));
        return;
      }
      value1 = value1.replace(currency, "");
      const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,3})$/;
      const numericValue = parseFloat(value1);
      if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
        state[e.target.name] = value1;
      } else {
        return false;
      }
      setState({ ...state }, handleValidate(e));
    }
    else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
      setState({
        ...state,
        [e.target.name]: capitalize(e.target.value)
      }, handleValidate(e))
    }
    else if ((e.target.name == 'contact_number' && args !== 'contact') || (e.target.name === 'ssn' && args !== 'contact') || e.target.name === 'alternate_contact_number') {
      convertFormat(e);
      if (e.target.name === 'contact_number') {
        if (validate_usContact(e.target.value, 'Mobile Number') == "" && error.contact_number == "") {
          if (!LocalStorage.getEmployeeId()) {
            var obj = {
              request_id: LocalStorage.uid(),
              contact_number: e.target.value,
              invited_employee_id: location.state && location.state.id ? location.state.id : ''
            }
          } else {
            // eslint-disable-next-line
            var obj = {
              request_id: LocalStorage.uid(),
              contact_number: e.target.value,
              id: LocalStorage.getEmployeeId()
            }
          }
          EmployeesApi.checkDuplicate(obj).then((res) => {
            removeLoader();
            if (res.data.statusCode === 1003) {
              if (res.data.valid === true) {
                error['contact_number'] = ""
              } else {
                // error['contact_number'] = `Mobile Number already exists`;
                error['contact_number'] =res?.data?.employee_id ? `The entered phone number ${obj.contact_number} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}`: 'The entered phone number is already linked to an employee who has been invited.';
              }
              setError({ ...error });
            }
            else {
              addErrorMsg(res.data.message);
              return false
            }
          });
        }
      }

      if (e.target.name === "ssn" && e.target.value !== "" && validate_ssn_mandatory(e.target.value) === "") {
        if (!LocalStorage.getEmployeeId()) {
          var ssnObj = {
            request_id: LocalStorage.uid(),
            ssn: e.target.value
          }
        } else {
          // eslint-disable-next-line
          var ssnObj = {
            request_id: LocalStorage.uid(),
            ssn: e.target.value,
            id: LocalStorage.getEmployeeId()
          }
        }
        EmployeesApi.checkDuplicate(ssnObj).then((res) => {
          removeLoader();
          if (res.data.statusCode === 1003) {
            if (res.data.valid === true) {
              error.ssn = "";
            } else {
              error.ssn = "SSN Number already exists";
            }
            setError({ ...error });
          }
          else {
            addErrorMsg(res.data.message);
            return false;
          }
        });
      }
    }
    else if (e.target.name === 'enable_login' || e.target.name === "enable_health_insurance" || e.target.name === "enable_payroll" || e.target.name == "make_standard_pay_as_salary") {
      state[e.target.name] = e.target.checked == true ? 1 : 0
      setState({ ...state })
    }
    else if (e.target.name === "enable_health_insurance" || e.target.name === "enable_payroll" || e.target.name == "make_standard_pay_as_salary") {
      state[e.target.name] = e.target.checked
      setState({ ...state })
    }
    else if (e.target.name == "is_usc") {
      state[e.target.name] = e.target.value;
      state.visa_type = null;
      state.visa_type_id = "";
      LocalStorage.removeVisaID();
      setState({ ...state }, handleValidate(e));
    } else if (e.target.name === "email_id") {
      state[e.target.name] = e.target.value;
      setState({ ...state });
      handleValidate(e);
      if (e.target.value !== "" && validates_emailId(e.target.value) === "") {
        if (!LocalStorage.getEmployeeId()) { // eslint-disable-next-line
          var obj = {
            request_id: LocalStorage.uid(),
            email_id: e.target.value,
            invited_employee_id: location.state && location.state.id ? location.state.id : '',
          }
        } else {
          // eslint-disable-next-line
          var obj = {
            request_id: LocalStorage.uid(),
            email_id: e.target.value,
            id: LocalStorage.getEmployeeId()
          }
        }
        // addLoader(true);
        EmployeesApi.checkDuplicate(obj).then((res) => {
          removeLoader();
          if (res.data.statusCode === 1003) {
            if (res.data.valid === true) {
              error['email_id'] = error.email_id != "" ? error.email_id : ''
            } else {
              // error['email_id'] = "Email ID already exists"
              error['email_id'] = res?.data?.employee_id ? `The entered email ID ${obj.email_id} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}` : 'The entered email ID is already linked to an employee who has been invited.';
            }
            setError({ ...error });
          }
          else {
            addErrorMsg(res.data.message);
            return false
          }
        });
      }
    } else if (e.target.name == 'visa_type_id') {
      setState({
        ...state,
        [e.target.name]: e.target.value
      }, handleValidate(e));
      LocalStorage.setVisaID(e.target.value);
    }
    else if (e.target.name == 'employment_type_id') {
      if (e.target.value === 1 || e.target.value === '1') {
        state.employment_category_id = ''
      }
      if (e.target.value === 1 || e.target.value === '1' || e.target.value === 3 || e.target.value === '3') {
        // eslint-disable-next-line
        state.emergency_contact.map((value, index) => {
          contactError[index].relationship_id = '';
          contactError[index].name = '';
          contactError[index].contact_number = '';
          contactError[index].address_1 = '';
          contactError[index].state_id = '';
          contactError[index].country_id = '';
          contactError[index].city = '';
          contactError[index].zip_code = '';
        });
        setContactError([...contactError])
      }
      categoryList(e.target.value);
      employeeID(e.target.value);
      handleValidate(e);
    }
    else if (e.target.name == 'company_id') {
      state.company_id = e.target.value;
      setState({ ...state })
      handleValidate(e)
    } else if (e.target.name == 'gender') {
      state[e.target.name] = e.target.value;
      state['gender_description'] = ''
      setState({ ...state })
      handleValidate(e)
    }
    else {
      if (args !== 'contact') {
        setState({ ...state, [e.target.name]: e.target.value }, handleValidate(e));
      }
    }
    if (e.target.name == 'zip_code' && args != 'contact') {
      fetchZipcodeData(e.target.value)
    }
    if (e.target.name == 'zip_code' && args == 'contact') {
      zipcodeAPICallMulti(e.target.value, index, 'emergency_contact')
    }
  }

  const convertFormat = (e) => {
    const value = e.target.value;
    const name = e.target.name
    const input = value.replace(/\D/g, '').substring(0, 10);

    // Divide numbers in 3 parts :"(123) 456-7890" 
    const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
    const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
    const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

    if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
      setState(
        {
          ...state,
          [e.target.name]: `${first}-${middle}-${last}`
        }, handleValidate(e));
      if (name == 'ssn') {
        if (last == '0000') {
          error.ssn = 'Invalid SSN, please check the format '
          setError(error)
          return true
        }
      }
    }
    else if (input.length > 3) {
      setState(
        {
          ...state,
          [e.target.name]: `${first}-${middle}`
        }, handleValidate(e));
      if (name == 'ssn') {
        if (middle == '00') {
          error.ssn = 'Invalid SSN, please check the format '
          setError(error)
          return true
        }
      }
    }
    else if (input.length >= 0) {
      setState(
        {
          ...state,
          [e.target.name]: input
        }, handleValidate(e));
      if (name == 'ssn') {
        if (first == '000' || first == '666' || first == '900') {
          error.ssn = 'Invalid SSN, please check the format '
          setError(error)
          return true
        }
      }
    }
  }
  // eslint-disable-next-line
  const ssnCheck = (value) => {
    const input = value
    if (input.substring(0, 3) == '000' || input.substring(0, 3) == '666' || input.substring(0, 3) == '900') {
      error.ssn = 'Invalid SSN, please check the format '
      setError(error)
    } else if (input.substring(3, 5) == '00') {
      error.ssn = 'Invalid SSN, please check the format '
      setError(error)
    } else if (input.substring(5, 9) == '0000') {
      error.ssn = 'Invalid SSN, please check the format '
      setError(error)
    }
  }

  const convertContactFormat = (e, index) => {

    const value = e.target.value;
    const name = e.target.name
    const input = value.replace(/\D/g, '').substring(0, 10);

    // Divide numbers in 3 parts :"(123) 456-7890" 
    const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
    const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
    const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

    if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
      state.emergency_contact[index][e.target.name] = `${first}-${middle}-${last}`
      setState({ ...state }, contactHandleValiadates(e.target, index))
    }
    else if (input.length > 3) {
      state.emergency_contact[index][e.target.name] = `${first}-${middle}`
      setState({ ...state }, contactHandleValiadates(e.target, index))
    }
    else if (input.length >= 0) {
      state.emergency_contact[index][e.target.name] = input
      setState({ ...state }, contactHandleValiadates(e.target, index))
    }
  }

  const handleValidate = (e) => {
    const input = e.target
    switch (input.name || input.tagName) {
      case "first_name":
        error.first_name = validate_input_fields(input.value, generalDetailsConfig?.first_name);
        break;
      case "middle_name":
        error.middle_name = validate_input_fields(input.value, generalDetailsConfig?.middle_name);
        break;
      case "last_name":
        error.last_name = validate_input_fields(input.value, generalDetailsConfig?.last_name);
        break;
      case 'dob':
        error.dob = validate_input_fields(input.value, generalDetailsConfig?.dob);
        break
      case 'gender':
        error.gender = validate_input_fields(input.value, generalDetailsConfig?.gender);
        break
      case 'gender_description':
        error.gender_description = validate_input_fields(input.value, generalDetailsConfig?.gender_description);
        break
      case 'blood_group':
        error.blood_group = validate_input_fields(input.value, generalDetailsConfig?.blood_group)
        break
      case 'marital_status_id':
        error.marital_status_id = validate_input_fields(input.value, generalDetailsConfig?.marital_status)
        break
      case 'contact_number':
        if ((input.value != "" || input.value != null) && input.value.length == 12 && input.value == state.alternate_contact_number) {
          error.contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
        } else if (input.value.length == 12 && input.value !== state.alternate_contact_number) {
          error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : validate_input_fields(state.alternate_contact_number, contactDetailsConfig?.alternate_contact_number)
          error.contact_number = validate_input_fields(input.value, contactDetailsConfig?.contact_number)
        } else {
          error.contact_number = validate_input_fields(input.value, contactDetailsConfig?.contact_number)
          error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : validate_input_fields(state.alternate_contact_number, contactDetailsConfig?.alternate_contact_number)
        }
        break;
      case 'alternate_contact_number':
        if ((input.value != "" || input.value != null) && input.value.length == 12 && input.value == state.contact_number) {
          error.alternate_contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
        } else if (input.value.length == 12 && state.contact_number.length == 12 && input.value !== state.contact_number) {
          error.alternate_contact_number = validate_input_fields(input.value, contactDetailsConfig?.alternate_contact_number);
          error.contact_number = !error.contact_number?.startsWith('The entered phone number') ? validate_input_fields(state.contact_number, contactDetailsConfig?.contact_number) : error.contact_number
        } else {
          error.alternate_contact_number = usMobileNumberValidator.test(input.value) ? '' : validate_input_fields(input.value, contactDetailsConfig?.alternate_contact_number)
          error.contact_number = !error.contact_number?.startsWith('The entered phone number') ? validate_input_fields(state.contact_number, contactDetailsConfig?.contact_number) : error.contact_number
        }
        break
      case 'email_id':
        if (state.alternate_email_id != "" && (input.value != "" || input.value != null) && input.value == state.alternate_email_id) {
          error.email_id = "Email ID and Alternate Email ID Not be Same"
        } else {
          error.alternate_email_id = validate_input_fields(state.alternate_email_id, contactDetailsConfig?.alternate_email_id)
          // error.alternate_email_id = "";
          error.email_id = validate_input_fields(input.value, contactDetailsConfig?.email_id)
        }
        break
      case 'alternate_email_id':
        if (state.email_id != "" && (input.value != "" || input.value != null) && (input.value.toLowerCase()) == (state.email_id.toLowerCase())) {
          error.alternate_email_id = "Email ID and Alternate Email ID Not be Same"
        } else if (error.email_id == "Email ID and Alternate Email ID Not be Same" && input.value !== state.email_id) {
          // error.email_id = "";
          error.alternate_email_id = validate_input_fields(input.value, contactDetailsConfig?.alternate_email_id)
          error.email_id = !error.email_id?.startsWith('The entered email ID') ? validate_input_fields(state.email_id, contactDetailsConfig?.email_id) : error.email_id
        } else {
          error.alternate_email_id = validate_input_fields(input.value, contactDetailsConfig?.alternate_email_id)
          error.email_id = !error.email_id?.startsWith('The entered email ID') ? validate_input_fields(state.email_id, contactDetailsConfig?.email_id) : error.email_id
        }
        break
      case 'address_line_one':
        error.address_line_one = validate_input_fields(input.value, currentAddressDetailsConfig?.address_line_one)
        break
      case 'address_line_two':
        error.address_line_two = validate_input_fields(input.value, currentAddressDetailsConfig?.address_line_two)
        break
      case 'zip_code':
        error.zip_code = state.country_id == 1 ? validate_zipcode_input(input.value, currentAddressDetailsConfig?.location?.zip_code) : validate_zipcode_input(input.value, currentAddressDetailsConfig?.location?.zip_code);
        break
      case 'city':
        error.city = validate_input_fields(input.value, currentAddressDetailsConfig?.location?.city)
        break
      case 'country_id':
        error.country_id = validate_emptyField(input.value)
        break
      case 'state_id':
        error.state_id = validate_emptyField(input.value)
        break
      case 'reference_id':
        error.reference_id = validate_emptyField(input.value)
        break
      case 'date_of_joining':
        error.date_of_joining = validate_input_fields(input.value, employmentDetailsConfig?.date_of_joining)
        /* date_of_joining must be greater than dob */
        error.date_of_joining = moment(state.date_of_joining, LocalStorage.getDateFormat()).isBefore(moment(state.dob, LocalStorage.getDateFormat())) ? employmentDetailsConfig?.date_of_joining?.errorMsg : "";
        break
      case 'employment_type_id':
        error.employment_type_id = validate_input_fields(input.value, employmentDetailsConfig?.employment_type)
        error.role_id = "";
        break
      case 'company_id':
        error.company_id = validate_input_fields(input.value, employmentDetailsConfig?.vendor)
        break
      case 'employment_category_id':
        error.employment_category_id = validate_input_fields(input.value, employmentDetailsConfig?.employment_category)
        break
      // case 'department_id':
      //   error.department_id = validate_emptyField(input.value);
      //   break;
      // case 'team_id':
      //   error.team_id = validate_emptyField(input.value);
      //   break;
      case 'ssn':
        error.ssn = validate_input_fields(input.value, employmentDetailsConfig?.ssn)
        break
      case 'is_usc':
        error.is_usc = validate_input_fields(input.value, employmentDetailsConfig?.is_us_citizen)
        break
      case 'visa_type_id':
        error.visa_type_id = validate_input_fields(input.value, employmentDetailsConfig?.visa_type)
        break
      case 'role_id':
        error.role_id = validate_emptyField(input.value)
        break
      case 'health_insurance_amount':
        error.health_insurance_amount = validate_input_fields(input.value === currency ? "" : input.value, employmentDetailsConfig?.insurance_amount)
        break
      case 'standard_pay_amount':
        error.standard_pay_amount = validate_input_fields(input.value === currency ? "" : input.value, employmentDetailsConfig?.standard_wage)
        break
      case 'opening_balance':
        error.opening_balance = validate_input_fields(input.value === currency ? "" : input.value, employmentDetailsConfig?.opening_balance)
        break
      default:
        break
    }
    setError({ ...error })
  }

  const contactHandleValiadates = (e, index) => {
    let input = e;
    // let error1 =
    //   contactError.length > 0
    //     ? contactError
    //       ? contactError[index]
    //       : contactError
    //     : contactError;

    let error1 = contactError[index] || {};

    for (var k = 0; k <= index; k++) {
      contactError.push({});
    }
    let s1 =
      contactError.length > 0 ? [...contactError] : [{ ...contactError }];
    switch (input.name || input.tagName) {
      case "name":
        error1.name = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.name) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.name);
        break;
      case "contact_number":
        error1.contact_number = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value.replace(/\D/g, '').substring(0, 10), emergencyContactDetailsConfig?.mobile_number) : usMobileNumberValidator.test(input.value) ? '' : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.mobile_number);
        break;
      case "relationship_id":
        error1.relationship_id = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.relation) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.relation);
        break;
      case "email_id":
        error1.email_id = input.value === '' ? '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.email_id);
        break;
      case "address_1":
        error1.address_1 = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.address_line_one) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.address_line_one);
        break;
      case "address_2":
        error1.address_2 = input.value === '' ? '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.address_line_two);
        break;
      case "city":
        error1.city = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.city) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.city);
        break;
      case "state_id":
        error1.state_id = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.state) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.state);
        break;
      case "country_id":
        error1.country_id = state.employment_type_id !== 4 ? input.value !== '' ? validate_input_fields(input.value, emergencyContactDetailsConfig?.country) : '' : validate_input_fields(input.value, emergencyContactDetailsConfig?.country);
        break;
      case "zip_code":
        error1.zip_code = state.employment_type_id !== 4 ? input.value !== '' ? state.emergency_contact[index].country_id == 1 ? validate_zipcode_input(input.value, emergencyContactDetailsConfig?.zip_code) : validate_zipcode_input(input.value, emergencyContactDetailsConfig?.zip_code) : '' : state.emergency_contact[index].country_id == 1 ? validate_zipcode_input(input.value, emergencyContactDetailsConfig?.zip_code) : validate_zipcode_input(input.value, emergencyContactDetailsConfig?.zip_code);
        break;
      default:
        break;
    }

    setContactError(s1);
  }

  const basicDetailsError = () => {
    const { first_name, last_name, dob, gender, gender_description, middle_name, blood_group, marital_status_id } = state
    let belowDOB = moment().subtract(generalDetailsConfig?.dob?.ageLimit, "years")
    let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
    error.first_name = validate_input_fields(first_name, generalDetailsConfig?.first_name);
    error.last_name = validate_input_fields(last_name, generalDetailsConfig?.last_name);
    error.dob = dob == 'Invalid date' ? 'Invalid data Entry' : dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : generalDetailsConfig?.dob?.ageLimitMsg : validate_input_fields(dob, generalDetailsConfig?.dob);
    error.gender = validate_input_fields(gender, generalDetailsConfig?.gender);
    error.gender_description = state.gender == 'Prefer to self-describe' ? validate_input_fields(gender_description, generalDetailsConfig?.gender_description) : '';
    error.middle_name = validate_input_fields(middle_name, generalDetailsConfig?.middle_name)
    error.blood_group = validate_input_fields(blood_group, generalDetailsConfig?.blood_group)
    error.marital_status_id = validate_input_fields(marital_status_id, generalDetailsConfig?.marital_status)
    return setError({ ...error })
  }

  const contact_detailsError = () => {
    const { contact_number, email_id, alternate_contact_number, alternate_email_id } = state
    error.contact_number = !error.contact_number?.startsWith('The entered phone number') ? validate_input_fields(contact_number, contactDetailsConfig?.contact_number) : error.contact_number;
    error.email_id = !error.email_id?.startsWith('The entered email ID') ? validate_input_fields(email_id, contactDetailsConfig?.email_id) : error.email_id;
    error.alternate_contact_number = (alternate_contact_number !== "" && contact_number !== "" && contact_number === alternate_contact_number) ? 'Mobile Number and Alternate Mobile Number Not be Same' : validate_input_fields(alternate_contact_number, contactDetailsConfig?.alternate_contact_number);
    error.alternate_email_id = (alternate_email_id !== "" && email_id !== "" && email_id === alternate_email_id) ? 'Email ID and Alternate Email ID Not be Same' : validate_input_fields(alternate_email_id, contactDetailsConfig?.alternate_email_id);
    return setError({ ...error })
  }

  // const emergency_detailsError = async () => {
  //   var emergency_details = state.emergency_contact
  //   // eslint-disable-next-line
  //   const validateEmergencyDetails = async () => {
  //     const errorContacts = await Promise.all(
  //       emergency_details.map(async (value, index) => {
  //         let errorContact = {};

  //         errorContact.relationship_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.relationship_id)
  //             : value.relationship_id !== ''
  //               ? validate_emptyField(value.relationship_id)
  //               : '';

  //         errorContact.name =
  //           state.employment_type_id == 4
  //             ? validate_charWithSpace(value.name)
  //             : value.name !== ''
  //               ? validate_charWithSpace(value.name)
  //               : '';

  //         errorContact.contact_number =
  //           state.employment_type_id == 4
  //             ? validate_usContact(value.contact_number, 'Mobile Number')
  //             : value.contact_number !== ''
  //               ? validate_usContact(value.contact_number, 'Mobile Number')
  //               : '';

  //         errorContact.address_1 =
  //           state.employment_type_id == 4
  //             ? address_validation(value.address_1)
  //             : value.address_1 !== ''
  //               ? validate_emptyField(value.address_1)
  //               : '';

  //         errorContact.state_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.state_id)
  //             : value.state_id !== ''
  //               ? validate_emptyField(value.state_id)
  //               : '';

  //         errorContact.country_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.country_id)
  //             : value.country_id !== ''
  //               ? validate_emptyField(value.country_id)
  //               : '';

  //         errorContact.city =
  //           state.employment_type_id == 4
  //             ? validate_city(value.city)
  //             : value.city !== ''
  //               ? validate_city(value.city)
  //               : '';

  //         errorContact.zip_code =
  //           state.employment_type_id == 4
  //             ? await checkZipcode(value.zip_code) : value.zip_code != '' ? await checkZipcode(value.zip_code)
  //               : '';

  //         return errorContact;
  //       })
  //     );

  //     return errorContacts; // Return the array of error contacts
  //   };
  //   const errors = await validateEmergencyDetails();
  //   var newArray = errors.filter(value => Object.keys(value).length !== 0);
  //   setContactError([...newArray])
  //   setContactError(newArray)
  // };

  // const emergency_detailsErrorCheck = async () => {
  //   var emergency_details = state.emergency_contact
  //   // eslint-disable-next-line
  //   const validateEmergencyDetails = async () => {
  //     const errorContacts = await Promise.all(
  //       emergency_details.map(async (value, index) => {
  //         let errorContact = {};

  //         errorContact.relationship_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.relationship_id)
  //             : value.relationship_id !== ''
  //               ? validate_emptyField(value.relationship_id)
  //               : '';

  //         errorContact.name =
  //           state.employment_type_id == 4
  //             ? validate_charWithSpace(value.name)
  //             : value.name !== ''
  //               ? validate_charWithSpace(value.name)
  //               : '';

  //         errorContact.contact_number =
  //           state.employment_type_id == 4
  //             ? validate_usContact(value.contact_number, 'Mobile Number')
  //             : value.contact_number !== ''
  //               ? validate_usContact(value.contact_number, 'Mobile Number')
  //               : '';

  //         errorContact.address_1 =
  //           state.employment_type_id == 4
  //             ? address_validation(value.address_1)
  //             : value.address_1 !== ''
  //               ? validate_emptyField(value.address_1)
  //               : '';

  //         errorContact.state_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.state_id)
  //             : value.state_id !== ''
  //               ? validate_emptyField(value.state_id)
  //               : '';

  //         errorContact.country_id =
  //           state.employment_type_id == 4
  //             ? validate_emptyField(value.country_id)
  //             : value.country_id !== ''
  //               ? validate_emptyField(value.country_id)
  //               : '';

  //         errorContact.city =
  //           state.employment_type_id == 4
  //             ? validate_city(value.city)
  //             : value.city !== ''
  //               ? validate_city(value.city)
  //               : '';

  //         errorContact.zip_code =
  //           state.employment_type_id == 4
  //             ? await checkZipcode(value.zip_code) : value.zip_code != '' ? await checkZipcode(value.zip_code)
  //               : '';

  //         return errorContact;
  //       })
  //     );

  //     return errorContacts; // Return the array of error contacts
  //   };
  //   const errors = await validateEmergencyDetails();


  //   var newArray = errors.filter(value => Object.keys(value).length !== 0);

  //   setContactError([...newArray])
  //   setContactError(newArray)

  //   if (isValidMulti(newArray) && state.employment_type_id == 4) {
  //     return true
  //   }
  //   else if (state.employment_type_id != 4) {
  //     return true
  //   }
  //   else return false
  // };


  const current_address_detailsError = async () => {
    const { address_line_one, address_line_two, zip_code, city, country_id, state_id } = state
    error.address_line_one = validate_input_fields(address_line_one, currentAddressDetailsConfig?.address_line_one);
    error.address_line_two = validate_input_fields(address_line_two, currentAddressDetailsConfig?.address_line_two);
    error.zip_code = await checkZipcode(zip_code)
    error.city = validate_input_fields(city, currentAddressDetailsConfig?.location?.city)
    error.country_id = validate_emptyField(country_id, currentAddressDetailsConfig?.country_id)
    error.state_id = validate_emptyField(state_id, currentAddressDetailsConfig?.state_id)
    return setError({ ...error })
  }

  const employment_detailsError = () => {
    const { date_of_joining, employment_category_id, ssn, is_usc, visa_type_id, role_id, employment_type_id, company_id, health_insurance_amount, standard_pay_amount, opening_balance } = state
    /* date_of_joining must be greater than dob */
    error.date_of_joining = state.date_of_joining == 'Invalid date' ? 'Invalid data Entry'
      : moment(state.date_of_joining, LocalStorage.getDateFormat()).isBefore(moment(state.dob, LocalStorage.getDateFormat())) ? employmentDetailsConfig?.date_of_joining?.errorMsg
        : validate_input_fields(date_of_joining, employmentDetailsConfig?.date_of_joining);
    error.employment_type_id = validate_input_fields(employment_type_id, employmentDetailsConfig?.employment_type)
    error.employment_category_id = employment_type_id !== 1 ? validate_input_fields(employment_category_id, employmentDetailsConfig?.employment_category) : '';
    error.ssn = error.ssn == "SSN Number already exists" ? employmentDetailsConfig?.ssn?.errorMsg : validate_input_fields(ssn, employmentDetailsConfig?.ssn);
    // ssnCheck(ssn);
    error.is_usc = validate_input_fields(is_usc, employmentDetailsConfig?.is_us_citizen)
    error.role_id = employment_type_id == 1 ? validate_emptyField(role_id) : ""
    error.visa_type_id = state.is_usc == 0 ? validate_input_fields(visa_type_id, employmentDetailsConfig?.visa_type) : ''
    error.company_id = employment_type_id == 3 ? validate_input_fields(company_id, employmentDetailsConfig?.vendor) : ''
    error.health_insurance_amount = state.enable_health_insurance ? health_insurance_amount == 0 ? employmentDetailsConfig?.insurance_amount?.errorMsg : validate_input_fields(health_insurance_amount, employmentDetailsConfig?.insurance_amount) : ''
    error.standard_pay_amount = findVisa(visaList, state.visa_type_id) ? /*standard_pay_amount == 0 ? employmentDetailsConfig?.standard_wage?.errorMsg :*/ validate_input_fields(standard_pay_amount, employmentDetailsConfig?.standard_wage) : ''
    error.opening_balance = validate_input_fields(opening_balance, employmentDetailsConfig?.opening_balance)
    return setError({ ...error })
  }



  const [finalSubmit, setFinalSubmit] = useState(false);
  const [loaderSwipeable, setLoaderSwipeable] = useState(false)



  const handleSubmit = async () => {
    // value 0 indicates the basic details of general form
    if (value === 0) {
      // First We are checking the validations for mandatory fields
      basicDetailsError()
      // this is to storing the errors for non-mandatory fields and also checking for particular basic fields
      // As we are checking the condition for all the errors so now we are taking variable and storing the particular errors in the basic_error variable
      let basic_error = {
        first_name: error.first_name,
        last_name: error.last_name,
        dob: error.dob,
        gender: error.gender,
        marital_status_id: error.marital_status_id,
        middle_name: error.middle_name ? error.middle_name : '',
        blood_group: error.blood_group ? error.blood_group : '',
        gender_description: state.gender == 'Prefer to self-describe' ? error.gender_description : ''
      }
      // we are checking error condition
      if (isValid(basic_error)) {
        setValue(value + 1);
        setSubstepper(subStepper + 1);
      }
      else {
        addWarningMsg(ErrorMessages.genericMessage);
        return true
      }
    }
    // value 1 indicates the contact details of general form
    else if (value === 1) {
      // First We are checking the validations for mandatory fields
      contact_detailsError();
      // this is to storing the errors for non-mandatory fields and also checking for particular contact fields
      // As we are checking the condition for all the errors so now we are taking variable and storing the particular errors of contact details in the contact_error variable
      let contact_error = {
        contact_number: error.contact_number,
        email_id: error.email_id,
        alternate_contact_number: error.alternate_contact_number ? error.alternate_contact_number : '',
        alternate_email_id: error.alternate_email_id ? error.alternate_email_id : ''
      }
      // we are checking error condition
      if (isValid(contact_error)) {
        if (!LocalStorage.getEmployeeId()) {
          var obj = {
            request_id: LocalStorage.uid(),
            email_id: state.email_id,
            contact_number: state.contact_number,
            invited_employee_id: location.state && location.state.id ? location.state.id : '',
          }
        } else {
          // eslint-disable-next-line
          var obj = {
            request_id: LocalStorage.uid(),
            email_id: state.email_id,
            contact_number: state.contact_number,
            id: LocalStorage.getEmployeeId()
          }
        }
        addLoader(true);
        setLoaderSwipeable(true)
        EmployeesApi.checkDuplicate(obj).then((res) => {
          removeLoader();
          if (res.data.statusCode === 1003) {
            setLoaderSwipeable(false)
            if (res.data.valid) {
              setValue(value + 1);
              setSubstepper(subStepper + 1);
            } else {
              addErrorMsg("Email/Mobile Number already exists")
            }
          }
          else {
            setLoaderSwipeable(false)

            addErrorMsg(res.data.message)
          }
        });
      }
      else {
        setLoaderSwipeable(false)

        addWarningMsg(ErrorMessages.genericMessage);
        return true
      }
    }
    // value 2 indicates the Emergency Contact Details of general form
    else if (value === 2) {
      // First We are checking the validations for mandatory fields
      await current_address_detailsError();
      // this is to storing the errors for non-mandatory fields and also checking for particular current address fields
      // As we are checking the condition for all the errors so now we are taking variable and storing the particular errors of current address details in the current_address_error variable
      let current_address_error = {
        address_line_one: error.address_line_one,
        zip_code: error.zip_code,
        city: error.city,
        country_id: error.country_id,
        state_id: error.state_id,
        address_line_two: error.address_line_two ? error.address_line_two : '',
      }
      // we are checking error condition
      if (isValid(current_address_error)) {
        setValue(value + 1);
        setSubstepper(subStepper + 1);
      }
      else {
        addWarningMsg(ErrorMessages.genericMessage);
        return true
      }
    }
    else if (value === 3) {
      // First We are checking the validations for mandatory fields
      employment_detailsError();
      // this is to storing the errors for non-mandatory fields and also checking for particular current address fields
      // As we are checking the condition for all the errors so now we are taking variable and storing the particular errors of current address details in the current_address_error variable
      let employment_error = {
        date_of_joining: error.date_of_joining,
        employment_category_id: error.employment_category_id,
        ssn: error.ssn ? error.ssn : "",
        is_usc: error.is_usc ? error.is_usc : '',
        // department_id: error.department_id,
        // team_id: error.team_id,
        visa_type_id: error.visa_type_id,
        role_id: error.role_id,
        employment_type_id: error.employment_type_id,
        company_id: error.company_id,
        health_insurance_amount: error.health_insurance_amount,
        standard_pay_amount: error.standard_pay_amount
      }
      // we are checking error condition
      if (isValid(employment_error)) {
        if (!LocalStorage.getEmployeeId()) {
          var ssnObj = {
            request_id: LocalStorage.uid(),
            ssn: state.ssn
          }
        } else {
          // eslint-disable-next-line
          var ssnObj = {
            request_id: LocalStorage.uid(),
            ssn: state.ssn,
            id: LocalStorage.getEmployeeId()
          }
        }
        if (state.ssn !== "") {
          addLoader(true);
          setLoaderSwipeable(true)

          EmployeesApi.checkDuplicate(ssnObj).then((res) => {
            removeLoader();
            if (res.data.statusCode === 1003) {
              setLoaderSwipeable(false)

              if (res.data.valid) {
                setValue(value + 1);
                setSubstepper(subStepper + 1);
              } else {
                addErrorMsg("SSN already exists")
              }
            }
            else {
              setLoaderSwipeable(false)

              addErrorMsg(res.data.message)
            }
          });
        } else {
          setLoaderSwipeable(false)

          setValue(value + 1);
          setSubstepper(subStepper + 1);
        }
        if (LocalStorage.getEmployeeVerifyId()) {
          state.emergency_contact[0]['contact_number'] = state.emergency_contact[0].mobile_number
          state.emergency_contact[1]['contact_number'] = state.emergency_contact[1].mobile_number
          setState({ ...state })
        }
      }
      else {
        addWarningMsg(ErrorMessages.genericMessage);
        return true
      }
    }
    else if (value === 4) {
      setFinalSubmit(true);
      if (isValidMulti(contactError)) {
        let id = LocalStorage.getEmployeeId();
        if (id !== null) {
          state.id = id;
          setLoaderSwipeable(true);
          OnBoardApi.employeeUpdate(state, LocalStorage.getAccessToken())
            .then((res) => {
              setLoaderSwipeable(false);
              if (res.data.statusCode === 1003) {
                LocalStorage.setEmployeeId(res.data.data[0].id);
                LocalStorage.setFullName(`${state.first_name} ${state.last_name}`);
                LocalStorage.removeEmployeeVerifyId();
                setMainStep(1);
                setDocStepper(1);
                setSubstepper(2);
                setValue(0);
                addSuccessMsg(`${state.first_name} ${state.last_name} Employee Updated Successfully`);
                setFinalSubmit(false);
              } else {
                addErrorMsg(res.data.message);
                setFinalSubmit(false);
              }
            })
            .catch(() => {
              setLoaderSwipeable(false);
              setFinalSubmit(false);
            });
        }
        else {
          if (LocalStorage.getEmployeeVerifyId() || (location.state && location.state.from === 'verifyFlow')) {
            state['invite_via_link_id'] = location.state.from === 'verifyFlow' ? location.state.id : LocalStorage.getEmployeeVerifyId();
            setState({ ...state });
          }
          setLoaderSwipeable(true);
          OnBoardApi.employeeStore(state, LocalStorage.getAccessToken())
            .then((res) => {
              setLoaderSwipeable(false);
              if (res.data.statusCode === 1003) {
                LocalStorage.setEmployeeId(res.data.data.id);
                LocalStorage.setFullName(`${state.first_name} ${state.last_name}`);
                setMainStep(1);
                setDocStepper(1);
                setSubstepper(2);
                setValue(0);
                addSuccessMsg(`${state.first_name} ${state.last_name} Employee Added Successfully`);
              } else {
                addErrorMsg(res.data.message);
              }
              setFinalSubmit(false);
            })
            .catch(() => {
              setLoaderSwipeable(false);
              setFinalSubmit(false);
            });
        }
      } else {
        setFinalSubmit(false);
        setLoaderSwipeable(false);
        addWarningMsg(ErrorMessages.genericMessage);
      }
    }

  }

  const back = () => {
    setLoaderSwipeable(false)
    if (value == 0) {
      setOpenpopup(true);

    } else {
      setValue(value - 1);
      setSubstepper(subStepper - 1);
    }
  }

  // const departmentList = () => {
  //   CommonApi.departmentList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
  //     if (res.data.statusCode === 1003) {
  //       setDepartment(res.data.data);
  //     }
  //   })
  // }

  const employmentList = () => {
    CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
      if (res.data.statusCode === 1003) {
        setEmploymentType(res.data.data);
      }
    })
  }


  const categoryList = (args) => {
    CommonApi.CategoryList(LocalStorage.uid(), args, LocalStorage.getAccessToken()).then((res) => {
      if (res.data.statusCode === 1003) {
        const capitalizedData = res.data.data.map((item) => ({
          ...item,
          value: capitalizeWords(item.value),
        }));
        setCategory(capitalizedData);
        // setCategory(res.data.data);
      }
    })
  }

  // const employeeTeamList = (dep_id) => {
  //   CommonApi.employeeTeam(LocalStorage.uid(), dep_id ? dep_id : '', LocalStorage.getAccessToken()).then((res) => {
  //     if (res.data.statusCode === 1003) {
  //       setEmpTeam(res.data.data);
  //     }
  //   })
  // }

  const relationList = () => {
    CommonApi.relation(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
      if (res.data.statusCode === 1003) {
        setRelation(res.data.data);
      }
    })
  }

  const visaListApi = () => {
    CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
      if (res.data.statusCode === 1003) {
        setVisaList(res.data.data);
      }
    })
  }
  const vendorListApi = () => {
    CommonApi.getVendorList('Active').then((res) => {
      if (res.data.statusCode === 1003) {
        setVendorTypes(res.data.data);
      }
    })
  }

  const maritalListApi = () => {
    CommonApi.maritalStatusList().then((res) => {
      if (res.data.statusCode === 1003) {
        setMaritalList(res.data.data);
      }
    })
  }

  const employeeID = (args) => {
    addLoader(true);
    CommonApi.prefix(args == 2 ? 'consultant' : 'contractor').then((res) => {
      removeLoader();
      if (res.data.statusCode === 1003) {
        state.employment_type_id = args
        state.reference_id = res.data.data
        state.role_id = ""
        state.employment_category_id = ""
        state.company_id = ''
        setState({ ...state, })
      }
    })
  }

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setDeleteOpen(true);
  }

  const handleBack = () => {
    setMainStep(mainStep - 1);
    setSubstepper(5);
    callAPI(LocalStorage.getEmployeeId());
  }

  const handleBackBankToDocs = () => {
    setValue(2);
    setDocStepper(3);
    setMainStep(1);
  }

  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  return (
    <div>
      {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_create" && item.is_allowed == true))) ?
        <Grid container justifyContent='center'>
          <Grid item container lg={7} md={9} p={'20px 0px 0px 0px'} justifyContent='center' position='fixed' zIndex='1' sx={{ background: '#FFFFFF', zIndex: 2 }}>
            <Grid item lg={11} md={10} sm={10} xs={12} textAlign='center' p={'10px 0px 10px 0px !important'}>
              <Stepper activeStep={mainStep} connector={<BrownMnCustomisedConnector />}>
                {
                  mainStepsList.map((item) => (
                    <Step>
                      <BrownMnColorlibStepLabel StepIconComponent={AddEmployeeStepper}>
                        <Text BrowmnMnStepperText>{item}</Text>
                      </BrownMnColorlibStepLabel>
                    </Step>
                  ))
                }
              </Stepper>
            </Grid>
            {
              mainStep == 0 ?
                <Grid item lg={10} md={10} sm={10} xs={12} textAlign='center' p={'30px 0px 25px 0px !important'}>
                  <Stepper activeStep={subStepper} connector={<BrownMnCustomisedConnector />}>
                    {
                      subStepperList.map((item) => (
                        <Step>
                          <BrownMnColorlibStepLabel StepIconComponent={AddEmpSubStepper}>
                            <Text BrowmnMnStepperText>{item}</Text>
                          </BrownMnColorlibStepLabel>
                        </Step>
                      ))
                    }
                  </Stepper>
                </Grid> :
                mainStep == 1 ?
                  <Grid item xl={10} md={10} lg={11} sm={10} xs={12} textAlign='center' p={'30px 0px 25px 0px !important'}>
                    <Stepper activeStep={docStepper} connector={<BrownMnCustomisedConnector />}>
                      {
                        contactSubStepperList.map((item) => (
                          <Step>
                            <BrownMnColorlibStepLabel StepIconComponent={AddEmpCont}>
                              <Text BrowmnMnStepperText>{item}</Text>
                            </BrownMnColorlibStepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid> : ''
            }
          </Grid>
          {
            mainStep == 0 ?
              <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' pt={14}>
                <Grid item xl={5.7} lg={6.7} md={7.5} sm={11} xs={12}>
                  {
                    mainStep == 0 &&
                    <SwipeableViews disabled index={value} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} onChangeIndex={handleChangeIndex}>
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.cardHeader} title={
                            <Text largeBlack>Basic Details</Text>
                          } />
                          <CardContent className={classes.cardContent}>
                            <Grid container lg={12} spacing={3} columnSpacing={3}>
                              {generalDetailsConfig?.first_name?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'first_name',
                                      value: state.first_name,
                                      inputProps: { maxLength: generalDetailsConfig?.first_name?.maxLength }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    placeholder={generalDetailsConfig?.first_name?.placeholder}
                                    labelText={generalDetailsConfig?.first_name?.required ? generalDetailsConfig?.first_name?.label : `${generalDetailsConfig?.first_name?.label} (Optional)`}
                                    disabled={generalDetailsConfig?.first_name?.isDisabled}
                                    error={error.first_name}
                                  />
                                  {
                                    error.first_name ?
                                      <Text red>{error.first_name ? error.first_name : ''}</Text> : ''
                                  }
                                </Grid>}
                              {generalDetailsConfig?.middle_name?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'middle_name',
                                      value: state.middle_name,
                                      inputProps: { maxLength: generalDetailsConfig?.middle_name?.maxLength }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    error={error.middle_name}
                                    placeholder={generalDetailsConfig?.middle_name?.placeholder}
                                    labelText={generalDetailsConfig?.middle_name?.required ? generalDetailsConfig?.middle_name?.label : `${generalDetailsConfig?.middle_name?.label} (Optional)`}
                                    disabled={generalDetailsConfig?.middle_name?.isDisabled}
                                  />
                                  {
                                    error.middle_name ?
                                      <Text red>{error.middle_name ? error.middle_name : ''}</Text> : ''
                                  }
                                </Grid>}
                              {generalDetailsConfig?.last_name?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'last_name',
                                      value: state.last_name,
                                      inputProps: { maxLength: generalDetailsConfig?.last_name?.maxLength }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    placeholder={generalDetailsConfig?.last_name?.placeholder}
                                    labelText={generalDetailsConfig?.last_name?.required ? generalDetailsConfig?.last_name?.label : `${generalDetailsConfig?.last_name?.label} (Optional)`}
                                    disabled={generalDetailsConfig?.last_name?.isDisabled}
                                    error={error.last_name}
                                  />
                                  {
                                    error.last_name ?
                                      <Text red>{error.last_name ? error.last_name : ''}</Text> : ''
                                  }
                                </Grid>}
                              <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                              {generalDetailsConfig?.dob?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Date
                                    labelText={generalDetailsConfig?.dob?.required ? generalDetailsConfig?.dob?.label : `${generalDetailsConfig?.dob?.label} (Optional)`}
                                    name='dob'
                                    value={state.dob}
                                    onChange={(value => dateChange(value, 'dob'))}
                                    height='54px'
                                    maxDate={moment().subtract(generalDetailsConfig?.dob?.ageLimit, "years")}
                                    error={error.dob}
                                    disabled={generalDetailsConfig?.dob?.isDisabled}
                                  />
                                  {
                                    error.dob ?
                                      <Text red>{error.dob ? error.dob : ''}</Text> : ''
                                  }
                                </Grid>}
                              {generalDetailsConfig?.gender?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Select
                                    name='gender'
                                    value={state.gender}
                                    onChange={(e) => { changeHandler(e) }}
                                    options={genderList}
                                    placeholder={generalDetailsConfig?.gender?.placeholder}
                                    label={generalDetailsConfig?.gender?.required ? generalDetailsConfig?.gender?.label : `${generalDetailsConfig?.gender?.label} (Optional)`}
                                    disabled={generalDetailsConfig?.gender?.isDisabled}
                                    helperText={
                                      error.gender ?
                                        <Text red>{error.gender ? error.gender : ''}</Text> : ''
                                    }
                                  />
                                </Grid>}
                              {
                                (generalDetailsConfig?.gender_description?.isVisible && state.gender == 'Prefer to self-describe') ?
                                  <Grid item lg={12} md={12} sm={12} xs={12} py={'4px'}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'gender_description',
                                        value: state.gender_description,
                                        inputProps: { minLength: 3, maxLength: generalDetailsConfig?.gender_description?.maxLength }
                                      }}
                                      handleChange={(e) => { changeHandler(e) }}
                                      clientInput
                                      placeholder={generalDetailsConfig?.gender_description?.placeholder}
                                      labelText={generalDetailsConfig?.gender_description?.required ? generalDetailsConfig?.gender_description?.label : `${generalDetailsConfig?.gender_description?.label} (Optional)`}
                                      disabled={generalDetailsConfig?.gender_description?.isDisabled}
                                      error={error.gender_description}
                                    />
                                    {
                                      error.gender_description &&
                                      <Text red>{error.gender_description ? error.gender_description : ''}</Text>
                                    }
                                  </Grid> : ''
                              }
                              {generalDetailsConfig?.blood_group?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box >
                                  <Select
                                    name='blood_group'
                                    value={state.blood_group}
                                    onChange={(e) => { changeHandler(e) }}
                                    options={bloodGroups}
                                    placeholder={generalDetailsConfig?.blood_group?.placeholder}
                                    label={generalDetailsConfig?.blood_group?.required ? generalDetailsConfig?.blood_group?.label : `${generalDetailsConfig?.blood_group?.label} (Optional)`}
                                    disabled={generalDetailsConfig?.blood_group?.isDisabled}
                                    helperText={
                                      error.blood_group ?
                                        <Text red>{error.blood_group ? error.blood_group : ''}</Text> : ''
                                    }
                                  />
                                </Box>
                              </Grid>}
                              {generalDetailsConfig?.marital_status?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Select
                                  name='marital_status_id'
                                  value={state.marital_status_id}
                                  onChange={(e) => { changeHandler(e) }}
                                  options={maritalList}
                                  placeholder={generalDetailsConfig?.marital_status?.placeholder}
                                  label={generalDetailsConfig?.marital_status?.required ? generalDetailsConfig?.marital_status?.label : `${generalDetailsConfig?.marital_status?.label} (Optional)`}
                                  disabled={generalDetailsConfig?.marital_status?.isDisabled}
                                  helperText={
                                    error.marital_status_id ?
                                      <Text red>{error.marital_status_id ? error.marital_status_id : ''}</Text> : ''
                                  }
                                />
                              </Grid>}
                            </Grid>
                          </CardContent>
                        </Card>
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.cardHeader} title={
                            <Text largeBlack>Contact Details</Text>
                          } />
                          <CardContent className={classes.cardContent} >
                            <Grid container spacing={3}>
                              {contactDetailsConfig?.contact_number?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'contact_number',
                                      value: state.contact_number,
                                      inputProps: { maxLength: 12 }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    labelText={contactDetailsConfig?.contact_number?.required ? contactDetailsConfig?.contact_number?.label : `${contactDetailsConfig?.contact_number?.label} (Optional) `}
                                    placeholder={contactDetailsConfig?.contact_number?.placeholder}
                                    disabled={contactDetailsConfig?.contact_number?.isDisabled}
                                    error={error.contact_number}
                                  />
                                  {
                                    error.contact_number ?
                                      <Text red>{error.contact_number ? error.contact_number : ''}</Text>
                                      : ''
                                  }
                                </Grid>}
                              {contactDetailsConfig?.alternate_contact_number?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'alternate_contact_number',
                                      value: state.alternate_contact_number,
                                      inputProps: { maxLength: 12 }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    error={error.alternate_contact_number}
                                    labelText={contactDetailsConfig?.alternate_contact_number?.required ? contactDetailsConfig?.alternate_contact_number?.label : `${contactDetailsConfig?.alternate_contact_number?.label} (Optional) `}
                                    placeholder={contactDetailsConfig?.alternate_contact_number?.placeholder}
                                    disabled={contactDetailsConfig?.alternate_contact_number?.isDisabled}
                                  />
                                  {
                                    error.alternate_contact_number ?
                                      <Text red>{error.alternate_contact_number ? error.alternate_contact_number : ''}</Text>
                                      : ''
                                  }
                                </Grid>}

                              {contactDetailsConfig?.email_id?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'email_id',
                                      value: state.email_id
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    error={error.email_id}
                                    labelText={contactDetailsConfig?.email_id?.required ? contactDetailsConfig?.email_id?.label : `${contactDetailsConfig?.email_id?.label} (Optional) `}
                                    placeholder={contactDetailsConfig?.email_id?.placeholder}
                                    disabled={contactDetailsConfig?.email_id?.isDisabled}
                                  />
                                  {
                                    error.email_id ?
                                      <Text red>{error.email_id ? error.email_id : ''}</Text>
                                      : ''
                                  }
                                </Grid>}

                              {contactDetailsConfig?.alternate_email_id?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'alternate_email_id',
                                      value: state.alternate_email_id
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    error={error.alternate_email_id}
                                    labelText={contactDetailsConfig?.alternate_email_id?.required ? contactDetailsConfig?.alternate_email_id?.label : `${contactDetailsConfig?.alternate_email_id?.label} (Optional) `}
                                    placeholder={contactDetailsConfig?.alternate_email_id?.placeholder}
                                    disabled={contactDetailsConfig?.alternate_email_id?.isDisabled}
                                  />
                                  {
                                    error.alternate_email_id ?
                                      <Text red>{error.alternate_email_id ? error.alternate_email_id : ''}</Text> : ''
                                  }
                                </Grid>}

                            </Grid>
                          </CardContent>
                        </Card>
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.cardHeader} title={
                            <Text largeBlack>Current Address</Text>
                          } />
                          <CardContent className={classes.cardContent}>
                            <Grid container lg={12} spacing={3}>
                              {currentAddressDetailsConfig?.address_line_one?.isVisible &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'address_line_one',
                                      value: state.address_line_one,
                                      inputProps: { maxLength: currentAddressDetailsConfig?.address_line_one?.maxLength }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    labelText={currentAddressDetailsConfig?.address_line_one?.required ? currentAddressDetailsConfig?.address_line_one?.label : `${currentAddressDetailsConfig?.address_line_one?.label} (Optional) `}
                                    placeholder={currentAddressDetailsConfig?.address_line_one?.placeholder}
                                    disabled={currentAddressDetailsConfig?.address_line_one?.isDisabled}
                                    error={error.address_line_one}
                                  />
                                  {error.address_line_one && <Text red>{error.address_line_one ? error.address_line_one : ''}</Text>}
                                </Grid>}
                              {currentAddressDetailsConfig?.address_line_two?.isVisible &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'address_line_two',
                                      value: state.address_line_two,
                                      inputProps: { maxLength: currentAddressDetailsConfig?.address_line_two?.maxLength }
                                    }}
                                    handleChange={(e) => changeHandler(e)}
                                    clientInput
                                    labelText={currentAddressDetailsConfig?.address_line_two?.required ? currentAddressDetailsConfig?.address_line_two?.label : `${currentAddressDetailsConfig?.address_line_two?.label} (Optional) `}
                                    placeholder={currentAddressDetailsConfig?.address_line_two?.placeholder}
                                    disabled={currentAddressDetailsConfig?.address_line_two?.isDisabled}
                                    error={error.address_line_two}
                                  />
                                  {error.address_line_two && <Text red>{error.address_line_two ? error.address_line_two : ''}</Text>}
                                </Grid>}
                              <Zipcode state={state} error={error} handleChange={changeHandler} columnLength={6} apiConfig={currentAddressDetailsConfig?.location} />
                            </Grid>
                          </CardContent>
                        </Card>
                      </TabPanel>
                      <TabPanel value={value} index={3} dir={theme.direction}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.cardHeader} title={
                            <Text largeBlack>Employment Details</Text>
                          } />
                          <CardContent className={classes.cardContent}>
                            <Grid container lg={12} spacing={3} sx={{ minHeight: '200px' }}>
                              {employmentDetailsConfig?.employment_type?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Select
                                  name='employment_type_id'
                                  value={state.employment_type_id}
                                  onChange={(e) => { changeHandler(e) }}
                                  options={employmentType}
                                  placeholder={employmentDetailsConfig?.employment_type?.placeholder}
                                  label={employmentDetailsConfig?.employment_type?.required ? employmentDetailsConfig?.employment_type?.label : `${employmentDetailsConfig?.employment_type?.label} (Optional)`}
                                  disabled={LocalStorage.getEmployeeVerifyId() || LocalStorage.getEmployeeId() || location?.state?.from == 'verifyFlow' || employmentDetailsConfig?.employment_type?.isDisabled}
                                  helperText={error.employment_type_id && <Text red>{error.employment_type_id ? error.employment_type_id : ''}</Text>}
                                />
                              </Grid>}

                              {/* {
                            state.employment_type_id == 1 ?
                              <Grid item lg={6}>
                                <Select
                                  name='role_id'
                                  value={state.role_id}
                                  onChange={(e) => { changeHandler(e) }}
                                  options={rolesList}
                                  label={'Role'}
                                  helperText={error.role_id && <Text red>{error.role_id ? error.role_id : ''}</Text>}
                                />
                              </Grid> : null
                          } */}

                              {
                                (employmentDetailsConfig?.employment_category?.isVisible && state.employment_type_id !== 1) ?
                                  <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Select
                                      name='employment_category_id'
                                      value={state.employment_category_id}
                                      onChange={(e) => { changeHandler(e) }}
                                      options={category}
                                      placeholder={employmentDetailsConfig?.employment_category?.placeholder}
                                      label={employmentDetailsConfig?.employment_category?.required ? employmentDetailsConfig?.employment_category?.label : `${employmentDetailsConfig?.employment_category?.label} (Optional)`}
                                      disabled={employmentDetailsConfig?.employment_category?.isDisabled}
                                      helperText={error.employment_category_id && <Text red>{error.employment_category_id ? error.employment_category_id : ''}</Text>}
                                    />
                                  </Grid>
                                  : null
                              }
                              {
                                (employmentDetailsConfig?.vendor?.isVisible && state.employment_type_id == 3) &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Select
                                    name='company_id'
                                    value={state.company_id}
                                    onChange={(e) => { changeHandler(e) }}
                                    options={vendorTypes}
                                    placeholder={employmentDetailsConfig?.vendor?.placeholder}
                                    label={employmentDetailsConfig?.vendor?.required ? employmentDetailsConfig?.vendor?.label : `${employmentDetailsConfig?.vendor?.label} (Optional)`}
                                    disabled={employmentDetailsConfig?.vendor?.isDisabled}
                                    helperText={error.company_id && <Text red>{error.company_id ? error.company_id : ''}</Text>}
                                  />
                                </Grid>
                              }
                              {employmentDetailsConfig?.date_of_joining?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Date
                                  name='date_of_joining'
                                  value={state.date_of_joining}
                                  onChange={(value => dateChange(value, 'date_of_joining'))}
                                  height='54px'
                                  error={error.date_of_joining}
                                  labelText={employmentDetailsConfig?.date_of_joining?.required ? employmentDetailsConfig?.date_of_joining?.label : `${employmentDetailsConfig?.date_of_joining?.label} (Optional)`}
                                  minDate={state.dob}
                                  disabled={employmentDetailsConfig?.date_of_joining?.isDisabled}
                                />
                                {error.date_of_joining && <Text red>{error.date_of_joining ? error.date_of_joining : ''}</Text>}
                              </Grid>}
                              {employmentDetailsConfig?.ssn?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Input
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    name: 'ssn',
                                    value: state.ssn,
                                    inputProps: { maxLength: 11 },
                                    type: eye.ssn ? 'text' : 'password',
                                  }}
                                  eyeIcon={eye.ssn}
                                  eyeCloseIcon={eye.ssn == false}
                                  eyeHandleChange={() => {
                                    setEye({
                                      ...eye,
                                      ssn: !eye.ssn
                                    })
                                  }}
                                  handleChange={(e) => changeHandler(e)}
                                  iconText
                                  error={error.ssn}
                                  placeholder={employmentDetailsConfig?.ssn?.placeholder}
                                  labelText={employmentDetailsConfig?.ssn?.required ? employmentDetailsConfig?.ssn?.label : `${employmentDetailsConfig?.ssn?.label} (Optional)`}
                                  disabled={employmentDetailsConfig?.ssn?.isDisabled}
                                />
                                {error.ssn && <Text red>{error.ssn ? error.ssn : ''}</Text>}
                              </Grid>}
                              {employmentDetailsConfig?.is_us_citizen?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12} >
                                <Select
                                  name='is_usc'
                                  value={state.is_usc}
                                  onChange={(e) => { changeHandler(e) }}
                                  options={IsEmpUSA}
                                  placeholder={employmentDetailsConfig?.is_us_citizen?.placeholder}
                                  label={employmentDetailsConfig?.is_us_citizen?.required ? employmentDetailsConfig?.is_us_citizen?.label : `${employmentDetailsConfig?.is_us_citizen?.label} (Optional)`}
                                  disabled={employmentDetailsConfig?.is_us_citizen?.isDisabled}
                                  helperText={
                                    error.is_usc ?
                                      <Text red>{error.is_usc ? error.is_usc : ''}</Text> : ''
                                  }
                                />
                              </Grid>}
                              {
                                (employmentDetailsConfig?.visa_type?.isVisible && state.is_usc === 0) &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <Select
                                    name='visa_type_id'
                                    value={state.visa_type_id}
                                    onChange={(e) => { changeHandler(e) }}
                                    options={visaList}
                                    placeholder={employmentDetailsConfig?.visa_type?.placeholder}
                                    label={employmentDetailsConfig?.visa_type?.required ? employmentDetailsConfig?.visa_type?.label : `${employmentDetailsConfig?.visa_type?.label} (Optional)`}
                                    disabled={employmentDetailsConfig?.visa_type?.isDisabled}
                                    helperText={error.visa_type_id && <Text red>{error.visa_type_id ? error.visa_type_id : ''}</Text>}
                                  />
                                </Grid>
                              }
                              {employmentDetailsConfig?.opening_balance?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Input
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    name: 'opening_balance',
                                    value: currency + '' + state.opening_balance,
                                    inputProps: {
                                      // maxLength: state.opening_balance && state.opening_balance.startsWith("-") ? 15 : 14, // Maximum length for "9999999999.99"
                                    },
                                  }}
                                  handleChange={(e) => changeHandler(e)}
                                  onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency}
                                  clientInput
                                  error={error.opening_balance}
                                  placeholder={employmentDetailsConfig?.opening_balance?.placeholder}
                                  labelText={employmentDetailsConfig?.opening_balance?.required ? employmentDetailsConfig?.opening_balance?.label : `${employmentDetailsConfig?.opening_balance?.label} (Optional)`}
                                  disabled={employmentDetailsConfig?.opening_balance?.isDisabled}
                                />
                                {error.opening_balance && <Text red>{error.opening_balance ? error.opening_balance : ''}</Text>}
                              </Grid>}
                              {
                                (employmentDetailsConfig?.standard_wage?.isVisible && findVisa(visaList, state.visa_type_id)) ?
                                  <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'standard_pay_amount',
                                        value: currency + '' + state.standard_pay_amount,
                                        inputProps: {
                                          // maxLength: 14, // Maximum length for "9999999999.99"
                                          // pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                                        },
                                      }}
                                      handleChange={(e) => changeHandler(e)}
                                      onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                      clientInput
                                      error={error.standard_pay_amount}
                                      placeholder={employmentDetailsConfig?.standard_wage?.placeholder}
                                      labelText={employmentDetailsConfig?.standard_wage?.required ? employmentDetailsConfig?.standard_wage?.label : `${employmentDetailsConfig?.standard_wage?.label} (Optional)`}
                                      disabled={employmentDetailsConfig?.standard_wage?.isDisabled}
                                    />
                                    {error.standard_pay_amount && <Text red>{error.standard_pay_amount ? error.standard_pay_amount : ''}</Text>}
                                  </Grid>
                                  : ''
                              }
                              {
                                (employmentDetailsConfig?.enable_health_insurance?.isVisible && state.employment_type_id != 3) ?
                                  <Grid item container lg={12} md={12} sm={12} xs={12} gap="3px" alignItems={"center"}>
                                    <ToggleSwitch name='enable_health_insurance' isActive={state.enable_health_insurance} switchChange={(e) => changeHandler(e)} sx={{ height: '24px !important' }} disable={employmentDetailsConfig?.enable_health_insurance?.isDisabled} />
                                    <Text mediumBlack>{employmentDetailsConfig?.enable_health_insurance?.label}</Text>
                                  </Grid> : ''
                              }

                              {
                                (employmentDetailsConfig?.insurance_amount?.isVisible && state.enable_health_insurance) ?
                                  <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'health_insurance_amount',
                                        value: currency + '' + state.health_insurance_amount,
                                        inputProps: {
                                          // maxLength: 14, // Maximum length for "9999999999.99"
                                          // pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                                        },
                                      }}
                                      handleChange={(e) => changeHandler(e)}
                                      onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                      clientInput
                                      error={error.health_insurance_amount}
                                      placeholder={employmentDetailsConfig?.insurance_amount?.placeholder}
                                      labelText={employmentDetailsConfig?.insurance_amount?.required ? employmentDetailsConfig?.insurance_amount?.label : `${employmentDetailsConfig?.insurance_amount?.label} (Optional)`}
                                      disabled={employmentDetailsConfig?.insurance_amount?.isDisabled}
                                    />
                                    {error.health_insurance_amount && <Text red>{error.health_insurance_amount ? error.health_insurance_amount : ''}</Text>}
                                  </Grid> : ''
                              }
                              {
                                (employmentDetailsConfig?.make_standard_pay_as_salary?.isVisible && findVisa(visaList, state.visa_type_id)) ?
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                                      <Checkbox name='make_standard_pay_as_salary' disabled={employmentDetailsConfig?.make_standard_pay_as_salary?.isDisabled} value={state.make_standard_pay_as_salary} checked={state.make_standard_pay_as_salary}
                                        onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />} />
                                      <Text mediumBlack>{employmentDetailsConfig?.make_standard_pay_as_salary?.label}</Text>
                                    </Box>
                                  </Grid>
                                  : ''
                              }
                              {employmentDetailsConfig?.enable_login?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                                  <Checkbox name='enable_login' value={state.enable_login} disabled={employmentDetailsConfig?.enable_login?.isDisabled} checked={state.enable_login === 1 ? true : false}
                                    onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                    checkedIcon={<CheckedIcon />} />
                                  <Text mediumBlack>{employmentDetailsConfig?.enable_login?.label}</Text>
                                </Box>
                              </Grid>}
                              {employmentDetailsConfig?.enable_payroll?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                                  <Checkbox name='enable_payroll' value={state.enable_payroll} disabled={employmentDetailsConfig?.enable_payroll?.isDisabled} checked={state.enable_payroll === 1 ? true : false}
                                    onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                    checkedIcon={<CheckedIcon />} />
                                  <Text mediumBlack>{employmentDetailsConfig?.enable_payroll?.label}</Text>
                                </Box>
                              </Grid>}
                            </Grid>
                          </CardContent>
                        </Card>
                      </TabPanel>
                      <TabPanel value={value} index={4} dir={theme.direction}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.cardHeader} title={
                            ''
                          } />
                          <CardContent className={classes.cardContent}>
                            {
                              state.emergency_contact.map((item, index) => (
                                <Grid container lg={12} spacing={2} p={index > 0 ? '30px 0px' : ''}>
                                  {
                                    state.emergency_contact.length > 0 &&
                                    <>
                                      <Grid item container lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={8} md={8} sm={8} xs={10}>
                                          <Text largeBlack>Emergency Contact Details -{index + 1} {state.employment_type_id !== 4 ? '(Optional)' : ''}</Text>
                                        </Grid>
                                        {
                                          state.emergency_contact.length > 2 &&
                                          <Grid item lg={4} md={4} sm={4} xs={2} textAlign='end'>
                                            {
                                              item.name !== "" ?
                                                <HtmlTooltip
                                                  placement="bottom"
                                                  arrow
                                                  title={
                                                    <React.Fragment>
                                                      <Box>
                                                        <Typography className={classes.profileTooltipText}>
                                                          You are removing {item.name} contact
                                                        </Typography>
                                                      </Box>
                                                    </React.Fragment>
                                                  }
                                                >
                                                  <img src={minus} alt='Minus' style={{ cursor: 'pointer' }} onClick={() => { handleDeleteOpen(index) }} />
                                                </HtmlTooltip>
                                                :
                                                <img src={minus} alt='Minus' style={{ cursor: 'pointer' }} onClick={() => { handleDeleteOpen(index) }} />
                                            }
                                          </Grid>
                                        }
                                      </Grid><Divider sx={{ width: '100%', color: '#C7CCD3 !important', margin: '10px' }} />
                                    </>
                                  }
                                  {emergencyContactDetailsConfig?.name?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'name',
                                        value: item.name,
                                        inputProps: { maxLength: emergencyContactDetailsConfig?.name?.maxLength }
                                      }}
                                      handleChange={(e) => changeHandler(e, index, 'contact')}
                                      clientInput
                                      error={contactError.length > 0 ? contactError[index] ? contactError[index].name : "" : ''}
                                      placeholder={emergencyContactDetailsConfig?.name?.placeholder}
                                      labelText={emergencyContactDetailsConfig?.name?.label}
                                      disabled={emergencyContactDetailsConfig?.name?.isDisabled}

                                    />
                                    {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].name : ""}</Text> : ''}
                                  </Grid>}
                                  {emergencyContactDetailsConfig?.mobile_number?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'contact_number',
                                        value: item.contact_number,
                                        // inputProps: { maxLength: 12 }
                                      }}
                                      handleChange={(e) => changeHandler(e, index, 'contact')}
                                      clientInput
                                      error={contactError.length > 0 ? contactError[index] ? contactError[index].contact_number : "" : ''}
                                      placeholder={emergencyContactDetailsConfig?.mobile_number?.placeholder}
                                      labelText={emergencyContactDetailsConfig?.mobile_number?.label}
                                      disabled={emergencyContactDetailsConfig?.mobile_number?.isDisabled}
                                    />
                                    {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].contact_number : ""}</Text> : ''}
                                  </Grid>}
                                  {emergencyContactDetailsConfig?.relation?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box >
                                      <Select
                                        name='relationship_id'
                                        value={item.relationship_id}
                                        onChange={(e) => { changeHandler(e, index, 'contact') }}
                                        options={relation}
                                        placeholder={emergencyContactDetailsConfig?.relation?.placeholder}
                                        label={emergencyContactDetailsConfig?.relation?.label}
                                        disabled={emergencyContactDetailsConfig?.relation?.isDisabled}
                                        helperText={contactError.length > 0 && contactError[index] && contactError[index].relationship_id && <Text red> {contactError[index].relationship_id}</Text>}
                                      />
                                      {/* <SearchSelect
                                    name='relationship_id'
                                    value={item.relationship_id}
                                    onChange={(e) => changeHandler(e, index, 'contact')}
                                    options={relation}
                                    error={contactError.length > 0 ? contactError[index] ? contactError[index].relationship_id : "" : ''}
                                    labelText={'Relation'}
                                  /> */}
                                    </Box>
                                    {/* {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].relationship_id : ""}</Text> : ''} */}
                                  </Grid>}
                                  <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {/* <Box pt={'10px'}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'email_id',
                                      value: item.email_id,
                                    }}
                                    handleChange={(e) => changeHandler(e, index, 'contact')}
                                    clientInput
                                    error={contactError.length > 0 ? contactError[index] ? contactError[index].email_id : "" : ''}
                                    labelText={'Email ID (Optional)'} />
                                </Box>
                                {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].email_id : ""}</Text> : ''} */}
                                  </Grid>
                                  <Grid item container lg={12} spacing={2}>
                                    {emergencyContactDetailsConfig?.address_line_one?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Input
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'address_1',
                                          value: item.address_1,
                                          inputProps: { maxLength: emergencyContactDetailsConfig?.address_line_one?.maxLength }
                                        }}
                                        handleChange={(e) => changeHandler(e, index, 'contact')}
                                        clientInput
                                        placeholder={emergencyContactDetailsConfig?.address_line_one?.placeholder}
                                        labelText={emergencyContactDetailsConfig?.address_line_one?.label}
                                        disabled={emergencyContactDetailsConfig?.address_line_one?.isDisabled}
                                        error={contactError.length > 0 ? contactError[index] ? contactError[index].address_1 : "" : ''}
                                      />
                                      {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].address_1 : ""}</Text> : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.address_line_two?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Input
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'address_2',
                                          value: item.address_2,
                                          inputProps: { maxLength: emergencyContactDetailsConfig?.address_line_two?.maxLength }
                                        }}
                                        handleChange={(e) => changeHandler(e, index, 'contact')}
                                        clientInput
                                        error={contactError.length > 0 ? contactError[index] ? contactError[index].address_2 : "" : ''}
                                        placeholder={emergencyContactDetailsConfig?.address_line_two?.placeholder}
                                        labelText={emergencyContactDetailsConfig?.address_line_two?.label}
                                        disabled={emergencyContactDetailsConfig?.address_line_two?.isDisabled}
                                      />
                                      {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].address_2 : ""}</Text> : ''}
                                    </Grid>}
                                  </Grid>
                                  {emergencyContactDetailsConfig?.zip_code?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'zip_code',
                                        value: item.zip_code,
                                        inputProps: { maxLength: emergencyContactDetailsConfig?.zip_code?.maxLength }
                                      }}
                                      handleChange={(e) => changeHandler(e, index, 'contact')}
                                      clientInput
                                      onKeyPress={onNumberOnlyChange}
                                      error={contactError.length > 0 ? contactError[index] ? contactError[index].zip_code : "" : ''}
                                      labelText={item?.zip_code?.length == parseInt(emergencyContactDetailsConfig?.zip_code?.zipcodeLength) ? emergencyContactDetailsConfig?.zip_code?.zipcodeLabel : item?.zip_code?.length == parseInt(emergencyContactDetailsConfig?.zip_code?.pincodeLenght) ? emergencyContactDetailsConfig?.zip_code?.pincodeLabel : emergencyContactDetailsConfig?.zip_code?.label}
                                    />
                                    {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].zip_code : ""}</Text> : ''}
                                  </Grid>}
                                  {emergencyContactDetailsConfig?.city?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: 'city',
                                        value: item.city || '',
                                        inputProps: { maxLength: emergencyContactDetailsConfig?.city?.maxLength }
                                      }}
                                      handleChange={(e) => changeHandler(e, index, 'contact')}
                                      clientInput
                                      error={contactError.length > 0 ? contactError[index] ? contactError[index].city : "" : ''}
                                      placeholder={emergencyContactDetailsConfig?.city?.placeholder}
                                      labelText={emergencyContactDetailsConfig?.city?.label}
                                      disabled={emergencyContactDetailsConfig?.city?.isDisabled}
                                    />
                                    {contactError.length > 0 ? <Text red>{contactError[index] ? contactError[index].city : ""}</Text> : ''}
                                  </Grid>}
                                  {emergencyContactDetailsConfig?.state?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box pt={'10px'}>
                                      <Input
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'state_name',
                                          value: item.state_name || '',
                                        }}
                                        disabled={true || emergencyContactDetailsConfig?.state?.isDisabled}
                                        clientInput
                                        placeholder={emergencyContactDetailsConfig?.state?.placeholder}
                                        labelText={emergencyContactDetailsConfig?.state?.label}
                                      />
                                    </Box>
                                  </Grid>}
                                  {emergencyContactDetailsConfig?.country?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box pt={'10px'}>
                                      <Input
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'country_name',
                                          value: item.country_name || '',
                                        }}
                                        disabled={true || emergencyContactDetailsConfig?.country?.isDisabled}
                                        clientInput
                                        placeholder={emergencyContactDetailsConfig?.country?.placeholder}
                                        labelText={emergencyContactDetailsConfig?.country?.label}
                                      />
                                    </Box>
                                  </Grid>}
                                </Grid>
                              ))
                            }
                            <Grid item lg={12} md={12} sm={12} xs={12} m={'15px 0px 0px 0px !important'}>
                              {state.emergency_contact?.length < 5 && <Button lightBlue onClick={() => addContact('Add')}>Add New Contact</Button>}
                            </Grid>
                          </CardContent>
                        </Card>
                      </TabPanel>
                    </SwipeableViews>
                  }
                  <Grid item container p={{ lg: '10px 25px', md: '10px 25px', sm: '10px 25px', xs: '10px 10px 50px 10px' }} justifyContent={"space-between"}>
                    {
                      (LocalStorage.getEmployeeVerifyId() && mainStep == 0 && value == 0) ?
                        <Button disabled={value == 4 ? finalSubmit : loaderSwipeable} blackCancel onClick={() => { navigate("/employee/verify", { state: { step: 'last' } }) }}>Back</Button>
                        :
                        <Button disabled={value == 4 ? finalSubmit : loaderSwipeable} blackCancel onClick={() => { back() }}>{value == 0 ? 'Cancel' : 'Back'}</Button>
                    }
                    {
                      value == 4 ?
                        <LoadingButton saveNcontinue
                          loading={finalSubmit}
                          onClick={() => handleSubmit()}>
                          {finalSubmit ? "Saving..." : "Save & Continue"}
                        </LoadingButton> :
                        <LoadingButton saveNcontinue loading={loaderSwipeable} onClick={() => handleSubmit()}>Save & Continue</LoadingButton>
                    }
                  </Grid>
                  {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { addContact('Remove') }} />}
                </Grid>
              </Grid> :
              mainStep == 1 ?
                <Documents setInviteData={setInviteData} inviteData={inviteData} handleBack={handleBack} docStepper={docStepper} setDocStepper={setDocStepper} mainStep={mainStep} setMainStep={setMainStep} setSubstepper={setSubstepper} setValue={setValue} value={value} ssn={state.ssn} documentApiConfig={documentsDetailsConfig} /> :
                mainStep == 2 ?
                  <NewBankDetails handleBackBankToDocs={handleBackBankToDocs} inviteData={inviteData} mainStep={mainStep} setMainStep={setMainStep} setValue={setValue} value={value} setDocStepper={setDocStepper} docStepper={docStepper} />
                  : ''
          }
          <ReusablePopup openPopup={openPopup} setOpenPopup={setOpenpopup} white iconHide fixedWidth>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={1}>
                <img src={offBoardSave} alt='noactive' />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} >
                <Text offBoardBodyFont>Your progress will not be saved</Text>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={'center'} mt={3} gap={3}>
                <Button smallBlackOutline onClick={() => { setOpenpopup(false) }}>
                  Cancel
                </Button>
                <Button smallBlue redBackground onClick={() => {
                  LocalStorage.removeRedirectedModule();
                  LocalStorage.removeFromPage(); navigate('/employees'); setOpenpopup(false); LocalStorage.removeEmployeeVerifyId(); LocalStorage.removeEmployeeId()
                }}  >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </ReusablePopup>
        </Grid>
        :
        <Grid container>
          <Box sx={{ marginLeft: "100px" }}>
            <Text mediumViewAmt >Add Employee</Text>
          </Box>
          {NoPermission()}
        </Grid>
      }
    </div >
  )
}

export default OnboardEmployee