import { Avatar, Box, Checkbox, Divider, Grid, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Text from '../../../../components/customText/Text'
import Input from '../../../../components/input/Input'
import { isValid, onCharactersAndNumbersWithSpaceChange, validate_emptyField, validate_goal_amount, validate_charWithSpace, validate_raise_amount, onCurrencyWithTwoDecimalOnlyChangeCurr } from '../../../../components/Validation'
import Select from '../../../../components/select/Select'
import moment from 'moment'
import { addErrorMsg, addLoader, addSuccessMsg, BlackToolTip, capitalize, dateFormat, getCurrencySymbol, NoDataFound, removeLoader } from '../../../../utils/utils'
import LocalStorage from '../../../../utils/LocalStorage'
import FileInput from '../../../../components/muiFileInput/FileInput'
import Search from '../../../../components/select/Select';
import CommonApi from '../../../../apis/CommonApi'
import Button from '../../../../components/customButton/Button'
import AddDeductionType from '../../addSelectForms/AddDeductionType'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup'
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import deductionIcon from '../../../../assets/svg/deductionIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit_2.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/deletered.svg';
import Date from '../../../../components/datePicker/Date'
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi'
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles'
import { ErrorMessages } from '../../../../utils/ErrorMessages'
import DeleteDeductionPopup from '../expenseManagement/DeductionPopup'
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import ExpenseManagement from '../../../../apis/admin/payroll/ExpenseManagement'

function DeductionsDrawer({ summary, getAllEmployees, deductionDrawer, reimbursementDeductionProp, deductionTypeDropDown, closeDeductionDrawer, clickedEmpId }) {
    const classes = PlacementDashboardStyles();
    // eslint-disable-next-line
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const [error, setError] = useState({})
    const [typeOpen, setTypeOpen] = useState(false)
    const [enableForm, setEnableForm] = useState(false)
    const [state, setState] = useState({
        id: "",
        name: "",
        expense_type_id: "",
        date_of_expense: "",
        raised_amount: "",//raised_amount
        is_recurring: false,//is_recurring
        remarks: "",
        recurring_type: "",
        goal_amount: '',
        recurring_start_date: '',
        recurring_count: '',
        date_of_joining: reimbursementDeductionProp?.date_of_joining,
        documents: [{
            document_name: '',
            document_url: '',
            new_document_id: ''
        }],
        employee_id: clickedEmpId,
        expense_effect_on: 1,
        recovered_amount:'',
        cycles_completed:''

    });
    // eslint-disable-next-line
    const [isEditing, setIsEditing] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [deductionTypeOptions, setDeductionTypeOptions] = useState(deductionTypeDropDown)

    const [deleteDeduction, setDeleteDeduction] = useState(false);
    const [deleteSelectedId,setDeleteSelectedId] = useState(null); 
    

    const deductOptions = [
        {
            id: 2,
            value: 'Balancesheet'
        },
        {
            id: 1,
            value: 'Payroll'
        }
    ]
    const reoccurOptions = [
        {
            id: 1,
            value: 'Employment'
        },
        {
            id: 2,
            value: 'Goal Amount'
        }
    ]

    useEffect(() => {
        if (!deductionDrawer) {
            handleDisableForm()
        } else {
            setState((prevState) => ({ ...prevState, date_of_joining: reimbursementDeductionProp?.date_of_joining }))
        }
        // eslint-disable-next-line
    }, [deductionDrawer])

     const handleApproveDeduction = () => {
                const data = {
                    id:deleteSelectedId,
                    request_id:LocalStorage.uid(),
                }
                ExpenseManagement.deleteTransaction(data).then((res) => {
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(res.data.message);
                        setDeleteDeduction(false);  
                        closeDeductionDrawer();
                    }
                    else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
    
    
            const handleCancelDeduction = () => {
                setDeleteDeduction(false);
            }


    const handleChange = (e) => {

        if (e.target.name == 'raised_amount' || e.target.name == 'goal_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value;
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value;
                            if (e.target.name == 'raised_amount') {
                                if (state.goal_amount !== '' && value !== '') {
                                    state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                                } else {
                                    state.recurring_count = ''
                                }
                            } else {
                                if (value !== '' && state.raised_amount !== '') {
                                    state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                                } else {
                                    state.recurring_count = ''
                                }
                            }
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value;
                if (e.target.name == 'raised_amount') {
                    if (state.goal_amount !== '' && value !== '') {
                        state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                    } else {
                        state.recurring_count = ''
                    }
                    if (parseInt(value) <= parseInt(state.goal_amount)) {
                        error['goal_amount'] = ''
                        setError({ ...error })
                    }
                } else {
                    if (value !== '' && state.raised_amount !== '') {
                        state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                    } else {
                        state.recurring_count = ''
                    }
                    if (parseInt(value) >= parseInt(state.raised_amount)) {
                        error['raised_amount'] = ''
                        setError({ ...error })
                    }
                }
            }
            setState({ ...state }, handleValidate(e));
        } else if (e.target.name == 'is_recurring') {
            state[e.target.name] = e.target.checked
            setState({ ...state, recurring_type: '', goal_amount: '', recurring_count: '', recurring_start_date: '' })
        }
        else {
            state[e.target.name] = e.target.name === 'name' ? capitalize(e.target.value) : e.target.value
            setState({ ...state })
        }
        handleValidate(e)
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value)
                break;
            case "expense_type_id":
                error.expense_type_id = validate_emptyField(input.value)
                break;
            case "date_of_expense":
                error.date_of_expense = validate_emptyField(input.value)
                break;
            case "expense_effect_on":
                error.expense_effect_on = validate_emptyField(input.value)
                break;
            case "raised_amount":
                error.raised_amount = validate_raise_amount(input.value, state.goal_amount, state.recurring_type)
                break;
            case "recurring_type":
                error.recurring_type = validate_emptyField(input.value)
                break;
            case "goal_amount":
                error.goal_amount = validate_goal_amount(state.raised_amount, input.value);
                break;
            case "recurring_start_date":
                error.recurring_start_date = validate_emptyField(input.value)
                break;
            case "recurring_count":
                error.recurring_count = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setError({ ...error })
    }
    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < Number(25)) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("expense-management", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            state.documents[0].new_document_id = res.data.data.id
                            state.documents[0].document_name = e.target.files[0].name
                            state.documents[0].document_url = res.data.data.document_url
                            setState({ ...state })
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG or PDF format only.");
        }
    }

    const handleChangeDate = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
        handleValidate(event);

    }

    const handleDeleteOpen = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].document_name = ''
        state.documents[0].document_path = ''
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    const handleDeductionTypes = () => {
        GeneratePayrollApi.DeductionTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductionTypeOptions(res.data.data);
            }
        })
    }

    const handleEnableForm = () => {
        setEnableForm(true);
        setIsEditing(false);
    }

    const handleDisableForm = () => {
        setEnableForm(false);
        setState({
            name: "",
            expense_type_id: "",
            date_of_expense: "",
            expense_effect_on: 1,
            raised_amount: "",
            is_recurring: false,
            remarks: "",
            recurring_type: "",
            goal_amount: '',
            recurring_start_date: '',
            recurring_count: '',
            date_of_joining: '',
            documents: [
                {
                    document_name: '',
                    document_url: '',
                    new_document_id: ''
                }
            ],
            recovered_amount:'',
            cycles_completed:''
        })
        setError({});
        setIsEditing(false);
    }




    const validateAll = () => {
        const { name, expense_type_id, date_of_expense, expense_effect_on, raised_amount, recurring_type, goal_amount, recurring_start_date, recurring_count } = state
        let errors = {}
        const dateOfJoining = moment(moment(state.date_of_joining, dateFormat()).format(dateFormat()), dateFormat());
        const today = moment(moment().format(dateFormat()), dateFormat());
        const givenDate = moment(date_of_expense, dateFormat());

        errors.name = validate_charWithSpace(name);
        errors.expense_type_id = validate_emptyField(expense_type_id);
        errors.date_of_expense = date_of_expense == '' ? 'This field is required' : givenDate.isBetween(dateOfJoining, today, null, '[]') ? validate_emptyField(date_of_expense) : 'Invalid date of Expense, Date must be between Date of Joining and Current date.';
        errors.expense_effect_on = validate_emptyField(expense_effect_on);
        errors.raised_amount = validate_raise_amount(raised_amount, goal_amount, recurring_type);
        errors.recurring_type = state.is_recurring ? validate_emptyField(recurring_type) : '';
        errors.goal_amount = state.recurring_type == 2 ? validate_goal_amount(raised_amount, goal_amount) : '';
        errors.recurring_start_date = state.recurring_type == 2  && state.is_recurring ?
                 (moment(state.recurring_start_date).isBefore(moment().format(dateFormat()))) ?
                  isEditing ? '':'Recovery date should not be the past date' : validate_emptyField(recurring_start_date) :
                   '';
        errors.recurring_count = state.recurring_type == 2 ? validate_emptyField(recurring_count) : '';

        setError({ ...errors })
        return errors;
    }
    const handleSave = () => {
        // if (state.is_recurring && state.recurring_type == 2) {
        //     if (moment(state.recurring_start_date).isBefore(moment().format(dateFormat()))) {
        //         error['recurring_start_date'] = 'Recovery date should not be the past date'
        //         setError({ ...error })
        //         addErrorMsg(ErrorMessages.genericMessage);
        //         return
        //     }
        // }
        const errors = validateAll();
        if (isValid(errors)) {
            state['entity_type'] = 2
            state['employee_id'] = clickedEmpId;
            state['id'] = state?.id ? state?.id : "";
            setBtnLoader(true);
            GeneratePayrollApi.SaveDeductionOrReimbursement(state).then((res) => {
                setBtnLoader(false);
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(res.data.message);
                    closeDeductionDrawer()
                    getAllEmployees()
                } else {
                    addErrorMsg(res.data.message);

                }
            })

        } else {
            const checkForName = (obj) => {
                // Check if the error message contains any of the required strings.
                return Object.values(obj).some((value) =>
                    value === "This field is required" || value === "Deduction amount is required"
                );
            };

            if (checkForName(errors)) {
                addErrorMsg(ErrorMessages.genericMessage);
            } else {
                addErrorMsg(ErrorMessages.fieldValidate);
                }
            let s1 = { error }
            s1 = errors
            setError(s1);
            }
        }

    const handleEdit = (item) => {
        setState((prevState) => ({
            ...prevState, name: item?.name,
            expense_type_id: item?.expense_type_id,
            date_of_expense: item?.date_of_expense,
            expense_effect_on: 1,
            raised_amount: item?.raised_amount,
            documents: [{
                ...item?.expense_documents[0],
                new_document_id: '',
                document_key: "expense-management",
                aws_s3_status: 1
            }],
            is_recurring: item?.is_recurring,
            remarks: item?.remarks,
            recurring_type: item?.recurring_type,
            goal_amount: item?.goal_amount ? item?.goal_amount : "",
            recurring_start_date: item?.recurring_start_date,
            recurring_count: item?.recurring_count,
            id: item?.id,
            recovered_amount:item?.recovered_amount,
            cycles_completed:item?.cycles_completed

        }))
        handleEnableForm();
        setIsEditing(true);
    }

    const [showEachDeduction, setShowDeduction] = useState(false);
    const [eachDeduction, setEachDeduction] = useState({})

    const handleEachDeduction = (summaryValue, item) => {
        if (summaryValue) {
            setShowDeduction(true)
            setEachDeduction(item)
        }
    }



    return (
        <div>
            <Box width={{ lg: '800px', md: "800px", sm: "700px", xs: "370px" }} height={'100vh'} p={3} sx={{ position: 'relative' }}>
                <Grid container item lg={12} md={12} xs={12} sm={12} alignItems={"center"} justifyContent={"space-between"} py={2}>
                    <Text black18px>{!summary ? isEditing ? 'Edit': 'Add' :isEditing ? 'Edit': 'Added'} Deduction</Text>

                    {
                        ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                            <>{!summary ? <> {!enableForm ? <Button save14 onClick={handleEnableForm}>Add New</Button> : ""}</> : ''}</> : ''
                    }



                </Grid>
                {enableForm ?
                    <Grid container spacing={3} columnSpacing={4}>

                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'name',
                                    value: state.name,
                                    inputProps: { maxLength: 50 }
                                }}
                                clientInput
                                labelText={'Name'}
                                handleChange={(e) => handleChange(e)}
                                onKeyPress={onCharactersAndNumbersWithSpaceChange}

                                error={error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}

                            />
                            {error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Search
                                name='expense_type_id'
                                value={state.expense_type_id}
                                options={deductionTypeOptions}
                                label='Type'
                                onChange={(e) => handleChange(e, 'expense_type_id')}
                                buttonHandler={() => setTypeOpen(true)}
                                buttonName='New Type'
                                showButton
                                error={error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}

                            />
                            {error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Date
                                labelText={"Date of Expense"}
                                name='date_of_expense'
                                value={state.date_of_expense}
                                maxDate={moment().format(dateFormat())}
                                minDate={moment(state.date_of_joining, dateFormat()).format(dateFormat())}

                                onChange={(e) => handleChangeDate(e, "date_of_expense")}
                                error={error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}

                            />
                            {error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Select
                                name='expense_effect_on'
                                value={state.expense_effect_on}
                                onChange={handleChange}
                                options={deductOptions}
                                label={'Deduct From'}
                                error={error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}
                                // disabled={true}
                            // helperText={error.placement_id ? <Text errorText> {error.placement_id ? error.placement_id : ""}</Text> : ""}
                            />
                            {error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'raised_amount',
                                    value: currency + "" +state.raised_amount,
                                }}
                                clientInput
                                onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                                labelText={'Deduction Amount'}
                                handleChange={(e) => { handleChange(e) }}
                                error={error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}

                            />
                            {error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <FileInput
                                name='supporting_document'
                                FileName={state.documents[0].document_name}
                                handleChange={(e) => uploadDocs(e)}
                                label={'Supporting Document (Optional)'}
                                handleDelete={() => handleDeleteOpen()}
                                actionState={state.documents && state.documents.length > 0 && state.documents[0].document_name ? 1 : ''}
                                documentUrl={state.documents && state.documents.length > 0 && state.documents[0].document_url}
                                state={state.documents[0]}
                            // state={item}
                            />
                            <Text smallGrey10>File size should not exceed 25mb</Text>
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} pt={4} alignItems={"center"}>
                            <Checkbox
                                sx={{ paddingLeft: "0px !important" }}
                                size='small'
                                name="is_recurring"
                                checked={state.is_recurring}
                                value={state.is_recurring}
                                onChange={(e) => { handleChange(e) }}
                                icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />}
                            />
                            <Text blackFont14>Recurring Deduction</Text>
                        </Grid>
                        {
                            state.is_recurring &&
                            <Grid item lg={6} xs={6}>
                                <Select
                                    name='recurring_type'
                                    value={state.recurring_type}
                                    onChange={(e) => { handleChange(e) }}
                                    options={reoccurOptions}
                                    label={'Re-occur Till'}
                                    helperText={
                                        error.recurring_type ?
                                            <Text red>{error.recurring_type ? error.recurring_type : ''}</Text> : ''
                                    }
                                />
                            </Grid>
                        }
                        { 
                            state.recurring_type == 2 && state.is_recurring &&
                            <>
                                
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Date
                                        labelText={'Recovery Start Date'}
                                        name='recurring_start_date'
                                        height='54px'
                                        value={state.recurring_start_date}
                                        onChange={(e) => handleChangeDate(e, 'recurring_start_date')}
                                        minDate={isEditing ? !state.recurring_start_date ? moment().format(dateFormat()) : moment(state.recurring_start_date,dateFormat()).format(dateFormat()) :  moment().format(dateFormat())}
                                        error={error.recurring_start_date ? <Text red>{error.recurring_start_date ? error.recurring_start_date : ''}</Text> : ''}
                                    />
                                    {error.recurring_start_date ? <Text red>{error.recurring_start_date ? error.recurring_start_date : ''}</Text> : ''}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'goal_amount',
                                            value:  currency + "" +state.goal_amount,
                                        }}
                                        handleChange={(e) => handleChange(e)}
                                        // onKeyPress={onNumberFloatCurrency}
                                        clientInput
                                        onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}

                                        labelText={'Goal Amount'}
                                        error={error.goal_amount}
                                    />
                                    {
                                        error.goal_amount ?
                                            <Text red>{error.goal_amount ? error.goal_amount : ''}</Text> : ''
                                    }
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'recovered_amount',
                                            value: state.recovered_amount,
                                        }}
                                        //handleChange={(e) => handleChange(e)}
                                        disabled
                                        clientInput
                                        labelText={'Recovered Amount'}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'recurring_count',
                                            value: state.recurring_count,
                                        }}
                                        //handleChange={(e) => handleChange(e)}
                                        disabled
                                        clientInput
                                        labelText={'No of pay cycles'}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'cycles_completed',
                                            value: state.cycles_completed,
                                        }}
                                        //handleChange={(e) => handleChange(e)}
                                        disabled
                                        clientInput
                                        labelText={'No of Cycles Completed'}
                                    />
                                </Grid>

                            </>
                        }
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'remarks',
                                    value: state.remarks,
                                    inputProps: { maxLength: 255 }
                                }}
                                clientInput
                                labelText={'Remarks (Optional)'}
                                handleChange={handleChange}
                                error={error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}
                            />
                            {error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} justifyContent={"end"} gap={3}>
                            <Button disabled={btnLoader} onClick={handleDisableForm} cancelBtn sx={{ minWidth: "60px !important", height: '40px !important' }}>Cancel</Button>
                            <Button disabled={btnLoader} saveBtn sx={{ minWidth: "60px !important" }} onClick={handleSave}>Save</Button>
                        </Grid>

                    </Grid>
                    : <>
                        {showEachDeduction ? <>
                            <Box sx={{ minHeight: '80vh', overflowY: 'scroll' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Name</Text>
                                            <Text mediumBlack14>
                                                {
                                                    eachDeduction.name && eachDeduction.name.length > 16 ?
                                                        <BlackToolTip title={eachDeduction.name} placement="right" arrow>
                                                            {eachDeduction.name.slice(0, 16) + (eachDeduction.name.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : eachDeduction.name
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Type</Text>
                                            <Text mediumBlack14>{eachDeduction.expense_type_id ? eachDeduction.expense_type_id : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Date od Expense</Text>
                                            <Text mediumBlack14>{eachDeduction.date_of_expense ? eachDeduction.date_of_expense : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Add To</Text>
                                            <Text mediumBlack14>{eachDeduction.added_to ? eachDeduction.added_to : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Reimbursement Amount</Text>
                                            <Text mediumBlack14>{eachDeduction.raised_amount ? `$ ${eachDeduction.raised_amount}` : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Supporting Document</Text>
                                            <Text mediumBlack14>{eachDeduction?.documents?.length > 0 ? eachDeduction?.documents[0]?.document_name : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' flexDirection='column' gap={1}>
                                            <Text mediumGreyHeader>Recurring Deduction</Text>
                                            <Text mediumBlack14>{eachDeduction.is_recurring == false ? 'No' : eachDeduction.is_recurring == true ? 'Yes' : '-'}</Text>
                                        </Box>
                                    </Grid>
                                    {
                                        eachDeduction.payment_mode !== '' ?
                                            <>
                                                {
                                                    eachDeduction.payment_mode == 'Payroll cycle' ?
                                                        <Grid item xs={6}>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text mediumGreyHeader>Payment Period</Text>
                                                                <Text mediumBlack14>{eachDeduction.pay_period ? eachDeduction.pay_period : '-'}</Text>
                                                            </Box>
                                                        </Grid> :
                                                        <Grid item xs={6}>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text mediumGreyHeader>Payment Date</Text>
                                                                <Text mediumBlack14>{eachDeduction.pay_date ? eachDeduction.pay_date : '-'}</Text>
                                                            </Box>
                                                        </Grid>
                                                }
                                            </>
                                            : ''
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display='flex' flexDirection='column' gap={1}>
                                        <Text mediumGreyHeader>Re-Occur Till</Text>
                                        <Text mediumBlack14>{eachDeduction.recurring_type ? eachDeduction.recurring_type : '-'}</Text>
                                    </Box>
                                </Grid>
                                {
                                    eachDeduction.is_recurring == true && eachDeduction.recurring_type == 'Goal Amount' ?
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={1}>
                                                    <Text mediumGreyHeader>Goal Amount</Text>
                                                    <Text mediumBlack14>{eachDeduction.goal_amount ? eachDeduction.goal_amount : '-'}</Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={1}>
                                                    <Text mediumGreyHeader>Recovery Start Date</Text>
                                                    <Text mediumBlack14>{eachDeduction.recurring_start_date ? eachDeduction.recurring_start_date : '-'}</Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box display='flex' flexDirection='column' gap={1}>
                                                    <Text mediumGreyHeader>No of Pay Cycles</Text>
                                                    <Text mediumBlack14>{eachDeduction.recurring_count ? eachDeduction.recurring_count : '-'}</Text>
                                                </Box>
                                            </Grid>
                                        </Grid> :
                                        ''
                                }
                                <Grid item xs={12}>
                                    <Box display='flex' flexDirection='column'>
                                        <Text mediumGreyHeader>Remarks</Text>
                                        <Text
                                            className={classes.remarksText}
                                            sx={{
                                                wordBreak: 'break-all',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {eachDeduction.remarks ? eachDeduction.remarks : '-'}
                                        </Text>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box mt={1} justifyContent='end' textAlign='end'>
                                <Button saveBtn400 onClick={() => setShowDeduction(false)}>Done</Button>
                            </Box>




                        </> : <>
                            {
                                reimbursementDeductionProp?.deductions?.length == 0 ?
                                    NoDataFound() : ''
                            }
                            <Box sx={{ overflow: 'auto', height: '72vh' }}>
                                {reimbursementDeductionProp?.deductions?.map((item, index) => (
                                    <>
                                        <Box px={1} py={3} key={index} onClick={() => handleEachDeduction(summary, item)} sx={{ cursor: 'pointer' }}>
                                            <Grid container >
                                                <Grid item lg={6} md={6} sm={6} xs={12} textAlign='start'>
                                                    <Box display='flex' alignItems='center' gap={2}>
                                                        <Avatar
                                                            src={deductionIcon}
                                                            alt={"deductionIcon"}
                                                            small
                                                        />
                                                        <Box>
                                                            <Text mediumBoldBlack noWrap style={{ margin: '4px 0' }}>
                                                                {
                                                                    item.name && item.name.length > 16 ?
                                                                        <BlackToolTip title={item.name} placement="right" arrow>
                                                                            {item.name.slice(0, 16) + (item.name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : item.name
                                                                }

                                                            </Text>
                                                            <Text largeGrey14>{item.type && item.type.length > 16 ?
                                                                <BlackToolTip title={item.type} placement="right" arrow>
                                                                    {item.type.slice(0, 16) + (item.type.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                : item.type} &nbsp;&#9679;&nbsp;{item?.added_to}</Text>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                                                    <Box display='flex' alignItems='center' justifyContent="end" height={"100%"} gap={2}>
                                                        <Text mediumBoldBlack >{getCurrencySymbol()} {item?.raised_amount}</Text>

                                                        {
                                                            ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                                                                <>{!summary ?<> <IconButton onClick={() => handleEdit(item)}> <EditIcon /> </IconButton> <IconButton onClick={() =>{
                                                                                                                                    setDeleteDeduction(true);setDeleteSelectedId(item.id)

                                                                }}> <DeleteIcon /> </IconButton>   </>: ''}</> : ''
                                                        }




                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {index < reimbursementDeductionProp?.deductions?.length - 1 && <Divider />}
                                    </>
                                ))}
                            </Box>
                            {(reimbursementDeductionProp?.deductions?.length > 0) && <>
                                <Divider />
                                <Box display='flex' flexDirection='row' justifyContent='space-between' my={2}>
                                    <Text largeBlack>Total Deductions </Text>
                                    <Text largeBlack>$ {reimbursementDeductionProp?.deduction_cards?.total_deduction}</Text>
                                </Box>
                                <Divider />
                                <Box mt={1} justifyContent='end' textAlign='end'>
                                    <Button saveBtn400 onClick={() => closeDeductionDrawer()}>Done</Button>
                                </Box>
                            </>}

                        </>}</>

                }


            </Box>

            <ReusablePopup iconHide openPopup={typeOpen} setOpenPopup={setTypeOpen} white fullWidth>
                <AddDeductionType open={typeOpen} closePopup={setTypeOpen} payTypeApi={handleDeductionTypes} />
            </ReusablePopup>

            <DeleteDeductionPopup
                openPopup={deleteDeduction}
                setOpenPopup={setDeleteDeduction}
                deleteAlertImage={deleteAlert}
                titleText={ "Deleting Deduction!"}
                descriptionText={  "It looks like you're deleting a deduction. Are you sure do you want to proceed."}
                cancelButtonText={ "Cancel"}
                ApproveButtonText={"Yes, Delete"}
                handleCancel={handleCancelDeduction}
                handleApprove={handleApproveDeduction}
            />
        </div>
    )
}

export default DeductionsDrawer
