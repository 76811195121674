import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import emergencyUser from '../../../../assets/svg/emergencyUser.svg'
import EditIcon from '../../../../assets/svg/newEdit.svg'
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select';// eslint-disable-next-line
import { address_validation, isValid, validate_charWithSpace, validate_charWithSpace_nonMandatory, validate_city, validate_city_optional, validate_empty_address, validate_emptyField, validate_optional_contact, validate_usContact, validate_zipcode, validate_zipcode_optional, validates_emailId, validates_emailId_optional, validate_input_fields } from '../../../../components/Validation';
import CommonApi from '../../../../apis/CommonApi';
import LocalStorage from '../../../../utils/LocalStorage';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import { BlackToolTip, addErrorMsg, addSuccessMsg, capitalize, checkZipcode } from '../../../../utils/utils';
import NoDataImg from "../../../../assets/images/no-data.png";
import Sure from '../../../../assets/svg/placementPop.svg'
import deleteIcon from '../../../../assets/employee/deletewithBg.svg'
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import Userplus from '../../../../assets/svg/plus.svg';
import Zipcode from '../../../admin/Zipcode';
import { useZipcodeAPICall } from '../../../admin/ZipcodeCommon';
import { ErrorMessages } from '../../../../utils/ErrorMessages';

export default function ViewEmergency(props) {// eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
    const emergencyContactDetailsConfig = props.generalDetailsConfig?.emergencyContactDetails;
    const [edit, setEdit] = useState(false) // eslint-disable-next-line
    const [employee, setEmployee] = useState(props.employee)// eslint-disable-next-line    
    const [relation, setRelation] = useState([]);
    const [popup, setPopup] = useState(false)
    const [indexCard, setIndexCard] = useState(false)
    const [details, setDetails] = useState({
        relationship_id: '',
        name: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        state_id: '',
        country_id: '',
        email_id: ''
    })
    const [openPopup, setOpenpopup] = useState(false)
    const [error, setError] = useState({
        relationship_id: '',
        name: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        state_id: '',
        country_id: '',
        email_id: ''
    })
    const [state, setState] = useState({
        relationship_id: '',
        name: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        city: '',
        zip_code: '',
        state_id: '',
        country_id: '',
        email_id: '',
        clear_all: false
    })
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [deletePopup, setdeletePopup] = useState(false);

    useEffect(() => {
        setEmployee(props.employee)
        setIndexCard(props.employee.emergency_contacts.length + 1)
    }, [props])

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (popup) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [popup]);

    const fetchZipcodeData = useZipcodeAPICall({ setState, setError });

    const handleChange = (e) => {
        state.clear_all = false
        if (e.target.name == 'zip_code') {
            fetchZipcodeData(e.target.value)
        }
        if (e.target.name == 'contact_number' || e.target.name === 'ssn' || e.target.name === 'alternate_contact_number') {
            convertFormat(e)
        }
        else if (e.target.name == 'name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else {
            if (e.target.name === 'country_id') {
                state.country_id = e.target.value
                state.state_id = ''
                state.city = ''
                state.zip_code = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e))
        }
    }

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (last == '0000') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (middle == '00') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidate(e));
            if (name == 'ssn') {
                if (first == '000' || first == '666' || first == '900') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "relationship_id":
                error.relationship_id = employee.employment_type_id === 2 ? validate_input_fields(input.value, emergencyContactDetailsConfig?.relationship_id) : '';
                break;
            case "name":
                error.name = employee.employment_type_id === 2 ? validate_input_fields(input.value, emergencyContactDetailsConfig?.name) : validate_input_fields(input.value, emergencyContactDetailsConfig?.name);
                break;
            case "email_id":
                error.email_id = employee.employment_type_id === 2 ? validates_emailId(input.value) : validates_emailId_optional(input.value);
                break;
            case "contact_number":
                error.contact_number = usMobileNumberValidator.test(input.value) ? '' : employee.employment_type_id === 2 ? validate_usContact(input.value, 'Mobile Number') : validate_optional_contact(input.value, 'Contact ');
                break;
            case "address_1":
                error.address_1 = validate_input_fields(input.value, emergencyContactDetailsConfig?.address_1);
                break;
            case "address_2":
                error.address_2 = validate_input_fields(input.value, emergencyContactDetailsConfig?.address_2);
                break;
            case "city":
                error.city = employee.employment_type_id === 2 ? validate_city(input.value) : validate_city_optional(input.value);
                break;
            case "state_id":
                error.state_id = employee.employment_type_id === 2 ? validate_emptyField(input.value) : '';
                break;
            case "country_id":
                error.country_id = employee.employment_type_id === 2 ? validate_emptyField(input.value) : '';
                break;
            case "zip_code":
                error.zip_code = employee.employment_type_id === 2 ? validate_zipcode(input.value, state.country_id) : validate_zipcode_optional(input.value, state.country_id);
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const relationList = () => {
        CommonApi.relation(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setRelation(res.data.data);
            }
        })
    }

    const openEmergency = (item, index) => {
        setPopup(true);
        setDetails(item);
        setIndexCard(index + 1)
        relationList()
        setState({
            ...item
        })
    }

    const addNew = () => {
        setPopup(true);
        setEdit(true)
        relationList()
        setIndexCard(props.employee.emergency_contacts.length == 0 ? 1 : props.employee.emergency_contacts.length + 1)
    }

    const clearAll = () => {
        setState({
            ...state,
            relationship_id: '',
            name: '',
            contact_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: '',
            clear_all: true
        })
        setError({
            ...error,
            relationship_id: '',
            name: '',
            contact_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: ''
        })
        setclearPopup(false)
    }

    const ValidateAll = async () => {
        const { relationship_id, name, contact_number, address_1, state_id, country_id, city, zip_code, address_2 } = state
        error.relationship_id = validate_emptyField(relationship_id);
        error.name = validate_charWithSpace(name);
        error.contact_number = validate_usContact(contact_number, 'Mobile Number');
        error.address_1 = address_validation(address_1);
        error.address_2 = validate_empty_address(address_2);
        error.state_id = validate_emptyField(state_id);
        error.country_id = validate_emptyField(country_id);
        error.city = validate_city(city);
        error.zip_code = await checkZipcode(zip_code)
        setError({ ...error })
    }

    const submitForm = () => {
        if (employee.employment_type_id === 2) {
            if (state.id && state.clear_all) { }
            else {
                ValidateAll()
            }
        }
        if (state.id) {
            let check_true = employee.emergency_contacts.length > 2 ? state.clear_all : false
            if (check_true || isValid(error)) {
                let data = {}
                data['emergency_contact'] = [state]
                data['request_id'] = LocalStorage.uid()
                data['employee_id'] = employee.id
                // console.log(data, 'data')
                EmployeesApi.updateEmployeeEmergencyContactConsultant(data).then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (state.clear_all) {
                            addSuccessMsg('Emergency contact deleted successfully')
                        }
                        else {
                            addSuccessMsg('Emergency contact updated successfully')
                        }
                        props.getIndex()
                        cancelForm()
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                addErrorMsg(ErrorMessages.genericMessage)
            }

        } else {
            if (isValid(error)) {
                let data = {}
                data['emergency_contact'] = [state]
                data['request_id'] = LocalStorage.uid()
                data['employee_id'] = employee.id
                EmployeesApi.storeEmployeeEmergencyContactConsultant(data).then((res) => {
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg('Emergency contact added successfully')
                        props.getIndex()
                        cancelForm()
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                addErrorMsg(ErrorMessages.genericMessage)
            }
        }
    }

    const cancelForm = () => {
        setDetails({});
        setPopup(false);
        setEdit(false);
        setState({
            relationship_id: '',
            name: '',
            contact_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: ''
        })
        setError({
            relationship_id: '',
            name: '',
            contact_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            country_id: '',
            email_id: ''
        })
    }

    const deleteContact = () => {
        state.clear_all = true
        let data = {}
        data['emergency_contact'] = [state]
        data['request_id'] = LocalStorage.uid()
        data['employee_id'] = employee.id
        // console.log(data, 'data')
        EmployeesApi.updateEmployeeEmergencyContactConsultant(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg('Emergency contact deleted successfully')
                props.getIndex()
                cancelForm()
                setdeletePopup(false)
            }
            else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const theme = useTheme();

    return (
        <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Grid item lg={10} md={8} sm={6} xs={7}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Emergency Contacts
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={5} container justifyContent={'end'}>
                {
                    employee.emergency_contacts === null ? '' :
                        employee.is_off_boarding_initiated === false ?
                            <>
                                {(employee.emergency_contacts !== null && employee.emergency_contacts.length > 4) ? '' :
                                    employee.emergency_contacts.length == 0 ? '' :
                                        <Box p={1} pr={0}>
                                            <Button finishFilledQuicksand sx={{ width: '146px !important', [theme.breakpoints.down('sm')]: { width: "fit-content !important", height: "34px !important", font: "12px Quicksand !important" } }} onClick={() => { if (props.formEdit) { setOpenpopup(true) } else { addNew() } }}><img src={Userplus} alt='plus' /> Add New</Button>
                                        </Box>}
                            </>
                            : ''


                }
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12} pt={4} pb={2}>
                <Box display='flex' flexDirection='row' direction='row' sx={{ overflowX: 'scroll' }}>
                    {
                        (employee.emergency_contacts !== null && employee.emergency_contacts.length > 0) ? employee.emergency_contacts.map((item, index) => (
                            <Box minWidth={'250px'} mr={2} onClick={() => { openEmergency(item, index) }} minHeight={'118px'} sx={{ background: '#F9FCFF', borderRadius: '8px', padding: '15px', cursor: 'pointer' }}>
                                <Grid container xs={12}>
                                    <Grid container xs={1}></Grid>
                                    <Grid container xs={2}>
                                        <img src={emergencyUser} alt='i' />
                                    </Grid>
                                    <Grid container xs={9} pl={2}>
                                        <Stack direction='column'>
                                            <Text boldBlackfont16>{item.name}</Text>
                                            <Text mediumBoldBlack400 sx={{ color: '#092333 !important' }}>{item.relationship_name}</Text>
                                        </Stack>
                                    </Grid>
                                    <Grid container xs={4}></Grid>
                                    <Grid xs={8} pt={1.5}>
                                        <Text mediumBoldBlack400 sx={{ color: '#525252 !important' }}>{item.contact_number}</Text>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                            :
                            <Box sx={{ height: "48vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                        <img src={NoDataImg} alt='no-data' />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                        <Text largeBlack>No Data Found </Text>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                        <Text mediumLabel sx={{ fontSize: '14px !important' }}>Click on add new contact to add emergency </Text>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'}>
                                        <Text mediumLabel sx={{ fontSize: '14px !important' }}>contact details to this employee </Text>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                        <Button finishFilledQuicksandLargeWidth onClick={() => { if (props.formEdit) { setOpenpopup(true) } else { addNew() } }}> <img src={Userplus} alt='plus' /> Add New Contact</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                    }
                </Box>
            </Grid>
            <ReusablePopup
                openPopup={popup}
                setOpenPopup={setPopup}
                onClose={() => { if (edit === false) { setPopup(false); cancelForm() } }}
                iconHide
                white
                fullWidth
            >
                <>
                    {
                        edit ?
                            <Grid container lg={12} md={12} sm={12} xs={12}>
                                <Grid item sm={8} md={10} lg={10} xs={12}>
                                    <Box p={1} pt={0}>
                                        <Text largeBlue>
                                            Emergency Contacts - {indexCard} {employee.employment_type_id != 2 ? '(Optional)' : ''}
                                        </Text>
                                    </Box>
                                </Grid>
                                {
                                    employee.is_off_boarding_initiated === false ?
                                        <Grid item lg={2} md={2} sm={4} xs={12}>
                                            {
                                                edit === false &&
                                                <Stack direction={'row'}>
                                                    <Box p={1} mt={-2} pr={0}>
                                                        <img src={deleteIcon} alt='delete' style={{ cursor: 'pointer' }} onClick={() => { setdeletePopup(true) }} />
                                                    </Box>
                                                    <Box p={1} mt={-2} pr={0}>
                                                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { if (props.formEdit) { setOpenpopup(true) } else { setEdit(true) } }} />
                                                    </Box>
                                                </Stack>
                                            }
                                        </Grid>
                                        :
                                        <Grid item sm={1}></Grid>
                                }
                                {emergencyContactDetailsConfig?.name?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2}>

                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'name',
                                                value: state.name,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.name?.maxLength }
                                            }}
                                            handleChange={(e) => handleChange(e)}
                                            clientInput
                                            error={error.name}
                                            placeholder={emergencyContactDetailsConfig?.name?.placeholder}
                                            disabled={emergencyContactDetailsConfig?.name?.isDisabled}
                                            labelText={emergencyContactDetailsConfig?.name?.label} />
                                        <Text red>{error.name}</Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.relationship_id?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Select
                                            name='relationship_id'
                                            value={state.relationship_id}
                                            onChange={handleChange}
                                            options={relation}
                                            label={emergencyContactDetailsConfig?.relationship_id?.label}
                                            placeholder={emergencyContactDetailsConfig?.relationship_id?.placeholder}
                                            disabled={emergencyContactDetailsConfig?.relationship_id?.isDisabled}
                                            error={error.relationship_id}
                                        />
                                        <Text red>{error.relationship_id}</Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.contact_number?.isVisible &&
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'contact_number',
                                                value: state.contact_number,
                                                inputProps: { maxLength: 12 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            error={error.contact_number}
                                            labelText={emergencyContactDetailsConfig?.contact_number?.label}
                                            placeholder={emergencyContactDetailsConfig?.contact_number?.placeholder}
                                            disabled={emergencyContactDetailsConfig?.contact_number?.isDisabled}
                                        />
                                        <Text red>{error.contact_number}</Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.address_1?.isVisible &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_1',
                                                value: state.address_1,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.address_1?.maxLength }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            error={error.address_1}
                                            labelText={emergencyContactDetailsConfig?.address_1?.label}
                                            placeholder={emergencyContactDetailsConfig?.address_1?.placeholder}
                                            disabled={emergencyContactDetailsConfig?.address_1?.isDisabled}
                                        />
                                        <Text red>{error.address_1}</Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.address_2?.isVisible &&
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_2',
                                                value: state.address_2,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.address_2?.maxLength }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            error={error.address_2}
                                            labelText={emergencyContactDetailsConfig?.address_2?.label}
                                            placeholder={emergencyContactDetailsConfig?.address_2?.placeholder}
                                            disabled={emergencyContactDetailsConfig?.address_2?.isDisabled} />
                                        <Text red>{error.address_2}</Text>
                                    </Box>
                                </Grid>}

                                <Zipcode state={state} error={error} handleChange={handleChange} columnLength={6}  apiConfig={emergencyContactDetailsConfig?.location}  />

                                <Grid item lg={4} pt={2} md={4} sm={6} xs={12}>
                                    <Button cancelOutlineQuicksand onClick={() => { cancelForm() }}>Cancel</Button>
                                </Grid>
                                <Grid item container lg={8} justifyContent={'flex-end'} pt={2} md={8} sm={6} xs={12}>
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <Button clearAllRed onClick={() => setclearPopup(true)}>Clear all</Button>
                                        <Button finishFilledQuicksand onClick={() => { submitForm() }}>Save</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <Grid container >
                                <Grid item sm={9.5} md={9.5} lg={9.5} xs={12}>
                                    <Box p={1} pt={0}>
                                        <Text largeBlue>
                                            Emergency Contacts - {indexCard}
                                        </Text>
                                    </Box>
                                </Grid>
                                {
                                    employee.is_off_boarding_initiated === false ?
                                        <Grid item sm={2.5} md={2.5} lg={2.5} xs={12} pt={{ xs: 2 }}>
                                            {
                                                edit === false &&
                                                <Stack direction={'row'}>
                                                    {employee.emergency_contacts !== null && employee.employment_details.employment_type_id === 2 && employee.emergency_contacts.length > 2 ?
                                                        <Box p={1} mt={-2} pr={0}>
                                                            <img height={'42px'} src={deleteIcon} alt='delete' style={{ cursor: 'pointer' }} onClick={() => { setdeletePopup(true) }} />
                                                        </Box>
                                                        :
                                                        employee.employment_details.employment_type_id !== 2 ?
                                                            <Box p={1} mt={-2} pr={0}>
                                                                <img height={'42px'} src={deleteIcon} alt='delete' style={{ cursor: 'pointer' }} onClick={() => { setdeletePopup(true) }} />
                                                            </Box>
                                                            :
                                                            <Box p={1} width={'52px'} mt={-2} pr={0}></Box>
                                                    }
                                                    <Box p={1} mt={-2} pr={0}>
                                                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { if (props.formEdit) { setOpenpopup(true) } else { setEdit(true) } }} />
                                                    </Box>
                                                </Stack>
                                            }
                                        </Grid>
                                        :
                                        <Grid item sm={1} md={1} lg={1} xs={12}></Grid>
                                }
                                {emergencyContactDetailsConfig?.name?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                         <Text mediumLabel title={emergencyContactDetailsConfig?.name?.label?.length > 28 ? emergencyContactDetailsConfig?.name?.label : ''}>
                                        {emergencyContactDetailsConfig?.name?.label?.length > 28 ? emergencyContactDetailsConfig?.name?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.name?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {
                                                details.name && details.name.length > 16 ?
                                                    <BlackToolTip title={details.name} placement="top" arrow>
                                                        {details.name.slice(0, 16) + (details.name.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    :
                                                    details.name
                                            }
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.relationship_id?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.relationship_id?.label?.length > 28 ? emergencyContactDetailsConfig?.relationship_id?.label : ''}>
                                        {emergencyContactDetailsConfig?.relationship_id?.label?.length > 28 ? emergencyContactDetailsConfig?.relationship_id?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.relationship_id?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.relationship_name}
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.contact_number?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.contact_number?.label?.length > 28 ? emergencyContactDetailsConfig?.contact_number?.label : ''}>
                                        {emergencyContactDetailsConfig?.contact_number?.label?.length > 28 ? emergencyContactDetailsConfig?.contact_number?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.contact_number?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.contact_number}
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.address_1?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.address_1?.label?.length > 28 ? emergencyContactDetailsConfig?.address_1?.label : ''}>
                                        {emergencyContactDetailsConfig?.address_1?.label?.length > 28 ? emergencyContactDetailsConfig?.address_1?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.address_1?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {
                                                details.address_1 && details.address_1.length > 16 ?
                                                    <BlackToolTip title={details.address_1} placement="top" arrow>
                                                        {details.address_1.slice(0, 16) + (details.address_1.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    :
                                                    details.address_1
                                            }
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.address_2?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.address_2?.label?.length > 28 ? emergencyContactDetailsConfig?.address_2?.label : ''}>
                                        {emergencyContactDetailsConfig?.address_2?.label?.length > 28 ? emergencyContactDetailsConfig?.address_2?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.address_2?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {
                                                details.address_2 && details.address_2.length > 16 ?
                                                    <BlackToolTip title={details.address_2} placement="top" arrow>
                                                        {details.address_2.slice(0, 16) + (details.address_2.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    :
                                                    details.address_2
                                            }
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.location?.country?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.location?.country?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.country?.label : ''}>
                                        {emergencyContactDetailsConfig?.location?.country?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.country?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.location?.country?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.country_name}
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.location?.state?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.location?.state?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.state?.label : ''}>
                                        {emergencyContactDetailsConfig?.location?.state?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.state?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.location?.state?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.state_name}
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.location?.city?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.location?.city?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.city?.label : ''}>
                                        {emergencyContactDetailsConfig?.location?.city?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.city?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.location?.city?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.city}
                                        </Text>
                                    </Box>
                                </Grid>}
                                {emergencyContactDetailsConfig?.location?.zip_code?.isVisible &&
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box p={1} pt={2}>
                                        <Text mediumLabel title={emergencyContactDetailsConfig?.location?.zip_code?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.zip_code?.label : ''}>
                                        {emergencyContactDetailsConfig?.location?.zip_code?.label?.length > 28 ? emergencyContactDetailsConfig?.location?.zip_code?.label?.substring(0,28)+"...": emergencyContactDetailsConfig?.location?.zip_code?.label}
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {details.zip_code}
                                        </Text>
                                    </Box>
                                </Grid>}
                            </Grid>
                    }
                </>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to delete the information</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { deleteContact() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fullWidth>
                <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <Button smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                            Cancel
                        </Button>
                        <Button smallBlue redBackground onClick={() => { props.setformEdit(false); setOpenpopup(false); addNew() }} >
                            Yes
                        </Button>
                    </Stack>
                </Grid>
            </ReusablePopup>
        </Grid >
    );
}