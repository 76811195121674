import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Avatar, Stack, Grid, CircularProgress,Breadcrumbs } from '@mui/material';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import Search from '../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../assets/svg/download-cloud-blue.svg';
import { addErrorMsg, addLoader, capitalizeAndAddSpace, ListLoadingIcon, NoDataFound, NoPermission, removeLoader } from '../../../utils/utils';
import { useNavigate } from "react-router-dom";
import Text from '../../../components/customText/Text';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../utils/LocalStorage';
import ClearIcon from '@mui/icons-material/Clear';
// import Loader from '../../../assets/gif/japfuLoader.gif';
import PlacementDashboardStyles from '../placements/newDashboard/PlacementDashboardStyles';
import moment from 'moment';
import FileSaver from 'file-saver';
import TimesheetApi from '../../../apis/admin/timesheets/TimesheetApi';
import Select from '../../../components/select/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Pending from '../../../assets/employee/Orange-PendingIcon.svg';
import Component87 from '../../../assets/svg/Component87.svg';
import CommonApi from '../../../apis/CommonApi';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));


export default function TimesheetSummary() {

    const classes = PlacementDashboardStyles();
    const navigate = useNavigate();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [placements, setPlacements] = useState([]); // eslint-disable-next-line
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [expanded, setExpanded] = React.useState(false);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date: [],
        month: moment().month() + 1,
        year: moment().year(),
        end_date: [],
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc"
    })

    // const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [years, setYears] = useState([]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
            getAllPlacements(filterData);
        }
        // eslint-disable-next-line
    }, [filterData]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
            defaultYears();
        }
        // eslint-disable-next-line
    }, []);

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }


    const handleDownloadExport = () => {
        addLoader(true);
        TimesheetApi.exportTimesheetsSummary(filterData).then((response) => {
            removeLoader();
            if (response.data.statusCode == 1003) {
                FileSaver.saveAs(response.data.data[0].file_path, `Timesheet-Summary-Details`);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const getAllPlacements = (filter) => {
        if (placements.length < 6) {
            setLoading(true);
        }
        TimesheetApi.summaryListing(filter).then((response) => {
            removeLoader();
            // setOpenBacKLoader(false);
            setListLoading(false);
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        })
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setLoading(true);
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    // setOpenBacKLoader(true);
                    setListLoading(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    // const years = [{
    //     id: 2023,
    //     value: '2023'
    // },
    // {
    //     id: 2024,
    //     value: '2024'
    // }]

    const monthsOptions = [
        {
            id: 1,
            value: 'January'
        },
        {
            id: 2,
            value: 'February'
        },
        {
            id: 3,
            value: 'March'
        },
        {
            id: 4,
            value: 'April'
        },
        {
            id: 5,
            value: 'May'
        },
        {
            id: 6,
            value: 'June'
        },
        {
            id: 7,
            value: 'July'
        },
        {
            id: 8,
            value: 'August'
        }, {
            id: 9,
            value: 'September'
        },
        {
            id: 10,
            value: 'October'
        },
        {
            id: 11,
            value: 'November'
        },
        {
            id: 12,
            value: 'December'
        }
    ]

    const getValueById = (id) => {
        const option = [{
            "id": "Weekly", "value": "Weekly"
        }, {
            "id": "Bi Weekly", "value": "Bi-Weekly"
        }, {
            "id": "Semi Monthly", "value": "Semi-Monthly"
        }, {
            "id": "Monthly", "value": "Monthly"
        }].find(option => option.id === id);
        return option ? option.value : '';
    };

    return (
        <Box className={classes.flexBox} pl={{ lg: "65px", md: "65px", sm: "10px", xs: "0px" }}>
            {
                false ? ''
                    // <RecruitersTable setRecruitersTableShow={setRecruitersTableShow} recruiters={recruiters} handleRecFilter={handleRecFilter} />
                    :
                    <Box width={{ lg: "90%", md: "100%", sm: "100%", xs: "100%" }}>
                        <Box style={{ padding: "20px 10px 10px 10px" }}>
                            <Grid container >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Text mediumGrey sx={{ cursor: 'pointer' }} onClick={() => navigate('/timesheet')}>Timesheet</Text>
                                        <Text mediumBlack>Timesheet Summary</Text>
                                    </Breadcrumbs>
                                </Grid>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} spacing={2}>
                                            <Grid item lg={8} md={7} sm={5.5} xs={12} pt={.3}>
                                                <Box className={classes.searchBox}>
                                                    <input
                                                        type="text"
                                                        value={filterData.search}
                                                        className={classes.EmployeesSearchInput}
                                                        onChange={handleSearch}
                                                        placeholder="Search by Name / Placement ID"
                                                    />
                                                    <Box sx={{ paddingRight: "15px !important" }}>
                                                        {
                                                            filterData.search !== "" ?
                                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                :
                                                                <img src={Search} alt="Search" />
                                                        }
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item container lg={3} md={4} sm={5} xs={9.5} display={'flex'} spacing={2} justifyContent={{ lg: 'end', md: 'end', sm: "end", xs: "start" }}>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Select
                                                        disableClearable
                                                        options={years}
                                                        label={'Select Year'}
                                                        value={filterData.year}
                                                        onChange={(e) => { setLoading(true); setFilterData({ ...filterData, year: e.target.value, limit: 10 }) }}
                                                    />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Select
                                                        disableClearable
                                                        options={monthsOptions}
                                                        label={'Select Month'}
                                                        value={filterData.month}
                                                        onChange={(e) => { setLoading(true); setFilterData({ ...filterData, month: e.target.value, limit: 10 }) }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={1} md={1} sm={1} xs={1} pl={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                                                <button title='Export' style={{ height: '40px !important' }} type="button" className={classes.borderButton1}
                                                    onClick={() => handleDownloadExport()}
                                                >
                                                    <img src={NewDownloadcloud} alt="Userplus" />
                                                </button>
                                            </Grid>
                                        </Grid>
                                        : ""
                                }
                            </Grid>
                        </Box>
                        {
                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                <Box style={{ padding: "10px" }} className={classes.tableBox}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.hzScrollGrid1}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px" }} >
                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                <Grid item lg={4} md={4} sm={4} xs={3.9} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                                </Grid>
                                                <Grid item lg={2.8} md={2} sm={2} xs={2.1} className={classes.headerNames}>Placement </Grid>
                                                <Grid item lg={1.4} md={1.5} sm={1.5} xs={1.6} className={classes.headerNames} >TS Cycle</Grid>
                                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} >Total Hours</Grid>
                                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5} className={classes.headerNames} >Total Pay</Grid>
                                            </Grid>
                                        </Box>
                                        {
                                            loading ?
                                                [1, 2, 3, 4].map((item) => (
                                                    <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                        <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                            <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <Box>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                </Box>
                                                                <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                )) :
                                                <>
                                                    {
                                                        placements.length > 0 ?
                                                            <Box sx={{
                                                                height: "60vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start', paddingRight: '6px',
                                                                "@media (min-height: 850px)": {
                                                                    height: '69.5vh'
                                                                }
                                                            }} ref={scrollBox} onScroll={handleScroll}>
                                                                {
                                                                    placements.map((place, key) => (
                                                                        <Accordion
                                                                            key={key}
                                                                            className={classes.newCustomAccordion}
                                                                            expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                            <AccordionSummary
                                                                                aria-controls="panel1bh-content"
                                                                                id="panel1bh-header"
                                                                                className={classes.AccordionSummary}
                                                                            >
                                                                                <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                    <Grid item lg={4} md={4} sm={4} xs={4} textAlign='start'>
                                                                                        <Box display='flex' alignItems='center' gap={2}>
                                                                                            <Box sx={{ position: 'relative', display: 'flex' }}>
                                                                                                <CircularProgress variant="determinate" value={place.profile_progress} size="3.8rem" thickness={2} sx={{ backgroundColor: "#F2F2F2", color: place.profile_progress >= 76 ? 'green' : place.profile_progress <= 75 && place.profile_progress >= 51 ? 'yellow' : place.profile_progress < 50 ? "#FFBF00" : '', borderRadius: "100%", }} />
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        top: 0,
                                                                                                        left: 0,
                                                                                                        bottom: 0,
                                                                                                        right: 0,
                                                                                                        position: 'absolute',
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'center',
                                                                                                    }}
                                                                                                >
                                                                                                    <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>
                                                                                                        <HtmlTooltip
                                                                                                            placement="bottom"
                                                                                                            arrow
                                                                                                            title={
                                                                                                                <React.Fragment>
                                                                                                                    <Box>
                                                                                                                        <Typography className={classes.profileTooltipText}>
                                                                                                                            {`Profile Completion - ${place.profile_progress}%`}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                        >
                                                                                                            <Avatar
                                                                                                                src={place.profile_picture_url}
                                                                                                                alt={place.employee_name ? capitalizeAndAddSpace(place.employee_name[0]) : ''}
                                                                                                                sx={{ width: 50, height: 50, background: place.profile_picture_url ? '' : '#e5b256 !important' }}
                                                                                                            />
                                                                                                        </HtmlTooltip>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Text smallBoldBlack >
                                                                                                    {place.employee_name === "" ? "--" : capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                            {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                        </HtmlTooltip>
                                                                                                        :
                                                                                                        `${place.employee_name}`
                                                                                                    }
                                                                                                    <HtmlTooltip title={<Text smallWhite>{place.employee_e_verified == 1 || place.employee_e_verified == 4 ? "E-verified" : "E-verification is pending"}</Text>} placement="right" arrow>
                                                                                                        {(place.employee_e_verified == 1 || place.employee_e_verified == 4 || place.employee_e_verified == 2) && <img src={place.employee_e_verified == 1 || place.employee_e_verified == 4 ? Component87 : place.employee_e_verified == 2 ? Pending : ''} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />}
                                                                                                    </HtmlTooltip></Text>
                                                                                                <Text mediumLabel style={{ margin: '4px 0' }}>  {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                            </Box>
                                                                                        </Box>

                                                                                    </Grid>
                                                                                    <Grid item lg={3} md={2.2} sm={2.2} xs={2.2} textAlign={"start"}>
                                                                                        <Text smallBoldBlack  >
                                                                                            {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.placement_reference_id) + ` ( ` + capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "") + ` )`}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                `${capitalizeAndAddSpace(place.placement_reference_id)} ( ${place.client_name} )`
                                                                                            }
                                                                                        </Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={1.5} md={1.6} sm={1.6} xs={1.6} textAlign={"start"}>
                                                                                        <Text smallBoldBlack  >{place.timesheet_cycle ? getValueById(place.timesheet_cycle) : "--"}</Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={1.5} md={1.6} sm={1.6} xs={1.6} textAlign={"start"}>
                                                                                        <Text smallBoldBlack  >{place.total_hours ? place.total_hours : "--"}</Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={1.5} md={1.5} sm={1.6} xs={1.6} textAlign={"start"}>
                                                                                        <Text smallBoldBlack  >{LocalStorage.getCurrencySymbol()}{place.total_amount ? place.total_amount : "--"}</Text>
                                                                                    </Grid>
                                                                                    <Grid item lg={.5} md={0.5} sm={0.5} xs={0.5} className={classes.headerNames} >
                                                                                        <Box p={1}>
                                                                                            {
                                                                                                expanded === `panel${key}` ?
                                                                                                    <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onChange={handleChange(`panel${key}`)} />
                                                                                                    :
                                                                                                    <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onChange={handleChange(`panel${key}`)} />
                                                                                            }
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Box className={classes.AccordionDetails} pt={1}>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Week - 1
                                                                                        </Typography>
                                                                                        <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 0, weekEnd: 7, hours: place.weekly[0].hours } }) }} >
                                                                                            {place.weekly.length > 0 ? place.weekly[0].hours : "--"}
                                                                                        </Text>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Week - 2
                                                                                        </Typography>
                                                                                        <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 8, weekEnd: 15, hours: place.weekly[1].hours } }) }} >
                                                                                            {place.weekly.length > 0 ? place.weekly[1].hours : "--"}
                                                                                        </Text>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Week - 3
                                                                                        </Typography>
                                                                                        <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 16, weekEnd: 23, hours: place.weekly[2].hours } }) }} >
                                                                                            {place.weekly.length > 0 ? place.weekly[2].hours : "--"}
                                                                                        </Text>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Week - 4
                                                                                        </Typography>
                                                                                        <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 24, weekEnd: 31, hours: place.weekly[3].hours } }) }} >
                                                                                            {place.weekly.length > 0 ? place.weekly[3].hours : "--"}
                                                                                        </Text>
                                                                                    </Box>
                                                                                    <Box width={'100%'}>
                                                                                        <Typography className={classes.text1}>
                                                                                            Week - 5
                                                                                        </Typography>
                                                                                        <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 32, weekEnd: 39, hours: place.weekly[4].hours } }) }} >
                                                                                            {place.weekly.length > 0 ? place.weekly[4].hours : "--"}
                                                                                        </Text>
                                                                                    </Box>
                                                                                    {
                                                                                        place.weekly.length > 5
                                                                                            ?
                                                                                            <Box width={'100%'}>
                                                                                                <Typography className={classes.text1}>
                                                                                                    Week - 6
                                                                                                </Typography>
                                                                                                <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 40, weekEnd: 47, hours: place.weekly[4].hours } }) }} >
                                                                                                    {place.weekly.length > 0 ? place.weekly[5].hours : "--"}
                                                                                                </Text>
                                                                                            </Box> : ''
                                                                                    }

                                                                                    <Box width={'100%'} >
                                                                                        <Text blue14 my={1} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: false, weekStart: 48, weekEnd: 55, hours: place.weekly[4].hours } }) }} >
                                                                                            Monthly View
                                                                                        </Text>

                                                                                    </Box>
                                                                                </Box>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))
                                                                }
                                                                {
                                                                    listLoading && (
                                                                        <ListLoadingIcon />
                                                                    )
                                                                }
                                                            </Box>
                                                            :
                                                            placements.length == 0 ?
                                                                <Box>
                                                                    {NoDataFound("No Records Found", 'There are no records here to show.')}
                                                                </Box>
                                                                : ''
                                                    }
                                                </>
                                        }
                                    </Grid>
                                </Box> :
                                <>
                                    {NoPermission()}
                                </>
                        }

                    </Box>
            }

        </Box >
    );
}