import React, { useEffect, useState } from "react";
import logoName from '../../assets/svg/logoName.svg';
import Text from "../../components/customText/Text";
import { Box, Grid } from '@mui/material';
import OnboardStyles from './OnboardStyles';
import onBoardLinkApi from "../../apis/onBoardLink/onBoardLinkApi";
import Button from '../../components/customButton/Button';
import { ReactComponent as ExpenseDeleteIcon } from '../../assets/svg/ExpenseDeleteIcon.svg';
import { ReactComponent as OfferRejected } from '../../assets/svg/OfferRejected.svg';
import OfferRejectedBackground from '../../assets/svg/OfferRejectedBackground.svg';
import onBoardLinkImage from '../../assets/svg/onBoardLink.svg';
import maximumBox from '../../assets/svg/maximumBox.svg'
import downloadBox from '../../assets/svg/downloadBox.svg'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import FsLightbox from 'fslightbox-react';
import { pdfjs } from 'react-pdf';
import { addErrorMsg, addLoader, addSuccessMsg, removeLoader } from "../../utils/utils";
import LocalStorage from "../../utils/LocalStorage";
import ReusablePopup from "../../components/reuablePopup/ReusablePopup";
import OnboardEmployeeUpload from "./OnboardEmployeeUpload";
import expired from '../../assets/employee/offboarding/link-exp.svg';
import { domain } from "../../config/Domain";
import onBoardLinkApiInstance from "../../apis/onBoardLink/onBoardLinkApi";
import rejectedImg from '../../assets/employee/offboarding/rejectedImg.svg';
import submitted from '../../assets/employee/offboarding/invite-submitted.svg';
import FileSaver from 'file-saver';
import LoadingButton from "../../components/customButton/LoadingButton";
import CommonApi from "../../apis/CommonApi";

function OnboardEmployeeInvite() {

    // const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    LocalStorage.setPathId()
    const id = queryParams.get('onboarding_id');
    const [state, setState] = useState({
        status: '',
        rejected_on: '',
        final: false,
        link_valid_till: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        gender: '',
        email_id: '',
        mobile_number: '',
        offer_letter_url: '',
        offer_letter_id: '',
        employment_type_id: '',
        employee_category_id: '',
        upload_documents: {
            copy_of_void_cheque: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            counter_signed_offer_letter: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            work_authorization: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            all_previous_i20s: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            signed_ssn: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            education_documents: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            passport: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            i94: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            drivers_license: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            }
        },
        i9andw4: {
            status: '',
            i9_sample: '',
            i9_download: '',
            i9_document: {
                id: '',
                document_url: '',
                document_name: ''
            },
            w4_sample: '',
            w4_download: '',
            w4_document: {
                id: '',
                document_url: '',
                document_name: ''
            }
        },
        emergency_contacts: [
            {
                relationship_id: '',
                relation_name: null,
                name: '',
                mobile_number: '',
                address_1: '',
                address_2: '',
                city: '',
                zip_code: '',
                state_id: '',
                state_name: null,
                country_id: '',
                country_name: null,
                email_id: ''
            },
            {
                relationship_id: '',
                relation_name: null,
                name: '',
                mobile_number: '',
                address_1: '',
                address_2: '',
                city: '',
                zip_code: '',
                state_id: '',
                state_name: null,
                country_id: '',
                country_name: null,
                email_id: ''
            },
        ]
    })
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        offer_letter_url: '',
        organization_name: '',
        logo_url: '',
        upload_documents: [],
    });
    const [dataUrl, setDataUrl] = useState(null);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [Rejected, setRejected] = useState(false);
    const [active, setActive] = useState(1);
    const [preView, setPreview] = useState([]);
    const [rejectLoader, setRejectLoader] = useState(false);
    const [firstName, setFirstName] = useState('');

    const onError = (error) => {
        console.error(error);
    };
    const classes = OnboardStyles();


    const handleDownloadPdf = () => {
        FileSaver.saveAs(dataUrl, `${firstName}-Offer-letter.pdf`);
        addSuccessMsg('Document has been downloaded successfully');
    }


    const handleClose = () => {
        setRejected(false);
        setOpenDialog(false);
    }

    useEffect(() => {
        getInviteViaLink(id);
        // eslint-disable-next-line
    }, []);

    const handleApprove = () => {
        updateApi('Pending');
    }

    const updateApi = (args) => {
        addLoader(true);
        setRejectLoader(true);
        state['status'] = args
        state['subdomain_name'] = domain
        state['request_id'] = LocalStorage.uid()
        state['basic_details'] = false
        onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
            removeLoader();
            setRejectLoader(false);
            if (res.data.statusCode == 1003) {
                getInviteViaLink(id);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const getInviteViaLink = (id) => {
        addLoader(true);
        onBoardLinkApi.uploadDocsIndex(id).then((res) => {
            removeLoader();
            if (res.data.statusCode === 1003) {
                handlLoadPDf(res.data.data.offer_letter_path);
                setFirstName(res.data.data.first_name);
                setData({ ...data, "first_name": res.data.data.first_name, "last_name": res.data.data.last_name, "upload_documents": res.data.data.upload_documents, organization_name: res.data.data.organization_name, logo_url: res.data.data.logo_url });
                // FileSaver.saveAs(res.data.data.offer_letter_url);              
                setState({ ...res.data.data })
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handlLoadPDf = (offer_letter_url) => {
        let postData = {
            request_id: LocalStorage.uid(),
            tenantId: LocalStorage.getPathId(),
            document_key: offer_letter_url
        }
        addLoader(true);
        CommonApi.documentViewS3WithoutToken('offer_letter', postData).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                data.offer_letter_url = res.data.data.document_url
                fetch(res.data.data.document_url)
                    .then(response => response.blob())
                    .then(blob => {
                        const fileReader = new FileReader();
                        fileReader.onload = (e) => {
                            setDataUrl(e.target.result);
                            setIsDocumentLoaded(true);
                        };
                        fileReader.readAsDataURL(blob);
                    })
                    .catch(error => console.error(error));
            }
        })

    }

    const fontOptions = {
        GungsuhBold: {
            normal: 'Gungsuh-Bold.ttf',
            bold: 'Gungsuh-Bold-Bold.ttf',
        },
    };

    return (
        <Grid container>
            {
                state.is_link_expired == true ?
                    <Grid container>
                        <Grid item lg={2}>
                            <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles} ></img>
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center' height='80vh' width='100%'>
                            <Box display='flex' flexDirection='column' pt={6}>
                                <img src={expired} alt="expired" />
                                <Text largeLabel700 pt={2}>Link Expired</Text>
                                <Text largeLabel pt={2}>Your offer letter link is expired on {state.link_valid_till}<br />Please contact to the management.</Text>
                            </Box>
                        </Grid>
                    </Grid>
                    : state.status == 'Pending' ?
                        <OnboardEmployeeUpload data={data} state={state} setState={setState} id={id} active={active} setActive={setActive} preView={preView} setPreview={setPreview} getInviteViaLink={getInviteViaLink} /> :
                        state.status == 'Offer Accepted' || state.status == 'In Progress' ?
                            <Grid container>
                                <Grid item lg={2}>
                                    <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center' height='80vh' width='100%'>
                                    <Box display='flex' flexDirection='column' pt={6}>
                                        <img src={submitted} alt="submitted" />
                                        <Text largeLabel700 pt={2}>Hurray!</Text>
                                        <Text largeLabel pt={2}>All documents submitted successfully.<br /> Your employer will get in touch with you soon!</Text>
                                    </Box>
                                </Grid>
                            </Grid> :
                            state.status == 'Offer Rejected' ?
                                <Grid container>
                                    <Grid item lg={2}>
                                        <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12}  justifyContent='center' textAlign='center' height='80vh' width='100%'>
                                        <Box display='flex' flexDirection='column' pt={6}>
                                            <img src={rejectedImg} alt="rejected" />
                                            <Text largeLabel700 pt={2}>Offer Rejected</Text>
                                            <Text largeLabel pt={2}> You have rejected the offer letter on {state.rejected_on}<br />Please contact to the management.</Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                                : state.status == 'Invitation Sent' || state.status == 'Waiting' ?
                                    <Grid container>
                                        <Grid item  container  lg={6} xl={6} md={6} sm={12} xs={12} className={classes.BrowseMonster} alignItems="center" justifyContent="center">
                                            <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.BrowseMonsterImageStyle} width={'140px'} height={'70px'} />
                                            <Box textAlign='center'>
                                                <img src={onBoardLinkImage} alt="onBoardLinkImage" className={classes.onBoardLinkImageStyle} />
                                                <Text veryLargeWhite>Congratulations</Text>,
                                                <Text largeWhite400>
                                                    Hello {data ? data.first_name : ""} {data ? data.last_name : ""}, you're just a few step away from joining {data ? data.organization_name : ""}.<br></br>
                                                    First things first,  please go through the <br></br>  offer letter and click on Accept to continue
                                                </Text>
                                                <Text largeWhite400 sx={{ paddingTop: '20px', fontWeight: `${700} !important` }}>If Not Accepted, Offer Letter Expires In 3 Days</Text>
                                            </Box>
                                        </Grid>

                                        <Grid item container lg={6} xl={6} md={6} sm={12} xs={12} className={classes.PDFRendergrid} alignItems="center" justifyContent="center">
                                            <Box className={classes.MainBox}>
                                                <Box component="div" sx={{ ':hover div': { opacity: 1 }, }} className={classes.PDFRenderBox}>
                                                    {isDocumentLoaded &&
                                                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                            <Viewer
                                                                width="100%"
                                                                fileUrl={dataUrl}
                                                                canvasCss={{
                                                                    fontFamily: 'Gungsuh-Bold', // Use a fallback font
                                                                }}
                                                                fontOptions={fontOptions}
                                                                onError={onError} />
                                                        </Worker>}
                                                    <Box component="div" gap={2} className={classes.PDFBoxDownloadAndViewBox}>
                                                        <img onClick={() => { setIsLightboxOpen(!isLightboxOpen); }} src={maximumBox} alt="maximumBox"></img>
                                                        <img onClick={handleDownloadPdf} src={downloadBox} alt="downloadBox"></img>
                                                    </Box>
                                                </Box>
                                                <Box className={classes.ButtonBox} gap={2}>
                                                    <Button className={classes.RejectButton} onClick={() => { setOpenDialog(true); }}>
                                                        Reject
                                                    </Button>
                                                    <Button className={classes.AcceptButton} onClick={handleApprove}>
                                                        {/* onClick={() => navigate("/onboard-employee-upload/" + id, { state: { data: data } })} */}
                                                        Accept
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <FsLightbox
                                                toggler={isLightboxOpen}
                                                sources={[
                                                    <iframe title="pdf" src={dataUrl} width={"10000px"} height={"10000px"} />
                                                ]}
                                            />
                                        </Grid>

                                        <ReusablePopup openPopup={openDialog} setOpenPopup={setOpenDialog} white crossIcon iconHide fullWidth>
                                            <Box textAlign='center' p={'10px 0px'}>
                                                {!Rejected ? <>
                                                    <ExpenseDeleteIcon />
                                                    <Text popupHead1 sx={{ margin: '10px 0px' }}>Are You Sure?</Text>
                                                    <Text popupHead2 sx={{ margin: '15px 0px' }}>Do you really want to reject the offer?</Text>
                                                    <Box className={classes.DialogContentButtonBox}>
                                                        <Button disabled={rejectLoader} onClick={handleClose} className={classes.CloseButton}>No</Button>
                                                        <LoadingButton loading={rejectLoader} className={classes.OkButton} onClick={() => { updateApi('Offer Rejected') }} >Yes, Reject</LoadingButton>
                                                    </Box></> :
                                                    <Box p={5}>
                                                        <Box
                                                            sx={{
                                                                backgroundImage: `url(${OfferRejectedBackground}) !important`,
                                                            }}
                                                            className={classes.OfferRejected}
                                                        >
                                                            <OfferRejected />
                                                        </Box>
                                                        <Text className={classes.OfferRejectedText}>Offer has been rejected.</Text>
                                                        <Box className={classes.OfferRejectedBox}>
                                                            <Button onClick={() => { setState({ ...state, status: 'Offer Rejected' }) }} className={classes.ExitButton}>Exit</Button>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                        </ReusablePopup>
                                    </Grid> : ''
            }
        </Grid>
    );

}

export default OnboardEmployeeInvite;