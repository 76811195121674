import React, { useEffect, useRef, useState } from 'react'// eslint-disable-next-line
import EmployeeVerifyApi from "../../../../apis/admin/employees/EmployeeVerifyApi";
import { Box, Card, CardContent, Dialog, DialogContent, Divider, Grid, Hidden, Skeleton, Slide, useTheme, } from '@mui/material'
import checkIcon from '../../../../assets/svg/checkedCircle.svg';// eslint-disable-next-line
import { addErrorMsg, addLoader, addSuccessMsg, checkZipcode, dateFormat, formatString, getCountryDetails, removeLoader } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import EmployeeVerifyStyles from './EmployeeVerifyStyles';
import { btnTxtBlack } from '../../../../theme';
import Text from '../../../../components/customText/Text';
import Input from '../../../../components/input/Input';// eslint-disable-next-line
import { onCharactersAndNumbersChange, onNumberOnlyChange, validate_Reason_Rejection, validate_accountNumber, validate_charWithSpace, validate_emptyField, validate_passport, validate_routingNumber, validate_ssn, validates_new_i94, validate_input_fields, onCharactersAndNumbersInputChange, isValid } from '../../../../components/Validation';
import Button from '../../../../components/customButton/Button';
import documentReject from '../../../../assets/images/documentReject.png';
import CommonApi from '../../../../apis/CommonApi';
import Date from '../../../../components/datePicker/Date';
import moment from 'moment';
import Select from '../../../../components/select/Select';
import whiteDownLoad from "../../../../assets/svg/whiteDownload.svg";
import whiteZoomin from "../../../../assets/svg/whiteZoomin.svg";
import blueLoader from '../../../../assets/gif/japfuLoader.gif';
import { useLocation, useNavigate } from 'react-router-dom'; // eslint-disable-next-line
import { pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { ErrorMessages } from '../../../../utils/ErrorMessages';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import LoadingButton from '../../../../components/customButton/LoadingButton';
import docSuccess from '../../../../assets/svg/docSuccess.svg';
import docReject from '../../../../assets/svg/docReject.svg';
import requestReuploadImg from '../../../../assets/svg/requestReupload.svg';
import successIcon from '../../../../assets/svg/succesIcon.svg';
import { employeeVerifyDefaultConfig } from '../../../../config/pageConfig/EmployerModuleConfig';

// const desiredOrder = [
//     'counter_signed_offer_letter',
//     'passport',
//     'i94',
//     'signed_ssn',
//     'work_authorization',
//     'all_previous_i20s',
//     'education_documents',
//     'drivers_license',
//     'copy_of_void_cheque'
// ];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"up"} ref={ref} {...props} />;
});


function EmployeeReVerifyDocuments() {

    const accountType = require('../../../../utils/jsons/AccountType.json');// eslint-disable-next-line
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation()  // eslint-disable-next-line
    const param = new URLSearchParams(window.location.search);
    const id = param.get('id');
    const employeeVerifyId = (id !== '' && id !== null && id !== undefined) ? id : LocalStorage.getRedirectedModule() != null ? LocalStorage.getRedirectedModule() == 'employee > verify' ? LocalStorage.getNotificationId() : LocalStorage.getEmployeeReVerifyId() : LocalStorage.getEmployeeReVerifyId();
    // eslint-disable-next-line
    const classes = EmployeeVerifyStyles();
    const [step, setStep] = useState(0);  // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [documentData, setDocumentData] = useState([]);
    const [visaTypes, setVisaTypes] = useState([]);// eslint-disable-next-line    
    const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeObject, setActiveObject] = useState(null);
    const [rejectOpen, setRejectOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    // eslint-disable-next-line
    const [scrollPosition, setScrollPosition] = useState(0);
    const [error, setError] = useState({});
    const [requestReupload, setRequestReupload] = useState(false);
    const [reuploadOpen, setReuploadOpen] = useState(false);
    const [finalPopOpen, setFinalPopOpen] = useState(false);
    const [passportConfig, setPassportConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.passport);
    const [i94Config, setI94Config] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.i94);
    const [ssnConfig, setSsnConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.ssn);
    const [visaConfig, setVisaConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.visa);
    const [drivingLicenseConfig, setDrivingLicenseConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.drivingLicense);
    const [voidChequeConfig, setVoidChequeConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.voidCheque);
    const [reasonForRejectionConfig, setReasonForRejectionConfig] = useState(employeeVerifyDefaultConfig?.uploadedDocumentsDetails?.reason_for_rejection);

    const [eye, setEye] = useState({
        document_number: false,
        account_number: false,
        routing_number: false,
        passport_document_number: false,
        ssn_document_number: false,
        driving_license_number: false,
    });
    const scrollToTop = () => {
        setScrollPosition(0); // Set scroll position to 0
    };

    const expiryTypeList = [
        {
            id: 2,
            value: "Date"
        },
        {
            id: 1,
            value: "D/S"
        }
    ]

    useEffect(() => {
        const data = {
            page_name: "employee-re-verify-flow",
            page_type: "read",
            with_token: true
        }
        async function getPageData() {
            const response = await CommonApi.fetchPageConfig(data)
            if (response.status === 200) {
                setPassportConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.passport);
                setI94Config(response?.data?.data?.page_data?.uploadedDocumentsDetails?.i94);
                setSsnConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.ssn);
                setDrivingLicenseConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.drivingLicense);
                setVisaConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.visa);
                setVoidChequeConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.voidCheque)
                setReasonForRejectionConfig(response?.data?.data?.page_data?.uploadedDocumentsDetails?.reason_for_rejection)
            }
        }
        getPageData()
    }, [])
    const useWindowCloseAlert = (targetPath, onLeaveCallback) => {
        useEffect(() => {
            const isReloadingFlag = 'isReloading';

            let userConfirmedLeave = false;

            const alertUser = (event) => {
                const isReloading = sessionStorage.getItem(isReloadingFlag);

                if (window.location.pathname === targetPath && !isReloading) {
                    event.preventDefault();
                    event.returnValue = 'Are you sure you want to leave this page? The verification process is not yet complete.';
                    return 'Are you sure you want to leave this page? The verification process is not yet complete.';
                }
            };

            const handleTabClosing = () => {
                if (userConfirmedLeave) {
                    onLeaveCallback();
                }
            };

            const setReloadFlag = () => {
                sessionStorage.setItem(isReloadingFlag, 'true');
            };

            const clearReloadFlag = () => {
                sessionStorage.removeItem(isReloadingFlag);
            };

            const handleKeydown = (event) => {
                if ((event.ctrlKey || event.metaKey) && (event.key === 'r' || event.key === 'F5')) {
                    setReloadFlag();
                }
            };

            window.addEventListener('load', () => {
                setTimeout(() => clearReloadFlag(), 100);
            });

            window.addEventListener('beforeunload', alertUser);
            window.addEventListener('unload', handleTabClosing);
            window.addEventListener('keydown', handleKeydown);

            // Track if the user confirmed the unload
            const handleUnloadConfirmation = (event) => {
                if (event.returnValue) {
                    userConfirmedLeave = true;
                } else {
                    userConfirmedLeave = false;
                }
            };

            window.addEventListener('beforeunload', handleUnloadConfirmation);

            return () => {
                window.removeEventListener('beforeunload', alertUser);
                window.removeEventListener('unload', handleTabClosing);
                window.removeEventListener('keydown', handleKeydown);
                window.removeEventListener('beforeunload', handleUnloadConfirmation);
            };
        }, [targetPath, onLeaveCallback]);
    };

    const unLinkAssignee = async () => {
        try {
            const data = {
                request_id: LocalStorage.uid(),
                id: employeeVerifyId,
            };
            const res = await EmployeesApi.employeeUnlinkAssign(data);
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        } catch (error) {
            addErrorMsg('Failed to unlink assignee.');
        }
    };

    useWindowCloseAlert('/employee/re-verify', unLinkAssignee);

    useEffect(() => {
        visaDropdownList();
        countriesList();
        getInviteViaLink(employeeVerifyId);
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    const getOCRdata = (obj) => {
        addLoader()
        if (obj && (obj.name == 'passport' && obj.approval_status != "Approved")) {
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('passport-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.passport_number ? res.data.data.passport_number : ''
                    obj.date_of_issue = res.data.data.date_of_issue ? moment(res.data.data.date_of_issue, dateFormat()).format(dateFormat()) : ''
                    obj.date_of_expiry = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                } else {
                    addErrorMsg("Could not extract data from document")
                }
            }
            )
        }
        else if (obj && (obj.name == 'signed_ssn' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('ssn-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.social_security_number ? res.data.data.social_security_number : ''
                    obj.date_of_issue = res.data.data.issue_date ? moment(res.data.data.issue_date, dateFormat()).format(dateFormat()) : ''
                    obj.date_of_expiry = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                } else {
                    addErrorMsg("Could not extract data from document")
                }
            }
            )
        }
        else if (obj && (obj.name == 'i94' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('i94-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.i94_record_number ? res.data.data.i94_record_number : res.data.data.i_94_record_number ? res.data.data.i94_record_number : ''
                    const admitUntilDate = res?.data?.data?.admit_until_date.toLowerCase();
                    obj.valid_from = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                    obj.valid_till = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : '' : ''
                    obj.expiry_type = res.data.data.admit_until_date ? admitUntilDate == 'd/s' ? 1 : 2 : ''
                    obj.date_of_issue = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                    obj.date_of_expiry = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : '' : ''
                    setActiveObject({ ...obj })
                } else {
                    addErrorMsg("Could not extract data from document")
                }
            }
            )
        }
        else if (obj && (obj.name == 'drivers_license' && obj.approval_status != "Approved")) {
            addLoader()
            const data = {
                id: obj.id,
                url: obj.document_url
            }
            CommonApi.ocrDocumentUpload('dl-data', data).then((res) => {
                removeLoader()
                if (res.data.statusCode === 1003) {
                    obj.document_number = res.data.data.driving_license_number ? res.data.data.driving_license_number : ''
                    obj.valid_from = res.data.data.issued_date ? moment(res.data.data.issued_date, dateFormat()).format(dateFormat()) : ''
                    obj.valid_till = res.data.data.expiry_date ? moment(res.data.data.expiry_date, dateFormat()).format(dateFormat()) : ''
                    setActiveObject({ ...obj })
                } else {
                    addErrorMsg("Could not extract data from document")
                }
            }
            )
        }
        else {
            removeLoader()
        }
    }

    /**********  API Integrations starts here    **********/
    const getInviteViaLink = (id) => {
        EmployeeVerifyApi.getVerifyEmployeeIndex(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setData(res.data.data);
                setDocumentData(handleFormArray(res.data.data));
                if (documentData.length == 0) {
                    // setTimeout(() => {
                    setActiveObject(handleFormArray(res.data.data)[0]);
                    // getOCRdata(handleFormArray(res.data.data)[0]);
                    setActiveIndex(0);
                    // }, 500)
                } else {
                    setTimeout(() => {
                        handleAssignState(0);
                    }, 500)
                }
                if (location.state != null && location.state != undefined && location.state != "") {
                    setStep(1);
                }

            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const updateInviteViaLink = (param, param1) => {
        param['request_id'] = LocalStorage.uid();
        setLoader(true);
        EmployeeVerifyApi.updateVerifyEmployeeIndex(param).then((res) => {
            if (res.data.statusCode === 1003) {
                if (param1 != undefined) {
                    LocalStorage.removeEmployeeId();
                    navigate('/employees/onboard', { state: { id: param.id, from: 'verifyFlow' } });
                }
                setLoader(false);
            } else {
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const countriesList = () => {
        CommonApi.getCountryList('', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCountries(res.data.data);
            }
        })
    }

    const visaDropdownList = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaTypes(res.data.data);
            }
        })
    }


    /**********  API Integrations Ends here    **********/

    /**
  * Convert nested object data into an array of objects with serial numbers.
  * @param {object} param - The input object containing nested data.
  * @returns {array} - An array of objects with serial numbers.
  */
    const handleFormArray = (param) => {
        // console.log("param = ", param);

        // Initialize an empty array to store merged data
        let mergedArray = [];
        // Initialize serial number counter
        let serialNumber = 1;

        const reUploadKeys = Object.keys(param.re_upload_documents)
        const sortedDocuments = {};
        for (const key of reUploadKeys) {
            if (param.upload_documents[key]) {
                sortedDocuments[key] = param.upload_documents[key];
            }
        }
        // Iterate over keys in the upload_documents object
        for (const key in sortedDocuments) {
            // Extract data array for the current key
            const dataArray = sortedDocuments[key].data;

            // Check if the data array contains only one object
            if (dataArray.length === 1) {
                // If only one object, push it to mergedArray with serial number
                const item = dataArray[0];
                if (item.name === "signed_ssn") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        document_type_id: 2,
                        document_number: item.document_number ? item.document_number : "",
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else if (item.name === "drivers_license") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        document_type_id: 1,
                        document_number: item.document_number ? item.document_number : "",
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else if (item.name === "i94") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        expiry_type: '',
                        country_id: item.country_id ? item.country_id : "",
                        document_number: item.document_number ? item.document_number : "",
                        date_of_issue: item.date_of_issue ? item.date_of_issue : "",
                        date_of_expiry: item.date_of_expiry ? item.date_of_expiry : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else if (item.name === "passport") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        issued_country_id: item.issued_country_id ? item.issued_country_id : "",
                        document_number: item.document_number ? item.document_number : "",
                        date_of_issue: item.date_of_issue ? item.date_of_issue : "",
                        date_of_expiry: item.date_of_expiry ? item.date_of_expiry : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else if (item.name === "copy_of_void_cheque") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        account_type: item.account_type ? item.account_type : "",
                        routing_number: item.routing_number ? item.routing_number : "",
                        account_number: item.account_number ? item.account_number : "",
                        bank_name: item.bank_name ? item.bank_name : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else if (item.name === "work_authorization") {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        valid_from: item.valid_from ? item.valid_from : "",
                        valid_till: item.valid_till ? item.valid_till : "",
                        visa_type_id: item.visa_type_id ? item.visa_type_id : "",
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                } else {
                    mergedArray.push({
                        serial_number: `${serialNumber}`,
                        ocr: false,
                        approval_status: item.approval_status ? item.approval_status : "Submitted",
                        reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                        ...item
                    });
                }
                serialNumber++;
            } else {
                if (dataArray.length > 0) {
                    // If multiple objects, push each with serial_number.sub_serial_number format
                    for (let i = 0; i < dataArray.length; i++) {
                        const item = dataArray[i];
                        if (item.name === "copy_of_void_cheque") {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                account_type: item.account_type ? item.account_type : "",
                                routing_number: item.routing_number ? item.routing_number : "",
                                account_number: item.account_number ? item.account_number : "",
                                bank_name: item.bank_name ? item.bank_name : "",
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                                ...item
                            });
                        } else if (item.name === "work_authorization") {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                valid_from: item.valid_from ? item.valid_from : "",
                                valid_till: item.valid_till ? item.valid_till : "",
                                visa_type_id: item.visa_type_id ? item.visa_type_id : "",
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                                ...item
                            });
                        } else {
                            mergedArray.push({
                                serial_number: `${serialNumber}.${i + 1}`,
                                serial: i + 1,
                                ocr: false,
                                approval_status: item.approval_status ? item.approval_status : "Submitted",
                                reason_for_rejection: item.reason_for_rejection ? item.reason_for_rejection : "",
                                ...item
                            });
                        }
                    }
                    serialNumber++;
                }
            }
        }
        if (reUploadKeys.includes("i9_document")) {
            if (param.i9andw4.i9_document.document_url !== "") {
                mergedArray.push({
                    serial_number: mergedArray.length == 0 ? "1" : mergedArray[mergedArray.length - 1].serial_number ? parseInt(mergedArray[mergedArray.length - 1].serial_number) + 1 : '1',
                    ocr: false,
                    id: param.i9andw4.i9_document.id,
                    name: "i9_document",
                    document_name: param.i9andw4.i9_document.document_name,
                    document_url: param.i9andw4.i9_document.document_url,
                    approval_status: param.i9andw4.i9_document.approval_status ? param.i9andw4.i9_document.approval_status : "Submitted",
                    i9_reason_for_rejection: param.i9andw4.i9_document.i9_reason_for_rejection ? param.i9andw4.i9_document.i9_reason_for_rejection : "",
                });
            }
        }
        if (reUploadKeys.includes("w4_document")) {
            if (param.i9andw4.w4_document.document_url !== "") {
                mergedArray.push({
                    serial_number: mergedArray.length == 0 ? "2" : mergedArray[mergedArray.length - 1].serial_number ? parseInt(mergedArray[mergedArray.length - 1].serial_number) + 1 : '2',
                    ocr: false,
                    name: "w4_document",
                    id: param.i9andw4.w4_document.id,
                    document_name: param.i9andw4.w4_document.document_name,
                    document_url: param.i9andw4.w4_document.document_url,
                    approval_status: param.i9andw4.w4_document.approval_status ? param.i9andw4.w4_document.approval_status : "Submitted",
                    w4_reason_for_rejection: param.i9andw4.w4_document.w4_reason_for_rejection ? param.i9andw4.w4_document.w4_reason_for_rejection : ""
                });
            }
        }
        return mergedArray;
    }

    // Function to handle state assignment based on index
    const handleAssignState = (index) => {
        // setLoader(true);
        if (documentData.length > 0) {

            if (activeIndex == (documentData.length - 1)) {
                var allUploadedDocs = handleFormArray(data);
                var rejectedDocs = allUploadedDocs.filter(doc => doc.approval_status === 'Rejected');
                if (rejectedDocs.length > 0) {
                    setRequestReupload(true);
                } else {
                    setLoader(false);
                    setFinalPopOpen(true);
                }
            } else {
                setTimeout(() => {
                    setActiveIndex(index);
                    setActiveObject(documentData[index]);
                    // getOCRdata(documentData[index]);
                    setLoader(false);
                }, 600);
            }
            setError({});
        }
    }

    const handleRequestReupload = () => {
        setTimeout(() => {
            setData({ ...data, re_upload: true });
            updateInviteViaLink({ ...data, re_upload: true });
            setReuploadOpen(true);
        }, 200);
    }

    const handleRequestContinue = () => {
        setReuploadOpen(false);
        navigate('/employees')
    }

    const handleApprove = (index) => {

        if (activeObject.name === "copy_of_void_cheque") {
            if (activeObject.account_number == "" || activeObject.routing_number == "" || activeObject.bank_name == "" || activeObject.account_type == "") {
                let void_cheque_errors = {}
                void_cheque_errors['account_number'] = validate_input_fields(activeObject.account_number, voidChequeConfig?.account_number)
                void_cheque_errors['routing_number'] = validate_input_fields(activeObject.routing_number, voidChequeConfig?.routing_number)
                void_cheque_errors['bank_name'] = validate_input_fields(activeObject.bank_name, voidChequeConfig?.bank_name);
                void_cheque_errors['account_type'] = validate_input_fields(activeObject.account_type, voidChequeConfig?.account_type)
                setError({ ...error, ...void_cheque_errors });

                if (!isValid(void_cheque_errors)) {
                    addErrorMsg("Please fill all mandatory fields");
                    return false;
                }
            }
            if (activeObject.account_number != "" && activeObject.account_number.length < voidChequeConfig?.account_number?.minLength) {
                let account_number_error = {}
                account_number_error['account_number'] = validate_input_fields(activeObject.account_number, voidChequeConfig?.account_number);
                setError({ ...error, ...account_number_error });

                if (!isValid(account_number_error)) {
                    addErrorMsg(voidChequeConfig?.account_number?.minLengthMsg);
                    return false;
                }
            }
            if (activeObject.routing_number != "" && activeObject.routing_number.length < voidChequeConfig?.routing_number?.minLength) {
                let routing_number_error = {}
                routing_number_error['routing_number'] = validate_input_fields(activeObject.routing_number, voidChequeConfig?.routing_number);
                setError({ ...error, ...routing_number_error });
                if (!isValid(routing_number_error)) {
                    addErrorMsg(voidChequeConfig?.routing_number?.minLengthMsg);
                    return false;
                }
            }
        }
        if (activeObject.name == "signed_ssn" || activeObject.name == "drivers_license" || activeObject.name == "i94" || activeObject.name == "passport") {
            if (activeObject.name == "drivers_license") {
                if (activeObject.document_number == "" || activeObject.valid_from == "" || activeObject.valid_till == "") {
                    let drivers_license_errors = {}
                    drivers_license_errors['document_number'] = validate_input_fields(activeObject.document_number, drivingLicenseConfig?.document_number);
                    drivers_license_errors['valid_from'] = validate_input_fields(activeObject.valid_from, drivingLicenseConfig?.valid_from);
                    drivers_license_errors['valid_till'] = validate_input_fields(activeObject.valid_till, drivingLicenseConfig?.valid_till);

                    setError({ ...error, ...drivers_license_errors })

                    if (!isValid(drivers_license_errors)) {
                        addErrorMsg("Please fill all mandatory fields");
                        return false;
                    }
                } else if (activeObject.document_number !== "" && activeObject.document_number.length < drivingLicenseConfig?.document_number?.minLength) {
                    let document_error = {}
                    document_error['document_number'] = validate_input_fields(activeObject.document_number, drivingLicenseConfig?.document_number);
                    setError({ ...error, ...document_error })
                    if (!isValid(document_error)) {
                        addErrorMsg(drivingLicenseConfig?.document_number?.minLengthMsg);
                        return false;
                    }
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
            } else if (activeObject.name == "i94") {
                if (activeObject.expiry_type == 2) {
                    if (activeObject.document_number == "" || activeObject.date_of_issue == "" || activeObject.date_of_expiry == "" || activeObject.expiry_type == "") {
                        let i94_errors = {}
                        i94_errors['document_number'] = validate_input_fields(activeObject.document_number, i94Config?.document_number);
                        i94_errors['expiry_type'] = activeObject.date_of_issue !== "" ? validate_input_fields(activeObject.expiry_type, i94Config?.expiry_type) : '';
                        i94_errors['date_of_issue'] = validate_input_fields(activeObject.date_of_issue, i94Config?.date_of_issue);
                        i94_errors['date_of_expiry'] = activeObject.expiry_type == 2 ? validate_input_fields(activeObject.date_of_expiry, i94Config?.date_of_expiry) : '';
                        setError({ ...error, ...i94_errors })
                        if (!isValid(i94_errors)) {
                            addErrorMsg("Please fill all mandatory fields");
                            return false;
                        }
                    }
                    if (activeObject.date_of_expiry == '') {
                        let date_of_expiry_error = {}
                        addErrorMsg("Admin until date is required");
                        date_of_expiry_error['date_of_expiry'] = validate_input_fields(activeObject.date_of_expiry, i94Config?.date_of_expiry);
                        setError({ ...error, ...date_of_expiry_error })
                        if (!isValid(date_of_expiry_error)) {
                            addErrorMsg("Please fill all mandatory fields");
                            return false;
                        }
                    }
                }
                if (activeObject.document_number == "" || activeObject.date_of_issue == "" || activeObject.date_of_expiry == "" || activeObject.expiry_type == "") {
                    let i94_errors = {}
                    i94_errors['document_number'] = validate_input_fields(activeObject.document_number, i94Config?.document_number);
                    i94_errors['expiry_type'] = activeObject.date_of_issue !== "" ? validate_input_fields(activeObject.expiry_type, i94Config?.expiry_type) : '';
                    i94_errors['date_of_issue'] = validate_input_fields(activeObject.date_of_issue, i94Config?.date_of_issue);
                    i94_errors['date_of_expiry'] = activeObject.expiry_type == 2 ? validate_input_fields(activeObject.date_of_expiry, i94Config?.date_of_expiry) : '';
                    setError({ ...error, ...i94_errors })
                    if (!isValid(i94_errors)) {
                        addErrorMsg("Please fill all mandatory fields");
                        return false;
                    }
                } else if (activeObject.document_number !== "" && validate_input_fields(activeObject.document_number, i94Config?.document_number)) {
                    let i94_errors = {}
                    i94_errors['document_number'] = validate_input_fields(activeObject.document_number, i94Config?.document_number);
                    setError({ ...error, ...i94_errors })
                    if (!isValid(i94_errors)) {
                        addErrorMsg(validate_input_fields(activeObject.document_number, i94Config?.document_number));
                        return false;
                    }
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
                if (moment(activeObject.date_of_issue, dateFormat()).isAfter(moment(), dateFormat())) {
                    addErrorMsg(i94Config?.date_of_issue?.invalidRecentDateMsg);
                    setError({ ...error })
                    return false;
                }
                if (moment(activeObject.date_of_expiry, dateFormat()).isBefore(moment(activeObject.date_of_issue, dateFormat()))) {
                    addErrorMsg(i94Config?.date_of_expiry?.invalidAdmitUntilDate);
                    setError({ ...error })
                    return false;
                }
            } else if (activeObject.name == "passport") {
                if (activeObject.document_number == "" || activeObject.date_of_expiry == "" || activeObject.date_of_issue == "" || activeObject.issued_country == "") {
                    let passport_errors = {}
                    passport_errors['document_number'] = validate_input_fields(activeObject.document_number, passportConfig?.document_number);
                    passport_errors['date_of_expiry'] = validate_input_fields(activeObject.date_of_expiry, passportConfig?.date_of_expiry);
                    passport_errors['date_of_issue'] = validate_input_fields(activeObject.date_of_issue, passportConfig?.date_of_issue);
                    passport_errors['issued_country'] = validate_input_fields(activeObject.issued_country, passportConfig?.issued_country);
                    setError({ ...error, ...passport_errors })

                    if (!isValid(passport_errors)) {
                        addErrorMsg("Please fill all mandatory fields");
                        return false;
                    }
                } else if (activeObject.document_number !== "" && activeObject.document_number.length < passportConfig?.document_number?.minLength) {
                    let passport_errors = {}
                    passport_errors['document_number'] = validate_input_fields(activeObject.document_number, passportConfig?.document_number);
                    setError({ ...error, ...passport_errors })
                    if (!isValid(passport_errors)) {
                        addErrorMsg(validate_input_fields(activeObject.document_number, passportConfig?.document_number));
                        return false;
                    }
                } else {
                    documentData[activeIndex].document_number = activeObject.document_number;
                    setDocumentData([...documentData]);
                }
            } else {
                if (activeObject.document_number == "") {
                    let ssn_error = {}
                    ssn_error['document_number'] = validate_input_fields(activeObject.document_number, ssnConfig?.document_number);
                    setError({ ...error, ...ssn_error })
                    if (!isValid(ssn_error)) {
                        addErrorMsg("Please fill all mandatory fields");
                        return false;
                    }
                } else {
                    if (activeObject.name == "signed_ssn" && validate_input_fields(activeObject.document_number, ssnConfig?.document_number)) {
                        let ssn_error = {}
                        ssn_error['document_number'] = validate_input_fields(activeObject.document_number, ssnConfig?.document_number);
                        setError({ ...error, ...ssn_error })
                        if (!isValid(ssn_error)) {
                            addErrorMsg(validate_input_fields(activeObject.document_number, ssnConfig?.document_number));
                            return false;
                        }
                    } else {
                        documentData[activeIndex].document_number = activeObject.document_number;
                        setDocumentData([...documentData]);
                    }
                }
            }
        }
        if (activeObject.name == "work_authorization") {
            if (activeObject.visa_type_id == "" || activeObject.date_of_issue == "" || activeObject.date_of_expiry == "") {
                let visa_errors = {};
                visa_errors['visa_type_id'] = validate_input_fields(activeObject.visa_type_id, visaConfig?.visa_type_id);
                visa_errors['date_of_issue'] = validate_input_fields(activeObject.date_of_issue, visaConfig?.date_of_issue);
                visa_errors['date_of_expiry'] = validate_input_fields(activeObject.date_of_expiry, visaConfig?.date_of_expiry);
                setError({ ...error, ...visa_errors });
                if (!isValid(visa_errors)) {
                    addErrorMsg("Please fill all mandatory fields");
                    return false;
                }
            }
        }
        activeObject.approval_status = "Approved";
        setActiveObject({ ...activeObject });
        // getOCRdata(activeObject);
        documentData[activeIndex] = activeObject;
        setDocumentData([...documentData]);
        if (activeObject.name == "i9_document") {
            data.i9andw4.i9_document = activeObject
            setData({ ...data });
            for (const key in data.re_upload_documents) {
                if (key === 'i9_document') {
                    delete data.re_upload_documents[key];
                    setData({ ...data });
                }
            }
        } else if (activeObject.name == "w4_document") {
            data.i9andw4.w4_document = activeObject
            setData({ ...data });
            for (const key in data.re_upload_documents) {
                if (key === 'w4_document') {
                    delete data.re_upload_documents[key];
                    setData({ ...data });
                }
            }
        } else {
            for (const key in data.upload_documents) {
                const dataArray = data.upload_documents[key].data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].document_path == documentData[activeIndex].document_path) {
                            dataArray[i] = activeObject;
                            setData({ ...data });
                        }
                    }
                }
            }

            for (const key in data.re_upload_documents) {
                const dataArray = data.re_upload_documents[key].data;
                const index = dataArray.findIndex(item => item.document_path === documentData[activeIndex].document_path);
                if (index !== -1) {
                    dataArray.splice(index, 1);
                    data.re_upload_documents[key].data = dataArray;
                    if (dataArray.length === 0) {
                        delete data.re_upload_documents[key];
                    }
                    setData({ ...data });
                }
            }
        }
        setData({ ...data });
        updateInviteViaLink(data);
        setEye({
            document_number: false,
            account_number: false,
            routing_number: false,
            passport_document_number: false,
            ssn_document_number: false,
            driving_license_number: false,
        })
        if (activeIndex === (documentData.length - 1)) {
            var allUploadedDocs = handleFormArray(data);
            var rejectedDocs = allUploadedDocs.filter(doc => doc.approval_status === 'Rejected');
            if (rejectedDocs.length > 0) {
                setRequestReupload(true);
            } else {
                setFinalPopOpen(true)
            }
        } else {
            handleAssignState(index);
        }

        scrollToTop();
    }

    const handleClick = async (item, index) => {
        setStep(0);
        setError({});
        setLoader(true);

        let dataObject = documentData[index];

        let data = {
            request_id: LocalStorage.uid(),
            document_key: dataObject.document_path
        };

        try {
            const res = await CommonApi.documentViewS3('invite_via_link', data);
            removeLoader();

            if (res.data.statusCode === 1003) {
                dataObject.document_url = res.data.data.document_url;
            }

            // Once API call completes, update the state
            setActiveIndex(index);
            setActiveObject(dataObject);
            // getOCRdata(dataObject);
        } catch (error) {
            console.error("Error fetching document URL:", error);
            removeLoader(); // ensure loader is removed on error as well
        } finally {
            setLoader(false); // stop loader after everything is done
        }
    };

    /**  onChange function are starts here   **/
    const handleChange = (e) => {
        if (activeObject.name == "signed_ssn") {
            const value = e.target.value;
            const name = e.target.name;
            const input = value.replace(/\D/g, '').substring(0, 10);

            // Divide numbers in 3 parts :"(123) 456-7890" 
            const first = input.substring(0, 3);
            const middle = input.substring(3, 5);
            const last = input.substring(5, 9);

            if (input.length > 5) {
                setActiveObject({ ...activeObject, [e.target.name]: `${first}-${middle}-${last}` });
                if (name == 'document_number') {
                    if (last == '0000') {
                        error['document_number'] = ssnConfig?.document_number?.patternMsg
                        setError(error)
                        return true
                    }
                }
            }
            else if (input.length > 3) {
                setActiveObject({ ...activeObject, [e.target.name]: `${first}-${middle}` });
                if (name == 'document_number') {
                    if (middle == '00') {
                        error['document_number'] = ssnConfig?.document_number?.patternMsg
                        setError(error)
                        // addErrorMsg('Invalid SSN, please check the format ');
                        return true
                    }
                }
            }
            else if (input.length >= 0) {
                setActiveObject({ ...activeObject, [e.target.name]: input });
                if (name == 'document_number') {
                    if (first == '000' || first == '666' || first == '900') {
                        error['document_number'] = ssnConfig?.document_number?.patternMsg
                        setError(error)
                        // addErrorMsg('Invalid SSN, please check the format ');
                        return true
                    }
                }
            }
            handleValidate(e, "ssn");
        } else {
            activeObject[e.target.name] = e.target.value;
            if (activeObject.name == 'i94') {
                setActiveObject({ ...activeObject });
                checkPassport('i94', e.target.value, 'i94')
            } else {
                setActiveObject({ ...activeObject });
                checkPassport('passport', e.target.value, 'document_number')
            }
            if (activeObject.name == "i94") {
                handleValidate(e, "i94");
            } else if (activeObject.name == "drivers_license") {
                handleValidate(e, "drivers_license");
            } else {
                handleValidate(e);
            }
        }
    }

    const handleChangeRejection = (e) => {
        activeObject[e.target.name] = e.target.value;
        setActiveObject({ ...activeObject });
        handleValidate(e);
    }

    const handleValidate = (e, param) => {
        var input = e.target;
        switch (input.name || input.tagName) {
            case "account_number":
                error['account_number'] = validate_input_fields(input.value, voidChequeConfig?.account_number);
                break;
            case "routing_number":
                error['routing_number'] = validate_input_fields(input.value, voidChequeConfig?.routing_number);
                break;
            case "bank_name":
                error['bank_name'] = validate_input_fields(input.value, voidChequeConfig?.bank_name);
                break;
            case "account_type":
                error['account_type'] = validate_input_fields(input.value, voidChequeConfig?.account_type);
                break;
            case "visa_type_id":
                error['visa_type_id'] = validate_input_fields(input.value, visaConfig?.visa_type_id);
                break;
            case "issued_country":
                error['issued_country'] = validate_input_fields(input.value, passportConfig?.issued_country);
                break;
            case "expiry_type":
                error['expiry_type'] = validate_input_fields(input.value, i94Config?.expiry_type);
                break;
            case "reason_for_rejection":
                error['reason_for_rejection'] = validate_input_fields(input.value, reasonForRejectionConfig);
                break;
            case "i9_reason_for_rejection":
                error['i9_reason_for_rejection'] = validate_input_fields(input.value, reasonForRejectionConfig);
                break;
            case "w4_reason_for_rejection":
                error['w4_reason_for_rejection'] = validate_input_fields(input.value, reasonForRejectionConfig);
                break;
            case "document_number":
                if (param == "ssn") {
                    error['document_number'] = validate_input_fields(input.value, ssnConfig?.document_number);
                } else if (param == 'i94') {
                    error['document_number'] = validate_input_fields(input.value, i94Config?.document_number);
                } else if (param == 'drivers_license') {
                    error['document_number'] = validate_input_fields(input.value, drivingLicenseConfig?.document_number);
                } else {
                    error['document_number'] = validate_input_fields(input.value, passportConfig?.document_number);
                }
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const dateChange = (e, name, args) => {
        let date = e == null ? '' : e.$d
        let key = name == 'date_of_issue' ? 'date_of_expiry' : name == 'valid_from' ? 'valid_till' : null

        if (key) {
            setActiveObject({
                ...activeObject,
                [name]: date == '' ? '' : moment(date).format(dateFormat()),
                [key]: ''
            })
        } else {
            setActiveObject({
                ...activeObject,
                [name]: date == '' ? '' : moment(date).format(dateFormat())
            })
        }
        if (name == 'date_of_expiry') {
            setError({
                ...error,
                date_of_expiry: ''
            })
        }

        if (name == 'date_of_issue' && date == '' && args == 'i94') {
            setError({
                ...error,
                expiry_type: '',
            })
        }

        if (name == 'date_of_issue' && date != '' && args == 'visa') {
            setError({
                ...error,
                date_of_issue: '',
            })
        }
        if (name == 'valid_from' && date != '' && args == 'drivers_license') {
            setError({
                ...error,
                valid_from: '',
            })
        }
        if (name == 'valid_till' && date != '' && args == 'drivers_license') {
            setError({
                ...error,
                valid_till: '',
            })
        }
        if (name == 'date_of_issue' && date != '' && args == 'password') {
            setError({
                ...error,
                date_of_issue: '',
            })
        }
        if (name == 'date_of_expiry' && date != '' && args == 'password') {
            setError({
                ...error,
                date_of_expiry: '',
            })
        }
    }

    const handleRejectOpen = (item) => {
        setError({});
        setRejectOpen(true);
        setActiveObject({ ...activeObject, reason_for_rejection: "" });
        setEye({
            document_number: false,
            account_number: false,
            routing_number: false,
            passport_document_number: false,
            ssn_document_number: false,
            driving_license_number: false,
        })
    }

    const handleRejectDocument = () => {
        const rejectionFieldMap = {
            i9_document: 'i9_reason_for_rejection',
            w4_document: 'w4_reason_for_rejection',
        };

        const field = rejectionFieldMap[activeObject?.name] || 'reason_for_rejection';

        if (activeObject?.[field] === "" || activeObject?.[field].length < reasonForRejectionConfig?.minLength) {
            setError({
                ...error,
                [field]: validate_input_fields(activeObject?.[field], reasonForRejectionConfig),
            });
            return;
        }

        activeObject.approval_status = "Rejected";
        setActiveObject({ ...activeObject });

        documentData[activeIndex] = activeObject;
        setDocumentData([...documentData]);
        let dataKeys = Object.keys(data.re_upload_documents)
        if (activeObject.name == "i9_document") {
            data.i9andw4.i9_document = activeObject
            setData({ ...data });
            if (dataKeys.length > 0) {
                for (const key in data.re_upload_documents) {
                    if (key === 'i9_document') {
                        data.re_upload_documents[key]['data'] = [activeObject]
                        setData({ ...data });
                    }
                }
            }
        } else if (activeObject.name == "w4_document") {
            data.i9andw4.w4_document = activeObject
            setData({ ...data });
            if (dataKeys.length > 0) {
                for (const key in data.re_upload_documents) {
                    if (key === 'w4_document') {
                        data.re_upload_documents[key]['data'] = [activeObject]
                        setData({ ...data });
                    }
                }
            }
        } else {
            for (const key in data.upload_documents) {
                // Extract data array for the current key
                const dataArray = data.upload_documents[key].data;
                if (dataArray.length > 0) {
                    for (let i = 0; i < dataArray.length; i++) {
                        if (dataArray[i].document_path == documentData[activeIndex].document_path) {
                            dataArray[i] = activeObject;
                            setData({ ...data });
                        }
                    }
                }
            }

            if (dataKeys.length > 0) {
                for (const key in data.re_upload_documents) {
                    // Extract data array for the current key
                    const dataArray = data.re_upload_documents[key].data;
                    if (dataArray.length > 0) {
                        for (let i = 0; i < dataArray.length; i++) {
                            if (dataArray[i].document_path == documentData[activeIndex].document_path) {
                                dataArray[i] = activeObject;
                                setData({ ...data });
                            }
                        }
                    }
                }
            }
        }

        // console.log("data", data);
        // updateInviteViaLink(data);
        setRejectOpen(false);
        addSuccessMsg("Document has been rejected");
    };

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && activeIndex !== undefined) {
            // Calculate the offset of the active index image
            const offset = activeIndex * containerRef.current.children[0].offsetHeight;
            // Scroll the container to show the active index image
            containerRef.current.scrollTop = offset;
        }
    }, [activeIndex]);

    const fullScreenRef = useRef(null);

    const handleFullScreen = () => {
        const elem = fullScreenRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    const onError = (error) => {
        console.error(error);
    };

    const formatReturn = (url) => {
        return url?.substring(url.lastIndexOf('.') + 1);
    }

    const checkPassport = (entityName, val, args) => {
        const delayDebounceFn = setTimeout(() => {
            if (args == 'document_number') {
                var passportObj = {
                    request_id: LocalStorage.uid(),
                    document_number: val,
                    id: '',
                }
            } else {
                var i94Obj = {
                    request_id: LocalStorage.uid(),
                    document_number: val,
                    id: '',
                }
            }


            EmployeesApi.checkDuplicateNumber(entityName, args == 'document_number' ? passportObj : i94Obj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (args == 'document_number') {
                            setError({
                                ...error,
                                document_number: passportConfig?.document_number?.alreadyExistsMsg
                            })

                        } else {
                            setError({
                                ...error,
                                document_number: i94Config?.document_number?.alreadyExistsMsg
                            })
                        }
                    }
                }
                else {
                    setError({
                        ...error,
                        document_number: ''
                    })
                }
            });
        }, 300)
        return () => clearTimeout(delayDebounceFn)
    }


    return (
        <Grid container pb={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} textAlign='left' ml={2} py={1}>
                <Text BrowmnMnStepperText>Verify Documents</Text>
            </Grid>
            <>
                {
                    step == 0 && !requestReupload && documentData.length > 0 ?
                        <>
                            {activeObject != undefined && activeObject != null ?
                                <>
                                    <Grid item lg={2} md={2.3} sm={12} xs={12} pt={3} ml={{ lg: 2, md: 2, sm: 0, xs: 0 }}>
                                        <Box className={classes.sideBarBox}>
                                            <Box className={classes.innerSideBox} ref={containerRef}>
                                                {documentData.length > 0 && documentData.map((item, ind) => (
                                                    <Box>
                                                        <Box key={ind} mt={1} className={`${classes.imageBox} ${activeIndex === ind ? classes.activeImageBox : ''}`} onClick={() => handleClick(item, ind)}>
                                                            {
                                                                formatReturn(item.document_name) == "pdf" ?
                                                                    <iframe title={item.document_url ? item.document_url : ""} src={item.document_url ? item.document_url : ""} style={{ width: "120px", [theme.breakpoints.down('sm')]: { width: "100%" } }} />
                                                                    :
                                                                    <img src={item.document_url} alt={"image_url"} className={classes.image} />
                                                            }
                                                            {activeIndex === ind ? <img src={checkIcon} alt="checkIcon" className={classes.activeImage} /> : <div className={classes.cropOverlay}></div>}
                                                        </Box>
                                                        <Text smallLabel sx={{ textAlign: "center" }}>{item.serial_number}</Text>
                                                        <Hidden mdDown> <Divider className={classes.divider} /></Hidden>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid onScroll={(e) => { setScrollPosition(e.target.scrollTop) }} item
                                        lg={activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ? 7 : 6}
                                        md={5.5} xs={12} sm={12} mt={3} ml={{ lg: -3, md: -3, sm: 1, xs: 1 }} className={classes.middleGrid}>
                                        {
                                            loader ?
                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                    <img src={blueLoader} height={100} width={100} alt='loading' style={{ marginTop: "170px" }} />
                                                </Grid>
                                                :
                                                <>
                                                    <Box className={classes.middleImageBox}  >
                                                        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"}>
                                                            <Grid item md={8} lg={6} xl={8} sm={7} xs={12}>

                                                                <Text mediumBlack14>{activeObject ? activeObject.name ? `${formatString(activeObject.name === "drivers_license"
                                                                    ? "Driving License/State ID"
                                                                    : activeObject.name
                                                                )}${activeObject.serial ? `- ${activeObject.serial}` : ""}` : "" : ""}</Text>
                                                            </Grid>
                                                            <Grid item md={4} lg={6} xl={4} sm={5} xs={12} textAlign={{ lg: 'center', md: 'center', sm: 'center', xs: 'end' }} mt={-4}>
                                                                {activeObject != null && activeObject.approval_status === "Rejected" &&
                                                                    <img src={documentReject} alt="documentReject" className={classes.rejectImage} />
                                                                }
                                                                {activeObject != null && activeObject.approval_status !== "Rejected" && <Grid container item md={10} justifyContent='end' pt={2} textAlign='end'>
                                                                    <Box display='flex' flexDirection='row' gap={2}>
                                                                        <img onClick={() => { handleFullScreen() }} src={whiteZoomin} alt="whiteZoomin" style={{ cursor: "pointer" }} />
                                                                        <img onClick={() => window.open(activeObject.document_url, '_blank', 'noopener,noreferrer')} src={whiteDownLoad} alt="whiteDownLoad" style={{ cursor: "pointer" }} />
                                                                    </Box>
                                                                </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            formatReturn(activeObject.document_name) == "pdf" ?
                                                                <div style={{ paddingTop: "80px", width: "-webkit-fill-available", display: "inline-table" }}>
                                                                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`} >
                                                                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`} >
                                                                            <div ref={fullScreenRef}>
                                                                                <Viewer
                                                                                    width="100%"
                                                                                    fileUrl={activeObject !== null ? activeObject.document_url : ''}
                                                                                    canvasCss={{
                                                                                        fontFamily: 'Gungsuh-Bold, sans-serif', // Use a fallback font
                                                                                    }}
                                                                                    onError={onError} />
                                                                            </div>
                                                                        </Worker>
                                                                    </Worker>
                                                                </div>
                                                                :
                                                                <img ref={fullScreenRef} src={activeObject != null ? activeObject.document_url : ''} alt={"image_url"} className={classes.middleImage} />
                                                        }
                                                    </Box>
                                                    {
                                                        activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ?
                                                            <Grid pt={3} item lg={12} md={12} sm={12} sx={{ background: "#ffffff !important" }}>
                                                                {
                                                                    activeObject.approval_status != "Submitted" ?
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} py={{ sm: 3 }} textAlign={"end"}>
                                                                            <Button saveNcontinue onClick={() => { handleAssignState(activeIndex + 1) }} sx={{ width: "175px !important", [theme.breakpoints.down('sm')]: { width: "fit-content !important" } }}>Continue</Button>
                                                                        </Grid>
                                                                        :
                                                                        <Grid container item lg={12} md={12} sm={12} xs={12} py={{ sm: 3 }} justifyContent={"space-between"}>
                                                                            <Button onClick={() => { handleRejectOpen() }} blackCancel sx={{ border: `1px solid ${btnTxtBlack.shade4} !important`, [theme.breakpoints.down('lg')]: { width: "fit-content !important" } }}>Reject</Button>
                                                                            <Button onClick={() => { handleApprove(activeIndex + 1) }} saveNcontinue sx={{ width: "175px !important", [theme.breakpoints.down('lg')]: { width: "fit-content !important", font: '12px Quicksand !important', } }}>Approve & Continue</Button>
                                                                        </Grid>
                                                                }
                                                            </Grid> : ""
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    {
                                        activeObject != null && (activeObject.name == "education_documents" || activeObject.name == "counter_signed_offer_letter" || activeObject.name == "w4_document" || activeObject.name == "i9_document" || activeObject.name == "all_previous_i20s") ?
                                            ""
                                            :
                                            <Grid item lg={4} md={4} sm={12} xs={12} mt={1} p={2} ml={1}>
                                                <Text largeBlack >Identified Fields</Text>
                                                <Grid container spacing={3} pt={2} pr={{ lg: 5, md: 0, sm: 0, xs: 0 }}>
                                                    <Box width={'100%'} sx={{ height: "65vh", [theme.breakpoints.down('md')]: { height: 'fit-content' } }}>
                                                        <Grid container spacing={3} mt={2} pl={3}>
                                                            {activeObject.name == "copy_of_void_cheque" ?
                                                                <>
                                                                    {voidChequeConfig?.account_number?.isVisible &&
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'account_number',
                                                                                            value: activeObject.account_number,
                                                                                            type: eye.account_number ? 'text' : 'password',
                                                                                            inputProps: { maxLength: voidChequeConfig?.account_number?.maxLength },
                                                                                        }}
                                                                                        eyeIcon={eye.account_number}
                                                                                        eyeCloseIcon={eye.account_number == false}
                                                                                        eyeHandleChange={() => {
                                                                                            setEye({
                                                                                                ...eye,
                                                                                                account_number: !eye.account_number
                                                                                            })
                                                                                        }}
                                                                                        disabled={activeObject.approval_status != "Submitted" || voidChequeConfig?.account_number?.isDisabled}
                                                                                        handleChange={(e) => { handleChange(e) }}
                                                                                        // onKeyPress={onNumberOnlyChange}
                                                                                        onKeyPress={(e) => onCharactersAndNumbersInputChange(e, voidChequeConfig?.account_number?.pattern)}
                                                                                        iconText
                                                                                        labelText={voidChequeConfig?.account_number?.required ? voidChequeConfig?.account_number?.label : voidChequeConfig?.account_number?.label + ' (Optional)'}
                                                                                        placeholder={voidChequeConfig?.account_number?.placeholder}
                                                                                        error={error.account_number}
                                                                                    />
                                                                            }
                                                                            {error.account_number ? <Text red>{error.account_number}</Text> : null}
                                                                        </Grid>}
                                                                    {voidChequeConfig?.routing_number?.isVisible &&
                                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'routing_number',
                                                                                            value: activeObject.routing_number,
                                                                                            type: eye.routing_number ? 'text' : 'password',
                                                                                            inputProps: { maxLength: voidChequeConfig?.routing_number?.maxLength }
                                                                                        }}
                                                                                        eyeIcon={eye.routing_number}
                                                                                        eyeCloseIcon={eye.routing_number == false}
                                                                                        eyeHandleChange={() => {
                                                                                            setEye({
                                                                                                ...eye,
                                                                                                routing_number: !eye.routing_number
                                                                                            })
                                                                                        }}
                                                                                        disabled={activeObject.approval_status != "Submitted" || voidChequeConfig?.routing_number?.isDisabled}
                                                                                        // onKeyPress={onNumberOnlyChange}
                                                                                        onKeyPress={(e) => onCharactersAndNumbersInputChange(e, voidChequeConfig?.routing_number?.pattern)}
                                                                                        handleChange={(e) => { handleChange(e) }}
                                                                                        iconText
                                                                                        labelText={voidChequeConfig?.routing_number?.required ? voidChequeConfig?.routing_number?.label : voidChequeConfig?.routing_number?.label + ' (Optional)'}
                                                                                        placeholder={voidChequeConfig?.routing_number?.placeholder}
                                                                                        error={error.routing_number}
                                                                                    />
                                                                            }
                                                                            {error.routing_number ? <Text red>{error.routing_number}</Text> : null}
                                                                        </Grid>}
                                                                    {voidChequeConfig?.bank_name?.isVisible &&
                                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'bank_name',
                                                                                            value: activeObject.bank_name,
                                                                                            type: 'text',
                                                                                            inputProps: { maxLength: voidChequeConfig?.bank_name?.maxLength }
                                                                                        }}
                                                                                        error={error.bank_name}
                                                                                        disabled={activeObject.approval_status != "Submitted" || voidChequeConfig?.bank_name?.isDisabled}
                                                                                        handleChange={(e) => { handleChange(e) }}
                                                                                        clientInput
                                                                                        labelText={voidChequeConfig?.bank_name?.required ? voidChequeConfig?.bank_name?.label : voidChequeConfig?.bank_name?.label + ' (Optional)'}
                                                                                        placeholder={voidChequeConfig?.bank_name?.placeholder}
                                                                                    />
                                                                            }
                                                                            {error.bank_name ? <Text red>{error.bank_name}</Text> : null}
                                                                        </Grid>}
                                                                    {voidChequeConfig?.account_type?.isVisible &&
                                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                            {
                                                                                loader ?
                                                                                    <Skeleton variant='rounded' height={50} /> :
                                                                                    <Select
                                                                                        name='account_type'
                                                                                        value={activeObject.account_type}
                                                                                        onChange={(e) => { handleChange(e) }}
                                                                                        options={accountType}
                                                                                        label={voidChequeConfig?.account_type?.required ? voidChequeConfig?.account_type?.label : voidChequeConfig?.account_type?.label + ' (Optional)'}
                                                                                        placeholder={voidChequeConfig?.account_type?.placeholder}
                                                                                        helperText={error.account_type ? <Text red>{error.account_type}</Text> : null}
                                                                                        disabled={activeObject.approval_status != "Submitted" || voidChequeConfig?.account_type?.isDisabled}
                                                                                    />
                                                                            }
                                                                        </Grid>}
                                                                </>
                                                                :
                                                                activeObject.name == "work_authorization" ?
                                                                    <>
                                                                        {visaConfig?.visa_type_id?.isVisible &&
                                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <Select
                                                                                            name='visa_type_id'
                                                                                            value={activeObject.visa_type_id}
                                                                                            onChange={(e) => { handleChange(e) }}
                                                                                            options={visaTypes}
                                                                                            label={visaConfig?.visa_type_id?.required ? visaConfig?.visa_type_id?.label : visaConfig?.visa_type_id?.label + ' (Optional)'}
                                                                                            placeholder={visaConfig?.visa_type_id?.placeholder}
                                                                                            disabled={activeObject.approval_status != "Submitted" || visaConfig?.visa_type_id?.isDisabled}
                                                                                            helperText={error.visa_type_id ? <Text red>{error.visa_type_id}</Text> : ""}
                                                                                        />
                                                                                }
                                                                            </Grid>}
                                                                        {visaConfig?.date_of_issue?.isVisible &&
                                                                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <>
                                                                                            <Date
                                                                                                labelText={visaConfig?.date_of_issue?.required ? visaConfig?.date_of_issue?.label : visaConfig?.date_of_issue?.label + ' (Optional)'}
                                                                                                name='date_of_issue'
                                                                                                value={activeObject.date_of_issue}
                                                                                                maxDate={activeObject.date_of_expiry !== '' ? moment(activeObject.date_of_expiry, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                                onChange={(value => dateChange(value, 'date_of_issue', 'visa'))}
                                                                                                height='56px'
                                                                                                disabled={activeObject.approval_status != "Submitted" || visaConfig?.date_of_issue?.isDisabled}
                                                                                                error={error.date_of_issue}
                                                                                            />
                                                                                            {error.date_of_issue ? <Text red> {error.date_of_issue}</Text> : ""}
                                                                                        </>
                                                                                }
                                                                            </Grid>}
                                                                        {visaConfig?.date_of_expiry?.isVisible &&
                                                                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <>
                                                                                            <Date
                                                                                                labelText={visaConfig?.date_of_expiry?.required ? visaConfig?.date_of_expiry?.label : visaConfig?.date_of_expiry?.label + ' (Optional)'}
                                                                                                name='date_of_expiry'
                                                                                                minDate={moment(activeObject.date_of_issue, dateFormat()).format(dateFormat())}
                                                                                                value={activeObject.date_of_expiry}
                                                                                                onChange={(value => dateChange(value, 'date_of_expiry', 'visa'))}
                                                                                                height='56px'
                                                                                                disabled={activeObject.approval_status != "Submitted" || visaConfig?.date_of_expiry?.isDisabled}
                                                                                                error={error.date_of_expiry}
                                                                                            />
                                                                                            {error.date_of_expiry ? <Text red> {error.date_of_expiry}</Text> : ""}
                                                                                        </>
                                                                                }
                                                                            </Grid>}
                                                                    </>
                                                                    :
                                                                    (activeObject.name == "passport" || activeObject.name == "i94") ?
                                                                        <>
                                                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <>
                                                                                            {
                                                                                                activeObject.name == "i94" ?
                                                                                                    <>
                                                                                                        {i94Config?.document_number?.isVisible &&
                                                                                                            <Input
                                                                                                                formControlProps={{
                                                                                                                    fullWidth: true
                                                                                                                }}
                                                                                                                inputProps={{
                                                                                                                    name: 'document_number',
                                                                                                                    value: activeObject.document_number,
                                                                                                                    inputProps: { maxLength: i94Config?.document_number?.maxLength },
                                                                                                                    type: eye.document_number ? 'text' : 'password',
                                                                                                                }}
                                                                                                                eyeIcon={eye.document_number}
                                                                                                                eyeCloseIcon={eye.document_number == false}
                                                                                                                eyeHandleChange={() => {
                                                                                                                    setEye({
                                                                                                                        ...eye,
                                                                                                                        document_number: !eye.document_number
                                                                                                                    })
                                                                                                                }}
                                                                                                                disabled={activeObject.approval_status != "Submitted" || i94Config?.document_number?.isDisabled}
                                                                                                                handleChange={(e) => { handleChange(e) }}
                                                                                                                // onKeyPress={onCharactersAndNumbersChange}
                                                                                                                iconText
                                                                                                                labelText={i94Config?.document_number?.required ? i94Config?.document_number?.label : i94Config?.document_number?.label + ' (Optional)'}
                                                                                                                placeholder={i94Config?.document_number?.placeholder}
                                                                                                                error={error.document_number}
                                                                                                            />}
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {passportConfig?.document_number?.isVisible &&
                                                                                                            <Input
                                                                                                                formControlProps={{
                                                                                                                    fullWidth: true
                                                                                                                }}
                                                                                                                inputProps={{
                                                                                                                    name: 'document_number',
                                                                                                                    value: activeObject.document_number,
                                                                                                                    type: eye.passport_document_number ? 'text' : 'password',
                                                                                                                    inputProps: { maxLength: passportConfig?.document_number?.maxLength }
                                                                                                                }}
                                                                                                                eyeIcon={eye.passport_document_number}
                                                                                                                eyeCloseIcon={eye.passport_document_number == false}
                                                                                                                eyeHandleChange={() => {
                                                                                                                    setEye({
                                                                                                                        ...eye,
                                                                                                                        passport_document_number: !eye.passport_document_number
                                                                                                                    })
                                                                                                                }}
                                                                                                                disabled={activeObject.approval_status != "Submitted" || passportConfig?.document_number?.isDisabled}
                                                                                                                handleChange={(e) => { handleChange(e) }}
                                                                                                                // onKeyPress={onCharactersAndNumbersChange}
                                                                                                                onKeyPress={(e) => onCharactersAndNumbersInputChange(e, passportConfig?.document_number?.pattern)}
                                                                                                                iconText
                                                                                                                labelText={passportConfig?.document_number?.required ? passportConfig?.document_number?.label : passportConfig?.document_number?.label + ' (Optional)'}
                                                                                                                placeholder={passportConfig?.document_number?.placeholder}
                                                                                                                error={error.document_number}
                                                                                                            />}
                                                                                                    </>
                                                                                            }

                                                                                        </>
                                                                                }
                                                                                {error.document_number ? <Text red> {error.document_number}</Text> : ""}
                                                                            </Grid>
                                                                            {
                                                                                activeObject.name != "i94" &&
                                                                                <Grid item md={12} xs={12} sm={12} lg={12}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            <>
                                                                                                {passportConfig?.issued_country?.isVisible &&
                                                                                                    <Select
                                                                                                        name='issued_country'
                                                                                                        value={activeObject.issued_country}
                                                                                                        onChange={(e) => { handleChange(e) }}
                                                                                                        options={countries}
                                                                                                        disabled={activeObject.approval_status != "Submitted" || passportConfig?.issued_country?.isDisabled}
                                                                                                        label={passportConfig?.issued_country?.required ? passportConfig?.issued_country?.label : passportConfig?.issued_country?.label + ' (Optional)'}
                                                                                                        placeholder={passportConfig?.issued_country?.placeholder}
                                                                                                        helperText={error.issued_country ? <Text red>{error.issued_country}</Text> : null}
                                                                                                    />}
                                                                                            </>
                                                                                    }
                                                                                </Grid>
                                                                            }

                                                                            <Grid item md={12} xs={12} sm={6} lg={6}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        <>
                                                                                            {activeObject.name == "i94" ?
                                                                                                <>
                                                                                                    {i94Config?.date_of_issue?.isVisible &&
                                                                                                        <>
                                                                                                            <Date
                                                                                                                labelText={i94Config?.date_of_issue?.required ? i94Config?.date_of_issue?.label : i94Config?.date_of_issue?.label + ' (Optional)'}
                                                                                                                name='date_of_issue'
                                                                                                                value={activeObject.date_of_issue}
                                                                                                                maxDate={activeObject.date_of_expiry !== '' ? moment(activeObject.date_of_expiry, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                                                onChange={(value => dateChange(value, 'date_of_issue', 'passport'))}
                                                                                                                height='56px'
                                                                                                                disabled={activeObject.approval_status != "Submitted" || i94Config?.date_of_issue?.isDisabled}
                                                                                                                error={error.date_of_issue}
                                                                                                            />
                                                                                                            {error.date_of_issue ? <Text red> {error.date_of_issue}</Text> : ""}
                                                                                                        </>}
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {passportConfig?.date_of_issue?.isVisible &&
                                                                                                        <>
                                                                                                            <Date
                                                                                                                labelText={activeObject.name == passportConfig?.date_of_issue?.required ? passportConfig?.date_of_issue?.label : passportConfig?.date_of_issue?.label + ' (Optional)'}
                                                                                                                name='date_of_issue'
                                                                                                                value={activeObject.date_of_issue}
                                                                                                                maxDate={activeObject.date_of_expiry !== '' ? moment(activeObject.date_of_expiry, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                                                onChange={(value => dateChange(value, 'date_of_issue', 'passport'))}
                                                                                                                height='56px'
                                                                                                                disabled={activeObject.approval_status != "Submitted" || passportConfig?.date_of_issue?.isDisabled}
                                                                                                                error={error.date_of_issue}
                                                                                                            />
                                                                                                            {error.date_of_issue ? <Text red> {error.date_of_issue}</Text> : ""}
                                                                                                        </>}
                                                                                                </>
                                                                                            }

                                                                                        </>
                                                                                }
                                                                            </Grid>
                                                                            {
                                                                                activeObject.name == "i94" ?
                                                                                    <>
                                                                                        {i94Config?.expiry_type?.isVisible &&
                                                                                            <Grid item md={6} xs={12} sm={6} lg={6}>
                                                                                                <Select
                                                                                                    options={expiryTypeList}
                                                                                                    name='expiry_type'
                                                                                                    value={activeObject.expiry_type || ''}
                                                                                                    onChange={(e) => { handleChange(e); setError({ ...error, date_of_expiry: '' }) }}
                                                                                                    label={i94Config?.expiry_type?.required ? i94Config?.expiry_type?.label : i94Config?.expiry_type?.label + ' (Optional)'}
                                                                                                    placeholder={i94Config?.expiry_type?.placeholder}
                                                                                                    disabled={activeObject.date_of_issue == '' || activeObject.approval_status != "Submitted" || i94Config?.expiry_type?.isDisabled}
                                                                                                    helperText={error.expiry_type ? <Text red>{error.expiry_type}</Text> : null}
                                                                                                />
                                                                                            </Grid>}
                                                                                    </>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                            <Grid item md={12} xs={12} sm={6} lg={6}>
                                                                                {
                                                                                    loader ?
                                                                                        <Skeleton variant='rounded' height={50} /> :
                                                                                        activeObject.name == "i94" && (activeObject.expiry_type == '' || activeObject.expiry_type == 1) ? '' :
                                                                                            <>
                                                                                                {activeObject.name == "i94" ?
                                                                                                    <>
                                                                                                        {i94Config?.date_of_expiry?.isVisible &&
                                                                                                            <>
                                                                                                                <Date
                                                                                                                    labelText={i94Config?.date_of_expiry?.required ? i94Config?.date_of_expiry?.label : i94Config?.date_of_expiry?.label + ' (Optional)'}
                                                                                                                    name='date_of_expiry'
                                                                                                                    minDate={moment(activeObject.date_of_issue, dateFormat()).format(dateFormat())}
                                                                                                                    value={activeObject.date_of_expiry}
                                                                                                                    onChange={(value => dateChange(value, 'date_of_expiry', 'i94'))}
                                                                                                                    height='56px'
                                                                                                                    disabled={activeObject.approval_status != "Submitted" || i94Config?.date_of_expiry?.isDisabled}
                                                                                                                    error={error.date_of_expiry}
                                                                                                                />
                                                                                                                {error.date_of_expiry ? <Text red> {error.date_of_expiry}</Text> : ""}
                                                                                                            </>}
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {passportConfig?.date_of_expiry?.isVisible &&
                                                                                                            <>
                                                                                                                <Date
                                                                                                                    labelText={passportConfig?.date_of_expiry?.required ? passportConfig?.date_of_expiry?.label : passportConfig?.date_of_expiry?.label + ' (Optional)'}
                                                                                                                    name='date_of_expiry'
                                                                                                                    minDate={moment(activeObject.date_of_issue, dateFormat()).format(dateFormat())}
                                                                                                                    value={activeObject.date_of_expiry}
                                                                                                                    onChange={(value => dateChange(value, 'date_of_expiry', 'passport'))}
                                                                                                                    height='56px'
                                                                                                                    disabled={activeObject.approval_status != "Submitted" || passportConfig?.date_of_expiry?.isDisabled}
                                                                                                                    error={error.date_of_expiry}
                                                                                                                />
                                                                                                                {error.date_of_expiry ? <Text red> {error.date_of_expiry}</Text> : ""}
                                                                                                            </>}
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                }
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        activeObject.name == "drivers_license" || activeObject.name == "signed_ssn" ?
                                                                            <>
                                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                                    {
                                                                                        loader ?
                                                                                            <Skeleton variant='rounded' height={50} /> :
                                                                                            <>
                                                                                                {activeObject.name == "signed_ssn" ?

                                                                                                    <>
                                                                                                        {ssnConfig?.document_number?.isVisible &&

                                                                                                            <Input
                                                                                                                formControlProps={{
                                                                                                                    fullWidth: true
                                                                                                                }}
                                                                                                                inputProps={{
                                                                                                                    name: 'document_number',
                                                                                                                    value: activeObject.document_number,
                                                                                                                    inputProps: { maxLength: ssnConfig?.document_number?.maxLength },
                                                                                                                    type: eye.ssn_document_number ? 'text' : 'password',
                                                                                                                }}
                                                                                                                eyeIcon={eye.ssn_document_number}
                                                                                                                eyeCloseIcon={eye.ssn_document_number == false}
                                                                                                                eyeHandleChange={() => {
                                                                                                                    setEye({
                                                                                                                        ...eye,
                                                                                                                        ssn_document_number: !eye.ssn_document_number
                                                                                                                    })
                                                                                                                }}
                                                                                                                disabled={activeObject.approval_status != "Submitted" || ssnConfig?.document_number?.isDisabled}
                                                                                                                handleChange={(e) => { handleChange(e) }}
                                                                                                                iconText
                                                                                                                // onKeyPress={onCharactersAndNumbersChange}
                                                                                                                labelText={ssnConfig?.document_number?.required ? ssnConfig?.document_number?.label : ssnConfig?.document_number?.label + ' (Optional)'}
                                                                                                                placeholder={ssnConfig?.document_number?.placeholder}
                                                                                                                error={error.document_number}
                                                                                                            />}
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {drivingLicenseConfig?.document_number?.isVisible &&
                                                                                                            <Input
                                                                                                                formControlProps={{
                                                                                                                    fullWidth: true
                                                                                                                }}
                                                                                                                inputProps={{
                                                                                                                    name: 'document_number',
                                                                                                                    value: activeObject.document_number,
                                                                                                                    inputProps: { maxLength: drivingLicenseConfig?.document_number?.maxLength },
                                                                                                                    type: eye.driving_license_number ? 'text' : 'password',
                                                                                                                }}
                                                                                                                eyeIcon={eye.driving_license_number}
                                                                                                                eyeCloseIcon={eye.driving_license_number == false}
                                                                                                                eyeHandleChange={() => {
                                                                                                                    setEye({
                                                                                                                        ...eye,
                                                                                                                        driving_license_number: !eye.driving_license_number
                                                                                                                    })
                                                                                                                }}
                                                                                                                disabled={activeObject.approval_status != "Submitted" || drivingLicenseConfig?.document_number?.isDisabled}
                                                                                                                handleChange={(e) => { handleChange(e) }}
                                                                                                                iconText
                                                                                                                // onKeyPress={onCharactersAndNumbersChange}
                                                                                                                nKeyPress={(e) => onCharactersAndNumbersInputChange(e, drivingLicenseConfig?.document_number?.pattern)}
                                                                                                                labelText={drivingLicenseConfig?.document_number?.required ? drivingLicenseConfig?.document_number?.label : drivingLicenseConfig?.document_number?.label + ' (Optional)'}
                                                                                                                placeholder={drivingLicenseConfig?.document_number?.placeholder}
                                                                                                                error={error.document_number}
                                                                                                            />}
                                                                                                    </>
                                                                                                }

                                                                                            </>
                                                                                    }
                                                                                    {error.document_number ? <Text red> {error.document_number}</Text> : ""}
                                                                                </Grid>
                                                                                {drivingLicenseConfig?.valid_from?.isVisible &&
                                                                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                                                                        {
                                                                                            loader ?
                                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                                activeObject.name != "signed_ssn" ?
                                                                                                    <>
                                                                                                        <Date
                                                                                                            labelText={drivingLicenseConfig?.valid_from?.required ? drivingLicenseConfig?.valid_from?.label : drivingLicenseConfig?.valid_from?.label + ' (Optional)'}
                                                                                                            name='valid_from'
                                                                                                            value={activeObject.valid_from}
                                                                                                            maxDate={activeObject.valid_till !== '' ? moment(activeObject.valid_till, dateFormat()).format(dateFormat()) : moment().format(dateFormat())}
                                                                                                            onChange={(value => dateChange(value, 'valid_from', activeObject.name))}
                                                                                                            height='56px'
                                                                                                            disabled={activeObject.approval_status != "Submitted" || drivingLicenseConfig?.valid_from?.isDisabled}
                                                                                                            error={error.valid_from}
                                                                                                        />
                                                                                                    </>
                                                                                                    : ''
                                                                                        }
                                                                                    </Grid>}
                                                                                {drivingLicenseConfig?.valid_till?.isVisible &&
                                                                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                                                                        {
                                                                                            loader ?
                                                                                                <Skeleton variant='rounded' height={50} /> :
                                                                                                activeObject.name != "signed_ssn" ?
                                                                                                    <>
                                                                                                        <Date
                                                                                                            labelText={drivingLicenseConfig?.valid_till?.required ? drivingLicenseConfig?.valid_till?.label : drivingLicenseConfig?.valid_till?.label + ' (Optional)'}
                                                                                                            name='valid_till'
                                                                                                            minDate={moment(activeObject.valid_from, dateFormat()).format(dateFormat())}
                                                                                                            value={activeObject.valid_till}
                                                                                                            onChange={(value => dateChange(value, 'valid_till', activeObject.name))}
                                                                                                            height='56px'
                                                                                                            disabled={activeObject.approval_status != "Submitted" || drivingLicenseConfig?.valid_till?.isDisabled}
                                                                                                            error={error.valid_till}

                                                                                                        />
                                                                                                        {error.valid_till ? <Text red> {error.valid_till}</Text> : ""}
                                                                                                    </>
                                                                                                    : ''
                                                                                        }
                                                                                    </Grid>}
                                                                            </>
                                                                            :
                                                                            ""
                                                            }
                                                        </Grid>
                                                    </Box>
                                                    {
                                                        !loader &&
                                                        <>
                                                            {
                                                                activeObject.approval_status != "Submitted" ?
                                                                    <Grid item md={12} xs={12} sm={12} lg={12} textAlign={"end"} my={{ sm: 5, xs: 4 }}>
                                                                        <Button saveNcontinue onClick={() => { handleAssignState(activeIndex + 1) }} sx={{ width: "175px !important", [theme.breakpoints.down('sm')]: { width: "fit-content !important" } }}>Continue</Button>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container item md={12} xs={12} sm={12} lg={12} my={{ sm: 5, xs: 4 }} justifyContent={"space-between"} spacing={2}>
                                                                        <Button onClick={() => { handleRejectOpen() }} blackCancel sx={{ border: `1px solid ${btnTxtBlack.shade4} !important`, [theme.breakpoints.down('lg')]: { width: "fit-content !important" } }}>Reject</Button>
                                                                        <Button onClick={() => { handleApprove(activeIndex + 1) }} saveNcontinue sx={{ width: "175px !important", [theme.breakpoints.down('lg')]: { width: "fit-content !important", font: '12px Quicksand !important', } }}>Approve & Continue</Button>
                                                                    </Grid>
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                    }
                                </>
                                : ''
                            }
                        </>
                        :
                        step == 0 && requestReupload ?
                            <Grid container >
                                <Grid item lg={2} md={2} sm={1} xs={1} pt={3}></Grid>
                                <Grid container item lg={7} md={7} sm={10} xs={12} px={{ xs: 1 }} mt={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Card sx={{ padding: '20px !important', boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important", borderRadius: '15px', [theme.breakpoints.down('sm')]: { padding: '10px !important' } }}>
                                            <CardContent sx={{ padding: '15px !important', height: '60vh', overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                                        <Text largeBlack>Approved Document</Text>
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                        {documentData.some(item => item.approval_status === "Approved") ? (
                                                            documentData.map((item) => (
                                                                item.approval_status === "Approved" && (
                                                                    <Grid item lg={6} md={6} sm={6} xs={12} py={1} key={item.id}>
                                                                        <Box className={classes.documentNameBox}>
                                                                            <img src={docSuccess} alt="checkedCircle" />
                                                                            <Text mediumBlack14>
                                                                                {item.name
                                                                                    ? `${formatString(item.name === "drivers_license"
                                                                                        ? "Driving License/State ID"
                                                                                        : item.name
                                                                                    )}${item.serial ? ` - ${item.serial}` : ""}`
                                                                                    : ""}
                                                                            </Text>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            ))
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Text grey16>No approved documents available.</Text>
                                                            </Grid>
                                                        )}
                                                    </Grid>

                                                    <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                                        <Text largeBlack>Rejected Document</Text>
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                            {documentData.some(item => item.approval_status === "Rejected") ? (
                                                                documentData.map((item) => (
                                                                    item.approval_status === "Rejected" && (
                                                                        <Grid item lg={6} md={6} sm={6} xs={12} py={1} key={item.id}>
                                                                            <Box className={classes.documentNameBox}>
                                                                                <img src={docReject} alt="checkedCircle" />
                                                                                <Text mediumBlack14>
                                                                                    {item.name
                                                                                        ? `${formatString(item.name === "drivers_license"
                                                                                            ? "Driving License/State ID"
                                                                                            : item.name
                                                                                        )}${item.serial ? ` - ${item.serial}` : ""}`
                                                                                        : ""}
                                                                                </Text>
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                ))
                                                            ) : (
                                                                <Grid item xs={12}>
                                                                    <Text grey16>No rejected documents available.</Text>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} pt={3} textAlign={"end"}>
                                        <Button onClick={() => { handleRequestReupload() }} saveNcontinue sx={{ width: "175px !important", }}>Request to Reupload</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                <Text>Documents Submitted </Text>
                            </Grid>
                }
            </>

            <Dialog classes={{ paper: classes.paper }} onClose={() => { setRejectOpen(false) }} open={rejectOpen} TransitionComponent={Transition}>
                <DialogContent sx={{
                    textAlign: "center", padding: "30px",
                    "@media (min-width: 100px) and (max-width: 499px)": {
                        padding: '10px',
                    },
                }}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <img src={deleteAlert} alt="deleteAlert" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2}>
                            <Text popupHead1 >Are You Sure?</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2}>
                            <Text offBoardBodyFont>Do you want to reject this document?</Text>
                        </Grid>
                        {reasonForRejectionConfig?.isVisible &&
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} textAlign={"left"}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: activeObject?.name == 'i9_document' ? 'i9_reason_for_rejection' : activeObject?.name == 'w4_document' ? 'w4_reason_for_rejection' : 'reason_for_rejection',
                                        value: activeObject?.name == 'i9_document' ? activeObject?.i9_reason_for_rejection : activeObject?.name == 'w4_document' ? activeObject?.w4_reason_for_rejection : activeObject?.reason_for_rejection,
                                        inputProps: { maxLength: reasonForRejectionConfig?.maxLength }
                                    }}
                                    // onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                    labelText={reasonForRejectionConfig?.required ? reasonForRejectionConfig?.label : reasonForRejectionConfig?.label + ' (Optional)'}
                                    handleChange={handleChangeRejection}
                                    rows={reasonForRejectionConfig?.rows}
                                    disabled={reasonForRejectionConfig?.isDisabled}
                                    placeholder={reasonForRejectionConfig?.placeholder}
                                    multiline={true}
                                    commentsField
                                    error={
                                        activeObject?.name === 'i9_document' && error.i9_reason_for_rejection ? (
                                            <Text red>{error.i9_reason_for_rejection}</Text>
                                        ) : activeObject?.name === 'w4_document' && error.w4_reason_for_rejection ? (
                                            <Text red>{error.w4_reason_for_rejection}</Text>
                                        ) : error.reason_for_rejection ? (
                                            <Text red>{error.reason_for_rejection}</Text>
                                        ) : null
                                    }
                                />
                                {activeObject?.name === 'i9_document' && error.i9_reason_for_rejection ? (
                                    <Text red>{error.i9_reason_for_rejection}</Text>
                                ) : activeObject?.name === 'w4_document' && error.w4_reason_for_rejection ? (
                                    <Text red>{error.w4_reason_for_rejection}</Text>
                                ) : error.reason_for_rejection ? (
                                    <Text red>{error.reason_for_rejection}</Text>
                                ) : null}

                            </Grid>}
                        <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>

                            <Button onClick={() => { setRejectOpen(false) }} outlineBlueMedium1 sx={{
                                width: "124px !important", borderRadius: "8px !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, minHeight: "44px !important",
                                "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: "12px Quicksand !important", width: "80px !important",
                                },
                            }}>No</Button>
                            <LoadingButton onClick={() => { handleRejectDocument() }} loading={loader} deleteButton sx={{
                                background: "#0C75EB !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, minWidth: "135px !important",
                                "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: "12px Quicksand !important", minWidth: "100px !important",
                                },
                            }}> Yes, Reject</LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
            <Dialog classes={{ paper: classes.paper }} onClose={() => { setReuploadOpen(false) }} open={reuploadOpen} TransitionComponent={Transition}>
                <DialogContent sx={{
                    textAlign: "center", padding: "30px",
                    "@media (min-width: 100px) and (max-width: 499px)": {
                        padding: '10px',
                    },
                }}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <img src={requestReuploadImg} alt="requestReupload" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2}>
                            <Text popupHead1 >Invitation Sent Successfully</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2}>
                            <Text offBoardBodyFont>We will notify you once the employee has uploaded their document. In the meantime, please continue verifying the emergency contact details.</Text>
                        </Grid>

                        <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>

                            <Button onClick={() => { handleRequestContinue() }} saveNcontinue sx={{
                                width: "100% !important",
                                font: "14px Quicksand !important", fontWeight: `${700} !important`, minHeight: "40px !important",
                                "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: "12px Quicksand !important"
                                },
                            }}>Go To Dashboard</Button>

                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
            <Dialog classes={{ paper: classes.paper }} onClose={() => { setFinalPopOpen(false) }} open={finalPopOpen} TransitionComponent={Transition}>
                <DialogContent sx={{
                    textAlign: "center", padding: "30px",
                    "@media (min-width: 100px) and (max-width: 499px)": {
                        padding: '10px',
                    },
                }}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <img src={successIcon} alt="successIcon" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2}>
                            <Text offBoardBodyFont>You have successfully verified documents of <span style={{ font: '14px Quicksand', fontWeight: 600 }}>{data?.first_name ? data?.first_name : ""}&nbsp;{data?.middle_name ? data?.middle_name : ""}&nbsp;{data?.last_name ? data?.last_name : ""}</span></Text>
                        </Grid>

                        <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>

                            <Button onClick={() => { handleRequestContinue() }} saveNcontinue sx={{
                                width: "100% !important",
                                font: "14px Quicksand !important", fontWeight: `${700} !important`, minHeight: "40px !important",
                                "@media (min-width: 100px) and (max-width: 499px)": {
                                    font: "12px Quicksand !important"
                                },
                            }}>Go To Dashboard</Button>

                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
        </Grid >
    )
}

export default EmployeeReVerifyDocuments