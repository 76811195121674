export const employeeProfileDefaultConfig = {
    "generalDetails": {
        "basicDetails": {
            "first_name": {
                "label": "First Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid First name",
                "isVisible": true,
                "isDisabled": true
            },
            "middle_name": {
                "label": "Middle Name",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Middle Name",
                "isVisible": true,
                "isDisabled": true
            },
            "last_name": {
                "label": "Last Name",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 40,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Last Name",
                "isVisible": true,
                "isDisabled": true
            },
            "dob": {
                "label": "Date of Birth",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "ageLimit": 18,
                "ageLimitMsg": "Minimum age should be greater than 18",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "gender": {
                "placeholder": "",
                "label": "Gender",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "gender_description": {
                "label": "Gender Description",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "minLength": 3,
                "minLengthMsg": "Invalid Charaters",
                "maxLength": 50,
                "pattern": "/^[a-zA-Z\\s-]*$/",
                "patternMsg": "Invalid Charaters",
                "isVisible": true,
                "isDisabled": false
            },
            "blood_group": {
                "placeholder": "",
                "label": "Blood Group",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "marital_status_id": {
                "placeholder": "",
                "label": "Marital Status",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "deposit_form_documents": {
                "size": 25,
                "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                "label": "W-4 Form",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "contactDetails": {
            "contact_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_contact_number": {
                "placeholder": "",
                "label": "Alternate Mobile Number",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Alternate Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "email_id": {
                "placeholder": "",
                "label": "Email ID",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            },
            "alternate_email_id": {
                "placeholder": "",
                "label": "Alternate Email ID",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
                "patternMsg": "Please enter a valid Email Id",
                "isVisible": true,
                "isDisabled": false
            }
        },
        "currentAddressDetails": {
            "address_line_one": {
                "placeholder": "",
                "label": "Address Line 1",
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_line_two": {
                "placeholder": "",
                "label": "Address Line 2",
                "required": false,
                "requiredMsg": "This field is required",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "location": {
                "zip_code": {
                    "label": "Zipcode / Pincode",
                    "zipcodeLabel": "Zipcode",
                    "pincodeLabel": "Pincode",
                    "placeholder": "",
                    "maxLength": 6,
                    "zipcodeLength": 5,
                    "pincodeLenght": 6,
                    "pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "city": {
                    "label": "City",
                    "placeholder": "",
                    "maxLength": 50,
                    "pattern": "/^[a-zA-Z0-9 ]+$/",
                    "patternMsg": "Please enter a valid city",
                    "required": true,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "state": {
                    "label": "State",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": true
                },
                "country": {
                    "label": "Country",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },
        "employmentDetails": {
            "employment_type_id": {
                "placeholder": "",
                "label": "Employment Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "employment_category_id": {
                "placeholder": "",
                "label": "Employment Category",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "company_id": {
                "placeholder": "",
                "label": "Vendor",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "date_of_joining": {
                "label": "Joining Date",
                "placeholder": null,
                "required": true,
                "requiredMsg": "This field is required",
                "maxLength": null,
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "ssn": {
                "label": "SSN",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "SSN Number already exists",
                "maxLength": null,
                "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                "patternMsg": "Invalid SSN, please check the format",
                "isVisible": true,
                "isDisabled": false
            },
            "is_usc": {
                "placeholder": "",
                "label": "Is the Employee a US Citizen?",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "visa_type_id": {
                "placeholder": "",
                "label": "Visa Type",
                "required": true,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "opening_balance": {
                "label": "Opening Balance",
                "placeholder": "",
                "required": false,
                "requiredMsg": "This field is required",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "standard_pay_amount": {
                "label": "Standard Wage",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            },
            "health_insurance_amount": {
                "label": "Insurance Amount",
                "placeholder": "",
                "required": true,
                "requiredMsg": "This field is required",
                "errorMsg": "Value must be greater than 0.",
                "pattern": "/^(?:\\d{1,10}|\\d{1,10}\\.\\d{0,2})$/",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": true
            }
        },
        "emergencyContactDetails": {
            "name": {
                "label": "Name",
                "placeholder": "",
                "maxLength": 33,
                "pattern": "/^[A-Za-z\\s'-]+$/",
                "patternMsg": "Please enter a valid Name",
                "isVisible": true,
                "isDisabled": false
            },
            "contact_number": {
                "placeholder": "",
                "label": "Mobile Number",
                "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
                "patternMsg": "Please enter a valid Mobile Number",
                "isVisible": true,
                "isDisabled": false
            },
            "relationship_id": {
                "placeholder": "",
                "label": "Relation",
                "pattern": "",
                "patternMsg": "",
                "isVisible": true,
                "isDisabled": false
            },
            "address_1": {
                "placeholder": "",
                "label": "Address Line 1",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "address_2": {
                "placeholder": "",
                "label": "Address Line 2 Optional",
                "maxLength": 225,
                "pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
                "patternMsg": "Please enter a valid address",
                "isVisible": true,
                "isDisabled": false
            },
            "location": {
                "zip_code": {
                    "label": "Zipcode / Pincode",
                    "zipcodeLabel": "Zipcode",
                    "pincodeLabel": "Pincode",
                    "placeholder": "",
                    "maxLength": 6,
                    "zipcodeLength": 5,
                    "pincodeLenght": 6,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "city": {
                    "label": "City",
                    "placeholder": "",
                    "maxLength": 50,
                    "pattern": "/^[a-zA-Z0-9 ]+$/",
                    "patternMsg": "Please enter a valid city",
                    "isVisible": true,
                    "isDisabled": false
                },
                "state": {
                    "label": "State",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "country": {
                    "label": "Country",
                    "placeholder": "",
                    "maxLength": null,
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        }
    },
    "documentsDetails": {
        "workAuthorization": {
            "passport": {
                "passportUpload": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only ",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Passport Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "passportNumber": {
                    "label": "Passport Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Passport Number is required",
                    "maxLength": 14,
                    "minLength": 8,
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "minLengthMsg": "Passport Number must be between 8 and 14 characters in length",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "issued_country_id": {
                    "label": "Issued Country",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Issued Country is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": null,
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "i94": {
                "new_document": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Please upload files in PDF,PNG OR JPEG only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "I-94 Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "I-94 Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "I-94 Number is required",
                    "maxLength": 11,
                    "maxLengthMsg": "i94 Number must be 11 characters in length",
                    "pattern": "/^(?!(\\d)\\1+$)(?:\\d{11}|\\d{9}[A-Za-z]\\d)$/",
                    "patternMsg": "Please enter a valid I-94 Number",
                    "alreadyExistsMsg": "I-94 Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Recent Date of Entry",
                    "required": false,
                    "requiredMsg": "Recent Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "expiry_type": {
                    "label": "Admit Until Date",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Admit Until Date is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Admit Until Date",
                    "required": false,
                    "requiredMsg": "This field is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "visa": {
                "visa_type_id": {
                    "label": "Visa Type",
                    "placeholder": "",
                    "required": true,
                    "pattern": "",
                    "patternMsg": "",
                    "requiredMsg": "Visa Type is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "visa_document": {
                    "format": [
                        "pdf",
                        "doc",
                        "docx"
                    ],
                    "formatMsg": "Please upload files in PDF,Docs only",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 10,
                    "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                    "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                    "label": {
                        "name": "*Add visa documents like EAD, I983, I-129, I-797, GC, GC EAD, etc",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                },
                "valid_from": {
                    "label": "Date of Issue",
                    "required": false,
                    "requiredMsg": "Date of Issue is required",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date of Expiry",
                    "required": false,
                    "requiredMsg": "Date of Expiry is required",
                    "isVisible": true,
                    "isDisabled": false
                }
            }
        },
        "educationDetails": {
            "education_documents": {
                "format": [
                    "pdf",
                    "doc",
                    "docx"
                ],
                "formatMsg": "Please upload files in PDF,Docs only",
                "size": 25,
                "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                "totalFiles": 10,
                "totalFilesMsg": "You can upload a maximum of 10 files at a time.",
                "totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
                "label": {
                    "name": "Add your educational documents like Grade card, certificates, transcripts, etc",
                    "format": "Document Size 25MB, Format - PDF, Docs"
                }
            },
            "uploaded_docs": {
                "label": "Uploaded Documents"
            }
        },
        "personalDocuments": {
            "drivingLicense": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "Upload Document",
                        "format": "Size 25MB, Format - PDF, IMG (Jpeg, PNG)"
                    }
                },
                "document_type_id": {
                    "label": "Document Type",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Document Type is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "document_number": {
                    "label": "Document Number",
                    "placeholder": "",
                    "required": true,
                    "requiredMsg": "Document Number is required",
                    "maxLength": 16,
                    "minLength": 4,
                    "minLengthMsg": "Document Number must be between 4 to 16 alphanumeric characters",
                    "pattern": "/^[a-zA-Z0-9]+$/",
                    "patternMsg": "",
                    "alreadyExistsMsg": "Passport Number is already exists",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_from": {
                    "label": "Date Of Issue",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Issue is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                },
                "valid_till": {
                    "label": "Date Of Expiry",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "Date Of Expiry is required",
                    "pattern": "",
                    "patternMsg": "",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "ssn": {
                "new_document_id": {
                    "format": [
                        "image/png",
                        "image/jpeg",
                        "application/jpeg",
                        "application/png",
                        "application/jpg",
                        "application/pdf"
                    ],
                    "formatMsg": "Upload documents Format PDF/IMG (Jpeg, PNG)",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "label": {
                        "name": "SSN Document",
                        "format": "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"
                    }
                },
                "document_number": {
                    "label": "SSN Number",
                    "placeholder": "",
                    "required": false,
                    "requiredMsg": "SSN Number is required",
                    "maxLength": 11,
                    "pattern": "/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/",
                    "patternMsg": "Invalid SSN, please check the format",
                    "isVisible": true,
                    "isDisabled": false
                }
            },
            "otherDocuments": {
                "other_documents": {
                    "format": [
                        "msword",
                        "doc",
                        "vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "ms-doc",
                        "pdf"
                    ],
                    "formatMsg": "Upload document Format PDF/Docs",
                    "size": 25,
                    "sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
                    "totalFiles": 5,
                    "totalFilesMsg": "Upload 5 Documents Only",
                    "label": {
                        "name": "Add any other documentation like Countersigned Offer Letter, Other Personal and Visa related documents.",
                        "format": "Document Size 25MB, Format - PDF, Docs"
                    }
                },
                "uploaded_docs": {
                    "label": "Uploaded Documents"
                }
            }
        }
    },
    "bankDetails": {
        "i9_document": {
            "label": "I-9 Documents",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/i-9.png",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "W4_document": {
            "label": "W-4 Form",
            "required": false,
            "requiredMsg": "This field is required",
            "sampleFileLink": "https://gateway-qa.japfu.app/api/v1/documents/Default/w4.jpg",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "void_cheque": {
            "label": "Void Cheque",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        },
        "bank_name": {
            "label": "Bank Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 2,
            "minLengthMsg": "Bank name should be greater than 2 characters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z]+( [a-zA-Z]+)*$/",
            "patternMsg": "Please enter a valid bank name",
            "isVisible": true,
            "isDisabled": false
        },
        "account_type": {
            "label": "Account Type",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": null,
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "account_number": {
            "label": "Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account Number is already exists",
            "errorMsg1": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_account_number": {
            "label": "Confirm Account Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 6,
            "minLengthMsg": "Please enter a valid 6 - 17 digit account number",
            "maxLength": 17,
            "errorMsg": "Account number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "routing_number": {
            "label": "Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "confirm_routing_number": {
            "label": "Confirm Routing Number",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "minLength": 9,
            "minLengthMsg": "Please enter a valid 9 digit routing number",
            "maxLength": 9,
            "errorMsg": "Routing number not matching",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_type": {
            "label": "Choose Deposit Configuration",
            "info": "Choose your bank deposit configuration and division",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "deposit_value": {
            "label1": "Deposit Percentage",
            "label2": "Deposit Value",
            "info1": "Enter the deposit percentage that you want to have in this bank",
            "info2": "Enter the deposit value that you want to have in this bank",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "errorMsg": "Routing number not matching",
            "pattern": "/^\\d+(\\.\\d+)?$/",
            "patternMsg": "Please enter only numbers",
            "isVisible": true,
            "isDisabled": false
        }
    },

    "skills": {
        "skill_id": {
            "placeholder": "",
            "label": "Skill Name",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "expertise": {
            "placeholder": "",
            "label": "Level Of Expertise",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "certification": {
            "placeholder": "",
            "label": "Certification Link/URL",
            "required": false,
            "requiredMsg": "This field is required",
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "certification_date": {
            "label": "Admit Until Date",
            "required": false,
            "requiredMsg": "This field is required",
            "isVisible": true,
            "isDisabled": false,
            "pattern": "",
            "patternMsg": ""
        },
        "experience_years": {
            "placeholder": "",
            "label": "Years of Experience",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "documents": {
            "label": "Upload Certificate",
            "required": false,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false,
            "fileSizeLimit": 25,
            "fileSizeLimitMsg": "File size must be 25 MB or less. Please upload a smaller file"
        }
    }
}

export const viaLinkOnboardingDefaultConfig = {
    "basicDetails": {
        "first_name": {
            "label": "First Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid First name",
            "isVisible": true,
            "isDisabled": true
        },
        "middle_name": {
            "label": "Middle Name",
            "placeholder": "",
            "required": false,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Middle Name",
            "isVisible": true,
            "isDisabled": false
        },
        "last_name": {
            "label": "Last Name",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "maxLength": 40,
            "pattern": "/^[A-Za-z\\s'-]+$/",
            "patternMsg": "Please enter a valid Last Name",
            "isVisible": true,
            "isDisabled": true
        },
        "email_id": {
            "placeholder": "",
            "label": "Email ID",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
            "patternMsg": "Please enter a valid Email Id",
            "existingEmailMsg": "Email ID already exists",
            "isVisible": true,
            "isDisabled": true
        },
        "mobile_number": {
            "placeholder": "",
            "label": "Mobile Number",
            "maxLength": 12,
            "required": true,
            "existingMobileMsg": "Mobile Number already exists",
            "requiredMsg": "This field is required",
            "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
            "patternMsg": "Please enter a valid Mobile Number",
            "isVisible": true,
            "isDisabled": false
        },
        "dob": {
            "label": "Date of Birth",
            "required": true,
            "requiredMsg": "This field is required",
            "ageLimit": 18,
            "ageLimitMsg": "Minimum age should be greater than 18",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "employee_category_id": {
            "placeholder": "",
            "label": "Employment Category",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender": {
            "placeholder": "",
            "label": "Gender",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        },
        "gender_description": {
            "label": "Gender Description",
            "placeholder": "",
            "required": true,
            "requiredMsg": "This field is required",
            "minLength": 3,
            "minLengthMsg": "Invalid Charaters",
            "maxLength": 50,
            "pattern": "/^[a-zA-Z\\s-]*$/",
            "patternMsg": "Invalid Charaters",
            "isVisible": true,
            "isDisabled": false
        },
        "date_of_joining": {
            "label": "Date of Joining",
            "required": true,
            "requiredMsg": "This field is required",
            "pattern": "",
            "patternMsg": "",
            "isVisible": true,
            "isDisabled": false
        }
    }
}