import React, { useEffect, useState } from 'react'
import CommonApi from '../../../../apis/CommonApi';
import { Box, Grid, Stack, Checkbox } from "@mui/material";
import Text from "../../../../components/customText/Text";
import Select from "../../../../components/select/Select";
import Input from "../../../../components/input/Input";
import Date from "../../../../components/datePicker/Date";
import FileInput from "../../../../components/muiFileInput/FileInput";
import Button from "../../../../components/customButton/Button";
import LocalStorage from '../../../../utils/LocalStorage';
import { addErrorMsg, addLoader, addSuccessMsg, capitalize, dateFormat, removeLoader } from "../../../../utils/utils";
import { isValid, onCharactersAndNumbersWithSpaceChange, onCurrencyWithTwoDecimalOnlyChangeCurr, validate_charWithSpace, validate_emptyField, validate_goal_amount, validate_raise_amount } from "../../../../components/Validation";
import moment from 'moment';
import DeductionApi from '../../../../apis/admin/payroll/DeductionApi';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import PayrollApi from '../../../../apis/admin/payroll/ExpenseManagement';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import AddDeductionType from '../../addSelectForms/AddDeductionType';
import { ErrorMessages } from '../../../../utils/ErrorMessages';

function Deduction(props) {
    const { filterData, getAllPlacements, setOpenPopup, cardDetailsApi, editAction, openPopup, placeSelected } = props
        const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    
    const [employeesList, setEmployeesList] = useState([]);
    const [error, setError] = useState({});
    const [payType, setPayType] = useState([]);
    const [openDeductionType, setOpenDeductionType] = useState(false)
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        employee_id: '',
        reference_id: '',
        name: '',
        expense_type_id: '',
        date_of_expense: '',
        expense_effect_on: '',
        raised_amount: '',
        documents: [
            {
                document_name: '',
                document_url: '',
                new_document_id: ''
            }
        ],
        is_recurring: false,
        recurring_type: '',
        goal_amount: '',
        recurring_start_date: '',
        recurring_count: '',
        remarks: '',
        date_of_joining: '',
        recovered_amount:'',
        cycles_completed:''
    })

    const reoccurOptions = [
        {
            id: 1,
            value: 'Employment'
        },
        {
            id: 2,
            value: 'Goal Amount'
        }
    ]
    const deductOptions = [
        {
            id: 2,
            value: 'Balancesheet'
        },
        { 
            id: 1,
            value: 'Payroll'
        }
    ]

    useEffect(() => {
        if (editAction) {
            const {  employee_reference_id, name, date_of_expense, expense_effect_on, raised_amount,
                is_recurring, recurring_type, goal_amount, recurring_start_date, recurring_count, remarks,recovered_amount,cycles_completed,id
            } = placeSelected
            const expenseEffect = expense_effect_on == "Balance Sheet" ? 2 : expense_effect_on == "Payroll" ? 1 : "";
            const recurringType = recurring_type == "Employment" ? 1 : recurring_type == "Goal Amount" ? 2 : "";
            setState((prevState) => ({
                ...prevState,
                reference_id: employee_reference_id,
                name: name,
                date_of_expense: date_of_expense,
                expense_effect_on: expenseEffect,
                raised_amount: raised_amount,
                documents: [
                    {
                        ...placeSelected?.documents[0],
                        new_document_id: '',
                        document_key: "expense-management",
                        aws_s3_status: 1
                    }
                ],
                is_recurring: is_recurring,
                recurring_type: recurringType,
                goal_amount: goal_amount,
                recurring_start_date: recurring_start_date,
                recurring_count: recurring_count,
                remarks: remarks,
                recovered_amount:recovered_amount,
                cycles_completed:cycles_completed,
                id:id

            }))
        }// eslint-disable-next-line
    }, [openPopup])

    useEffect(() => {
        employeesListApi();
        payTypeApi();// eslint-disable-next-line
    }, [])

    const employeesListApi = () => {
        CommonApi.Employeesdropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setEmployeesList(res.data.data);
                
            }
            if(editAction){
                const employeeDetail = res.data.data.find(i => i.reference_id == placeSelected.employee_reference_id);
                setTimeout(() => {
                    setState((prevState)=>({...prevState,employee_id:employeeDetail.id,reference_id:employeeDetail.reference_id,date_of_joining:employeeDetail.date_of_joining}))
                    
                }, 500);
            }
        })
    }

    const payTypeApi = () => {
        DeductionApi.getDeductionDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setPayType(res.data.data);
            }
            if(editAction){
                const expenseType = res.data.data.find(i => i.value == placeSelected.expense_type);
                if(expenseType){
                    setState((prevState)=>({...prevState,expense_type_id:expenseType.id}))
                }
            }
        })
    }

    const cancelButton = () => {
        setOpenPopup(false);
        setState({
            request_id: LocalStorage.uid(),
            employee_id: '',
            reference_id: '',
            name: '',
            expense_type_id: '',
            date_of_expense: '',
            expense_effect_on: '',
            raised_amount: '',
            documents: [
                {
                    document_name: '',
                    document_url: '',
                    new_document_id: ''
                }
            ],
            is_recurring: false,
            recurring_type: '',
            goal_amount: 0,
            recurring_start_date: '',
            recurring_count: '',
            remarks: '',
            date_of_joining: '',
            recovered_amount:'',
            cycles_completed:''
        })
        setError({});
    }

    const changeHandler = (e) => {
        if (e.target.name == 'employee_id') {
            state[e.target.name] = e.target.value
            let id = employeesList.filter(i => i.id == e.target.value)
            state['reference_id'] = id[0].reference_id
            state['date_of_joining'] = id[0].date_of_joining
            setState({ ...state })
        } else if (e.target.name == 'raised_amount' || e.target.name == 'goal_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value;
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value;
                            if (e.target.name == 'raised_amount') {
                                if (state.goal_amount !== '' && value !== '') {
                                    state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                                } else {
                                    state.recurring_count = ''
                                }
                            } else {
                                if (value !== '' && state.raised_amount !== '') {
                                    state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                                } else {
                                    state.recurring_count = ''
                                }
                            }
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value;
                if (e.target.name == 'raised_amount') {
                    if (state.goal_amount !== '' && value !== '') {
                        state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                    } else {
                        state.recurring_count = ''
                    }
                    if (parseInt(value) <= parseInt(state.goal_amount)) {
                        error['goal_amount'] = ''
                        setError({ ...error })
                    }
                } else {
                    if (value !== '' && state.raised_amount !== '') {
                        state.recurring_count = (Math.ceil((parseInt(state.goal_amount) - parseInt(state.raised_amount)) / parseInt(state.raised_amount)) + 1)
                    } else {
                        state.recurring_count = ''
                    }
                    if (parseInt(value) >= parseInt(state.raised_amount)) {
                        error['raised_amount'] = ''
                        setError({ ...error })
                    }
                }
            }
            setState({ ...state }, handleValidate(e));
        } else if (e.target.name == 'is_recurring') {
            state[e.target.name] = e.target.checked
            setState({ ...state, recurring_type: '', goal_amount: '', recurring_count: '', recurring_start_date: '' })
            error['recurring_type'] = ''
            setError({ ...error })
        } else if (e.target.name == 'recurring_type') {
            state[e.target.name] = e.target.value
            setState({ ...state, goal_amount: '', recurring_start_date: '' })
            error['goal_amount'] = ''
            error['recurring_start_date'] = ''
            setError({ ...error })
        }
        else {
            state[e.target.name] = e.target.name === 'name' ? capitalize(e.target.value) : e.target.value
            setState({ ...state })
        }
        handleValidate(e)
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value)
                break;
            case "employee_id":
                error.employee_id = validate_emptyField(input.value)
                break;
            case "expense_type_id":
                error.expense_type_id = validate_emptyField(input.value)
                break;
            case "date_of_expense":
                error.date_of_expense = validate_emptyField(input.value)
                break;
            case "expense_effect_on":
                error.expense_effect_on = validate_emptyField(input.value)
                break;
            case "raised_amount":
                error.raised_amount = validate_raise_amount(input.value, state.goal_amount, state.recurring_type)
                break;
            case "recurring_type":
                error.recurring_type = validate_emptyField(input.value)
                break;
            case "goal_amount":
                error.goal_amount = validate_goal_amount(state.raised_amount, input.value);
                break;
            case "recurring_start_date":
                error.recurring_start_date = validate_emptyField(input.value)
                break;
            case "recurring_count":
                error.recurring_count = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setError({ ...error })
    }

    const dateHandler = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
        handleValidate(event);
    }

    const validateAll = () => {
        const { employee_id, name, expense_type_id, date_of_expense, expense_effect_on, raised_amount, recurring_type, goal_amount, recurring_start_date, recurring_count } = state
        let errors = {}
        const dateOfJoining = moment(moment(state.date_of_joining, dateFormat()).format(dateFormat()), dateFormat());
        const today = moment(moment().format(dateFormat()), dateFormat());
        const givenDate = moment(date_of_expense, dateFormat());
        errors.employee_id = validate_emptyField(employee_id);
        errors.name = validate_charWithSpace(name);
        errors.expense_type_id = validate_emptyField(expense_type_id);
        errors.date_of_expense = state.date_of_expense == '' ? 'This field is required' : givenDate.isBetween(dateOfJoining, today, null, '[]') ? validate_emptyField(date_of_expense) : 'Invalid date of Expense, Date must be between Date of Joining and Current date.';
        errors.expense_effect_on = validate_emptyField(expense_effect_on);
        errors.raised_amount = validate_raise_amount(raised_amount, goal_amount, recurring_type);
        errors.recurring_type = state.is_recurring ? validate_emptyField(recurring_type) : '';
        errors.goal_amount = state.recurring_type == 2 ? validate_goal_amount(raised_amount, goal_amount) : '';
        errors.recurring_start_date = state.recurring_type == 2 && state.is_recurring ?
         (moment(state.recurring_start_date).isBefore(moment().format(dateFormat()))) ?
          editAction ? '':'Recovery date should not be the past date' : validate_emptyField(recurring_start_date) :
           '';
        errors.recurring_count = state.recurring_type == 2 ? validate_emptyField(recurring_count) : '';
        setError({ ...errors })
        return errors;
    }

    const [btnLoader, setBtnLoader] = useState(false);
  
    // const submitHandler = () => {
    //     const errors = validateAll();
    
    //     if (isValid(errors)) {
    //         state['entity_type'] = 2;
    //         setBtnLoader(true);
    
    //         PayrollApi.storePayroll(state).then((res) => {
    //             setBtnLoader(false);
    //             if (res.data.statusCode === 1003) {
    //                 addSuccessMsg(res.data.message);
    //                 setOpenPopup(false);
    //                 getAllPlacements(filterData);
    //                 cardDetailsApi();
    //             } else {
    //                 addErrorMsg(res.data.message);
    //             }
    //         });
    //     } else {
    //         const checkForName = (obj) => Object.values(obj).includes("This field is required" || "Deduction amount is required");
    //         if (checkForName(errors)) {
    //             addErrorMsg(ErrorMessages.genericMessage);
    //           } else {
    //             addErrorMsg(ErrorMessages.fieldValidate);
    //           }
    
    //         let s1 = { error };
    //         s1 = errors;
    //         setError(s1);
    //     }
    // };
    
    const submitHandler = () => {
        const errors = validateAll();
    
        if (isValid(errors)) {
            state['entity_type'] = 2;
            setBtnLoader(true);
            PayrollApi.storePayroll(state).then((res) => {
                setBtnLoader(false);
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(res.data.message);
                    setOpenPopup(false);
                    getAllPlacements(filterData);
                    cardDetailsApi();
                } else {
                    addErrorMsg(res.data.message);
                    setOpenPopup(false);

                }
            });
        } else {
            const checkForName = (obj) => {
                // Check if the error message contains any of the required strings.
                return Object.values(obj).some((value) => 
                    value === "This field is required" || value === "Deduction amount is required"
                );
            };
    
            if (checkForName(errors)) {
                addErrorMsg(ErrorMessages.genericMessage);
            } else {
                addErrorMsg(ErrorMessages.fieldValidate);
            }
    
            setError(errors);  // Directly assign errors to setError, no need for intermediate variable
        }
    };
    

    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < Number(25)) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("expense-management", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            state.documents[0].new_document_id = res.data.data.id
                            state.documents[0].document_name = e.target.files[0].name
                            state.documents[0].document_url = res.data.data.document_url
                            setState({ ...state })
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG or PDF format only");
        }
    }

    const deleteDocs = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].document_name = ''
        state.documents[0].document_path = ''
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    return (
        <Grid container p={'15px 25px'} spacing={2}>
            <Grid item xs={12}>
                <Text mediumViewAmt>{editAction ? "Edit" : "Add"} Deduction</Text>
            </Grid>
            <Grid item xs={12} pt={2}>
                <Box p={1}>
                    <Text smallBlack>Employee Details</Text>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Select
                    name='employee_id'
                    value={state.employee_id}
                    label={'Employee Name'}
                    options={employeesList}
                    onChange={(e) => changeHandler(e)}
                    disabled={editAction ? true : false}
                    error={error.employee_id ? <Text red>{error.employee_id ? error.employee_id : ''}</Text> : ''}
                    
                />
                {error.employee_id ? <Text red>{error.employee_id ? error.employee_id : ''}</Text> : ''}
            </Grid>
            <Grid item xs={6}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'reference_id',
                        value: state.reference_id
                    }}
                    disabled
                    clientInput
                    labelText={'Employee ID'}
                />
            </Grid>
            <Grid item xs={12}>
                <Box p={1}>
                    <Text smallBlack>Deduction Details</Text>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'name',
                        value: state.name,
                        inputProps: { maxLength: 50 }
                    }}
                    handleChange={(e) => changeHandler(e)}
                    onKeyPress={onCharactersAndNumbersWithSpaceChange}
                    clientInput
                    labelText={'Name'}
                    error={error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}
                />
                {error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}
            </Grid>
            <Grid item xs={6}>
                <Select
                    name='expense_type_id'
                    value={state.expense_type_id}
                    label='Type'
                    onChange={changeHandler}
                    options={payType}
                    buttonName='New Type'
                    buttonHandler={() => setOpenDeductionType(true)}
                    showButton
                    error={error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
                />
                {error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
            </Grid>
            <Grid item xs={6}>
                <Date
                    labelText={'Date of Expense'}
                    name='date_of_expense'
                    height='54px'
                    value={state.date_of_expense}
                    onChange={(e) => dateHandler(e, 'date_of_expense')}
                    minDate={moment(state.date_of_joining, dateFormat()).format(dateFormat())}
                    maxDate={moment().format(dateFormat())}
                    error={error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}
                />
                {error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}
            </Grid>
            <Grid item xs={6}>
                <Select
                    name='expense_effect_on'
                    label={'Deduct from'}
                    value={state.expense_effect_on}
                    options={deductOptions}
                    onChange={changeHandler}
                    error={error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}
                />
                {error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}
            </Grid>
            <Grid item xs={6}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'raised_amount',
                        value: currency + "" +state.raised_amount
                    }}
                    onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                    handleChange={changeHandler}
                    clientInput
                    labelText={'Deduction Amount'}
                    error={error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}
                />
                {error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <FileInput
                    name='new_document_id'
                    FileName={state.documents[0].document_name}
                    handleChange={(e) => uploadDocs(e)}
                    label='Supporting Document (Optional)'
                    handleDelete={deleteDocs}
                    actionState={state.documents && state.documents.length > 0 && state.documents[0].document_name ? 1 : ''}
                    documentUrl={state.documents && state.documents.length > 0 && state.documents[0].document_url}
                    state={state.documents[0]}
                />
                <Text greyLabel sx={{ padding: '5px 0px' }}>File size should not exceed 25mb</Text>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                    <Checkbox name='is_recurring' value={state.is_recurring} checked={state.is_recurring}
                        onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                        checkedIcon={<CheckedIcon />} />
                    <Text mediumBlack>Recurring Deduction</Text>
                </Box>
            </Grid>
            {
                state.is_recurring &&
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Select
                        name='recurring_type'
                        value={state.recurring_type}
                        onChange={(e) => { changeHandler(e) }}
                        options={reoccurOptions}
                        label={'Re-occur Till'}
                        helperText={
                            error.recurring_type ?
                                <Text red>{error.recurring_type ? error.recurring_type : ''}</Text> : ''
                        }
                    />
                </Grid>
            }
            {
                state.recurring_type == 2 && state.is_recurring &&
                <>
                    <Grid item xs={6}>
                        <Date
                            labelText={'Recovery Start Date'}
                            name='recurring_start_date'
                            height='54px'
                            value={state.recurring_start_date}
                            onChange={(e) => dateHandler(e, 'recurring_start_date')}
                            minDate={editAction ? !state.recurring_start_date ? moment().format(dateFormat()) :  moment(state.recurring_start_date,dateFormat()).format(dateFormat()) :  moment().format(dateFormat())}
                            error={error.recurring_start_date ? <Text red>{error.recurring_start_date ? error.recurring_start_date : ''}</Text> : ''}
                        />
                        {error.recurring_start_date ? <Text red>{error.recurring_start_date ? error.recurring_start_date : ''}</Text> : ''}
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'goal_amount',
                                value: currency+""+state.goal_amount,
                            }}
                            onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                            handleChange={(e) => changeHandler(e)}
                            clientInput
                            labelText={'Goal Amount'}
                            error={error.goal_amount}
                        />
                        {
                            error.goal_amount ?
                                <Text red>{error.goal_amount ? error.goal_amount : ''}</Text> : ''
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'recovered_amount',
                                value:currency+""+ state.recovered_amount,
                            }}
                            //handleChange={(e) => changeHandler(e)}
                            disabled
                            clientInput
                            labelText={'Recovered Amount'}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'recurring_count',
                                value: state.recurring_count,
                            }}
                            //handleChange={(e) => changeHandler(e)}
                            disabled
                            clientInput
                            labelText={'No of pay cycles'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'cycles_completed',
                                value: state.cycles_completed,
                            }}
                            //handleChange={(e) => changeHandler(e)}
                            disabled
                            clientInput
                            labelText={'No of Cycles Completed'}
                        />
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: "remarks",
                        value: state.remarks,
                        inputProps: { maxLength: 255 }
                    }}
                    handleChange={changeHandler}
                    clientInput
                    labelText={'Remarks (Optional)'}
                    error={error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}
                />
                {error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}
            </Grid>
            <Grid item container xs={12} justifyContent={'flex-end'}>
                <Stack direction={'row'}>
                    <Box p={1}>
                        <Button disabled={btnLoader} finishLater onClick={cancelButton}>Cancel</Button>
                    </Box>
                    <Box p={1}>
                        <Button saveVerySmall disabled={btnLoader} onClick={submitHandler}>Save</Button>
                    </Box>
                </Stack>
            </Grid>
            <ReusablePopup openPopup={openDeductionType} white iconHide fixedWidth setOpenpopup={setOpenDeductionType}>
                <AddDeductionType open={openDeductionType} closePopup={setOpenDeductionType} payTypeApi={payTypeApi} />
            </ReusablePopup>
        </Grid>
    )
}

export default Deduction