import React, { useState, useRef, useEffect } from 'react';
import UserProfileStyles from '../UserProfileStyles';
import { Box, Grid, Skeleton, Stack, Popover, Divider } from '@mui/material';
import ClientsAPI from '../../../../../apis/admin/clients/ClientsApi';
import CommonApi from '../../../../../apis/CommonApi';
import Text from '../../../../../components/customText/Text';
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, capitalizeAndAddSpace, removeLoader } from '../../../../../utils/utils';
import { isValid, validate_emptyField, validate_zipcode, validate_empty_address, empty_city, isValidMulti, client_name, nonZeroNumericals } from "../../../../../components/Validation";
import LocalStorage from "../../../../../utils/LocalStorage";
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../../assets/svg/File.svg';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import Button from '../../../../../components/customButton/Button';
import Input from '../../../../../components/input/Input';
import LoadingScreen from '../../../employees/userprofile/document/LoadingScreen';
import LoaderButton from '../../../../../components/customButton/LoadingButton';
import Sure from '../../../../../assets/svg/placementPop.svg'
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';
import { useZipcodeAPICallMulti } from '../../../ZipcodeCommon';
import { Buffer } from 'buffer';
import Search from '../../../../../components/select/Select';
import ClientStyles from '../../ClientStyles';


export default function CompanyDetails(props) {
    const { action, setAction, formData, setFormData, getCompanyDetails, loading, getActivity } = props;
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = UserProfileStyles();
    const clientClasses = ClientStyles();

    const [error, setError] = useState({});
    const [erro, setErro] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [buttonLoaderDaysSave, setButtonLoaderDaysSave] = useState(false);

    const [deletePopup, setdeletePopup] = useState(false);
    const boxRef = useRef(null);
    const [days, setDays] = useState('');
    const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    useEffect(() => {
        paymentTermsDropdown();
        // eslint-disable-next-line
    }, [])

    const paymentTermsDropdown = () => {
        CommonApi.getPaymentTermsDropdown().then((res) => {
            if (res.data.statusCode == 1003) {
                setPaymentTermsOptions(res.data.data);
                if (days !== '') {
                    const newValue = res.data.data.find(option => option.value === days);
                    setFormData({
                        ...formData,
                        net_pay_terms_id: newValue?.id,

                    })
                    setDays('');
                    setError({ ...error, net_pay_terms_id: validate_emptyField(newValue?.id) });
                    handleClose();
                }
            }
        })
    }
    const handleDaysPaymentTerms = () => {
        const data = {
            request_id: LocalStorage.uid(),
            days: days,
            is_active: true
        }
        setButtonLoaderDaysSave(true);
        CommonApi.postPaymentTermsDays(data).then((res) => {
            if (res.data.statusCode == 1003) {
                paymentTermsDropdown();
                setButtonLoaderDaysSave(false);
            }
            else {
                addErrorMsg(res.data.message);
                setButtonLoaderDaysSave(false);
            }
        })
    }



    const handleClick = (event) => {
        setAnchorEl(boxRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPaymentTerms = Boolean(anchorEl);
    const paymentTermsId = openPaymentTerms ? 'payment-terms-popover' : undefined;


    const updateCompanyDetails = () => {
        let data = { ...formData, request_id: LocalStorage.uid(), encoded_name: Buffer.from(formData.name).toString('base64') };
        setButtonLoader(true)
        ClientsAPI.updateCompany(data, "end-client").then((response) => {
            setTimeout(() => {
                setButtonLoader(false)
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                    props.setformEdit(false)
                    handleCancel();
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const handleChange = (e) => {
        if (e.target.name == 'name') {
            setFormData({ ...formData, [e.target.name]: capitalize(e.target.value) });
            handleValidations(e);
        } else if (e.target.name == 'days') {
            if (nonZeroNumericals(e.target.value)) {
                setDays(e.target.value.replace(/\D/g, ""))
            }
        }
        else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            handleValidations(e);
        }

    };

    const { zipcodeAPICallMulti } = useZipcodeAPICallMulti(formData, setFormData, erro, setErro);

    const handleChangeAddress = (e) => {
        formData.address[0][e.target.name] = e.target.value;
        setFormData({ ...formData });
        handleValidationsAddress(e, 0)
        if (e.target.name == 'zip_code') {
            zipcodeAPICallMulti(e.target.value, 0, 'address')
        }
    }

    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "name":
                err.name = client_name(input.value, "end client ");
                break;

            default:
                break;
        }
        setError(err);
    }

    const handleValidationsAddress = (e, index) => {
        let input = e.target;
        let err = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "address_line_one":
                err.address_line_one = validate_empty_address(input.value);
                break;
            case "address_line_two":
                err.address_line_two = validate_empty_address(input.value);
                break;
            case "city":
                err.city = empty_city(input.value);
                break;
            case "state_id":
                err.state_id = validate_emptyField(input.value);
                break;
            case "country_id":
                err.country_id = validate_emptyField(input.value);
                break;
            case "zip_code":
                err.zip_code = validate_zipcode(input.value, input.zip_code);
                break;
            default:
                break;
        }
        setErro(s2);
    }

    const validateAll = () => {
        let { name } = formData;
        let errors = {};
        errors.name = client_name(name, 'end client ');
        return errors;
    };

    const companyCommAddressValidations = () => {
        let errors = {};
        let err = []; // eslint-disable-next-line
        formData.address.map((value) => {
            errors = {};
            errors.address_line_one = validate_empty_address(value.address_line_one);
            errors.address_line_two = validate_empty_address(value.address_line_two);
            errors.city = empty_city(value.city);
            errors.zip_code = erro ? erro.length > 0 ? erro[0].zip_code : '' : ''
            return err.push(errors);
        });
        return err;
    };

    const handleSubmit = () => {
        let errors = validateAll();
        let commErrors = companyCommAddressValidations();
        if (isValid(errors) && isValidMulti(commErrors)) {
            updateCompanyDetails();
        } else {
            addWarningMsg(ErrorMessages.genericMessage);
            let s1 = { error };
            s1 = errors;
            let s2 = { error };
            s2 = commErrors;
            setError(s1);
            setErro(s2);
        }
    }


    const uploadDocs = (e) => {
        addLoader(true);
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                const formData1 = new FormData();
                formData1.append("files", e.target.files[0]);
                formData1.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("company-logo", formData1, LocalStorage.getAccessToken())
                    .then((response) => {
                        removeLoader();
                        if (response.data.statusCode == 1003) {
                            formData['logo_path_id'] = response.data.data.id
                            formData['logo_name'] = response.data.data.document_name
                            formData.logo_url = response.data.data.document_url
                            setFormData({ ...formData })
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    });
            }
            else {
                removeLoader()
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            removeLoader()
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }


    const deleteDocs = () => {
        formData.logo_path_id = ''
        formData.logo_name = ''
        formData.logo_url = ''
        setFormData({ ...formData });
        setdeletePopup(false)
    }

    const handleCancel = () => {
        props.setformEdit(false)
        setError({})
        setAction("view");
        getCompanyDetails();
        getActivity();
        setError({});
        setErro([{
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ]
        }])
    }

    const editAction = () => {
        props.setformEdit(true)
        setAction('update');
        setError({});
        setErro([{
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ]
        }])
    }

    const openInNewTab = (documentUrl) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true)))) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <Grid container p={{ lg: '30px', md: "30px", sm: "25px", xs: "10px" }}  className={classes.boxShadow}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Text largeBlue> End Client Details </Text>
                    {
                        (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_edit" && item.is_allowed !== true))) ? "" :
                            formData.status === 'In Active' ? '' : action == 'view' &&
                                loading ? <Skeleton animation='linear' className={classes.editSkeleton} /> :
                                action == 'view' ? <img src={EditIcon} alt='Edit' className={classes.cursor} onClick={editAction} /> : ''
                    }
                </Box>
            </Grid>
            {
                action == 'view' ?
                    <>
                        {
                            loading ?
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={5}>
                                    <LoadingScreen />
                                </Grid>
                                :
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <Grid item container lg={12}>
                                        <Grid item lg={3} md={4} sm={4} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> End Client Name</Text>
                                                <Text pt={1} mediumBlack14 >
                                                    {
                                                        formData.name ? formData.name.length > 16 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(formData.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(formData.name).slice(0, 16) + capitalizeAndAddSpace(formData.name.length > 16 ? "..." : "")}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(formData.name) : '--'
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> End Client ID</Text>
                                                <Text pt={1} mediumBlack14 >{formData.reference_id ? formData.reference_id : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={12} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> End Client Logo (Optional)</Text>
                                                {
                                                    formData.logo_name === '' ? '--' :
                                                        <Box display='flex' flexDirection='row' gap={1} pt={1} className={classes.cursor}>
                                                            <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} onClick={() => { openInNewTab(formData.logo_url) }} />
                                                            <Text blue14 onClick={() => { openInNewTab(formData.logo_url) }}>
                                                                <BlackToolTip arrow placement='top' title={formData.logo_name}>
                                                                    {formData.logo_name.slice(0, 16)} {formData.logo_name.length > 16 ? '...' : ''}
                                                                </BlackToolTip>
                                                            </Text>
                                                        </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={6} sm={6} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader> Address Line - 1</Text>
                                            <Text pt={1} mediumBlack14>
                                                {
                                                    formData.address[0].address_line_one ? formData.address[0].address_line_one.length > 130 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(formData.address[0].address_line_one)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(formData.address[0].address_line_one).slice(0, 130) + capitalizeAndAddSpace(formData.address[0].address_line_one.length > 130 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(formData.address[0].address_line_one) : '--'
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={6} sm={6} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader> Address Line - 2 (Optional)</Text>
                                            <Text pt={1} mediumBlack14>
                                                {
                                                    formData.address[0].address_line_two ? formData.address[0].address_line_two.length > 130 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(formData.address[0].address_line_two)} placement="top" arrow>
                                                            {capitalizeAndAddSpace(formData.address[0].address_line_two).slice(0, 130) + capitalizeAndAddSpace(formData.address[0].address_line_two.length > 130 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : capitalizeAndAddSpace(formData.address[0].address_line_two) : '--'
                                                }
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={12} >
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> Country</Text>
                                                <Text pt={1} mediumBlack14 >{formData.address[0].country_name ? formData.address[0].country_name : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader> State</Text>
                                                <Text pt={1} mediumBlack14 >{formData.address[0].state_name ? formData.address[0].state_name : '--'}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader>City</Text>
                                                <Text pt={1} mediumBlack14 >
                                                    {
                                                        formData.address[0].city ? formData.address[0].city.length > 25 ?
                                                            <BlackToolTip title={formData.address[0].city} placement="top" arrow>
                                                                {formData.address[0].city.slice(0, 25) + (formData.address[0].city.length > 25 ? "..." : "")}
                                                            </BlackToolTip>
                                                            : formData.address[0].city : '--'
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2}>
                                                <Text mediumGreyHeader>Zip Code</Text>
                                                <Text pt={1} mediumBlack14 >{formData.address[0].zip_code ? formData.address[0].zip_code : '--'}</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={6} sm={6} xs={12}>
                                        <Box p={1} pt={2}>
                                            <Text mediumGreyHeader>Payment Terms</Text>
                                            <Text pt={1} mediumBlack14 >
                                                {formData.net_pay_term_days ? `${formData.net_pay_term_days} days` : '--'}

                                            </Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                        }
                    </>
                    :
                    <>
                        {
                            loading ?
                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <LoadingScreen />
                                </Grid> :
                                <Box my={1} className={classes.contentScroll}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'name',
                                                    value: formData.name,
                                                    inputProps: { maxLength: 50 },
                                                }}
                                                handleChange={handleChange}
                                                clientInput
                                                labelText='End Client Name'
                                                error={error.name ? error.name : ""}
                                            />
                                            <Text errorText> {error.name ? error.name : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'reference_id',
                                                    value: formData.reference_id,
                                                }}
                                                disabled={true}
                                                handleChange={handleChange}
                                                clientInput
                                                labelText='End Client ID'
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FileInput
                                                name='logo_path_id'
                                                FileName={formData.logo_name}
                                                handleChange={(e) => uploadDocs(e)}
                                                label='End Client Logo (Optional)'
                                                handleDelete={() => { setdeletePopup(true) }}
                                                actionState={formData.logo_name ? 1 : ''}
                                                documentUrl={formData.logo_url}
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'address_line_one',
                                                    value: formData.address[0].address_line_one,
                                                    inputProps: { maxLength: 225 },
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='Address Line 1 (Optional)'
                                                error={erro[0] && erro[0].address_line_one}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].address_line_one : ""}</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'address_line_two',
                                                    value: formData.address[0].address_line_two,
                                                    inputProps: { maxLength: 225 },
                                                }}
                                                error={erro[0] && erro[0].address_line_two}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='Address Line 2 (Optional)'
                                            />
                                            <Text errorText> {erro[0] ? erro[0].address_line_two : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'zip_code',
                                                    value: formData.address[0].zip_code,
                                                    inputProps: { maxLength: 5 }
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                error={erro[0] ? erro[0].zip_code : ""}
                                                labelText={"Zip Code (Optional)"}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].zip_code == ErrorMessages.fieldRequired ? erro[0].zip_code : erro[0].zip_code ? ErrorMessages.invalidZipcode : "" : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'city',
                                                    value: formData.address[0].city,
                                                    inputProps: { maxLength: 50 },
                                                }}
                                                handleChange={(e) => handleChangeAddress(e, "address")}
                                                clientInput
                                                labelText='City (Optional)'
                                                error={erro[0] && erro[0].city}
                                            />
                                            <Text errorText> {erro[0] ? erro[0].city : ""}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'state_name',
                                                    value: formData.address[0].state_name || '',
                                                }}
                                                disabled={true}
                                                clientInput
                                                labelText='State (Optional)'
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'country_name',
                                                    value: formData.address[0].country_name || '',
                                                }}
                                                disabled={true}
                                                clientInput
                                                labelText='Country (Optional)'
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Divider className={clientClasses.dividerContact} />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Text headerBlack>Invoice Configuration</Text>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12} mb={2}>
                                            <Box ref={boxRef} >
                                                <Search
                                                    name='net_pay_terms_id'
                                                    value={formData.net_pay_terms_id}
                                                    options={paymentTermsOptions}
                                                    label='Payment Terms (Optional)'
                                                    onChange={handleChange}
                                                    buttonName='New Pay Term'
                                                    error={error.net_pay_terms_id}
                                                    buttonHandler={
                                                        handleClick
                                                    }
                                                    showButton
                                                />
                                            </Box>
                                            <Popover
                                                id={paymentTermsId}
                                                open={openPaymentTerms}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}

                                            >
                                                <Box sx={{ width: '275px !important', height: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' }}>
                                                    <Grid container flexDirection='column' spacing={2} >
                                                        <Grid item > <Text headerBlack>Add New Pay Term</Text></Grid>
                                                        <Grid item ><Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'days',
                                                                value: days || '',
                                                                inputProps: { maxLength: 3 }

                                                            }}
                                                            handleChange={handleChange}
                                                            disabled={false}
                                                            clientInput
                                                            labelText='Days'
                                                        /></Grid>
                                                        <Grid item container justifyContent='end' gap={2}>
                                                            <Grid item >
                                                                <Button disabled={buttonLoaderDaysSave} smallBlueOutline sx={{ height: '40px !important' }} onClick={handleClose}>Cancel</Button>

                                                            </Grid>
                                                            <Grid item >
                                                                <LoaderButton loading={buttonLoaderDaysSave} saveVerySmall sx={{ height: '40px !important' }} onClick={handleDaysPaymentTerms}>Save</LoaderButton>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Popover>

                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} pt={1} justifyContent='space-between'>
                                            <Button disabled={buttonLoader} blackCancelBtn onClick={handleCancel}>Cancel</Button>
                                            <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>Save</LoaderButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                        }
                    </>
            }
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setdeletePopup(false) }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { deleteDocs() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}