import { Backdrop, Box, Grid, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Text from '../../components/customText/Text';
import logoName from '../../assets/svg/logoName.svg';
import OnboardStyles from './OnboardStyles';
import Dropzone from 'react-dropzone';
import download from '../../assets/employee/filedownload.svg';
import Button from '../../components/customButton/Button';
import { addErrorMsg, addLoader, BlackToolTip, removeLoader, scrollToTop } from '../../utils/utils';
import Loader from '../../assets/gif/japfuLoader.gif';
import onBoardLinkApiInstance from '../../apis/onBoardLink/onBoardLinkApi';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';
import docReject from '../../assets/svg/docReject.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { employeeInviteViaLinkReUploadDocumentDefaultConfig } from '../../config/pageConfig/EmployerModuleConfig';
import CommonApi from '../../apis/CommonApi';

function OnboardEmployeeReUploadDocs({ state, setState, id, active, setActive, setPreview, getInviteViaLink, data }) {


    let dataKeys = Object.keys(state.re_upload_documents)
    const [uploadDocumentsConfig, setUploadDocumentsConfig] = useState(employeeInviteViaLinkReUploadDocumentDefaultConfig?.uploadDocuments)

    let options = {
        counter_signed_offer_letter: {
            name: 'Countersigned Offer Letter',
            content: `A countersigned offer is a document that has been signed by both the employer and the prospective employee, indicating mutual agreement and acceptance of the terms outlined in the job offer. This counter offer can be downloaded from the mail.`
        },
        passport: {
            name: 'Passport',
            content: `Please include all pages with stamps or visas, along with the biodata page, ensuring correct sizing and order, excluding blank pages.`
        },
        i94: {
            name: 'I94',
            content: `Please upload your record showing entry date, immigration status, and authorized duration of stay.`
        },
        signed_ssn: {
            name: 'Signed SSN',
            content: `Your "signed SSN" on official documents like the Social Security card or tax forms is validated for various purposes like identity verification, employment eligibility, tax reporting, and accessing government benefits or services.`
        },
        work_authorization: {
            name: 'Work Authorization',
            content: `Please upload your current visa document and previous visa documents.`
        },
        all_previous_i20s: {
            name: `All Previous I-20’s`,
            content: `The "previous signed I-20" is a prior Form I-20, a crucial document issued by U.S. educational institutions to international students, confirming acceptance and visa status terms.`
        },
        education_documents: {
            name: 'Education Documents',
            content: `Upload your undergraduate and highest degree documents.`
        },
        drivers_license: {
            name: 'Driving License/State ID',
            content: `Please upload a clear copy of your Driver's License. This document serves as an official identification and verifies your driving eligibility.`
        },
        copy_of_void_cheque: {
            name: 'Copy Of Void Cheque',
            content: `A void cheque bears the word 'VOID' prominently. It's a blank cheque used to share account details securely. Please enter max 5 void cheques.`
        },
        i9_document: {
            name: 'I9',
            content: ``
        },
        w4_document: {
            name: 'W4',
            content: ``
        }
    }

    const classes = OnboardStyles();
    const [expand, setExpand] = useState(true);
    const [disableExpand, setDisableExpand] = useState(false);
    const [status, setStatus] = useState('');
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [name, setName] = useState(dataKeys[0]);

     useEffect(() => {
            const data = {
                page_name: "employee-reupload-document",
                page_type: "update",
                client_id: LocalStorage.getPathId()
            }
            async function getPageData() {
                const response = await CommonApi.fetchPageConfig(data)
                if (response.status === 200) {
                    setUploadDocumentsConfig(response?.data?.data?.page_data?.uploadDocuments);
                }
            }
            getPageData()
        }, [])

    const getFileSizeLimitConfig = (fieldName) => {
        let fileConfig = {}
        switch (fieldName) {
            case 'counter_signed_offer_letter':
                fileConfig = uploadDocumentsConfig?.counter_signed_offer_letter
                break
            case 'passport':
                fileConfig = uploadDocumentsConfig?.passport
                break
            case 'i94':
                fileConfig = uploadDocumentsConfig?.i94
                break
            case 'signed_ssn':
                fileConfig = uploadDocumentsConfig?.signed_ssn
                break
            case 'work_authorization':
                fileConfig = uploadDocumentsConfig?.work_authorization
                break
            case 'all_previous_i20s':
                fileConfig = uploadDocumentsConfig?.all_previous_i20s
                break
            case 'education_documents':
                fileConfig = uploadDocumentsConfig?.education_documents
                break
            case 'copy_of_void_cheque':
                fileConfig = uploadDocumentsConfig?.copy_of_void_cheque
                break
            case 'drivers_license':
                fileConfig = uploadDocumentsConfig?.drivers_license
                break
            case 'i9_document':
                fileConfig = uploadDocumentsConfig?.i9_document
                break
            case 'w4_document':
                fileConfig = uploadDocumentsConfig?.w4_document
                break     
            default:
                break
        }
        return fileConfig
    }


    const handleExpand = (args, name) => {
        if (args == active) {
            setExpand(!expand)
        } else {
            if (name === 'active') {
                setDisableExpand(!disableExpand);
                setStatus(args);
            } else {
                setDisableExpand(disableExpand);
                setStatus('');
            }
        }
    }
    const handleDrop = (e) => {
        const { format, formatMsg, size, sizeMsg, totalFiles, totalFilesExcedMsg } = getFileSizeLimitConfig(name)
        if (e.length <= uploadDocumentsConfig?.overAllFilesLimit) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => format?.includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < size) {
                        const formData = new FormData();
                        formData.append("files", e[i]);
                        onBoardLinkApiInstance.documentUploadInvite(
                            name == 'copy_of_void_cheque' ? 'bank-document' : name == 'counter_signed_offer_letter' ? 'personal-document' : name == 'work_authorization' ? 'visa-document' :
                                name == 'all_previous_i20s' ? 'visa-document' : name == 'signed_ssn' ? 'education-document' : name == 'education_documents' ? 'education-document' :
                                    name == 'passport' ? 'passport-document' : name == 'i94' ? 'i94-document' : name == 'drivers_license' ? 'personal-document' : 'bank-document', formData).then((res) => {
                                        if (res.data.statusCode == 1003) {
                                            if (name == 'counter_signed_offer_letter' && state.re_upload_documents.counter_signed_offer_letter.data.length < 5) {
                                                state.re_upload_documents.counter_signed_offer_letter.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.counter_signed_offer_letter.data);
                                            } else if (name == 'work_authorization' && state.re_upload_documents.work_authorization.data.length < totalFiles) {
                                                state.re_upload_documents.work_authorization.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.work_authorization.data);
                                            } else if (name == 'all_previous_i20s' && state.re_upload_documents.all_previous_i20s.data.length < totalFiles) {
                                                state.re_upload_documents.all_previous_i20s.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.all_previous_i20s.data);
                                            } else if (name == 'signed_ssn' && state.re_upload_documents.signed_ssn.data.length < totalFiles) {
                                                state.re_upload_documents.signed_ssn.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.signed_ssn.data);
                                            } else if (name == 'education_documents' && state.re_upload_documents.education_documents.data.length < totalFiles) {
                                                state.re_upload_documents.education_documents.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.education_documents.data)
                                            } else if (name == 'passport' && state.re_upload_documents.passport.data.length < totalFiles) {
                                                state.re_upload_documents.passport.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.passport.data)
                                            } else if (name == 'i94' && state.re_upload_documents.i94.data.length < totalFiles) {
                                                state.re_upload_documents.i94.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.i94.data)
                                            } else if (name == 'drivers_license' && state.re_upload_documents.drivers_license.data.length < totalFiles) {
                                                state.re_upload_documents.drivers_license.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.drivers_license.data);
                                            }
                                            else if (name == 'copy_of_void_cheque' && state.re_upload_documents.copy_of_void_cheque.data.length < totalFiles) {
                                                state.re_upload_documents.copy_of_void_cheque.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    reason_for_rejection: ""
                                                });
                                                setPreview(state.re_upload_documents.copy_of_void_cheque.data);
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                            } else if (name == 'i9_document' && state.re_upload_documents.i9_document.data.length < totalFiles) {
                                                state.re_upload_documents.i9_document.data.push({
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    i9_reason_for_rejection: "",
                                                    id: res.data.data.id
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.i9_document.data);

                                            } else if (name == 'w4_document' && state.re_upload_documents.w4_document.data.length < totalFiles) {

                                                state.re_upload_documents.w4_document.data.push({
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted",
                                                    w4_reason_for_rejection: "",
                                                    id: res.data.data.id
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.w4_document.data);

                                            }
                                            else {
                                                setOpenBackDrop(false);
                                                addErrorMsg(totalFilesExcedMsg)

                                            }
                                            setState({ ...state })
                                        } else {
                                            setOpenBackDrop(false);
                                            addErrorMsg(res.data.message);
                                        }
                                    })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg(sizeMsg);
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg(formatMsg)
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg(uploadDocumentsConfig?.overAllFilesLimitMsg)
        }
    }

    const deleteChip = (args) => {
        state.re_upload_documents[name].data.splice(args, 1)
        setState({ ...state });
    }


    const updateApi = () => {
        state['subdomain_name'] = domain
        state['request_id'] = LocalStorage.uid();
        state['basic_details'] = false
        if (active == dataKeys.length) {
            state['submit'] = true;
            Object.keys(state.re_upload_documents).forEach(key => {
                if (key === 'i9_document') {
                    if (state.i9andw4.hasOwnProperty(key)) {
                        state.i9andw4['i9_document'] = state.re_upload_documents['i9_document'].data.length > 0 ? state.re_upload_documents['i9_document'].data[0] : state.i9andw4['i9_document'];
                    }
                } else if (key === 'w4_document') {
                    if (state.i9andw4.hasOwnProperty(key)) {
                        state.i9andw4['w4_document'] = state.re_upload_documents['w4_document'].data.length > 0 ? state.re_upload_documents['w4_document'].data[0] : state.i9andw4['w4_document'];
                    }
                } else {
                    if (state.re_upload_documents[key].data.length > 0) {
                        if (state.upload_documents.hasOwnProperty(key)) {
                            state.upload_documents[key].data = [...state.re_upload_documents[key].data];
                        }
                    }
                }
            });
            setState({...state});          
            // console.log("state",state);
            
            // return   
        }
        addLoader(true);
        onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                getInviteViaLink(id);
                if (active <= (dataKeys.length - 1)) {
                    setActive(active + 1);
                    setName(dataKeys[active]);
                } else if (active == dataKeys.length) {
                    scrollToTop();
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleSubmit = () => {
        updateApi();
    }

    const handleSkip = () => {
        if (active <= (dataKeys.length - 1)) {
            setActive(active + 1);
            setName(dataKeys[active]);
        } else if (active == dataKeys.length) {
            scrollToTop();
        }
    }
    const handleBack = () => {
        if (active <= dataKeys.length) {
            setActive(active - 1);
            setName(dataKeys[active - 2]);
        }
    }

    const theme = useTheme();

    return (
        <Grid container p={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
            <Grid item lg={2} md={2} sm={2} xs={2}>
                <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                <Grid item xl={8} lg={9} md={11} sm={11} xs={12} pt={3}>
                    <Text BrowmnMnStepperText>Uploaded Documents</Text>
                </Grid>
                <Grid item container xl={8} lg={9} md={11} sm={11} xs={12} justifyContent='center' textAlign='center' pt={7} spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ height: '550px', overflowY: 'scroll',[theme.breakpoints.down('sm')]:{height:"300px"} }}>
                        {
                            dataKeys.map((item, index) => (
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: "fit-content", textAlign: "left" }} className={active == index + 1 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(index + 1, 'active', item)}>
                                    {
                                        active == index + 1 ?
                                            <>
                                                <Text largeWhite>{index + 1}. {options[item].name}</Text>
                                                {
                                                    (active == index + 1) && expand ?
                                                        <Text mediumBoldWhite400 sx={{ paddingTop: '10px', paddingLeft: "10px" }}>{options[item].content}</Text> : ''
                                                }
                                            </> :
                                            <>
                                                <Text overViewLable>{index + 1}. {options[item].name}</Text>
                                                {
                                                    disableExpand && (status == (index + 1)) ?
                                                        <Text smallGreyFont sx={{ paddingTop: '10px', paddingLeft: "10px" }}>{options[item].content}</Text> : ''
                                                }
                                            </>
                                    }
                                </Grid>
                            ))
                        }
                    </Grid> 

                    <Grid item container spacing={2} lg={8} md={8} sm={8} xs={12}>
                        {(state?.re_upload_documents[name]?.data?.length > 0 && data?.upload_documents[name]?.data?.length > 0) ? '' :
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ width: "100%", height: "fit-content", border: "1px solid #EAECF0", padding: "10px", textAlign: "start", borderRadius: "8px" }}>
                                    <Box display={"flex"} alignItems={"center"} gap="5px" width={"100%"}>
                                        <img src={docReject} alt="docReject" />
                                        <Box width={"100%"}>
                                            <Text largeBlack700>{state?.re_upload_documents[name]?.name}</Text>
                                            <Text black12 sx={{ font: "13px Quicksand !important", fontWeight: `${400} !important`, wordBreak: 'break-word !important', overflowWrap: 'break-word  !important', whiteSpace: 'pre-wrap  !important' }}><span style={{ font: '13px Quicksand', fontWeight: 700 }}>Reason For Rejection:&nbsp;</span>
                                                {name === "i9_document" ? state?.i9andw4[name]?.i9_reason_for_rejection : name === "w4_document" ? state?.i9andw4[name]?.w4_reason_for_rejection : state?.upload_documents[name]?.data[0]?.reason_for_rejection}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        }
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className={classes.dropZone}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Box className={classes.dropzoneContainer}>
                                                <Grid container spacing={1} textAlign='center' justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'-8px'}>
                                                        <img src={download} alt='download' />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Text largeBlack>Drag & Drop Documents</Text>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Text smallGrey>or</Text>
                                                    </Grid>
                                                    <Box display='flex' flexDirection='row' gap={2} textAlign='center' p={'10px 0px 20px 0px'}>
                                                        <Text smallBlue1 className={classes.clickhere}>Click Here</Text>
                                                        <Text largeBlack>To Upload</Text>
                                                    </Box>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                        <Text smallGreyFont>{getFileSizeLimitConfig(name)?.dropAreaLabel}</Text>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                            {
                                state?.re_upload_documents[name]?.data?.length > 0 ?
                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#0C75EB !important", textAlign: "left !important" }} title={getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel : ''} >{getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel?.substring(0, 80) + '...' : getFileSizeLimitConfig(name)?.fieldLabel}</Text>
                                        <Grid item container lg={12} md={12} columnGap={1.5}>
                                            {
                                                state?.re_upload_documents[name]?.data.map((item, index) => (
                                                    <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                        <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important` }}>
                                                            <a href={item.document_url} style={{ color: "#0C75EB" }} target="_blank" rel="noreferrer">
                                                                {item.document_name.length > 24 ?
                                                                    <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 24)} {item.document_name.length > 24 ? '...' : ''}
                                                                    </BlackToolTip>
                                                                    :
                                                                    <>
                                                                        {item.document_name}
                                                                    </>}
                                                            </a>
                                                        </Text>
                                                        <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { deleteChip(index) }} />
                                                    </Box>
                                                ))
                                            }
                                        </Grid>
                                    </Box> :
                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "15px 10px", background: "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "14px Quicksand !important", color: "#737373 !important", textAlign: "left !important" }} title={getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel : ''}>{getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel?.substring(0, 80) + '...' : getFileSizeLimitConfig(name)?.fieldLabel} </Text>
                                        <Grid item container lg={12} columnGap={1.5}>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item lg={7} md={5} sm={5} xs={12} textAlign='start'>
                                {
                                    active == 1 ? '' :
                                        <Button blackCancel onClick={handleBack}>Back</Button>
                                }
                            </Grid>
                            <Grid item lg={5} md={7} sm={7} xs={12} textAlign='end'>
                                {active == dataKeys.length ?
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                        <Button saveNcontinueSmall onClick={handleSubmit}>Submit</Button>
                                    </Box> :
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                        <Button borderlessSkip onClick={handleSkip}>Skip</Button>
                                        <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Grid >
    )
}

export default OnboardEmployeeReUploadDocs