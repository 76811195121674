import { Grid, Box } from '@mui/material'
import React, { useState } from 'react'
import Input from '../../../../../components/input/Input'
// import Text from '../../../../../components/customText/Text'
import Button from '../../../../../components/customButton/Button'
import FileInput from '../../../../../components/muiFileInput/FileInput'
import Date from '../../../../../components/datePicker/Date'
import { useEffect } from 'react'
import moment from 'moment'
import { dateFormat } from '../../../../../utils/utils'
import OnboardStyles from '../OnboardStyles'// eslint-disable-next-line
import { onCharactersAndNumbersChange, onCharactersAndNumbersInputChange } from '../../../../../components/Validation'
import Select from '../../../../../components/select/Select'
import ReusableClearAll from '../../../../../components/reuablePopup/ReusableClearAll'
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete'
import Text from '../../../../../components/customText/Text'
import LoadingButton from '../../../../../components/customButton/LoadingButton'

function PersonalDocsForm({ personalDocLoader, state, changeHandler, dateChange, edit, error, handleSubmit, docsList, setState, data, uploadDocs, setEdit, handleCancelForm, handleClearAll, inviteData, drivingLicenseConfig, eye, setEye }) {
  // eslint-disable-next-line
  const classes = OnboardStyles();

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const cancel = () => {
    setEye({ document_number: false })
    handleCancelForm();
  }

  const deleteDoc = (args) => {
    if (state.id !== '' && state.documents[args].id != "") {
      state['documents_deleted_ids'] = [state.documents[args].id];
    }
    if (inviteData != null) {
      state.documents[args].slug = '';
      state.documents[args].document_slug = '';
    }
    state.documents[args].new_document_id = '';
    state.documents[args].document_name = '';
    state.documents[args].document_url = '';
    state.documents[args].id = '';
    setState({ ...state });
    setDeleteOpen(false);
  }

  const clearAll = () => {
    setEye({ document_number: false })
    handleClearAll();
    setOpen(false);
  }

  useEffect(() => {
    if (data != undefined || data != null) {
      setState({
        ...state,
        id: data.id,
        document_type_id: data.document_type_id,
        valid_from: data.valid_from,
        valid_till: data.valid_till,
        document_number: data.document_number,
        documents: data.documents
      })
    }
    // eslint-disable-next-line
  }, [])


  return (
    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} columnSpacing={3}>
      {
        state.documents.map((item, index) => (
          <Grid item lg={12} md={12} sm={12} xs={12} pb={1}>
            <Box pt={'9px'}>
              <FileInput
                name='new_document_id'
                handleChange={(e) => uploadDocs(e, index, 'dls')}
                FileName={item.document_name ? item.document_name : ''}
                actionState={item.document_name ? 1 : ''}
                handleDelete={() => setDeleteOpen(true)}
                label={`${drivingLicenseConfig?.new_document_id?.label?.name} ${item.document_name ? "" : drivingLicenseConfig?.new_document_id?.label?.format}`}
                isDisabled={!edit}
                disabled={!edit}
                // error={docError[index] ? docError[index].new_document_id : ''}
                documentUrl={item.document_url ? item.document_url : ''}
                state={item}
                labelSize={58}
              />
            </Box>
            {/* {docError && docError.length > 0 ? (
              <Text red>{docError[index] ? docError[index].new_document_id : ''}</Text>
            ) : (
              ""
            )} */}
          </Grid>
        ))
      }
      {drivingLicenseConfig?.document_type_id?.isVisible &&
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Select
            name='document_type_id'
            value={state.document_type_id}
            onChange={(e) => { changeHandler(e) }}
            options={docsList}
            label={`${drivingLicenseConfig?.document_type_id?.label }`}
            placeholder={drivingLicenseConfig?.document_type_id?.placeholder}
            helperText={error.document_type_id && <Text red>{error.document_type_id ? error.document_type_id : ''}</Text>}
            disabled={drivingLicenseConfig?.document_type_id?.isDisabled || !edit}
          />
        </Grid>}
      {drivingLicenseConfig?.document_number?.isVisible &&
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: 'document_number',
              value: state.document_number,
              disabled: !edit,
              inputProps: { maxLength: 16 },
              type: eye.document_number ? 'text' : 'password',
            }}
            eyeIcon={eye.document_number}
            eyeCloseIcon={eye.document_number == false}
            eyeHandleChange={() => {
              setEye({
                ...eye,
                document_number: !eye.document_number
              })
            }}
            disabled={drivingLicenseConfig?.document_number?.isDisabled || !edit}
            handleChange={changeHandler}
            // onKeyPress={onCharactersAndNumbersChange}
            onKeyPress={(e) => onCharactersAndNumbersInputChange(e, drivingLicenseConfig?.document_number?.pattern)}
            iconText
            error={error.document_number}
            labelText={`${ drivingLicenseConfig?.document_number?.label}`}
            placeholder={drivingLicenseConfig?.document_number?.placeholder}
          />
          {<Text red>{error.document_number ? error.document_number : ''}</Text>}
        </Grid>}
      {drivingLicenseConfig?.valid_from?.isVisible &&
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box pt={'9px'}>
            <Date
              labelText={`${!edit ? drivingLicenseConfig?.valid_from?.label : drivingLicenseConfig?.valid_from?.required ? drivingLicenseConfig?.valid_from?.label : drivingLicenseConfig?.valid_from?.label + ' (Optional)'}`}
              name='valid_from'
              maxDate={moment().format(dateFormat())}
              value={state.valid_from}
              onChange={(value) => dateChange(value, 'valid_from')}
              height='56px'
              disabled={drivingLicenseConfig?.valid_from?.isDisabled || !edit}
              error={error.valid_from}
            />
          </Box>
          {error.valid_from && <Text red>{error.valid_from ? error.valid_from : ''}</Text>}
        </Grid>}
      {drivingLicenseConfig?.valid_till?.isVisible &&
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box pt={'9px'}>
            <Date
              labelText={`${!edit ? drivingLicenseConfig?.valid_till?.label : drivingLicenseConfig?.valid_till?.required ? drivingLicenseConfig?.valid_till?.label : drivingLicenseConfig?.valid_till?.label + ' (Optional)'}`}
              name='valid_till'
              value={state.valid_till}
              minDate={state.valid_from}
              onChange={(value) => dateChange(value, 'valid_till')}
              height='56px'
              disabled={drivingLicenseConfig?.valid_till?.isDisabled || !edit}
              error={error.valid_till}
            />
          </Box>
          {error.valid_till && <Text red>{error.valid_till ? error.valid_till : ''}</Text>}
        </Grid>}
      {
        edit &&
        <Grid item container lg={12} md={12} sm={12} xs={12} m={'25px 0px 10px 0px'}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Button disabled={personalDocLoader} blackCancel onClick={cancel}>Cancel</Button>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
            <Button disabled={personalDocLoader} onClick={() => { setOpen(true) }} clearall sx={{ marginRight: '10px' }}>Clear all</Button>
            <LoadingButton loading={personalDocLoader} brownMnSave onClick={() => handleSubmit()}>Save</LoadingButton>
          </Grid>
        </Grid>
      }
      {open && <ReusableClearAll open={open} setOpen={setOpen} onClick={() => { clearAll() }} />}
      {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(0) }} />}
    </Grid >
  )
}

export default PersonalDocsForm