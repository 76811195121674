import { Box, Grid, Hidden } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { ForgotPasswordStyles } from "./ForgotPasswordStyles";
import Text from "../../components/customText/Text";
import CustomInput from '../../components/input/Input';
import { isValid, validate_input_fields } from "../../components/Validation";
import ReusablePopup from "../../components/reuablePopup/ReusablePopup";
import { domain } from "../../config/Domain";
import LocalStorage from "../../utils/LocalStorage";
import CommonApi from "../../apis/CommonApi";
import { addWarningMsg } from "../../utils/utils";
import OTP from "../otp/OTP";
import { LoadingButton } from "@mui/lab";
import Logo from '../../assets/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import { resetPasswordDefaultConfig } from "../../config/pageConfig/publicConfig";

/*fun starts here*/
function ResetPassword() {
    const navigate = useNavigate()
    const location = useLocation()
    const classes = ForgotPasswordStyles();
    const [resetPasswordConfig, setResetPasswordConfig] = useState(resetPasswordDefaultConfig);
    const lowerCase = new RegExp(resetPasswordConfig?.password?.lowerCaseReg?.slice(1,-1));
    const upperCase = new RegExp(resetPasswordConfig?.password?.upperCaseReg?.slice(1,-1));
    const digit = new RegExp(resetPasswordConfig?.password?.digitReg?.slice(1,-1));
    const specialCharacter = new RegExp(resetPasswordConfig?.password?.specialCharacterReg?.slice(1,-1));
    const [state, setState] = useState({
        password: "",
        confirmpassword: ""
    });
    const [eye, setEye] = useState({
        password: false,
        confirmpassword: false
    });
    const [error, setError] = useState({});
    const [errorShow, setErrorShow] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const token = location.search.split('=')
    /*handle change fun*/
    const changeHandler = (e) => {
        setErrorShow(true)
        setState(
            {
                ...state,
                [e.target.name]: e.target.value,
            },
            handlevalidate(e)
        );
    };

    const handlevalidate = (e) => {
        let input = e.target;
        let err = { ...error };
        switch (input.name || input.tagName) {
            case "password":
                // error.password = validates_password(input.value);
                error.password = validate_input_fields(input.value, resetPasswordConfig?.password);
                error.confirmpassword = state.confirmpassword != "" ? input.value == state.confirmpassword ? '' : "Password not matched" : '';
                break;
            case "confirmpassword":
                error.confirmpassword = (resetPasswordConfig?.password?.isVisible && (!resetPasswordConfig?.password?.isDisabled)) ? state.password == input.value ? '' : resetPasswordConfig?.confirmpassword?.requiredMsg : validate_input_fields(input.value, resetPasswordConfig?.confirmpassword) ;
                break;
            default:
                break;
        }
        setError(err);
    };

    /*email validation */
    const emailValidations = () => {
        let { password, confirmpassword } = state;
        let errors = {};
        // errors.password = validates_password(password);
        errors.password =  (resetPasswordConfig?.password?.isVisible && (!resetPasswordConfig?.password?.isDisabled)) ? validate_input_fields(password, resetPasswordConfig?.password) : '' ;
        // errors.confirmpassword = password == confirmpassword ? validates_password(confirmpassword) : "Password not matched";
        errors.confirmpassword = (resetPasswordConfig?.password?.isVisible && (!resetPasswordConfig?.password?.isDisabled)) ? (resetPasswordConfig?.confirmpassword?.isVisible && (!resetPasswordConfig?.confirmpassword?.isDisabled)) ?  password == confirmpassword ? validate_input_fields(confirmpassword, resetPasswordConfig?.password) : resetPasswordConfig?.confirmpassword?.requiredMsg : '' : validate_input_fields(confirmpassword, resetPasswordConfig?.confirmpassword);
        return errors;
    };

    /*submit fun*/
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain,
            temp_access_token: token[1],
            confirm_password: state.confirmpassword,
            password: state.password
        }
        let errors = emailValidations();
        if (isValid(errors)) {
            CommonApi.changePassword(data)
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        // setOpenPopup(true);
                        // addSuccessMsg("OTP Send To Your Mail"); //success msg Alert
                        setLoader(false)
                        navigate('login')
                    } else {
                        setLoader(false)
                        addWarningMsg(response.data.message); // warning msg Alert
                    }
                })
        } else {
            setLoader(false)
            let err = { error };
            err = errors;
            setError(err);
        }
    };

    const ImagesList = [
        Image1,
        Image2,
        // Image3,
        Image4
    ];
    const [currentImage, setCurrentImage] = useState(0);

   

    useEffect(() => {

        const data = {
            page_name:"reset-password",
            page_type:"update"
          }

        async function getPageData() {
              const response = await CommonApi.fetchPageConfig(data) 
              if(response.status === 200){
                setResetPasswordConfig(response?.data?.data?.page_data)
              }else{
                setResetPasswordConfig(resetPasswordDefaultConfig)
              }
            } 
            getPageData()
        // Set an interval to update the current image every 3 seconds
        const intervalId = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % ImagesList.length);
        }, 3000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} height={'100vh'}>
                <Hidden mdDown>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ height: '100vh', overflow: 'hidden' }}>
                        <img
                            src={ImagesList[currentImage]}
                            alt={`img-${currentImage}`}
                            style={{
                                width: '100%',  // Ensures the image takes the full width of its container
                                height: '100%', // Ensures the image's height adjusts based on the parent (grid item)
                                objectFit: 'cover', // Maintain aspect ratio and fill the grid item without distortion
                            }}
                        />
                    </Grid>
                </Hidden>

                <Grid item container xl={6} lg={6} md={6} sm={12} xs={12}  justifyContent={'center'} alignContent={'center'}>
                    <Grid item lg={7} md={7} sm={7} xs={10} height={'95%'} justifyContent={'center'} alignContent={'center'}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1}>
                                        <img src={Logo} alt='logo' />
                                    </Box>
                                </Grid>
                                <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                    <Box p={1}>
                                        <Text popupHead1>Reset Password</Text>
                                    </Box>
                                </Grid>
                                {resetPasswordConfig?.password?.isVisible &&   
                                <Grid item sm={12} xs={12} pt={5}>
                                    <Box p={1}>
                                        <CustomInput
                                            onCut={(e) => { e.preventDefault() }}
                                            onCopy={(e) => { e.preventDefault() }}
                                            onPaste={(e) => { e.preventDefault() }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: "password",
                                                value: state.password,
                                                type: eye.password ? 'text' : 'password',
                                                inputProps:{
                                                    maxlength:resetPasswordConfig?.password?.maxLength
                                                }
                                            }}
                                            eyeIcon={eye.password}
                                            eyeCloseIcon={eye.password == false}
                                            eyeHandleChange={() => {
                                                setEye({
                                                    ...eye,
                                                    password: !eye.password
                                                })
                                            }}
                                            iconText
                                            handleChange={changeHandler}
                                            disabled={resetPasswordConfig?.password?.isDisabled}
                                            labelText={resetPasswordConfig?.password?.label}
                                            placeholder={resetPasswordConfig?.password?.placeholder}
                                            error={error.password}
                                        />
                                        {error.password ? <Text red>{error.password}</Text> : ""}
                                    </Box>

                                </Grid> }
                                {resetPasswordConfig?.confirmpassword?.isVisible &&
                                <Grid item sm={12} xs={12}>
                                    <Box p={1}>
                                        <CustomInput
                                            onCut={(e) => { e.preventDefault() }}
                                            onCopy={(e) => { e.preventDefault() }}
                                            onPaste={(e) => { e.preventDefault() }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: "confirmpassword",
                                                value: state.confirmpassword,
                                                type: eye.confirmpassword ? 'text' : 'password',
                                                inputProps:{
                                                    maxlength:resetPasswordConfig?.confirmpassword?.maxLength
                                                }
                                            }}
                                            eyeIcon={eye.confirmpassword}
                                            eyeCloseIcon={eye.confirmpassword == false}
                                            iconText
                                            eyeHandleChange={() => {
                                                setEye({
                                                    ...eye,
                                                    confirmpassword: !eye.confirmpassword
                                                })
                                            }}
                                            disabled={resetPasswordConfig?.confirmpassword?.isDisabled}
                                            handleChange={changeHandler}
                                            labelText={resetPasswordConfig?.confirmpassword?.label}
                                            placeholder={resetPasswordConfig?.confirmpassword?.placeholder}
                                            error={error.confirmpassword}
                                        />
                                        {error.confirmpassword ? <Text red>{error.confirmpassword}</Text> : ""}
                                    </Box>
                                </Grid>}
                                {
                                    errorShow ?
                                        <Grid item sm={12} xs={12} p={1} pl={2}>
                                            <Text mediumLabel sx={{ color: '#111827 !important', paddingBottom: '5px' }}>Password Requirements:</Text>
                                            <Text mediumLabel sx={{ color: upperCase.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}> <span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: upperCase.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span> At least {resetPasswordConfig?.password?.upperCaseTxt} upper case {upperCase.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: lowerCase.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: lowerCase.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span> At least {resetPasswordConfig?.password?.lowerCaseTxt} lower case {lowerCase.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: specialCharacter.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: specialCharacter.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span>At least {resetPasswordConfig?.password?.specialCharacterTxt} special character {specialCharacter.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: state.password.length > 7 || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: state.password.length > 7 || state.password.length == 0 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '6.5px 8px 2px 0px'
                                            }}> </span>The password length min of {resetPasswordConfig?.password?.passwordLengthTxt} characters. {state.password.length > 7 ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                            <Text mediumLabel sx={{ color: digit.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                                height: '6px',
                                                width: '6px',
                                                borderRadius: '50%',
                                                background: digit.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                                                display: 'flex',
                                                margin: '8px 8px 2px 0px'
                                            }}> </span>At least {resetPasswordConfig?.password?.digitTxt} number {digit.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        </Grid>
                                        : ''
                                }
                                <Grid item sm={12} xs={12} p={1}>
                                    <Box className={classes.buttonStyles}>
                                        <LoadingButton
                                            className={classes.loginButton}
                                            loading={loader}
                                            variant="contained"
                                            type="submit"
                                            onClick={(e) => { handleSubmit(e) }}
                                            fullWidth
                                        >
                                            Reset Password
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item container xs={12} sm={12} lg={12} md={12} justifyContent={'center'} alignContent={'center'} height={'5%'}>
                        <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}>
                            <a href={'https://www.japfu.ai/contact'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Us</a> </Text>
                    </Grid>
                </Grid>

                
            </Grid>
            <ReusablePopup
                title="Enter OTP"
                dialogTitleSmaller
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <OTP email={state.emailId} />
            </ReusablePopup>
        </div>
    );
}

export default ResetPassword;

