import LocalStorage from '../../utils/LocalStorage';
import React, { useState, useEffect } from 'react';

import { Box, Stack, Grid, Divider, Hidden, useMediaQuery, useTheme, Avatar } from '@mui/material';
import PayrollDashboardStyles from '../../views/admin/payroll/payrollDashboard/PayrollDashboardStyles'
import Text from '../../components/customText/Text';
import Button from '../../components/customButton/Button';
import { BlackToolTip, capitalizeAndAddSpace, NoDataFound, NoPermission } from '../../utils/utils';
import ReactApexChart from 'react-apexcharts';
import calendar from '../../assets/svg/payroll/calendar.svg';
import Search from '../../assets/svg/search1.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from "@mui/material/IconButton";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import { useNavigate } from 'react-router-dom';
import employees from '../../assets/svg/payroll/employees.svg';
import paidAmount from '../../assets/svg/payroll/paidAmount.svg'
import pendingDue from '../../assets/svg/payroll/pendingIcon.svg'
import reimbursed from '../../assets/svg/payroll/reimbursement-dashboard.svg'
import recovered from '../../assets/svg/payroll/recoveredIcon.svg'
import dollar from '../../assets/svg/payroll/currency-dollar.svg'
import finance from '../../assets/svg/payroll/financedollar.svg'
import payPeriod from '../../assets/svg/payroll/payperiodIcon.svg';
import currentCycle from '../../assets/svg/payroll/currentCycle.svg';
import checkDateIcon from '../../assets/svg/payroll/checkDate.svg';
import Userplus from '../../assets/svg/clients/user-plus-bold.svg';
import raisedImage from "../../assets/images/raised.png"
import approvedImage from "../../assets/images/check-circle.png"
import approvalImage from "../../assets/images/clock.png"
import totalImage from "../../assets/images/dollar-sign.png"
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import PlacementDashboardStyles from '../../views/admin/placements/newDashboard/PlacementDashboardStyles';
import { employee_URL } from '../../config/development';
import NewDownloadcloud from '../../assets/svg/download-cloud-new.svg';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    fontSize: "14px",
    fontWeight: 600,
    color: '#262626',
    textAlign: 'left'
}));

const StyledTableRowBody = styled(TableRow)(({ theme }) => ({
    margin: "4px 0px !important",
    borderRadius: "20px !important",
    cursor: 'pointer',
    boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05) !important",
    "& td, & th": {
        border: "none", // Removes all borders
    },
    "&:hover": {
        boxShadow: "0px 0px 15px 1px #cce9ffeb !important",
        transition: "all .3s ease",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));
export default function TourPayroll({ startTour, setStartTour, destroyTour, setDestroyTour}) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PayrollDashboardStyles();
    const classesBalanceSheet = PlacementDashboardStyles();
    const [content, setContent] = useState('dashboard');
    const navigate = useNavigate();

    const [dashboard, setDashboard] = useState('generate');
    const [activeState, setActiveState] = useState('reimbursement');


    const today = new Date(); // Use current date
    const currentDay = today.getDate();
    const totalDaysInMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
    ).getDate();

    const percentage = (currentDay / totalDaysInMonth) * 100;
    const finalDay = percentage.toFixed(2);
    var currency = LocalStorage.getCurrencySymbol();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm")); // Extra-small screens
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // Small screens
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // Medium screens


    // eslint-disable-next-line
    const [upcomingPayrollData, setUpcomingPayrollData] = useState({
        pay_period: {
            from_date: '',
            id: '',
            to_date: ''
        },
        current_cycle: {
            from_date: '',
            to_date: ''
        },
        check_date: '',
        current_balance: '',
        is_submited: true
    })

    const searchWidth = isXs ? "200px" : isSm ? "250px" : isMd ? "300px" : "368px";


    const semicircle = {
        series: [56],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: 0,
                sparkline: {
                    enabled: false
                }
            },
            fill: {
                type: 'solid',
                colors: ['#FA9174', '#7F56DA']
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#7F56DA",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels    
                        borderRadius: 30,
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 0,
                            fontSize: '22px',
                            color: '#fff',
                            fontWeight: `${700} !important`,
                            fontFamily: 'Quicksand !important'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
    }

    const balanceData = [
        {
            gender: "Male",
            e_verified: 0,
            employee_id: "ed52571b-216e-4204-b25a-3550b2799094",
            total_salary: 500,
            employee_name: "ABC",
            total_earning: 0,
            current_balance: 4512,
            employment_type: "W2",
            initial_balance: 5000,
            total_deduction: 0,
            profile_percentage: 55.56,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            total_reimbursement: 12,
            employee_reference_id: "CONS-3"
        },
        {
            gender: "Male",
            e_verified: 0,
            employee_id: "296d0ebd-ee06-4f73-82b5-13734c3c1f62",
            total_salary: 1200,
            employee_name: "DEF",
            total_earning: 0,
            current_balance: 0,
            employment_type: "W2",
            initial_balance: 0,
            total_deduction: 0,
            profile_percentage: 55.56,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            total_reimbursement: 0,
            employee_reference_id: "CONS-2"
        },
        {
            gender: "Male",
            e_verified: 0,
            employee_id: "13732224-3ecf-4fe4-858c-4ccd683a272f",
            total_salary: 1000,
            employee_name: "GHI",
            total_earning: 0,
            current_balance: 1000,
            employment_type: "W2",
            initial_balance: 1000,
            total_deduction: 0,
            profile_percentage: 35.71,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            total_reimbursement: 0,
            employee_reference_id: "CONS-1"
        },
        {
            gender: "Male",
            e_verified: 0,
            employee_id: "ed52571b-216e-4204-b25a-3550b2799094",
            total_salary: 500,
            employee_name: "IJK",
            total_earning: 0,
            current_balance: 4512,
            employment_type: "W2",
            initial_balance: 5000,
            total_deduction: 0,
            profile_percentage: 55.56,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            total_reimbursement: 12,
            employee_reference_id: "CONS-3"
        },
        {
            gender: "Male",
            e_verified: 0,
            employee_id: "296d0ebd-ee06-4f73-82b5-13734c3c1f62",
            total_salary: 1200,
            employee_name: "LMN",
            total_earning: 0,
            current_balance: 0,
            employment_type: "W2",
            initial_balance: 0,
            total_deduction: 0,
            profile_percentage: 55.56,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            total_reimbursement: 0,
            employee_reference_id: "CONS-2"
        },
    ]


    const handleTab = (value) => {
        setActiveState(value)

    }

    const cardsJson = [
        {
            name: 'Total Reimbursements Raised',
            imgPath: raisedImage,
            mainKey: 'total_reimbursement_raised',
            color: '#E7F2FD'
        },
        {
            name: 'Total Reimbursements Approved',
            imgPath: approvedImage,
            mainKey: 'total_reimbursement_approved',
            color: '#EAF9EB'
        },
        {
            name: 'Total Reimbursements Pending Approval',
            imgPath: approvalImage,
            mainKey: 'total_reimbursement_pending_approval',
            color: '#F3F0FF'
        },
        {
            name: 'Total Reimbursed Amount',
            imgPath: totalImage,
            mainKey: 'total_reimbursed_amount',
            color: '#FFFBE1'
        }
    ]

    const DeductionCardsJson = [
        {
            name: 'Total Deductions',
            imgPath: raisedImage,
            mainKey: 'total_deductions',
            color: '#E7F2FD'
        },
        {
            name: 'Total Deductions Recovered',
            imgPath: approvedImage,
            mainKey: 'total_deductions_recovered',
            color: '#EAF9EB'
        },
        {
            name: 'Total Deductions Due',
            imgPath: approvalImage,
            mainKey: 'total_deduction_due',
            color: '#F3F0FF'
        }
    ]

    const placements = [
        {
            display_name: "ABC",
            employee_reference_id: "CONS-3",
            gender: "Male",
            e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            category_type: "W2",
            id: "3a2269ae-3ef3-45e2-898f-4a7239dd2ca9",
            name: "decone",
            expense_type: "RE",
            expense_effect_on: "Payroll",
            date_of_expense: "12/04/2024",
            status: "Approved",
            reason_for_rejection: "",
            raised_amount: 10,
            approved_amount: 10,
            raised_by: "Srinivas  Payroll",
            raised_on: "12/17/2024",
            deduct_from: "",
            payment_mode: "Payroll cycle",
            pay_date: "",
            pay_period: "12/01/2024 - 12/07/2024",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            last_digit_of_bank_account: "",
            remarks: "",
            profile_percentage: 55.56,
            documents: [
                {
                    id: "e2f281a4-e1e5-472a-a9ed-2d00a4fac1df",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-3/Reimbursement_Document_20241217060131.jpg",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217060131",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-3/Reimbursement_Document_20241217060131.jpg",
                    expense_management_id: "3a2269ae-3ef3-45e2-898f-4a7239dd2ca9"
                },
                {
                    id: "ffaeac8d-5032-4f3b-b07e-649d824daef0",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-3/Reimbursement_Document_20241217060201.png",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217060201",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-3/Reimbursement_Document_20241217060201.png",
                    expense_management_id: "3a2269ae-3ef3-45e2-898f-4a7239dd2ca9"
                }
            ]
        },
        {
            display_name: "DEF",
            employee_reference_id: "CONS-2",
            gender: "Male",
            e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            category_type: "W2",
            id: "7b806f6e-bc4c-4dd2-9531-5889aab4991c",
            name: "decfive",
            expense_type: "RE",
            expense_effect_on: "Payroll",
            date_of_expense: "12/17/2024",
            status: "Approved",
            reason_for_rejection: "",
            raised_amount: 10,
            approved_amount: 10,
            raised_by: "Srinivas  Payroll",
            raised_on: "12/17/2024",
            deduct_from: "",
            payment_mode: "Payroll cycle",
            pay_date: "",
            pay_period: "12/01/2024 - 12/07/2024",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            last_digit_of_bank_account: "",
            remarks: "",
            profile_percentage: 55.56,
            documents: [
                {
                    id: "7b51befd-67ff-4b7e-b352-21047f982310",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217055928.jpg",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217055928",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217055928.jpg",
                    expense_management_id: "7b806f6e-bc4c-4dd2-9531-5889aab4991c"
                }
            ]
        },
        {
            display_name: "HIJ",
            employee_reference_id: "CONS-2",
            gender: "Male",
            e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            category_type: "W2",
            id: "a53e731b-e462-4bbe-838b-0300a6e03505",
            name: "decfour",
            expense_type: "RE",
            expense_effect_on: "Payroll",
            date_of_expense: "12/11/2024",
            status: "Approved",
            reason_for_rejection: "",
            raised_amount: 10,
            approved_amount: 10,
            raised_by: "Srinivas  Payroll",
            raised_on: "12/17/2024",
            deduct_from: "",
            payment_mode: "Payroll cycle",
            pay_date: "",
            pay_period: "12/01/2024 - 12/07/2024",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            last_digit_of_bank_account: "",
            remarks: "",
            profile_percentage: 55.56,
            documents: [
                {
                    id: "f775b198-079f-439a-9b41-fe9ac3e9a270",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217055607.png",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217055607",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217055607.png",
                    expense_management_id: "a53e731b-e462-4bbe-838b-0300a6e03505"
                }
            ]
        },
        {
            display_name: "KLM",
            employee_reference_id: "CONS-2",
            gender: "Male",
            e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            category_type: "W2",
            id: "64ec4267-a872-40c5-8522-976be1fa04c0",
            name: "decthree",
            expense_type: "RE",
            expense_effect_on: "Payroll",
            date_of_expense: "12/10/2024",
            status: "Approved",
            reason_for_rejection: "",
            raised_amount: 10,
            approved_amount: 10,
            raised_by: "Srinivas  Payroll",
            raised_on: "12/17/2024",
            deduct_from: "",
            payment_mode: "Payroll cycle",
            pay_date: "",
            pay_period: "12/01/2024 - 12/07/2024",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            last_digit_of_bank_account: "",
            remarks: "",
            profile_percentage: 55.56,
            documents: [
                {
                    id: "59d2a54a-e8da-4601-9fb1-0155fd6c35fc",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217054956.png",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217054956",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217054956.png",
                    expense_management_id: "64ec4267-a872-40c5-8522-976be1fa04c0"
                }
            ]
        },
        {
            display_name: "MNO",
            employee_reference_id: "CONS-2",
            gender: "Male",
            e_verified: 0,
            profile_picture_url: `${employee_URL + 'documents/avatar/male/25.png'}`,
            category_type: "W2",
            id: "9e6b0964-bf0d-487b-b52e-3d476db95f25",
            name: "dectwo",
            expense_type: "RE",
            expense_effect_on: "Payroll",
            date_of_expense: "12/04/2024",
            status: "Approved",
            reason_for_rejection: "",
            raised_amount: 10,
            approved_amount: 10,
            raised_by: "Srinivas  Payroll",
            raised_on: "12/17/2024",
            deduct_from: "",
            payment_mode: "Payroll cycle",
            pay_date: "",
            pay_period: "12/01/2024 - 12/07/2024",
            transaction_id: "",
            payee_name: "",
            bank_name: "",
            last_digit_of_bank_account: "",
            remarks: "",
            profile_percentage: 55.56,
            documents: [
                {
                    id: "867204e9-6cb2-4137-b35e-5959d776e09d",
                    document_url: "https://workforce-documents.s3.ap-south-1.amazonaws.com/gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217054844.png",
                    aws_s3_status: false,
                    document_name: "Reimbursement_Document_20241217054844",
                    document_path: "gimuemoa/Expense_Management/Reimbursement/CONS-2/Reimbursement_Document_20241217054844.png",
                    expense_management_id: "9e6b0964-bf0d-487b-b52e-3d476db95f25"
                }
            ]
        },

    ]

    const steps = [
        {
            element: '#payrolldashboard', popover: {
                title: 'Payroll', description: 'This button directs you to the payroll module.', side: "left",
                align: 'start'
            }
        },]

    if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) {
        steps.push(...[{
            element: '#generatepayroll', popover: {
                title: 'Generate Payroll', description: 'This button allows users to generate a draft that includes all employees, along with their worked hours, reimbursements, and deductions.', side: "right",
                align: 'start'
            }
        },])
    }

    steps.push(...[


        {
            element: '#summarypayroll', popover: {
                title: 'Payroll Summary', description:
                    'This button allows users to view the payroll summary once it is finalized', side: "right",
                align: 'start'
            }
        }, {
            element: '#balancesheet', popover: {
                title: 'Balance Sheet', description:
                    'This button directs you to the Balancesheet Module', side: "right",
                align: 'start'
            }
        }, {
            element: '#expensemanagement', popover: {
                title: 'Expense Management', description:
                    'This button directs you to the Expense Management Module.', side: "right",
                align: 'start'
            }
        }, {
            element: '#reimbursement', popover: {
                title: 'Reimbursement', description:
                    'This button displays a list of reimbursements along with their detailed information', side: "right",
                align: 'start'
            }
        }])

    if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) {
        steps.push(...[{
            element: '#addreimbursement', popover: {
                title: 'Add Reimbursements', description: 'This button allows users to submit a reimbursement request.', side: "right",
                align: 'start'
            }
        },{
            element: '#approvalpending', popover: {
                title: 'Pending For Approval', description:
                    'This button displays the list of reimbursement requests pending approval.', side: "right",
                align: 'start'
            }
        }])
    }
    steps.push(...[
         {
            element: '#deduction', popover: {
                title: 'Deduction', description:
                    'This button displays a list of deductions along with their detailed information.', side: "right",
                align: 'start'
            }
        }])
    if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) {
        steps.push(...[{
            element: '#adddeduction', popover: {
                title: 'Add Deductions', description: 'This button allows users to submit a deduction. .', side: "right",
                align: 'start'
            }
        },])
    }
    useEffect(() => {
        const driverObjPayroll = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 8,
            stageRadius: 8,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 21,

            onPopoverRender: (popover, opts) => {//creates the popover with required design 
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    })
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        const sidebarPopUp = document.getElementById('sidebarleftArrow');

                        event.stopPropagation();
                        if (opts.config.steps[currentStep - 1].popover.title == 'Payroll') {
                            sidebarPopUp.click();
                            setTimeout(() => {
                                driverObjPayroll.movePrevious();
                            }, 300);
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Generate Payroll') {
                            setDashboard('generate')
                            driverObjPayroll.movePrevious();
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Payroll Summary') {
                            setDashboard('summary')
                            setContent('dashboard')
                            sidebarPopUp.click();
                            setTimeout(() => {
                                driverObjPayroll.movePrevious();
                            }, 300);
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Balance Sheet') {
                            setContent('balancesheet')
                            driverObjPayroll.movePrevious();

                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Expense Management') {
                            sidebarPopUp.click();
                            setTimeout(() => {
                                driverObjPayroll.movePrevious();
                            }, 300);
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Pending For Approval') {
                            handleTab('reimbursement')
                            driverObjPayroll.movePrevious();
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Reimbursement') {
                            handleTab('reimbursement')
                            driverObjPayroll.movePrevious();
                        }
                        else {
                            driverObjPayroll.movePrevious();
                        }
                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    LocalStorage.setStartTour({ 'payroll': false })
                    updateTourGuideStatus('PAYROLL');
                    setStartTour();
                    driverObjPayroll.destroy();
                    if (opts.config.steps[currentStep].popover.title == 'Expense Management' || opts.config.steps[currentStep].popover.title ==  'Balance Sheet' || opts.config.steps[currentStep].popover.title ==  'Payroll' ) {
                        const sidebar = document.getElementById('sidebarleftArrow');
                        sidebar.click()
                    }

                    if (LocalStorage?.getUserData()?.org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    LocalStorage.setStartTour({ 'payroll': false })
                    updateTourGuideStatus('PAYROLL');
                    setStartTour();
                    driverObjPayroll.destroy();
                    if (LocalStorage?.getUserData()?.org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    const sidebarleftArrow = document.getElementById('sidebarleftArrow');

                    if (opts.config.steps[currentStep + 1].popover.title == 'Generate Payroll') {
                        sidebarleftArrow.click();
                        driverObjPayroll.moveNext();
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Payroll Summary') {
                        if (((rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == false)))) {
                            sidebarleftArrow.click();
                        }
                        setDashboard('summary')
                        driverObjPayroll.moveNext();
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Balance Sheet') {
                        sidebarleftArrow.click()
                        setContent('balancesheet')
                        setTimeout(() => {
                            driverObjPayroll.moveNext();
                        }, 300);
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Expense Management') {
                        setContent('expense')
                        setTimeout(() => {
                            driverObjPayroll.moveNext();
                        }, 200);
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Reimbursement') {
                        sidebarleftArrow.click()
                        driverObjPayroll.moveNext();
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Add Reimbursements') {
                        driverObjPayroll.moveNext();
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Pending For Approval') {
                        driverObjPayroll.moveNext();
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Deduction') {
                        handleTab('deductions')
                        setTimeout(() => {
                            driverObjPayroll.moveNext();
                        }, 200);
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Add Deductions') {
                        driverObjPayroll.moveNext();
                    }
                    else {
                        driverObjPayroll.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });

        if (destroyTour) {
            driverObjPayroll.destroy();
            setDestroyTour(false); // Reset the destroyTour state
        }else{
        if ((LocalStorage.getStartTour() && LocalStorage.getStartTour().payroll == true) && (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) && LocalStorage.getUserData().admin_login == true) {
            const sidebar = document.getElementById('sidebarleftArrow');
            const sidebarbox = document.getElementById('sidebarbox');
            if(sidebarbox?.offsetWidth &&sidebarbox.offsetWidth == 94){
                sidebar.click();
            }
            if(window.innerWidth < 900){
                if (sidebar ) {
                    sidebar.click();
                }
            }
            setTimeout(() => {
                driverObjPayroll.drive();
            }, 500);
        }}

        return () => {
            driverObjPayroll.destroy();
        };
        // eslint-disable-next-line
    }, [destroyTour, setDestroyTour])
    return <>
        {
            ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)))) ?
                <>

                    {content == 'dashboard' && <>
                        <Grid container className={classes.paddingLeft} mt={2}>
                            <Grid item container xs={12} lg={12} md={12} alignItems='center'>
                                <Grid item sm={7} lg={6} md={6} xs={12}>
                                    <Box display='flex' flexDirection='column' gap={1}>
                                        <Text largeLabel>Current Balance</Text>
                                        <Text veryLargeLabel>{`$ 123456.00`}</Text>
                                    </Box>
                                </Grid>

                                {dashboard == 'generate' && <>

                                    {
                                        (( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                                            <Grid item xs={10} sm={4} lg={5.3} md={5.3} textAlign='end'>
                                                <Button generatePayroll id='generatepayroll'>$ Generate Payroll</Button>
                                            </Grid> : ''
                                    }


                                </>}
                                {dashboard == 'summary' &&
                                    <>
                                        {
                                            (( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)))) ?
                                                <Grid item xs={10} sm={4} lg={5.3} md={5.3} textAlign='end'>
                                                    <Button generatePayroll id='summarypayroll'>Payroll Summary</Button>
                                                </Grid> : ''
                                        }
                                    </>}

                            </Grid>

                            <>
                                <Grid item container xs={12} lg={12} md={12} mt={2}>
                                    <Box sx={{ width: '94.4%', height: { sm: '25vh', xs: '100%' }, boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '12px' }}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Text largeBlack>Upcoming Payroll</Text>
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item container xs={12} lg={12} md={12}>
                                                <Box className={classes.upcomingPayroll}>
                                                    <Box className={classes.payperiod}>
                                                        <img src={payPeriod} alt='payPeriod' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBoldWhite600>05-01-2024 - 06-01-2024</Text>
                                                    </Box>
                                                    <Box className={classes.currentCycle}>
                                                        <Box
                                                            sx={{
                                                                position: "absolute",
                                                                left: `${finalDay}%`,
                                                                top: "-35px",
                                                                transform: "translateX(-50%)",
                                                                textAlign: "center",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Text smallBlack mb={1}>Today</Text>
                                                            <Box className={classes.currentDate}>
                                                                <Text largeWhite16>{today.getDate()}th</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                                                            <img src={currentCycle} alt='currentCycle' style={{ paddingRight: '5px' }} />
                                                            <Text mediumBoldWhite600>07-01-2024 - 08-01-2024</Text>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.checkDate}>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                                                            <img src={checkDateIcon} alt='checkDateIcon' style={{ paddingRight: '5px' }} />
                                                            <Text black12>30-01-2024</Text>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Hidden>
                                        <Hidden smUp>
                                            <Grid item container xs={12} lg={12} md={12}>
                                                <Grid item xs={12} pb={2} pt={1}>
                                                    <Box className={classes.payperiod}>
                                                        <img src={payPeriod} alt='payPeriod' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBoldWhite600>{upcomingPayrollData.pay_period.from_date} - {upcomingPayrollData.pay_period.to_date}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} pb={2} pt={3}>
                                                    <Box className={classes.currentCycle}>
                                                        <Box
                                                            sx={{
                                                                position: "absolute",
                                                                left: `${finalDay}%`,
                                                                top: "-35px",
                                                                transform: "translateX(-50%)",
                                                                textAlign: "center",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Text smallBlack mb={1}>Today</Text>
                                                            <Box className={classes.currentDate}>
                                                                <Text largeWhite16>{today.getDate()}th</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                                                            <img src={currentCycle} alt='currentCycle' style={{ paddingRight: '5px' }} />
                                                            <Text mediumBoldWhite600>07-01-2024 - 08-01-2024</Text>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} pb={2}>
                                                    <Box className={classes.checkDate}>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' pt={'5px'}>
                                                            <img src={checkDateIcon} alt='checkDateIcon' style={{ paddingRight: '5px' }} />
                                                            <Text black12>30-01-2024</Text>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                        <Grid item container xs={12} lg={12} md={12}>
                                            <Box sx={{ width: '30%' }}>
                                                <Text black12>Pay Period</Text>
                                            </Box>
                                            <Box sx={{ width: '60%' }}>
                                                <Text black12>Current cycle</Text>
                                            </Box>
                                            <Box sx={{ width: '10%' }}>
                                                <Text black12>Check Date</Text>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item container xs={12} lg={12} md={12} my={4}>
                                    <Divider sx={{ border: '0.5px solid #EAECF0 !important', width: '94.4%' }} />
                                </Grid>
                                <Grid item container xs={12} alignItems='center'>
                                    <Grid item xs={6}>
                                        <Text largeLabel>Payroll History</Text>
                                    </Grid>
                                    <Grid item container xs={5.3} justifyContent='end'>

                                        <>
                                            <Button
                                                choosePeriod
                                                id="basic-button"
                                                aria-haspopup="true"
                                            >
                                                <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                    <Text offBoardBodyFont>Choose period</Text>
                                                    <img src={calendar} alt='calendar' />
                                                </Box>
                                            </Button>

                                        </>

                                    </Grid>
                                    <Grid item container xs={12} lg={12} md={12} mt={3}>
                                        <Box sx={{ width: '94.4%', boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '15px 15px 25px 15px' }}>
                                            <Grid item container xs={12} alignItems='center'>
                                                <Grid item xs={6} lg={6} md={6}>
                                                    <Text largeLabel>{`05-01-2024 - 06-01-2024`}</Text>
                                                </Grid>
                                                <Grid item xs={6} lg={6} md={6} textAlign='end'>

                                                    <Text largeBlue style={{ cursor: 'pointer' }} >View Summary</Text>

                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} lg={12} md={12} my={3}>
                                                <Divider sx={{ border: '0.5px solid #EAECF0 !important', width: '100%' }} />
                                            </Grid>
                                            <Grid item container xs={12} lg={12} md={12} alignItems='center'>
                                                <Grid item container xs={12} sm={3} lg={3} md={3} alignItems='center' justifyContent='center' textAlign='center'>
                                                    <Box display='flex' flexDirection='column' justifyContent='center' gap={2}>
                                                        <Box style={{ position: "relative" }}>
                                                            <ReactApexChart options={semicircle.options} series={semicircle.series} type="radialBar" />
                                                            <Box
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "70%",
                                                                    left: "50%",
                                                                    transform: "translate(-50%, -50%)",
                                                                    textAlign: "center",
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                <Box display='flex' flexDirection='column' gap={0.5} justifyContent='center'>
                                                                    <img src={employees} alt='employees' style={{ height: '18px', width: '20px', marginLeft: '4px' }} />
                                                                    <Text veryLargeLabel>520</Text>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text smallGrey400>Finalized</Text>
                                                                <Text blue14>500</Text>
                                                                <Divider sx={{ border: '1px solid #FA9174' }} />
                                                            </Box>
                                                            <Box display='flex' flexDirection='column' gap={1}>
                                                                <Text smallGrey400>Non Finalized</Text>
                                                                <Text blue14>20</Text>
                                                                <Divider sx={{ border: '1px solid #7F56DA' }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Divider orientation='vertical' variant='middle' flexItem />
                                                <Grid item xs={12} sm={2.9} lg={2.9} md={2.9} alignItems='center'>
                                                    <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={paidAmount} alt='amount' />
                                                            <Text mediumBlack600>Amount Paid</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 144000.00`}</Text>
                                                        <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={pendingDue} alt='amount' />
                                                            <Text mediumBlack600>Pending Dues</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 44000.00`}</Text>
                                                    </Box>
                                                </Grid>
                                                <Divider orientation='vertical' variant='middle' flexItem />
                                                <Grid item xs={12} sm={2.9} lg={2.9} md={2.9} alignItems='center'>
                                                    <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={reimbursed} alt='amount' />
                                                            <Text mediumBlack600>Reimbursed Amount</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 40000.00`}</Text>
                                                        <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={recovered} alt='amount' />
                                                            <Text mediumBlack600>Recovered Amount</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 1690.00`}</Text>
                                                    </Box>
                                                </Grid>
                                                <Divider orientation='vertical' variant='middle' flexItem />
                                                <Grid item xs={12} sm={2.9} lg={2.9} md={2.9} alignItems='center'>
                                                    <Box display='flex' flexDirection='column' gap={2} justifyContent='center' pl={3}>
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={dollar} alt='amount' />
                                                            <Text mediumBlack600>Margin Earned</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 1690.00`}</Text>
                                                        <Divider sx={{ margin: '10px 5px 10px -10px' }} />
                                                        <Box display='flex' flexDirection='row' gap={1} >
                                                            <img src={finance} alt='amount' />
                                                            <Text mediumBlack600>Final Balance</Text>
                                                        </Box>
                                                        <Text largeBlack22>{`$ 1690.00`}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid >

                            </>


                        </Grid>
                    </>}

                    {content == 'balancesheet' && <>

                        <Grid container sx={{
                            pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                            pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
                        }}>
                            <Grid item xs={12} pb={2}>
                                <Text mediumViewAmt>Balance Sheet</Text>
                            </Grid>
                        </Grid>
                        <Box className={classesBalanceSheet.flexBox} sx={{
                            paddingLeft: {
                                xs: "0px",
                                sm: "0px",
                                md: "65px",
                            }
                        }}>

                            <Box sx={{ width: "92%" }} >
                                <Box style={{ padding: { md: "20px 10px 10px 10px", sm: "0px" } }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                            <Box sx={{
                                                height: "44px", border: "1.5px solid #E2E5E6", width: searchWidth, borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center"
                                            }}>
                                                <input
                                                    type="text"
                                                    value={''}
                                                    className={classesBalanceSheet.EmployeesSearchInput}
                                                    placeholder="Search by Name / ID"
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>

                                                    <img src={Search} alt="Search" />

                                                </Box>
                                            </Box>
                                        </div>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px", paddingLeft: '5px' }}>
                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                    <Text offBoardBodyFont>
                                                        Count

                                                        {` - 5 Employees`}</Text>
                                                </Grid>
                                                <button title="Filter"
                                                    type="button"
                                                    className={classesBalanceSheet.borderButton}>
                                                    <FilterListIcon sx={{ color: "#2BEA2B" }} />

                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </Box>
                                <TableContainer sx={{ overflow: 'scroll', width: '100%' }}>
                                    <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                                        width: '100%',
                                        // tableLayout: 'fixed',
                                    }}
                                    >
                                        <Box style={{ padding: "10px" }}>
                                            <Box sx={{
                                                display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                                "@media (min-width: 200px) and (max-width: 1400px)": {
                                                    font: "12px Quicksand",
                                                    fontWeight: "600",
                                                    paddingTop: '1px'
                                                },
                                            }} >
                                                <StyledTableRow>
                                                    <StyledTableCell align="start" sx={{ minWidth: { xs: 200, sm: 250 } }}>Employee Name
                                                        <IconButton
                                                            style={{
                                                                padding: '1px',
                                                                margin: '8px',
                                                                backgroundColor: '#ffffff',
                                                                border: '0',
                                                                minWidth: '22px',
                                                            }}
                                                        >
                                                            <FilterListIcon sx={{ height: '19px', color: "#2BEA2B" }} />
                                                        </IconButton>

                                                    </StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 200 }}>Initial Opening Balance</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 150 }}>Total Earnings</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 150 }}>Total Salary</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 200 }}>Total Reimbursement</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 150 }}>Total Deductions</StyledTableCell>
                                                    <StyledTableCell align="start" sx={{ minWidth: 150 }}>Current Balance</StyledTableCell>
                                                </StyledTableRow>
                                            </Box>


                                            <>

                                                <Box sx={{
                                                    width: '100%',
                                                    height: "60.5vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                    paddingLeft: '5px', paddingRight: '5px',
                                                    "@media (min-height: 850px)": {
                                                        height: '73.5vh'
                                                    }
                                                }} >
                                                    {
                                                        balanceData.map((place, key) => (
                                                            <StyledTableRowBody key={key} >
                                                                <StyledTableCell align="start" sx={{ minWidth: { xs: 200, sm: 250 } }}>
                                                                    <Box display='flex' alignItems='center' gap={2}>

                                                                        <Avatar
                                                                            alt={'name'}
                                                                            src={place.profile_picture_url}
                                                                            sx={{ width: 56, height: 56 }}
                                                                        />
                                                                        <Box>
                                                                            {
                                                                                place.status == 'In Active' ?
                                                                                    <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                        {
                                                                                            capitalizeAndAddSpace(place.employee_name)
                                                                                        }

                                                                                    </Text>
                                                                                    :
                                                                                    <Text mediumBoldBlack noWrap>
                                                                                        {capitalizeAndAddSpace(place.employee_name)
                                                                                        }

                                                                                    </Text>
                                                                            }
                                                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' sx={{ padding: { sm: '4px 0px', xs: '0px' } }}>
                                                                                <Text greyLabel> {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                {
                                                                                    place.employment_type ?
                                                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                            <Box sx={{ height: '4px', width: '4px', background: '#C7CCD3', borderRadius: '50%' }} />
                                                                                            <Text greyLabel> {place.employment_type}</Text>
                                                                                        </Box> : ''
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 200 }}>{currency} {place.initial_balance}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.total_earning}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.total_salary}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 200 }}>{currency} {place.total_reimbursement}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.total_deduction}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.current_balance}</StyledTableCell>
                                                            </StyledTableRowBody>

                                                        ))
                                                    }
                                                </Box>


                                            </>

                                        </Box>
                                    </Table>
                                </TableContainer>

                            </Box>


                        </Box>
                    </>}



                    {content == 'expense' &&
                        <>
                            <Grid container
                                sx={{
                                    pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                                    pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
                                }}
                            >

                                <Grid item xs={12}>
                                    <Text mediumViewAmt>Expense Management</Text>
                                </Grid>



                                <Grid item xs={12} container pt={2}>
                                    <Grid item container xl={8} md={6} sm={5}>
                                        <Grid item xl={3.7} lg={6} md={8} sm={9}>
                                            <Box className={classesBalanceSheet.tabBg}>
                                                <Box id='reimbursement' className={activeState == 'reimbursement' ? classesBalanceSheet.ActiveBg : classesBalanceSheet.inactiveBg} onClick={() => handleTab('reimbursement')}>
                                                    {
                                                        activeState == 'reimbursement' ?
                                                            <Text largeWhite400>Reimbursements</Text> :
                                                            <Text blackHeader>Reimbursements</Text>
                                                    }
                                                </Box>
                                                <Box id='deduction' className={activeState == 'deductions' ? classesBalanceSheet.ActiveBg : classesBalanceSheet.inactiveBg} onClick={() => handleTab('deductions')}>
                                                    {
                                                        activeState == 'deductions' ?
                                                            <Text largeWhite400>Deductions</Text> :
                                                            <Text blackHeader>Deductions</Text>
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xl={4} md={6} sm={7} xs={12} justifyContent={'flex-end'} pr={1} spacing={2} sx={{ paddingTop: { xs: '10px' } }}>
                                        {
                                            activeState == 'reimbursement' ?
                                                <Grid item xs={6} sm={4} md={6} lg={6} >
                                        {( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))) &&

                                                    <Button id='approvalpending' sx={{ width: "100% !important", height: '45px !important' }} outlineBlue >Pending for Approval</Button>}
                                                </Grid>
                                                : ''
                                        }
                                        <Grid item xs={6}>

                                        {( (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))) &&
                                            <Button id={activeState == 'reimbursement' ? 'addreimbursement' : 'adddeduction'} sx={{ width: "100%" }} addButton >
                                                <img src={Userplus} alt='plus' className={classesBalanceSheet.addIcon} />
                                                &nbsp;Add {activeState == "reimbursement" ? 'Reimbursement' : activeState == "deductions" ? 'Deduction' : ""}
                                            </Button>}



                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} pt={3} gap={2}>
                                    <>
                                        {
                                            (activeState == 'reimbursement' ? cardsJson : DeductionCardsJson).map((item) => (
                                                <Grid item container lg={activeState == 'reimbursement' ? 2.8 : 3.8} md={activeState == 'reimbursement' ? 2.78 : 3.8} sm={activeState == 'reimbursement' ? 2.72 : 3.75} sx={{ backgroundColor: item.color, borderRadius: '12px ' }} alignContent={'center'} >
                                                    <Grid item xs={12}>
                                                        <Box p={2} minHeight={'110px'}>
                                                            <Grid item xs={12}>
                                                                <Text blackHeader1 sx={{ fontSize: { md: '13.5px !important', sm: '10px !important' } }}>{item.name}</Text>
                                                            </Grid>
                                                            <Box p={2} height={'2vh'}></Box>
                                                            <Grid item container xs={12}>
                                                                <Grid item xl={4} sm={5}>
                                                                    <Stack direction={'row'}>
                                                                        <img src={item.imgPath} alt={item.name} />
                                                                        {
                                                                            (activeState == 'reimbursement' && item.mainKey != "total_reimbursed_amount") ?
                                                                                <Text largeBlack22>&nbsp;  10</Text> : ''
                                                                        }
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xl={8} sm={7} container justifyContent={'flex-end'}>
                                                                    {
                                                                        activeState == 'reimbursement' ?
                                                                            <Text largeBlack22>$&nbsp; 123</Text> :
                                                                            <Text largeBlack22>$&nbsp; 123</Text>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </>
                                </Grid>

                            </Grid>
                            <Box className={classesBalanceSheet.flexBox} sx={{
                                paddingLeft: {
                                    xs: "0px",
                                    sm: "0px",
                                    md: "65px",
                                },
                            }}>

                                <Box sx={{ width: "92%" }} >
                                    <Box style={{ padding: "20px 10px 10px 10px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <Box sx={{
                                                    height: "44px", border: "1.5px solid #E2E5E6", width: searchWidth, borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                                }}>
                                                    <input
                                                        type="text"
                                                        value={''}
                                                        className={classesBalanceSheet.EmployeesSearchInput}
                                                        placeholder={"Search by Name / ID"}
                                                    />
                                                    <Box sx={{ paddingRight: "15px !important" }}>
                                                        {

                                                            <img src={Search} alt="Search" />
                                                        }
                                                    </Box>
                                                </Box>
                                            </div>
                                            {
                                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                                <Text offBoardBodyFont>
                                                                    Count

                                                                    {` - 5 `}</Text>
                                                            </Grid>
                                                            <button title="Export" type="button" className={classesBalanceSheet.borderButton}

                                                            >
                                                                <img src={NewDownloadcloud} alt="Userplus" />
                                                            </button>
                                                            <button title="Filter"
                                                                type="button"
                                                                className={classesBalanceSheet.borderButton}
                                                            >
                                                                <FilterListIcon sx={{ color: "#2BEA2B" }} />

                                                            </button>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                        </div>

                                    </Box>
                                    {
                                        ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                            <TableContainer sx={{ overflow: 'scroll', width: '100%' }}>
                                                <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                                                    width: '100%',
                                                    // tableLayout: 'fixed',
                                                }}
                                                >
                                                    <Box>

                                                        <Box sx={{
                                                            display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                                            "@media (min-width: 200px) and (max-width: 1400px)": {
                                                                font: "12px Quicksand",
                                                                fontWeight: "600",
                                                                paddingTop: '1px'
                                                            },
                                                        }} >
                                                            <StyledTableRow>
                                                                <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 300 : 350 }}>

                                                                    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>

                                                                        <Text mediumBlack14>Employee Name</Text>

                                                                    </Box>




                                                                </StyledTableCell>
                                                                <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 200 : 250 }}>Type</StyledTableCell>
                                                                <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{activeState == 'reimbursement' ? 'Add To' : 'Deducted From'}</StyledTableCell>
                                                                <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>Expense Date</StyledTableCell>
                                                                {activeState == 'reimbursement' &&
                                                                    <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }} >
                                                                        <Text sx={{ display: 'flex', flexDirection: 'row', paddingTop: '5px', font: '14px Quicksand !important', fontWeight: `${600} !important` }}>Status
                                                                            Status
                                                                        </Text>


                                                                    </StyledTableCell>
                                                                }
                                                                {activeState == 'reimbursement' &&
                                                                    <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>Raised Amount</StyledTableCell>
                                                                }
                                                                {

                                                                    <StyledTableCell align="start" sx={{ minWidth: 150 }}>{activeState == 'reimbursement' ? 'Approved Amount' : 'Amount'}</StyledTableCell>
                                                                }
                                                            </StyledTableRow>
                                                        </Box>

                                                        <>
                                                            {
                                                                placements.length > 0 ?
                                                                    <Box sx={{
                                                                        height: "55vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                                        paddingLeft: '5px', paddingRight: '5px',
                                                                        "@media (min-height: 850px)": {
                                                                            height: "65vh"
                                                                        }
                                                                    }} >
                                                                        {
                                                                            placements.map((place, key) => (
                                                                                <StyledTableRowBody key={key}  >
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 310 : 360, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                                                                        {!LocalStorage.getUserData().admin_login ?
                                                                                            <BlackToolTip arrow title={place.name}>{place.name ? place.name.length > 16 ? place.name.slice(0, 16) + '...' : place.name : "--"}
                                                                                            </BlackToolTip>
                                                                                            :
                                                                                            <Box display='flex' alignItems='center' gap={2}>

                                                                                                <Avatar
                                                                                                    alt={'name'}
                                                                                                    src={place.profile_picture_url}
                                                                                                    sx={{ width: 56, height: 56 }}
                                                                                                />
                                                                                                <Box>

                                                                                                    <Text mediumBoldBlack noWrap>

                                                                                                        {capitalizeAndAddSpace(place.display_name)}


                                                                                                    </Text>

                                                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' p={'4px 0px'}>
                                                                                                        <Text greyLabel> {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                                        {
                                                                                                            place.category_type ?
                                                                                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                                    <Box sx={{ height: '4px', width: '4px', background: '#C7CCD3', borderRadius: '50%' }} />
                                                                                                                    <Text greyLabel> {place.category_type}</Text>
                                                                                                                </Box> : ''
                                                                                                        }
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        }
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 200 : 250 }}>
                                                                                        {place.expense_type}
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{place.expense_effect_on}</StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{place.date_of_expense}</StyledTableCell>
                                                                                    {activeState == 'reimbursement' &&
                                                                                        <StyledTableCell align="center" sx={{ minWidth: 150 }}>{place.status}</StyledTableCell>
                                                                                    }
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{currency} {place.raised_amount}</StyledTableCell>
                                                                                    {activeState == 'reimbursement' &&
                                                                                        <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.approved_amount}</StyledTableCell>
                                                                                    }
                                                                                </StyledTableRowBody>

                                                                            ))
                                                                        }
                                                                    </Box>
                                                                    :
                                                                    placements.length == 0 ?
                                                                        <Box>
                                                                            {NoDataFound(`To fetch the data. Please add ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'}`, `No  ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'} Yet`)}
                                                                        </Box>
                                                                        : ''
                                                            }


                                                        </>

                                                    </Box>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <>
                                                {NoPermission()}
                                            </>
                                    }
                                </Box>


                            </Box >

                        </>}


                </>


                :
                NoPermission()
        }






    </>
}