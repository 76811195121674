import { Box, Divider, Grid } from "@mui/material";
import Text from "../../../components/customText/Text";

export default function ClientDetails(props) {
    const { clientDetailsAPI } = props
    return (
        <>
            {/* Outstanding Information */}
            <Grid container spacing={2} sx={{ marginY: 3 }}>
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            backgroundColor: '#FFF7E6',
                            padding: 4,
                            textAlign: 'center',
                            borderRadius: 2,
                        }}
                    >
                        <Text largeLabel sx={{ paddingBottom: '10px' }}>Outstanding Invoices</Text>
                        <Text blackHeader600>{clientDetailsAPI.client_details.outstanding_invoices}</Text>
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            backgroundColor: '#F3F0FF',
                            padding: 4,
                            textAlign: 'center',
                            borderRadius: 2,
                        }}
                    >
                        <Text largeLabel sx={{ paddingBottom: '10px' }}>Outstanding Receivables</Text>
                        <Text blackHeader600>$ {clientDetailsAPI.client_details.outstanding_amount}</Text>
                    </Box>
                </Grid>
            </Grid>

            {/* Client Details */}
            <Box sx={{
                height: { xl: '27.5vh' }, overflow: 'scroll', "@media (min-height: 750px)": {
                    height: '44vh'
                }
            }}>
                <Text boldBlackfont16 sx={{ paddingBottom: '15px' }}>
                    Client Details
                </Text>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            Address Line 1
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].address_line_one}</Text>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            Address Line -2 (Optional)
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].address_line_two ? clientDetailsAPI?.client_details.address[0].address_line_two : '-' }</Text>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            Country
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].country_name}</Text>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            State
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].state_name}</Text>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            City
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].city}</Text>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                            Zip Code
                        </Text>
                        <Text mediumBlack14>{clientDetailsAPI?.client_details.address[0].zip_code}</Text>
                    </Grid>
                </Grid>
                <Grid item container xs={12} pt={2}>
                    <Box my={1} sx={{ width: '100%' }}>
                        <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                    </Box>
                </Grid>

                {/* Invoice Configuration */}
                <Grid item container xs={12}>
                    <Box sx={{ marginTop: 2 }}>
                        <Text boldBlackfont16 sx={{ paddingBottom: '15px' }}>Invoice Configuration</Text>
                        <Grid item xs={12}>
                            <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                Payment Terms
                            </Text>
                            <Text mediumBlack14>{clientDetailsAPI.client_details.net_pay_term_days}</Text>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item container xs={12} pt={2}>
                    <Box my={1} sx={{ width: '100%' }}>
                        <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                    </Box>
                </Grid>
                {
                    clientDetailsAPI?.client_details?.contacts?.map((item, index) => (
                        <Grid item container xs={12} pt={2}>
                            <Text boldBlackfont16 sx={{ padding: '15px', paddingLeft: '0px' }}>
                                Contact Details - {index + 1} {item.is_primary ? `(Primary)` : ''}
                            </Text>
                            <Grid container spacing={2} pb={2}>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        First Name
                                    </Text>
                                    <Text mediumBlack14>{item.first_name}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Middle Name
                                    </Text>
                                    <Text mediumBlack14>{item.middle_name ? item.middle_name : '-'}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Last Name
                                    </Text>
                                    <Text mediumBlack14>{item.last_name ? item.last_name : '-'}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Email ID
                                    </Text>
                                    <Text mediumBlack14>{item.email_id ? item.email_id : '-'}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Phone Number
                                    </Text>
                                    <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '-'}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Extension
                                    </Text>
                                    <Text mediumBlack14>{item.telephone_number_ext ? item.telephone_number_ext : '-'}</Text>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Mobile Number (Optional)
                                    </Text>
                                    <Text mediumBlack14>{item.mobile_number ? item.mobile_number : '-'}</Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }


            </Box>

        </>
    )
}