module.exports = {
    /* Development */
    // API_URL: 'https://workforce-dev-api.codetru.org/api/v1/',
    API_URL: process.env.REACT_APP_API_URL,
    AdminURL: process.env.REACT_APP_API_URL,
    client_URL: process.env.REACT_APP_API_URL,
    org_URL: process.env.REACT_APP_API_URL,
    employee_URL: process.env.REACT_APP_API_URL,
    placement_URL: process.env.REACT_APP_API_URL,
    timesheet_URL: process.env.REACT_APP_API_URL,
    SSE_URL: process.env.REACT_APP_API_URL,
    PAGE_CONFIG_URL:process.env.REACT_APP_PAGE_CONFIG_URL,

    dev_url: process.env.REACT_APP_DOMAIN_URL,
    qa_url: process.env.REACT_APP_DOMAIN_URL,
    uat_url: process.env.REACT_APP_DOMAIN_URL,

    /*  OCR URL  */
    Chatbot_URL: process.env.REACT_APP_Chatbot_URL,

}