import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material';
import Text from '../../../components/customText/Text';
import { capitalizeAndAddSpace } from '../../../utils/utils';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';

function ClientDetails(props) {

    const { HtmlTooltip } = props;

    const placementID = props.id ? props.id : "";
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        client_name: "",
        client_id: "",
        client_reference_id: "",
        client_contact_id: '',
        client_contact_name: "",
        end_client_name: "",
        end_client_id: "",
        end_client_reference_id: "",
        end_client_contact_id: '',
        end_client_contact_name: ""
    })

    useEffect(() => {
        getPlacementClientData(placementID)// eslint-disable-next-line
    }, []);

    const getPlacementClientData = (id) => {
        setLoading(true);
        PlacementApi.getPlacementIndexConsultant("client-details", id).then((res) => {
            if (res.data.statusCode === 1003) {
                setLoading(false);
                setState({ ...res.data.data.client_details })
                props.setclientDetails({ ...res.data.data.client_details })
            } else {
                setLoading(false);
            }
        })
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={5} p={0.1} mt={3}>
                <Box mb={2} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "65vh", overflow: "auto !important" }}>
                    <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 2 }} spacing={2} alignItems={"center"} >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Text largeBlue>Client Details</Text>
                        </Grid>
                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Client Name</Text>
                                        <Text mediumBlack14 pt={0.8}>
                                            {state.client_name === "" ? "--" : capitalizeAndAddSpace(state.client_name).length > 16 ?
                                                <HtmlTooltip title={capitalizeAndAddSpace(state.client_name)} placement="right" arrow>
                                                    {capitalizeAndAddSpace(state.client_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_name).length > 16 ? "..." : "")}
                                                </HtmlTooltip>
                                                :
                                                capitalizeAndAddSpace(state.client_name)
                                            }
                                        </Text>
                                    </>
                            }
                        </Grid>
                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Client Id</Text>
                                        <Text mediumBlack14 pt={0.8}>{state.client_reference_id ? state.client_reference_id : "--"}</Text>
                                    </>
                            }
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Contact</Text>
                                        <Text mediumBlack14 pt={0.8}>
                                            {state.client_contact_name === "" ? "--" : capitalizeAndAddSpace(state.client_contact_name).length > 16 ?
                                                <HtmlTooltip title={capitalizeAndAddSpace(state.client_contact_name)} placement="right" arrow>
                                                    {capitalizeAndAddSpace(state.client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_contact_name).length > 16 ? "..." : "")}
                                                </HtmlTooltip>
                                                :
                                                capitalizeAndAddSpace(state.client_contact_name)
                                            }
                                        </Text>
                                    </>
                            }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
                            <Text largeBlue>End Client Details</Text>
                        </Grid>
                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Client Name</Text>
                                        <Text mediumBlack14 pt={0.8}>
                                            {state.end_client_name === "" ? "--" : capitalizeAndAddSpace(state.end_client_name).length > 16 ?
                                                <HtmlTooltip title={capitalizeAndAddSpace(state.end_client_name)} placement="right" arrow>
                                                    {capitalizeAndAddSpace(state.end_client_name).slice(0, 16) + (capitalizeAndAddSpace(state.end_client_name).length > 16 ? "..." : "")}
                                                </HtmlTooltip>
                                                :
                                                capitalizeAndAddSpace(state.end_client_name)
                                            }
                                        </Text>
                                    </>
                            }
                        </Grid>
                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Client Id</Text>
                                        <Text mediumBlack14 pt={0.8}>{state.end_client_reference_id ? state.end_client_reference_id : "--"}</Text>
                                    </>
                            }
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                            {
                                loading ?
                                    <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
                                    :
                                    <>
                                        <Text mediumGreyHeader1>Contact</Text>
                                        <Text mediumBlack14 pt={0.8}>
                                            {state.end_client_contact_name === "" ? "--" : capitalizeAndAddSpace(state.end_client_contact_name).length > 16 ?
                                                <HtmlTooltip title={capitalizeAndAddSpace(state.end_client_contact_name)} placement="right" arrow>
                                                    {capitalizeAndAddSpace(state.end_client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state.end_client_contact_name).length > 16 ? "..." : "")}
                                                </HtmlTooltip>
                                                :
                                                capitalizeAndAddSpace(state.end_client_contact_name)
                                            }
                                        </Text>
                                    </>
                            }
                        </Grid>
                    </Grid>
                </Box>

            </Grid>
        </Grid>
    )
}

export default ClientDetails