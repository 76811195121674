import APIURL from "../../../config/development";
import BaseApi from "../../BaseApi";
import LocalStorage from "../../../utils/LocalStorage";

class PlacementApi {
    billingDetailsStore(data, token) {
        return BaseApi.postWithToken(APIURL.API_URL + `placement/billing/store`, data, token)
    }
    placementsIndex(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `placement/index?request_id=${LocalStorage.uid()}&placement_id=${id}`, LocalStorage.getAccessToken());
    }

    getPlacementFilterDetails(data) {
        const { status_type, from_date, to_date } = data;
        return BaseApi.getWithParams(APIURL.API_URL + `/placement/status-analytics/${status_type}?request_id=${LocalStorage.uid()}&from_date=${from_date}&to_date=${to_date}`, LocalStorage.getAccessToken())
    }
    // Latest code
    getFilterPlacement(params) {
        return BaseApi.getWithParams(APIURL.API_URL + `/placement/status-analytics/${params.slug}?request_id=${LocalStorage.uid()}&from_date=${params.from_date}&to_date=${params.to_date}`, LocalStorage.getAccessToken())
    }


    getPlacementTablelisting(data) {
        const { employee_id, reference_id, client_id, page, limit, status_type, value, search, from_date, to_date } = data;
        return BaseApi.getWithParams(APIURL.API_URL + `placement/listing?request_id=${LocalStorage.uid()}&employee_id=${employee_id}&reference_id=${reference_id}&client_id=${client_id}&page=${page}&limit=${limit}&status_type=${status_type}&value=${value}&search=${search}&from_date=${from_date}&to_date=${to_date}`, LocalStorage.getAccessToken());
    }

    getPayroll(id, value) {
        return BaseApi.getWithParams(APIURL.API_URL + `employee/salary-per-payroll-amount?request_id=${LocalStorage.uid()}&employee_id=${id}&pay_value=${value}`, LocalStorage.getAccessToken())
    }

    placementClientStore(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `placement/client/store`, data, LocalStorage.getAccessToken());
    }
    placementEmpPay(data) {
        return BaseApi.putWithToken(APIURL.API_URL + `placement/employee-pay`, data, LocalStorage.getAccessToken())
    }
    payRate(data) {
        return BaseApi.putWithToken(APIURL.API_URL + `placement/employee-pay`, data, LocalStorage.getAccessToken())
    }
    defaultCheck(data) {
        return BaseApi.putWithToken(APIURL.API_URL + `placement/employee/default-pay`, data, LocalStorage.getAccessToken())
    }

    exportPlacement(data) {
        return BaseApi.postWithToken(APIURL.API_URL + 'export/placements', data, LocalStorage.getAccessToken())
    }
    dashboardGraph(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `placement/dashboard-analytics`, data, LocalStorage.getAccessToken())
    }




    // New Ui Changes APIS

    // latest code
    getPlacementListing(param) {
        param.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.placement_URL + 'placements', param, LocalStorage.getAccessToken())
    }
    getPlacementListingConsultant(param) {
        param.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.placement_URL + 'consultant/placements', param, LocalStorage.getAccessToken())
    }
    placementEnd(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/end`, data, LocalStorage.getAccessToken())
    }
    placementActivityLogs(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/activity/listing`, data, LocalStorage.getAccessToken());
    }
    downLoadPlacementExcel() {
        return BaseApi.getWithParams(APIURL.placement_URL + `placement/export?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    storePlacement(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/store`, data, LocalStorage.getAccessToken())
    }
    getPlacementIndex(segement, id) {
        // segement: ['client-details','placement-details','payrate-details', 'timesheet-details']
        return BaseApi.getWithParams(APIURL.placement_URL + `placement/${segement}/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    getOldPlacementPayrates(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/payrate/listing`,data, LocalStorage.getAccessToken());
    }
    getPlacementIndexConsultant(segement, id) {
        // segement: ['client-details','placement-details','payrate-details', 'timesheet-details']
        return BaseApi.getWithParams(APIURL.placement_URL + `consultant/placement/${segement}/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    clientUpdate(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/client`, data, LocalStorage.getAccessToken())
    }
    billUpdate(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/billing`, data, LocalStorage.getAccessToken())
    }
    placementDetailsUpdate(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/details`, data, LocalStorage.getAccessToken())
    }
    payRateConfigUpdate(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/pay-config`, data, LocalStorage.getAccessToken())
    }
    timeSheetConfigUpdate(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/timesheet`, data, LocalStorage.getAccessToken())
    }

    downloadPlacementActivity(id) {
        return BaseApi.getWithParams(APIURL.placement_URL + `placement/activity/export?id=${id}&request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    employeePlacements(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/dropdown`, data, LocalStorage.getAccessToken())
    }
    employeePlacementsConsultant(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `consultant/placement/dropdown`, data, LocalStorage.getAccessToken())
    }

    getDefaultHours() {
        return BaseApi.getWithParams(APIURL.org_URL + `organization/default-hours/index?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    defaultTSgetApi() {
        return BaseApi.getWithParams(APIURL.org_URL + `org_default_config/index?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    defaultTsUpdate(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `org_default_config/store-update`, data, LocalStorage.getAccessToken());
    }

    defaultHoursUpdate(id, data) {
        return BaseApi.putWithToken(APIURL.org_URL + `organization/default-hours/update/${id}`, data, LocalStorage.getAccessToken());
    }

    getExpenseListing(param) {
        param.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.placement_URL + 'expense-management/listing', param, LocalStorage.getAccessToken())
    }

    getCarDetailListing(id, emp_id) {
        return BaseApi.getWithParams(APIURL.org_URL + `expense-management/cards?request_id=${LocalStorage.uid()}&entity_type=${id}&employee_id=${emp_id}`, LocalStorage.getAccessToken())
    }

    zohoIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/placement/zoho_books`, data, LocalStorage.getAccessToken())
    }

    quickbooksIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/placement/quick_books`, data, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new PlacementApi();
