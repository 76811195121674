import { Box, Divider, Grid } from "@mui/material"
import InvoiceThemeStyles from './InvoiceThemeStyles';
import LocalStorage from "../../../../../utils/LocalStorage";
import Text from "../../../../../components/customText/Text";
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { getCurrencySymbol } from "../../../../../utils/utils";
import statusInvoice from '../../../../../assets/images/status_invoice.png'

export default function GlobalTemplate(props) {
    const classes1 = InvoiceThemeStyles()
    const {color, color1} = props
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: color,
            padding: '8px',
            borderRight: '1px solid #E2E5E6'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: '8px',
            borderRight: '1px solid #E2E5E6', // Apply borderRight here
        },
        "&:not(:last-child)": { // Ensure it applies to all cells except the last one
            borderRight: '1px solid #E2E5E6',
        },
    }));
    const rows = [
        createData(1, 'Rahul Raj', 80, getCurrencySymbol() + 1200, getCurrencySymbol() + 1500, getCurrencySymbol() + 1200, getCurrencySymbol() + 1500),

    ];
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }
    const StyledTableRow = styled(TableRow)(({ theme }) => ({

        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const cardTitle = [
        {
            name: "Sub Total",
            amount: `${getCurrencySymbol()} 5000.00`
        },
        {
            name: "Discount",
            amount: `${getCurrencySymbol()} 5000.00`
        },
        {
            name: "Adjustments",
            amount: `${getCurrencySymbol()} 5000.00`
        },
        {
            name: "Total",
            amount: `${getCurrencySymbol()} 5000.00`
        },
        {
            name: "Balance Due",
            amount: `${getCurrencySymbol()} 5000.00`
        }
    ]
    return (
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className={classes1.activeItemBox} >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} p={3} pr={0}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src={LocalStorage.getUserData().logo_url} alt="Logo" style={{ height: "54px" }} />
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <img src={statusInvoice} alt="Logo" width={'100px'} />
                    </div>
                </div>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} p={3} >
                <Box sx={{ backgroundColor: color1, width: '100%', padding: '15px', borderRadius: '3.5px' }}>
                    <Grid container p={1}>
                        <Grid item xl={8} lg={8} md={8} sm={6} xs={6} >
                            <Text sx={{ color: `${color} !important`, fontSize: '22px', fontWeight: '600 !important', fontFamily: 'Quicksand', paddingBottom: '5px' }}>Invoice</Text>
                            <Text black18px>INV - 123456</Text>
                        </Grid>
                        <Grid item container xl={4} lg={4} md={4} sm={6} xs={6} display={'flex'} flexDirection={'column'} justifyContent={"flex-end"}>
                            <Text black12 sx={{ fontWeight: `${400} !important`, textAlign: 'right !important' }}>Invoice Amount</Text>
                            <Text sx={{ color: `${color} !important`, fontSize: '22px', fontWeight: '600 !important', paddingTop: '5px', paddingBottom: '5px', fontFamily: 'Quicksand', textAlign: 'right !important' }}>{getCurrencySymbol()} 5000.00</Text>
                            <Text black14 sx={{ textAlign: 'right !important' }}>Due Date : {LocalStorage.getDateFormat()}</Text>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} p={3}>
                <Grid item xl={8} lg={7} md={6} sm={6} xs={6} >
                    <Text smallBoldBlack>From: Organization Name</Text>
                </Grid>
                <Grid item xl={4} lg={5} md={6} sm={6} xs={6} container justifyContent={'flex-end'} >
                    <Text smallBoldBlack>To: Organization Name</Text>
                </Grid>
                <Grid item lg={9} md={6} sm={6} xs={6} container direction={'column'} pt={1} >
                    <Text mediumGreyHeader>Organization Address</Text>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6} container justifyContent={'flex-end'} pt={1} >
                    <Text mediumGreyHeader>Organization Address</Text>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Divider />
                </Grid>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} p={3} pt={0} pb={0} pl={5} justifyContent={'center'} >
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Text smallBoldBlack>Invoice Date:  {LocalStorage.getDateFormat()} | Terms: 00 Days | Due Date: {LocalStorage.getDateFormat()}</Text>
                    </div>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Divider />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={3}>
                    <TableContainer component={Paper} sx={{ overflow: { xl: 'hidden', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto' } }}>
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                            <TableHead  >
                                <TableRow sx={{ backgroundColor: color }} >
                                    <StyledTableCell sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }} >S.no</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>Details</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>Hours</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>Rate</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>OT Hours</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>OT Rate</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: '12px', color: 'white', fontWeight: '600', fontFamily: 'Quicksand' }}>Amount</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.calories}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.fat}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.carbs}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.protein}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.protein}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: '12px !important', color: '#171717', fontWeight: '600', fontFamily: 'Quicksand' }} style={{ borderRight: '1px solid #E2E5E6' }}>{row.protein}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {
                    cardTitle.map((item, index) => (
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} mt={index == 0 ? 6 : 0}>
                            <Grid item xl={10} lg={9} md={8} sm={6} xs={6} pt={1} display={"flex"} justifyContent={"end"}> <Text errorText sx={{ color: "#171717 !important", fontWeight: '400 !important' }}>{item.name}</Text></Grid>
                            <Grid item xl={2} lg={3} md={4} sm={6} xs={6} pt={1} display={"flex"} justifyContent={"end"}><Text errorText sx={{ color: "#262626 !important", fontWeight: '600 !important' }}>{item.amount}</Text></Grid>
                            {
                                item.name == 'Total' &&
                                <Grid item lg={8}> </Grid>
                            }
                            {
                                item.name == 'Total' &&
                                <Grid item lg={4} container justifyContent={'flex-end'}>
                                    <Divider sx={{ my: 1, width: '100%' }} />
                                </Grid>
                            }
                        </Grid>
                    ))
                }
                <Grid item lg={12} md={12} sm={12} xs={12} mt={6} container direction={'column'} >
                    <Text smallBlack sx={{ color: "#262626 !important", fontWeight: '700 !important' }}>Documents:</Text>
                    <Text smallBlack400 sx={{ color: "#0C75EB !important", textDecoration: 'underline' }}>attachment.pdf</Text>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} container direction={'column'} >
                    <Text smallBlack sx={{ color: "#262626 !important", fontWeight: '700 !important' }}>Terms & Conditions</Text>
                    <Text smallBlack400 sx={{ color: "#525252 !important" }}>Please do acknowledge the receipt of Invoice.</Text>
                </Grid>
            </Grid>


        </Grid>
    )
}