import React, { useState } from 'react';
import { Box, Grid, } from '@mui/material';
import { addErrorMsg, addLoader, removeLoader } from '../../../../utils/utils';
import Button from '../../../../components/customButton/Button';
import Text from '../../../../components/customText/Text';
import minusCircle from '../../../../assets/svg/clients/minus_circle_icon.svg';
import Input from '../../../../components/input/Input';
import FileInput from '../../../../components/muiFileInput/FileInput';
import LocalStorage from '../../../../utils/LocalStorage';
import CommonApi from '../../../../apis/CommonApi';
import { validate_document_name, validate_emptyField } from '../../../../components/Validation';
import ReusableDelete from '../../../../components/reuablePopup/ReusableDelete';

export default function AddDocuments(props) {

    const { documentsState, setDocumentsState, documentsError, setDocumentsError } = props;

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);


    const addNewDocument = (action, index) => {
        let newDocument = {
            new_document_id: "",
            document_name: "",
            file_name: "",
            document_url: "",
            id: '',
        };
        if (action === "Add") {
            setDocumentsState((prev) => ({ ...prev, documents: [...prev.documents, newDocument], }));
        } else if (action === "Remove") {
            const updatedDocuments = documentsState.documents.filter((_, i) => i !== index);
            const documentId = documentsState.documents[index]?.id;
            if (documentId !== "" && documentId !== null && documentId !== undefined) {
                const updatedDeleteDocumentIds = [...documentsState.delete_document_ids, documentId];
                setDocumentsState({ ...documentsState, documents: updatedDocuments, delete_document_ids: updatedDeleteDocumentIds });
            } else {
                setDocumentsState({ ...documentsState, documents: updatedDocuments });
            }
            let errorsus = {}; let err = [];
            updatedDocuments.map((value, ind) => {
                errorsus = {};
                errorsus.document_name = (value.document_url !== "" && value.document_name === "") ? validate_emptyField(value.document_name) : validate_document_name(value.document_name);
                errorsus.new_document_id = value.document_name !== "" ? validate_emptyField(value.document_url) : '';
                return err.push(errorsus);
            });
            setDocumentsError(err);
        }
    };

    const deleteDocuments = (index) => {
        const updatedDocuments = [...documentsState.documents];
        updatedDocuments[index].new_document_id = "";
        updatedDocuments[index].file_name = "";
        updatedDocuments[index].document_url = "";
        if (documentsState.documents[index]?.id !== "") {
            const documentId = updatedDocuments[index]?.id
            const updatedDeleteDocumentIds = [...documentsState.delete_document_ids, documentId];
            updatedDocuments[index]['id'] = "";
            setDocumentsState({
                ...documentsState,
                documents: updatedDocuments,
                delete_document_ids: updatedDeleteDocumentIds,
            });
        }
        setDeleteOpen(false);
        setDeleteIndex(null);
        const event = { target: { name: "new_document_id", value: '' } }
        handleValidateDocuments(event, index)
    };

    const handleDocumentsChange = (e, index) => {
        documentsState.documents[index][e.target.name] = e.target.value;
        setDocumentsState({ ...documentsState });
        handleValidateDocuments(e, index)
    };

    const handleValidateDocuments = (e, index) => {
        const input = e.target;

        setDocumentsError((prevError) => {
            // Ensure prevError is an array
            let updatedErrors = Array.isArray(prevError) ? [...prevError] : [];

            // Initialize the error object for this index if it doesn't exist
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }

            // Validate input based on its name or tag
            switch (input.name || input.tagName) {
                case "document_name":
                    updatedErrors[index].document_name = (documentsState.documents[index].document_url !== "" && input.value === "") ? validate_emptyField(input.value) : validate_document_name(input.value);
                    if (documentsState.documents[index].document_url === "" && input.value === "") {
                        updatedErrors[index].new_document_id = '';
                    }
                    break;
                case "new_document_id":
                    updatedErrors[index].new_document_id = (documentsState.documents[index].document_name === "" && input.value === "") ? "" : validate_emptyField(input.value);
                    if (documentsState.documents[index].document_name === "" && input.value === "") {
                        updatedErrors[index].document_name = '';
                    }
                    break;
                default:
                    break;
            }

            return updatedErrors; // Return the updated errors array
        });
    };


    const uploadDocument = (e, index) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'pdf', 'jpeg'].includes(r))) {
            if (Number(((e.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("company-document", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            const updateddocument = { ...documentsState };

                            // Update the specific document in the nested array
                            updateddocument.documents[index] = {
                                ...updateddocument.documents[index],
                                id: "",
                                new_document_id: res.data?.data?.id,
                                // document_name: updateddocument.documents[index].document_name == "" ? res.data?.data?.document_name : updateddocument.documents[index].document_name,
                                file_name: res.data?.data?.document_name,
                                document_url: res.data?.data?.document_url,
                            };

                            // Update the state with the modified copy
                            setDocumentsState(updateddocument);
                            const event = {
                                target: {
                                    name: 'new_document_id',
                                    value: res.data?.data?.id
                                }
                            }
                            handleValidateDocuments(event, index)
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPEG or PDF format only.");
        }
    }


    return (
        <div>
            <Grid item container spacing={1} lg={12} md={12} sm={12} xs={12} alignItems={"center"}>
                <Box px={2} sx={{
                    minHeight: documentsState?.documents.length > 4 ? '43vh' : 'fit-content',
                    maxHeight: documentsState?.documents.length > 4 ? '43vh' : 'fit-content',
                    width: '100%',
                    overflowY: documentsState?.documents.length > 4 ? 'auto' : 'visible',
                }}
                >
                    {
                        documentsState?.documents?.map((item, index) => (
                            <Grid container spacing={2} mt={index !== 0 ? 1 : 0} alignItems={"center"}>
                                <Grid item lg={documentsState.documents.length > 1 > 0 ? 5.5 : 6} md={6} sm={6} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'document_name',
                                            value: item?.document_name,
                                            inputProps: { maxLength: 50 }
                                        }}
                                        handleChange={(e) => handleDocumentsChange(e, index)}
                                        clientInput
                                        labelText='Document Name'
                                        error={documentsError.length > 0 && documentsError[index] && documentsError[index].document_name}
                                    />
                                    {documentsError.length > 0 && <Box sx={{ height: '0px' }}>
                                        <Text errorText>{documentsError[index] && documentsError[index].document_name && documentsError[index].document_name}</Text>
                                    </Box>}
                                </Grid>
                                <Grid item lg={documentsState.documents.length > 1 > 0 ? 6.5 : 6} md={6} sm={6} xs={12}>
                                    <Box width={"100%"} display={"flex"} gap={1.5} alignItems={"center"}>
                                        <Box width={"100%"}>
                                            <FileInput
                                                name='Upload_document'
                                                FileName={item?.file_name ? item?.file_name : ''}
                                                handleChange={(e) => uploadDocument(e, index)}
                                                label='Upload Document'
                                                handleDelete={() => { setDeleteOpen(true); setDeleteIndex(index) }}
                                                actionState={item?.file_name ? 1 : ''}
                                                documentUrl={item?.document_url ? item?.document_url : ''}
                                                state={item}
                                                error={documentsError.length > 0 && documentsError[index] && documentsError[index].new_document_id && documentsError[index].new_document_id}
                                            />
                                            {documentsError.length > 0 && <Box sx={{ height: '0px' }}>
                                                <Text errorText>{documentsError[index] && documentsError[index].new_document_id && documentsError[index].new_document_id}</Text>
                                            </Box>
                                            }
                                        </Box>
                                        {documentsState.documents.length > 1 && (
                                            <img src={minusCircle} alt="minus" style={{ cursor: 'pointer', marginRight: "-16px" }} onClick={() => addNewDocument('Remove', index)} />
                                        )}
                                    </Box>
                                </Grid>

                            </Grid>
                        ))
                    }
                </Box>
                {
                    documentsState.documents.length <= 9 &&
                    <Grid item lg={12} md={12} sm={12} xs={12} m={documentsState.length > 1 ? '25px 30px 0px 0px !important' : '25px 0px 0px 0px !important'}>
                        <Button lightBlue onClick={() => addNewDocument('Add')}>Add New Document</Button>
                    </Grid>
                }
            </Grid>
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDocuments(deleteIndex) }} />}
        </div>

    );
}