import React, { useEffect, useState, } from "react";
import { Box, Grid, Breadcrumbs, } from "@mui/material";
import TimeSheetStyles from "../timesheets/newTimeSheets/TimeSheetStyles";
import LoadingButton from "../../../components/customButton/LoadingButton";
import SideHistory from "./SideHistory";
import { useRef } from "react";
import InvoiceVoice from "./Invoice_navbar";
import deletePopupIcon from "../../../assets/svg/placementPop.svg";
import Text from "../../../components/customText/Text";
import ReusablePopup from "../../../components/reuablePopup/ReusablePopup";
import Button from '../../../components/customButton/Button';
import invoiceApprove from '../../../assets/svg/invoice_approve_popup.svg';
import LocalStorage from "../../../utils/LocalStorage";
import InvoiceHistoryCilentAPI from "../../../apis/admin/invoice/InvoiceHistoryCilentAPI";
import Input from "../../../components/input/Input";
import { addErrorMsg, addSuccessMsg } from "../../../utils/utils";
import { isValid, validate_emptyField } from "../../../components/Validation";
import { ErrorMessages } from "../../../utils/ErrorMessages";
import { useLocation, useNavigate } from "react-router-dom";


function InvoiceHistory() {
    const location = useLocation()
    const params = new URL(window.location.href).searchParams;
    const getId = params.get('id')
    const client_location = params.get('client_id')
    const id = getId ? getId : LocalStorage.getRedirectedModule() == 'invoice > view' || LocalStorage.getRedirectedModule() == 'invoice > invoice_details' ? LocalStorage.getNotificationId() : location?.state?.invoiceId;
    const client_id = client_location ? client_location : LocalStorage.getRedirectedModule() == 'invoice > view' || LocalStorage.getRedirectedModule() == 'invoice > invoice_details' ? LocalStorage.getClientID() : location?.state?.companiesId
    const classes = TimeSheetStyles();
    const scrollBox = useRef(null);
    const [buttonColour, setButtonColour] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [cilentSelectedValue, setCilentSelectedValue] = useState("");
    const [allSelectedValue, setAllSelectedValue] = useState("");
    const [cilentHistory, setCilentHistory] = useState([])
    const [allHistory, setAllHistory] = useState([])
    const [cilentPagination, setCilentPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );
    const [allPagination, setAllPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );
    const [cilentFilterData, setCilentFilterData] = useState({
        limit: 10,
        page: 1
    });
    const [allFilterData, setAllFilterData] = useState({
        limit: 10,
        page: 1
    });
    const [reasonForRejection, setReasonForRejection] = useState("");
    const [error, setError] = useState({});
    const [keyStatus, setkeyStatus] = useState({});
    const [selectInvoiceId, setSelectInvoiceId] = useState('');
    const [approveOpen, setApproveOpen] = useState(false);
    const navigate = useNavigate();

    const childRef = useRef();

    const handleFetchFromParent = () => {
        if (childRef.current) {
            childRef.current.invoicePdf(); // Trigger the child's API call
        }
    };


    const handleFetchFromParent2 = () => {
        if (childRef.current) {
            childRef.current.invoiceSendRemainder(); // Trigger the child's API call
        }
    };


    useEffect(() => {
        cilentHistoryDetails();
        // eslint-disable-next-line 
    }, [cilentSelectedValue]);

    useEffect(() => {
        allHistoryDetails();
        // eslint-disable-next-line 
    }, [allSelectedValue]);

    const loadMoreAll = () => {
        allFilterData.limit = Number(allFilterData.limit) + 10;
        setAllFilterData({ ...allFilterData });
        allHistoryDetails();
    }

    const loadMoreCilent = () => {
        cilentFilterData.limit = Number(cilentFilterData.limit) + 10;
        setCilentFilterData({ ...cilentFilterData });
        cilentHistoryDetails();
    }


    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;

            if (scrollTop + clientHeight >= scrollHeight - 5) {

                buttonColour
                    ?
                    (Number(allPagination.total) >= allFilterData.limit && loadMoreAll())
                    :
                    (Number(cilentPagination.total) >= cilentFilterData.limit && loadMoreCilent())
            }
        }
    };

    const handleClientHistoryClick = () => {
        setAllSelectedValue('');
        setButtonColour(false);
        setAllFilterData((prev) => ({
            ...prev,
            limit: 10,
        }));
        cilentHistoryDetails();
    };

    const handleAllClick = () => {
        setCilentSelectedValue('');
        setButtonColour(true);
        setCilentFilterData((prev) => ({
            ...prev,
            limit: 10,
        }));
        allHistoryDetails();
    };

    const handleDeletePopup = () => {
        setDeleteOpen(true);
    }


    const handleApprovePopup = (status, button) => {
        approveStatus(status, button)
    }



    const cilentHistoryDetails = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            companies: [selectInvoiceId?.company_id || client_id],
            status: cilentSelectedValue,
            limit: cilentFilterData?.limit,
            page: 1
        }
        InvoiceHistoryCilentAPI.cilentHistory(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setCilentHistory(res.data.data);
                setCilentPagination(res.data.pagination);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }
    const allHistoryDetails = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            companies: [],
            status: allSelectedValue,
            limit: allFilterData?.limit,
            page: 1
        }
        InvoiceHistoryCilentAPI.cilentHistory(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setAllHistory(res.data.data);
                setAllPagination(res.data.pagination);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }



    const approveStatus = (status, button) => {
        var obj = {
            request_id: LocalStorage.uid(),
            id: selectInvoiceId?.id || id, //Invoice ID
            status: status,
        }
        InvoiceHistoryCilentAPI.invoiceApproveAndReject(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                if (button == 'Approve') {
                    setApproveOpen(true);
                } else if (button == "Approve & Save") {
                    addSuccessMsg(res.data.message);
                    allHistoryDetails();
                    cilentHistoryDetails();
                    handleFetchFromParent();
                } else {
                    // handleSendRemainder(true);
                    if (keyStatus?.send_invoice_email) {
                        handleFetchFromParent2();
                    } else {
                        allHistoryDetails();
                        cilentHistoryDetails();
                        handleFetchFromParent();
                    }
                }
            } else {
                setApproveOpen(false);
                addErrorMsg(res.data.message)
            }
        })
    }


    const rejectStatus = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            id: selectInvoiceId?.id || id, //Invoice ID
            status: 'Rejected',
            reason_for_rejection: reasonForRejection
        }
        InvoiceHistoryCilentAPI.invoiceApproveAndReject(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                allHistoryDetails();
                cilentHistoryDetails();
                setDeleteOpen(false);
                handleFetchFromParent();
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const rejectValidation = () => {
        let errors = {};
        errors.reasonForRejection = validate_emptyField(reasonForRejection);
        return errors;
    };


    const changeHandler = (e) => {
        const { value } = e.target;
        setReasonForRejection(value);
        if (value === '') {
            setError({ ...error, reasonForRejection: ErrorMessages.fieldRequired })
        } else {
            setError({ ...error, reasonForRejection: '' })
        }
    };

    const handleSubmit = () => {
        let errors = rejectValidation();
        if (isValid(errors)) {
            rejectStatus()
        } else {
            let err = errors;
            setError(err);
        }
    }

    const handleCancel = () => {
        setReasonForRejection("");
        setError({ ...error, reasonForRejection: '' })
        setDeleteOpen(false)
    }

    const handleKeyStatus = (status) => {
        setkeyStatus(status)
    }

    const handleSelectInvoiceId = (invoiceId) => {
        setSelectInvoiceId(invoiceId)
    }




    const getApproveButtonLabel = () => {
        if (keyStatus?.status == "Pending Approval" && LocalStorage.getUserData().super_admin) {
            if (keyStatus?.is_last_stage) {
                return keyStatus?.send_invoice_email ? 'Approve & send' : 'Approve & Save';
            } else if (keyStatus?.is_last_stage == false) {
                return 'Approve';
            }
        } else {
            if (keyStatus?.status == "Pending Approval" && keyStatus?.is_last_stage) {
                return keyStatus?.send_invoice_email ? 'Approve & send' : 'Approve & Save';
            } else if (keyStatus?.is_last_stage == false) {
                return 'Approve';
            }
        }
    };
    const breadCrumbsHandler = () => {
        navigate('/invoice')
    }

    const handleDone = () => {
        allHistoryDetails();
        cilentHistoryDetails();
        setApproveOpen(false);
        handleFetchFromParent()
    }

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} pl={2} sx={{
            "@media (min-width: 968px)": {
                paddingLeft: '90px',
            },
            "@media (min-width: 1025px)": {
                paddingLeft: '130px',
            },
        }}>
            <Box display='flex' justifyContent='space-between'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Text onClick={breadCrumbsHandler} className={classes.breadcrumbsLink}>Invoices</Text>
                    <Text className={classes.breadcrumbsName}>
                        Invoice History
                    </Text>
                </Breadcrumbs>
            </Box>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'center'} pt={2} alignItems='flex-start' sx={{
                height: (keyStatus?.status == "Pending Approval" && (LocalStorage.getUserData().super_admin || keyStatus?.can_approve)) ? '75vh' : '83vh',
                overflow: {
                    lg: 'hidden',
                    md: 'auto',
                    sm: 'auto',
                    xs: 'auto',
                },
            }}>
                <Grid item container lg={8} md={8} sm={12} xs={12} >
                    <InvoiceVoice handleKeyStatus={handleKeyStatus} selectInvoiceId={selectInvoiceId?.id || id} cilentHistoryDetails={cilentHistoryDetails} allHistoryDetails={allHistoryDetails} ref={childRef} />
                </Grid>
                <Grid item container lg={4} md={4} sm={12} xs={12}>
                    <SideHistory handleScroll={handleScroll} scrollBox={scrollBox} cilentHistory={cilentHistory} handleClientHistoryClick={handleClientHistoryClick} handleAllClick={handleAllClick} buttonColour={buttonColour} setCilentSelectedValue={setCilentSelectedValue} setAllSelectedValue={setAllSelectedValue} allHistory={allHistory} keyStatus={keyStatus} handleSelectInvoiceId={handleSelectInvoiceId} selectInvoiceId={selectInvoiceId?.id || id} />
                </Grid>

            </Grid>
            {keyStatus?.status == "Pending Approval" && LocalStorage.getUserData().super_admin ? (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0 2px 24px rgba(145, 158, 171, 0.12)",
                        padding: "16px",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "16px",
                        paddingRight: "70px",
                    }}
                >
                    <Button
                        sx={{
                            color: "#F70D0D !important",
                            width: "106px !important",
                            height: "34px !important",
                            borderRadius: "8px !important",
                            border: "1px solid #F70D0D !important",
                            textTransform: "none !important",
                            fontSize: "15px !important",
                            fontFamily: "Quicksand !important",
                            fontWeight: "400 !important",
                        }}
                        onClick={() => handleDeletePopup()}
                    >
                        Reject
                    </Button>
                    <LoadingButton
                        saveNcontinue
                        sx={{
                            height: "36px !important",
                        }}
                        onClick={() => handleApprovePopup("Approved", getApproveButtonLabel())}
                    >
                        {getApproveButtonLabel()}
                    </LoadingButton>
                </Box>
            ) : (
                keyStatus?.status == "Pending Approval" && keyStatus?.can_approve && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0 2px 24px rgba(145, 158, 171, 0.12)",
                            padding: "16px",
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "16px",
                            paddingRight: "70px",
                        }}
                    >
                        <Button
                            sx={{
                                color: "#F70D0D !important",
                                width: "106px !important",
                                height: "34px !important",
                                borderRadius: "8px !important",
                                border: "1px solid #F70D0D !important",
                                textTransform: "none !important",
                                fontSize: "15px !important",
                                fontFamily: "Quicksand !important",
                                fontWeight: "400 !important",
                            }}
                            onClick={() => handleDeletePopup()}
                        >
                            Reject
                        </Button>
                        <LoadingButton
                            saveNcontinue
                            sx={{
                                height: "36px !important",
                            }}
                            onClick={() => handleApprovePopup("Approved", getApproveButtonLabel())}
                        >
                            {getApproveButtonLabel()}
                        </LoadingButton>
                    </Box>
                )
            )}
            <ReusablePopup iconHide white openPopup={deleteOpen} setOpenPopup={setDeleteOpen} fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={deletePopupIcon} alt="deletePopupIcon" />
                    </Box>
                    <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text popupHead1>
                            Are You Sure?
                        </Text>
                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                            Do you want to reject {keyStatus?.invoice_id}?
                        </Text>
                    </Box>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        multiline={true}
                        rows={3}
                        inputProps={{
                            name: 'reasonForRejection',
                            value: reasonForRejection,
                            inputProps: { maxLength: 255 }
                        }}
                        handleChange={changeHandler}
                        commentsField
                        labelText={'Add reason for rejection'}
                        placeholder={'Add reason'}
                        error={error.reasonForRejection}
                    />
                    {<Text red sx={{ textAlign: 'start' }}>{error.reasonForRejection}</Text>}
                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                        <Grid item lg={5} xs={6} textAlign='right'>
                            <Button onClick={handleCancel} blackBorder sx={{ fontSize: '13px !important' }}>Cancel</Button>
                        </Grid>
                        <Grid item lg={5} xs={6} textAlign='left'>
                            <LoadingButton onClick={handleSubmit} deleteButton sx={{ fontSize: '13px !important' }}>Yes, Reject</LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide white openPopup={approveOpen} setOpenPopup={setApproveOpen} fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={invoiceApprove} alt="invoiceApprove" />
                    </Box>
                    <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text popupHead1>
                            Invoice Approved
                        </Text>
                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                            {`${keyStatus?.invoice_id} is successfully approved and sent to further approval.`}
                        </Text>
                    </Box>
                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} >
                        <Button saveNcontinue className={classes.btnStyles} onClick={handleDone} >Done</Button>
                    </Grid>
                </Box>
            </ReusablePopup>

        </Grid>
    )
}

export default InvoiceHistory