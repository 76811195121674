import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, SwipeableDrawer, } from '@mui/material';
import Text from '../../../../../components/customText/Text';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import LocalStorage from '../../../../../utils/LocalStorage';
import { BlackToolTip } from '../../../../../utils/utils';
import Button from '../../../../../components/customButton/Button';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/ExpenseDeleteIcon.svg';
import offBoardingInfo from '../../../../../assets/employee/offBoarding_info.svg';
import ActivityLog from '../../../../../components/activityLog/ActivityLog';
import timeIcon from '../../../../../assets/svg/timeIcon.svg'
import { getFormattedLabel } from '../../../../../utils/utils';
import MaskDataView from '../../../../../components/input/MaskDataView';


export default function ViewGeneralDetails(props) {// eslint-disable-next-line
    const generalDetailsConfig = props.generalDetailsConfig?.basicDetails;
    const contactDetailsConfig = props.generalDetailsConfig?.contactDetails;
    const currentAddressDetailsConfig = props.generalDetailsConfig?.currentAddressDetails;
    const employmentDetailsConfig = props.generalDetailsConfig?.employmentDetails;
    const [employee, setEmployee] = useState(props.employee);
    const [clearPopup3, setclearPopup3] = useState(false);
    useEffect(() => {
        setEmployee(props.employee)
    }, [props])
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    const [activityLogDrawer, setActivityLogDrawer] = useState(false);
    const [current_balancePopup, setcurrent_balancePopup] = useState(false);


    return (
        <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            {employee.reason_for_offboarding ? <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} sx={{ backgroundColor: 'rgba(248, 232, 125, 0.2)', borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <Box pt={1}>
                        <img src={offBoardingInfo} alt='offBoardingInfo' />
                    </Box>
                    <Text offBoardingText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        {employee.reason_for_offboarding}
                    </Text>

                </Box>
            </Grid> : ''}
            <Grid item lg={11} md={11} sm={11} xs={10}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            {
                employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                <Grid item lg={1} md={1} sm={1} xs={2}>
                    <Box p={1} pr={0}>
                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => {
                            if (employee.pending_requests.general_details) {
                                setclearPopup3(true)
                            }
                            else { props.setEdit(true); LocalStorage.setEditForm('edit'); props.setformEdit(true) }
                        }} />
                    </Box>
                </Grid>
            }
            {generalDetailsConfig?.first_name?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.first_name)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.first_name && employee.first_name.length > 16 ?
                                    <BlackToolTip title={employee.first_name} placement="top" arrow>
                                        {employee.first_name.slice(0, 16) + (employee.first_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : employee.first_name
                            }

                        </Text>
                    </Box>
                </Grid>}
            {generalDetailsConfig?.middle_name?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.middle_name)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.middle_name && employee.middle_name.length > 16 ?
                                    <BlackToolTip title={employee.middle_name} placement="top" arrow>
                                        {employee.middle_name.slice(0, 16) + (employee.middle_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : employee.middle_name
                            }
                        </Text>
                    </Box>
                </Grid>}
            {generalDetailsConfig?.last_name?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.last_name)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.last_name && employee.last_name.length > 16 ?
                                    <BlackToolTip title={employee.last_name} placement="top" arrow>
                                        {employee.last_name.slice(0, 16) + (employee.last_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.last_name
                            }
                        </Text>
                    </Box>
                </Grid>}
            <Grid item lg={3} md={0} sm={0} xs={12}></Grid>
            {generalDetailsConfig?.dob?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.dob)}
                        <Text pt={1} mediumBlack14>
                            {employee.dob}
                        </Text>
                    </Box>
                </Grid>}
            {generalDetailsConfig?.gender?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.gender)}
                        <Text pt={1} mediumBlack14>
                            {employee.gender}
                        </Text>
                    </Box>
                </Grid>}
            {
                employee.gender == 'Prefer to self-describe' && generalDetailsConfig?.gender_description?.isVisible ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            {getFormattedLabel(generalDetailsConfig?.gender_description)}
                            <Text pt={1} mediumBlack14 sx={{ wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{employee.gender_description ? employee.gender_description : "-"}
                                {/* {
                                    employee.gender_description && employee.gender_description.length > 16 ?
                                        <BlackToolTip title={employee.gender_description} placement="top" arrow>
                                            {employee.gender_description.slice(0, 16) + (employee.gender_description.length > 16 ? "..." : "")}
                                        </BlackToolTip>
                                        : employee.gender_description
                                } */}
                            </Text>
                        </Box>
                    </Grid>
                    : ''
            }
            {generalDetailsConfig?.blood_group?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.blood_group)}
                        <Text pt={1} mediumBlack14>
                            {employee.blood_group ? employee.blood_group : '-'}
                        </Text>
                    </Box>
                </Grid>}
            {generalDetailsConfig?.marital_status?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(generalDetailsConfig?.marital_status)}
                        <Text pt={1} mediumBlack14>
                            {employee.marital_status}
                        </Text>
                    </Box>
                </Grid>}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            {contactDetailsConfig?.contact_number?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(contactDetailsConfig?.contact_number)}
                        <Text pt={1} mediumBlack14>
                            {employee.contact_number}

                        </Text>
                    </Box>
                </Grid>}
            {contactDetailsConfig?.alternate_contact_number?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(contactDetailsConfig?.alternate_contact_number)}
                        <Text pt={1} mediumBlack14>
                            {employee.alternate_contact_number ? employee.alternate_contact_number : '-'}
                        </Text>
                    </Box>
                </Grid>}
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            {contactDetailsConfig?.email_id?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(contactDetailsConfig?.email_id)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.email_id && employee.email_id.length > 16 ?
                                    <BlackToolTip title={employee.email_id} placement="top" arrow>
                                        {employee.email_id.slice(0, 16) + (employee.email_id.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.email_id
                            }
                        </Text>
                    </Box>
                </Grid>}
            {contactDetailsConfig?.alternate_email_id?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(contactDetailsConfig?.alternate_email_id)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.alternate_email_id && employee.alternate_email_id.length > 16 ?
                                    <BlackToolTip title={employee.alternate_email_id} placement="top" arrow>
                                        {employee.alternate_email_id.slice(0, 16) + (employee.alternate_email_id.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.alternate_email_id ? employee.alternate_email_id : '-'
                            }
                        </Text>
                    </Box>
                </Grid>}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Employee ID
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.reference_id}
                    </Text>
                </Box>
            </Grid>
            {employmentDetailsConfig?.date_of_joining?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.date_of_joining)}
                        <Text pt={1} mediumBlack14>
                            {employee.date_of_joining}
                        </Text>
                    </Box>
                </Grid>}
            {employmentDetailsConfig?.employment_type?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.employment_type)}
                        <Text pt={1} mediumBlack14>
                            {employee.employment_type}
                        </Text>
                    </Box>
                </Grid>}
            {
                employee.employment_type_id !== 1 && employmentDetailsConfig?.employment_type?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.employment_category)}
                        <Text pt={1} mediumBlack14>
                            {employee.employee_category}
                        </Text>
                    </Box>
                </Grid>
            }
            {
                employee.employment_type_id == 3 && employmentDetailsConfig?.vendor?.isVisible && <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.vendor)}
                        <Text pt={1} mediumBlack14>
                            {/* {employee.company_name} */}
                            {

                                employee.company_name && employee.company_name.length > 16 ?
                                    <BlackToolTip title={employee.company_name} placement="top" arrow>
                                        {employee.company_name.slice(0, 16) + (employee.company_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.company_name ? employee.company_name : '-'
                            }
                        </Text>
                    </Box>
                </Grid>
            }
            {
                employee.employment_type_id == 1 &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel>
                            Role
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.role_name}
                        </Text>
                    </Box>
                </Grid>
            }
            {employmentDetailsConfig?.ssn?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.ssn)}
                        <MaskDataView value={employee.ssn} />
                        {/* <Text pt={1} mediumBlack14>{eye.ssn ? employee.ssn : '-' '-'}</Text> */}
                    </Box>
                </Grid>
            }
            {employmentDetailsConfig?.is_us_citizen?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.is_us_citizen)}
                        <Text pt={1} mediumBlack14>
                            {employee.is_usc == 0 ? 'No' : 'Yes'}
                        </Text>
                    </Box>
                </Grid>}
            {employee.is_usc == 0 && employmentDetailsConfig?.visa_type?.isVisible ?
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.visa_type)}
                        <Text pt={1} mediumBlack14>
                            {employee.visa_type && employee.visa_type.length > 16 ?
                                <BlackToolTip title={employee.visa_type} placement="top" arrow>
                                    {employee.visa_type.slice(0, 16) + (employee.visa_type.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.visa_type ? employee.visa_type : '--'
                            }
                        </Text>
                    </Box>
                </Grid>
                : ''}
            {employmentDetailsConfig?.opening_balance?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.opening_balance)}
                        <Stack direction={'row'} gap={2}>
                            <Text pt={1} mediumBlack14>
                                {currency} {employee.opening_balance ? employee.opening_balance : 0}
                            </Text>
                            {props.employee.is_opening_balance_edit ? <Box pt={1} sx={{ cursor: 'pointer' }}>
                                <img src={timeIcon} alt='setting' onClick={() => { setActivityLogDrawer(true); setcurrent_balancePopup(false) }} />
                            </Box> : ''}

                        </Stack>

                    </Box>
                </Grid>}
            {
                props.employee.is_opening_balance_edit ? '' :
                    <Grid item lg={3} md={4} sm={6} xs={12}>

                        <Box p={1} pt={2}>
                            <Text mediumLabel>
                                Current Balance
                            </Text>
                            <Stack direction={'row'} gap={2}>
                                <Text pt={1} mediumBlack14>
                                    {currency} {employee.balance_amount ? employee.balance_amount : 0}
                                </Text>
                                <Box pt={1} sx={{ cursor: 'pointer' }}>
                                    <img src={timeIcon} alt='setting' onClick={() => { setActivityLogDrawer(true); setcurrent_balancePopup(true) }} />
                                </Box>
                            </Stack>

                        </Box>

                    </Grid>
            }

            {employee.employment_type_id != 3 && employee.enable_health_insurance ?
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel>
                            Health Insurance
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.enable_health_insurance ? 'Enabled' : '-'}
                        </Text>
                    </Box>
                </Grid> : ''
            }
            {employee.employment_type_id != 3 && employee.enable_health_insurance && employmentDetailsConfig?.insurance_amount?.isVisible ?
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.insurance_amount)}
                        <Text pt={1} mediumBlack14>
                            {currency} {employee.health_insurance_amount ? employee.health_insurance_amount : 0}
                        </Text>
                    </Box>
                </Grid>
                : ''}
            {
                ['H-1B', 'H-1B1', 'E3'].includes(employee.visa_type) && employmentDetailsConfig?.standard_wage?.isVisible ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            {getFormattedLabel(employmentDetailsConfig?.standard_wage)}
                            <Text pt={1} mediumBlack14>
                                {currency} {employee.standard_pay_amount ? employee.standard_pay_amount : 0}
                            </Text>
                        </Box>
                    </Grid>
                    : ''
            }
            {
                ['H-1B', 'H-1B1', 'E3'].includes(employee.visa_type) ?
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Text mediumLabel>
                                {employmentDetailsConfig?.make_standard_pay_as_salary?.label}
                            </Text>
                            <Text pt={1} mediumBlack14>
                                {employee.make_standard_pay_as_salary ? 'Enabled' : '-'}
                            </Text>
                        </Box>
                    </Grid>
                    : ''}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                    </Text>
                </Box>
            </Grid>
            {currentAddressDetailsConfig?.address_line_one?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(currentAddressDetailsConfig?.address_line_one)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.address_line_one && employee.address_line_one.length > 16 ?
                                    <BlackToolTip title={employee.address_line_one} placement="top" arrow>
                                        {employee.address_line_one.slice(0, 16) + (employee.address_line_one.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.address_line_one
                            }
                        </Text>
                    </Box>
                </Grid>}
            {currentAddressDetailsConfig?.address_line_two?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        {getFormattedLabel(currentAddressDetailsConfig?.address_line_two)}
                        <Text pt={1} mediumBlack14>
                            {
                                employee.address_line_two && employee.address_line_two.length > 16 ?
                                    <BlackToolTip title={employee.address_line_two} placement="top" arrow>
                                        {employee.address_line_two.slice(0, 16) + (employee.address_line_two.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.address_line_two ? employee.address_line_two : '-'
                            }
                        </Text>
                    </Box>
                </Grid>}
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            {currentAddressDetailsConfig?.location?.country?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel title={currentAddressDetailsConfig?.location?.country?.label?.length > 28 ? currentAddressDetailsConfig?.location?.country?.label : ''}>
                            {currentAddressDetailsConfig?.location?.country?.label?.length > 28 ? currentAddressDetailsConfig?.location?.country?.label?.substring(0, 28) + "..." : currentAddressDetailsConfig?.location?.country?.label}
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.country_name}
                        </Text>
                    </Box>
                </Grid>}
            {currentAddressDetailsConfig?.location?.state?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel title={currentAddressDetailsConfig?.location?.state?.label?.length > 28 ? currentAddressDetailsConfig?.location?.state?.label : ''}>
                            {currentAddressDetailsConfig?.location?.state?.label?.length > 28 ? currentAddressDetailsConfig?.location?.state?.label?.substring(0, 28) + "..." : currentAddressDetailsConfig?.location?.state?.label}
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.state_name}
                        </Text>
                    </Box>
                </Grid>}
            {currentAddressDetailsConfig?.location?.city?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel title={currentAddressDetailsConfig?.location?.city?.label?.length > 28 ? currentAddressDetailsConfig?.location?.city?.label : ''}>
                            {currentAddressDetailsConfig?.location?.city?.label?.length > 28 ? currentAddressDetailsConfig?.location?.city?.label?.substring(0, 28) + "..." : currentAddressDetailsConfig?.location?.city?.label}
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {
                                employee.city && employee.city.length > 16 ?
                                    <BlackToolTip title={employee.city} placement="top" arrow>
                                        {employee.city.slice(0, 16) + (employee.city.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    :
                                    employee.city
                            }
                        </Text>
                    </Box>
                </Grid>}
            {currentAddressDetailsConfig?.location?.zip_code?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel title={currentAddressDetailsConfig?.location?.zip_code?.label?.length > 28 ? currentAddressDetailsConfig?.location?.zip_code?.label : ''}>
                            {currentAddressDetailsConfig?.location?.zip_code?.label?.length > 28 ? currentAddressDetailsConfig?.location?.zip_code?.label?.substring(0, 28) + "..." : currentAddressDetailsConfig?.location?.zip_code?.label}
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.zip_code}
                        </Text>
                    </Box>
                </Grid>}
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>There is an open request to change the details.</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Please take actions before editing the fields.</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                        <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                            Okay
                        </Button>
                    </Grid>
                </Box>

            </ReusablePopup>
            <SwipeableDrawer
                anchor={'right'}
                open={activityLogDrawer}
                onClose={() => { setActivityLogDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog activityLogDrawer={activityLogDrawer} empId={props.employee.id} current_balancePopup={current_balancePopup} />

            </SwipeableDrawer>
        </Grid>
    );
}