import { Box, Grid, Divider, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import commonApi from '../../apis/CommonApi'
import Button from '../../components/customButton/Button'
import Text from '../../components/customText/Text'
import { isValid, validate_input_fields } from '../../components/Validation'
import LocalStorage from '../../utils/LocalStorage'
import { useNavigate } from 'react-router-dom';
import { addErrorMsg, addLoader, addSuccessMsg, removeLoader } from '../../utils/utils'
import Input from '../../components/input/Input'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowLeft from '../../assets/svg/fi_arrow-left.svg';
import { changePasswordDefaultConfig } from "../../config/pageConfig/publicConfig";

function ChangePasswordForm() {

  const navigate = useNavigate()
  const [changePasswordConfig, setChangePasswordConfig] = useState(changePasswordDefaultConfig);


  useEffect(() => {

    const data = {
      page_name: "change-password",
      page_type: "update",
      with_token: true
    }

    async function getPageData() {
      const response = await commonApi.fetchPageConfig(data)
      if (response.status === 200) {
        setChangePasswordConfig(response?.data?.data?.page_data)
      } else {
        setChangePasswordConfig(changePasswordDefaultConfig)
      }
    }
    getPageData()
  }, []);


  

  const [state, setState] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',

  });
  const [eye, setEye] = useState({
    currentPassword: false,
    password: false,
    confirmpassword: false
  });
  const [error, setError] = useState({});
  const lowerCase = new RegExp(/.*[a-z].*$/);
  const upperCase = new RegExp(/.*[A-Z].*$/);
  const digit = new RegExp(/.*\d.*$/);
  const specialCharacter = new RegExp(/.*\W.*$/);
  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    }, handleValidate(e));
  };




  const handleValidate = (e) => {
    let input = e.target
    let err = { ...error }
    switch (input.name || input.tagName) {
      case 'currentPassword':
        err['currentPassword'] = validate_input_fields(input.value, changePasswordConfig?.currentPassword);
        break
      case 'password':
        err['password'] = validate_input_fields(input.value, changePasswordConfig?.password);
        error.confirmPassword = state.confirmPassword != "" ? input.value == state.confirmPassword ? '' : changePasswordConfig?.confirmPassword?.requiredMsg : '';
        break;
      case 'confirmPassword':
        err.confirmPassword = (changePasswordConfig?.password?.isVisible && (!changePasswordConfig?.password?.isDisabled)) ? state.password == input.value ? '' : changePasswordConfig?.confirmPassword?.requiredMsg : validate_input_fields(input.value, changePasswordConfig?.confirmPassword);
        break;
      default:
        break
    }
    setError({ ...err })
  }

  /* fun is for final submission validation when saving the form data*/
  const formValidations = () => {
    let { currentPassword, password, confirmPassword } = state;
    let errors = {};
    errors.currentPassword = validate_input_fields(currentPassword, changePasswordConfig?.currentPassword);
    errors.password = validate_input_fields(password, changePasswordConfig?.password);
    errors.confirmPassword = password == confirmPassword ? validate_input_fields(confirmPassword, changePasswordConfig?.confirmPassword) : changePasswordConfig?.confirmPassword?.requiredMsg;
    return errors;
  };

  /*submit fun*/
  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      request_id: LocalStorage.uid(),
      current_password: state.currentPassword,
      new_password: state.password,
      confirm_password: state.confirmPassword,
    }
    let errors = formValidations();
    if (isValid(errors)) {
      addLoader(true)
      commonApi.profileChangePassword(data, LocalStorage.getAccessToken())
        .then(response => {
          if (response.data.statusCode == 1003) {
            removeLoader()
            addSuccessMsg("Changes Your Password Successfully");
            localStorage.clear();
            window.location.reload();
          }
          else {
            removeLoader()
            addErrorMsg(response?.data?.message);
          }
        })
        .catch((e) => {
          removeLoader()
        })
    }
    else {
      let err = { error };
      err = errors;
      setError(err);
    }

  };
  // eslint-disable-next-line
  const handleCancel = () => {
    setState({
      ...state,
      currentPassword: '',
      password: '',
      confirmPassword: '',
    });
    setError({});
    navigate('/dashboard');
  }

  const handleBackNavigation = () => {
    navigate("/myprofile")
  }
  return (

    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <Box mx={{ lg: 12, md: 12, sm: 12, xs: 6 }} display={'flex'} alignItems={'center'} gap={1}>
          <IconButton disableRipple onClick={handleBackNavigation}>
            <img src={ArrowLeft} alt='ArrowLeft'></img>
          </IconButton>
          <Text boldBlackfont22 >My Profile</Text>
        </Box>
      </Grid>
      <form onSubmit={submitHandler}>
        <Grid container justifyContent={'center'}>
          <Grid item lg={0.8}></Grid>
          <Grid item lg={6} p={2}>
            <Box m={1} p={{ lg: 4, md: 4, sm: 4, xs: 1 }} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
              <Grid container justifyContent={'center'} spacing={2}>
                <Grid item lg={12}>
                  <Text blackHeader2>Change Password</Text>
                </Grid>
                {changePasswordConfig?.currentPassword?.isVisible &&
                  <Grid item xs={12} >
                    <Input
                      onCut={(e) => { e.preventDefault() }}
                      onCopy={(e) => { e.preventDefault() }}
                      onPaste={(e) => { e.preventDefault() }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "currentPassword",
                        value: state.currentPassword,
                        type: eye.currentPassword ? 'text' : 'password',
                        inputProps: { maxLength: changePasswordConfig?.currentPassword?.maxLength }
                      }}
                      eyeIcon={eye.currentPassword}
                      eyeCloseIcon={eye.currentPassword == false}
                      eyeHandleChange={() => {
                        setEye({
                          ...eye,
                          currentPassword: !eye.currentPassword
                        })
                      }}
                      iconText
                      handleChange={changeHandler}
                      disabled={changePasswordConfig?.currentPassword?.isDisabled}
                      labelText={changePasswordConfig?.currentPassword?.label}
                      placeholder={changePasswordConfig?.currentPassword?.placeholder}
                      error={error.currentPassword}
                    />
                    {error.currentPassword ? (
                      <Text red>{error.currentPassword}</Text>
                    ) : (
                      ""
                    )}
                  </Grid>}
                {changePasswordConfig?.password?.isVisible &&
                  <Grid item xs={12} >
                    <Box>
                      <Input
                        onCut={(e) => { e.preventDefault() }}
                        onCopy={(e) => { e.preventDefault() }}
                        onPaste={(e) => { e.preventDefault() }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "password",
                          value: state.password,
                          type: eye.password ? 'text' : 'password',
                          inputProps: { maxLength: changePasswordConfig?.password?.maxLength }
                        }}
                        eyeIcon={eye.password}
                        eyeCloseIcon={eye.password == false}
                        eyeHandleChange={() => {
                          setEye({
                            ...eye,
                            password: !eye.password
                          })
                        }}
                        iconText
                        handleChange={changeHandler}
                        disabled={changePasswordConfig?.password?.isDisabled}
                        labelText={changePasswordConfig?.password?.label}
                        placeholder={changePasswordConfig?.password?.placeholder}
                        error={error.password}
                      />

                      {error.password ? (
                        <Text red>{error.password}</Text>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>}

                {changePasswordConfig?.confirmPassword?.isVisible &&
                  <Grid item xs={12} >
                    <Box>
                      <Input
                        onCut={(e) => { e.preventDefault() }}
                        onCopy={(e) => { e.preventDefault() }}
                        onPaste={(e) => { e.preventDefault() }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "confirmPassword",
                          value: state.confirmPassword,
                          type: eye.confirmpassword ? 'text' : 'password',
                          inputProps: { maxLength: changePasswordConfig?.confirmPassword?.maxLength }
                        }}
                        eyeIcon={eye.confirmpassword}
                        eyeCloseIcon={eye.confirmpassword == false}
                        iconText
                        eyeHandleChange={() => {
                          setEye({
                            ...eye,
                            confirmpassword: !eye.confirmpassword
                          })
                        }}
                        handleChange={changeHandler}
                        disabled={changePasswordConfig?.confirmPassword?.isDisabled}
                        labelText={changePasswordConfig?.confirmPassword?.label}
                        placeholder={changePasswordConfig?.confirmPassword?.placeholder}
                        error={error.confirmPassword}
                      />
                      {error.confirmPassword ? (
                        <Text red>{error.confirmPassword}</Text>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>}
                <Grid item xs={12} p={1} >
                  <Divider />
                </Grid>
                {changePasswordConfig?.password?.isVisible && <Grid item sm={12} xs={12} p={1} pl={2}>
                  <Text mediumLabel sx={{ color: '#111827 !important', paddingBottom: '5px' }}>Password Requirements:</Text>
                  <Text mediumLabel sx={{ color: upperCase.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}> <span style={{
                    height: '6px',
                    width: '6px',
                    borderRadius: '50%',
                    background: upperCase.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                    display: 'flex',
                    margin: '6.5px 8px 2px 0px'
                  }}> </span> At least {changePasswordConfig?.password?.upperCaseTxt} upper case {upperCase.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                  <Text mediumLabel sx={{ color: lowerCase.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                    height: '6px',
                    width: '6px',
                    borderRadius: '50%',
                    background: lowerCase.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                    display: 'flex',
                    margin: '6.5px 8px 2px 0px'
                  }}> </span> At least {changePasswordConfig?.password?.lowerCaseTxt} lower case {lowerCase.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                  <Text mediumLabel sx={{ color: specialCharacter.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                    height: '6px',
                    width: '6px',
                    borderRadius: '50%',
                    background: specialCharacter.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                    display: 'flex',
                    margin: '6.5px 8px 2px 0px'
                  }}> </span>At least {changePasswordConfig?.password?.specialCharacterTxt} special character {specialCharacter.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                  <Text mediumLabel sx={{ color: state.password.length > 7 || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                    height: '6px',
                    width: '6px',
                    borderRadius: '50%',
                    background: state.password.length > 7 || state.password.length == 0 ? '#404040' : 'red',
                    display: 'flex',
                    margin: '6.5px 8px 2px 0px'
                  }}> </span>The password length min of {changePasswordConfig?.password?.passwordLengthTxt} characters. {state.password.length > 7 ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                  <Text mediumLabel sx={{ color: digit.test(state.password) || state.password.length == 0 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                    height: '6px',
                    width: '6px',
                    borderRadius: '50%',
                    background: digit.test(state.password) || state.password.length == 0 ? '#404040' : 'red',
                    display: 'flex',
                    margin: '8px 8px 2px 0px'
                  }}> </span>At least {changePasswordConfig?.password?.digitTxt} number {digit.test(state.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                </Grid>}

                <Grid item p={3} lg={12} md={12} xs={12} sm={12} container justifyContent={'center'}>
                  <Button save variant='contained' fullWidth type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>

  )
}

export default ChangePasswordForm