import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Avatar, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useState, useEffect } from 'react'
import warning from '../../../../../assets/employee/info.svg';
import Text from '../../../../../components/customText/Text';
import GeneratePayrollStyles from '../GeneratePayrollStyles';
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/customButton/Button';


import { BlackToolTip, capitalizeAndAddSpace, getCurrencySymbol, NoDataFound } from '../../../../../utils/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { onNumberWithTwoDecimalOnlyChangeCurrency } from '../../../../../components/Validation';
import LocalStorage from '../../../../../utils/LocalStorage';

function PayCycleDetails({ summary, activeProp, currentSalaryRowProp, closePopUp, setCurrentBalance, setUpdateStandardPayPopUp, handlePayAmount,btnLoader }) {

    const classes = GeneratePayrollStyles();
    const [expanded, setExpanded] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [customAmount, setCustomAmount] = useState('');
    const [intialCheckedRadio, setIntialCheckedRadio] = useState(null);

    const visaTypes = ["H-1B", "E-3", "H-1B1", "E3", "H1B1", "H1B"];
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';


    useEffect(() => {
        if (activeProp) {
            if (currentSalaryRowProp?.amount_paid_type) {
                if ((currentSalaryRowProp?.placements?.length === 0 || currentSalaryRowProp?.placements?.some(client => client?.pay_type === 1 || client?.pay_type === null))) {
                    if (currentSalaryRowProp?.amount_paid_type == 1 && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
                        setSelectedCheckbox(0);
                        setIntialCheckedRadio(0);
                    } else if (currentSalaryRowProp?.amount_paid_type == 2 && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
                        setSelectedCheckbox(1);
                        setIntialCheckedRadio(1);
                    } else if (currentSalaryRowProp?.amount_paid_type == 3) {
                        setSelectedCheckbox(2);
                        setIntialCheckedRadio(2);
                    } else if (currentSalaryRowProp?.amount_paid_type == 4) {
                        setSelectedCheckbox(3);
                        setIntialCheckedRadio(3);
                        setCustomAmount(currentSalaryRowProp?.amount_paid)
                    }
                } else {
                    if (currentSalaryRowProp?.amount_paid_type == 1 || currentSalaryRowProp?.amount_paid_type == 3) {
                        setSelectedCheckbox(2);
                        setIntialCheckedRadio(2);
                    } else if (currentSalaryRowProp?.amount_paid_type == 2 && visaTypes.includes(currentSalaryRowProp?.visa_type)) {
                        setSelectedCheckbox(1);
                        setIntialCheckedRadio(1);
                    } else if (currentSalaryRowProp?.amount_paid_type == 4) {
                        setSelectedCheckbox(3);
                        setIntialCheckedRadio(3);
                        setCustomAmount(currentSalaryRowProp?.amount_paid)
                    }
                }
            } else {
                setSelectedCheckbox(null);
                setIntialCheckedRadio(null);
            }
        } else {
            setCustomAmount('');
            setSelectedCheckbox(null);
        }
        // eslint-disable-next-line
    }, [activeProp])


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handleCheckbox = (e) => {
        setSelectedCheckbox(e.target.value);
        handlePayAmount(customAmount, Number(e.target.value), intialCheckedRadio)
    }

    const handleCustomInput = (e) => {
        let customEnteredValue;


        let value1 = e.target.value.trim();
        if (value1 == "$" || value1 == "") {
            customEnteredValue = "";
            setSelectedCheckbox(3)
            setCustomAmount(customEnteredValue);
            handlePayAmount(customEnteredValue, 3, intialCheckedRadio)
            return;
        }
        value1 = value1.replace(currency, "");
        const regex = /^([^\d.-]+)?\d{1,10}(\.\d{0,3})?$/;
        const numericValue = parseFloat(value1.replace(/[^\d.-]/g, ''));
        if (value1 == "-") {
            customEnteredValue = '';
        }
        else if (regex.test(value1) && !isNaN(numericValue) && numericValue >= -9999999999.99 && numericValue <= 9999999999.99) {
            customEnteredValue = value1;
        } else {
            return false;
        }



        setSelectedCheckbox(3)
        setCustomAmount(customEnteredValue);
        handlePayAmount(customEnteredValue, 3, intialCheckedRadio)
    }



    const otAmounts = currentSalaryRowProp?.placements?.map((item) => item.ot_amount);
    const otTotal = otAmounts?.reduce((acc, curr) => acc + curr, 0);
    const earnedAmounts = currentSalaryRowProp?.placements?.map((item) => item.earned_amount);
    const earnedTotal = earnedAmounts?.reduce((acc, curr) => acc + curr, 0);


    return (
        <div>
            <Box px={3}>
                {currentSalaryRowProp?.lca_violation_message?.map((eachItem) =>
                    <Box className={classes.warningBox} sx={{ marginBottom: '5px' }}>
                        <img src={warning} alt='warning' />
                        <Text mediumOrangeRehire> {eachItem}</Text>
                    </Box>)}


                <Box className={classes.accordionBox} sx={{ height: "54vh", overflowY: "auto" }}>


                    {currentSalaryRowProp?.placements?.length > 0 ?
                        currentSalaryRowProp?.placements?.map((client, key) => (
                            <>
                                {
                                    client?.pay_type === 2 ?
                                        <Accordion key={key} className={classes.newCustomAccordion}>
                                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.AccordionSummary}>
                                                <Grid container alignItems='center'>
                                                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign='start'>
                                                        <Box display='flex' alignItems='center' gap={2}>
                                                            <Avatar
                                                                alt={client.client_name ? capitalizeAndAddSpace(client.client_name[0]) : ''}
                                                                src={client.client_logo == null || client.client_logo == '' ? client.client_name[0] : client.client_logo}
                                                                sx={{ width: 50, height: 50, background: client.client_logo ? '' : '#e5b256 !important' }}
                                                            />
                                                            <Box>
                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                                                                    {
                                                                        client.client_name ? client.client_name.length > 16 ?
                                                                            <BlackToolTip title={capitalizeAndAddSpace(client.client_name)} placement="top" arrow>
                                                                                {capitalizeAndAddSpace(client.client_name).slice(0, 16) + capitalizeAndAddSpace(client.client_name.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : capitalizeAndAddSpace(client.client_name) : '--'
                                                                    }
                                                                </Text>
                                                                <Text largeGrey14>{client?.client_reference_id}</Text>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                                                        <Text mediumBoldBlack >Salary Amount</Text>
                                                        <Text largeGrey14 pt="4px" noWrap sx={{ marginLeft: 'auto', marginRight: '5px' }}>{getCurrencySymbol()} {client?.regular_amount}</Text>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>

                                        </Accordion>
                                        :
                                        <Accordion key={key} className={classes.newCustomAccordion}
                                            expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}
                                        >
                                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" className={classes.AccordionSummary}>
                                                <Grid container alignItems='center'>
                                                    <Grid item lg={4} md={4} sm={4} xs={12} textAlign='start'>
                                                        <Box display='flex' alignItems='center' gap={2}>
                                                            <Avatar
                                                                alt={client.client_name ? capitalizeAndAddSpace(client.client_name[0]) : ''}
                                                                src={client.client_logo == null || client.client_logo == '' ? client.client_name[0] : client.client_logo}
                                                                sx={{ width: 50, height: 50, background: client.client_logo ? '' : '#e5b256 !important' }}
                                                            />
                                                            <Box>
                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                                                                    {
                                                                        client.client_name ? client.client_name.length > 16 ?
                                                                            <BlackToolTip title={capitalizeAndAddSpace(client.client_name)} placement="top" arrow>
                                                                                {capitalizeAndAddSpace(client.client_name).slice(0, 16) + capitalizeAndAddSpace(client.client_name.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : capitalizeAndAddSpace(client.client_name) : '--'
                                                                    }
                                                                </Text>
                                                                <Text largeGrey14>{client?.client_reference_id}</Text>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4} xs={12} textAlign='start'>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '52%' }}>
                                                            <Text mediumBoldBlack ml='7px'>Regular Earnings</Text>
                                                            <Text largeGrey14 pt="4px" noWrap sx={{ marginLeft: 'auto', marginRight: '5px' }}>{getCurrencySymbol()} {client.regular_amount}</Text>
                                                        </Box>

                                                    </Grid>
                                                    <Grid item lg={3} md={3} sm={3} xs={9} textAlign='start'>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                                                            <Text mediumBoldBlack ml='11px'>Overtime Earnings</Text>
                                                            <Text largeGrey14 pt="4px" noWrap sx={{ marginLeft: 'auto', marginRight: '5px' }}>{getCurrencySymbol()} {client.ot_amount}</Text> </Box>
                                                    </Grid>
                                                    <Grid item lg={1} md={1} sm={1} xs={3} textAlign='end'>
                                                        <ExpandMoreIcon />
                                                    </Grid>

                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box className={classes.AccordionDetails}>
                                                    <Box className={classes.AccordionDetailsContent}>
                                                        <Grid container>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.contentLeftAlign}>
                                                                <Text grey16>Regular Hours</Text>
                                                                <Text blackHeader600 pt="4px">{client.total_hours_logged}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>Regular Bill Rate</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.bill_rate}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>Regular Payrate</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.regular_rate}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>Regular Earnings</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.regular_amount}</Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider />
                                                    <Box className={classes.AccordionDetailsContent}>
                                                        <Grid container>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.contentLeftAlign}>
                                                                <Text grey16>OT Hours</Text>
                                                                <Text blackHeader600 pt="4px">{client.total_ot_hours_logged}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>OT Bill Rate</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.ot_bill_rate}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>OT Payrate</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.ot_pay_rate}</Text>
                                                            </Grid>
                                                            <Grid item md={3} sm={3} xs={6} className={classes.constRightAlign}>
                                                                <Text grey16>OT Earnings</Text>
                                                                <Text blackHeader600 pt="4px" noWrap>{getCurrencySymbol()} {client.ot_amount}</Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                }
                            </>
                        )) :
                        <Box>
                            {NoDataFound()}
                        </Box>
                    }

                </Box>
                {/* {currentSalaryRowProp?.placements?.length > 0 && currentSalaryRowProp?.placements?.some(client => client?.pay_type === 1 || client?.pay_type === null) && (
<>
{!summary ? <Text black18px py={2}>Total Regular Earnings: $ {earnedTotal > 0 ? `${earnedTotal} (${earnedAmounts?.join(" + ")})` : earnedTotal}, OT Earnings: $ {otTotal > 0 ? `${otTotal} (${otAmounts?.join(" + ")})` : otTotal}</Text> : ''}
</>
)} */}
                {(currentSalaryRowProp?.placements?.length === 0 || currentSalaryRowProp?.placements?.some(client => client?.pay_type === 1 || client?.pay_type === null)) &&
                    !summary && (
                        <Text black18px py={2}>
                            Total Regular Earnings: $ {earnedTotal > 0 ? `${earnedTotal} (${earnedAmounts?.join(" + ")})` : earnedTotal},
                            OT Earnings: $ {otTotal > 0 ? `${otTotal} (${otAmounts?.join(" + ")})` : otTotal}
                        </Text>
                    )
                }

            </Box>

            {!summary ? <>
                <Divider />
                <Box p={3}>
                    <Text largeBlue>Pay</Text>
                    <Grid container>
                        {(currentSalaryRowProp?.placements?.length === 0 || currentSalaryRowProp?.placements?.some(client => client?.pay_type === 1 || client?.pay_type === null)) ?
                            <FormControl sx={{ width: '100%' }}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => handleCheckbox(e)}
                                    value={selectedCheckbox}

                                >
                                    {visaTypes.includes(currentSalaryRowProp?.visa_type) &&
                                        <FormControlLabel value={0} control={<Radio />}
                                            label={
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "standard_pay",
                                                        value: `${getCurrencySymbol()} ${currentSalaryRowProp?.standard_pay}`,
                                                        inputProps: { maxLength: 33 }
                                                    }}
                                                    disabled={true}
                                                    labelText={`Standard Pay`}
                                                    clientInput

                                                />} sx={{ width: "23%" }} />}

                                    {visaTypes.includes(currentSalaryRowProp?.visa_type) && <FormControlLabel value={1} control={<Radio />}
                                        label={
                                            <Input
                                                formControlProps={{
                                                    fullWidth: "true",
                                                }}
                                                inputProps={{
                                                    name: "base_pay",
                                                    value: `${getCurrencySymbol()} ${currentSalaryRowProp?.lca_wage}`,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                disabled={true}
                                                labelText={`Base Pay (as per LCA)`}
                                                clientInput
                                            />} sx={{ width: "23%" }} />}
                                    <FormControlLabel value={2} control={<Radio />}
                                        label={
                                            <Input
                                                formControlProps={{
                                                    fullWidth: "true",
                                                }}
                                                inputProps={{
                                                    name: "earned_wages",
                                                    value: `${getCurrencySymbol()} ${currentSalaryRowProp?.earned_pay}`,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                disabled={true}
                                                labelText={`Earned wage`}
                                                clientInput
                                            />} sx={{ width: "23%" }} />

                                    <FormControlLabel value={3} control={<Radio />}
                                        label={
                                            <Input
                                                formControlProps={{
                                                    fullWidth: "true",
                                                }}
                                                inputProps={{
                                                    name: "custom_amount",
                                                    value: currency + '' + customAmount,
                                                    inputProps: { maxLength: 16, pattern: "\\d*\\.?\\d{0,3}" }
                                                }}
                                                handleChange={(e) => handleCustomInput(e)}
                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                // disabled={true}
                                                labelText={`Custom`}
                                                clientInput
                                            />} sx={{ width: "23%" }} />
                                </RadioGroup>
                            </FormControl>
                            :
                            <FormControl sx={{ width: '100%' }}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => handleCheckbox(e)}
                                    value={selectedCheckbox}

                                >
                                    <FormControlLabel value={2} control={<Radio />}
                                        label={
                                            <Input
                                                formControlProps={{
                                                    fullWidth: "true",
                                                }}
                                                inputProps={{
                                                    name: "earned_wages",
                                                    value: `${getCurrencySymbol()} ${currentSalaryRowProp?.earned_pay}`,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                disabled={true}
                                                labelText={"Salary Amount"}
                                                clientInput
                                            />} sx={{ width: "30%" }} />

                                    {visaTypes.includes(currentSalaryRowProp?.visa_type) &&
                                        <FormControlLabel value={1} control={<Radio />}
                                            label={
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: "true",
                                                    }}
                                                    inputProps={{
                                                        name: "base_pay",
                                                        value: `${getCurrencySymbol()} ${currentSalaryRowProp?.lca_wage}`,
                                                        inputProps: { maxLength: 33 }
                                                    }}
                                                    disabled={true}
                                                    labelText={`Base Pay (as per LCA)`}
                                                    clientInput
                                                />} sx={{ width: "30%" }} />}

                                    <FormControlLabel value={3} control={<Radio />}
                                        label={
                                            <Input
                                                formControlProps={{
                                                    fullWidth: "true",
                                                }}
                                                inputProps={{
                                                    name: "custom_amount",
                                                    value: currency + '' + customAmount,
                                                    inputProps: { maxLength: 16, pattern: "\\d*\\.?\\d{0,3}" }
                                                }}
                                                handleChange={(e) => handleCustomInput(e)}
                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                // disabled={true}
                                                labelText={`Custom`}
                                                clientInput
                                            />} sx={{ width: "30%" }} />
                                </RadioGroup>
                            </FormControl>
                        }

                    </Grid>
                </Box></> : ''}

            <Box sx={{ background: "#EAECF0" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} alignItems={"center"} p={1} px={3} gap={2} justifyContent={"end"}>
                    <Button disabled={btnLoader} cancelBtn sx={{ width: "50px !important", height: '40px !important' }} onClick={closePopUp}>Cancel</Button>
                    {!summary ? <Button save14 sx={{ width: "50px !important" }} onClick={setUpdateStandardPayPopUp}>Finalize</Button> : <Button save14 sx={{ width: "50px !important" }} onClick={closePopUp}>Done</Button>}
                </Grid>
            </Box>

        </div>
    )
}

export default PayCycleDetails;

