import React from "react";
import LocalStorage from "../utils/LocalStorage";
import { ErrorMessages } from "../utils/ErrorMessages";
const namesValidator = RegExp(/^[a-zA-z]*[a-zA-Z]{1,50}$/);
const numberValidator = new RegExp(/^[0-9]{10}$/);
// const usMobileNumberValidator = new RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/);
// const contactNumberValidator = new RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/);
// const ssnValidator = new RegExp(/^(?!666|900|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
const usMobileNumberValidator = new RegExp(/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/);
const contactNumberValidator = new RegExp(/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/);
const ssnValidator = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
const aNumberValidator = new RegExp(/^A\d{9}$/);
const sevisNumberValidator = new RegExp(/^N\d{10}$/);
const visaNumberValidator = new RegExp(/^[a-zA-Z]\d{8}$/);
const i94Validator = new RegExp(/^[a-zA-Z0-9]{11}$/);
const foriegnPassportNumber = new RegExp(/^[a-zA-Z0-9]{6,12}$/);
const doc6to9Number = new RegExp(/^[a-zA-Z0-9]{6,9}$/);
const docAAANumber = new RegExp(/^[a-zA-Z]{3}(\d{10}|[*]\d{9})$/);
const doc1to17Number = new RegExp(/^\d{1,17}$/)
const integerValidator = new RegExp(/^[0-9\b]+$/);
const zipCodeValidator = new RegExp(/^(?!0+$|1+$)\d{5,6}$/);
const extension = new RegExp(/^[0-9\b]+$/);
const integerdecimal = new RegExp(/^[0-9.\b]+$/);
const alpaNumericwithreqchar = new RegExp(/^[a-zA-Z0-9,./& ]+$/) // Alpha numeric with /,.&
const emailValidator = new RegExp(/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\.[a-zA-Z]{2,6}$/);
const passwordValidator = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
var pattern = new RegExp("^[a-zA-Z]+$");
var charWithSpace = new RegExp(/^[a-zA-Z]+( [a-zA-Z]+)*$/);
var singleAlphabet = new RegExp(/^[a-zA-Z]$/);
var alphaSpecialCharacters = new RegExp(/^[a-zA-Z!@#()$%^_&\-* ]+$/);
var alphaSpecialCharacterswithNumber = new RegExp(/^[a-zA-Z0-9!@#$%^_&\-* ]+$/);
var alphaNumeric = new RegExp(/^[a-zA-Z0-9 ]+$/); // Alpha numeric with Spaces
var i94Regex = new RegExp(/^\d{9}[a-zA-Z]\d$/);
var alphaNumericSplcharacters = new RegExp(/^[a-zA-Z0-9 :-]+$/); // Alpha numeric with Spaces and ":", "-"
var alphaNumericWithoutSpace = new RegExp(/^[a-zA-Z0-9]+$/);
var WithCharDigits = new RegExp(/^[a-zA-Z0-9@./#&+-_ ]*$/);
var Charpattern = new RegExp("^[a-zA-Z0-9]+$");
var datePattern = new RegExp("^[0-9_./#&+-]+$");
var WithChar = new RegExp("^[A-Za-z0-9_@./#&+-]+$");
var floatPattern = new RegExp("^-?\\d+(\\.\\d+)*$");
var alphaNumericWithSpace = new RegExp(/^[a-zA-Z0-9 ]+$/);
var hours_format = new RegExp("([01]?[0-9]|2[0-3]):[0-5][0-9]");
var ProjectValidation = new RegExp(/^[a-zA-Z0-9-()/ ]+$/);
var float = new RegExp(/^\d*\.?\d{0,3}$/);
var alphaNumericCharacters = new RegExp("^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$");
// eslint-disable-next-line
const address = new RegExp(/^[a-zA-Z0-9 !_#&(\)[\]\/<>,\.'";\-\\]*$/);
const billrate = new RegExp(/^\d{0,2}(?:\.\d{0,3})?$/)
const url = new RegExp(/\b(?:https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?\b/);
const skill = new RegExp(/[{}?!'~$%*><]/);
const deposit_value = new RegExp(/^\d+(\.\d+)?$/);
// var passportPattern = new RegExp("/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/");"^[a-zA-Z0-9 !_#&()[]<>/,.';-]+$"
const EverifyUsername = new RegExp(/^[a-zA-Z](?!.*([a-zA-Z0-9])\1{2})[a-zA-Z0-9]{7,19}$/);
const EverifyPassword = new RegExp(/^(?!\d)(?!.*(\w{2,})\1)(?=.*\d)(?=.*[<{(!@$%^&*->):?;+-~}])(?=.*[a-z])(?=.*[A-Z]).{8,}(?<!\d)$/)
const visaValidation = new RegExp(/^[a-zA-Z0-9]{8}$/);
const verifyempId = new RegExp(/^[a-zA-Z0-9\- ]*$/)
const clientName = new RegExp(/^(?:[A-Za-z0-9&£@€$¥-] ?)+$/)
var alphaNumericWithSpecialChars = new RegExp(/^[A-Za-z0-9 &@£$€¥-]{2,60}$/);
var genderValidataion = new RegExp(/^[a-zA-Z\s-]*$/);
var charWithSpaceNumeric = new RegExp(/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/);
var alphabetsWithSpace = new RegExp(/^[a-zA-Z ]+$/);
var banknameValidation = new RegExp(/^[a-zA-Z.,&()/ ]*$/)
var transactionID = new RegExp(/^[a-zA-Z0-9- ]+$/);
var documentName = new RegExp(/^[a-zA-Z0-9\s-_]+$/);
var visaName = new RegExp("^[a-zA-Z0-9- ]+$");
var charWithSpaceHyphen = new RegExp(/^[A-Za-z\s'-]+$/);
const taskRegex = new RegExp(/^(?!\s*$)[a-zA-Z0-9().#\-\s]+$/);

export const isValidMulti = (errors) => {
  let keys = [];
  // eslint-disable-next-line
  errors.map((value) => {
    let key = Object.keys(value);
    keys.push(key);
  });
  let count;
  let val = 0;
  // eslint-disable-next-line
  keys.map((value) => {
    // eslint-disable-next-line
    errors.map((response) => {
      count = value.reduce((acc, curr) => (response[curr] ? acc + 1 : acc), 0);
      if (count !== undefined && count > 0) {
        val = count
      }
    });
  });
  return val == 0 && count == 0;
};

/*currency name validation*/
export const validate_emptyField = (value) =>
  value === "" || value == null || value == undefined ? ErrorMessages.fieldRequired : "";

export const empty_fields = (value) =>
  value === "" || value == null || value == undefined ? "" : "";

export const check_empty = (value) =>
  value === "" || value == null || value == undefined ? "" : "";

export const empty_documents = (value) =>
  value === "" || value == null ? "Plesae upload the documents" : "";

export const hours_pattern_time = (value1) =>
  value1 == "" || value1 == null
    ? ErrorMessages.fieldRequired
    : !hours_format.test(value1)
      ? ErrorMessages.checkNumber
      : "";

export const validate_toHours = (value1, value2) =>
  value1 == "" || value1 == null
    ? ErrorMessages.fieldRequired
    : !integerValidator.test(value1)
      ? ErrorMessages.checkNumber
      : Number(value2) >= Number(value1)
        ? ErrorMessages.validToHours
        : "";

export const validate_Extension = (value1) =>
  value1 == "" || value1 == null
    ? ErrorMessages.fieldRequired
    : !extension.test(value1)
      ? ErrorMessages.checkNumber
      : "";

export const validate_everify_username = (value1) =>
  value1 == "" || value1 == null
    ? ErrorMessages.fieldRequired
    : !EverifyUsername.test(value1)
      ? "Please enter valid data"
      : "";

/* errors valid */
export const isValid = (errors) => {
  let keys = Object.keys(errors);
  let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);
  return count == 0;
};

/*Validation for users functions starts here*/

export const validates_password = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !passwordValidator.test(value)
      ? "Password not matched to the expected scenario"
      : "";
export const validates_password_login = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !passwordValidator.test(value)
      ? "Invalid Password"
      : "";

export const validates_emptyArray = (value) =>
  value.length <= 0
    ? ErrorMessages.fieldRequired
    : "";

export const validate_passport = (value) =>
  !alphaNumericWithoutSpace.test(value)
    ? ErrorMessages.validPassport : value != "" && value.length < 8 ? ErrorMessages.validPassportLength : '';

export const validate_i94 = (value) =>
  !i94Regex.test(value)
    ? ErrorMessages.validI94 : '';

export const validate_vissa = (value) =>
  !alphaNumeric.test(value)
    ? ErrorMessages.validVisa : '';

export const validate_fieldCharacters = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !namesValidator.test(value)
      ? ErrorMessages.checkCharacters
      : "";

export const validate_charWithSpace = (value, args) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !charWithSpace.test(value)
      ? `Please enter a valid ${args ? args : ''} name`
      : "";

export const validate_bank_name = (value, args) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !charWithSpace.test(value)
      ? `Please enter a valid ${args ? args : ''}name`
      : value.length < 2 ? ErrorMessages.validBankName :
        "";
export const aphaNumericwithreqChar = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alpaNumericwithreqchar.test(value)
      ? ErrorMessages.validName
      : "";
export const validate_alphaSpecialCharacterswithNumber = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaSpecialCharacterswithNumber.test(value)
      ? ErrorMessages.validName
      : "";

export const validate_alphaNumaric = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumericCharacters.test(value)
      ? ErrorMessages.validName
      : "";

export const validateAplaSpecialChar = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaSpecialCharacters.test(value)
      ? ErrorMessages.validName
      : "";

export const validate_accountNumber = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value.length < 8
      ? ErrorMessages.validAccountNumber
      : "";

export const validate_routingNumber = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value.length < 9 || value.length > 9
      ? ErrorMessages.validRouteNumber
      : "";

export const validate_alphaNumeric = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumeric.test(value)
      ? ErrorMessages.validName : '';

export const validate_handle_alphaNumeric = (value) =>
  value == "" || value == null
    ? ""
    : !alphaNumeric.test(value)
      ? ErrorMessages.validName : '';

export const validate_project_name = (value) =>
  value == "" || value == null
    ? ""
    : !ProjectValidation.test(value)
      ? ErrorMessages.validName : '';

export const validate_alphaNumeWithoutSpace = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumericWithoutSpace.test(value)
      ? ErrorMessages.validName : '';

export const validate_withCharDigit = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !WithCharDigits.test(value)
      ? ErrorMessages.validData
      : "";

export const validate_ssn = (value) =>
  value == '' || value == null
    ? ''
    :
    // value.length >= 11 ? "" :
    !ssnValidator.test(value)
      ? ErrorMessages.invalidSSN : '';

export const validate_ssn_empty = (value) =>
  value === '' || value === null
    ? ''
    // : value.length >= 11 ? "" 
    :
    !ssnValidator.test(value)
      ? ErrorMessages.invalidSSN : '';

export const validate_usContact = (value, args) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  if (input === '' || input == null) {
    return ErrorMessages.fieldRequired;
  }
  if (!usMobileNumberValidator.test(input)) {
    return `Please enter a valid ${args ? args : ''} `;
  }
  return '';
};


// export const validate_usContact = (value, args) => {
//   value = value.trim(); // Trim whitespace from input

//   if (value == '' || value == null) {
//     return ErrorMessages.fieldRequired;
//   } else if (!usMobileNumberValidator.test(value)) {
//     return `Please enter a Valid ${args ? args : ''}`;
//   } else {
//     return '';
//   }
// };

export const validate_contact_number = (value, msg) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  if (input === '' || input == null) {
    return ErrorMessages.fieldRequired;
  }
  if (!contactNumberValidator.test(input)) {
    return `Please enter a valid ${msg ? msg : ''} `;
  }
  return '';
};
// value == '' || value == null
//   ? ErrorMessages.fieldRequired
//   : !contactNumberValidator.test(value)
//     ? `Please enter valid ${msg ? msg : ''}` : '';

export const validate_optional_contact = (value, msg) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  if (value && !usMobileNumberValidator.test(input)) {
    return `Please enter a valid ${msg ? msg : 'mobile'} number`;
  }
  return '';
}
// value && !usMobileNumberValidator.test(value)
//   ? `Please enter a valid ${msg ? msg : 'Mobile'} number`
//   : '';

export const validates_emailId = (value, msg) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !emailValidator.test(value)
      ? `Please enter a valid ${msg ? msg : 'Email Id'}`
      : "";

export const validates_date = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !datePattern.test(value)
      ? "Please enter valid date"
      : "";

export const validates_contact = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !numberValidator.test(value)
      ? 'Please enter valid phone number' : '';

export const validates_contact_number = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !numberValidator.test(value)
      ? ErrorMessages.validNumber : '';

/*Validation functions end here*/

export const validate_Name = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !pattern.test(value)
      ? ErrorMessages.validName
      : "";

export const validate_Char = (value, args) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !Charpattern.test(value)
      ? `Please enter valid ${args ? args : ''} name`
      : "";
export const validates_Integer = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !integerValidator.test(value)
      ? ErrorMessages.checkNumber
      : "";

export const validateDecimalInteger = (value) =>
  value == '' || value == null ?
    ErrorMessages.fieldRequired : !integerdecimal.test(value) ? ErrorMessages.checkNumber
      : "";

export const validate_withchar = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !WithChar.test(value)
      ? ErrorMessages.validCharacters
      : "";

export const validate_date = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !datePattern.test(value)
      ? ErrorMessages.validDate
      : "";

export const validate_selectField = (targetName, error) => {
  // eslint-disable-next-line
  let clearObj = new Object();
  Object.keys(error).forEach(function (index, value) {
    if (index == targetName) {
      clearObj[index] = "";
    } else {
      clearObj[index] = error[index];
    }
  });
  return clearObj;
}

export const float_validation = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !float.test(value)
      ? ErrorMessages.checkNumber
      : "";

export const validates_float = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !floatPattern.test(value)
      ? ErrorMessages.checkNumber
      : "";

export const validate_deposit_value = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !deposit_value.test(value)
      ? ErrorMessages.checkNumber
      : "";

export const validates_handlechange_float = (value) =>
  value == "" || value == null
    ? ""
    : !float.test(value)
      ? ErrorMessages.checkNumber
      : "";

const newI94 = new RegExp(/^(?!(\d)\1+$)(?:\d{11}|\d{9}[A-Za-z]\d)$/)
export const validates_new_i94 = (value) =>
  value == "" || value == null
    ? ""
    : !newI94.test(value)
      ? ErrorMessages.validI94
      : "";

export const validate_Visa_Name = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value.length < 1
      ? ErrorMessages.VisaName
      : !visaName.test(value)
        ? ErrorMessages.VisaName
        : "";

export const validate_Description = (value) => {
  const descriptionRegex = /^[A-Za-z0-9.,;:()'"\\-\s]+$/; // Regex for allowed characters

  return value == "" || value == null
    ? ""
    : value.length < 10
      ? ErrorMessages.invalidDescription
      : !descriptionRegex.test(value)
        ? ErrorMessages.invalidDescription
        : "";
};

export const validate_Onboarding_Doc = (value) => {
  const onbName = /^[a-zA-Z0-9\s-_.]+$/;

  return value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value.length < 3
      ? ErrorMessages.validDocName
      : !onbName.test(value)
        ? ErrorMessages.validDocName
        : "";
};

export const passwordList = () => {
  return (
    <div>
      <ul>
        password must contain
        <li>one special character</li>
        <li>one upper and lower case letters and</li>
        <li>one digit</li>
      </ul>
    </div>
  );
}

// // Mobile Validation for US pattern  //
// export const phoneNumberValidation = function (value) {
//   let input = value.replace(/\D/g, '');
//   input = input.substring(0, 10);
//   var size = input.length;
//   if (size == 0) {
//     // eslint-disable-next-line
//     input = input;
//   } else if (size < 4) {
//     input = '-' + input;
//   } else if (size < 7) {
//     input = input.substring(0, 3) + '-' + input.substring(3, 6);
//   } else {
//     input = input.substring(0, 3) + '-' + input.substring(3, 6) + '-' + input.substring(6, 10);
//   }
//   return input;
// }

export const onCharactersOnlyChange = (event) => {
  var regex = new RegExp("^[a-zA-Z ]+$");
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
}

export const onCharactersUppercase = (event) => {
  var regex = new RegExp("^[a-zA-Z]+$");
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
}

export const onNumberOnlyChange = (event) => {
  const allowedKeys = [
    "Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown",
    "Tab", "Enter", "Escape"
  ];

  if (allowedKeys.includes(event.key) || event.ctrlKey || event.metaKey) {
    return;
  }

  const selection = window.getSelection().toString();
  if (selection.length > 0) {
    return;
  }

  if (!/^\d$/.test(event.key)) {
    event.preventDefault();
  }
};




export const onCharactersAndNumbersChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  const isCharacterOrNumber = new RegExp("^[a-zA-Z0-9]+$").test(keyValue);

  // Check if the key pressed is a character or a number
  if (!isCharacterOrNumber) {
    event.preventDefault();
    return false;
  }
};

export const onCharactersAndNumbersWithSpaceChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow only alphabets, numbers, and a single space
  const isCharacterOrNumber = new RegExp("^[a-zA-Z0-9 ]+$").test(keyValue);

  // Check if the key pressed is a character, number, or a single space
  if (!isCharacterOrNumber) {
    event.preventDefault();
    return false;
  }
};

export const onCharactersNumbersWithSpaceHyChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow only alphabets, numbers, and a space
  const isCharacterOrNumber = new RegExp("^[a-zA-Z0-9- ]+$").test(keyValue);

  // Check if the key pressed is a character, number, or a single space
  if (!isCharacterOrNumber) {
    event.preventDefault();
    return false;
  }
};


export const onNumberWithDecimalOnlyChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Check if the entered value is '0' and not part of a larger number
  const isZeroNotAllowed = keyValue === '0' && event.target.value === '';

  // Check if the entered value is a valid digit or a decimal point
  const isValid = new RegExp('^[0-9.]+$').test(keyValue);

  if (!isValid || isZeroNotAllowed) {
    event.preventDefault();
    return;
  }
};

export const onNumberWithTwoDecimal = (event) => {
  const { selectionStart, selectionEnd, value } = event.target;
  const keyValue = event.key;

  // Allow control keys (e.g., Backspace, Arrow keys)
  if (
    keyValue === "Backspace" ||
    keyValue === "Delete" ||
    keyValue === "ArrowLeft" ||
    keyValue === "ArrowRight"
  ) {
    return; // Allow these keys without further validation
  }

  // Simulate the new value based on where the key is inserted
  const newValue =
    value.substring(0, selectionStart) +
    keyValue +
    value.substring(selectionEnd);

  // Regex to validate optional currency symbol and up to 3 decimals (positive only)
  const isValid = /^[0-9]*(\.[0-9]{0,3})?$/u.test(newValue);

  // Prevent invalid input
  if (!isValid) {
    event.preventDefault(); // Block invalid input
  }
};

export const onNumberWithTwoDecimalOnlyChange = (event) => {
  const { selectionStart, selectionEnd, value } = event.target;
  const keyValue = event.key;

  if (
    keyValue === "Backspace" ||
    keyValue === "Delete" ||
    keyValue === "ArrowLeft" ||
    keyValue === "ArrowRight" ||
    keyValue === "Tab" ||
    event.ctrlKey
  ) {
    return;
  }

  // Allow only numbers and dot
  if (!/[\d.]/.test(keyValue)) {
    event.preventDefault();
    return;
  }

  // Check if entire text is selected
  const isReplacingAllText = selectionStart === 0 && selectionEnd === value.length;

  let newValue;

  if (isReplacingAllText) {
    // If entire text is selected, replace it with the new key
    newValue = keyValue === "." ? "0." : keyValue;
  } else {
    // Normal case where user types in between
    newValue = value.substring(0, selectionStart) + keyValue + value.substring(selectionEnd);
  }

  // Temporary valid states: empty, just dot, or "0."
  const isTemporarilyValid = newValue === "" || newValue === "." || newValue === "0.";

  // Validate final format (max 10 digits before decimal, and up to 3 decimals)
  const isValid = /^(\d{1,10}|\d{1,10}\.\d{0,3})$/.test(newValue);

  if (!isTemporarilyValid && !isValid) {
    event.preventDefault();
  }
};



export const onCurrencyWithTwoDecimalOnlyChangeCurr = (event) => {
  const { selectionStart, selectionEnd, value } = event.target;
  const keyValue = event.key;

  if (
    keyValue === "Backspace" ||
    keyValue === "Delete" ||
    keyValue === "ArrowLeft" ||
    keyValue === "ArrowRight"
  ) {
    return;
  }

  const newValue =
    value.substring(0, selectionStart) +
    keyValue +
    value.substring(selectionEnd);

  const isTemporarilyValid = newValue === "" || newValue === ".";

  const isValid = /^[\p{Sc}]?[0-9]*(\.[0-9]{0,3})?$/u.test(newValue);

  if (!isTemporarilyValid && !isValid) {
    event.preventDefault();
  }
};


// export const onNumberWithTwoDecimalOnlyChangeCurrency = (event) => {
//   const value = event.target.value;

//   const isZeroNotAllowed = value === '0' || value === '0.';
//   const isValid = new RegExp('^[^0-9]*[0-9]*(\\.[0-9]{0,2})?$').test(value);
//   if ((!isValid && !isCurrencySymbol) || isZeroNotAllowed) {
//     event.preventDefault();
//     return;
//   }
// };


export const onNumberWithTwoDecimalOnlyChangeCurrency = (event) => {
  let value = event.target.value;

  // Allow empty input
  if (value === '') return value;

  // Remove any non-numeric characters except '.' and digits
  value = value.replace(/[^0-9.]/g, '');

  // Ensure only one decimal point exists
  const decimalCount = (value.match(/\./g) || []).length;
  if (decimalCount > 1) {
    value = value.slice(0, -1); // Remove last character if multiple decimals
  }

  // Allow numbers with up to two decimal places
  const isValid = /^\d+(\.\d{0,3})?$/.test(value);

  if (!isValid) {
    return; // Stop processing invalid input
  }

  return value; // Return the sanitized value
};

export const onNumberWithTwoDecimalOnlyChangeWithNegative = (event) => {
  const { selectionStart, selectionEnd, value } = event.target;
  const keyValue = event.key;

  // Allow control keys (e.g., Backspace, Arrow keys)
  if (
    keyValue === "Backspace" ||
    keyValue === "Delete" ||
    keyValue === "ArrowLeft" ||
    keyValue === "ArrowRight"
  ) {
    return; // Allow these keys without further validation
  }

  // Simulate the new value based on where the key is inserted
  const newValue =
    value.substring(0, selectionStart) +
    keyValue +
    value.substring(selectionEnd);

  // Regex to validate positive/negative numbers with up to 2 decimal places
  const isValid = /^-?[0-9]*(\.[0-9]{0,3})?$/.test(newValue);

  // Prevent invalid input
  if (!isValid) {
    event.preventDefault(); // Block invalid input
  }
};

export const onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency = (event) => {
  const { selectionStart, selectionEnd, value } = event.target;
  const keyValue = event.key;

  // Allow control keys (e.g., Backspace, Arrow keys)
  if (
    keyValue === "Backspace" ||
    keyValue === "Delete" ||
    keyValue === "ArrowLeft" ||
    keyValue === "ArrowRight"
  ) {
    return; // Allow these keys without further validation
  }

  // Simulate the new value based on where the key is inserted
  const newValue =
    value.substring(0, selectionStart) +
    keyValue +
    value.substring(selectionEnd);

  // Regex to validate optional currency symbol, negative numbers, and up to 3 decimals
  const isValid = /^[\p{Sc}]?-?[0-9]*(\.[0-9]{0,3})?$/u.test(newValue);

  // Prevent invalid input
  if (!isValid) {
    event.preventDefault(); // Block invalid input
  }
};



export const onNumberFloatCurrency = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Check if the entered value is '0' and not part of a larger number
  const isZeroNotAllowed = keyValue === '0' && event.target.value === '';

  // Check if the entered value is a valid digit or a decimal point
  const isValid = new RegExp(`^\\${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'}?[0-9]+(\\.[0-9]{0,3})?$`).test(event.target.value + keyValue);

  if (!isValid || isZeroNotAllowed) {
    event.preventDefault();
    return;
  }
};

export const onNumberPercentage = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Check if the entered value is '0' and not part of a larger number
  const isZeroNotAllowed = keyValue === '0' && event.target.value === '';

  // Check if the entered value is a valid number followed by a percentage symbol
  const isValid = new RegExp('^\\d+%?$').test(event.target.value + keyValue);

  // Check if the entered value is valid and ends with a percentage symbol
  if (!isValid || isZeroNotAllowed) {
    event.preventDefault();
    return;
  }
};

// export const i94OnlyChange = (event) => {
//   const keyCode = event.keyCode || event.which;
//   const keyValue = String.fromCharCode(keyCode);
//   if (/^(?:\d{11}|\d{9}[A-Za-z]\d)$/.test(event.target.value + keyValue)) {
//     event.preventDefault();
//   }
// };


export const onFloatOnlyChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  // Check if the key pressed is a number or a decimal point
  if (!/^[0-9]*\.?\d{0,3}$/.test(event.target.value + keyValue)) {
    event.preventDefault();
  }
}

export const validate_alphaNumeric_Splcharacters = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumericSplcharacters.test(value)
      ? ErrorMessages.validName : '';

export const validate_city = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumericWithSpace.test(value)
      ? ErrorMessages.validCity : '';

export const validate_zipcode = (value, country) =>
  value == '' ? ErrorMessages.fieldRequired :
    !zipCodeValidator.test(value)
      ? `Invalid ${value.length == 6 ? 'Pincode' : (value.length == 5 || country == 'us') ? 'Zipcode' : 'Zipcode / Pincode'}` : ''

export const empty_Email_id = (value, args) =>
  value == "" || value == null
    ? ""
    : !emailValidator.test(value)
      ? `Please enter a valid ${args ? args : 'email'}`
      : "";

export const empty_zipcode = (value, country) =>
  value == '' || value == null || value == undefined ? '' :
    !zipCodeValidator.test(value)
      ? `Invalid ${value.length == 6 ? 'Pincode' : value.length == 5 ? 'Zipcode' : 'Zipcode / Pincode'}` : ''

export const empty_usContact = (value, name) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  if (input === '' || input == null) {
    return ErrorMessages.fieldRequired;
  }
  if (!usMobileNumberValidator.test(input)) {
    return `Please enter a valid ${name ? name : ''} `;
  }
  return '';
}
// value == '' || value == null
//   ? ''
//   : !usMobileNumberValidator.test(value)
//     ? `Please enter a valid ${name ? name : ""}` : '';

export const empty_city = (value) =>
  value == "" || value == null
    ? ""
    : !alphaNumericWithSpace.test(value)
      ? ErrorMessages.validName : '';


export const empty_name = (value, args) =>
  value == "" || value == null
    ? ""
    : !charWithSpace.test(value)
      ? `Please enter a valid ${args ? args : ''}name`
      : "";

export const empty_integer = (value) =>
  value == "" || value == null
    ? ""
    : !integerValidator.test(value)
      ? ErrorMessages.checkNumber
      : "";

export const validate_annual_pay = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value == 0 ?
      "Value Should be greater than 0"
      : !floatPattern.test(value)
        ? ErrorMessages.checkNumber
        : "";

export const validate_charWithSpace_nonMandatory = (value, args) =>
  value == "" || value == null
    ? ""
    : !charWithSpace.test(value)
      ? `Please enter valid ${args ? args : ''}name`
      : "";
export const validates_emailId_optional = (value) =>
  value == "" || value == null
    ? ""
    : !emailValidator.test(value)
      ? "Invalid email ID"
      : "";
export const validate_city_optional = (value) =>
  value == "" || value == null
    ? ""
    : !alphaNumericWithSpace.test(value)
      ? ErrorMessages.validCity : '';
export const validate_zipcode_optional = (value, country) =>
  value == '' ? '' :
    !zipCodeValidator.test(value)
      ? `Invalid ${value.length == 6 ? 'Pincode' : value.length == 5 ? 'Zipcode' : 'Zipcode / Pincode'}` : ''

export const validate_alphaNumeric_optional = (value) =>
  value == "" || value == null
    ? ""
    : !alphaNumeric.test(value)
      ? ErrorMessages.validName : '';

export const empty_address = (value) =>
  value == "" || value == null
    ? ""
    : !WithCharDigits.test(value)
      ? ErrorMessages.validData
      : "";

export const validate_passport_optional = (value) =>
  value == "" || value == null
    ? "" : !alphaNumericWithoutSpace.test(value)
      ? ErrorMessages.validPassport :
      value.length < 8 ? ErrorMessages.validPassport :
        '';

export const validate_driving_optional = (value) =>
  value == "" || value == null
    ? "" : !alphaNumeric.test(value)
      ? ErrorMessages.validDocumentNumber :
      value.length < 4 ? ErrorMessages.documentNumberDrivingAndStateID :
        '';

export const empty_contact_number = (value, msg) =>
  value == '' || value == null
    ? ''
    : !contactNumberValidator.test(value)
      ? `Please enter a valid ${msg ? msg : ''}` : '';

export const address_validation = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !address.test(value)
      ? ErrorMessages.validAddress
      : "";

export const validate_empty_address = (value) =>
  value == "" || value == null
    ? ""
    : !address.test(value)
      ? ErrorMessages.validAddress
      : "";


export const validate_billrate = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !billrate.test(value)
      ? ErrorMessages.validBillRate
      : "";

export const validate_url = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !url.test(value)
      ? ErrorMessages.validURL
      : "";
export const validate_url_optional = (value) =>
  value == "" || value == null
    ? ""
    : !url.test(value)
      ? ErrorMessages.validURL
      : "";
export const validate_Extension_Optional = (value1) =>
  value1 == "" || value1 == null
    ? ""
    : !extension.test(value1)
      ? ErrorMessages.checkNumber
      : "";

export const validate_skill = (value, args) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : skill.test(value)
      ? ErrorMessages.validSkill
      : "";

export const getInvalidZipcodeMessage = (data) => {
  return data.length == 0 ? ErrorMessages.fieldRequired :
    data.length === 6
      ? ErrorMessages.invalidPincode
      : data.length === 5
        ? ErrorMessages.invalidZipcode
        : ErrorMessages.invalidPinZipcode
};
export const validate_everify_password = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !EverifyPassword.test(value)
      ? "Invalid Password"
      : "";

export const validate_anumber = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !aNumberValidator.test(value)
      ? 'Invalid A Number, please check the format' : '';

export const validate_sevisnumber = (value) =>
  value == '' || value == null
    ? '' :
    !sevisNumberValidator.test(value)
      ? 'Invalid SEVIS Number, please check the format' : '';


export const validate_visanumber = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !visaNumberValidator.test(value)
      ? 'Invalid VISA Number, please check the format' : '';


export const validate_i94number = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !i94Validator.test(value)
      ? 'Invalid i94 Number, please check the format' : '';



export const validate_foriegnPassportNumber = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !foriegnPassportNumber.test(value)
      ? 'Invalid Passport Number, please check the format' : '';


export const validate_doc6to12Number = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !foriegnPassportNumber.test(value)
      ? ErrorMessages.invalidDocumentFormat : '';

export const valdoc6to9number = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !doc6to9Number.test(value)
      ? ErrorMessages.invalidDocumentFormat : '';

export const validate_doc1to17Number = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !doc1to17Number.test(value)
      ? ErrorMessages.invalidDocumentFormat : '';

export const validate_docaaaNUmber = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !docAAANumber.test(value)
      ? ErrorMessages.invalidDocumentFormat : '';
export const everify_visa = (value) =>
  value == "" || value == null
    ? ''
    : !visaValidation.test(value)
      ? ErrorMessages.Visanumber : '';
export const everify_middle_name = (value, args) =>
  value == "" || value == null
    ? ''
    : !singleAlphabet.test(value)
      ? `Please enter a valid ${args ? args : ''}name`
      : "";
export const everify_employeeId = (value1) =>
  value1 == "" || value1 == null
    ? ""
    : !verifyempId.test(value1)
      ? "Please enter valid ID"
      : "";

export const validate_ssn_mandatory = (value) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !ssnValidator.test(value)
      ? ErrorMessages.invalidSSN : '';

export const client_name = (value, args) =>
  value == '' || value == null
    ? ErrorMessages.fieldRequired :
    !clientName.test(value)
      ? `Please enter valid ${args ? args : ''} name` : '';

export const validate_alphaNumericWithSpecialChars = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired : !alphaNumericWithSpecialChars.test(value)
      ? ErrorMessages.validOrganizationName :
      '';

export const gender_validations = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired :
    value.length < 3 ? ErrorMessages.InvalidCharacters :
      !genderValidataion.test(value)
        ? ErrorMessages.InvalidCharacters :
        '';

export const nonZeroNumericals = (value) =>
  !['0', '00', '000'].includes(value) ? true : false;

export const validate_alphaNumericId = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphaNumericWithoutSpace.test(value)
      ? ErrorMessages.InvalidID : '';

export const validate_goal_amount = (value, value2) =>{
  let v1 = value?.toString().replace("$","");
  let v2  = value2?.toString().replace("$","")
  return v2 == "" || v2 == null
    ? ErrorMessages.fieldRequired :
    v2 <= 0 ? ErrorMessages.RaiseAmount :
      (parseFloat(v2) < parseFloat(v1)) ? ErrorMessages.GoalAmountValidation
        : !float.test(v1)
          ? ErrorMessages.validData : '';}

export const validate_raise_amount = (value, value2, reoccur) =>{
  console.log(value)
  let v1 = value?.toString().replace("$","");
  let v2  = value2?.toString().replace("$","");
  console.log("v1",v1)
  return v1 == "" || v1 == null
  ? ErrorMessages.DeductionAmount :
  v1 <= 0 ? ErrorMessages.RaiseAmount :
    reoccur == 2 && (parseFloat(v1) > parseFloat(v2)) ? ErrorMessages.RaiseAmountValidation
      : !float.test(v1)
        ? ErrorMessages.validData : '';
}
  

export const validate_raise_amount_deduction = (value, value2, reoccur) =>
{
  console.log(value)
  let v1 = value?.toString().replace("$","");
  let v2  = value2?.toString().replace("$","")
  return v1 == "" || v1 == null
  ? ErrorMessages.DeductionAmount :
  v1 <= 0 ? ErrorMessages.RaiseAmount :
    reoccur == 2 && (parseFloat(v1) >= parseFloat(v2)) ? ErrorMessages.RaiseAmountValidation
      : !float.test(v1)
        ? '' : '';
}
  

export const validate_alphaNumeWithoutSpace_optional = (value) =>
  value == "" || value == null
    ? ''
    : !alphaNumericWithoutSpace.test(value)
      ? ErrorMessages.validName : '';

export const validate_alphanumericwithspace_optional = (value) =>
  value == "" || value == null
    ? ""
    : !alphaNumericWithSpace.test(value)
      ? ErrorMessages.validName : '';

export const validate_alphanumericwithspace = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !charWithSpaceNumeric.test(value)
      ? ErrorMessages.validName : '';

export const validate_name_withspace = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !alphabetsWithSpace.test(value)
      ? ErrorMessages.validName : '';

export const validate_bankName = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !banknameValidation.test(value)
      ? ErrorMessages.validData : '';

export const validate_bankName_optional = (value) =>
  value == "" || value == null
    ? ''
    : !banknameValidation.test(value)
      ? ErrorMessages.validData : '';

export const validate_transaction_id = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !transactionID.test(value)
      ? ErrorMessages.InvalidID : '';

export const validate_document_name = (value) =>
  value == "" || value == null
    ? ""
    : !documentName.test(value)
      ? ErrorMessages.validDocName
      : "";

export const validate_charWithSpaceHyphen = (value, args) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !charWithSpaceHyphen.test(value)
      ? `Please enter a valid ${args ? args : ''} name`
      : "";

export const validate_charWithSpaceHyphen_optional = (value, args) =>
  value == "" || value == null
    ? ''
    : !charWithSpaceHyphen.test(value)
      ? `Please enter a valid ${args ? args : ''} name`
      : "";

export const validate_input_fields = (value, config_data) => {

  const { required, pattern, requiredMsg, patternMsg, minLength, minLengthMsg, isDisabled, isVisible } = config_data || {};

  const regex_pattern = new RegExp(pattern.slice(1, -1))

  if (isVisible && !isDisabled && required && (value === "" || value === null || value == undefined)) {
    return requiredMsg;
  }
  else if (isVisible && !isDisabled && required && minLength && (value.length < minLength)) {
    return minLengthMsg
  } else if (isVisible && !isDisabled && !required && minLength && (value.length > 0 && value.length < minLength)) {
    return minLengthMsg
  }
  else if (!regex_pattern.test(value) && (value !== "" && value !== undefined)) {
    return patternMsg;
  } 
  else {
    return "";
  }

};


export const validate_Reason_Rejection = (value) => {
  // const descriptionRegex = /^[A-Za-z0-9.,;:()'"\\-\s]+$/; // Regex for allowed characters

  return value == "" || value == null
    ? ErrorMessages.fieldRequired
    : value.length < 5
      // ? ErrorMessages.invalidReason
      // : !descriptionRegex.test(value)
      ? ErrorMessages.invalidReason
      : "";
};

export const validate_zipcode_input = (value, config) => {
  const { zipcodeLabel, pincodeLabel, zipcodeLength, pincodeLenght, label } = config
  return value == '' || value == null || value == undefined ? '' :
    !zipCodeValidator.test(value)
      ? `Invalid ${value.length == parseInt(pincodeLenght) ? pincodeLabel : value.length == parseInt(zipcodeLength) ? zipcodeLabel : label}` : ''
}

export const onCharactersAndNumbersInputChange = (event, pattern) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  const isCharacterOrNumber = new RegExp(pattern.slice(1, -1)).test(keyValue);

  // Check if the key pressed is a character or a number
  if (!isCharacterOrNumber) {
    event.preventDefault();
    return false;
  }
};

export const validate_taskRegex = (value) =>
  value == "" || value == null
    ? ErrorMessages.fieldRequired
    : !taskRegex.test(value)
      ? `Please enter a valid task name`
      : "";
