export const addPlacementsDefaultConfig = {
	"client_details": {
		"full_name": {
			"label": "Employee Name",
			"placeholder": "",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"reference_id": {
			"label": "Employee ID",
			"placeholder": "",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"visa_type": {
			"label": "Visa Type",
			"placeholder": "",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"lca_amount": {
			"label": "Minimum Annual Wage (As per LCA)",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"maxLength": 14,
			"pattern": "/\\d*\\.?\\d{0,2}/",
			"patternMsg": "Please enter a valid Minimum Annual Wage",
			"isVisible": true,
			"isDisabled": false
		},
		"client_id": {
			"label": "Client Name",
			"placeholder": "",
			"pattern": "",
			"patternMsg": "",
			"required": true,
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"client_reference_id": {
			"label": "Client ID",
			"placeholder": "",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"client_contact_id": {
			"label": "Contact",
			"placeholder": "",
			"pattern": "",
			"patternMsg": "",
			"required": true,
			"requiredMsg": "This field is required",
			"isVisible": true
		},
		"end_client_id": {
			"label": "End Client Name",
			"placeholder": "",
			"pattern": "",
			"patternMsg": "",
			"required": false,
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"endclient_reference_id": {
			"label": "End Client ID",
			"placeholder": "",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"end_client_contact_id": {
			"label": "Contact",
			"placeholder": "",
			"pattern": "",
			"patternMsg": "",
			"required": false,
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"bill_type": {
			"label": "Bill Rate Type",
			"placeholder": "",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true
		},
		"bill_rate": {
			"label": "Bill Rate per Hour ($)",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"maxLength": 4,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"ot_bill_rate": {
			"label": "OT Bill Rate per Hour ($)",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"maxLength": 4,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"effective_from": {
			"label": "Start Date",
			"placeholder": null,
			"required": true,
			"requiredMsg": "This field is required",
			"maxLength": null,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		}
	},
	"placement_details": {
		"job_title_id": {
			"label": "Job Title",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"work_email_id": {
			"label": "Work Email ID",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"recruiter_id": {
			"label": "Recruiter",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"work_location_type": {
			"label": "Work Location",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"maxLength": 40,
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"office": {
			"address_line_one": {
				"placeholder": "",
				"label": "Address Line 1",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"address_line_two": {
				"placeholder": "",
				"label": "Address Line 2",
				"required": false,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"zip_code": {
				"label": "Zipcode",
				"zipcodeLabel": "Zipcode",
				"pincodeLabel": "Pincode",
				"placeholder": "",
				"maxLength": 6,
				"zipcodeLength": 5,
				"pincodeLenght": 6,
				"pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			},
			"city": {
				"label": "City",
				"placeholder": "",
				"maxLength": 50,
				"pattern": "/^[a-zA-Z0-9 ]+$/",
				"patternMsg": "Please enter a valid city",
				"required": true,
				"requiredMsg": "This field is required",
				"isVisible": true,
				"isDisabled": false
			},
			"state": {
				"label": "State",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": true
			},
			"country": {
				"label": "Country",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			}
		},
		"remote": {
			"address_line_one": {
				"placeholder": "",
				"label": "Address Line 1",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"address_line_two": {
				"placeholder": "",
				"label": "Address Line 2",
				"required": false,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"zip_code": {
				"label": "Zipcode",
				"zipcodeLabel": "Zipcode",
				"pincodeLabel": "Pincode",
				"placeholder": "",
				"maxLength": 6,
				"zipcodeLength": 5,
				"pincodeLenght": 6,
				"pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			},
			"city": {
				"label": "City",
				"placeholder": "",
				"maxLength": 50,
				"pattern": "/^[a-zA-Z0-9 ]+$/",
				"patternMsg": "Please enter a valid city",
				"required": true,
				"requiredMsg": "This field is required",
				"isVisible": true,
				"isDisabled": false
			},
			"state": {
				"label": "State",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": true
			},
			"country": {
				"label": "Country",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			}
		},
		"supporting_documents": {
			"format": [
				"pdf",
				"doc",
				"docx"
			],
			"formatMsg": "Please upload files in PDF,Docs only",
			"size": 25,
			"sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
			"totalFiles": 10,
			"totalFilesMsg": "You can upload a maximum of 10 files at a time.",
			"totalFilesExcedMsg": "You can upload a maximum of 10 files at a time. Please remove excess files and try again",
			"label": {
				"name": "Add your PO, MSA, COI etc..",
				"format": "Document Size 25MB, Format - PDF, Docs"
			},
			"uploaded_docs": {
				"label": "Uploaded Documents"
			}
		}
		
	},
	"pay_rate_details":{
		"pay_type":{
			"label": "Pay Type",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"annual_pay": {
			"label": "Annual Pay",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "/^\\d*\\.?\\d{0,2}$/",
			"maxLength": 13,
			"patternMsg": "Please enter only numbers",
			"isVisible": true,
			"isDisabled": false
		},
		"from_hour": {
			"label": "From Hours",
			"isVisible": true
		},
		"to_hour": {
			"label": "To Hours",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"value": {
			"value1":{
				"label": "Percentage",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxValue": 100,
				"maxValueMsg": "Percentage is not allowed more than 100%",
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			},
			"value2":{
				"label": "Value",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 4,
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			}
			
		},
		"ot_pay_config_value":{
			"value2":{
				"label": "OT Pay Rate",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required sdv",
				"maxLength": 4,
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			},
			"value3":{
				"label": "OT Pay Rate Multiplier",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required jkjkl",
				"maxValue": 99.99,
				"maxValueMsg": "OT Pay Rate Multiplier should be less than 100",
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			}
		}
	},
	"timesheet_details":{
		"timesheet_cycle":{
			"label": "Timesheet Cycle",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"default_hours":{
			"label": "Default Hours",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"timesheet_start_date": {
			"label": "Timesheet Effective Start Date",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"timesheet_start_day":{
			"label": "Week Start Day",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		}
	}
}

export const editPlacementDefaultConfig = {
	"client_details": {
		"client_name": {
			"label": "Client Name",
			"isVisible": true
		},
		"client_reference_id": {
			"label": "Client ID",
			"isVisible": true
		},
		"client_contact_id": {
			"placeholder": "",
			"label": "Contact",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"end_client_name": {
			"label": "End Client Name",
			"isVisible": true
		},
		"end_client_reference_id": {
			"label": "End Client ID",
			"isVisible": true
		},
		"end_client_contact_id": {
			"placeholder": "",
			"label": "End Client Contact",
			"required": false,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"lca_amount": {
			"label": "Minimum Annual Wage (As per LCA)",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"maxLength": 14,
			"minValue": 1,
			"minValueMsg": "Value must be greater than 0",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		}
	},
	"billing_details": {
		"bill_type": {
			"label": "Bill Rate Type",
			"isVisible": true
		},
		"bill_rate": {
			"label": "Bill Rate Per Hour",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "/^\\d*\\.?\\d{0,2}$/",
			"patternMsg": "Please enter only numbers",
			"isVisible": true,
			"isDisabled": false
		},
		"ot_bill_rate": {
			"label": "OT Bill Rate",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"pattern": "/^\\d*\\.?\\d{0,2}$/",
			"patternMsg": "Please enter only numbers",
			"isVisible": true,
			"isDisabled": false
		},
		"effective_from": {
			"label": "Start Date",
			"placeholder": null,
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"end_date": {
			"label": "End Date",
			"isVisible": true
		},
		"start_and_end_date": {
			"label": " Start Date - End Date",
			"isVisible": true
		}
	},
	"placement_details": {
		"job_title_id": {
			"label": "Job Title",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"work_email_id": {
			"label": "Work Email ID",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
			"patternMsg": "Please enter a valid Email Id",
			"isVisible": true,
			"isDisabled": false
		},
		"recruiter_id": {
			"label": "Recruiter Name",
			"placeholder": "",
			"required": false,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"end_date": {
			"label": "End Date",
			"required": false,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"work_location_type": {
			"label": "Work Location",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"office": {
			"address_line_one": {
				"placeholder": "",
				"label": "Address Line 1",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"address_line_two": {
				"placeholder": "",
				"label": "Address Line 2",
				"required": false,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"zip_code": {
				"label": "Zip code",
				"zipcodeLabel": "Zipcode",
				"pincodeLabel": "Pincode",
				"placeholder": "",
				"maxLength": 5,
				"zipcodeLength": 5,
				"pincodeLenght": 6,
				"pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			},
			"city": {
				"label": "City",
				"placeholder": "",
				"maxLength": 50,
				"pattern": "/^[a-zA-Z0-9 ]+$/",
				"patternMsg": "Please enter a valid city",
				"required": true,
				"requiredMsg": "This field is required",
				"isVisible": true,
				"isDisabled": false
			},
			"state": {
				"label": "State",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": true
			},
			"country": {
				"label": "Country",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			}
		},
		"remote": {
			"address_line_one": {
				"placeholder": "",
				"label": "Address Line 1",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"address_line_two": {
				"placeholder": "",
				"label": "Address Line 2",
				"required": false,
				"requiredMsg": "This field is required",
				"maxLength": 225,
				"pattern": "^[a-zA-Z0-9 !_#&(\\)\\[\\]\\/<>.,';\\-\\\\]*$",
				"patternMsg": "Please enter a valid address",
				"isVisible": true,
				"isDisabled": false
			},
			"zip_code": {
				"label": "Zip code",
				"zipcodeLabel": "Zipcode",
				"pincodeLabel": "Pincode",
				"placeholder": "",
				"maxLength": 5,
				"zipcodeLength": 5,
				"pincodeLenght": 6,
				"pattern": "/^(?!0+$|1+$)\\d{5,6}$/",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			},
			"city": {
				"label": "City",
				"placeholder": "",
				"maxLength": 50,
				"pattern": "/^[a-zA-Z0-9 ]+$/",
				"patternMsg": "Please enter a valid city",
				"required": true,
				"requiredMsg": "This field is required",
				"isVisible": true,
				"isDisabled": false
			},
			"state": {
				"label": "State",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": true
			},
			"country": {
				"label": "Country",
				"placeholder": "",
				"maxLength": null,
				"pattern": "",
				"patternMsg": "",
				"isVisible": true,
				"isDisabled": false
			}
		},
		"supporting_documents": {
			"format": [
				"image/jpg",
				"image/jpeg",
				"image/png",
				"application/pdf"
			],
			"formatMsg": "please upload document Format PDF/Images",
			"size": 25,
			"sizeMsg": "File size must be 25 MB or less. Please upload a smaller file",
			"totalFiles": 5,
			"totalFilesMsg": "Upload 5 Documents Only",
			"totalFilesExcedMsg": "You can only upload up to 5 documents.",
			"label": {
				"name": "Add your PO, MSA, COI etc..",
				"format": "Document Size 25MB, Format - PDF, Images"
			},
			"uploaded_docs": {
				"label": "Uploaded Documents"
			}
		}
	},
	"pay_rate_details": {
		"pay_type": {
			"label": "Pay Type",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"annual_pay": {
			"label": "Annual Pay",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "/^\\d*\\.?\\d{0,2}$/",
			"maxLength": 9,
			"patternMsg": "Please enter only numbers",
			"isVisible": true,
			"isDisabled": false
		},
		"payroll_configuration_id": {
			"label": "Upcoming Check Dates",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"from_hour": {
			"label": "From Hours",
			"isVisible": true
		},
		"to_hour": {
			"label": "To Hours",
			"placeholder": "",
			"required": true,
			"requiredMsg": "This field is required",
			"pattern": "",
			"patternMsg": "",
			"isVisible": true,
			"isDisabled": false
		},
		"value": {
			"value1": {
				"label": "Percentage",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxValue": 100,
				"maxValueMsg": "Percentage is not allowed more than 100%",
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			},
			"value2": {
				"label": "Value",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 4,
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			}

		},
		"ot_pay_config_value": {
			"value2": {
				"label": "OT Pay Rate",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxLength": 4,
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			},
			"value3": {
				"label": "OT Pay Rate Multiplier",
				"placeholder": "",
				"required": true,
				"requiredMsg": "This field is required",
				"maxValue": 99.99,
				"maxValueMsg": "OT Pay Rate Multiplier should be less than 100",
				"pattern": "/^-?\\d+(\\.\\d+)*$/",
				"patternMsg": "Please enter only number",
				"isVisible": true,
				"isDisabled": false
			}
		}
	},
	"timesheet_details": {
		"timesheet_cycle": {
			"label": "Timesheet Cycle",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"default_hours": {
			"label": "Default Hours",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"timesheet_start_date": {
			"label": "Timesheet Actual Start Date",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": true
		},
		"timesheet_next_cycle_start": {
			"label": "Timesheet Effective Start Date",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"timesheet_start_day": {
			"label": "Week Start Day",
			"placeholder": "",
			"required": true,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
		"approval_users": {
			"label": "Approvers",
			"required": false,
			"pattern": "",
			"patternMsg": "",
			"requiredMsg": "This field is required",
			"isVisible": true,
			"isDisabled": false
		},
	}

}
