import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Stack, Dialog, Slide, DialogContent, SwipeableDrawer, useTheme } from '@mui/material';
import Text from '../../../../../components/customText/Text';
import Button from '../../../../../components/customButton/Button';
import Input from '../../../../../components/input/Input';
import moment from 'moment';
import Date from '../../../../../components/datePicker/Date';
import CommonApi from '../../../../../apis/CommonApi';
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, capitalizeWords, checkZipcode, dateFormat, findVisa, getCountryDetails, removeLoader } from '../../../../../utils/utils';
import LocalStorage from '../../../../../utils/LocalStorage';
import Select from '../../../../../components/select/Select';
import { validate_input_fields, validate_zipcode_input, isValid, onCharactersAndNumbersWithSpaceChange, onNumberWithTwoDecimalOnlyChangeCurrency, onNumberWithTwoDecimalOnlyChangeWithNegative, onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency, validate_emptyField, validate_ssn_mandatory, validates_emailId } from '../../../../../components/Validation';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/ExpenseDeleteIcon.svg'
import settingIcon from '../../../../../assets/svg/settingsicon.svg'
import placementEnd from '../../../../../assets/svg/successimage.svg';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import { debounce } from 'lodash';
import Zipcode from '../../../Zipcode';
import { useZipcodeAPICall } from '../../../ZipcodeCommon';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';
import { styled } from '@mui/material/styles';
import ActivityLog from '../../../../../components/activityLog/ActivityLog';
import UserProfileApi from '../../../../../apis/admin/userprofile/UserProfileApi';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function EditGeneralDetails(props) {
    // eslint-disable-next-line
    const generalDetailsConfig = props.generalDetailsConfig?.basicDetails;
    const contactDetailsConfig = props.generalDetailsConfig?.contactDetails;
    const currentAddressDetailsConfig = props.generalDetailsConfig?.currentAddressDetails;
    const employmentDetailsConfig = props.generalDetailsConfig?.employmentDetails;
    const openingBalanceConfig = props.generalDetailsConfig?.openingBalance;
    const [error, setError] = useState({})// eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
    const genderList = require('../../../../../utils/jsons/Gender.json');
    const bloodGroups = require('../../../../../utils/jsons/BloodGroup.json');
    const IsEmpUSA = [
        {
            id: 1,
            value: 'Yes'
        },
        {
            id: 0,
            value: 'No'
        }
    ]

    const [state, setState] = useState(props.employee)
    const [visaList, setVisaList] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);// eslint-disable-next-line
    const [args, setArgs] = useState('')
    const [val, setVal] = useState('')
    const [category, setCategory] = useState([]);// eslint-disable-next-line
    const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [openingBalancePopup, setOpeningBalancePopUp] = useState(false);
    const [current_balancePopup, setcurrent_balancePopup] = useState(false);
    const [editOpeningBalance, setEditOpeningBalance] = useState(false);
    const [activityLogDrawer, setActivityLogDrawer] = useState(false);
    const [successEdit, setSuccessEdit] = useState(false)
    const theme = useTheme();
    const [loader, setLoader] = useState(false);
    const [w4Show, setw4Show] = useState(false);
    const [vendorTypes, setVendorTypes] = useState([]);
    const [clearPopup4, setclearPopup4] = useState(false);
    const [maritalList, setMaritalList] = useState([]);
    const [openingBlalancepopup, setOpeningBalancePopValues] = useState({
        opening_balance: '',
        new_opening_balance: null,
        balance_impact: '',
        modification: ''
    });
    const [errorOpeningBalance, errorSetOpeningBalance] = useState({})
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    const [eye, setEye] = useState({ ssn: false });

    useEffect(() => {
        employmentList();
        visaListApi();
        vendorListApi();
        maritalListApi();
        categoryList(state.employment_type_id)
        EmployeesApi.getEmployee(props.employee.id).then((response) => {
            setState({ ...response.data.data });
            if (response.data.data.deposit_form_documents == undefined || response.data.data.deposit_form_documents == null) {
                state.deposit_form_documents = [{
                    id: '',
                    new_document_id: '',
                    document_url: '',
                    document_name: ''
                }]
                setState({ ...state })
            }
        }
        )
        if (state.deposit_form_documents == undefined || state.deposit_form_documents == null) {
            state.deposit_form_documents = [{
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: ''
            }]
            setState({ ...state })
        }
        // eslint-disable-next-line
    }, [])

    const handleChangeOpeningBalance = (e) => {
        setOpeningBalancePopValues({ ...openingBlalancepopup, [e.target.name]: e.target.value }, handleValidateOpeningBalance(e))
    }

    const handleChangeOpeningBalanceAmount = (e, name, type) => {

        var value = e.target.value.trim();
        value = value.replace("$", "");
        if (value.length > 10) {
            const num = parseFloat(value);
            const isFloat = value.includes('.') || num % 1 !== 0;
            if (isFloat) {
                openingBlalancepopup[e.target.name] = value;
            } else {
                if (value.length === 11) {
                    if (value.slice(-1) === ".") {
                        openingBlalancepopup[e.target.name] = value;
                    } else {
                        return false;
                    }
                }
            }
        }
        else {
            openingBlalancepopup[e.target.name] = value;
        }
        setOpeningBalancePopValues({ ...openingBlalancepopup }, handleValidateOpeningBalance(e));
    };

    const handleValidateOpeningBalance = (e) => {
        let input = e.target
        switch (input.name) {

            case "new_opening_balance":
                errorOpeningBalance.new_opening_balance = input.value == '-' ? 'Please enter valid number' : validate_input_fields(input.value, openingBalanceConfig?.new_opening_balance)
                break;
            case "balance_impact":
                errorOpeningBalance.balance_impact = validate_input_fields(input.value, openingBalanceConfig?.balance_impact)
                break;
            case "modification":
                errorOpeningBalance.modification = validate_input_fields(input.value, openingBalanceConfig?.modification)
                break;
            default:
                break;
        }
        errorSetOpeningBalance({ ...errorOpeningBalance })
    }
    const [openingBalSubmit, setOpeningBalSubmit] = useState(false);
    const handleOpeningBalanceSave = () => {
        //const data = { comment: values.comment, payroll_payment_details_id: paymentId }
        const errorOpeningBalance = validateAllOpeningBalance();

        if (isValid(errorOpeningBalance)) {
            if (current_balancePopup) {
                let data = {
                    request_id: LocalStorage.uid(),
                    employee_id: props.employee.id,
                    old_balance_amount: state.balance_amount,
                    new_balance_amount: openingBlalancepopup.new_opening_balance,
                    impact_balance: parseFloat(((parseFloat(openingBlalancepopup.new_opening_balance) || 0) - parseFloat(state.balance_amount)).toFixed(3)),
                    reason_for_change: openingBlalancepopup.modification
                }
                setOpeningBalSubmit(true);
                UserProfileApi.openingCurrentUpdate(data).then((res) => {
                    setOpeningBalSubmit(false);
                    if (res.data.statusCode == 1003) {
                        addSuccessMsg(res.data.message)
                        setEditOpeningBalance(false)
                        setSuccessEdit(true)
                        setEye({
                            ssn: false
                        })
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                let data = {
                    request_id: LocalStorage.uid(),
                    employee_id: props.employee.id,
                    old_balance_amount: state.opening_balance,
                    new_balance_amount: openingBlalancepopup.new_opening_balance,
                    impact_balance: parseFloat(((parseFloat(openingBlalancepopup.new_opening_balance) || 0) - parseFloat(state.opening_balance)).toFixed(3)),
                    reason_for_change: openingBlalancepopup.modification
                }
                setOpeningBalSubmit(true);
                UserProfileApi.openingBalanceUpdate(data).then((res) => {
                    setOpeningBalSubmit(false);
                    if (res.data.statusCode == 1003) {
                        addSuccessMsg(res.data.message)
                        setEditOpeningBalance(false);
                        setSuccessEdit(true)
                        setEye({
                            ssn: false
                        })
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                })
            }

        }
    }

    const handleOpeningBalanceCancel = () => {
        setEditOpeningBalance(false);


    }

    const validateAllOpeningBalance = () => {
        const { new_opening_balance,
            modification } = openingBlalancepopup
        let errorOpeningBalance = {}

        errorOpeningBalance.new_opening_balance = new_opening_balance == '-' ? 'Please enter valid number' : validate_input_fields(new_opening_balance, openingBalanceConfig?.new_opening_balance);

        // errorOpeningBalance.balance_impact = validate_emptyField(balance_impact);
        errorOpeningBalance.modification = validate_input_fields(modification, openingBalanceConfig?.modification);
        errorSetOpeningBalance({ ...errorOpeningBalance })
        return errorOpeningBalance;
    }



    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }
    // eslint-disable-next-line
    const categoryList = (args) => {
        CommonApi.CategoryList(LocalStorage.uid(), args, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                const capitalizedData = res.data.data.map((item) => ({
                    ...item,
                    value: capitalizeWords(item.value),
                }));
                setCategory(capitalizedData);
                // setCategory(res.data.data);
            }
        })
    }

    const visaListApi = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaList(res.data.data);
            }
        })
    }

    const fetchZipcodeData = useZipcodeAPICall({ setState, setError });
    const handleChange = (e) => {
        if (e.target.name == 'zip_code') {
            fetchZipcodeData(e.target.value)
        }
        if (e.target.name === "health_insurance_amount" || e.target.name === "standard_pay_amount" || e.target.name === "opening_balance" || e.target.name === "current_balance") {
            let value1 = e.target.value.trim();
            if (value1 == "$" || value1 == "") {
                state[e.target.name] = "";
                setState({ ...state }, handleValidate(e));
                return;
            }
            value1 = value1.replace(currency, "");
            const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,3})$/;
            const numericValue = parseFloat(value1);
            if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
                state[e.target.name] = value1;
            } else {
                return false;
            }
            setState({ ...state }, handleValidate(e));
        }
        else if (e.target.name == "enable_health_insurance" || e.target.name == "make_standard_pay_as_salary") {
            setState({
                ...state,
                [e.target.name]: e.target.checked
            }, handleValidate(e))
        }
        else if (e.target.name == 'contact_number' || e.target.name === 'ssn' || e.target.name === 'alternate_contact_number') {
            convertFormat(e);
            if (e.target.name == 'contact_number') {
                checkEmailHandle(e.target.value, 'mobile')
            }
            else {
                checkEmailHandle(e.target.value, 'ssn')
            }
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else if (e.target.name == 'is_usc') {
            if (e.target.value == 1) {
                setclearPopup(true)
            }
            else {
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                }, handleValidate(e))
            }
        }
        else if (e.target.name == 'company_id') {
            state.company_id = e.target.value;
            setState({ ...state })
            handleValidate(e)
        } else if (e.target.name == 'gender') {
            state[e.target.name] = e.target.value;
            state['gender_description'] = ''
            setState({ ...state })
            handleValidate(e)
        }
        else {
            if (e.target.name === "marital_status_id") {
                if ((props.employee.marital_status_id == 1 && e.target.value == 2) ||
                    (props.employee.marital_status_id == 2 && ([1, 3, 4, 5].includes(e.target.value)))) {
                    if (props.employee.bank_pending) {
                        setclearPopup4(true)
                        return true
                    } else {
                        setw4Show(true)
                    }
                }
                else {
                    setw4Show(false);
                    state.deposit_form_documents[0].new_document_id = ''
                    state.deposit_form_documents[0].id = ''
                    state.deposit_form_documents[0].document_url = ''
                    state.deposit_form_documents[0].document_name = ''
                }
                state.basic_details[e.target.name] = e.target.value
                state[e.target.name] = e.target.value
                setState({
                    ...state
                }, handleValidate(e))
            }
            if (e.target.name === 'country_id') {
                state.state_id = ''
                state.city = ''
                state.zip_code = ''
            }
            if (e.target.name === 'employment_type_id') {
                categoryList(e.target.value)
                state.employment_category_id = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e))

            if (e.target.name === 'email_id') {
                checkEmailHandle(e.target.value, 'email')
            }
        }

    }

    const resetStateWithError = (data) => {
        setState((prevState) => ({
            ...prevState,
            state_name: '',
            city: '',
            state_id: '',
            country_name: '',
            country_id: ''
        }));

        setError((prevError) => ({
            ...prevError,
            city: '',
            zip_code:
                data.length == 0 ? ErrorMessages.fieldRequired :
                    data.length == 6 ? ErrorMessages.invalidPincode : data.length == 5 ? ErrorMessages.invalidZipcode : ErrorMessages.invalidPinZipcode,
            state_id: '',
            country_id: ''
        }));
    };

    // eslint-disable-next-line
    const zipcodeAPICall = useCallback(
        debounce((data) => {
            CommonApi.getZipcodeData(data).then((res) => {
                const { statusCode, data: responseData } = res.data;

                if (statusCode === 1003) {
                    if (responseData.length > 0) {
                        const { state_name, country_name, city, state_id, country_id } = responseData[0];

                        setState((prevState) => ({
                            ...prevState,
                            state_name,
                            country_name, // Ensure country is being set in state
                            city,
                            state_id,
                            country_id
                        }));
                        setError((prevError) => ({
                            ...prevError,
                            city: '',
                            zip_code: '',
                            state_id: '',
                            country_id: ''
                        }));
                    } else {
                        resetStateWithError(data);
                    }
                } else {
                    resetStateWithError(data);
                }
            });

        }, 500), // 500ms delay
        []
    );




    const checkEmail = (args, val) => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'email') {
                            setError({
                                ...error,
                                // email_id: "Email ID already exists"
                                email_id: res?.data?.employee_id ? `The entered email ID ${obj.email_id} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}` : 'The entered email ID is already linked to an employee who has been invited.'
                            })

                        }
                        else if (val == 'mobile') {
                            setError({
                                ...error,
                                // contact_number: "Mobile Number already exists"
                                contact_number: res?.data?.employee_id ? `The entered phone number ${Mobileobj.contact_number} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}` : 'The entered phone number is already linked to an employee who has been invited.'
                            })
                        }
                        else {
                            setError({
                                ...error,
                                ssn: "SSN already exists"
                            })
                        }
                    }
                }
                else {
                    addErrorMsg(res.data.message)
                }
            });
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }

    const checkEmailHandle = (args, val) => {
        if (val == 'email' && props.employee.email_id == args) {
            return
        }
        setArgs(args);
        setVal(val)

    }

    const deleteDoc = (index, args) => {
        state.deposit_form_documents[0].new_document_id = ''
        state.deposit_form_documents[0].document_name = ''
        setState({ ...state })
    }



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            let err = validates_emailId(obj.email_id)
            let errSSN = validate_ssn_mandatory(ssnObj.ssn)
            if (Mobileobj.contact_number.length > 11 || (err == '') || (errSSN == '')) {
                EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (res.data.valid) {

                        } else {
                            if (val == 'email') {
                                // error.email_id = 'Email ID already exists'
                                error.email_id = res?.data?.employee_id ? `The entered email ID ${obj.email_id} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}` : 'The entered email ID is already linked to an employee who has been invited.'
                                setError({ ...error })
                            }
                            else if (val == 'mobile') {
                                // error.contact_number = "Mobile Number already exists"
                                error.contact_number = res?.data?.employee_id ? `The entered phone number ${Mobileobj.contact_number} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}` : 'The entered phone number is already linked to an employee who has been invited.'
                                setError({ ...error })
                            }
                            else {
                                error.ssn = "SSN already exists"
                                setError({ ...error })
                            }
                        }
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                });
            }

        }, 300)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line
    }, [args, val])

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (last == '0000') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (middle == '00') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidate(e));
            if (name == 'ssn') {
                if (first == '000' || first == '666' || first == '900') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_input_fields(input.value, generalDetailsConfig?.first_name);
                break;
            case "middle_name":
                error.middle_name = validate_input_fields(input.value, generalDetailsConfig?.middle_name);
                break;
            case "last_name":
                error.last_name = validate_input_fields(input.value, generalDetailsConfig?.last_name);
                break;
            case 'dob':
                error.dob = validate_input_fields(input.value, generalDetailsConfig?.dob);
                break
            case 'gender':
                error.gender = validate_input_fields(input.value, generalDetailsConfig?.gender);
                break
            case 'blood_group':
                error.blood_group = validate_input_fields(input.value, generalDetailsConfig?.blood_group)
                break
            case 'marital_status_id':
                error.marital_status_id = validate_input_fields(input.value, generalDetailsConfig?.marital_status)
                break
            case 'contact_number':
                if ((input.value != "" || input.value != null) && input.value.length == 12 && input.value == state.alternate_contact_number) {
                    error.contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                } else if (input.value.length == 12 && input.value !== state.alternate_contact_number) {
                    error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : validate_input_fields(state.alternate_contact_number, contactDetailsConfig?.alternate_contact_number)
                    error.contact_number = validate_input_fields(input.value, contactDetailsConfig?.contact_number)
                } else {
                    error.contact_number = validate_input_fields(input.value, contactDetailsConfig?.contact_number)
                    error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : validate_input_fields(state.alternate_contact_number, contactDetailsConfig?.alternate_contact_number)
                }
                break;
            case 'alternate_contact_number':
                if ((input.value != "" || input.value != null) && input.value.length == 12 && input.value == state.contact_number) {
                    error.alternate_contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                } else if (input.value.length == 12 && state.contact_number.length == 12 && input.value !== state.contact_number) {
                    error.alternate_contact_number = validate_input_fields(input.value, contactDetailsConfig?.alternate_contact_number);
                    error.contact_number = !error.contact_number?.startsWith('The entered phone number') ? validate_input_fields(state.contact_number, contactDetailsConfig?.contact_number) : error.contact_number
                } else {
                    error.alternate_contact_number = usMobileNumberValidator.test(input.value) ? '' : validate_input_fields(input.value, contactDetailsConfig?.alternate_contact_number)
                    error.contact_number = !error.contact_number?.startsWith('The entered phone number') ? validate_input_fields(state.contact_number, contactDetailsConfig?.contact_number) : error.contact_number
                }
                break
            case 'email_id':
                if (input.value != "" && (input.value.toLowerCase()) == (state.alternate_email_id.toLowerCase())) {
                    error.email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.email_id = validate_input_fields(input.value, contactDetailsConfig?.email_id)
                    error.alternate_email_id = validate_input_fields(state.alternate_email_id, contactDetailsConfig?.alternate_email_id)
                    // error.alternate_email_id = ''
                }
                break
            case 'alternate_email_id':
                if (input.value !== '' && ((input.value.toLowerCase()) == (state.email_id.toLowerCase()))) {
                    error.alternate_email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.alternate_email_id = validate_input_fields(input.value, contactDetailsConfig?.alternate_email_id)
                    error.email_id = !error.email_id?.startsWith('The entered email ID') ? validate_input_fields(state.email_id, contactDetailsConfig?.email_id) : error.email_id
                    // error.email_id = ''
                }

                break
            case 'address_line_one':
                error.address_line_one = validate_input_fields(input.value, currentAddressDetailsConfig?.address_line_one)
                break
            case 'address_line_two':
                error.address_line_two = validate_input_fields(input.value, currentAddressDetailsConfig?.address_line_two)
                break
            case 'zip_code':
                error.zip_code = validate_zipcode_input(input.value, currentAddressDetailsConfig?.location?.zip_code)
                break
            case 'city':
                error.city = validate_input_fields(input.value, currentAddressDetailsConfig?.location?.city)
                break
            case 'country_id':
                error.country_id = validate_emptyField(input.value)
                break
            case 'state_id':
                error.state_id = validate_emptyField(input.value)
                break
            case 'reference_id':
                error.reference_id = validate_emptyField(input.value)
                break
            case 'date_of_joining':
                error.date_of_joining = validate_input_fields(input.value, employmentDetailsConfig?.date_of_joining)
                break
            case 'employment_type_id':
                error.employment_type_id = validate_input_fields(input.value, employmentDetailsConfig?.employment_type)
                error.role_id = "";
                break
            case 'employment_category_id':
                error.employment_category_id = validate_input_fields(input.value, employmentDetailsConfig?.employment_category)
                break
            case 'company_id':
                error.company_id = validate_input_fields(input.value, employmentDetailsConfig?.vendor)
                break
            case 'department_id':
                error.department_id = validate_emptyField(input.value);
                break;
            case 'team_id':
                error.team_id = validate_emptyField(input.value);
                break;
            case 'ssn':
                error.ssn = usMobileNumberValidator.test(input.value) ? '' : validate_input_fields(input.value, employmentDetailsConfig?.ssn)
                break
            case 'is_usc':
                error.is_usc = validate_input_fields(input.value, employmentDetailsConfig?.is_us_citizen)
                break
            case 'visa_type_id':
                error.visa_type_id = validate_input_fields(input.value, employmentDetailsConfig?.visa_type)
                break
            case 'role_id':
                error.role_id = validate_emptyField(input.value)
                break
            case 'gender_description':
                error.gender_description = validate_input_fields(input.value, generalDetailsConfig?.gender_description);
                break
            case 'deposit_form_documents':
                error.deposit_form_documents = validate_emptyField(input.value)
                break
            case 'health_insurance_amount':
                error.health_insurance_amount = validate_input_fields(input.value === currency ? "" : input.value, employmentDetailsConfig?.insurance_amount)
                break
            case 'standard_pay_amount':
                error.standard_pay_amount = validate_input_fields(input.value === currency ? "" : input.value, employmentDetailsConfig?.standard_wage)
                break
            default:
                break
        }
        setError({ ...error })
    }

    const checkJoiningDateError = (date_of_joining, rejoin_date) => {
        if (date_of_joining && date_of_joining !== '' && date_of_joining != 'Invalid Date') {
            if (rejoin_date && rejoin_date !== '' && rejoin_date !== 'Invalid Date') {
                error.date_of_joining = moment(date_of_joining, dateFormat()).isBefore(rejoin_date) ? 'Please select a date that is greater than Re-Joining date' : ''
            }
            else if (moment(date_of_joining, LocalStorage.getDateFormat()).isBefore(moment(state.dob, LocalStorage.getDateFormat()))) {
                error.date_of_joining = "Please select a date after your birth date."
            }
            else {
                error.date_of_joining = validate_input_fields(date_of_joining, employmentDetailsConfig?.date_of_joining)
            }
        } else {
            error.date_of_joining = 'Please select a valid date'
        }
        setError({ ...error })
    }

    const validateAll = async () => {
        const { first_name, last_name, dob, gender, contact_number, employment_type_id, email_id, address_line_one, zip_code, city, country_id, state_id, date_of_joining, employment_category_id, ssn, // eslint-disable-next-line
            is_usc, visa_type_id, address_line_two, company_id, rejoin_date, gender_description, health_insurance_amount, standard_pay_amount, middle_name } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        error.health_insurance_amount = state.enable_health_insurance ? health_insurance_amount == 0 ? ErrorMessages.greaterThanZero : validate_input_fields(health_insurance_amount, employmentDetailsConfig?.insurance_amount) : ''
        error.standard_pay_amount = findVisa(visaList, state.visa_type_id) ? /*standard_pay_amount == 0 ? ErrorMessages.greaterThanZero :*/ validate_input_fields(standard_pay_amount, employmentDetailsConfig?.standard_wage) : ''
        error.first_name = validate_input_fields(first_name, generalDetailsConfig?.first_name)
        error.last_name = validate_input_fields(last_name, generalDetailsConfig?.last_name);
        error.middle_name = validate_input_fields(middle_name, generalDetailsConfig?.middle_name)
        error.visa_type_id = state.is_usc == 0 ? validate_input_fields(visa_type_id, employmentDetailsConfig?.visa_type) : ''
        error.dob = dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : 'Minimum age should be greater than 18' : validate_input_fields(dob, generalDetailsConfig?.dob);
        error.gender = validate_input_fields(gender, generalDetailsConfig?.gender);
        error.contact_number = error.contact_number?.startsWith('The entered phone number') ? error.contact_number : validate_input_fields(contact_number, contactDetailsConfig?.contact_number);
        error.email_id = error.email_id?.startsWith('The entered email ID') ? error.email_id : validate_input_fields(email_id, contactDetailsConfig?.email_id);
        error.address_line_one = validate_input_fields(address_line_one, currentAddressDetailsConfig?.address_line_one);
        error.address_line_two = validate_input_fields(address_line_two, currentAddressDetailsConfig?.address_line_two);
        error.zip_code = await checkZipcode(zip_code)
        error.city = validate_input_fields(city, currentAddressDetailsConfig?.location?.city)
        error.country_id = validate_emptyField(country_id, currentAddressDetailsConfig?.country_id)
        error.state_id = validate_emptyField(state_id, currentAddressDetailsConfig?.state_id)
        error.employment_type_id = validate_input_fields(employment_type_id, employmentDetailsConfig?.employment_type)
        error.employment_category_id = employment_type_id != 1 ? validate_input_fields(employment_category_id, employmentDetailsConfig?.employment_category) : ''
        error.company_id = employment_type_id == 3 ? validate_input_fields(company_id, employmentDetailsConfig?.vendor) : '';
        error.ssn = error.ssn == 'SSN already exists' ? 'SSN already exists' : validate_input_fields(ssn, employmentDetailsConfig?.ssn)
        error.is_usc = validate_input_fields(is_usc, employmentDetailsConfig?.is_us_citizen)
        error.deposit_form_documents = w4Show ? validate_emptyField(state.deposit_form_documents[0].new_document_id) : ''
        error.gender_description = state.gender == 'Prefer to self-describe' ? validate_input_fields(gender_description, generalDetailsConfig?.gender_description) : '';
        if (props.employee.email_id !== email_id) {
            checkEmail(email_id, 'email');
        }
        checkJoiningDateError(date_of_joining, rejoin_date)
        checkEmail(contact_number, 'mobile');
        checkEmail(state.ssn, 'ssn');
        setError({ ...error })
        return error;
    }

    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (Number(((value.target.files[0].size / (1024 * 1024)).toFixed(2))) < 25) {
            CommonApi
                .documentUpload("bank-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader();
                        let docInfo = response.data.data;
                        removeLoader()
                        state.deposit_form_documents[0].new_document_id = docInfo.id;
                        state.deposit_form_documents[0].document_url = docInfo.document_url;
                        state.deposit_form_documents[0].document_name = value.target.files[0].name;
                        error.deposit_form_documents = ''
                        setError({ ...error })
                        setState({ ...state });
                        removeLoader();
                    } else {
                        addErrorMsg(response.data.message);
                        removeLoader();
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
    };

    const saveForm = async () => {
        let errors = await validateAll();
        if (errors.deposit_form_documents == '') {
            setLoader(true);
            if (isValid(errors)) {
                state['request_id'] = LocalStorage.uid()
                state['marital_status_id'] = state.basic_details.marital_status_id
                OnBoardApi.employeeUpdate(state, LocalStorage.getAccessToken()).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setLoader(false)
                        setEye({ ssn: false })
                        props.getIndex()
                        addSuccessMsg('Changes Saved Successfully')
                        props.setEdit(false);
                        props.setformEdit(false)
                    }
                    else {
                        setLoader(false)
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                setLoader(false)
                addWarningMsg(ErrorMessages.genericMessage);
            }
        } else {
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const cancelForm = () => {
        props.setEdit(false)
        props.setformEdit(false)
        setState(props.employee)
        setError({})
        setEye({
            ssn: false
        })
    }

    const vendorListApi = () => {
        CommonApi.getVendorList('Active').then((res) => {
            if (res.data.statusCode === 1003) {
                setVendorTypes(res.data.data);
            }
        })
    }

    const maritalListApi = () => {
        CommonApi.maritalStatusList().then((res) => {
            if (res.data.statusCode === 1003) {
                setMaritalList(res.data.data);
            }
        })
    }

    return (
        <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            {generalDetailsConfig?.first_name?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'first_name',
                            value: state.first_name,
                            inputProps: { maxLength: generalDetailsConfig?.first_name?.maxLength }
                        }}
                        clientInput
                        handleChange={handleChange}
                        placeholder={generalDetailsConfig?.first_name?.placeholder}
                        labelText={generalDetailsConfig?.first_name?.required ? generalDetailsConfig?.first_name?.label : `${generalDetailsConfig?.first_name?.label} (Optional)`}
                        disabled={generalDetailsConfig?.first_name?.isDisabled}
                        error={error.first_name}
                    />
                    {
                        error.first_name ?
                            <Text red>{error.first_name ? error.first_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>}
            {generalDetailsConfig?.middle_name?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'middle_name',
                            value: state.middle_name,
                            inputProps: { maxLength: generalDetailsConfig?.middle_name?.maxLength }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.middle_name}
                        placeholder={generalDetailsConfig?.middle_name?.placeholder}
                        labelText={generalDetailsConfig?.middle_name?.required ? generalDetailsConfig?.middle_name?.label : `${generalDetailsConfig?.middle_name?.label} (Optional)`}
                        disabled={generalDetailsConfig?.middle_name?.isDisabled}
                    // labelText={error.middle_name ? 'Middle Name (Optional)' : <span className={classes.optional}>(Optional)</span>}
                    />
                    {
                        error.middle_name ?
                            <Text red>{error.middle_name ? error.middle_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>}
            {generalDetailsConfig?.last_name?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'last_name',
                            value: state.last_name,
                            inputProps: { maxLength: generalDetailsConfig?.last_name?.maxLength }
                        }}
                        handleChange={handleChange}
                        clientInput
                        placeholder={generalDetailsConfig?.last_name?.placeholder}
                        labelText={generalDetailsConfig?.last_name?.required ? generalDetailsConfig?.last_name?.label : `${generalDetailsConfig?.last_name?.label} (Optional)`}
                        disabled={generalDetailsConfig?.last_name?.isDisabled}
                        error={error.last_name}
                    />
                    {
                        error.last_name ?
                            <Text red>{error.last_name ? error.last_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>}
            {generalDetailsConfig?.dob?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        labelText={generalDetailsConfig?.dob?.required ? generalDetailsConfig?.dob?.label : `${generalDetailsConfig?.dob?.label} (Optional)`}
                        name='dob'
                        height='54px'
                        value={state.dob}
                        onChange={(value => dateChange(value, 'dob'))}
                        maxDate={moment().subtract(generalDetailsConfig?.dob?.ageLimit, "years")}
                        error={error.dob}
                        disabled={generalDetailsConfig?.dob?.isDisabled}
                    />
                    {
                        error.dob ?
                            <Text red>{error.dob ? error.dob : ''}</Text> : ''
                    }
                </Box>
            </Grid>}
            {generalDetailsConfig?.gender?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='gender'
                        value={state.gender}
                        onChange={handleChange}
                        options={genderList}
                        placeholder={generalDetailsConfig?.gender?.placeholder}
                        label={generalDetailsConfig?.gender?.required ? generalDetailsConfig?.gender?.label : `${generalDetailsConfig?.gender?.label} (Optional)`}
                        disabled={generalDetailsConfig?.gender?.isDisabled}
                        helperText={
                            error.gender ?
                                <Text red>{error.gender ? error.gender : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>}
            {
                (generalDetailsConfig?.gender_description?.isVisible && state.gender == 'Prefer to self-describe') ?
                    <Grid item lg={12} md={12} sm={12} xs={12} py={'4px'} px={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'gender_description',
                                value: state.gender_description,
                                inputProps: { minLength: 3, maxLength: generalDetailsConfig?.gender_description?.maxLength }
                            }}
                            handleChange={handleChange}
                            clientInput
                            placeholder={generalDetailsConfig?.gender_description?.placeholder}
                            labelText={generalDetailsConfig?.gender_description?.required ? generalDetailsConfig?.gender_description?.label : `${generalDetailsConfig?.gender_description?.label} (Optional)`}
                            disabled={generalDetailsConfig?.gender_description?.isDisabled}
                            error={error.gender_description}
                        />
                        {
                            error.gender_description &&
                            <Text red>{error.gender_description ? error.gender_description : ''}</Text>
                        }
                    </Grid> : ''
            }
            {generalDetailsConfig?.blood_group?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='blood_group'
                        value={state.blood_group}
                        onChange={handleChange}
                        options={bloodGroups}
                        placeholder={generalDetailsConfig?.blood_group?.placeholder}
                        label={generalDetailsConfig?.blood_group?.required ? generalDetailsConfig?.blood_group?.label : `${generalDetailsConfig?.blood_group?.label} (Optional)`}
                        disabled={generalDetailsConfig?.blood_group?.isDisabled}
                        helperText={error.blood_group ? <Text red>{error.blood_group ? error.blood_group : ''}</Text> : ''}
                    />
                </Box>
            </Grid>}
            {generalDetailsConfig?.marital_status?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='marital_status_id'
                        value={state.marital_status_id}
                        onChange={handleChange}
                        options={maritalList}
                        placeholder={generalDetailsConfig?.marital_status?.placeholder}
                        label={generalDetailsConfig?.marital_status?.required ? generalDetailsConfig?.marital_status?.label : `${generalDetailsConfig?.marital_status?.label} (Optional)`}
                        disabled={generalDetailsConfig?.marital_status?.isDisabled}
                        helperText={
                            error.marital_status_id ?
                                <Text red>{error.marital_status_id ? error.marital_status_id : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>}
            {
                w4Show
                    ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {generalDetailsConfig?.W4_document?.isVisible && <Box p={1} pt={2}>
                            <FileInput
                                name={"deposit_form_documents"}
                                FileName={state.deposit_form_documents ? state.deposit_form_documents[0].document_name : ''}
                                handleChange={(e) => uploadDocs(e, 0, "deposit_form_documents")}
                                label={generalDetailsConfig?.W4_document?.required ? generalDetailsConfig?.W4_document?.label : `${generalDetailsConfig?.W4_document?.label} (Optional)`}
                                isDisabled={generalDetailsConfig?.W4_document?.isDisabled}
                                handleDelete={() => deleteDoc()}
                                actionState={state.deposit_form_documents && state.deposit_form_documents[0].document_name ? 1 : ''}
                                documentUrl={state.deposit_form_documents && state.deposit_form_documents[0].document_url}
                                error={error.deposit_form_documents ? error.deposit_form_documents : ""}
                                state={state.deposit_form_documents && state.deposit_form_documents[0]}
                                labelSize={58}
                            />
                            <Text errorText> {error.deposit_form_documents ? error.deposit_form_documents : ""}</Text>
                        </Box>}
                    </Grid>
                    : ''
            }

            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            {contactDetailsConfig?.contact_number?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'contact_number',
                            value: state.contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={contactDetailsConfig?.contact_number?.required ? contactDetailsConfig?.contact_number?.label : `${contactDetailsConfig?.contact_number?.label} (Optional) `}
                        placeholder={contactDetailsConfig?.contact_number?.placeholder}
                        disabled={contactDetailsConfig?.contact_number?.isDisabled}
                        error={error.contact_number}
                    />
                    {
                        error.contact_number ?
                            <Text red>{error.contact_number ? error.contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>}
            {contactDetailsConfig?.alternate_contact_number?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_contact_number',
                            value: state.alternate_contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_contact_number}
                        labelText={contactDetailsConfig?.alternate_contact_number?.required ? contactDetailsConfig?.alternate_contact_number?.label : `${contactDetailsConfig?.alternate_contact_number?.label} (Optional) `}
                        placeholder={contactDetailsConfig?.alternate_contact_number?.placeholder}
                        disabled={contactDetailsConfig?.alternate_contact_number?.isDisabled}
                    />
                    {
                        error.alternate_contact_number ?
                            <Text red>{error.alternate_contact_number ? error.alternate_contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>}
            <Grid item lg={4} md={0} sm={0} xs={12}></Grid>
            {contactDetailsConfig?.email_id?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'email_id',
                            value: state.email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.email_id}
                        labelText={contactDetailsConfig?.email_id?.required ? contactDetailsConfig?.email_id?.label : `${contactDetailsConfig?.email_id?.label} (Optional) `}
                        placeholder={contactDetailsConfig?.email_id?.placeholder}
                        disabled={contactDetailsConfig?.email_id?.isDisabled}
                    />
                    {
                        error.email_id ?
                            <Text red>{error.email_id ? error.email_id : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>}
            {contactDetailsConfig?.alternate_email_id?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_email_id',
                            value: state.alternate_email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_email_id}
                        labelText={contactDetailsConfig?.alternate_email_id?.required ? contactDetailsConfig?.alternate_email_id?.label : `${contactDetailsConfig?.alternate_email_id?.label} (Optional) `}
                        placeholder={contactDetailsConfig?.alternate_email_id?.placeholder}
                        disabled={contactDetailsConfig?.alternate_email_id?.isDisabled}
                    />
                    {
                        error.alternate_email_id ?
                            <Text red>{error.alternate_email_id ? error.alternate_email_id : ''}</Text> : ''
                    }
                </Box>
            </Grid>}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'reference_id',
                            value: state.reference_id,
                            disabled: true
                        }}
                        disabled={true}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Employee ID'}
                    />
                </Box>
            </Grid>
            {employmentDetailsConfig?.date_of_joining?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        name='date_of_joining'
                        value={state.date_of_joining}
                        onChange={(value => dateChange(value, 'date_of_joining'))}
                        height='54px'
                        error={error.date_of_joining}
                        labelText={employmentDetailsConfig?.date_of_joining?.required ? employmentDetailsConfig?.date_of_joining?.label : `${employmentDetailsConfig?.date_of_joining?.label} (Optional)`}
                        disabled={employmentDetailsConfig?.date_of_joining?.isDisabled}
                        minDate={state.rejoin_date ? state.rejoin_date : state.dob}
                    />
                    {error.date_of_joining && <Text red>{error.date_of_joining ? error.date_of_joining : ''}</Text>}
                </Box>
            </Grid>}
            {employmentDetailsConfig?.employment_type?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='employment_type_id'
                        value={state.employment_type_id}
                        onChange={handleChange}
                        options={employmentType}
                        placeholder={employmentDetailsConfig?.employment_type?.placeholder}
                        label={employmentDetailsConfig?.employment_type?.required ? employmentDetailsConfig?.employment_type?.label : `${employmentDetailsConfig?.employment_type?.label} (Optional)`}
                        disabled={true}
                    />
                </Box>
            </Grid>}
            {
                (employmentDetailsConfig?.employment_category?.isVisible && state.employment_type_id !== 1) &&
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='employment_category_id'
                            value={state.employment_category_id}
                            onChange={handleChange}
                            options={category}
                            placeholder={employmentDetailsConfig?.employment_category?.placeholder}
                            label={employmentDetailsConfig?.employment_category?.required ? employmentDetailsConfig?.employment_category?.label : `${employmentDetailsConfig?.employment_category?.label} (Optional)`}
                            disabled={employmentDetailsConfig?.employment_category?.isDisabled}
                            helperText={error.employment_category_id && <Text red>{error.employment_category_id ? error.employment_category_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            {
                (employmentDetailsConfig?.vendor?.isVisible && state.employment_type_id == 3) &&
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='company_id'
                            value={state.company_id}
                            onChange={handleChange}
                            options={vendorTypes}
                            placeholder={employmentDetailsConfig?.vendor?.placeholder}
                            label={employmentDetailsConfig?.vendor?.required ? employmentDetailsConfig?.vendor?.label : `${employmentDetailsConfig?.vendor?.label} (Optional)`}
                            disabled={employmentDetailsConfig?.vendor?.isDisabled}
                            helperText={error.company_id && <Text red>{error.company_id ? error.company_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            {employmentDetailsConfig?.ssn?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'ssn',
                            value: state.ssn,
                            type: eye.ssn ? 'text' : 'password',
                            inputProps: { maxLength: 11 }
                        }}
                        eyeIcon={eye.ssn}
                        eyeCloseIcon={eye.ssn == false}
                        eyeHandleChange={() => {
                            setEye({
                                ...eye,
                                ssn: !eye.ssn
                            })
                        }}
                        handleChange={handleChange}
                        iconText
                        error={error.ssn}
                        placeholder={employmentDetailsConfig?.ssn?.placeholder}
                        labelText={employmentDetailsConfig?.ssn?.required ? employmentDetailsConfig?.ssn?.label : `${employmentDetailsConfig?.ssn?.label} (Optional)`}
                        disabled={(state?.e_verified == '0' || state?.e_verified == '3' || employmentDetailsConfig?.ssn?.isDisabled) ? false : true}
                    />
                    {error.ssn && <Text red>{error.ssn ? error.ssn : ''}</Text>}
                </Box>
            </Grid>}
            {employmentDetailsConfig?.is_us_citizen?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='is_usc'
                        value={state.is_usc}
                        onChange={handleChange}
                        options={IsEmpUSA}
                        placeholder={employmentDetailsConfig?.is_us_citizen?.placeholder}
                        label={employmentDetailsConfig?.is_us_citizen?.required ? employmentDetailsConfig?.is_us_citizen?.label : `${employmentDetailsConfig?.is_us_citizen?.label} (Optional)`}
                        disabled={employmentDetailsConfig?.is_us_citizen?.isDisabled}
                        helperText={
                            error.is_usc ?
                                <Text red>{error.is_usc ? error.is_usc : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>}
            {
                (employmentDetailsConfig?.visa_type?.isVisible && state.is_usc === 0) &&
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='visa_type_id'
                            value={state.visa_type_id}
                            onChange={handleChange}
                            options={visaList}
                            placeholder={employmentDetailsConfig?.visa_type?.placeholder}
                            label={employmentDetailsConfig?.visa_type?.required ? employmentDetailsConfig?.visa_type?.label : `${employmentDetailsConfig?.visa_type?.label} (Optional)`}
                            disabled={employmentDetailsConfig?.visa_type?.isDisabled}
                            helperText={
                                error.visa_type_id ?
                                    <Text red>{error.visa_type_id ? error.visa_type_id : ''}</Text> : ''
                            }
                        />
                    </Box>
                </Grid>
            }
            {employmentDetailsConfig?.opening_balance?.isVisible && <Grid item lg={4} md={6} sm={6} xs={12} >
                <Box p={1} pt={2} >
                    <Stack direction={'row'} gap={2}>

                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'opening_balance',
                                value: currency + '' + state.opening_balance,
                                inputProps: {
                                    maxLength: 16, // Maximum length for "9999999999.99"
                                    pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                },
                            }}
                            handleChange={(e) => handleChange(e)}
                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency}
                            clientInput
                            error={error.opening_balance}
                            placeholder={employmentDetailsConfig?.opening_balance?.placeholder}
                            labelText={employmentDetailsConfig?.opening_balance?.required ? employmentDetailsConfig?.opening_balance?.label : `${employmentDetailsConfig?.opening_balance?.label} (Optional)`}
                            disabled={employmentDetailsConfig?.opening_balance?.isDisabled}
                        />
                        {
                            props.employee.is_opening_balance_edit ?
                                <Grid item lg={1} md={1} sm={2} xs={12} sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                    <img style={{ cursor: 'pointer' }} src={settingIcon} alt='setting' onClick={() => { setOpeningBalancePopUp(true); setcurrent_balancePopup(false) }} />
                                </Grid>
                                : ''
                        }
                    </Stack>

                    {error.opening_balance && <Text red>{error.opening_balance ? error.opening_balance : ''}</Text>}
                    {props.employee.is_opening_balance_edit ? <Text mediumLabel>By clicking on the settings icon you can edit the Opening Balance</Text> : ''}


                </Box>
            </Grid>}

            {
                props.employee.is_opening_balance_edit ? '' :
                    <Grid item lg={4} md={4} sm={6} xs={12} >
                        <Box p={1} pt={2} >
                            <Stack direction={'row'} gap={2}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'current_balance',
                                        value: currency + '' + state.balance_amount || '',
                                        inputProps: {
                                            maxLength: 16, // Maximum length for "9999999999.99"
                                            pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                        },
                                    }}
                                    handleChange={(e) => handleChange(e)}
                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegativeCurrency}
                                    clientInput
                                    disabled={true}
                                    error={error.current_balance}
                                    labelText='Current Balance'
                                />
                                {
                                    props.employee.is_opening_balance_edit ? '' :
                                        <Grid item lg={1} md={1} sm={2} xs={12} sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ cursor: 'pointer' }} src={settingIcon} alt='setting' onClick={() => { setOpeningBalancePopUp(true); setcurrent_balancePopup(true) }} />
                                        </Grid>
                                }
                                <Box>

                                </Box>
                            </Stack>

                            {error.current_balance && <Text red>{error.current_balance ? error.current_balance : ''}</Text>}
                            <Text mediumLabel>By clicking on the settings icon you can edit the Current Balance</Text>
                        </Box>
                    </Grid>
            }

            {/* <Grid item lg={1} md={1} sm={2} xs={12} sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                <img src={timeIcon} alt='setting' onClick={() => setActivityLogDrawer(true)} />
            </Grid> */}
            {
                (employmentDetailsConfig?.standard_wage?.isVisible && findVisa(visaList, state.visa_type_id)) ?
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'standard_pay_amount',
                                    value: currency + '' + state.standard_pay_amount,
                                    inputProps: {
                                        maxLength: 16, // Maximum length for "9999999999.99"
                                        pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                    },
                                }}
                                handleChange={(e) => handleChange(e)}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                clientInput
                                error={error.standard_pay_amount}
                                placeholder={employmentDetailsConfig?.standard_wage?.placeholder}
                                labelText={employmentDetailsConfig?.standard_wage?.required ? employmentDetailsConfig?.standard_wage?.label : `${employmentDetailsConfig?.standard_wage?.label} (Optional)`}
                                disabled={employmentDetailsConfig?.standard_wage?.isDisabled}
                            />
                            {error.standard_pay_amount && <Text red>{error.standard_pay_amount ? error.standard_pay_amount : ''}</Text>}
                        </Box>
                    </Grid>
                    : ''
            }
            {(employmentDetailsConfig?.enable_health_insurance?.isVisible && state.employment_type_id != 3) ?
                <Grid item lg={12} md={12} sm={12} xs={12} pt={2} pl={2}>
                    <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                        <Checkbox name='enable_health_insurance' value={state.enable_health_insurance} checked={state.enable_health_insurance}
                            onChange={handleChange} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                            checkedIcon={<CheckedIcon />} disable={employmentDetailsConfig?.enable_health_insurance?.isDisabled} />
                        <Text mediumBlack>{employmentDetailsConfig?.enable_health_insurance?.label}</Text>
                    </Box>
                </Grid> : ''}
            {
                (employmentDetailsConfig?.insurance_amount?.isVisible && state.enable_health_insurance) ?
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'health_insurance_amount',
                                    value: currency + '' + state.health_insurance_amount,
                                    inputProps: {
                                        maxLength: 16, // Maximum length for "9999999999.99"
                                        pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                    },
                                }}
                                handleChange={(e) => handleChange(e)}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                clientInput
                                error={error.health_insurance_amount}
                                placeholder={employmentDetailsConfig?.insurance_amount?.placeholder}
                                labelText={employmentDetailsConfig?.insurance_amount?.required ? employmentDetailsConfig?.insurance_amount?.label : `${employmentDetailsConfig?.insurance_amount?.label} (Optional)`}
                                disabled={employmentDetailsConfig?.insurance_amount?.isDisabled}
                            />
                            {error.health_insurance_amount && <Text red>{error.health_insurance_amount ? error.health_insurance_amount : ''}</Text>}
                        </Box>
                    </Grid> : ''
            }
            {
                (employmentDetailsConfig?.make_standard_pay_as_salary?.isVisible && findVisa(visaList, state.visa_type_id)) ?
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2} pl={2}>
                        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                            <Checkbox name='make_standard_pay_as_salary' value={state.make_standard_pay_as_salary} checked={state.make_standard_pay_as_salary}
                                onChange={handleChange} disabled={employmentDetailsConfig?.make_standard_pay_as_salary?.isDisabled} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />} />
                            <Text mediumBlack>{employmentDetailsConfig?.make_standard_pay_as_salary?.label}</Text>
                        </Box>
                    </Grid>
                    : ''
            }
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                    </Text>
                </Box>
            </Grid>
            {currentAddressDetailsConfig?.address_line_one?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_one',
                            value: state.address_line_one,
                            inputProps: { maxLength: currentAddressDetailsConfig?.address_line_one?.maxLength }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={currentAddressDetailsConfig?.address_line_one?.required ? currentAddressDetailsConfig?.address_line_one?.label : `${currentAddressDetailsConfig?.address_line_one?.label} (Optional) `}
                        placeholder={currentAddressDetailsConfig?.address_line_one?.placeholder}
                        disabled={currentAddressDetailsConfig?.address_line_one?.isDisabled}
                        error={error.address_line_one}
                    />
                    {error.address_line_one && <Text red>{error.address_line_one ? error.address_line_one : ''}</Text>}
                </Box>
            </Grid>}
            {currentAddressDetailsConfig?.address_line_two?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_two',
                            value: state.address_line_two,
                            inputProps: { maxLength: currentAddressDetailsConfig?.address_line_two?.maxLength }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.address_line_two}
                        labelText={currentAddressDetailsConfig?.address_line_two?.required ? currentAddressDetailsConfig?.address_line_two?.label : `${currentAddressDetailsConfig?.address_line_two?.label} (Optional) `}
                        placeholder={currentAddressDetailsConfig?.address_line_two?.placeholder}
                        disabled={currentAddressDetailsConfig?.address_line_two?.isDisabled}
                    />
                    {error.address_line_two && <Text red>{error.address_line_two ? error.address_line_two : ''}</Text>}
                </Box>
            </Grid>}

            <Zipcode state={state} error={error} handleChange={handleChange} columnLength={4} apiConfig={currentAddressDetailsConfig?.location} />

            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
            <Grid item container justifyContent={'space-between'} lg={12} md={12} sm={12} xs={12} pt={2} px={1}>
                <Button sx={{ [theme.breakpoints.down('sm')]: { width: "fit-content !important" } }} disabled={loader} cancelOutlineQuicksand onClick={() => cancelForm()}>Cancel</Button>
                <LoadingButton sx={{ [theme.breakpoints.down('sm')]: { minWidth: "fit-content !important", height: "34px !important" } }} loading={loader} smallSaveLoader onClick={() => saveForm()}>Save</LoadingButton>
            </Grid>
            <ReusablePopup openPopup={openingBalancePopup} setOpenPopup={setOpeningBalancePopUp} white iconHide>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={Sure} alt='noactive' />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>
                            Do you want to make this changes to {current_balancePopup ? 'Current' : 'Opening'}
                        </Text>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>
                            Balance of this employee ?
                        </Text>
                    </Box>
                    <Box>
                        <Stack direction={"row"} spacing={3}>
                            <Button smallBlueOutline onClick={() => { setOpeningBalancePopUp(false); }}>
                                No
                            </Button>
                            <Button finishFilledQuicksand sx={{ width: '184px !important', [theme.breakpoints.down('sm')]: { width: "fit-content !important" } }} onClick={() => {
                                setOpeningBalancePopValues({
                                    ...openingBlalancepopup,
                                    opening_balance: '',
                                    new_opening_balance: null,
                                    balance_impact: '',
                                    modification: ''
                                });
                                errorSetOpeningBalance({});
                                setEditOpeningBalance(true); setOpeningBalancePopUp(false);
                            }}>
                                Yes, Continue
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </ReusablePopup>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={editOpeningBalance}
                fullWidth={true}
            // maxWidth={"md"}
            >
                <DialogContent >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'start' }}>
                            <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Edit {current_balancePopup ? 'Current' : 'Opening'} Balance</Text>
                        </Box>
                        <Grid container spacing={2}>
                            {(current_balancePopup ? openingBalanceConfig?.current_balance?.isVisible : openingBalanceConfig?.opening_balance?.isVisible) && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'opening_balance',
                                            value: current_balancePopup ? state.balance_amount : state.opening_balance,
                                            inputProps: { maxLength: current_balancePopup ? openingBalanceConfig?.current_balance?.maxLength : openingBalanceConfig?.opening_balance?.maxLength }
                                        }}
                                        sx={{ backgroundColor: '#D1E1FF !important' }}
                                        clientInput
                                        // handleChange={handleChange}
                                        labelText={current_balancePopup ? (openingBalanceConfig?.current_balance?.required ? openingBalanceConfig?.current_balance?.label : `${openingBalanceConfig?.current_balance?.label} (Optional)`) : (openingBalanceConfig?.opening_balance?.required ? openingBalanceConfig?.opening_balance?.label : `${openingBalanceConfig?.opening_balance?.label} (Optional)`)}
                                        disabled={current_balancePopup ? openingBalanceConfig?.current_balance?.isDisabled : openingBalanceConfig?.opening_balance?.isDisabled}
                                    />

                                </Box>
                            </Grid>}
                            {(current_balancePopup ? openingBalanceConfig?.new_current_balance?.isVisible : openingBalanceConfig?.new_opening_balance?.isVisible) && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box >

                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'new_opening_balance',
                                            value: openingBlalancepopup.new_opening_balance || '',
                                            inputProps: { maxLength: current_balancePopup ? openingBalanceConfig?.new_current_balance?.maxLength : openingBalanceConfig?.new_opening_balance?.maxLength },
                                        }}
                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                        handleChange={(e) => handleChangeOpeningBalanceAmount(e, 'new_opening_balance', 'min')}
                                        clientInput
                                        labelText={current_balancePopup ? (openingBalanceConfig?.new_current_balance?.required ? openingBalanceConfig?.new_current_balance?.label : `${openingBalanceConfig?.new_current_balance?.label} (Optional)`) : (openingBalanceConfig?.new_opening_balance?.required ? openingBalanceConfig?.new_opening_balance?.label : `${openingBalanceConfig?.new_opening_balance?.label} (Optional)`)}
                                        error={errorOpeningBalance.new_opening_balance}
                                        disabled={current_balancePopup ? openingBalanceConfig?.new_current_balance?.isDisabled : openingBalanceConfig?.new_opening_balance?.isDisabled}
                                    />
                                    {
                                        errorOpeningBalance.new_opening_balance ?
                                            <Text red>{errorOpeningBalance.new_opening_balance ? errorOpeningBalance.new_opening_balance : ''}</Text> : ''
                                    }

                                </Box>
                            </Grid>}
                            {openingBalanceConfig?.balance_impact?.isVisible && <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box >
                                    {
                                        current_balancePopup ?
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'balance_impact',
                                                    value: openingBlalancepopup.new_opening_balance != null ? openingBlalancepopup.new_opening_balance == '-' ? 0 : (openingBlalancepopup.new_opening_balance - state.balance_amount).toFixed(3) : 0,
                                                    inputProps: { maxLength: openingBalanceConfig?.balance_impact?.maxLength },
                                                }}
                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                handleChange={(e) => handleChangeOpeningBalanceAmount(e, 'balance_impact', 'min')}
                                                clientInput
                                                disabled={openingBalanceConfig?.balance_impact?.isDisabled}
                                                labelText={openingBalanceConfig?.balance_impact?.required ? openingBalanceConfig?.balance_impact?.label : `${openingBalanceConfig?.balance_impact?.label} (Optional)`}
                                                error={errorOpeningBalance.balance_impact}
                                            />
                                            :
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'balance_impact',
                                                    value: openingBlalancepopup.new_opening_balance != null ? openingBlalancepopup.new_opening_balance == '-' ? 0 : (openingBlalancepopup.new_opening_balance - state.opening_balance).toFixed(3) : 0,
                                                    inputProps: { maxLength: openingBalanceConfig?.balance_impact?.maxLength },
                                                }}
                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                handleChange={(e) => handleChangeOpeningBalanceAmount(e, 'balance_impact', 'min')}
                                                clientInput
                                                disabled={openingBalanceConfig?.balance_impact?.isDisabled}
                                                labelText={openingBalanceConfig?.balance_impact?.required ? openingBalanceConfig?.balance_impact?.label : `${openingBalanceConfig?.balance_impact?.label} (Optional)`}
                                                error={errorOpeningBalance.balance_impact}
                                            />
                                    }
                                    {
                                        errorOpeningBalance.balance_impact ?
                                            <Text red>{errorOpeningBalance.balance_impact ? errorOpeningBalance.balance_impact : ''}</Text> : ''
                                    }
                                </Box>
                            </Grid>}
                            {openingBalanceConfig?.modification?.isVisible && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'modification',
                                        value: openingBlalancepopup.modification,
                                        inputProps: { maxLength: openingBalanceConfig?.modification?.maxLength }
                                    }}
                                    onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                    labelText={openingBalanceConfig?.modification?.required ? openingBalanceConfig?.modification?.label : `${openingBalanceConfig?.modification?.label} (Optional)`}
                                    disabled={openingBalanceConfig?.modification?.isDisabled}
                                    handleChange={handleChangeOpeningBalance}
                                    rows={4}
                                    multiline={true}
                                    // descriptionFormControl1
                                    // descriptionInput
                                    commentsField
                                    error={errorOpeningBalance.modification ? <Text red>{errorOpeningBalance.modification ? errorOpeningBalance.modification : ''}</Text> : ''}
                                />
                                {errorOpeningBalance.modification ? <Text red>{errorOpeningBalance.modification ? errorOpeningBalance.modification : ''}</Text> : ''}
                            </Grid>}
                            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'end'} spacing={2} gap={1} mt={2}>
                                <Button disabled={openingBalSubmit} cancelOutlineRed onClick={() => { handleOpeningBalanceCancel() }}>Cancel</Button>
                                <Button blueBtnSave onClick={() => { handleOpeningBalanceSave() }}>Save</Button>

                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={successEdit}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent >
                    <Grid container justifyContent={'center'} alignContent={'center'} p={{ lg: 3, md: 3, sm: 3, xs: 1 }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={placementEnd} alt='placementEnd' />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Successfully Updated</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                            <Text offBoardBodyFont>Notification has been sent to the employee and to the employers</Text>
                        </Grid>
                        <Grid item lg={.5} md={1} sm={1} xs={1}></Grid>
                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                            <Button sx={{ width: "182px !important", height: "44px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }} smallBlue onClick={() => { cancelForm(); props.getIndex() }}>Go to Profile</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <SwipeableDrawer
                anchor={'right'}
                open={activityLogDrawer}
                onClose={() => { setActivityLogDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog activityLogDrawer={activityLogDrawer} empId={props.employee.id} />

            </SwipeableDrawer>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"}>
                        <Text offBoardBodyFont>Do you want to change the citizenship status for </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"}>
                        <Text offBoardBodyFont>employee? By changing the status previous visa</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign={"center"}>
                        <Text offBoardBodyFont>documents for this employee will be cleared.</Text>
                    </Grid>
                    <Grid container textAlign={"center"} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => {
                                setState({
                                    ...state,
                                    is_usc: 1,
                                    visa_type_id: ''
                                })
                                setError({
                                    ...error,
                                    is_usc: ''
                                })
                                setclearPopup(false)
                            }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>

            <ReusablePopup openPopup={clearPopup4} setOpenPopup={setclearPopup4} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>Please add bank details before adding W4</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>document to the employee</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                        <Button finishFilledQuicksand onClick={() => { setclearPopup4(false) }} >
                            Okay
                        </Button>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}