export const ErrorMessages = {
    genericMessage: "Please fill all mandatory fields",
    fieldRequired: "This field is required",
    checkNumber: "Please enter only numbers",
    validName: "Please enter a valid name",
    checkCharacters: "Please enter only characters",
    invalidSSN: "Invalid SSN, please check the format",
    validData: "Please enter valid data",
    validPincode: "Please enter a valid Pincode",
    validZipcode: "Please enter a valid Zipcode",
    validAddress: "Please enter a valid address",
    validBillRate: "Please enter a valid Bill rate",
    validURL: "Please enter a valid URL",
    validCity: "Please enter a valid city",
    validSkill: "Please enter a valid skill",
    validCharacters: "Please enter valid characters",
    validDate: "Please enter a valid date",
    validNumber: "Please enter a valid number",
    validPassport: "Please enter a valid Passport Number",
    validBankName: "Bank name should be greater than 2 characters",
    validI94: "Please enter a valid I-94 Number",
    validToHours: "Please enter a value greater than the from hours",
    validVisa: "Please enter a valid Visa Number",
    validDocumentNumber: "Please enter a valid Document Number",
    validAccountNumber: "Please enter a valid 6 - 17 digit account number",
    validRouteNumber: "Please enter a valid 9 digit routing number",
    validPassportLength: "Passport Number must be between 8 and 14 characters in length",
    documentNumberDrivingAndStateID: "Document Number must be between 4 to 16 alphanumeric characters",
    documentContinue: "Please enter the document number to continue",
    invalidZipcode: "Invalid Zipcode",
    invalidPincode: "Invalid Pincode",
    invalidPinZipcode: "Invalid Zipcode / Pincode",
    invalidFilesize: "File size must be 25 MB or less. Please upload a smaller file",
    invalidFormatPdfImg: "Upload documents Format PDF/IMG (Jpeg, PNG)",
    documentRequired: "Document Number is required",
    documentTypeRequired: "Document Type is required",
    invalidRecentDate: "Recent Date of Entry should not be a future date",
    invalidAdmitUntilDate: "The Admit Until Date must be a future date",
    invalidDocumentFormat: "Invalid document Number, please check the format",
    validOrganizationName: "Please enter a valid Organization name",
    InvalidCharacters: "Invalid Charaters",
    InvalidID: "Please enter valid ID",
    GoalAmountValidation: "The goal amount must be equal to or greater than the deduction amount.",
    RaiseAmount: "The amount should be greater than 0",
    DeductionAmount: "Deduction amount is required",
    RaiseAmountValidation: "Raise Amount should be less than goal amount",
    Visanumber: "Please enter a valid number",
    greaterThanZero: "Value must be greater than 0.",
    validDocName: "Please enter a valid document name",
    VisaName: "Please enter a valid visa name",
    invalidDescription: "Please enter a valid description",
    invalidReason: "Please enter a valid reason",
    fieldValidate: "Please address the highlighted issues before continuing"

};
