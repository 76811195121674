import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material"
import { blue } from "../../../../theme";


const GeneratePayrollStyles = makeStyles(() => ({
    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
        [useTheme().breakpoints.down('sm')]: {
            // width: '150% !important'
        },
        "@media (min-width: 100px) and (max-width: 900px)": {
            left: "0px",
            width:"100%"
        },
    },
    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "12px !important",
        fontWeight: '700 !important',
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    searchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: "400",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        },
        [useTheme().breakpoints.down('sm')]: {
            fontSize: "8px",
        }
    },
    borderButton1: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "44px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important", marginRight: "-10px !important",
        "@media (min-width: 100px) and (max-width: 589px)": {
            fontSize: '10px  !important',
            width: "35px !important", height: "34px !important",
        },
    },
    profileTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    borderRight: {
        borderRight: "1px solid #EAECF0",
        padding: "0px 20px 0px"
    },

    // PreviewPayrol 
    btnBox: {
        width: "330px !important", padding: "8px 10px !important", borderRadius: "12px !important", backgroundColor: "#F4F8FF !important", display: "flex",
        [useTheme().breakpoints.down('xl')]: {
            width: '300px !important',
        }
    },
    activeButton: {
        font: "14px Quicksand !important", minWidth: "144px !important", borderRadius: "4px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: "12px Quicksand !important",
            minWidth: "134px !important"
        }
    },
    inActiveButton: {
        font: "14px Quicksand !important", minWidth: "144px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: "12px Quicksand !important",
            minWidth: "134px !important"
        }
    },





    // Filters styles

    divider: {
        margin: "0px !important",
        color: "#EAECF0 !important"
    },
    listItem: {
        padding: "0px !important",
        height: "53px !important",
        cursor: "pointer"
    },
    listItemActive: {
        padding: "0px !important",
        height: "53px !important",
        background: "#E4F1FF !important",
        cursor: "pointer"
    },
    startDateBox: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: '30px !important',
        height: '14px !important',
        borderRadius: '50px',
        opacity: '0px',
    },

    amountBoxes: {
        border: "1px solid #737373", height: "50px", borderRadius: "12px", padding: "10px", display: "flex", alignItems: "center"
    },


    dateStyle:{
        fontFamily: "Quicksand !important",
        color:"#525252 !important",
        fontSize: "14px !important",
        fontWeight: `${600} !important`,
        lineHeight: "16.8px !important",
        
    },

    amountTotal:{
    

fontFamily: "Quicksand !important",
        color:"#262626 !important",
        fontSize: "14px !important",
        fontWeight: `${700} !important`,
        lineHeight: "16.8px !important",

    },



    // Table Styles 
    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "18px !important",
        fontWeight: `${800} !important`,
        // textAlign: "center",
        textTransform: "capitalize !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '14px !important',
            fontWeight: `${700} !important`,
        }
    },

    profileNameMain:{
        fontFamily: "Quicksand !important",
        color: "#262626 !important",
        fontSize: "14px !important",
        fontWeight: `${700} !important`,
        // textAlign: "center",
        textTransform: "capitalize !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '14px !important',
            fontWeight: `${700} !important`,
        }
    },

    
    profileId: {
        fontFamily: "Quicksand !important",
        paddingTop: "5px",
        fontWeight: '600 !important',
        color: "#525252 !important",
        fontSize: "14px !important",
        textAlign: "center",
        lineHeight: "8.8px !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '11px !important',
            fontWeight: `${600} !important`,
        }
    },

    totalLabels: {
        fontFamily: "Quicksand !important",
        paddingTop: "5px",
        paddingBottom: "4px",

        fontWeight: '600 !important',
        color: "#737373 !important",
        fontSize: "12px !important",
        textAlign: "center",
        lineHeight: "8.8px !important",
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '11px !important',
            fontWeight: `${600} !important`,
        }
    },

    viewDetails: {
        font: '12px Quicksand !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize !important',
        color: '#0C75EB !important',
        backgroundColor: "#fff !important",
        padding: "2px 0px !important",
        cursor: "pointer !important",
    },
    amountBox: {
        display: "flex", alignItems: "center", cursor: "pointer", width: "fit-content",
    },
    salaryBox: {
        width: "92%",
        border: "1px solid #737373",
        padding: "10px",
        borderRadius: "8px",
        cursor: "pointer"
    },
    commentIcon: {
        cursor: "pointer",
        marginRight: "-10px",
    },
    infoIcon: {
        position: "absolute !important",
        bottom: 0,
        right: 0,
        padding: '0px 0px 4px 1px !important',
        minWidth: '0px !important',
        marginBottom: "-5px !important"
    },

    // SalaryAmountDrawer Styles

    mainBox: {
        display: "flex", alignItems: "center", gap: 2.5, height: "15vh", background: "#D1E1FF", borderRadius: "12px", padding: "18px", justifyContent: "space-between"
    },
    mainSubBox: {
        background: `${blue}`, padding: "14px 18px", textAlign: "center", borderRadius: "8px ",
        [useTheme().breakpoints.down("lg")]: {
            borderRadius: "6px ",
            padding: "7px 5px !important",
        },
        [useTheme().breakpoints.down("md")]: {
            borderRadius: "4px ",
            padding: "5px 3px !important",
        },
        [useTheme().breakpoints.down("sm")]: {
            borderRadius: "2px ",
            padding: "2px 1px !important",
        },
    },

    tabsContainer: {
        background: "#F4F8FF !important",
        padding: "10px !important",
        borderRadius: "10px !important",
        boxShadow: "none !important",
        marginTop: "15px !important",
    },
    tabListMargin: {
        margin: "0px !important",
        borderRadius: "10px !important",
        justifyContent: "space-between"
    },
    tabText: {
        font: "13px Quicksand !important",
        color: "#242424 !important",
        textTransform: "capitalize !important",
        alignItems: "center",
        padding: "8px 18px !important",
        [useTheme().breakpoints.down("sm")]: {
            font: "10px Quicksand !important",
        },
    },
    tabTextActive: {
        font: "13px Quicksand !important",
        color: "#FFFFFF !important",
        textTransform: "capitalize !important",
        background: `${blue} !important`,
        padding: "8px 10px !important",
        borderRadius: "7px !important",
        [useTheme().breakpoints.down("sm")]: {
            font: "10px Quicksand !important",
            padding: "7px 5px !important",
        },
    },

    // Paycycle Details

    warningBox: {
        height: '40px', width: '100%', borderRadius: '8px', background: '#FDECCE', paddingLeft: '20px', display: "flex", gap: 10, alignItems: 'center'
    },

    accordionBox: {
        height: "39vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
        paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px',
    },

    newCustomAccordion: {
        padding: "8px 16px !important",
        backgroundColor: "#ffffff !important",
        margin: "12px 0px !important",
        borderRadius: "20px !important",
        boxShadow: "5px 5px 10px 0px #0000000D !important",
        width: "100% !important",
        alignItems: 'center',
        '&:before': { display: "none" },
        [useTheme().breakpoints.down('lg')]: {
            padding: "8px !important",
        },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        }
    },
    AccordionSummary: {
        backgroundColor: "#ffffff",
        height: "70px"
    },
    AccordionDetails: {
        backgroundColor: "#F4F8FF ",
        border: `1px solid ${blue}`,
        borderRadius: "8px",
        width: "100%",

    },
    AccordionDetailsContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px"
    },
    contentLeftAlign: {
        textAlign: "start",
    },
    constRightAlign: {
        textAlign: "end",
    },

    // Timesheet

    tmPrimaryBox: {
        // padding: "10px",
        border: `1px solid ${blue}`,
        borderRadius: "8px",
        background: "#F4F8FF",
        width: "100%",
        // margin: "10px 0px",
    },

    // Balance sheet

    customAccordion: {
        padding: "8px 0px !important",
        backgroundColor: "#ffffff !important",
        margin: "12px 0px !important",
        borderRadius: "20px !important",
        boxShadow: "5px 5px 10px 0px #0000000D !important",
        width: "100% !important",
        alignItems: 'center',
        '&:before': { display: "none" },
        [useTheme().breakpoints.down('lg')]: {
            padding: "8px !important",
        },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        }
    },
    customSummary: {
        backgroundColor: "#ffffff",
        height: "50px"
    },


}))

export default GeneratePayrollStyles;