import { makeStyles } from "@mui/styles";
import { btnBgGrey } from "../../../../theme";
import { useTheme } from "@mui/material";

const AnalyticStyles = makeStyles(() => ({
    Paper: {
        display: "flex",
        alignItems: "center",
        height: '48px ! important',
        border: `1px solid ${btnBgGrey.shade4} !important`,
        boxShadow: "none !important",
        borderRadius: '8px !important',
        cursor: 'pointer',
        width: "100% !important",
        paddingRight: '10px',
    },
    InputBase: {
        flex: 1,
        font: '13px Quicksand !important',
        color: 'rgba(38, 38, 38, 1)',
        background: '#FFFFFF !important',
        opacity: 1,
        borderRadius: '5px !important',
        fontWeight: `${400} !important`,
        width: '100%',
        height: '30px ! important',
        textAlign: 'left !important',
        '& input': {
            textAlign: 'left !important',
            paddingLeft: '10px !important'
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '11px Quicksand !important',
            fontWeight: `${400} !important`,
        },
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
    },
    cloudBox: {
        height: '47px',
        border: `1px solid ${btnBgGrey.shade4} !important`,
        borderRadius: '8px !important',
        alignItems: "center !important",
        padding: '9px 15px 0px 15px',
        cursor: 'pointer',
        background: '#FFFFFF !important'
    },
    accordian: {
        padding: "0px 8px 2px 12px !important",
        margin: "5px 0px !important",
        borderRadius: "20px !important",
        boxShadow: '0px 2px 24px 0px #919EAB1F',
        width: "100% !important",
        alignItems: 'center !important',
        cursor: 'pointer',
        '&:before': { display: "none" },
        [useTheme().breakpoints.down('lg')]: {
            padding: "2px !important",
        },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        }
    },
    Clientaccordian: {
        padding: "12px 16px !important",
        background: "#fff !important",
        margin: "5px 0px !important",
        borderRadius: "20px !important",
        boxShadow: '0px 2px 24px 0px #919EAB1F',
        width: "100% !important",
        alignItems: 'center',
        height: '88px',
        '&:before': { display: "none" },
        [useTheme().breakpoints.down('lg')]: {
            height: 'fit-content',
        },
    },
    ActiveBg: {
        display: "flex",background: "#0C75EB", height: '35px', padding: "7px 13px", borderRadius: "8px", cursor: "pointer", alignItems: 'center !important', textAlign: 'center', justifyContent: 'center', width: '100%'
    },
    inactiveBg: {
        display: "flex",background: "transparent", padding: "12px 5px 12px 5px", borderRadius: "8px", cursor: "pointer", width: '100%',justifyContent: 'center'
    },
    tabBg: {
        display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", width: '100% !important', alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3
    },

    mdDownImg:{
        [useTheme().breakpoints.down('md')]: {
            height:"20px"
        },  
    },
    // Client Analytics

    boxShadow: {
        boxShadow: '0px 2px 24px 0px #919EAB1F',
        borderRadius: '12px'
    },
    paddingLeft: {
        paddingLeft: '110px !important',
        [useTheme().breakpoints.down('lg')]: {
            paddingLeft: '80px !important'
        },
        [useTheme().breakpoints.down('md')]: {
            paddingLeft: '10px !important'
        },
        [useTheme().breakpoints.down('sm')]: {
            paddingLeft: '10px !important'
        },
    },
    clientLogo: {
        height: '44px !important',
        width: '44px !important',
        borderRadius: '50%',
        background: '#e5b256 !important',
        [useTheme().breakpoints.down('lg')]: {
            height: '35px !important',
            width: '35px !important',
        },
    },
    scrollContent: {
        height: "60vh !important",
        overflowY: 'scroll !important',
        alignItems: 'start !important',
        justifyContent: 'start',
        paddingRight: '10px',
        marginTop: '0px !important',
        width: '100%',
        "@media (min-height: 850px)": {
            maxHeight: '70vh'
        }
    },
    textSkeleton: {
        height: '30px !important', width: '100px !important', background: '#F6F6F6 !important'
    },
    placementSkeleton: {
        height: '20px !important', width: '100px !important', background: '#fff !important'
    },
    payrateNote: {
        minHeight: '47px', width: '100%', borderRadius: '8px', background: '#F0F5FF', textAlign: 'center', alignItems: 'center', padding: '14px 12px'
    },
    tableBox: {
        width: '100%',
        [useTheme().breakpoints.down('md')]: {
            overflowX: 'auto !important', width: '100% !important'
        }
    },
    hzScrollGrid: {
        [useTheme().breakpoints.down('md')]: {
            minWidth: '730px'
        },
        [useTheme().breakpoints.down('sm')]: {
            minWidth: '780px'
        }
    },
    tableBoxCharts: {
        [useTheme().breakpoints.down('sm')]: {
            overflowX: 'auto !important', width: '100% !important'
        }
    },
    hzScrollGridCharts: {
        [useTheme().breakpoints.down('sm')]: {
            minWidth: '780px'
        }
    },
}))

export default AnalyticStyles;