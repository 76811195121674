import { v4 as uuid } from 'uuid';


class LocalStorage {
    uid() {
        const id = uuid();
        return id
    }
    getURLNAME() {
        var temp = window.location.hostname;
        return temp;
    }

    setPathId() {
        const local_data = window.location.pathname.split('/')[2];
        return localStorage.setItem('path_id', local_data)
    }
    getPathId() {
        return localStorage.getItem('path_id');
    }
    setUserData(data) {
        const local_data = JSON.stringify(data);
        return localStorage.setItem('UserData', local_data)
    }
    getUserData() {
        const str = localStorage.getItem('UserData');
        const parsedArray = JSON.parse(str);
        return parsedArray
    }
    removeUserData() {
        return localStorage.removeItem('UserData')
    }
    setAccessToken() {
        // return localStorage.setItem('access_token', data)
        return this.getUserData().access_token
    }
    getAccessToken() {
        return this.getUserData() ? this.getUserData().access_token : ''
    }
    removeAcessToken() {
        return localStorage.removeItem('access_token')
    }
    setRolesData(data) {
        const local_data = JSON.stringify(data);
        return localStorage.setItem('RolesData', local_data)
    }
    getRolesData() {
        const str = localStorage.getItem('RolesData');
        const parsedArray = JSON.parse(str);
        return parsedArray
    }
    removeRolesData() {
        return localStorage.removeItem('RolesData')
    }

    setDateFormat(data) {
        return localStorage.setItem('date_format', data);
    }
    getDateFormat() {
        return localStorage.getItem('date_format');
    }
    removeDateFormat() {
        return localStorage.removeItem('date_format')
    }


    setCurrencySymbol(data) {
        return localStorage.setItem('currency_symbol', data);
    }
    getCurrencySymbol() {
        return localStorage.getItem('currency_symbol');
    }
    removeCurrencySymbol() {
        return localStorage.removeItem('currency_symbol')
    }

    setEmployeeId(data) {
        return localStorage.setItem('EmployeeID', data);
    }
    getEmployeeId() {
        return localStorage.getItem('EmployeeID');
    }
    removeEmployeeId() {
        return localStorage.removeItem('EmployeeID');
    }

    setFullName(data) {
        return localStorage.setItem('FullName', data);
    }
    getFullName() {
        return localStorage.getItem('FullName');
    }
    removeFullName() {
        return localStorage.removeItem('FullName');
    }

    setVendorID(data) {
        return localStorage.setItem('vendorID', data)
    }
    getVendorID() {
        return localStorage.getItem('vendorID')
    }
    removeVendorID() {
        return localStorage.removeItem('vendorID')
    }

    setEndClientID(data) {
        return localStorage.setItem('EndClientID', data)
    }
    getEndClientID() {
        return localStorage.getItem('EndClientID')
    }
    removeEndClientID() {
        return localStorage.removeItem('EndClientID')
    }

    setClientID(data) {
        return localStorage.setItem('ClientID', data)
    }
    getClientID() {
        return localStorage.getItem('ClientID')
    }
    removeClientID() {
        return localStorage.removeItem('ClientID')
    }

    setPlacementID(data) {
        return localStorage.setItem('PlacementID', data)
    }
    getPlacementID() {
        return localStorage.getItem('PlacementID')
    }
    removePlacementID() {
        return localStorage.removeItem('PlacementID')
    }
    setExpenseCache(data) {
        const premiumData = JSON.stringify(data);
        return localStorage.setItem('expenceCache', premiumData)
    }
    getExpenseCache() {
        const data = localStorage.getItem('expenceCache')
        const parsedArray = JSON.parse(data)
        return parsedArray
    }
    setplacementCache(data) {
        const premiumData = JSON.stringify(data);
        return localStorage.setItem('placementCache', premiumData)
    }
    getplacementCache() {
        const data = localStorage.getItem('placementCache')
        const parsedArray = JSON.parse(data)
        return parsedArray
    }


    setTidData(data) {
        const local_data = JSON.stringify(data);
        return localStorage.setItem('tIds', local_data)
    }
    getTidData() {
        const str = localStorage.getItem('tIds');
        const parsedArray = JSON.parse(str);
        return parsedArray
    }
    removeTidData() {
        return localStorage.removeItem('tIds')
    }

    setVisaID(data) {
        return localStorage.setItem('visaID', data)
    }
    getVisaID() {
        return localStorage.getItem('visaID')
    }
    removeVisaID() {
        return localStorage.removeItem('visaID')
    }
    setEditForm(data) {
        return localStorage.setItem('formEdit', data)
    }
    getEditForm() {
        return localStorage.getItem('formEdit')
    }
    removeEditForm() {
        return localStorage.removeItem('formEdit')
    }

    setEmployeeVerifyId(data) {
        return localStorage.setItem('EmployeeVerifyID', data);
    }
    getEmployeeVerifyId() {
        return localStorage.getItem('EmployeeVerifyID');
    }
    removeEmployeeVerifyId() {
        return localStorage.removeItem('EmployeeVerifyID');
    }

    setEmployeeReVerifyId(data) {
        return localStorage.setItem('EmployeeReVerifyID', data);
    }
    getEmployeeReVerifyId() {
        return localStorage.getItem('EmployeeReVerifyID');
    }
    removeEmployeeReVerifyId() {
        return localStorage.removeItem('EmployeeReVerifyID');
    }

    setAnalyticsRoute(data) {
        return localStorage.setItem('analytics_route', data);
    }
    getAnalyticsRoute() {
        return localStorage.getItem('analytics_route');
    }
    removeAnalyticsRoute() {
        return localStorage.removeItem('analytics_route')
    }

    setAppIntegrationId(data) {
        return localStorage.setItem('app_integration_id', data);
    }
    getAppIntegrationId() {
        return localStorage.getItem('app_integration_id');
    }
    removeAppIntegrationId() {
        return localStorage.removeItem('app_integration_id')
    }

    setNotificationId(data) {
        return localStorage.setItem('notification_id', data);
    }
    getNotificationId() {
        return localStorage.getItem('notification_id');
    }
    removeNotificationId() {
        return localStorage.removeItem('notification_id')
    }

    setRedirectedModule(data) {
        return localStorage.setItem('module_name', data);
    }
    getRedirectedModule() {
        return localStorage.getItem('module_name');
    }
    removeRedirectedModule() {
        return localStorage.removeItem('module_name')
    }

    setNotificationEmpId(data) {
        return localStorage.setItem('notification_emp_id', data);
    }
    getNotificationEmpId() {
        return localStorage.getItem('notification_emp_id');
    }
    removeNotificationEmpId() {
        return localStorage.removeItem('notification_emp_id')
    }

    setFromPage(data) {
        return localStorage.setItem('from_page', data);
    }
    getFromPage() {
        return localStorage.getItem('from_page');
    }
    removeFromPage() {
        return localStorage.removeItem('from_page')
    }

    setSubModule(data) {
        return localStorage.setItem('sub_module', data);
    }
    getSubModule() {
        return localStorage.getItem('sub_module');
    }
    removeSubModule() {
        return localStorage.removeItem('sub_module')
    }

    showProgress(data) {
        return localStorage.setItem('progress', data)
    }
    getProgress() {
        return localStorage.getItem('progress');
    }
    removeProgress() {
        return localStorage.removeItem('progress');
    }

    setRenew(data) {
        return localStorage.setItem('renew', data)
    }
    getRenew() {
        return localStorage.getItem('renew');
    }
    removeRenew() {
        return localStorage.removeItem('renew');
    }

    setTsPlacementID(data) {
        return localStorage.setItem('ts_placement_id', data)
    }
    getTsPlacementID() {
        return localStorage.getItem('ts_placement_id');
    }
    removeTsPlacementID() {
        return localStorage.removeItem('ts_placement_id');
    }
    setPlanCheckLocal(data) {
        const local_data = JSON.stringify(data);
        return localStorage.setItem('planCheckLocal', local_data);
    }
    getPlanCheckLocal() {
        const str = localStorage.getItem('planCheckLocal');
        const parsedArray = JSON.parse(str);
        return parsedArray
    }
    removePlanCheckLocal() {
        return localStorage.removeItem('planCheckLocal')
    }
    setlocationPath(data) {
        return localStorage.setItem('locationPath', data)
    }
    getlocationPath() {
        return localStorage.getItem('locationPath');
    }
    removelocationPath() {
        return localStorage.removeItem('locationPath');
    }
    setCountryData(data) {
        const local_data = JSON.stringify(data);
        return localStorage.setItem('CountryData', local_data)
    }
    getCountryData() {
        const str = localStorage.getItem('CountryData');
        const parsedArray = JSON.parse(str);
        return parsedArray
    }
    removeCountryData() {
        return localStorage.removeItem('CountryData')
    }
    setStartTour(data){
        return localStorage.setItem('TourStart',JSON.stringify(data))
    }
    getStartTour(){
        return JSON.parse(localStorage.getItem('TourStart'))
    }
    removeStartTour(){
        return localStorage.removeItem('TourStart')
    }
    getOrgStartDate() {
        return JSON.parse(localStorage.getItem('organizationStartDate'))
    }
    setOrgStartDate(data){
        return localStorage.setItem('organizationStartDate',JSON.stringify(data))
    }
}
// eslint-disable-next-line
export default new LocalStorage()