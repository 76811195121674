import React from 'react';
import { Box, Paper } from '@mui/material';
import Text from '../../../components/customText/Text';

const UnpaidInvoicesCard = (props) => {

  const { clientDetailsAPI } = props

  return (
    <Box sx={{ p: 2 }}>
      <Text boldBlackfont16 sx={{ paddingBottom: '20px' }}>
        Unpaid Invoices
      </Text>
      {clientDetailsAPI.unpaid_invoices.map((item) => (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 2,
          }}
        >
          <Box pt={1} pb={1}>
            <Text largeLabel sx={{ paddingBottom: '10px' }}>
              {item.reference_id}
            </Text>
            <Text mediumGreyHeader >
              {item.due_date}
            </Text>
          </Box>
          <Box textAlign="right">
            <Text largeLabel sx={{ paddingBottom: '10px' }}>
              $ {item.round}
            </Text>
            <Text mediumBlue600 sx={{ fontWeight: '400 !important', color: item.status.includes('OVER') ? '#F70D0D !important' : '#0C75EB !important' }}>
              {item.status}
            </Text>
          </Box> 
        </Paper>
      ))
      }
    </Box >
  );
};

export default UnpaidInvoicesCard;
