import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { Avatar, Box, Checkbox, SwipeableDrawer, tableCellClasses, Grid, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from '../../../../components/customText/Text';
import { BlackToolTip, getCurrencySymbol, capitalizeAndAddSpace, formatAmount } from '../../../../utils/utils';
import GeneratePayrollStyles from '../generatePayroll/GeneratePayrollStyles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import blueEdit from '../../../../assets/client/blue-edit.svg';
import payrollInfo from '../../../../assets/svg/payroll_ifo.svg';
import { ReactComponent as CommentAdd } from '../../../../assets/svg/comment_add.svg';
import { ReactComponent as CommentRead } from '../../../../assets/svg/comment_read.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import QuitImage from '../../../../assets/svg/quitimage.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import EarnedAmountPopup from './EarnedAmountPopup';
import CurrentBalancePopup from './CurrentBalancePopup';
import CommentsDrawer from '../generatePayroll/CommentsDrawer';
import SalaryAmountDrawer from '../generatePayroll/SalaryAmountDrawer';
import DeductionsDrawer from '../generatePayroll/DeductionsDrawer';
import ReimbursementsDrawer from '../generatePayroll/ReimbursementsDrawer';
import Button from '../../../../components/customButton/Button';
import PayrollPreview from '../generatePayroll/PayrollPreview';
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi';
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../../../utils/LocalStorage';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 50,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
        font: '14px',
        backgroundColor: "#FBFBFB",
        padding: "6px 16px",
        fontFamily: "Quicksand !important",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "6px 16px",
        color: "#000000",
        font: '14px',
        fontFamily: "Quicksand !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));

function PayRow(props) {
// eslint-disable-next-line
    const { classes, rowData, handleEarnedAmountData, handleCurrentBalanceData, handleSalaryData, handleCommentOpen, handleDeduction, handleReimbursements, rowOnChange, rowChecked } = props;
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';


    return (<React.Fragment>
        <StyledTableRow
            sx={{
                height: "74px !important",
                boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                '&:hover': {
                    borderRadius: '8px',
                    transition: "all .3s ease",
                    boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                },

                '& > *': {
                    borderBottom: 'none !important',
                    paddingTop: '10px !important',
                    paddingBottom: '10px !important',
                },
            }}

        >
            <StyledTableCell sx={{ width: '50px' }}>
                <Checkbox
                    color="primary"
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                    onChange={(e) => rowOnChange(rowData?.employee_id, e)}
                    checked={rowChecked}
                    icon={<CheckBorderIcon />}
                    checkedIcon={<CheckedIcon />}
                />
            </StyledTableCell>

            {/* Employee Details Column */}
            <StyledTableCell sx={{ width: '280px', paddingRight: "0px !important" }} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.7 }}>
                        <Box sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Avatar
                                alt={rowData.display_name}
                                src={rowData.profile_picture_url}
                                sx={{ width: 56, height: 56 }}
                            />
                            {rowData.lca_wage_violation &&
                                <Button className={classes.infoIcon} component="label"
                                >
                                    <img src={payrollInfo} alt="edit" />
                                </Button>}
                        </Box>

                        <Box >
                            <Text className={classes.profileNameMain}>
                                {rowData.display_name && rowData.display_name.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(rowData.display_name)} placement="top" arrow>
                                        {capitalizeAndAddSpace(rowData.display_name).slice(0, 16) + (capitalizeAndAddSpace(rowData.display_name.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : capitalizeAndAddSpace(rowData.display_name)
                                }
                            </Text>
                            <Text textAlign="start" className={classes.totalLabels}>
                                Emp ID: {rowData?.emp_reference_id}
                            </Text>
                            <Text className={classes.viewDetails} onClick={() => { handleSalaryData(rowData) }} blue14>
                                View Details
                            </Text>
                        </Box>
                    </Box>

                    {
                      ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                      <HtmlTooltip
                      placement="bottom"
                      arrow
                      title={
                          <React.Fragment>
                              <Box>
                                  <Typography className={classes.profileTooltipText}>
                                      Comments
                                  </Typography>
                              </Box>
                          </React.Fragment>
                      }
                  >
                      {rowData.comments_exist ? <CommentRead onClick={() => { handleCommentOpen(rowData) }} className={classes.commentIcon} /> : <CommentAdd onClick={() => { handleCommentOpen(rowData) }} className={classes.commentIcon} />}
                  </HtmlTooltip> : ''
                    }
                    
                </Box>

            </StyledTableCell>

            {/* Placement Info Columns */}
            <StyledTableCell colSpan={3} sx={{ padding: 0 }}>
                {rowData?.placements?.length > 0 ? (
                    rowData.placements.map((pld, index) => (
                        <StyledTableRow
                            key={index}
                            sx={{
                                display: "flex",
                                width: "100%",
                                padding: 0,
                            }}
                        >
                            <StyledTableCell
                                align="start"
                                sx={{
                                    flex: 1,
                                    minWidth: 150,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    paddingLeft: "5px !important"
                                }}
                            >
                                <BlackToolTip arrow placement="top" title={pld?.client_name}><Text mediumBlack14>{pld.client_name.slice(0, 16)}{pld?.client_name.length > 16 ? "..." : ""}</Text></BlackToolTip>
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{
                                    flex: 1,
                                    minWidth: 100,
                                    paddingLeft: "0px !important"
                                }}
                            >
                                <Text mediumBlack14>{rowData?.visa_type}</Text>
                            </StyledTableCell>
                            <StyledTableCell
                                align="right"
                                sx={{
                                    flex: 1,
                                    minWidth: 120,
                                    paddingRight: "5px !important"
                                }}
                            >
                                {pld.earned_amount ? (
                                    <Text onClick={() => { handleEarnedAmountData(pld); }} blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }}>
                                        {getCurrencySymbol()} {pld?.earned_amount}
                                    </Text>
                                ) : (
                                    <Text mediumBlack14>--</Text>
                                )}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))
                ) : (
                    <StyledTableRow
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: 0,
                        }}
                    >
                       <StyledTableCell
                            align="start"
                            sx={{
                                flex: 1,
                                minWidth: 150,
                                paddingLeft: "5px !important"
                            }}
                        >
                            <Text mediumBlack14>--</Text>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                flex: 1,
                                minWidth: 100,
                                paddingLeft: "0px !important"
                            }}
                        >
                            <Text mediumBlack14>{rowData?.visa_type}</Text>
                        </StyledTableCell>
                        <StyledTableCell
                           align="right"
                           sx={{
                               flex: 1,
                               minWidth: 120,
                               paddingLeft: "0px !important",
                               paddingRight: "5px !important"
                           }}
                        >
                            <Text mediumBlack14>--</Text>

                        </StyledTableCell>
                    </StyledTableRow>
                )}
            </StyledTableCell>


            {/* Remaining Columns */}
            <StyledTableCell align="right" sx={{ textAlign: '-webkit-right', minWidth: 150, paddingRight: "16px !important" }}>


                <Box onClick={() => { handleSalaryData(rowData) }} className={classes.amountBox} >
                {rowData?.amount_paid ? <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.amount_paid}</Text>: <Text mediumBlack14 > {getCurrencySymbol()} 0</Text>}

                    {/* {rowData?.amount_paid > 0 ? <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.amount_paid}</Text> : rowData?.make_standard_pay_as_salary && visaTypes.includes(rowData?.visa_type) ?
                        <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.standard_pay_amount}</Text> :
                        rowData?.lca_wage > 0 && visaTypes.includes(rowData?.visa_type) ?
                            <Text mediumBlack14   > {getCurrencySymbol()} {rowData?.lca_wage}</Text> :
                            <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.earned_pay}</Text>
                    } */}
                    <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' />
                </Box>

            </StyledTableCell>
            <StyledTableCell align="right" sx={{ textAlign: '-webkit-right' }}>
                {rowData?.reimbursement_amount !== 0 ? (
                    <Box className={classes.amountBox} onClick={() => { handleReimbursements(rowData) }}>
                        <Text blue14 sx={{ textDecoration: 'underline' }}>$ {rowData?.reimbursement_amount}</Text>
                        <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' />
                    </Box>
                ) : (
                    <Box className={classes.amountBox} onClick={() => { handleReimbursements(rowData) }}>
                        <ControlPointIcon sx={{ color: "#0C75EB" }} /> <Text offBoardBodyFont sx={{ lineHeight: "16.8px !important" }}>Add</Text>
                    </Box>
                )}
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ textAlign: '-webkit-right' }}>
                {rowData?.deduction_amount !== 0 ? (
                    <Box className={classes.amountBox} onClick={() => { handleDeduction(rowData) }}>
                        <Text blue14 sx={{ textDecoration: 'underline' }}>$ {rowData?.deduction_amount}</Text>
                        <img style={{ paddingLeft: "5px" }} src={blueEdit} alt='edit' />
                    </Box>
                ) : (
                    <Box className={classes.amountBox} onClick={() => { handleDeduction(rowData) }}>
                        <ControlPointIcon sx={{ color: "#0C75EB" }} /> <Text offBoardBodyFont sx={{ lineHeight: "16.8px !important" }}>Add</Text>
                    </Box>
                )}
            </StyledTableCell>
            <StyledTableCell align="right" >
                {rowData?.balance_amount ? (
                    <Text onClick={() => { handleCurrentBalanceData(rowData) }} blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }} >{getCurrencySymbol()} {rowData.balance_amount}</Text>
                ) : (
                    <Text onClick={() => { handleCurrentBalanceData(rowData) }} blue14 sx={{ cursor: "pointer !important", textDecoration: 'underline' }} >{getCurrencySymbol()} 0</Text>
                )}
            </StyledTableCell>

        </StyledTableRow>


    </React.Fragment>




    );
}


function PayrollTable(props) {

    const { tableData, payrollDataProp, getAllEmployees, payrollConfigId, deductionTypeDropDown, reimbursementTypeDropDown, reimbursementPayPeriodDropDown } = props;
    const navigate = useNavigate();
    const [earnedOpen, setEarnedOpen] = useState(false);
    const [currentOpen, setCurrentOpen] = useState(false);
    const [commentedOn, setCommentedOn] = useState(false);
    const [salaryOpen, setSalaryOpen] = useState(false);
    const [remDrawer, setRemDrawer] = useState(false);
    const [deductionDrawer, setDeductionsDrawer] = useState(false);
    const [quitPopup, setQuitPopup] = useState(false)
    const [active, setActive] = useState(0);
    const [viewClientEarnedAmount, setViewClientEarnedAmount] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [finalizedEmployees, setFinalizedEmployees] = useState(() => {
        return tableData.filter(item => item.is_finalized === true);
    });
    const [nonfinalizedEmployees, setNonFinalizedEmployees] = useState(() => {
        return tableData.filter(item => item.is_finalized === false);
    });
    const [commentRow, setCommentRow] = useState({ comments: [] });
    const [currentBalanceRow, setCurrentBalanceRow] = useState({});
    const [currentSalaryRow, setCurrentSalaryRow] = useState({});
    const [commentsList, setCommentsList] = useState([]);
    const [timesheetList, setTimesheetList] = useState([]);
    const [reimbursementDeduction, setReimbursementDeduction] = useState({});
    const [balanceSheet, setBalanceSheet] = useState([]);
    const [allBalanceSheet, setAllBalanceSheet] = useState([]);
    const [clickedEmpId, setClickedEmpId] = useState('');


    const visaTypes = ["H-1B", "E-3", "H-1B1", "E3", "H1B1", "H1B"];



    const handlePayrollPreview = () => {
        setPreviewOpen(true);
    }
    const [selectedRows, setSelectedRows] = useState(tableData.reduce((acc, row) => {
        if (row?.is_finalized) {
            acc.add(row?.employee_id);
        }
        return acc;
    }, new Set()));

    // eslint-disable-next-line
    const [tableDataProp, setTableDataProp] = useState(tableData);



    const [breakdown, setBreakDown] = useState({
        earned_pay: 0,
        reimbursement_amount: 0,
        deduction_amount: 0,
        salary_amount: 0,
        balance_amount: 0,
    });
    useEffect(() => {
        setBreakDown({
            earned_pay: tableData.filter(item => item.is_finalized === true).reduce((total, item) => total + Number(item.earned_pay), 0),
            reimbursement_amount: tableData.filter(item => item.is_finalized === true).reduce((total, item) => total + Number(item.reimbursement_amount), 0),
            deduction_amount: tableData.filter(item => item.is_finalized === true).reduce((total, item) => total + Number(item.deduction_amount), 0),
            salary_amount: tableData.filter(item => item.is_finalized === true).reduce((total, item) => total + Number(item.amount_paid), 0),
            balance_amount: tableData.filter(item => item.is_finalized === true).reduce((total, item) => total + Number(item.balance_amount), 0)
        })
        setFinalizedEmployees(tableData.filter(item => item.is_finalized === true));
        setNonFinalizedEmployees(tableData.filter(item => item.is_finalized === false));
        setSelectedRows(tableData.reduce((acc, row) => {
            if (row?.is_finalized) {
                acc.add(row?.employee_id);
            }
            return acc;
        }, new Set()))

    }, [tableData])




    const handleRowSelect = (id, e) => {
        setSelectedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
        setTableDataProp((prevData) =>
            prevData.map((item) =>
                item.employee_id === id
                    ? { ...item, is_finalized: !item.is_finalized }
                    : item
            )
        );
        const itemFound = tableData.find((item) => item.employee_id === id)
        if (itemFound) {
            const earned = parseFloat(itemFound.earned_pay || 0);
            const reimbursement = parseFloat(itemFound.reimbursement_amount || 0);
            const deduction = parseFloat(itemFound.deduction_amount || 0);
            //const salary = itemFound.amount_paid > 0 ? parseFloat(itemFound.amount_paid) : itemFound?.make_standard_pay_as_salary && visaTypes.includes(itemFound?.visa_type) ? parseFloat(itemFound.standard_pay_amount || 0) : itemFound?.lca_wage > 0 && visaTypes.includes(itemFound?.visaTypes) ? parseFloat(itemFound.lca_wage || 0) : parseFloat(itemFound.earned_pay || 0);
            const salary = parseFloat(itemFound.amount_paid || 0);
            
            const balance = parseFloat(itemFound.balance_amount || 0);
            if (e.target.checked) {
                setNonFinalizedEmployees((prev) => prev.filter((emp) => emp.employee_id !== itemFound.employee_id));
                setFinalizedEmployees((prev) => [...prev, itemFound]);

                setBreakDown((prev) => ({
                    ...prev,
                    earned_pay: prev.earned_pay + earned,
                    reimbursement_amount: prev.reimbursement_amount + reimbursement,
                    deduction_amount: prev.deduction_amount + deduction,
                    salary_amount: prev.salary_amount + salary,
                    balance_amount: prev.balance_amount + balance,
                }));

            } else {
                setFinalizedEmployees((prev) => prev.filter((emp) => emp.employee_id !== itemFound.employee_id));
                setNonFinalizedEmployees((prev) => [...prev, itemFound]);

                setBreakDown((prev) => ({
                    ...prev,
                    earned_pay: prev.earned_pay - earned,
                    reimbursement_amount: prev.reimbursement_amount - reimbursement,
                    deduction_amount: prev.deduction_amount - deduction,
                    salary_amount: prev.salary_amount - salary,
                    balance_amount: prev.balance_amount - balance,
                }));
            }
        }
    };

    const handleSelectAll = (e) => {
        const globalDataChange = tableData.map((item) => ({ ...item, is_finalized: e.target.checked }))
        setTableDataProp(globalDataChange);
        if (!e.target.checked) {
            setSelectedRows(new Set());
            setFinalizedEmployees([]);
            setNonFinalizedEmployees(globalDataChange);
            setBreakDown({
                earned_pay: 0,
                reimbursement_amount: 0,
                deduction_amount: 0,
                salary_amount: 0,
                balance_amount: 0
            });
        } else {
            const allIds = new Set(tableData.map((row) => row.employee_id));
            setSelectedRows(allIds);
            setNonFinalizedEmployees([]);
            setFinalizedEmployees(globalDataChange);
            setBreakDown(
                tableData.reduce(
                    (acc, item) => {
                        acc.earned_pay += parseFloat(item.earned_pay || 0);
                        acc.reimbursement_amount += parseFloat(item.reimbursement_amount || 0);
                        acc.deduction_amount += parseFloat(item.deduction_amount || 0);
                       // acc.salary_amount += item.amount_paid > 0 ? parseFloat(item.amount_paid || 0) : item?.make_standard_pay_as_salary && visaTypes.includes(item?.visa_type) ? parseFloat(item.standard_pay_amount || 0) : item?.lca_wage > 0 && visaTypes.includes(item?.visaTypes) ? parseFloat(item.lca_wage || 0) : parseFloat(item.earned_pay || 0);
                        acc.salary_amount +=parseFloat(item.amount_paid || 0);
                        
                        acc.balance_amount += parseFloat(item.balance_amount || 0);
                        return acc;
                    },
                    { earned_pay: 0, reimbursement_amount: 0, deduction_amount: 0, salary_amount: 0, balance_amount: 0 }
                ))
        }
    };

    const classes = GeneratePayrollStyles();

    const handleEarnedAmountData = (param) => {
        setViewClientEarnedAmount(param);
        setEarnedOpen(true);
    }

    const handleCurrentBalanceData = (param) => {
        setCurrentOpen(true);

        getBalanceSheetOfEmployee(param);


    }

    const getCommentsOfEmployee = (paymentId) => {
        GeneratePayrollApi.Comments(paymentId).then((res) => {
            if (res.data.statusCode === 1003) {
                setCommentsList(res.data.data);
            }
        })
    }

    const getTimeSheetofEmployee = (param) => {
        GeneratePayrollApi.Timesheet(payrollConfigId, param?.employee_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setTimesheetList(res.data.data);


            }
        })
    }

    const getReimbursementandDeduction = (param, text) => {
        setClickedEmpId(param?.employee_id);
        GeneratePayrollApi.GetReimbursementDeductions(payrollConfigId, param?.employee_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementDeduction(res.data.data);
                if (text == 'reimbursement') {
                    setRemDrawer(true);
                }
                if (text == 'deduction') {
                    setDeductionsDrawer(true);
                }
            }
        })
    }

    const getBalanceSheetOfEmployee = (param) => {
        const data = {
            employee_id: param?.employee_id,
            pay_period_from: payrollDataProp?.from_date,
            pay_period_to: payrollDataProp?.to_date
        }
        GeneratePayrollApi.GetBalanceSheetCurrent(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setBalanceSheet(res.data.data)
                setCurrentBalanceRow(param);
            }
        })
    }
    const [pagination, setPagination] = useState({});
    const [paginationTotal, setPaginationTotal] = useState({
        total: null,
        balanceSheets: []
    })

    const getAllBalanceSheetsOfEmployee = (param) => {
        const data = {
            employee_id: param?.employee_id
        }
        GeneratePayrollApi.GetBalanceSheet(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setAllBalanceSheet(res.data.data);
                setPagination(res.data.pagination);
                paginationTotal.total = Number(res.data.pagination.total);
                paginationTotal.balanceSheets = res.data.data;
                setPaginationTotal({ ...paginationTotal })
            }
        })
    }

    const getDetailsOfEmployee = (param) => {
        setActive(0)
        setSalaryOpen(true);
        getTimeSheetofEmployee(param);
        getReimbursementandDeduction(param, '');
        getAllBalanceSheetsOfEmployee(param);


    }

    const handleCommentOpen = (param) => {
        setCommentedOn(true);
        setCommentRow(param);
        getCommentsOfEmployee(param.payroll_payment_detail_id)
    }

    const handleSalaryData = (param) => {
        setCurrentSalaryRow(param);
        getDetailsOfEmployee(param);
    }


    const handleReimbursements = (param) => {
        getReimbursementandDeduction(param, 'reimbursement')

    }

    const handleDeduction = (param) => {
        getReimbursementandDeduction(param, 'deduction')

    }

    const handleCloseCommentDrawerAndUpdate = () => {
        setCommentedOn(false);
        getAllEmployees(payrollConfigId);
    }

    return (
        <div style={{ height: '100%' }}>
            <Box sx={{ margin: 1, height: '83%' }}>
                <TableContainer sx={{ maxHeight: '54vh', overflow: 'scroll', width: '100%' }}>
                    <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                        width: '100%',
                        // tableLayout: 'fixed',
                    }}
                    >
                        <TableHead >
                            <StyledTableRow sx={{ backgroundColor: "#FBFBFB !important" }}>
                                <StyledTableCell sx={{ width: '50px' }}>
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'select all employees',
                                        }}
                                        //indeterminate={selectedRows?.size > 0 && selectedRows?.size < tableData?.length}
                                        onChange={(e) => handleSelectAll(e)}
                                        checked={selectedRows?.size == tableData?.length}
                                        icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}

                                    />
                                </StyledTableCell>
                                <StyledTableCell sx={{ minWidth: '275px' }}>Employee</StyledTableCell>
                                <StyledTableCell align="start" sx={{ minWidth: 130 }}>
                                    Client Name
                                </StyledTableCell>
                                <StyledTableCell align="start" sx={{ minWidth: 100 }}>
                                    Visa Type
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ minWidth: 140 }}>
                                    Earned Amount
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ minWidth: 170 }}>
                                    Pay Amount
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ minWidth: 120 }}>
                                    Reimbursements
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ minWidth: 120 }}>
                                    Deductions
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ minWidth: 150 }}>
                                    Current Balance
                                </StyledTableCell>
                            </StyledTableRow>

                        </TableHead>

                        <TableBody >
                            {tableData.map((row, index) => (
                                <PayRow key={index} classes={classes} rowData={row}
                                    handleEarnedAmountData={handleEarnedAmountData}
                                    handleCurrentBalanceData={handleCurrentBalanceData}
                                    handleCommentOpen={handleCommentOpen}
                                    handleSalaryData={handleSalaryData}
                                    handleReimbursements={handleReimbursements}
                                    handleDeduction={handleDeduction}
                                    rowOnChange={handleRowSelect}
                                    rowChecked={selectedRows.has(row?.employee_id)}
                                    visaTypes={visaTypes}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"10vh"} marginLeft={'10px'} sx={{background:"#fffff",zIndex:1,overflow:'auto'}}>
                <Grid container  >
                    <Grid container item lg={10} md={10} sm={10} xs={12} >
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="start" >Total Selected Payees</Text>
                            <Text className={classes.amountTotal} textAlign="start" pt="4px" >{finalizedEmployees?.length}</Text>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="end">Total Earned Amount</Text>
                            <Text className={classes.amountTotal} textAlign="end" pt="4px">{getCurrencySymbol()} {formatAmount(breakdown?.earned_pay)}</Text>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="end">Total Salary Amount</Text>
                            <Text className={classes.amountTotal} textAlign="end" pt="4px">{getCurrencySymbol()} {formatAmount(breakdown?.salary_amount)}</Text>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="end">Total Reimbursements</Text>
                            <Text className={classes.amountTotal} textAlign="end" pt="4px">{getCurrencySymbol()} {formatAmount(breakdown?.reimbursement_amount)}</Text>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="end">Total Deductions</Text>
                            <Text className={classes.amountTotal} textAlign="end" pt="4px">{getCurrencySymbol()} {formatAmount(breakdown?.deduction_amount)}</Text>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={6} className={classes.borderRight}>
                            <Text className={classes.totalLabels} textAlign="end">Total Balance</Text>
                            <Text className={classes.amountTotal} textAlign="end" pt="4px">{getCurrencySymbol()} {formatAmount(breakdown?.balance_amount)}</Text>
                        </Grid>

                    </Grid>
                    <Grid container alignItems={"center"} justifyContent={"space-around"} item lg={2} md={2} sm={2} xs={12} >
                        <Button cancelBtn sx={{ minWidth: "60px !important", height: '40px !important' }} onClick={() => { setQuitPopup(true) }}>Cancel</Button>
                        <Button onClick={handlePayrollPreview} saveBtn sx={{ minWidth: "80px !important" }} >Preview</Button>
                    </Grid>
                </Grid>
            </Box>
            {/* Earned Amount  */}
            <ReusablePopup white iconHide statusWidth openPopup={earnedOpen} setOpenPopup={setEarnedOpen} onClose={() => { setEarnedOpen(false) }} showTitle={true}>
                <EarnedAmountPopup earnedOpen={earnedOpen} viewClientEarnedAmount={viewClientEarnedAmount} />
            </ReusablePopup>

            {/* Current Balance */}

            <ReusablePopup white iconHide statusWidth openPopup={currentOpen} setOpenPopup={setCurrentOpen} onClose={() => { setCurrentOpen(false) }} showTitle={true}>
                <CurrentBalancePopup currentOpen={currentOpen} currentBalanceRow={currentBalanceRow} balanceSheetProp={balanceSheet} />
            </ReusablePopup>

            {/* {previewpopup} */}
            <ReusablePopup white iconHide width800 openPopup={previewOpen} setOpenPopup={setPreviewOpen} onClose={() => { setPreviewOpen(false) }} showTitle={true}>
                <PayrollPreview payrollConfigId={payrollConfigId} previewOpen={previewOpen} tableData={tableData} payrollDataProp={payrollDataProp} finalizedEmployees={finalizedEmployees} nonfinalizedEmployees={nonfinalizedEmployees} closePopup={() => { setPreviewOpen(false) }} />
            </ReusablePopup>

            {/* Comments Drawer */}

            {commentedOn && <SwipeableDrawer
                anchor={'right'}
                open={commentedOn}
                onClose={() => { setCommentedOn(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <CommentsDrawer
                    commentedOn={commentedOn}
                    comments={commentsList}
                    paymentId={commentRow?.payroll_payment_detail_id}
                    handleCloseDrawer={handleCloseCommentDrawerAndUpdate}
                    balanceSheet={false}
                />
            </SwipeableDrawer>}

            {/* Salary Amount */}

            <SwipeableDrawer
                anchor={'right'}
                open={salaryOpen}
                onClose={() => { setSalaryOpen(false); setCurrentSalaryRow({}) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >

                <SalaryAmountDrawer summary={false} active={active} setActive={setActive} getAllEmployees={() => getAllEmployees(payrollConfigId)}
                    currentSalaryRowProp={currentSalaryRow}
                    timesheetListProp={timesheetList}
                    reimbursementDeductionProp={reimbursementDeduction}
                    allBalanceSheetProp={allBalanceSheet}
                    closePopUp={() => { setSalaryOpen(false); setCurrentSalaryRow({}) }}
                    balanceSheetPagination={pagination}
                    balanceSheetPaginationTotal={paginationTotal} payrollConfigId={payrollConfigId} salaryOpen={salaryOpen} />
            </SwipeableDrawer>

            {/* Reimbursements */}

            <SwipeableDrawer
                anchor={'right'}
                open={remDrawer}
                onClose={() => { setRemDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ReimbursementsDrawer summary={false} closeReimbursement={() => { setRemDrawer(false) }} getAllEmployees={() => getAllEmployees(payrollConfigId)} remDrawer={remDrawer} clickedEmpId={clickedEmpId} reimbursementDeductionProp={reimbursementDeduction} reimbursementTypeDropDown={reimbursementTypeDropDown} reimbursementPayPeriodDropDown={reimbursementPayPeriodDropDown} />
            </SwipeableDrawer>

            {/* Deductions */}
            <SwipeableDrawer
                anchor={'right'}
                open={deductionDrawer}
                onClose={() => { setDeductionsDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <DeductionsDrawer summary={false} getAllEmployees={() => getAllEmployees(payrollConfigId)} deductionDrawer={deductionDrawer} clickedEmpId={clickedEmpId} reimbursementDeductionProp={reimbursementDeduction} deductionTypeDropDown={deductionTypeDropDown} closeDeductionDrawer={() => { setDeductionsDrawer(false) }} />
            </SwipeableDrawer>

            {/* cancelpop */}
            <ReusablePopup white iconHide statusWidth openPopup={quitPopup} onClose={() => { setQuitPopup(false) }} setOpenPopup={setQuitPopup} showTitle={true}>
                <Box>
                    <Grid container display={"flex"} alignItems={'center'} justifyContent={'center'} sx={{ height: '380px', width: '100%', padding: '24px' }}>
                        <Grid item >
                            <img src={QuitImage} alt="quit" />
                        </Grid>
                        <Grid item display={"flex"} flexDirection={"column"} alignItems={'center'} justifyContent={'center'} marginTop={'32px'}>
                            <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                            <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>Do you want to quit generating the payroll or</Text>
                            <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}> save it as draft? </Text>

                        </Grid>
                        
                        <Grid container item md={12} sm={12} xs={12} justifyContent={"center"} gap={3} pt={2}>
                            <Button blackCancel100 sx={{ width: "100px !important", fontWeight: `${400} !important`, }} onClick={() => setQuitPopup(false) }>Cancel</Button>
                            <Button save14 sx={{ width: "100px !important", fontWeight: `${400} !important`, }} onClick={() => navigate("/payrollDashboard")}>Yes, Quit</Button>
                        </Grid>
                    </Grid>

                </Box>

            </ReusablePopup>

        </div>
    )
}

export default PayrollTable
