import { Backdrop, Box, Grid, Step, Stepper, Autocomplete, Chip, TextField, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { BrownMnColorlibStepLabel, BrownMnCustomisedConnector, clientandEndclientStepper } from '../../theme';
import Text from '../../components/customText/Text';
import logoName from '../../assets/svg/logoName.svg';
import OnboardStyles from './OnboardStyles';
import Dropzone from 'react-dropzone';
import download from '../../assets/employee/filedownload.svg';
import Button from '../../components/customButton/Button';
import { addErrorMsg, addLoader, addWarningMsg, capitalize, dateFormat, removeLoader, scrollToTop } from '../../utils/utils';
import Loader from '../../assets/gif/japfuLoader.gif';
import EmergencyContactInformation from './EmergencyContactInformation';
import I9andW4Documents from './I9andW4Documents';
import onBoardLinkApiInstance from '../../apis/onBoardLink/onBoardLinkApi';
// import clear from '../../assets/employee/cross-grey-circle.svg';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';
import { employeeInviteViaLinkDefaultConfig } from '../../config/pageConfig/EmployerModuleConfig';
import CommonApi from '../../apis/CommonApi';
import Input from '../../components/input/Input';
import { isValid, validate_input_fields } from '../../components/Validation';
import moment from 'moment';
import EmployeesApi from '../../apis/admin/employees/EmployeesApi';
import Date from '../../components/datePicker/Date';
import Select from '../../components/select/Select';
import { viaLinkOnboardingDefaultConfig } from '../../config/pageConfig/EmployeeModuleConfig';
import { ErrorMessages } from '../../utils/ErrorMessages';


function OnboardEmployeeUpload({ state, setState, id, active, setActive, preView, setPreview, getInviteViaLink, data }) {



    const genderList = require('../../utils/jsons/Gender.json');
    const [uploadDocumentsConfig, setUploadDocumentsConfig] = useState(employeeInviteViaLinkDefaultConfig?.uploadDocuments)
    const [i9W4DocumentsConfig, setI9E4DocumentsConfig] = useState(employeeInviteViaLinkDefaultConfig?.i9W4Documents)
    const [emergencyContactDetailsConfig, setEmergencyContactDetailsConfig] = useState(employeeInviteViaLinkDefaultConfig?.emergencyContactDetails)
    const [basicDetailsConfig, setBasicDetailsConfig] = useState(viaLinkOnboardingDefaultConfig?.basicDetails)
    const [error, setError] = useState({});
    let dataKeys = Object.keys(data.upload_documents)
    let x = dataKeys.filter(
        (i) => data.upload_documents[i].checked == true
    )
    let options = {
        counter_signed_offer_letter: {
            name: 'Countersigned Offer Letter',
            content: `A countersigned offer is a document that has been signed by both the employer and the prospective employee, indicating mutual agreement and acceptance of the terms outlined in the job offer. This counter offer can be downloaded from the mail.`
        },
        passport: {
            name: 'Passport',
            content: `Please include all pages with stamps or visas, along with the biodata page, ensuring correct sizing and order, excluding blank pages.`
        },
        i94: {
            name: 'I94',
            content: `Please upload your record showing entry date, immigration status, and authorized duration of stay.`
        },
        signed_ssn: {
            name: 'Signed SSN',
            content: `Your "signed SSN" on official documents like the Social Security card or tax forms is validated for various purposes like identity verification, employment eligibility, tax reporting, and accessing government benefits or services.`
        },
        work_authorization: {
            name: 'Work Authorization',
            content: `Please upload your current visa document and previous visa documents.`
        },
        all_previous_i20s: {
            name: `All Previous I-20’s`,
            content: `The "previous signed I-20" is a prior Form I-20, a crucial document issued by U.S. educational institutions to international students, confirming acceptance and visa status terms.`
        },
        education_documents: {
            name: 'Education Documents',
            content: `Upload your undergraduate and highest degree documents.`
        },
        drivers_license: {
            name: 'Driving License/State ID',
            content: `Please upload a clear copy of your Driver's License. This document serves as an official identification and verifies your driving eligibility.`
        },
        copy_of_void_cheque: {
            name: 'Copy Of Void Cheque',
            content: `A void cheque bears the word 'VOID' prominently. It's a blank cheque used to share account details securely. Please enter max 5 void cheques.`
        }
    }

    const classes = OnboardStyles();
    const mainStepsList = ['Basic Details', 'Upload Documents', 'I-9 & W4', 'Emergency Contact Information'];
    const [mainStep, setMainStep] = useState(0);
    const [expand, setExpand] = useState(true);
    const [disableExpand, setDisableExpand] = useState(false);
    const [status, setStatus] = useState('');
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [name, setName] = useState(x[0]);



    useEffect(() => {
        const pageData = {
            page_name: "employee-invite-via-link",
            page_type: "create",
            client_id: LocalStorage.getPathId()
        };

        const inviteData = {
            page_name: "employee-add",
            page_type: "create",
            with_token: true
        };

        async function fetchData() {
            try {
                // Fetch both API calls in parallel
                const [pageResponse, inviteResponse] = await Promise.all([
                    CommonApi.fetchPageConfig(pageData),
                    CommonApi.fetchPageConfig(inviteData)
                ]);

                if (pageResponse.status === 200) {
                    setUploadDocumentsConfig(pageResponse?.data?.data?.page_data?.uploadDocuments);
                    setI9E4DocumentsConfig(pageResponse?.data?.data?.page_data?.i9W4Documents);
                    setEmergencyContactDetailsConfig(pageResponse?.data?.data?.page_data?.emergencyContactDetails);
                }

                if (inviteResponse.status === 200) {
                    setBasicDetailsConfig(inviteResponse?.data?.data?.page_data?.basicDetails);
                }
            } catch (error) {
                console.error("Error fetching page data:", error);
            }
        }

        fetchData();

    }, [])

    const changeHandler = (e) => {
        const { name, value } = e.target;

        if (name === 'mobile_number') {
            setState(prevState => ({ ...prevState, [name]: formatPhoneNumber(value) }));
        } else if (name === 'first_name' || name === 'last_name' || name === 'middle_name') {
            setState(prevState => ({ ...prevState, [name]: capitalize(value) }));
        } else if (name === 'gender') {
            setState(prevState => ({ ...prevState, gender: value, gender_description: '' }));
        } else {
            setState(prevState => ({ ...prevState, [name]: value }));
        }
        handleValidate(e)
    };


    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({ ...state, [name]: date == '' ? '' : moment(date).format(dateFormat()) })
        handleValidate(event)
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case 'first_name':
                error.first_name = validate_input_fields(input.value, basicDetailsConfig?.first_name)
                break
            case 'last_name':
                error.last_name = validate_input_fields(input.value, basicDetailsConfig?.last_name)
                break
            case 'middle_name':
                error.middle_name = validate_input_fields(input.value, basicDetailsConfig?.middle_name)
                break
            case 'dob':
                error.dob = validate_input_fields(input.value, basicDetailsConfig?.dob)
                break
            case 'gender':
                error.gender = validate_input_fields(input.value, basicDetailsConfig?.gender)
                break
            case 'mobile_number':
                error.mobile_number = validate_input_fields(input.value, basicDetailsConfig?.mobile_number)
                if (input.value.length === 12) {
                    let payload;
                    payload = {
                        request_id: LocalStorage.uid(),
                        contact_number: input.value,
                        id: '',
                        invited_employee_id: ''
                    };
                    EmployeesApi.checkDuplicate(payload).then((res) => {
                        if (res.data.statusCode === 1003) {
                            if (!res.data.valid) {
                                error.mobile_number = res?.data?.employee_id
                                    ? `The entered mobile_number ${input.value} is already associated with Employee ID: ${res?.data?.employee_id ?? '--'}`
                                    : `The entered mobile_number is already linked to an invited employee.`;
                            }
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })

                }
                break
            case 'gender_description':
                error.gender_description = validate_input_fields(input.value, basicDetailsConfig?.gender_description)
                break
            case 'date_of_joining':
                error.date_of_joining = validate_input_fields(input.value, basicDetailsConfig?.date_of_joining)
                break
            default:
                break
        }
        setError({ ...error });
    }

    const formatPhoneNumber = (value) => {
        // Remove non-numeric characters
        value = value.replace(/\D/g, '');

        if (value.length <= 3) return value;
        if (value.length <= 6) return `${value.slice(0, 3)}-${value.slice(3)}`;
        return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    };


    const getFileSizeLimitConfig = (fieldName) => {
        let fileConfig = {}
        switch (fieldName) {
            case 'counter_signed_offer_letter':
                fileConfig = uploadDocumentsConfig?.counter_signed_offer_letter
                break
            case 'passport':
                fileConfig = uploadDocumentsConfig?.passport
                break
            case 'i94':
                fileConfig = uploadDocumentsConfig?.i94
                break
            case 'signed_ssn':
                fileConfig = uploadDocumentsConfig?.signed_ssn
                break
            case 'work_authorization':
                fileConfig = uploadDocumentsConfig?.work_authorization
                break
            case 'all_previous_i20s':
                fileConfig = uploadDocumentsConfig?.all_previous_i20s
                break
            case 'education_documents':
                fileConfig = uploadDocumentsConfig?.education_documents
                break
            case 'copy_of_void_cheque':
                fileConfig = uploadDocumentsConfig?.copy_of_void_cheque
                break
            case 'drivers_license':
                fileConfig = uploadDocumentsConfig?.drivers_license
                break
            default:
                break
        }
        return fileConfig
    }

    const handleExpand = (args, name) => {
        if (args == active) {
            setExpand(!expand)
        } else {
            if (name === 'active') {
                setDisableExpand(!disableExpand);
                setStatus(args);
            } else {
                setDisableExpand(disableExpand);
                setStatus('');
            }
        }
    }
    const handleDrop = (e) => {
        const { format, formatMsg, size, sizeMsg, totalFiles, totalFilesExcedMsg } = getFileSizeLimitConfig(name)

        if (e.length <= uploadDocumentsConfig?.overAllFilesLimit) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => format?.includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < size) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        // formData.append("tenant_id", '4a0bcfaf-5fb3-4d88-b133-b1b5eb6c137f');
                        onBoardLinkApiInstance.documentUploadInvite(
                            name == 'copy_of_void_cheque' ? 'bank-document' : name == 'counter_signed_offer_letter' ? 'personal-document' : name == 'work_authorization' ? 'visa-document' :
                                name == 'all_previous_i20s' ? 'visa-document' : name == 'signed_ssn' ? 'education-document' : name == 'education_documents' ? 'education-document' :
                                    name == 'passport' ? 'passport-document' : name == 'i94' ? 'i94-document' : name == 'drivers_license' ? 'personal-document' : '', formData).then((res) => {
                                        if (res.data.statusCode == 1003) {
                                            if (name == 'counter_signed_offer_letter' && state.upload_documents.counter_signed_offer_letter.data.length < totalFiles) {
                                                state.upload_documents.counter_signed_offer_letter.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.counter_signed_offer_letter.data);
                                            } else if (name == 'work_authorization' && state.upload_documents.work_authorization.data.length < totalFiles) {
                                                state.upload_documents.work_authorization.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.work_authorization.data);
                                            } else if (name == 'all_previous_i20s' && state.upload_documents.all_previous_i20s.data.length < totalFiles) {
                                                state.upload_documents.all_previous_i20s.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.all_previous_i20s.data);
                                            } else if (name == 'signed_ssn' && state.upload_documents.signed_ssn.data.length < totalFiles) {
                                                state.upload_documents.signed_ssn.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.signed_ssn.data);
                                            } else if (name == 'education_documents' && state.upload_documents.education_documents.data.length < totalFiles) {
                                                state.upload_documents.education_documents.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.education_documents.data)
                                            } else if (name == 'passport' && state.upload_documents.passport.data.length < totalFiles) {
                                                state.upload_documents.passport.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.passport.data)
                                            } else if (name == 'i94' && state.upload_documents.i94.data.length < totalFiles) {
                                                state.upload_documents.i94.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.i94.data)
                                            } else if (name == 'drivers_license' && state.upload_documents.drivers_license.data.length < totalFiles) {
                                                state.upload_documents.drivers_license.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.upload_documents.drivers_license.data);
                                                // const data1 = {
                                                //     id: res.data.data.id,
                                                //     url: res.data.data.document_url
                                                // }
                                                // EmployeesApi.ocrDocumentUpload('dl-data', data1).then((res) => {
                                                //     if (res.data.statusCode == 1003) {
                                                //         if (i === e.length - 1) {
                                                //             setOpenBackDrop(false);
                                                //         }
                                                //         setPreview(state.upload_documents.drivers_license.data);
                                                //     } else {
                                                //         addErrorMsg(res.data.message);
                                                //     }
                                                // })
                                            }
                                            else if (name == 'copy_of_void_cheque' && state.upload_documents.copy_of_void_cheque.data.length < totalFiles) {
                                                state.upload_documents.copy_of_void_cheque.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                setPreview(state.upload_documents.copy_of_void_cheque.data);
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                            }
                                            else {
                                                setOpenBackDrop(false);
                                                addErrorMsg(totalFilesExcedMsg)
                                                // need to add totalFilesExcedMsg for offer letter
                                            }
                                            setState({ ...state })
                                        } else {
                                            setOpenBackDrop(false);
                                            addErrorMsg(res.data.message);
                                        }
                                    })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg(sizeMsg);
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg(formatMsg)
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg(uploadDocumentsConfig?.overAllFilesLimitMsg)
        }
    }

    const deleteChip = (args) => {
        if (name == 'copy_of_void_cheque') {
            state.upload_documents.copy_of_void_cheque.data.splice(args, 1)
        } else if (name == 'counter_signed_offer_letter') {
            state.upload_documents.counter_signed_offer_letter.data.splice(args, 1)
        } else if (name == 'work_authorization') {
            state.upload_documents.work_authorization.data.splice(args, 1)
        } else if (name == 'all_previous_i20s') {
            state.upload_documents.all_previous_i20s.data.splice(args, 1)
        } else if (name == 'signed_ssn') {
            state.upload_documents.signed_ssn.data.splice(args, 1)
        } else if (name == 'education_documents') {
            state.upload_documents.education_documents.data.splice(args, 1);
        } else if (name == 'passport') {
            state.upload_documents.passport.data.splice(args, 1);
        } else if (name == 'i94') {
            state.upload_documents.i94.data.splice(args, 1);
        } else if (name == 'drivers_license') {
            state.upload_documents.drivers_license.data.splice(args, 1);
        }
        setState({ ...state });
    }

    const [btLoading, setBtnLoading] = useState(false);
    const updateApi = () => {
        state['subdomain_name'] = domain
        state['request_id'] = LocalStorage.uid();
        if (mainStep === 0) { state['basic_details'] = true }
        else { state['basic_details'] = false }
        addLoader(true);
        setBtnLoading(true);
        onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
            setBtnLoading(false);
            if (res.data.statusCode == 1003) {
                removeLoader();
                getInviteViaLink(id);
                if (mainStep === 0) {
                    setMainStep(1);
                    scrollToTop();
                } else {
                    if (active <= (x.length - 1)) {
                        setActive(active + 1);
                        setName(x[active]);
                    } else if (active == x.length) {
                        setMainStep(mainStep + 1);
                        scrollToTop();
                    }
                }

            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleSubmit = () => {
        if (mainStep === 0) {
            basicDetailsError()
            let basic_error = {
                first_name: error.first_name,
                last_name: error.last_name,
                dob: error.dob,
                offer_letter_id: error.offer_letter_id,
                gender: error.gender,
                email_id: error.email_id,
                mobile_number: error.mobile_number,
                employee_category_id: error.employee_category_id,
                middle_name: error.middle_name ? error.middle_name : '',
                blood_group: error.blood_group ? error.blood_group : '',
                gender_description: state.gender == 'Prefer to self-describe' ? error.gender_description : '',
                date_of_joining: error.date_of_joining,
            }
            if (isValid(basic_error)) {
                addLoader(true);
                updateApi();
            } else {
                addWarningMsg(ErrorMessages.genericMessage);
            }
        } else {
            addLoader(true);
            updateApi();
        }
    }

    const handleSkip = () => {
        if (active <= (x.length - 1)) {
            setActive(active + 1);
            setName(x[active]);
        } else if (active == x.length) {
            setMainStep(mainStep + 1);
            scrollToTop();
        }
    }
    const handleBack = () => {
        if (active <= x.length) {
            setActive(active - 1);
            setName(x[active - 2]);
        }
    }

    const basicDetailsError = () => {
        const { first_name, last_name, middle_name, dob, gender, mobile_number, gender_description, date_of_joining } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        error.first_name = validate_input_fields(first_name, basicDetailsConfig?.first_name)
        error.last_name = validate_input_fields(last_name, basicDetailsConfig?.last_name)
        error.middle_name = validate_input_fields(middle_name, basicDetailsConfig?.middle_name)
        error.dob = dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : basicDetailsConfig?.dob?.ageLimitMsg : validate_input_fields(dob, basicDetailsConfig?.dob);
        error.gender = validate_input_fields(gender, basicDetailsConfig?.gender);
        error.mobile_number = error.mobile_number?.startsWith('The entered phone number') ? error.mobile_number : validate_input_fields(mobile_number, basicDetailsConfig?.mobile_number);
        error.gender_description = state.gender == 'Prefer to self-describe' ? validate_input_fields(gender_description, basicDetailsConfig?.gender_description) : '';
        error.date_of_joining = state.date_of_joining == 'Invalid date' ? 'Invalid data Entry'
            : moment(state.date_of_joining, LocalStorage.getDateFormat()).isBefore(moment(state.dob, LocalStorage.getDateFormat())) ? 'Please select a date after your birth date.'
                : validate_input_fields(date_of_joining, basicDetailsConfig?.date_of_joining);

        setError({ ...error })
        return setError({ ...error })
    }

    const useThem = useTheme()
    return (
        <Grid container>
            <Grid item lg={2} md={2} sm={2} xs={2}>
                <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                <Grid item xl={7} lg={8} md={10} sm={10} xs={12} justifyContent='center' textAlign='center' pt={3}>
                    <Stepper activeStep={mainStep} connector={<BrownMnCustomisedConnector />}>
                        {
                            mainStepsList.map((item) => (
                                <Step>
                                    <BrownMnColorlibStepLabel StepIconComponent={clientandEndclientStepper}>
                                        <Text BrowmnMnStepperText>{item}</Text>
                                    </BrownMnColorlibStepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Grid>
                <Grid item container xl={8} lg={9} md={11} sm={11} xs={11} justifyContent='center' textAlign='center' pt={7} spacing={2} pb={{ xs: 5 }}>
                    {
                        mainStep == 0 ?
                            <Box my={1} px={{ lg: 8, md: 7, sm: 6, xs: 1 }} textAlign='start'>
                                <Grid container spacing={2} columnSpacing={3} mb={3} >
                                    {basicDetailsConfig?.first_name?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'first_name',
                                                    value: state.first_name,
                                                    inputProps: { maxLength: basicDetailsConfig?.first_name?.maxLength }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={basicDetailsConfig?.first_name?.required ? basicDetailsConfig?.first_name?.label : basicDetailsConfig?.first_name?.label + ' (Optional)'}
                                                placeholder={basicDetailsConfig?.first_name?.placeholder}
                                                error={error.first_name}
                                                disabled={basicDetailsConfig?.first_name?.isDisabled}
                                            />
                                            {
                                                error.first_name &&
                                                <Text red>{error.first_name ? error.first_name : ''}</Text>
                                            }
                                        </Grid>}
                                    {basicDetailsConfig?.middle_name?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'middle_name',
                                                    value: state.middle_name,
                                                    inputProps: { maxLength: basicDetailsConfig?.middle_name?.maxLength }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                error={error.middle_name}
                                                labelText={basicDetailsConfig?.middle_name?.required ? basicDetailsConfig?.middle_name?.label : basicDetailsConfig?.middle_name?.label + ' (Optional)'}
                                                placeholder={basicDetailsConfig?.middle_name?.placeholder}
                                                disabled={basicDetailsConfig?.middle_name?.isDisabled}
                                            />
                                            {
                                                error.middle_name &&
                                                <Text red>{error.middle_name ? error.middle_name : ''}</Text>
                                            }
                                        </Grid>}
                                    {basicDetailsConfig?.last_name?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'last_name',
                                                    value: state.last_name,
                                                    inputProps: { maxLength: basicDetailsConfig?.last_name?.maxLength }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={basicDetailsConfig?.last_name?.required ? basicDetailsConfig?.last_name?.label : basicDetailsConfig?.last_name?.label + ' (Optional)'}
                                                placeholder={basicDetailsConfig?.last_name?.placeholder}
                                                disabled={basicDetailsConfig?.last_name?.isDisabled}
                                                error={error.last_name}
                                            />
                                            {
                                                error.last_name &&
                                                <Text red>{error.last_name ? error.last_name : ''}</Text>
                                            }
                                        </Grid>}
                                    {basicDetailsConfig?.email_id?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'email_id',
                                                    value: state.email_id
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                error={error.email_id}
                                                labelText={basicDetailsConfig?.email_id?.required ? basicDetailsConfig?.email_id?.label : basicDetailsConfig?.email_id?.label + ' (Optional)'}
                                                placeholder={basicDetailsConfig?.email_id?.placeholder}
                                                disabled={basicDetailsConfig?.email_id?.isDisabled}
                                            />
                                            {
                                                error.email_id &&
                                                <Text red>{error.email_id ? error.email_id : ''}</Text>
                                            }
                                        </Grid>}
                                </Grid>
                                <Grid container spacing={2} columnSpacing={3}>
                                    {basicDetailsConfig?.mobile_number?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'mobile_number',
                                                    value: state.mobile_number,
                                                    inputProps: { maxLength: basicDetailsConfig?.mobile_number?.maxLength }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={basicDetailsConfig?.mobile_number?.required ? basicDetailsConfig?.mobile_number?.label : basicDetailsConfig?.mobile_number?.label + ' (Optional)'}
                                                placeholder={basicDetailsConfig?.mobile_number?.placeholder}
                                                disabled={basicDetailsConfig?.mobile_number?.isDisabled}
                                                error={error.mobile_number}
                                            />
                                            {
                                                error.mobile_number &&
                                                <Text red>{error.mobile_number ? error.mobile_number : ''}</Text>
                                            }
                                        </Grid>}
                                    {basicDetailsConfig?.dob?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box >
                                                <Date
                                                    labelText={basicDetailsConfig?.dob?.required ? basicDetailsConfig?.dob?.label : basicDetailsConfig?.dob?.label + ' (Optional)'}
                                                    name='dob'
                                                    value={state.dob}
                                                    onChange={(value => dateChange(value, 'dob'))}
                                                    height='54px'
                                                    maxDate={moment().subtract(basicDetailsConfig?.dob?.ageLimit, "years")}
                                                    error={error.dob ? error.dob : ''}
                                                    disabled={basicDetailsConfig?.dob?.isDisabled}
                                                />
                                            </Box>
                                            {
                                                error.dob &&
                                                <Text red>{error.dob ? error.dob : ''}</Text>
                                            }
                                        </Grid>}
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: 'Consultant',
                                                disabled: true
                                            }}
                                            disabled={true}
                                            clientInput
                                            labelText={'Employment Type'}
                                        />
                                    </Grid>
                                    {basicDetailsConfig?.gender?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box pt={'8px'}>
                                                <Select
                                                    name='gender'
                                                    value={state.gender}
                                                    onChange={changeHandler}
                                                    options={genderList}
                                                    label={basicDetailsConfig?.gender?.required ? basicDetailsConfig?.gender?.label : basicDetailsConfig?.gender?.label + ' (Optional)'}
                                                    placeholder={basicDetailsConfig?.gender?.placeholder}
                                                    disabled={basicDetailsConfig?.gender?.isDisabled}
                                                    error={error.gender ? error.gender : ''}
                                                />
                                                {
                                                    error.gender &&
                                                    <Text red>{error.gender ? error.gender : ''}</Text>
                                                }
                                            </Box>
                                        </Grid>}
                                    {
                                        state.gender == 'Prefer to self-describe' && basicDetailsConfig?.gender_description?.isVisible ?
                                            <Grid item lg={12} md={12} sm={12} xs={12} py={'4px'}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'gender_description',
                                                        value: state.gender_description,
                                                        inputProps: { minLength: basicDetailsConfig?.gender_description?.minLength, maxLength: basicDetailsConfig?.gender_description?.maxLength }
                                                    }}
                                                    handleChange={changeHandler}
                                                    clientInput
                                                    labelText={basicDetailsConfig?.gender_description?.required ? basicDetailsConfig?.gender_description?.label : basicDetailsConfig?.gender_description?.label + ' (Optional)'}
                                                    placeholder={basicDetailsConfig?.gender_description?.placeholder}
                                                    disabled={basicDetailsConfig?.gender_description?.isDisabled}
                                                    error={error.gender_description}
                                                />
                                                {
                                                    error.gender_description &&
                                                    <Text red>{error.gender_description ? error.gender_description : ''}</Text>
                                                }
                                            </Grid> : ''
                                    }
                                    {basicDetailsConfig?.date_of_joining?.isVisible &&
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box pt={'8px'}>
                                                <Date
                                                    labelText={basicDetailsConfig?.date_of_joining?.required ? basicDetailsConfig?.date_of_joining?.label : basicDetailsConfig?.date_of_joining?.label + ' (Optional)'}
                                                    name='date_of_joining'
                                                    value={state?.date_of_joining}
                                                    onChange={(value => dateChange(value, 'date_of_joining'))}
                                                    height='54px'
                                                    minDate={state?.dob}
                                                    disabled={basicDetailsConfig?.date_of_joining?.isDisabled}
                                                    error={error.date_of_joining ? error.date_of_joining : ''}
                                                />
                                            </Box>
                                            {
                                                error.date_of_joining &&
                                                <Text red>{error.date_of_joining ? error.date_of_joining : ''}</Text>
                                            }
                                        </Grid>}

                                    <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='end' pt={3}>
                                        <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            mainStep == 1 ?
                                <>
                                    <Grid item container lg={4} md={4} sm={4} xs={12} rowSpacing={2} sx={{ height: '550px', overflowY: 'scroll', [useThem.breakpoints.down('sm')]: { height: "300px" } }}>
                                        {
                                            x.map((item, index) => (
                                                <Grid item lg={12} md={12} sm={12} xs={12} className={active == index + 1 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(index + 1, 'active', item)}>
                                                    {
                                                        active == index + 1 ?
                                                            <>
                                                                <Text largeWhite>{index + 1}. {options[item].name}</Text>
                                                                {
                                                                    (active == index + 1) && expand ?
                                                                        <Text mediumBoldWhite400 sx={{ paddingTop: '10px' }}>{options[item].content}</Text> : ''
                                                                }
                                                            </> :
                                                            <>
                                                                <Text overViewLable>{index + 1}. {options[item].name}</Text>
                                                                {
                                                                    disableExpand && (status == (index + 1)) ?
                                                                        <Text smallGreyFont sx={{ paddingTop: '10px' }}>{options[item].content}</Text> : ''
                                                                }
                                                            </>
                                                    }
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    <Grid item container spacing={2} lg={8} md={8} sm={8} xs={12}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className={classes.dropZone}>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <Box className={classes.dropzoneContainer}>
                                                                <Grid container spacing={1} textAlign='center' justifyContent='center'>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'-8px'}>
                                                                        <img src={download} alt='download' />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                        <Text largeBlack>Drag & Drop Documents</Text>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                        <Text smallGrey>or</Text>
                                                                    </Grid>
                                                                    <Box display='flex' flexDirection='row' gap={2} textAlign='center' p={'10px 0px 20px 0px'}>
                                                                        <Text smallBlue1 className={classes.clickhere}>Click Here</Text>
                                                                        <Text largeBlack>To Upload</Text>
                                                                    </Box>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                                        <Text smallGreyFont>{getFileSizeLimitConfig(name)?.dropAreaLabel}</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                                <div style={{
                                                    width: '100%',
                                                    paddingTop:
                                                        (name == "copy_of_void_cheque" && state.upload_documents.copy_of_void_cheque.data.length > 0) ||
                                                            (name == "counter_signed_offer_letter" && state.upload_documents.counter_signed_offer_letter.data.length > 0) ||
                                                            (name == "work_authorization" && state.upload_documents.work_authorization.data.length > 0) ||
                                                            (name == "all_previous_i20s" && state.upload_documents.all_previous_i20s.data.length > 0) || (name == "signed_ssn" && state.upload_documents.signed_ssn.data.length > 0) ||
                                                            (name == "education_documents" && state.upload_documents.education_documents.data.length > 0) || (name == "passport" && state.upload_documents.passport.data.length > 0) ||
                                                            (name == "i94" && state.upload_documents.i94.data.length > 0) || (name == "drivers_license" && state.upload_documents.drivers_license.data.length > 0)
                                                            ? '12px' : '0px',
                                                    minHeight: '59px', display: 'flex', alignItems: "center",
                                                }}>
                                                    <Autocomplete
                                                        clearIcon={false}
                                                        options={[]}
                                                        freeSolo
                                                        multiple
                                                        onKeyPress={(e) => { e.preventDefault() }}
                                                        value={(name == "copy_of_void_cheque" && state.upload_documents.copy_of_void_cheque.data) || (name == "counter_signed_offer_letter" && state.upload_documents.counter_signed_offer_letter.data) ||
                                                            (name == "work_authorization" && state.upload_documents.work_authorization.data) || (name == "all_previous_i20s" && state.upload_documents.all_previous_i20s.data) ||
                                                            (name == "signed_ssn" && state.upload_documents.signed_ssn.data) || (name == "education_documents" && state.upload_documents.education_documents.data) ||
                                                            (name == "passport" && state.upload_documents.passport.data) || (name == "i94" && state.upload_documents.i94.data) || (name == "drivers_license" && state.upload_documents.drivers_license.data)}
                                                        renderTags={(value, props) =>
                                                            value.map((option, index) => (
                                                                <Chip label={option.document_name} {...props({ index })}
                                                                    onClick={() => window.open(option.document_url)}
                                                                    onDelete={() => { deleteChip(index) }}
                                                                    className={classes.chipLabel}
                                                                    sx={{ gap: '6px', m: "4px 6px", p: "4px", }}
                                                                />
                                                            ))
                                                        }
                                                        renderInput={(params) => <TextField label={getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel?.substring(0, 80) + '...' : getFileSizeLimitConfig(name)?.fieldLabel} title={getFileSizeLimitConfig(name)?.fieldLabel?.length > 80 ? getFileSizeLimitConfig(name)?.fieldLabel : ''} {...params} />}
                                                        sx={{
                                                            width: '100%',
                                                            "& .MuiInputBase-root": {
                                                                cursor: "pointer",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                cursor: "pointer",
                                                            },
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border: "none"
                                                            },
                                                            "& .MuiAutocomplete-endAdornment": {
                                                                display: "none"
                                                            },
                                                            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                                transform: "translate(10px, 16px) scale(1);"
                                                            },
                                                            "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                                color: "#737373",
                                                                fontSize: "14px",
                                                                fontFamily: "Quicksand !important",
                                                                fontWeight: 400,
                                                            },
                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                                color: "#737373",
                                                                fontSize: "15px",
                                                                fontFamily: "Quicksand !important",
                                                                fontWeight: 500,
                                                            },
                                                            "&.Mui-focused .MuiInputLabel-outlined": {
                                                                color: "#737373",
                                                                fontSize: "10px",
                                                                fontFamily: "Quicksand !important",
                                                                fontWeight: 400,
                                                                transform: (active == 1 && state.upload_documents.copy_of_void_cheque.data.length == 0) ||
                                                                    (active == 2 && state.upload_documents.counter_signed_offer_letter.data.length == 0) ||
                                                                    (active == 3 && state.upload_documents.work_authorization.data.length == 0) ||
                                                                    (active == 4 && state.upload_documents.all_previous_i20s.data.length == 0) || (active == 5 && state.upload_documents.signed_ssn.data.length == 0) ||
                                                                    (active == 6 && state.upload_documents.education_documents.data.length == 0) || (active == 7 && state.upload_documents.passport.data.length == 0) ||
                                                                    (active == 8 && state.upload_documents.i94.data.length == 0) || (active == 9 && state.upload_documents.drivers_license.data.length == 0) ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                                            <Grid item lg={7} md={5} sm={5} xs={12} textAlign='start'>
                                                {
                                                    active == 1 ?
                                                        <Button disabled={btLoading} blackCancel onClick={() => { setMainStep(0); getInviteViaLink(id); }}>Back</Button> :
                                                        <Button disabled={btLoading} blackCancel onClick={handleBack}>Back</Button>
                                                }
                                            </Grid>
                                            <Grid item lg={5} md={7} sm={7} xs={12} textAlign='end'>
                                                <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                                    <Button disabled={btLoading} borderlessSkip onClick={handleSkip}>Skip</Button>
                                                    <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </> :
                                mainStep == 2 ?
                                    <I9andW4Documents mainStep={mainStep} setMainStep={setMainStep} state={state} setState={setState} getInviteViaLink={getInviteViaLink} id={id} i9W4DocumentsConfig={i9W4DocumentsConfig} /> :
                                    mainStep == 3 ?
                                        <EmergencyContactInformation mainStep={mainStep} setMainStep={setMainStep} state={state} setState={setState} getInviteViaLink={getInviteViaLink} id={id} emergencyContactDetailsConfig={emergencyContactDetailsConfig} /> : ''
                    }
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Grid >
    )
}

export default OnboardEmployeeUpload