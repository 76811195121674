import { Avatar, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Button from '../../../../components/customButton/Button'
import GeneratePayrollStyles from './GeneratePayrollStyles';
import Text from '../../../../components/customText/Text';
import { addErrorMsg, BlackToolTip, capitalizeAndAddSpace, downloadExcel, formatNumberWithCommas, getCurrencySymbol, NoDataFound } from '../../../../utils/utils';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';

import { ReactComponent as GeneratePayroll } from '../../../../assets/svg/generate_payroll.svg'
import { ReactComponent as SuccessPayroll } from '../../../../assets/svg/success_payroll.svg'
import submission_payroll from '../../../../assets/gif/payroll_submission.gif'
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const DataTableRow = (props) => {
    const { rowData, classes } = props;
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    height: "74px !important",
                    boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    '&:hover': {
                        borderRadius: '8px',
                        transition: "all .3s ease",
                        boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                    },

                    '& > *': {
                        borderBottom: 'none !important',
                        paddingTop: '10px !important',
                        paddingBottom: '10px !important',
                    },
                }}

            >
                <TableCell sx={{ minWidth: 240, font: "14px Quicksand !important" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.7 }}>
                        <Avatar
                            alt={rowData?.display_name}
                            src={rowData?.profile_picture_url}
                            small
                        />
                        <Box>
                            <Text className={classes.profileName} sx={{ fontSize: '13px !important' }}>

                                {rowData?.display_name && rowData?.display_name.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(rowData?.display_name)} placement="top" arrow>
                                        {capitalizeAndAddSpace(rowData?.display_name).slice(0, 16) + (capitalizeAndAddSpace(rowData?.display_name.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : capitalizeAndAddSpace(rowData?.display_name)
                                }
                            </Text>
                            <Text textAlign="start" className={classes.profileId} sx={{ fontSize: '9px !important' }}>
                                Emp ID : &nbsp; 
                                {rowData?.emp_reference_id && rowData?.emp_reference_id.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(rowData?.emp_reference_id)} placement="top" arrow>
                                        {capitalizeAndAddSpace(rowData?.emp_reference_id).slice(0, 16) + (capitalizeAndAddSpace(rowData?.emp_reference_id.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : capitalizeAndAddSpace(rowData?.emp_reference_id)
                                }
                            </Text>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align={'right'} sx={{ minWidth: 150, font: "14px Quicksand !important" }}> 
                {rowData?.amount_paid ? <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.amount_paid}</Text>: <Text mediumBlack14 > {getCurrencySymbol()} 0</Text>}

                    {/* {rowData?.amount_paid > 0 ? <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.amount_paid}</Text>
                     : rowData?.make_standard_pay_as_salary && visaTypes.includes(rowData?.visa_type) ?
                        <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.standard_pay_amount}</Text> :
                        rowData?.lca_wage > 0 && visaTypes.includes(rowData?.visa_type) ?
                            <Text mediumBlack14   > {getCurrencySymbol()} {rowData?.lca_wage}</Text> :
                            <Text mediumBlack14 > {getCurrencySymbol()} {rowData?.earned_pay}</Text>
                    } */}
                    </TableCell>
                <TableCell align={'right'} sx={{ minWidth: 150, font: "14px Quicksand !important" }}>{getCurrencySymbol()}{rowData?.reimbursement_amount}</TableCell>
                <TableCell align={'right'} sx={{ minWidth: 150, font: "14px Quicksand !important" }}>{getCurrencySymbol()}{rowData?.deduction_amount}</TableCell>
                <TableCell align={'right'} sx={{ minWidth: 150, font: "14px Quicksand !important" }}>{getCurrencySymbol()}{rowData?.balance_amount}</TableCell>

            </TableRow>
        </React.Fragment>
    );
}

function PayrollPreview(props) {

    const { finalizedEmployees, nonfinalizedEmployees, closePopup, payrollDataProp, payrollConfigId } = props;
    const classes = GeneratePayrollStyles()
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(1);// eslint-disable-next-line
    const [totalAmount, setTotalAmount] = useState(4578412);
    const [generatePayroll, setGeneratePayroll] = useState(false);
    const [step, setStep] = useState(1);
    const [submitFinalizedEmployees, setsubmitFinalizedEmployees] = useState([]);
    const [submitNonFinalizedEmployees, setsubmitNonFinalizedEmployees] = useState([]);
    const totalSalaryAmount = finalizedEmployees.reduce((acc, item) => { return acc += item?.amount_paid }, 0);

    useEffect(() => {
        setsubmitFinalizedEmployees(finalizedEmployees.map((eachItem) => ({
            payroll_payment_details_id: eachItem.payroll_payment_detail_id,
            employee_id: eachItem.employee_id,
            earned_amount: eachItem.total_amount,
            amount_paid: eachItem.amount_paid,
            balance_amount: eachItem.balance_amount,
            deduction_amount: eachItem.deduction_amount,
            reimbursement_amount: eachItem.reimbursement_amount,
            payroll_configuration_id: payrollConfigId,
            is_finalized: eachItem.is_finalized
        })))

        setsubmitNonFinalizedEmployees(nonfinalizedEmployees.map((eachItem) => ({
            payroll_payment_details_id: eachItem.payroll_payment_detail_id,
            employee_id: eachItem.employee_id,
            earned_amount: eachItem.total_amount,
            amount_paid: eachItem.amount_paid,
            balance_amount: eachItem.balance_amount,
            deduction_amount: eachItem.deduction_amount,
            reimbursement_amount: eachItem.reimbursement_amount,
            payroll_configuration_id: payrollConfigId,
            is_finalized: false
        })))// eslint-disable-next-line
    }, [finalizedEmployees, nonfinalizedEmployees])

    const handleSelectChange = (param) => {
        setSelectedOption(param)
    }

    const tableHeaders = [
        { id: 1, name: "Employee", width: '170px' },
        { id: 2, name: "Salary Amount", width: '150px' },
        { id: 3, name: "Reimbursements", width: "150px" },
        { id: 4, name: "Deductions", width: "150px" },
        { id: 5, name: "Current Balance", width: "150px" }
    ]

    const handleGeneratePayroll = () => {
        setGeneratePayroll(true);
        setStep(1);
    }

    const [submitLoader,setSubmitLoader] =useState(false);
    const handleFinalPayrollSubmission = () => {
        setStep(2);
        const data = {
            employees: [...submitFinalizedEmployees, ...submitNonFinalizedEmployees],
            payroll_configuration_id: payrollConfigId
        }
        setSubmitLoader(true);
        GeneratePayrollApi.SubmitEmployeePayroll(data).then((res) => {
            setSubmitLoader(false);
            if (res.data.statusCode === 1003) {
                setStep(3);
            }else{
                setGeneratePayroll(false);
                addErrorMsg(res.data.message);
            }
        })

    }

    const handleViewSummary = () => {
        setGeneratePayroll(false); closePopup();
        navigate('/payrollSummary', { state: { id: payrollConfigId } })
    }

    const handleDownloadSummary = () => {
        const data = {
            search: "",
            earned_amount_from: 0,
            earned_amount_to: 0,
            salary_amount_from: 0,
            salary_amount_to: 0,
            balance_amount_from: 0,
            balance_amount_to: 0,
            deduction_amount_from: 0,
            deduction_amount_to: 0,
            reimbursement_amount_from: 0,
            reimbursement_amount_to: 0,
            clients: [],
            visa: [],
        }
        GeneratePayrollApi.exportGeneratepayroll(data, payrollConfigId).then((response) => {
            if (response.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `GeneratePayroll_${formattedDate}.xlsx`
                setGeneratePayroll(false); 
                closePopup();
                downloadExcel(blob, filename);
                 }
            else {
                addErrorMsg(response.data.message)
            }

        })

    }

    return (
        <div>
            <Box p={2}>
                <Text largeHeaderText>List Of Employees</Text>
                <Text grey16 pt={"4px"}>{`${payrollDataProp?.cycle} ( ${payrollDataProp?.from_date} - ${payrollDataProp?.to_date} )`}</Text>
                <Box className={classes.btnBox} my={2}>
                    <Button onClick={() => handleSelectChange(1)} saveBtn className={selectedOption == 1 ? classes.activeButton : classes.inActiveButton}>Finalized ({finalizedEmployees?.length})</Button>
                    <Button onClick={() => handleSelectChange(2)} saveBtn className={selectedOption == 2 ? classes.activeButton : classes.inActiveButton} sx={{ marginLeft: "8px" }}>Not Finalized ({nonfinalizedEmployees.length})</Button>
                </Box>
            </Box>
            <Box mx={2} mt={1}>

                {selectedOption == 1 ? finalizedEmployees?.length > 0 ?
                    <TableContainer sx={{ maxHeight: '54vh', overflow: 'scroll' }}>
                        <Table size="small" stickyHeader aria-label="collapsible table">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map((header, i) => (
                                        <TableCell key={i} align={header.name === "Employee" ? "left" : 'right'} sx={{ minWidth: `${header.width}`, font: "14px Quicksand !important" }}>{header.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >{
                                selectedOption == 1 && finalizedEmployees?.map((row, index) => (
                                    <DataTableRow key={index} rowData={row} classes={classes} />

                                ))
                            }

                            </TableBody>
                        </Table>
                    </TableContainer> : <Box width={'770px'}> {NoDataFound()}</Box> : ''}
                {selectedOption == 2 ? nonfinalizedEmployees?.length > 0 ?
                    <TableContainer sx={{ maxHeight: '54vh', overflow: 'scroll' }}>
                        <Table size="small" stickyHeader aria-label="collapsible table">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map((header, i) => (
                                        <TableCell key={i} align={header.name === "Employee" ? "left" : 'right'} sx={{ minWidth: `${header.width}`, font: "14px Quicksand !important" }}>{header.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >{
                                selectedOption == 2 && nonfinalizedEmployees?.map((row, index) => (
                                    <DataTableRow key={index} rowData={row} classes={classes} />

                                ))
                            }

                            </TableBody>
                        </Table>
                    </TableContainer> : <Box width={'770px'}>{NoDataFound()}</Box> : ''}
            </Box>
            <Box >
                <Grid container py={2} px={3} >
                    <Grid item lg={3} md={3} sm={4} xs={12} textAlign={"end"}>
                        <Box width={"fit-content"}>
                            <Text className={classes.profileName} textAlign="start">Total Salary Amount</Text>
                            <Text largeBoldGreen>{getCurrencySymbol()}{formatNumberWithCommas(totalSalaryAmount)}</Text>
                        </Box>
                    </Grid>
                    <Grid container item lg={9} md={9} sm={8} xs={12} gap={2} justifyContent={"end"}>
                        <Button onClick={closePopup} blackCancelBtn sx={{ minWidth: "180px" }}  >Add More Employees</Button>
                        <Button onClick={() => { handleGeneratePayroll() }} saveNcontinue sx={{ minWidth: "180px" }} >Generate Payroll</Button>
                    </Grid>
                </Grid>
            </Box>

            {
                generatePayroll &&
                <ReusablePopup white iconHide statusWidth openPopup={generatePayroll} setOpenPopup={setGeneratePayroll} showTitle={true}>
                    <Box textAlign={"center"} p={6}>
                        {step === 1 ?
                            <Box>
                                <GeneratePayroll />
                                <Text pt={1} popupHead1>Are You Sure To Generate Payroll?</Text>
                                <Text pt={1} offBoardBodyFont>Recheck before running the payroll.</Text>
                                <Grid container item md={12} sm={12} xs={12} justifyContent={"center"} gap={3} pt={2}>
                                    <Button disabled={submitLoader} onClick={() => { setGeneratePayroll(false) }} blackCancel100 sx={{ width: "140px !important", fontWeight: `${400} !important`, }}>No, Cancel</Button>
                                    <Button onClick={() => { handleFinalPayrollSubmission() }} save14 >Yes, Generate</Button>
                                </Grid>
                            </Box>
                            :
                            step === 2 ?
                                <Box>
                                    <img src={submission_payroll} alt="submission_payroll" />
                                    <Text pt={1} popupHead1>Generating Payroll... </Text>
                                    <Text pt={1} offBoardBodyFont>Just a moment, our HR elves are double-checking <br />each digit to ensure precision. No payroll types here!"</Text>
                                </Box>
                                :
                                step === 3 ?
                                    <Box>
                                        <SuccessPayroll />
                                        <Text pt={1} popupHead1>Successful</Text>
                                        <Text pt={1} offBoardBodyFont>Payroll has been generated successfully.</Text>
                                        <Grid container item md={12} sm={12} xs={12} justifyContent={"center"} gap={3} pt={2}>
                                            <Button blackCancel100 sx={{ width: "170px !important", fontWeight: `${400} !important`, }} onClick={() => handleDownloadSummary()}>Download Summary</Button>
                                            <Button save14 sx={{ width: "170px !important", fontWeight: `${400} !important`, }} onClick={() => handleViewSummary()}>View Summary</Button>
                                        </Grid>
                                    </Box>
                                    : ""
                        }
                    </Box>
                </ReusablePopup>

            }
        </div>
    )
}

export default PayrollPreview
