import React, { useRef } from 'react'
import Button from '../customButton/Button';
import FileInputStyles from './FileInputStyles';
import uploadArrowBlue from '../../assets/employee/blue-upload.svg';
import { Box, useTheme } from '@mui/material';
import eye from '../../assets/employee/viewithBg.svg';
import deleteIcon from "../../assets/employee/deletewithBg.svg";
import { openDocumentInNewtab } from '../../utils/utils';

export default function FileInput(props) {
    const { name, handleChange, label, isDisabled, FileName, actionState, value, handleDelete, disabled, uploadKeyName, error, documentUrl, state, required, labelSize } = props;

    const fileInputRef = useRef(null);

    const handleFileClick = () => {
        if (!isDisabled) {
            fileInputRef.current.click();
        }
    };

    const onInputClick = (event) => {
        event.target.value = ''
    }

    const openInNewTab = (documentUrl) => {
        console.log(state, 'state')
        if (state == undefined || state == null) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            if (state.aws_s3_status == 0 || state.aws_s3_status == false) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            } else if (state.aws_s3_status == undefined || state.aws_s3_status == undefined) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
            else if (state.new_document_id != '' || state.new_document_id == undefined) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
            else {
                openDocumentInNewtab(state.document_key, state.document_path)
            }
        }
    }

    const classes = FileInputStyles();
    const isMobile = window.innerWidth <= 499;

    const theme = useTheme();

    return (
        <Box className={`${isDisabled || disabled ? classes.rootDisabled : error ? classes.errorRoot : classes.root}`}>
            <Box className={classes.inputField}>

                {FileName != '' ?
                    (
                        <>
                            {error ?
                                <div className={classes.labelActiveRed} title={label?.length > labelSize ? label : ''}>{label?.length > labelSize ? label.substring(0, labelSize) + '...' : label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</div> :
                                (isDisabled || disabled) ?
                                    <div className={classes.labeldisabled}>{label?.length > labelSize ? label.substring(0, labelSize) + '...' : label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</div> :
                                    <div className={classes.labelActive} title={label?.length > labelSize ? label : ''}>{label?.length > labelSize ? label.substring(0, labelSize) + '...' : label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</div>
                            }
                            <div className={classes.fileName} title={FileName?.length > labelSize ? FileName : ''}>
                                {FileName?.length > labelSize ? FileName.substring(0, labelSize) + '...' : FileName}
                            </div>
                        </>
                    )
                    : (error ? <div className={classes.labelRed} title={label?.length > labelSize ? label : ''}>{label?.length > labelSize ? label.substring(0, labelSize) + '...' : label}</div> : <div className={classes.label} title={label?.length > labelSize ? label : ''}>{label?.length > labelSize ? label.substring(0, labelSize) + '...' : label}{required ? <span style={{ color: 'red' }}>*</span> : ''}</div>)}
            </Box>

            {
                actionState == 'deleteState' ?
                    <Box display='flex' flexDirection='row' columnGap={{ lg: 1, md: 1, sm: 1, xs: 0.5 }}>
                        <img src={eye} alt='view' className={`${classes.viewIcon} ${classes.iconStyles}`} onClick={() => openInNewTab(documentUrl)} />
                        <img src={deleteIcon} alt='delete Icon' className={classes.iconStyles} />
                    </Box>
                    : actionState == 'view' ?
                        <img src={eye} alt='view' className={`${classes.cursor}  ${classes.iconStyles}`} onClick={() => openInNewTab(documentUrl)} />
                        : actionState == 1 ?
                            <>
                                {
                                    isDisabled || disabled ?
                                        <img src={eye} alt='view' className={`${classes.cursor}  ${classes.iconStyles}`} onClick={() => openInNewTab(documentUrl)} /> :
                                        <Box display='flex' flexDirection='row' gap={{ lg: 2, md: 2, sm: 2, xs: 1 }} textAlign='center' alignItems='center'>
                                            <img src={eye} alt='view' className={`${classes.cursor} ${classes.iconStyles}`} onClick={() => openInNewTab(documentUrl)} />
                                            <img src={deleteIcon} alt='delete Icon' className={`${classes.cursor}  ${classes.iconStyles}`} onClick={handleDelete} />
                                        </Box>
                                }
                            </>
                            :
                            actionState == "Upload" ?
                                <Button
                                    browseBtnUpload
                                    startIcon={<img src={uploadArrowBlue} alt='browse' style={{ height: isMobile ? "12px" : "auto" }} />}
                                    onClick={handleFileClick}
                                    disabled={isDisabled}
                                    sx={{ [theme.breakpoints.down('sm')]: { marginRight: "-8px !important" } }}
                                >
                                    {uploadKeyName ? `${uploadKeyName}` : 'Upload'}
                                </Button>
                                :
                                <Button
                                    browseBtn
                                    startIcon={<img src={uploadArrowBlue} alt='browse' style={{ height: isMobile ? "12px" : "auto" }} />}
                                    onClick={handleFileClick}
                                    disabled={isDisabled}
                                    sx={{ [theme.breakpoints.down('sm')]: { marginRight: "-8px !important" } }}
                                >
                                    {uploadKeyName ? `${uploadKeyName}` : 'Upload'}
                                </Button>
            }
            <input
                name={name}
                type='file'
                onClick={onInputClick}
                className={classes.input}
                onChange={handleChange}
                ref={fileInputRef}
                actionState={actionState}
                value={value}
                handleDelete={handleDelete}
                disabled={disabled}
            />

        </Box>
    )
}

