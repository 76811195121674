import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ViewGeneralDetails from './ViewGeneralDetails';
import EditGeneralDetails from './EditGeneralDetails';


export default function GeneralDetailsComponent(props) {
    const [edit, setEdit] = useState(false)
    return (
        <Grid container>
            {
                edit && props.formEdit ?
                    <EditGeneralDetails formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} getIndex={() => props.getIndex()} setEdit={setEdit} generalDetailsConfig={props.generalDetailsConfig}/>
                    :
                    <ViewGeneralDetails rolePermission={props.rolePermission} formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} setEdit={setEdit} generalDetailsConfig={props.generalDetailsConfig}/>
            }
        </Grid>
    );
}