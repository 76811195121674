import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const OnboardStyles = makeStyles(() => ({
    optional: {
        font: '14px Quicksand !important',
        color: '#737373 !important',
        marginLeft: '4px !important'
    },
    checkBoxbg: {
        height: '56px',
        borderRadius: '10px', alignItems: 'center !important',
        display: 'flex',
        flexDirection: 'row',
        background: '#FAFAFA !important',
        width:"100%"
    },
    checkBox: {
        border: '1px solid #c4c2c2',
        height: '56px',
        borderRadius: '10px',
        alignItems: 'center !important',
        display: 'flex',
        flexDirection: 'row',
        width:"100%"
    },
    checkboxColor: {
        // color: '#E2E5E6 !important'
    },
    ViewContainer: {
        height: '45vh',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    },    
    profileTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    card:{
        padding: '15px !important', boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important", borderRadius: '15px' ,
        [useTheme().breakpoints.down('sm')]:{
            padding: '5px !important',
        }
    },
    cardContent:{
        padding: '20px 0px 30px 30px !important',
        [useTheme().breakpoints.down('lg')]:{
            padding: '20px 0px 30px 0px !important',
        },
        [useTheme().breakpoints.down('sm')]:{
            padding: '5px !important',
        }
    },
    cardHeader:{
        padding: '15px 0px 0px 25px !important',
        [useTheme().breakpoints.down('lg')]:{
            padding: '15px 0px 0px 0px !important',
        },
        [useTheme().breakpoints.down('sm')]:{
            padding: '15px 0px 15px 5px !important',
        }
    }
}))

export default OnboardStyles;