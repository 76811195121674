import { makeStyles } from "@mui/styles";
import { blue, btnBgGreen, btnTxtBlack, warning, greyButton } from "../../../../theme"
import { useTheme } from "@mui/material";

const Styles = makeStyles(() => ({

    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
        [useTheme().breakpoints.down('md')]: {
            left: "0px",
            width: "calc(100% - 0px)",
        },
    },
    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "22px !important",
        textAlign: "center",
        textOverflow: 'ellipsis !important',
        whiteSpace: 'nowrap !important'
    },
    profileId: {
        fontFamily: "Quicksand !important",
        color: "#737373 !important",
        fontSize: "14px !important",
        textAlign: "center",
    },
    eVerifyBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#4ABE43 !important",
        border: "1px solid #4ABE43 !important",
        height: "35px",
        width: "140px",
        padding: "0px 18px !important",
        borderRadius: "8px !important"
    },
    moreBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#9D9E9F !important",
        border: "1px solid #9D9E9F !important",
        height: "35px !important",
        minWidth: "35px !important",
        padding: "5px !important",
        borderRadius: "8px !important"
    },
    listItems: {
        font: "14px Quicksand !important",
        minHeight: "32px",
        margin: "6px 0px !important",
        color: '#092333',
        borderRadius: "4px !important",
        fontWeight: `${600} !important`,
        '&:hover': {
            backgroundColor: '#F5F6F6 !important',
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    listItemsActive: {
        minHeight: "32px",
        margin: "6px 0px !important",
        color: '#fff !important',
        backgroundColor: '#0C75EB !important',
        transition: "all .3s ease",
        font: '14px Quicksand !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: '#F5F6F6 !important',
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },

    avatarBox: {
        display: "flex",
        borderRadius: "50%",
        border: "2px solid #037847",
    },
    avatar: {
        width: "120px !important",
        height: "120px !important",
        margin: "2px"
    },
    tabTitle: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        textTransform: "capitalize !important",
        fontSize: "15px !important",
        fontWeight: 500,
        margin: "0px !important",
        padding: "14px 20px !important"
    },
    activeTabTitle: {
        color: "#0C75EB !important",
    },
    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },
    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    sideTooltip: {
        height: "34px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },
    sideTooltipText: {
        fontSize: "14px",
        fontFamily: "Quicksand",
        fontWeight: "400",
        color: "#FFFFFF",
    },
    viewText: {
        borderBottom: '1px solid #EAECF0 !important',
        font: '13px Quicksand !important',
        height: '37px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    inactive: {
        backgroundColor: "#ffffff !important",
        fontWeight: "500 !important",
        font: '12px Quicksand !important',
        minWidth: "100px !important",
        height: '44px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        padding: "4px 12px !important",
        "&:hover": {
            background: 'none !important'
        }
    },
    optional: {
        color: "#C7CCD3",
        font: '12px Quicksand !important',
        fontWeight: 400,
    },
    BarChartTitle: {
        color: `${btnTxtBlack.shade4} !important`,
        font: "15px Quicksand !important",
        fontWeight: `600 !important`,
    },
    uploadBtn: {
        font: '12px Quicksand !important',
        fontWeight: '500 !important',
        textTransform: 'capitalize !important',
        color: '#0C75EB !important',
        backgroundColor: "#ffffff !important",
        padding: "2px 8px !important"
    },
    profileTooltipText: {
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    multiSelectinputLabel: {
        color: '#737373 !important',
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`
    },
    contentScroll: {
        height: '58vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: '10px',
        width: '100%'
    },
    contentScrollAnalytics: {
        maxHeight: '80vh !important',
        overflowY: 'scroll !important',
        paddingBottom: '10px !important',
        boxShadow: ' 0px 2px 24px 0px #919EAB1F !important',
        borderRadius: "12px !important",
    },
    contentScrollAnalytics1: {
        maxHeight: '75vh !important',
        overflowY: 'scroll !important',
        paddingBottom: '10px !important',
        boxShadow: ' 0px 2px 24px 0px #919EAB1F !important',
        borderRadius: "12px !important",
    },
    cardBg: {
        width: "100% !important",
        minHeight: "80vh !important",
        borderRadius: "8px !important"
    },
    cardContent: {
        width: "100% !important",
        minHeight: "40vh !important",
        maxHeight: "80vh !important",
        borderRadius: "8px !important",
    },
    boxShadow: {
        boxShadow: ' 0px 2px 24px 0px #919EAB1F !important',
        borderRadius: "8px !important",
    },
    statuText: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
    },
    red: {
        color: `red !important`,
    },
    green: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${btnBgGreen.shade1} !important`,
        opacity: 1
    },
    orange: {
        font: '14px Quicksand !important',
        letterSpacing: '0px',
        color: `${warning} !important`,
        opacity: 1
    },
    editorHeight: {
        minHeight: '70px !important',
        borderRadius: '8px !important',
    },

    // Updated screen Styles from 02/04/2024

    clientList: {
        maxHeight: "40vh",
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C7CCD3',
            outline: '1px solid #C7CCD3',
            borderRadius: "4px",
        }
    },
    cursor: {
        cursor: 'pointer !important'
    },
    blueText: {
        color: `${blue} !important`
    },
    divider: {
        border: '1px solid #E2E5E6 !important', width: '100% !important', margin: '15px 0px !important'
    },
    skeleton: {
        height: '15px !important', width: '30px !important', background: '#F6F6F6 !important'
    },
    profileskeleton: {
        height: '100px !important', width: '70px !important', borderRadius: '50% !important', background: '#F6F6F6 !important'
    },
    placementProfileskeleton: {
        height: '70px !important', width: '40px !important', borderRadius: '50% !important', background: '#F6F6F6 !important'
    },
    textSkeleton: {
        height: '30px !important', width: '100px !important', background: '#F6F6F6 !important'
    },
    editSkeleton: {
        height: '40px !important', width: '30px !important', background: '#F6F6F6 !important'
    },
    table: {
        border: '1px solid #F6F6F6',
        width: '100%',
        borderRadius: '12px 12px 0px 0px'
    },
    responsiveContainer: {
        height: '50vh',
        overflowY: 'scroll',
        width: "100% !important",
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    responseiveBoxShadow: {
        height: '50vh',
        overflowY: 'scroll',
        width: "100% !important",
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        borderRadius: '8px !important',
        boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D !important",
    },
    forConsultant: {
        font: '13px Quicksand',
        letterSpacing: '0px',
        color: "#737373",
        opacity: 1
    },

    // Invoice Configuration
    boxContainer: {
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        width: "64px",
        height: "52px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        cursor: "pointer",
        textAlign: 'center'
    },
    percentileIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: "8px",
    },
    selectionIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    verticalDivider: {
        height: "100%",
        backgroundColor: "#ccc",
        // margin: "0 8px",
    },
    arrowImage: {
        width: '16px', height: '16px', marginLeft: '8px', cursor: 'pointer !important'
    },
    tableContainer: {
        height: '65vh !important', width: '100% !important', overflowY: 'scroll',
        [useTheme().breakpoints.down('sm')]: {
            width: 'max-content !important',
            overflowX: 'auto',
            display: 'block !important'
        }
    },
    tablePlacement: {
        border: '1px solid #F6F6F6', boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '12px 12px 0px 0px',
        [useTheme().breakpoints.down('sm')]: {
            minWidth: '600px',
        }
    },
    bluebordersmall: {
        height: '33px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        width: '81px !important',
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "14px Quicksand !important",
        fontWeight: `${600} !important`,
        padding: '8px !important',
        cursor: 'pointer',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    disabledBorderSmall: {
        height: '33px !important',
        background: `#FFFFFF !important`,
        color: `${greyButton} !important`,
        textTransform: 'capitalize !important',
        width: '81px !important',
        borderRadius: '8px !important',
        border: `1px solid ${greyButton} !important`,
        font: "14px Quicksand !important",
        fontWeight: `${600} !important`,
        padding: '8px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    termsConditions: {
        maxHeight: '10vh !important',
        overflowY: 'scroll !important',
        paddingBottom: '10px !important',
    },
}))

export default Styles;
