import React, { useEffect, useRef, useState } from 'react'
import PaymentsStyles from '../PaymentsStyles';
import { Accordion, AccordionSummary, AccordionDetails, Avatar, Grid, Box, Checkbox, Chip, FormControlLabel, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Divider, Stack, SwipeableDrawer, Tooltip, tooltipClasses, useTheme, useMediaQuery, Badge, Typography } from '@mui/material';
import Text from '../../../../components/customText/Text';
import { filterStorage } from '../../../../utils/FilterStorage';
import LocalStorage from '../../../../utils/LocalStorage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../components/customButton/Button';
import Userplus from '../../../../assets/svg/clients/user-plus-bold.svg';
import Search from '../../../../assets/svg/search1.svg';
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';
import cloud from '../../../../assets/svg/download-cloud-blue.svg';
import CommonApi from '../../../../apis/CommonApi';
import FilterListIcon from '@mui/icons-material/FilterList';
import arrowDown from '../../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../../assets/svg/clients/blackarrowUp.svg';
import quickBooks from '../../../../assets/svg/QuickbooksSync.svg'
import zohoBooks from '../../../../assets/svg/ZohobooksSync.svg'
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import { BlackToolTip, dateFormat, NoDataFound, capitalizeAndAddSpace, addErrorMsg, addLoader, removeLoader, ListLoadingIcon, downloadExcel, addSuccessMsg } from '../../../../utils/utils';
import Date from '../../../../components/datePicker/Date';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import moment from 'moment';
import { validate_emptyField } from '../../../../components/Validation';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import infoCircle from '../../../../assets/images/info-circle.png';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import zohosyncIcon from '../../../../assets/svg/zohosyncIcon.svg';
import InvoiceListingAPI from '../../../../apis/admin/invoice/InvoiceListingAPI';
import greenUp from '../../../../assets/svg/clients/greenupArrow.svg'
import greendown from '../../../../assets/svg/clients/greendownArrow.svg'
import invoiceSent from "../../../../assets/svg/sent.svg";
import invoiceSaved from "../../../../assets/svg/save.svg";
// import FileSaver from 'file-saver';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import importingInvoice from '../../../../assets/svg/invoice/importingInvoice.svg'
import syncingInvoice from '../../../../assets/svg/invoice/syncingInvoice.svg'
import importingZohoInvoice from '../../../../assets/svg/invoice/importingZohoInvoices.svg'
import syncingZohoInvoice from '../../../../assets/svg/invoice/syncingZohoInvoices.svg'



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0C75EB',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    fontSize: "14px",
    fontWeight: 600,
    color: '#262626',
    textAlign: 'left',
    "@media (min-width: 425px) and (max-width: 799px)": {
        fontSize: "12px",
    },
}));



const StyledTableRowBody = styled(TableRow)(({ theme }) => ({
    width: '100%',
    // margin: "4px 0px !important",
    // borderRadius: "20px !important",
    cursor: 'pointer',
    // boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05) !important",
    "& td, & th": {
        border: "none", // Removes all borders
    },

    // "&:hover": {
    //     boxShadow: "0px 0px 15px 1px #cce9ffeb !important",
    //     transition: "all .3s ease",
    // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));


export default function InvoiceIndex() {
    const classes = PaymentsStyles();
    const location = useLocation();
    const [activeState, setActiveState] = useState('All');
    const [expanded, setExpanded] = React.useState(false);
    const [openQuickbookArrow, setOpenQuickbookArrow] = useState(false);
    const [openZohoArrow, setOpenZohoArrow] = useState(false);
    const [quickbooksList, setQuickbooksList] = useState(false);
    const [zohoList, setZohoList] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [clientDropdown, setClientDropdown] = useState([]);
    const [approvalLevels, setAppeovalLevels] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openStatusArrow, setOpenStatusArrow] = useState(false);
    const open = Boolean(anchorEl);// eslint-disable-next-line
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [searchInvoice, setSearchInvoice] = useState('');// eslint-disable-next-line
    const [searchZoho, setSearchZoho] = useState('');
    const overDueRange = require('../../../../utils/jsons/OverdueOptions.json')
    const [overdueRange, setOverdueRange] = useState([]);
    const navigate = useNavigate()
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const data = location && location.state// eslint-disable-next-line
    const [connectedAppsList, setConnectedAppsList] = useState({
        isZohoIntegrated: false,
        isQuickBookIntegrated: false
    })
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [selectedInvoicesZoho, setSelectedInvoicesZoho] = useState([]);

    const [syncInvoices, setSyncInvoices] = useState([]);
    const params = new URL(window?.location?.href)?.searchParams;
    const getStatus = params?.get('status')

    useEffect(() => {
        if (LocalStorage.getRedirectedModule() == 'invoice > due') {
            setActiveState('All');
            filterState.status = 'Due';
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState })
            return true
        }
        if (LocalStorage.getRedirectedModule() == 'invoice') {
            setActiveState('All');
            filterState.status = 'Paid';
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState })
            return true
        }
        if (LocalStorage.getRedirectedModule() == 'invoice > draft') {
            setActiveState('Drafted');
            filterState.status = 'Drafted';
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState })
            return true
        }
        if (LocalStorage.getRedirectedModule() == 'invoice > overdue') {
            setActiveState('All');
            filterState.status = 'Overdue';
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState })
            return true
        }
        if (LocalStorage.getRedirectedModule() == 'invoice > pending') {
            setActiveState('Pending Approval');
            filterState.status = 'Pending Approval';
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState, status: 'Pending Approval' })
            return true
        }
        if (getStatus) {
            let status = typeof getStatus === 'string' ? getStatus.replace(/'/g, '') : getStatus;
            if (status === 'Drafted') {
                setActiveState('Drafted')
            } else if (status == 'Pending Approval') {
                setActiveState('Pending Approval')
            } else {
                setActiveState('All')
            }
            setFilterState({ ...filterState, status: status })
            invoicesListingApi({ ...filterState, status: status })
        }
        if (getStatus === '' || getStatus === null) {
            setLoading(true);
            const invoiceFilter = filterStorage.getFilter('All');
            const draftFilter = filterStorage.getFilter('Drafted');
            const pendingFilter = filterStorage.getFilter('Pending Approval')
            if (invoiceFilter && invoiceFilter !== null && invoiceFilter !== undefined) {
                invoiceFilter.sort_column = '';
                invoiceFilter.sort_order = '';
                setActiveState('All')
                setLoading(true);
                setFilterState(invoiceFilter)
                invoicesListingApi(invoiceFilter, invoiceFilter.scrollId)
            } else if (draftFilter && draftFilter !== null && draftFilter !== undefined) {
                draftFilter.sort_column = '';
                draftFilter.sort_order = '';
                setActiveState('Drafted')
                setLoading(true);
                setFilterState(draftFilter)
                invoicesListingApi(draftFilter, draftFilter.scrollId)
            } else if (pendingFilter && pendingFilter !== null && pendingFilter !== undefined) {
                pendingFilter.sort_column = '';
                pendingFilter.sort_order = '';
                setActiveState('Pending Approval')
                setLoading(true);
                setFilterState(pendingFilter)
                invoicesListingApi(pendingFilter, pendingFilter.scrollId)
            } else {
                setLoading(true);
                invoicesListingApi({ ...filterState, status: 'All' })
            }
            // if (data?.status) {
            //     setFilterState({ ...filterState, status: data?.status })
            //     setLoading(true);
            //     invoicesListingApi({ ...filterState, status: data?.status })
            //     window.history.replaceState({}, document.title, location.pathname);
            // }
            // const invoiceFilter = filterStorage.getFilter('All')
            // if (invoiceFilter && invoiceFilter !== null && invoiceFilter !== undefined) {
            //     invoiceFilter.sort_column = '';
            //     invoiceFilter.sort_order = '';
            //     invoiceFilter.status = data?.status ? data?.status : filterState.status;
            //     setActiveState('All')
            //     setLoading(true);
            //     invoicesListingApi(invoiceFilter, invoiceFilter.scrollId)
            // } else {
            //     if (!data?.status && activeState === 'All') {
            //         setLoading(true);
            //         invoicesListingApi({ ...filterState, status: 'All' })
            //     }
            // }
            // const draftFilter = filterStorage.getFilter('Drafted')
            // if (draftFilter && draftFilter !== null && draftFilter !== undefined) {
            //     draftFilter.sort_column = '';
            //     draftFilter.sort_order = '';
            //     // draftFilter.status = '';
            //     setActiveState('Drafted')
            //     setLoading(true);
            //     invoicesListingApi(draftFilter, draftFilter.scrollId)
            // } else {
            //     if (!data?.status && activeState === 'Drafted') {
            //         setLoading(true);
            //         invoicesListingApi({ ...filterState, status: 'Drafted' })
            //     }
            // }
            // const pendingFilter = filterStorage.getFilter('Pending Approval')
            // if (pendingFilter && pendingFilter !== null && pendingFilter !== undefined) {
            //     pendingFilter.sort_column = '';
            //     pendingFilter.sort_order = '';
            //     // pendingFilter.status = '';
            //     setActiveState('Pending Approval')
            //     setLoading(true);
            //     invoicesListingApi(pendingFilter, pendingFilter.scrollId)
            // } else {
            //     if (!data?.status && activeState === 'Pending Approval') {
            //         setLoading(true);
            //         invoicesListingApi({ ...filterState, status: 'Pending Approval' })
            //     }
            // }

        }
        // navigate("/invoice")
        // eslint-disable-next-line
    }, [])

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const scrollBox = document.querySelector(`.${classes.scrollBox}`);
            if (scrollBox) {
                const elementPosition = element.offsetTop; // Getting the element's position inside the container
                const containerPosition = scrollBox.offsetTop; // Getting the scroll container's position
                const offset = 250; // Increase offset to move the element further down
                scrollBox.scrollTo({
                    top: elementPosition - containerPosition - offset,
                    behavior: "smooth",
                });
            }
        }
    };


    useEffect(() => {
        clientDropdownApi();
        connectedApps();
        approvalLevelsApi();
        setOverdueRange(overDueRange)
        // eslint-disable-next-line
    }, []);

    const connectedApps = () => {
        CommonApi.connectedApps().then((res) => {
            if (res.data.statusCode === 1003) {
                setConnectedAppsList(res.data.data);
            }
        })
    }

    const clientDropdownApi = () => {
        ClientsApi.dropdown('client', "").then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDropdown(res.data.data);
            }
        })
    }

    const approvalLevelsApi = () => {
        InvoiceListingAPI.approvalLevels().then((res) => {
            if (res.data.statusCode === 1003) {
                setAppeovalLevels(res.data.data);
            }
        })
    }

    const [filterState, setFilterState] = useState({
        request_id: LocalStorage.uid(),
        sort_column: "",
        sort_order: "",
        limit: 10,
        page: 1,
        search: '',
        status: 'All',
        companies: [],
        invoice_sent_from: "",
        invoice_sent_to: "",
        overdue_type: location?.state?.period ? [location?.state?.period] : "",
        invoice_payment_from: "",
        invoice_payment_to: "",
        invoice_date_from: "",
        invoice_date_to: "",
        invoice_amount: [0, 0],
        balance_amount: [0, 0],
        levels: [],
        invoice_draft_to: "",  //Drafted
        invoice_draft_from: "",
        scrollId: ''
    })

    const [filterData, setFilterData] = useState({
        companies: [],
        invoice_sent_from: "",
        invoice_sent_to: "",
        overdue_type: location?.state?.period ? [location?.state?.period] : "",
        invoice_payment_from: "",
        invoice_payment_to: "",
        invoice_date_from: "",
        invoice_date_to: "",
        levels: [], //pending
        invoice_draft_from: "",  //Drafted
        invoice_draft_to: "",
    })

    const [invoiceRows, setInvoiceRows] = useState([]);

    const invoicesListingApi = (data, param) => {
        if (invoiceRows.length < 6) {
            setLoading(true);
        }
        InvoiceListingAPI.listing(data).then((response) => {
            removeLoader();
            setListLoading(false);
            setLoading(false);
            if (response.data.statusCode === 1003) {
                setDrawer(null);
                setInvoiceRows(response.data.data)
                setPagination(response.data.pagination)
                //getTotalInvoices(response.data.pagination.total, searchInvoice)
                if (param !== undefined && param !== "" && param !== null) {
                    setTimeout(() => { scrollToElement(param) }, 500);
                    setTimeout(() => { setFilterState({...filterState,scrollId:''}) },10000);
                }
            } else {
                addErrorMsg(response.data.message)
                setLoading(false);
            }
        })
    }

    const getTotalInvoices = (limit, search) => {
        const data = {
            request_id: LocalStorage.uid(),
            search: search ? search : "",
            sort_column: "",
            sort_order: "",
            limit: limit ? limit : '',
            page: 1,
            status: ""
        }
        const paginationData = {
            total: limit ? limit : '',
            currentPage: 0,
            perPage: limit ? limit : '',
            totalPages: ""
        }
        InvoiceListingAPI.listing(data, paginationData).then((res) => {
            if (res.data.statusCode == 1003) {
                setSyncInvoices(res.data.data);
            }
        })
    }

    const [datesError, setDatesError] = useState({
        invoice_sent_from: "",
        invoice_sent_to: "",
        invoice_payment_from: "",
        invoice_payment_to: "",
        invoice_date_from: "",
        invoice_date_to: "",
        invoice_draft_from: "",
        invoice_draft_to: "",
    })

    const [pagination, setPagination] = useState(
        {
            total: "6",
            currentPage: 0,
            perPage: 10,
            totalPages: ""
        }
    );

    const handleTab = (args) => {
        datesError.invoice_sent_from = '';
        datesError.invoice_sent_to = '';
        datesError.invoice_payment_from = '';
        datesError.invoice_payment_to = '';
        datesError.invoice_date_from = '';
        datesError.invoice_date_to = '';
        datesError.invoice_draft_from = '';
        datesError.invoice_draft_to = '';
        setDatesError({ ...datesError })
        filterData.search = ''
        filterData.companies = [];
        filterData.invoice_sent_from = "";
        filterData.invoice_sent_to = "";
        filterData.filterDataoverdue_type = "";
        filterData.invoice_payment_from = "";
        filterData.invoice_payment_to = "";
        filterData.invoice_date_from = "";
        filterData.invoice_date_to = "";
        filterData.levels = [];
        filterData.invoice_draft_from = "";
        filterData.invoice_draft_to = "";
        setFilterData({ ...filterData })
        filterState.status = args;
        filterState.search = ''
        filterState.companies = [];
        filterState.invoice_sent_from = "";
        filterState.invoice_sent_to = "";
        filterState.overdue_type = "";
        filterState.invoice_payment_from = "";
        filterState.invoice_payment_to = "";
        filterState.invoice_date_from = "";
        filterState.invoice_date_to = "";
        filterState.levels = [];
        filterState.invoice_draft_from = "";
        filterState.invoice_draft_to = "";
        filterState.limit = 10;
        filterState.page = 1;
        filterState.sort_column = '';
        filterState.sort_order = '';
        filterState.scrollId = '';
        setFilterState({ ...filterState })
        setSelectedFilter('')
        setActiveState(args);
        setExpanded(false);
        if (args === 'All') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true))) {
                pagination['perPage'] = 10
                setPagination({ ...pagination })
                setLoading(true);
                invoicesListingApi(filterState)
                filterStorage.setFilter('All', filterState);
                filterStorage.removeFilter('Drafted');
                filterStorage.removeFilter('Pending Approval');
            }
        } else if (args === 'Drafted') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true))) {
                pagination['perPage'] = 10
                setPagination({ ...pagination })
                setLoading(true);
                invoicesListingApi(filterState)
                filterStorage.setFilter("Drafted", filterState);
                filterStorage.removeFilter('All');
                filterStorage.removeFilter('Pending Approval');
            }
        } else if (args === 'Pending Approval') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true))) {
                pagination['perPage'] = 10
                setPagination({ ...pagination })
                setLoading(true);
                invoicesListingApi(filterState)
                filterStorage.setFilter("Pending Approval", filterState);
                filterStorage.removeFilter('All');
                filterStorage.removeFilter('Drafted');
            }
        }
    }

    const changeHandler = (e, args) => {
        if (args == 'invoice') {
            setSearchInvoice(e.target.value);
            getTotalInvoices(pagination.total, e.target.value);
        } else if (args == 'zoho') {
            setSearchZoho(e.target.value);
            getTotalInvoices(pagination.total, e.target.value);
        }
        else {
            if (activeState === 'All') {
                setFilterState({ ...filterState, search: e.target.value, status: 'All', scrollId: '' });
                setLoading(true);
                invoicesListingApi({ ...filterState, search: e.target.value });
                filterStorage.setFilter('All', filterState);
            } else if (activeState === 'Drafted') {
                setFilterState({ ...filterState, search: e.target.value, status: 'Drafted', scrollId: '' });
                setLoading(true);
                invoicesListingApi({ ...filterState, search: e.target.value })
                filterStorage.setFilter("Drafted", filterState);
            } else if (activeState === 'Pending Approval') {
                setFilterState({ ...filterState, search: e.target.value, status: 'Pending Approval', scrollId: '' });
                setLoading(true);
                invoicesListingApi({ ...filterState, search: e.target.value })
                filterStorage.setFilter("Pending Approval", filterState);
            }
        }

    }

    const checkHandler = (e, args, val) => {
        const checked = e.target.checked;
        if (args == "all") {
            if (checked) {
                let ids = syncInvoices.map((invoice) => invoice.id)
                setSelectedInvoices([...ids])
            } else {
                setSelectedInvoices([]);
            }
        } else {
            const updatedArray = [...selectedInvoices];
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncInvoices.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.id);
                }
            }
            setSelectedInvoices([...updatedArray]);
        }
    }

    const checkHandlerZoho = (e, args, val) => {
        const checked = e.target.checked;
        if (args == 'all') {
            if (checked) {
                let ids = syncInvoices.map((client) => client.id)
                setSelectedInvoicesZoho([...ids]);
            } else {
                setSelectedInvoicesZoho([]);
            }
        } else {
            const updatedArray = [...selectedInvoicesZoho];
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncInvoices.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.id);
                }
            }
            setSelectedInvoicesZoho([...updatedArray]);
        }
    }

    const handleSearchClear = (args) => {
        if (args == 'invoice') {
            setSearchInvoice('');
            getTotalInvoices(pagination.total);
        } else if (args == 'zoho') {
            setSearchZoho('');
            getTotalInvoices(pagination.total);
        }
        else {
            if (activeState == 'All') {
                filterState.search = ''
                filterState.scrollId = ''
                setFilterState({ ...filterState, status: 'All' })
                invoicesListingApi({ ...filterState });
            } else if (activeState == 'Drafted') {
                filterState.search = ''
                filterState.scrollId = ''
                setFilterState({ ...filterState, status: 'Drafted' })
                invoicesListingApi({ ...filterState })
            } else {
                filterState.search = ''
                filterState.scrollId = ''
                setFilterState({ ...filterState, status: 'Pending Approval' })
                invoicesListingApi({ ...filterState })
            }
        }

    }

    const download = () => {
        if ((activeState === "All" || activeState === "Drafted" || activeState === "Pending Approval") && invoiceRows.length == 0) {
            addErrorMsg('No data to export');
            return false;
        }
        addLoader(true);
        filterState.request_id = LocalStorage.uid();
        InvoiceListingAPI.export(filterState).then((response) => {
            removeLoader();
            if (response?.data instanceof Blob) {

                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `Invoice_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }

        })

    }

    const addForm = () => {
        navigate('/addInvoice')
    }

    const quickbookHandler = (e, args) => {
        if (args == 'zoho') {
            setOpenZohoArrow(e.currentTarget);
        } else {
            setOpenQuickbookArrow(e.currentTarget);
        }
    }

    const handleOpenDrawer = () => {
        if (activeState === 'All') {
            setDrawer("filter");
            setDatesError({
                invoice_sent_from: "",
                invoice_sent_to: "",
                invoice_payment_from: "",
                invoice_payment_to: "",
                invoice_date_from: "",
                invoice_date_to: "",
                invoice_draft_from: "",
                invoice_draft_to: "",
            })
            setFilterData({
                companies: filterState.companies,
                invoice_sent_from: filterState.invoice_sent_from,
                invoice_sent_to: filterState.invoice_sent_to,
                overdue_type: filterState.overdue_type,
                invoice_payment_from: filterState.invoice_payment_from,
                invoice_payment_to: filterState.invoice_payment_to,
                invoice_date_from: filterState.invoice_date_from,
                invoice_date_to: filterState.invoice_date_to,
            });
        }
        // else if(activeState === "Drafted") {
        //     setDrawer("filter")
        // }
        else {
            setDrawer("draftFilter")
            if (activeState == 'Drafted') {
                setFilterData({
                    companies: filterState.companies,
                    invoice_draft_from: filterState.invoice_draft_from,
                    invoice_draft_to: filterState.invoice_draft_to,
                });
            } else {
                setFilterData({
                    companies: filterState.companies,
                    levels: filterState.levels,
                    invoice_sent_from: filterState.invoice_sent_from,
                    invoice_sent_to: filterState.invoice_sent_to,
                });
            }

        }
    }


    const handleQuickBooks = (args) => {
        setQuickbooksList(args)
        setSelectedInvoices([])
        getTotalInvoices(pagination.total)
    }

    const handleZoho = (args) => {
        setZohoList(args)
        setSelectedInvoicesZoho([])
        getTotalInvoices(pagination.total)
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterData[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }
        setFilterData(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleDeleteChip = (id, name) => {
        if (name === "invoice_sent" || name === "invoice_payment" || name === "invoice_date" || name === "invoice_draft") {
            const updatedFilterState = { ...filterData }; // Create a copy of the filterData object

            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            setFilterData(updatedFilterState);
        } else {
            const newFilterState = { ...filterData };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            setFilterData(newFilterState);
        }
    };

    const clearAllFilter = () => {
        datesError.invoice_sent_from = '';
        datesError.invoice_sent_to = '';
        datesError.invoice_payment_from = '';
        datesError.invoice_payment_to = '';
        datesError.invoice_date_from = '';
        datesError.invoice_date_to = '';
        datesError.invoice_draft_from = '';
        datesError.invoice_draft_to = '';

        setDatesError({ ...datesError });
        setFilterData(prevState => ({
            ...prevState,
            companies: [] // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            overdue_type: [] // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_sent_from: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_sent_to: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_payment_from: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_payment_to: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_date_from: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_date_to: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_draft_from: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            invoice_draft_to: '' // Update the state with the new array
        }));
        setFilterData(prevState => ({
            ...prevState,
            levels: [] // Update the state with the new array
        }))
    }

    const cancelFilter = () => {
        setDrawer(false);
        setSelectedFilter("");

    }

    const handleApplyFilter = () => {
        if (activeState === 'All') {
            if (filterData.invoice_date_from != "" && filterData.invoice_date_to == "" && filterData.invoice_payment_from != "" && filterData.invoice_payment_to == "" && filterData.invoice_sent_from != "" && filterData.invoice_sent_to == "") {
                datesError.invoice_date_to = "Please select the To date.";
                datesError.invoice_payment_to = "Please select the To date.";
                datesError.invoice_sent_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_date_from == "" && filterData.invoice_date_to != "") {
                datesError.invoice_date_from = "Please select the from date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_payment_from == "" && filterData.invoice_payment_to != "") {
                datesError.invoice_payment_from = "Please select the from date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_sent_from == "" && filterData.invoice_sent_to != "") {
                datesError.invoice_sent_from = "Please select the from date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_date_from != "" && filterData.invoice_date_to == "") {
                datesError.invoice_date_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_payment_from != "" && filterData.invoice_payment_to == "") {
                datesError.invoice_payment_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_sent_from != "" && filterData.invoice_sent_to == "") {
                datesError.invoice_sent_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") {
                if (moment(filterData.invoice_date_from, dateFormat()).isSameOrBefore(moment(filterData.invoice_date_to, dateFormat()))) {
                    filterState.invoice_date_from = filterData.invoice_date_from;
                    filterState.invoice_date_to = filterData.invoice_date_to;
                } else {
                    addErrorMsg("Please select valid From/To dates");
                    return false;
                }
            } else {
                filterState.invoice_date_from = '';
                filterState.invoice_date_to = '';
            }
            if (filterData.invoice_payment_from !== "" && filterData.invoice_payment_to !== "") {
                if (moment(filterData.invoice_payment_from, dateFormat()).isSameOrBefore(moment(filterData.invoice_payment_to, dateFormat()))) {
                    filterState.invoice_payment_from = filterData.invoice_payment_from;
                    filterState.invoice_payment_to = filterData.invoice_payment_to;
                } else {
                    addErrorMsg("Please select valid From/To dates");
                    return false;
                }
            } else {
                filterState.invoice_payment_from = '';
                filterState.invoice_payment_to = '';
            }
            if (filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "") {
                if (moment(filterData.invoice_sent_from, dateFormat()).isSameOrBefore(moment(filterData.invoice_sent_to, dateFormat()))) {
                    filterState.invoice_sent_from = filterData.invoice_sent_from;
                    filterState.invoice_sent_to = filterData.invoice_sent_to;
                } else {
                    addErrorMsg("Please select valid From/To dates");
                    return false;
                }
            } else {
                filterState.invoice_sent_from = '';
                filterState.invoice_sent_to = '';
            }
            filterState.request_id = LocalStorage.uid();
            filterState.companies = filterData.companies;
            filterState.overdue_type = filterData.overdue_type;
            filterState.scrollId = ''
            setFilterState({ ...filterState })
            addLoader(true)
            invoicesListingApi({ ...filterState, limit: 10, page: 1 });

        } else if (activeState === 'Drafted') {
            if (filterData.invoice_draft_from == "" && filterData.invoice_draft_to != "") {
                datesError.invoice_draft_from = "Please select the From date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_draft_from != "" && filterData.invoice_draft_to == "") {
                datesError.invoice_draft_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }

            if (filterData.invoice_draft_from !== "" && filterData.invoice_draft_to !== "") {
                if (moment(filterData.invoice_draft_from, dateFormat()).isSameOrBefore(moment(filterData.invoice_draft_to, dateFormat()))) {
                    filterState.invoice_draft_from = filterData.invoice_draft_from;
                    filterState.invoice_draft_to = filterData.invoice_draft_to;
                } else {
                    addErrorMsg("Please select valid From/To dates");
                    return false;
                }
            } else {
                filterState.invoice_draft_from = '';
                filterState.invoice_draft_to = '';
            }
            filterState.request_id = LocalStorage.uid();
            filterState.companies = filterData.companies;
            filterState.scrollId = ''
            setFilterState({ ...filterState })
            addLoader(true)
            invoicesListingApi({ ...filterState, limit: 10, page: 1 });
        } else {
            if (filterData.invoice_sent_from != "" && filterData.invoice_sent_to == "") {
                datesError.invoice_sent_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_sent_from == "" && filterData.invoice_sent_to != "") {
                datesError.invoice_sent_from = "Please select the from date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_sent_from != "" && filterData.invoice_sent_to == "") {
                datesError.invoice_sent_to = "Please select the To date.";
                setDatesError({ ...datesError });
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
            if (filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "") {
                if (moment(filterData.invoice_sent_from, dateFormat()).isSameOrBefore(moment(filterData.invoice_sent_to, dateFormat()))) {
                    filterState.invoice_sent_from = filterData.invoice_sent_from;
                    filterState.invoice_sent_to = filterData.invoice_sent_to;
                } else {
                    addErrorMsg("Please select valid From/To dates");
                    return false;
                }
            } else {
                filterState.invoice_sent_from = '';
                filterState.invoice_sent_to = '';
            }

            filterState.request_id = LocalStorage.uid();
            filterState.companies = filterData.companies;
            filterState.levels = filterData.levels;
            filterState.scrollId = ''
            const updatedLevels = filterState.levels.map(level => parseInt(level));

            const newFilterState = {
                ...filterState,
                levels: updatedLevels.length ? updatedLevels : "",
            };

            setFilterState({ ...newFilterState, ...filterState });
            addLoader(true);
            invoicesListingApi({ ...newFilterState, limit: 10, page: 1 });

        }
    }

    const dateChange = (e, keyName) => {
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterData[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterData({ ...filterData }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'invoice_sent_to':
                datesError.invoice_sent_to = validate_emptyField(input.value)
                break;
            case 'invoice_payment_to':
                datesError.invoice_payment_to = validate_emptyField(input.value)
                break;
            case 'invoice_date_to':
                datesError.invoice_date_to = validate_emptyField(input.value)
                break;
            case 'invoice_draft_to':
                datesError.invoice_draft_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setExpanded(false);
        if (activeState === 'All') {
            filterState.sort_column = sort_column;
            filterState.sort_order = sort_order;
            filterState.status = 'All';
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            setLoading(true);
            invoicesListingApi(filterState);
            filterStorage.setFilter("All", filterState)

        } else if (activeState === 'Drafted') {
            filterState.sort_column = sort_column;
            filterState.sort_order = sort_order;
            filterState.status = 'Drafted';
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            setLoading(true);
            invoicesListingApi(filterState)
            filterStorage.setFilter("Drafted", filterState)

        } else if (activeState === 'Pending Approval') {
            filterState.sort_column = sort_column;
            filterState.sort_order = sort_order;
            filterState.status = 'Pending Approval';
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            setLoading(true);
            invoicesListingApi(filterState)
            filterStorage.setFilter("Pending Approval", filterState)

        }
    }

    const handleClick = (event, args) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const [listLoading, setListLoading] = useState(false);

    const loadMoreData = () => {

        if (activeState == "All") {
            // let status = filterState.status;
            filterState.limit = Number(filterState.limit) + 6;
            // filterState.status = status;
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            invoicesListingApi({ ...filterState });
            filterStorage.setFilter("All", filterState)
        } else if (activeState == 'Drafted') {
            filterState.limit = Number(filterState.limit) + 6;
            // filterState.status = 'Drafted';
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            setListLoading(true);
            invoicesListingApi({ ...filterState });
            filterStorage.setFilter("Drafted", filterState)
        } else {
            filterState.limit = Number(filterState.limit) + 6;
            // filterState.status = 'Pending Approval';
            filterState.scrollId = ''
            setFilterState({ ...filterState });
            setListLoading(true);
            invoicesListingApi({ ...filterState });
            filterStorage.setFilter("Pending Approval", filterState);
        }
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterState.limit) {
                    loadMoreData();
                } else {
                    setLoading(false);
                }
            } else {
            }
        }
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const theme = useTheme();

    // Use useMediaQuery to evaluate breakpoints
    const isXs = useMediaQuery(theme.breakpoints.down("sm")); // Extra-small screens
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // Small screens
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // Medium screens
    // const isLg = useMediaQuery(theme.breakpoints.up("lg")); // Large screens

    const searchWidth = isXs
        ? "290px" // Extra small screens
        : isSm
            ? "350px" // Small screens
            : isMd
                ? "380px" // Medium screens
                : "400px"; // Large screens

    const handleStatusOpen = (e) => {
        if (activeState === 'All') {
            setOpenStatusArrow(e.currentTarget);
        }
    }

    const handleStatus = (args) => {
        if (activeState === 'All') {
            filterState.status = args
            filterState.scrollId = ''
            setFilterState({ ...filterState })
            invoicesListingApi({ ...filterState, status: args });
            filterStorage.setFilter("All", filterState)
        }
        setOpenStatusArrow(null);

    }

    const handlePopSearchClear = (args) => {
        if (args == 'invoice') {
            setSearchInvoice('');
            setSelectedInvoices([])
            getTotalInvoices(pagination.total)
        } else if (args == 'zoho') {
            setSearchZoho('');
            setSelectedInvoicesZoho([])
            getTotalInvoices(pagination.total)
        }
    }

    const [progress, setProgress] = useState(10);
    const [sync, setSync] = useState(false);
    const [syncZoho, setSyncZoho] = useState(false)
    const [QBButtonLoader, setQBButtonLoader] = useState(false);
    const [ZHButtonLoader, setZHButtonLoader] = useState(false);

    const handleQBSync = () => {
        if (quickbooksList === "sync") {
            setProgress(10)
            setQBButtonLoader(true);
            const data = {
                request_id: LocalStorage.uid(),
                sync_item_ids: selectedInvoices
            }

            InvoiceListingAPI.quickbookIntegration(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setSync("syncing");
                    setQBButtonLoader(false);
                    setSelectedInvoices([]);
                    setProgress(10);
                    setSearchInvoice('')
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 100) {
                                clearInterval(timer);
                                return 100;
                            }
                            return Math.min(prevProgress + 10, 100);
                        });
                    }, 100);
                    addSuccessMsg(res.data.message);

                    getTotalInvoices(pagination.total, filterState.search);

                    setTimeout(() => {
                        setQuickbooksList('sync');
                        setSync("");
                    }, 600);

                } else {
                    setSync("syncing");
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 90) {
                                clearInterval(timer);
                                return 90;
                            }
                            return Math.min(prevProgress + 10, 90);
                        });
                    }, 90);
                    addErrorMsg(res.data.message);
                    setQBButtonLoader(false);
                    setQuickbooksList('sync');
                    setSync("");
                }
            });


        } else if (quickbooksList === "import") {
            setProgress(10);
            setQBButtonLoader(true);
            const data = {
                request_id: LocalStorage.uid(),
                sync_item_ids: selectedInvoices
            }

            InvoiceListingAPI.quickbookImportIntegration(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setSync("importing");
                    setQBButtonLoader(false);
                    setSelectedInvoices([]);
                    setProgress(10);
                    setSearchZoho('')
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 100) {
                                clearInterval(timer);
                                return 100;
                            }
                            return Math.min(prevProgress + 10, 100);
                        });
                    }, 100);
                    addSuccessMsg(res.data.message);

                    getTotalInvoices(pagination.total, filterState.search);

                    setTimeout(() => {
                        setQuickbooksList('import');
                        setSync("");
                    }, 600);

                } else {
                    setSync("importing");
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 90) {
                                clearInterval(timer);
                                return 90;
                            }
                            return Math.min(prevProgress + 10, 90);
                        });
                    }, 90);
                    addErrorMsg(res.data.message);
                    setQBButtonLoader(false);
                    setQuickbooksList('import');
                    setSync("");
                }
            });
        }

    };

    const handleZHSync = () => {
        if (zohoList === "sync") {
            setProgress(10);
            setZHButtonLoader(true);
            const data = {
                request_id: LocalStorage.uid(),
                sync_item_ids: selectedInvoicesZoho
            }

            InvoiceListingAPI.zohoIntegration(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setSyncZoho("syncing");
                    setZHButtonLoader(false);
                    setSelectedInvoicesZoho([]);
                    setProgress(10);

                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 100) {
                                clearInterval(timer);
                                return 100;
                            }
                            return Math.min(prevProgress + 10, 100);
                        });
                    }, 100);
                    addSuccessMsg(res.data.message);
                    getTotalInvoices(pagination.total, filterState.search);

                    setTimeout(() => {
                        setZohoList('sync');
                        setSyncZoho("");
                    }, 600);

                } else {
                    setSyncZoho("syncing");
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 90) {
                                clearInterval(timer);
                                return 90;
                            }
                            return Math.min(prevProgress + 10, 90);
                        });
                    }, 90);
                    addErrorMsg(res.data.message);
                    setZHButtonLoader(false);
                    setZohoList('sync');
                    setSyncZoho("");
                }
            });


        } else if (zohoList === "import") {
            setProgress(10);
            setZHButtonLoader(true);
            const data = {
                request_id: LocalStorage.uid(),
                sync_item_ids: selectedInvoicesZoho,
            }

            InvoiceListingAPI.zohoImportIntegration(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setSyncZoho("importing");
                    setZHButtonLoader(false);
                    setSelectedInvoicesZoho([]);
                    setProgress(10);

                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 100) {
                                clearInterval(timer);
                                return 100;
                            }
                            return Math.min(prevProgress + 10, 100);
                        });
                    }, 100);
                    addSuccessMsg(res.data.message);
                    getTotalInvoices(pagination.total, filterState.search);

                    setTimeout(() => {
                        setZohoList('import');
                        setSyncZoho("");
                    }, 600);

                } else {
                    setSyncZoho("importing");
                    const timer = setInterval(() => {
                        setProgress((prevProgress) => {
                            if (prevProgress >= 90) {
                                clearInterval(timer);
                                return 90;
                            }
                            return Math.min(prevProgress + 10, 90);
                        });
                    }, 90);
                    addErrorMsg(res.data.message);
                    setZHButtonLoader(false);
                    setZohoList('import');
                    setSyncZoho("");
                }
            });
        }

    };

    const handleViewInvoice = (item) => {
        filterState.scrollId = item.id;
        filterStorage.setFilter(activeState, filterState);
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationId();
    }


    return (
        <>
            <Box className={classes.flexBox} sx={{
                paddingLeft: {
                    xs: "0px",
                    sm: "0px",
                    md: "65px",
                }
            }}>
                <Box sx={{ width: "90.5%" }} >
                    <Box style={{ padding: { md: "20px 10px 10px 10px", sm: "0px" } }}>
                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent='center' spacing={2} px={{ lg: 0, md: 0, sm: 0, xs: 0 }} alignItems={"center"}>
                            <Grid item container xl={5} lg={6} md={8} sm={7} xs={12} alignItems={"center"} px={{ xs: '0px !important' }}>
                                <Box className={classes.tabBg}>
                                    <Box className={activeState == 'All' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('All')}>
                                        {
                                            activeState == 'All' ?
                                                <Text largeWhiteActive400>All Invoices</Text> :
                                                <Text mediumInactiveLabel>All Invoices</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'Drafted' || data?.name == 'Drafted' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('Drafted')}>
                                        {
                                            activeState == 'Drafted' || data?.name == 'Drafted' ?
                                                <Text largeWhiteActive400>Drafted</Text> :
                                                <Text mediumInactiveLabel>Drafted</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'Pending Approval' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('Pending Approval')}>
                                        {
                                            activeState == 'Pending Approval' ?
                                                <Text largeWhiteActive400>Pending for Approval</Text> :
                                                <Text mediumInactiveLabel>Pending for Approval</Text>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item container xl={7} lg={6} md={4} sm={5} xs={12} justifyContent={'end'} gap={"10px"} alignItems={"center"} pl={{ lg: 4, md: 2, sm: 2, xs: 0 }}>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_create" && item.is_allowed == true))) ?
                                        <Button addButton onClick={addForm}><img src={Userplus} alt='plus' className={classes.addIcon} />Add Invoice</Button>
                                        : ""
                                }
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} mt={'2px'}>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                    <Box sx={{
                                        height: "44px", border: "1.5px solid #E2E5E6", width: searchWidth, borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center"
                                    }}>
                                        <input
                                            type="text"
                                            value={filterState.search}
                                            className={classes.EmployeesSearchInput}
                                            onChange={(e) => changeHandler(e)}
                                            onDrop={(event) => {
                                                event.preventDefault();
                                            }}
                                            onDragOver={(event) => {
                                                event.preventDefault();
                                            }}

                                            placeholder="Search by employee name, client name & Invoice ID"
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {
                                                filterState.search === "" ?
                                                    <img src={Search} alt="Search" />
                                                    :
                                                    <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8} lg={8} md={8} pr={{ lg: 0, md: 0, sm: 2, xs: 0 }} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='flex-end'>
                                        {
                                            activeState == 'All' && connectedAppsList.isQuickBookIntegrated &&
                                            <Box display='flex' flexDirection='row' gap={2} className={classes.quickbooksbg}>
                                                <img src={quickBooks} alt='quickbooks' />
                                                {
                                                    openQuickbookArrow ?
                                                        <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                        <img src={arrowDown} aria-controls="status-menu" alt='quickbooks' className={classes.arrowSize} onClick={quickbookHandler} />
                                                }
                                                <Menu
                                                    anchorEl={openQuickbookArrow}
                                                    id="status-menu"
                                                    open={openQuickbookArrow}
                                                    onClose={() => { setOpenQuickbookArrow(null) }}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: "visible",
                                                            boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                            mt: 3,
                                                            width: '265px'
                                                        }
                                                    }}
                                                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                >
                                                    <MenuItem className={classes.menuItem} onClick={() => { handleQuickBooks('import') }}><Text smallBlack>Import payment status from Quickbooks</Text></MenuItem>
                                                    <Divider className={classes.divider} />
                                                    <MenuItem className={classes.menuItem} onClick={() => { handleQuickBooks('sync') }}><Text smallBlack>Sync Invoices to Quickbooks</Text></MenuItem>

                                                </Menu>
                                            </Box>
                                        }

                                        {
                                            activeState == 'All' && connectedAppsList.isZohoIntegrated &&
                                            <Box display='flex' flexDirection='row' gap={2} className={classes.zohobooksbg}>
                                                <img src={zohoBooks} alt='zohobooks' />
                                                {
                                                    openZohoArrow ?
                                                        <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                        <img src={arrowDown} alt='zohobooks' className={classes.arrowSize} onClick={(e) => quickbookHandler(e, 'zoho')} />
                                                }
                                                <Menu
                                                    anchorEl={openZohoArrow}
                                                    id="status-menu"
                                                    open={openZohoArrow}
                                                    onClose={() => { setOpenZohoArrow(null) }}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: "visible",
                                                            boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                            mt: 3,
                                                            width: '200px'
                                                        }
                                                    }}
                                                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                >
                                                    <MenuItem className={classes.menuItem} onClick={() => handleZoho('import')}><Text smallBlack>Import payment status from Zohobooks</Text></MenuItem>
                                                    <Divider className={classes.divider} />
                                                    <MenuItem className={classes.menuItem} onClick={() => handleZoho('sync')}><Text smallBlack>Sync Invoices to Zohobooks</Text></MenuItem>
                                                </Menu>
                                            </Box>
                                        }

                                        <button title="Filter" type="button" className={classes.borderButton}
                                            onClick={() => { handleOpenDrawer() }}
                                        >
                                            <Badge badgeContent={filterState.companies.length + filterState.overdue_type.length + filterState.levels.length + ((filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") && 1) + ((filterState.invoice_payment_from !== "" && filterState.invoice_payment_to !== "") && 1) + ((filterState.invoice_sent_from !== "" && filterState.invoice_sent_to !== "") && 1) + ((filterState.invoice_draft_from !== "" && filterState.invoice_draft_to !== "") && 1)} color="error" >
                                                <FilterListIcon sx={{ color: (filterState.companies.length > 0 || (filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") || (filterState.invoice_payment_from !== "" && filterState.invoice_payment_to !== "") || (filterState.invoice_sent_from !== "" && filterState.invoice_sent_to !== "") || filterState.overdue_type.length > 0 || (filterState.invoice_draft_from !== "" && filterState.invoice_draft_to !== "")) ? "#2BEA2B" : "" }} />
                                            </Badge>
                                        </button>
                                        <button title="Export" type="button" className={classes.borderButton}
                                            onClick={() => download()}
                                        >
                                            <img src={cloud} alt="Userplus" />
                                        </button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Box>
                    <TableContainer sx={{ overflow: 'scroll', width: '100%' }}>
                        <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                            width: '100%',
                        }}
                        >
                            <Box style={{ padding: "0px 0px" }}>
                                {/* header */}
                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                        font: "12px Quicksand",
                                        fontWeight: "600",
                                    },
                                }} >
                                    <StyledTableRow>
                                        <StyledTableCell align="start" className={classes.colWidth}>Invoice Id
                                            <IconButton
                                                style={{
                                                    padding: '1px',
                                                    margin: '8px',
                                                    backgroundColor: '#ffffff',
                                                    border: '0',
                                                    minWidth: '22px',
                                                }}
                                                onClick={handleClick}
                                            >
                                                <FilterListIcon sx={{ height: '19px', color: filterState.sort_column === "name" || filterState.sort_column === "created_at" ? "#2BEA2B" : "#777A8E" }} />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                id="account-menu"
                                                open={open}
                                                onClose={handleCloseFilter}
                                                onClick={handleCloseFilter}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 0.5,
                                                        ml: -2,
                                                        padding: '5px',
                                                        '& .MuiMenuItem-root:hover': {
                                                            backgroundColor: '#EAECF0 !important',
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                            >
                                                <MenuItem onClick={() => handleSort('created_at', 'desc')} sx={{ background: filterState.sort_column === 'created_at' && filterState.sort_order === 'desc' ? '#EAECF0' : '' }}>
                                                    <Text mediumBoldBlack400> Newest First</Text>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleSort('created_at', 'asc')} sx={{ background: filterState.sort_column === 'created_at' && filterState.sort_order === 'asc' ? '#EAECF0' : '' }}>
                                                    <Text mediumBoldBlack400> Oldest First</Text>
                                                </MenuItem>
                                            </Menu>
                                        </StyledTableCell>
                                        <StyledTableCell align="start" className={classes.colWidth8}>Client Name</StyledTableCell>
                                        <StyledTableCell align="start" className={classes.colWidth5}>Invoice Date</StyledTableCell>
                                        <StyledTableCell align="start" className={classes.colWidth4} >
                                            <div onClick={handleStatusOpen}>
                                                Status
                                                {activeState === "All" ? (
                                                    openStatusArrow ? (
                                                        (filterState.status == '' || filterState.status == null) ? (
                                                            <img
                                                                src={arrowUp}
                                                                alt="up"
                                                                className={classes.statusIcon}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={greenUp}
                                                                alt="up"
                                                                className={classes.statusIcon}
                                                            />
                                                        )
                                                    ) : (
                                                        (filterState.status == '' || filterState.status == null) ? (
                                                            <img
                                                                src={arrowDown}
                                                                alt="down"
                                                                className={classes.statusIcon}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={greendown}
                                                                alt="down"
                                                                className={classes.statusIcon}
                                                            />
                                                        )
                                                    )
                                                ) : null}
                                            </div>

                                            {activeState === "All" ? <Menu
                                                anchorEl={openStatusArrow}
                                                id="status-menu"
                                                open={openStatusArrow}
                                                onClose={() => { setOpenStatusArrow(null) }}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: "visible",
                                                        boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                        mt: 0.5,
                                                        width: '200px'
                                                    }
                                                }}
                                                transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                            >
                                                <MenuItem onClick={() => handleStatus('All')} className={(activeState == 'All' && filterState.status == 'All') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>All</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Pending Approval')} className={(activeState == 'All' && filterState.status == 'Pending Approval') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Pending for Approval</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Overdue')} className={(activeState == 'All' && filterState.status == 'Overdue') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Overdue</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Paid')} className={(activeState == 'All' && filterState.status == 'Paid') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Paid</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Partially Paid')} className={(activeState == 'All' && filterState.status == 'Partially Paid') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Partially Paid</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Due')} className={(activeState == 'All' && filterState.status == 'Due') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Due</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Write Off')} className={(activeState == 'All' && filterState.status == 'Write Off') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Written Off</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Void')} className={(activeState == 'All' && filterState.status == 'Void') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Voided</Text>
                                                </MenuItem>
                                                <Divider className={classes.divider} />
                                                <MenuItem onClick={() => handleStatus('Drafted')} className={(activeState == 'All' && filterState.status == 'Drafted') ? classes.activeMenuItem : classes.menuItem}>
                                                    <Text smallBlack>Draft</Text>
                                                </MenuItem>
                                            </Menu> : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="start" className={classes.colWidth3}>Invoice Amount</StyledTableCell>
                                        <StyledTableCell align="start" className={classes.colWidth2}>
                                            {
                                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px", paddingLeft: '5px' }}>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                            <Text offBoardBodyFont>
                                                                Count
                                                                <HtmlTooltip
                                                                    placement="bottom"
                                                                    arrow
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box>
                                                                                <Text className={classes.tooltipText}>
                                                                                    Total Invoice count is <br />based on the filters applied
                                                                                </Text>
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                                </HtmlTooltip>
                                                                {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Invoices`}</Text>
                                                        </Grid>
                                                    </div>
                                                    : ""
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                </Box>
                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        )) :
                                        <>
                                            {invoiceRows.length > 0 ?
                                                <Box className={classes.scrollBox} sx={{
                                                    // "@media (min-width: 350px)": {
                                                    //     height: '37.5vh'
                                                    // }
                                                }}
                                                    ref={scrollBox} onScroll={handleScroll}
                                                >
                                                    {
                                                        invoiceRows.length > 0 && invoiceRows.map((item, key) => (

                                                            <StyledTableRowBody>
                                                                <Accordion
                                                                    id={item.id}
                                                                    key={key}
                                                                    // className={classes.newCustomAccordion}
                                                                    className={`${classes.newCustomAccordion} ${filterState.scrollId === item.id ? classes.blinking : ''}`}
                                                                    expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}
                                                                    sx={{
                                                                        border: expanded === `panel${key}` ? '1.5px solid #0C75EB' : 'none', // Add border only when expanded
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        className={classes.AccordionSummary}
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            },
                                                                        }}
                                                                    >
                                                                        <StyledTableCell align="start" className={classes.colWidth}>
                                                                            <Box display="flex" alignItems="center" gap={2} >
                                                                                <Box>
                                                                                    <Text mediumBoldBlack noWrap style={{ margin: '4px 0' }}>
                                                                                        {item.reference_id === "" ? "--" : item.reference_id}
                                                                                    </Text>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '6px' }}>
                                                                                        {item.is_email_sent === true &&
                                                                                            <img src={invoiceSent} alt='invoice_sent' style={{ margin: "0px 2px 0px 0px" }} />
                                                                                        }
                                                                                        {item.is_email_saved === true &&
                                                                                            <img src={invoiceSaved} alt='invoice_saved' style={{ margin: "0px 4px 0px 0px" }} />
                                                                                        }
                                                                                        {Array.isArray(item.invoice_items) && item.invoice_items.slice(0, 2).map((employee, index) => (
                                                                                            <Text mediumGrey3 key={index} style={{ marginRight: index === 0 ? '2px' : '0' }}>
                                                                                                {employee.employee_name === "" ? "--" :
                                                                                                    capitalizeAndAddSpace(employee.employee_name).length > 16 ?
                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(employee.employee_name)} placement="right" arrow>
                                                                                                            {capitalizeAndAddSpace(employee.employee_name).slice(0, 16) + (capitalizeAndAddSpace(employee.employee_name).length > 16 ? "..." : "")}
                                                                                                        </HtmlTooltip>
                                                                                                        :
                                                                                                        capitalizeAndAddSpace(employee.employee_name)
                                                                                                }
                                                                                                {index === 0 && item.invoice_items.length > 1 ? ', ' : ''}
                                                                                            </Text>
                                                                                        ))}
                                                                                        {Array.isArray(item.invoice_items) && item.invoice_items.length > 2 && (
                                                                                            <HtmlTooltip title={item.invoice_items.slice(2).map((emp, index) => <div key={index}>{emp.employee_name}</div>)} placement="bottom" arrow style={{ display: "inline-block" }}>
                                                                                                <div className={classes.blueText}>
                                                                                                    &ensp;+{item.invoice_items.length - 2}
                                                                                                </div>
                                                                                            </HtmlTooltip>
                                                                                        )}
                                                                                    </div>
                                                                                </Box>
                                                                            </Box>
                                                                        </StyledTableCell>

                                                                        {/* Second Column */}
                                                                        <StyledTableCell align="left" className={classes.colWidth8}>
                                                                            <Box display="flex" alignItems="center" gap={2} width={'50%'}>
                                                                                <Text mediumBlack14>{item.client_name ?
                                                                                    capitalizeAndAddSpace(item.client_name).length > 16 ?
                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(item.client_name)} placement="right" arrow>
                                                                                            {capitalizeAndAddSpace(item.client_name).slice(0, 16) + (capitalizeAndAddSpace(item.client_name).length > 16 ? "..." : "")}
                                                                                        </HtmlTooltip>
                                                                                        : capitalizeAndAddSpace(item.client_name)
                                                                                    : '--'}
                                                                                </Text>
                                                                            </Box>
                                                                        </StyledTableCell>

                                                                        {/* Third Column */}
                                                                        <StyledTableCell align="left" className={classes.colWidth6}>
                                                                            <Text mediumBlack14>{item.invoice_date ? item.invoice_date : '--'}</Text>
                                                                        </StyledTableCell>

                                                                        {/* Status Column */}
                                                                        <StyledTableCell align="center" className={classes.colWidth4}>
                                                                            <Box
                                                                                className={classes.activeStatus}
                                                                                style={{ background: item.color }}
                                                                            >
                                                                                {item.invoice_status}
                                                                            </Box>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell align="left" className={classes.colWidth3}>
                                                                            <Text mediumBlack14>{item.total_amount ? `${LocalStorage.getCurrencySymbol()} ${item.total_amount}` : '--'}</Text>
                                                                        </StyledTableCell>

                                                                        {/* View Invoice Column */}
                                                                        <StyledTableCell align="center !important" className={classes.colWidth7} >
                                                                            {
                                                                                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true))) &&
                                                                                <Typography
                                                                                    onClick={() => { handleViewInvoice(item) }}
                                                                                    component={Link}
                                                                                    to={true && '/invoiceIndex'}
                                                                                    state={{ invoiceId: item.id, companiesId: item.company_id }}
                                                                                    className={classes.linkText}
                                                                                >
                                                                                    View Invoice
                                                                                </Typography>
                                                                            }
                                                                        </StyledTableCell>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                            <Box style={{ width: '91%' }}>

                                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                    <div>
                                                                                        <Text mediumGrey2>Due Date</Text>
                                                                                        <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                            {item.due_date ? item.due_date : "--"}
                                                                                        </Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Text mediumGrey2>Received Amount</Text>
                                                                                        <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                            {item.payment_amount ? `${LocalStorage.getCurrencySymbol()} ${item.payment_amount}` : "--"}
                                                                                        </Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Text mediumGrey2>Balance Amount</Text>
                                                                                        <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                            {item.balance_amount ? `${LocalStorage.getCurrencySymbol()} ${item.balance_amount}` : "--"}
                                                                                        </Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Text mediumGrey2>Payment received on</Text>
                                                                                        <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                            {item.payment_date ? item.payment_date : "Not yet received"}
                                                                                        </Text>
                                                                                    </div>
                                                                                </div>
                                                                            </Box>
                                                                        </Box>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </StyledTableRowBody>

                                                        ))
                                                    }

                                                    {listLoading && (
                                                        <Box display='flex' justifyContent='center' width='100%' alignItems='center'>
                                                            <ListLoadingIcon />
                                                        </Box>
                                                    )}
                                                </Box>
                                                :
                                                invoiceRows.length == 0 ?
                                                    <Box>
                                                        {NoDataFound(`No data found`)}
                                                    </Box>
                                                    : ''

                                            }
                                        </>
                                }
                            </Box>
                        </Table>
                    </TableContainer>
                </Box>

                <SwipeableDrawer
                    anchor={'right'}
                    open={drawer}
                    onClose={() => { setDrawer(null) }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                            width: { xs: '100% !important', sm: '80% !important', md: '45% !important' },
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                >

                    <Box >
                        <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                            <Text headerBlack>Filters</Text>
                        </Box>
                        {drawer === "filter" ?
                            <>

                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    {/* chips */}
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        {
                                            filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterData.invoice_date_from} - {filterData.invoice_date_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "invoice_date")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterData.invoice_payment_from !== "" && filterData.invoice_payment_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterData.invoice_payment_from} - {filterData.invoice_payment_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "invoice_payment")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterData.invoice_sent_from} - {filterData.invoice_sent_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "invoice_sent")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            clientDropdown.map((item, key) => (
                                                filterData.companies.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "companies")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }
                                        {
                                            overdueRange.map((item, key) => (
                                                filterData.overdue_type.includes(String(item.id)) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(String(item.id), "overdue_type")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }

                                    </Box>
                                    {
                                        ((filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") || (filterData.invoice_payment_from !== "" && filterData.invoice_payment_to !== "") || (filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "") || filterData.companies.length > 0 || filterData.overdue_type.length > 0) ?
                                            <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                            :
                                            ''
                                    }
                                </Box>
                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    {/* lists */}
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} sx={{ width: { xs: '50%', sm: "50%", md: "43%" } }} borderRight={'1px solid #EAECF0'} pt={0} >
                                        <List component="nav" aria-label="secondary mailbox folder" pt='0px'>
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterData.companies.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterData.companies.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Client Name</Text> : <Text ml={3} mediumBlack>Client Name</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterData.invoice_sent_from !== "" && filterData.invoice_sent_to ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterData.invoice_sent_from !== "" && filterData.invoice_sent_to ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 2 ? <Text smallBlue1 ml={3}>Invoice Date</Text> : <Text mediumBlack ml={3}>Invoice Date</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterData.overdue_type.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterData.overdue_type.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Overdue</Text> : <Text ml={3} mediumBlack>Overdue</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterData.invoice_payment_from !== "" && filterData.invoice_payment_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterData.invoice_payment_from !== "" && filterData.invoice_payment_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Payment Received</Text> : <Text ml={3} mediumBlack>Payment Received</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{(filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Due Invoices</Text> : <Text ml={3} mediumBlack>Due Invoices</Text>} />
                                            </ListItem>
                                            <Divider />
                                            {/* <ListItem className={selectedFilter === 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={(filterData.write_off_from !== "" && filterData.write_off_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA419E" }}><Text mediumWhite400>{(filterData.write_off_from !== "" && filterData.write_off_to !== "")}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Write off</Text> : <Text ml={3} mediumBlack>Write off</Text>} />
                                            </ListItem> */}
                                            <Divider />
                                        </List>

                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto", width: { xs: '50%', sm: "50%", md: "57%" } }} flexDirection={'column'} height={'70vh'} pl={'25px !important'} pt={2}>
                                        {
                                            selectedFilter == 1 ?
                                                <>
                                                    {
                                                        clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"companies"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterData.companies.includes(item.id)} />}
                                                                    label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        :
                                                                        item.value}</Text>}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </> :
                                                selectedFilter == 2 ?
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item lg={12} md={12} sm={12} py={2}>
                                                            <Text mediumBlack>Select Date Range</Text>
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`From`}
                                                                name='invoice_sent_from'
                                                                value={filterData.invoice_sent_from}
                                                                onChange={(value) => dateChange(value, 'invoice_sent_from')}
                                                                height='56px'
                                                            />
                                                        </Grid>
                                                        <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                            &#8594;
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`To`}
                                                                name='invoice_sent_to'
                                                                value={filterData.invoice_sent_to}
                                                                onChange={(value) => dateChange(value, 'invoice_sent_to')}
                                                                height='56px'
                                                                minDate={moment(filterData.invoice_sent_from, dateFormat()).format(dateFormat())}
                                                                disabled={filterData.invoice_sent_from == ""}
                                                                error={datesError.invoice_sent_to}
                                                            />
                                                            <Box sx={{ height: "0px" }}>{datesError.invoice_sent_to ? <Text red>{datesError.invoice_sent_to ? datesError.invoice_sent_to : ''}</Text> : ''}</Box>
                                                        </Grid>
                                                    </Grid> :
                                                    selectedFilter == 3 ?
                                                        <>
                                                            {
                                                                overdueRange.length > 0 && overdueRange.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        {
                                                                            item.id != 5 &&
                                                                            <>
                                                                                <FormControlLabel
                                                                                    key={index}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            size="small"
                                                                                            name={"overdue_type"}
                                                                                            value={item.id}
                                                                                            onChange={(e) => handleChangeCheckBox(e)}
                                                                                            icon={<CheckBorderIcon />}
                                                                                            checkedIcon={<CheckedIcon />}
                                                                                            checked={filterData.overdue_type.includes(String(item.id))} />}
                                                                                    label={<Text mediumBlack >{item.value}</Text>
                                                                                    }
                                                                                />
                                                                            </>
                                                                        }

                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        : selectedFilter == 4 ?
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item lg={12} md={12} sm={12} py={2}>
                                                                    <Text mediumBlack>Select Date Range</Text>
                                                                </Grid>
                                                                <Grid item lg={5.5} md={5} sm={5}>
                                                                    <Date
                                                                        labelText={`From`}
                                                                        name='invoice_payment_from'
                                                                        value={filterData.invoice_payment_from}
                                                                        onChange={(value) => dateChange(value, 'invoice_payment_from')}
                                                                        height='56px'
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                    &#8594;
                                                                </Grid>
                                                                <Grid item lg={5.5} md={5} sm={5}>
                                                                    <Date
                                                                        labelText={`To`}
                                                                        name='invoice_payment_to'
                                                                        value={filterData.invoice_payment_to}
                                                                        onChange={(value) => dateChange(value, 'invoice_payment_to')}
                                                                        height='56px'
                                                                        minDate={moment(filterData.invoice_payment_from, dateFormat()).format(dateFormat())}
                                                                        disabled={filterData.invoice_payment_from == ""}
                                                                        error={datesError.invoice_payment_to}
                                                                    />
                                                                    <Box sx={{ height: "0px" }}>{datesError.invoice_payment_to ? <Text red>{datesError.invoice_payment_to ? datesError.invoice_payment_to : ''}</Text> : ''}</Box>
                                                                </Grid>
                                                            </Grid>
                                                            : selectedFilter == 5 ?
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item lg={12} md={12} sm={12} py={2}>
                                                                        <Text mediumBlack>Select Date Range</Text>
                                                                    </Grid>
                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`From`}
                                                                            name='invoice_date_from'
                                                                            value={filterData.invoice_date_from}
                                                                            onChange={(value) => dateChange(value, 'invoice_date_from')}
                                                                            height='56px'
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                        &#8594;
                                                                    </Grid>
                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`To`}
                                                                            name='invoice_date_to'
                                                                            value={filterData.invoice_date_to}
                                                                            onChange={(value) => dateChange(value, 'invoice_date_to')}
                                                                            height='56px'
                                                                            minDate={moment(filterData.invoice_date_from, dateFormat()).format(dateFormat())}
                                                                            disabled={filterData.invoice_date_from == ""}
                                                                            error={datesError.invoice_date_to}
                                                                        />
                                                                        <Box sx={{ height: "0px" }}>{datesError.invoice_date_to ? <Text red>{datesError.invoice_date_to ? datesError.invoice_date_to : ''}</Text> : ''}</Box>
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                    <Grid container>
                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                            <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                        </Grid>
                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                            <Text veryLargeLabel>Gear Up!</Text>
                                                                        </Grid>
                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                            <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                        }
                                    </Box>
                                </Box>
                            </> : ''
                        }

                        {drawer === "draftFilter" ?
                            <>
                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    {/* chips */}
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        {activeState === "Drafted" ?
                                            (
                                                <>
                                                    {
                                                        clientDropdown.map((item, key) => (
                                                            filterData.companies.includes(item.id) &&
                                                            <Chip
                                                                sx={{ border: "1px solid #E5E8E9" }}
                                                                key={key}
                                                                icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    :
                                                                    item.value}</Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip(item.id, "companies")}
                                                                deleteIcon={<CloseIcon />} />
                                                        ))
                                                    }
                                                    {
                                                        filterData.invoice_draft_from !== "" && filterData.invoice_draft_to !== "" &&
                                                        <>
                                                            <Chip
                                                                sx={{ border: "1px solid #E5E8E9" }}
                                                                key={1}
                                                                icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack>{filterData.invoice_draft_from} - {filterData.invoice_draft_to}</Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip('', "invoice_draft")}
                                                                deleteIcon={<CloseIcon />} />
                                                        </>
                                                    }
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {
                                                        clientDropdown.map((item, key) => (
                                                            filterData.companies.includes(item.id) &&
                                                            <Chip
                                                                sx={{ border: "1px solid #E5E8E9" }}
                                                                key={key}
                                                                icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    :
                                                                    item.value}
                                                                </Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip(item.id, "companies")}
                                                                deleteIcon={<CloseIcon />}
                                                            />
                                                        ))
                                                    }
                                                    {
                                                        approvalLevels.map((item, key) => (
                                                            filterData?.levels?.includes(String(item.id)) &&
                                                            <Chip
                                                                sx={{ border: "1px solid #E5E8E9" }}
                                                                key={key}
                                                                icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack nowrap>{item.value}</Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip(String(item.id), "levels")}
                                                                deleteIcon={<CloseIcon />}
                                                            />
                                                        ))
                                                    }
                                                    {filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "" &&
                                                        <>
                                                            <Chip
                                                                sx={{ border: "1px solid #E5E8E9" }}
                                                                key={1}
                                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack>{filterData.invoice_sent_from} - {filterData.invoice_sent_to}</Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip('', "invoice_sent")}
                                                                deleteIcon={<CloseIcon />}
                                                            />
                                                        </>
                                                    }
                                                </>

                                            )
                                        }

                                    </Box>
                                    {
                                        activeState === 'Drafted' ?
                                            (
                                                ((filterData.invoice_draft_from !== "" && filterData.invoice_draft_to !== "") || filterData.companies.length > 0) ?
                                                    <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                    :
                                                    ''
                                            )
                                            :
                                            (
                                                ((filterData.invoice_sent_from !== "" && filterData.invoice_sent_to !== "") || filterData.companies.length > 0 || filterData.levels.length > 0) ?
                                                    <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                    :
                                                    ''
                                            )

                                    }
                                </Box>
                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    {/* lists */}
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} sx={{ width: { xs: '40%', sm: "43%" } }} borderRight={'1px solid #EAECF0'} pt={0} >
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterData.companies.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterData.companies.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Client Name</Text> : <Text ml={3} mediumBlack>Client Name</Text>} />
                                            </ListItem>
                                            <Divider />
                                            {activeState === "Drafted" &&
                                                <>
                                                    <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterData.invoice_draft_from !== "" && filterData.invoice_draft_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterData.invoice_draft_from !== "" && filterData.invoice_draft_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Drafted Date</Text> : <Text ml={3} mediumBlack>Drafted Date</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            }
                                            {activeState === "Pending Approval" &&
                                                <>
                                                    <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterData.levels.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{filterData.levels.length}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 2 ? <Text smallBlue1 ml={3}>Approval Level</Text> : <Text mediumBlack ml={3}>Approval Level</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterData.invoice_sent_from !== "" && filterData.invoice_sent_to ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.invoice_sent_from !== "" && filterState.invoice_sent_to ? "1" : ""}</Text></Box> : ""}>
                                                        <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Date</Text> : <Text ml={3} mediumBlack>Date</Text>} />
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            }

                                        </List>

                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto", width: { xs: '50%', sm: "55%" } }} flexDirection={'column'} height={'70vh'} pl={'25px !important'} pt={2}>

                                        {
                                            selectedFilter == 1 ?
                                                <>
                                                    {
                                                        clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            name={"companies"}
                                                                            value={item.id}
                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                            icon={<CheckBorderIcon />}
                                                                            checkedIcon={<CheckedIcon />}
                                                                            checked={filterData.companies.includes(item.id)}
                                                                        />}
                                                                    label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        :
                                                                        item.value}</Text>}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </> :
                                                activeState === "Drafted" && selectedFilter == 2 ?
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item lg={12} md={12} sm={12} py={2}>
                                                            <Text mediumBlack>Select Date Range</Text>
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`From`}
                                                                name='invoice_draft_from'
                                                                value={filterData.invoice_draft_from}
                                                                onChange={(value) => dateChange(value, 'invoice_draft_from')}
                                                                height='56px'
                                                            />
                                                        </Grid>
                                                        <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                            &#8594;
                                                        </Grid>
                                                        <Grid item lg={5.5} md={5} sm={5}>
                                                            <Date
                                                                labelText={`To`}
                                                                name='invoice_draft_to'
                                                                value={filterData.invoice_draft_to}
                                                                onChange={(value) => dateChange(value, 'invoice_draft_to')}
                                                                height='56px'
                                                                minDate={moment(filterData.invoice_draft_from, dateFormat()).format(dateFormat())}
                                                                disabled={filterData.invoice_draft_from == ""}
                                                                error={datesError.invoice_draft_to}
                                                            />
                                                            <Box sx={{ height: "0px" }}>{datesError.invoice_draft_to ? <Text red>{datesError.invoice_draft_to ? datesError.invoice_draft_to : ''}</Text> : ''}</Box>
                                                        </Grid>
                                                    </Grid> :
                                                    activeState === "Pending Approval" && selectedFilter == 2 ?
                                                        <>
                                                            {
                                                                approvalLevels.length > 0 && approvalLevels.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <FormControlLabel
                                                                            key={index}
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"levels"}
                                                                                    value={item.id}
                                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={filterData?.levels?.includes(String(item.id))}
                                                                                />}
                                                                            label={<Text mediumBlack nowrap>{item.value}</Text>}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        : activeState === "Pending Approval" && selectedFilter == 3 ?
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item lg={12} md={12} sm={12} py={2}>
                                                                    <Text mediumBlack>Select Date Range</Text>
                                                                </Grid>
                                                                <Grid item lg={5.5} md={5} sm={5}>
                                                                    <Date
                                                                        labelText={`From`}
                                                                        name='invoice_sent_from'
                                                                        value={filterData.invoice_sent_from}
                                                                        onChange={(value) => dateChange(value, 'invoice_sent_from')}
                                                                        height='56px'
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                    &#8594;
                                                                </Grid>
                                                                <Grid item lg={5.5} md={5} sm={5}>
                                                                    <Date
                                                                        labelText={`To`}
                                                                        name='invoice_sent_to'
                                                                        value={filterData.invoice_sent_to}
                                                                        onChange={(value) => dateChange(value, 'invoice_sent_to')}
                                                                        height='56px'
                                                                        minDate={moment(filterData.invoice_sent_from, dateFormat()).format(dateFormat())}
                                                                        disabled={filterData.invoice_sent_from == ""}
                                                                        error={datesError.invoice_sent_to}
                                                                    />
                                                                    <Box sx={{ height: "0px" }}>{datesError.invoice_sent_to ? <Text red>{datesError.invoice_sent_to ? datesError.invoice_sent_to : ''}</Text> : ''}</Box>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                <Grid container>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                        <Text veryLargeLabel>Gear Up!</Text>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </> : ""
                        }
                        <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                            <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                            <Button saveSmall onClick={() => handleApplyFilter()}>Apply Filters</Button>
                        </Box>

                    </Box >

                </SwipeableDrawer>

                <ReusablePopup
                    iconHide
                    white
                    openPopup={quickbooksList}
                    setOpenPopup={setQuickbooksList}
                    onClose={() => { setQuickbooksList(false); setOpenQuickbookArrow(null); }}
                    width800
                >
                    {sync === "syncing" || sync === "importing" ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Text headerBlack>
                                    {quickbooksList === "import" ? "Import payment status from QuickBooks" : "Sync Invoices to QuickBooks"}
                                </Text>
                            </Grid>

                            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} mt={1} pl={2} justifyContent={'center'} spacing={2} height={'64vh'}>
                                <Box pt={8}>
                                    {quickbooksList === "sync" ?
                                        <img src={syncingInvoice} alt="syncing" /> :

                                        <img src={importingInvoice} alt="importing" />
                                    }
                                    <Box pt={8} textAlign='center'>
                                        <Text mediumBoldBlack2> {quickbooksList === "import" ? "Importing payment status from QuickBooks" : "Syncing Invoices to QuickBooks"}</Text>
                                        <Stack direction="row" >
                                            <BorderLinearProgress
                                                sx={{ width: '100%', margin: '20px 10px' }}
                                                color="primary"
                                                value={progress}
                                                variant="determinate"
                                            />
                                            <Text mediumBlack14 pt={2}>
                                                {`${Math.round(progress)}%`}
                                            </Text>
                                        </Stack>
                                    </Box>

                                </Box>

                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Text headerBlack>
                                    {quickbooksList === "import" ? "Import payment status from QuickBooks" : "Sync Invoices to QuickBooks"}
                                </Text>
                            </Grid>
                            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '100% !important' }}>
                                    <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '45%' }}>
                                        <input
                                            className={classes.EmployeesSearchInput}
                                            name="searchInvoice"
                                            onChange={(e) => changeHandler(e, 'invoice')}
                                            placeholder="Search by Invoice ID"
                                            value={searchInvoice}
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {searchInvoice === '' ? <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePopSearchClear('invoice')} />}
                                        </Box>
                                    </Box>
                                    {
                                        selectedInvoices.length > 0 ?
                                            <Box>
                                                <LoadingButton addButton180 loading={QBButtonLoader} onClick={handleQBSync}>
                                                    {quickbooksList === "import" ? "Import" : "Sync Invoice"}
                                                </LoadingButton>
                                            </Box> : ''
                                    }
                                </Box>
                            </Grid>
                            <Grid item container xs={12} mt={2}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        value={syncInvoices.length === selectedInvoices.length}
                                        checked={syncInvoices.length === selectedInvoices.length}
                                        sx={{ padding: '0px !important' }}
                                        icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onClick={(e) => checkHandler(e, 'all')}
                                    />
                                </Grid>
                                <Grid item container xs={11} spacing={2} justifyContent="center">
                                    <Grid item xs={3}><Text mediumBlack14>Invoice ID</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Invoice Day</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Client</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Invoice Amount</Text></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} my={0.8}>
                                <Divider />
                            </Grid>
                            <Box sx={{
                                maxHeight: '44vh', overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                paddingLeft: '5px', paddingRight: '5px', width: '100%',
                                "@media (min-height: 850px)": {
                                    maxHeight: '73vh'
                                }
                            }}>
                                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} mt={1} pl={2}>
                                    {syncInvoices.length > 0 ? syncInvoices.map((item, index) => (

                                        <Grid item container xs={12} mt={1} key={index} margin="20px 0px" >
                                            <Grid item xs={1}>
                                                <Checkbox
                                                    value={selectedInvoices.some(i => i === item.id)}
                                                    checked={selectedInvoices.some(i => i === item.id)}
                                                    sx={{ padding: '0px !important' }}
                                                    icon={<CheckBorderIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                    onClick={(e) => checkHandler(e, 'each', item)}
                                                />
                                            </Grid>
                                            <Grid item container xs={11} columnSpacing={2} justifyContent="center" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center" textAlign="start">
                                                        <Avatar
                                                            alt="QB"
                                                            src={quickBooks}
                                                            className={item.quickbook_migration_status === 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                        />
                                                        <Text analyticsViewAll>
                                                            {item.reference_id === "" ? "--" : item.reference_id}
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.invoice_date ? item.invoice_date : '--'}</Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>
                                                        {item.client_name === "" ? "-" : capitalizeAndAddSpace(item.client_name).length > 12 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(item.client_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(item.client_name).slice(0, 12) + (capitalizeAndAddSpace(item.client_name).length > 12 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(item.client_name)
                                                        }
                                                    </Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.total_amount ? item.total_amount : '--'}</Text>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                        :
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        ))}
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                </ReusablePopup>

                <ReusablePopup iconHide white openPopup={zohoList} setOpenPopup={setZohoList} onClose={() => { setZohoList(false); setOpenZohoArrow(null) }} max1200>
                    {syncZoho === "syncing" || syncZoho === "importing" ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Text headerBlack>
                                    {zohoList === "import" ? "Import payment status from Zohobooks" : "Sync Invoices to Zohobooks"}
                                </Text>
                            </Grid>

                            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} mt={1} pl={2} justifyContent={'center'} spacing={2} height={'64vh'}>
                                <Box pt={8}>
                                    {zohoList === "sync" ?
                                        <img src={syncingZohoInvoice} alt="syncing" /> :

                                        <img src={importingZohoInvoice} alt="importing" />
                                    }
                                    <Box pt={8} textAlign='center'>
                                        <Text mediumBoldBlack2> {zohoList === "import" ? "Importing payment status from Zohobooks" : "Syncing Invoices to Zohobooks"}</Text>
                                        <Stack direction="row" >
                                            <BorderLinearProgress
                                                sx={{ width: '100%', margin: '20px 10px' }}
                                                color="primary"
                                                value={progress}
                                                variant="determinate"
                                            />
                                            <Text mediumBlack14 pt={2}>
                                                {`${Math.round(progress)}%`}
                                            </Text>
                                        </Stack>
                                    </Box>

                                </Box>

                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Text headerBlack>
                                    {zohoList === "import" ? "Import payment status from Zohobooks" : "Sync Invoices to Zohobooks"}
                                </Text>
                            </Grid>
                            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '100% !important' }}>
                                    <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '45%' }}>
                                        <input
                                            className={classes.EmployeesSearchInput}
                                            name="searchZoho"
                                            onChange={(e) => changeHandler(e, 'zoho')}
                                            placeholder="Search by Invoice ID"
                                            value={searchZoho}
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {searchZoho === '' ? <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePopSearchClear('zoho')} />}
                                        </Box>
                                    </Box>
                                    {
                                        selectedInvoicesZoho.length > 0 ?
                                            <Box>
                                                <LoadingButton addButton180 loading={ZHButtonLoader} onClick={handleZHSync}>
                                                    {zohoList === "import" ? "Import" : "Sync Invoice"}
                                                </LoadingButton>
                                            </Box> : ''
                                    }
                                </Box>
                            </Grid>
                            <Grid item container xs={12} mt={2}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        value={syncInvoices.length === selectedInvoicesZoho.length}
                                        checked={syncInvoices.length === selectedInvoicesZoho.length}
                                        sx={{ padding: '0px !important' }}
                                        icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onClick={(e) => checkHandlerZoho(e, 'all')}
                                    />
                                </Grid>
                                <Grid item container xs={11} spacing={2} justifyContent="center">
                                    <Grid item xs={3}><Text mediumBlack14>Invoice ID</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Invoice Day</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Client</Text></Grid>
                                    <Grid item xs={3}><Text mediumBlack14>Invoice Amount</Text></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} my={0.8}>
                                <Divider />
                            </Grid>
                            <Box sx={{
                                maxHeight: '44vh', overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                paddingLeft: '5px', paddingRight: '5px', width: '100%',
                                "@media (min-height: 850px)": {
                                    maxHeight: '73vh'
                                }
                            }}>
                                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} mt={1} pl={2}>
                                    {syncInvoices.length > 0 ? syncInvoices.map((item, index) => (

                                        <Grid item container xs={12} mt={1} key={index} margin="20px 0px" >
                                            <Grid item xs={1}>
                                                <Checkbox
                                                    value={selectedInvoicesZoho.some(i => i === item.id)}
                                                    checked={selectedInvoicesZoho.some(i => i === item.id)}
                                                    sx={{ padding: '0px !important' }}
                                                    icon={<CheckBorderIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                    onClick={(e) => checkHandlerZoho(e, 'each', item)}
                                                />
                                            </Grid>
                                            <Grid item container xs={11} columnSpacing={2} justifyContent="center" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center" textAlign="start">
                                                        <Avatar
                                                            alt="QB"
                                                            src={zohosyncIcon}
                                                            className={item.zoho_migration_status === 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                        />
                                                        <Text analyticsViewAll>
                                                            {item.reference_id === "" ? "--" : item.reference_id}
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.invoice_date ? item.invoice_date : '--'}</Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>
                                                        {item.client_name === "" ? "-" : capitalizeAndAddSpace(item.client_name).length > 12 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(item.client_name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(item.client_name).slice(0, 12) + (capitalizeAndAddSpace(item.client_name).length > 12 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(item.client_name)
                                                        }
                                                    </Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.total_amount ? item.total_amount : '--'}</Text>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                        :
                                        [1, 2, 3, 4].map((item) => (
                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Box>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        ))}
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                </ReusablePopup>
            </Box>
        </>
    )
}
