
import { Autocomplete, Box, Chip, Dialog, DialogContent, Divider, Grid, Menu, MenuItem, SwipeableDrawer, TextField, Tooltip, useTheme, } from "@mui/material";
import React, { useRef, useImperativeHandle, forwardRef } from "react";
import Text from "../../../components/customText/Text";
import DashboardStyles from "../DasboardStyles";
import activityLog from '../../../assets/svg/invoice_activity_log.svg';
import editIcon from '../../../assets/svg/invoive_edit.svg';
import deleteIcon from '../../../assets/svg/invoice_delete.svg';
import sendEmailIcon from '../../../assets/svg/invoice_send_email.svg';
import downloadIcon from '../../../assets/svg/invoice_download.svg';
import bellIcon from '../../../assets/svg/invoice_bill_icon.svg';
import syncIcon from '../../../assets/svg/invoice_sync_icon.svg';
import { useState } from "react";
import ReusablePopup from "../../../components/reuablePopup/ReusablePopup";
// import deletePopupIcon from '../../../assets/svg/invoice_delete_popup_icon.svg';
import deletePopupIcon from "../../../assets/svg/placementPop.svg";
import LoadingButton from "../../../components/customButton/LoadingButton";
import Button from '../../../components/customButton/Button';
import ToggleSwitch from "../../../components/toggle/CustomToggle";

// import CustomSelect from "../../../components/customSelect/CustomSelect";
import InvoiceActivityLog from "./Invoice_activity_log";
import bellIconOn from '../../../assets/svg/notification_bell_on.svg';
import sendEmailOn from '../../../assets/svg/send_email_on.svg';
import syncOn from '../../../assets/svg/sync_on.svg';
import savedIcon from '../../../assets/svg/invoice_saved_icon.svg';
import sentIcon from '../../../assets/svg/invoice_sent_icon.svg';
import partiallyPaidIcon from '../../../assets/svg/invoice_partially_paid_icon.svg';
import InvoicePdfScreen from "./Invoice_pdf_view";
import InvoiceShowPdfScreen from "./invoice_show_pdf";
import { addErrorMsg, addLoader, addSuccessMsg, downloadExcel, openDocumentInNewtab, removeLoader } from "../../../utils/utils";
import { useEffect } from "react";
// import moment from "moment/moment";
import LocalStorage from "../../../utils/LocalStorage";
import InvoiceHistoryCilentAPI from "../../../apis/admin/invoice/InvoiceHistoryCilentAPI";
// import Input from "../../../components/input/Input";
import { ReactComponent as CancelChip } from '../../../assets/svg/cancel_chip_icon.svg';
import uploadIcon from '../../../assets/svg/upload_icon.svg';
import pdfIcon from '../../../assets/svg/pdf_icon.svg';
import pdfDeleteIcon from '../../../assets/svg/delete_icon.svg';
import RichTextEditor from "react-rte";
import MainStyles from '../../settings/configurations/MainStyles'
import RecordPaymentDrawer from "./RecordPaymentDrawer";
import VoidDrawer from "./VoidDrawer";
import WriteoffDrawer from "./WriteoffDrawer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountMenuStyles from "../../../layouts/menu/AccountMenuStyles";

import ApproveSendIcon from "../../../assets/svg/approve_send_icon.svg";
import CommonApi from "../../../apis/CommonApi";
import { useNavigate } from "react-router-dom";

const InvoiceVoice = forwardRef((props, ref) => {
    const { handleKeyStatus, selectInvoiceId, allHistoryDetails, cilentHistoryDetails } = props;
    const allowedStatuses = ["Saved", "Sent", "Due", "Overdue", "Partially Paid"];
    const syncStatuses = ["Saved", "Sent", "Write Off", "Paid", "Due", "Overdue", "Partially Paid", "Void"];
    const navigate = useNavigate();
    const classes = DashboardStyles();
    const classesMainStyles = MainStyles();
    const [sendReminder, setSendReminder] = useState(false);
    const recordPaymentStatus = ['Saved', 'Partially Paid', 'Overdue', 'Sent', 'Due'];
    const writeoffStatusYes = ['Saved', 'Sent', 'Partially Paid'];
    const writeoffStatusNo = ['Drafted', 'Pending Approval', 'Paid'];
    const voidStatusNo = ['Drafted', 'Pending Approval', 'Paid', 'Partially Paid'];
    const recordPaymentDropdown = ['Drafted', 'Pending Approval', 'Approved', 'Rejected', 'Void', 'Write Off'];
    const [selectedPayment, setSelectedPayment] = useState('Record Payment');
    const classesAccountMenuStyles = AccountMenuStyles();
    const [paymentEl, setPaymentEl] = useState(null);
    const paymentOptions = Boolean(paymentEl);
    const [recordPaymentDrawer, setRecordPaymentDrawer] = useState(false);
    const [voidDrawer, setVoidDrawer] = useState(false);
    const [writeoffDrawer, setWriteoffDrawer] = useState(false);
    const [fileAttachements, setFileAttachements] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toggleOpen, setToggleOpen] = useState(false);
    const [anchorTourGuideEl, setAnchorTourGuideEl] = useState(null);
    const [anchorBellIconEl, setAnchorBellIconEl] = useState(null);
    const [anchorSyncIconEl, setAnchorSyncIconEl] = useState(null);
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    // const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [zohoSnackbarOpen, setZohoSnackbarOpen] = useState(false);
    const openTourGuideOptions = Boolean(anchorTourGuideEl);
    const openBellIconOptions = Boolean(anchorBellIconEl);
    const opensyncIconOptions = Boolean(anchorSyncIconEl);
    const activityOpen = Boolean(activityAnchorEl);
    const [activityData, setActivityData] = useState([])
    const [paymentData, setPaymentData] = useState([])

    const [value, setValue] = useState('');
    const [writeOff, setWriteOff] = useState('');
    const [invoicePdfData, setInvoicePdfData] = useState({});
    const [invoiceGetTemplate, setInvoiceGetTemplate] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [approveSend, setApproveSend] = useState("");
    const [connectedAppsList, setConnectedAppsList] = useState({
        isZohoIntegrated: false,
        isQuickBookIntegrated: false
    })
    const [emailError, setEmailError] = useState(false);
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [sentEmail, setSentEmail] = useState(false);
    // Email validation regex (ensures proper email format)
    const emailValidator = new RegExp(/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\.[a-zA-Z]{2,6}$/);

    useEffect(() => {
        invoicePdf();
        connectedApps();
        // eslint-disable-next-line 
    }, [selectInvoiceId])

    useEffect(() => {
        if (value == '') {
            // eslint-disable-next-line
            allAcitivity();
        } else {
            // eslint-disable-next-line
            paymentLog();
        }
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        if (invoicePdfData?.status) {
            if (recordPaymentStatus.includes(invoicePdfData?.status)) {
                setSelectedPayment('Record Payment');
            } else if ((writeoffStatusYes.includes(invoicePdfData?.status) && !writeoffStatusNo.includes(invoicePdfData?.status))) {
                setSelectedPayment('Write-Off');
            } else if (!voidStatusNo.includes(invoicePdfData?.status)) {
                setSelectedPayment('Void');
            }
            else {
                setSelectedPayment('');
            }
        } else {
            setSelectedPayment('')

        }
        // eslint-disable-next-line
    }, [invoicePdfData])

    const handlePaymentClose = () => {
        setPaymentEl(null);
    }
    const handlePaymentAnchorEle = (event) => {
        event.stopPropagation();
        setPaymentEl(event.currentTarget);
    }

    const handlePaymentType = (paymentType) => {

        if (paymentType == 'Record Payment') {
            setRecordPaymentDrawer(true);
        }
        if (paymentType == 'Write-Off') {
            setWriteoffDrawer(true);
        }
        if (paymentType == 'Void') {
            setVoidDrawer(true);
        }
        handlePaymentClose();
        setSelectedPayment(paymentType);

    };


    const handleTourGuideAnchorEle = (event) => {
        event.stopPropagation();
        setAnchorTourGuideEl(event.currentTarget);
    }
    const handleTourGuideClick = () => {
        // setAnchorEl(null);
        invoiceSendEmail();
        setAnchorTourGuideEl(null);
    }

    const handleCloseTourGuide = () => {
        setAnchorTourGuideEl(null);
    };

    const handleDeletePopup = () => {
        setDeleteOpen(true);
    }

    const handleBellIconAnchorEle = (event) => {
        event.stopPropagation();
        setFileAttachements([]);
        setAnchorBellIconEl(event.currentTarget);
    }

    const handleBellIconClick = () => {
        // setAnchorEl(null);
        setAnchorBellIconEl(null);
        setSendReminder(true);
        invoiceSendRemainder();
        setInvoiceGetTemplate([]);
        setAttachments([]);
    }

    const handleCloseBellIcon = () => {
        setAnchorBellIconEl(null);
    };

    const handleSyncIconAnchorEle = (event) => {
        event.stopPropagation();
        setAnchorSyncIconEl(event.currentTarget);
    }
    // const handleSyncIconClick = () => {
    //     setAnchorSyncIconEl(null);
    // }


    const handleSyncQuickBooksClick = () => {
        setAnchorSyncIconEl(null);
        syncQuickBookApi();
    }


    const handleImportQuickBookClick = () => {
        setAnchorSyncIconEl(null);
        importQuickBookApi();
    }

    const handleSyncZohoClick = () => {
        setAnchorSyncIconEl(null);
        syncZohoApi();
    }

    const handleImportZohobookClick = () => {
        setAnchorSyncIconEl(null);
        importZohoBookApi();
    }

    const handleCloseSyncIcon = () => {
        setAnchorSyncIconEl(null);
    };

    const handleToggleOpen = (e) => {
        if (e.target.checked) {
            setToggleOpen(true);
        } else {
            setToggleOpen(false);
        }
    }


    // eslint-disable-next-line
    // const handleSendReminderClose = () => {
    //     setSendReminder(false); // Close the Snackbar
    // };

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
        setValue('');
        allAcitivity();
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleWriteOff = () => {
        setWriteOff(true);
    }

    const openInNewTab = (args, documentUrl) => {
        openDocumentInNewtab(args.document_key, args.document_path)
    }

    const allAcitivity = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId,
            referrable_type: '',
        }
        InvoiceHistoryCilentAPI.invoiceActivity(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setActivityData(res.data.data);
            } else {
                if (res.data.message != "No data available") {
                    addErrorMsg(res.data.message)
                }

            }
        })
    }



    const paymentLog = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId,
            referrable_type: 2,
        }
        InvoiceHistoryCilentAPI.invoiceActivity(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setPaymentData(res.data.data);
            } else {
                if (res.data.message != "No data available") {
                    addErrorMsg(res.data.message)
                }
            }
        })
    }



    const invoicePdf = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId,
        }
        InvoiceHistoryCilentAPI.invoicePdfViewAPi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setInvoicePdfData(res.data.data[0]);
                handleKeyStatus(res.data.data[0])
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const invoiceDeleteApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId
        }
        InvoiceHistoryCilentAPI.invoiceDeleteAPi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                setDeleteOpen(false)
                navigate('/invoice')
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const handleDelete = () => {
        // setDeleteOpen(true)
        invoiceDeleteApi();
    }

    const invoiceSendEmail = async () => {
        try {
            const obj = {
                request_id: LocalStorage.uid(),
                invoice_id: selectInvoiceId,
                slug: 'send-mail'
            };
            addLoader(true);
            const res = await InvoiceHistoryCilentAPI.invoiceSendEmail(obj);
            if (res.data.statusCode === 1003) {
                const formData = new FormData();
                formData.append('request_id', LocalStorage.uid());
                formData.append('toEmail', JSON.stringify(res.data.data[0]?.toEmail));
                formData.append('subject', res.data.data[0]?.subject);
                formData.append('html', res.data.data[0]?.html);
                formData.append('organization_name', res.data.data[0]?.organization_name);
                formData.append('invoice_id', selectInvoiceId);
                const postRes = await InvoiceHistoryCilentAPI.invoicePostTemplateApi(formData);
                if (postRes.data.statusCode === 1003) {
                    removeLoader();
                    setApproveSend(true);
                    setSentEmail(true);
                } else {
                    removeLoader();
                    addErrorMsg(postRes.data.message);
                }
            }
            else {
                removeLoader();
            }
        } catch (error) {
            addErrorMsg("Something went wrong. Please try again.");
        }
    };




    const getInvoiceStatusIcon = (status) => {
        switch (status) {
            case "Saved":
                return savedIcon;
            case "Pending Approval":
            case "Approved":
            case "Drafted":
                return partiallyPaidIcon;
            case "Sent":
            case "Partially Paid":
            case "Paid":
            case "Void":
            case "Write Off":
                return sentIcon;
            case "Rejected":
                return '';
            default:
                return;
        }
    };


    // const invoiceSendRemainder = () => {
    //     var obj = {
    //         request_id: LocalStorage.uid(),
    //         invoice_id: selectInvoiceId,
    //         slug: 'reminder-mail'
    //     }
    //     var data = {
    //         request_id: LocalStorage.uid(),
    //         invoice_id: selectInvoiceId,
    //     };

    //     InvoiceHistoryCilentAPI.invoiceDownload(data).then((res) => {
    //         if (res.data instanceof Blob) {
    //             const blob = res.data;
    //             const file = new File([blob], `invoice_${selectInvoiceId}.pdf`, { type: blob.type });
    //             console.log('file', file)
    //             setInvoiceFile(file); // Store the file in state
    //         } else {
    //             addErrorMsg('Failed');
    //         }
    //     })
    //     InvoiceHistoryCilentAPI.invoiceGetTemplateApi(obj).then((res) => {
    //         if (res.data.statusCode === 1003) {
    //             setInvoiceGetTemplate(res.data.data[0])
    //         } else {
    //             addErrorMsg(res.data.message)
    //         }
    //     })
    // }

    const invoiceSendRemainder = async () => {
        setSendReminder(true)
        try {
            var obj = {
                request_id: LocalStorage.uid(),
                invoice_id: selectInvoiceId,
                slug: 'reminder-mail'
            };
            var data = {
                request_id: LocalStorage.uid(),
                invoice_id: selectInvoiceId,
            };

            // Call the template API after the file is set
            const templateRes = await InvoiceHistoryCilentAPI.invoiceGetTemplateApi(obj);
            if (templateRes.data.statusCode === 1003) {
                setInvoiceGetTemplate(templateRes.data.data[0]);
                // Call the download API
                const res = await InvoiceHistoryCilentAPI.invoiceDownload(data);
                if (res.data instanceof Blob) {
                    const blob = res.data;
                    const file = new File([blob], `invoice_${selectInvoiceId}.pdf`, { type: blob.type });
                    const fileObject = {
                        document_path: file.name,
                        file: file,
                    };
                    // Update state to display in UI
                    setAttachments((prev) => [...prev, fileObject]);
                    setFileAttachements((file) => [...file, file])
                } else {
                    addErrorMsg('Failed to get the file.');
                }
            } else {
                addErrorMsg(templateRes.data.message);
            }
        } catch (error) {
            addErrorMsg("Something went wrong.");
        }
    };

    // Function to handle file selection
    const uploadDocs = (e) => {
        const fileInput = e.target; // Store reference to input field
        const selectedFile = fileInput.files[0];
        if (selectedFile.type.split('/').some(r => ['pdf'].includes(r))) {
            const fileSizeMB = (selectedFile.size / (1024 * 1024)).toFixed(2);
            if (fileSizeMB < 25) {
                const fileObject = {
                    document_path: selectedFile.name,
                    file: selectedFile,
                };
                setAttachments((prev) => [...prev, fileObject]);
                setFileAttachements((file) => [...file, selectedFile])

            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PDF format only.");
        }
        fileInput.value = null;
    };

    const handleDeleteChip = (chipToDelete) => {
        // setInvoiceGetTemplate((prev) => prev.filter((chip) => chip !== chipToDelete));
        setInvoiceGetTemplate((prev) => {
            const updatedEmails = prev.toEmail.filter((chip) => chip !== chipToDelete);
            return { ...prev, toEmail: updatedEmails };
        });
    };

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const invoicePostSendRemainder = () => {
        if (!invoiceGetTemplate?.toEmail || invoiceGetTemplate.toEmail.length === 0) {
            setEmailError(true);
        }
        addLoader(true);
        const formData = new FormData();
        formData.append('request_id', LocalStorage.uid());
        formData.append('toEmail', JSON.stringify(invoiceGetTemplate?.toEmail));
        formData.append('subject', invoiceGetTemplate?.subject);
        formData.append('html', invoiceGetTemplate?.html);
        formData.append('organization_name', invoicePdfData.from_details[0]?.organization_name);
        formData.append('invoice_id', selectInvoiceId)
        if (fileAttachements?.length > 0) {
            fileAttachements.forEach(file => formData.append('attachments', file))
        }
        InvoiceHistoryCilentAPI.invoicePostTemplateApi(formData).then((res) => {
            if (res.data.statusCode === 1003) {
                // setInvoiceGetTemplate(res.data.data)
                removeLoader();
                setSendReminder(false)
                setApproveSend(true)
            } else {
                removeLoader();
                addErrorMsg(res.data.message)
            }
        })
    }




    const validateEmail = (email) => {
        return emailValidator.test(email);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const enteredEmail = inputValue.trim();

            if (validateEmail(enteredEmail)) {
                setInvoiceGetTemplate((prev) => ({
                    ...prev,
                    toEmail: [...(prev.toEmail || []), enteredEmail], // Append valid email
                }));
                setInputValue(""); // Clear input only if valid
                setEmailError(false);
            } else {
                addErrorMsg("Invalid email format. Please enter a valid email.");
                setEmailError(true);
            }
            event.preventDefault(); // Prevent form submission
        }
    };

    const handleCancelSendRemainder = () => {
        setInvoiceGetTemplate([]);
        setAttachments([]);
        setSendReminder(false);
        invoicePdf();
        cilentHistoryDetails();
        allHistoryDetails();
        setEmailError(false);
    }

    const handleCloseRemainder = () => {
        setApproveSend(false)
    }

    const cancelWriteOff = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId,
        }
        InvoiceHistoryCilentAPI.invoiceCancelWriteOff(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                invoicePdf(); cilentHistoryDetails(); allHistoryDetails(); setWriteOff(false)
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }


    const invoiceDownloadApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            invoice_id: selectInvoiceId,
        }
        InvoiceHistoryCilentAPI.invoiceDownload(obj).then((res) => {
            if (res.data instanceof Blob) {
                const blob = res.data;
                downloadExcel(blob, `invoice_${selectInvoiceId}.pdf`);
            } else {
                addErrorMsg('Failed')
            }
        })
    }




    const connectedApps = () => {
        CommonApi.connectedApps().then((res) => {
            if (res.data.statusCode === 1003) {
                setConnectedAppsList(res.data.data);
            }
        })
    }



    const syncZohoApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            sync_item_ids: [selectInvoiceId],
        }
        InvoiceHistoryCilentAPI.invoiceSyncZohoApi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const syncQuickBookApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            sync_item_ids: [selectInvoiceId],
        }
        InvoiceHistoryCilentAPI.invoiceSyncQuickBookApi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const importQuickBookApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            sync_item_ids: [selectInvoiceId],
        }
        InvoiceHistoryCilentAPI.invoiceImportQuickBookApi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }


    const importZohoBookApi = () => {
        var obj = {
            request_id: LocalStorage.uid(),
            sync_item_ids: [selectInvoiceId],
        }
        InvoiceHistoryCilentAPI.invoiceimportZohoBookApi(obj).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleDeleteFile = (index) => {
        setAttachments(attachments.filter((_, i) => i !== index))
        setFileAttachements(fileAttachements.filter((_, i) => i !== index))
    }

    const callRefetch = () => {
        invoicePdf(); cilentHistoryDetails(); allHistoryDetails();
    }

    const getApproveButtonLabel = () => {
        if (LocalStorage.getUserData().super_admin) {
            if (invoicePdfData?.is_last_stage) {
                return invoicePdfData?.send_invoice_email ? 'Approve & send' : '';
            }
        } else if (invoicePdfData?.is_last_stage) {
            return invoicePdfData?.send_invoice_email ? 'Approve & send' : '';
        } else {
            return '';
        }

    };

    const handleDone = () => {
        if (getApproveButtonLabel() === "Approve & send") {
            invoicePdf();
            cilentHistoryDetails();
            allHistoryDetails();
            handleCloseRemainder();
        } else {
            invoicePdf();
            cilentHistoryDetails();
            allHistoryDetails();
            handleCloseRemainder();
            setSentEmail(false);
        }

    }
    useImperativeHandle(ref, () => ({
        invoicePdf, invoiceSendRemainder
    }));

    const theme = useTheme();

    return (
        <Grid item container xs={12} className={classes.container} sx={{ height: '100vh !important' }}>
            <Grid item xs={12}>
                <Box className={classes.greyBg} sx={{ padding: '8px', height: "58px", display: 'flex', [theme.breakpoints.down('lg')]: { height: "fit-content" } }} >
                    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item lg={4} md={12} sm={4} xs={12}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={getInvoiceStatusIcon(invoicePdfData?.status)}
                                    alt=""
                                    style={{ marginRight: '8px' }}
                                />
                                <Text mediumBoldBlack sx={{ marginRight: "8px", fontSize: '13px !important' }}>{invoicePdfData?.invoice_id}</Text>
                                <Box className={classes.activeBox} style={{ backgroundColor: invoicePdfData?.color, minWidth: 'fit-content', display: 'flex', alignItems: 'center' }}>
                                    <Text mediumBoldWhite sx={{ colour: invoicePdfData?.color, fontSize: '13px !important', }}>{invoicePdfData?.status}</Text>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container item lg={8} md={12} sm={8} xs={12} spacing={1} alignItems='center' >
                            <Grid container item lg={
                                selectedPayment == "Record Payment"
                                    ? 5 : selectedPayment == "Write-Off" ? 4.5 : invoicePdfData?.status == "Rejected" ? 7.5 : selectedPayment == "Void" ? 5.5 : 8.5} md={selectedPayment == "Record Payment"
                                        ? 4 : selectedPayment == "Write-Off" ? 6.5 : invoicePdfData?.status == "Rejected" ? 7.5 : selectedPayment == "Void" ? 4 : 6} sm={selectedPayment == "Record Payment"
                                            ? 4 : selectedPayment == "Write-Off" ? 5.5 : invoicePdfData?.status == "Rejected" ? 7.5 : selectedPayment == "Void" ? 4 : 6.5} xs={12} alignItems='center' justifyContent={{ xl: "flex-end", lg: "flex-end", md: "start", sm: "end", xs: "end" }} gap={"5px"}>

                                {toggleOpen ? "" : <>

                                    <Tooltip
                                        placement="right"
                                        arrow
                                        sx={{ fontSize: '14px !important' }}
                                        title={<Text smallWhite>Activity Log</Text>}
                                    >
                                        <Box
                                            sx={{ cursor: 'pointer', }}
                                            onClick={handleActivityClick}
                                        >
                                            <img
                                                src={activityLog}
                                                alt="activitylog"
                                            />
                                        </Box>
                                    </Tooltip>

                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true))) && ["Sent", "Partially Paid", "Drafted", "Saved", "Paid", "Rejected"].includes(invoicePdfData?.status) && (
                                        <>

                                            <Divider
                                                sx={{
                                                    margin: "0px !important",
                                                    borderColor: "#E2E5E6 !important",
                                                    borderWidth: "1px !important",
                                                    height: '20px'
                                                }}
                                            />


                                            <Box
                                                sx={{ cursor: 'pointer', }}
                                                onClick={() => { navigate('/addInvoice', { state: { invoiceId: invoicePdfData?.id } }) }}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="editIcon"
                                                />
                                            </Box>

                                        </>
                                    )}
                                    {invoicePdfData?.status == "Drafted" && (

                                        <Box
                                            onClick={() => { handleDeletePopup() }}
                                            sx={{ cursor: 'pointer', }}
                                        >
                                            <img
                                                src={deleteIcon}
                                                alt="deleteIcon"
                                            />
                                        </Box>

                                    )}

                                    <Divider
                                        sx={{
                                            margin: "0px !important",
                                            borderColor: "#E2E5E6 !important",
                                            borderWidth: "1px !important",
                                            height: '20px'
                                        }}
                                    />

                                    {["Sent", "Partially Paid", "Saved", "Paid",].includes(invoicePdfData?.status) && (

                                        <Box>
                                            <Box
                                                onClick={handleTourGuideAnchorEle}
                                                id="tourguide"
                                                aria-controls={openTourGuideOptions ? 'tourguide-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openTourGuideOptions ? 'true' : undefined}
                                                sx={{ cursor: 'pointer', }}

                                            >
                                                <img
                                                    src={openTourGuideOptions ? sendEmailOn : sendEmailIcon}
                                                    alt="sendEmail"
                                                />
                                            </Box>
                                            <Menu
                                                anchorEl={anchorTourGuideEl}
                                                MenuListProps={{
                                                    'aria-labelledby': 'tourguide',
                                                }}
                                                id="tourguide-menu"
                                                open={openTourGuideOptions}
                                                onClose={handleCloseTourGuide}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        height: '40px',
                                                        width: '130px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        boxShadow: 'rgba(16, 24, 40, 0.03) 0px 4px 6px -2px, rgba(16, 24, 40, 0.08) 0px 12px 16px -4px',
                                                        border: '1px solid #F2F4F7',
                                                        borderRadius: '8px',
                                                    },
                                                    '& .MuiMenu-list': {
                                                        padding: '0px',
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        mt: "6px",
                                                    }
                                                }}
                                            >
                                                <MenuItem onClick={() => { handleTourGuideClick() }} sx={{ backgroundColor: 'white !important' }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        gap: '15px',
                                                    }}>
                                                        <Text tourText>Send E-mail</Text>
                                                    </Box>
                                                </MenuItem>
                                            </Menu>
                                        </Box>)}

                                    <Box
                                        sx={{ cursor: 'pointer', }}
                                        onClick={() => { invoiceDownloadApi() }}
                                    >
                                        <img
                                            src={downloadIcon}
                                            alt="downloadIcon"
                                        />
                                    </Box>

                                    <Divider
                                        sx={{
                                            margin: "0px !important",
                                            borderColor: "#E2E5E6 !important",
                                            borderWidth: "1px !important",
                                            height: '20px'
                                        }}
                                    />

                                    {invoicePdfData?.send_reminder_email && ["Sent", "Partially Paid", "Saved", "Paid", "Overdue"].includes(invoicePdfData?.status) && (
                                        <>

                                            <Box>
                                                <Box
                                                    onClick={handleBellIconAnchorEle}
                                                    id="bellIcon"
                                                    aria-controls={openBellIconOptions ? 'bellIcon-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openBellIconOptions ? 'true' : undefined}
                                                    sx={{ cursor: 'pointer', }}
                                                >
                                                    <img
                                                        src={openBellIconOptions ? bellIconOn : bellIcon}
                                                        alt="bellIcon"
                                                    />
                                                </Box>
                                                <Menu
                                                    anchorEl={anchorBellIconEl}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'bellIcon',
                                                    }}
                                                    id="bellIcon-menu"
                                                    open={openBellIconOptions}
                                                    onClose={handleCloseBellIcon}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right', // Align the menu's left with the image's left
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right', // Ensure the menu aligns from its left side
                                                    }}

                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            height: '35px',
                                                            width: '200px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            boxShadow: 'rgba(16, 24, 40, 0.03) 0px 4px 6px -2px, rgba(16, 24, 40, 0.08) 0px 12px 16px -4px',
                                                            border: '1px solid #F2F4F7',
                                                            borderRadius: '8px',
                                                            overflow: 'visible',
                                                        },
                                                        '& .MuiMenu-list': {
                                                            padding: '0px',
                                                            borderRadius: '8px',
                                                        },
                                                    }}
                                                    PaperProps={{
                                                        sx: {
                                                            mt: "6px",
                                                        }
                                                    }}

                                                >
                                                    <MenuItem onClick={() => { handleBellIconClick() }} sx={{ backgroundColor: 'white !important', }}>
                                                        <Box >
                                                            <Text smallBoldBlack sx={{ fontSize: '12px !important', }}>Send Email Reminder</Text>
                                                        </Box>
                                                    </MenuItem>

                                                </Menu>
                                            </Box>

                                        </>)}

                                    {(connectedAppsList.isQuickBookIntegrated || connectedAppsList.isZohoIntegrated)
                                        && (allowedStatuses.includes(invoicePdfData?.status) || syncStatuses.includes(invoicePdfData?.status)) && <Box>
                                            <Box
                                                onClick={handleSyncIconAnchorEle}
                                                id="syncIcon"
                                                aria-controls={opensyncIconOptions ? 'syncIcon-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={opensyncIconOptions ? 'true' : undefined}
                                                sx={{ cursor: 'pointer', }}
                                            >
                                                <img
                                                    src={opensyncIconOptions ? syncOn : syncIcon}
                                                    alt="syncIcon"
                                                />
                                            </Box>
                                            <Menu
                                                anchorEl={anchorSyncIconEl}
                                                MenuListProps={{
                                                    'aria-labelledby': 'syncIcon',
                                                }}
                                                id="syncIcon-menu"
                                                open={opensyncIconOptions}
                                                onClose={handleCloseSyncIcon}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}

                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        height: 'auto',
                                                        width: 'auto',
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        boxShadow: 'rgba(16, 24, 40, 0.03) 0px 4px 6px -2px, rgba(16, 24, 40, 0.08) 0px 12px 16px -4px',
                                                        border: '1px solid #F2F4F7',
                                                        borderRadius: '8px',
                                                    },
                                                    '& .MuiMenu-list': {
                                                        padding: '0px',
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        mt: "6px",
                                                    }
                                                }}

                                            >
                                                {connectedAppsList.isQuickBookIntegrated && allowedStatuses.includes(invoicePdfData?.status) && (
                                                    <>
                                                        <MenuItem onClick={() => { handleImportQuickBookClick(); }} sx={{ backgroundColor: 'white !important' }}>
                                                            <Box >
                                                                <Text offBoardHeadFont sx={{ fontSize: '12px !important', lineHeight: '14.4px !important', }} >Import payment status from Quickbooks</Text>
                                                            </Box>
                                                        </MenuItem>
                                                        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />
                                                    </>
                                                )}

                                                {
                                                    connectedAppsList.isQuickBookIntegrated && syncStatuses.includes(invoicePdfData?.status) && (
                                                        <>
                                                            <MenuItem onClick={() => { handleSyncQuickBooksClick() }} sx={{ backgroundColor: 'white !important' }}>
                                                                <Box >
                                                                    <Text offBoardHeadFont sx={{ fontSize: '12px !important', lineHeight: '14.4px !important', }} >Sync To Quickbooks</Text>
                                                                </Box></MenuItem>
                                                            <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />
                                                        </>
                                                    )
                                                }

                                                {connectedAppsList.isZohoIntegrated && allowedStatuses.includes(invoicePdfData?.status) && (
                                                    <>
                                                        <MenuItem onClick={() => { handleImportZohobookClick() }} sx={{ backgroundColor: 'white !important' }}>
                                                            <Box >
                                                                <Text offBoardHeadFont sx={{ fontSize: '12px !important', lineHeight: '14.4px !important', }}>Import payment status from Zohobooks</Text>
                                                            </Box>
                                                        </MenuItem>
                                                        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />
                                                    </>
                                                )}
                                                {connectedAppsList.isZohoIntegrated &&
                                                    syncStatuses.includes(invoicePdfData?.status) && (
                                                        <>
                                                            <MenuItem onClick={() => { handleSyncZohoClick() }} sx={{ backgroundColor: 'white !important' }}>
                                                                <Box >
                                                                    <Text offBoardHeadFont sx={{ fontSize: '12px !important', lineHeight: '14.4px !important', }}>Sync To Zohobooks</Text>
                                                                </Box>
                                                            </MenuItem>
                                                        </>
                                                    )}
                                            </Menu>

                                        </Box>}

                                    {(
                                        (invoicePdfData?.send_reminder_email &&
                                            (["Sent", "Partially Paid", "Saved", "Paid", "Overdue"].includes(invoicePdfData?.status))) ||
                                        connectedAppsList.isQuickBookIntegrated ||
                                        connectedAppsList.isZohoIntegrated
                                    ) &&
                                        (
                                            allowedStatuses.includes(invoicePdfData?.status) ||
                                            syncStatuses.includes(invoicePdfData?.status)
                                        ) && (
                                            <Divider
                                                sx={{
                                                    margin: "0px !important",
                                                    borderColor: "#E2E5E6 !important",
                                                    borderWidth: "1px !important",
                                                    height: '20px'
                                                }}
                                            />

                                        )}
                                </>}
                            </Grid>


                            <Grid container item
                                lg={selectedPayment == "Record Payment" ? 7 : selectedPayment == "Write-Off" ? 6 : invoicePdfData?.status == "Rejected" ? 4.5 : selectedPayment == "Void" ? 6.5 : 3.5}
                                md={selectedPayment == "Record Payment" ? 8 : selectedPayment == "Write-Off" ? 5.5 : invoicePdfData?.status == "Rejected" ? 4.5 : selectedPayment == "Void" ? 8 : 5.5} sm={selectedPayment == "Record Payment" ? 8 : selectedPayment == "Write-Off" ? 6.5 : invoicePdfData?.status == "Rejected" ? 4.5 : selectedPayment == "Void" ? 8 : 5.5} xs={12} alignItems='center' justifyContent={{ xl: "flex-end", lg: "flex-end", md: "start", sm: "end", xs: "end" }}>
                                <Text offBoardHeadFont sx={{ fontSize: '13px !important', padding: '5px !important' }}>Show PDF View</Text>
                                <ToggleSwitch name='is_active' sx={{ height: '24px !important', }} switchChange={(e) => handleToggleOpen(e)} />
                                {toggleOpen ?

                                    "" : <>


                                        {/* <CustomSelect
                                        name='year'
                                        // viewDrop1
                                        viewDrop
                                        scrollTrue={true}
                                        options={textData}
                                        value={1}
                                        onChange={handleSelectChange}
                                    /> */}
                                        {/* <Grid item > */}
                                        {(!recordPaymentDropdown.includes(invoicePdfData?.status) && selectedPayment != '' && invoicePdfData?.status) &&
                                            <Box>
                                                <Box
                                                    onClick={handlePaymentAnchorEle}
                                                    id="syncIcon"
                                                    aria-controls={paymentOptions ? 'payment-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={paymentOptions ? 'true' : undefined}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        padding: '8px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #C7CCD3',
                                                        backgroundColor: '#FFFFFF',
                                                        height: '33px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                                        width: 'max-content'
                                                    }}
                                                >
                                                    <Text smallBlack> {selectedPayment} </Text>
                                                    {paymentOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </Box>
                                                <Menu
                                                    anchorEl={paymentEl}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'syncIcon',
                                                    }}
                                                    id="payment-menu"
                                                    open={paymentOptions}
                                                    onClose={handlePaymentClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            overflow: "auto",
                                                            '&::-webkit-scrollbar': {
                                                                display: "none", // For Chrome, Safari, and Edge
                                                            },

                                                        }
                                                    }}
                                                >
                                                    {recordPaymentStatus.includes(invoicePdfData?.status) && <>
                                                        <MenuItem onClick={() => handlePaymentType('Record Payment')} className={classesAccountMenuStyles.tourMenuItem} sx={{ width: '140px' }}>
                                                            <Box className={classesAccountMenuStyles.ListItem}>
                                                                <Text tourText >Record Payment</Text>
                                                            </Box>
                                                        </MenuItem>

                                                        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important', width: '100%' }} /></>}


                                                    {(writeoffStatusYes.includes(invoicePdfData?.status) && !writeoffStatusNo.includes(invoicePdfData?.status)) && <>
                                                        <MenuItem onClick={() => handlePaymentType('Write-Off')} className={classesAccountMenuStyles.tourMenuItem} sx={{ width: '140px' }}>
                                                            <Box className={classesAccountMenuStyles.ListItem}>
                                                                <Text tourText>Write-Off</Text>
                                                            </Box></MenuItem>
                                                        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} /></>}


                                                    {!voidStatusNo.includes(invoicePdfData?.status) && <>
                                                        <MenuItem onClick={() => handlePaymentType('Void')} className={classesAccountMenuStyles.tourMenuItem} sx={{ width: '140px' }}>
                                                            <Box className={classesAccountMenuStyles.ListItem}>
                                                                <Text tourText>Void</Text>
                                                            </Box>
                                                        </MenuItem>
                                                        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} /></>}

                                                </Menu>

                                            </Box>}
                                        {(invoicePdfData?.status == "Write Off" || invoicePdfData?.status == "Void") && (
                                            <Button
                                                sx={{
                                                    borderRadius: '10px !important',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '2px solid #C7CCD3 !important',
                                                    height: '30px',
                                                    padding: '0px 12px',
                                                    font: '12px Quicksand !important',
                                                    color: '#C7CCD3 !important',
                                                    fontWeight: `${400} !important`,
                                                    textTransform: 'Capitalize !important',
                                                    width: 'max-content'
                                                }}
                                                onClick={handleWriteOff}
                                            >
                                                <Text BlackExtraDark sx={{ fontSize: '13px !important' }}>
                                                    {invoicePdfData?.status === "Write Off" ? "Cancel Write Off" : "Convert to Draft"}
                                                </Text>
                                            </Button>
                                        )}

                                        {/* </Grid> */}
                                    </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {toggleOpen ? <InvoiceShowPdfScreen invoicePdfData={invoicePdfData} /> : <InvoicePdfScreen invoicePdfData={invoicePdfData} openInNewTab={openInNewTab} callRefetch={callRefetch} />}
            </Grid>
            <SwipeableDrawer
                anchor={'right'}
                open={activityOpen}
                onClose={handleActivityClose}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <InvoiceActivityLog
                    activityData={activityData}
                    paymentData={paymentData}
                    value={value}
                    handleChange={handleChange}
                />
            </SwipeableDrawer>
            <ReusablePopup iconHide white openPopup={deleteOpen} setOpenPopup={setDeleteOpen} fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={deletePopupIcon} alt="deletePopupIcon" />
                    </Box>
                    <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text popupHead1>
                            Are You Sure?
                        </Text>
                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                            Do you want to delete this drafted invoice ?
                        </Text>
                    </Box>
                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                        <Grid item lg={5} xs={6} textAlign='right'>
                            <Button onClick={() => { setDeleteOpen(false) }} blackBorder sx={{ fontSize: '13px !important' }}>Cancel</Button>
                        </Grid>
                        <Grid item lg={5} xs={6} textAlign='left'>
                            <LoadingButton onClick={handleDelete} deleteButton sx={{ fontSize: '13px !important' }}>Yes, Delete</LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide white openPopup={writeOff} setOpenPopup={setWriteOff} fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={deletePopupIcon} alt="deletePopupIcon" />
                    </Box>
                    <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text popupHead1>
                            Are You Sure?
                        </Text>
                        <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px !important' }} mx={3} my={1}>
                            {invoicePdfData?.status === "Write Off" ? 'Do you want to cancel write off for this invoice? This will revert the changes applied after write off?' : 'Do you want to convert the status of this invoice from “Void” >> “Draft”? This will revert the changes applied after void?'}
                        </Text>
                    </Box>
                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                        <Grid item lg={5} xs={6} textAlign='right'>
                            <Button onClick={() => { setWriteOff(false) }} blackBorder sx={{ fontSize: '13px !important' }}>Cancel</Button>
                        </Grid>
                        <Grid item lg={5} xs={6} textAlign='left'>
                            <Button finishFilledQuicksand
                                sx={{ fontSize: '13px !important' }} onClick={() => { cancelWriteOff() }} >
                                Yes, Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide white openPopup={approveSend} setOpenPopup={handleCloseRemainder} fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={ApproveSendIcon} alt="ApproveSendIcon" />
                    </Box>
                    {(getApproveButtonLabel() == "Approve & send" || sentEmail) ? <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text blackHeader1>
                            Email Sent Successfully
                        </Text>
                    </Box> : <Box sx={{ margin: '20px 0px 10px 0px' }}>
                        <Text blackHeader1>
                            Email Remainder
                        </Text>
                    </Box>}
                    {(getApproveButtonLabel() == "Approve & send" || sentEmail) ? <Box>
                        <Text mediumGrey2>
                            {`Email with ${invoicePdfData?.invoice_id}  is sent to  ${invoicePdfData?.to_details?.length > 0 ? invoicePdfData.to_details[0]?.name : "--"}`}
                        </Text>
                    </Box> : <Box>
                        <Text mediumGrey2>
                            Email sent successfully.
                        </Text>
                    </Box>}

                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={3} >
                        <Button saveNcontinue className={classes.btnStyles} onClick={handleDone} >Done</Button>
                    </Grid>
                </Box>
            </ReusablePopup>

            <Dialog open={sendReminder} onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    return;
                }
                setSendReminder(false);
            }} fullWidth >
                <DialogContent>
                    <Box>
                        <Text blackHeader1>{invoicePdfData?.status == "Pending Approval" ? 'Email Preview' : 'Remainder Email Preview'}</Text>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <>
                                <Autocomplete
                                    multiple
                                    id="multiple-limit-tags"
                                    freeSolo
                                    options={[]}
                                    getOptionLabel={(option) => option.value}
                                    value={invoiceGetTemplate?.toEmail || []}
                                    inputValue={inputValue}
                                    onInputChange={(_, newInputValue) => setInputValue(newInputValue)}

                                    onKeyDown={handleKeyDown}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            margin="dense"
                                            {...params}
                                            label="Send To"
                                            error={emailError}
                                            helperText={emailError ? "Please enter at least 1 email id to send this email" : ""}
                                            sx={{

                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: '8px !important',
                                                    borderRadius: '8px',
                                                    background: '#ffffff',
                                                    border: emailError ? '1px solid red' : '1px solid #C7CCD3',
                                                    '&:hover': {
                                                        background: '#ffffff',
                                                    },
                                                    '&.Mui-focused': {
                                                        background: '#ffffff',
                                                        border: emailError ? "1px solid red" : "2px solid #0C75EB",
                                                    },
                                                },
                                                "& .MuiFilledInput-root:before": {
                                                    borderBottom: 'none !important',
                                                },
                                                "& .MuiFilledInput-root:after": {
                                                    borderBottom: 'none !important',
                                                },
                                                "& .MuiInputBase-input": {
                                                    font: "14px Quicksand !important",
                                                },
                                                '& .MuiInputBase-root': {
                                                    position: 'relative',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    background: '#FFFFFF !important',
                                                    borderRadius: '8px',
                                                    border: emailError ? "1px solid red" : '1px solid #C7CCD3',
                                                    "&.Mui-focused": {
                                                        border: emailError ? "1px solid red" : `2px solid #0C75EB !important`,
                                                    },
                                                    "& .MuiFormLabel-asterisk": {
                                                        color: "blue !important"
                                                    },
                                                    color: "#181A18",
                                                    font: '10px Quicksand !important',
                                                    fontWeight: "500 !important",
                                                    paddingRight: "80px !important",
                                                    marginRight: '60px !important',
                                                },
                                                endAdornment: (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            right: 8,
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            color: emailError ? "red" : 'gray',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        CC/BCC
                                                    </div>
                                                ),
                                            }}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) => (

                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>

                                            {value.map((option, key) => (
                                                <Chip
                                                    key={key}
                                                    label={option}
                                                    {...getTagProps({ key })}
                                                    deleteIcon={<CancelChip />}
                                                    onDelete={() => handleDeleteChip(option)}
                                                />
                                            ))}
                                        </Box>
                                    )}

                                    sx={{
                                        width: '100%',
                                        marginRight: '60px !important',
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: emailError ? 'red' : 'blue',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                        "&.Mui-focused .MuiInputLabel-outlined": {
                                            color: "#181A18",
                                            fontSize: "10px",
                                            fontFamily: "Quicksand",
                                            fontWeight: 500,
                                            lineHeight: "12px"

                                        },
                                    }}
                                />

                            </>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                pt={0.8}
                                pl={1.5}
                                sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.2)',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Text mediumLabel>
                                    Subject
                                </Text>
                                <RichTextEditor
                                    disabled={true}
                                    value={invoiceGetTemplate?.subject
                                        ? RichTextEditor.createValueFromString(invoiceGetTemplate?.subject, "html")
                                        : RichTextEditor.createValueFromString("", "html")
                                    }
                                    editorClassName={classesMainStyles.editorHeight}
                                    toolbarClassName={classesMainStyles.toolBar}
                                    className={`${classesMainStyles.customEditorStyle} `}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    minHeight: '150px',
                                    overflowY: 'auto',
                                }}
                                dangerouslySetInnerHTML={{ __html: invoiceGetTemplate?.html }}
                            />
                        </Grid>

                    </Grid>
                    <Box mt={2}>
                        <Text blackHeader1>Invoice Attachment</Text>
                    </Box>
                    <Grid container spacing={2} mt={.5}>
                        {/* Uploaded File List in Grid */}
                        {attachments.map((file, index) => (
                            <Grid item key={index}>
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        padding: "8px 12px",
                                        borderRadius: "8px",
                                        border: "1px solid #ccc",
                                        backgroundColor: "#f9f9f9",
                                        width: "auto",
                                    }}
                                >
                                    {/* Left-side icon */}
                                    <Grid item>
                                        <img src={pdfIcon} alt="pdfIcon" />
                                    </Grid>

                                    {/* File name in the center */}
                                    <Grid item xs>
                                        <Text
                                            sx={{
                                                textAlign: "center",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontSize: '12px'
                                            }}
                                            mediumBoldBlack2
                                        >
                                            {file.document_path?.slice(0, 16)} {file.document_path?.length > 16 ? '...' : ''}
                                        </Text>
                                    </Grid>

                                    {/* Right-side delete icon */}
                                    <Grid item>
                                        <img
                                            src={pdfDeleteIcon}
                                            alt="pdfDeleteIcon"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { handleDeleteFile(index) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                        {/* Upload Button */}
                        <Grid item mt={1}>
                            <Button
                                dropdownAdd
                                onClick={handleFileClick}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img src={uploadIcon} alt="uploadIcon" style={{ padding: "8px 12px", }} />
                                <Text mediumBlue600 sx={{ fontWeight: "400" }}>Add Attachment</Text>
                            </Button>

                            {/* Hidden File Input */}
                            <input
                                type="file"
                                multiple
                                hidden
                                ref={fileInputRef}
                                onChange={uploadDocs}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button onClick={() => { handleCancelSendRemainder() }} sx={{ border: `1px solid #171717 !important`, height: '40px', width: '100px' }}><Text blackHeader18 sx={{ fontSize: '13px !important', }}>
                                Cancel
                            </Text></Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton saveExtraSmall onClick={() => { invoicePostSendRemainder() }}><Text largeWhite sx={{ fontSize: '13px !important', }}>Send</Text></LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <SwipeableDrawer
                anchor={'right'}
                open={recordPaymentDrawer}
                onClose={() => { setRecordPaymentDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <RecordPaymentDrawer recordPaymentDrawer={recordPaymentDrawer} setRecordPaymentDrawer={() => { setRecordPaymentDrawer(false) }} apix={invoicePdfData} callRefetch={callRefetch} />
            </SwipeableDrawer>
            <SwipeableDrawer
                anchor={'right'}
                open={voidDrawer}
                onClose={() => { setVoidDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <VoidDrawer voidDrawer={voidDrawer} setVoidDrawer={() => { setVoidDrawer(false) }} apix={invoicePdfData} callRefetch={callRefetch} />
            </SwipeableDrawer>
            <SwipeableDrawer
                anchor={'right'}
                open={writeoffDrawer}
                onClose={() => { setWriteoffDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <WriteoffDrawer writeoffDrawer={writeoffDrawer} setWriteoffDrawer={() => { setWriteoffDrawer(false) }} apix={invoicePdfData} callRefetch={callRefetch} />
            </SwipeableDrawer>
        </Grid>
    )
}
)
export default InvoiceVoice