import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Menu, Backdrop, Badge, Chip, FormControlLabel, Drawer, Slider, Avatar, Breadcrumbs, useTheme, useMediaQuery, Tab } from '@mui/material';
import crossIcon from '../../../../assets/svg/crossIcon.svg';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../../assets/svg/Component87.svg';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import InviteViaLink from '../../../../assets/svg/invite-via-link.svg';
import Onboart from '../../../../assets/svg/onboard.svg';
import { addErrorMsg, addLoader, addLoaderPlanExpired, addSuccessMsg, BlackToolTip, capitalizeAndAddSpace, dateFormat, downloadExcel, NoDataFound, NoPermission, openDocumentInNewtab, removeExpiredLoader, removeLoader } from '../../../../utils/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import infoCircle from '../../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Date from '../../../../components/datePicker/Date';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import {  onCurrencyWithTwoDecimalOnlyChangeCurr, onNumberWithTwoDecimalOnlyChangeWithNegative, validate_alphaNumeric, validate_emptyField } from '../../../../components/Validation';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import clientsApi from '../../../../apis/admin/clients/ClientsApi';
import CommonApi from '../../../../apis/CommonApi';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import Pending from '../../../../assets/employee/Orange-PendingIcon.svg';
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress';
import Userplus from '../../../../assets/svg/clients/user-plus-bold.svg';
import raisedImage from "../../../../assets/images/raised.png"
import approvedImage from "../../../../assets/images/check-circle.png"
import approvalImage from "../../../../assets/images/clock.png"
import totalImage from "../../../../assets/images/dollar-sign.png"
import Deduction from "./Deduction";
import Reimbursement from "./Reimbursement";
import Input from '../../../../components/input/Input';
import verified from '../../../../assets/svg/Component87.svg';
import PayrollApi from '../../../../apis/admin/payroll/ExpenseManagement';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import approvalIcon from '../../../../assets/svg/payroll/approvalIcon.svg';
import successIcon from '../../../../assets/svg/payroll/payrollApproved.svg';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { filterStorage } from '../../../../utils/FilterStorage';
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import menu from '../../../../assets/svg/clients/client-menu-withoutbg.svg';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Nodata from '../../../../assets/svg/nodataActivity.svg';
import timelineIcon from '../../../../assets/svg/timelineIcon.svg';

import DeleteDeductionPopup from './DeductionPopup';
import ExpenseManagement from '../../../../apis/admin/payroll/ExpenseManagement';
import ExpenseManagementApi from '../../../../apis/configurations/expense/ExpenseManagementApi';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    fontSize: "14px",
    fontWeight: 600,
    color: '#262626',
    textAlign: 'left'
}));

const StyledTableRowBody = styled(TableRow)(({ theme }) => ({
    margin: "4px 0px !important",
    borderRadius: "20px !important",
    cursor: 'pointer',
    boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05) !important",
    "& td, & th": {
        minHeight: '65px',
        border: "none", // Removes all borders
    },
    "&:hover": {
        boxShadow: "0px 0px 15px 1px #cce9ffeb !important",
        transition: "all .3s ease",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));



export default function ExpenseDashboard() {

    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const param = new URLSearchParams(window.location.search);
    const tab = param.get('tab');
    const getStatus = param.get('status');
    const id = param.get('id');
    const urlID = (LocalStorage.getNotificationId() !== null && LocalStorage.getNotificationId() !== '') ? LocalStorage.getNotificationId() : (id !== '' && id !== 'undefined' && id !== '') ? id : ''
    const classes = PlacementDashboardStyles();
    const navigate = useNavigate();
    const [expand, setExpand] = useState('')// eslint-disable-next-line

    const [placements, setPlacements] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [open, setOpen] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [pendingApproval, setPendingApproval] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [activeState, setActiveState] = useState('reimbursement');
    const [openRejection, setOpenRejection] = useState(false);
    const [openApproval, setOpenApproval] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [status, setStatus] = useState('');
    const [statusErrors, setStatusErrors] = useState({
        approved_amount: '',
        reason_for_rejection: ''
    });
    const [viewData, setViewData] = useState({
        id: '',
        approved_amount: '',
        bank_name: '',
        date_of_expense: '',
        display_name: '',
        documents: [
            {
                id: '',
                document_name: ''
            }
        ],
        employee_reference_id: '',
        expense_effect_on: '',
        expense_type: '',
        last_digit_of_bank_account: '',
        name: '',
        pay_date: '',
        pay_period: '',
        payee_name: '',
        payment_mode: '',
        profile_picture_url: '',
        raised_amount: '',
        raised_on: '',
        remarks: '',
        status: '',
        transaction_id: '',
        visa_type: '',
        category_type: '',
        e_verified: ''
    });
    const [state, setState] = useState({
        approved_amount: '',
        reason_for_rejection: ''
    });
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        deductionType: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc",
        expense_type_ids: [],
        raised_by: [],
        expense_effect_on: [],
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        expenses: [],
        entity_type: activeState == 'reimbursement' ? 1 : 2,
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
    })
    const [openPopUp, setOpenPopup] = useState(false);
    const [activityData, setActivityData] = useState([])

    // const activityData = [
    //     {
    //         "activity_name": "User Profile > General Details",
    //         "created_at": "03:54 PM 13/02/2025",
    //         "action_by": "Rama Krishna",
    //         "change_log": [
    //             "Employee Name > Charan K is created"
    //         ]
    //     }
    // ]

    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const [isDeleted, setIsDeleted] = useState(null)
    const [isEditable, setIsEditable] = useState(null);
    const openActions = Boolean(actionAnchorEl);
    const handleCloseAction = () => {
        setActionAnchorEl(null);
    };
    const handleClickActionMenu = (event, item) => {
        // if ((LocalStorage.getUserData().super_admin && LocalStorage.getUserData().admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true)))) {
        setActionAnchorEl(event.currentTarget);
        setViewData(item);
        setIsDeleted(item.is_deleted);
        setIsEditable(item.is_editable);
        // activityLogAPI(item.id);
        // }
        // else {
        //     addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        // }
    };


    const activityLogAPI = (id) => {

        ExpenseManagementApi.activityLog(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setActivityData(res.data.data);
            } else {
                setActivityData([]);
            }
        })
    }


    const [actionAnchorDrawerEl, setActionAnchorDrawerEl] = useState(null);
    const [editAction, setEditAction] = useState(false);
    const [placeSelected, setPlaceSelected] = useState(null)
    const openActionsDrawer = Boolean(actionAnchorDrawerEl);

    const handleCloseActionDrawer = () => {
        setActionAnchorDrawerEl(null)
    }
    const handleClickActionMenuDrawer = (event) => {
        setActionAnchorDrawerEl(event.currentTarget)
    }

    const handleEditAction = (e, item) => {
        setPlaceSelected(viewData)
        setEditAction(true);
        handleCloseAction()
        setActionAnchorDrawerEl(null);
        setOpenPopup(true);
    }

    const drawerEditHandle = () => {
        setOpenPopup(true);
        setEditAction(true);
        setActionAnchorDrawerEl(null);
        setOpenView(false);
        setValueTabs('1')
        setPlaceSelected(viewData)

    }

    const handleOpenApprove = () => {
        setDeleteDeduction(true);
        setActionAnchorDrawerEl(null);
        setActionAnchorEl(null);
        setOpenView(false);
        setValueTabs('1')

    }
    const [valueTabs, setValueTabs] = useState('1');

    const handleChange = (event, newValue) => {
        setValueTabs(newValue);
    };
    const [deleteDeduction, setDeleteDeduction] = useState(false);

    const handleTab = (value) => {
        // setLoading(true)
        setActiveState(value)
        clearAllFilter()
        filterData.expense_type_ids = [];
        filterData.raised_by = [];
        filterData.expense_effect_on = [];
        filterData.status = []
        filterData.raised_amount_from = ''
        filterData.raised_amount_to = ''
        filterData.date_of_expense_from = ''
        filterData.date_of_expense_to = ''
        filterData.approved_amount_from = ''
        filterData.approved_amount_to = ''
        filterData.expenses = []
        filterData.search = ''
        filterData.limit = 10
        filterData.page= 1
        setFilterData({ ...filterData });
        filterStorage.setFilter("expense", { ...filterData, entity_type: value == 'reimbursement' ? 1 : 2, 'pendingApproval': pendingApproval ? true : false });
    }

    const accordianHandler = () => {
        setOpenView(true);
        handleCloseAction();
        activityLogAPI(viewData.id);
    }

    
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    
    const deductedFromDropdown = [
        {
            "id": 2,
            "value": "Balancesheet"
        },
        {
            "id": 1,
            "value": "Payroll"
        }
    ]
    const cardsJson = [
        {
            name: 'Total Reimbursements Raised',
            imgPath: raisedImage,
            mainKey: 'total_reimbursement_raised',
            color: '#E7F2FD'
        },
        {
            name: 'Total Reimbursements Approved',
            imgPath: approvedImage,
            mainKey: 'total_reimbursement_approved',
            color: '#EAF9EB'
        },
        {
            name: 'Total Reimbursements Pending Approval',
            imgPath: approvalImage,
            mainKey: 'total_reimbursement_pending_approval',
            color: '#F3F0FF'
        },
        {
            name: 'Total Reimbursed Amount',
            imgPath: totalImage,
            mainKey: 'total_reimbursed_amount',
            color: '#FFFBE1'
        }
    ]

    const DeductionCardsJson = [
        {
            name: 'Total Deductions',
            imgPath: raisedImage,
            mainKey: 'total_deductions',
            color: '#E7F2FD'
        },
        {
            name: 'Total Deductions Recovered',
            imgPath: approvedImage,
            mainKey: 'total_deductions_recovered',
            color: '#EAF9EB'
        },
        {
            name: 'Total Deductions Due',
            imgPath: approvalImage,
            mainKey: 'total_deduction_due',
            color: '#F3F0FF'
        }
    ]

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        deductionType: [],
        status: [],
        recruiter: [],
        timesheet_cycle: [],
        expense_type_ids: [],
        raised_by: [],
        expense_effect_on: [],
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        date_of_expense_from: "",
        date_of_expense_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount_from: "",
        approved_amount_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [deductionTypeDropdown, setDeductionType] = useState([]);
    const [reimbursementTypeDropdown, setReimbursementType] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);
    const [approver, setApprover] = useState('');
    const [cardDetails, setCardDetails] = useState([]);
    const [filterAmount, setFilterAmount] = useState({ raised_amount: { max: 0, min: 0 }, approved_amount: { max: 0, min: 0 } });
    const statusList = [{
        id: "Pending",
        value: "Pending"
    },
    {
        id: "Approved",
        value: "Approved"
    },
    {
        id: "Rejected",
        value: "Rejected"
    }
    ]

    useEffect(() => {

        const appliedFilters = filterStorage.getFilter('expense');
        if (appliedFilters && appliedFilters !== null && appliedFilters !== undefined) {
            setFilterData(appliedFilters);
            getAllPlacements(appliedFilters);
            if (appliedFilters?.entity_type) {
                setActiveState(appliedFilters?.entity_type === 1 ? 'reimbursement' : 'deductions')
            }
            if (appliedFilters?.pendingApproval) {
                setPendingApproval(true);
            }
        } else {
            const requestData = { ...filterData }
            requestData.entity_type = activeState == "reimbursement" ? 1 : 2
            setFilterData({ ...requestData })
            getAllPlacements(requestData);
        }

        cardDetailsApi(activeState)
        // eslint-disable-next-line
    }, [activeState])


    useEffect(() => {
        getPlanCheck();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            deductionTypeDropdownApi();
            employmentList()
            reimbursementTypeDropdownApi()
        }
        if ((LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'reimbursement') || (tab !== '' && tab == 'Reimbursements')) {
            setActiveState('reimbursement');
            if ((LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'reimbursement > pending') || (urlID !== '' && urlID !== 'undefined' && urlID !== null)) {
                setPendingApproval(true);
                filterData['status'] = ["Pending"]
                setFilterData({ ...filterData })
                getAllPlacements(filterData)
            }
        }
        if ((LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'deduction') || (tab !== '' && tab == 'Deductions')) {
            setActiveState('deductions');
        }
        if ((getStatus !== '' && getStatus == 'Pending') || (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() == 'reimbursement > pending')) {
            setPendingApproval(true);
            filterData['status'] = ["Pending"]
            setFilterData({ ...filterData })
            getAllPlacements(filterData)
        }
        // eslint-disable-next-line
    }, []);

    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }

    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } });
                    filterStorage.clearAllFilters();
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const deductionTypeDropdownApi = () => {
        clientsApi.deductionDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductionType(res.data.data);
            }
        })
    }

    const reimbursementTypeDropdownApi = () => {
        clientsApi.reimbursementDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementType(res.data.data);
            }
        })
    }


    const handleDownloadExport = () => {
        if (placements.length == 0) {
            addErrorMsg('No data to export');
            return false;
        }
        addLoader(true);
        filterData.request_id = LocalStorage.uid()
        PayrollApi.downLoadExpenseExcel(filterData).then((response) => {
            removeLoader();
            if (response?.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `ExpenseMangement_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const cardDetailsApi = (args) => {
        let emp_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
        PlacementApi.getCarDetailListing(args ? args == 'reimbursement' ? 1 : 2 : activeState == 'reimbursement' ? 1 : 2, emp_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setCardDetails(res.data.data);
            }
        })
    }


    const getAllPlacements = (filter) => {
        if (placements.length < 6) {
            setLoading(true);
        }
        PlacementApi.getExpenseListing(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setDrawer(null);
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
                setApprover(response.data.is_approver);
                setFilterAmount(response.data.filters)
            } else {
                setLoading(false);
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })

    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        getAllPlacements(filterData);
        filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllPlacements(filterData);
        filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
    }

    const handleDeleteChip = (id, name) => {
        if (name === "approved_amount" || name === "date_of_expense" || name === "raised_amount") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            // if (filterState[name].includes(id)) {
            //     filterState[name].splice(filterState[name].findIndex(item => item === parseInt(id)), 1)
            // }
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        var finalValue = value
        if (e.target.name != "status") {
            finalValue = Number(value)
        }
        const index = updatedArray.findIndex(item => item === finalValue);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(finalValue); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }
    // eslint-disable-next-line
    const handleChangeRecruiters = (e) => {
        e.preventDefault();
        const { name } = e.target;
        var val = Number(e.target.value)
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === val);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(val); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });

        // filterState.timesheet_cycle = [];
        // filterState.deductionType = []
        // filterState.recruiter = [];
        // filterState.start_date = [];
        // filterState.end_date = [];
        // setFilterState({ ...filterState });
        setFilterState(prevState => ({
            ...prevState,
            expense_type_ids: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            status: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_by: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            expense_effect_on: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            date_of_expense_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            date_of_expense_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            raised_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            approved_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            approved_amount_to: '' // Update the state with the new array
        }));
        // filterData.timesheet_cycle = [];
        // filterData.deductionType = []
        // filterData.recruiter = [];
        // filterData.start_date = [];
        // filterData.end_date = [];
        // setFilterData({ ...filterData });
        // setDrawer(null);
        // getAllPlacements(filterData);
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const dateChange = (e, keyName) => {
        // let date = e.$d;
        // let event = { target: { name: keyName, value: date } }
        // datesState[keyName] = moment(date).format(dateFormat());
        // setDatesState({ ...datesState }, handleValidate(event));

        // if (datesState.start_range_one !== "" && datesState.end_range_one !== "") {
        //     filterState.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     setFilterState({ ...filterState });
        //     // filterData.start_date = [datesState.start_range_one, datesState.end_range_one];
        //     // setFilterData({ ...filterData });
        // }
        // if (datesState.start_range_two !== "" && datesState.end_range_two !== "") {
        //     filterState.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     setFilterState({ ...filterState });
        //     // filterData.end_date = [datesState.start_range_two, datesState.end_range_two];
        //     // setFilterData({ ...filterData });
        // }
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleCloseView = () => {
        setOpenView(null);
        setValueTabs('1')
        statusErrors['approved_amount'] = ''
        setStatusErrors({ ...statusErrors })
        setState({ ...state, approved_amount: '' })
    }

    const handleApplyFilters = () => {
        if (filterState.date_of_expense_from != "" && filterState.date_of_expense_to == "") {
            datesError.date_of_expense_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.date_of_expense_from == "" && filterState.date_of_expense_to != "") {
            datesError.date_of_expense_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") {
            if (moment(filterState.date_of_expense_from, dateFormat()).isSameOrBefore(moment(filterState.date_of_expense_to, dateFormat()))) {
                filterData.date_of_expense_from = filterState.date_of_expense_from;
                filterData.date_of_expense_to = filterState.date_of_expense_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.date_of_expense_from = '';
            filterData.date_of_expense_to = '';
        }
        if (filterState.raised_amount_from != "" && filterState.raised_amount_to == "") {
            datesError.raised_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.raised_amount_from == "" && filterState.raised_amount_from != 0) && filterState.raised_amount_to != "") {
            datesError.raised_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") {
            if (filterState.raised_amount_from < filterState.raised_amount_to) {
                filterData.raised_amount_from = filterState.raised_amount_from;
                filterData.raised_amount_to = filterState.raised_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.raised_amount_from = '';
            filterData.raised_amount_to = '';
        }
        if (filterState.approved_amount_from != "" && filterState.approved_amount_to == "") {
            datesError.approved_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.approved_amount_from == '' && filterState.approved_amount_from != 0) && filterState.approved_amount_to != "") {
            datesError.approved_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") {
            if (filterState.approved_amount_from < filterState.approved_amount_to) {
                filterData.approved_amount_from = filterState.approved_amount_from;
                filterData.approved_amount_to = filterState.approved_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.approved_amount_from = '';
            filterData.approved_amount_to = '';
        }
        filterData.expense_type_ids = filterState.expense_type_ids;
        filterData.raised_by = filterState.raised_by;
        filterData.expense_effect_on = filterState.expense_effect_on;
        filterData.status = filterState.status
        setFilterData({ ...filterData });
        addLoader(true)
        getAllPlacements({ ...filterData, limit: 10, page: 1 });
        filterStorage.setFilter("expense", { ...filterData, limit: 10, page: 1, 'pendingApproval': pendingApproval ? true : false });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [openStatus, setOpenStatus] = useState(Boolean(anchorEl1));
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleStatusOpen = (event) => {
        setAnchorEl1(event.currentTarget);
        setOpenStatus(!openStatus)
    }

    const handleStatusClose = (param) => {
        setAnchorEl1(null);
        setOpenStatus(!openStatus)
        // setOpenBacKLoader(true);
        if (param === undefined) {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.status = [];
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
            filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
        } else {
            filterData.limit = 10;
            filterData.page = 1;
            filterData['status'] = [param]
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
            filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
        }
    }

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setOpenBacKLoader(true);
        filterData.sort_column = sort_column;
        filterData.sort_order = sort_order;
        setFilterData({ ...filterData });
        setLoading(true);
        getAllPlacements({ ...filterData });
        // setOpenBacKLoader(false);
        filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllPlacements(filterData);
        filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") {
            filterState.date_of_expense_from = filterData.date_of_expense_from;
            filterState.date_of_expense_to = filterData.date_of_expense_to;
            // setSelectedFilter(1);
        } else {
            filterState.date_of_expense_from = '';
            filterState.date_of_expense_to = '';
        }
        if (filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") {
            filterState.raised_amount_from = filterData.raised_amount_from;
            filterState.raised_amount_to = filterData.raised_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.raised_amount_from = '';
            filterState.raised_amount_to = '';
        }
        if (filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "") {
            filterState.approved_amount_from = filterData.approved_amount_from;
            filterState.approved_amount_to = filterData.approved_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.approved_amount_from = '';
            filterState.approved_amount_to = '';
        }
        if (filterData.expense_type_ids.length > 0) {
            if (activeState == 'reimbursement') {
                setSelectedFilter(6);
            }
            else {
                setSelectedFilter(1);
            }

        }
        if (filterData.recruiter.length > 0) {
            setSelectedFilter(4);
        }
        if (filterData.timesheet_cycle.length > 0) {
            setSelectedFilter(5);
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycle.length == 0 && filterData.deductionType.length == 0 && filterData.recruiter.length == 0) {
            setSelectedFilter(null);
        }
        filterState.deductionType = filterData.deductionType;
        filterState.expense_type_ids = filterData.expense_type_ids
        filterState.raised_by = filterData.raised_by
        filterState.expense_effect_on = filterData.expense_effect_on
        filterState.status = filterData.status
        filterState.recruiter = filterData.recruiter;
        filterState.timesheet_cycle = filterData.timesheet_cycle;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleSliderChange = (event, newValue) => {
        filterState.approved_amount_from = newValue[0]
        filterState.approved_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeRaised = (event, newValue) => {
        filterState.raised_amount_from = newValue[0]
        filterState.raised_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleInputChange = (index, value, name, type) => {
        let numericValue;

        const baseName = name ? name.replace(/_from|_to$/, '') : '';
        const limitValue = type === "min"
            ? filterAmount[baseName].min
            : filterAmount[baseName].max;

        const maxValue = filterAmount[baseName].max;

        if (value === '-' || value === '' || value.endsWith('.')) {
            numericValue = value;
        } else if (!isNaN(Number(value))) {
            numericValue = Number(value);

            if (type === "min" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "min" && numericValue < limitValue) {
                numericValue = Math.max(Math.floor(numericValue / 10), limitValue); // Ensure it stays above minValue
            }
            if (type === "max" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "max" && numericValue > limitValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), limitValue); // Ensure it stays below maxValue
            }
        } else {
            // Fallback to empty string for invalid input
            numericValue = '';
        }

        filterState[name] = numericValue;
        setFilterState({ ...filterState });

    };

    const handleFilterDrawerClose = () => {
        filterState.expense_type_ids = [];
        filterState.raised_by = []
        filterState.expense_effect_on = [];
        filterState.date_of_expense_from = '';
        filterState.date_of_expense_to = '';
        filterState.raised_amount_from = '';
        filterState.raised_amount_to = '';
        filterState.approved_amount_from = '';
        filterState.approved_amount_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const pendingApprovalStatus = (args) => {
        filterData.expense_type_ids = [];
        filterData.raised_by = [];
        filterData.expense_effect_on = [];
        filterData.status = ["Pending"]
        filterData.raised_amount_from = ''
        filterData.raised_amount_to = ''
        filterData.date_of_expense_from = ''
        filterData.date_of_expense_to = ''
        filterData.approved_amount_from = ''
        filterData.approved_amount_to = ''
        filterData.expenses = []
        filterData.search = ''
        if (args == 'home') {
            filterData.status = []
            clearAllFilter()
            setPendingApproval(false)
        }
        else {
            filterData.status = ["Pending"]
            setFilterState(prevState => ({
                ...prevState,
                status: ["Pending"] // Update the state with the new array
            }));
            setPendingApproval(true)
        }
        setFilterData({ ...filterData });
        getAllPlacements(filterData);
        filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': args == 'home' ? false : true });
    }

    const handleChangePendingApproval = (e, args, val) => {
        setOpenView(false);
        if (args == 'all') {
            if (placements.filter((item)=>item.is_deleted == false).length == filterData.expenses.filter((item)=>item.is_deleted == false).length) {
                setFilterData({ ...filterData, expenses: [] });
                filterStorage.setFilter("expense", { ...filterData, expenses: [], 'pendingApproval': pendingApproval ? true : false });
            }
            else {
                const ids = placements.filter(item =>item.is_deleted == false);
                setFilterData({ ...filterData, expenses: ids });
                filterStorage.setFilter("expense", { ...filterData, expenses: ids, 'pendingApproval': pendingApproval ? true : false });
            }
        }
        else {
            const updatedArray = [...filterData['expenses']]; // Create a new array
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item.id === finalValue);

            if (index !== -1) {
                updatedArray.splice(index, 1); // Remove the object if it exists
            } else {
                // Find the object in the original array by its id and add it
                const newItem = placements.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem); // Add the full object if it doesn't exist
                }
            }


            setFilterData(prevState => ({
                ...prevState,
                expenses: updatedArray // Update the state with the new array
            }));
            filterStorage.setFilter("expense", { ...filterData, 'pendingApproval': pendingApproval ? true : false });
        }
    }

    const changeHandler = (e) => {
        if (e.target.name == 'approved_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value
            }
            setState({ ...state }, handleStatusValidate(e));
        } else {
            state[e.target.name] = e.target.value
            setState({ ...state }, handleStatusValidate(e));
        }
    }

    const handleStatusValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'approved_amount':
                statusErrors.approved_amount = validate_emptyField(input.value);
                if (statusErrors.approved_amount == '' && input.value == '$0') {
                    statusErrors.approved_amount = 'The approved amount should be greater than 0'
                }
                if (input.value == '$') {
                    statusErrors.approved_amount = 'This field is required'
                }
                break;
            case 'reason_for_rejection':
                statusErrors.reason_for_rejection = validate_alphaNumeric(input.value)
                break;
            default:
                break;
        }
        setStatusErrors({ ...statusErrors });
    }

    const approveorRejectApi = (args) => {
        let expensesData = filterData.expenses
        let finalData = expensesData.map(item => ({
            ...item,
            status: "Rejected",
            reason_for_rejection: state.reason_for_rejection
        }));
        const data = {
            request_id: LocalStorage.uid(),
            expenses: filterData.expenses.length > 0 ? finalData : [
                {
                    id: viewData.id,
                    status: status,
                    approved_amount: state.approved_amount,
                    reason_for_rejection: state.reason_for_rejection,
                }
            ]
        }
        PayrollApi.updateStatus(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                handleCloseDrawer();
                if (args != 'reject') {
                    setOpenSuccess(true);
                }
                setOpenRejection(false);
                setOpenApproval(false);
                getAllPlacements(filterData);
                setOpenView(false);
                setValueTabs('1');
            } else {
                addErrorMsg(res.data.message);
                getAllPlacements(filterData)
            }
        })
    }

    const handleCloseDrawer = () => {
        setOpenView(false);
        setValueTabs('1')
        setOpenRejection(false);
        setState({ ...state, approved_amount: '', reason_for_rejection: '' })
    }

    const handleButtons = (args) => {
        if (args == 'ApproveAll') {
            setOpenApproval(true);
        }
        else if (args == 'Approved') {
            if (state.approved_amount == '') {
                statusErrors['approved_amount'] = 'This field is required';
                setStatusErrors({ ...statusErrors });
                addErrorMsg(ErrorMessages.genericMessage)
            } else {
                setStatus(args)
                setOpenApproval(true);
            }
        }
        else {
            setStatus('Rejected')
            setOpenRejection(true);
            setState({ ...state, reason_for_rejection: '' })
        }
    }

    const approveAPI = () => {
        let expensesData = filterData.expenses
        let finalData = expensesData.map(item => ({
            ...item,
            status: "Approved",
            approved_amount: item.raised_amount
        }));
        const data = {
            request_id: LocalStorage.uid(),
            expenses: finalData
        }
        PayrollApi.updateStatus(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                filterData.status = ['Pending']
                setOpenSuccess(true);
                setOpenRejection(false);
                setOpenApproval(false);
                getAllPlacements(filterData);
                setOpenView(false);
                setValueTabs('1');
            } else {
                filterData.status = ['Pending']
                getAllPlacements(filterData)
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleRejectButtons = (args) => {
        if (args == 'Approved') {
            setStatus('Rejected');
            if (state.reason_for_rejection == '') {
                statusErrors['reason_for_rejection'] = 'This field is required';
                setStatusErrors({ ...statusErrors });
                addErrorMsg(ErrorMessages.genericMessage)
            } else {
                setStatus('Rejected');
                approveorRejectApi('reject')
            }
        } else {
            setStatus('Rejected');
            setOpenRejection(false);
            setOpenView(false);
            setValueTabs('1');
        }

    }

    const handleApproveButtons = (args) => {
        setStatus('Approved');
        if (args == 'Approved') {
            if (filterData.expenses.length > 0) {
                approveAPI()
            }
            else {
                setStatus('Approved');
                approveorRejectApi(args);
            }
        } else {
            if (filterData.expenses.length == 0) {
                setStatus('Rejected');
            }
            setOpenApproval(false);
            setOpenView(false);
            setValueTabs('1');
        }
    }

    const openInNewTab = (args, documentUrl) => {
        openDocumentInNewtab('expense-management', args.document_path)
    }

    const theme = useTheme();

    // Use useMediaQuery to evaluate breakpoints
    const isXs = useMediaQuery(theme.breakpoints.down("sm")); // Extra-small screens
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // Small screens
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // Medium screens
    const isLg = useMediaQuery(theme.breakpoints.up("lg")); // Large screens


    const getLength = (args) => {
        let maxLength = args || 14;
        if (isXs) maxLength = 4;
        else if (isSm) maxLength = 6;
        else if (isMd) maxLength = 10;
        else if (isLg) maxLength = args;
        return maxLength;
    };

    const imgWidth = isXs ? "30px" : isSm ? "40px" : isMd ? "50px" : "60px";
    const imgHeight = imgWidth;
    const avatarWidth = imgWidth;
    const avatarHeight = imgWidth;
    const size = isXs ? 40 : isSm ? 48 : isMd ? 54 : 62; // Adjust overall size

    const searchWidth = isXs
        ? "200px" // Extra small screens
        : isSm
            ? "250px" // Small screens
            : isMd
                ? "300px" // Medium screens
                : "368px"; // Large screens

    const handleCancelDeduction = () => {
        setDeleteDeduction(false);
    }

    const handleApproveDeduction = () => {
        const data = {
            id: viewData.id,
            request_id: LocalStorage.uid(),
        }
        ExpenseManagement.deleteTransaction(data).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                setDeleteDeduction(false);
                getAllPlacements(filterData)
            }
            else {
                addErrorMsg(res.data.message);
            }
        })
    }




    return (
        <>
            {

                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                    <Grid container
                        sx={{
                            pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                            pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
                        }}
                    >
                        {
                            pendingApproval ?
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Text mediumGrey sx={{ cursor: 'pointer' }} onClick={() => { pendingApprovalStatus('home') }}>Expense Management</Text>
                                        <Text mediumBlack>Pending Reimbursements</Text>
                                    </Breadcrumbs>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Text mediumViewAmt>Expense Management</Text>
                                </Grid>
                        }

                        {
                            !pendingApproval ?
                                <Grid item xs={12} container pt={2}>
                                    <Grid item container xl={6} lg={6} md={6} sm={5} xs={12}>
                                        {/* <Grid item xl={3.7} lg={6} md={8} sm={9}> */}
                                            <Box className={classes.tabBg} sx={{width:"fit-content !important"}}>
                                                <Box className={activeState == 'reimbursement' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('reimbursement')}>
                                                    {
                                                        activeState == 'reimbursement' ?
                                                            <Text largeWhite400>Reimbursements</Text> :
                                                            <Text blackHeader>Reimbursements</Text>
                                                    }
                                                </Box>
                                                <Box className={activeState == 'deductions' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('deductions')}>
                                                    {
                                                        activeState == 'deductions' ?
                                                            <Text largeWhite400>Deductions</Text> :
                                                            <Text blackHeader>Deductions</Text>
                                                    }
                                                </Box>
                                            </Box>
                                        {/* </Grid> */}
                                    </Grid>
                                    <Grid item container xl={6} lg={6} md={6} sm={7} xs={12} justifyContent={'flex-end'} pr={1} spacing={2} sx={{ paddingTop: { xs: '10px' } }}>
                                        {
                                            activeState == 'reimbursement' && LocalStorage.getUserData().admin_login && approver ?
                                                <Grid item xs={6} sm={4} md={6} lg={6} >
                                                    <Button sx={{ width: "100% !important", height: '45px !important' }} outlineBlue onClick={() => { pendingApprovalStatus('status') }}>Pending for Approval</Button>
                                                </Grid>
                                                : ''
                                        }
                                        <Grid item xs={6}>
                                            {
                                                (((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?

                                                    activeState != "reimbursement" && !LocalStorage.getUserData().admin_login ? ''
                                                        :
                                                        <Button sx={{ width: "100%" }} addButton onClick={() => { setOpenPopup(true); setEditAction(false) }}>
                                                            <img src={Userplus} alt='plus' className={classes.addIcon} />
                                                            &nbsp;Add {activeState == "reimbursement" ? 'Reimbursement' : activeState == "deductions" ? 'Deduction' : ""}
                                                        </Button>


                                                    :
                                                    (LocalStorage.getUserData().admin_login && (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))) ?
                                                        <>
                                                            {
                                                                activeState == "reimbursement" ?
                                                                    < Button sx={{ width: "100%" }} addButton onClick={() => { setOpenPopup(true); setEditAction(false) }}>
                                                                        <img src={Userplus} alt='plus' className={classes.addIcon} />
                                                                        &nbsp;Add Reimbursement
                                                                    </Button> : ''
                                                            }
                                                        </> : ''
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid> : ''
                        }
                        {
                            !pendingApproval ?
                                <Grid item container xs={12} pt={3} gap={2}>
                                    <>
                                        {
                                            (activeState == 'reimbursement' ? cardsJson : DeductionCardsJson).map((item) => (
                                                <Grid item container lg={activeState == 'reimbursement' ? 2.8 : 3.8} md={activeState == 'reimbursement' ? 2.78 : 3.8} sm={activeState == 'reimbursement' ? 2.72 : 3.75} sx={{ backgroundColor: item.color, borderRadius: '12px ' }} alignContent={'center'} >
                                                    <Grid item xs={12}>
                                                        <Box p={2} minHeight={'110px'}>
                                                            <Grid item xs={12}>
                                                                <Text blackHeader1 sx={{ fontSize: { md: '13.5px !important', sm: '10px !important' } }}>{item.name}</Text>
                                                            </Grid>
                                                            <Box p={2} height={'2vh'}></Box>
                                                            <Grid item container xs={12}>
                                                                <Grid item xl={4} sm={5}>
                                                                    <Stack direction={'row'}>
                                                                        <img src={item.imgPath} alt={item.name} />
                                                                        {
                                                                            (activeState == 'reimbursement' && item.mainKey != "total_reimbursed_amount") ?
                                                                                <Text largeBlack22>&nbsp; {cardDetails[item.mainKey]?.count ? cardDetails[item.mainKey]?.count : '0'}</Text> : ''
                                                                        }
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xl={8} sm={7} container justifyContent={'flex-end'}>
                                                                    {
                                                                        activeState == 'reimbursement' ?
                                                                            <Text largeBlack22>$&nbsp; {cardDetails[item.mainKey]?.amount ? cardDetails[item.mainKey]?.amount : '0'}</Text> :
                                                                            <Text largeBlack22>$&nbsp; {item.mainKey == 'total_deductions' ? cardDetails.total_deduction : item.mainKey == 'total_deductions_recovered' ? cardDetails.total_deduction_recovered : item.mainKey == 'total_deduction_due' ? cardDetails.total_deduction_due : ''}</Text>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </>
                                </Grid>
                                : ''}
                        <Drawer
                            anchor={"right"}
                            open={openPopUp}
                            className={classes.miviDrawer}
                            onClose={() => { setOpenPopup(false) }}
                            sx={{
                                width: { sm: '65% !important', md: '55% !important' },
                                '& .MuiDrawer-paper': {
                                    width: { xs: '100% !important', sm: '65% !important', md: '55% !important' },
                                },
                            }}
                        >
                            {
                                activeState == "deductions" ?
                                    <Deduction placeSelected={placeSelected} openPopUp={openPopUp} editAction={editAction} setOpenPopup={setOpenPopup} getAllPlacements={getAllPlacements} cardDetailsApi={cardDetailsApi} filterData={filterData} />
                                    :
                                    <Reimbursement placeSelected={placeSelected} openPopUp={openPopUp} editAction={editAction} setOpenPopup={setOpenPopup} getAllPlacements={getAllPlacements} cardDetailsApi={cardDetailsApi} filterData={filterData} />
                            }

                        </Drawer>
                    </Grid >
                    : ''
            }
            {

                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                    <Box className={classes.flexBox} sx={{
                        paddingLeft: {
                            xs: "0px",
                            sm: "0px",
                            md: "65px",
                        },
                    }}>

                        <Box sx={{ width: "92%" }} >
                            <Box style={{ padding: "20px 10px 10px 10px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <Box sx={{
                                            height: "44px", border: "1.5px solid #E2E5E6", width: searchWidth, borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                        }}>
                                            <input
                                                type="text"
                                                value={filterData.search}
                                                className={classes.EmployeesSearchInput}
                                                onChange={handleSearch}
                                                placeholder={LocalStorage.getUserData().admin_login ? "Search by Name / ID" : "Search by Name"}
                                            />
                                            <Box sx={{ paddingRight: "15px !important" }}>
                                                {
                                                    filterData.search !== "" ?
                                                        <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                        :
                                                        <img src={Search} alt="Search" />
                                                }
                                            </Box>
                                        </Box>
                                    </div>
                                    {
                                        ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                                    <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                        <Text offBoardBodyFont>
                                                            Count
                                                            <HtmlTooltip
                                                                placement="bottom"
                                                                arrow
                                                                title={
                                                                    <React.Fragment>
                                                                        <Box>
                                                                            <Typography className={classes.profileTooltipText}>
                                                                                Total {LocalStorage.getUserData().admin_login ? 'Employee' : activeState == 'reimbursement' ? 'Reimbursements' : 'Deductions'} count is <br />based on the filters applied
                                                                            </Typography>
                                                                        </Box>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                            </HtmlTooltip>
                                                            {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} ${LocalStorage.getUserData().admin_login ? 'Employees' : activeState == 'reimbursement' ? 'Reimbursements' : 'Deductions'} `}</Text>
                                                    </Grid>
                                                    <button title="Export" type="button" className={classes.borderButton}
                                                        onClick={() => handleDownloadExport()}
                                                    >
                                                        <img src={NewDownloadcloud} alt="Userplus" />
                                                    </button>
                                                    <button title="Filter"
                                                        type="button"
                                                        className={classes.borderButton}
                                                        onClick={() => { handleOpenDrawer() }}>
                                                        <Badge badgeContent={filterData.raised_by.length + (filterData.status.length && !pendingApproval) + filterData.expense_type_ids.length + filterData.expense_effect_on.length + ((filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") && 1) + ((filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") && 1) + ((filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "") && 1)} color="error">
                                                            <FilterListIcon sx={{ color: (filterData.raised_by.length > 0 || (filterData.status.length > 0 && !pendingApproval) || filterData.expense_type_ids.length > 0 || filterData.expense_effect_on.length > 0 || (filterData.raised_amount_from !== "" && filterData.raised_amount_to !== "") || (filterData.date_of_expense_from !== "" && filterData.date_of_expense_to !== "") || (filterData.approved_amount_from !== "" && filterData.approved_amount_to !== "")) ? "#2BEA2B" : "" }} />
                                                        </Badge>
                                                    </button>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>

                            </Box>
                            {
                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                    <TableContainer sx={{ overflow: 'scroll', width: '100%' }}>
                                        <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                                            width: '100%',
                                            // tableLayout: 'fixed',
                                        }}
                                        >
                                            <Box>

                                                <Box sx={{
                                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                                        font: "12px Quicksand",
                                                        fontWeight: "600",
                                                        paddingTop: '1px'
                                                    },
                                                }} >
                                                    <StyledTableRow>
                                                        <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 300 : 350 }}>
                                                            {!LocalStorage.getUserData().admin_login ? 'Name' :
                                                                <>
                                                                {console.log("a",filterData.expenses,"b",placements)}
                                                                    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                                                        {
                                                                            pendingApproval && placements.length != 0 ?
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"expenses"}
                                                                                    onChange={(e) => { handleChangePendingApproval(e, 'all') }}
                                                                                    // onClick = {handleCheckClick()}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={(filterData.expenses.filter((item)=>item.is_deleted == false).length == placements.filter((item)=>item.is_deleted == false).length) && placements.filter((item)=>item.is_deleted == false).length > 0 } />
                                                                                : ''
                                                                        }
                                                                        <Text mediumBlack14>Employee Name</Text>
                                                                        <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                            <FilterListIcon sx={{ height: "19px", color: (filterData.sort_column == "name" || filterData.sort_column == "created_at") ? "#2BEA2B" : "" }} />
                                                                        </IconButton>
                                                                    </Box>
                                                                    <Menu
                                                                        anchorEl={anchorEl}
                                                                        id="account-menu"
                                                                        open={openFilter}
                                                                        onClose={handleCloseFilter}
                                                                        onClick={handleCloseFilter}
                                                                        PaperProps={{
                                                                            elevation: 0,
                                                                            sx: {
                                                                                overflow: "visible",
                                                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                                mt: 0.5,
                                                                                ml: -2,
                                                                                padding: "5px",
                                                                                "& .MuiMenuItem-root:hover": {
                                                                                    backgroundColor: "#EAECF0 !important",
                                                                                }
                                                                            },
                                                                        }}
                                                                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                    >
                                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'desc')}> Newest First</MenuItem>
                                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'created_at' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('created_at', 'asc')}> Oldest First</MenuItem>
                                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'asc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'asc')}> Name (A-Z)</MenuItem>
                                                                        <MenuItem className={classes.menuItem1} sx={{ background: filterData.sort_column == 'name' && filterData.sort_order == 'desc' ? '#EAECF0' : "" }} onClick={() => handleSort('name', 'desc')}> Name (Z-A)</MenuItem>
                                                                    </Menu>
                                                                </>

                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 150 }}>Type</StyledTableCell>
                                                        <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{activeState == 'reimbursement' ? 'Add To' : 'Deducted From'}</StyledTableCell>
                                                        <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>Expense Date</StyledTableCell>
                                                        {activeState == 'reimbursement' &&
                                                            <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }} onClick={handleStatusOpen}>
                                                                <Text sx={{ display: 'flex', flexDirection: 'row', paddingTop: '5px', font: '14px Quicksand !important', fontWeight: `${600} !important` }}>Status
                                                                    {
                                                                        pendingApproval ? '' :
                                                                            openStatus ?
                                                                                <KeyboardArrowUpIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                                                :
                                                                                <KeyboardArrowDownIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                                                    }
                                                                </Text>

                                                                <Menu
                                                                    anchorEl={anchorEl1}
                                                                    id="status-menu"
                                                                    open={openStatus}
                                                                    onClose={() => { setAnchorEl1(null) }}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            width: "170px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            },
                                                                        }
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                                                    <MenuItem onClick={() => { handleStatusClose("Pending") }} className={filterData.status.length > 0 && filterData.status.includes("Pending") ? classes.activeMenuItem : classes.menuItem}> Pending</MenuItem> <Divider className={classes.divider} />
                                                                    <MenuItem onClick={() => { handleStatusClose("Approved") }} className={filterData.status.length > 0 && filterData.status.includes("Approved") ? classes.activeMenuItem : classes.menuItem}> Approved</MenuItem> <Divider className={classes.divider} />
                                                                    <MenuItem onClick={() => { handleStatusClose("Rejected") }} className={filterData.status.length > 0 && filterData.status.includes("Rejected") ? classes.activeMenuItem : classes.menuItem}> Rejected</MenuItem>
                                                                </Menu>
                                                            </StyledTableCell>
                                                        }
                                                        {activeState == 'reimbursement' &&
                                                            <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>Raised Amount</StyledTableCell>
                                                        }
                                                        {
                                                            pendingApproval ? '' :
                                                                <StyledTableCell align="start" sx={{ minWidth: 150 }}>{activeState == 'reimbursement' ? 'Approved Amount' : 'Amount'}</StyledTableCell>
                                                        }
                                                        <StyledTableCell align="start" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>Actions</StyledTableCell>

                                                    </StyledTableRow>
                                                </Box>
                                                {
                                                    loading ?
                                                        [1, 2, 3, 4].map((item) => (
                                                            <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                                <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                                    <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <Box>
                                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                        </Box>
                                                                        <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                    </Box>
                                                                </Box>
                                                            </Stack>
                                                        )) :
                                                        <>
                                                            {
                                                                placements.length > 0 ?
                                                                    <Box sx={{
                                                                        height: pendingApproval ? "55vh" : "33.3vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                                        paddingLeft: '5px', paddingRight: '5px',
                                                                        "@media (min-height: 850px)": {
                                                                            height: pendingApproval ? "65vh" : '73.5vh'
                                                                        }
                                                                    }} ref={scrollBox} onScroll={handleScroll}>
                                                                        {
                                                                            placements.map((place, key) => (
                                                                                <StyledTableRowBody key={key}  sx={{backgroundColor:place.is_deleted ? "#EAECF0 !important" : ''}} >
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 310 : 360, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                        {
                                                                                            pendingApproval && place?.is_deleted == false ?
                                                                                                <Checkbox
                                                                                                    size="small"
                                                                                                    name={"expenses"}
                                                                                                    onChange={(e) => { handleChangePendingApproval(e, 'solo', place) }}
                                                                                                    // onClick = {handleCheckClick()}
                                                                                                    icon={<CheckBorderIcon />}
                                                                                                    checkedIcon={ <CheckedIcon />}
                                                                                                    disabled ={place.is_deleted}
                                                                                                    checked={filterData.expenses.some(item => item.id === place.id)} />
                                                                                                : <Box sx={{width:"37px"}}></Box>}
                                                                                        {!LocalStorage.getUserData().admin_login ?
                                                                                            <BlackToolTip arrow title={place.name}>{place.name ? place.name.length > 16 ? place.name.slice(0, 16) + '...' : place.name : "--"}
                                                                                            </BlackToolTip>
                                                                                            :
                                                                                            <Box display='flex' alignItems='center' gap={2}>
                                                                                                <CustomCircularProgress
                                                                                                    percentage={place.profile_percentage}
                                                                                                    color={place.profile_percentage >= 76 ? 'green' : place.profile_percentage <= 75 && place.profile_percentage >= 51 ? 'yellow' : place.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                                                    src={place.profile_picture_url}
                                                                                                    name={place.profile_picture_url == undefined || place.profile_picture_url == null || place.profile_picture_url == '' ? place.display_name && capitalizeAndAddSpace(place.display_name[0]) : ''}
                                                                                                    imgWidth={`${imgWidth} !important`}
                                                                                                    imgHeight={`${imgHeight} !important`}
                                                                                                    avatarWidth={`${avatarWidth} !important`}
                                                                                                    avatarHeight={`${avatarHeight} !important`}
                                                                                                    value={place.profile_percentage}
                                                                                                    size={size}
                                                                                                    tooltip
                                                                                                    tooltipContent={
                                                                                                        <Box p={'3px 0px'} width='100%'>
                                                                                                            <Text mediumBoldWhite400>{`Profile Completion - ${place.profile_percentage}%`}</Text>
                                                                                                        </Box>
                                                                                                    }
                                                                                                />
                                                                                                <Box >
                                                                                                    {
                                                                                                        place.status == 'In Active' ?<Box sx={{display:"flex"}}>
                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                {place.display_name === "" ? "--" : capitalizeAndAddSpace(place.display_name).length > getLength(16) ?
                                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.display_name)} placement="right" arrow>
                                                                                                                        {capitalizeAndAddSpace(place.display_name).slice(0, getLength(16)) + (capitalizeAndAddSpace(place.display_name).length > getLength(16) ? "..." : "")}
                                                                                                                    </HtmlTooltip>
                                                                                                                    :
                                                                                                                    capitalizeAndAddSpace(place.display_name) 
                                                                                                                }
                                                                                                                
                                                                                                                {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                    </HtmlTooltip>
                                                                                                                    : place.employee_e_verified == 2 ?
                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                            <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                        </HtmlTooltip> : ''
                                                                                                                }
                                                                                                                
                                                                                                            </Text> 
                                                                                                            {place.is_deleted && <Text sx={{
                                                                                                                    fontWeight: '600 !important',
                                                                                                                    fontSize: '5px !important',
                                                                                                                    backgroundColor:"#EA5B45 !important",
                                                                                                                    width: '54px !important',
                                                                                                                    height: '18px !important',
                                                                                                                    borderRadius: '30px !important',
                                                                                                                    marginLeft:"4px !important",
                                                                                                                    color:"#FFFFFF !important",
                                                                                                                    alignItems:"center !important",
                                                                                                                    textAlign:"center !important"}}>Deleted</Text>}</Box>
                                                                                                            :<Box sx={{display:"flex"}}>
                                                                                                            <Text mediumBoldBlack noWrap>
                                                                                                                {place.display_name === "" ? "--" :
                                                                                                                    capitalizeAndAddSpace(place.display_name).length > getLength(16) ?
                                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(place.display_name)} placement="right" arrow>
                                                                                                                            {capitalizeAndAddSpace(place.display_name).slice(0, getLength(16)) + (capitalizeAndAddSpace(place.display_name).length > getLength(16) ? "..." : "")}
                                                                                                                        </HtmlTooltip>
                                                                                                                        :
                                                                                                                        capitalizeAndAddSpace(place.display_name)
                                                                                                                }
                                                                                                                
                                                                                                                {place.employee_e_verified == 1 || place.employee_e_verified == 4 ?
                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-Verified</Text>} placement="right" arrow>
                                                                                                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                    </HtmlTooltip>
                                                                                                                    : place.employee_e_verified == 2 ?
                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-Verification is pending</Text>} placement="right" arrow>
                                                                                                                            <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                        </HtmlTooltip> : ''
                                                                                                                }
                                                                                                                
                                                                                                            </Text> {place.is_deleted && 
                                                                                                            <Text sx={{
                                                                                                                    fontWeight: '600 !important',
                                                                                                                    fontSize: '12px !important',
                                                                                                                    backgroundColor:"#EA5B45 !important",
                                                                                                                    width: '54px !important',
                                                                                                                    height: '18px !important',
                                                                                                                    borderRadius: '30px !important',
                                                                                                                    marginLeft:"4px !important",
                                                                                                                    color:"#FFFFFF !important",
                                                                                                                    alignItems:"center !important",
                                                                                                                    textAlign:"center !important"
                                                                                                                }}>Deleted</Text>
                                                                                                                }</Box>
                                                                                                    }

                                                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' p={'4px 0px'}>
                                                                                                        <Text greyLabel> {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                                        {
                                                                                                            place.category_type ?
                                                                                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                                    <Box sx={{ height: '4px', width: '4px', background: '#C7CCD3', borderRadius: '50%' }} />
                                                                                                                    <Text greyLabel> {place.category_type}</Text>
                                                                                                                </Box> : ''
                                                                                                        }
                                                                                                        {/* {place.is_deleted &&
                                                                                                                    <Box className={classes.deletedStyles}>
                                                                                                                        Deleted
                                                                                                                    </Box>
                                                                                                                } */}
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        }
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 150 }}>
                                                                                        <BlackToolTip arrow title={place.expense_type}>{place.expense_type ? place.expense_type.length > getLength(10) ? place.expense_type.slice(0, getLength(10)) + '...' : place.expense_type : "--"}
                                                                                        </BlackToolTip>
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{place.expense_effect_on}</StyledTableCell>
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }}>{place.date_of_expense}</StyledTableCell>
                                                                                    {activeState == 'reimbursement' &&
                                                                                        <StyledTableCell align="center" sx={{ minWidth: 150 }}>{place.status}</StyledTableCell>
                                                                                    }
                                                                                    <StyledTableCell align="center" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 150 }}>{currency} {place.raised_amount ? place.raised_amount : '-'}</StyledTableCell>
                                                                                    {pendingApproval ? '' : activeState == 'reimbursement' &&
                                                                                        <StyledTableCell align="center" sx={{ minWidth: 150 }}>{currency} {place.approved_amount ? place.approved_amount : '-'}</StyledTableCell>
                                                                                    }
                                                                                    <StyledTableCell align="center" id="actions" sx={{ minWidth: activeState == 'reimbursement' ? 150 : 220 }} className={classes.cursor}>
                                                                                        <Box sx={{ cursor: 'pointer !important' }}>

                                                                                        <img src={menu} id="actions" alt='menu' className={classes.cursor} onClick={(e) => { handleClickActionMenu(e, place) }} />
                                                                                        </Box>
                                                                                        <Menu
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'actions',
                                                                                            }}
                                                                                            id="basic-menu"
                                                                                            anchorEl={actionAnchorEl}
                                                                                            open={openActions}
                                                                                            onClose={handleCloseAction}
                                                                                            PaperProps={{
                                                                                                elevation: 0,
                                                                                                sx: {
                                                                                                    overflow: "visible",
                                                                                                    //boxShadow: 'rgba(16, 24, 40, 0.03) 0px 4px 6px -2px, rgba(16, 24, 40, 0.08) 0px 12px 16px -4px',
                                                                                                    //filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                                                    mt: 0.5,
                                                                                                    boxShadow: 'none',
                                                                                                    border: '1px solid #F2F4F7',

                                                                                                    width: "80px",
                                                                                                    "& .MuiMenuItem-root:hover": {
                                                                                                        backgroundColor: "#EAECF0 !important",
                                                                                                    },
                                                                                                }
                                                                                            }}
                                                                                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                                                        > 
                                                                                           {
                                                                                            ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) ) && 
                                                                                            <MenuItem onClick={(e) => { accordianHandler(e, place) }} className={classes.menuItem} >View</MenuItem> }
                                                                                            {
                                                                                            ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true))) &&

                                                                                            <>
                                                                                            <Divider className={classes.divider} />
                                                                                            {isDeleted ? '' :isEditable && 
                                                                                                 <MenuItem className={classes.menuItem} onClick={(e) => { handleEditAction(e, place) }}>Edit</MenuItem> }
                                                                                                 <Divider className={classes.divider} />
                                                                                            {isDeleted ? '' : isEditable &&
                                                                                                <MenuItem className={classes.menuItem} onClick={() => { handleOpenApprove() }}>Delete</MenuItem> }
                                                                                            </>}

                                                                                        </Menu>
                                                                                    </StyledTableCell>

                                                                                </StyledTableRowBody>

                                                                            ))
                                                                        }
                                                                    </Box>
                                                                    :
                                                                    placements.length == 0 ?
                                                                        <Box>
                                                                            {NoDataFound(`To fetch the data. Please add ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'}`, `No  ${activeState == 'reimbursement' ? "Reimbursements" : 'Deductions'} Yet`)}
                                                                        </Box>
                                                                        : ''
                                                            }
                                                            {
                                                                filterData.expenses.length > 0 ?
                                                                    <Grid item xs={12} justifyContent='end' pt={1}>
                                                                        <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>

                                                                            <Button redBorder sx={{ width: '100px !important' }} onClick={() => handleButtons('Rejected')}>Reject</Button>

                                                                            <Button saveBtn600 onClick={() => handleButtons('ApproveAll')}>Approve</Button>

                                                                        </Box>
                                                                    </Grid>
                                                                    : ''
                                                            }

                                                        </>
                                                }
                                            </Box>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <>
                                        {NoPermission()}
                                    </>
                            }
                        </Box>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openBacKLoader}
                        // onClick={handleClose}
                        >
                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                        </Backdrop>

                        <BootstrapDialog
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            fullWidth={true}
                            maxWidth={"md"}
                        >

                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                    boxShadow: 'none !important',
                                    "&:hover": {
                                        boxShadow: 'none !important',
                                        background: 'none !important'
                                    }
                                }}
                            >
                                <img src={crossIcon} alt="cross" />
                                {/* <CloseIcon sx={{ color: "rgba(38, 38, 38, 1)" }} /> */}
                            </IconButton>
                            <DialogContent sx={{ margin: "50px", }}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Box sx={{ margin: "12px 0px" }}>
                                                    <img src={InviteViaLink} alt='invite-via-link' style={{ height: "200px" }} />
                                                </Box>
                                                <Box sx={{ margin: "8px 0px" }}>
                                                    {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                            Send the employee an invite link to add them to <br /> the organization.
                                        </Typography> */}
                                                    <Text mediumLabel>Send the employee an invite link to add them to <br /> the organization.</Text>
                                                </Box>
                                                <Box sx={{ margin: "4px 0px" }}>
                                                    <button
                                                        onClick={() => { navigate("/employees/add"); filterStorage.clearAllFilters(); }}
                                                        type="button"
                                                        className={classes.inviteLinkBtn}
                                                    // style={{
                                                    //     margin: "12px 0px", all: "unset", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", width: "150px", height: "40px", border: "1.5px solid rgba(12, 117, 235, 1)", borderRadius: "6px", color: "rgba(12, 117, 235, 1)",
                                                    //     "&::hover": {
                                                    //         backgroundColor:'rgba(12, 117, 235, 1)',color:'#FFFFFF'
                                                    // } }}
                                                    >
                                                        Invite via link
                                                    </button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center", borderLeft: "1.5px solid rgba(199, 204, 211, 1)" }}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Box sx={{ margin: "16px 0px" }}>
                                                    <img src={Onboart} alt='onboart' style={{ height: "200px" }} />
                                                </Box>
                                                <Box sx={{ margin: "6px 0px" }}>
                                                    {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                        Onboard an employee manually, to add them to <br />the organization.
                                        </Typography> */}
                                                    <Text mediumLabel> Onboard an employee manually, to add them to <br />the organization.</Text>
                                                </Box>
                                                <Box sx={{ margin: "20px 0px", }}>
                                                    <button
                                                        type="button"
                                                        className={classes.onboardBtn}
                                                        onClick={() => { navigate('/employees/onboard'); LocalStorage.removeEmployeeId(); LocalStorage.removeVisaID(); LocalStorage.removeFullName(); filterStorage.clearAllFilters(); }}
                                                    >
                                                        Onboard
                                                    </button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </BootstrapDialog>

                        <SwipeableDrawer
                            anchor={'right'}
                            open={drawer}
                            onClose={() => handleFilterDrawerClose()}
                            transitionDuration={300}
                            sx={{
                                ".MuiDrawer-paper ": {
                                    borderRadius: '8px 0px 0px 8px !important',
                                    width: { xs: '100% !important', sm: '65% !important', md: '55% !important' },
                                },
                                "& .MuiBackdrop-root.MuiModal-backdrop": {
                                    backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                                }
                            }
                            }
                        >
                            {
                                drawer === "filter" ?
                                    <Box height={'100vh'} >
                                        <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                            <Text headerBlack>Filters</Text>
                                        </Box>

                                        <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                            <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                                {
                                                    filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "" &&
                                                    <>
                                                        <Chip
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            key={1}
                                                            icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack>{filterState.date_of_expense_from} - {filterState.date_of_expense_to}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip('', "date_of_expense")}
                                                            deleteIcon={<CloseIcon />} />
                                                    </>
                                                }

                                                {
                                                    filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "" &&
                                                    <>
                                                        <Chip
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            key={1}
                                                            icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack>{filterState.approved_amount_from} - {filterState.approved_amount_to}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip('', "approved_amount")}
                                                            deleteIcon={<CloseIcon />} />
                                                    </>
                                                }
                                                {
                                                    filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "" &&
                                                    <>
                                                        <Chip
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            key={1}
                                                            icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack>{filterState.raised_amount_from} - {filterState.raised_amount_to}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip('', "raised_amount")}
                                                            deleteIcon={<CloseIcon />} />
                                                    </>
                                                }
                                                {
                                                    deductionTypeDropdown.map((item, key) => (
                                                        filterState.expense_type_ids.includes(item.id) &&
                                                        <Chip
                                                            sx={{ border: "1px solid #EA5151" }}
                                                            key={key}
                                                            icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.value}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip(item.id, "expense_type_ids")}
                                                            deleteIcon={<CloseIcon />} />
                                                    ))
                                                }
                                                {
                                                    reimbursementTypeDropdown.map((item, key) => (
                                                        filterState.expense_type_ids.includes(item.id) &&
                                                        <Chip
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            key={key}
                                                            icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.value}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip(item.id, "expense_type_ids")}
                                                            deleteIcon={<CloseIcon />} />
                                                    ))
                                                }
                                                {
                                                    employmentType.map((item, key) => (
                                                        filterState.raised_by.includes(item.id) &&
                                                        <Chip
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            key={key}
                                                            icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack nowrap>{
                                                                item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    :
                                                                    item.value
                                                            }</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip(item.id, "raised_by")}
                                                            deleteIcon={<CloseIcon />}
                                                        />
                                                    ))
                                                }
                                                {
                                                    deductedFromDropdown.map((item, key) => (
                                                        filterState.expense_effect_on.includes(item.id) &&
                                                        <Chip key={key}
                                                            sx={{ border: "1px solid #E5E8E9" }}
                                                            icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                            label={<Text smallBlack>{item.value}</Text>}
                                                            variant="outlined"
                                                            onDelete={() => handleDeleteChip(item.id, "expense_effect_on")}
                                                            deleteIcon={<CloseIcon />}
                                                        />
                                                    ))
                                                }
                                                {
                                                    pendingApproval ? '' :
                                                        statusList.map((item, key) => (
                                                            filterState.status.includes(item.id) &&
                                                            <Chip key={key}
                                                                sx={{ border: "1px solid #EA5151" }}
                                                                icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                                label={<Text smallBlack>{item.value}</Text>}
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteChip(item.id, "status")}
                                                                deleteIcon={<CloseIcon />}
                                                            />
                                                        ))
                                                }
                                            </Box>
                                            {
                                                ((filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") || (filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") || (filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") || (filterState.status.length > 0 && !pendingApproval) || filterState.expense_type_ids.length > 0 || filterState.raised_by.length > 0 || filterState.expense_effect_on.length > 0) ?
                                                    <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                    :
                                                    ''
                                            }
                                        </Box>

                                        <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                            <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                                {
                                                    activeState != "reimbursement" ?
                                                        <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>

                                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.expense_type_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.expense_type_ids.length}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Deduction Type</Text> : <Text ml={3} mediumBlack>Deduction Type</Text>} />
                                                            </ListItem>
                                                            <Divider className={classes.divider} />
                                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterState.expense_effect_on.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.expense_effect_on.length}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Deduction From</Text> : <Text ml={3} mediumBlack>Deduction From</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Expense Date</Text> : <Text ml={3} mediumBlack>Expense Date</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Amount</Text> : <Text ml={3} mediumBlack>Amount</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                        </List>
                                                        :
                                                        <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                                            <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={filterState.expense_type_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.expense_type_ids.length}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Expense Type</Text> : <Text ml={3} mediumBlack>Expense Type</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            {
                                                                LocalStorage.getUserData().admin_login ?
                                                                    <ListItem className={selectedFilter == 7 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(7)} secondaryAction={filterState.raised_by.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.raised_by.length}</Text></Box> : ""}>
                                                                        <ListItemText primary={selectedFilter == 7 ? <Text ml={3} smallBlue1>Expense Raised by</Text> : <Text ml={3} mediumBlack>Expense Raised by</Text>} />
                                                                    </ListItem> : ''
                                                            }
                                                            {
                                                                LocalStorage.getUserData().admin_login ?
                                                                    <Divider />
                                                                    : ''}
                                                            <ListItem className={selectedFilter == 8 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(8)} secondaryAction={filterState.expense_effect_on.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.expense_effect_on.length}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 8 ? <Text ml={3} smallBlue1>Added To</Text> : <Text ml={3} mediumBlack>Added To</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem className={selectedFilter == 9 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(9)} secondaryAction={(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.date_of_expense_from !== "" && filterState.date_of_expense_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 9 ? <Text ml={3} smallBlue1>Expense Date</Text> : <Text ml={3} mediumBlack>Expense Date</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.raised_amount_from !== "" && filterState.raised_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Raised Amount</Text> : <Text ml={3} mediumBlack>Raised Amount</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                            {pendingApproval ? '' :
                                                                <ListItem className={selectedFilter == 10 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(10)} secondaryAction={filterState.status.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.status.length}</Text></Box> : ""}>
                                                                    <ListItemText primary={selectedFilter == 10 ? <Text ml={3} smallBlue1>Status</Text> : <Text ml={3} mediumBlack>Status</Text>} />
                                                                </ListItem>
                                                            }
                                                            <Divider />
                                                            <ListItem className={selectedFilter == 11 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(11)} secondaryAction={(filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.approved_amount_from !== "" && filterState.approved_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                                <ListItemText primary={selectedFilter == 11 ? <Text ml={3} smallBlue1>Approved Amount</Text> : <Text ml={3} mediumBlack>Approved Amount</Text>} />
                                                            </ListItem>
                                                            <Divider />
                                                        </List>
                                                }


                                            </Box>
                                            <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                                {
                                                    selectedFilter == 1 ?
                                                        <>
                                                            {
                                                                deductionTypeDropdown.length > 0 && deductionTypeDropdown.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <FormControlLabel
                                                                            key={index}
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"expense_type_ids"}
                                                                                    value={item.id}
                                                                                    onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                    // onClick = {handleCheckClick()}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={filterState.expense_type_ids.includes(item.id)} />}
                                                                            label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                :
                                                                                item.value}</Text>}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        selectedFilter == 2 ?
                                                            <>
                                                                {
                                                                    deductedFromDropdown.length > 0 && deductedFromDropdown.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"expense_effect_on"}
                                                                                        value={item.id}
                                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                        // onClick = {handleCheckClick()}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={<CheckedIcon />}
                                                                                        checked={filterState.expense_effect_on.includes(item.id)} />}
                                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                    </BlackToolTip>
                                                                                    :
                                                                                    item.value}</Text>}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </>

                                                            :
                                                            selectedFilter == 3 ?
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item xs={12} pb={2}>
                                                                        <Text mediumBlack14 >
                                                                            Select Date Range
                                                                        </Text>
                                                                    </Grid>

                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`From`}
                                                                            name='date_of_expense_from'
                                                                            value={filterState.date_of_expense_from}
                                                                            onChange={(value) => dateChange(value, 'date_of_expense_from')}
                                                                            height='56px'
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                        &#8594;
                                                                    </Grid>
                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`To`}
                                                                            name='date_of_expense_to'
                                                                            value={filterState.date_of_expense_to}
                                                                            onChange={(value) => dateChange(value, 'date_of_expense_to')}
                                                                            height='56px'
                                                                            minDate={moment(filterState.date_of_expense_from).format(dateFormat())}
                                                                            disabled={filterState.date_of_expense_from == ""}
                                                                            error={datesError.date_of_expense_to}
                                                                        />
                                                                        <Box sx={{ height: "0px" }}>{datesError.date_of_expense_to ? <Text red>{datesError.date_of_expense_to ? datesError.date_of_expense_to : ''}</Text> : ''}</Box>
                                                                    </Grid>
                                                                </Grid> :
                                                                selectedFilter == 4 ?
                                                                    <>
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                                    <Text mediumBlack14 pb={1}>
                                                                                        Select Amount Range
                                                                                    </Text>
                                                                                    <Slider
                                                                                        value={[filterState.raised_amount_from, filterState.raised_amount_to]}
                                                                                        onChange={handleSliderChangeRaised}
                                                                                        valueLabelDisplay="auto"
                                                                                        min={filterAmount.raised_amount.min}
                                                                                        max={filterAmount.raised_amount.max}
                                                                                        sx={{ mb: 2 }}
                                                                                    />
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'number',
                                                                                                value: filterState.raised_amount_from,
                                                                                                inputProps: { maxLength: 50 },
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'raised_amount_from', 'min')}
                                                                                            clientInput
                                                                                            labelText={'$ Min'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                        <Input
                                                                                            formControlProps={{
                                                                                                fullWidth: true
                                                                                            }}
                                                                                            inputProps={{
                                                                                                name: 'number',
                                                                                                value: filterState.raised_amount_to,
                                                                                            }}
                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'raised_amount_to', 'max')}
                                                                                            clientInput
                                                                                            labelText={'$ Max'}
                                                                                            size="small"
                                                                                            sx={{ width: "35%" }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </> :
                                                                    selectedFilter == 5 ?
                                                                        <>
                                                                            {
                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                                            <Text mediumBlack14 pb={1}>
                                                                                                Select Amount Range
                                                                                            </Text>
                                                                                            <Slider
                                                                                                value={[filterState.raised_amount_from, filterState.raised_amount_to]}
                                                                                                onChange={handleSliderChangeRaised}
                                                                                                valueLabelDisplay="auto"
                                                                                                min={filterAmount.raised_amount.min}
                                                                                                max={filterAmount.raised_amount.max}
                                                                                                sx={{ mb: 2 }}
                                                                                            />
                                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'raised_amount_from',
                                                                                                        value: filterState.raised_amount_from,
                                                                                                        inputProps: { maxLength: 50 },
                                                                                                    }}
                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                    handleChange={(e) => handleInputChange(0, e.target.value, 'raised_amount_from', 'min')}
                                                                                                    clientInput
                                                                                                    labelText={'$ Min'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'raised_amount_to',
                                                                                                        value: filterState.raised_amount_to,
                                                                                                    }}
                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                    handleChange={(e) => handleInputChange(1, e.target.value, 'raised_amount_to', 'max')}
                                                                                                    clientInput
                                                                                                    labelText={'$ Max'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </>
                                                                        :
                                                                        selectedFilter == 6 ?
                                                                            <>
                                                                                {
                                                                                    reimbursementTypeDropdown.length > 0 && reimbursementTypeDropdown.map((item, index) => (
                                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                                            <FormControlLabel
                                                                                                key={index}
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        size="small"
                                                                                                        name={"expense_type_ids"}
                                                                                                        value={item.id}
                                                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                        // onClick = {handleCheckClick()}
                                                                                                        icon={<CheckBorderIcon />}
                                                                                                        checkedIcon={<CheckedIcon />}
                                                                                                        checked={filterState.expense_type_ids.includes(item.id)} />}
                                                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                    </BlackToolTip>
                                                                                                    :
                                                                                                    item.value}</Text>}
                                                                                            />

                                                                                        </Grid>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            selectedFilter == 7 ?
                                                                                <>
                                                                                    {
                                                                                        employmentType.length > 0 && employmentType.map((item, index) => (
                                                                                            <Grid container alignItems={"center"} pb={2}>
                                                                                                <FormControlLabel
                                                                                                    key={index}
                                                                                                    control={
                                                                                                        <Checkbox
                                                                                                            size="small"
                                                                                                            name={"raised_by"}
                                                                                                            value={item.id}
                                                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                            // onClick = {handleCheckClick()}
                                                                                                            icon={<CheckBorderIcon />}
                                                                                                            checkedIcon={<CheckedIcon />}
                                                                                                            checked={filterState.raised_by.includes(item.id)} />}
                                                                                                    label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                        </BlackToolTip>
                                                                                                        :
                                                                                                        item.value}</Text>}
                                                                                                />
                                                                                            </Grid>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                :
                                                                                selectedFilter == 8 ?
                                                                                    <>
                                                                                        {
                                                                                            deductedFromDropdown.length > 0 && deductedFromDropdown.map((item, index) => (
                                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                                    <FormControlLabel
                                                                                                        key={index}
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                size="small"
                                                                                                                name={"expense_effect_on"}
                                                                                                                value={item.id}
                                                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                                // onClick = {handleCheckClick()}
                                                                                                                icon={<CheckBorderIcon />}
                                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                                checked={filterState.expense_effect_on.includes(item.id)} />}
                                                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            :
                                                                                                            item.value}</Text>}
                                                                                                    />
                                                                                                </Grid>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    selectedFilter == 9 ?
                                                                                        <Grid container alignItems={"center"}>
                                                                                            <Grid item xs={12} pb={2}>
                                                                                                <Text mediumBlack14 >
                                                                                                    Select Date Range
                                                                                                </Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                                                <Date
                                                                                                    labelText={`From`}
                                                                                                    name='date_of_expense_from'
                                                                                                    value={filterState.date_of_expense_from}
                                                                                                    onChange={(value) => dateChange(value, 'date_of_expense_from')}
                                                                                                    height='56px'
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                                                &#8594;
                                                                                            </Grid>
                                                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                                                <Date
                                                                                                    labelText={`To`}
                                                                                                    name='date_of_expense_to'
                                                                                                    value={filterState.date_of_expense_to}
                                                                                                    onChange={(value) => dateChange(value, 'date_of_expense_to')}
                                                                                                    height='56px'
                                                                                                    minDate={moment(filterState.date_of_expense_from,dateFormat()).format(dateFormat())}
                                                                                                    disabled={filterState.date_of_expense_from == ""}
                                                                                                    error={datesError.date_of_expense_to}
                                                                                                />
                                                                                                <Box sx={{ height: "0px" }}>{datesError.date_of_expense_to ? <Text red>{datesError.date_of_expense_to ? datesError.date_of_expense_to : ''}</Text> : ''}</Box>
                                                                                            </Grid>
                                                                                        </Grid> :
                                                                                        selectedFilter == 10 ?
                                                                                            <>
                                                                                                {
                                                                                                    statusList.length > 0 && statusList.map((item, index) => (
                                                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                                                            <FormControlLabel
                                                                                                                key={index}
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        size="small"
                                                                                                                        name={"status"}
                                                                                                                        value={item.id}
                                                                                                                        onChange={(e) => handleChangeCheckBox(e)}
                                                                                                                        icon={<CheckBorderIcon />}
                                                                                                                        checkedIcon={<CheckedIcon />}
                                                                                                                        checked={filterState.status.includes(item.id)} />}
                                                                                                                label={<Text mediumBlack >{item.value}</Text>}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    ))
                                                                                                }
                                                                                            </> :
                                                                                            selectedFilter == 11 ?
                                                                                                <>
                                                                                                    {
                                                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                                                                    <Text mediumBlack14 pb={1}>
                                                                                                                        Select Amount Range
                                                                                                                    </Text>
                                                                                                                    <Slider
                                                                                                                        value={[filterState.approved_amount_from, filterState.approved_amount_to]}
                                                                                                                        onChange={handleSliderChange}
                                                                                                                        valueLabelDisplay="auto"
                                                                                                                        min={filterAmount.approved_amount.min}
                                                                                                                        max={filterAmount.approved_amount.max}
                                                                                                                        sx={{ mb: 2 }}
                                                                                                                    />
                                                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                                        <Input
                                                                                                                            formControlProps={{
                                                                                                                                fullWidth: true
                                                                                                                            }}
                                                                                                                            inputProps={{
                                                                                                                                name: 'approved_amount_from',
                                                                                                                                value: filterState.approved_amount_from,
                                                                                                                                inputProps: { maxLength: 50 },
                                                                                                                            }}
                                                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'approved_amount_from', 'min')}
                                                                                                                            clientInput
                                                                                                                            labelText={'$ Min'}
                                                                                                                            size="small"
                                                                                                                            sx={{ width: "35%" }}
                                                                                                                        />
                                                                                                                        <Input
                                                                                                                            formControlProps={{
                                                                                                                                fullWidth: true
                                                                                                                            }}
                                                                                                                            inputProps={{
                                                                                                                                name: 'approved_amount_to',
                                                                                                                                value: filterState.approved_amount_to,
                                                                                                                            }}
                                                                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'approved_amount_to', 'max')}
                                                                                                                            clientInput
                                                                                                                            labelText={'$ Max'}
                                                                                                                            size="small"
                                                                                                                            sx={{ width: "35%" }}
                                                                                                                        />
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                                                    <Grid container>
                                                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                                            <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                                                        </Grid>
                                                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                                            <Text veryLargeLabel>Gear Up!</Text>
                                                                                                        </Grid>
                                                                                                        <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                                            <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Box>
                                                    // nofilterplacement
                                                }
                                            </Box>
                                        </Box>

                                        <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                            <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                            <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                        </Box>
                                    </Box >
                                    : null
                            }

                        </SwipeableDrawer>


                        <SwipeableDrawer
                            anchor={'right'}
                            open={openView}
                            onClose={() => handleCloseView()}
                            transitionDuration={300}
                            sx={{
                                width: '50%',
                                ".MuiDrawer-paper ": {
                                    width: '50%',
                                    borderRadius: '8px 0px 0px 8px !important',
                                },
                                "& .MuiBackdrop-root.MuiModal-backdrop": {
                                    backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                                }
                            }
                            }
                        >
                            <Grid container p={'10px 15px 10px 20px'} mt={3} xs={12}>
                                <Grid item xs={12}>
                                    <Grid item container justifyContent={'space-between'} xs={12}>
                                        <Grid item>
                                            <Text dashboardblack14>{activeState == 'reimbursement' ? 'Reimbursement' : 'Deduction'} Details</Text>
                                        </Grid>
                                        <Grid item >
                                            {viewData.is_deleted ? "" : viewData.is_editable  &&
                                                <Box sx={{ cursor: 'pointer !important' }}>
                                                    <img src={menu} id="actionsdrawer" alt='menu' className={classes.cursor} onClick={handleClickActionMenuDrawer} />

                                                </Box>}
                                            <Menu
                                                MenuListProps={{
                                                    'aria-labelledby': 'actionsdrawer',
                                                }}
                                                id="basic-menu"
                                                anchorEl={actionAnchorDrawerEl}
                                                open={openActionsDrawer}
                                                onClose={handleCloseActionDrawer}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: "visible",
                                                        //boxShadow: 'rgba(16, 24, 40, 0.03) 0px 4px 6px -2px, rgba(16, 24, 40, 0.08) 0px 12px 16px -4px',
                                                        //filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                        mt: 0.5,
                                                        boxShadow: 'none',
                                                        border: '1px solid #F2F4F7',

                                                        width: "80px",
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "#EAECF0 !important",
                                                        },
                                                    }
                                                }}
                                                transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                            >


                                                {((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true))) && <>
                                                {viewData.is_deleted ? "" : viewData.is_editable && 
                                                    <MenuItem className={classes.menuItem} onClick={(e) => { drawerEditHandle() }}>Edit</MenuItem> }<Divider className={classes.divider} />
                                                {viewData.is_deleted ? "" : viewData.is_editable &&
                                                    <MenuItem className={classes.menuItem} onClick={() => { handleOpenApprove() }}>Delete</MenuItem>}</>}


                                            </Menu>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ background: '#D1E1FF', borderRadius: '8px', padding: "15px", marginTop: '15px' }}>
                                        <Grid item container xs={12} spacing={2} alignItems='center'>
                                            <Grid item container xs={6}>
                                                <Box display='flex' flexDirection='row' gap={2}>
                                                    {/* <Avatar src={viewData.profile_picture_url ? viewData.profile_picture_url : ''} alt={viewData?.display_name ? viewData.display_name[0] : ''} /> */}
                                                    <Avatar src={viewData.profile_picture_url == ''
                                                        || viewData.profile_picture_url == null ?
                                                        capitalizeAndAddSpace(viewData?.display_name) :
                                                        viewData.profile_picture_url} alt={capitalizeAndAddSpace(viewData?.display_name)}
                                                        sx={{
                                                            height: '50px', width: '50px', borderRadius: '50% !important',
                                                            background: viewData.profile_picture_url ? '' : '#e5b256 !important'
                                                        }} />
                                                    <Box display='flex' flexDirection='column' gap={1}>
                                                        <Box display='flex' flexDirection='row' gap={1}>
                                                            <Text dashboardblack14>
                                                                <BlackToolTip arrow title={viewData.display_name}>{viewData.display_name ? viewData.display_name.length > 16 ? viewData.display_name.slice(0, 16) + '...' : viewData.display_name : "--"}
                                                                </BlackToolTip>
                                                            </Text>
                                                            {
                                                                viewData.e_verified == 1 ?
                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                        <img src={verified} alt='verified' />
                                                                    </BlackToolTip> : ''
                                                            }
                                                        </Box>
                                                        <Text greysmallLabel>{viewData.employee_reference_id ? viewData.employee_reference_id : '-'}</Text>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {
                                                activeState == 'reimbursement' ?
                                                    <Grid item container xs={6} lg={6} justifyContent='end' alignItems='center'>
                                                        <Grid item sm={6} lg={3} justifyContent='flex-end' textAlign='center' alignItems='center' className={viewData.status == 'Pending' ? classes.pendingStatus : viewData.status == 'Approved' ? classes.successStatus : viewData.status == 'Rejected' ? classes.rejectedStatus : ''}>
                                                            {viewData.status}
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} justifyContent='flex-end' pt={1.5}>
                                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                                                <Text greyLabel14>Raised by: </Text>
                                                                <Text mediumBlack14>
                                                                    {
                                                                        viewData.raised_by && viewData.raised_by.length > 12 ?
                                                                            <BlackToolTip title={viewData.raised_by} placement="bottom" arrow>
                                                                                {viewData.raised_by.slice(0, 12) + (viewData.raised_by.length > 12 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : viewData.raised_by
                                                                    } on {viewData.raised_on ? viewData.raised_on : '-'}</Text>
                                                            </Box>
                                                        </Grid>
                                                    </Grid> : ''
                                            }
                                        </Grid>
                                    </Box>
                                    <TabContext value={valueTabs} >
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                            <TabList onChange={handleChange} sx={{ display: "flex", width: '100%' }}>
                                                <Tab label={activeState === 'reimbursement' ? 'Reimbursement Details' : 'Deduction Details'} value="1" sx={{
                                                    color: valueTabs === "1" ? '#0C75EB !important' : '#000000 !important',
                                                    font: "13px Quicksand !important",
                                                    textTransform: 'capitalize',
                                                    fontWeight: `${600} !important`,
                                                    flexGrow: 1, // This makes the Tab occupy 100% of the width
                                                    textAlign: 'center',

                                                }} />
                                                <Tab label="Activity Log" value="2" sx={{
                                                    color: valueTabs == "2" ? '#0C75EB !important' : '#000000 !important',
                                                    font: "12px Quicksand !important",
                                                    textTransform: 'capitalize',
                                                    fontWeight: `${600} !important`,
                                                    flexGrow: 1, // This makes the Tab occupy 100% of the width
                                                    textAlign: 'center',

                                                }} />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" >
                                            <Grid item container xs={12} spacing={2} pl={2} mt={0}>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Name</Text>
                                                        <Text mediumBlack14>{viewData.name ?
                                                            <>
                                                                {
                                                                    viewData.name && viewData.name.length > 16 ?
                                                                        <BlackToolTip title={viewData.name} placement="right" arrow>
                                                                            {viewData.name.slice(0, 16) + (viewData.name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : viewData.name
                                                                }
                                                            </>
                                                            : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Type</Text>
                                                        <Text mediumBlack14>
                                                            {viewData.expense_type ?
                                                                <>
                                                                    {
                                                                        viewData.expense_type && viewData.expense_type.length > 16 ?
                                                                            <BlackToolTip title={viewData.expense_type} placement="top" arrow>
                                                                                {viewData.expense_type.slice(0, 16) + (viewData.expense_type.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : viewData.expense_type
                                                                    }
                                                                </>
                                                                : '-'}
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Date of Expense</Text>
                                                        <Text mediumBlack14>{viewData.date_of_expense ? viewData.date_of_expense : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>{activeState == 'reimbursement' ? 'Add To' : 'Deduct From'}</Text>
                                                        <Text mediumBlack14>{viewData.expense_effect_on ? viewData.expense_effect_on : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>{activeState == 'reimbursement' ? 'Reimbursement Amount' : 'Deduction Amount'}</Text>
                                                        <Text mediumBlack14> {viewData.raised_amount ? `$ ${viewData.raised_amount}` : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                {
                                                    activeState == 'reimbursement' && viewData.approved_amount !== '' ?
                                                        <Grid item xs={6}>
                                                            <Box display='flex' flexDirection='column' gap={2}>
                                                                <Text mediumGreyHeader>Approved amount</Text>
                                                                <Text mediumBlack14>{viewData.approved_amount ? viewData.approved_amount : '-'}</Text>
                                                            </Box>
                                                        </Grid> : ''
                                                }
                                                <Grid item xs={6}>
                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                        <Text mediumGreyHeader>Supporting Document</Text>
                                                        <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => { openInNewTab(viewData.documents[0], viewData.documents[0].document_url) }}>{viewData.documents && viewData.documents.length > 0 ?
                                                            <>
                                                                {
                                                                    viewData.documents[0].document_name && viewData.documents[0].document_name.length > 16 ?
                                                                        <BlackToolTip title={viewData.documents[0].document_name} placement="right" arrow>
                                                                            {viewData.documents[0].document_name.slice(0, 16) + (viewData.documents[0].document_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : viewData.documents[0].document_name
                                                                }
                                                            </>
                                                            : '-'}</Text>
                                                    </Box>
                                                </Grid>
                                                {
                                                    activeState == 'reimbursement' ?
                                                        <>
                                                            {
                                                                viewData.expense_effect_on == 'Payroll' ?
                                                                    < Grid item xs={6}>
                                                                        <Box display='flex' flexDirection='column' gap={2}>
                                                                            <Text mediumGreyHeader>Payment Mode</Text>
                                                                            <Text mediumBlack14>{viewData.payment_mode ? viewData.payment_mode : '-'}</Text>
                                                                        </Box>
                                                                    </Grid> : ''
                                                            }
                                                        </>
                                                        :
                                                        <Grid item xs={6}>
                                                            <Box display='flex' flexDirection='column' gap={2}>
                                                                <Text mediumGreyHeader>Recurring Deduction</Text>
                                                                <Text mediumBlack14>{viewData.is_recurring == false ? 'No' : viewData.is_recurring == true ? 'Yes' : '-'}</Text>
                                                            </Box>
                                                        </Grid>
                                                }
                                                {
                                                    activeState == 'reimbursement' && viewData.payment_mode !== '' &&
                                                    <>
                                                        {
                                                            viewData.payment_mode == 'Payroll cycle' ?
                                                                <Grid item xs={6}>
                                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                                        <Text mediumGreyHeader>Payment Period</Text>
                                                                        <Text mediumBlack14>{viewData.pay_period_value ? viewData.pay_period_value : '-'}</Text>
                                                                    </Box>
                                                                </Grid> :
                                                                <Grid item xs={6}>
                                                                    <Box display='flex' flexDirection='column' gap={2}>
                                                                        <Text mediumGreyHeader>Payment Date</Text>
                                                                        <Text mediumBlack14>{viewData.pay_date ? viewData.pay_date : '-'}</Text>
                                                                    </Box>
                                                                </Grid>
                                                        }
                                                    </>
                                                }
                                                {
                                                    activeState == 'reimbursement' && viewData.payment_mode == 'Off-Payroll cycle' ?
                                                        <>
                                                            <Grid item xs={12} m={'10px 0px'}>
                                                                <Text smallBlack400>Transaction Details</Text>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Transaction ID</Text>
                                                                    <Text mediumBlack14>{viewData.transaction_id ? viewData.transaction_id : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Payee Name</Text>
                                                                    <Text mediumBlack14>{viewData.payee_name ? viewData.payee_name : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Bank Name</Text>
                                                                    <Text mediumBlack14>{viewData.bank_name ? viewData.bank_name : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Last Four Digits of Bank Account</Text>
                                                                    <Text mediumBlack14>{viewData.last_digit_of_bank_account ? viewData.last_digit_of_bank_account : '-'}</Text>
                                                                </Box>
                                                            </Grid>
                                                        </> :
                                                        activeState == 'deductions' ?
                                                            <>
                                                                {
                                                                    viewData.is_recurring == true ?
                                                                    <Grid item xs={6}>
                                                                        <Box display='flex' flexDirection='column' gap={2}>
                                                                            <Text mediumGreyHeader>Re-Occur Till</Text>
                                                                            <Text mediumBlack14>{viewData.recurring_type ? viewData.recurring_type : '-'}</Text>
                                                                        </Box>
                                                                    </Grid> : ''
                                                                }
                                                                {
                                                                    viewData.is_recurring == true && viewData.recurring_type == 'Goal Amount' ?
                                                                        <Grid item container xs={12} spacing={2}>
                                                                            <Grid item xs={6}>
                                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                                    <Text mediumGreyHeader>Goal Amount</Text>
                                                                                    <Text mediumBlack14>{viewData.goal_amount ? viewData.goal_amount : '-'}</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                                    <Text mediumGreyHeader>Recovery Start Date</Text>
                                                                                    <Text mediumBlack14>{viewData.recurring_start_date ? viewData.recurring_start_date : '-'}</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                                    <Text mediumGreyHeader>No of Pay Cycles</Text>
                                                                                    <Text mediumBlack14>{viewData.recurring_count ? viewData.recurring_count : '-'}</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                                    <Text mediumGreyHeader>Recovered Amount</Text>
                                                                                    <Text mediumBlack14>{viewData.recovered_amount ? parseFloat(viewData.recovered_amount).toFixed(2) : '-'}</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                                    <Text mediumGreyHeader>No of Cycles Completed</Text>
                                                                                    <Text mediumBlack14>{viewData.cycles_completed ? viewData.cycles_completed : '-'}</Text>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid> :
                                                                        ''
                                                                }
                                                            </> : ''
                                                }
                                                <Grid item xs={12} >
                                                    <Box display='flex' flexDirection='column' gap={1}>
                                                        <Text mediumGreyHeader>Remarks</Text>
                                                        <Text
                                                            className={classes.remarksText}
                                                            sx={{
                                                                wordBreak: 'break-all',
                                                                whiteSpace: 'normal'
                                                            }}
                                                        >
                                                            {viewData.remarks ? viewData.remarks : '-'}
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                {
                                                    viewData.status == 'Approved' || viewData.status == 'Pending' || viewData.status == undefined ? '' :
                                                        <Grid item xs={12}>
                                                            <Box display='flex' flexDirection='column' gap={2}>
                                                                <Text mediumGreyHeader>Reason for Rejection</Text>
                                                                <Text mediumBlack14>
                                                                    {
                                                                        viewData.reason_for_rejection && viewData.reason_for_rejection.length > 30 ?
                                                                            <BlackToolTip title={viewData.reason_for_rejection} placement="right" arrow>
                                                                                {viewData.reason_for_rejection.slice(0, 30) + (viewData.reason_for_rejection.length > 30 ? "..." : "-")}
                                                                            </BlackToolTip>
                                                                            : viewData.reason_for_rejection
                                                                    }
                                                                </Text>
                                                            </Box>
                                                        </Grid>
                                                }
                                                {
                                                    LocalStorage.getUserData().admin_login && approver && activeState == 'reimbursement' && viewData.status == 'Pending' && viewData?.is_deleted == false ?
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Divider sx={{ margin: '10px 0px' }} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box display='flex' flexDirection='column' gap={2}>
                                                                    <Text mediumGreyHeader>Actions</Text>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'approved_amount',
                                                                            value: currency+"" +state.approved_amount,
                                                                        }}
                                                                        handleChange={(e) => changeHandler(e)}
                                                                        onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                                                                        clientInput
                                                                        labelText={'Approved Amount'}
                                                                        error={statusErrors.approved_amount ? <Text red>{statusErrors.approved_amount ? statusErrors.approved_amount : ''}</Text> : ''}
                                                                    />
                                                                    {statusErrors.approved_amount ? <Text red>{statusErrors.approved_amount ? statusErrors.approved_amount : ''}</Text> : ''}
                                                                </Box>
                                                            </Grid>
                                                        </> : ''
                                                }
                                                {
                                                    !LocalStorage.getUserData().admin_login ?
                                                        <Grid item xs={11.5} textAlign='end'>
                                                            <Button saveBtn600 onClick={() => { setOpenView(false); setValueTabs('1') }}>Done</Button>
                                                        </Grid>
                                                        :
                                                        activeState == 'deductions' ?
                                                            <Grid item xs={11.5} textAlign='end'>
                                                                <Button saveBtn600 onClick={() => { setOpenView(false); setValueTabs('1') }}>Done</Button>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12} justifyContent='end'>
                                                                <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>
                                                                    {
                                                                        viewData.status == 'Pending' && approver && viewData.is_deleted == false ?
                                                                            <Button redBorder sx={{ width: '100px !important' }} onClick={() => handleButtons('Rejected')}>Reject</Button> :
                                                                            <Button greyBorder sx={{ width: '100px !important' }} onClick={() => { setOpenView(false); setValueTabs('1') }}>Close</Button>
                                                                    }
                                                                    {
                                                                        viewData.status == 'Pending' && approver && viewData.is_deleted == false ?
                                                                            <Button saveBtn600 onClick={() => { handleButtons('Approved'); }}>Approve</Button> :
                                                                            <Button saveBtn600 onClick={() => { setOpenView(false); setValueTabs('1') }}>Done</Button>
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                }
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="2" >



                                            <Box height={'76vh'} overflow={'scroll'} sx={{ width: '100% !important' }}>
                                                {
                                                    activityData.length == 0 ?
                                                        <Grid item lg={12} md={12} xs={12} container justifyContent={'center'} alignContent={'center'} textAlign={'center'}>
                                                            <Grid item lg={12} md={12} xs={12} pl={5} container justifyContent={'center'} alignContent={'center'}>
                                                                <img src={Nodata} alt='nodata' />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} xs={12} pl={5}>
                                                                <Text popupHead1>No Logs Found Yet!</Text>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        activityData.map((item, index) => (
                                                            <Grid container lg={12} md={12} xs={12}>
                                                                <Grid item lg={2.75} md={2.75} xs={2.75}>
                                                                    <Grid item>
                                                                        <Box p={1} pt={1.9}>
                                                                            <Text smallBlue sx={{ fontWeight: '600 !important' }}>{item.created_at}</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box pl={1}>
                                                                            <Text verySmallBlack sx={{ color: "#737373 !important" }}>{item.action_by}</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid >
                                                                <Grid item lg={1} md={1} xs={1}>
                                                                    <Box p={1}>
                                                                        <img src={timelineIcon} alt='ic' />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={7} md={7} xs={7}>
                                                                    <Grid item>
                                                                        <Box p={1}>
                                                                            <Text mediumBlack>{item.activity_name}</Text>
                                                                        </Box>
                                                                    </Grid>


                                                                </Grid>
                                                                <Grid item lg={1} md={1} xs={1}>
                                                                    <Box p={1}>
                                                                        {expand === index ?
                                                                            <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} /> :
                                                                            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} />
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                {
                                                                    expand === index ?
                                                                        <Grid item container pb={2}>
                                                                            <Grid item lg={3.43} md={3.43} xs={3.43}></Grid>
                                                                            <Grid item class="tree" >
                                                                                <ul>
                                                                                    {
                                                                                        item.change_log.map((i) => (
                                                                                            <li>
                                                                                                <Text greysmallLabel>
                                                                                                    {i.length > 70 ?

                                                                                                        <BlackToolTip arrow placement='top' title={
                                                                                                            <Box p={1}>{i}</Box>
                                                                                                        }>
                                                                                                            {i.slice(0, 70)} ...
                                                                                                        </BlackToolTip>

                                                                                                        : i}
                                                                                                </Text>
                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            </Grid>
                                                                        </Grid>
                                                                        :

                                                                        ((activityData.length - 1) == index) ? '' :
                                                                            <Grid item container pb={2}>
                                                                                <Grid item lg={3.15} md={3.15} xs={3.15}></Grid>
                                                                                <Grid item mt={-0.5} md={-0.5} xs={-0.5}>
                                                                                    <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                }
                                                            </Grid>
                                                        ))}
                                            </Box>
                                        </TabPanel>
                                    </TabContext>


                                </Grid>
                            </Grid>
                        </SwipeableDrawer>

                        <ReusablePopup openPopup={openRejection} setOpenPopup={setOpenRejection} white iconHide fixedWidth>
                            <Grid container p={2} spacing={2} textAlign='center'>
                                <Grid item xs={12}>
                                    <img src={deleteAlert} alt='delete' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Text popupHead1>Are you Sure?</Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text mediumOverView>Do you want to reject reimbursements for the selected employees.</Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'reason_for_rejection',
                                            value: state.reason_for_rejection,
                                            inputProps: { maxLength: 225 }
                                        }}
                                        handleChange={(e) => changeHandler(e)}
                                        clientInput
                                        labelText={'Reason for Rejection'}
                                        error={statusErrors.reason_for_rejection ? <Text red>{statusErrors.reason_for_rejection ? statusErrors.reason_for_rejection : ''}</Text> : ''}
                                    />
                                    {statusErrors.reason_for_rejection ? <Text red sx={{ textAlign: 'start !important' }}>{statusErrors.reason_for_rejection ? statusErrors.reason_for_rejection : ''}</Text> : ''}
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                        <Button redBorder sx={{ width: '110px !important' }} onClick={() => handleRejectButtons('Rejected')}>No,Cancel</Button>
                                        <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => handleRejectButtons('Approved')}>Yes,Reject</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ReusablePopup>
                        <ReusablePopup openPopup={openApproval} setOpenPopup={setOpenApproval} white iconHide fixedWidth>
                            <Grid container p={2} spacing={2} textAlign='center'>
                                <Grid item xs={12}>
                                    <img src={approvalIcon} alt='delete' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Text popupHead1>Are you Sure?</Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text mediumOverView>Do you want to approve reimbursements for the selected employees.</Text>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                        <Button redBorder sx={{ width: '110px !important' }} onClick={() => handleApproveButtons('Rejected')}>No,Cancel</Button>
                                        <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => handleApproveButtons('Approved')}>Yes,Approve</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ReusablePopup>
                        <ReusablePopup openPopup={openSuccess} setOpenPopup={setOpenSuccess} white iconHide fixedWidth>
                            <Grid container p={2} spacing={2} textAlign='center'>
                                <Grid item xs={12}>
                                    <img src={successIcon} alt='approved' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Text popupHead1>Approved</Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text mediumOverView>Selected Reimbursement are approved successfully</Text>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Button saveBtn400 sx={{ width: "110px !important" }} onClick={() => { setOpenSuccess(false); getAllPlacements(filterData) }}>Done</Button>
                                </Grid>
                            </Grid>
                        </ReusablePopup>

                        <DeleteDeductionPopup
                            openPopup={deleteDeduction}
                            setOpenPopup={setDeleteDeduction}
                            deleteAlertImage={deleteAlert}
                            titleText={activeState == "reimbursement" ? "Are you sure?" : "Deleting Deduction!"}
                            descriptionText={activeState == "reimbursement" ?
                                "It looks like you're deleting a reimbursement. Are you sure do you want to proceed." :
                                "It looks like you're deleting a deduction. Are you sure do you want to proceed."}
                            cancelButtonText={"Cancel"}
                            ApproveButtonText={"Yes, Delete"}
                            handleCancel={handleCancelDeduction}
                            handleApprove={handleApproveDeduction}
                        />
                        {/* <ReusablePopup openPopup={deleteDeduction} setOpenPopup={setDeleteDeduction} white iconHide fixedWidth>
                            <Grid container p={2} spacing={2} textAlign='center'>
                                <Grid item xs={12}>
                                    <img src={deleteAlert} alt='delete' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Text veryLargeLabel >Deleting Deduction!</Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px !important' }}>It looks like you're deleting a recurring deduction. Would you like to waive the remaining amount or deduct it from the balance?</Text>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                        <Button selectButton sx={{ color: '#525252 !important', fontSize: '13px !important', height: "44px !important", fontWeight: '600 !important' }} onClick={() => handleCancelDeduction(false)} >Waive the amount</Button>
                                        <Button sx={{ minWidth: 'fit-content !important', fontSize: '13px !important', height: "44px !important" }} submitButtonEverify onClick={() => setDeleteDeduction(false)}>Deduct from balance</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ReusablePopup> */}
                    </Box>
                    :
                    NoPermission()
            }
        </>
    );
}