import React from 'react';
import { Grid } from '@mui/material';
import Driving from './Driving';
import SSN from './SSN';
import OtherDocument from './OtherDocument';


export default function PersonalDocuments(props) {

    const personalDocumentsConfig = props.documentApiConfig
    const drivingLicenseConfig = personalDocumentsConfig?.personalDocuments?.drivingLicense
    const ssnConfig =personalDocumentsConfig?.personalDocuments?.ssn
    const otherDocumentsConfig = personalDocumentsConfig?.personalDocuments?.otherDocuments

    return (
        <Grid container>
            <Grid container p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                <Driving formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} drivingLicenseConfig={drivingLicenseConfig}/>
            </Grid>
            <Grid container mt={2} p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                <SSN formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} ssnConfig={ssnConfig}/>
            </Grid>
            <Grid container mt={2} p={1} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                <OtherDocument formEdit={props.formEdit} setformEdit={props.setformEdit} employee={props.employee} documentPosition={props.documentPosition} otherDocumentsConfig={otherDocumentsConfig}/>
            </Grid>
        </Grid>

    );
}