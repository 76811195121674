import { Grid, Box, Divider, Stack, Skeleton, Tooltip, Avatar, useTheme, Hidden } from "@mui/material";
import Text from "../../components/customText/Text";
import LocalStorage from "../../utils/LocalStorage";
import ReactApexChart from "react-apexcharts";
import Verified from '../../assets/svg/Component87.svg';
import CustomSelect from "../../components/customSelect/CustomSelect";
import { useNavigate, Link, useLocation } from "react-router-dom";
import DashboardAPI from "../../apis/admin/DashboardAPI";
import { BlackToolTip, NoDataFound, addErrorMsg, addLoader, addLoaderPlanExpired, capitalizeAndAddSpace, removeExpiredLoader, removeLoader } from "../../utils/utils";
import DashboardStyles from '../admin/DasboardStyles';
import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/customButton/Button";
import PlacementApi from "../../apis/admin/placements/PlacementApi";
import EmployeesApi from '../../apis/admin/employees/EmployeesApi';
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import purchaseEmpty from "../../assets/svg/placementEmpty.svg"
// import disableVerified from '../../assets/client/disableVerified.svg';
import CommonApi from "../../apis/CommonApi";
import { domain } from "../../config/Domain";
import Pending from '../../assets/employee/Orange-PendingIcon.svg';
import moment from "moment/moment";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

export default function Dashboard() {

    const navigate = useNavigate()
    const classes = DashboardStyles();
    const theme = useTheme()
    const [timeSheetData, settimeSheetData] = useState({
        billed_amount_in_hours: {
            series: [],
            colors: [],
            labels: []
        }
    }); // eslint-disable-next-line
    const [bannerIndex, setbannerIndex] = useState(0);
    const [placements, setPlacements] = useState([]);
    const [Reminders, setReminders] = useState([]);
    const [employee, setEmployee] = useState({

    });
    const [loader, setLoader] = useState(false);
    const [nameLoader, setNameLoader] = useState(false);
    const [year, setyear] = useState(moment().format('YYYY')); // eslint-disable-next-line
    const [bannerData, setbannerData] = useState([]);
    const [filterData, setFilterData] = useState({
        limit: 6,
        page: 1
    });
    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 6,
            totalPages: ""
        }
    );
    const scrollBox = useRef(null);
    const [years, setYears] = useState([]);
    const location = useLocation();

    useEffect(() => {
        getEmployeeApi();
        const filter = {
            limit: 100,
            page: 1,
            search: "",
            status: [],
            start_date: [],
            end_date: [],
            clients: [],
            recruiter: [],
            timesheet_cycle: [],
            employee_id: LocalStorage.getUserData().login_id,
            sort_column: "created_at",
            sort_order: "desc"
        }
        bannerlistingApi();
        PlacementApi.getPlacementListingConsultant(filter).then((response) => {
            if (response.data.statusCode == 1003) {
                setPlacements(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        })
        listingApi();
        defaultYears();// eslint-disable-next-line       
    }, [])

    useEffect(() => {
        timesheetGraph()
        // eslint-disable-next-line  
    }, [year])


    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }

    const timesheetGraph = () => {
        DashboardAPI.timesheetGraphListingConsultant(year).then((res) => {
            if (res.data.statusCode == 1003) {
                settimeSheetData(res.data.data)
            }
        })
    }

    const bannerlistingApi = () => {
        DashboardAPI.bannerListingConsultant(year).then((res) => {
            if (res.data.statusCode == 1003) {
                setbannerData(res.data.data)
            }
        })
    }

    const listingApi = () => {
        if (Reminders.length < 6) {
            setLoader(true);
        }
        DashboardAPI.pendingRequestListingConsultant(filterData.limit).then((res) => {
            setLoader(false);
            removeLoader();
            if (res.data.statusCode == 1003) {
                setReminders(res.data.data);
                setPagination(res.data.pagination);
            } else {
                removeLoader()
                addErrorMsg(res.data.message);
            }
        })
    }

    const hoursList = [
        { color: '#9747FF', label: 'Drafted' },
        { color: '#165DFF', label: 'Pending Approval' },
        { color: '#14C9C9', label: 'Approved' },
        { color: '#F7BA1E', label: 'Rejected' }
    ]

    const areaOptions = {
        series: timeSheetData.billed_amount_in_hours.series,
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: timeSheetData.billed_amount_in_hours.colors,
            width: 2,
            curve: 'straight'
        },
        fill: {
            // type: 'gradient',
            colors: timeSheetData.billed_amount_in_hours.colors,
            // gradient: {
            //     opacityFrom: 0.4,
            //     opacityTo: 0.1,
            // }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: timeSheetData.billed_amount_in_hours.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val, index) {
                    // const allZeroExceptOne = timeSheetData.billed_amount_in_hours.series.reduce((acc, series) => 
                    //     acc + series.data.filter(v => v !== '0.00').length, 0
                    // ) <= 1;

                    // // timeSheetData.billed_amount_in_hours.series.every(series => 
                    // //     series.data.filter(v => v !== '0.00').length === 0
                    // // ) 
                    // if (allZeroExceptOne) {
                    //     // If all values are zero, show index
                    //     return val + ' Hrs';
                    // }else{
                    return val && val.toFixed(1) + 'Hrs';
                    // }
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        formatter: function (val, opts) {
                            if (val == 0) {
                                return '';
                            } else {
                                return val;
                            }
                        },
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 500
                        }
                    }
                }
            },
        },
    }

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            // const { scrollTop, scrollHeight, clientHeight } = current;
            // if (scrollTop + clientHeight >= scrollHeight - 5) {
            //     if (Number(pagination.total) >= filterData.limit) {
            //         loadMore();
            //     }
            // }
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = current;

            // Detect if the screen is small (tab or mobile)
            const isSmallScreen = window.innerWidth <= theme.breakpoints.values.md;

            if (isSmallScreen) {
                // Check for horizontal scroll
                if (scrollLeft + clientWidth >= scrollWidth - 5 && Number(pagination.total) >= filterData.limit) {
                    loadMore();
                }
            } else {
                // Check for vertical scroll
                if (scrollTop + clientHeight >= scrollHeight - 5 && Number(pagination.total) >= filterData.limit) {
                    loadMore();
                }
            }
        }
    };
    const loadMore = () => {
        addLoader(true)
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        setLoader(true);
        listingApi();
    }
    const navigatePending = (args) => {
        if (args.placement_id == '' || args.placement_id == null || args.placement_id == undefined) {
            navigate('/pending-actions', { state: { id: args.id, empId: args.employee_id, placementId: '', from: 'employee', employee: 'employee' } })
        } else {
            navigate('/pending-actions', { state: { id: '', timesheet_id: args.id, empId: args.employee_id, placementId: args.placement_id, from: 'dashboard', employee: '' } })
        }
    }

    const getEmployeeApi = () => {
        setNameLoader(true)
        EmployeesApi.getEmployeeConsultant(LocalStorage.getUserData().login_id).then((response) => {
            if (response.data.statusCode == 1003) {
                setEmployee({ ...response.data.data });
                setNameLoader(false)
            } else {
                addErrorMsg(response.data.message);
                setNameLoader(false)
            }

        });
    }

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    useEffect(() => {
        getPlanCheck()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const intervalId = setInterval(() => {

            if (bannerData.length > 1) {
                // eslint-disable-next-line
                bannerData.map((item, index) => {
                    if (bannerIndex == 0) {
                        setTimeout(() => {
                            if (bannerData.length >= 2) {
                                setbannerIndex(1)
                            }
                            else {
                                setbannerIndex(0)
                            }
                        }, 3000)
                    }
                    else if (bannerIndex == 1) {
                        setTimeout(() => {
                            if (bannerData.length >= 3) {
                                setbannerIndex(2)
                            }
                            else {
                                setbannerIndex(0)
                            }
                        }, 3000)
                    }
                    else if (bannerIndex == 2) {
                        setTimeout(() => {
                            if (bannerData.length >= 4) {
                                setbannerIndex(3)
                            }
                            else {
                                setbannerIndex(0)
                            }
                        }, 3000)
                    }
                    else if (bannerIndex == 3) {
                        setTimeout(() => {
                            if (bannerData.length >= 4) {
                                setbannerIndex(4)
                            }
                            else {
                                setbannerIndex(0)
                            }
                        }, 3000)
                    }
                    else {
                        setTimeout(() => {
                            setbannerIndex(0)
                        }, 3000)
                    }
                }
                )
            }

        }, 5000);
        return () => clearInterval(intervalId);
    });


    return (
        <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 1 }} pr={{ lg: 4, md: 2 }} pl={{ lg: 16, md: 10 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                    {
                        nameLoader ? <Skeleton animation='wave' width='100px' /> : <Text largeHeaderText>Hi, {capitalizeAndAddSpace(`${employee?.basic_details?.full_name}`)}</Text>
                    }

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box pb={3}>
                    <Text smallGrey>Here’s how your dashboard looking today</Text>
                </Box>
            </Grid>
            {
                bannerData?.length > 0 ?
                    <Grid item container lg={12} md={12} sm={12} xs={12} m={0.5} sx={{ border: '1px solid #EAECF0', borderRadius: '8px' }}>
                        <Grid item container lg={6} md={6} sm={6} xs={6} alignContent={'center'} height={'138px'}>
                            <Grid item container lg={12} md={12} sm={12} xs={12} alignContent={'center'} pl={4} pb={1} >
                                <Hidden smDown>
                                    <Text popupHead1>{bannerData?.length > 0 && bannerData[bannerIndex]?.heading}</Text>
                                </Hidden>
                                <Hidden smUp>
                                    <Text popupHead1>{bannerData?.length > 0 &&
                                        bannerData[bannerIndex]?.heading &&
                                        bannerData[bannerIndex]?.heading.length > 30 ?
                                        <BlackToolTip title={bannerData[bannerIndex]?.heading} placement="right" arrow>
                                            {bannerData[bannerIndex]?.heading.slice(0, 30) + (bannerData[bannerIndex]?.heading.length > 30 ? "..." : "")}
                                        </BlackToolTip>
                                        :
                                        bannerData[bannerIndex]?.heading
                                    }</Text>
                                </Hidden>
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} alignContent={'center'} pl={4} pt={1} >
                                <Hidden smDown>
                                    <Text greyLabel>{bannerData?.length > 0 && bannerData[bannerIndex]?.sub_text}</Text>
                                </Hidden>
                                <Hidden smUp>
                                    <Text greyLabel>{bannerData?.length > 0 &&
                                        bannerData[bannerIndex]?.sub_text &&
                                        bannerData[bannerIndex]?.sub_text.length > 80 ?
                                        <BlackToolTip title={bannerData[bannerIndex]?.sub_text} placement="right" arrow>
                                            {bannerData[bannerIndex]?.sub_text.slice(0, 80) + (bannerData[bannerIndex]?.sub_text.length > 80 ? "..." : "")}
                                        </BlackToolTip>
                                        :
                                        bannerData[bannerIndex]?.sub_text
                                    }</Text>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Grid item container lg={6} md={6} sm={6} xs={6} alignContent={'center'} height={'138px'}>
                            <img loading="lazy" src={bannerData?.length > 0 && bannerData[bannerIndex]?.banner_url} width="100%" height="138" alt="img" />
                        </Grid>
                    </Grid>
                    : ''}

            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'center'} alignContent={'center'}>
                <Stack direction={'row'}>
                    {
                        bannerData?.length > 0 ?
                            bannerData.map((item, index) => (
                                <>
                                    {
                                        bannerIndex == index ?
                                            <span key={index}
                                                onClick={() => { setbannerIndex(index) }}
                                                style={{
                                                    height: '8px',
                                                    width: '32px',
                                                    borderRadius: '10px',
                                                    background: '#0C75EB',
                                                    display: 'flex',
                                                    margin: '8px 5px 2px 0px'
                                                }}> </span>
                                            :
                                            <span key={index}
                                                onClick={() => { setbannerIndex(index) }}
                                                style={{
                                                    height: '8px',
                                                    width: '8px',
                                                    borderRadius: '50%',
                                                    background: '#D1E1FF',
                                                    display: 'flex',
                                                    margin: '8px 5px 2px 0px',
                                                    cursor: 'pointer'
                                                }}> </span>
                                    }
                                </>
                            ))
                            : ''
                    }
                </Stack>

            </Grid>


            <Grid item container lg={12} md={12} sm={12} xs={12}>
                <Grid item container lg={8} md={8} sm={12} xs={12} alignItems={"center"}>
                    <Grid item xl={7.5} xs={12} lg={7} md={7} sm={7}>
                        <Box p={1}>
                            <Text profileTitle>Timesheets</Text>
                        </Box>
                    </Grid>
                    <Grid item xl={2} xs={6} lg={2} md={2} sm={2} container justifyContent={'flex-end'}>
                        <Box width={'100%'} p={1} pt={2}>
                            <CustomSelect
                                name='placementYear'
                                viewDrop
                                value={year}
                                scrollTrue={true}
                                options={years}
                                onChange={(e) => { setyear(e.target.value) }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={2} xs={6} lg={2} md={2} sm={2} container justifyContent={'flex-end'}>
                        <Box width={'100%'} p={1} pt={2}>
                            <Button onClick={() => { navigate('/timesheet') }} scrollBtn className={classes.alldrafts}>
                                All Drafts
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box width={"100%"}>
                            <ReactApexChart
                                options={areaOptions}
                                series={areaOptions.series}
                                type="bar"
                                height='290px'
                            />
                        </Box>
                        <Grid item container xs={12} md={12} lg={12} xl={12} pt={2} spacing={{ xs: 1 }}>
                            {
                                hoursList.map((series, key) => (
                                    <Grid item lg={series.label == 'Pending Approval' ? 2 : 1.3} md={series.label == 'Pending Approval' ? 2.5 : 1.5} sm={2} xs={6}>
                                        <Stack spacing={1} direction="row" alignItems='center'>
                                            <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                            <Text smallLabelblack>{series.label}</Text>
                                        </Stack>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} sm={9} py={{ lg: 0, md: 0, sm: 2, xs: 3 }}>
                        <Text profileTitle>Placement History</Text>
                    </Grid>
                    {
                        placements.length > 0 ?
                            <Grid item xs={3} md={3} lg={3} sm={3} textAlign={'end'} py={{ lg: 0, md: 0, sm: 2, xs: 3 }}>
                                {/* <Box width={'100%'} p={1} pt={2} pl={6}> */}
                                <Text blue14 sx={{ cursor: 'pointer' }} onClick={() => navigate('/placement/dashboard', { state: { from: 'dashboard' } })}>View All</Text>
                                {/* </Box> */}
                            </Grid> : ''
                    }
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: placements.length == 2 ? '20vh' : placements.length == 4 ? '30vh' : '40vh', overflow: 'scroll' }} pt={{ lg: 0, md: 0, sm: 2, xs: 1 }}>
                        {
                            placements.length == 0 ?
                                <Grid container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} >
                                    <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={'center'} alignContent={'center'}>
                                        <img src={purchaseEmpty} alt='img' />
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12} pt={4} justifyContent={'center'} alignContent={'center'}>
                                        <Text offBoardHeadFont sx={{ color: '#737373 !important', fontSize: '18px !important', fontWeight: `${700} !important` }}>No Placement Found</Text>
                                    </Grid>
                                </Grid>
                                :
                                placements.map((place, key) => (
                                    <Grid container lg={11} md={11} sm={11} xs={11} ml={1} mb={1} justifyContent='center' alignItems='center' mt={key === 0 ? 0 : 2}>
                                        <Grid item lg={4} md={6} sm={4} xs={12} textAlign={"start"}>
                                            <Box display='flex' alignItems='center' gap={2} state={{ id: place.employee_id, placement_id: place.id }} component={Link} to={`/placements/view-placement`}
                                                onClick={() => { LocalStorage.removeEmployeeVerifyId(); LocalStorage.removeRedirectedModule(); LocalStorage.removeNotificationId(); }}
                                            >
                                                <Avatar
                                                    alt={place.client_name[0]}
                                                    src={place.client_logo_url ? place.client_logo_url : place.client_name[0]}
                                                    sx={{
                                                        width: 50, height: 50, backgroundColor: '#e5b256 !important', borderRadius: '50%',
                                                        [theme.breakpoints.down('sm')]: {
                                                            width: 30, height: 30,
                                                        }
                                                    }}
                                                />
                                                <Box>
                                                    {
                                                        place.status == 'In Active' ?
                                                            <Text
                                                                mediumBoldBlack
                                                                style={{ margin: '4px 0', cursor: 'pointer' }}
                                                                  >
                                                                {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(place.client_name)
                                                                }
                                                            </Text>
                                                            :
                                                            <Text mediumBoldBlack
                                                                 sx={{ color: '#0C75EB !important', cursor: 'pointer' }}
                                                            >
                                                                {place.client_name === "" ? "--" :
                                                                    capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                        <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                            {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                        </HtmlTooltip>
                                                                        :
                                                                        capitalizeAndAddSpace(place.client_name)
                                                                }
                                                            </Text>

                                                    }
                                                    <Text mediumLabel style={{ margin: '4px 0' }}>  {place.placement_reference_id ? place.placement_reference_id : "--"}</Text>

                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={8} textAlign={"start"}>
                                            <Text smallBoldBlack >{place.start_date ? place.start_date : "--"} - {place.end_date ? place.end_date : "Current"}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={2} sm={4} xs={4} textAlign={"center"}>
                                            {
                                                place.status == 'In Active' ?
                                                    <Text sx={{ color: '#737373', font: '12px Quicksand', fontWeight: '700' }}>Ended</Text>
                                                    : <Text sx={{ color: '#22C55E', font: '12px Quicksand', fontWeight: '700' }}>Active</Text>
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                    </Grid>
                </Grid>
                {/* Request History */}
                <Grid item container lg={4} md={4} sm={12} xs={12} pt={2}>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Box p={1} pt={2}>
                            <Text profileTitle>Request History</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} container justifyContent={'flex-end'}>
                        <Box p={1} pt={2}>
                            <Text blue14 sx={{ cursor: 'pointer' }} onClick={() => navigate('/pending-actions', { state: { from: 'dashboard' } })}>View All</Text>
                        </Box>
                    </Grid>
                    <Grid item container lg={12} xs={12} sm={12} md={12} pt={3}>
                        <Box className={classes.empcardContainer}>
                            {
                                loader ?
                                    <Box className={classes.sidecard}>
                                        <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                                            <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                                                <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                                                <Stack gap='8px'>
                                                    <Skeleton animation='wave' width='100px' />
                                                    <Skeleton animation='wave' width='100px' />
                                                </Stack>
                                            </Stack>
                                            <Stack alignContent={'center'} alignItems={'center'}>
                                                <Skeleton animation='wave' width='50px' />
                                            </Stack>
                                        </Stack>
                                        <Divider sx={{ width: '100%', border: '1px solid #F5F5F5' }} />
                                        <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'} p={'10px'}>
                                            <Stack direction={'column'} justifyContent={'space-between'}>
                                                <Skeleton animation='wave' width='100px' />
                                                <Skeleton animation='wave' width='100px' />
                                            </Stack>
                                            <Skeleton animation='wave' width='100px' />
                                        </Stack>
                                    </Box> :
                                    Reminders.length == 0 ?
                                        NoDataFound('') :
                                        <Grid item lg={12} xs={12} sm={12} pl={1} className={classes.empcardContainer} ref={scrollBox} onScroll={handleScroll}>
                                            {
                                                Reminders.length > 0 ? Reminders.map((item) => (
                                                    <Grid item container ml={.5} lg={11} xs={12} md={12} sm={12}
                                                        onClick={() => navigatePending(item)}
                                                        className={classes.sideCardbg} justifyContent='end'
                                                    >
                                                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} p={{ lg: 2, md: 1, sm: 1, xs: 1 }}>
                                                            <Grid item xl={2} md={3} lg={2} sm={2} xs={3}>
                                                                <Avatar
                                                                    src={item.profile_picture_url ? `${item.profile_picture_url}?w=40&h=40&fit=crop` : ''}
                                                                    alt={item.display_name ? capitalizeAndAddSpace(item.display_name[0]) : ''}
                                                                    sx={{ height: '40px', width: '40px', borderRadius: '50%', background: item.profile_picture_url ? '' : '#e5b256 !important' }}
                                                                />
                                                            </Grid>
                                                            <Grid item xl={7} md={9} lg={7} sm={7} xs={9} pl={1}>
                                                                <Text dashboardblack14>
                                                                    {
                                                                        item.display_name ? item.display_name.length > 16 ?
                                                                            <BlackToolTip title={capitalizeAndAddSpace(item.display_name)} placement="top" arrow>
                                                                                {capitalizeAndAddSpace(item.display_name.slice(0, 16)) + capitalizeAndAddSpace(item.display_name.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : capitalizeAndAddSpace(item.display_name) : '--'
                                                                    }
                                                                    {
                                                                        item.employee_e_verified == 1 || item.employee_e_verified == 4 ?
                                                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                                <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                            </BlackToolTip>
                                                                            : item.employee_e_verified == 2 ?
                                                                                <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is pending</Text>}>
                                                                                    <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                </BlackToolTip> : ''
                                                                    }                                                                </Text>
                                                                {
                                                                    (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                                        <Text greysmallLabel>{item.reference_id}</Text> :
                                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                            <Text greysmallLabel>{item.client_reference_id}</Text>
                                                                            <Box className={classes.greyDot} />
                                                                            <Text greysmallLabel>{item.placement_reference_id ? item.placement_reference_id : '-'}</Text>
                                                                        </Box>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={2.5} md={12} sm={3} xs={12} textAlign={{ lg: "center", md: "-webkit-right", sm: "-webkit-right", xs: "-webkit-right" }}>
                                                                <Box textAlign='center' className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' || item.status == 'Pending Approval' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : classes.drafted}>
                                                                    <Text mediumWhite400>{item.status == 'Pending Approval' ? 'Pending' : item.status}</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Divider sx={{
                                                                width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5',
                                                                [theme.breakpoints.down('md')]: {
                                                                    borderWidth: 0,
                                                                    borderStyle: 'solid',
                                                                    borderColor: 'rgba(0, 0, 0, 0.12)',
                                                                    borderBottomWidth: 'thin',
                                                                    height: "2px",
                                                                }
                                                            }} />
                                                            {
                                                                (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                                    <>
                                                                        <Grid item xl={8} lg={9} xs={8} md={6} sm={6}>
                                                                            <Text greysmallLabel>Employee Type</Text>
                                                                            <Text dashboardblack14>{item.employee_type ? item.employee_type : '-'}</Text>
                                                                        </Grid>
                                                                        <Grid item container xl={4} lg={3} md={6} sm={6} xs={4} alignItems={"center"} justifyContent={"end"}>
                                                                            <Text mediumBlue600>Profile Update</Text>
                                                                        </Grid>
                                                                    </> :
                                                                    <Grid container alignItems='center'>
                                                                        <Grid item xl={8} lg={9} xs={8} md={6} sm={6}>
                                                                            <Text greysmallLabel>Client Name</Text>
                                                                            <Text dashboardblack12>
                                                                                {
                                                                                    item.client_name ? item.client_name.length > 16 ?
                                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                                            {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                                        </BlackToolTip>
                                                                                        : capitalizeAndAddSpace(item.client_name) : '--'
                                                                                }
                                                                            </Text>
                                                                        </Grid>
                                                                        <Grid item container xl={4} lg={3} md={6} sm={6} xs={4} alignItems={"center"} justifyContent={"end"}>
                                                                            <Text mediumBlue600>Timesheet</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )) : ''
                                            }
                                        </Grid>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
