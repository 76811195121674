import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const EmployeeVerifyStyles = makeStyles(() => ({
    sideBarBox: {
        height: "75vh",
        width: "82%",
        padding: "0px 25px !important",
        marginLeft: '6px !important',
        overflowY: 'auto', overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            display: "none !important"
        },
        [useTheme().breakpoints.down('lg')]: {
            padding: "0px 0px !important",
        },
        [useTheme().breakpoints.down('md')]: {
            width: "99%",
            height: "26vh",
            overflowY: 'hidden', overflowX: 'auto',
        }
    },
    innerSideBox: {
        width: "100%",
        border: "1px solid #E2E5E6",
        borderRadius: "4px",
        padding: "15px",
        height: "75vh",
        overflowY: 'auto', overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            display: "none !important"
        },
        [useTheme().breakpoints.down('md')]: {
            display:"flex",
            gap:"15px",
            height: "26vh",
            width: "95%",
            overflowY: 'hidden', overflowX: 'auto',
        }
    },
    imageBox: {
        position: "relative",
        display: "inline-flex",
        overflow: 'auto',
        cursor: "pointer",
        [useTheme().breakpoints.down('md')]: {
            minWidth: "fit-content !important",
            height: "140px",
        }
    },
    activeImageBox: {
        border: "2px solid #0C75EB !important",
        borderRadius: "4px",
    },
    image: {
        height: "140px",
        width: "120px",
        borderRadius: "4px !important",
        [useTheme().breakpoints.down('sm')]: {
            width: "120px"
        }
    },
    activeImage: {
        position: 'absolute',
        right: 0,
        height: "20px !important",
        paddingRight: "11px !important",
        marginTop: "8px !important",
        borderRadius: "8px !important"
    },
    cropOverlay: {
        position: 'absolute',
        height: "140px",
        width: "100%",
        top: 0,
        left: 0,
        opacity: "20%",
        backgroundColor: '#313131', // Set your desired background color and transparency
    },
    rejectImage: {
        height: "132px",
        width: "150px",
        position: 'absolute',
        zIndex: 10,
        [useTheme().breakpoints.down('lg')]: {
            width: "100px",
            height: "102px",
        },
        [useTheme().breakpoints.down('sm')]: {
            width: "70px",
            height: "60px",
            right:'15px',
            marginTop:"35px"
        }  
    },
    middleGrid: {
        background: "#F6F6F6",
        borderRadius: "8px",
        [useTheme().breakpoints.down('md')]: {
            marginRight: "10px !important",
        }
    },
    xsImg:{
        [useTheme().breakpoints.down('sm')]: {
            width: "30px !important",
        }
    },
    middleImageBox: {
        height: "75vh",
        width: "100%",
        padding: "40px 70px ",
        textAlign: "left",
        overflow: "auto !important",
        "&::-webkit-scrollbar": {
            display: "none !important"
        },
        [useTheme().breakpoints.down('md')]: {
            padding: "40px 10px ",
        } ,
        [useTheme().breakpoints.down('sm')]: {
            padding: "10px 10px ",
        } 
    },
    middleImage: {       
        width: "-webkit-fill-available",
        paddingTop: "80px !important",
        margin: 'auto',
        [useTheme().breakpoints.down('md')]: {
            width: "100%",
        }
    }, 
    noVoidChequeSidebar: {
        height: "140px",
        width: "120px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "4px !important"
    },
    documentNameBox:{
        border: "1px solid #EAECF0", width: "100%", height: "100%", display: "flex", gap: "10px", alignItems: "center",padding:"10px",borderRadius:"8px" 
    },
    paper: {
        position: 'absolute',
        borderRadius: '8px 8px 8px 8px !important',
        background: '#FFFFFF !important',
        width: "400px !important"
    },
}))

export default EmployeeVerifyStyles;