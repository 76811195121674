import { makeStyles } from "@mui/styles";
import { blue, btnBgBlue, red } from "../../../../theme";
import { useTheme } from "@mui/material";

const ClientAndEndClientStyles = makeStyles(() => ({
    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },

    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    addIcon: {
        color: `${blue} !important`,
        cursor: 'pointer !important'
    },
    removeIcon: {
        color: `${red} !important`,
        cursor: 'pointer !important'
    },
    infoIcon: {
        height: '20px',
        width: '20px'
    },
    clickHere: {
        textDecoration: `1px underline ${blue} !important`, cursor: 'pointer'
    },
    dropZone: {
        background: '#F0F7FF',
        border: `1px dashed #0C75EB !important`,
        padding: '20px',
        borderRadius: '12px',
        // cursor:'unset'
    },
    chipLabel: {
        font: "12px Quicksand !important",
        letterSpacing: '0px',
        color: `${btnBgBlue.shade4} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        margin: '8px 8px 0px 0px !important'
    },
    btnBox1: {
        width: "100% !important", padding: "8px 10px !important", borderRadius: "12px !important", backgroundColor: "#F4F8FF !important", display: "flex"
    },
    activeButton1: {
        font: "15px Quicksand !important", minWidth: "100% !important", borderRadius: "8px !important", height: "35px !important"
    },
    inActiveButton1: {
        font: "15px Quicksand !important", minWidth: "100% !important", borderRadius: "8px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important"
    },
    error: {
        font: '15px Quicksand !important',
        opacity: 1,
        "&.MuiInputBase-input": {
            padding: '25.41px 12px 10px 0px !important',
            height: '17px',
            color: '#262626 !important',
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        "&:disabled": {
            background: "#FAFAFA !important",
            borderRadius: '8px !important',
            border: '1px solid #FAFAFA !important',
            '-webkit-text-fill-color': "#525252 !important",
        },
    },
    error1: {
        font: '15px Quicksand !important',
        opacity: 1,
        "&.MuiInputBase-input": {
            padding: '25.41px 12px 10px 0px !important',
            height: '17px',
            color: '#262626 !important',
            font: '14px Quicksand !important',
            fontWeight: `${600} !important`,
        },
        "&:disabled": {
            background: "#FAFAFA !important",
            borderRadius: '8px !important',
            border: '1px solid #FAFAFA !important',
            '-webkit-text-fill-color': "#525252 !important",
        },
    },

    clientInput: {
        font: '14px Quicksand !important',
        background: "#FFFFFF !important",
        opacity: 1,
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        "&:focus": {
            "&.MuiFormLabel-root.MuiInputLabel-root": {
                color: `${blue} !important`
            },
        },
    },
    payrateNote: {
        minHeight: '60px', width: '100%', borderRadius: '8px', background: '#F0F5FF', textAlign: 'center', alignItems: 'center', padding: '14px 12px'
    },
    clickHereText: {
        color: '#0C75EB', font: '14px Quicksand', fontWeight: '400px', cursor: 'pointer'
    },
    blackHeader1: {
        [useTheme().breakpoints.down('md')]: {
            font: '13px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },
    card: {
        "&.MuiCard-root": {
            padding: "10px !important",
            boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important",
            borderRadius: "12px !important",
        },
        [useTheme().breakpoints.down("sm")]: {
            "&.MuiCard-root": {
                padding: "0px !important",
            },
        },
    },

    cardContent: {
        padding: '20px 30px 30px 30px !important',
        [useTheme().breakpoints.down('lg')]: {
            padding: '10px !important',
        },
    },

    ycButtons: {
        width: "122px !important", height: "44px !important",
        [useTheme().breakpoints.down('sm')]: {
            width: 'fit-content !important',
            paddingRight: "20px !important",
            paddingLeft: "20px !important"
        },
    }


}))

export default ClientAndEndClientStyles;
