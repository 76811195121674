import { makeStyles } from "@mui/styles";
import { btnBgGrey } from "../../../theme";
import { useTheme } from "@mui/material";

const PaymentsStyles = makeStyles(() => ({

    ActiveBg: {
        background: "#0C75EB", height: '35px', padding: "6px 15px", borderRadius: "8px", cursor: "pointer", display: "flex", alignItems: 'center', justifyContent: 'center',
        "@media (min-width: 300px) and (max-width: 380px)": {
            padding: "6px 7px !important",
        },

    },
    inactiveBg: {
        background: "transparent", padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer",
        "@media (min-width: 300px) and (max-width: 375px)": {
            padding: '12px 3px 12px 3px'
        },
    },
    tabBg: {
        display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3,
    },
    addIcon: {
        height: '22px', width: '22px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        }
    },
    zohoIcon: {
        height: '24px', width: '24px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    zohoRefresh: {
        height: '24px', width: '24px', marginLeft: '7px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    syncedSpan: {
        display: 'flex',
        justifyContent: 'center',
    },
    searchIcon: {
        color: `${btnBgGrey.shade4} !important`, paddingRight: '10px !important'
    },
    menuItem: {
        height: "35px",
        "&:hover": {
            background: 'none !important'
        },
        "&:focus": {
            background: 'none !important'
        }
    },
    divider: {
        margin: "0px !important", color: '#E2E5E6 !important'
    },
    newCustomAccordion: {
        padding: "2px 0px !important",
        backgroundColor: "#ffffff !important",
        margin: "2px 0px !important",
        borderRadius: "12px !important",
        boxShadow: "5px 5px 10px 0px #0000000D !important",
        '&:before': { display: "none" },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        },
    },
    AccordionSummary: {
        // backgroundColor: "#ffffff",
        height: "70px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",

    },

    AccordionDetails: {
        backgroundColor: "#F9FCFF",
        height: "80px",
        borderRadius: "16px",
        // width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    activeStatus: {
        width: '100px !important', borderRadius: "20px", padding: "8px 3px", font: '13px Quicksand', fontWeight: `${600} !important`, color: '#ffffff', textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"
    },
    inactiveStatus: {
        height: "25px", width: '100px !important', borderRadius: "20px", padding: "3px", background: '#494747', font: '14px Quicksand', fontWeight: `${600} !important`, color: '#fff', textAlign: "center",
    },
    infoIcon: {
        height: '16px', width: '16px', margin: '0px 0px -2px 4px'
    },
    statusGrid: {
        cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "end", gap: "3px",
    },
    dividerContact: {
        width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important'
    },
    scrollContent: {
        maxHeight: "64.8vh",
        overflowY: 'scroll !important',
        alignItems: 'start',
        justifyContent: 'start',
        paddingLeft: '5px',
        paddingRight: '10px',
        marginTop: '15px !important',
        "@media (min-height: 850px)": {
            maxHeight: '72.5vh'
        }
    },

    padding: {
        padding: '20px 0px 0px 0px !important'
    },
    padding8: {
        paddingTop: '8px !important'
    },
    paddingTop: {
        padding: '20px 0px !important'
    },
    activeMenuItem: {
        font: "12px Quicksand !important",
        fontWeight: `${600} !important`,
        color: "#000000 !important",
        height: "35px !important",
        background: "#EAECF0 !important"
    },
    EmployeesSearchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    borderButton: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "44px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important"
    },
    quickbooksbg: {
        background: '#DFFCE9 !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    arrowSize: {
        width: '20px !important',
        height: '20px !important',
        cursor: 'pointer !important'
    },
    zohobooksbg: {
        background: '#C8DBEC !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    verifiedIcon: {
        width: '32px !important',
        height: '32px !important'
    },
    qbverifiedBox: {
        background: '#15803D',
        borderRadius: '8px',
        padding: '20px 30px',
        marginTop: '20px',
        textAlign: 'center !important',
    },
    greyAvatar: {
        filter: 'grayscale(100%)',
        opacity: 0.2,
        width: '24px !important',
        height: '24px !important'
    },
    activeProfile: {
        width: '24px !important',
        height: '24px !important'
    },


    // Invoice Configuration
    boxContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "64px",
        height: "52px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        cursor: "pointer",
        textAlign: 'center'
    },
    percentileIcon: {
        fontSize: "18px",
        fontWeight: "bold",
        paddingRight: "8px",
        justifyContent: 'center'
    },
    verticalDivider: {
        height: "100%",
        backgroundColor: "#ccc",
        // margin: "0 8px",
    },
    arrowImage: {
        width: '16px', height: '16px', marginLeft: '8px', cursor: 'pointer !important'
    },

    //filter drawer
    listItem: {
        padding: "0px !important",
        height: "53px !important",
        cursor: "pointer"
    },
    listItemActive: {
        padding: "0px !important",
        height: "53px !important",
        background: "#E4F1FF !important",
        cursor: "pointer"
    },
    startDateBox: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: '30px !important',
        height: '14px !important',
        borderRadius: '50px',
        opacity: '0px',
    },
    circularIcon: {
        height: "14px !important",
        width: "14px !important"
    },


    ///

    flexBox: {
        display: "flex", justifyContent: "center", alignItems: "center"
    },
    statusIcon: {
        cursor: "pointer",
        marginBottom: " -4px",
        marginLeft: "4px",
    },
    tooltipText: {
        fontSize: "12px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    blueText: {
        font: '12px  Quicksand !important',
        fontWeight: `${600} !important`,
        color: `#0C75EB !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '10px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },

    importingBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        height: '450px',
        [useTheme().breakpoints.down('md')]: {
            height: '350px',
        }
    },

    importingBox2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '200px',
    },

    linkText: {
        font: "12px Quicksand !important",
        fontWeight: "400 !important",
        color: "#0C75EB !important"
    },
    colWidth: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 140
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 140
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 300
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 245
        },
    },
    colWidth2: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 100
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 120
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 200
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 200
        },
    },
    colWidth3: {
        "@media (min-width: 0px) and (max-width: 424px)": {
            minWidth: 200,
            marginLeft: 20
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 80
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 110
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 180,
            marginLeft: 20
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 210,
            marginLeft: 20
        },
    },
    colWidth4: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 100
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 120
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 190
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 260,

        },
    },
    colWidth5: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 85
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 120
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 160
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 240
        },
    },
    colWidth6: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 180
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 90
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 100
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 140
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 215
        },
    },
    colWidth7: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200,
            marginLeft: 30
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 80
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 160,
            marginLeft: 20
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 200,
            marginLeft: 10
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 310,
            marginLeft: 50
        },
    },
    colWidth8: {
        "@media (min-width: 0px) and (max-width: 599px)": {
            minWidth: 200
        },
        "@media (min-width: 425px) and (max-width: 799px)": {
            minWidth: 100
        },
        "@media (min-width: 800px) and (max-width: 1199px)": {
            minWidth: 120
        },
        "@media (min-width: 1200px) and (max-width: 1599px)": {
            minWidth: 200
        },
        "@media (min-width: 1600px) and (max-width: 2560px)": {
            minWidth: 260
        },
    },
    scrollBox:{
        width: '100%',
        height: "54.5vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
        paddingLeft: '5px', marginTop: "6px",
        "@media (min-height: 850px)": {
            height: '63.5vh'
        },
    },
    blinking: {
        borderRadius: '8px',
        transition: "all .3s ease",
        boxShadow: '0px 0px 15px 1px #cce9ffeb !important',
        animation: 'blinking 1s infinite'
    }, 
}))

export default PaymentsStyles;