import React, { useState } from 'react'
import { Grid, Box, Slide, DialogContent, Dialog, useTheme, Typography, FormControl, TextField, InputAdornment } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Select from '../../../../components/select/Select';
import Button from '../../../../components/customButton/Button';
import Input from '../../../../components/input/Input';
import CustomRadioGroup from '../../../settings/configurations/configComponents/radio/CustomRadio';
import ViewStyles from './ViewStyles';
import RemoveIcon from '@mui/icons-material/Remove';// eslint-disable-next-line
import { float_validation, isValid, isValidMulti, onNumberWithTwoDecimalOnlyChange, onNumberOnlyChange, validate_emptyField, validate_toHours, onNumberFloatCurrency, onFloatOnlyChange, validates_float, validate_input_fields } from '../../../../components/Validation';
import { styled } from "@mui/material/styles";
import approvalDelete from '../../../../assets/svg/approvalDelete.svg';
import { addErrorMsg, addSuccessMsg, addWarningMsg } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import info from '../../../../assets/svg/fi_info.svg'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { inputLabelClasses } from "@mui/material/InputLabel";
import blueinfo from '../../../../assets/svg/placement/blueinfo.svg';
import redInfo from '../../../../assets/svg/redInfo.svg';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from '../../../../utils/ErrorMessages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

function PayRateConfigurationForm(props) {
    // eslint-disable-next-line
    const { edit, setEdit, state, setState, placementID, getPlacementPayRateData, checkDates, upcomingDates, payRateDetails } = props;
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    // const percentage = '%';
    const classes = ViewStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const payTypes = require('../../../../utils/jsons/PayType.json');
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const options = [
        { value: 1, label: <Text largeGrey16>Same as Payrate</Text> },
        { value: 2, label: <Text largeGrey16>Fixed Value</Text> },
        { value: 3, label: <Text largeGrey16>Variable</Text> },
    ];
    const [deleteLevelOpen, setDeleteLevelOpen] = useState(false);
    const [deleteLevelIndex, setDeleteLevelIndex] = useState(null);
    const [docError, setDocError] = useState([]);
    const [errors, setErrors] = useState({});
    const [focusIndex, setFocusIndex] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    // const [cycleName, setCycleName] = useState('');

    // useEffect(() => {
    //     getTimesheetCycle(placementID)// eslint-disable-next-line
    // }, []);

    // const getTimesheetCycle = (id) => {
    //     PlacementApi.getPlacementIndex("timesheet-details", id).then((res) => {
    //         if (res.data.statusCode === 1003) {
    //             setCycleName(res.data.data.timesheet_details.timesheet_cycle)
    //         }
    //     })
    // }

    const handleTabChange = (param) => {

        state.pay_config_type = param
        let errors = {};
        let err = [];// eslint-disable-next-line
        for (var i in state.pay_details) {
            state.pay_details[i].value = "";
            errors = {};
            errors.value = ""
            err.push(errors)
        }
        setState({ ...state });
        setDocError(err);
    }

    const clickHandler = () => {
        navigate('/configuration', { state: { from: 'placement' } });
    }

    const handleClick = () => {
        if (state.pay_details.length > 1 && state.pay_details[state.pay_details.length - 1].from_hour == "") {
            let errors = {};
            let err = [];// eslint-disable-next-line
            state.pay_details.map((value, index) => {
                errors = {};
                errors.to_hour = state.pay_details.length !== (index + 1) && validate_toHours(value.to_hour, value.from_hour);
                errors.value = validates_float(value.value);
                err.push(errors)
            })
            setDocError(err);
            addErrorMsg(`You can't perform this action before entering the above To Hours.`);
            return false;
        } else {
            state.pay_details[state.pay_details.length - 1].to_hour = "";
        }
        var newObj = {
            from_hour: "",
            to_hour: "",
            value: ""
        }

        state.pay_details.push(newObj);
        setState({ ...state });
    }

    const changeHandler = (e, index) => {
        if (index !== undefined && index !== false) {
            if (e.target.name == "to_hour") {
                state.pay_details[index][e.target.name] = e.target.value;
                state.pay_details[0]['from_hour'] = 0;
                state.pay_details[index + 1]['from_hour'] = e.target.value !== "" ? Number(e.target.value) + 1 : "";
                if (state.pay_details.length > 1) {
                    for (let i = index; i < state.pay_details.length; i++) {
                        if (i + 1 !== state.pay_details.length) {
                            let val = state.pay_details[i + 1][e.target.name] === '' ? 0 : state.pay_details[i + 1][e.target.name]
                            if (val > state.pay_details[i][e.target.name]) {
                                let length = i + 1
                                for (let y = length; y <= state.pay_details.length; y++) {
                                    if (state.pay_details.length > y) {
                                        state.pay_details[y][e.target.name] = ''
                                        state.pay_details[y]['from_hour'] = ''
                                    }

                                }
                                break;
                            }
                        }
                    }
                }
                if (docError.length === 0) {
                    for (let i = 0; i <= index + 1; i++) {
                        docError.push({ from_hour: '' })
                    }
                    docError.filter(value => JSON.stringify(value) !== '{}');
                }
                if (docError[index + 1] !== undefined) {
                    docError[index + 1]['from_hour'] = '';
                }
            }
            else if (e.target.name === "value") {
                handleValidatePayConfig(e.target, index)
                if (state.pay_config_type == 1) {
                    if (e.target.value > payRateDetails?.value?.value1?.maxValue) {
                        addWarningMsg(payRateDetails?.value?.value1?.maxValueMsg);
                        return false;
                    } else {
                        state.pay_details[index][e.target.name] = e.target.value;
                    }
                } else {
                    var value = e.target.value.trim();
                    // value = value.replace(LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$', "");
                    if (value.length > payRateDetails?.value?.value2?.maxLength) {
                        const num = parseFloat(value);
                        const isFloat = value.includes('.') || num % 1 !== 0;
                        if (isFloat) {
                            state.pay_details[index][e.target.name] = value;
                        } else {
                            if (value.length === 5) {
                                if (value.slice(-1) === ".") {
                                    state.pay_details[index][e.target.name] = value;
                                } else {
                                    return false;
                                }
                            }
                        }
                    } else {
                        state.pay_details[index][e.target.name] = value;
                    }
                }
            } else {
                state.pay_details[index][e.target.name] = e.target.value;
            }
            setState({ ...state }, handleValidatePayConfig(e.target, index));
        } else {
            if (e.target.name === "pay_type") {
                state[e.target.name] = e.target.value;
                if (e.target.value === 2) {
                    state.pay_config_type = '';
                    state.ot_pay_config_type = "";
                    state.ot_pay_config_value = "";
                    state.ot_pay_config_multi = "";
                    state.payroll_configuration_id = "";
                    state.pay_details = [];
                    setState({ ...state });
                    handleValidate(e);
                    setDocError([]);
                } else if (e.target.value === 1) {
                    state.pay_config_type = 1;
                    state.payroll_pay = "";
                    state.payroll_configuration_id = "";
                    state.annual_pay = "";
                    // state.payroll_cycle = "";
                    state.pay_details = [{ from_hour: 0, to_hour: "", value: "" }];
                    setState({ ...state });
                    handleValidate(e);
                    setErrors({});
                } else {
                    setState({ ...state });
                    handleValidate(e)
                    errors['payroll_pay'] = "";
                    errors['annual_pay'] = "";
                    errors['payroll_configuration_id'] = "";
                    setErrors({ ...errors });
                    setDocError([]);
                }
            } else if (e.target.name == "ot_pay_config_value" || e.target.name === "payroll_pay") {
                if (e.target.name == "ot_pay_config_value" && (state.ot_pay_config_type == 3 || state.ot_pay_config_type == "3")) {
                    var val = e.target.value !== "" ? Number(e.target.value) : "";
                    if (val > payRateDetails?.ot_pay_config_value?.value3?.maxValue) {
                        addWarningMsg(payRateDetails?.ot_pay_config_value?.value3?.maxValueMsg);
                        return false;
                    } else {
                        state[e.target.name] = e.target.value;
                        setState({ ...state });
                        handleValidate(e);
                        if (val === "") {
                            errors['ot_pay_config_value'] = validate_input_fields(val, payRateDetails?.ot_pay_config_value?.value3);
                            setErrors({ ...errors })
                        }
                    }
                } else {
                    // eslint-disable-next-line
                    var value = e.target.value.trim();
                    value = value.replace(LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$', "");
                    if (value.length > payRateDetails?.ot_pay_config_value?.value2?.maxLength) {
                        const num = parseFloat(value);
                        const isFloat = value.includes('.') || num % 1 !== 0;
                        if (isFloat) {
                            state[e.target.name] = value;
                        } else {
                            if (value.length === 5) {
                                if (value.slice(-1) === ".") {
                                    state[e.target.name] = value;
                                } else {
                                    return false;
                                }
                            }
                        }
                    } else {
                        state[e.target.name] = value;
                    }
                    setState({ ...state }, handleValidateCurrency(e.target.name, value));
                }
            } else if (e.target.name === "annual_pay") {// eslint-disable-next-line
                var value = e.target.value.trim();
                value = value.replace(LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$', "");
                if (value.length > payRateDetails?.annual_pay?.maxLength) {
                    const num = parseFloat(value);
                    const isFloat = value.includes('.') || num % 1 !== 0;
                    if (isFloat) {
                        state[e.target.name] = value;
                    } else {
                        if (value.length === 10) {
                            if (value.slice(-1) === ".") {
                                state[e.target.name] = value;
                            } else {
                                return false;
                            }
                        }
                    }
                } else {
                    state[e.target.name] = value;
                }
                setState({ ...state }, handleValidateCurrency(e.target.name, value));
            } else if (e.target.name === "payroll_pay") {
                return false;
            } else {
                state[e.target.name] = e.target.value;
                setState({ ...state });
                handleValidate(e);
            }
        }
    }

    const handleRadio1Change = (event) => {

        state.ot_pay_config_type = event.target.value;
        state.ot_pay_config_value = "";

        errors['ot_pay_config_value'] = "";
        setErrors({ ...errors })
        setState({ ...state })
    };

    const handleDeleteLevelOpen = (index) => {
        setDeleteLevelOpen(true);
        setDeleteLevelIndex(index);
    }

    const handleRemove = (index) => {

        if (state.pay_details[index].id !== "") {
            state['pay_details_deleted_ids'].push(state.pay_details[index].id);
        }

        state.pay_details.splice(index, 1);
        if (index !== 0 && state.pay_details.length > 2) {
            if (state.pay_details[index] != undefined) {
                state.pay_details[index].from_hour = Number(state.pay_details[index - 1].to_hour) + 1
            } else {
                state.pay_details[index - 1].from_hour = Number(state.pay_details[index - 2].to_hour) + 1
            }
        }
        if (state.pay_details.length == 2) {
            state.pay_details[1].from_hour = state.pay_details[0].to_hour !== "" ? Number(state.pay_details[0].to_hour) + 1 : "";
            state.pay_details[1].to_hour = ""
        }
        if (state.pay_details.length == 1) {
            state.pay_details[0].from_hour = 0
            state.pay_details[0].to_hour = ""
        }
        state.pay_details[state.pay_details.length - 1].to_hour = "";
        let errors = {};
        let err = [];// eslint-disable-next-line
        state.pay_details.map((value, index) => {
            errors = {};
            errors.to_hour = state.pay_details.length !== (index + 1) && validate_toHours(value.to_hour, value.from_hour);
            errors.value = state.pay_config_type == 2 ? validate_input_fields(value.value, payRateDetails?.value?.value2) : validate_input_fields(value.value, payRateDetails?.value?.value1);
            err.push(errors)
        })
        setDocError(err);

        setState({ ...state });
        setDeleteLevelOpen(false);
        setDeleteLevelIndex(null);
    }

    const handleSubmit = () => {
        let error = formValidations();
        let multiErrors = validateMultiple();
        if (isValid(error) && (state.pay_type == 1 ? isValidMulti(multiErrors) : [])) {
            setSaveLoading(true);
            if (state.pay_type == 2) {
                state.pay_details = [];
                setState({ ...state });
            }
            const data = {
                request_id: LocalStorage.uid(),
                placement_id: placementID,
                pay_rate_details: state
            }
            PlacementApi.payRateConfigUpdate(data).then((res) => {
                if (res.data.statusCode === 1003) {
                    props.setformEdit(false);
                    setSaveLoading(false);
                    addSuccessMsg("Pay rate configuration details updated successfully");
                    getPlacementPayRateData(placementID);
                    setEdit(false);
                } else {
                    setSaveLoading(false);
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let err = { error };
            err = error;
            setErrors(err);
            let s3 = { docError }
            s3 = multiErrors
            setDocError(s3);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const handleValidateCurrency = (name, value) => {
        switch (name) {
            case "annual_pay":
                errors.annual_pay = validate_input_fields(value, payRateDetails?.annual_pay)
                if (errors.annual_pay == "" && parseInt(value) <= 0) {
                    errors.annual_pay = 'The annual pay should be greater than 0'
                }
                break
            case "payroll_pay":
                errors.payroll_pay = validates_float(value)
                break
            case "ot_pay_config_value":
                errors.ot_pay_config_value = validate_input_fields(value, payRateDetails?.ot_pay_config_value?.value2)
                break
            default:
                break
        }
        setErrors({ ...errors })
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'pay_type':
                errors.pay_type = validate_input_fields(input.value, payRateDetails?.pay_type)
                break;
            case 'ot_pay_config_value':
                errors.ot_pay_config_value = ((state.pay_type == 2 || state.pay_type == "2") || (state.ot_pay_config_type == 3 || state.pay_type == "3")) ? validate_input_fields(input.value, payRateDetails?.ot_pay_config_value?.value3) : ""
                break
            case 'payroll_configuration_id':
                errors.payroll_configuration_id = validate_input_fields(input.value, payRateDetails?.payroll_configuration_id)
                break
            default:
                break
        }
        setErrors({ ...errors })
    }

    const handleValidatePayConfig = (e, index) => {
        let input = e;
        let error = docError.length > 0 ? (docError ? docError[index] : docError) : docError;
        for (var k = 0; k <= index; k++) {
            docError.push({});
        }
        let s1 = docError.length > 0 ? [...docError] : [{ ...docError }];
        switch (input.name || input.tagName) {
            case "to_hour":
                error.to_hour = validate_toHours(input.value, state.pay_details[index].from_hour);
                break;
            case "value":
                error.value = state.pay_config_type == 1 ? validate_input_fields(input.value, payRateDetails?.value?.value1) : validate_input_fields(input.value, payRateDetails?.value?.value2);
                if (error.value == "" && parseInt(input.value) <= 0) {
                    error.value = "The value should be greater than 0"
                }
                break;
            default:
                break;
        }
        setDocError(s1);
    }

    // final submission validation/
    const formValidations = () => {
        const { pay_type, annual_pay, ot_pay_config_value, payroll_configuration_id } = state;
        let error = {};
        error.pay_type = validate_input_fields(pay_type, payRateDetails?.pay_type);
        error.payroll_configuration_id = validate_input_fields(payroll_configuration_id, payRateDetails?.payroll_configuration_id);
        error.ot_pay_config_value = ((state.pay_type == 1 && state.ot_pay_config_type == 2) || (state.pay_type == 1 && state.ot_pay_config_type == 3)) ? state.ot_pay_config_type == 3 ? validate_input_fields(ot_pay_config_value, payRateDetails?.ot_pay_config_value?.value3) : validate_input_fields(ot_pay_config_value, payRateDetails?.ot_pay_config_value?.value2) : "";
        error.annual_pay = state.pay_type == 2 ? validate_input_fields(annual_pay, payRateDetails?.annual_pay) == "" ? parseInt(annual_pay) <= 0 ? 'The rate should be greater than 0' : "" : validates_float(annual_pay) : "";
        // error.payroll_pay = state.pay_type == 2 ? float_validation(payroll_pay) : "";
        return error;
    };

    const validateMultiple = () => {
        let errors = {};
        let err = [];// eslint-disable-next-line
        state.pay_details.map((value, index) => {
            errors = {};
            errors.to_hour = state.pay_details.length !== (index + 1) && validate_toHours(value.to_hour, value.from_hour);
            errors.value = state.pay_config_type == 1 ? validate_input_fields(value.value, payRateDetails?.value?.value1) : validate_input_fields(value.value, payRateDetails?.value?.value2) == "" ? parseInt(value.value) <= 0 ? 'The value should be greater than 0' : "" : state.pay_config_type == 1 ? validate_input_fields(value.value, payRateDetails?.value?.value1) : validate_input_fields(value.value, payRateDetails?.value?.value2);
            err.push(errors)
        })
        return err;
    }

    const handleFocus = (index) => {
        setFocusIndex(index);
    };

    const handleBlur = () => {
        setFocusIndex(null);
    };

    const getDateById = (id, param) => {
        const matchedItem = upcomingDates.find(item => item.id === id);
        return matchedItem ? matchedItem[param] || "--" : "--";
    };


    return (
        <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1 }} alignItems={"center"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Text largeBlue>Pay Rate Configuration</Text>
            </Grid>
            {payRateDetails?.pay_type?.isVisible &&
                <Grid item lg={4} md={4} sm={4} pt={setEdit ? 4 : 0} xs={12}>
                    <Select
                        name='pay_type'
                        value={state.pay_type}
                        onChange={(e) => { changeHandler(e) }}
                        options={payTypes}
                        label={payRateDetails?.pay_type?.required ? payRateDetails?.pay_type?.label : payRateDetails?.pay_type?.label + ' (Optional)'}
                        placeholder={payRateDetails?.pay_type?.placeholder}
                        disabled={payRateDetails?.pay_type?.isDisabled}
                        helperText={errors.pay_type ? <Text red>{errors.pay_type ? errors.pay_type : ''}</Text> : ""}
                    />
                </Grid>}
            {
                state.pay_type === 2 ?
                    <>
                        {payRateDetails?.annual_pay?.isVisible &&
                            <Grid item lg={setEdit ? 3 : 4} md={4} sm={4} mt={3.5} xs={12} pl={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'annual_pay',
                                        value: currency + '' + state.annual_pay,
                                        // inputProps: { maxLength: payRateDetails?.annual_pay?.maxLength }
                                    }}

                                    handleChange={(e) => { changeHandler(e) }}
                                    onKeyPress={onNumberFloatCurrency}
                                    selectBesideInput
                                    labelText={payRateDetails?.annual_pay?.required ? payRateDetails?.annual_pay?.label : payRateDetails?.annual_pay?.label + ' (Optional)'}
                                    placeholder={payRateDetails?.annual_pay?.placeholder}
                                    disabled={payRateDetails?.annual_pay?.isDisabled}
                                    error={errors.annual_pay}
                                />
                                <Box sx={{ height: '0px' }}>
                                    {errors.annual_pay ? <Text red>{errors.annual_pay ? errors.annual_pay : ''}</Text> : ''}
                                </Box>
                            </Grid>}
                        {payRateDetails?.payroll_configuration_id?.isVisible &&
                            <Grid item lg={4} md={4} sm={4} xs={12} mt={3.5} pl={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                                <Select
                                    name='payroll_configuration_id'
                                    value={state.payroll_configuration_id}
                                    onChange={(e) => { changeHandler(e) }}
                                    options={checkDates}
                                    label={payRateDetails?.payroll_configuration_id?.required ? payRateDetails?.payroll_configuration_id?.label : payRateDetails?.payroll_configuration_id?.label + ' (Optional)'}
                                    placeholder={payRateDetails?.payroll_configuration_id?.placeholder}
                                    disabled={payRateDetails?.payroll_configuration_id?.isDisabled}
                                    helperText={errors.payroll_configuration_id ? <Text red>{errors.payroll_configuration_id ? errors.payroll_configuration_id : ''}</Text> : ""}
                                />
                            </Grid>}

                        {checkDates.some(item => item.id === Number(state.payroll_configuration_id)) && (
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                <Box display='flex' flexDirection='row' gap={1.5} className={classes.payrateNoteOrange}>
                                    <Box pt={'4px'}>
                                        <img src={redInfo} alt='warning' />
                                    </Box>
                                    <Box textAlign='start'>
                                        <Text mediumOverView>
                                            Updates to the 'Pay Rate Configuration' will take effect starting from the payroll period
                                            “<span className={classes.datesText}>{getDateById(state.payroll_configuration_id, 'from_date')}</span>
                                            to <span className={classes.datesText}>{getDateById(state.payroll_configuration_id, 'to_date')}</span> ”.
                                        </Text>
                                    </Box>
                                </Box>
                            </Grid>
                        )}

                        <Grid item lg={12} md={12} xs={12} sm={12} mt={3}>
                            <Box display='flex' flexDirection='row' gap={1.5} className={classes.payrateNote}>
                                <Box pt={'4px'}>
                                    <img src={blueinfo} alt='warning' />
                                </Box>
                                <Box textAlign='start'>
                                    {
                                        LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "working_hours_edit" && item.is_allowed == true)) ?
                                            <Text mediumOverView>
                                                The default hours / year for your organization is set to {state.org_default_annual_hours} which will be used to calculate the hourly rate on the basis of provided annual pay amount. To change the default hour / year configuration, <span className={classes.clickHereText} onClick={() => clickHandler()}>click here.</span>
                                            </Text> :
                                            <Text mediumOverView>
                                                The default hours / year for your organization is set to {state.org_default_annual_hours} which will be used to calculate the hourly rate on the basis of provided annual pay amount. To change the default hour / year configuration, Please contact Organization Administrator.
                                            </Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </> :
                    state.pay_type === 1 ?
                        <>
                            {payRateDetails?.payroll_configuration_id?.isVisible &&
                                <Grid item lg={4} md={4} sm={4} xs={12} mt={3.5} pl={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                                    <Select
                                        name='payroll_configuration_id'
                                        value={state.payroll_configuration_id}
                                        onChange={(e) => { changeHandler(e) }}
                                        options={checkDates}
                                        label={payRateDetails?.payroll_configuration_id?.required ? payRateDetails?.payroll_configuration_id?.label : payRateDetails?.payroll_configuration_id?.label + ' (Optional)'}
                                        placeholder={payRateDetails?.payroll_configuration_id?.placeholder}
                                        disabled={payRateDetails?.payroll_configuration_id?.isDisabled}
                                        helperText={errors.payroll_configuration_id ? <Text red>{errors.payroll_configuration_id ? errors.payroll_configuration_id : ''}</Text> : ""}
                                    />
                                </Grid>}
                            {checkDates.some(item => item.id === Number(state.payroll_configuration_id)) && (
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                    <Box display='flex' flexDirection='row' gap={1.5} className={classes.payrateNoteOrange}>
                                        <Box pt={'4px'}>
                                            <img src={redInfo} alt='warning' />
                                        </Box>
                                        <Box textAlign='start'>
                                            <Text mediumOverView>
                                                Updates to the 'Pay Rate Configuration' will take effect starting from the payroll period
                                                “<span className={classes.datesText}>{getDateById(state.payroll_configuration_id, 'from_date')}</span>
                                                to <span className={classes.datesText}>{getDateById(state.payroll_configuration_id, 'to_date')}</span> ”.
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                <Text largeBlue >Choose Pay Configuration</Text>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                                <Box className={classes.btnBox1} gap={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button onClick={() => handleTabChange(1)} saveBtn className={state.pay_config_type == 1 ? classes.activeButton1 : classes.inActiveButton1}>Percentage</Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button onClick={() => handleTabChange(2)} saveBtn className={state.pay_config_type == 2 ? classes.activeButton1 : classes.inActiveButton1}>Value</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                            {
                                state.pay_details.length > 0 && state.pay_details.map((item, index) => (
                                    <Grid container columnSpacing={4} pt={4} spacing={{ xs: 4 }} alignItems={"center"}>
                                        {payRateDetails?.from_hour?.isVisible &&
                                            <Grid item lg={3.5} md={3.5} sm={4} xs={6}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'from_hour',
                                                        value: index == 0 ? '0' : item.from_hour,
                                                    }}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={payRateDetails?.from_hour?.label}
                                                />
                                            </Grid>}
                                        {payRateDetails?.to_hour?.isVisible &&
                                            <Grid item lg={3.5} md={3.5} sm={3.5} xs={6}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'to_hour',
                                                        value: state.pay_details.length == (index + 1) ? `Remainder` : item.to_hour,
                                                        onClick: payRateDetails?.to_hour?.isDisabled ? '' : state.pay_details.length == (index + 1) ? handleClick : null
                                                    }}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    handleChange={(e) => changeHandler(e, index)}
                                                    labelText={payRateDetails?.to_hour?.required ? payRateDetails?.to_hour?.label : payRateDetails?.to_hour?.label + ' (Optional)'}
                                                    placeholder={payRateDetails?.to_hour?.placeholder}
                                                    disabled={payRateDetails?.to_hour?.isDisabled}
                                                    error={docError[index] ? docError[index].to_hour : ''}
                                                />
                                                {docError.length > 0 ?
                                                    <Box sx={{ height: '0px' }}>
                                                        <Text red>{docError[index] ? docError[index].to_hour : ''}</Text>
                                                    </Box> : ("")}
                                            </Grid>}
                                        <Grid item lg={3.5} md={3.5} sm={3.5} xs={10}>
                                            <FormControl fullWidth>
                                                {state.pay_config_type == 1 ?
                                                    <>
                                                        {payRateDetails?.value?.value1?.isVisible &&
                                                            <TextField
                                                                title={payRateDetails?.value?.value1?.label?.length > 23 ? payRateDetails?.value?.value1?.label : ''}
                                                                label={payRateDetails?.value?.value1?.label?.length > 23 ? payRateDetails?.value?.value1?.label?.substring(0, 23) + "..." : payRateDetails?.value?.value1?.required ? payRateDetails?.value?.value1?.label : payRateDetails?.value?.value1?.label + ' (Optional)'}
                                                                placeholder={payRateDetails?.value?.value1?.placeholder}
                                                                disabled={payRateDetails?.value?.value1?.isDisabled}
                                                                margin="dense"
                                                                type={"text"}
                                                                value={item.value}
                                                                onChange={(e) => changeHandler(e, index)}
                                                                onKeyPress={onFloatOnlyChange}
                                                                name={'value'}
                                                                autoComplete='off'
                                                                variant="filled"
                                                                onFocus={() => { handleFocus(index) }}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    // startAdornment: state.pay_config_type == 2 && (<InputAdornment position="start"><Text mediumBlack14>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'}</Text></InputAdornment>),
                                                                    endAdornment: state.pay_config_type == 1 && (<InputAdornment position="end"><Text mediumBlack14>%</Text></InputAdornment>),
                                                                    sx: {
                                                                        borderRadius: '8px !important',
                                                                        border: docError[index] && docError[index].value ? '1px solid red !important' : focusIndex == index ? "1px solid #0C75EB !important" : `1px solid #C7CCD3 !important`,
                                                                        // border: (docError[index] && docError[index].value) ? `1px solid red !important` : `1px solid #C7CCD3 !important`,
                                                                        background: "#ffffff !important",
                                                                    },
                                                                    disableUnderline: true,
                                                                    classes: { input: ((docError[index] && docError[index].value) && state.pay_config_type == 2) ? classes.error1 : ((docError[index] && docError[index].value) && state.pay_config_type == 1) ? classes.error : classes.clientInput }
                                                                }}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: (docError[index] && docError[index].value) ? 'red !important' : "#737373 !important",
                                                                        font: '15px Quicksand !important',
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            color: (docError[index] && docError[index].value) ? 'red !important' : "#0C75EB !important",
                                                                            font: '15px Quicksand !important',
                                                                        },
                                                                    },
                                                                }}
                                                            />}
                                                    </>
                                                    :
                                                    <>
                                                        {payRateDetails?.value?.value2?.isVisible &&
                                                            <TextField
                                                                title={payRateDetails?.value?.value2?.label?.length > 23 ? payRateDetails?.value?.value2?.label : ''}
                                                                label={payRateDetails?.value?.value2?.label?.length > 23 ? payRateDetails?.value?.value2?.label?.substring(0, 23) + "..." : payRateDetails?.value?.value2?.required ? payRateDetails?.value?.value2?.label : payRateDetails?.value?.value2?.label + ' (Optional)'}
                                                                placeholder={payRateDetails?.value?.value2?.placeholder}
                                                                disabled={payRateDetails?.value?.value2?.isDisabled}
                                                                margin="dense"
                                                                type={"text"}
                                                                value={item.value}
                                                                onChange={(e) => changeHandler(e, index)}
                                                                onKeyPress={onFloatOnlyChange}
                                                                name={'value'}
                                                                autoComplete='off'
                                                                variant="filled"
                                                                onFocus={() => { handleFocus(index) }}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    startAdornment: state.pay_config_type == 2 && (<InputAdornment position="start"><Text mediumBlack14>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'}</Text></InputAdornment>),
                                                                    // endAdornment: state.pay_config_type == 1 && (<InputAdornment position="end"><Text mediumBlack14>%</Text></InputAdornment>),
                                                                    sx: {
                                                                        borderRadius: '8px !important',
                                                                        border: docError[index] && docError[index].value ? '1px solid red !important' : focusIndex == index ? "1px solid #0C75EB !important" : `1px solid #C7CCD3 !important`,
                                                                        // border: (docError[index] && docError[index].value) ? `1px solid red !important` : `1px solid #C7CCD3 !important`,
                                                                        background: "#ffffff !important",
                                                                    },
                                                                    disableUnderline: true,
                                                                    classes: { input: ((docError[index] && docError[index].value) && state.pay_config_type == 2) ? classes.error1 : ((docError[index] && docError[index].value) && state.pay_config_type == 1) ? classes.error : classes.clientInput }
                                                                }}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: (docError[index] && docError[index].value) ? 'red !important' : "#737373 !important",
                                                                        font: '15px Quicksand !important',
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            color: (docError[index] && docError[index].value) ? 'red !important' : "#0C75EB !important",
                                                                            font: '15px Quicksand !important',
                                                                        },
                                                                    },
                                                                }}
                                                            />}
                                                    </>
                                                }


                                            </FormControl>
                                            {/* <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'value',
                                                    value: state.pay_config_type == 2 ? currency + '' + item.value : item.value,
                                                    inputProps: { maxLength: state.pay_config_type == 2 ? 8 : 3 }
                                                }}
                                                handleChange={(e) => changeHandler(e, index)}
                                                onKeyPress={state.pay_config_type == 2 ? onNumberFloatCurrency : onNumberOnlyChange}
                                                clientInput
                                                labelText={state.pay_config_type == 1 ? "Percentage" : `Value`}
                                                error={docError[index] ? docError[index].value : ''}
                                            /> */}

                                            {docError.length > 0 ? <Box sx={{ height: '0px' }}><Text red>{docError[index] ? docError[index].value : ''}</Text></Box> : ("")}
                                        </Grid>
                                        {
                                            state.pay_details.length > 1 &&
                                            <Grid item lg={1} md={1} sm={1} xs={2} >
                                                <RemoveIcon onClick={() => handleDeleteLevelOpen(index)} className={classes.removeIcon} />
                                            </Grid>
                                        }
                                    </Grid>
                                ))
                            }
                            {
                                props.employeeType == 2 ?
                                    <>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={4}>
                                            <Text largeBlue >OT Pay Rate Config Type</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                            <CustomRadioGroup mediumGrey2 options={options} value={state.ot_pay_config_type} onChange={handleRadio1Change} />
                                        </Grid>
                                        <Grid container columnSpacing={4} pt={3}>
                                            {
                                                state.ot_pay_config_type == 3 && payRateDetails?.ot_pay_config_value?.value3?.isVisible &&
                                                <Grid container item lg={3.5} md={4} sm={4} xs={12} alignItems={"center"}>
                                                    <Box>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'ot_pay_config_value',
                                                                value: state.ot_pay_config_value,
                                                                // inputProps: { maxLength: 3 }
                                                            }}
                                                            handleChange={changeHandler}
                                                            onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                            clientInput
                                                            labelText={payRateDetails?.ot_pay_config_value?.value3?.required ? payRateDetails?.ot_pay_config_value?.value3?.label : payRateDetails?.ot_pay_config_value?.value3?.label + ' (Optional)'}
                                                            placeholder={payRateDetails?.ot_pay_config_value?.value3?.placeholder}
                                                            disabled={payRateDetails?.ot_pay_config_value?.value3?.isDisabled}
                                                            error={errors.ot_pay_config_value}
                                                        />
                                                        {errors.ot_pay_config_value ? <Text red>{errors.ot_pay_config_value ? errors.ot_pay_config_value : ''}</Text> : ''}
                                                    </Box>
                                                    <HtmlTooltip
                                                        placement="bottom"
                                                        arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Box>
                                                                    <Typography className={classes.profileTooltipText}>
                                                                        Enter the multiplier to multiply the regular pay rate and calculate the OT pay rate
                                                                    </Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <img src={info} alt="infoCircle" height={"17px"} style={{ marginLeft: "10px", marginBottom: "-4px", cursor: 'pointer' }} />
                                                    </HtmlTooltip>
                                                </Grid>
                                            }
                                            {
                                                state.ot_pay_config_type == 2 && payRateDetails?.ot_pay_config_value?.value2?.isVisible &&
                                                <Grid item lg={3.5} md={4} sm={4} xs={12} >
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'ot_pay_config_value',
                                                            value: currency + '' + state.ot_pay_config_value,
                                                            // inputProps: { maxLength: payRateDetails?.ot_pay_config_value?.value2?.maxLength }
                                                        }}
                                                        disabled={state.ot_pay_config_type == 1 || state.ot_pay_config_type == 3 || state.ot_pay_config_type == "" || payRateDetails?.ot_pay_config_value?.value2?.isDisabled}
                                                        clientInput
                                                        onKeyPress={onNumberFloatCurrency}
                                                        handleChange={(e) => { changeHandler(e) }}
                                                        error={errors.ot_pay_config_value}
                                                        labelText={payRateDetails?.ot_pay_config_value?.value2?.required ? payRateDetails?.ot_pay_config_value?.value2?.label : payRateDetails?.ot_pay_config_value?.value2?.label + ' (Optional)'}
                                                        placeholder={payRateDetails?.ot_pay_config_value?.value2?.placeholder}
                                                    />
                                                    {errors.ot_pay_config_value ? <Text red>{errors.ot_pay_config_value ? errors.ot_pay_config_value : ''}</Text> : ''}

                                                </Grid>
                                            }
                                        </Grid>
                                    </>
                                    : ""
                            }
                        </> : ""
            }

            <Grid container item lg={12} md={12} sm={12} xs={12} pt={4} justifyContent={"space-between"}>
                <Button blackCancel1 disabled={saveLoading} onClick={() => { setEdit(false); props.setformEdit(false); getPlacementPayRateData(placementID) }}>Cancel</Button>
                <LoadingButton saveBtn loading={saveLoading} onClick={() => handleSubmit()}>Save</LoadingButton>
            </Grid>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={deleteLevelOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent >
                    <Grid container my={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} >
                            <img src={approvalDelete} alt='approvalDelete' />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Are You Sure?</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'}>
                            <Text offBoardBodyFont>Do you want delete the Pay roll configuration level</Text>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={3} gap="10px">
                            <Button outlineBlue sx={{
                                width: "122px !important", height: "44px !important", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                [theme.breakpoints.down('sm')]: { width: "fit-content !important" }
                            }} onClick={() => { setDeleteLevelOpen(false); setDeleteLevelIndex(null) }} >
                                Cancel
                            </Button>
                            <Button sx={{ width: "122px !important", height: "44px !important", [theme.breakpoints.down('sm')]: { width: "fit-content !important" } }} smallBlue redBackground onClick={() => { handleRemove(deleteLevelIndex) }} >
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </Grid>
    )
}

export default PayRateConfigurationForm