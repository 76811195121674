import { useCallback } from 'react';
import { debounce } from 'lodash';
import CommonApi from '../../apis/CommonApi';
import { ErrorMessages } from '../../utils/ErrorMessages';

export const resetStateWithError = (data, setState, setError) => {
  setState((prevState) => ({
    ...prevState,
    state_name: '',
    city: '',
    state_id: '',
    country_name: '',
    country_id: ''
  }));

  setError((prevError) => ({
    ...prevError,
    city: '',
    zip_code: data.length == 0 ? ErrorMessages.fieldRequired : data.length == 6 ? ErrorMessages.invalidPincode : data.length == 5 ? ErrorMessages.invalidZipcode : ErrorMessages.invalidPinZipcode,
    state_id: '',
    country_id: ''
  }));
};

export const useZipcodeAPICall = ({ setState, setError }) => {
  // eslint-disable-next-line
  return useCallback(
    debounce((data) => {
      CommonApi.getZipcodeData(data).then((res) => {
        const { statusCode, data: responseData } = res.data;

        if (statusCode === 1003) {
          if (responseData.length > 0) {
            const { state_name, country_name, city, state_id, country_id } = responseData[0];

            setState((prevState) => ({
              ...prevState,
              state_name,
              country_name,
              city,
              state_id,
              country_id
            }));
            setError((prevError) => ({
              ...prevError,
              city: '',
              zip_code: '',
              state_id: '',
              country_id: ''
            }));
          } else {
            resetStateWithError(data, setState, setError);
          }
        } else {
          resetStateWithError(data, setState, setError);
        }
      });

    }, 500), 
    []
  )
};

export const useZipcodeAPICallMulti = (state, setState, errorState, setErrorState) => {

  const resetStateWithErrorEmergencyMulti = (data, index, fieldType, validation) => {
    setState((prevState) => {
      const updatedField = prevState[fieldType].map((item, i) => {
        if (i === index) {
          return {
            ...item, // Only modify the specific index
            state_id: '',
            state_name: '',
            country_name: '',
            city: '',
            country_id: '',
            zip_code: data, 
          };
        }
        return item;
      });

      return {
        ...prevState,
        [fieldType]: updatedField,
      };
    });

    setErrorState((prevErrorState) => {
      const updatedErrorState = prevErrorState.map((item, i) => {
        if (i === index) {
          return {
            ...item, 
            city: '',
            zip_code: (validation != 'validation' && data.length === 0)
              ? ''
              :
              (validation == 'validation' && data.length === 0) ?
              ErrorMessages.fieldRequired
              : data.length === 6
              ? ErrorMessages.invalidPincode
              : ErrorMessages.invalidZipcode,
            state_id: '',
            country_id: '',
          };
        }
        return item; 
      });

      return updatedErrorState;
    });
  };

  // eslint-disable-next-line
  const zipcodeAPICallMulti = useCallback(
    debounce((data, index, fieldType, validation) => {
      // Update the zip_code field without affecting other fields
      setState((prevState) => {
        const updatedField = prevState[fieldType].map((item, i) => {
          if (i === index) {
            return {
              ...item, // Only modify the specific index
              zip_code: data,
            };
          }
          return item;
        });

        return {
          ...prevState,
          [fieldType]: updatedField, 
        };
      });

      // API call to get zipcode data
      CommonApi.getZipcodeData(data).then((res) => {
        const { statusCode, data: responseData } = res.data;

        if (statusCode === 1003 && responseData.length > 0) {
          const { state_name, country_name, city, state_id, country_id } = responseData[0];

          setState((prevState) => {
            const updatedFieldWithResponse = prevState[fieldType].map((item, i) => {
              if (i === index) {
                return {
                  ...item, // Update only the fields based on the response
                  state_id,
                  state_name,
                  country_name,
                  city,
                  country_id,
                };
              }
              return item; 
            });

            return {
              ...prevState,
              [fieldType]: updatedFieldWithResponse, 
            };
          });

          setErrorState((prevErrorState) => {
            const updatedErrorState = prevErrorState.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  city: '',
                  zip_code: '',
                  state_id: '',
                  country_id: '',
                };
              }
              return item; 
            });

            return updatedErrorState;
          });
        } else {
          resetStateWithErrorEmergencyMulti(data, index, fieldType, validation);
        }
      }).catch(error => {
        resetStateWithErrorEmergencyMulti(data, index, fieldType, validation);
      });
    }, 500),
    [] // Add dependencies here
  );

  return { zipcodeAPICallMulti };
};

export const resetStateWithErrorInvoice = (data, setClientState, setError) => {
  setClientState((prevState) => ({
    ...prevState,
    state_name: '',
    city: '',
    state_id: '',
    country_name: '',
    country_id: ''
  }));

  setError((prevError) => ({
    ...prevError,
    city: '',
    zip_code: data.length == 0 ? ErrorMessages.fieldRequired : data.length == 6 ? ErrorMessages.invalidPincode : data.length == 5 ? ErrorMessages.invalidZipcode : ErrorMessages.invalidPinZipcode,
    state_id: '',
    country_id: ''
  }));
};

export const useZipcodeAPICallInvoice = ({ setClientState, setError }) => {
  // eslint-disable-next-line
  return useCallback(
    debounce((data) => {
      CommonApi.getZipcodeData(data).then((res) => {
        const { statusCode, data: responseData } = res.data;

        if (statusCode === 1003) {
          if (responseData.length > 0) {
            const { state_name, country_name, city, state_id, country_id } = responseData[0];

            setClientState((prevState) => ({
              ...prevState,
              state_name,
              country_name,
              city,
              state_id,
              country_id
            }));
            setError((prevError) => ({
              ...prevError,
              city: '',
              zip_code: '',
              state_id: '',
              country_id: ''
            }));
          } else {
            resetStateWithErrorInvoice(data, setClientState, setError);
          }
        } else {
          resetStateWithErrorInvoice(data, setClientState, setError);
        }
      });

    }, 500), 
    []
  )
};



