import { Box, Grid, Hidden, Skeleton } from "@mui/material";

export default function LoadingScreen(props) {
    return (
        <Grid container spacing={2} height={'25vh'} alignContent={'center'} justifyContent={'center'}>
            <Grid item lg={4} md={4} sm={4} xs={12} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box mr={5} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "2rem" }} />
                </Box>
            </Grid>
            <Hidden smDown>
            <Grid item lg={4} md={4} sm={4} xs={4} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <Box mr={5} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "2rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} display={'flex'} justifyContent={'center'}>
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "8rem" }} />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <Box mr={5} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "2rem" }} />
                </Box>
            </Grid>
            </Hidden>
        </Grid>
    )
}