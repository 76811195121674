import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import EyeIcon from '../../assets/svg/eye.svg';
import CloseEyeIcon from '../../assets/svg/NotVisible.svg'
import Text from "../customText/Text";

const MaskDataView = ({ label, value, ...props }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => setVisible(false), 10000);
            return () => clearTimeout(timer);
        }
    }, [visible]);

    return (
        <Box pt={1} display={"flex"} justifyContent={"start"} alignItems={"center"} gap={1}>
            <Text mediumBlack14>{visible ? value || "-" : value ? "***********" : "-"}</Text>
            {value && <img
                src={visible ? EyeIcon : CloseEyeIcon}
                alt="Toggle Visibility"
                style={{ height: 20, width: 20, cursor: "pointer" }}
                onClick={() => setVisible(!visible)}
            />}
        </Box>
    );
};

export default MaskDataView;
