import { Grid, Stack, Divider, Box, Avatar, Skeleton, useTheme } from "@mui/material";
import Text from "../../components/customText/Text";
import LocalStorage from "../../utils/LocalStorage";
import Verified from '../../assets/svg/Component87.svg';
import { useEffect, useRef, useState } from "react";
import DashboardAPI from "../../apis/admin/DashboardAPI";
import { BlackToolTip, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, openDocumentInNewtab, removeLoader } from "../../utils/utils";
import Styles from "./profile/UserProfileStyles";
import LoadingScreen from "../admin/employees/userprofile/document/LoadingScreen";
import FileSvg from '../../assets/svg/File.svg';
import Button from '../../components/customButton/Button';
import ReusablePopup from '../../components/customPopup/CustomPopups';
import approveImg from '../../assets/timeSheets/pending-approval.svg';
import emptyEmployee from '../../assets/employee/emp-employee.svg';
import noDataFound from '../../assets/svg/noDataFound.svg';
import LoadingButton from "../../components/customButton/LoadingButton";
import rejectSVg from "../../assets/svg/placementPop.svg";
import visaUpdated from '../../assets/employee/Visa_updated.svg';
import Pending from '../../assets/employee/Orange-PendingIcon.svg';
import Input from "../../components/input/Input";
import { validate_Reason_Rejection } from "../../components/Validation";
import MaskDataView from "../../components/input/MaskDataView";

export default function TimesheetEmployeePending(props) {
    const { fetchId, employee, setNewRequests } = props;
    const calculateColumnSize = (length) => {
        let a = 12 / length;
        return a
    }
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [list, setList] = useState([]);
    const classes = Styles();
    const theme = useTheme();
    const [id, setId] = useState(fetchId);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openVisaChange, setOpenVisachange] = useState(false);
    const [status, setStatus] = useState('');
    const scrollBox = useRef(null);
    const [profile, setProfile] = useState('');
    const [rejectLoad, setRejectLoad] = useState(false);
    const [openReject, setOpenRejected] = useState(false);
    const [approveLoad, setApproveLoad] = useState(false);
    const [visaDetailsSuccess, setVisaDetailsSuccess] = useState(false);
    const [rejectComment, setRejectComment] = useState("");
    const [error, setError] = useState("");

    const [filterData, setFilterData] = useState({
        limit: 100,
        page: 1,
        total: "",
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
        request_id: LocalStorage.uid(),
        search: '',
        employee_status: []
    });

    useEffect(() => {
        if (employee == 'employee') {
            indexApi(id);
        }  // eslint-disable-next-line
    }, [])

    useEffect(() => {
        filterData.employee_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
        filterData.request_id = LocalStorage.uid()
        filterData.search = props.searchId
        filterData.employee_status = props.filterState
        listingApi(filterData);
        // eslint-disable-next-line
    }, [props.searchId, props.filterState])

    const handleScroll = () => {
        const { current } = scrollBox;
        // if (current) {
        //     const { scrollTop, scrollHeight, clientHeight } = current;
        //     if (scrollTop + clientHeight >= scrollHeight - 5) {
        //         if (Number(filterData.total) >= filterData.limit) {
        //             loadMore();
        //         }
        //     }
        // }
        if (current) {
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = current;

            // Detect if the screen is small (tab or mobile)
            const isSmallScreen = window.innerWidth <= theme.breakpoints.values.md;

            if (isSmallScreen) {
                // Check for horizontal scroll
                if (scrollLeft + clientWidth >= scrollWidth - 5 && Number(filterData.total) >= filterData.limit) {
                    loadMore();
                }
            } else {
                // Check for vertical scroll
                if (scrollTop + clientHeight >= scrollHeight - 5 && Number(filterData.total) >= filterData.limit) {
                    loadMore();
                }
            }
        }
    };

    const loadMore = () => {
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        setLoading(true);
        listingApi(filterData);
    }

    const listingApi = (args, limit) => {
        setLoading(true);
        args.request_id = LocalStorage.uid()
        DashboardAPI.pendingemployeeListing(args, limit).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                setLoader(false)
                removeLoader();
                setList(res.data.data);
                if (res.data.data.length > 0) {
                    setFilterData({
                        ...res.data.pagination,
                        limit: res.data.pagination.perPage,
                        page: res.data.pagination.currentPage,
                        total: res.data.pagination.total,
                    });
                    setNewRequests(res.data.pagination.new_requests)
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].id == id) {
                            setProfile(res.data.data[i].profile_picture_url);
                        }
                    }
                } else {
                    setNewRequests('');
                }
            } else {
                removeLoader()
                setLoader(false)
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const indexApi = (args) => {
        setLoader(true);
        DashboardAPI.pendingEmployeeIndex(args).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false);
                setLoading(false)
                setData(res.data.data);
            } else {
                setLoader(false);
                setLoading(false)
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleClick = (args) => {
        indexApi(args.id);
        setId(args.id);
        setProfile(args.profile_picture_url);
    }

    const openInNewTab = (args) => {

        openDocumentInNewtab(args.document_key, args.document_path)

        // const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
    }

    const approveUpdate = (args) => {
        addLoader(true)
        const getId = data[0].id
        const updatedata = {
            request_id: LocalStorage.uid(),
            employee_id: data[0].employee_id,
            id: data[0].id,
            status: args
        }
        setStatus(args);
        if (args == 'Rejected') {
            // setRejectLoad(true);
            setOpenRejected(true);
            return false;
        }
        else {
            setApproveLoad(true);
            setVisaDetailsSuccess(true);
        }
        DashboardAPI.updateApprove(updatedata).then((res) => {
            removeLoader()
            setRejectLoad(false);
            setApproveLoad(false)
            if (res.data.statusCode == 1003) {
                setOpenApprove(true);
                indexApi(getId);
                filterData.employee_status = [];
                setFilterData({ ...filterData });
                listingApi(filterData);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const okayHandler = () => {
        setOpenApprove(false);
        if ((data.length > 0 && data[0].employee_e_verified !== 0) && visaDetailsSuccess) {
            setOpenVisachange(true);
        }
    }

    const handleRejectChange = (e) => {
        setRejectComment(e.target.value);
        setError(validate_Reason_Rejection(e.target.value))
    }

    const rejectHandler = () => {
        if (rejectComment == '') {
            setError('This field is required');
            return false;
        }
        if (rejectComment.length < 5) {
            setError(validate_Reason_Rejection(rejectComment))
            return false;
        }
        const rejectData = {
            request_id: LocalStorage.uid(),
            employee_id: data[0].employee_id,
            id: data[0].id,
            status: 'Rejected',
            reason_for_rejection: rejectComment,
        }
        setRejectLoad(true)
        DashboardAPI.updateApprove(rejectData).then((res) => {
            removeLoader()
            setRejectLoad(false);
            setApproveLoad(false);
            setOpenRejected(false);
            if (res.data.statusCode == 1003) {
                setOpenApprove(true);
                indexApi(data[0].id);
                filterData.employee_status = [];
                setFilterData({ ...filterData });
                listingApi(filterData);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <>
            {
                LocalStorage.getRolesData() == null || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true)) ?
                    <>
                        {list.length == 0 && !loading && !loader ?
                            <Box justifyContent='center' width='100%' alignItems='center'>
                                <img src={noDataFound} alt="noDataFound" className={classes.nodata} />
                            </Box>
                            :
                            <Grid container lg={12} md={12} sm={12} xs={12} spacing={1} columnSpacing={2}>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    {
                                        loading ?
                                            <Box className={classes.sidecard} width='100%'>
                                                <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                                                        <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                                                        <Stack gap='8px'>
                                                            <Skeleton animation='wave' width='100px' />
                                                            <Skeleton animation='wave' width='100px' />
                                                        </Stack>
                                                    </Stack>
                                                    <Stack alignContent={'center'} alignItems={'center'}>
                                                        <Skeleton animation='wave' width='50px' />
                                                    </Stack>
                                                </Stack>
                                                <Divider sx={{ width: '100%', border: '1px solid #F5F5F5' }} />
                                                <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'} p={'10px'}>
                                                    <Stack direction={'column'} justifyContent={'space-between'}>
                                                        <Skeleton animation='wave' width='100px' />
                                                        <Skeleton animation='wave' width='100px' />
                                                    </Stack>
                                                    <Skeleton animation='wave' width='100px' />
                                                </Stack>
                                            </Box>
                                            :
                                            <Box className={classes.sidecardScroll} onScroll={handleScroll} pt={1} width='100%'>
                                                {
                                                    list.length == 0 ?
                                                        <Box justifyContent='center' width='100%'>
                                                            <img src={noDataFound} alt="noDataFound" style={{ width: '100%' }} />
                                                        </Box>
                                                        :
                                                        list.length > 0 ? list.map((item) => (
                                                            <Grid item container lg={11} md={12} sm={12} xs={12}
                                                                sx={{ border: item.id == id ? '1px solid #0C75EB !important' : '1px solid #fff' }} onClick={() => handleClick(item)}
                                                                className={classes.sidecard}
                                                            >
                                                                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} p={1} >
                                                                    <Grid item xl={2} md={3} lg={2} sm={3} xs={3}>
                                                                        <Avatar variant="rounded" sx={{ width: 40, height: 40, borderRadius: '50%', background: item.profile_picture_url ? '' : '#e5b256 !important' }} src={item.profile_picture_url} alt={item.display_name ? capitalizeAndAddSpace(item.display_name[0]) : ''} />
                                                                    </Grid>
                                                                    <Grid item xl={6} md={9} lg={6} sm={9} xs={9} pl={1} mt={'4px'} >
                                                                        <Text dashboardblack14 >
                                                                            {
                                                                                item.display_name ? item.display_name.length > 16 ?
                                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.display_name)} placement="top" arrow>
                                                                                        {capitalizeAndAddSpace(item.display_name.slice(0, 16)) + capitalizeAndAddSpace(item.display_name.length > 16 ? "..." : "")}
                                                                                    </BlackToolTip>
                                                                                    : capitalizeAndAddSpace(item.display_name) : '--'
                                                                            }
                                                                            {
                                                                                item.employee_e_verified == 1 || item.employee_e_verified == 4 ?
                                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                                        <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                    </BlackToolTip>
                                                                                    : item.employee_e_verified == 2 ?
                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is pending</Text>}>
                                                                                            <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                        </BlackToolTip> : ''
                                                                            }
                                                                        </Text>
                                                                        <Text greysmallLabel >{item.reference_id}</Text>
                                                                    </Grid>
                                                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12} textAlign={{ lg: "center", md: "-webkit-right", sm: "-webkit-right", xs: "-webkit-right" }} mt={'4px'}>
                                                                        <Box className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : ''}>
                                                                            <Text mediumWhite400 >{item.status}</Text>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Divider sx={{
                                                                        width: '100%', margin: '0px', border: '1px solid #F5F5F5', mt: 1,
                                                                        [theme.breakpoints.down('md')]: {
                                                                            borderWidth: 0,
                                                                            borderStyle: 'solid',
                                                                            borderColor: 'rgba(0, 0, 0, 0.12)',
                                                                            borderBottomWidth: 'thin',
                                                                            height: "2px",
                                                                        }
                                                                    }} />
                                                                    <Grid item xl={7} lg={7} xs={8} md={6} sm={6} mt={1}>
                                                                        <Text greysmallLabel>Employee Type</Text>
                                                                        <Text dashboardblack14 >{item.employee_type ? item.employee_type : '-'}</Text>
                                                                    </Grid>
                                                                    <Grid item container xl={5} lg={5} md={6} sm={6} xs={4} alignItems={"center"} justifyContent={"end"} mt={1}>
                                                                        <Text mediumBlue600 >Profile Update</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )) : ''
                                                }
                                            </Box>
                                    }
                                </Grid>
                                <Grid item container lg={9} md={9} sm={12} xs={12}>
                                    {
                                        (id == '' || id == null | id == undefined) ?
                                            <Box justifyContent='center' width='100%' alignContent='center' textAlign='center'>
                                                <img src={emptyEmployee} alt='empty' className={classes.nodata} />
                                                <Text mediumBlack700>Select any employee</Text>
                                            </Box> :
                                            <Box className={classes.sidecardScroll1} ref={scrollBox} pt={1} pl={1}>
                                                <Box sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D !important", borderRadius: "8px", }}>
                                                    <Grid item container lg={12} justifyContent={'center'}>
                                                        <Grid item lg={10} container justifyContent={'center'} spacing={1} p={1}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'} mt={2}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} /> :
                                                                        <Avatar
                                                                            src={profile}
                                                                            alt={data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name[0]) : ''}
                                                                            sx={{
                                                                                width: '75px',
                                                                                height: '75px',
                                                                                background: profile ? '' : '#e5b256 !important'
                                                                            }}
                                                                        />
                                                                }
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ?
                                                                        <Skeleton animation='wave' width='100px' />
                                                                        :
                                                                        data.length > 0 &&
                                                                        <Text dashboardblack14>{data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name) : '-'}
                                                                            {data.length > 0 && data[0].employee_e_verified == 1 ?
                                                                                <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                                    <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                </BlackToolTip>
                                                                                : data[0].employee_e_verified == 2 ?
                                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is pending</Text>}>
                                                                                        <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                                    </BlackToolTip> : ''
                                                                            }
                                                                        </Text>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text greysmallLabel>{data.length > 0 ? data[0].employee_reference_id : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text mediumBoldBlack>{data.length > 0 ? data[0].request_type : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text greysmallLabel>{data.length > 0 ? data[0].status_approval : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            {
                                                                loader ?
                                                                    <LoadingScreen />
                                                                    :
                                                                    <>
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'} sx={{ background: '#F9F9F9', borderRadius: '8px', padding: '10px', overflow: 'hidden' }} mt={4}>
                                                                            {
                                                                                data.length > 0 && data[0].old_data.map((ele, i) => (
                                                                                    ele.map((item, index) => (
                                                                                        <Grid item xs={12} sm={item.header ? 12 : 6} md={calculateColumnSize(ele.length)} lg={calculateColumnSize(ele.length)} container justifyContent={item.header ? 'left' : ''} >
                                                                                            <Box p={1}>
                                                                                                {
                                                                                                    item.header ?
                                                                                                        <Text popupHead1 >{item.name}</Text>
                                                                                                        :
                                                                                                        <Text mediumLabel >{item.name}</Text>
                                                                                                }
                                                                                                {
                                                                                                    item.is_mask ? <MaskDataView pTop={'0px'} value={item.value} />
                                                                                                        :
                                                                                                <Text mediumBlack14 >
                                                                                                    {item.url ?
                                                                                                        <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item) }}>
                                                                                                            <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                                                                            <Text mediumBlue>
                                                                                                                {item.value && item.value.length > 22 ?
                                                                                                                    <BlackToolTip arrow placement='top' title={item.value}>
                                                                                                                        {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "--")}
                                                                                                                    </BlackToolTip>
                                                                                                                    :
                                                                                                                    item.value}
                                                                                                            </Text>

                                                                                                        </Stack>
                                                                                                        :
                                                                                                        item.value && item.value.length > 22 ?
                                                                                                            <BlackToolTip title={item.value} placement="top" arrow>
                                                                                                                {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "--")}
                                                                                                            </BlackToolTip>
                                                                                                            :
                                                                                                            item.value
                                                                                                    }
                                                                                                </Text>
                                                                                                }
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </Grid>
                                                                        <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'center'} sx={{ border: '1px solid #0C75EB', borderRadius: '8px', padding: '10px', overflow: 'hidden' }} mt={4}>
                                                                            {
                                                                                data.length > 0 && data[0].new_data.map((ele, i) => (
                                                                                    ele.map((item, index) => (
                                                                                        <Grid item xs={12} sm={6} md={calculateColumnSize(ele.length)} lg={calculateColumnSize(ele.length)} container justifyContent={item.header ? 'left' : item.status ? 'end' : ''} style={{ overflow: 'hidden' }}>
                                                                                            <Box p={1}>
                                                                                                {
                                                                                                    item.status ?
                                                                                                        <Box className={data[0].status == 'Approved' ? classes.statusBox : data[0].status == 'Requested' ? classes.orangeBox : data[0].status == 'Rejected' ? classes.redBox : ''}>
                                                                                                            <Text mediumWhite400>{item.name}</Text>
                                                                                                        </Box>
                                                                                                        :
                                                                                                        item.header ?
                                                                                                            <Text largeBlue>{item.name}</Text>
                                                                                                            :
                                                                                                            <Text mediumLabel>{item.name}</Text>
                                                                                                }
                                                                                                {
                                                                                                    item.is_mask ? <MaskDataView pTop={'0px'} value={item.value} />
                                                                                                        :
                                                                                                <Text mediumBlack14>
                                                                                                    {
                                                                                                        item.url ?
                                                                                                            <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item) }}>
                                                                                                                <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />

                                                                                                                <Text mediumBlue>
                                                                                                                    {item.value && item.value.length > 22 ?
                                                                                                                        <BlackToolTip arrow placement='top' title={item.value}>
                                                                                                                            {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "")}
                                                                                                                        </BlackToolTip>
                                                                                                                        :
                                                                                                                        item.value}
                                                                                                                </Text>

                                                                                                            </Stack>
                                                                                                            :
                                                                                                            item.value && item.value.length > 22 ?
                                                                                                                <BlackToolTip title={item.value} placement="top" arrow>
                                                                                                                    {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "")}
                                                                                                                </BlackToolTip>
                                                                                                                :
                                                                                                                item.value
                                                                                                    }
                                                                                                </Text>
                                                                                                }
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </Grid>
                                                                        {
                                                                            LocalStorage.getUserData().admin_login && (data.length > 0 && data[0].status == 'Requested') ?
                                                                                <Grid item lg={12} ms={12} sm={12} xs={12} textAlign='center'>
                                                                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                                                                        <LoadingButton loading={rejectLoad} smallBorderBlue onClick={() => approveUpdate('Rejected')}>Reject</LoadingButton>
                                                                                        <LoadingButton scrollBtn loading={approveLoad} onClick={() => approveUpdate('Approved')}>Approve</LoadingButton>
                                                                                    </Box>
                                                                                </Grid> : ''
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <ReusablePopup openPopup={openApprove} setOpenPopup={setOpenApprove} iconHide fixedWidth white>
                                                    <Grid container item lg={12} xs={12} justifyContent='center' spacing={2}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <img src={approveImg} alt="approve" />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <Text largeBlack22>Changes {status}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <Text offBoardBodyFont>You have successfully {status} the profile <br />updating of <span className={classes.boldName}>{data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name) : '-'}</span>.</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <Button saveBtn400 onClick={okayHandler}>Okay</Button>
                                                        </Grid>
                                                    </Grid>
                                                </ReusablePopup>
                                                <ReusablePopup openPopup={openVisaChange} setOpenPopup={setOpenVisachange} statusWidth white iconHide>
                                                    <Grid container item lg={12} xs={12} textAlign='center' spacing={2} p={2}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <img src={visaUpdated} alt="approve" className={classes.visaUpdated} />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <Text largeBlack22>E-Verify Employee</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                            <Text offBoardBodyFont>As &nbsp;{data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name) : '-'}&nbsp; visa type has been changed, please ensure that the employee undergoes the E-verification process.</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} mt={2}>
                                                            <Button blueButton onClick={() => setOpenVisachange(false)}
                                                                sx={{
                                                                    [theme.breakpoints.down('sm')]: {
                                                                        width: '70% !important',
                                                                    }
                                                                }}
                                                            >Done</Button>
                                                        </Grid>
                                                    </Grid>
                                                </ReusablePopup>
                                            </Box>
                                    }
                                </Grid>
                            </Grid >
                        }
                    </> :
                    <>
                        {NoPermission()}
                    </>
            }

            <ReusablePopup openPopup={openReject} setOpenPopup={setOpenRejected} iconHide fixedWidth white>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                        <img src={rejectSVg} alt='rejectSVg' />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                        <Text offBoardHeadFont sx={{ fontSize: "22px !important" }}>Are you sure?</Text>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} pt={1} textAlign={"center"}>
                        <Text offBoardBodyFont>Do you want to reject the request?</Text>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3} >
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'rejectComment',
                                value: rejectComment,
                                inputProps: { maxLength: 250 }
                            }}
                            clientInput
                            labelText={'Add reason for rejection'}
                            handleChange={handleRejectChange}
                            error={error}
                        />
                        <Text red> {error ? error : ""}</Text>
                    </Grid>
                    <Grid container item md={12} sm={12} mt={3} justifyContent={"center"} gap={2}>
                        <Button disabled={rejectLoad} sx={{
                            width: "150px !important", font: "15px Quicksand !important", fontWeight: `${600} !important`, borderRadius: "8px !important",
                            [theme.breakpoints.down('sm')]: {
                                width: "70px !important"
                            }
                        }} outlineBlueMedium1 onClick={() => { setOpenRejected(false); setError(''); setRejectComment(''); setRejectLoad(false); removeLoader() }} >Cancel</Button>
                        <LoadingButton saveBtn sx={{
                            minWidth: '150px !important', font: "15px Quicksand !important",
                            [theme.breakpoints.down('sm')]: {
                                minWidth: "100px !important"
                            }
                        }} loading={rejectLoad} onClick={rejectHandler}>Yes, Reject</LoadingButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </>
    )
}