import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";

class ClientsApi {
    listing(data) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/client/listing`, data, LocalStorage.getAccessToken())
    }

    getTransactionInvoiceList(company_id, page, limit) {
        return BaseApi.getWithParams(APIURL.API_URL + `ledgers/invoice/listing?request_id=${LocalStorage.uid()}&page=${page}&limit=${limit}&company_id=${company_id}`, LocalStorage.getAccessToken())
    }
    getTransactionPaymentList(company_id, page, limit) {
        return BaseApi.getWithParams(APIURL.API_URL + `ledger-payments/payment/listing?request_id=${LocalStorage.uid()}&page=${page}&limit=${limit}&company_id=${company_id}`, LocalStorage.getAccessToken())
    }
    getTransactionConsultantList(client_id, page, limit) {
        return BaseApi.getWithParams(APIURL.API_URL + `placement/listing?request_id=${LocalStorage.uid()}&page=${page}&limit=${limit}&client_id=${client_id}`, LocalStorage.getAccessToken())
    }
    // getTransactionInvoiceList(slug, company_id, page, limit) {
    //     return BaseApi.getWithParams(APIURL.API_URL + `ledgers/${slug}/listing?request_id=${LocalStorage.uid()}&page=${page}&limit=${limit}&company_id=${company_id}`, LocalStorage.getAccessToken())
    // }

    destroy(id, data) {
        return BaseApi.deleteWithToken(APIURL.API_URL + `companies/client/destroy/${id}`, data, LocalStorage.getAccessToken());
    }

    storeCompany(data, slug) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}`, data, LocalStorage.getAccessToken());
    }
    storeContact(data, slug) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/contacts`, data, LocalStorage.getAccessToken());
    }
    storeTimesheet(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/timesheet/store`, data, LocalStorage.getAccessToken());
    }
    storeInvoice(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/invoice/store`, data, LocalStorage.getAccessToken());
    }

    companyIndexApi(id, slug) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    contactIndex(id, slug) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/contacts?company_id=${id}&request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    timesheetIndex(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/timesheet/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    invoiceIndex(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/invoice/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }

    updateCompany(data, slug) {
        return BaseApi.putWithToken(APIURL.client_URL + `company/${slug}`, data, LocalStorage.getAccessToken())
    }
    updateContact(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `companies/client/contacts/update/${id}`, data, LocalStorage.getAccessToken());
    }


    getCompanyDetails(path, id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/${path}/company-details/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    storeCompanyDetails(path, data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/${path}/company-details/store`, data, LocalStorage.getAccessToken());
    }
    updateCompanyDetails(path, id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `companies/${path}/company-details/update/${id}`, data, LocalStorage.getAccessToken());
    }
    deleteCompanyDetails(path, id) {
        return BaseApi.deleteWithToken(APIURL.API_URL + `companies/${path}/destroy/${id}`, { request_id: LocalStorage.uid() }, LocalStorage.getAccessToken())
    }
    updateStatus(slug, data) {
        return BaseApi.putWithToken(APIURL.client_URL + `company/${slug}/status`, data, LocalStorage.getAccessToken());
    }

    getContactsDetails(path, id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/${path}/contacts/index?request_id=${LocalStorage.uid()}&company_id=${id}`, LocalStorage.getAccessToken());
    }
    storeContactsDetails(path, data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/${path}/contacts/store`, data, LocalStorage.getAccessToken());
    }
    updateContactsDetails(slug, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `company/${slug}/contacts/update`, data, LocalStorage.getAccessToken());
    }
    deleteContactsDetails(path, id, data) {
        return BaseApi.deleteWithToken(APIURL.API_URL + `companies/${path}/contacts/destroy/${id}`, data, LocalStorage.getAccessToken())
    }
    uploadClientLogo(slug, data) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/logo`, data, LocalStorage.getAccessToken());
    }



    getTimesheetConfiguration(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/timesheet/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    storeTimesheetConfiguration(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/timesheet/store`, data, LocalStorage.getAccessToken());
    }
    updateTimesheetConfiguration(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `companies/timesheet/update/${id}`, data, LocalStorage.getAccessToken());
    }


    getInvoiceConfiguration(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/invoice/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    storeInvoiceConfiguration(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `companies/invoice/store`, data, LocalStorage.getAccessToken());
    }
    updateInvoiceConfiguration(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `companies/invoice/update/${id}`, data, LocalStorage.getAccessToken());
    }
    updateTimesheet(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `companies/timesheet/update/${id}`, data, LocalStorage.getAccessToken());
    }

    dropdown(slug, search) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/dropdown?request_id=${LocalStorage.uid()}&status=${search}`, LocalStorage.getAccessToken());
    }

    contactsDropdown(slug, id) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/contacts/dropdown?request_id=${LocalStorage.uid()}&company_id=${id}`, LocalStorage.getAccessToken());
    }

    destroyContact(slug, id, data) {
        return BaseApi.deleteWithToken(APIURL.API_URL + `companies/${slug}/contacts/destroy/${id}`, data, LocalStorage.getAccessToken())
    }

    duplicateCheck(data) {
        return BaseApi.putWithToken(APIURL.placement_URL + `placement/duplicate-check`, data, LocalStorage.getAccessToken())
    }

    overView(id, year) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/client/analytics?id=${id}&year=${year}&request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    //export
    exportactivity(slug, data) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/activity/export`, data, LocalStorage.getAccessToken())
    }

    downloadActivity(data, slug) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/activity/listing`, data, LocalStorage.getAccessToken())
    }

    downloadExport(slug) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/export?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    placementClientListing(id, slug) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/placement/${slug}/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    deductionDropdown() {
        return BaseApi.getWithParams(APIURL.client_URL + `payroll-config/expense-management/deduction/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    reimbursementDropdown() {
        return BaseApi.getWithParams(APIURL.client_URL + `payroll-config/expense-management/reimbursement/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    zohoIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/company/zoho_books`, data, LocalStorage.getAccessToken())
    }
    cilentProfileDeleteApi(data, slug) {
        return BaseApi.putWithToken(APIURL.client_URL + `company/${slug}/logo`, data, LocalStorage.getAccessToken())
    }
    cilentDocumentApi(slug, id) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/document/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    clientStoreDocumentApi(slug, id, data) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/document/${id}`, data, LocalStorage.getAccessToken())
    }

    quickbookIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/company/quick_books`, data, LocalStorage.getAccessToken())
    }

    //Client_invoice_configuration_api
    clientStoreInvoiceApi(slug, data) {
        return BaseApi.postWithToken(APIURL.client_URL + `company/${slug}/invoice_configuration`, data, LocalStorage.getAccessToken())
    }

    //client invoice configuration get api
    clientGetInvoiceApi(id, slug) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/${slug}/invoice_configuration/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
}
// eslint-disable-next-line
export default new ClientsApi();