import React from "react";
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    Paper,
} from "@mui/material";
import Text from "../../../components/customText/Text";
// import showPdfSaved from '../../../assets/svg/show_pdf_saved_icon.svg';
import showPdfDue from '../../../assets/svg/show_pdf_due_icon.svg';
import showPdfDraft from '../../../assets/svg/show_pdf_draft_icon.svg';
import showPdfPaid from '../../../assets/svg/show_pdf_paid_icon.svg';
import showPdfPartially from '../../../assets/svg/show_pdf_partially_paid_icon.svg';
import showPdfWritten from '../../../assets/svg/show_pdf_written_off_icon.svg';
import showPdfVoided from '../../../assets/svg/show_pdf_voided_icon.svg';
import showPdfRejected from '../../../assets/svg/show_pdf_rejected_icon.svg';
import showPdfPending from '../../../assets/svg/show_pdf_pending_approval_icon.svg';
import TimeSheetTable from '../timesheets/newTimeSheets/TimeSheetTable';
// import { BlackToolTip } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";
import moment from "moment";
const InvoiceShowPdfScreen = (props) => {

    const { invoicePdfData } = props
    const getInvoiceStatusIcon = (status) => {
        switch (status) {
            case "Saved":
                return showPdfDue;
            case "Pending Approval":
                return showPdfPending;
            case "Approved":
                return showPdfDue;
            case "Drafted":
                return showPdfDraft;
            case "Sent":
                return showPdfDue;
            case "Partially Paid":
                return showPdfPartially;
            case "Paid":
                return showPdfPaid;
            case "Void":
                return showPdfVoided;
            case "Write Off":
                return showPdfWritten;
            case "Rejected":
                return showPdfRejected;
            default:
                return;
        }
    };
    const handleTable = (param, startDay) => {
        var dumArr = [];
        var finalDumArr = [];
        for (var i in param) {
            dumArr.push(param[i]);
            if (moment(param[i].date).format("d") === handleRetunrDay(startDay)) {
                let finalObj = {
                    billable_hours: handleLoopingArray(dumArr, "bill"),
                    ot_hours: handleLoopingArray(dumArr, "ot"),
                    total_hours: handleLoopingArray(dumArr, "total"),
                    timesheet: dumArr,
                }
                finalDumArr.push(finalObj);
                dumArr = [];
            }
        }
        if (dumArr.length !== 0) {
            let finalOb = {
                billable_hours: handleLoopingArray(dumArr, "bill"),
                ot_hours: handleLoopingArray(dumArr, "ot"),
                total_hours: handleLoopingArray(dumArr, "total"),
                timesheet: dumArr,
            }
            finalDumArr.push(finalOb);
        }
        handleLoopingArray(dumArr);
        return finalDumArr;
    }

    const handleRetunrDay = (param) => {
        switch (param) {
            case 'Monday':
                return '0'
            case 'Tuesday':
                return '1'
            case 'Wednesday':
                return '2'
            case 'Thursday':
                return '3'
            case 'Friday':
                return '4'
            case 'Saturday':
                return '5'
            case 'Sunday':
                return '6'
            default:
                break;
        }
    }
    // function for assigning total hours based all calculation
    const handleLoopingArray = (param, param1) => {
        var dumpArray = [];
        if (param1 === "total") {
            for (var i in param) {
                dumpArray.push(param[i].total_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
        else if (param1 === "ot") {
            for (var k in param) {
                dumpArray.push(param[k].ot_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
        else {
            for (var j in param) {
                dumpArray.push(param[j].billable_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
    }

    function sumTime(time1, time2) {
        // Split time into hours and minutes
        var [hours1, minutes1] = time1.split(':');
        var [hours2, minutes2] = time2.split(':');

        // Convert time to minutes
        var totalMinutes = parseInt(hours1) * 60 + parseInt(minutes1) + parseInt(hours2) * 60 + parseInt(minutes2);

        // Convert minutes back to HH:MM format
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        // Add leading zeros if necessary
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return hours + ':' + minutes;
    }
    const globalTimeCalculation = (arr) => {
        var start = "00:00";
        for (var i = 0; i < arr.length; i++) {
            start = sumTime(start, arr[i]);
        }
        return start;
    }

    const formattedInvoiceItems = invoicePdfData?.item_details?.map(({ time_sheets, ...rest }) => ({
        ...rest,
        timesheet: handleTable(time_sheets, rest?.timesheet_start_day || 'Monday')
    }));

    return (

        <Box
            sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 2,
                height: (LocalStorage.getUserData().super_admin || invoicePdfData?.can_approve) ? '60vh' : '70vh',

                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for WebKit browsers
                },
                "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer/Edge
                "scrollbar-width": "none", // Hide scrollbar for Firefox
            }}
        >
            <Box sx={{
                padding: 4
            }}>
                <Box sx={{
                    border: '3px solid #FAFAFA',
                    borderRadius: '12px',
                    padding: 3,
                    position: "relative",
                }}
                    mb={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <img
                            src={invoicePdfData.from_details && invoicePdfData.from_details[0]?.logo_url ? `${invoicePdfData.from_details[0]?.logo_url}?w=40&h=40&fit=crop` : ''}
                            alt="Logo"
                            style={{ width: 50, height: 50, borderRadius: "50%" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 20,
                            right: -10,
                        }}
                    >
                        <img
                            src={getInvoiceStatusIcon(invoicePdfData?.status)}
                            alt="showPdfSaved"

                        />
                    </Box>
                    <Box sx={{
                        border: `3px solid ${invoicePdfData.theme?.sub_color || '#ECF5FF'} !important`,
                        borderRadius: '6px',
                        padding: 2,
                        background: `${invoicePdfData.theme?.sub_color || '#E8F1FF'} !important`
                    }}
                        mb={3} mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Text mediumBlue600 sx={{
                                    fontSize: '20px !important', color: `${invoicePdfData.theme?.color || '#0C75EB'} !important`
                                }}>
                                    Invoice
                                </Text>
                                <Text black18px sx={{ fontSize: '12px !important' }} mt={1}>{invoicePdfData.invoice_id}</Text>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                <Text BlackExtraDark sx={{ fontSize: '14px !important' }}>Invoice Amount</Text>
                                <Text mediumBlue600 sx={{
                                    fontWeight: '700', fontSize: '16px !important', color: `${invoicePdfData.theme?.color || '#0C75EB'} !important`
                                }} mt={1}>
                                    ${invoicePdfData.total_amount}
                                </Text>
                                <Text mediumViewAmt sx={{ fontSize: '14px !important' }} mt={1}>Due Date {invoicePdfData?.due_date}</Text>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="space-between">
                            {/* From Section */}
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Box>
                                    <Text blackHeader1>
                                        From: {invoicePdfData.from_details?.[0]?.organization_name}
                                    </Text>
                                    <Text
                                        mt={1}
                                        largeLabel
                                        sx={{
                                            fontWeight: '400 !important',
                                            fontSize: "13px !important",
                                            whiteSpace: "normal",
                                            wordBreak: "break-word",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {invoicePdfData.from_details?.[0]?.total_address}
                                    </Text>
                                </Box>
                            </Grid>

                            {/* To Section (Aligned to the end) */}
                            <Grid item xs={12} sm={6} md={6} lg={4} display="flex" justifyContent={{ lg: "flex-end" }}>
                                <Box textAlign={{ xs: "left", sm: "right" }}>
                                    <Text blackHeader1>
                                        To: {invoicePdfData.to_details ? invoicePdfData.to_details[0]?.name : ""}
                                    </Text>
                                    <Text
                                        mt={1}
                                        largeLabel
                                        sx={{
                                            fontWeight: '400 !important',
                                            fontSize: "13px !important",
                                            whiteSpace: "normal",
                                            wordBreak: "break-word",
                                            overflowWrap: "break-word",
                                            display: "inline-block",
                                        }}
                                    >
                                        {invoicePdfData.to_details?.[0]?.total_address}
                                    </Text>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Divider sx={{ marginY: 2 }} />

                    {/* <Box display="flex" justifyContent="space-evenly" alignItems="center" mb={2.5}>
                        <Box>
                            <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                Invoice Date:  {invoicePdfData.invoice_date}
                            </Text>
                        </Box>
                        <Divider
                            sx={{
                                margin: "0px !important",
                                borderColor: "#171717 !important",
                                borderWidth: "1px !important",
                                height: '20px'
                            }}
                        />
                        <Box>
                            <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                Terms: {invoicePdfData.net_pay_terms} Days
                            </Text>
                        </Box>
                        <Divider
                            sx={{
                                margin: "0px !important",
                                borderColor: "#171717 !important",
                                borderWidth: "1px !important",
                                height: '20px'
                            }}
                        />
                        <Box>
                            <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                Due Date: {invoicePdfData.due_date}
                            </Text>
                        </Box>
                    </Box> */}
                    <Grid
                        container
                        spacing={2}
                        justifyContent="space-evenly"
                        alignItems="center"
                        mb={2.5}
                    >
                        <Grid item xs={12} sm="auto">
                            <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                Invoice Date: {invoicePdfData.invoice_date}
                            </Text>
                        </Grid>

                        <Grid item xs={12} sm="auto">
                            <Divider
                                sx={{
                                    margin: "0px !important",
                                    borderColor: "#171717 !important",
                                    borderWidth: "1px !important",
                                    height: { xs: '1px', sm: '20px' },
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                Terms: {invoicePdfData.net_pay_terms} Days
                            </Text>
                        </Grid>

                        <Grid item xs={12} sm="auto">
                            <Divider
                                sx={{
                                    margin: "0px !important",
                                    borderColor: "#171717 !important",
                                    borderWidth: "1px !important",
                                    height: { xs: '1px', sm: '20px' },
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm="auto">
                            <Text blackHeader1 sx={{ fontSize: { xs: '12px', sm: '13px' } }}>
                                Due Date: {invoicePdfData.due_date}
                            </Text>
                        </Grid>
                    </Grid>
                    {invoicePdfData.item_details && <Divider sx={{ marginY: 2 }} />}
                    <Box sx={{ width: "100%", overflow: "auto" }} pt={2}>
                        <TableContainer component={Paper} sx={{ border: '1px solid #E2E5E6', borderRadius: '10px', minWidth: 550, }}>
                            <Table>
                                {invoicePdfData.item_details && <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '8%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>S.No</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '30%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Details</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '10%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Hours</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '10%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Rate</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '13%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Hours</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '13%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Rate</Text></TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", borderRight: "1px solid rgba(0, 0, 0, 0.12)", textAlign: 'center', width: '12%' }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Amount</Text></TableCell>
                                    </TableRow>
                                </TableHead>}
                                <TableBody>
                                    {invoicePdfData.item_details && invoicePdfData.item_details?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '8%' }}>
                                                <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', }}>
                                                    {index + 1}
                                                </Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '35%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', fontWeight: '700 !important', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.item_name ? row.item_name : '--'}
                                                </Text>

                                                <Text mediumLabel sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>{row.description ? row.description : "--"}</Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '8%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.hours}
                                                </Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.bill_rate}
                                                </Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.ot_hours}
                                                </Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.ot_bill_rate}
                                                </Text>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: '10%' }}>
                                                <Text blackHeader1 sx={{
                                                    fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {row.amount}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box mt={3} display="flex" justifyContent="flex-end" mb={4}>
                        <Box>
                            <Box display="flex" justifyContent="space-between" gap={3}>
                                <Text greysmallLabel>Sub Total</Text>
                                <Text black400 sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.sub_total_amount ? invoicePdfData.sub_total_amount : '--'}</Text>
                            </Box>
                            {invoicePdfData.discount_amount ? <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                <Text greysmallLabel>Discount</Text>
                                <Text veryLargeLabel sx={{ fontSize: '12px !important', color: '#EA5B45 !important' }}>  $ {invoicePdfData.discount_amount ? `- ${invoicePdfData.discount_amount}` : '--'}</Text>
                            </Box> : ''}
                            {(invoicePdfData.adjustment_amount && invoicePdfData.adjustment_amount != 0.00) ? <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                <Text greysmallLabel>Adjustments</Text>
                                <Text veryLargeLabel sx={{ fontSize: '12px !important', color: invoicePdfData.adjustment_type == 2 ? '#EA5B45 !important' : '' }}>{invoicePdfData.adjustment_type == 2 && '-'} $ {invoicePdfData.adjustment_amount ? invoicePdfData.adjustment_amount : '--'}</Text>
                            </Box> : ''}
                            <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                <Text greysmallLabel>Total</Text>
                                <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.total_amount ? invoicePdfData.total_amount : '--'}</Text>
                            </Box>
                            {invoicePdfData.payment_made == '0' ? '' : <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                <Text greysmallLabel>Payment Made</Text>
                                <Text veryLargeLabel sx={{ fontSize: '12px !important', color: '#EA5B45 !important' }}>  $ {invoicePdfData.payment_made ? `- ${invoicePdfData.payment_made}` : '--'}</Text>
                            </Box>}
                            <Divider sx={{ marginY: 1 }} />
                            <Box display="flex" justifyContent="space-between" gap={3}>
                                <Text greysmallLabel>Balance Due:</Text>
                                <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ {invoicePdfData.balance_amount ? invoicePdfData.balance_amount : '--'}</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {
                    invoicePdfData.terms_and_conditions ? <Box
                        sx={{
                            border: '1px solid #FAFAFA',
                            borderRadius: '12px',
                            padding: 3,
                        }}
                        mb={3}
                    >
                        <Text mb={4} veryLargeLabel sx={{ fontSize: '16px !important' }}>
                            Terms & Conditions
                        </Text>
                        <Box sx={{ marginBottom: 2 }}>
                            <pre>
                                <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px important', }}>{invoicePdfData?.terms_and_conditions}</Text>
                            </pre>
                        </Box>
                    </Box> : ''
                }
                {
                    invoicePdfData.item_details && formattedInvoiceItems?.some(item => item?.timesheet?.length > 0) && <Box sx={{
                        border: '1px solid #FAFAFA',
                        borderRadius: '12px',
                        // overflow: "scroll"
                    }}
                        mb={3}>
                        {invoicePdfData.is_timesheets_attached ? formattedInvoiceItems?.some(item => item?.timesheet?.length > 0) && <>   <Text veryLargeLabel sx={{ fontSize: '15px !important', }} m={2}>Attachments</Text>
                            <Box>
                                {formattedInvoiceItems.map((value, index) => (
                                    value.timesheet?.map((data, i) =>
                                        <>
                                            {data?.timesheet.length > 0 &&
                                                <Grid container>
                                                    <Grid item lg={12} xs={6}>
                                                        <TimeSheetTable data={data} index={i} isEdit={true} />
                                                    </Grid>
                                                </Grid>}
                                        </>

                                    )


                                ))}
                            </Box></> : ''}
                    </Box>
                }
            </Box >
        </Box >
    );
};

export default InvoiceShowPdfScreen;
