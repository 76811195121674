import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const DocumentStyles = makeStyles(() => ({
    optional: {
        font: '14px Quicksand !important',
        color: 'rgb(194 188 188) !important',
        marginLeft: '4px !important'
    },
    ViewContainer: {
        height: '45vh',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    },
    dropzoneMainBox: {
        width: '100%',
        height: "31vh",
        [useTheme().breakpoints.down('sm')]:{
            height: "fit-content",
        }
    },
    dropBox: {
        width: '100%',
        height: "31vh",
        display: 'inline-block',
        backgroundColor: "#F2F7FF",
        border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
        [useTheme().breakpoints.down('sm')]:{
            height: "fit-content",
        }
    },
    dropBox1: {
        width: '100%',
        height: "31vh",
        display: 'inline-block',
        // backgroundColor: "#F2F7FF",
        // border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
        [useTheme().breakpoints.down('sm')]:{
            height: "fit-content",
        }
    },
    dropZone: {
        background: '#F0F7FF',
        border: `dashed 2px #0C75EB`,
        padding: '20px',
        borderRadius: '12px'
    },
    dropZoneDisable: {
        background: '#ECECEC',
        border: `dashed 2px #525252`,
        padding: '20px',
        borderRadius: '12px'
    },
    dropzoneText: {
        font: "15px Quicksand !important",
        fontWeight: "600 !important",
        color: '#171717 !important'
    },
    card:{
       padding: '10px !important', boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important", borderRadius: '15px',
        [useTheme().breakpoints.down('sm')]:{
            padding: '5px !important',
        }
    },
    cardContent:{
        padding: '30px 0px 20px 20px !important',
        [useTheme().breakpoints.down('lg')]:{
            padding: '20px 0px 30px 0px !important',
        },
        [useTheme().breakpoints.down('sm')]:{
            padding: '5px !important',
        }
    },
    bankCardContent:{
        padding: '10px 20px !important',
        [useTheme().breakpoints.down('lg')]:{
            padding: '10px !important'
        },
        [useTheme().breakpoints.down('sm')]:{
            padding: '10px 5px !important'
        }
    },
    cardHeader:{
        padding: '15px 0px 0px 23px !important',
        [useTheme().breakpoints.down('lg')]:{
            padding: '15px 0px 0px 0px !important',
        },
        [useTheme().breakpoints.down('sm')]:{
            padding: '15px 0px 15px 5px !important',
        }
    }
}))

export default DocumentStyles;