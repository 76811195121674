import { React, useState, useEffect, useCallback } from "react";
import { Box, Card, CardHeader, CardContent, Grid, useTheme } from "@mui/material";
import OnboardStyles from './OnboardStyles'
import Text from "../../components/customText/Text";
import { validate_emptyField, validate_zipcode, isValidMulti, validate_input_fields } from "../../components/Validation";
import { addErrorMsg, addLoader, addWarningMsg, capitalize, checkZipcodewithoutToken, removeLoader } from "../../utils/utils";
import onBoardLinkApi from "../../apis/onBoardLink/onBoardLinkApi";
import warning from '../../assets/svg/warning.svg';
import Input from '../../components/input/Input';
import Button from '../../components/customButton/Button';
import Select from '../../components/select/Select';
import onBoardLinkApiInstance from "../../apis/onBoardLink/onBoardLinkApi";
import LocalStorage from "../../utils/LocalStorage";
import { domain } from "../../config/Domain";
import { debounce } from "lodash";
import CommonApi from "../../apis/CommonApi";
import { ErrorMessages } from "../../utils/ErrorMessages";


function EmergencyContactInformation({ setMainStep, mainStep, state, setState, id, getInviteViaLink, emergencyContactDetailsConfig }) {
    const [erro, setErro] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const classes = OnboardStyles();

    const [dummyState, setDummyState] = useState([
        {
            relationship_id: '',
            relation_name: null,
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            state_name: null,
            country_id: '',
            country_name: null,
            email_id: ''
        },
        {
            relationship_id: '',
            relation_name: null,
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            state_name: null,
            country_id: '',
            country_name: null,
            email_id: ''
        },
    ])

    const changeHandler = (e, index) => {
        // console.log(index, "index");
        if (e.target.name == "mobile_number") {
            convertFormat(e, index);
        }
        else if (e.target.name == "country_id") {
            dummyState[index][e.target.name] = e.target.value
            dummyState[index]['state_id'] = ''
            dummyState[index]['zip_code'] = ''
        } else {
            dummyState[index][e.target.name] = e.target.name === 'name' ? capitalize(e.target.value) : e.target.value;
        }
        setDummyState([...dummyState])
        handleValidateContact(e, index);
        if (e.target.name == 'zip_code') {
            zipcodeAPICallEmergency(e.target.value, index)
        }
    };

    const resetStateWithErrorEmergency = (data, index) => {
        // Correctly update the specific index of dummyState without affecting other indices
        setDummyState((prevDummyState) =>
            prevDummyState.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        state_id: '',
                        state_name: '',
                        country_name: '',
                        city: '',
                        country_id: '',
                        zip_code: data
                    }
                    : item // Preserve other indices
            )
        );

        // Correctly update the specific index of erro without affecting other indices
        setErro((prevErro) =>
            prevErro.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        city: '',
                        state_id: '',
                        country_id: '',
                        zip_code:
                            data.length == 0 ? ErrorMessages.fieldRequired :
                                data.length === 6
                                    ? ErrorMessages.invalidPincode
                                    : data.length === 5
                                        ? ErrorMessages.invalidZipcode
                                        : ErrorMessages.invalidPinZipcode
                    }
                    : item // Preserve other indices
            )
        );
    };
    // eslint-disable-next-line
    const zipcodeAPICallEmergency = useCallback(
        debounce((data, index) => {
            CommonApi.getZipcodeDataWithoutToken(data).then((res) => {
                const { statusCode, data: responseData } = res.data;

                if (statusCode === 1003 && responseData.length > 0) {
                    const { state_name, country_name, city, state_id, country_id } = responseData[0];

                    // Update the specific index in dummyState without affecting others
                    setDummyState((prevDummyState) =>
                        prevDummyState.map((item, i) =>
                            i === index
                                ? {
                                    ...item,
                                    state_id,
                                    state_name,
                                    country_name,
                                    city,
                                    country_id,
                                    zip_code: data
                                }
                                : item // Preserve other indices
                        )
                    );

                    // Update the specific index in erro without affecting others
                    setErro((prevErro) =>
                        prevErro.map((item, i) =>
                            i === index
                                ? {
                                    ...item,
                                    city: '',
                                    zip_code: '',
                                    state_id: '',
                                    country_id: ''
                                }
                                : item // Preserve other indices
                        )
                    );
                } else {
                    // Reset only the specific index if no data is found
                    resetStateWithErrorEmergency(data, index);
                }
            });
        }, 500), // 500ms delay
        [] // No dependencies needed since we're using setState callbacks
    );





    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' ? 6 : 5)) {
            dummyState[index][e.target.name] = `${first}-${middle}-${last}`
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
        else if (input.length > 3) {
            dummyState[index][e.target.name] = `${first}-${middle}`
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
        else if (input.length >= 0) {
            dummyState[index][e.target.name] = input
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
    }

    const handleValidateContact = (e, index) => {
        let input = e.target;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "mobile_number":
                error.mobile_number = validate_input_fields(input.value.replace(/\D/g, '').substring(0, 10), emergencyContactDetailsConfig?.mobile_number);
                break;
            case "relationship_id":
                error.relationship_id = validate_input_fields(input.value, emergencyContactDetailsConfig?.relationship_id);
                break;
            case "address_1":
                error.address_1 = validate_input_fields(input.value, emergencyContactDetailsConfig?.address_1);
                break;
            case "address_2":
                error.address_2 = validate_input_fields(input.value, emergencyContactDetailsConfig?.address_2);
                break;
            case "name":
                error.name = validate_input_fields(input.value, emergencyContactDetailsConfig?.name);
                break;
            case "city":
                error.city = validate_input_fields(input.value, emergencyContactDetailsConfig?.city);
                break;
            case "zip_code":
                error.zip_code = validate_zipcode(input.value, dummyState[index].country_id);
                break;
            case "country_id":
                error.country_id = validate_emptyField(input.value);
                break;
            case "state_id":
                error.state_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setErro(s2);
    };

    useEffect(() => {
        getRelationList();
        // eslint-disable-next-line
    }, []);


    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImI5ZjQ2ZWNmLTk0ZGItNDRhNC04YmQyLTQyMDhhMTQ4MGRhNSIsImlzX3N1cGVyX2FkbWluIjpmYWxzZSwidXNlck5hbWUiOiJtZWdoYXJhaiBwbiIsInRlbmFudF9pZCI6ImI3OTQzZGE2LWRiMWYtNDdlZi04YjMzLWRlMjk4MmMxZTI3MiIsInN1YmRvbWFpbl9uYW1lIjoibmV3c2NyZWVuIiwicm9sZV9pZCI6MiwiaWF0IjoxNzA1OTI4Mzg5LCJleHAiOjE3MDYwMTQ3ODl9.GjX597fbmB-kAL9alSqdpV7JfqkrnFEmb03VJPD_Y30";

    const getRelationList = () => {
        onBoardLinkApi.relation().then((res) => {
            if (res.data.statusCode === 1003) {
                setRelationList(res.data.data);
            } else {
                addErrorMsg(res.data.message);
            }
        });
    }
    const [btLoading, setBtnLoading] = useState(false);
    const handleSubmit = async () => {
        let errors = await validateAll();
        if (isValidMulti(errors)) {
            state['subdomain_name'] = domain
            state['request_id'] = LocalStorage.uid();
            state.emergency_contacts = dummyState
            state['final'] = true
            state['basic_details'] = false
            setState({ ...state })
            addLoader(true)
            setBtnLoading(true);
            onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
                setBtnLoading(false);
                removeLoader()
                if (res.data.statusCode == 1003) {
                    getInviteViaLink(id);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        }
        else {
            removeLoader()
            let s1 = { erro }
            s1 = errors;
            setErro(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    };

    const validateAll = async () => {
        const err = await Promise.all(
            dummyState.map(async (value) => {
                const errorsus = {}; // Initialize for each item

                errorsus.name = validate_input_fields(value.name, emergencyContactDetailsConfig?.name)
                errorsus.relationship_id = validate_input_fields(value.relationship_id, emergencyContactDetailsConfig?.relationship_id)
                errorsus.address_1 = validate_input_fields(value.address_1, emergencyContactDetailsConfig?.address_1)
                errorsus.address_2 = validate_input_fields(value.address_2, emergencyContactDetailsConfig?.address_2)
                errorsus.mobile_number = validate_input_fields(value.mobile_number, emergencyContactDetailsConfig?.mobile_number);
                errorsus.city = (!emergencyContactDetailsConfig?.city?.isVisible || emergencyContactDetailsConfig?.city?.isDisabled) ? "" : validate_emptyField(value.city);
                errorsus.zip_code = (!emergencyContactDetailsConfig?.zip_code?.isVisible || emergencyContactDetailsConfig?.zip_code?.isDisabled) ? "" : await checkZipcodewithoutToken(value.zip_code); // Await async operation
                errorsus.country_id = (!emergencyContactDetailsConfig?.country_id?.isVisible || emergencyContactDetailsConfig?.country_id?.isDisabled) ? "" : validate_input_fields(value.country_id, emergencyContactDetailsConfig?.country_id);
                errorsus.state_id = (!emergencyContactDetailsConfig?.state_id?.isVisible || emergencyContactDetailsConfig?.state_id?.isDisabled) ? "" : validate_input_fields(value.state_id, emergencyContactDetailsConfig?.state_id);

                return errorsus; // Return the error object for this item
            })
        );

        setErro(err); // Update state with the array of error objects

        return err; // Return the final error array
    };

    const theme = useTheme();


    return (
        <Grid container item lg={12} md={12} sm={12} xs={11.9}>
            <Card sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '10px 20px', [theme.breakpoints.down('sm')]: { padding: '10px 5px' } }}>
                <CardHeader
                    title={
                        <Box display='flex' flexDirection='row' gap={1.5} className={classes.information}>
                            <Box pt={'5px'}>
                                <img src={warning} alt="warning" />
                            </Box>
                            <Box>
                                <Text mediumOrangeRehire>Please provide information of 2 of your emergency contacts.</Text>
                            </Box>
                        </Box>}
                />
                <CardContent sx={{ textAlign: 'start', height: '400px', overflowY: 'scroll', [theme.breakpoints.down('md')]: { height: '60vh' }  }}>
                    {
                        dummyState.map((item, index) => (
                            <>
                            <Text headerBlack sx={{ paddingTop: '10px' }}>Emergency Contact Details - {index + 1}</Text>
                                <Grid container spacing={3} my={2} mb={2}>
                                    {emergencyContactDetailsConfig?.name?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'name',
                                                value: item.name,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.name?.maxLength }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            placeholder={emergencyContactDetailsConfig?.name?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.name?.required ? emergencyContactDetailsConfig?.name?.label : `${emergencyContactDetailsConfig?.name?.label} (Optional)`}
                                            disabled={emergencyContactDetailsConfig?.name?.isDisabled}
                                            error={erro.length > 0 && erro[index] ? erro[index].name : ''} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].name : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.mobile_number?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'mobile_number',
                                                value: item.mobile_number,
                                                // inputProps: { maxLength: 12 },
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            placeholder={emergencyContactDetailsConfig?.mobile_number?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.mobile_number?.required ? emergencyContactDetailsConfig?.mobile_number?.label : `${emergencyContactDetailsConfig?.mobile_number?.label} (Optional)`}
                                            disabled={emergencyContactDetailsConfig?.mobile_number?.isDisabled}
                                            error={erro.length > 0 && erro[index] ? erro[index].mobile_number : ""} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].mobile_number : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.relationship_id?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Select
                                            name='relationship_id'
                                            value={item.relationship_id}
                                            onChange={(e) => changeHandler(e, index)}
                                            options={relationList}
                                            placeholder={emergencyContactDetailsConfig?.relationship_id?.placeholder}
                                            label={emergencyContactDetailsConfig?.relationship_id?.required ? emergencyContactDetailsConfig?.relationship_id?.label : `${emergencyContactDetailsConfig?.relationship_id?.label} (Optional)`}
                                            disabled={emergencyContactDetailsConfig?.relationship_id?.isDisabled}
                                            error={erro.length > 0 && erro[index] ? erro[index].relationship_id : ""}
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].relationship_id : ""}</Text>) : ''}
                                    </Grid>}
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
                                    {emergencyContactDetailsConfig?.address_1?.isVisible && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_1',
                                                value: item.address_1,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.address_1?.maxLength }
                                            }}
                                            clientInput
                                            handleChange={(e) => changeHandler(e, index)}
                                            placeholder={emergencyContactDetailsConfig?.address_1?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.address_1?.required ? emergencyContactDetailsConfig?.address_1?.label : `${emergencyContactDetailsConfig?.address_1?.label} (Optional)`}
                                            disabled={emergencyContactDetailsConfig?.address_1?.isDisabled}
                                            error={erro.length > 0 && erro[index] ? erro[index].address_1 : ""} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].address_1 : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.address_2?.isVisible && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_2',
                                                value: item.address_2,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.address_2?.maxLength }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            placeholder={emergencyContactDetailsConfig?.address_2?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.address_2?.required ? emergencyContactDetailsConfig?.address_2?.label : `${emergencyContactDetailsConfig?.address_2?.label} (Optional)`}
                                            disabled={emergencyContactDetailsConfig?.address_2?.isDisabled}
                                            error={erro.length > 0 && erro[index] ? erro[index].address_2 : ""} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].address_2 : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.zip_code?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'zip_code',
                                                value: item.zip_code,
                                                disabled: item.country_id === '',
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.zip_code?.maxLength }
                                            }}
                                            disabled={emergencyContactDetailsConfig?.zip_code?.isDisabled}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            error={erro.length > 0 && erro[index] ? erro[index].zip_code : ""}
                                            labelText={item?.zip_code?.length == parseInt(emergencyContactDetailsConfig?.zip_code?.zipcodeLength) ? emergencyContactDetailsConfig?.zip_code?.zipcodeLabel : item?.zip_code?.length == parseInt(emergencyContactDetailsConfig?.zip_code?.pincodeLenght) ? emergencyContactDetailsConfig?.zip_code?.pincodeLabel : emergencyContactDetailsConfig?.zip_code?.label}
                                        />
                                        {/*{ console.log(erro, 'erro')}*/}
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].zip_code : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.city?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'city',
                                                value: item.city,
                                                inputProps: { maxLength: emergencyContactDetailsConfig?.city?.maxLength }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            error={erro.length > 0 && erro[index] ? erro[index].city : ""}
                                            placeholder={emergencyContactDetailsConfig?.city?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.city?.label}
                                            disabled={emergencyContactDetailsConfig?.city?.isDisabled}
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].city : ""}</Text>) : ''}
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.state_id?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'state_name',
                                                value: item.state_name || '',
                                            }}
                                            disabled={true || emergencyContactDetailsConfig?.state_id?.isDisabled}
                                            clientInput
                                            placeholder={emergencyContactDetailsConfig?.state_id?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.state_id?.label}
                                        />
                                    </Grid>}
                                    {emergencyContactDetailsConfig?.country_id?.isVisible && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'country_name',
                                                value: item.country_name || '',
                                            }}
                                            disabled={true || emergencyContactDetailsConfig?.country_id?.isDisabled}
                                            clientInput
                                            placeholder={emergencyContactDetailsConfig?.country_id?.placeholder}
                                            labelText={emergencyContactDetailsConfig?.country_id?.label}
                                        />
                                    </Grid>}
                                </Grid></>
                        ))
                    }
                </CardContent>
            </Card>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='space-between' pt={3}>
                <Button disabled={btLoading} blackCancel onClick={() => setMainStep(mainStep - 1)}>Back</Button>
                <Button saveNcontinueSmall onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
    )

}

export default EmergencyContactInformation;