import { Avatar, Box, Checkbox, Divider, Grid } from "@mui/material"
import Text from "../../../components/customText/Text";
import Button from '../../../components/customButton/Button';
import PersonIcon from '../../../assets/svg/personicon.svg';
import MailIcon from '../../../assets/svg/mailicon.svg';
import ViewInvoiceStyles from "./ViewInvoiceStyles";
import Input from "../../../components/input/Input";
import Date from "../../../components/datePicker/Date";
import Select from "../../../components/select/Select";
import Dropzone from "react-dropzone";
import download from '../../../assets/svg/clients/download-Arrow.svg';
import { useEffect, useState } from "react";
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, BlackToolTip, capitalizeAndAddSpace, dateFormat, openDocumentInNewtab, removeLoader, scrollToTop, } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { isValid, onCurrencyWithTwoDecimalOnlyChangeCurr, validate_emptyField } from "../../../components/Validation";
import moment from 'moment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CommonApi from "../../../apis/CommonApi";
import { ErrorMessages } from "../../../utils/ErrorMessages";
import InvoiceCreateAPI from "../../../apis/admin/invoice/InvoiceHistoryCilentAPI";
import LoaderButton from '../../../components/customButton/LoadingButton';

export default function EditRecordPaymentDrawer({ apix, data, setPaymentDrawer, paymentDrawer, callRefetch }) {
    const classes = ViewInvoiceStyles();
    const currency = LocalStorage.getUserData().currency_symbol ? LocalStorage.getUserData().currency_symbol : "$";

    const [error, setError] = useState({});
    // eslint-disable-next-line
    const [openBacKLoader, setOpenBackDrop] = useState(false); // eslint-disable-next-line 
    const [paymentModeOptions, setPaymentModeOptions] = useState([]); // eslint-disable-next-line 
    const [depositToOptions, setDepositToOptions] = useState([]);
    const [oldamount, setOldAmount] = useState()

    const [state, setState] = useState({
        amount: '',
        payment_date: '',
        payment_mode: '',
        deposit_to: '',
        id: '',
        note: '',
        send_email: false,
        documents: [],
        s_no: '',
        invoice_id: '',
        reference_id: '',
        documents_deleted_ids: []
    })
    const [buttonLoader, setButtonLoader] = useState(false);

    const openInNewTab = (documentUrl, url) => {
        if (documentUrl != '' && documentUrl != undefined && documentUrl != null) {
            openDocumentInNewtab('invoice_documents', documentUrl)
        } else {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    useEffect(() => {
        setError({})
        if (paymentDrawer) {
            scrollToTop()
            paymentModes()
            setOldAmount(data.amount)
            setState({
                ...data,
                documents_deleted_ids: deletedDocs
            });
        }// eslint-disable-next-line
    }, [paymentDrawer]);


    const paymentModes = () => {
        InvoiceCreateAPI.dropdownApi().then((res) => {
            if (res.data.statusCode === 1003) {
                setPaymentModeOptions(res.data.data.payment_modes);
                setDepositToOptions(res.data.data.deposit_types);
            }
        })
    }

    const [deletedDocs, setDeletedDocs] = useState([]);
    const deleteChip = (index, id) => {
        let updatedDocuments = [...state.documents];
        updatedDocuments.splice(index, 1);

        setState({ ...state, documents: updatedDocuments });

        if (id && !deletedDocs.includes(id)) {
            setDeletedDocs([...deletedDocs, id]);
        }
    };

    const handleDrop = (e) => {
        if (e.length < 6) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'png', 'jpg', 'jpeg'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 5) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("invoice_documents", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {

                                if (state.documents?.length < 5) {

                                    state.documents.push({
                                        id: '',
                                        new_document_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 5 files at a time. Please remove excess files and try again')
                                }
                                setState({ ...state })
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 5 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or Images only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 5 files at a time.')
        }
    }

    const dateChange = (e, name) => {

        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const checkAmountRecieved = (amount) => {
        let balanceAmount = parseFloat(apix.balance_amount);
        balanceAmount += parseFloat(oldamount)
        if (Number(amount) > Number(balanceAmount)) {//check the key from the backend and throw error for '50000'
            error.amount = `The total amount due is ${balanceAmount}. Please ensure that the payment amount does not exceed the outstanding balance.`
        }
        setError({ ...error })
    }

    const handleChange = (e) => {
        if (e.target.name == 'send_email') {
            state[e.target.name] = e.target.checked == true ? 1 : 0
            setState({ ...state })
        } else if (e.target.name == 'amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value;
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value;
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value;
            }

            setState({ ...state }, handleValidate(e));
        } else {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value,
                },
                handleValidate(e)
            );
        }

    }



    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "amount":
                error.amount = input.value == '$' ? 'This field is required' : validate_emptyField(input.value);
                break;
            case "payment_date":
                error.payment_date = validate_emptyField(input.value);
                break;
            case "payment_mode":
                error.payment_mode = validate_emptyField(input.value);
                break;
            case "deposit_to":
                error.deposit_to = validate_emptyField(input.value);
                break;
            case "id":
                error.id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    };

    // eslint-disable-next-line 
    const validateAll = () => {
        const { id, deposit_to, payment_mode, payment_date, amount } = state;
        const today = moment(moment().format(dateFormat()), dateFormat());


        error.id = validate_emptyField(id);
        error.deposit_to = validate_emptyField(deposit_to);
        error.payment_mode = validate_emptyField(payment_mode);
        error.payment_date = payment_date !== ''
            ? moment(payment_date, dateFormat()).isAfter(today)
                ? 'Payment date must be past or current date.' : payment_date == 'Invalid date'
                    ? ErrorMessages.validDate : validate_emptyField(payment_date) : validate_emptyField(payment_date)



        // error.payment_date = validate_emptyField(payment_date);

        error.amount = amount == '-' ? 'Please enter valid number' : validate_emptyField(amount);
        // checkPaymentDateError(payment_date);
        checkAmountRecieved(amount);
        setError({ ...error })
        return error;
    }
    console.log(state, "state")
    const handleSaveEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            const paymentMode = parseInt(state.payment_mode) ? paymentModeOptions.find((eachItem) => eachItem.id == state.payment_mode) : { value: state.payment_mode };
            const depositTo = parseInt(state.deposit_to) ? depositToOptions.find((eachItem) => eachItem.id == state.deposit_to) : { value: state.deposit_to };
            const data = {
                request_id: LocalStorage.uid(),
                invoice_id: apix.id, //integrate with other
                amount: state.amount,
                payment_date: state.payment_date,
                payment_mode: paymentMode ? paymentMode.value : state.payment_mode,
                deposit_to: depositTo ? depositTo.value : state.deposit_to,
                reference_id: state.reference_id,
                note: state.note,
                documents: state.documents,
                send_email: state.send_email,
                id: state.id,
                s_no: state.s_no,
                documents_deleted_ids: deletedDocs,
            }
            addLoader(true);
            setButtonLoader(true);
            InvoiceCreateAPI.recordPayment(data).then((response) => {
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                    setButtonLoader(false);
                    callRefetch();
                    setPaymentDrawer();
                    removeLoader();
                    setDeletedDocs([]);
                } else {
                    setButtonLoader(false);
                    addErrorMsg(response.data.message);
                    removeLoader();
                }
            });
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }


    return (
        <div>
            <Box width={{ lg: '608px', md: "608px", sm: "508px", xs: "310px" }} height={'100vh'} p={4} sx={{ position: 'relative' }}>
                <Grid container gap={2} sx={{ overflowY: 'hidden' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text mediumBlack14>Edit Payment for {apix?.reference_id}</Text>
                    </Grid>
                    <Grid item container className={classes.recordDetails} gap={0.5} lg={12} md={12} sm={12} xs={12}>
                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12}>
                            <Grid container lg={6} md={6} sm={6} xs={12} >
                                <Box display='flex' alignItems='center' gap={2} >
                                    {/* <Avatar
                                        alt={'name'}
                                        src={data?.to_details?.[0]?.logo_url}
                                        sx={{ width: 56, height: 56 }}
                                    /> */}

                                    <Avatar src={apix?.to_details?.[0]?.logo_url == ''
                                        || apix?.to_details?.[0]?.logo_url == null ?
                                        capitalizeAndAddSpace(apix?.to_details?.[0]?.name[0]) :
                                        apix?.to_details?.[0]?.logo_url} alt={capitalizeAndAddSpace(apix?.to_details?.[0]?.name)}
                                        sx={{
                                            height: '35px', width: '35px', borderRadius: '50% !important',
                                            background: apix?.to_details?.[0]?.logo_url ? '' : '#e5b256 !important'
                                        }} />
                                    <Box>
                                        <Text mediumBlack14 mb={1}>
                                            {apix?.to_details?.[0]?.name && apix?.to_details?.[0]?.name.length > 16 ?
                                                <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.name)} placement="top" arrow>
                                                    {capitalizeAndAddSpace(apix?.to_details?.[0]?.name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.name).length > 16 ? "..." : "")}
                                                </BlackToolTip>
                                                : apix?.to_details?.[0]?.name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.name) : '--'
                                            }
                                        </Text>
                                        <Text mediumLabel >{apix?.to_details?.[0]?.client_id ? apix?.to_details?.[0]?.client_id : '--'}</Text>

                                    </Box>
                                </Box>
                            </Grid>

                            <Grid container lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <Box display='flex' flexDirection={'column'} alignItems='start' >
                                    <Text mediumBlack14 mb={1} marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }}>Invoice Amount</Text>

                                    <Text mediumLabel marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }} > {`${currency} ${apix?.total_amount ? apix?.total_amount : '--'}`}</Text>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>

                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12} >
                            <Grid item container lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
                                <img src={PersonIcon} alt='person' style={{ height: '16px', width: '16px', marginRight: '8px' }} />

                                <Text mediumLabel >{apix?.to_details?.[0]?.poc_name && apix?.to_details?.[0]?.poc_name.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name) : '--'
                                }</Text>

                            </Grid>
                            <Grid item container alignItems={"center"} lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <img src={MailIcon} alt='person' style={{ height: '16px', width: '16px' }} />


                                <Text mediumLabel sx={{ marginLeft: '12px' }}>{apix?.to_details?.[0]?.poc_email && apix?.to_details?.[0]?.poc_email.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_email ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email) : '--'
                                }</Text>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} >
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>

                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'amount',
                                        value: currency + "" + state.amount,
                                    }}
                                    onKeyPress={onCurrencyWithTwoDecimalOnlyChangeCurr}
                                    handleChange={handleChange}
                                    clientInput
                                    labelText={'Amount Received'}
                                    error={error.amount}
                                />
                                {
                                    error.amount ?
                                        <Text red>{error.amount ? error.amount : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Date
                                    labelText={'Payment Date'}
                                    name='payment_date'
                                    height='54px'
                                    value={state.payment_date}
                                    onChange={(value => dateChange(value, 'payment_date'))}
                                    maxDate={moment().format(dateFormat())}
                                    error={error.payment_date ? error.payment_date : ''}
                                />
                                {
                                    error.payment_date ?
                                        <Text red>{error.payment_date ? error.payment_date : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Select
                                    name='payment_mode'
                                    value={state.payment_mode}
                                    onChange={handleChange}
                                    options={paymentModeOptions}
                                    label={'Payment Mode'}
                                    error={error.payment_mode ? error.payment_mode : ''}
                                />
                                {
                                    error.payment_mode ?
                                        <Text red>{error.payment_mode ? error.payment_mode : '-'}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box pt={1}>
                                <Select
                                    name='deposit_to'
                                    value={state.deposit_to}
                                    onChange={handleChange}
                                    options={depositToOptions}
                                    label={'Deposit To'}
                                    error={error.deposit_to ? error.deposit_to : ''}
                                />
                                {
                                    error.deposit_to ?
                                        <Text red>{error.deposit_to ? error.deposit_to : ''}</Text> : ''
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box pt={1}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'reference_id',
                                        value: state.reference_id,
                                        inputProps: { maxLength: 50 }
                                    }}
                                    clientInput
                                    handleChange={handleChange}
                                    labelText={'Reference ID'}
                                    error={error.id}
                                />
                                {
                                    error.id ?
                                        <Text red>{error.id ? error.id : ''}</Text> : ''
                                }
                            </Box>

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>

                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'note',
                                    value: state.note,
                                    inputProps: { maxLength: 255 }
                                }}
                                labelText={'Note (Optional)'}
                                handleChange={handleChange}
                                rows={4}
                                multiline={true}
                                // descriptionFormControl1
                                // descriptionInput
                                commentsField
                                error={error.note ? <Text red>{error.note ? error.note : ''}</Text> : ''}
                            />
                            {error.note ? <Text red>{error.note ? error.note : ''}</Text> : ''}

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>
                        <Grid item container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <Text mediumBlack14>Attachments</Text>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className={classes.dropZone}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Grid container spacing={1} textAlign='center' >
                                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                            <Text largeBlack>Drag & Drop Documents</Text>
                                                            <Text smallGrey>or</Text>
                                                            <Text smallBlue1 className={classes.clickHere} sx={{ cursor: 'pointer' }}>Click Here</Text>
                                                            <Text largeBlack>To Upload</Text>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'8px'}>
                                                        <img src={download} alt='download' />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                        <Text smallGreyFont>You can upload a maximum of 5 files, 5MB each</Text>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {
                                    state?.documents?.length > 0 ?
                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                            <Text smallLabel sx={{ font: "12px Quicksand !important" }}>{`Uploaded Documents (Optional)`}</Text>
                                            <Grid item container lg={12} md={12} columnGap={1.5}>
                                                {
                                                    state?.documents.map((item, index) => (
                                                        <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                            <Text onClick={() => openInNewTab(item.document_path, item.document_url)} sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important`, cursor: "pointer !important" }}>
                                                                {item.document_name.length > 16 ?
                                                                    <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                    </BlackToolTip>
                                                                    :
                                                                    <>
                                                                        {item.document_name}
                                                                    </>}
                                                            </Text>
                                                            <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { deleteChip(index, item.id) }} />
                                                        </Box>
                                                    ))
                                                }
                                            </Grid>
                                        </Box> :
                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                            <Text smallLabel sx={{ font: "14px Quicksand !important" }}>{`Uploaded Documents (Optional)`}</Text>
                                            <Grid item container lg={12} columnGap={1.5}>
                                            </Grid>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>
                        <Grid item container alignItems={'center'} lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Checkbox name='send_email' value={state.send_email} checked={state.send_email ? true : false}
                                onChange={handleChange}
                                sx={{ padding: '0px !important', marginRight: '8px' }} icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />} />
                            <Text mediumLabel>Email a "thank you" note for this payment.</Text>
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} mt={1} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }} spacing={2} mb={2}>
                            <Grid item >
                                <Button blackCancelBtn onClick={setPaymentDrawer}>Cancel</Button>
                            </Grid>
                            <Grid item >
                                <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSaveEdit}>{buttonLoader ? 'Updating' : 'Update'}</LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
