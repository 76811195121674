import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";


class InvoiceCreateAPI {

    cilentHistory(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + 'invoice/listing', data, LocalStorage.getAccessToken())
    }
    invoiceActivity(params) {
        let { invoice_id, referrable_type, request_id } = params
        return BaseApi.getWithParams(APIURL.employee_URL + `invoice/activity-listing?request_id=${request_id}&invoice_id=${invoice_id}&referrable_type=${referrable_type}`, LocalStorage.getAccessToken());
    }
    invoiceApproveAndReject(data) {
        return BaseApi.putWithToken(APIURL.employee_URL + `invoice/approval-status`, data, LocalStorage.getAccessToken())
    }

    recordPayment(data) {   
        return BaseApi.postWithToken(APIURL.org_URL+`invoice/record-payment`,data,LocalStorage.getAccessToken());
    }

    dropdownApi(){
        return BaseApi.getWithParams(APIURL.org_URL+`invoice/record-payment/dropdowns?request_id=${LocalStorage.uid()}`,LocalStorage.getAccessToken());
    }

    voidWriteOffApi(data){
        return BaseApi.putWithToken(APIURL.org_URL+`invoice/change-status`,data,LocalStorage.getAccessToken());
    }



    invoicePdfViewAPi(params) {
        let { invoice_id, request_id } = params
        return BaseApi.getWithParams(APIURL.employee_URL + `invoice/index?request_id=${request_id}&invoice_id=${invoice_id}`, LocalStorage.getAccessToken());
    }

    invoiceDeleteAPi(data) {
        return BaseApi.deleteWithToken(APIURL.employee_URL + `invoice/delete`, data, LocalStorage.getAccessToken());
    }
    invoiceSendEmail(data) {
        let { invoice_id, request_id, slug } = data
        return BaseApi.getWithParams(APIURL.employee_URL + `/invoice/get-template?request_id=${request_id}&invoice_id=${invoice_id}&slug=${slug}`, LocalStorage.getAccessToken());
    }
    invoiceGetTemplateApi(data) {
        let { invoice_id, request_id, slug } = data
        return BaseApi.getWithParams(APIURL.employee_URL + `invoice/get-template?request_id=${request_id}&invoice_id=${invoice_id}&slug=${slug}`, LocalStorage.getAccessToken());
    }
    invoicePostTemplateApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `invoice/send-email`, data, LocalStorage.getAccessToken());
    }

    invoiceCancelWriteOff(data) {
        return BaseApi.putWithToken(APIURL.employee_URL + 'invoice/convert-status', data, LocalStorage.getAccessToken())
    }
    invoiceDownload(data) {
        let { invoice_id, request_id } = data
        return BaseApi.getDownloadPdf(APIURL.employee_URL + `invoice/download?request_id=${request_id}&invoice_id=${invoice_id}`, LocalStorage.getAccessToken());
    }
    invoiceSyncZohoApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `zoho-sync/invoice/zoho_books`, data, LocalStorage.getAccessToken());
    }
    invoiceSyncQuickBookApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `zoho-sync/invoice/quick_books`, data, LocalStorage.getAccessToken());
    }
    invoiceImportQuickBookApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `invoice/quickbooks-sync`, data, LocalStorage.getAccessToken());
    }
    invoiceimportZohoBookApi(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `invoice/zohobooks-sync`, data, LocalStorage.getAccessToken());
    }
}
// eslint-disable-next-line
export default new InvoiceCreateAPI();
