import React, { useEffect, useState } from "react";
import logoName from '../../assets/svg/logoName.svg';
import Text from "../../components/customText/Text";
import { Box, Grid } from '@mui/material';
import OnboardStyles from './OnboardStyles';
import onBoardLinkApi from "../../apis/onBoardLink/onBoardLinkApi";
import { addErrorMsg, addLoader, removeLoader } from "../../utils/utils";
import LocalStorage from "../../utils/LocalStorage";
// import { domain } from "../../config/Domain";
import submitted from '../../assets/employee/offboarding/invite-submitted.svg';
import CommonApi from "../../apis/CommonApi";
import OnboardEmployeeReUploadDocs from "./OnboardEmployeeReUploadDocs";

function OnboardEmployeeReuploads() {

    // const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    LocalStorage.setPathId()
    const id = queryParams.get('onboarding_id');
    const [state, setState] = useState({
        status: '',
        rejected_on: '',
        final: false,
        link_valid_till: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        gender: '',
        email_id: '',
        mobile_number: '',
        offer_letter_url: '',
        offer_letter_id: '',
        employment_type_id: '',
        employee_category_id: '',
        requested: "",
        upload_documents: {
            copy_of_void_cheque: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            counter_signed_offer_letter: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            work_authorization: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            all_previous_i20s: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            signed_ssn: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            education_documents: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            passport: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            i94: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            },
            drivers_license: {
                status: true,
                default: true,
                approval_status: '',  // Submitted or Approved or Rejected
                data: []
            }
        },
        i9andw4: {
            status: '',
            i9_sample: '',
            i9_download: '',
            i9_document: {
                id: '',
                document_url: '',
                document_name: ''
            },
            w4_sample: '',
            w4_download: '',
            w4_document: {
                id: '',
                document_url: '',
                document_name: ''
            }
        },
        emergency_contacts: [
            {
                relationship_id: '',
                relation_name: null,
                name: '',
                mobile_number: '',
                address_1: '',
                address_2: '',
                city: '',
                zip_code: '',
                state_id: '',
                state_name: null,
                country_id: '',
                country_name: null,
                email_id: ''
            },
            {
                relationship_id: '',
                relation_name: null,
                name: '',
                mobile_number: '',
                address_1: '',
                address_2: '',
                city: '',
                zip_code: '',
                state_id: '',
                state_name: null,
                country_id: '',
                country_name: null,
                email_id: ''
            },
        ]
    })
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        offer_letter_url: '',
        organization_name: '',
        logo_url: '',
        upload_documents: [],
    });

    const [active, setActive] = useState(1);
    const [preView, setPreview] = useState([]);

    const classes = OnboardStyles();


    useEffect(() => {
        getInviteViaLink(id);
        // eslint-disable-next-line
    }, []);

    const getInviteViaLink = (id) => {
        addLoader(true);
        onBoardLinkApi.uploadDocsIndex(id).then((res) => {
            if (res.data.statusCode === 1003) {
                handlLoadPDf(res.data.data.offer_letter_path);
                const responseData = res.data.data;
                const updatedData = { ...responseData };
                if (Object.keys(updatedData.re_upload_documents).length > 0) {
                    setState(updatedData);
                    // setData({ ...data, "first_name": res.data.data.first_name, "last_name": res.data.data.last_name, "upload_documents": res.data.data.re_upload_documents, organization_name: res.data.data.organization_name, logo_url: res.data.data.logo_url });
                
                    
                    const reuploadKeysRe = Object.keys(responseData.re_upload_documents);
                    const reuploadReDocuments = {};

                    reuploadKeysRe.forEach((key) => {
                        const document = updatedData.re_upload_documents?.[key];
                    
                        reuploadReDocuments[key] = {
                            ...document,
                            data: document.data?.some(item => item.approval_status === "Rejected") ? [] : document.data || [],
                        };
                    });
                    
                    setData({
                        ...data,
                        first_name: res.data.data.first_name,
                        last_name: res.data.data.last_name,
                        organization_name: res.data.data.organization_name,
                        logo_url: res.data.data.logo_url,
                        upload_documents: {
                            ...reuploadReDocuments,
                        },
                    });

                    setState((prevState) => ({
                        ...prevState,
                        ...updatedData,
                        re_upload_documents: {
                            ...prevState.re_upload_documents,
                            ...reuploadReDocuments
                        },
                    }));
                    removeLoader();
                } else {
                    // Filter documents for re-upload
                    const reuploadKeys = Object.keys(responseData.upload_documents).filter(
                        (key) =>
                            responseData.upload_documents[key].checked &&
                            Array.isArray(responseData.upload_documents[key].data) &&
                            responseData.upload_documents[key].data.some(
                                (obj) => obj.reason_for_rejection !== ""
                            )
                    );

                    // Update re_upload_documents in state
                    const reuploadDocuments = {};
                    reuploadKeys.forEach((key) => {
                        if (!updatedData.re_upload_documents?.[key]) {
                            reuploadDocuments[key] = {
                                ...responseData.upload_documents[key],
                                data: [],
                            };
                        }
                    });

                    // Filter documents for re-upload
                    const reuploadi9w4Keys = Object.keys(responseData.i9andw4).filter((key) => {
                        const doc = responseData.i9andw4[key];
                        return doc.approval_status && doc.approval_status == "Rejected";
                    });

                    // Update re_upload_documents in state
                    const reuploadi9w4Documents = {};
                    reuploadi9w4Keys.forEach((key) => {
                        if (!updatedData.re_upload_documents?.[key]) {
                            reuploadi9w4Documents[key] = {
                                ...responseData.i9andw4[key],
                                name: key == "i9_document" ? 'I9' : "W4",
                                data: [], // Add additional handling for data if necessary
                            };
                        }
                    });
                    setData({
                        ...data,
                        "first_name": res.data.data.first_name, "last_name": res.data.data.last_name, organization_name: res.data.data.organization_name, logo_url: res.data.data.logo_url,
                        upload_documents: {
                            ...reuploadDocuments,
                            ...reuploadi9w4Documents, // Additional i9 and w4 documents for re-upload
                        },
                    });

                    setState((prevState) => ({
                        ...prevState,
                        ...updatedData,
                        re_upload_documents: {
                            ...prevState.re_upload_documents,
                            ...reuploadDocuments,
                            ...reuploadi9w4Documents,
                        },
                    }));
                    removeLoader();
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handlLoadPDf = (offer_letter_url) => {
        let postData = {
            request_id: LocalStorage.uid(),
            tenantId: LocalStorage.getPathId(),
            document_key: offer_letter_url
        }
        addLoader(true);
        CommonApi.documentViewS3WithoutToken('offer_letter', postData).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                data.offer_letter_url = res.data.data.document_url
                fetch(res.data.data.document_url)
                    .then(response => response.blob())
                    .then(blob => {
                        const fileReader = new FileReader();
                        fileReader.onload = (e) => {
                        };
                        fileReader.readAsDataURL(blob);
                    })
                    .catch(error => console.error(error));
            }
        })
    }

    return (
        <Grid container>
            {state.re_request == 'requested' ?
                <OnboardEmployeeReUploadDocs data={data} state={state} setState={setState} id={id} active={active} setActive={setActive} preView={preView} setPreview={setPreview} getInviteViaLink={getInviteViaLink} />
                :
                state.re_request == 'uploaded' ?
                    <Grid container>
                        <Grid item lg={2} md={2} sm={12} xs={2}>
                            <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center' height='80vh' width='100%'>
                            <Box display='flex' flexDirection='column' pt={6}>
                                <img src={submitted} alt="submitted" />
                                <Text largeLabel700 pt={2}>Submitted Successfully</Text>
                                <Text largeLabel pt={2}>Your documents has been submitted successfully,<br />Your employer will get back to you in short.</Text>
                            </Box>
                        </Grid>
                    </Grid>
                    : ""
            }
        </Grid>
    );

}

export default OnboardEmployeeReuploads;