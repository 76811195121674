import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Text from '../../components/customText/Text';
import { ReactComponent as User } from '../../assets/svg/fi_user.svg';
import { ReactComponent as Configuration } from '../../assets/svg/Configuration.svg';
import { ReactComponent as PasswordIcon } from '../../assets/svg/changePassword.svg';
import { ReactComponent as Support } from '../../assets/svg/Support.svg';
import { ReactComponent as Logout } from '../../assets/svg/Logout.svg';
import { ReactComponent as Release } from '../../assets/svg/release-note.svg';
import { ReactComponent as Plane } from '../../assets/svg/plane.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/fi_chevron-right.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import AccountMenuStyles from './AccountMenuStyles';
import styled from '@emotion/styled';
import { Badge, Dialog, DialogContent, Grid, Slide, Stack } from '@mui/material';
import LocalStorage from '../../utils/LocalStorage';
import CommonApi from '../../apis/CommonApi';
import { addErrorMsg, addSuccessMsg, BlackToolTip } from '../../utils/utils';
import Button from '../../components/customButton/Button';
import offBoardSave from "../../assets/svg/offBoardSave.svg"
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import TourGuideConfigApi from '../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import { filterStorage } from '../../utils/FilterStorage';
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    border: '2px solid white',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
  },
}));


export default function AccountMenu(props) {

  const classes = AccountMenuStyles();
  let location = useLocation();
  const person = props.person ? props.person : () => { };
  const UserName = props.UserName ? props.UserName : () => { };
  const MailId = props.MailId ? props.MailId : () => { };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
      borderRadius: "16px",
      width: "500px"
    }
  }));// eslint-disable-next-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });// eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorTourGuideEl, setAnchorTourGuideEl] = React.useState(null);
  const [openPopup, setOpenpopup] = React.useState(false)
  const [url, setUrl] = React.useState('')
  React.useEffect(() => {
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      allowClose: false,
      stagePadding: 0,
      stageRadius: 0,
      animate: false,
      allowKeyboardControl: false,
      disableActiveInteraction: true,
      steps: [
        {
          element: '#tourguide', popover: {
            title: 'Guiding Screens', description: 'If you wish to walkthrough the application with the guiding screens, you can access them at any point of your journey in your profile section!', side: "left",
            align: 'start'
          }
        },
      ],

      onPopoverRender: (popover, opts) => {
        const currentStep = opts.state.activeIndex;
        const updateTourGuideStatus = async () => {
          TourGuideConfigApi.updateApi({
            "request_id": LocalStorage.uid(),
            "module": "DONE_TOUR",
            "status": true
          })
        }
        const title = document.createElement('div');
        title.style.display = 'flex';
        title.style.alignItems = 'center';
        title.style.marginBottom = '8px';
        const textContainer = document.createElement('div');
        textContainer.style.display = 'flex';
        textContainer.style.justifyContent = 'space-between';
        textContainer.style.width = '100%';
        const titleText = document.createElement('p');
        titleText.textContent = opts.config.steps[currentStep].popover.title;
        titleText.style.fontFamily = 'Quicksand';
        titleText.style.fontSize = '15px';
        titleText.style.fontWeight = '700';

        textContainer.appendChild(titleText);

        title.appendChild(textContainer);
        const description = document.createElement('div');
        description.innerText = opts.config.steps[currentStep].popover.description;
        description.style.marginBottom = '24px';
        description.style.fontSize = '14px';
        description.style.fontWeight = '400';

        description.style.color = '#404040';
        description.style.fontFamily = 'Quicksand';
        const footer = document.createElement('div');
        footer.style.display = 'flex';
        footer.style.justifyContent = 'space-around';
        footer.style.width = '100%';

        footer.style.boxSizing = 'border-box';
        const applyInviteLinkBtnStyles = (element, text) => {
          element.style.cursor = 'pointer';
          element.style.textAlign = 'center';
          element.style.fontSize = '12px';
          element.style.fontFamily = 'Quicksand';
          element.style.fontWeight = '600';
          element.style.width = '100%';
          element.style.height = '32px';
          element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
          element.style.borderRadius = '4px';
          element.style.color = (text == 'skip' || text == "gotodashboard") ? '#0C75EB' : '#ffffff';
          element.style.transition = 'all .3s ease';
          element.style.outline = 'none';
          element.style.backgroundColor = (text == 'skip' || text == "gotodashboard") ? '#ffffff' : '#0C75EB';
        };

        const doneButton = document.createElement('button');
        doneButton.innerText = 'Done';
        applyInviteLinkBtnStyles(doneButton, 'Done');
        doneButton.onclick = () => {
          updateTourGuideStatus();

          handleClose()
          driverObj.destroy();
        };
        footer.appendChild(doneButton);
        popover.wrapper.innerHTML = '';
        const arrow = popover.arrow;
        if (arrow) {
          popover.wrapper.appendChild(arrow);
        }
        popover.wrapper.appendChild(title);
        popover.wrapper.appendChild(description);
        popover.wrapper.appendChild(footer);

      }
    });



    const getTourGuideDetails = async () => {
      let response = await TourGuideConfigApi.getApi();
      if (response.data.statusCode == 1003) {
        if (anchorEl && LocalStorage.getUserData() && LocalStorage.getUserData().admin_login == true && response.data.data?.DONE_TOUR == false) {
          setTimeout(() => {
            driverObj.drive();
          }, 200);
        }
      }
    }
    getTourGuideDetails();

    return () => {
      driverObj.destroy();
    };

  }, [anchorEl])
  const open = Boolean(anchorEl);
  const openTourGuideOptions = Boolean(anchorTourGuideEl);
  const navigate = useNavigate();
  let formsIncude = ['/employees/onboard', '/employees/add', '/timesheet/add-timesheet', '/placements/addclientAndendclient']
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTourGuideAnchorEle = (event) => {
    event.stopPropagation();
    setAnchorTourGuideEl(event.currentTarget);
  }

  const handleCloseTourGuide = () => {
    setAnchorTourGuideEl(null); // Close only the tour guide menu
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    //userData passing thorough the api
    const userData = {
      request_id: LocalStorage.uid(),
      login_id: LocalStorage.getUserData() && LocalStorage.getUserData().login_id
    }
    CommonApi.Logout(userData, LocalStorage.getAccessToken()).then((response) => {
      if (response.data.statusCode == 1003) {
        addSuccessMsg("Sucessfully Logged Out") //for success msg Alert
        localStorage.clear();
        sessionStorage.clear();
        setAnchorEl(null);
        window.location.reload();
      }
      else {
        addErrorMsg(response.data.message); //for Error msg Alert
      }
    })
  };

  const handleViewProfile = () => {
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/myprofile')
    } else {
      navigate('/myprofile')
    }
    filterStorage.clearAllFilters();
  }
  const handleClickNavigateToConfiguration = () => {
    LocalStorage.removeRedirectedModule();
    LocalStorage.removeFromPage();
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/configuration')
      setAnchorEl(null);
    } else {
      navigate('/configuration');
      setAnchorEl(null);
    }
    filterStorage.clearAllFilters();
  }

  const handleClickNavigateToPassword = () => {
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/change-password')
      setAnchorEl(null);
    } else {
      navigate('/change-password');
      setAnchorEl(null);
    }
    filterStorage.clearAllFilters();
  }

  const handleTourGuideClick = (menuItem, pathRoute) => {
    setAnchorEl(null);
    setAnchorTourGuideEl(null);
    if (menuItem == 'dashboard' && LocalStorage.getUserData() && LocalStorage.getUserData().org_config_set == false) {
      addErrorMsg('Please fill out the organization details before going to the dashboard!');
      return;
    }
    LocalStorage.setStartTour({ [menuItem]: true });
    if (location.pathname == pathRoute) {
      window.location.reload();
    } else {
      navigate(pathRoute);
    }
    filterStorage.clearAllFilters();
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }} id="avatar-click-box">

        <Box className={`${open ? classes.avatarBoxActive : classes.avatarBox}`} onClick={handleClick} id="avatar-click">
          <Avatar sx={{
            width: 40, height: 40,
            "@media (min-width: 100px) and (max-width: 599px)": {
              height: '28px',
              width: '25px',
            },
          }} src={person} />
        </Box>

      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className={classes.menu}
          sx={{
            '& .MuiPaper-root': {
              height: LocalStorage.getUserData() && LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ? '363px' : '160px',
              overflow: "auto",
              '&::-webkit-scrollbar': {
                display: "none", // For Chrome, Safari, and Edge
              },

            }
          }}
          PaperProps={{
            sx: {
              mt: "6px",
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem className={classes.menuItem} onClick={handleClose} sx={{

          }}>

            <Box className={classes.profileInfo}>

              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
              >
                <Avatar sx={{ width: 40, height: 40 }} src={person} />

              </StyledBadge>
              <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Text largeBlack>{UserName.length > 20 ?
                  <BlackToolTip title={UserName} placement="top" arrow>
                    {(UserName).slice(0, 15) + (UserName.length > 15 ? "..." : "")}
                  </BlackToolTip>
                  : (UserName)}</Text>
                <Text mediumLabel>
                  {
                    MailId.length > 20 ?
                      <BlackToolTip title={MailId} placement="top" arrow>
                        {(MailId).slice(0, 20) + (MailId.length > 20 ? "..." : "")}
                      </BlackToolTip>
                      : (MailId)
                  }
                </Text>
              </Box>
            </Box>

          </MenuItem>

          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

          {
            LocalStorage.getUserData() && LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
              <MenuItem onClick={handleViewProfile} className={classes.menuItemIn}>

                <Box className={classes.ListItem}>
                  <User />
                  <Text mediumBlack >View profile</Text>
                </Box>
              </MenuItem>
              : ''}
          {
            LocalStorage.getUserData() && LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
              <div>
                <MenuItem onClick={handleClickNavigateToConfiguration} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <Configuration />

                    <Text mediumBlack >Configuration</Text>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleClickNavigateToPassword} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <PasswordIcon />

                    <Text mediumBlack >Change Password</Text>
                  </Box>
                </MenuItem>
                <a href='https://www.japfu.ai/contact' rel="noreferrer" target='_blank'>
                  <MenuItem onClick={handleClose} className={classes.menuItemIn}>
                    <Box className={classes.ListItem}>
                      <Support />

                      <Text mediumBlack >Support</Text>
                    </Box>
                  </MenuItem>
                </a>
                {/* <a href='https://www.japfu.ai/product-updates' rel="noreferrer" target='_blank'>
                <MenuItem onClick={handleClose} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <Release />

                    <Text mediumBlack >Release Notes</Text>
                  </Box>
                </MenuItem>
              </a> */}
              </div>
              :
              LocalStorage.getUserData() && LocalStorage.getUserData().org_config_set == false ? '' :
                <MenuItem onClick={handleClickNavigateToPassword} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <PasswordIcon />

                    <Text mediumBlack >Change Password</Text>
                  </Box>
                </MenuItem>
          }
          {LocalStorage.getUserData() && LocalStorage.getUserData().admin_login &&
            <MenuItem onClick={handleTourGuideAnchorEle} className={classes.menuItemIn} id="tourguide"
              aria-controls={openTourGuideOptions ? 'tourguide-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openTourGuideOptions ? 'true' : undefined}>
              <Box className={classes.TourItem}>
                <Box className={classes.ListItem}>
                  <Plane />
                  <Text mediumBlack >Tour Guide</Text>
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginLeft={'auto'}>
                  <ArrowRight />
                </Box></Box>
            </MenuItem>}
          {LocalStorage.getUserData() && LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
            <a href='https://www.japfu.ai/product-updates' rel="noreferrer" target='_blank'>
              <MenuItem onClick={handleClose} className={classes.menuItemIn}>
                <Box className={classes.ListItem}>
                  <Release />
                  <Text mediumBlack >Release Notes</Text>
                </Box>
              </MenuItem>
            </a> : null}
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />
          <MenuItem onClick={handleLogout} className={classes.menuItemIn}>
            <Box className={classes.ListItem} >
              <Logout />
              <Text mediumBlack sx={{ color: '#F4535C !important' }}>Log out</Text>
            </Box>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorTourGuideEl}
          className={classes.menuTour}
          MenuListProps={{
            'aria-labelledby': 'tourguide',
          }}
          sx={{
            
            '& .MuiPaper-root': {
              height: '363px' ,
              overflow: "auto",
              '&::-webkit-scrollbar': {
                display: "none", // For Chrome, Safari, and Edge
              },

            },}}
          id="tourguide-menu"
          open={openTourGuideOptions}
          onClose={handleCloseTourGuide}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left', // Position the submenu to the right of the parent
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        ><MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('orgconfig', '/configuration')}>
            <Box className={classes.ListItem}>
              <Text tourText >Org Configuration</Text>
            </Box>
          </MenuItem> <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('dashboard', '/dashboard')}>
            <Box className={classes.ListItem}>
              <Text tourText >Dashboard</Text>
            </Box></MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />



          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('employees', '/employees')}>
            <Box className={classes.ListItem}>
              <Text tourText >Employee</Text>
            </Box>
          </MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />


          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('placements', '/placements')}>
            <Box className={classes.ListItem}>
              <Text tourText >Placement</Text>
            </Box>
          </MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />




          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('timesheets', '/timesheet')}>
            <Box className={classes.ListItem}>
              <Text tourText >Timesheet</Text>
            </Box>
          </MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />



          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('companies', '/clients')}>
            <Box className={classes.ListItem}>
              <Text tourText >Clients and End Clients</Text>
            </Box>
          </MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('payroll', '/payrollDashboard')}>
            <Box className={classes.ListItem}>
              <Text tourText >Payroll</Text>
            </Box>
          </MenuItem>
          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('invoice', '/invoiceDashboard')}>
            <Box className={classes.ListItem}>
              <Text tourText >Invoice</Text>
            </Box>
          </MenuItem>

          <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

          <MenuItem className={classes.tourMenuItem} onClick={() => handleTourGuideClick('appintegrations', '/app-integrations')}>
            <Box className={classes.ListItem}>
              <Text tourText >Integration</Text>
            </Box>
          </MenuItem>

        </Menu>
      </Box>
      <BootstrapDialog
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="customized-dialog-title"
        open={openPopup}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent sx={{ margin: "50px", }}>
          <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
            <img src={offBoardSave} alt='noactive' />
          </Grid>
          <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
          </Grid>
          <Grid container justifyContent={'center'} alignContent={'center'}>
            <Text offBoardBodyFont>Your progress will not be saved</Text>
          </Grid>
          <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
            <Stack direction={"row"} spacing={3}>
              <Button smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                Cancel
              </Button>
              <Button smallBlue redBackground onClick={() => { navigate(url); setOpenpopup(false); filterStorage.clearAllFilters(); }} >
                Yes
              </Button>
            </Stack>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}