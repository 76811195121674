import { makeStyles } from "@mui/styles";
import { blue, btnTxtBlue } from "../../theme";
import { useTheme } from "@mui/material";


const OnboardStyles = makeStyles(() => ({

    BrowseMonster: {
        width: "100% !important",
        height: "auto !important",
        background: "#0C75EB !important"
    },

    BrowseMonsterImageStyle: {
        position: "absolute !important",
        top: "20px !important",
        left: "20px !important",
        width: "54px !important",
        height: "54px !important",
        borderRadius: '50%'
    }, 
    PDFRendergrid: {
        width: "100% !important",
        height: "100vh !important"
    },
    MainBox: {
        margin: "20px !important",
        [useTheme().breakpoints.down('lg')]:{
            width: "100% !important",
        }
    },
    PDFRenderBox: {
        position: "relative",
        "@media (max-width:455px)": {
            width: "100% !important",
        },
        cursor: "pointer",
        height: "640px",
        width: "580px",
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.3s',
        [useTheme().breakpoints.down('lg')]:{
            width: "100% !important",
        }
    },
    PDFBoxDownloadAndViewBox: {
        position: 'absolute !important',
        top: 0,
        left: 0,
        width: '100%',  // Adjust this value
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: '#fff',
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    ButtonBox: {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        marginTop: "10px",
        marginRight: "20px"
    },
    RejectButton: {
        color: "#E51A1A !important",
        width: "126px !important",
        height: "44px !important",
        borderRadius: "8px !important",
        border: "1px solid #E51A1A !important",
        textTransform: "none !important",
        fontSize: "15px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important",
        [useTheme().breakpoints.down('sm')]: {
            width:"fit-content !important",
            fontSize: "12px !important",
        }
    },
    AcceptButton: {
        color: "#FFFFFF !important",
        background: "#0C75EB !important",
        width: "126px !important",
        height: "44px !important",
        borderRadius: "8px !important",
        border: "none !important",
        textTransform: "none !important",
        fontSize: "15px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important",
        [useTheme().breakpoints.down('sm')]: {
            width:"fit-content !important",
            fontSize: "12px !important",
        }
    },  
    DialogContent: {
        width: '500px',
        maxHeight: '450px',
        '&.MuiDialogContent-root': { padding: '0px' }
    },
    DialogContentButtonBox: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px !important",
        gap: "10px"
    },
    CloseButton: {
        font: "15px Quicksand !important",
        width: "126px !important",
        height: "44px !important",
        textTransform: "none !important",
        color: "#0C75EB !important",
        borderRadius: "8px !important",
        border: "1px solid #0C75EB !important",
        [useTheme().breakpoints.down('sm')]: {
            width:"fit-content !important",
            fontSize: "12px !important",
        }
    },
    OkButton: {
        font: "15px Quicksand !important",
        background: `#F85036 !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "126px !important",
        height: "44px !important",
        variant: "outlined",
        [useTheme().breakpoints.down('sm')]: {
            width:"fit-content !important",
            fontSize: "12px !important",
        }
    },
    OfferRejected: {
        backgroundSize: "100% 100% !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        width: '170px !important',
        height: '139px !important',
        margin: 'auto !important'
    },
    OfferRejectedText: {
        fontSize: "18px !important",
        fontFamily: "Quicksand !important",
        color: "#54595E !important",
        fontWeight: "600 !important",
        paddingTop: '25px !important'
    },
    OfferRejectedBox: {
        display: "flex  !important",
        justifyContent: "center  !important",
        marginTop: "40px  !important",
        gap: "10px !important"
    },
    ExitButton: {
        font: "15px Quicksand !important",
        width: "126px !important",
        height: "44px !important",
        textTransform: "none !important",
        color: "#0C75EB !important",
        borderRadius: "8px !important",
        border: "1px solid #0C75EB !important",
        [useTheme().breakpoints.down('sm')]: {
            width:"fit-content !important",
            fontSize: "12px !important",
        }
    },  
    BackButton: {
        color: "#171717 !important",
        width: "126px !important",
        height: "44px !important",
        borderRadius: "8px !important",
        border: "1px solid #171717 !important",
        textTransform: "none !important",
        fontSize: "15px",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important",
    },
    ContinueButton: {
        color: "#FFFFFF !important",
        background: '#0C75EB !important',
        width: "126px !important",
        height: "44px !important",
        borderRadius: "8px !important",
        border: "none !important",
        textTransform: "none !important",
        fontSize: "15px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important",
    },
    DownloadFormText: {
        color: '#404040 !important',
        fontSize: "14px !important",
        fontWeight: "400 !important",
        fontFamily: "Quicksand !important",
    },
    DownloadFormButton: {
        color: "#0C75EB !important",
        background: '#EDF5FF !important',
        width: "350px !important",
        height: "56px !important",
        borderRadius: "8px !important",
        border: "1px solid #0C75EB !important",
        textTransform: "none !important",
        fontSize: "15px", fontFamily: "Quicksand", fontWeight: "400",
        '&:hover': {
            color: "#EDF5FF !important",
            background: "#0C75EB !important",
        },
    },
    I9DocUploadBoxMain: {
        display: 'flex !important',
        position: 'relative !important',
        height: '54px !important',
        width: '100% !important',
        borderRadius: '8px !important',
        border: '1px solid #cdd0d4 !important',
        borderWidth: '0.2ex !important',
        background: 'white !important',
        padding: '10px 12px !important',
        justifyContent: 'end !important'
    },
    I9DocBox: {
        height: '100% !important',
        width: '100% !important',
        overflow: 'hidden !important'
    },
    I9DocBox2: {
        padding: '5px !important',
        font: '14px Quicksand !important',
        fontWeight: `${400} !important`,
        color: '#262626 !important',
        pointerEvents: 'none !important',
        overflow: 'hidden !important'
    },
    I9Text: {
        color: "#737373 !important",
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important"
    },
    ViewI9Text: {
        color: "#737373 !important",
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "400 !important",
    },
    ClickHere: {
        color: "#0C75EB !important",
        textDecorationLine: "underline !important",
        cursor: "pointer !important"
    },
    ImgMargin: {
        marginRight: "10px !important"
    },
    Card: {
        height: "75vh !important",
        overflowY: "auto !important",
        width: '824px !important',
        padding: '10px !important',
        boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important",
        borderRadius: '8px !important'
    },
    blueBg: {
        background: `${blue} !important`,
        borderRadius: '8px',
        padding: '10px 20px',
        marginBottom: '10px !important',
        cursor: 'pointer'
    },
    greyBg: {
        background: `#FAFAFA !important`,
        borderRadius: '8px',
        padding: '16px !important',
        marginBottom: '10px !important',
        cursor: 'pointer'
    },
    clickhere: {
        textDecoration: `1px underline ${blue} !important`,
        cursor: 'pointer',
    },
    dropZone: {
        background: '#F0F7FF',
        border: `2px dashed #0C75EB !important`,
        borderRadius: '12px',
    },
    dropzoneContainer: {
        minHeight: '45vh',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    //    I9 & W4

    cloudContainer: {
        background: '#EDF5FF', borderRadius: '8px', height: '56px', textAlign: 'center', alignItems: 'center', justifyContent: 'center', border: `1px solid ${btnTxtBlue.shade2}`, cursor: 'pointer'
    },
    information: {
        minHeight: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'start', alignItems: 'center !important', paddingLeft: '20px'
    },
    cursor: {
        cursor: 'pointer'
    },
    chip: {
        background: '#EEEEEE', borderRadius: '55px', minWidth: '120px !important', maxWidth: 'auto !important', padding: '8px', margin: '0px 4px 4px 4px'
    },
    chipsScroll: {
        minHeight: '40px !important', maxHeight: '150px !important', overflowY: 'scroll', minWidth: '180px !important', display: 'flex', flexDirection: 'row'
    },
    uploadDocsInput: {
        border: "1px solid #C7CCD3", borderRadius: "8px", paddingLeft: '10px', minHeight: '54px !important', maxHeight: '160px !important', overflowY: 'scroll', paddingTop: '3px', display: 'flex', flexDirection: 'column', gap: 1, textAlign: 'start'
    },
    greyText: {
        width: '100% !important', display: 'flex', justifyContent: 'space-between !important', alignItems: "center", border: "1px solid #C7CCD3 !important", borderRadius: "8px", height: '54px !important', paddingLeft: '15px'
    },
    chipLabel: {
        color: `${blue} !important`,
        font: "12px Quicksand !important",
        letterSpacing: '0px',
        fontWeight: `${400} !important`,
        [useTheme().breakpoints.down('xl')]: {
            fontSize: '10px !important',
            fontWeight: `${400} !important`,
        }
    },
    logoStyles: {
        width: '54px !important',
        height: '54px !important',
        borderRadius: '50% !important'        
    },
    onBoardLinkImageStyle:{
        [useTheme().breakpoints.down('sm')]: {
            width:"70%"
        }
    }
}))

export default OnboardStyles;
