import BaseApi from '../../BaseApi'
import APIURL from '../../../config/development'
import LocalStorage from '../../../utils/LocalStorage';

class TemplateApi {

    getTemplates(params, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `templates/dropdown?request_id=${params}`, token);
    }

    getAllTemplates() {
        return BaseApi.getWithParams(APIURL.org_URL + `templates/listing?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    getTemplateIndex(params, slug, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `templates/index?request_id=${params}&module_slug=${slug}`, token);
    }

    updateTemplateIndex(params,token) {
        return BaseApi.putWithToken(APIURL.org_URL + `templates/update`, params, token);
    }

    getTemplateSlug(params,slug,token){
        return BaseApi.getWithParams(APIURL.org_URL + `templates/list-param?request_id=${params}&module_slug=${slug}`, token);
    }

    getAllColors() {
        return BaseApi.getWithParams(APIURL.org_URL + `templates/color-codes?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    updateColor(data) {
        return BaseApi.putWithToken(APIURL.org_URL + `templates/invoice/update-colorcode`,data, LocalStorage.getAccessToken());
    }

    getActiveColor() {
        return BaseApi.getWithParams(APIURL.org_URL + `templates/invoice/get-colorcode?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

}
// eslint-disable-next-line
export default new TemplateApi();