import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";



class RemindersAPI {


    // storeReminderConfig(data,) {
    //     return BaseApi.postWithToken(APIURL.API_URL + `reminder-config/store`, data, LocalStorage.getAccessToken())
    // }https://gateway-dev.japfu.app/api/v1/reminder-config/invoice/listing?request_id=

    invoicesList(params) {
            let {search,limit,page}=params
            return BaseApi.getWithParams(APIURL.org_URL + `reminder-config/invoice/listing?request_id=${LocalStorage.uid()}&search=${search}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }

    emailTemplateDropdown(){
        return BaseApi.getWithParams(APIURL.org_URL + `reminder-config/invoice/template-dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    storeInvoiceReminder(data){
        return BaseApi.postWithToken(APIURL.org_URL + "reminder-config/invoice/store", data, LocalStorage.getAccessToken());
        
    }

    getReminderDetailsView(id){
        return BaseApi.getWithParams(APIURL.org_URL + `reminder-config/invoice/index?request_id=${LocalStorage.uid()}&id=${id}`);
    }

    deleteReminder(id,data){
        return BaseApi.deleteWithToken(APIURL.API_URL+`reminder-config/invoice/destroy/${id}?request_id=${LocalStorage.uid()}`, data, LocalStorage.getAccessToken())
    }

    updateReminder(id,data){
        return BaseApi.putWithToken(APIURL.API_URL+`reminder-config/invoice/update/${id}?request_id=${LocalStorage.uid()}`, data, LocalStorage.getAccessToken())
    } 

    updateReminderToggle(id,data){
        return BaseApi.putWithToken(APIURL.API_URL+`reminder-config/invoice/update-status/${id}?request_id=${LocalStorage.uid()}`, data, LocalStorage.getAccessToken())
    }





    updateReminderConfig(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `reminder-config/update/${id}`, data, LocalStorage.getAccessToken());
    }

    getReminderConfig(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `reminder-config/index?request_id=${LocalStorage.uid()}&referrable_type=${id}`, LocalStorage.getAccessToken());
    }

    

}

export default new RemindersAPI();