import { Box, Checkbox, Chip, Divider, Grid, InputAdornment, Popover, Stack, Select, TextField, MenuItem, Autocomplete, Dialog, DialogContent, Slide, Breadcrumbs } from "@mui/material";
import Text from "../../../components/customText/Text";
import Date from "../../../components/datePicker/Date";
import ClientCard from "./ClientCard";
import EmployeeTaskTable from "./EmployeeTaskTable";
import React, { useEffect, useRef, useState } from "react";
import ClientsApi from "../../../apis/admin/clients/ClientsApi";
import Search from '../../../components/select/Select';
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../../components/input/Input";
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import RadioGroup from "../../../components/customButton/RadioGroup";
import LoaderButton from '../../../components/customButton/LoadingButton'
import Button from "../../../components/customButton/Button";
import { float_validation, isValid, onNumberOnlyChange, onNumberWithTwoDecimalOnlyChange, onNumberWithTwoDecimalOnlyChangeCurrency, validate_emptyField } from "../../../components/Validation";
import CommonApi from "../../../apis/CommonApi";
import { addErrorMsg, addLoader, addSuccessMsg, dateFormat, removeLoader } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";
import { ErrorMessages } from "../../../utils/ErrorMessages";
import moment from "moment";
import InvoiceAPI from "../../../apis/admin/InvoiceAPI";
import { ReactComponent as CancelChip } from '../../../assets/svg/cancel_chip_icon.svg';
import { debounce } from "lodash";
import { inputLabelClasses } from "@mui/material/InputLabel";
import InfoIcon from '../../../assets/svg/Information.svg';
import RichTextEditor from "react-rte";
import uploadIcon from '../../../assets/svg/upload_icon.svg';
import pdfIcon from '../../../assets/svg/pdf_icon.svg';
import pdfDeleteIcon from '../../../assets/svg/delete_icon.svg';
import MainStyles from '../../settings/configurations/MainStyles'
import LoadingButton from "../../../components/customButton/LoadingButton";
import offBoardSave from '../../../assets/svg/ExpenseDeleteIcon.svg'
import { styled } from "@mui/material/styles";


export default function AddInvoice() {
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialog-paper ": {
            borderRadius: "16px",
            width: "500px"
        }
    }));
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });// eslint-disable-next-line
    const navigate = useNavigate();
    const location = useLocation();
    const classesMainStyles = MainStyles();
    const [clientList, setClientList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openPaymentTerms = Boolean(anchorEl);
    const boxRef = useRef(null);
    const paymentTermsId = openPaymentTerms ? 'payment-terms-popover' : undefined;
    const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
    const [buttonLoaderDaysSave, setButtonLoaderDaysSave] = useState(false);
    const [days, setDays] = useState('')
    const [paytermError, setPaytermError] = useState({})
    const [error, setError] = useState({})
    const [employeeDetails, setEmployeeDetails] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [invoiceGetTemplate, setInvoiceGetTemplate] = useState([]);
    const [invoiceId, setInvoiceId] = useState(location?.state?.invoiceId);
    const [emailPreview, setemailPreview] = useState(false);
    const [netpayShow, setNetpayShow] = useState(false);
    const [timesheetShow, setTimesheetShow] = useState(false);
    const fileInputRef = useRef(null);
    const [clientDetailsAPI, setClientDetailsAPI] = useState({
        client_details: {
            id: "",
            name: "",
            reference_id: "",
            status: "",
            logo_url: null,
            net_pay_terms_id: '',
            net_pay_term_days: '',
            discount_type: '',
            discount_value: '',
            profile_percentage: '',
            address: [],
            contacts: []
        },
        unpaid_invoices: [],
        unpaid_invoice_count: ''
    })
    const [attachments, setAttachments] = useState([]);
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        search: '',
        client_id: "",
        from_date: "",
        to_date: "",
        invoice_date: moment().format(dateFormat()),
        net_pay_terms_id: "",
        due_date: "",
        clone_invoice: true,
        bill_timesheet_type: 1,
        item_details: [],
        sub_total_amount: "",
        discount_type: "",
        discount_value: "",
        discount_amount: 0,
        adjustment_amount: '',
        adjustment_type: "",
        adjustment_comment: "",
        total_amount: "",
        email_ids: [],
        terms_and_conditions: "",
        is_timesheets_attached: false,
        is_recurring: false,
        repeat_key: '',
        recurring_cycle_type: "",
        recurring_cycle_number: "",
        recurring_start_date: "",
        recurring_never_expires: false,
        recurring_end_date: "",
        recurring_invoice_date: "",
        is_draft: false,
        deleted_item_dettail_ids: []
    })
    const [fileAttachements, setFileAttachements] = useState([]);
    const [clientConfigError,setClientConfigError] = useState(false)

    const adjustmentList = [
        {
            id: 1,
            value: "Add to Amount"
        },
        {
            id: 2,
            value: "Subtract from Amount"
        }
    ]

    useEffect(() => {
        clientDropdownList()
        paymentTermsDropdown()
        if (location?.state?.invoiceId) {
            getIndexAPI()
        }
        else {
            CommonApi.prefix("invoice").then((res) => {
                if (res.data.statusCode == 1003) {
                    state['reference_id'] = res.data.data
                    setState({ ...state });
                } else {
                    addErrorMsg(res.data.message);
                }
            });
        }

        // eslint-disable-next-line
    }, [])

    const getTemplate = (id) => {
        InvoiceAPI.getTemplate(id).then(async (res) => {
            if (res.data.statusCode === 1003) {
                setInvoiceGetTemplate(res.data.data[0])
                var data = {
                    request_id: LocalStorage.uid(),
                    invoice_id: id,
                };
                const result = await InvoiceAPI.invoiceDownload(data);
                if (result.data instanceof Blob) {
                    const blob = result.data;
                    const file = new File([blob], `invoice_${id}.pdf`, { type: blob.type });
                    const fileObject = {
                        document_path: file.name,
                        file: file,
                    };
                    // Update state to display in UI
                    setAttachments((prev) => [...prev, fileObject]);
                    setFileAttachements((file) => [...file, file])
                }
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const getIndexAPI = () => {
        let data = {
            request_id: LocalStorage.uid(),
            invoice_id: location?.state?.invoiceId
        }
        InvoiceAPI.invoicePdfViewAPi(data).then((response) => {
            if (response.data.statusCode == 1003) {
                var daySplit = response.data.data[0].net_pay_terms?.split('-') || 0
                var today = moment().format(dateFormat())
                if (response.data.data[0].email_ids) {
                    setEmails([...response.data.data[0].email_ids])
                }
                setState({
                    ...response.data.data[0],
                    invoice_date: today,
                    due_date: moment(today, dateFormat()).add(Number(daySplit.length > 1 ? daySplit[1] : 0), 'days').format(dateFormat()),
                    search: '',
                    deleted_item_dettail_ids : []
                })
            }
        })
    }

    const clientDropdownList = () => {
        ClientsApi.dropdown('client', '').then((response) => {
            if (response.data.statusCode == 1003) {
                setClientList(response.data.data);
            }
        });
    };

    const paymentTermsDropdown = () => {
        CommonApi.getPaymentTermsDropdown().then((res) => {
            if (res.data.statusCode == 1003) {
                setPaymentTermsOptions(res.data.data);
                handleClose();
            }
        })
    }

    const handleClick = () => {
        setAnchorEl(boxRef.current);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setDays('')
        paytermError['days'] = ''
        setPaytermError({ ...paytermError });

    };

    const handleDaysPaymentTerms = () => {
        if (days == '') {
            paytermError.days = ErrorMessages.fieldRequired
            setPaytermError({ ...paytermError });
        } else {
            const data = {
                request_id: LocalStorage.uid(),
                days: days,
                is_active: true
            }
            setButtonLoaderDaysSave(true);
            CommonApi.postPaymentTermsDays(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    paymentTermsDropdown();
                    setButtonLoaderDaysSave(false);

                }
                else {
                    addErrorMsg(res.data.message);
                    setButtonLoaderDaysSave(false);
                }
            })
            setDays('')
        }
    }

    const handleDayChange = (e) => {
        setDays(e.target.value)
        handleValidate(e, e.target.value)
    }

    const changeHandler = (e) => {
        if (e.target.name == 'client_id') {
            if (e.target.value != '') {
                state.item_details = []
                getClientDetails(e.target.value)
                // setTimeout(()=>{
                //     setState({
                //         ...state,
                //         [e.target.name]: e.target.value
                //     }, handleValidate(e, e.target.value));
                // },500)
            }
            else {
                setState({
                    ...state,
                    net_pay_terms_id: '',
                    discount_type: '',
                    discount_value: '',
                    email_ids: '',
                    due_date: '',
                    client_id: ''
                });
                setClientDetailsAPI({
                    client_details: {
                        name: ''
                    }
                })
            }

            handleValidate(e, e.target.value)
        }
        else if (e.target.name == "adjustment_amount") {
            let value1 = e.target.value.trim();
            if (value1 == "") {
                state[e.target.name] = "";
                setState({ ...state }, handleValidate(e));
                return;
            }
            const regex = /^(\d{1,10}|\d{1,10}\.\d{0,3})$/;
            const numericValue = parseFloat(value1);
            if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 999999999.999)) {
                state[e.target.name] = value1;
            } else {
                return false;
            }
            setState({ ...state }, handleValidate(e));
        }
        else if (e.target.name == "discount_value") {
            if (state.discount_type == 1) {
                if (e.target.value > 100) {
                    addErrorMsg('Percentage is not allowed more than 100%')
                    return true
                }
                else {
                    setState({
                        ...state,
                        [e.target.name]: e.target.value
                    }, handleValidate(e, e.target.value));
                }
            }
            else {
                let value1 = e.target.value.trim();
                if (value1 == "") {
                    state[e.target.name] = "";
                    setState({ ...state }, handleValidate(e));
                    return;
                }
                const regex = /^(\d{1,10}|\d{1,10}\.\d{0,3})$/;
                const numericValue = parseFloat(value1);
                if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.999)) {
                    state[e.target.name] = value1;
                } else {
                    return false;
                }
                setState({ ...state }, handleValidate(e));
            }
        }
        else if (e.target.name == "discount_type") {
            setState({
                ...state,
                discount_value: 0,
                [e.target.name]: e.target.value,
            }, handleValidate(e, e.target.value));
        }
        else if (e.target.name == "clone_invoice" || e.target.name == "is_recurring" || e.target.name == "recurring_never_expires" || e.target.name == "is_timesheets_attached") {
            if (e.target.name == "is_timesheets_attached" && state.client_id != '') {
                setTimesheetShow(true)
            }
            if (e.target.name == "clone_invoice" && e.target.checked) {
                state.bill_timesheet_type = 1
            }
            if (e.target.name == "clone_invoice" && e.target.checked == false) {
                state.bill_timesheet_type = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.checked
            }, handleValidate(e, e.target.checked));
        }
        else if (e.target.name == 'net_pay_terms_id') {
            if (state.client_id != '' && e.target.value != '') {
                setNetpayShow(true);
            }
            if (e.target.value != '') {
                var days = paymentTermsOptions.find((i) => i.id == e.target.value);
                var daySplit = days.value.split('-')
                state.due_date = moment(state.invoice_date, dateFormat()).add(Number(daySplit.length > 1 ? daySplit[1] : 0), 'days').format(dateFormat());
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e, e.target.value));
        }
        else {
            if (e.target.name == "repeat_key" || e.target.name == "recurring_cycle_type") {
                state.recurring_invoice_date = ''
                state.recurring_start_date = ''
                state.recurring_end_date = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e, e.target.value));
        }

    }

    const getClientDetails = (id) => {
        InvoiceAPI.getClientDetails(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDetailsAPI(res.data.data);
                if (location?.state?.invoiceId) { }
                else {
                    const daysOf = paymentTermsOptions.find((i) => i.id === res.data.data.client_details.net_pay_terms_id);
                    var daySplit = daysOf?.value.split('-') || 0
                    const emailArray = res.data.data.client_details.contacts.map(contact => contact.email_id);
                    setEmails([...emailArray])
                    setState(prevState => ({
                        ...prevState,
                        client_id: id,
                        net_pay_terms_id: res.data.data.client_details.net_pay_terms_id,
                        discount_type: res.data.data.client_details.discount_type,
                        discount_value: res.data.data.client_details.discount_value,
                        terms_and_conditions: res.data.data.client_details.terms_and_conditions || "",
                        email_ids: emailArray,
                        item_details: [],
                        is_timesheets_attached: res.data.data.client_details.is_timesheets_attached,
                        due_date: moment(prevState.invoice_date, dateFormat()).add(Number(daySplit.length > 1 ? daySplit[1] : 0), 'days').format(dateFormat())
                    }));
                    setError(prevError => ({
                        ...prevError,
                        net_pay_terms_id: '',
                    }));
                    setPaytermError(prevPaytermError => ({
                        ...prevPaytermError,
                        net_pay_terms_id: '',
                    }));
                }
                setClientConfigError(false);

            }
            else {
                setState(prevState => ({
                    ...prevState,
                    client_id: id,
                    net_pay_terms_id: '',
                    discount_type: '',
                    discount_value: '',
                    email_ids: [],
                    due_date: '',
                    terms_and_conditions: '',
                    item_details: [],
                    is_timesheets_attached: false,
                }));
                setClientDetailsAPI({
                    client_details: {
                        name: ''
                    }
                });
                setClientConfigError(true);
                addErrorMsg(res.data.message);
            }
        });
    };


    useEffect(() => {
        const fetchData = debounce(() => {
            if (state.from_date && state.to_date && state.client_id) {
                getClientDetails(state.client_id);
                let data = {
                    client_id: state.client_id,
                    from_date: state.from_date,
                    to_date: state.to_date,
                    search: state.search
                };

                InvoiceAPI.getEmployeeTimesheet(data).then((res) => {
                    if (res.data.statusCode === 1003) {
                        setEmployeeDetails(res.data.data);
                    } else {
                        setEmployeeDetails([]);
                        addErrorMsg(res.data.message);
                    }
                });
            }
        }, 500);

        fetchData();

        return () => fetchData.cancel();
        // eslint-disable-next-line
    }, [state.search, state.from_date, state.to_date, state.client_id]);


    const handleValidate = (e, value) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case 'discount_value':
                paytermError.discount_value = float_validation(value)
                break
            case 'net_pay_terms_id':
                paytermError.net_pay_terms_id = validate_emptyField(value)
                error.net_pay_terms_id = validate_emptyField(value)
                break
            case 'days':
                paytermError.days = validate_emptyField(value)
                break
            case 'recurring_end_date':
                error.recurring_end_date = ''
                break
            case 'recurring_start_date':
                error.recurring_start_date = ''
                break
            case 'recurring_invoice_date':
                error.recurring_invoice_date = ''
                break
            case 'from_date':
                error.from_date = ''
                break
            case 'to_date':
                error.to_date = ''
                break
            case 'client_id':
                error.client_id = validate_emptyField(value)
                break
            case 'recurring_cycle_type':
                error.recurring_cycle_type = ''
                break
            case 'recurring_cycle_number':
                error.recurring_cycle_number = ''
                break
            case 'repeat_key':
                error.repeat_key = validate_emptyField(value)
                break
            default:
                break
        }
        setError({ ...error })
        setPaytermError({ ...paytermError });
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (name == 'recurring_start_date') {
            state.recurring_end_date = ''
            state.recurring_invoice_date = ''
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const validateAllErrors = () => {
        let { from_date, to_date, client_id, net_pay_terms_id, recurring_invoice_date, recurring_cycle_type, recurring_end_date, recurring_start_date, repeat_key, recurring_cycle_number } = state;
        let errors = {};
        errors.from_date = validate_emptyField(from_date);
        errors.to_date = validate_emptyField(to_date);
        errors.client_id = validate_emptyField(client_id);
        errors.net_pay_terms_id = validate_emptyField(net_pay_terms_id);
        errors.recurring_invoice_date = state.is_recurring ? validate_emptyField(recurring_invoice_date) : ''
        errors.recurring_cycle_type = (state.is_recurring && state.repeat_key == 9) ? validate_emptyField(recurring_cycle_type) : ''
        errors.recurring_cycle_number = (state.is_recurring && state.repeat_key == 9) ? validate_emptyField(recurring_cycle_number) : ''
        errors.recurring_start_date = state.is_recurring ? validate_emptyField(recurring_start_date) : ''
        errors.recurring_end_date = (state.is_recurring && !state.recurring_never_expires) ? validate_emptyField(recurring_end_date) : ''
        errors.repeat_key = state.is_recurring ? validate_emptyField(repeat_key) : ''
        setError({ ...errors })
        return errors;
    }

    const  formSubmit = (type) => {
        const errors = validateAllErrors()
        addLoader(true)
        if (isValid(errors)) {
            if (clientDetailsAPI.client_details.send_invoice_email) {
                if (state.email_ids.length == 0) {
                    addErrorMsg('At least one email address is required.')
                    removeLoader()
                    return true
                }
            }
            state.is_draft = type == 'draft' ? true : false
            state.request_id = LocalStorage.uid()
            state.total_amount = Number(state.total_amount).toFixed(3)
            state.sub_total_amount = Number(state.sub_total_amount).toFixed(3)
            state.discount_amount = Number(state.discount_amount).toFixed(3)
            if (location?.state?.invoiceId) {
                InvoiceAPI.updateInvoice(state).then((res) => {
                    if (res.data.statusCode == 1003) {
                        removeLoader()
                        addSuccessMsg(res.data.message);
                        if (clientDetailsAPI.client_details.send_invoice_email && type != 'draft') {
                            setemailPreview(true);
                            getTemplate(res.data.data.id)
                            setInvoiceId(res.data.data.id)
                        }
                        else {
                            navigate('/invoice')
                        }
                    }
                    else {
                        removeLoader()
                        addErrorMsg(res.data.message)
                    }
                })
            }
            else {
                InvoiceAPI.storeInvoice(state).then((res) => {
                    if (res.data.statusCode == 1003) {
                        removeLoader()
                        addSuccessMsg(res.data.message);
                        if (clientDetailsAPI.client_details.send_invoice_email && type != 'draft') {
                            setemailPreview(true);
                            getTemplate(res.data.data.id)
                            setInvoiceId(res.data.data.id)
                        }
                        else {
                            navigate('/invoice')
                        }
                    }
                    else {
                        removeLoader()
                        addErrorMsg(res.data.message)
                    }
                })
            }
        }
        else {
            removeLoader()
            addErrorMsg(ErrorMessages.genericMessage)
        }
    }

    useEffect(() => {
        let total = state.item_details.length > 0
            ? state.item_details.reduce((prev, next) => prev + Number(next['amount']), 0)
            : 0;

        const adjustment = Number(state.adjustment_amount) || 0; // Ensure adjustment is a number

        // const finalTotal = state.adjustment_type == 1 ? total + adjustment : state.adjustment_type == 2 ? total - adjustment : total;
        let discount = state.discount_type == 1 ? total - ((total * state.discount_value) / 100) : state.discount_value

        let discountTotal = state.discount_value ? state.discount_type == 1 ? total - ((total * state.discount_value) / 100) : total - state.discount_value : total - 0
        let finalTotal = state.adjustment_type == 1 ? discountTotal + adjustment : state.adjustment_type == 2 ? discountTotal - adjustment : discountTotal;

        setState(prevState => ({
            ...prevState,
            sub_total_amount: total == '' ? 0 : total.toFixed(3),
            discount_amount: state.discount_value == '' ? 0 : state.discount_type == 1 ? total - discount : state.discount_value,
            total_amount: finalTotal == '' ? 0 : finalTotal.toFixed(3)
        }));
    }, [state.item_details, state.adjustment_amount, state.adjustment_type, state.total_amount, state.discount_value, state.discount_amount, state.discount_type]); // Dependency array

    const [emails, setEmails] = useState(state.email_ids);
    const [inputValue, setInputValue] = useState("");

    const isValidEmail = (email) => {
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const email = inputValue.trim();

            if (isValidEmail(email) && !emails.includes(email)) {
                setEmails([...emails, email]);
                let array = [...emails, email]
                setState({
                    ...state,
                    email_ids: array
                })
                setInputValue("");
            }
        }
    };

    const handleEmailRemainder = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const email = inputValue.trim();

            if (isValidEmail(email) && !emails.includes(email)) {
                setInvoiceGetTemplate((prev) => ({
                    ...prev,
                    toEmail: [...(prev.toEmail || []), email], // Append valid email
                }));
                setInputValue("");
            }
        }
    };

    const handleDelete = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
        let array = [...state.email_ids]
        let filter = array.filter((email) => email !== emailToDelete)
        setState({
            ...state,
            email_ids: filter
        })
    };

    const uploadDocs = (e) => {
        const fileInput = e.target; // Store reference to input field
        const selectedFile = fileInput.files[0];
        if (selectedFile.type.split('/').some(r => ['pdf'].includes(r))) {
            const fileSizeMB = (selectedFile.size / (1024 * 1024)).toFixed(2);
            if (fileSizeMB < 25) {
                const fileObject = {
                    document_path: selectedFile.name,
                };
                setAttachments((prev) => [...prev, fileObject]);
                setFileAttachements((file) => [...file, selectedFile])
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PDF format only.");
        }
        fileInput.value = null;
    };

    const handleDeleteChip = (chipToDelete) => {
        // setInvoiceGetTemplate((prev) => prev.filter((chip) => chip !== chipToDelete));
        setInvoiceGetTemplate((prev) => {
            const updatedEmails = prev.toEmail.filter((chip) => chip !== chipToDelete);
            return { ...prev, toEmail: updatedEmails };
        });
    };

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const sendEmail = () => {
        const formData = new FormData();
        formData.append('request_id', LocalStorage.uid());
        formData.append('toEmail', JSON.stringify(invoiceGetTemplate?.toEmail));
        formData.append('subject', invoiceGetTemplate?.subject);
        formData.append('html', invoiceGetTemplate?.html);
        formData.append('invoice_id', invoiceId);
        if (fileAttachements?.length > 0) {
            fileAttachements.forEach(file => formData.append('attachments', file))
        }
        addLoader(true)
        InvoiceAPI.invoicePostTemplateApi(formData).then((res) => {
            if (res.data.statusCode === 1003) {
                setemailPreview(false)
                navigate('/invoice')
                removeLoader()
            } else {
                addErrorMsg(res.data.message)
                removeLoader()
            }
        })
    }

    const handleDeleteFile = (index) => {
        setAttachments(attachments.filter((_, i) => i !== index))
        setFileAttachements(fileAttachements.filter((_, i) => i !== index))
    }

    const updateClientNetPayterms = (name) => {
        if (name == 'timesheet') {
            let data = {
                request_id: LocalStorage.uid(),
                client_id: state.client_id,
                is_timesheets_attached: state.is_timesheets_attached
            }
            InvoiceAPI.updateClientTimesheet(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setTimesheetShow(false)
                }
                else {
                    addErrorMsg(res.data.message)
                }
            })
        }
        else {
            let data = {
                request_id: LocalStorage.uid(),
                client_id: state.client_id,
                net_pay_terms_id: state.net_pay_terms_id
            }
            InvoiceAPI.updateNetPay(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setNetpayShow(false)
                }
                else {
                    addErrorMsg(res.data.message)
                }
            })
        }

    }

    const calculateInvoice = () => {
        if (state.recurring_start_date == '') {
            return moment().add(1, 'days').format(dateFormat())
        }
        if (moment(state.recurring_start_date, dateFormat()).isSameOrBefore(moment(state.invoice_date, dateFormat()))) {
            return moment(state.invoice_date, dateFormat()).add(1, 'days').format(dateFormat())
        }
        let minInvoiceDate = state.invoice_date
        if (state.repeat_key == 9) {
            minInvoiceDate = moment(state.recurring_start_date, dateFormat()).add(state.recurring_cycle_number, state.recurring_cycle_type).format(dateFormat());
        }
        else {
            let count = (state.repeat_key == 1 || state.repeat_key == 3 || state.repeat_key == 8) ? 1 : (state.repeat_key == 2 || state.repeat_key == 4) ? 2 : state.repeat_key == 5 ? 3 : state.repeat_key == 6 ? 6 : 8
            let time = (state.repeat_key == 1 || state.repeat_key == 2) ? 'weeks' : state.repeat_key == 8 ? 'years' : 'months'
            minInvoiceDate = moment(state.recurring_start_date, dateFormat()).add(count, time).format(dateFormat());
        }
        return moment(minInvoiceDate, dateFormat()).format(dateFormat())
    }


    return (
        <>

            <Grid container sx={{
                pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
            }}>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                    <Box display='flex' justifyContent='space-between' pb={1} pl={1}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Text onClick={() => { navigate('/invoice') }} sx={{
                                fontFamily: "Quicksand !important",
                                color: "#849199 !important",
                                fontSize: "14px !important",
                                fontWeight: '700 !important',
                                textDecoration: "none !important",
                                cursor: "pointer !important",
                            }}>Invoices</Text>
                            <Text sx={{
                                fontFamily: "Quicksand !important",
                                color: "#092333 !important",
                                fontSize: "14px !important",
                            }}>
                                Add Invoice
                            </Text>
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid item xl={12} sm={12} md={12} xs={12} pl={1} pb={2}>
                    <Text headerBlack>New Invoice</Text>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1}>
                            <Text headerBlack sx={{ paddingBottom: '10px' }}>Invoice Period</Text>
                            <Date
                                labelText={'From Date'}
                                name='from_date'
                                height='55px'
                                onChange={(value => dateChange(value, 'from_date'))}
                                value={state.from_date}
                                error={error.from_date}
                                disabled={location?.state?.invoiceId}
                            />
                            <Text errorText>{error.from_date}</Text>
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1} pt={4.7}>
                            <Date
                                labelText={'To Date'}
                                name='to_date'
                                height='55px'
                                onChange={(value => dateChange(value, 'to_date'))}
                                value={state.to_date}
                                error={error.to_date}
                                disabled={location?.state?.invoiceId}
                                minDate={state.from_date}
                                maxDate={moment().format(dateFormat())}
                            />
                            <Text errorText>{error.to_date}</Text>
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1} pt={4.5}>
                            <Search
                                name='client_id'
                                options={clientList}
                                label='Client Name'
                                buttonName='New Client'
                                value={state.client_id}
                                onChange={changeHandler}
                                buttonHandler={() => {
                                    navigate('/addClient', { state: { flow: 'invoice' } })
                                }}
                                showButton
                                error={error.client_id}
                                disabled={location?.state?.invoiceId}
                            />
                            <Text errorText>{error.client_id}</Text>
                        </Box>
                    </Grid>
                </Grid>
                {
                    clientDetailsAPI.client_details.name != '' &&
                    <Grid item xl={9} xs={12}>
                        <Box p={1}>
                            <ClientCard state={state} setState={setState} clientDetailsAPI={clientDetailsAPI} employeeDetails={employeeDetails} open={open} setOpen={setOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} getClientDetails={getClientDetails} />
                        </Box>
                    </Grid>
                }

                <Grid item container spacing={2}>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'reference_id',
                                    value: state.reference_id || '',
                                    type: 'text',
                                }}
                                clientInput
                                labelText={'Invoice ID'}
                                disabled={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1}>
                            <Date
                                labelText={'Invoice Date'}
                                name='invoice_date'
                                height='55px'
                                onChange={(value => dateChange(value, 'invoice_date'))}
                                value={state.invoice_date}
                                disabled={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1}>
                            <Box ref={boxRef}>
                                <Search
                                    name='net_pay_terms_id'
                                    options={paymentTermsOptions}
                                    label='Payment Terms'
                                    buttonName='New Payment Terms'
                                    error={error.net_pay_terms_id}
                                    buttonHandler={
                                        handleClick
                                    }
                                    showButton
                                    value={state.net_pay_terms_id}
                                    onChange={changeHandler}
                                    disabled={location?.state?.invoiceId}
                                />
                                <Text errorText>{error.net_pay_terms_id ? error.net_pay_terms_id : paytermError.net_pay_terms_id ? paytermError.net_pay_terms_id : ''}</Text>
                            </Box>
                            <Popover
                                id={paymentTermsId}
                                open={openPaymentTerms}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}

                            >
                                <Box sx={{ width: '300px !important', minHeight: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' }}>
                                    <Grid container flexDirection='column' spacing={2} >
                                        <Grid item xs={12}><Text headerBlack>Add Net PayTerms</Text></Grid>
                                        <Grid item>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Box width='15%'>
                                                    <Text headerText14>Net - </Text>
                                                </Box>
                                                <Box width='85%'>
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'days',
                                                            value: days || '',
                                                            inputProps: { maxLength: 3 }

                                                        }}
                                                        handleChange={handleDayChange}
                                                        onKeyPress={onNumberOnlyChange}
                                                        disabled={false}
                                                        clientInput
                                                        error={paytermError.days}
                                                        labelText='Days'
                                                    />
                                                    {
                                                        paytermError.days ?
                                                            <Text red>{paytermError.days ? paytermError.days : ''}</Text> : ''
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item container justifyContent='end' gap={2}>
                                            <Grid item >
                                                <Button smallBlueOutline sx={{ height: '40px !important' }} onClick={handleClose}>Cancel</Button>
                                            </Grid>
                                            <Grid item >
                                                <LoaderButton loading={buttonLoaderDaysSave} saveVerySmall sx={{ height: '40px !important' }} onClick={handleDaysPaymentTerms}>Save</LoaderButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popover>

                        </Box>
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                        <Box p={1}>
                            <Date
                                labelText={'Due Date'}
                                name='due_date'
                                height='55px'
                                onChange={(value => dateChange(value, 'due_date'))}
                                value={state.due_date}
                                disabled={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={12} xs={12}>
                        <Box p={1}>
                            <EmployeeTaskTable state={state} setState={setState} clientDetailsAPI={clientDetailsAPI} open={open} setOpen={setOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        </Box>
                    </Grid>
                    <Grid item xl={12} xs={12} container pt={2} pb={1}>
                        <Box display='flex' flexDirection='row' gap={2} alignItems='center' pl={1}>
                            <Checkbox name='clone_invoice' value={state.clone_invoice} checked={state.clone_invoice}
                                onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                checkedIcon={<CheckedIcon />} />
                            <Text mediumBlack>Clone invoice for other employees.</Text>
                        </Box>
                    </Grid>
                    {
                        state.clone_invoice ?
                            <Grid item xs={12} container alignContent={'center'}>
                                <Stack direction={'row'} gap={1} sx={{ background: '#F8F4E3', borderRadius: '5px' }} p={2} alignContent={'center'}>
                                    <Box pt={1.2}>
                                        <img src={InfoIcon} alt='img' height={'20px'} width={'20px'} />
                                    </Box>
                                    <Text mediumOrange>
                                        All other details in the invoice excluding adjustments will remain the same. The employee name, rates and hours will change and the generated invoice can be viewed in the drafts section.
                                    </Text>
                                </Stack>
                            </Grid>
                            : ''}
                    {
                        state.clone_invoice ?
                            <Grid item container lg={3} md={3} sm={3} xs={12}>
                                <Box display={'flex'} flexDirection={'row'} pl={1}>
                                    <RadioGroup
                                        row
                                        name="bill_timesheet_type"
                                        items={[
                                            { id: 1, title: '', value: 1 },
                                        ]}
                                        onChange={() => { setState({ ...state, bill_timesheet_type: 1 }) }}
                                        value={state.bill_timesheet_type}
                                    />
                                    <Text mediumBlack sx={{ paddingTop: '8px' }}>Bill only for approved timesheets.</Text>
                                </Box>
                            </Grid>
                            : ''
                    }
                    {
                        state.clone_invoice ?
                            <Grid item container lg={3} md={3} sm={3} xs={12}>
                                <Box display={'flex'} flexDirection={'row'} pl={1}>
                                    <RadioGroup
                                        row
                                        name="bill_timesheet_type"
                                        items={[
                                            { id: 2, title: '', value: 2 },
                                        ]}
                                        onChange={() => { setState({ ...state, bill_timesheet_type: 2 }) }}
                                        value={state.bill_timesheet_type}
                                    />
                                    <Text mediumBlack sx={{ paddingTop: '8px' }}>Bill for all timesheets.</Text>
                                </Box>
                            </Grid>
                            : ''}
                    <Grid item container xs={12}>
                        <Grid item container md={6} sm={12} xs={12}>
                            <Grid item xs={12} pb={1} pl={1}>
                                <Text mediumBlack>Adjustments</Text>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box p={1}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'adjustment_amount',
                                            type: 'text',
                                        }}
                                        clientInput
                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                        labelText={'Adjustment Amount'}
                                        value={state.adjustment_amount}
                                        handleChange={changeHandler}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box p={1}>
                                    <Search
                                        name='adjustment_type'
                                        options={adjustmentList}
                                        label='Adjustment Details'
                                        value={state.adjustment_type}
                                        onChange={changeHandler}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiline={true}
                                        rows={3}
                                        inputProps={{
                                            name: 'adjustment_comment',
                                            value: state.adjustment_comment,
                                            inputProps: { maxLength: 256 }
                                        }}
                                        handleChange={changeHandler}
                                        commentsField
                                        labelText={'Comments'}
                                        placeholder={'Type here…'}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid item container md={6} sm={12} xs={12}>
                            <Grid item xs={12} p={1} pt={3}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        margin: '0 auto',
                                        padding: '16px',
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {/* Sub Total */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '16px' }}>
                                        <Text mediumBlack>Sub Total</Text>
                                        <Text mediumBlack fontWeight="bold">$ {state.sub_total_amount}</Text>
                                    </Box>

                                    {/* Discount */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '16px' }}>
                                        <Text mediumBlack>Discount</Text>
                                        <Box display="flex" alignItems="center" gap="8px">
                                            <TextField
                                                size="small"
                                                name="discount_value"
                                                sx={{ width: '80px' }}
                                                value={state.discount_value}
                                                onChange={changeHandler}
                                                onKeyDown={onNumberWithTwoDecimalOnlyChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Text>{state.discount_type == 1 ? '%' : state.discount_type == 2 ? '$' : ''}</Text>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Select
                                                name="discount_type"
                                                value={state.discount_type}
                                                onChange={changeHandler}
                                                size="small"
                                            >
                                                <MenuItem value={1}>%</MenuItem>
                                                <MenuItem value={2}>$</MenuItem>
                                            </Select>
                                        </Box>
                                        <Text mediumBlack fontWeight="bold">$ {state.discount_amount ? Number(state.discount_amount).toFixed(3) : '0'}</Text>
                                    </Box>

                                    {/* Adjustments */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '16px' }}>
                                        <Text mediumBlack>Adjustments</Text>
                                        <Text mediumBlack fontWeight="bold">$ {state.adjustment_amount ? state.adjustment_amount : '00.00'}</Text>
                                    </Box>

                                    {/* Divider */}
                                    <Divider sx={{ marginBottom: '16px' }} />

                                    {/* Total */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Text mediumBlack fontWeight="bold">Total</Text>
                                        <Text mediumBlack fontWeight="bold">$ {state.total_amount ? Number(state.total_amount).toFixed(3) : '0.000'}</Text>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <Box p={1} pt={2}>
                                {/* <Text black14 sx={{ paddingBottom: '5px' }}>Terms and Conditions</Text> */}
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    multiline={true}
                                    rows={2.5}
                                    inputProps={{
                                        name: 'terms_and_conditions',
                                        value: state.terms_and_conditions,
                                        inputProps: { maxLength: 255 }
                                    }}
                                    handleChange={changeHandler}
                                    commentsField
                                    labelText={"Terms and conditions"}
                                    placeholder={'Type here…'}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <Box p={1}>
                                {/* <Text black14 >Email Adress</Text> */}
                                <Autocomplete
                                    multiple
                                    id="multiple-limit-tags"
                                    freeSolo
                                    options={[]}
                                    getOptionLabel={(option) => option}
                                    value={state.email_ids || []}
                                    inputValue={inputValue}
                                    onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                                    onKeyDown={handleKeyDown}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            margin="dense"
                                            {...params}
                                            label="Email Address"
                                            multiline={true}
                                            // rows={2.5}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: '8px !important',
                                                    borderRadius: '8px',
                                                    background: '#ffffff',
                                                    border: '1px solid #C7CCD3',
                                                    '&:hover': {
                                                        background: '#ffffff',
                                                    },
                                                    '&.Mui-focused': {
                                                        background: '#ffffff',
                                                        border: "2px solid #0C75EB",
                                                    },
                                                },
                                                "& .MuiFilledInput-root:before": {
                                                    borderBottom: 'none !important',
                                                },
                                                "& .MuiFilledInput-root:after": {
                                                    borderBottom: 'none !important',
                                                    color: '#0C75EB !important'
                                                },
                                                "& .MuiInputBase-input": {
                                                    font: "14px Quicksand !important",
                                                },
                                                '& .MuiInputBase-root': {
                                                    position: 'relative',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    background: '#FFFFFF !important',
                                                    borderRadius: '8px',
                                                    border: '1px solid #C7CCD3',
                                                    "&.Mui-focused": {
                                                        border: `2px solid #0C75EB !important`,
                                                    },
                                                    "& .MuiFormLabel-asterisk": {
                                                        color: "blue !important"
                                                    },
                                                    color: "#181A18",
                                                    font: '10px Quicksand !important',
                                                    fontWeight: "500 !important",
                                                    paddingRight: "80px !important",
                                                    marginRight: '60px !important',
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    font: '15px Quicksand !important',
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        "& .MuiFormLabel-asterisk": {
                                                            color: "red !important"
                                                        },
                                                        color: "#0C75EB",
                                                        font: '15px Quicksand !important',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) => (

                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>
                                            {value?.length > 0 && value?.map((option, key) => (
                                                <Chip
                                                    key={key}
                                                    label={option}
                                                    {...getTagProps({ key })}
                                                    onDelete={() => { handleDelete(option) }}
                                                    deleteIcon={
                                                        <CancelChip />
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    )}

                                    sx={{
                                        width: '100%',
                                        marginTop: '-2px',
                                        marginRight: '60px !important',
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: 'blue',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                        "&.Mui-focused .MuiInputLabel-outlined": {
                                            color: "#181A18",
                                            fontSize: "10px",
                                            fontFamily: "Quicksand",
                                            fontWeight: 500,
                                            lineHeight: "12px"

                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xl={12} xs={12} container pt={2} pb={1}>
                            <Box display='flex' flexDirection='row' gap={2} alignItems='center' pl={1}>
                                <Checkbox name='is_timesheets_attached' checked={state.is_timesheets_attached} onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                    checkedIcon={<CheckedIcon />} />
                                <Text mediumBlack>Attach timesheets to invoice</Text>
                            </Box>
                        </Grid>
                        <Grid item xl={12} xs={12} container pt={2} pb={4}>
                            <Box display='flex' flexDirection='row' gap={2} alignItems='center' pl={1}>
                                <Checkbox onChange={changeHandler} checked={state.is_recurring} name='is_recurring' sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                    checkedIcon={<CheckedIcon />} />
                                <Text mediumBlack>Recurring Invoice</Text>
                            </Box>
                        </Grid>
                        {
                            state.is_recurring ?
                                <Grid container xs={12}>
                                    <Grid item md={3} sm={12} xs={12}>
                                        <Box p={1}>
                                            <Search
                                                name='repeat_key'
                                                options={[
                                                    {
                                                        id: 1,
                                                        value: 'Week'
                                                    },
                                                    {
                                                        id: 2,
                                                        value: '2 Weeks'
                                                    },
                                                    {
                                                        id: 3,
                                                        value: 'Month'
                                                    },
                                                    {
                                                        id: 4,
                                                        value: '2 Months'
                                                    },
                                                    {
                                                        id: 5,
                                                        value: '3 Months'
                                                    },
                                                    {
                                                        id: 6,
                                                        value: '6 Months'
                                                    },
                                                    {
                                                        id: 7,
                                                        value: '8 Months'
                                                    },
                                                    {
                                                        id: 8,
                                                        value: 'Year'
                                                    },
                                                    {
                                                        id: 9,
                                                        value: 'Custom'
                                                    }
                                                ]}
                                                label='Repeat Every'
                                                value={state.repeat_key}
                                                onChange={changeHandler}
                                                error={error.repeat_key}
                                            />
                                            <Text errorText>{error.repeat_key}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container md={9} sm={12} xs={12}>
                                        {
                                            state.repeat_key == 9 &&
                                            <Grid item container xs={12} spacing={2}>
                                                <Grid item xs={1.8}>
                                                    <Box p={1}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'recurring_cycle_number',
                                                                value: state.recurring_cycle_number,
                                                                inputProps: { maxLength: 3 }
                                                            }}
                                                            handleChange={changeHandler}
                                                            onKeyDown={onNumberOnlyChange}
                                                            commentsField
                                                            labelText={"Count"}
                                                            errror={error.recurring_cycle_number}
                                                        />
                                                        <Text errorText>{error.recurring_cycle_number}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} sm={12} pt={1} xs={12}>
                                                    <Box p={1}>
                                                        <Search
                                                            name='recurring_cycle_type'
                                                            options={[
                                                                {
                                                                    id: 'days',
                                                                    value: 'Days'
                                                                },
                                                                {
                                                                    id: "weeks",
                                                                    value: 'Weeks'
                                                                },
                                                                {
                                                                    id: "months",
                                                                    value: 'Months'
                                                                },
                                                                {
                                                                    id: "years",
                                                                    value: 'Years'
                                                                }
                                                            ]}
                                                            label='Frequency'
                                                            fullWidth
                                                            value={state.recurring_cycle_type}
                                                            onChange={changeHandler}
                                                            error={error.recurring_cycle_type}
                                                        />
                                                        <Text errorText>{error.recurring_cycle_type}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Box p={1}>
                                            <Date
                                                labelText={'Recurring Start Date'}
                                                name='recurring_start_date'
                                                height='55px'
                                                onChange={(value => dateChange(value, 'recurring_start_date'))}
                                                value={state.recurring_start_date}
                                                disabled={state.repeat_key == ''}
                                                minDate={moment(state.to_date, dateFormat()).add(1, 'days').format(dateFormat())}
                                                error={error.recurring_start_date}
                                            />
                                            <Text red>{error.recurring_start_date}</Text>
                                        </Box>
                                    </Grid>
                                    {
                                        state.recurring_never_expires ?
                                            '' :
                                            <Grid item md={3} sm={4} xs={12}>
                                                <Box p={1}>
                                                    <Date
                                                        labelText={'Recurring End Date'}
                                                        name='from_date'
                                                        height='55px'
                                                        onChange={(value => dateChange(value, 'recurring_end_date'))}
                                                        minDate={state.recurring_start_date}
                                                        value={state.recurring_end_date}
                                                        disabled={state.repeat_key == ''}
                                                        error={error.recurring_end_date}
                                                    />
                                                    <Text red>{error.recurring_end_date}</Text>
                                                </Box>
                                            </Grid>
                                    }
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Box p={1}>
                                            <Date
                                                labelText={'Invoice Date'}
                                                name='from_date'
                                                height='55px'
                                                onChange={(value => dateChange(value, 'recurring_invoice_date'))}
                                                minDate={calculateInvoice()}
                                                value={state.recurring_invoice_date}
                                                disabled={state.repeat_key == ''}
                                                error={error.recurring_invoice_date}
                                            />
                                            <Text red>{error.recurring_invoice_date}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12} container pt={3} pb={4}>
                                        <Box display='flex' flexDirection='row' gap={2} alignItems='center' pl={1}>
                                            <Checkbox name='recurring_never_expires' onChange={changeHandler} checked={state.recurring_never_expires} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                checkedIcon={<CheckedIcon />} />
                                            <Text mediumBlack>Never Expires</Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                        }
                        <Grid item container lg={2} md={2} sm={3} pt={2} pb={2} xs={12}>
                            {
                                (state.status == 'Drafted' || state.status == undefined || state.status == null || state.status == '') ?
                                    <Button disabled={clientConfigError} outlineBlueAuto onClick={() => { formSubmit('draft') }} 
                                        sx={{
                                            background: clientConfigError?'#C7CCD3 !important':"",
                                            color: clientConfigError?`#FFFFFF !important`:"",
                                            border: clientConfigError?`1px solid #FFFFFF !important`:""
                                        }}>Save as Draft</Button> : ''
                            }
                        </Grid>
                        <Grid item container lg={10} md={10} xs={12} sm={9} justifyContent={'flex-end'} pt={2} pb={2}>
                            <Stack direction={'row'} gap={2}>
                                <Button blackCancel onClick={() => { navigate('/invoice') }}>Cancel</Button>
                                {
                                    clientDetailsAPI.client_details.send_invoice_email ?
                                        <Button disabled={clientConfigError} blueButton onClick={() => { formSubmit('') }} sx={{
                                            background: clientConfigError?'#C7CCD3 !important':""}}>Save & Send</Button>
                                        : <Button disabled={clientConfigError} blueButton onClick={() => { formSubmit('') }} sx={{
                                            background: clientConfigError?'#C7CCD3 !important':"",
                                    
                                                                            }}>Save</Button>
                                }

                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={emailPreview} onClose={() => { setemailPreview(false); navigate('/invoice') }} fullWidth >
                    <DialogContent>
                        <Box>
                            <Text blackHeader1>Email Preview</Text>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <>
                                    <Autocomplete
                                        multiple
                                        id="multiple-limit-tags"
                                        freeSolo
                                        options={[]}
                                        getOptionLabel={(option) => option.value}
                                        value={invoiceGetTemplate?.toEmail || []}
                                        inputValue={inputValue}
                                        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}

                                        onKeyDown={handleEmailRemainder}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="filled"
                                                margin="dense"
                                                {...params}
                                                label="Send To"

                                                sx={{

                                                    '& .MuiOutlinedInput-root': {
                                                        fontSize: '8px !important',
                                                        borderRadius: '8px',
                                                        background: '#ffffff',
                                                        border: '1px solid #C7CCD3',
                                                        '&:hover': {
                                                            background: '#ffffff',
                                                        },
                                                        '&.Mui-focused': {
                                                            background: '#ffffff',
                                                            border: "2px solid #0C75EB",
                                                        },
                                                    },
                                                    "& .MuiFilledInput-root:before": {
                                                        borderBottom: 'none !important',
                                                    },
                                                    "& .MuiFilledInput-root:after": {
                                                        borderBottom: 'none !important',
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        font: "14px Quicksand !important",
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        position: 'relative',
                                                    },
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    sx: {
                                                        background: '#FFFFFF !important',
                                                        borderRadius: '8px',
                                                        border: '1px solid #C7CCD3',
                                                        "&.Mui-focused": {
                                                            border: `2px solid #0C75EB !important`,
                                                        },
                                                        "& .MuiFormLabel-asterisk": {
                                                            color: "blue !important"
                                                        },
                                                        color: "#181A18",
                                                        font: '10px Quicksand !important',
                                                        fontWeight: "500 !important",
                                                        paddingRight: "80px !important",
                                                        marginRight: '60px !important',
                                                    }
                                                }}
                                            />
                                        )}
                                        renderTags={(value, getTagProps) => (

                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>

                                                {value.map((option, key) => (
                                                    <Chip
                                                        key={key}
                                                        label={option}
                                                        {...getTagProps({ key })}
                                                        deleteIcon={<CancelChip />}
                                                        onDelete={() => handleDeleteChip(option)}
                                                    />
                                                ))}
                                            </Box>
                                        )}

                                        sx={{
                                            width: '100%',
                                            marginRight: '60px !important',
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: 'blue',
                                            },
                                            "& .MuiAutocomplete-endAdornment": {
                                                display: "none",
                                            },
                                            "&.Mui-focused .MuiInputLabel-outlined": {
                                                color: "#181A18",
                                                fontSize: "10px",
                                                fontFamily: "Quicksand",
                                                fontWeight: 500,
                                                lineHeight: "12px"

                                            },
                                        }}
                                    />

                                </>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    pt={1}
                                    pl={1.5}
                                    sx={{
                                        border: '1px solid rgba(0, 0, 0, 0.2)',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Text mediumLabel>
                                        Subject
                                    </Text>
                                    <RichTextEditor
                                        disabled={true}
                                        value={invoiceGetTemplate?.subject
                                            ? RichTextEditor.createValueFromString(invoiceGetTemplate?.subject, "html")
                                            : RichTextEditor.createValueFromString("", "html")
                                        }
                                        editorClassName={classesMainStyles.editorHeight}
                                        toolbarClassName={classesMainStyles.toolBar}
                                        className={`${classesMainStyles.customEditorStyle} `}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        minHeight: '150px',
                                        overflowY: 'auto',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: invoiceGetTemplate?.html }}
                                />
                            </Grid>

                        </Grid>
                        <Box mt={2}>
                            <Text blackHeader1>Invoice Attachment</Text>
                        </Box>
                        <Grid container spacing={2} mt={.5}>
                            {/* Uploaded File List in Grid */}
                            {attachments.map((file, index) => (
                                <Grid item key={index}>
                                    <Grid
                                        container
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            padding: "8px 12px",
                                            borderRadius: "8px",
                                            border: "1px solid #ccc",
                                            backgroundColor: "#f9f9f9",
                                            width: "auto",
                                        }}
                                    >
                                        {/* Left-side icon */}
                                        <Grid item>
                                            <img src={pdfIcon} alt="pdfIcon" />
                                        </Grid>

                                        {/* File name in the center */}
                                        <Grid item xs>
                                            <Text
                                                sx={{
                                                    textAlign: "center",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    fontSize: '12px'
                                                }}
                                                mediumBoldBlack2
                                            >
                                                {file.document_path?.slice(0, 16)} {file.document_path?.length > 16 ? '...' : ''}
                                            </Text>
                                        </Grid>

                                        {/* Right-side delete icon */}
                                        <Grid item>
                                            <img
                                                src={pdfDeleteIcon}
                                                alt="pdfDeleteIcon"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { handleDeleteFile(index) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}

                            {/* Upload Button */}
                            <Grid item mt={1}>
                                <Button
                                    dropdownAdd
                                    onClick={handleFileClick}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" style={{ padding: "8px 12px", }} />
                                    <Text mediumBlue600 sx={{ fontWeight: "400" }}>Add Attachment</Text>
                                </Button>

                                {/* Hidden File Input */}
                                <input
                                    type="file"
                                    multiple
                                    hidden
                                    ref={fileInputRef}
                                    onChange={uploadDocs}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 2 }} />
                        <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={() => { setemailPreview(false); navigate('/invoice') }} sx={{ border: `1px solid #171717 !important`, height: '40px', width: '100px' }}><Text blackHeader18 sx={{ fontSize: '13px !important', }}>
                                    Cancel
                                </Text></Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton saveExtraSmall onClick={sendEmail}><Text largeWhite sx={{ fontSize: '13px !important', }}>Send</Text></LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <BootstrapDialog
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="customized-dialog-title"
                    open={netpayShow}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogContent sx={{ margin: "10px", }}>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                            <img src={offBoardSave} alt='noactive' />
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px!important" }}>Are you sure?</Text>
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                            <Text greyLabel14 sx={{ textAlign: 'center !important' }}>Would you like to update the payment terms for this invoice <br /> only or apply the change to all invoices for this customer?</Text>
                        </Grid>
                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                            <Stack direction={"row"} spacing={3}>
                                <Button sx={{ width: 'auto', fontSize: '12px !important', fontWeight: 'bold', fontFamily: 'QuickSand', background: 'white', color: '#0C75EB', border: '1px solid #0C75EB', textTransform: 'capitalize' }} onClick={() => { setNetpayShow(false) }}>
                                    No, Apply only for this invoice
                                </Button>
                                <Button sx={{ width: 'auto', fontSize: '12px !important', fontWeight: 'bold', fontFamily: 'QuickSand', color: 'white', background: '#0C75EB', border: '1px solid #0C75EB', textTransform: 'capitalize', '&:hover': { background: '#0C75EB' } }} onClick={() => { updateClientNetPayterms() }} >
                                    Yes, Apply to All
                                </Button>
                            </Stack>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
                <BootstrapDialog
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="customized-dialog-title"
                    open={timesheetShow}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogContent sx={{ margin: "10px", }}>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                            <img src={offBoardSave} alt='noactive' />
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px!important" }}>Overriding Default Setting!</Text>
                        </Grid>
                        <Grid xs={12} item container justifyContent={'center'} alignContent={'center'} textAlign={'center'} pt={2}>
                            <Text greyLabel14 sx={{ textAlign: 'center !important' }}>You are overriding a default setting. What is the <br /> scope you want to update?</Text>
                        </Grid>
                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                            <Stack direction={"row"} spacing={3}>
                                <Button sx={{ width: 'auto', fontSize: '12px !important', fontWeight: 'bold', fontFamily: 'QuickSand', background: 'white', color: '#0C75EB', border: '1px solid #0C75EB', textTransform: 'capitalize' }} onClick={() => { setTimesheetShow(false) }}>
                                    This Invoice
                                </Button>
                                <Button sx={{ width: 'auto', fontSize: '12px !important', fontWeight: 'bold', fontFamily: 'QuickSand', color: 'white', background: '#0C75EB', border: '1px solid #0C75EB', textTransform: 'capitalize', '&:hover': { background: '#0C75EB' } }} onClick={() => { updateClientNetPayterms('timesheet') }} >
                                    All invoices for this client
                                </Button>
                            </Stack>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </>
    )
}