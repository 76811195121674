import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import Text from '../../../../../components/customText/Text';
import Input from '../../../../../components/input/Input';
import Select from '../../../../../components/select/Select';
import Button from '../../../../../components/customButton/Button';
import ToggleSwitch from '../../../../../components/toggle/CustomToggle';
import { validate_charWithSpace, validate_emptyField, isValid } from '../../../../../components/Validation';
import { addErrorMsg, addSuccessMsg, addWarningMsg, BlackToolTip } from '../../../../../utils/utils';
import RemindersApi from '../../../../../apis/configurations/reminders/RemindersApi';
import RichTextEditor from 'react-rte';
import LocalStorage from '../../../../../utils/LocalStorage';
import MainStyles from '../../MainStyles';
import CustomSelect from '../../../../../components/customSelect/CustomSelect';

const ReminderDrawer = ({ current, currentCTA, remDrawer, setRemDrawer, clickedViewReminder, clickedEditReminder, getActivity }) => {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    const classes = MainStyles();
    const [error, setError] = useState({});
    const [state, setState] = useState({
        beforeafter: 1,
        triggerDays: '',
        repeatDays: '',
        reminder_name: '',
        emailtemplate: '',
        to: ''
    })

    const [stateView, setStateView] = useState({
        beforeafter: '',
        triggerDays: '',
        repeatDays: '',
        reminder_name: '',
        emailtemplate: '',
        to: '',
        subject: RichTextEditor.createValueFromString("", "html"),
        body:"",
    })
    const [currentView, setCurrentView] = useState('')
    const [repeatToggle, setRepeatToggle] = useState(false);
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [emailTemplateData, setEmailTemplateData] = useState({
        id: '',
        slug: '',
        value: '',
        subject: RichTextEditor.createValueFromString("", "html"),
        template: ""
    });

    useEffect(() => {
        if (!remDrawer) {
            setState({
                beforeafter: '',
                triggerDays: '',
                repeatDays: '',
                reminder_name: '',
                emailtemplate: '',
                to: ''
            })
            setError({});
            setRepeatToggle(false)
        } else {
            setCurrentView(currentCTA);
            if (currentCTA == 'add') {
                setState({
                    beforeafter: 1,
                    triggerDays: '01',
                    repeatDays: '01',
                    reminder_name: '',
                    emailtemplate: '',
                    to: ''
                })
                setRepeatToggle(false);
                setEmailTemplateData({
                    id: '',
                    slug: '',
                    value: '',
                    subject: RichTextEditor.createValueFromString("", "html"),
                    template: ""
                })
            }
            getEmailTemplates();
        }
        // eslint-disable-next-line 
    }, [remDrawer]);


    const getEmailTemplates = () => {
        RemindersApi.emailTemplateDropdown().then(response => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setEmailTemplateOptions(response.data.data);
                    if (currentCTA == 'view') {
                        const emailTemplate = response.data.data.find((eachTemplate) => eachTemplate.slug == clickedViewReminder.email_template_slug);
                        setStateView({
                            beforeafter: clickedViewReminder.reminder_type == 1 ? 'before' : 'after',
                            triggerDays: clickedViewReminder.reminder_days,
                            repeatDays: clickedViewReminder.repeat_reminder_days,
                            reminder_name: clickedViewReminder.name,
                            emailtemplate: emailTemplate.value,
                            subject: RichTextEditor.createValueFromString(emailTemplate.subject, "html"), body: emailTemplate.template,
                            to: clickedViewReminder.send_to == 1 ? 'Me' : clickedViewReminder.send_to == 2 ? 'Customer' : 'Customer and Copy Me',
                        })
                    }
                    if (currentCTA == 'edit') {
                        const emailTemplate = response.data.data.find((eachTemplate) => eachTemplate.slug == clickedEditReminder.email_template_slug);
                        setState({
                            beforeafter: clickedEditReminder.reminder_type,
                            triggerDays: clickedEditReminder.reminder_days,
                            repeatDays: clickedEditReminder.repeat_reminder ? clickedEditReminder.repeat_reminder_days : "01",
                            reminder_name: clickedEditReminder.name,
                            to: clickedEditReminder.send_to,
                            emailtemplate: emailTemplate.id
                        })
                        setEmailTemplateData({
                            id: emailTemplate.id,
                            slug: emailTemplate.slug,
                            value: emailTemplate.value,
                            subject: RichTextEditor.createValueFromString(emailTemplate.subject, "html"),
                            template: emailTemplate.template
                        });
                        setRepeatToggle(clickedEditReminder.repeat_reminder);
                    }
                    if (currentCTA == 'add') {
                        setRepeatToggle(false)
                    }
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 300)
        })
    }


    const handleChange = (e) => {
        if (e.target.name == 'triggerDays' || e.target.name == 'repeatDays') {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value.replace(/\D/g, '').substring(0, 3),
                },
                handleValidate(e)
            );
        }
        else if (e.target.name == 'emailtemplate') {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value,
                },
                handleValidate(e)
            );
            const emailTemplate = emailTemplateOptions.find((eachId) => eachId.id === e.target.value)
            setEmailTemplateData({
                id: emailTemplate.id,
                slug: emailTemplate.slug,
                value: emailTemplate.value,
                subject: RichTextEditor.createValueFromString(emailTemplate.subject, "html"),
                template: emailTemplate.template
            })
        }
        else {
            setState(
                {
                    ...state,
                    [e.target.name]: e.target.value,
                },
                handleValidate(e)
            );
        }
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "reminder_name":
                error.reminder_name = validate_charWithSpace(input.value, 'reminder');
                break;
            case "repeatDays":
                error.repeatDays = validate_emptyField(input.value);
                break;
            case "triggerDays":
                error.triggerDays = validate_emptyField(input.value);
                break;
            case "to":
                error.to = validate_emptyField(input.value);
                break;
            case "emailtemplate":
                error.emailtemplate = validate_emptyField(input.value);
                break;
            case "beforeafter":
                error.beforeafter = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    };

    const beforeafteroptions = [
        {
            "id": 1,
            "value": "Before"
        },
        {
            "id": 2,
            "value": "After"
        },
    ]
    const validateAll = () => {
        const { beforeafter,
            triggerDays,
            repeatDays,
            reminder_name,
            emailtemplate,
            to } = state
        error.beforeafter = validate_emptyField(beforeafter);
        error.emailtemplate = validate_emptyField(emailtemplate);
        error.to = validate_emptyField(to);
        error.triggerDays = validate_emptyField(triggerDays);
        if (repeatToggle) {
            error.repeatDays = validate_emptyField(repeatDays);
        }
        error.reminder_name = validate_charWithSpace(reminder_name, 'reminder');
        setError({ ...error })
        return error;
    }
    const handleSaveEdit = () => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true)))) {

            let errors = validateAll();
            const emailTemplate = emailTemplateOptions.find(eachId => eachId.id === state.emailtemplate);
            const params = {
                "reminder_days": parseInt(state.triggerDays),
                "reminder_type": state.beforeafter,
                "repeat_reminder": repeatToggle,
                "repeat_reminder_days": repeatToggle ? parseInt(state.repeatDays) : 0,
                "name": state.reminder_name,
                "email_template_slug": emailTemplate.slug,
                "send_to": state.to,
                "request_id": LocalStorage.uid()
            }
            if (isValid(errors)) {
                if (currentView != 'edit') {
                    RemindersApi.storeInvoiceReminder(params).then(response => {
                        if (response.data.statusCode == 1003) {
                            addSuccessMsg(response.data.message);
                            setRemDrawer();
                            getActivity();
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    })
                } else {
                    let id;
                    if (currentCTA == 'view') {
                        id = clickedViewReminder.id
                    } else if (currentCTA == 'edit') {
                        id = clickedEditReminder.id
                    }
                    RemindersApi.updateReminder(id, params).then(response => {
                        if (response.data.statusCode == 1003) {
                            addSuccessMsg(response.data.message);
                            setRemDrawer();
                            getActivity();
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    })
                }
            } else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');

            }

        }
    }


    const handleRepeatToggle = () => {
        if (repeatToggle) {
            setError({ ...error, repeatDays: '' })
        }
        if (currentCTA == 'add') {
            setState({ ...state, repeatDays: '01' });
        }
        setRepeatToggle((prevState) => !prevState)
    }

    const handleEditIcon = () => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true)))) {
            setCurrentView('edit');
            const emailTemplate = emailTemplateOptions.find((eachTemplate) => eachTemplate.slug == clickedViewReminder.email_template_slug);
            setState({
                beforeafter: clickedViewReminder.reminder_type,
                triggerDays: clickedViewReminder.reminder_days,
                repeatDays: clickedViewReminder.repeat_reminder ? clickedViewReminder.repeat_reminder_days : "01",
                reminder_name: clickedViewReminder.name,
                to: clickedViewReminder.send_to,
                emailtemplate: emailTemplate.id
            })
            setEmailTemplateData({
                id: emailTemplate.id,
                slug: emailTemplate.slug,
                value: emailTemplate.value,
                subject: RichTextEditor.createValueFromString(emailTemplate.subject, "html"),
                template: emailTemplate.template
            });
            setRepeatToggle(clickedViewReminder.repeat_reminder);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }


    }

    return (
        <div>
            <Box width={{ lg: '660px', md: "660px", sm: "560px", xs: "310px" }} height={'100vh'} p={3} sx={{ position: 'relative' }}>

                {(currentView == 'edit' || currentView == 'add') &&
                    <Box height={'100%'}>
                        <Box height={'7%'}>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text blackHeader1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{currentView == 'add' ? 'Add' : 'Edit'} Reminder</Text >

                            </Grid>
                        </Box>
                        <Box style={{ overflowY: 'auto' }} height={'92%'} >
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box >
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'reminder_name',
                                                value: state.reminder_name,
                                                inputProps: { maxLength: 50 }
                                            }}
                                            clientInput
                                            handleChange={handleChange}
                                            labelText={'Reminder Name'}
                                            error={error.reminder_name}
                                        />
                                        {
                                            error.reminder_name ?
                                                <Text red>{error.reminder_name ? error.reminder_name : ''}</Text> : ''
                                        }
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Text mediumBlack14>
                                            Trigger Settings
                                        </Text>
                                        <Grid container mt={2} gap={1}>
                                            <Grid item lg={1} md={1} sm={1} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                <Text mediumLabel >
                                                    Remind
                                                </Text>
                                            </Grid>

                                            <Grid item lg={3.8} md={3.8} sm={3.8} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'triggerDays',
                                                        value: state.triggerDays,
                                                        inputProps: { maxLength: 3 },


                                                    }}
                                                    
                                                    clientInputCenter
                                                    handleChange={handleChange}
                                                    // labelText={'Days'}
                                                    error={error.triggerDays}

                                                />



                                                {
                                                    error.triggerDays ?
                                                        <Text red>{error.triggerDays ? error.triggerDays : ''}</Text> : ''
                                                }
                                            </Grid>
                                            <Grid container item lg={0.8} md={0.8} sm={0.8} xs={12} alignItems='center' justifyContent={{ lg: 'center', md: 'center', sm: 'center', xs: 'start' }}>

                                                <Text mediumLabel  >
                                                    {`day(s)`}
                                                </Text>
                                            </Grid>

                                            <Grid item lg={3.8} md={3.8} sm={3.8} xs={12}>
                                                {/* <Select
                                                    name='beforeafter'
                                                    value={state.beforeafter}
                                                    onChange={handleChange}
                                                    options={beforeafteroptions}
                                                    label={''}
                                                    error={error.beforeafter ? error.beforeafter : ''}
                                                    
                                                /> */}
                                                <CustomSelect
                                                    name='beforeafter'
                                                    value={state.beforeafter}
                                                    selectCenter
                                                    scrollTrue={true}
                                                    options={beforeafteroptions}
                                                    onChange={handleChange}
                                                    labelId={''}
                                                    error={error.beforeafter ? error.beforeafter : ''}
                                                />
                                                {
                                                    error.beforeafter ?
                                                        <Text red>{error.beforeafter ? error.beforeafter : ''}</Text> : ''
                                                }
                                            </Grid>
                                            <Grid container item lg={1.5} md={1.5} sm={1.5} xs={12} alignItems='center' justifyContent='start'>

                                                <Text mediumLabel >
                                                    due date
                                                </Text>
                                            </Grid>

                                        </Grid>
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                            <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                <Text mediumBlack14 >
                                                    Repeat  Reminder
                                                </Text>
                                            </Grid>
                                            <Grid item  >
                                                <ToggleSwitch name='is_active' isActive={repeatToggle}
                                                    switchChange={handleRepeatToggle}
                                                    sx={{ height: '24px !important' }} />
                                            </Grid>
                                        </Grid>
                                        {repeatToggle &&
                                            <Grid container mt={2} gap={1}>
                                                <Grid item lg={1.6} md={1.6} sm={1.6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Text mediumLabel >
                                                        Repeat every
                                                    </Text>
                                                </Grid>
                                                <Grid item lg={3.4} md={3.4} sm={3.4} xs={12} >
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'repeatDays',
                                                            value: state.repeatDays,
                                                            inputProps: { maxLength: 3 }
                                                        }}
                                                        clientInputCenter
                                                        handleChange={handleChange}
                                                        error={error.repeatDays}
                                                    />
                                                    {
                                                        error.repeatDays ?
                                                            <Text red>{error.repeatDays ? error.repeatDays : ''}</Text> : ''
                                                    }
                                                </Grid>
                                                <Grid container item lg={6.4} md={6.4} sm={6.4} xs={12} alignItems='center' justifyContent='start'>

                                                    <Text mediumLabel  >
                                                        {`day(s) ${state.beforeafter == 1 ? "before" : "after"}`} due date until invoice is paid
                                                    </Text>
                                                </Grid>
                                            </Grid>}
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Select
                                            name='emailtemplate'
                                            value={state.emailtemplate}
                                            onChange={handleChange}
                                            options={emailTemplateOptions}
                                            label={'Choose Email Template'}
                                            error={error.emailtemplate}
                                        />
                                        {
                                            error.emailtemplate ?
                                                <Text red>{error.emailtemplate ? error.emailtemplate : ''}</Text> : ''
                                        }
                                    </Box>
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Select
                                            name='to'
                                            value={state.to}
                                            onChange={handleChange}
                                            options={[{
                                                "id": 1,
                                                "value": "Me"
                                            },
                                            {
                                                "id": 2,
                                                "value": "Customer"
                                            }, {
                                                "id": 3,
                                                "value": "Customer and Copy me"
                                            }]}
                                            label={'To'}
                                            error={
                                                error.to}
                                        />
                                        {
                                            error.to ?
                                                <Text red>{error.to ? error.to : ''}</Text> : ''
                                        }
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2} pb={1} pl={1.5} sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '8px' }} mt={2}>
                                        <Text mediumLabel >
                                            Subject
                                        </Text>
                                        <Box mt={1}>
                                        {state.emailtemplate != '' &&
                                            <RichTextEditor
                                                disabled={true}
                                                // readOnly
                                                // onChange={onChangeEditor}
                                                value={state.emailtemplate ? emailTemplateData?.subject : RichTextEditor.createValueFromString("", "html")}
                                                editorClassName={classes.editorHeight}
                                                toolbarClassName={classes.toolBar}
                                                // placeholder={}
                                                // labelText={'Template'}
                                                className={`${classes.customEditorStyle} ${classes.disabledEditor} `}

                                            />}
                                        </Box>

                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Box pt={2} pb={1} pl={1.5} sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '8px', zIndex: "99px" }} mt={1}>


                                        <Text mediumLabel>
                                            Body
                                        </Text>
                                        <Box mt={1}>
                                        {state.emailtemplate != '' &&
                                        // <RichTextEditor
                                        //     disabled={true}
                                        //     // readOnly
                                        //     // onChange={onChangeEditor}
                                        //     value={state.emailtemplate ? emailTemplateData?.template : RichTextEditor.createValueFromString("", "html")}
                                        //     editorClassName={classes.editorHeight}
                                        //     toolbarClassName={classes.toolBar}
                                        //     // placeholder={}
                                        //     // labelText={'Template'}
                                        //     className={`${classes.customEditorStyle} ${classes.disabledEditor}`}

                                        // />


                                        <div
                                        style={{
                                            minHeight: '150px',
                                            overflowY: 'auto',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: emailTemplateData?.template  }}
                                    />
                                        }
                                        </Box>
                                    </Box>

                                </Grid>
                                <Grid item container lg={6} md={6} sm={6} xs={6} mt={2} display={'flex'} marginLeft={'auto'} justifyContent={'end'} spacing={2}>

                                    <Grid item width={'50%'}>
                                        <Button blackCancel100 onClick={() => setRemDrawer(false)}>Cancel</Button>

                                    </Grid>
                                    <Grid item width={'50%'}>
                                        <Button blueButton onClick={handleSaveEdit}>Save</Button>

                                    </Grid>


                                </Grid>


                            </Grid>
                        </Box>
                    </Box>

                }
                {currentView == 'view' &&
                    <Box height={'100%'}>
                        <Box height={'7%'}>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text blackHeader1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>View Reminder</Text>
                                <div onClick={handleEditIcon} style={{ cursor: 'pointer' }}>
                                    <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} />
                                </div>
                            </Grid>
                        </Box>
                        <Box style={{ overflowY: 'auto' }} height={'92%'}>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box >
                                        <Text mediumLabel>
                                            Reminder Name
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {
                                                stateView.reminder_name && stateView.reminder_name.length > 16 ?
                                                    <BlackToolTip title={stateView.reminder_name} placement="top" arrow>
                                                        {stateView.reminder_name.slice(0, 16) + (stateView.reminder_name.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    : stateView.reminder_name ? stateView.reminder_name : '--'
                                            }

                                        </Text>
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Text mediumLabel>
                                            Trigger Settings
                                        </Text>
                                        <Grid container>

                                        </Grid>
                                        <Text pt={1} black400>
                                            Remind me <span style={{ color: '#1A141F', fontWeight: '800' }}>{stateView.triggerDays ? stateView.triggerDays : '--'}</span> {`day(s)`} <span style={{ color: '#1A141F', fontWeight: '800' }}>{stateView.beforeafter ? stateView.beforeafter : '--'}</span> due date
                                        </Text>
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                </Grid>
                                {clickedViewReminder.repeat_reminder &&
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box pt={2}>
                                            <Text mediumLabel>
                                                Repeat Reminder
                                            </Text>
                                            <Text pt={1} black400>
                                            Repeat every <span style={{ color: '#1A141F', fontWeight: '800' }}>{stateView.repeatDays ? stateView.repeatDays : '--'}</span> {`day(s)`} <span style={{ color: '#1A141F', fontWeight: '800' }}>{stateView.beforeafter ? stateView.beforeafter : '--'}</span> due date until invoice is paid
                                            </Text>
                                        </Box>

                                    </Grid>}
                                {clickedViewReminder.repeat_reminder &&

                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                                    </Grid>}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Text mediumLabel>
                                            Email Template
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {stateView.emailtemplate ? stateView.emailtemplate : "--"}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Text mediumLabel>
                                            To
                                        </Text>
                                        <Text pt={1} mediumBlack14>
                                            {stateView.to ? stateView.to : '--'}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2} >
                                        <Text mediumLabel>
                                            Subject
                                        </Text>
                                        <Box mt={1}>
                                        <RichTextEditor
                                            disabled={true}
                                            // readOnly
                                            // onChange={onChangeEditor}
                                            value={stateView.subject}
                                            editorClassName={classes.editorHeight}
                                            toolbarClassName={classes.toolBar}
                                            // placeholder={}
                                            // labelText={'Template'}
                                            className={`${classes.customEditorStyle} ${classes.disabledEditor}`}

                                        /> 

                                    
                                        </Box>
                                    </Box>

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box pt={2}>
                                        <Text mediumLabel>
                                            Body
                                        </Text>
                                        <Box mt={1}>

                                        {/* <RichTextEditor
                                            disabled={true}
                                            // readOnly
                                            // onChange={onChangeEditor}
                                            value={stateView.body}
                                            editorClassName={classes.editorHeight}
                                            toolbarClassName={classes.toolBar}
                                            // placeholder={}
                                            // labelText={'Template'}
                                            className={`${classes.customEditorStyle} ${classes.disabledEditor}`}

                                        />  */}
                                        
                                        <div
                                    style={{
                                        minHeight: '150px',
                                        overflowY: 'auto',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: stateView?.body }}
                                />
                                        </Box>
                                    </Box>

                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                }
            </Box>
        </div>
    )

}

export default ReminderDrawer