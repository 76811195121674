import React, { useState } from 'react'
import { Grid, Skeleton } from '@mui/material';
import Text from '../../../components/customText/Text';
import moment from 'moment';
import { dateFormat } from '../../../utils/utils';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';
import { useEffect } from 'react';


function TimesheetConfiguration(props) {

  const placementID = props.id ? props.id : "";
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    default_hours: "",
    timesheet_cycle: "",
    is_timesheet_attachment_mandatory: false,
    timesheet_start_date: "",
    timesheet_start_day: "",
    timesheet_next_cycle_start: "",
    approval_levels: []
  });

  useEffect(() => {
    getPlacementTimesheetData(placementID)// eslint-disable-next-line
  }, []);


  const getPlacementTimesheetData = (id) => {
    setLoading(true);
    PlacementApi.getPlacementIndexConsultant("timesheet-details", id).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 1003) {
        res.data.data.timesheet_details['deleted_level_ids'] = [];
        res.data.data.timesheet_details['deleted_approver_ids'] = [];
        // res.data.data.timesheet_details['timesheet_next_cycle_start'] = res.data.data.timesheet_details
        setState({ ...res.data.data.timesheet_details });
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  return (
    <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 2 }} pt={0} spacing={2} alignItems={"center"}>
      <Grid item lg={12} md={12} sm={12} xs={12} >
        <Text largeBlue>Timesheet Configuration</Text>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}  pt={4}>
        {
          loading ?
            <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
            :
            <>
              <Text mediumGreyHeader1>Timesheet Cycle</Text>
              <Text mediumBlack14 pt={0.8}>{state.timesheet_cycle ? state.timesheet_cycle : "--"}</Text>
            </>
        }
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}  pt={4}>
        {
          loading ?
            <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
            :
            <>
              <Text mediumGreyHeader1>Default Hours</Text>
              <Text mediumBlack14 pt={0.8}>{state.default_hours ? state.default_hours : "--"}</Text>
            </>
        }
      </Grid>
      {
        loading ?
          <Grid item lg={4} md={4} sm={6} xs={12}  pt={4}>
            <Skeleton variant='rounded' animation="wave" width={"100%"} height={40} />
          </Grid>
          :
          <>
            {
              state.timesheet_next_cycle_start !== "" ?
                <Grid item lg={4} md={4} sm={6} xs={12}  pt={4}>
                  <Text mediumGreyHeader1>Effective Start Date From </Text>
                  <Text mediumBlack14 pt={0.8}>{state.timesheet_next_cycle_start ? moment(state.timesheet_next_cycle_start, dateFormat()).format(dateFormat()) : "--"}</Text>
                </Grid> : ""
            }
          </>
      }
    </Grid>
  )
}

export default TimesheetConfiguration