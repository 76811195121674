import React from 'react';
import LocalStorage from '../../utils/LocalStorage';
import Text from '../customText/Text';
import { MenuItem } from '@mui/material';


const EmployeeDisableActions = ({ employee, rolePermission, handleStatus, classes }) => {
    const isAuthorized = LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug === "employee_edit" && item.is_allowed === true));

    const Item = ({ text, onClick, className }) => (
        <MenuItem
            className={isAuthorized ? className : classes.inactiveDisable}
            onClick={isAuthorized ? onClick : undefined}
            sx={{ borderBottom: 'none !important' }}

        >
            <Text smallBlackBold>{text}</Text>
        </MenuItem>
    );

    return (
        <Item
            text={`${employee.employment_details.enable_login === 1 ? 'Disable' : 'Enable'} User Access`}
            onClick={() => handleStatus(1)}
            className={classes.viewText}
        />
    );
};

export default EmployeeDisableActions;