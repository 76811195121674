import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    IconButton,
    Typography,
    Box,
    SwipeableDrawer,
    Stack,
    Grid,
} from "@mui/material";
import EditIcon from '../../../assets/svg/newEdit.svg'
import DeleteIcon from '../../../assets/employee/deletewithBg.svg'
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "../../../components/customButton/Button";
import Input from "../../../components/input/Input";
import Text from "../../../components/customText/Text";
import { isValid, onNumberWithTwoDecimalOnlyChange, onNumberWithTwoDecimalOnlyChangeCurrency, validate_charWithSpace, validate_emptyField, validate_taskRegex } from "../../../components/Validation";
import { addErrorMsg, capitalize } from "../../../utils/utils";
import { ErrorMessages } from "../../../utils/ErrorMessages";
import LocalStorage from "../../../utils/LocalStorage";

const EmployeeTaskTable = (props) => {
    const { state, setState, setOpen, setSelectedTab, clientDetailsAPI } = props
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    const [openDetails, setOpenDetails] = useState(false)
    const [taskOpen, setTaskOpen] = useState(false)
    const [itemData, setItemData] = useState({
        id: '',
        name: '',
        employee_id: '',
        description: '',
        hours: 0,
        bill_rate: 0,
        ot_hours: 0,
        ot_bill_rate: 0,
        amount: 0,
        timesheet_ids: [],
        timesheet_hour_ids: []
    })
    const [error, setError] = useState({})
    const deleteIndex = (row) => {
        let filterData = state.item_details.filter((i) => i.id != row.id);

        let arr = [...state.deleted_item_dettail_ids]
        arr.push(row.item_id)
        if (row.item_id) {
            state.deleted_item_dettail_ids = arr
        } else {
            state.deleted_item_dettail_ids = []
        }
        setState({
            ...state,
            item_details: filterData
        })
    }

    const validateAllErrors = () => {
        let { hours, name, bill_rate, amount } = itemData;
        let errors = {};
        errors.name = itemData.employee_id == '' ? validate_taskRegex(name) : validate_charWithSpace(name)
        errors.hours = taskOpen || itemData.employee_id == '' ? '' : validate_emptyField(hours);
        errors.bill_rate = taskOpen || itemData.employee_id == '' ? '' : validate_emptyField(bill_rate);
        errors.amount = validate_emptyField(amount);
        setError({ ...errors })
        return errors;
    }

    const submit = () => {
        let errors = validateAllErrors()
        if (isValid(errors)) {
            let array = [...state.item_details]
            let findData = array.findIndex((i) => i.id == itemData.id)
            if (findData == 0 || (findData && findData > 0)) {
                state.item_details[findData] = itemData
                setState({
                    ...state
                })
                setTaskOpen(false);
                setOpenDetails(false)
                let total = state.item_details.length > 0
                    ? state.item_details.reduce((prev, next) => prev + Number(next['amount']), 0)
                    : 0;

                const adjustment = Number(state.adjustment_amount) || 0; // Ensure adjustment is a number
                const finalTotal = state.adjustment_type == 1 ? total + adjustment : state.adjustment_type == 2 ? total - adjustment : total;
                let discount = state.discount_type == 1 ? total - ((total * state.discount_value) / 100) : state.discount_value

                setState(prevState => ({
                    ...prevState,
                    sub_total_amount: total == '' ? 0 : total.toFixed(3),
                    discount_amount: state.discount_value == '' ? 0 : state.discount_type == 1 ? total - discount : state.discount_value,
                    total_amount: finalTotal == '' ? 0 : state.discount_value == '' ? finalTotal.toFixed(3) : state.discount_type == 1 ? finalTotal.toFixed(3) - (total - discount) : finalTotal.toFixed(3) - state.discount_value
                }));
            }
            else {
                itemData.id = state.item_details.length + 1
                array.push(itemData)
                setState({
                    ...state,
                    item_details: array
                })
                setTaskOpen(false);
                setOpenDetails(false)
                let total = state.item_details.length > 0
                    ? state.item_details.reduce((prev, next) => prev + Number(next['amount']), 0)
                    : 0;

                const adjustment = Number(state.adjustment_amount) || 0; // Ensure adjustment is a number
                const finalTotal = state.adjustment_type == 1 ? total + adjustment : state.adjustment_type == 2 ? total - adjustment : total;
                let discount = state.discount_type == 1 ? total - ((total * state.discount_value) / 100) : state.discount_value

                setState(prevState => ({
                    ...prevState,
                    sub_total_amount: total == '' ? 0 : total.toFixed(3),
                    discount_amount: state.discount_value == '' ? 0 : state.discount_type == 1 ? total - discount : state.discount_value,
                    total_amount: finalTotal == '' ? 0 : state.discount_value == '' ? finalTotal.toFixed(3) : state.discount_type == 1 ? finalTotal.toFixed(3) - (total - discount) : finalTotal.toFixed(2) - state.discount_value
                }));
            }

        }
        else {
            addErrorMsg(ErrorMessages.genericMessage)
        }
    }

    const handleValidate = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name':
                error.name = itemData.employee_id == '' ? validate_taskRegex(value) : validate_charWithSpace(value)
                break
            case 'hours':
                error.hours = !taskOpen || itemData.employee_id != '' ? validate_emptyField(value) : ''
                break
            case 'amount':
                error.amount = validate_emptyField(value)
                break
            case 'bill_rate':
                error.bill_rate = !taskOpen || itemData.employee_id != '' ? validate_emptyField(value) : ''
                break
            default:
                break
        }
        setError({ ...error })
    }

    const onChange = (e) => {
        const { name, value } = e.target;

        if (name == 'hours' || name == 'ot_hours') {
            let value1 = e.target.value.trim();
            if (value1 == "") {
                itemData[e.target.name] = "";
                setItemData({ ...itemData }, handleValidate(e));
                return;
            }
            const regex = /^(\d{1,10}|\d{1,10}\.\d{0,3})$/;
            const numericValue1 = parseFloat(value1);
            let newAmount = itemData.amount
            if (regex.test(value1) && (!isNaN(numericValue1) && numericValue1 <= 999.999)) {
                itemData[e.target.name] = value1;
                if (!taskOpen && itemData.employee_id != '') {
                    const numericValue = Number(value1);
                    if (name === "hours" || name === "bill_rate" || name === "ot_hours" || name === "ot_bill_rate") {
                        if (!isNaN(numericValue)) {
                            if (name === "hours") {
                                newAmount = (itemData.ot_hours * itemData.ot_bill_rate) + (itemData.bill_rate * numericValue);
                            } else if (name === "bill_rate") {
                                newAmount = (itemData.ot_hours * itemData.ot_bill_rate) + (itemData.hours * numericValue);
                            } else if (name === "ot_hours") {
                                newAmount = (numericValue * itemData.ot_bill_rate) + (itemData.hours * itemData.bill_rate);
                            } else if (name === "ot_bill_rate") {
                                newAmount = (itemData.ot_hours * numericValue) + (itemData.hours * itemData.bill_rate);
                            }
                        }
                    }
                }
            } else {
                return false;
            }
            setItemData({ ...itemData, amount: newAmount != '' ? Number(newAmount).toFixed(3) : '' }, handleValidate(e));
        }
        else if (name == 'bill_rate' || name == 'ot_bill_rate') {
            let value1 = e.target.value.trim();
            if (value1 == currency || value1 == "") {
                itemData[e.target.name] = "";
                setItemData({ ...itemData }, handleValidate(e));
                return;
            }
            value1 = value1.replace(currency, "");
            const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,3})$/;
            const numericValue2 = parseFloat(value1);
            let newAmount = itemData.amount
            if (regex.test(value1) && (!isNaN(numericValue2) && numericValue2 <= 9999.999)) {
                itemData[e.target.name] = value1;
                if (!taskOpen && itemData.employee_id != '') {
                    const numericValue = Number(value1);
                    if (name === "hours" || name === "bill_rate" || name === "ot_hours" || name === "ot_bill_rate") {
                        if (!isNaN(numericValue)) {
                            if (name === "hours") {
                                newAmount = (itemData.ot_hours * itemData.ot_bill_rate) + (itemData.bill_rate * numericValue);
                            } else if (name === "bill_rate") {
                                newAmount = (itemData.ot_hours * itemData.ot_bill_rate) + (itemData.hours * numericValue);
                            } else if (name === "ot_hours") {
                                newAmount = (numericValue * itemData.ot_bill_rate) + (itemData.hours * itemData.bill_rate);
                            } else if (name === "ot_bill_rate") {
                                newAmount = (itemData.ot_hours * numericValue) + (itemData.hours * itemData.bill_rate);
                            }
                        }
                    }
                }
            } else {
                return false;
            }
            setItemData({ ...itemData, amount: newAmount != '' ? Number(newAmount).toFixed(3) : '' }, handleValidate(e));
        }
        else if (name == 'amount') {
            let value1 = e.target.value.trim();
            if (value1 == currency || value1 == "") {
                itemData[e.target.name] = "";
                setItemData({ ...itemData }, handleValidate(e));
                return;
            }
            value1 = value1.replace(currency, "");
            const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,3})$/;
            const numericValue = parseFloat(value1);

            if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.999)) {
                itemData[e.target.name] = value1;
            } else {
                return false;
            }
            setItemData({ ...itemData }, handleValidate(e));
        } else if(name == 'name') {
            setItemData({
                ...itemData,
                [name]: capitalize(value),
            }, handleValidate(e));
        }
        else {
            setItemData({
                ...itemData,
                // amount: newAmount,
                [name]: value,
            }, handleValidate(e));
        }


    }


    return (
        <Box sx={{ margin: 0 }}>
            <TableContainer sx={{ borderRadius: 3, overflowX: 'scroll' }}>
                <Table>
                    <TableHead sx={{ background: '#EAECF0' }}>
                        <TableRow>
                            <TableCell><strong>Employee / Task</strong></TableCell>
                            <TableCell><strong>Details</strong></TableCell>
                            <TableCell><strong>Hours</strong></TableCell>
                            <TableCell><strong>Bill Rate ($)</strong></TableCell>
                            <TableCell><strong>OT Hours</strong></TableCell>
                            <TableCell><strong>OT Bill Rate ($)</strong></TableCell>
                            <TableCell><strong>Amount ($)</strong></TableCell>
                            <TableCell align="center"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.item_details.length == 0 ?
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell>
                                        No data
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                                :
                                state.item_details.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell style={{ maxWidth: '350px', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                            <Box display="flex" alignItems="center">
                                                {row.profile_picture_url ? (
                                                    <Avatar src={row.profile_picture_url} alt={row.name} />
                                                ) : (
                                                    <Avatar sx={{ bgcolor: "#e0e0e0" }}>
                                                        <ListAltIcon />
                                                    </Avatar>
                                                )}
                                                <Typography sx={{ ml: 2 }}>
                                                    {row.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{ maxWidth: '350px', wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{row.description ? row.description : '-'}</TableCell>
                                        <TableCell>{row.hours}</TableCell>
                                        <TableCell>$ {row.bill_rate}</TableCell>
                                        <TableCell>{row.ot_hours != '' ? row.ot_hours : '0'}</TableCell>
                                        <TableCell>$ {row.ot_bill_rate != '' ? row.ot_bill_rate : '0'}</TableCell>
                                        <TableCell>$ {row.amount}</TableCell>
                                        <TableCell align="center">
                                            <IconButton color="primary">
                                                <img src={EditIcon} alt="edit" onClick={() => { setTaskOpen(false); setOpenDetails(true); setItemData({ ...row }) }} />
                                            </IconButton>
                                            <IconButton color="error">
                                                <img src={DeleteIcon} onClick={() => { deleteIndex(row) }} alt="delete" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" mt={-2} sx={{ mt: { xs: 2, sm: -2 } }}>
                <Button fullWidth sx={{
                    borderWidth: '0px 0px 1px 1px',
                    borderStyle: 'solid',
                    borderColor: "#EAECF0"
                }} onClick={() => {
                    setTaskOpen(true); setOpenDetails(true); setItemData({
                        id: '',
                        name: '',
                        employee_id: '',
                        description: '',
                        hours: '',
                        bill_rate: '',
                        ot_hours: '',
                        ot_bill_rate: '',
                        amount: '',
                        timesheet_ids: [],
                        timesheet_hour_ids: []
                    })
                }} >
                    + Add Task
                </Button>
                {
                    clientDetailsAPI.client_details.name != '' &&
                    <Button fullWidth
                        onClick={() => { setOpen(true); setSelectedTab(1) }}
                        sx={{
                            borderWidth: '0px 1px 1px 1px',
                            borderStyle: 'solid',
                            borderColor: "#EAECF0"
                        }}>
                        + Add Employee
                    </Button>
                }

            </Box>
            <SwipeableDrawer
                anchor={'right'}
                open={openDetails}
                onClose={() => { setOpenDetails(false); setError({}) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                        width: { xs: '90% !important', sm: '65% !important', md: '55% !important' },
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <Box p={4}>
                    <Grid item container spacing={3}>
                        <Grid item lg={12} container pb={2}>
                            <Text largeBlack>{taskOpen ? 'Add Task' : 'Edit'} Details</Text>
                        </Grid>
                        {
                            (taskOpen || itemData.employee_id == '') ?
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'name',
                                            inputProps: { maxLength: 50 },
                                            value: itemData.name || ''
                                        }}
                                        clientInput
                                        labelText='Task Name'
                                        handleChange={onChange}
                                        error={error.name}
                                    />
                                    <Text errorText>{error.name}</Text>
                                </Grid>
                                :
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack direction={'row'} sx={{ background: '#FAFAFA' }} gap={2} alignContent={'center'} p={1}>
                                        <Avatar src={itemData?.profile_picture_url} alt={itemData?.name} />
                                        <Text black14 sx={{ pt: 1.2 }}>{itemData?.name}</Text>
                                    </Stack>
                                </Grid>
                        }
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'hours',
                                    value: itemData.hours || ''
                                }}
                                clientInput
                                labelText={itemData.employee_id == '' ? 'Hours (Optional)' : 'Hours'}
                                onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                handleChange={onChange}
                                error={error.hours}
                            />
                            <Text errorText>{error.hours}</Text>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'bill_rate',
                                    value: currency + '' + itemData.bill_rate || ''
                                }}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                clientInput
                                labelText={itemData.employee_id == '' ? 'Bill Rate (Optional)' : 'Bill Rate'}
                                handleChange={onChange}
                                error={error.bill_rate}
                            />
                            <Text errorText>{error.bill_rate}</Text>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'ot_hours',
                                    value: itemData.ot_hours || ''
                                }}
                                clientInput
                                labelText={itemData.employee_id == '' ? 'OT Hours (Optional)' : "OT Hours"}
                                onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                handleChange={onChange}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'ot_bill_rate',
                                    value: currency + '' + itemData.ot_bill_rate || ''
                                }}
                                clientInput
                                labelText={itemData.employee_id == '' ? 'OT Bill Rate (Optional)' : 'OT Bill Rate'}
                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                handleChange={onChange}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'amount',
                                    value: currency + '' + itemData.amount || ''
                                }}
                                clientInput
                                disabled={!taskOpen && itemData.employee_id != ''}
                                labelText='Amount'
                                handleChange={onChange}
                                error={error.amount}
                            />
                            <Text errorText>{error.amount}</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'description',
                                    value: itemData.description || ''
                                }}
                                clientInput
                                labelText='Details'
                                handleChange={onChange}
                            />
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'flex-end'} mt={2}>
                            <Stack direction={'row'} gap={2}>
                                <Button blueboldCancel onClick={() => { setOpenDetails(false); setError({}) }}>
                                    Cancel
                                </Button>
                                <Button blueBtnSave sx={{ width: '88px !important', height: "42px !important", }} onClick={() => { submit() }} >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </SwipeableDrawer>
        </Box >
    );
};

export default EmployeeTaskTable;
