import { Box, Grid, Hidden } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { ForgotPasswordStyles } from "./ForgotPasswordStyles";
import Text from "../../components/customText/Text";
import CustomInput from '../../components/input/Input';
import { isValid, validate_input_fields } from "../../components/Validation";
import ReusablePopup from "../../components/reuablePopup/ReusablePopup";
import { domain } from "../../config/Domain";
import LocalStorage from "../../utils/LocalStorage";
import CommonApi from "../../apis/CommonApi";
import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
import OTP from "../otp/OTP";
import { LoadingButton } from "@mui/lab";
import Logo from '../../assets/logo.svg';
// import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import logo from '../../assets/svg/logoName.svg'
import attempts from '../../assets/svg/attempts.svg'
import { forgotPasswordDefaultConfig } from "../../config/pageConfig/publicConfig";

/*fun starts here*/
function ForgotPassword() {
  // const navigate = useNavigate()
  
  const classes = ForgotPasswordStyles();
  const [state, setState] = useState({
    emailId: "",
  });
  const location = useLocation()
  const [error, setError] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState('create')
  const [loader, setLoader] = useState(false);
  /*handle change fun*/
  const changeHandler = (e) => {
    setState(
      {
        ...state,
        [e.target.name]: e.target.value,
      },
      handlevalidate(e)
    );
  };

  const handlevalidate = (e) => {
    let input = e.target;
    let err = { ...error };
    switch (input.name || input.tagName) {
      case "emailId":
        error.emailId = validate_input_fields(input.value, forgotPasswordConfig?.emailId);
        break;
      default:
        break;
    }
    setError(err);
  };

  /*email validation */
  const emailValidations = () => {
    let { emailId } = state;
    let errors = {};
    errors.emailId = (forgotPasswordConfig?.emailId?.isVisible && (!forgotPasswordConfig?.emailId?.isDisabled)) ? validate_input_fields(emailId, forgotPasswordConfig?.emailId) : '';
    return errors;
  };

  /*submit fun*/
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    let domainName = location.state
    const data = {
      request_id: LocalStorage.uid(),
      email_id: state.emailId,
      subdomain_name: domainName != null ? domainName.domain : domain,
    };
    let errors = emailValidations();
    if (isValid(errors)) {
      CommonApi.ForgotPassword(data)
        .then((response) => {
          if (response.data.statusCode == 1003) {
            // setOpenPopup(true);
            addSuccessMsg("Email sent"); //success msg Alert
            setLoader(false)
            setStatus('read')
          } else {
            if (response.data.message == 'Reset Password Link can be sent only 3 times within a 24-hour period.') {
              setStatus('fail')
            }
            else {
              addErrorMsg(response.data.message);
            }
            setLoader(false)
            // warning msg Alert
          }
        })
    } else {
      setLoader(false)
      let err = { error };
      err = errors;
      setError(err);
    }
  };

  const ImagesList = [
    Image1,
    Image2,
    // Image3,
    Image4
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [forgotPasswordConfig, setForgotPasswordConfig] = useState(forgotPasswordDefaultConfig);

  useEffect(() => {

    const data = {
      page_name:"forgot-password",
      page_type:"update"
    }
    async function getPageData() {
      const response = await CommonApi.fetchPageConfig(data) 
      if(response.status === 200){
        setForgotPasswordConfig(response?.data?.data?.page_data)
      }else{
        setForgotPasswordConfig(forgotPasswordDefaultConfig)
      }
    } 
    getPageData()
    // Set an interval to update the current image every 3 seconds
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % ImagesList.length);
    }, 3000);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

 


  return (
    <div>
      {
        status == 'fail' ?
          <Grid container>
            <Grid item xs={12}>
              <img src={logo} alt='logo' />
            </Grid>
            <Grid item xs={12}>
              <Box height={'15vh'} />
            </Grid>
            <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
              <img src={attempts} alt='attempts' />
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box p={1} pt={4}>
                <Text popupHead1 sx={{ color: '#090914 !important' }}>Out of attempts!</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box p={1} pb={0}>
                <Text grey16>It looks like you've exceeded the verification limit.</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>Please wait for 24hrs or provide a detailed explanation</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>of the problem in E-mail so that our sales staff can </Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>respond asap.</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'} mt={1}>
              <Box className={classes.buttonStyles}>
                <LoadingButton
                  className={classes.loginButton1}
                  loading={loader}
                  variant="contained"
                  type="submit"
                  href={'mailto:support@japfu.ai'}
                >
                  Send E-mail
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          :
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12} sx={{ height: '100vh'}}>
            <Hidden mdDown>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ height: '100vh', overflow: 'hidden' }}>
                <img
                  src={ImagesList[currentImage]}
                  alt={`img-${currentImage}`}
                  style={{
                    width: '100%',  // Ensures the image takes the full width of its container
                    height: '100%', // Ensures the image's height adjusts based on the parent (grid item)
                    objectFit: 'cover', // Maintain aspect ratio and fill the grid item without distortion
                  }}
                />
              </Grid>
            </Hidden>
            
            <Grid item container xl={6} lg={6} md={6} sm={12} xs={12}  justifyContent={'center'} alignContent={'center'}>
              {
                status == 'create' ?
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid item lg={12} md={12} sm={12} xs={12} height={'95%'} justifyContent={'center'} alignContent={'center'}>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text popupHead1>Forgot Password</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pb={0} sx={{textAlign:'center' ,width:'60%'}}>
                        <Text grey16>Please provide your registered email address to change password.</Text>
                      </Box>
                    </Grid>
                    {/* <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text grey16>change password.</Text>
                      </Box>
                    </Grid> */}
                    {forgotPasswordConfig?.emailId?.isVisible &&
                    <Grid item sm={12} xs={12} pt={2}>
                      <Box p={1}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "emailId",
                            value: state.emailId,
                          }}
                          disabled={forgotPasswordConfig?.emailId?.isDisabled}
                          clientInput
                          placeholder={forgotPasswordConfig?.emailId?.placeholder}
                          handleChange={changeHandler}
                          labelText={forgotPasswordConfig?.emailId?.required ? forgotPasswordConfig?.emailId?.label : forgotPasswordConfig?.emailId?.label + " (Optional)"}
                          // labelText={'Email Address'}
                          error={error.emailId}
                        />
                        {error.emailId ? <Text red>{error.emailId}</Text> : ""}
                      </Box>

                    </Grid> }
                    <Grid item sm={12} xs={12} p={1}>
                      <Box className={classes.buttonStyles}>
                        <LoadingButton
                          className={classes.loginButton}
                          loading={loader}
                          variant="contained"
                          type="submit"
                          onClick={(e) => { handleSubmit(e) }}
                          fullWidth
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Grid>

                    </Grid>
                    
                  </form>
                  :

                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12} height={'95%'} justifyContent={'center'} alignContent={'center'}>
                      <form>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text popupHead1>Forgot Password</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pb={0}>
                        <Text grey16>We have sent an E-mail to your registered E-mail Id to</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={0}>
                        <Text grey16>change your password , You can change your</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text grey16>password from there.</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text mediumBoldGrey>{state.emailId}</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text grey16 sx={{ color: '#AEAEAE !important' }}>Haven’t received the  mail? <span onClick={(e) => { handleSubmit(e) }} style={{ color: '#0C75EB', cursor: 'pointer' }}>Resend</span></Text>
                      </Box>
                    </Grid>
                    </form>
                    </Grid>
                    

                    <Grid item container xs={12} sm={12} lg={12} md={12} justifyContent={'center'} alignContent={'center'} height={'5%'}>
                    
                    
                   
                     
                        <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}><span onClick={() => { setStatus('create') }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Change E-mail ID</span>
                          <span style={{
                            height: '6px',
                            width: '6px',
                            borderRadius: '50%',
                            background: '#D9D9D9',
                            display: 'flex',
                            margin: '8px 5px 2px 0px'
                          }}> </span>
                          <a href={'https://www.japfu.ai/contact'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Us</a> </Text>
                     
                    </Grid>
                    
                  </>
              }

            </Grid>
          </Grid>
      }
      <ReusablePopup
        title="Enter OTP"
        dialogTitleSmaller
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OTP email={state.emailId} />
      </ReusablePopup>

    </div>
  );
}

export default ForgotPassword;

