import React, { useEffect, useState } from 'react'
import GeneratePayrollStyles from './GeneratePayrollStyles'
import { Box, Breadcrumbs, Checkbox, Chip, Divider, FormControlLabel, Grid, List, ListItem, ListItemText, SwipeableDrawer, Typography, Badge, Skeleton, Stack } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Calendar from '../../../../assets/svg/calendar.svg';
import CalanderOrange from '../../../../assets/svg/calendar_orange.svg';
import CalanderBlack from '../../../../assets/svg/calendar_black.svg';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import infoCircle from '../../../../assets/images/info-circle.png';
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PayrollTable from '../payrollTable/PayrollTable';
import Button from '../../../../components/customButton/Button';
import { addErrorMsg, BlackToolTip, addLoader, removeLoader, downloadExcel, NoPermission, NoDataFound } from '../../../../utils/utils';
import warning from '../../../../assets/employee/info.svg';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import CommonApi from '../../../../apis/CommonApi';
import CustomSlider from '../../../../components/sliders/CustomSlider';
import GeneratePayrollApi from '../../../../apis/configurations/generatePayroll/GeneratePayrollApi';

import moment from 'moment';
import LocalStorage from '../../../../utils/LocalStorage';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/input/Input';
import {  onNumberWithTwoDecimalOnlyChangeCurrency, onNumberWithTwoDecimalOnlyChangeWithNegative } from '../../../../components/Validation';
import DashboardApi from '../../../../apis/admin/payroll/DashboardApi';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));


function GeneratePayroll() {

    const classes = GeneratePayrollStyles();
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const navigate = useNavigate();
    const [drawer, setDrawer] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);// eslint-disable-next-line
    const [tableData, setTabledata] = useState([]);
    const [payrollData, setpayrollData] = useState({})
    const [clientDropdown, setClientDropdown] = useState([]);
    const [visaTypeDropdown, setVisaTypeDropdown] = useState([]);// eslint-disable-next-line
    const [tableDataLoading, setTableDataLoading] = useState(false);
    const [payrollConfigId, setPayRollConfigId] = useState('');
    const [reimbursementPayPeriodDropDown, setReimbursementPayPeriodDropDown] = useState([]);
    const [reimbursementTypeDropDown, setReimbursementTypeDropDown] = useState([]);
    const [deductionTypeDropDown, setDeductionTypeDropDown] = useState([]);


    
    const [filterData, setFilterData] = useState({
        search: "",
        earned_amount_from: "",
        earned_amount_to: "",
        salary_amount_from: "",
        salary_amount_to: "",
        balance_amount_from: "",
        balance_amount_to: "",
        deduction_amount_from: "",
        deduction_amount_to: "",
        reimbursement_amount_from: "",
        reimbursement_amount_to: "",
        clients: [],
        visa: [],
    })

    const [filterState, setFilterState] = useState({
        search: "",
        earned_amount_from: "",
        earned_amount_to: "",
        salary_amount_from: "",
        salary_amount_to: "",
        balance_amount_from: "",
        balance_amount_to: "",
        deduction_amount_from: "",
        deduction_amount_to: "",
        reimbursement_amount_from: "",
        reimbursement_amount_to: "",
        clients: [],
        visa: [],
    })

    const [filterAmount, setFilterAmount] = useState({
        max:0,min:0
    })




    useEffect(() => {
        clientDropdownApi();
        getVisaTypeDropdown();
        getPayrollConfigId();
        getReimbursementPayPeriodDropdown();
        getReimbursementTypeDropdown();
        getDeductionTypeDropdown();
        // eslint-disable-next-line
    }, [])


    const getReimbursementPayPeriodDropdown = () => {
        GeneratePayrollApi.ReimbursementPayPeriodDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementPayPeriodDropDown(res.data.data);
            }
        })
    }

    const getReimbursementTypeDropdown = () => {
        GeneratePayrollApi.ReimbursementTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setReimbursementTypeDropDown(res.data.data);
            }
        })
    }

    const getDeductionTypeDropdown = () => {
        GeneratePayrollApi.DeductionTypeDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductionTypeDropDown(res.data.data);
            }
        })
    }


    const getPayrollConfigId = () => {
        DashboardApi.getupcomingPayroll().then((res) => {
            if (res.data.statusCode === 1003) {
                setPayRollConfigId(res.data.data?.pay_period?.id);
                setTimeout(() => {
                    getAllEmployees(res.data.data?.pay_period?.id);
                }, 500);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const getAllEmployees = (payrollId) => {
        setTableDataLoading(true);
        GeneratePayrollApi.getAllEmployees(filterState, payrollId).then((res) => {
            removeLoader()
            if (res.data.statusCode === 1003) {
                setDrawer(null);

                setTimeout(() => {
                    setTableDataLoading(false);
                    setTabledata(res.data?.data);
                    setpayrollData(res.data?.payroll_data);
                    setFilterAmount(res.data?.filters)
                    setFilterAmount((prevState)=>({...prevState,max:res.data?.filters?.current_balance?.max,min:res.data?.filters?.current_balance?.min}))

                }, 500);
            } else {
                setTableDataLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleDownloadExport = (param) => {
        GeneratePayrollApi.exportGeneratepayroll(filterState, payrollConfigId).then((response) => {
            if (response.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `GeneratePayroll_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }

        })
    }

    const clientDropdownApi = () => {
        ClientsApi.dropdown('client', "").then((res) => {
            if (res.data.statusCode === 1003) {
                setClientDropdown(res.data.data);
            }
        })
    }

    const getVisaTypeDropdown = () => {
        let search = "";
        CommonApi.VisaTypeDropdown(search).then((response) => {
            if (response.data.statusCode == 1003) {
                setVisaTypeDropdown(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const handleChangeCheckBoxStr = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }
        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleChangeCheckBoxInt = (e) => {
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        const index = updatedArray.findIndex(item => item === parseInt(value));
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(parseInt(value)); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleChangeAmount = (name, value) => {
        setFilterState(prevState => ({
            ...prevState,    // Spread the previous state
            [`${name}_from`]: value[0],  // Update the specific field
            [`${name}_to`]: value[1],
        }));
    }



    const handleSearch = (e) => {
        filterState.search = e.target.value;
        setFilterState({ ...filterState });
        getAllEmployees(payrollConfigId);
    }

    const handleSearchClear = () => {
        filterState.search = "";
        setFilterState({ ...filterState });
        getAllEmployees(payrollConfigId);

    }


    const cancelFilter = () => {
        setDrawer(false)
    }

    // const handleApplyFilters = async () => {
    //     setDrawer(false)
    //     addLoader()
    //     getAllEmployees(payrollConfigId)
    // }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    // const handlePayrollPreview = () => {
    //     setPreviewOpen(true);
    // }


    const handleDeleteChip = (id, name) => {
        if (name === "earned_amount" || name === "salary_amount" || name === "deduction_amount" || name === "reimbursement_amount" || name === "balance_amount") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[`${name}_from`] = "";
            updatedFilterState[`${name}_to`] = "";

            // Update the state with the modified filterState object
            setFilterState({ ...updatedFilterState });
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;

            setFilterState({ ...newFilterState });
        }


    };



    const clearAllFilter = () => {
        setFilterState(prevState => ({
            ...prevState,
            earned_amount_from: "",
            earned_amount_to: "",
            salary_amount_from: "",
            salary_amount_to: "",
            balance_amount_from: "",
            balance_amount_to: "",
            deduction_amount_from: "",
            deduction_amount_to: "",
            reimbursement_amount_from: "",
            reimbursement_amount_to: "",
            clients: [],
            visa: [],
        }));
    }



    const handleApplyFilters = () => {


        if (filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") {
            if (filterState.salary_amount_from < filterState.salary_amount_to) {
                filterData.salary_amount_from = filterState.salary_amount_from;
                filterData.salary_amount_to = filterState.salary_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.salary_amount_from = '';
            filterData.salary_amount_to = '';
        }

        if (filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") {
            if (filterState.earned_amount_from < filterState.earned_amount_to) {
                filterData.earned_amount_from = filterState.earned_amount_from;
                filterData.earned_amount_to = filterState.earned_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.earned_amount_from = '';
            filterData.earned_amount_to = '';
        }
        if (filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") {
            if (filterState.balance_amount_from < filterState.balance_amount_to) {
                filterData.balance_amount_from = filterState.balance_amount_from;
                filterData.balance_amount_to = filterState.balance_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.balance_amount_from = '';
            filterData.balance_amount_to = '';
        }

        if (filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") {
            if (filterState.deduction_amount_from < filterState.deduction_amount_to) {
                filterData.deduction_amount_from = filterState.deduction_amount_from;
                filterData.deduction_amount_to = filterState.deduction_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.deduction_amount_from = '';
            filterData.deduction_amount_to = '';
        }

        if (filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") {
            if (filterState.reimbursement_amount_from < filterState.reimbursement_amount_to) {
                filterData.reimbursement_amount_from = filterState.reimbursement_amount_from;
                filterData.reimbursement_amount_to = filterState.reimbursement_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.reimbursement_amount_from = '';
            filterData.reimbursement_amount_to = '';
        }

        filterData.visa = filterState.visa;
        filterData.clients = filterState.clients;
        setFilterData({ ...filterData });
        addLoader(true)
        getAllEmployees(payrollConfigId);
    }

    const handleOpenDrawer = () => {
        setDrawer('filter')
       
        if (filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "") {
            filterState.earned_amount_from = filterData.earned_amount_from;
            filterState.earned_amount_to = filterData.earned_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.earned_amount_from = '';
            filterState.earned_amount_to = '';
        }

        if (filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "") {
            filterState.salary_amount_from = filterData.salary_amount_from;
            filterState.salary_amount_to = filterData.salary_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.salary_amount_from = '';
            filterState.salary_amount_to = '';
        }

        if (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") {
            filterState.balance_amount_from = filterData.balance_amount_from;
            filterState.balance_amount_to = filterData.balance_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.balance_amount_from = '';
            filterState.balance_amount_to = '';
        }

        if (filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "") {
            filterState.deduction_amount_from = filterData.deduction_amount_from;
            filterState.deduction_amount_to = filterData.deduction_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.deduction_amount_from = '';
            filterState.deduction_amount_to = '';
        }

        if (filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "") {
            filterState.reimbursement_amount_from = filterData.reimbursement_amount_from;
            filterState.reimbursement_amount_to = filterData.reimbursement_amount_to;
            // setSelectedFilter(2);
        } else {
            filterState.reimbursement_amount_from = '';
            filterState.reimbursement_amount_to = '';
        }



        filterState.clients = filterData.clients
        filterState.visa = filterData.visa
        setFilterState({ ...filterState });
    }

    const handleInputChange = (e) => {

        let value1 = e.target.value.trim();
        if (value1 == "$" || value1 == "") {
            filterState[e.target.name] = "";
            setFilterState({ ...filterState });
            return;
        }
        value1 = value1.replace(currency, "");
        const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,3})$/;
        const numericValue = parseFloat(value1);
        if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
            filterState[e.target.name] = value1;
        } else {
            return false;
        }
        setFilterState({ ...filterState });
    };

    const handleInputChangeNegative = (index, value, name, type) => {
        let numericValue;
        const limitValue = type === "min"
            ? filterAmount.min
            : filterAmount.max;

        const maxValue = filterAmount.max;

        if (value === '-' || value === '' || value.endsWith('.')) {
            numericValue = value;
        } else if (!isNaN(Number(value))) {
            numericValue = Number(value);

            if (type === "min" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "min" && numericValue < limitValue) {
                numericValue = Math.max(Math.floor(numericValue / 10), limitValue); // Ensure it stays above minValue
            }
            if (type === "max" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            } 
            else if (type === "max" && numericValue > limitValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), limitValue); // Ensure it stays below maxValue
            }
        } else {
            // Fallback to empty string for invalid input
            numericValue = '';
        }

        filterState[name] = numericValue;
        setFilterState({ ...filterState });

    };


    return (
        <div>
            <Box className={classes.mainContainer} px={{lg: 5, md: 4, sm: 3, xs: 1}} pt={1} >
                <Box mx={2} pl={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography className={classes.breadcrumbsLink} onClick={() => { navigate('/payrollDashboard') }}>Payroll Dashboard</Typography>
                        <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Generate Summary Sheet</Text>
                    </Breadcrumbs>
                </Box>

                {LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)) ?

                    <>
                        <Box style={{ padding: "0px 10px 10px 20px" }}>

                        <Box mt={2} p={2} sx={{ background: " #FAFAFA", borderRadius: "8px" }}>
                                <Grid container sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <Grid  container  item lg={6} md={6} sm={12} xs={12} columnGap={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <img src={Calendar} alt="Calendar" />
                                        <Text blue14>Pay Period</Text>
                                        <Text className={classes.dateStyle}>&nbsp;-&nbsp; {payrollData?.from_date} - {payrollData?.to_date}</Text>
                                    </Grid>
 
                                    <Grid container  item lg={6} md={6} sm={12} xs={12} columnGap={3} sx={{display:'flex',flexDirection:'row',
                                    justifyContent:'end',
                                    "@media (min-width: 600px) and (max-width: 900px)": {
                                        justifyContent:'start'
                                    },
                                    "@media (min-width: 300px) and (max-width: 599px)": {
                                        justifyContent:'start'
                                    },
                                    "@media (min-width: 100px) and (max-width: 299px)": {
                                        justifyContent:'start'
                                    },}}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <img src={CalanderBlack} alt="Calendar" />
                                        <Text mediumBlack14>Today</Text>
                                        <Text className={classes.dateStyle}>&nbsp;-&nbsp; {moment().format('MM/DD/YYYY')}</Text>
                                        </Box>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <img src={CalanderOrange} alt="Calendar" />
                                        <Text mediumBlack14 sx={{color:'orange !important' }}>Check Date</Text>
                                        <Text className={classes.dateStyle}ck14>&nbsp;- &nbsp;{payrollData?.check_date}</Text>
                                        </Box>
                                    </Grid>
                                   
                                </Grid>
                            </Box>
                            {
                                    (tableDataLoading||payrollData?.is_checK_date_cross == false) ? '' : 
                                    <Grid item pb={2}  mt={4}>
                                        <Box display='flex' flexDirection='row' gap={1.5} p={2} sx={{ width: '100%', borderRadius: '8px', background: '#FDECCE', alignItems: 'center', paddingLeft: '20px' }}>
                                            <Box pt={'4px'}>
                                                <img src={warning} alt='warning' />
                                            </Box>
                                            <Box>
                                            <Text mediumOrangeRehire> Note : Your check date was originally set for {payrollData?.actual_check_date ? payrollData?.actual_check_date :''}. Finalize payroll within {payrollData?.no_of_days ? payrollData?.no_of_days : ""} day(s). Otherwise, the earned amounts will be added to the respective employees' balances.   </Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
 
                            <Grid container>
                                <Grid item lg={7} md={7} pt={2}>
                                <Box sx={{
                                        height: "44px", border: "1.5px solid #E2E5E6", width: "350px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center",
                                        "@media (min-width: 100px) and (max-width: 599px)": {
                                            width: "250px",
                                            height: "35px",
                                        },
                                        "@media (min-width: 100px) and (max-width: 330px)": {
                                            width: "220px"
                                        },
                                    }}>
                                        <input
                                            type="text"
                                            value={filterState.search}
                                            className={classes.searchInput}
                                            onChange={handleSearch}
                                            placeholder="Search by Name / ID / Client Name"
                                        />
                                        <Box sx={{ paddingRight: "15px !important" }}>
                                            {
                                                filterState.search !== "" ?
                                                    <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                    :
                                                    <img src={Search} alt="Search" />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={5} md={5} pt={3} container alignItems={"center"} justifyContent={"end"} columnGap={4}>
                                    <Text offBoardBodyFont>
                                        Count
                                        <HtmlTooltip
                                            placement="bottom"
                                            arrow
                                            title={
                                                <React.Fragment>
                                                    <Box>
                                                        <Typography className={classes.profileTooltipText}>
                                                            Total Employees count is <br />based on the filters applied
                                                        </Typography>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        >
                                            <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                        </HtmlTooltip>
                                        - {tableData?.length} Employees
                                    </Text>
                                    <button title="Export" type="button" className={classes.borderButton1} onClick={() => handleDownloadExport()}><img src={NewDownloadcloud} alt="Userplus" /></button>
                                    <button title="Filter" type="button" className={classes.borderButton1} onClick={() => { handleOpenDrawer() }}>
                                        <Badge badgeContent={filterData.visa.length + filterData.clients.length + ((filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "") && 1) + ((filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "") && 1) + ((filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "") && 1) + ((filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") && 1) + ((filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "") && 1)} color="error">
                                            <FilterListIcon sx={{
                                                color: (filterData.visa.length > 0 || filterData.clients.length > 0 || (filterData.earned_amount_from !== "" && filterData.earned_amount_to !== "")
                                                    || (filterData.salary_amount_from !== "" && filterData.salary_amount_to !== "")
                                                    || (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "")
                                                    || (filterData.deduction_amount_from !== "" && filterData.deduction_amount_to !== "")
                                                    || (filterData.reimbursement_amount_from !== "" && filterData.reimbursement_amount_to !== "")) ? "#737373" : ''
                                            }} />
                                        </Badge>

                                    </button>

                                </Grid>
                            </Grid>
                        </Box>


                        {tableDataLoading ?
                            [1, 2, 3, 4].map((item) => (
                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Box>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            </Box>
                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                        </Box>
                                    </Box>
                                </Stack>
                            )) : <>
                                {tableData.length > 0 ?
                                    <Grid height={'62vh'}>
                                        <PayrollTable tableData={tableData} payrollDataProp={payrollData} getAllEmployees={getAllEmployees} payrollConfigId={payrollConfigId} deductionTypeDropDown={deductionTypeDropDown} reimbursementTypeDropDown={reimbursementTypeDropDown} reimbursementPayPeriodDropDown={reimbursementPayPeriodDropDown} /></Grid> :

                                    tableData.length == 0 ? <Box textAlign={'center'} alignItems={'center'}>{NoDataFound()}</Box> : ''}

                            </>}

                    </> :
                    <Grid container>
                        {NoPermission()}
                    </Grid>}

                <SwipeableDrawer
                    anchor={'right'}
                    open={drawer}
                    onClose={() => { setDrawer(false);setSelectedFilter(null); }}
                    transitionDuration={300}
                    sx={{
                        ".MuiDrawer-paper ": {
                            borderRadius: '8px 0px 0px 8px !important',
                        },
                        "& .MuiBackdrop-root.MuiModal-backdrop": {
                            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                        }
                    }
                    }
                > {
                        drawer === "filter" ?
                            <Box width={{ lg: '660px', md: "660px", sm: "550px", xs: "370px" }} height={'100vh'} >
                                <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                    <Text headerBlack>Filters</Text>
                                </Box>

                                <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                    <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        {
                                            clientDropdown.map((item, key) => (
                                                filterState.clients.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={key}
                                                    icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.value}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip(item.id, "clients")}
                                                    deleteIcon={<CloseIcon />} />
                                            ))
                                        }
                                        {
                                            visaTypeDropdown.map((item, key) => (
                                                filterState.visa.includes(item.id) &&
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    icon={<CircleIcon sx={{ color: "#FFC469 !important" }} className={classes.circularIcon} />}
                                                    key={key}
                                                    label={<Text smallBlack>{item.value}</Text>}
                                                    variant="outlined" onDelete={() => handleDeleteChip(item.id, "visa")}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            ))
                                        }
                                        {
                                            filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.earned_amount_from} - {filterState.earned_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "earned_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.salary_amount_from} - {filterState.salary_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "salary_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#0C75EB !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.reimbursement_amount_from} - {filterState.reimbursement_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "reimbursement_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#4375A3 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.deduction_amount_from} - {filterState.deduction_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "deduction_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                        {
                                            filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "" &&
                                            <>
                                                <Chip
                                                    sx={{ border: "1px solid #E5E8E9" }}
                                                    key={1}
                                                    icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                    label={<Text smallBlack>{filterState.balance_amount_from} - {filterState.balance_amount_to}</Text>}
                                                    variant="outlined"
                                                    onDelete={() => handleDeleteChip('', "balance_amount")}
                                                    deleteIcon={<CloseIcon />} />
                                            </>
                                        }
                                    </Box>
                                    {
                                        (filterState.clients.length > 0 || filterState.visa.length > 0 || (filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") ||(filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "")||(filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") || (filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") ) ?
                                            <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                            :
                                            ''
                                    }
                                </Box>

                                <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                    <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} >
                                        <List component="nav" aria-label="secondary mailbox folder">
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)}
                                                secondaryAction={filterState.clients.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}>
                                                    <Text mediumWhite400>{filterState.clients.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Client Name</Text> : <Text ml={3} mediumBlack>Client Name</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)}
                                                secondaryAction={filterState.visa.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FFC469" }}>
                                                    <Text mediumWhite400>{filterState.visa.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Visa Type</Text> : <Text ml={3} mediumBlack>Visa Type</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)}
                                                secondaryAction={(filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}>
                                                    <Text mediumWhite400>{(filterState.earned_amount_from !== "" && filterState.earned_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Earned Amount</Text> : <Text ml={3} mediumBlack>Earned Amount</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)}
                                                secondaryAction={(filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}>
                                                    <Text mediumWhite400>{(filterState.salary_amount_from !== "" && filterState.salary_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Pay Amount</Text> : <Text ml={3} mediumBlack>Pay Amount</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)}
                                                secondaryAction={(filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#0C75EB" }}>
                                                    <Text mediumWhite400>{(filterState.reimbursement_amount_from !== "" && filterState.reimbursement_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Reimbursements</Text> : <Text ml={3} mediumBlack>Reimbursements</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)}
                                                secondaryAction={(filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#4375A3" }}>
                                                    <Text mediumWhite400>{(filterState.deduction_amount_from !== "" && filterState.deduction_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Deductions</Text> : <Text ml={3} mediumBlack>Deductions</Text>} />
                                            </ListItem>
                                            <Divider />
                                            <ListItem className={selectedFilter == 7 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(7)}
                                                secondaryAction={(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}>
                                                    <Text mediumWhite400>{(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 7 ? <Text ml={3} smallBlue1>Current Balance</Text> : <Text ml={3} mediumBlack>Current Balance</Text>} />
                                            </ListItem>
                                            <Divider />

                                        </List>


                                    </Box>
                                    <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>
                                        {selectedFilter === 1 ?
                                            <>
                                                {
                                                    clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                        <Grid container alignItems={"center"} pb={2}>
                                                            <FormControlLabel
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        name={"clients"}
                                                                        value={item.id}
                                                                        onChange={(e) => { handleChangeCheckBoxStr(e) }}
                                                                        // onClick = {handleCheckClick()}
                                                                        icon={<CheckBorderIcon />}
                                                                        checkedIcon={<CheckedIcon />}
                                                                        checked={filterState.clients.includes(item.id)} />}
                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    :
                                                                    item.value}</Text>}
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                            </>
                                            :
                                            selectedFilter === 2 ?
                                                <>
                                                    {visaTypeDropdown.map((item, key) => (
                                                        <Grid container alignItems={"center"} pb={2}>
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        name={"visa"}
                                                                        value={item.id}
                                                                        onChange={(e) => { handleChangeCheckBoxInt(e) }}
                                                                        icon={<CheckBorderIcon />}
                                                                        checkedIcon={<CheckedIcon />}
                                                                        checked={filterState.visa.includes(item.id)}
                                                                    />}
                                                                label={<Text mediumBlack noWrap >{item.value}</Text>} />
                                                        </Grid>
                                                    ))}
                                                </>
                                                :
                                                selectedFilter === 3 ?
                                                    <Grid container alignItems={"center"}>
                                                        <Text headerBlack>Select Amount Range</Text>
                                                        <Box p={2} width={"100%"}>
                                                            <CustomSlider
                                                                min={0}
                                                                max={10000000}
                                                                value={[filterState.earned_amount_from, filterState.earned_amount_to]}
                                                                name="earned_amount"
                                                                onValueChange={handleChangeAmount}
                                                            />
                                                        </Box>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>

                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                inputProps={{
                                                                    name: 'earned_amount_from',
                                                                    value: filterState.earned_amount_from==='' ?filterState.earned_amount_from : currency + '' + filterState.earned_amount_from,
                                                                    inputProps: {
                                                                        maxLength: 16, // Maximum length for "9999999999.999"
                                                                        pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                    },
                                                                }}
                                                                handleChange={(e) => handleInputChange(e)}
                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                clientInput
                                                                labelText={'$ Min'}
                                                                size="small"
                                                                sx={{ width: "35%" }}
                                                            />
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                inputProps={{
                                                                    name: 'earned_amount_to',
                                                                    value: filterState.earned_amount_to==='' ?filterState.earned_amount_to : currency + '' + filterState.earned_amount_to,
                                                                    inputProps: {
                                                                        maxLength: 16, // Maximum length for "9999999999.999"
                                                                        pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                    },
                                                                }}
                                                                handleChange={(e) => handleInputChange(e)}
                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                clientInput
                                                                labelText={'$ Max'}
                                                                size="small"
                                                                sx={{ width: "35%" }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    :
                                                    selectedFilter === 4 ?
                                                        <Grid container alignItems={"center"}>
                                                            <Text headerBlack>Select Amount Range</Text>
                                                            <Box p={2} width={"100%"}>
                                                                <CustomSlider
                                                                    min={0}
                                                                    max={10000000}
                                                                    value={[filterState.salary_amount_from, filterState.salary_amount_to]}
                                                                    name="salary_amount"
                                                                    onValueChange={handleChangeAmount}
                                                                />
                                                            </Box>
                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'salary_amount_from',
                                                                        value: filterState.salary_amount_from===''? filterState.salary_amount_from:currency + '' + filterState.salary_amount_from,
                                                                        inputProps: {
                                                                            maxLength: 16, // Maximum length for "9999999999.999"
                                                                            pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                        },
                                                                    }}
                                                                    handleChange={(e) => handleInputChange(e)}
                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                    clientInput
                                                                    labelText={'$ Min'}
                                                                    size="small"
                                                                    sx={{ width: "35%" }}
                                                                />
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'salary_amount_to',
                                                                        value:filterState.salary_amount_to===''?filterState.salary_amount_to: currency + '' + filterState.salary_amount_to,
                                                                        inputProps: {
                                                                            pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                        },
                                                                    }}
                                                                    handleChange={(e) => handleInputChange(e)}
                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                    clientInput
                                                                    labelText={'$ Max'}
                                                                    size="small"
                                                                    sx={{ width: "35%" }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        :
                                                        selectedFilter === 5 ?
                                                            <Grid container alignItems={"center"}>
                                                                <Text headerBlack>Select Amount Range</Text>
                                                                <Box p={2} width={"100%"}>
                                                                    <CustomSlider
                                                                        min={0}
                                                                        max={10000000}
                                                                        value={[filterState.reimbursement_amount_from, filterState.reimbursement_amount_to]}
                                                                        name="reimbursement_amount"
                                                                        onValueChange={handleChangeAmount}
                                                                    />
                                                                </Box>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'reimbursement_amount_from',
                                                                            value: filterState.reimbursement_amount_from===''? filterState.reimbursement_amount_from : currency + '' + filterState.reimbursement_amount_from,
                                                                            inputProps: {
                                                                                maxLength: 16, // Maximum length for "9999999999.999"
                                                                                pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                            },
                                                                        }}
                                                                        handleChange={(e) => handleInputChange(e)}
                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                        clientInput
                                                                        labelText={'$ Min'}
                                                                        size="small"
                                                                        sx={{ width: "35%" }}
                                                                    />
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'reimbursement_amount_to',
                                                                            value:filterState.reimbursement_amount_to===''? filterState.reimbursement_amount_to: currency + '' + filterState.reimbursement_amount_to,
                                                                            inputProps: {
                                                                                maxLength: 16, // Maximum length for "9999999999.999"
                                                                                pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                            },
                                                                        }}
                                                                        handleChange={(e) => handleInputChange(e)}
                                                                        onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                        clientInput
                                                                        labelText={'$ Max'}
                                                                        size="small"
                                                                        sx={{ width: "35%" }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            :
                                                            selectedFilter === 6 ?
                                                                <Grid container alignItems={"center"}>
                                                                    <Text headerBlack>Select Amount Range</Text>
                                                                    <Box p={2} width={"100%"}>
                                                                        <CustomSlider
                                                                            min={0}
                                                                            max={10000000}
                                                                            value={[filterState.deduction_amount_from, filterState.deduction_amount_to]}
                                                                            name="deduction_amount"
                                                                            onValueChange={handleChangeAmount}
                                                                        />
                                                                    </Box>
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'deduction_amount_from',
                                                                                value:filterState.deduction_amount_from===''? filterState.deduction_amount_from: currency + '' + filterState.deduction_amount_from,
                                                                                inputProps: {
                                                                                    maxLength: 16, // Maximum length for "9999999999.99"
                                                                                    pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                                },
                                                                            }}
                                                                            handleChange={(e) => handleInputChange(e)}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                            clientInput
                                                                            labelText={'$ Min'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'deduction_amount_to',
                                                                                value:filterState.deduction_amount_to===''? filterState.deduction_amount_to: currency + '' + filterState.deduction_amount_to,
                                                                                inputProps: {
                                                                                    maxLength: 16, // Maximum length for "9999999999.999"
                                                                                    pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                                },
                                                                            }}
                                                                            handleChange={(e) => handleInputChange(e)}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                            clientInput
                                                                            labelText={'$ Max'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                :
                                                                selectedFilter === 7 ?
                                                                    <Grid container alignItems={"center"}>
                                                                        <Text headerBlack>Select Amount Range</Text>
                                                                        <Box p={2} width={"100%"}>
                                                                            <CustomSlider
                                                                                min={filterAmount?.min}
                                                                                max={filterAmount?.max}
                                                                                value={[filterState?.balance_amount_from, filterState?.balance_amount_to]}
                                                                                name="balance_amount"
                                                                                onValueChange={handleChangeAmount}
                                                                            />
                                                                        </Box>
                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'balance_amount_from',
                                                                                    value: filterState.balance_amount_from,
                                                                                    inputProps: {
                                                                                        maxLength: 16, // Maximum length for "9999999999.999"
                                                                                        // pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                                    },
                                                                                }}
                                                                                handleChange={(e) => handleInputChangeNegative(0, e.target.value, 'balance_amount_from', 'min')}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                clientInput
                                                                                labelText={'$ Min'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'balance_amount_to',
                                                                                    value: filterState.balance_amount_to,
                                                                                    inputProps: {
                                                                                        maxLength: 16, // Maximum length for "9999999999.99"
                                                                                        // pattern: "\\d*\\.?\\d{0,3}", // Allow numbers with up to 2 decimals
                                                                                    },
                                                                                }}
                                                                                handleChange={(e) => handleInputChangeNegative(1, e.target.value, 'balance_amount_to', 'max')}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                clientInput
                                                                                labelText={'$ Max'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                    :
                                                                    <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                        <Grid container>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                <Text veryLargeLabel>Gear Up!</Text>
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>

                                        }
                                    </Box>


                                </Box>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                    <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                    <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                </Box>
                            </Box> : null
                    }
                </SwipeableDrawer>
            </Box>
        </div>
    )
}

export default GeneratePayroll
