
import { Avatar, Box, Button, Divider, Grid, Menu, MenuItem, } from "@mui/material";
import React, { useState } from "react";
import Text from "../../../components/customText/Text";
import DashboardStyles from "../../admin/DasboardStyles";
import filterIcon from '../../../assets/svg/filter_icon.svg';
import { BlackToolTip, NoDataFound } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";


function SideHistory(props) {
    const classes = DashboardStyles();
    const { handleAllClick, cilentHistory, handleClientHistoryClick, handleScroll, scrollBox, buttonColour, setCilentSelectedValue, allHistory, setAllSelectedValue, keyStatus, handleSelectInvoiceId, selectInvoiceId } = props;

    const [anchorTourGuideEl, setAnchorTourGuideEl] = useState(null);
    const openTourGuideOptions = Boolean(anchorTourGuideEl);

    const handleTourGuideAnchorEle = (event) => {
        event.stopPropagation();
        setAnchorTourGuideEl(event.currentTarget);
    }
    // const handleTourGuideClick = (menuItem, pathRoute) => {
    //     setAnchorEl(null);
    //     setAnchorTourGuideEl(null);
    // }

    const handleCloseTourGuide = () => {
        setAnchorTourGuideEl(null);
    };

    const menuItems = ['All', 'Pending Approval', 'Paid', 'Partially Paid', 'Overdue', 'Due', 'Write Off', 'Sent', 'Saved', 'Drafted', 'Void'];


    const handleMenuItemClick = (item) => {
        buttonColour ? setAllSelectedValue(item) : setCilentSelectedValue(item);
        handleCloseTourGuide();

    };



    return (
        <Grid item container sm={12} xs={12} p={'0px 10px 0px 20px'} >
            {/* <Grid
                item
                container
                sm={12}
                xs={12}
                pt={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" alignItems="center">
                    <Button
                        onClick={handleClientHistoryClick}
                        style={{
                            backgroundColor: buttonColour ? "#E2E5E6" : "#0C75EB",
                            borderRadius: "8px 0px 0px 8px",
                            font: "12px Quicksand !important",
                            width: "150px",
                            height: "40px",
                            color: buttonColour ? "#404040" : "#FFFFFF",
                            fontWeight: "400",
                            textTransform: "capitalize",
                        }}
                    >
                        Client History
                    </Button>
                    <Button
                        onClick={handleAllClick}
                        style={{
                            backgroundColor: buttonColour ? "#0C75EB" : "#E2E5E6",
                            borderRadius: "0px 8px 8px 0px",
                            font: "12px Quicksand !important",
                            width: "80px",
                            height: "40px",
                            color: buttonColour ? "#FFFFFF" : "#404040",
                            fontWeight: "400",
                            textTransform: "capitalize",
                        }}
                    >
                        All
                    </Button>
                </Box>
                <Box>
                    <Box
                        onClick={handleTourGuideAnchorEle}
                        id="tourguide"
                        aria-controls={openTourGuideOptions ? 'tourguide-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openTourGuideOptions ? 'true' : undefined}
                        sx={{ cursor: 'pointer' }} // Add cursor styling for better UX
                    >
                        <img
                            src={filterIcon}
                            alt="filterIcon"
                            style={{
                                paddingRight: '40px'
                            }}
                        />
                    </Box>

                    <Menu
                        anchorEl={anchorTourGuideEl}
                        MenuListProps={{
                            'aria-labelledby': 'tourguide',
                        }}
                        id="tourguide-menu"
                        open={openTourGuideOptions}
                        onClose={handleCloseTourGuide}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right', // Align the menu's left with the image's left
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right', // Ensure the menu aligns from its left side
                        }}

                        sx={{
                            marginLeft: '-40px',
                            '& .MuiPaper-root': {
                                height: '163px',
                                overflow: "auto",
                                // '&::-webkit-scrollbar': {
                                //     display: "none", // For Chrome, Safari, and Edge
                                // },

                            }
                        }}
                        PaperProps={{
                            sx: {
                                mt: "6px",
                            }
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <Box key={index}>
                                <MenuItem onClick={() => handleMenuItemClick(item)}>
                                    <Box>
                                        <Text filterText>{item}</Text>
                                    </Box>
                                </MenuItem>
                                {index < menuItems.length - 1 && (
                                    <Divider
                                        sx={{
                                            margin: "0px !important",
                                            borderColor: "#F2F4F7 !important",
                                            borderWidth: "1px !important",
                                        }}
                                    />
                                )}
                            </Box>
                        ))}

                    </Menu>
                </Box>

            </Grid> */}
            <Grid container sm={12} xs={12} pt={2} alignItems="center">
                {/* Buttons Section */}
                <Grid item xs={12} sm={6} md={6} lg={6} display="flex" alignItems="center">
                    <Button
                        onClick={handleClientHistoryClick}
                        style={{
                            backgroundColor: buttonColour ? "#E2E5E6" : "#0C75EB",
                            borderRadius: "8px 0px 0px 8px",
                            font: "12px Quicksand !important",
                            width: "150px",
                            height: "40px",
                            color: buttonColour ? "#404040" : "#FFFFFF",
                            fontWeight: "400",
                            textTransform: "capitalize",
                        }}
                    >
                        Client History
                    </Button>
                    <Button
                        onClick={handleAllClick}
                        style={{
                            backgroundColor: buttonColour ? "#0C75EB" : "#E2E5E6",
                            borderRadius: "0px 8px 8px 0px",
                            font: "12px Quicksand !important",
                            width: "80px",
                            height: "40px",
                            color: buttonColour ? "#FFFFFF" : "#404040",
                            fontWeight: "400",
                            textTransform: "capitalize",
                        }}
                    >
                        All
                    </Button>
                </Grid>

                {/* Menu Button Section */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    display="flex"
                    justifyContent={{ xs: "flex-end", sm: "flex-end", md: "flex-end", lg: "flex-end" }}
                >
                    <Box>
                        <Box
                            onClick={handleTourGuideAnchorEle}
                            id="tourguide"
                            aria-controls={openTourGuideOptions ? "tourguide-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openTourGuideOptions ? "true" : undefined}
                            sx={{ cursor: "pointer" }} // Add cursor styling for better UX
                            pt={{ xs: 1 }}
                        >
                            <img
                                src={filterIcon}
                                alt="filterIcon"
                                style={{
                                    paddingRight: "40px",
                                }}
                            />
                        </Box>

                        <Menu
                            anchorEl={anchorTourGuideEl}
                            MenuListProps={{
                                "aria-labelledby": "tourguide",
                            }}
                            id="tourguide-menu"
                            open={openTourGuideOptions}
                            onClose={handleCloseTourGuide}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right", // Align the menu's left with the image's left
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right", // Ensure the menu aligns from its left side
                            }}
                            sx={{
                                marginLeft: "-40px",
                                "& .MuiPaper-root": {
                                    height: "163px",
                                    overflow: "auto",
                                },
                            }}
                            PaperProps={{
                                sx: {
                                    mt: "6px",
                                },
                            }}
                        >
                            {menuItems.map((item, index) => (
                                <Box key={index}>
                                    <MenuItem onClick={() => handleMenuItemClick(item)}>
                                        <Box>
                                            <Text filterText>{item}</Text>
                                        </Box>
                                    </MenuItem>
                                    {index < menuItems.length - 1 && (
                                        <Divider
                                            sx={{
                                                margin: "0px !important",
                                                borderColor: "#F2F4F7 !important",
                                                borderWidth: "1px !important",
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Menu>
                    </Box>
                </Grid>
            </Grid>

            <Grid item container sm={12} xs={12} pt={3} mb={8}>
                <Box
                    className={classes.cardContainer}
                    // sx={{
                    //     // height: ((buttonColour ? allHistory : cilentHistory)?.length < 5 ? "fit-content" : "58vh") + "!important",
                    //     height: "58vh !important",
                    // }}
                    sx={{
                        height: (keyStatus?.status == "Pending Approval" && (LocalStorage.getUserData().super_admin || keyStatus?.can_approve)) ? "58vh !important" : '72vh !important', overflow: 'auto', scrollbarWidth: "none !important",
                        "&::-webkit-scrollbar": { display: "none" },

                    }}
                    ref={scrollBox} onScroll={handleScroll}

                >
                    {(buttonColour ? allHistory : cilentHistory)?.length > 0
                        ? (buttonColour ? allHistory : cilentHistory).map((item, index) => {
                            return (<Grid
                                key={item.id}
                                container
                                ml={0.5}
                                lg={11}
                                xs={12}
                                onClick={() => { handleSelectInvoiceId(item) }}
                                sx={{
                                    boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                    borderRadius: '8px',
                                    marginBottom: index === (buttonColour ? allHistory : cilentHistory).length - 1 ? '50px !important' : '10px !important',
                                    cursor: 'pointer',
                                    border: selectInvoiceId === item.id ? '1px solid #0C75EB' : '2px solid transparent',
                                }}

                            >
                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} >
                                    <Grid item lg={2} md={3} sm={2} xs={4}>
                                        <Avatar
                                            src={item?.document_url ? `${item?.document_url}?w=40&h=40&fit=crop` : ''}
                                            alt={item?.name}
                                            sx={{ height: 40, width: 40, borderRadius: '50%', background: item?.profile_picture_url ? '' : '#e5b256' }}
                                        />
                                    </Grid>
                                    <Grid item lg={5} md={8} sm={7} xs={8}>
                                        {/* <Text dashboardblack14>{item.client_name}</Text> */}
                                        {item?.client_name === "" ? "--" :
                                            item?.client_name?.length > 16 ?
                                                <Text dashboardblack14>
                                                    <BlackToolTip arrow placement='right' title={item?.client_name}>
                                                        {item.client_name.slice(0, 16)} {item?.client_name?.length > 16 ? '...' : ''}
                                                    </BlackToolTip>
                                                </Text>
                                                :
                                                <Text dashboardblack14>{item?.client_name}</Text>
                                        }
                                        {item?.contact_name === "" ? "--" :
                                            item?.contact_name?.length > 16 ?
                                                <Text greysmallLabel>
                                                    <BlackToolTip arrow placement='right' title={item?.contact_name}>
                                                        {item?.contact_name.slice(0, 16)} {item?.contact_name?.length > 16 ? '...' : ''}
                                                    </BlackToolTip>
                                                </Text>
                                                :
                                                <Text greysmallLabel>{item?.contact_name}</Text>
                                        }


                                        {/* <Text greysmallLabel>{item.contact_name}</Text> */}
                                    </Grid>
                                    <Grid item lg={5} md={12} sm={3} xs={12} textAlign="right">
                                        <Text mediumBlue600>{item?.reference_id}</Text>
                                        <Text dashboardblack14>${item?.total_amount}</Text>
                                    </Grid>
                                    <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                    <Grid item lg={7} md={6} sm={6} xs={6}>
                                        <Box display="flex" alignItems="center">
                                            <Text greysmallLabel sx={{ marginRight: "8px" }}>Date</Text>
                                            <Text greysmallLabel>{item?.invoice_date}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item container lg={5} md={6} sm={6} xs={6} className={classes.textCenter} alignItems="center" justifyContent="end">
                                        <Text mediumBlue600 sx={{ color: `${item.color} !important` }}>{item?.invoice_status}</Text>
                                    </Grid>
                                </Grid>
                            </Grid>);

                        })
                        : NoDataFound()}
                </Box>
            </Grid>
        </Grid>
    )
}
export default SideHistory