import React, { useEffect, useRef, useState } from "react";
import LocalStorage from "../../utils/LocalStorage";
import TourGuideConfigApi from "../../apis/configurations/tourguideConfig/TourGuideConfigApi";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Box, IconButton, Divider, Tooltip, tooltipClasses, useTheme, useMediaQuery, Avatar, TableHead, TableBody, Typography, Breadcrumbs, SwipeableDrawer, Tabs, Tab } from '@mui/material';

import Text from '../../components/customText/Text';
import Button from '../../components/customButton/Button';
import { BlackToolTip, capitalizeAndAddSpace, NoDataFound } from '../../utils/utils';
import Search from '../../assets/svg/search1.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import LeftNavigateArrow from '../../assets/svg/LeftNavigateArrow.svg'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Userplus from '../../assets/svg/clients/user-plus-bold.svg';
import arrowDown from '../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../assets/svg/clients/blackarrowUp.svg';
import ClearIcon from '@mui/icons-material/Clear';
import cloud from '../../assets/svg/download-cloud-blue.svg';
import EditIcon from '../../assets/svg/newEdit.svg'
import DeleteIcon from '../../assets/employee/deletewithBg.svg'
import ListAltIcon from "@mui/icons-material/ListAlt";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import activityLog from '../../assets/svg/invoice_activity_log.svg';
import editIcon from '../../assets/svg/invoive_edit.svg';
import sendEmailIcon from '../../assets/svg/invoice_send_email.svg';
import downloadIcon from '../../assets/svg/invoice_download.svg';
import bellIcon from '../../assets/svg/invoice_bill_icon.svg';
import syncIcon from '../../assets/svg/invoice_sync_icon.svg';
import sentIcon from '../../assets/svg/invoice_sent_icon.svg';
import { makeStyles } from "@mui/styles";
import Date from "../datePicker/Date";
import Input from "../input/Input";
import DashboardStyles from "../../views/admin/DasboardStyles";
import ToggleSwitch from "../toggle/CustomToggle";
import { employee_URL } from '../../config/development';


const PaymentsStyles = makeStyles(() => ({
    // New Screen Styles
    ActiveBg: {
        background: "#0C75EB", height: '35px', padding: "6px 15px", borderRadius: "8px", cursor: "pointer", display: "flex", alignItems: 'center', justifyContent: 'center'
    },
    inactiveBg: {
        background: "transparent", padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer"
    },
    greenbg: {
        background: '#EAFFF6',
        borderRadius: '12px',
        padding: '15px 12px',
    },
    tabBg: {
        display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", width: '100% !important', alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3,
        [useTheme().breakpoints.up('md')]: {
            minWidth: '300px !important'
        },
        [useTheme().breakpoints.down('md')]: {
            minWidth: '400px !important',
        }
    },
    addIcon: {
        height: '22px', width: '22px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        }
    },
    zohoIcon: {
        height: '24px', width: '24px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    divider: {
        margin: "0px !important", color: '#E2E5E6 !important'
    },
    newCustomAccordion: {
        padding: "2px 0px !important",
        backgroundColor: "#ffffff !important",
        margin: "2px 0px !important",
        borderRadius: "12px !important",
        boxShadow: "5px 5px 10px 0px #0000000D !important",
        '&:before': { display: "none" },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        },
    },
    AccordionSummary: {
        // backgroundColor: "#ffffff",
        height: "70px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",

    },
    
    AccordionDetails: {
        backgroundColor: "#F9FCFF",
        height: "80px",
        borderRadius: "16px",
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    activeStatus: {
        height: "25px", width: '100px !important', borderRadius: "20px", padding: "3px", background: '#16A34A', font: '14px Quicksand', fontWeight: `${600} !important`, color: '#fff', textAlign: "center",
    },
    inactiveStatus: {
        height: "25px", width: '100px !important', borderRadius: "20px", padding: "3px", background: '#494747', font: '14px Quicksand', fontWeight: `${600} !important`, color: '#fff', textAlign: "center",
    },

    EmployeesSearchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    borderButton: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "44px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important"
    },
    quickbooksbg: {
        background: '#DFFCE9 !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    arrowSize: {
        width: '20px !important',
        height: '20px !important',
        cursor: 'pointer !important'
    },
    zohobooksbg: {
        background: '#C8DBEC !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    flexBox: {
        display: "flex", justifyContent: "center", alignItems: "center"
    },
    statusIcon: {
        cursor: "pointer",
        marginBottom: " -4px",
        marginLeft: "4px",
    },
    
}))

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    fontSize: "14px",
    fontWeight: 600,
    color: '#262626',
    textAlign: 'left'
}));



const StyledTableRowBody = styled(TableRow)(({ theme }) => ({
    width: '100%',
    cursor: 'pointer',
    "& td, & th": {
        border: "none",
    },


}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:td blue": {
        backgroundColor: "red !important",
    },
}));

const rows = [
    {
        id: 1,
        name: "ABC DEF",
        avatar: "https://via.placeholder.com/40",
        details: "Lorem ipsum dolor sit amet consectetur. Se...",
        hours: 40,
        billRate: 70,
        otHours: 0.0,
        otBillRate: 0.0,
        amount: 2800,
    },
    {
        id: 2,
        name: "GHI JKL",
        avatar: "https://via.placeholder.com/40",
        details: "Lorem ipsum dolor sit amet consectetur. Se...",
        hours: 40,
        billRate: 70,
        otHours: 0.0,
        otBillRate: 0.0,
        amount: 2800,
    },
    {
        id: 3,
        name: "MNO PQR",
        avatar: "https://via.placeholder.com/40",
        details: "Lorem ipsum dolor sit amet consectetur. Se...",
        hours: 40,
        billRate: 70,
        otHours: 0.0,
        otBillRate: 0.0,
        amount: 2800,
    },
    {
        id: 4,
        name: "STU VWX",
        avatar: null, // For tasks without avatars
        details: "Lorem ipsum dolor sit amet consectetur. Se...",
        hours: 40,
        billRate: 70,
        otHours: 0.0,
        otBillRate: 0.0,
        amount: 2800,
        isTask: true,
    },
];





export default function TourInvoice({ startTour, setStartTour ,destroyTour, setDestroyTour}) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const navigate = useNavigate();
    const classes = PaymentsStyles();
    const classesDashboard = DashboardStyles()
    const location = useLocation();
    const activeState = 'all-invoices'
    const expanded = false;
    const openStatusArrow = false;
    const [open, setOpen] = useState(false)

    const data = location && location.state// eslint-disable-next-line
    const recentSection = useRef(null);


    const rowData = [
        {
            id: "01",
            name: "ABC DEF",
            details: "Timesheet between 12/03/2023 and 14/03/2023 For ABC DEF Consultant",
            hours: "25",
            rate: "$243",
            otHours: "02",
            otRate: "$150", 
            amount: "$2800",
        }, 

    ];

    const cilentHistory = [

        {
            "id": "0d0efae9-5a5e-4cec-9a8d-2bcbe1ad97d1",
            "color": "#318CF1",
            "due_date": "01/01/2025",
            "client_name": "ABC DEF",
            "contact_name": "ABC DEF",
            "document_url": "",
            "invoice_date": "30/12/2024",
            "payment_date": "",
            "reference_id": "INVC-0000009",
            "total_amount": 1200,
            "invoice_items": "",
            "balance_amount": 1200,
            "invoice_status": "Saved",
            "payment_amount": 0
        },
        {
            "id": "19a4c588-938d-4498-b902-7071ea93f977",
            "color": "#318CF1",
            "due_date": "01/02/2025",
            "client_name": "GHI JKL",
            "contact_name": "GHI JKL",
            "document_url": "",
            "invoice_date": "01/01/2025",
            "payment_date": "",
            "reference_id": "INVC-0000014",
            "total_amount": 1000,
            "invoice_items": "",
            "balance_amount": 1000,
            "invoice_status": "Sent",
            "payment_amount": 0
        },
        {
            "id": "1f063e23-23c2-4a42-92b8-1865f9e6937d",
            "color": "#318CF1",
            "due_date": "29/01/2025",
            "client_name": "MNO PQR",
            "contact_name": "MNO PQR",
            "document_url": "",
            "invoice_date": "01/01/2025",
            "payment_date": "",
            "reference_id": "INVC-0000013",
            "total_amount": 1100,
            "invoice_items": "",
            "balance_amount": 1100,
            "invoice_status": "Sent",
            "payment_amount": 0
        },
        {
            "id": "20231493-18e2-4e5b-98db-6d5f98ee3cac",
            "color": "#318CF1",
            "due_date": "01/12/2024",
            "client_name": "STU VWX",
            "contact_name": "STU VWX",
            "document_url": "",
            "invoice_date": "30/11/2024",
            "payment_date": "",
            "reference_id": "INVC-0000010",
            "total_amount": 1000,
            "invoice_items": "",
            "balance_amount": 1000,
            "invoice_status": "Sent",
            "payment_amount": 0
        },
        {
            "id": "26b05a44-f020-4d8b-a05a-b29cfabbfef0",
            "color": "#16A34A",
            "due_date": "01/11/2024",
            "client_name": "ABC DEF",
            "contact_name": "ABC DEF",
            "document_url": "",
            "invoice_date": "30/10/2024",
            "payment_date": "01/11/2024",
            "reference_id": "INVC-0000008",
            "total_amount": 10000,
            "invoice_items": "",
            "balance_amount": 0,
            "invoice_status": "Paid",
            "payment_amount": 10000
        },
        


    ];

    // const terms = [
    //     {
    //         title: '1. Payment Terms',
    //         description: 'Payment is due within [X days] from the invoice date. Late payments may incur a [percentage/fixed amount] penalty.',
    //     },
    //     {
    //         title: '2. Scope of Work',
    //         description: "This invoice covers consulting services rendered by [Consultant's Name] for the period [start date] to [end date].",
    //     },
    //     {
    //         title: '3. Taxes',
    //         description: 'Applicable taxes, as per local regulations, are included. The client is responsible for compliance with tax laws.',
    //     },
    //     {
    //         title: '4. Reimbursable Expenses',
    //         description: 'Pre-approved expenses, if any, are itemized and supported by documentation upon request.',
    //     },
    //     {
    //         title: '5. Disputes',
    //         description: 'Any disputes must be raised within [X days] of receiving this invoice. After this period, the invoice is considered accepted.',
    //     },
    //     {
    //         title: '6. Payment Details',
    //         description: 'Payments should be made to the account specified in the invoice. Any transfer fees are the client\'s responsibility.',
    //     },
    //     {
    //         title: '7. Confidentiality',
    //         description: 'This invoice is confidential and intended solely for the recipient.',
    //     },
    //     {
    //         title: '8. Contact',
    //         description: 'For queries, contact [Your Name] at [Your Email/Phone].',
    //     },
    // ];


    const filterState = {
        limit: 10,
        page: 1,
        search: "",
        sort_column: "created_at",
        sort_order: "desc",

        clients: [],
        invoice_sent_from: "",
        invoice_sent_to: "",
        overdue_from: "",
        overdue_to: "",
        payment_received_from: "",
        payment_received_to: "",
        due_invoices_from: "",
        due_invoices_to: "",
        write_off_from: "",
        write_off_to: "",

    }



    const filter = {
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: ""
    }


    const pagination =
    {
        total: "",
        currentPage: 0,
        perPage: 10,
        totalPages: ""
    }

    const clientRows = [
        {
            "company_id": "2972c1de-ec9b-439e-9b10-286aa6b42195",
            "company_name": "ABC DEF",
            "reference": "CLT-173",
            "document_url": "",
            "telephone_number": "123-456-7899",
            "telephone_number_ext": null,
            "mobile_number": "",
            "contact_email": "",
            "status": "Active",
            "profile_percentage": 50,
            "created_at": "2025-01-15T09:13:46.203Z",
            "primary_contact": 'ABC DEF',
            "zoho_migration_status": "Migrated",
            "quickbook_migration_status": "Not Migrated",
            "id": "2972c1de-ec9b-439e-9b10-286aa6b42195",
            "name": "ABC DEF",
            "reference_id": "CLT-173"
        },
        {
            "company_id": "da701cf5-0db7-418a-a71f-f988d1f3ffbc",
            "company_name": "GHI JKL",
            "reference": "CLT-172",
            "document_url": "",
            "telephone_number": "123-456-7899",
            "telephone_number_ext": null,
            "mobile_number": "",
            "contact_email": "",
            "status": "Active",
            "profile_percentage": 50,
            "created_at": "2025-01-15T08:22:45.341Z",
            "primary_contact": 'GHI JKL',
            "zoho_migration_status": "Migrated",
            "quickbook_migration_status": "Not Migrated",
            "id": "da701cf5-0db7-418a-a71f-f988d1f3ffbc",
            "name": "GHI JKL",
            "reference_id": "CLT-172"
        },
        {
            "company_id": "e441ecda-00a1-48fe-95d2-3c925a375db2",
            "company_name": "MNO PQR",
            "reference": "CLT-171",
            "document_url": "",
            "telephone_number": "123-456-7899",
            "telephone_number_ext": null,
            "mobile_number": "",
            "contact_email": "wer@sdg.asf",
            "status": "Active",
            "profile_percentage": 100,
            "created_at": "2025-01-15T05:36:19.397Z",
            "primary_contact": "MNO PQR",
            "zoho_migration_status": "Migrated",
            "quickbook_migration_status": "Not Migrated",
            "id": "e441ecda-00a1-48fe-95d2-3c925a375db2",
            "name": "MNO PQR",
            "reference_id": "CLT-171"
        },
        {
            "company_id": "066dfa4c-229b-48dc-9475-109a29692ad6",
            "company_name": "STU VWX",
            "reference": "CLT-170",
            "document_url": "",
            "telephone_number": "123-456-7899",
            "telephone_number_ext": null,
            "mobile_number": "",
            "contact_email": "wer@dxg.asf",
            "status": "Active",
            "profile_percentage": 100,
            "created_at": "2025-01-15T04:59:00.179Z",
            "primary_contact": "STU VWX",
            "zoho_migration_status": "Migrated",
            "quickbook_migration_status": "Not Migrated",
            "id": "066dfa4c-229b-48dc-9475-109a29692ad6",
            "name": "GHI JKL",
            "reference_id": "CLT-170"
        },
        {
            "company_id": "2972c1de-ec9b-439e-9b10-286aa6b42195",
            "company_name": "ABC DEF",
            "reference": "CLT-173",
            "document_url": "",
            "telephone_number": "123-456-7899",
            "telephone_number_ext": null,
            "mobile_number": "",
            "contact_email": "",
            "status": "Active",
            "profile_percentage": 50,
            "created_at": "2025-01-15T09:13:46.203Z",
            "primary_contact": 'ABC DEF',
            "zoho_migration_status": "Migrated",
            "quickbook_migration_status": "Not Migrated",
            "id": "2972c1de-ec9b-439e-9b10-286aa6b42195",
            "name": "ABC DEF",
            "reference_id": "CLT-173"
        },
        
    ]

    const theme = useTheme();

    // Use useMediaQuery to evaluate breakpoints
    const isXs = useMediaQuery(theme.breakpoints.down("sm")); // Extra-small screens
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // Small screens
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // Medium screens
    // const isLg = useMediaQuery(theme.breakpoints.up("lg")); // Large screens

    const searchWidth = isXs
        ? "200px" // Extra small screens
        : isSm
            ? "250px" // Small screens
            : isMd
                ? "360px" // Medium screens
                : "400px"; // Large screens

    const steps = [
        {
            element: '#invoicedashboard', popover: {
                title: 'Invoices Dashboard', description: 'This button directs you to the invoices dashboard where you can view and track all invoice related data', side: "left",
                align: 'start'
            }
        }, {
            element: '#invoice', popover: {
                title: 'Invoices', description: 'Navigate to the section where you can create new invoices and view existing ones', side: "left",
                align: 'start'
            }
        },]
        if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_create" && item.is_allowed == true)))) {
        
            steps.push(...[{
                element: '#addinvoice', popover: {
                    title: 'Add Invoice', description: 'Create a new invoice with the required details', side: "left",
                    align: 'start'
                }
            },])
        }

        if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_create" && item.is_allowed == true)))) {
            if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true)))) {


            steps.push(...[{
                element: '#viewclientdetails', popover: {
                    title: 'View Client Details', description: 'View comprehensive client information, including outstanding receivables and unpaid invoices.', side: "left",
                    align: 'start'
                }
            }])
        }}


        if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_create" && item.is_allowed == true)))) {
        
            steps.push(...[{
                element: '#addtask', popover: {
                    title: 'Add Task', description: 'Add additional tasks that are needed in the invoice', side: "left",
                    align: 'start'
                }
            },{
                element: '#addemployee', popover: {
                    title: 'Add Employee', description: 'Add additional employees to a specific invoice as needed.', side: "left",
                    align: 'start'
                }
            },])
        }

        
        if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)))) {
        
            steps.push(...[ {
                element: '#viewinvoice', popover: {
                    title: 'View Invoice', description: 'View the details of the invoice.', side: "left",
                    align: 'start'
                }
            }])
        }

        if (( (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true)))) {
            steps.push(...[ {

           
            element: '#activitylog', popover: {
                title: 'Activity Log', description: 'View the activity log for this invoice.', side: "left",
                align: 'start'
            }
        }, {
            element: '#editinvoice', popover: {
                title: 'Edit Invoice', description: 'Edit the invoice details', side: "left",
                align: 'start'
            }
        }, {
            element: '#share', popover: {
                title: 'Share', description: 'Share via email', side: "left",
                align: 'start'
            }
        }, {
            element: '#downlaod', popover: {
                title: 'Download', description: 'Download the invoice', side: "left",
                align: 'start'
            }
        }, {
            element: '#reminder', popover: {
                title: 'Reminder', description: 'Send a reminder to the client for the invoice payment', side: "left",
                align: 'start'
            }
        }, {
            element: '#syncicon', popover: {
                title: 'Sync icon', description: 'Sync invoices and payments to or from ZohoBooks or QuickBooks', side: "left",
                align: 'start'
            }
        }])}

    const [content,setContent] = useState('dashboard');
    const clientData = [
        { id: 1, client: 'ABC', placement: 20, payment: '$20000' },
        { id: 2, client: 'DEF', placement: 40, payment: '$23000' },
        { id: 3, client: 'GHI', placement: 60, payment: '$20000' },
        { id: 4, client: 'JKL', placement: 28, payment: '$200000' },
        { id: 5, client: 'MNO', placement: 50, payment: '$50000' },
    ];
 
   const invoiceData = [
        { label: '1-15 Days', amount: '$20,000' },
        { label: '16-30 Days', amount: '$20,000' },
        { label: '31-45 Days', amount: '$10,000' },
        { label: 'Above 45 Days', amount: '$2,000' },
    ];
 
    const dataDash = [
        {
            bgClass: classesDashboard.bluebg,
            // icon: billed,
            label: 'Total Amount Raised',
            amount: '$ 76544',
        },
        {
            bgClass: classes.greenbg,
            // icon: pay,
            label: 'Total Amount Received',
            amount: '$ 20000',
        },
        {
            bgClass: classesDashboard.yellowbg,
            // icon: margin,
            label: 'Total Amount Due',
            amount: '$ 45432',
        },
    ];
    useEffect(() => {
        const driverObjInvoice = driver({
            popoverClass: 'driverjs-theme',
            allowClose: false,
            stagePadding: 8,
            stageRadius: 8,
            animate: false,
            allowKeyboardControl: false,
            disableActiveInteraction: true,
            steps: steps,
            popoverOffset: 21,

            onPopoverRender: (popover, opts) => {
                const currentStep = opts.state.activeIndex;
                const totalSteps = opts.config.steps.length;
                const updateTourGuideStatus = async (tourModule) => {
                    await TourGuideConfigApi.updateApi({
                        "request_id": LocalStorage.uid(),
                        "module": tourModule,
                        "status": true
                    })
                }
                const title = document.createElement('div');
                title.style.display = 'flex';
                title.style.alignItems = 'center';
                title.style.marginBottom = '8px';

                const icon = document.createElement('img');
                icon.src = LeftNavigateArrow;
                icon.alt = 'Back Arrow';
                icon.style.width = '16px';
                icon.style.height = '16px';
                icon.style.marginRight = '2px';
                icon.style.cursor = 'pointer';
                if (currentStep !== 0) {
                    icon.onclick = (event) => {
                        const sidebarPopUp = document.getElementById('sidebarleftArrow');

                        event.stopPropagation();
                        if (opts.config.steps[currentStep - 1].popover.title == 'Invoices Dashboard') {
                            setContent('dashboard')
                            setTimeout(() => {
                                driverObjInvoice.movePrevious();
                            }, 300);
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Invoices') {
                            sidebarPopUp.click();
                            setTimeout(() => {
                                driverObjInvoice.movePrevious();
                            }, 300);
                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'View Client Details') {
                            setOpen(true);
                            setTimeout(() => {
                                driverObjInvoice.movePrevious();
                            }, 300);

                        }
                        else if (opts.config.steps[currentStep - 1].popover.title == 'Add Invoice') {
                            setOpen(false);
                            setContent('allinvoice')
                            setTimeout(() => {
                                driverObjInvoice.movePrevious();
                            }, 300);
                        }else if (opts.config.steps[currentStep - 1].popover.title == 'Add Employee'){
                            setContent('clientinvoice')
                            driverObjInvoice.movePrevious();
                        }
                        
                        else {
                            driverObjInvoice.movePrevious();
                        }
                    };
                    title.appendChild(icon);
                }
                const textContainer = document.createElement('div');
                textContainer.style.display = 'flex';
                textContainer.style.justifyContent = 'space-between';
                textContainer.style.width = '100%';
                const titleText = document.createElement('p');
                titleText.textContent = opts.config.steps[currentStep].popover.title;
                titleText.style.fontFamily = 'Quicksand';
                titleText.style.fontSize = '15px';
                titleText.style.fontWeight = '700';
                const stepSpan = document.createElement('span');
                stepSpan.textContent = `(${currentStep + 1}/${totalSteps})`;
                stepSpan.style.display = 'flex';
                stepSpan.style.alignItems = 'center';
                stepSpan.style.fontSize = '12px';
                stepSpan.style.fontWeight = '600';
                stepSpan.style.color = '#525252';
                stepSpan.style.fontFamily = 'Quicksand';
                textContainer.appendChild(titleText);
                textContainer.appendChild(stepSpan);
                title.appendChild(textContainer);

                const description = document.createElement('div');
                description.innerText = opts.config.steps[currentStep].popover.description;
                description.style.marginBottom = '24px';
                description.style.fontSize = '14px';
                description.style.fontWeight = '400';
                description.style.color = '#404040';
                description.style.fontFamily = 'Quicksand';

                const footer = document.createElement('div');
                footer.style.display = 'flex';
                footer.style.justifyContent = 'space-between';
                footer.style.width = '100%';
                footer.style.boxSizing = 'border-box';
                const applyInviteLinkBtnStyles = (element, text) => {
                    element.style.cursor = 'pointer';
                    element.style.textAlign = 'center';
                    element.style.fontSize = '12px';
                    element.style.fontFamily = 'Quicksand';
                    element.style.fontWeight = '600';
                    element.style.width = (text == 'finish') ? '100%' : '130px';
                    element.style.height = '32px';
                    element.style.border = '1.5px solid rgba(12, 117, 235, 1)';
                    element.style.borderRadius = '4px';
                    element.style.color = (text == 'skip') ? '#0C75EB' : '#ffffff';
                    element.style.marginRight = (text == 'skip') ? '10px' : '';

                    element.style.transition = 'all .3s ease';
                    element.style.outline = 'none';
                    element.style.backgroundColor = (text == 'skip') ? '#ffffff' : '#0C75EB';

                };
                const skipButton = document.createElement('button');
                skipButton.innerText = 'Skip';
                applyInviteLinkBtnStyles(skipButton, 'skip');

                skipButton.onclick = async () => {
                    LocalStorage.setStartTour({ 'invoice': false })
                    updateTourGuideStatus('INVOICE');
                    setStartTour();
                    driverObjInvoice.destroy();
                    if (opts.config.steps[currentStep].popover.title == 'Invoices Dashboard' || opts.config.steps[currentStep].popover.title == 'Invoices' ) {
                        const sidebar = document.getElementById('sidebarleftArrow');
                        sidebar.click()
                    }

                    if (LocalStorage?.getUserData()?.org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const finishButton = document.createElement('button');
                finishButton.innerText = 'Finish';
                applyInviteLinkBtnStyles(finishButton, 'finish');
                finishButton.onclick = () => {
                    LocalStorage.setStartTour({ 'invoice': false })
                    updateTourGuideStatus('INVOICE');
                    setStartTour();
                    driverObjInvoice.destroy();
                    if (LocalStorage?.getUserData()?.org_config_set == false) {
                        navigate('/configuration');
                    }
                };
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next';
                applyInviteLinkBtnStyles(nextButton, 'next');
                nextButton.onclick = () => {
                    const sidebarleftArrow = document.getElementById('sidebarleftArrow');

                    if (opts.config.steps[currentStep + 1].popover.title == 'Add Invoice') {
                        sidebarleftArrow.click();
                        setTimeout(() => {
                            driverObjInvoice.moveNext();
                        }, 300);
                        setContent('allinvoice');
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'View Client Details') {
                        setOpen(true);
                        setContent('clientinvoice');
                        setTimeout(() => {
                            driverObjInvoice.moveNext();
                        }, 500);
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Add Task') {
                        if(opts.config.steps[currentStep].popover.title == 'Add Invoice'){
                            setContent('clientinvoice')
                        }
                        setOpen(false);
                        setTimeout(() => {
                            driverObjInvoice.moveNext();
                        }, 300);

                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'View Invoice' ) {
                        if(opts.config.steps[currentStep].popover.title == 'Invoices'){
                            sidebarleftArrow.click();

                        }
                        setContent('viewinvoice')
                        window.scroll({
                            top: recentSection.current.scrollIntoView(),
                            behavior: 'smooth'
                        })
                        setTimeout(() => {
                            driverObjInvoice.moveNext();
                        }, 300);
                    }
                    else if (opts.config.steps[currentStep + 1].popover.title == 'Activity Log' ) {
                        
                        window.scroll({
                            top: recentSection.current.scrollIntoView(),
                            behavior: 'smooth'
                        })
                        setTimeout(() => {
                            driverObjInvoice.moveNext();
                        }, 300);
                    }
                    else {
                        driverObjInvoice.moveNext();
                    }
                };
                if (currentStep === totalSteps - 1) {
                    footer.appendChild(finishButton);
                } else {
                    footer.appendChild(skipButton);
                    footer.appendChild(nextButton);
                }
                popover.wrapper.innerHTML = '';
                const arrow = popover.arrow;
                if (arrow) {
                    popover.wrapper.appendChild(arrow);
                }
                popover.wrapper.appendChild(title);
                popover.wrapper.appendChild(description);
                popover.wrapper.appendChild(footer);

            }
        });

        if (destroyTour) {
            driverObjInvoice.destroy();
            setDestroyTour(false); // Reset the destroyTour state
        }else{
         if ((LocalStorage.getStartTour() && LocalStorage.getStartTour().invoice == true) && (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)) && LocalStorage.getUserData().admin_login == true) {
        const sidebar = document.getElementById('sidebarleftArrow');
        const sidebarbox = document.getElementById('sidebarbox');
        if (sidebarbox?.offsetWidth && sidebarbox?.offsetWidth == 94  ) {
            sidebar.click();
        }
        
        if(window.innerWidth < 900){
            if (sidebar ) {
                sidebar.click();
            }
        }
        
         setTimeout(() => {
            driverObjInvoice.drive();
        }, 500);
         }

         

        }
        return () => {
            driverObjInvoice.destroy();
        };
        // eslint-disable-next-line
    }, [destroyTour, setDestroyTour])

   

    
    return (
        <>

        {content == 'dashboard' && 
        <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} pr={4} py={4} gap={4} sx={{ pl: { xs: "16px", sm: "32px", md: "120px" }, overflow: "auto", '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none', }}>
 
            <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center' sx={{ pt: `0px !important`, pl: `0px !important` }}>
                <Grid lg={10} md={9} sm={6} xs={6}>
                    <Text smallLabelblack16>Invoice Dashboard</Text>
                </Grid>
               
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} columnSpacing={4} sx={{ pt: `0px !important`, pl: `0px !important` }}>
                {dataDash.map((item, index) => (
                    <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                        <Box alignItems='center' className={item.bgClass}>
                            <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                <Grid item lg={2} md={2} xs={3}>
                                    {/* <img loading="eager" src={item.icon} alt='clients' width='48' height='48' /> */}
                                </Grid>
                                <Grid item lg={10} md={10} xs={9}>
                                    <Text mediumBoldBlack>{item.label}</Text>
                                    <Text boldblack22>{item.amount}</Text>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Grid item container xs={12} lg={12} md={12} mt={3} spacing={2} columnSpacing={4} sx={{ pt: `0px !important`, pl: `0px !important` }}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '30px' }}>
                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                            <Grid item container lg={9} md={9} sm={6} xs={6} alignItems="center">
                                <Text largeBlack20>Unpaid Invoices</Text>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6} container justifyContent="flex-end">
                                <Text smallBlue1 >+ Add Invoice</Text>
                            </Grid>
                            <Grid item container xs={12} lg={12} md={12} alignItems="center" pt={3}>
                                <Grid item xs={6} lg={6} md={6} sm={6} sx={{ pb: { xs: "16px" }, }}>
                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                                        <Box sx={{ background: '#3772C6', height: '10px', width: '10px', borderRadius: '50%' }} />
                                        <Text mediumBlack600>Invoices Due</Text>
                                    </Box>
                                    <Text boldblack22>$52,000</Text>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6} sm={6} justifyContent="flex-end">
                                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                                        <Box sx={{ background: '#FF8C42', height: '10px', width: '10px', borderRadius: '50%' }} />
                                        <Text mediumBlack600>Invoices Overdue</Text>
                                    </Box>
                                    <Text boldblack22>$6,200</Text>
                                </Grid>
                            </Grid>
                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} pr={2}>
                                <Grid item lg={11} md={9} xs={9} >
                                    <Text mediumLabel>Overdue Breakdown</Text>
                                </Grid>
                                <Grid item container lg={1} md={3} xs={3} justifyContent="flex-end">
                                    <Text mediumLabel>Amount</Text>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} lg={12} md={12} spacing={2} mt={'2px'} p="0px 12px">
                                {invoiceData.map((data, index) => (
                                    <Box key={index} sx={{ background: '#F6F6F6', borderRadius: '6px', padding: '16px', width: '100%', mt: '16px' }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6} lg={9} md={3}>
                                                <Text largeBlue>{data.label}</Text>
                                            </Grid>
                                            <Grid item xs={6} lg={3} md={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Text greyColor16>{data.amount}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '30px' }}>
                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                            <Grid item container lg={9} md={9} sm={6} xs={6} alignItems="center" justifyContent="flex-start">
                                <Text largeBlack style={{ display: 'flex', alignItems: 'center' }}>
                                    Leading clients by payments received
                                    {/* <img src={Varient} alt="Varient" style={{ paddingLeft: '5px' }} /> */}
                                </Text>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6} container justifyContent="flex-end">
                                <Text smallBlue1 sx={{ cursor: 'pointer' }} >View All</Text>
                            </Grid>
                        </Grid>

                        <Grid item container lg={12} md={12} sm={12} xs={12} p={2}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} pl={1} spacing={2} alignItems="center">
                                <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start">
                                    <Text mediumLabel>S.no</Text>
                                </Grid>
                                <Grid item lg={4} md={4} sm={5} xs={3} container>
                                    <Text mediumLabel>Client</Text>
                                </Grid>
                                <Grid item lg={4} md={4} sm={3} xs={5} container>
                                    <Text mediumLabel>Placement</Text>
                                </Grid>
                                <Grid item lg={3} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                    <Text mediumLabel>Payment Received</Text>
                                </Grid>
                            </Grid>
                            <Divider sx={{ width: '100%', margin: '16px 0px 30px 0px', borderColor: '#EAECF0' }} />

                            {clientData.map(({ id, client, placement, payment }, index) => (
                                <React.Fragment key={id}>
                                    <Grid container spacing={2} alignItems="center" pl={1}>
                                        <Grid item lg={1} md={2} sm={1} xs={2} container justifyContent="flex-start" pb="10px">
                                            <Text black400>{id.toString().padStart(2, '0')}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={5} xs={3} container>
                                            <Text offBoardHeadFont>{client}</Text>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={3} xs={5} container>
                                            <Text offBoardHeadFont>{placement}</Text>
                                        </Grid>
                                        <Grid item lg={3} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                            <Text offBoardHeadFont>{payment}</Text>
                                        </Grid>
                                    </Grid>

                                    {/* Only render Divider if this is not the last item */}
                                    {index !== clientData.length - 1 && (
                                        <Divider sx={{ width: '100%', margin: '8px 0px 30px 0px', borderColor: '#EAECF0' }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            </Grid>

        }

            {content == 'allinvoice' && 
            <Box className={classes.flexBox} sx={{
                paddingLeft: {
                    xs: "0px",
                    sm: "0px",
                    md: "65px",
                }
            }}>
                <Box sx={{ width: "92%" }} >
                    <Box style={{ padding: { md: "20px 10px 10px 10px", sm: "0px" } }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <Box className={classes.tabBg}>
                                    <Box className={activeState == 'all-invoices' ? classes.ActiveBg : classes.inactiveBg} >
                                        {
                                            activeState == 'all-invoices' ?
                                                <Text largeWhite400>All Invoices</Text> :
                                                <Text blackHeader>All Invoices</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'draft' || data?.name == 'draft' ? classes.ActiveBg : classes.inactiveBg}>
                                        {
                                            activeState == 'draft' || data?.name == 'draft' ?
                                                <Text largeWhite400>Drafted</Text> :
                                                <Text blackHeader>Drafted</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'pending-for-approval' ? classes.ActiveBg : classes.inactiveBg}>
                                        {
                                            activeState == 'pending-for-approval' ?
                                                <Text largeWhite400>Pending for Approval</Text> :
                                                <Text blackHeader>Pending for Approval</Text>
                                        }
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Button id="addinvoice" style={{ width: '94%' }} addButton ><img src={Userplus} alt='plus' className={classes.addIcon} />Add Invoice</Button>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "28px 0px" }}>
                            <div>
                                <Box sx={{
                                    height: "44px", border: "1.5px solid #E2E5E6", width: searchWidth, borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center"
                                }}>
                                    <input
                                        type="text"
                                        value={filterState.search}
                                        className={classes.EmployeesSearchInput}
                                        placeholder="Search by employee name, client name & Invoice ID"
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            filterState.search !== "" ?
                                                <ClearIcon sx={{ cursor: "pointer" }} />
                                                :
                                                <img src={Search} alt="Search" />
                                        }
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box display='flex' flexDirection='row' gap={2} justifyContent='flex-end'>


                                    {/* <Box display='flex' flexDirection='row' gap={2} className={classes.quickbooksbg}>
                                        <img src={quickBooks} alt='quickbooks' />
                                        {
                                            openQuickbookArrow ?
                                                <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                <img src={arrowDown} aria-controls="status-menu" alt='quickbooks' className={classes.arrowSize} />
                                        }

                                    </Box>


                                    <Box display='flex' flexDirection='row' gap={2} className={classes.zohobooksbg}>
                                        <img src={zohoBooks} alt='zohobooks' />
                                        {
                                            openZohoArrow ?
                                                <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                <img src={arrowDown} alt='zohobooks' className={classes.arrowSize} />
                                        }

                                    </Box> */}
                                    <button title="Filter" type="button" className={classes.borderButton}

                                    >
                                        <FilterListIcon />
                                    </button>
                                    <button title="Export" type="button" className={classes.borderButton}

                                    >
                                        <img src={cloud} alt="Userplus" />
                                    </button>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    <TableContainer sx={{ overflow: 'scroll', width: '100%' }}>
                        <Table size="small" stickyHeader aria-label="collapsible table" sx={{
                            width: '100%',
                            // tableLayout: 'fixed',
                        }}
                        >
                            <Box style={{ padding: "10px" }}>
                                {/* header */}
                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                    "@media (min-width: 200px) and (max-width: 1400px)": {
                                        font: "12px Quicksand",
                                        fontWeight: "600",
                                        paddingTop: '1px'
                                    },
                                }} >
                                    <StyledTableRow>
                                        <StyledTableCell align="start" sx={{ minWidth: { xs: 250, sm: 300 } }}>Invoice Id
                                            <IconButton
                                                style={{
                                                    padding: '1px',
                                                    margin: '8px',
                                                    backgroundColor: '#ffffff',
                                                    border: '0',
                                                    minWidth: '22px',
                                                }}
                                            >
                                                <FilterListIcon sx={{ height: '19px', color: filterState.sort_column === "name" || filterState.sort_column === "created_at" ? "#2BEA2B" : "" }} />
                                            </IconButton>

                                        </StyledTableCell>
                                        <StyledTableCell align="start" sx={{ minWidth: 200 }}>Client Name</StyledTableCell>
                                        <StyledTableCell align="start" sx={{ minWidth: 150 }}>Invoice Date</StyledTableCell>
                                        <StyledTableCell align="start" sx={{ minWidth: 170 }} >
                                            <div>
                                                {/* <Text mediumBoldBlack2 >Status</Text> */}
                                                Status
                                                {
                                                    openStatusArrow ?
                                                        (activeState == 'all-invoices' && filter.status == '')
                                                            ? <img src={arrowUp} alt='up' className={classes.statusIcon} /> : ''
                                                        :
                                                        (activeState == 'all-invoices' && filter.status == '')
                                                            ? <img src={arrowDown} alt='down' className={classes.statusIcon} /> : ''
                                                }
                                            </div>


                                        </StyledTableCell>
                                        <StyledTableCell align="start" sx={{ minWidth: 200 }}>Invoice Amount</StyledTableCell>
                                        <StyledTableCell align="start" sx={{ minWidth: 200 }}>
                                            {
                                                ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px", paddingLeft: '5px' }}>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5} display={"flex"} justifyContent={"center"}>
                                                            <Text offBoardBodyFont>
                                                                Count

                                                                {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Employees`}</Text>
                                                        </Grid>
                                                    </div>
                                                    : ""
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                </Box>

                                <>
                                    {clientRows.length > 0 ?
                                        <Box sx={{
                                            width: '100%',
                                            height: "50.5vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                            paddingLeft: '5px', marginTop: "6px",
                                            "@media (min-height: 850px)": {
                                                height: '73.5vh'
                                            }
                                        }}
                                        >
                                            {
                                                activeState === 'all-invoices' && clientRows.length > 0 && clientRows.map((item, key) => (

                                                    <StyledTableRowBody>
                                                        <Accordion
                                                            key={key}
                                                            className={classes.newCustomAccordion}
                                                            expanded={expanded === `panel${key}`}
                                                            sx={{
                                                                border: expanded === `panel${key}` ? '1.5px solid #0C75EB' : 'none', // Add border only when expanded
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                // expandIcon={selectedOption === "All employees" && <ExpandMoreIcon style={{ '& svg path': { stroke: 'black', strokeWidth: 2 }, color: '#000000' }} />}
                                                                aria-controls="panel1bh-content"
                                                                id="panel1bh-header"
                                                                className={classes.AccordionSummary}
                                                                sx={{
                                                                    "& .MuiAccordionSummary-content": {
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    },
                                                                }}
                                                            >
                                                                <StyledTableCell align="start" sx={{ minWidth: { xs: 250, sm: 300 } }}>
                                                                    <Box display="flex" alignItems="center" gap={2} width={'50%'}>

                                                                        <Box>
                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                {item.name === "" ? "--" : capitalizeAndAddSpace(item.name).length > 16 ?
                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                                        {capitalizeAndAddSpace(item.name).slice(0, 16) + (capitalizeAndAddSpace(item.name).length > 16 ? "..." : "")}
                                                                                    </HtmlTooltip>
                                                                                    : capitalizeAndAddSpace(item.name)}

                                                                            </Text>
                                                                            <Text mediumLabel noWrap style={{ margin: '4px 0' }}>
                                                                                {item.reference_id === "" ? "--" : item.reference_id}
                                                                            </Text>
                                                                        </Box>
                                                                    </Box>
                                                                </StyledTableCell>

                                                                {/* Second Column */}
                                                                <StyledTableCell align="left" sx={{ minWidth: 200 }}>
                                                                    <Box display="flex" alignItems="center" gap={2} width={'50%'}>
                                                                        <Text mediumBlack14>{item.primary_contact ?
                                                                            capitalizeAndAddSpace(item.primary_contact).length > 20 ?
                                                                                <HtmlTooltip title={capitalizeAndAddSpace(item.primary_contact)} placement="right" arrow>
                                                                                    {capitalizeAndAddSpace(item.primary_contact).slice(0, 20) + (capitalizeAndAddSpace(item.primary_contact).length > 20 ? "..." : "")}
                                                                                </HtmlTooltip>
                                                                                : capitalizeAndAddSpace(item.primary_contact)
                                                                            : '--'}</Text>
                                                                    </Box>

                                                                </StyledTableCell>

                                                                {/* Third Column */}
                                                                <StyledTableCell align="left" sx={{ minWidth: 150 }}>
                                                                    <Text mediumBlack14>12/03/2025</Text>
                                                                </StyledTableCell>

                                                                

                                                                {/* Status Column */}
                                                                <StyledTableCell align="center" sx={{ minWidth: 200 }}>
                                                                    <Box className={item.status === 'Active' ? classes.activeStatus : classes.inactiveStatus}>
                                                                        {item.status === 'Active' ? 'Active' : 'Inactive'}
                                                                    </Box>
                                                                </StyledTableCell>

                                                                <StyledTableCell align="left" sx={{ minWidth: 170 }}>
                                                                    <Text mediumBlack14>{`$12345`}</Text>
                                                                </StyledTableCell>

                                                                {/* View Profile Column */}
                                                                <StyledTableCell align="center !important" sx={{ minWidth: 200, marginLeft: "30px" }}>
                                                                    <Text mediumBlue >View Profile</Text>
                                                                </StyledTableCell>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Box className={classes.AccordionDetails} pt={1}>
                                                                    <Box style={{ padding: { md: "20px 10px 10px 10px", sm: "0px" }, width: '92%' }}>

                                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                            <div>
                                                                                <Text mediumGrey2>Due Date</Text>
                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                    {
                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : (item.contact_email) : '--'
                                                                                    }
                                                                                </Text>
                                                                            </div>
                                                                            <div>
                                                                                <Text mediumGrey2>Received Amount</Text>
                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                    {
                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : (item.contact_email) : '--'
                                                                                    }
                                                                                </Text>
                                                                            </div>
                                                                            <div>
                                                                                <Text mediumGrey2>Balance Amount</Text>
                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                    {
                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : (item.contact_email) : '--'
                                                                                    }
                                                                                </Text>
                                                                            </div>
                                                                            <div>
                                                                                <Text mediumGrey2>Payment received on</Text>
                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                    {
                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : (item.contact_email) : '--'
                                                                                    }
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </StyledTableRowBody>

                                                ))}
                                        </Box>
                                        :
                                        clientRows.length == 0 ?
                                            <Box>
                                                {NoDataFound(`No data found`)}
                                            </Box>
                                            : ''

                                    }
                                </>

                            </Box>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>}


            {content == 'clientinvoice' && 
            <Grid container sx={{
                pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
            }}>
                <Grid item xl={12} pl={1} pb={2}>
                    <Text headerBlack>New Invoice</Text>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xl={3}>
                        <Box p={1}>
                            <Text headerBlack sx={{ paddingBottom: '10px' }}>Invoice Period</Text>
                            <Date
                                labelText={'From Date'}
                                name='from_date'
                                height='55px'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3}>
                        <Box p={1} pt={4.7}>
                            <Date
                                labelText={'To Date'}
                                name='from_date'
                                height='55px'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3}>
                        <Box p={1} pt={4.5}>
                            {/* <Search
                            name='client_id'
                            options=[{clientList}]
                            label='Client Name'
                            buttonName='New Client'
                           
                            showButton
                        /> */}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xl={9} xs={12}>
                    <Box p={1}>
                        <Box
                            sx={{
                                padding: 3,
                                borderRadius: 3,
                                backgroundColor: "#F9FAFA",
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                {/* Left Section */}
                                <Grid item sm={1} xs={12} p={1}>
                                    <Avatar
                                        src="https://via.placeholder.com/40"
                                        alt="Client Logo"
                                        sx={{
                                            width: 60,
                                            height: 60,
                                            border: "1px solid #FFD700",
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12} p={1}>
                                    <Text headerBlack>
                                        ABC DEF LLC
                                    </Text>
                                    <Text greyLabel sx={{ paddingTop: '10px' }}>
                                        CLT-47{" "}
                                        <Link
                                            href="#"
                                            underline="none"
                                            sx={{ color: "red", fontWeight: "bold" }}
                                        >
                                            4 Unpaid Invoices
                                        </Link>
                                    </Text>
                                </Grid>

                                {/* Right Section */}
                                <Grid item container justifyContent={'flex-end'} sm={3} xs={12} p={1}>
                                    <Link
                                        href="#"
                                        underline="none"
                                        sx={{
                                            color: "#0C75EB",
                                            fontWeight: "bold",
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        View Client Details &gt;
                                    </Link>
                                </Grid>
                            </Grid>

                            {/* Divider */}
                            <Box my={1}>
                                <Divider />
                            </Box>

                            {/* Address Section */}
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item sm={10} xs={12} p={1}>
                                    <Text black14>
                                        Client Address
                                    </Text>
                                    <Text greyLabel14 sx={{ paddingTop: '10px' }}>
                                        XYZ Building - 4 Floor No 2 USA 123456
                                    </Text>
                                </Grid>
                                <Grid item container justifyContent={'flex-end'} sm={2} xs={12} p={1}>
                                    <Link
                                        href="#"
                                        underline="none"
                                        sx={{
                                            color: "#0C75EB",
                                            fontSize: "0.775rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Edit
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xl={3}>
                        <Box p={1}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'invoice_id',
                                    type: 'text',
                                }}
                                clientInput
                                labelText={'Invoice ID'}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3}>
                        <Box p={1}>
                            <Date
                                labelText={'Due Date'}
                                name='from_date'
                                height='55px'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3}>
                        <Box p={1}>
                            {/* <Search
                            name='client_id'
                            options={clientList}
                            label='Client Name'
                            buttonName='New Client'
                            buttonHandler={() => {
                                navigate('/addClient', { state: { flow: 'placement' } })
                            }}
                            showButton
                        /> */}
                        </Box>
                    </Grid>
                    <Grid item xl={3}>
                        <Box p={1}>
                            <Date
                                labelText={'Due Date'}
                                name='from_date'
                                height='55px'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={12} xs={12}>
                        <Box p={1}>
                            <Box sx={{ margin: 0 }}>
                                <TableContainer sx={{ borderRadius: 3, overflowX: 'scroll' }}>
                                    <Table>
                                        <TableHead sx={{ background: '#EAECF0' }}>
                                            <TableRow>
                                                <TableCell><strong>Employee / Task</strong></TableCell>
                                                <TableCell><strong>Details</strong></TableCell>
                                                <TableCell><strong>Hours</strong></TableCell>
                                                <TableCell><strong>Bill Rate ($)</strong></TableCell>
                                                <TableCell><strong>OT Hours</strong></TableCell>
                                                <TableCell><strong>OT Bill Rate ($)</strong></TableCell>
                                                <TableCell><strong>Amount ($)</strong></TableCell>
                                                <TableCell align="center"><strong>Actions</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>
                                                        <Box display="flex" alignItems="center">
                                                            {row.avatar ? (
                                                                <Avatar src={row.avatar} alt={row.name} />
                                                            ) : (
                                                                <Avatar sx={{ bgcolor: "#e0e0e0" }}>
                                                                    <ListAltIcon />
                                                                </Avatar>
                                                            )}
                                                            <Typography sx={{ ml: 2 }}>
                                                                {row.name}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{row.details}</TableCell>
                                                    <TableCell>{row.hours}</TableCell>
                                                    <TableCell>${row.billRate}</TableCell>
                                                    <TableCell>{row.otHours.toFixed(2)}</TableCell>
                                                    <TableCell>${row.otBillRate.toFixed(2)}</TableCell>
                                                    <TableCell>${row.amount}</TableCell>
                                                    <TableCell>
                                                        <IconButton color="primary">
                                                            <img src={EditIcon} alt="edit" />
                                                        </IconButton>
                                                        <IconButton color="error">
                                                            <img src={DeleteIcon} alt="delete" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display="flex" justifyContent="space-between" mt={2}>
                                    <Button id='addtask' fullWidth sx={{ border: '1px solid #EAECF0' }}>
                                        + Add Task
                                    </Button>
                                    <Button fullWidth id='addemployee' sx={{ border: '1px solid #EAECF0' }}>
                                        + Add Employee
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>}

            {content == 'viewinvoice'  && 
            <Grid container ref={recentSection} sx={{
                pt:'70px',
                pl: { xs: "16px", sm: "32px", md: "130px" }, // Adjust padding-left
                pr: { xs: "8px", sm: "16px", md: "65px" },  // Adjust padding-right
            }}>
                <Box display='flex' justifyContent='space-between'>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Text className={classesDashboard.breadcrumbsLink}>Invoices</Text>
                        <Text className={classesDashboard.breadcrumbsName}>
                            Invoice History
                        </Text>
                    </Breadcrumbs>
                </Box>
                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} justifyContent={'center'} pt={2} alignItems='flex-start' sx={{
                 // Ensure the grid takes full viewport height
                    
                }}>


                    <Grid item container md={8} sm={12} xs={12} className={classesDashboard.container} sx={{ height: '100vh !important',overflowY:'none !important', }}>
                        <Grid item xs={12}  >
                            <Box className={classesDashboard.greyBg} sx={{ padding: '8px', height: "58px" }}>
                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }} pt={'9px'}>
                                        <img src={sentIcon} alt='sentIcon' style={{ marginRight: '8px' }} />
                                        <Text mediumBoldBlack style={{ marginRight: "8px" }}>INV - 167</Text>

                                    </Box>
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }} pt={'8px'}>
                                        {false ? "" :
                                            <>

                                                <Box
                                                    sx={{ cursor: 'pointer', marginRight: '12px', display: 'flex', alignItems: 'center' }}
                                                    id='activitylog'
                                                >
                                                    <img
                                                        src={activityLog}
                                                        alt="activitylog"
                                                    />
                                                </Box>
                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#E2E5E6 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                                <Box id='editinvoice'
                                                    sx={{ cursor: 'pointer', marginRight: '12px', marginLeft: '12px', display: 'flex', alignItems: 'center' }}
                                                >
                                                    <img
                                                        src={editIcon}
                                                        alt="editIcon"
                                                    />
                                                </Box>
                                                {/* <Box
                                    
                                    sx={{ cursor: 'pointer', marginRight: '12px', }}
                                >
                                    <img
                                        src={deleteIcon}
                                        alt="deleteIcon"
                                    />
                                </Box> */}
                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#E2E5E6 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                                <Box>
                                                    <Box
                                                        id="share"
                                                        aria-controls={'tourguide-menu'}
                                                        aria-haspopup="true"
                                                        aria-expanded={'true'}
                                                        sx={{ cursor: 'pointer', marginRight: '12px', marginLeft: '12px', display: 'flex', alignItems: 'center' }}

                                                    >
                                                        <img
                                                            src={sendEmailIcon}
                                                            alt="sendEmail"
                                                        />
                                                    </Box>

                                                </Box>
                                                <Box id="downlaod"
                                                    sx={{ cursor: 'pointer', marginRight: '12px', display: 'flex', alignItems: 'center' }}
                                                >
                                                    <img
                                                        src={downloadIcon}
                                                        alt="downloadIcon"
                                                    />
                                                </Box>
                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#E2E5E6 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                                <Box>
                                                    <Box
                                                        id="reminder"
                                                        aria-controls={'bellIcon-menu'}
                                                        aria-haspopup="true"
                                                        aria-expanded={'true'}
                                                        sx={{ cursor: 'pointer', marginRight: '12px', marginLeft: '12px', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <img
                                                            src={bellIcon}
                                                            alt="bellIcon"
                                                        />
                                                    </Box>

                                                </Box>
                                                <Box>
                                                    <Box
                                                        id="syncicon"
                                                        aria-controls={'syncIcon-menu'}
                                                        aria-haspopup="true"
                                                        aria-expanded={'true'}
                                                        sx={{ cursor: 'pointer', marginRight: '12px', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <img
                                                            src={syncIcon}
                                                            alt="syncIcon"
                                                        />
                                                    </Box>


                                                </Box>

                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#E2E5E6 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                            </>}
                                        <Grid sx={{
                                            marginLeft: '12px',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <Text offBoardHeadFont sx={{ fontSize: '14px !important', padding: '0px !important' }}>Show PDF View</Text>
                                        </Grid>
                                        <Grid item lg={1} md={1.5} sm={1.5} xs={12} sx={{ marginLeft: '12px', marginRight: '16px' }}>
                                            <ToggleSwitch name='is_active' sx={{ height: '24px !important', }} />
                                        </Grid>
                                        {false ?

                                            "" : <Grid sx={{ marginLeft: '10px' }}>

                                                {/* <Grid item > */}
                                                <Button addPayroll sx={{
                                                    borderRadius: '10px !important', backgroundColor: '#FFFFFF', border: '2px solid #C7CCD3 !important',
                                                    height: '30px',
                                                    padding: '0px 12px',
                                                    font: '12px Quicksand !important',
                                                    color: '#C7CCD3 !important',
                                                    fontWeight: `${400} !important`,
                                                    textTransform: 'Capitalize !important'
                                                }}>
                                                    <Text BlackExtraDark>
                                                        Cancel Write Off
                                                    </Text>
                                                </Button>
                                                {/* </Grid> */}
                                            </Grid>}
                                    </Grid>
                                </Grid>
                            </Box>
                                <Box id='viewinvoice'
                                    sx={{
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: 2,
                                        // height: '50vh',

                                        // overflowY: "auto",
                                        // "&::-webkit-scrollbar": {
                                        //     display: "none", // Hide scrollbar for WebKit browsers
                                        // },
                                        // "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer/Edge
                                        // "scrollbar-width": "none", // Hide scrollbar for Firefox
                                    }}
                                >
                                    <Box  sx={{
                                        padding: 4,
                                    }}>


                                        <Box sx={{
                                            border: '1px solid #FAFAFA',
                                            borderRadius: '12px',
                                            padding: 3,
                                        }}
                                            mb={3}>
                                            <Grid container spacing={3} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box display="flex" alignItems="center" gap={2}>
                                                            <Avatar
                                                                src={`${employee_URL + 'documents/avatar/male/21.png'}`}
                                                                alt={'logo'}
                                                                sx={{ height: '60px', width: '60px', borderRadius: '50%', background: '#e5b256' }}
                                                            />
                                                        </Box>
                                                        <Text blackHeader1 textAlign="right">
                                                            Invoice No
                                                            <br />
                                                            <Text black18px sx={{ fontSize: '12px !important' }}>#INV-167</Text>
                                                        </Text>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" >
                                                        <Box>
                                                            <Text blackHeader1>From: ABC DEF</Text>
                                                            <Text
                                                                mt={1}
                                                                largeLabel
                                                                sx={{
                                                                    fontWeight: '400 !important',
                                                                    fontSize: '13px !important',
                                                                    whiteSpace: 'normal',
                                                                    wordBreak: 'break-word',
                                                                    overflowWrap: 'break-word',
                                                                    width: '40%'
                                                                }}
                                                            >
                                                                1234 N, 56th St, XYZ Terrace, STATE, ABC States,12345 123-456-7890
                                                            </Text>
                                                        </Box>
                                                        <Box textAlign="right">
                                                            <Text blackHeader1>To: ABC DEF</Text>
                                                            <Text textAlign="right"
                                                                mt={1}
                                                                largeLabel
                                                                sx={{
                                                                    fontWeight: '400 !important',
                                                                    fontSize: '13px !important',
                                                                    whiteSpace: 'normal',
                                                                    wordBreak: 'break-word',
                                                                    overflowWrap: 'break-word',
                                                                    display: "inline-block", // Ensures text stays aligned properly
                                                                    textAlign: "right", // Keeps text right-aligned
                                                                    maxWidth: "40%" // Prevents unwanted wrapping issues
                                                                }}
                                                            >
                                                                121, XYZ, ABC XYZ Municipality, ABC Islands, 123456
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>


                                            <Divider sx={{ marginY: 2 }} />

                                            <Box display="flex" justifyContent="space-evenly" alignItems="center" mb={3}>
                                                <Box>
                                                    <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                                        Invoice Date: 12/03/2023
                                                    </Text>
                                                </Box>
                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#171717 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                                <Box>
                                                    <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                                        Terms: 7 Days
                                                    </Text>
                                                </Box>
                                                <Divider
                                                    sx={{
                                                        margin: "0px !important",
                                                        borderColor: "#171717 !important",
                                                        borderWidth: "1px !important",
                                                        height: '20px'
                                                    }}
                                                />
                                                <Box>
                                                    <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                                        Due Date: 19/03/2023
                                                    </Text>
                                                </Box>
                                            </Box>

                                            <Divider sx={{ marginY: 2 }} />
                                            <TableContainer sx={{ border: '1px solid #E2E5E6', borderRadius: '20px' }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: "5%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>S.No</Text></TableCell>
                                                            <TableCell sx={{ width: "30%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Details</Text></TableCell>
                                                            <TableCell sx={{ width: "8%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Hours</Text></TableCell>
                                                            <TableCell sx={{ width: "8%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Rate</Text></TableCell>
                                                            <TableCell sx={{ width: "10%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Hours</Text></TableCell>
                                                            <TableCell sx={{ width: "10%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>OT Rate</Text></TableCell>
                                                            <TableCell sx={{ width: "8%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}><Text blackHeader1 sx={{ fontSize: '13px !important' }}>Amount</Text></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rowData.map((row, index) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important' }}>
                                                                        {row.id}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', fontWeight: '700 !important' }}>
                                                                        {row.name}
                                                                    </Text>

                                                                    <Text mediumLabel sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>{row.details}</Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                        {row.hours}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                        {row.rate}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                        {row.otHours}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                        {row.otRate}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                                                    <Text blackHeader1 sx={{ fontSize: '13px !important', textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                                        {row.amount}
                                                                    </Text>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            {/* <Box mt={3} display="flex" justifyContent="flex-end" mb={4}>
                                                <Box>
                                                    <Box display="flex" justifyContent="space-between" gap={3}>
                                                        <Text greysmallLabel>Sub Total</Text>
                                                        <Text black400 sx={{ fontSize: '12px !important' }}>$ 2500.00</Text>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                                        <Text greysmallLabel>Total</Text>
                                                        <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ 2500.00</Text>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" gap={3} mt={1}>
                                                        <Text greysmallLabel>Payment Made</Text>
                                                        <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>- $ 2000.00</Text>
                                                    </Box>
                                                    <Divider sx={{ marginY: 1 }} />
                                                    <Box display="flex" justifyContent="space-between" gap={3}>
                                                        <Text greysmallLabel>Balance Due:</Text>
                                                        <Text veryLargeLabel sx={{ fontSize: '12px !important' }}>$ 5000.00</Text>
                                                    </Box>
                                                </Box>

                                            </Box>
                                            <Box mt={3} display="flex" justifyContent="flex-start" mb={3}>
                                                <Box>
                                                    <Box>
                                                        <Text popupHead1 sx={{ fontSize: '14px !important' }}>Documents:</Text>
                                                        <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} >
                                                            <Text mediumBlue>
                                                                abcdefg
                                                            </Text>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Box> */}
                                        </Box>
                                    </Box>
                                    
                                    {/* <Box
                                        sx={{
                                            border: '1px solid #FAFAFA',
                                            borderRadius: '12px',
                                            padding: 3,
                                        }}
                                        mb={3}
                                    >
                                        <Text mb={4} veryLargeLabel sx={{ fontSize: '16px !important' }}>
                                            Terms & Conditions
                                        </Text>

                                        {terms.map((term, index) => (
                                            <Box key={index} sx={{ marginBottom: 2 }}>
                                                <Text largeLabel sx={{ fontWeight: '700 !important', fontSize: '13px important', }} mb={1.5}>
                                                    {term.title}
                                                </Text>
                                                <Text largeLabel sx={{ fontWeight: '400 !important', fontSize: '13px important', }}>{term.description}</Text>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box sx={{
                                        border: '1px solid #FAFAFA',
                                        borderRadius: '12px',
                                    }}
                                        mb={3}>
                                        <Text veryLargeLabel sx={{ fontSize: '15px !important' }} m={2}>Attachments</Text>
                                        <Box >

                                        </Box>
                                    </Box> */}

                                </Box>
                            </Grid>
                        </Grid>


                    <Grid item container lg={4} md={4} sm={12} xs={12} p={'0px 10px 0px 20px'}>
                        <Grid
                            item
                            container
                            lg={12}
                            sm={12}
                            xs={12}
                            pt={2}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box display="flex" alignItems="center">
                                <Button
                                    style={{
                                        backgroundColor: "#0C75EB",
                                        borderRadius: "8px 0px 0px 8px",
                                        font: "12px Quicksand !important",
                                        width: "150px",
                                        height: "40px",
                                        color: "#FFFFFF",
                                        fontWeight: "400",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Client History
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#E2E5E6",
                                        borderRadius: "0px 8px 8px 0px",
                                        font: "12px Quicksand !important",
                                        width: "80px",
                                        height: "40px",
                                        color: "#404040",
                                        fontWeight: "400",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    All
                                </Button>
                            </Box>
                            <Box>
                                <Box
                                    id="tourguide"
                                    aria-controls={'tourguide-menu'}
                                    aria-haspopup="true"
                                    aria-expanded={'true'}
                                    sx={{ cursor: 'pointer' }}
                                >

                                </Box>


                            </Box>

                        </Grid>

                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                            <Box className={classesDashboard.cardContainer} sx={{ height: cilentHistory?.length < 5 ? "fit-content !important" : "58vh !important", scrollbarWidth: 'none !important', }}>
                                <Grid item lg={12} xs={12} sx={{ height: "fit-content !important" }} >
                                    {cilentHistory?.length > 0 ?
                                        cilentHistory?.map((item) => (
                                            <Grid
                                                key={item.id}
                                                container
                                                ml={0.5}
                                                lg={11}
                                                xs={12}
                                                sx={{
                                                    boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                    borderRadius: '8px',
                                                    marginBottom: '10px !important',
                                                    cursor: 'pointer',

                                                }}
                                            >
                                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} spacing={{ lg: 0, md: 1, sm: 0, xs: 1 }}>
                                                    <Grid item lg={2} md={3} sm={2} xs={4}>
                                                        <Avatar
                                                            src={`${employee_URL + 'documents/avatar/male/25.png'}`}
                                                            alt={item.name}
                                                            sx={{ height: '40px', width: '40px', borderRadius: '50%', background: '#e5b256' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={5} md={8} sm={7} xs={8}>
                                                        <Text dashboardblack14>{item.client_name}</Text>
                                                        <Text greysmallLabel>{item.contact_name}</Text>
                                                    </Grid>
                                                    <Grid item lg={5} md={12} sm={3} xs={12} justifyContent="flex-end"
                                                        textAlign="right">
                                                        <Text mediumBlue600>{item.reference_id}</Text>
                                                        <Text dashboardblack14>${item.total_amount}</Text>
                                                    </Grid>
                                                    <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                                    <Grid item lg={7} md={6} sm={6} xs={6}>
                                                        <Box display="flex" alignItems="center">
                                                            <Text greysmallLabel sx={{ marginRight: "8px" }}>Date</Text>
                                                            <Text greysmallLabel>{item.invoice_date}</Text>
                                                        </Box>

                                                    </Grid>
                                                    <Grid item container lg={5} md={6} sm={6} xs={6} className={classesDashboard.textCenter} alignItems='center' justifyContent='end'>
                                                        <Text mediumBlue600 sx={{ color: `${item.color} !important` }}>
                                                            {item.invoice_status}
                                                        </Text>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )) : 'No Data Found'}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>}

            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={() => setOpen(false)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                        width: { xs: '90% !important', sm: '50% !important', md: '40% !important' },
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <Box sx={{ padding: 2 }} >
                    {/* Top Section */}
                    <Box id='viewclientdetails'>
                        <Box
                            sx={{
                                padding: 3,
                                borderRadius: 3,
                                backgroundColor: "#F9FAFA",
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={3} sm={3} md={1.5}>
                                    <Avatar
                                        src={`${employee_URL + 'documents/avatar/male/25.png'}`}
                                        alt={'logo'}
                                        sx={{ height: '40px', width: '40px', borderRadius: '50%', background: '#e5b256' }}
                                    />
                                </Grid>
                                <Grid item xs={9} sm={9} md={10}>
                                    <Text blackHeader600 sx={{ paddingBottom: '10px' }}>ABC DEFG LLC</Text>
                                    <Text largeLabel>
                                        CLT-47
                                    </Text>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Box my={1} sx={{ width: '100%' }}>
                                        <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                                    </Box>
                                </Grid>
                                <Grid item container md={12} sm={12} xs={12}>
                                    <Grid item md={6} sm={12}>
                                        <Box display="flex" alignItems="center">
                                            <PermIdentityIcon sx={{ marginRight: 1 }} />
                                            <Text largeLabel>
                                                ABCD EFGH
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box display="flex" alignItems="center">
                                            <MailOutlineIcon sx={{ marginRight: 1 }} />
                                            <Text largeLabel>
                                                abc.efgh@example.com
                                            </Text>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>


                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                            <Tabs sx={{ width: '100%', display: 'flex', justifyContent: 'space-around !important' }} value={0} aria-label="basic tabs example">
                                <Tab label={<Text largeLabel sx={{ color: true ? '#0C75EB !important' : '#525252 !important', textTransform: "capitalize !important" }}>Details </Text>} />
                                <Tab label={<Text largeLabel sx={{ color: '#525252 !important', textTransform: "capitalize !important" }}>Employees </Text>} />
                                <Tab label={<Text largeLabel sx={{ color: '#525252 !important', textTransform: "capitalize !important" }}>Unpaid Invoices </Text>} />
                                <Tab label={<Text largeLabel sx={{ color: '#525252 !important', textTransform: "capitalize !important" }}>Activity Log </Text>} />

                            </Tabs>
                        </Box>


                        <Grid container spacing={2} pt={1}>
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        backgroundColor: '#FFF7E6',
                                        padding: 2,
                                        textAlign: 'center',
                                        borderRadius: 2,
                                    }}
                                >
                                    <Text largeLabel sx={{ paddingBottom: '10px' }}>Outstanding Invoices</Text>
                                    <Text blackHeader600>10</Text>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        backgroundColor: '#F3F0FF',
                                        padding: 2,
                                        textAlign: 'center',
                                        borderRadius: 2,
                                    }}
                                >
                                    <Text largeLabel sx={{ paddingBottom: '10px' }}>Outstanding Receivables</Text>
                                    <Text blackHeader600>$ 500,000</Text>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Client Details */}
                        <Box sx={{ padding: 3 }}>
                            <Text boldBlackfont16 sx={{ paddingBottom: '15px' }}>
                                Client Details
                            </Text>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Address Line 1
                                    </Text>
                                    <Text mediumBlack14>13 Abc efg Street</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Address Line -2 (Optional)
                                    </Text>
                                    <Text mediumBlack14>5th floor</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Country
                                    </Text>
                                    <Text mediumBlack14>ABC</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        State
                                    </Text>
                                    <Text mediumBlack14>Abcdefgh</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        City
                                    </Text>
                                    <Text mediumBlack14>MNOP</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Zip Code
                                    </Text>
                                    <Text mediumBlack14>123456</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                        Pay Date
                                    </Text>
                                    <Text mediumBlack14>02-01-2025</Text>
                                </Grid>
                            </Grid>



                            <Grid item container xs={12} pt={2}>
                                <Box my={1} sx={{ width: '100%' }}>
                                    <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                                </Box>
                            </Grid>

                            {/* Invoice Configuration */}
                            <Grid item container xs={12}>
                                <Box sx={{ marginTop: 2 }}>
                                    <Text boldBlackfont16 sx={{ paddingBottom: '15px' }}>Invoice Configuration</Text>
                                    <Grid item xs={12}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Payment Terms
                                        </Text>
                                        <Text mediumBlack14>30</Text>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item container xs={12} pt={2}>
                                <Box my={1} sx={{ width: '100%' }}>
                                    <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                                </Box>
                            </Grid>
                        </Box>
                        <Box sx={{ padding: 3 }}>
                            <Grid container xs={12} pt={2}>
                                <Text boldBlackfont16 sx={{ padding: '15px', paddingLeft: '0px' }}>
                                    Contact Details - 1 {`(Primary)`}
                                </Text>
                                <Grid container spacing={2} pb={2}>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            First Name
                                        </Text>
                                        <Text mediumBlack14>ABC</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Middle Name
                                        </Text>
                                        <Text mediumBlack14>DEF</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Last Name
                                        </Text>
                                        <Text mediumBlack14>GHI</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Email ID
                                        </Text>
                                        <Text mediumBlack14>abcsfsd@gmail.com</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Phone Number
                                        </Text>
                                        <Text mediumBlack14>789-989-9999</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Extension
                                        </Text>
                                        <Text mediumBlack14>+1 777-999-0000</Text>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Text mediumGreyHeader sx={{ paddingBottom: '5px' }}>
                                            Mobile Number (Optional)
                                        </Text>
                                        <Text mediumBlack14>888-999-0000</Text>
                                    </Grid>
                                </Grid>
                            </Grid></Box>
                    </Box>
                </Box>
            </SwipeableDrawer>


        </>
    )
}