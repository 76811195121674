import { Avatar, Box, Divider, Grid } from "@mui/material"
import Text from "../../../components/customText/Text";
import Button from '../../../components/customButton/Button';
import PersonIcon from '../../../assets/svg/personicon.svg';
import MailIcon from '../../../assets/svg/mailicon.svg';
import ViewInvoiceStyles from "./ViewInvoiceStyles";
import Input from "../../../components/input/Input";
import { useEffect, useState } from "react";
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, BlackToolTip, capitalizeAndAddSpace, removeLoader } from "../../../utils/utils";
import LocalStorage from "../../../utils/LocalStorage";
import { isValid, validate_emptyField } from "../../../components/Validation";
import InvoiceCreateAPI from "../../../apis/admin/invoice/InvoiceHistoryCilentAPI";



const WriteoffDrawer = ({ writeoffDrawer, setWriteoffDrawer, apix, callRefetch }) => {
    const classes = ViewInvoiceStyles();
    const currency = LocalStorage.getUserData().currency_symbol ? LocalStorage.getUserData().currency_symbol : "$";

    const [error, setError] = useState({});
    // eslint-disable-next-line

    const [state, setState] = useState({
        reason_for_writeoff: ''
    })

    useEffect(() => {
        if (!writeoffDrawer) {
            setState({
                reason_for_writeoff: ''
            })
            setError({});
        }
    }, [writeoffDrawer])

    const handleChange = (e) => {
        setState(
            {
                ...state,
                [e.target.name]: e.target.value,
            },
            handleValidate(e)
        );
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "reason_for_writeoff":
                error.reason_for_writeoff = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    };


    const validateAll = () => {
        const { reason_for_writeoff } = state
        error.reason_for_writeoff = validate_emptyField(reason_for_writeoff);
        setError({ ...error })
        return error;
    }


    const handleSaveEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            //call api
            const data = {
                request_id: LocalStorage.uid(),
                invoice_id: apix.id,
                status: 'Write Off',
                reason: state.reason_for_writeoff
            }
            addLoader(true);
            InvoiceCreateAPI.voidWriteOffApi(data).then((response) => {
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                    setWriteoffDrawer();
                    callRefetch();
                    removeLoader();
                }
                else {
                    removeLoader();
                    addErrorMsg(response.data.message)
                }
            })
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }



    return (
        <div>
            <Box width={{ lg: '608px', md: "608px", sm: "508px", xs: "310px" }} height={'100vh'} p={4} sx={{ position: 'relative' }}>
                <Grid gap={2} container >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text mediumBlack14>Payment for {apix?.reference_id}</Text>
                    </Grid>
                    <Grid item container className={classes.recordDetails} lg={12} md={12} sm={12} xs={12}>
                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12}>
                            <Grid container lg={6} md={6} sm={6} xs={12} >
                                <Box display='flex' alignItems='center' gap={2} >
                                <Avatar src={apix?.to_details?.[0]?.logo_url == ''
                                        || apix?.to_details?.[0]?.logo_url == null ?
                                        capitalizeAndAddSpace(apix?.to_details?.[0]?.name[0]) :
                                        apix?.to_details?.[0]?.logo_url} alt={capitalizeAndAddSpace(apix?.to_details?.[0]?.name)}
                                        sx={{
                                            height: '35px', width: '35px', borderRadius: '50% !important',
                                            background: apix?.to_details?.[0]?.logo_url ? '' : '#e5b256 !important'
                                        }} />
                                    <Box>
                                        <Text mediumBlack14 mb={1}>
                                            {apix?.to_details?.[0]?.name && apix?.to_details?.[0]?.name.length > 16 ?
                                                <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.name)} placement="top" arrow>
                                                    {capitalizeAndAddSpace(apix?.to_details?.[0]?.name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.name.length > 16 ? "..." : ""))}
                                                </BlackToolTip>
                                                : apix?.to_details?.[0]?.name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.name) : '--'
                                            }
                                        </Text>

                                        <Text mediumLabel >{apix?.to_details?.[0]?.client_id ? apix?.to_details?.[0]?.client_id : '--'}</Text>

                                    </Box>
                                </Box>
                            </Grid>

                            <Grid container lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <Box display='flex' flexDirection={'column'} alignItems='start' >
                                    <Text mediumBlack14 mb={1} marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }}>Invoice Amount</Text>

                                    <Text mediumLabel marginLeft={{ lg: 'auto', md: 'auto', sm: 'auto', xs: '' }} > {`${currency} ${apix?.total_amount ? apix?.total_amount : '--'}`}</Text>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Divider style={{ width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important' }} />
                        </Grid>

                        <Grid item container alignItems={"center"} lg={12} md={12} sm={12} xs={12} >
                            <Grid item container lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
                                <img src={PersonIcon} alt='person' style={{ height: '16px', width: '16px', marginRight: '8px' }} />
                                <Text mediumLabel >{apix?.to_details?.[0]?.poc_name && apix?.to_details?.[0]?.poc_name.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_name ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_name) : '--'
                                }</Text>

                            </Grid>
                            <Grid item container alignItems={"center"} lg={6} md={6} sm={6} xs={12} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }}>
                                <img src={MailIcon} alt='person' style={{ height: '16px', width: '16px' }} />
                                <Text mediumLabel sx={{ marginLeft: '12px' }}>{apix?.to_details?.[0]?.poc_email && apix?.to_details?.[0]?.poc_email.length > 16 ?
                                    <BlackToolTip title={capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email)} placement="top" arrow>
                                        {capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email).slice(0, 16) + (capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email.length > 16 ? "..." : ""))}
                                    </BlackToolTip>
                                    : apix?.to_details?.[0]?.poc_email ? capitalizeAndAddSpace(apix?.to_details?.[0]?.poc_email) : '--'
                                }</Text>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} >
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>

                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'reason_for_writeoff',
                                    value: state.reason_for_writeoff,
                                    inputProps: { maxLength: 255 }
                                }}
                                labelText={'Reason For Writeoff'}
                                handleChange={handleChange}
                                rows={4}
                                multiline={true}
                                // descriptionFormControl1
                                // descriptionInput
                                commentsField
                                error={error.reason_for_writeoff ? <Text red>{error.reason_for_writeoff ? error.reason_for_writeoff : ''}</Text> : ''}
                            />
                            {error.reason_for_writeoff ? <Text red>{error.reason_for_writeoff ? error.reason_for_writeoff : ''}</Text> : ''}

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ position: 'absolute', bottom: '33px', width: '92%' }} alignItems={'end'} justifyContent={{ lg: 'end', md: 'end', sm: 'end', xs: 'start' }} spacing={2} >
                    <Grid item >
                        <Button blackCancelBtn onClick={setWriteoffDrawer}>Cancel</Button>
                    </Grid>
                    <Grid item >
                        <Button blueButton onClick={handleSaveEdit}>Save</Button>
                    </Grid>
                </Grid>

            </Box>
        </div>

    )
}

export default WriteoffDrawer