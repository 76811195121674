import React, { useRef } from 'react';
import ClientStyles from './ClientStyles';
import { Box, Grid, Menu, MenuItem, Skeleton, Typography, Stack, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, Tooltip, tooltipClasses, Checkbox, Avatar, Hidden } from '@mui/material';
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import Search from '../../../assets/svg/search1.svg';
import cloud from '../../../assets/svg/download-cloud-blue.svg';
import Userplus from '../../../assets/svg/clients/user-plus-bold.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';
import VendorApi from '../../../apis/admin/clients/VendorApi';
import EndClientApi from '../../../apis/admin/clients/EndClientApi';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import deactivateImg from '../../../assets/client/deactivateImg.svg';
import CustomButton from '../../../components/customButton/Button';
import LocalStorage from '../../../utils/LocalStorage';
import { BlackToolTip, NoDataFound, addErrorMsg, NoPermission, addLoader, addSuccessMsg, capitalizeAndAddSpace, removeLoader, addLoaderPlanExpired, removeExpiredLoader, ListLoadingIcon } from '../../../utils/utils';
import { styled } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import arrowDown from '../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../assets/svg/clients/blackarrowUp.svg';
import Component87 from '../../../assets/svg/Component87.svg';
import Pending from '../../../assets/employee/Orange-PendingIcon.svg';
import info from '../../../assets/svg/clients/grey-info-bold.svg';
import CustomCircularProgress from '../../../components/progressbar/CircularProgress';
import FileSaver from 'file-saver';
import greenUp from '../../../assets/svg/clients/greenupArrow.svg'
import greendown from '../../../assets/svg/clients/greendownArrow.svg'
import ClearIcon from '@mui/icons-material/Clear';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { domain } from '../../../config/Domain';
import TourClients from '../../../components/tourGuideDetails/TourClient';
import TourGuideConfigApi from '../../../apis/configurations/tourguideConfig/TourGuideConfigApi';
import quickBooks from '../../../assets/svg/QuickbooksSync.svg'
import zohoBooks from '../../../assets/svg/ZohobooksSync.svg'
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import zohosyncIcon from '../../../assets/svg/zohosyncIcon.svg';
import LoadingButton from '../../../components/customButton/LoadingButton';
import CommonApi from '../../../apis/CommonApi';
import { filterStorage } from '../../../utils/FilterStorage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function ClientsDashboard() {
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#696969",
            padding: "6px 14px",
            minWidth: 100,
            border: "1px solid #696969",
            borderRadius: '4px'
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#696969",
            "&::before": {
                backgroundColor: "#696969",
                border: "1px solid #696969"
            }
        },
    }));

    const classes = ClientStyles();
    const navigate = useNavigate();
    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [anchorEl, setAnchorEl] = useState(null);
    const [openStatusArrow, setOpenStatusArrow] = useState(false);
    const open = Boolean(anchorEl);
    const data = location && location.state
    const [clientRows, setClientRows] = useState([]);
    const [vendorRows, setVendorRows] = useState([]);
    const [endCltRows, setEndCltRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [activeState, setActiveState] = useState('clients');
    const [startTour, setStartTour] = useState(false);
    const messagesEndRef = useRef(null);
    const [openQuickbookArrow, setOpenQuickbookArrow] = useState(false);
    const [openZohoArrow, setOpenZohoArrow] = useState(false);
    const [syncQuickbooksList, setSyncQuicbooksList] = useState(false);
    const [syncZohoList, setSyncZohoList] = useState(false);
    const [searchInvoice, setSearchInvoice] = useState('');
    const [searchZoho, setSearchZoho] = useState('');
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedClientsZoho, setSelectedClientsZoho] = useState([]);
    const [syncClients, setSyncClients] = useState([]);
    const [QBButtonLoader, setQBButtonLoader] = useState(false);
    const [ZHButtonLoader, setZHButtonLoader] = useState(false);
    const [connectedAppsList, setConnectedAppsList] = useState({
        isZohoIntegrated: false,
        isQuickBookIntegrated: false
    })
    const [destroyTour, setDestroyTour] = useState(false); // State to trigger tour destruction


    const [filter, setFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: "",
        scrollId: "",
    })
    const [endFilter, setEndFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: "",
        scrollId: "",
    })
    const [vendorFilter, setVendorFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: "",
        scrollId: "",
    })
    // const [loader, setLoader] = useState(false);
    const scrollBox = useRef(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const checkHandler = (e, args, val) => {
        const checked = e.target.checked;
        if (args == 'all') {
            if (checked) {
                let ids = syncClients.map((client) => client.id)
                setSelectedClients([...ids]);
            } else {
                setSelectedClients([]);
            }
        } else {
            const updatedArray = [...selectedClients];
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncClients.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.id);
                }
            }
            setSelectedClients([...updatedArray]);
        }
    }

    const checkHandlerZoho = (e, args, val) => {
        const checked = e.target.checked;
        if (args == 'all') {
            if (checked) {
                let ids = syncClients.map((client) => client.id)
                setSelectedClientsZoho([...ids]);
            } else {
                setSelectedClientsZoho([]);
            }
        } else {
            const updatedArray = [...selectedClientsZoho];
            const finalValue = val.id;
            const index = updatedArray.findIndex(item => item === finalValue);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            } else {
                const newItem = syncClients.find(item => item.id == finalValue);
                if (newItem) {
                    updatedArray.push(newItem.id);
                }
            }
            setSelectedClientsZoho([...updatedArray]);
        }
    }

    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 10,
            totalPages: ""
        }
    );

    const [vendorPagination, setVendorPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );
    const [endClientPagination, setEndClientPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );

    const [listLoading, setListLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (data && data.page == 'vendors') {
            setActiveState('vendors');
        } else if (data && data.page == 'end-Client') {
            setActiveState('end-clients')
        } else {
            setActiveState('clients')
        }
        const vendorAppliedFilter = filterStorage.getFilter('vendors');
        if (vendorAppliedFilter && vendorAppliedFilter !== null && vendorAppliedFilter !== undefined) {
            setVendorFilter(vendorAppliedFilter);
            setActiveState('vendors');
            vendorsListingApi(vendorAppliedFilter, vendorPagination,vendorAppliedFilter.scrollId);
        } else {
            vendorsListingApi(vendorFilter, vendorPagination);
        }
        const clientsAppliedFilter = filterStorage.getFilter('clients');
        if (clientsAppliedFilter && clientsAppliedFilter !== null && clientsAppliedFilter !== undefined) {
            setFilter(clientsAppliedFilter);
            setActiveState('clients');
            clientListingApi(clientsAppliedFilter, pagination,clientsAppliedFilter.scrollId);
        } else {
            clientListingApi(filter, pagination);
        }
        const endClientAppliedFilter = filterStorage.getFilter('end-clients');
        if (endClientAppliedFilter && endClientAppliedFilter !== null && endClientAppliedFilter !== undefined) {
            setEndFilter(endClientAppliedFilter);
            setActiveState('end-clients');
            EndCltListingApi(endClientAppliedFilter, endClientPagination,endClientAppliedFilter.scrollId);
        } else {
            EndCltListingApi(endFilter, endClientPagination);
        }

        navigate("/clients", { replace: true })
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        getPlanCheck();
        connectedApps();
        const handleResize = () => {
            setStartTour(false); // Stop the tour on resize
            LocalStorage.setStartTour({ 'companies': false });
            setDestroyTour(true);

        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    }, []);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const scrollBox = document.querySelector(`.${classes.scrollBox}`);
            if (scrollBox) {
                const elementPosition = element.offsetTop; // Getting the element's position inside the container
                const containerPosition = scrollBox.offsetTop; // Getting the scroll container's position
                const offset = 100; // Increase offset to move the element further down
          
                scrollBox.scrollTo({
                  top: elementPosition - containerPosition - offset,
                  behavior: "smooth",
                });
            }
        }
    };


    const connectedApps = () => {
        CommonApi.connectedApps().then((res) => {
            if (res.data.statusCode === 1003) {
                setConnectedAppsList(res.data.data);
            }
        })
    }

    const getTourGuideDetails = async () => {
        let response = await TourGuideConfigApi.getApi();
        if (((LocalStorage.getStartTour() && LocalStorage.getStartTour().companies == true) || (response.data.statusCode == 1003 && response.data.data?.COMPANIES == false)) && LocalStorage.getUserData().admin_login == true) {
            LocalStorage.setStartTour({ 'companies': true });
            setStartTour(true);
        }

    }

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                    filterStorage.clearAllFilters();
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
                if (!res.data.plan_not_set && !res.data.plan_expired) {
                    if (LocalStorage.getUserData().admin_login && (rolePermission !== "" &&
                        (rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true)
                            || rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true)
                            || rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true)))
                    ) {
                        getTourGuideDetails();
                    } else {
                        LocalStorage.setStartTour({ 'companies': false });
                    }
                }
            }
        })
    }

    const download = () => {
        if ((activeState == 'clients' && clientRows.length == 0) || (activeState == 'vendors' && vendorRows.length == 0) || (activeState == 'end-clients' && endCltRows.length == 0)) {
            addErrorMsg('No data to export');
            return false;
        }
        addLoader(true);
        ClientsApi.downloadExport(activeState == 'clients' ? 'client' : activeState == 'vendors' ? 'vendor' : activeState == 'end-clients' ? 'end-client' : '').then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                FileSaver.saveAs(res.data.data[0].file_path);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const viewAnalytics = () => {
        LocalStorage.setAnalyticsRoute('client')
        navigate('/client/timesheets-analytics', { state: { activeState: 'client', from: 'client' } })
        filterStorage.clearAllFilters();
    }
    // eslint-disable-next-line
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const loadMore = () => {
        if (activeState == "clients") {
            // addLoader(true)
            setListLoading(true);
            filter.limit = Number(filter.limit) + 6;
            filter.scrollId='';
            setFilter({ ...filter });
            clientListingApi(filter, pagination);
            filterStorage.setFilter("clients", filter)
        } else if (activeState == 'vendors') {
            vendorFilter.limit = Number(vendorFilter.limit) + 6;
            vendorFilter.scrollId='';
            setVendorFilter({ ...vendorFilter });
            // addLoader(true)
            setListLoading(true);
            vendorsListingApi(vendorFilter, vendorPagination);
            filterStorage.setFilter("vendors", vendorFilter)
        } else {
            endFilter.limit = Number(endFilter.limit) + 6;
            endFilter.scrollId='';
            setEndFilter({ ...endFilter });
            // addLoader(true)
            setListLoading(true);
            EndCltListingApi(endFilter, endClientPagination);
            filterStorage.setFilter("end-clients", endFilter);
        }
    }

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (activeState == 'clients') {
                    if (Number(pagination.total) >= filter.limit) {
                        loadMore();
                    } else {
                        // setLoader(false);
                        setLoading(false);
                    }
                } else if (activeState == 'vendors') {
                    if (Number(vendorPagination.total) >= vendorFilter.limit) {
                        loadMore();
                    } else {
                        // setLoader(false);
                        setLoading(false);
                    }
                } else if (activeState == 'end-clients') {
                    if (Number(endClientPagination.total) >= endFilter.limit) {
                        loadMore();
                    } else {
                        // setLoader(false);
                        setLoading(false);
                    }
                }
            }
        }
    };

    const handleClick = (event, args) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setExpanded(false);
        if (activeState == "clients") {
            filter.sort_column = sort_column;
            filter.sort_order = sort_order;
            filter.scrollId = '';
            setFilter({ ...filter });
            setLoading(true);
            clientListingApi(filter, pagination);
            filterStorage.setFilter("clients", filter)

        } else if (activeState == "vendors") {
            vendorFilter.sort_column = sort_column;
            vendorFilter.sort_order = sort_order;
            vendorFilter.scrollId = '';
            setVendorFilter({ ...vendorFilter });
            setLoading(true);
            vendorsListingApi(vendorFilter, vendorPagination);
            filterStorage.setFilter("vendors", vendorFilter)
        } else if (activeState == "end-clients") {
            endFilter.sort_column = sort_column;
            endFilter.sort_order = sort_order;
            endFilter.scrollId = '';
            setEndFilter({ ...endFilter });
            setLoading(true);
            EndCltListingApi(endFilter, endClientPagination);
            filterStorage.setFilter("end-clients", endFilter);
        }
    }

    const handleStatus = (args) => {
        if (activeState == 'clients') {
            filter.status = args
            filter.scrollId = '';
            setFilter({ ...filter })
            clientListingApi({ ...filter, status: args }, pagination);
            filterStorage.setFilter("clients", filter)
        } else if (activeState == 'vendors') {
            vendorFilter.status = args
            vendorFilter.scrollId = '';
            setVendorFilter({ ...vendorFilter })
            vendorsListingApi({ ...vendorFilter, status: args }, vendorPagination)
            filterStorage.setFilter("vendors", vendorFilter)
        } else if (activeState == 'end-clients') {
            endFilter.status = args;
            endFilter.scrollId = '';
            setEndFilter({ ...endFilter })
            EndCltListingApi({ ...endFilter, status: args }, endClientPagination);
            filterStorage.setFilter("end-clients", endFilter);
        }
        setOpenStatusArrow(null);
    }

    const handleView = (args) => {
        setRowData(args);
        if (activeState == 'clients') {
            filter.scrollId = args.id;
            filterStorage.setFilter("clients", filter)
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeNotificationId();
            navigate('/clients/clients-user-profile', { state: { id: args.id, data: args, name: args.name } })
        } else if (activeState == 'vendors') {
            vendorFilter.scrollId = args.id;
            filterStorage.setFilter("vendors", vendorFilter)
            navigate('/vendor/user-profile', { state: { id: args.id, data: args } })
        } else if (activeState == 'end-clients') {
            endFilter.scrollId = args.id;
            filterStorage.setFilter("end-clients", endFilter)
            navigate('/clients/end-clients-user-profile', { state: { id: args.id, data: args, name: args.name } })
        }
    }

    const deleteRow = () => {
        setDeletePopup(false);
        let disableClientRow = clientRows.filter(item => item.id == rowData.id);
        let disableVendorRow = vendorRows.filter(item => item.id == rowData.id);
        let disableEndClientRow = endCltRows.filter(item => item.id == rowData.id);
        // setOpenStatus(null);
        const updateData = {
            request_id: LocalStorage.uid(),
            status: rowData.status == 'Active' ? 'In Active' : 'Active'
        }
        if (activeState == 'clients') {
            ClientsApi.updateStatus(disableClientRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    clientListingApi(filter, pagination);
                } else {
                    if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                    }
                    else {
                        addErrorMsg(res.data.message);
                    }
                }
            })
        } else if (activeState == 'vendors') {
            ClientsApi.updateStatus('vendor', disableVendorRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    vendorsListingApi(vendorFilter, vendorPagination);
                } else {
                    if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                    }
                    else {
                        addErrorMsg(res.data.message);
                    }
                }
            })
        } else if (activeState == 'end-clients') {
            ClientsApi.updateStatus('end-client', disableEndClientRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    EndCltListingApi(endFilter, endClientPagination);
                } else {
                    if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                    }
                    else {
                        addErrorMsg(res.data.message);
                    }
                }
            })
        }
    }

    const handleStatusOpen = (e) => {
        setOpenStatusArrow(e.currentTarget);
    }

    const handleSearchClear = (args) => {
        if (args == 'invoice') {
            setSearchInvoice('');
            syncListData(pagination.total);
        } else if (args == 'zoho') {
            setSearchZoho('');
            syncListData(pagination.total);
        }
        else {
            if (activeState == 'clients') {
                filter.search = ''
                filter.scrollId = ''
                setFilter({ ...filter })
                clientListingApi(filter, { ...pagination, currentPage: 1 });
                filterStorage.setFilter("clients", filter)
            } else if (activeState == 'vendors') {
                vendorFilter.search = ''
                vendorFilter.scrollId = ''
                setVendorFilter(vendorFilter);
                vendorsListingApi(vendorFilter, { ...vendorPagination, currentPage: 1 })
                filterStorage.setFilter("vendors", vendorFilter)
            } else {
                endFilter.search = ''
                endFilter.scrollId = ''
                setEndFilter(endFilter);
                EndCltListingApi(endFilter, { ...endClientPagination, currentPage: 1 })
                filterStorage.setFilter("end-clients", endFilter);
            }
        }
    }

    const changeHandler = (e, args) => {
        if (args == 'invoice') {
            setSearchInvoice(e.target.value);
            syncListData(pagination.total, e.target.value);
        } else if (args == 'zoho') {
            setSearchZoho(e.target.value);
            syncListData(pagination.total, e.target.value);
        }
        else {
            if (activeState === 'clients') {
                setFilter({ ...filter, search: e.target.value ,scrollId : ''});
                setLoading(true);
                clientListingApi({ ...filter, search: e.target.value }, { ...pagination, currentPage: 1 });
                filterStorage.setFilter("clients", { ...filter, search: e.target.value ,scrollId : ''})
            } else if (activeState === 'vendors') {
                setVendorFilter({ ...vendorFilter, search: e.target.value,scrollId : '' });
                setLoading(true);
                vendorsListingApi({ ...vendorFilter, search: e.target.value }, { ...vendorPagination, currentPage: 1 })
                filterStorage.setFilter("vendors", { ...vendorFilter, search: e.target.value,scrollId : '' })
            } else if (activeState === 'end-clients') {
                setEndFilter({ ...endFilter, search: e.target.value,scrollId : '' });
                setLoading(true);
                EndCltListingApi({ ...endFilter, search: e.target.value }, { ...endClientPagination, currentPage: 1 })
                filterStorage.setFilter("end-clients", { ...endFilter, search: e.target.value,scrollId : '' });
            }
        }
    }

    const addForm = () => {
        if (activeState == 'clients') {
            navigate('/addClient')
        } else if (activeState == 'vendors') {
            navigate('/addVendor')
        } else if (activeState == 'end-clients') {
            navigate('/addEnd-Client')
        }
        filterStorage.clearAllFilters();
    }

    const syncListData = (args, search) => {
        const data = {
            request_id: LocalStorage.uid(),
            search: search ? search : "",
            sort_column: "created_at",
            sort_order: "desc",
            limit: args,
            page: 1,
            status: ""
        }
        const pageDate = {
            total: "",
            currentPage: 0,
            perPage: '',
            totalPages: ""
        }
        ClientsApi.listing(data, pageDate).then((res) => {
            if (res.data.statusCode == 1003) {
                if (res.data.data.length > 0) {
                    setSyncClients(res.data.data);
                }
            }
        })
    }

    const clientListingApi = (data, paginationData,param) => {
        if (clientRows.length < 6) {
            setLoading(true);
        }
        ClientsApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            setListLoading(false);
            if (res.data.statusCode == 1003) {
                setClientRows(res.data.data);
                setPagination(res.data.pagination);
                //syncListData(res.data.pagination.total);
                //getTotalClients(res.data.pagination.total, searchInvoice)
                if (param !== undefined && param !== "" && param !== null) {
                    setTimeout(() => { scrollToElement(param)}, 500);
                    setTimeout(() => { setFilter({...filter,scrollId:''}) },10000);
                }
            } else {
                if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(res.data.message);
                }
            }
        })
    }

    const getTotalClients = (limit, search) => {
        const data = {
            request_id: LocalStorage.uid(),
            search: search ? search : "",
            sort_column: "created_at",
            sort_order: "desc",
            limit: limit ? limit : '',
            page: 1,
            status: ""
        }
        const paginationData = {
            total: limit ? limit : '',
            currentPage: 0,
            perPage: limit ? limit : '',
            totalPages: ""
        }
        ClientsApi.listing(data, paginationData).then((res) => {
            if (res.data.statusCode == 1003) {
                setSyncClients(res.data.data);
            }
        })
    }

    const vendorsListingApi = (data, paginationData,param) => {
        if (vendorRows.length < 6) {
            setLoading(true);
        }
        VendorApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            // removeLoader();
            setListLoading(false);
            if (res.data.statusCode === 1003) {
                setVendorRows(res.data.data);
                setVendorPagination(res.data.pagination);
                if (param !== undefined && param !== "" && param !== null) {
                    setTimeout(() => { scrollToElement(param) }, 500);
                    setTimeout(() => { setVendorFilter({...vendorFilter,scrollId:''}) },10000);
                }
            } else {
                if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(res.data.message);
                }
            }
        })
    }

    const EndCltListingApi = (data, paginationData,param) => {
        if (endCltRows.length < 6) {
            setLoading(true);
        }
        EndClientApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            // removeLoader();
            setListLoading(false);
            if (res.data.statusCode === 1003) {
                setEndCltRows(res.data.data);
                setEndClientPagination(res.data.pagination);
                if (param !== undefined && param !== "" && param !== null) {
                    setTimeout(() => { scrollToElement(param) }, 500);
                    setTimeout(() => { setEndFilter({...endFilter,scrollId:''}) },10000);
                }
            } else {
                if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(res.data.message);
                }
            }
        })
    }
    // eslint-disable-next-line
    const handleChangePagination = (e, page) => {
        if (activeState == 'clients') {
            pagination['currentPage'] = page
            setPagination(pagination);
            clientListingApi(filter, pagination);
        } else if (activeState == 'vendors') {
            vendorPagination['currentPage'] = page
            setEndClientPagination(vendorPagination);
            vendorsListingApi(vendorFilter, vendorPagination);
        }
        else if (activeState == 'end-clients') {
            endClientPagination['currentPage'] = page
            setEndClientPagination(endClientPagination);
            EndCltListingApi(endFilter, endClientPagination);
        }
    }

    const handleTab = (args) => {
        setActiveState(args);
        setExpanded(false);
        if (args == 'clients') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
                filter.limit = 10
                filter.status = ''
                filter.search = ""
                filter.sort_column = "created_at"
                filter.sort_order = "desc"
                filter.page = 1 
                filter.scrollId=''
                setFilter({ ...filter })
                pagination['perPage'] = 10
                setPagination({ ...pagination })
                setLoading(true);
                clientListingApi(filter, pagination);
                filterStorage.setFilter("clients", filter);
                filterStorage.removeFilter('vendors');
                filterStorage.removeFilter('end-clients');
            }
        } else if (args == 'vendors') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
                vendorFilter.limit = 10
                vendorFilter.status = ''
                vendorFilter.search = ""
                vendorFilter.sort_column = "created_at"
                vendorFilter.sort_order = "desc"
                vendorFilter.page = 1
                vendorFilter.scrollId=''
                setVendorFilter({ ...vendorFilter })
                vendorPagination['perPage'] = 10
                setVendorPagination({ ...vendorPagination })
                setLoading(true);
                vendorsListingApi(vendorFilter, vendorPagination);
                filterStorage.setFilter("vendors", vendorFilter);
                filterStorage.removeFilter('clients');
                filterStorage.removeFilter('end-clients');
            }
        } else if (args == 'end-clients') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))) {
                endFilter.limit = 10
                endFilter.status = ''
                endFilter.search = ""
                endFilter.sort_column = "created_at"
                endFilter.sort_order = "desc"
                endFilter.page = 1
                endFilter.scrollId=''
                setEndFilter({ ...endFilter })
                endClientPagination['perPage'] = 10
                setEndClientPagination({ ...endClientPagination })
                setLoading(true);
                EndCltListingApi(endFilter, endClientPagination);
                filterStorage.setFilter("end-clients", endFilter);
                filterStorage.removeFilter('clients');
                filterStorage.removeFilter('vendors');
            }
        }
    }

    const completeProfile = (args) => {
        if (activeState == 'clients') {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeNotificationId();
            navigate('/clients/clients-user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        } else if (activeState == 'vendors') {
            navigate('/vendor/user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        } else if (activeState == 'end-clients') {
            navigate('/clients/end-clients-user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        }
    }

    const quickbookHandler = (e, args) => {
        if (args == 'zoho') {
            setOpenZohoArrow(e.currentTarget);
        } else {
            setOpenQuickbookArrow(e.currentTarget);
        }
    }
    const handleZHSync = () => {
        setZHButtonLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            sync_item_ids: selectedClientsZoho
        }
        ClientsApi.zohoIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setZHButtonLoader(false);
                setSelectedClientsZoho([]);
                addSuccessMsg(res.data.message);
                getTotalClients(pagination.total, filter.search);
            } else {
                addErrorMsg(res.data.message);
                setZHButtonLoader(false);
            }
        })
    }

    const handleQBSync = () => {
        setQBButtonLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            sync_item_ids: selectedClients
        }
        ClientsApi.quickbookIntegration(data).then((res) => {
            if (res.data.statusCode == 1003) {
                setQBButtonLoader(false);
                setSelectedClients([]);
                addSuccessMsg(res.data.message);
                getTotalClients(pagination.total, filter.search);
            } else {
                addErrorMsg(res.data.message);
                setQBButtonLoader(false);
            }
        })
    };

    const handleQuickBookSync = () => {
        setSyncQuicbooksList(true);
        getTotalClients(pagination.total);

   }

   const handleZohoBookSync = () => {
        setSyncZohoList(true);
        getTotalClients(pagination.total);
   }

    return (
        <Grid container pl={{ lg: 13, md: 12, sm: 2, xs: 0 }} pt={{ lg: 2, md: 2, sm: 2, xs: 0 }} justifyContent='center'>
            {startTour ? <TourClients startTour={startTour} setStartTour={() => setStartTour(false)} destroyTour={destroyTour} setDestroyTour={setDestroyTour} /> : <>
                {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "end_client_view" || item.slug == "client_view" || item.slug == "vendor_view") && item.is_allowed == true))) ?
                        <Grid item container lg={11} md={12} sm={12} xs={12} pt={2} justifyContent='center' spacing={2} px={{ lg: 0, md: 0, sm: 0, xs: 2 }} alignItems={"center"}>
                            <Grid item container xl={5} lg={4} md={activeState == 'clients' ? 4 : 12} sm={activeState == 'clients' ? 6 : 12} xs={12} alignItems={"center"}>
                                <Box className={classes.tabBg}>
                                    <Box className={activeState == 'clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('clients')}>
                                        {
                                            activeState == 'clients' ?
                                                <Text largeWhite400>Clients</Text> :
                                                <Text blackHeader>Clients</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'vendors' || data?.name == 'vendors' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('vendors')}>
                                        {
                                            activeState == 'vendors' || data?.name == 'vendors' ?
                                                <Text largeWhite400>Vendors</Text> :
                                                <Text blackHeader>Vendors</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'end-clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('end-clients')}>
                                        {
                                            activeState == 'end-clients' ?
                                                <Text largeWhite400>End Clients</Text> :
                                                <Text blackHeader>End Clients</Text>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item container xl={7} lg={8} md={activeState == 'clients' ? 8 : 12} sm={activeState == 'clients' ? 6 : 12} xs={12} justifyContent={'end'} gap={"10px"} alignItems={"center"} pr={{ lg: 0, md: 2, sm: 2, xs: 0 }}>
                                {
                                    activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                        activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                            activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                                <>
                                                    {
                                                        activeState == 'clients' ? '' :
                                                            <Box className={classes.inputBox}>
                                                                <input
                                                                    className={classes.EmployeesSearchInput}
                                                                    name="text"
                                                                    onChange={(e) => changeHandler(e)}
                                                                    placeholder='Search by Name / ID'
                                                                    value={activeState == 'clients' ? filter.search : activeState == 'end-clients' ? endFilter.search : vendorFilter.search}
                                                                    onDrop={(event) => {
                                                                        event.preventDefault(); // Prevent the drop action
                                                                    }}
                                                                    onDragOver={(event) => {
                                                                        event.preventDefault(); // Prevent default drag behavior
                                                                    }}
                                                                />
                                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                                    {
                                                                        (activeState == 'clients' ? filter.search == '' : activeState == 'end-clients' ? endFilter.search == '' : vendorFilter.search == '') ?
                                                                            <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                    }
                                                                </Box>
                                                            </Box>
                                                    }
                                                    {
                                                        activeState == 'clients' ? '' :
                                                            <button title="Export" type="button" className={classes.borderButton}
                                                                onClick={() => download()}
                                                            >
                                                                <img src={cloud} alt="Userplus" />
                                                            </button>
                                                    }
                                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                                                        <Button analyticsView onClick={viewAnalytics}>View Analytics</Button>
                                                        : ""}
                                                    <Button addButton onClick={addForm}><img src={Userplus} alt='plus' className={classes.addIcon} />Add {activeState == "clients" ? 'Client' : activeState == "vendors" ? 'Vendor' : activeState == "end-clients" ? 'End Client' : ""}</Button>
                                                </>}
                            </Grid>
                            {
                                activeState == 'clients' ?
                                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1}>
                                        <Grid item xl={3.3} lg={3.3} md={3.3} sm={4} xs={12}>
                                            <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <input
                                                    className={classes.EmployeesSearchInput}
                                                    name="text"
                                                    onChange={(e) => changeHandler(e)}
                                                    placeholder='Search by Name / ID'
                                                    value={activeState == 'clients' ? filter.search : activeState == 'end-clients' ? endFilter.search : vendorFilter.search}
                                                    onDrop={(event) => {
                                                        event.preventDefault(); // Prevent the drop action
                                                    }}
                                                    onDragOver={(event) => {
                                                        event.preventDefault(); // Prevent default drag behavior
                                                    }}
                                                />
                                                <Box sx={{ paddingRight: "15px !important" }}>
                                                    {
                                                        (activeState == 'clients' ? filter.search == '' : activeState == 'end-clients' ? endFilter.search == '' : vendorFilter.search == '') ?
                                                            // ((activeState == 'clients' && filter.search == '') || (activeState == 'end-clients' && endfilter.search == '') || (activeState == "vendors" && vendorfilter.search == '')) ?
                                                            <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={8.5} md={8.5} pr={{ lg: 0, md: 0, sm: 2, xs: 0 }}>
                                            <Box display='flex' flexDirection='row' gap={2} justifyContent='flex-end'>
                                                {
                                                    connectedAppsList.isQuickBookIntegrated &&
                                                    <Box display='flex' flexDirection='row' gap={2} className={classes.quickbooksbg}>
                                                        <img src={quickBooks} alt='quickbooks' />
                                                        {
                                                            openQuickbookArrow ?
                                                                <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                                <img src={arrowDown} aria-controls="status-menu" alt='quickbooks' className={classes.arrowSize} onClick={quickbookHandler} />
                                                        }
                                                        <Menu
                                                            anchorEl={openQuickbookArrow}
                                                            id="status-menu"
                                                            open={openQuickbookArrow}
                                                            onClose={() => { setOpenQuickbookArrow(null) }}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: "visible",
                                                                    boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                    mt: 3,
                                                                    width: '200px'
                                                                }
                                                            }}
                                                            transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                        >
                                                            <MenuItem className={classes.menuItem} onClick={handleQuickBookSync}><Text smallBlack>Sync Clients to Quickbooks</Text></MenuItem>
                                                        </Menu>
                                                    </Box>
                                                }
                                                {
                                                    connectedAppsList.isZohoIntegrated &&
                                                    <Box display='flex' flexDirection='row' gap={2} className={classes.zohobooksbg}>
                                                        <img src={zohoBooks} alt='zohobooks' />
                                                        {
                                                            openZohoArrow ?
                                                                <img src={arrowUp} alt='up' className={classes.arrowSize} /> :
                                                                <img src={arrowDown} alt='zohobooks' className={classes.arrowSize} onClick={(e) => quickbookHandler(e, 'zoho')} />
                                                        }
                                                        <Menu
                                                            anchorEl={openZohoArrow}
                                                            id="status-menu"
                                                            open={openZohoArrow}
                                                            onClose={() => { setOpenZohoArrow(null) }}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: "visible",
                                                                    boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                    mt: 3,
                                                                    width: '200px'
                                                                }
                                                            }}
                                                            transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                        >
                                                            <MenuItem className={classes.menuItem} onClick={handleZohoBookSync}><Text smallBlack>Sync Clients to Zohobooks</Text></MenuItem>
                                                        </Menu>
                                                    </Box>
                                                }
                                                <button title="Export" type="button" className={classes.borderButton} onClick={() => download()} >
                                                    <img src={cloud} alt="Userplus" />
                                                </button>
                                            </Box>
                                        </Grid>
                                    </Grid> : ''
                            }
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className={classes.tableBox}>
                                    <Grid item container lg={11.6} md={11} sm={11.5} xs={11} pt={4} className={classes.hzScrollGrid}>
                                        {
                                            activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                                activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                                    activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                                        <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center' sx={{ borderBottom: "1px solid #F3EFEF", padding: "16px 12px 5px 12px" }}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign={'left'} display='flex' flexDirection='row' alignItems='center'>
                                                                <Text mediumBoldBlack2>{activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text>
                                                                <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                                    <FilterListIcon sx={{
                                                                        height: "19px", color: ((activeState == 'clients' && clientRows.length == 0) || (activeState == 'vendors' && vendorRows.length == 0) || (activeState == 'end-clients' && endCltRows.length == 0)) ? '#777A8E' : '#2BEA2B'
                                                                    }} />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    id="account-menu"
                                                                    open={open}
                                                                    onClose={() => setAnchorEl(null)}
                                                                    // onClick={handleCloseFilter}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                            mt: 0.5,
                                                                            ml: -2,
                                                                            padding: "5px",
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "#EAECF0 !important",
                                                                            }
                                                                        },
                                                                    }}
                                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                                >
                                                                    <>
                                                                        <MenuItem sx={{
                                                                            background: activeState == 'clients' ? filter.sort_column == 'created_at' && filter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                                :
                                                                                activeState == 'end-clients' ? endFilter.sort_column == 'created_at' && endFilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                                    :
                                                                                    vendorFilter.sort_column == 'created_at' && vendorFilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                        }} onClick={() => handleSort('created_at', 'desc')}> <Text mediumBoldBlack400>Newest First</Text>
                                                                        </MenuItem>
                                                                        <MenuItem sx={{
                                                                            background: activeState == 'clients' ? filter.sort_column == 'created_at' && filter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                                :
                                                                                activeState == 'end-clients' ? endFilter.sort_column == 'created_at' && endFilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                                    :
                                                                                    vendorFilter.sort_column == 'created_at' && vendorFilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                        }} onClick={() => handleSort('created_at', 'asc')}> <Text mediumBoldBlack400>Oldest First</Text>
                                                                        </MenuItem>
                                                                        <MenuItem sx={{
                                                                            background: activeState == 'clients' ? filter.sort_column == 'name' && filter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                                :
                                                                                activeState == 'end-clients' ? endFilter.sort_column == 'name' && endFilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                                    :
                                                                                    vendorFilter.sort_column == 'name' && vendorFilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                        }} onClick={() => handleSort('name', 'asc')}><Text mediumBoldBlack400>Name (A-Z)</Text>
                                                                        </MenuItem>
                                                                        <MenuItem sx={{
                                                                            background: activeState == 'clients' ? filter.sort_column == 'name' && filter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                                :
                                                                                activeState == 'end-clients' ? endFilter.sort_column == 'name' && endFilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                                    :
                                                                                    vendorFilter.sort_column == 'name' && vendorFilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                        }} onClick={() => handleSort('name', 'desc')}> <Text mediumBoldBlack400>Name (Z-A)</Text>
                                                                        </MenuItem>
                                                                    </>
                                                                </Menu>
                                                            </Grid>
                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                                <Text mediumBoldBlack2>Primary Contact</Text>
                                                            </Grid>
                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                                <Text mediumBoldBlack2>Phone Number</Text>
                                                            </Grid>
                                                            <Grid item container lg={1.5} md={2} sm={2} xs={2} justifyContent='center'>
                                                                <Grid aria-controls="status-menu" item lg={6} onClick={handleStatusOpen} className={classes.statusGrid}>
                                                                    <Text mediumBoldBlack2 sx={{ paddingRight: '5px' }}>Status</Text>
                                                                    {
                                                                        openStatusArrow ?
                                                                            ((activeState == 'clients' && filter.status == '')
                                                                                ||
                                                                                (activeState == 'end-clients' && endFilter.status == '')
                                                                                ||
                                                                                (activeState == 'vendors' && vendorFilter.status == '')) ? <img src={arrowUp} alt='up' className={classes.cursor} /> : <img src={greenUp} alt='up' className={classes.cursor} />
                                                                            :
                                                                            ((activeState == 'clients' && filter.status == '')
                                                                                ||
                                                                                (activeState == 'end-clients' && endFilter.status == '')
                                                                                ||
                                                                                (activeState == 'vendors' && vendorFilter.status == '')) ? <img src={arrowDown} alt='down' className={classes.cursor} /> : <img src={greendown} alt='down' className={classes.cursor} />
                                                                    }
                                                                </Grid>
                                                                <Menu
                                                                    anchorEl={openStatusArrow}
                                                                    id="status-menu"
                                                                    open={openStatusArrow}
                                                                    onClose={() => { setOpenStatusArrow(null) }}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: "visible",
                                                                            boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                            mt: 1,
                                                                            width: '200px'
                                                                        }
                                                                    }}
                                                                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                                >
                                                                    <MenuItem onClick={() => handleStatus('')} className={((activeState == 'clients' && filter.status == '') || (activeState == 'end-clients' && endFilter.status == '') || (activeState == 'vendors' && vendorFilter.status == '')) ? classes.activeMenuItem : classes.menuItem}><Text smallBlack>All</Text></MenuItem> <Divider className={classes.divider} />
                                                                    <MenuItem onClick={() => handleStatus('Active')} className={((activeState == 'clients' && filter.status == 'Active') || (activeState == 'end-clients' && endFilter.status == 'Active') || (activeState == 'vendors' && vendorFilter.status == 'Active')) ? classes.activeMenuItem : classes.menuItem}> <Text smallBlack>Active  {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''} </Text></MenuItem> <Divider className={classes.divider} />
                                                                    <MenuItem onClick={() => handleStatus('In Active')} className={((activeState == 'clients' && filter.status == 'In Active') || (activeState == 'end-clients' && endFilter.status == 'In Active') || (activeState == 'vendors' && vendorFilter.status == 'In Active')) ? classes.activeMenuItem : classes.menuItem}> <Text smallBlack>Inactive {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text></MenuItem>
                                                                </Menu>
                                                            </Grid>
                                                            <Grid item lg={2.5} md={2} sm={2} xs={2}>
                                                                <Text offBoardBodyFont>Count
                                                                    <BlackToolTip arrow placement='bottom' title={
                                                                        <Box p={'4px'}>
                                                                            <Text mediumWhite400>Total {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End-Clients' : ''} count is based<br /> on the filters applied</Text>
                                                                        </Box>
                                                                    }>
                                                                        <img src={info} alt='info' className={classes.infoIcon} style={{ cursor: 'pointer' }} />
                                                                    </BlackToolTip>
                                                                    &nbsp;&nbsp;- {activeState == 'clients' ? clientRows.length > 0 ? pagination.total : '0' : activeState == 'vendors' ? vendorRows.length > 0 ? vendorPagination.total : '0' : activeState == 'end-clients' ? endCltRows.length > 0 ? endClientPagination.total : '0' : ''} {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text>
                                                            </Grid>
                                                        </Grid>
                                        }
                                        {
                                            loading ?
                                                [1, 2, 3].map((item) => (
                                                    <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                        <Box sx={{ width: "70%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                            <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <Box>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                </Box>
                                                                <Box sx={{ width: "0%", alignSelf: "center" }}>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                                </Box>
                                                                <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: "30%", display: "flex", alignItems: "center", gap: 2 }}>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                )) :
                                                <>
                                                    {
                                                        activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                            activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                                activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                                    <>
                                                                        {
                                                                            (activeState == 'clients' ? clientRows.length > 0 : activeState == 'vendors' ? vendorRows.length > 0 : activeState == 'end-clients' ? endCltRows.length > 0 : '') ?
                                                                                <Grid container  className={`${classes.scrollBox} ${activeState === "clients" ? classes.scrollContentClient : classes.scrollContent}`} 
                                                                                ref={scrollBox} onScroll={handleScroll}>
                                                                                    {
                                                                                        (activeState == 'clients' ? clientRows.length > 0 && clientRows : activeState == 'vendors' ? vendorRows.length > 0 && vendorRows : activeState == 'end-clients' ? endCltRows.length > 0 && endCltRows : '').map((item, key) => (
                                                                                            <Accordion
                                                                                                id={item.id}
                                                                                                key={key}
                                                                                                // className={classes.newCustomAccordion}
                                                                                                className={`${classes.newCustomAccordion} ${
                                                                                                    (activeState === "clients" && filter.scrollId === item.id) ||
                                                                                                    (activeState === "vendors" && vendorFilter.scrollId === item.id) ||
                                                                                                    (activeState === "end-clients" && endFilter.scrollId === item.id)
                                                                                                      ? classes.blinking
                                                                                                      : ""
                                                                                                  }`}                                                                                                  
                                                                                                expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                                                <AccordionSummary
                                                                                                    // expandIcon={selectedOption === "All employees" && <ExpandMoreIcon style={{ '& svg path': { stroke: 'black', strokeWidth: 2 }, color: '#000000' }} />}
                                                                                                    aria-controls="panel1bh-content"
                                                                                                    id="panel1bh-header"
                                                                                                    className={classes.AccordionSummary}                                                    >
                                                                                                    <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                                        <Grid item container lg={4} md={4} sm={4} xs={4} textAlign='start'>
                                                                                                            <Box display='flex' alignItems='center' gap={2} width={'50%'}>
                                                                                                                <CustomCircularProgress
                                                                                                                    percentage={item.profile_percentage}
                                                                                                                    color={item.profile_percentage >= 76 ? 'green' : item.profile_percentage <= 75 && item.profile_percentage >= 51 ? 'yellow' : item.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                                                                    src={item.document_url}
                                                                                                                    name={item.document_url == undefined || item.document_url == null || item.document_url == '' ? item.name && item.name[0] : ''}
                                                                                                                    imgWidth='50px !important'
                                                                                                                    imgHeight='50px !important'
                                                                                                                    avatarWidth='50px !important'
                                                                                                                    avatarHeight='50px !important'
                                                                                                                    value={item.profile_percentage}
                                                                                                                    size={58}
                                                                                                                    tooltip
                                                                                                                    tooltipContent={
                                                                                                                        <Box p={'3px 0px'} width='100%'>
                                                                                                                            <Text mediumBoldWhite400>{`Profile Completion - ${item.profile_percentage}%`}</Text>
                                                                                                                        </Box>
                                                                                                                    }
                                                                                                                    dashboardContent={item.status == 'Active' && item.profile_percentage == 50 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} onClick={() => completeProfile(item)}>Complete Profile</Button> : ''}
                                                                                                                />
                                                                                                                <Box minWidth={"100%"}>
                                                                                                                    {
                                                                                                                        item.status == 'In Active' ?
                                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0' }}>
                                                                                                                                {item.name === "" ? "--" : capitalizeAndAddSpace(item.name).length > 16 ?
                                                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                                                                                        {capitalizeAndAddSpace(item.name).slice(0, 16) + (capitalizeAndAddSpace(item.name).length > 16 ? "..." : "")}
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    :
                                                                                                                                    capitalizeAndAddSpace(item.name)
                                                                                                                                }
                                                                                                                                {item.e_verified == 1 || item.e_verified == 4 ?
                                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    : item.e_verified == 2 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verification is pending</Text>} placement="right" arrow>
                                                                                                                                            <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip> : ''
                                                                                                                                }
                                                                                                                            </Text>
                                                                                                                            :
                                                                                                                            <Text mediumBoldBlack style={{ margin: '4px 0px' }}>
                                                                                                                                {item.name === "" ? "--" :
                                                                                                                                    capitalizeAndAddSpace(item.name).length > 16 ?
                                                                                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                                                                                            {capitalizeAndAddSpace(item.name).slice(0, 16) + (capitalizeAndAddSpace(item.name).length > 16 ? "..." : "")}
                                                                                                                                        </BlackToolTip>
                                                                                                                                        :
                                                                                                                                        capitalizeAndAddSpace(item.name)
                                                                                                                                }
                                                                                                                                {item.e_verified == 1 || item.e_verified == 4 ?
                                                                                                                                    <HtmlTooltip title={<Text smallWhite>E-verified</Text>} placement="right" arrow>
                                                                                                                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                    </HtmlTooltip>
                                                                                                                                    : item.e_verified == 2 ?
                                                                                                                                        <HtmlTooltip title={<Text smallWhite>E-verification is pending</Text>} placement="right" arrow>
                                                                                                                                            <img src={Pending} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                                        </HtmlTooltip> : ''
                                                                                                                                }
                                                                                                                            </Text>
                                                                                                                    }
                                                                                                                    <Text mediumLabel style={{ margin: '4px 0' }}>  {item.reference_id === "" ? "--" : item.reference_id}</Text>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                                                                            <Text mediumBlack14>{item.primary_contact ?
                                                                                                                capitalizeAndAddSpace(item.primary_contact).length > 20 ?
                                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(item.primary_contact)} placement="right" arrow>
                                                                                                                        {capitalizeAndAddSpace(item.primary_contact).slice(0, 20) + (capitalizeAndAddSpace(item.primary_contact).length > 20 ? "..." : "")}
                                                                                                                    </HtmlTooltip>
                                                                                                                    :
                                                                                                                    capitalizeAndAddSpace(item.primary_contact)
                                                                                                                : '--'}</Text>
                                                                                                        </Grid>
                                                                                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left' pl={'5px'}>
                                                                                                            <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '--'}</Text>
                                                                                                        </Grid>
                                                                                                        <Grid item container lg={2} md={2} sm={2} xs={2} justifyContent='space-around' display='flex' flexDirection='row' alignItems='center'>
                                                                                                            <Hidden mdDown><Divider orientation="vertical" flexItem style={{ height: '50px' }} /></Hidden>
                                                                                                            <Box className={item.status == 'Active' ? classes.activeStatus : classes.inactiveStatus}>
                                                                                                                {item.status == 'Active' ? 'Active' : 'Inactive'}
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        <Grid item lg={1.9} md={2} sm={2} xs={2}>
                                                                                                            <Text mediumBlue onClick={() => handleView(item)}>View Profile</Text>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails>
                                                                                                    <Box className={classes.AccordionDetails} pt={1}>
                                                                                                        <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center' alignItems='center'>
                                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                                <Text mediumGrey2>Email ID</Text>
                                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                                                    {
                                                                                                                        item.contact_email ? item.contact_email.length > 16 ?
                                                                                                                            <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                                                {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                                                            </BlackToolTip>
                                                                                                                            : (item.contact_email) : '--'
                                                                                                                    }
                                                                                                                </Text>
                                                                                                            </Grid>
                                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                                <Text mediumGrey2>Mobile Number</Text>
                                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.mobile_number ? item.mobile_number : '--'}</Text>
                                                                                                            </Grid>
                                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                                <Text mediumGrey2>Extension</Text>
                                                                                                                <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.telephone_number_ext ? item.telephone_number_ext : '--'}</Text>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Box>
                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        ))
                                                                                    }
                                                                                    {listLoading && (
                                                                                        <Box display='flex' justifyContent='center' width='100%' alignItems='center'>
                                                                                            <ListLoadingIcon />
                                                                                        </Box>
                                                                                    )}
                                                                                    <div ref={messagesEndRef} />
                                                                                </Grid>
                                                                                :
                                                                                (activeState == 'clients' ? clientRows.length == 0 : activeState == 'vendors' ? vendorRows.length == 0 : activeState == 'end-clients' ? endCltRows.length == 0 : '') ?
                                                                                    <Box justifyContent='center' width='100%'>
                                                                                        {NoDataFound(((filter.status == 'In Active' && clientRows.length == 0) || (vendorFilter.status == 'In Active' && vendorRows.length == 0) || (endFilter.status == 'In Active' && endCltRows.length == 0)) ? '' : `To fetch the data. Please add ${activeState == 'clients' ? 'Client' : activeState == 'vendors' ? 'Vendor' : activeState == 'end-clients' ? 'End-Client' : ''}`, 'No Data Found')}
                                                                                    </Box> : ''
                                                                        }
                                                                    </>
                                                    }
                                                </>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid >
                        :
                        <Grid container>
                            <Box>
                                <Text mediumViewAmt>Clients</Text>
                            </Box>
                            {NoPermission()}
                        </Grid>
                }</>}
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop> */}
            <ReusablePopup iconHide white openPopup={deletePopup} setOpenPopup={setDeletePopup} fullWidth>
                <Box sx={{ margin: "20px", }}>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={deactivateImg} alt="warning" />
                    </Box>
                    <Box my={3}>
                        <Typography my={1} sx={{ color: "#54595E", font: '18px  Quicksand', fontWeight: 600, textAlign: 'center' }}>
                            Are You Sure?
                        </Typography>
                        <Typography my={1} sx={{ color: "#54595E99", font: '14px  Nunito , Nunito Sans, sans-serif', fontWeight: 400, textAlign: 'center' }}>
                            Do you really wish to {rowData.status == 'Active' ? 'In Activate' : 'Activate'} the {activeState == 'clients' ? 'Client' : activeState == 'vendors' ? 'vendor' : activeState == 'end-clients' ? 'End - Client' : ''}.
                        </Typography>
                    </Box>
                    <Box my={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                        <CustomButton no onClick={() => setDeletePopup(false)}>
                            No
                        </CustomButton>
                        <CustomButton popupDelete onClick={deleteRow}>
                            Yes, {rowData.status == 'Active' ? 'In Activate' : 'Activate'}
                        </CustomButton>
                    </Box>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide white openPopup={syncQuickbooksList} setOpenPopup={setSyncQuicbooksList} onClose={() => { setSyncQuicbooksList(false); setSelectedClients([]); setOpenQuickbookArrow(null) }} max1200>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12} pl={1}>
                        <Text headerBlack>Sync Clients to Quickbooks</Text>
                    </Grid>
                    <>
                        <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' mt={1}>
                            <Box display='flex' flexDirection={{ lg: 'row', md: "row", sm: "row", xs: "column" }} gap='10px' justifyContent='space-between' sx={{ width: '100% !important' }}>
                                <Box className={classes.zohoInputBox}>
                                    <input
                                        className={classes.EmployeesSearchInput}
                                        name="searchInvoice"
                                        onChange={(e) => changeHandler(e, 'invoice')}
                                        placeholder='Search by Invoice ID'
                                        value={searchInvoice}
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            searchInvoice == '' ?
                                                <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear('invoice')} />
                                        }
                                    </Box>
                                </Box>
                                {
                                    selectedClients.length > 0 ?
                                        <Box>
                                            <LoadingButton addButton180 loading={QBButtonLoader} onClick={handleQBSync}>Sync Clients</LoadingButton>
                                        </Box> : ''
                                }
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item container xs={12} mt={2}>
                                <Grid item xs={1}>
                                    <Checkbox value={syncClients.length == selectedClients.length}
                                        checked={syncClients.length == selectedClients.length}
                                        sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        onClick={(e) => checkHandler(e, 'all')}
                                    />
                                </Grid>
                                <Grid item container xs={11} spacing={2} justifyContent='center'>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Client Name</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Primary Contact</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Phone Number</Text>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Text mediumBlack14>Status</Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item xs={12} my={0.8}>
                                <Divider />
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            {
                                syncClients.length > 0 ? syncClients.map((item, index) => (
                                    <Grid item container xs={12}>
                                        <Grid item xs={1}>
                                            <Checkbox value={selectedClients.some(i => i === item.id)}
                                                checked={selectedClients.some(i => i === item.id)}
                                                sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                checkedIcon={<CheckedIcon />}
                                                onClick={(e) => checkHandler(e, 'each', item)}
                                            />
                                        </Grid>
                                        <Grid item container xs={11} columnSpacing={2} justifyContent='center' alignItems='center'>
                                            <Grid item xs={3}>
                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                    <Avatar
                                                        alt="Q"
                                                        src={quickBooks}
                                                        className={item.quickbook_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                    />
                                                    <Text analyticsViewAll>
                                                        {item.name === "" ? "-" : capitalizeAndAddSpace(item.name).length > 12 ?
                                                            <HtmlTooltip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                {capitalizeAndAddSpace(item.name).slice(0, 12) + (capitalizeAndAddSpace(item.name).length > 12 ? "..." : "")}
                                                            </HtmlTooltip>
                                                            :
                                                            capitalizeAndAddSpace(item.name)
                                                        }
                                                    </Text>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.primary_contact ? item.primary_contact : '-'}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '-'}</Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text mediumBlack14>{item.status ? item.status : '-'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))


                                : 
                                [1, 2, 3, 4].map((item) => (
                                    <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                        <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                            <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Box>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                                <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            </Box>
                                        </Box>
                                    </Stack>
                                )) 
                            }
                        </Hidden>
                        <Hidden smUp>
                            <Box sx={{ width: "100%", overflow: "auto" }} pt={2}>
                                <TableContainer component={Paper} sx={{ minWidth: 550, height: '60vh', }}>
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow sx={{ width: 40 }}>
                                                <TableCell>
                                                    <Checkbox value={syncClients.length == selectedClients.length}
                                                        checked={syncClients.length == selectedClients.length}
                                                        sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                        checkedIcon={<CheckedIcon />}
                                                        onClick={(e) => checkHandler(e, 'all')}
                                                    /></TableCell>
                                                <TableCell align="left" sx={{ width: 180 }}><Text mediumBlack14>Client Name</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 180 }}><Text mediumBlack14>Primary Contact</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 160 }}><Text mediumBlack14>Phone Number</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 100 }}><Text mediumBlack14>Status</Text></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {syncClients.length > 0 ? syncClients.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ width: 40, border: 'none' }}>
                                                        <Checkbox value={selectedClients.some(i => i === row.id)}
                                                            checked={selectedClients.some(i => i === row.id)}
                                                            sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                            checkedIcon={<CheckedIcon />}
                                                            onClick={(e) => checkHandler(e, 'each', row)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ width: 180, border: 'none' }}>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                            <Avatar
                                                                alt="Q"
                                                                src={quickBooks}
                                                                className={row.quickbook_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                            />
                                                            <Text analyticsViewAll>
                                                                {row.name === "" ? "-" : capitalizeAndAddSpace(row.name).length > 12 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(row.name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(row.name).slice(0, 12) + (capitalizeAndAddSpace(row.name).length > 12 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(row.name)
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ width: 180, border: 'none' }}> <Text mediumBlack14>{row.primary_contact ? row.primary_contact : '-'}</Text></TableCell>
                                                    <TableCell align="left" sx={{ width: 160, border: 'none' }}><Text mediumBlack14>{row.telephone_number ? row.telephone_number : '-'}</Text></TableCell>
                                                    <TableCell align="left" sx={{ width: 100, border: 'none' }}><Text mediumBlack14  >{row.status ? row.status : '-'}</Text></TableCell>
                                                </TableRow>
                                            ))

                                            : 
                                            [1, 2, 3, 4].map((item) => (
                                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Box>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            )) 
                                        
                                        
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Hidden>
                    </>
                </Grid>
            </ReusablePopup>
            <ReusablePopup iconHide white openPopup={syncZohoList} setOpenPopup={setSyncZohoList} onClose={() => { setSyncZohoList(false); setSelectedClientsZoho([]); setOpenZohoArrow(null) }} max1200>
                <Grid container spacing={2}>
                    <Grid lg={12} md={12} sm={12} xs={12} pl={1}>
                        <Text headerBlack>Sync Clients to Zohobooks</Text>
                    </Grid>

                    <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' mt={1}>
                        <Box display='flex' flexDirection={{ lg: 'row', md: "row", sm: "row", xs: "column" }} justifyContent='space-between' gap='10px' sx={{ width: '100% !important' }}>
                            <Box className={classes.zohoInputBox}>
                                <input
                                    className={classes.EmployeesSearchInput}
                                    name="searchZoho"
                                    onChange={(e) => changeHandler(e, 'zoho')}
                                    placeholder='Search by Invoice ID'
                                    value={searchZoho}
                                />
                                <Box sx={{ paddingRight: "15px !important" }}>
                                    {
                                        searchZoho == '' ?
                                            <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear('zoho')} />
                                    }
                                </Box>
                            </Box>
                            {
                                selectedClientsZoho.length > 0 ?
                                    <Box>
                                        <LoadingButton addButton180 loading={ZHButtonLoader} onClick={handleZHSync}>Sync Clients</LoadingButton>
                                    </Box> : ''
                            }
                        </Box>
                    </Grid>
                    <Box width={"100%"} pl={2}>
                        <Hidden smDown>
                            <Grid container spacing={2}>
                                <Grid item container xs={12} mt={2}>
                                    <Grid item xs={1}>
                                        <Checkbox value={syncClients.length == selectedClientsZoho.length}
                                            checked={syncClients.length == selectedClientsZoho.length}
                                            sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                            checkedIcon={<CheckedIcon />}
                                            onClick={(e) => checkHandlerZoho(e, 'all')}
                                        />
                                    </Grid>
                                    <Grid item container xs={11} spacing={2} justifyContent='center'>
                                        <Grid item xs={3}>
                                            <Text mediumBlack14>Client Name</Text>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Text mediumBlack14>Primary Contact</Text>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Text mediumBlack14>Phone Number</Text>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Text mediumBlack14>Status</Text>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} my={0.8}>
                                    <Divider />
                                </Grid>
                                {
                                    syncClients.length > 0 ? syncClients.map((item) => (
                                        <Grid item container xs={12}>
                                            <Grid item xs={1}>
                                                <Checkbox value={selectedClientsZoho.some(i => i === item.id)}
                                                    checked={selectedClientsZoho.some(i => i === item.id)}
                                                    sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                    onClick={(e) => checkHandlerZoho(e, 'each', item)}
                                                />
                                            </Grid>
                                            <Grid item container xs={11} columnSpacing={2} justifyContent='center' alignItems='center'>
                                                <Grid item xs={3}>
                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                        <Avatar
                                                            alt="Z"
                                                            src={zohosyncIcon}
                                                            className={item.zoho_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                        />
                                                        <Text analyticsViewAll>
                                                            {item.name === "" ? "-" : capitalizeAndAddSpace(item.name).length > 12 ?
                                                                <HtmlTooltip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                    {capitalizeAndAddSpace(item.name).slice(0, 12) + (capitalizeAndAddSpace(item.name).length > 12 ? "..." : "")}
                                                                </HtmlTooltip>
                                                                :
                                                                capitalizeAndAddSpace(item.name)
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.primary_contact ? item.primary_contact : '-'}</Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '-'}</Text>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Text mediumBlack14>{item.status ? item.status : '-'}</Text>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))

                                    : 
                                    [1, 2, 3, 4].map((item) => (
                                        <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                            <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                    <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    )) 
                                }
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Box sx={{ width: "100%", overflow: "auto" }} pt={2}>
                                <TableContainer component={Paper} sx={{ minWidth: 550, height: '60vh', }}>
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow sx={{ width: 40 }}>
                                                <TableCell>
                                                    <Checkbox value={syncClients.length == selectedClientsZoho.length}
                                                        checked={syncClients.length == selectedClientsZoho.length}
                                                        sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                        checkedIcon={<CheckedIcon />}
                                                        onClick={(e) => checkHandlerZoho(e, 'all')}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: 180 }}><Text mediumBlack14>Client Name</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 180 }}><Text mediumBlack14>Primary Contact</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 160 }}><Text mediumBlack14>Phone Number</Text></TableCell>
                                                <TableCell align="left" sx={{ width: 100 }}><Text mediumBlack14>Status</Text></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {syncClients.length > 0 ? syncClients.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ width: 40, border: 'none' }}>
                                                        <Checkbox value={selectedClientsZoho.some(i => i === row.id)}
                                                            checked={selectedClientsZoho.some(i => i === row.id)}
                                                            sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                            checkedIcon={<CheckedIcon />}
                                                            onClick={(e) => checkHandlerZoho(e, 'each', row)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ width: 180, border: 'none' }}>
                                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='start'>
                                                            <Avatar
                                                                alt="Z"
                                                                src={zohosyncIcon}
                                                                className={row.zoho_migration_status == 'Not Migrated' ? classes.greyAvatar : classes.activeProfile}
                                                            />
                                                            <Text analyticsViewAll>
                                                                {row.name === "" ? "-" : capitalizeAndAddSpace(row.name).length > 12 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(row.name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(row.name).slice(0, 12) + (capitalizeAndAddSpace(row.name).length > 12 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(row.name)
                                                                }
                                                            </Text>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ width: 180, border: 'none' }}> <Text mediumBlack14>{row.primary_contact ? row.primary_contact : '-'}</Text></TableCell>
                                                    <TableCell align="left" sx={{ width: 160, border: 'none' }}><Text mediumBlack14>{row.telephone_number ? row.telephone_number : '-'}</Text></TableCell>
                                                    <TableCell align="left" sx={{ width: 100, border: 'none' }}><Text mediumBlack14  >{row.status ? row.status : '-'}</Text></TableCell>
                                                </TableRow>
                                            ))
                                            : 
                                            [1, 2, 3, 4].map((item) => (
                                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Box>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            )) 
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Hidden>
                    </Box>
                </Grid>
            </ReusablePopup>
        </Grid >
    )
};
