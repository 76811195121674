import { Autocomplete, Box, Chip, Divider, Grid, Menu, MenuItem, Popover, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Text from '../../../../components/customText/Text'
import Search from '../../../../components/select/Select';
import CommonApi from '../../../../apis/CommonApi';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/customButton/Button';
import LoaderButton from '../../../../components/customButton/LoadingButton'
import { float_validation, onNumberOnlyChange, onNumberWithTwoDecimal, validate_emptyField } from '../../../../components/Validation';
import LocalStorage from '../../../../utils/LocalStorage';
import { addErrorMsg, addSuccessMsg, addWarningMsg } from '../../../..//utils/utils';
import ClientStyles from '../ClientStyles';
import percentile from '../../../../assets/client/percentile.svg';
import dollarIcon from '../../../../assets/client/dollarIcon.svg';
import arrowDown from '../../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../../assets/svg/clients/blackarrowUp.svg';
// import infoCircle from '../../../assets/images/info-circle.png';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/closeIcon.svg';
import { ReactComponent as RemoveSvg } from '../../../../assets/svg/removeIcon.svg';
import ToggleSwitch from '../../../../components/toggle/CustomToggle';
import { ErrorMessages } from '../../../../utils/ErrorMessages';

function ClientInvoiceConfigurationAddFlow(props) {
    const { invoiceConfiguration, error, setError, setInvoiceConfiguration, deletedchips, setDeletedchips, deletedLevels, setDeletedLevels } = props
    const classes = ClientStyles();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const boxRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPaymentTerms = Boolean(anchorEl);
    const paymentTermsId = openPaymentTerms ? 'payment-terms-popover' : undefined;
    const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
    const [buttonLoaderDaysSave, setButtonLoaderDaysSave] = useState(false);
    const [openStatusArrow, setOpenStatusArrow] = useState(false);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_create" && item.is_allowed == true))) {
            paymentTermsDropdown();
            employeesList();
        }
        // eslint-disable-next-line       
    }, []);

    const paymentTermsDropdown = () => {
        CommonApi.getPaymentTermsDropdown().then((res) => {
            if (res.data.statusCode == 1003) {
                setPaymentTermsOptions(res.data.data);
                handleClose();
            }
        })
    }

    const employeesList = () => {
        CommonApi.getInvoiceApprovers().then((response) => {
            if (response.data.statusCode == 1003) {
                setEmployees(response.data.data);
            }
        });
    }

    const [days, setDays] = useState('')

    const handleDayChange = (e) => {
        setDays(e.target.value)
        handleValidate(e, e.target.value)
    }

    const handleDaysPaymentTerms = () => {
        if (days == '') {
            error.days = ErrorMessages.fieldRequired
            setError({ ...error });
        } else {
            const data = {
                request_id: LocalStorage.uid(),
                days: days,
                is_active: true
            }
            setButtonLoaderDaysSave(true);
            CommonApi.postPaymentTermsDays(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    paymentTermsDropdown();
                    setButtonLoaderDaysSave(false);
                    addSuccessMsg(res.data.message)
                }
                else {
                    addErrorMsg(res.data.message);
                    setButtonLoaderDaysSave(false);
                }
            })
            setDays('')
        }
    }

    const changeHandler = (e) => {
        if (e.target.name == 'discount_value') {
            if (invoiceConfiguration.discount_type == 1) {
                if (e.target.value > 100) {
                    addWarningMsg('Percentage is not allowed more than 100%');
                    return false;
                } else {
                    invoiceConfiguration[e.target.name] = e.target.value;
                }
            } else {
                var value = e.target.value.trim();
                if (value.length > 9) {
                    const num = parseFloat(value);
                    const isFloat = value.includes('.') || num % 1 !== 0;
                    if (isFloat) {
                        invoiceConfiguration[e.target.name] = value;
                    } else {
                        if (value.length === 10) {
                            if (value.slice(-1) === ".") {
                                invoiceConfiguration[e.target.name] = value;
                            } else {
                                return false;
                            }
                        }
                    }
                } else {
                    invoiceConfiguration[e.target.name] = value;
                    setInvoiceConfiguration({ ...invoiceConfiguration })
                }
            }
        } else if (e.target.name == 'net_pay_terms_id') {
            invoiceConfiguration.net_pay_terms_id = e.target.value
            setInvoiceConfiguration({ ...invoiceConfiguration })
            handleClose();
        }
        else {
            invoiceConfiguration[e.target.name] = e.target.value
            setInvoiceConfiguration({ ...invoiceConfiguration })
        }
        handleValidate(e, e.target.value)
    }

    const handleChecklist = (e, toggleName) => {
        setInvoiceConfiguration({
            ...invoiceConfiguration,
            [toggleName]: e.target.checked
        });
    }

    const handleValidate = (e, value) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case 'discount_value':
                error.discount_value = float_validation(value)
                break
            case 'net_pay_terms_id':
                error.net_pay_terms_id = validate_emptyField(value)
                break
            case 'days':
                error.days = validate_emptyField(value)
                break
            default:
                break
        }
        setError({ ...error });
    }

    const handleClick = () => {
        setAnchorEl(boxRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDays('')
        error['days'] = ''
        setError({ ...error });

    };

    const handleStatusOpen = (e) => {
        setOpenStatusArrow(e.currentTarget);
    }

    const handleStatus = (args) => {
        invoiceConfiguration['discount_type'] = args
        setInvoiceConfiguration({
            ...invoiceConfiguration,
            discount_value: ''
        })
        // setInvoiceConfiguration({ ...invoiceConfiguration })
        setOpenStatusArrow(false);
        setAnchorEl(null);
    }

    const handleChangeLevels = (e, newArr, index) => {
        if (!["", null, undefined].includes(e.target.value)) {
            let { id, value } = newArr[newArr.length - 1];
            let approvals = invoiceConfiguration.approval_levels
            let approverIdsArr = approvals[index].approval_users;
            if (approverIdsArr.filter((i) => i.employee_id == id).length == 0) {
                approverIdsArr.push({ id: "", employee_id: id, employee_name: value });
                setInvoiceConfiguration({ ...invoiceConfiguration, approval_levels: approvals })
            }
        }
    }

    const handleDeleteLevelOpen = (index) => {
        let arr = invoiceConfiguration.approval_levels;
        let deletedLevelArr = deletedLevels;
        if (arr[index].id !== '') {
            deletedLevelArr.push(arr[index].id);
        }
        arr.splice(index, 1);
        arr.forEach((ele, key) => {
            ele.rank = key + 1;
            ele.level = key + 1;
        });
        setInvoiceConfiguration({ ...invoiceConfiguration, approval_levels: arr });
        setDeletedLevels([...deletedLevelArr]);
    }

    const handleDeleteChipLevels = (key, index) => {
        let approvals = invoiceConfiguration.approval_levels;
        let newArr = approvals[index].approval_users;
        let deletedChipsArr = deletedchips;
        if (newArr[key].id !== '') {
            deletedChipsArr.push(newArr[key].id);
        }
        newArr.splice(key, 1);
        if (newArr.length == 0) {
            let deletedLevelArr = deletedLevels;
            if (approvals[index].id !== '') {
                deletedLevelArr.push(approvals[index].id);
            }
            setDeletedLevels([...deletedLevelArr]);
        }
        setInvoiceConfiguration({ ...invoiceConfiguration, approval_levels: approvals });
        setDeletedchips([...deletedChipsArr]);
    }

    const handleAddNewLevel = () => {
        let arr = invoiceConfiguration.approval_levels;
        arr.push({
            id: "",
            level: arr.length + 1,
            approval_users: [],
        },);
        setInvoiceConfiguration({ ...invoiceConfiguration, approval_levels: arr });
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box ref={boxRef}>
                        <Search
                            name='net_pay_terms_id'
                            value={invoiceConfiguration.net_pay_terms_id}
                            options={paymentTermsOptions}
                            label='Payment Terms'
                            onChange={(e) => changeHandler(e)}
                            buttonName='New Payment Terms'
                            error={error.net_pay_terms_id}
                            buttonHandler={
                                handleClick
                            }
                            showButton
                        />
                    </Box>
                    <Popover
                        id={paymentTermsId}
                        open={openPaymentTerms}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}

                    >
                        <Box sx={{ width: '300px !important', minHeight: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' }}>
                            <Grid container flexDirection='column' spacing={2} >
                                <Grid item xs={12}><Text headerBlack>Add Net PayTerms</Text></Grid>
                                <Grid item>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Box width='15%'>
                                            <Text headerText14>Net - </Text>
                                        </Box>
                                        <Box width='85%'>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'days',
                                                    value: days || '',
                                                    inputProps: { maxLength: 3 }

                                                }}
                                                handleChange={handleDayChange}
                                                onKeyPress={onNumberOnlyChange}
                                                disabled={false}
                                                clientInput
                                                error={error.days}
                                                labelText='Days'
                                            />
                                            {
                                                error.days ?
                                                    <Text red>{error.days ? error.days : ''}</Text> : ''
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item container justifyContent='end' gap={2}>
                                    <Grid item >
                                        <Button smallBlueOutline sx={{ height: '40px !important' }} onClick={handleClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item >
                                        <LoaderButton loading={buttonLoaderDaysSave} saveVerySmall sx={{ height: '40px !important' }} onClick={handleDaysPaymentTerms}>Save</LoaderButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Popover>
                    {
                        error.net_pay_terms_id ?
                            <Text red>{error.net_pay_terms_id ? error.net_pay_terms_id : ''}</Text> : ''
                    }
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box width='100%' alignItems='center' p={'10px 0px'}>
                        <Text headerBlack>Client Discount</Text>
                    </Box>
                    <Box display='flex' flexDirection='row' gap={2} width='100% !important' pt={2}>
                        <Box sx={{ width: '80% !important' }}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'discount_value',
                                    value: invoiceConfiguration.discount_value,
                                }}
                                handleChange={changeHandler}
                                clientInput
                                onKeyPress={onNumberWithTwoDecimal}
                                error={error.discount_value}
                                labelText={invoiceConfiguration.discount_type == 1 ? 'Percentage' : 'Value'}
                            />
                            {
                                error.discount_value ?
                                    <Text red>{error.discount_value ? error.discount_value : ''}</Text> : ''
                            }
                        </Box>
                        <Box className={classes.boxContainer}>
                            <Box className={classes.percentileIcon}>
                                {
                                    invoiceConfiguration.discount_type == 1 ?
                                        <img src={percentile} alt='percentile' /> : <img src={dollarIcon} alt='dollarIcon' />
                                }
                            </Box>
                            <Divider orientation="vertical" className={classes.verticalDivider} />
                            <Box className={classes.selectionIcon} onClick={handleStatusOpen} >
                                {
                                    openStatusArrow ?
                                        <img src={arrowUp} alt='arrowUp' className={classes.arrowImage} /> :
                                        <img src={arrowDown} alt='arrowDown' className={classes.arrowImage} />
                                }
                            </Box>
                            <Menu
                                anchorEl={openStatusArrow}
                                id="status-menu"
                                open={openStatusArrow}
                                onClose={() => { setOpenStatusArrow(null) }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                        mt: 2.5,
                                        width: '70px',
                                    }
                                }}
                                sx={{ left: '-0.8% !important' }}
                                transformOrigin={{ horizontal: "center", vertical: "top" }}
                                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                            >
                                <MenuItem className={classes.menuItem} onClick={() => handleStatus(1)}><Text smallBlack><img src={percentile} alt='percentile' /></Text></MenuItem>
                                <Divider className={classes.divider} />
                                <MenuItem className={classes.menuItem} onClick={() => handleStatus(2)}> <Text smallBlack>$</Text></MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' alignItems='center' p={'15px 8px 0px 15px'}>
                    <Text headerBlack>Invoice Approval Configuration</Text>
                    {
                        (invoiceConfiguration.approval_levels.length > 0 && invoiceConfiguration.approval_levels.length < 5 && invoiceConfiguration.approval_levels[invoiceConfiguration.approval_levels.length - 1].approval_users.length > 0) &&
                        <Button blueBorderOutlined onClick={handleAddNewLevel}>Add Level</Button>
                    }
                </Box>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {
                        invoiceConfiguration.approval_levels.length > 0 && invoiceConfiguration.approval_levels.map((i, key) => (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", margin: '12px 0px'
                                }
                                } >
                                    <div style={{ width: '100%', paddingTop: i.approval_users.length > 0 ? '16px' : '0px', minHeight: '69px', display: 'flex', alignItems: "center", }}>
                                        <Autocomplete
                                            multiple
                                            limitTags={5}
                                            id="multiple-limit-tags"
                                            options={employees}
                                            getOptionLabel={(option) => option.value}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.uniqueIdentifier}>
                                                    {option.value}
                                                </li>
                                            )}
                                            value={i.approval_users}
                                            renderInput={(params) => (
                                                <TextField {...params} label={`Level ${key + 1} Approvers (Optional)`} pt={2} />
                                            )}
                                            onChange={(e, newArr) => handleChangeLevels(e, newArr, key)}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, keyId) => (
                                                    <Chip
                                                        {...getTagProps({ keyId })}
                                                        key={keyId}
                                                        label={option && option.employee_name}
                                                        sx={{ gap: '6px', m: "4px 6px", p: "4px", font: "12px Quicksand", fontWeight: 500, }}
                                                        onDelete={() => handleDeleteChipLevels(keyId, key)}
                                                        deleteIcon={<DeleteIcon />}
                                                    />
                                                ))
                                            }
                                            sx={{
                                                width: '100%',
                                                "& .MuiInputBase-root": {
                                                    cursor: "pointer",
                                                },
                                                "& .MuiInputBase-input": {
                                                    cursor: "pointer",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none"
                                                },
                                                "& .MuiAutocomplete-endAdornment": {
                                                    display: "none"
                                                },
                                                "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                    transform: "translate(10px, 16px) scale(1);"
                                                },
                                                "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                    color: "#737373",
                                                    fontSize: "14px",
                                                    fontFamily: "Quicksand",
                                                    fontWeight: 400,
                                                },
                                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                    color: "#737373",
                                                    fontSize: "15px",
                                                    fontFamily: "Quicksand",
                                                    fontWeight: 500,
                                                },
                                                "&.Mui-focused .MuiInputLabel-outlined": {
                                                    color: "#737373",
                                                    fontSize: "10px",
                                                    fontFamily: "Quicksand",
                                                    fontWeight: 400,
                                                    transform: i.approval_users.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                },
                                            }}
                                        />
                                    </div>
                                    {
                                        invoiceConfiguration.approval_levels.length > 1 ?
                                            <Box mx={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", }}>
                                                <RemoveSvg onClick={() => handleDeleteLevelOpen(key)} style={{ cursor: "pointer" }} />
                                            </Box> : null
                                    }
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider sx={{ border: '1px solid #EAECF0' }} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box display='flex' flexDirection='row' gap={1}>
                        <Text headerBlack>Terms and Conditions</Text>
                        <p style={{ color: '#C7CCD3', font: '16px Quicksand', fontWeight: `${500}` }}>(Optional)</p>
                    </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        multiline={true}
                        rows={3}
                        inputProps={{
                            name: 'terms_and_conditions',
                            value: invoiceConfiguration.terms_and_conditions,
                            // inputProps: { maxLength: 255 }
                        }}
                        handleChange={changeHandler}
                        descriptionFormControl1
                        descriptionInput
                        placeholder={'Type Here'}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={1} mb={2}>
                    <Text headerBlack>Advanced Options</Text>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Attach timesheet to invoice</Text>
                        <ToggleSwitch
                            isActive={invoiceConfiguration.attach_timesheet}
                            switchChange={(e) => handleChecklist(e, 'attach_timesheet')}
                        />
                    </Box>
                    <Divider sx={{ margin: '6px 0px', border: '1px solid #EAECF0' }} />
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send invoice to client via E-mail</Text>
                        <ToggleSwitch
                            isActive={invoiceConfiguration.send_invoice_email}
                            switchChange={(e) => handleChecklist(e, 'send_invoice_email')}
                        />
                    </Box>
                    <Divider sx={{ margin: '6px 0px', border: '1px solid #EAECF0' }} />
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send reminder to client via E-mail</Text>
                        <ToggleSwitch
                            isActive={invoiceConfiguration.send_reminder_email}
                            switchChange={(e) => handleChecklist(e, 'send_reminder_email')}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ClientInvoiceConfigurationAddFlow