import { Autocomplete, Box, Divider, Grid, Chip, Menu, MenuItem, Popover, TextField, Skeleton, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import UserProfileStyles from '../UserProfileStyles';
import React, { useEffect, useRef, useState } from 'react';
import Text from '../../../../../components/customText/Text';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import ToggleSwitch from '../../../../../components/toggle/CustomToggle';
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/customButton/Button';
import { addErrorMsg, addSuccessMsg, addWarningMsg } from '../../../../../utils/utils';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';
import LocalStorage from '../../../../../utils/LocalStorage';
import Search from '../../../../../components/select/Select';
import CommonApi from '../../../../../apis/CommonApi';
import { float_validation, isValid, onNumberWithTwoDecimal, onNumberOnlyChange, validate_emptyField } from '../../../../../components/Validation';
import percentile from '../../../../../assets/client/percentile.svg';
import dollarIcon from '../../../../../assets/client/dollarIcon.svg';
import arrowDown from '../../../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../../../assets/svg/clients/blackarrowUp.svg';
import { ReactComponent as RemoveSvg } from '../../../../../assets/svg/removeIcon.svg';
import LoaderButton from '../../../../../components/customButton/LoadingButton'
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/closeIcon.svg';
import ClientsApi from '../../../../../apis/admin/clients/ClientsApi';
import LoadingScreen from '../../../employees/userprofile/document/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));
export default function InvoiceConfiguration(props) {
  const { id, invoiceState, setInvoiceState, actionInvoice, setActionInvoice, loading, setLoading, getActivity, status } = props
  const classes = UserProfileStyles();
  const [days, setDays] = useState('');
  const [error, setError] = useState([]);
  const [deletedchips, setDeletedchips] = useState([]);
  const [deletedLevels, setDeletedLevels] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPaymentTerms = Boolean(anchorEl);
  const boxRef = useRef(null);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const paymentTermsId = openPaymentTerms ? 'payment-terms-popover' : undefined;
  const [buttonLoaderDaysSave, setButtonLoaderDaysSave] = useState(false);
  const [openStatusArrow, setOpenStatusArrow] = useState(false);
  const [employees, setEmployees] = useState([]);
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const navigate = useNavigate()
  const [connectedAppsList, setConnectedAppsList] = useState({
    isZohoIntegrated: false,
    isQuickBookIntegrated: false
  })


  useEffect(() => {
    getInvoiceConfigDetails();
    // eslint-disable-next-line       
  }, [props.current]);

  useEffect(() => {
    paymentTermsDropdown();
    employeesList();
    connectedApps()
    // eslint-disable-next-line 
  }, [])
  
  const paymentTermsDropdown = () => {
    CommonApi.getPaymentTermsDropdown().then((res) => {
      if (res.data.statusCode == 1003) {
        setPaymentTermsOptions(res.data.data);
        handleClose();
      }
    })
  }

  const connectedApps = () => {
    CommonApi.connectedApps().then((res) => {
      if (res.data.statusCode === 1003) {
        setConnectedAppsList(res.data.data);
      }
    })
  }

  const employeesList = () => {
    CommonApi.getInvoiceApprovers().then((response) => {
      if (response.data.statusCode == 1003) {
        setEmployees(response.data.data);
      }
    });
  }

  const getInvoiceConfigDetails = () => {
    setLoading(true);
    setActionInvoice('view')
    ClientsApi.clientGetInvoiceApi(id, 'client').then((response) => {
      setTimeout(() => {
        setLoading(false);
        if (response.data.statusCode == 1003) {
          if (response.data.data.approval_levels.length > 0 && response.data.data.approval_levels[0].approval_users.length != 0) {
            setInvoiceState({
              ...response.data.data,
            });
          } else {
            setInvoiceState({
              ...response.data.data,
              approval_levels: [
                {
                  approval_users: [],
                  rank: 1,
                },
              ],
            });
          }
        } else {
          addErrorMsg(response.data.message);
        }
      }, 400)
    })
  }

  const editActionInvoice = () => {
    setActionInvoice('update');
    props.setformEdit(true)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setDays('')
    error['days'] = ''
    setError({ ...error });

  };

  const handleClick = () => {
    setAnchorEl(boxRef.current);
  };

  const handleDaysPaymentTerms = () => {
    if (days == '') {
      error.days = ErrorMessages.fieldRequired
      setError({ ...error });
    } else {
      const data = {
        request_id: LocalStorage.uid(),
        days: days,
        is_active: true
      }
      setButtonLoaderDaysSave(true);
      CommonApi.postPaymentTermsDays(data).then((res) => {
        if (res.data.statusCode == 1003) {
          paymentTermsDropdown();
          setButtonLoaderDaysSave(false);
          addSuccessMsg(res.data.message)
        }
        else {
          addErrorMsg(res.data.message);
          setButtonLoaderDaysSave(false);
        }
      })
      setDays('')
    }
  }

  const changeHandler = (e) => {
    if (e.target.name == 'discount_value') {
      if (invoiceState.discount_type == 1) {
        if (e.target.value > 100) {
          addWarningMsg('Percentage is not allowed more than 100%');
          return false;
        } else {
          invoiceState[e.target.name] = e.target.value;
        }
      } else {
        var value = e.target.value.trim();
        if (value.length > 9) {
          const num = parseFloat(value);
          const isFloat = value.includes('.') || num % 1 !== 0;
          if (isFloat) {
            invoiceState[e.target.name] = value;
          } else {
            if (value.length === 10) {
              if (value.slice(-1) === ".") {
                invoiceState[e.target.name] = value;
              } else {
                return false;
              }
            }
          }
        } else {
          invoiceState[e.target.name] = value;
          setInvoiceState({ ...invoiceState })
        }
      }
    } else if (e.target.name == 'net_pay_terms_id') {
      invoiceState.net_pay_terms_id = e.target.value
      setInvoiceState({ ...invoiceState })
      handleClose();
    }
    else {
      invoiceState[e.target.name] = e.target.value
      setInvoiceState({ ...invoiceState })
    }
    handleValidate(e, e.target.value)
  }

  const handleValidate = (e, value) => {
    let input = e.target
    switch (input.name || input.tagName) {
      case 'discount_value':
        error.discount_value = float_validation(value)
        break
      case 'net_pay_terms_id':
        error.net_pay_terms_id = validate_emptyField(value)
        break
      case 'days':
        error.days = validate_emptyField(value)
        break
      default:
        break
    }
    setError({ ...error });
  }

  const handleChecklist = (e, toggleName) => {
    setInvoiceState({
      ...invoiceState,
      [toggleName]: e.target.checked
    });
  }

  const handleDayChange = (e) => {
    setDays(e.target.value)
    handleValidate(e, e.target.value)
  }

  const handleStatusOpen = (e) => {
    setOpenStatusArrow(e.currentTarget);
  }

  const handleStatus = (args) => {
    invoiceState['discount_type'] = args
    setInvoiceState({
      ...invoiceState,
      discount_value: ''
    })
    setOpenStatusArrow(false);
    setAnchorEl(null);
  }

  const handleDeleteLevelOpen = (index) => {
    let arr = invoiceState.approval_levels;
    let deletedLevelArr = deletedLevels;
    if (arr[index].id !== '') {
      deletedLevelArr.push(arr[index].id);
    }
    arr.splice(index, 1);
    arr.forEach((ele, key) => {
      ele.rank = key + 1;
      ele.level = key + 1;
    });
    setInvoiceState({ ...invoiceState, approval_levels: arr, deleted_level_ids: deletedLevels });
    setDeletedLevels([...deletedLevelArr]);
  }

  const handleDeleteChipLevels = (key, index) => {
    let approvals = invoiceState.approval_levels;
    let newArr = approvals[index].approval_users;
    let deletedChipsArr = deletedchips;

    if (newArr.length === 1) {
      addErrorMsg(`${newArr[key].employee_name} is currently the sole approver for this level. Note that if this approver is removed, all invoices pending approval at this level will be automatically marked as approved.`);

      if (newArr[key].id !== '') {
        deletedChipsArr.push(newArr[key].id);
      }
      newArr.splice(key, 1);

      let deletedLevelArr = deletedLevels;
      if (approvals[index].id !== '') {
        deletedLevelArr.push(approvals[index].id);
      }
      setDeletedLevels([...deletedLevelArr]);

      setInvoiceState({ ...invoiceState, approval_levels: approvals, deleted_approver_ids: deletedchips });
      setDeletedchips([...deletedChipsArr]);
      return;
    }

    if (newArr[key].id !== '') {
      deletedChipsArr.push(newArr[key].id);
    }
    newArr.splice(key, 1);
    if (newArr.length == 0) {
      let deletedLevelArr = deletedLevels;
      if (approvals[index].id !== '') {
        deletedLevelArr.push(approvals[index].id);
      }
      setDeletedLevels([...deletedLevelArr]);
    }

    setInvoiceState({ ...invoiceState, approval_levels: approvals, deleted_approver_ids: deletedchips });
    setDeletedchips([...deletedChipsArr]);
  }

  const handleAddNewLevel = () => {
    let arr = invoiceState.approval_levels;
    addErrorMsg("Approvers at this new level will have the authority to approve all invoices created moving forward. This change will not affect invoices that have already been created.")
    arr.push({
      id: "",
      level: arr.length + 1,
      approval_users: [],
    },);
    setInvoiceState({ ...invoiceState, approval_levels: arr });
  }

  const handleChangeLevels = (e, newArr, index) => {
    if (!["", null, undefined].includes(e.target.value)) {
      let { id, value } = newArr[newArr.length - 1];
      let approvals = invoiceState.approval_levels
      let approverIdsArr = approvals[index].approval_users;
      if (approverIdsArr.filter((i) => i.employee_id == id).length == 0) {
        approverIdsArr.push({ id: "", employee_id: id, employee_name: value });
        setInvoiceState({ ...invoiceState, approval_levels: approvals })
      }
      // validateApprovalLevels();
    }
  }


  const [buttonLoader, setButtonLoader] = useState(false);

  const handleCancel = () => {
    setActionInvoice('view');
    getActivity();
    getInvoiceConfigDetails();
    setError([]);
    props.setformEdit(false)
  }

  const validateInvoiceErrors = () => {
    let { discount_value, net_pay_terms_id } = invoiceState;
    let errors = {};
    errors.net_pay_terms_id = validate_emptyField(net_pay_terms_id);
    errors.discount_value = validate_emptyField(discount_value);
    return errors;
  }

  const handleSubmit = async () => {
    let invoiceConfigErrors = validateInvoiceErrors();
    const sortedApprovalLevels = [...invoiceState.approval_levels].sort((a, b) => a.level - b.level);
    const updatedApprovalLevels = sortedApprovalLevels.map((level, index) => {
      level.level = index + 1;
      return level;
    });

    if (isValid(invoiceConfigErrors)) {
      const data = {
        request_id: LocalStorage.uid(),
        company_id: id,
        ...invoiceState,
        approval_levels: updatedApprovalLevels,
      }
      setButtonLoader(true);
      ClientsApi.clientStoreInvoiceApi('client', data).then((res) => {
        if (res.data.statusCode === 1003) {
          setButtonLoader(false);
          setActionInvoice('view')
          addSuccessMsg(res.data.message);
          props.setformEdit(false)
          handleCancel();
          getActivity();
          getInvoiceConfigDetails()
        } else {
          addErrorMsg(res.data.message);
          setButtonLoader(false);
        }
      })
    } else {
      addWarningMsg(ErrorMessages.genericMessage);
      let s1 = { error };
      s1 = invoiceConfigErrors;
      setError(s1);
    }
  }

  return (
    <>
      <Grid container className={classes.contentScrollAnalytics1} p={{ lg: '30px', md: "30px", sm: "30px", xs: "30px 10px" }}>
        {actionInvoice === 'view' ?
          <Box pb={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Text largeBlue> Invoice Configuration </Text>
                {
                  actionInvoice == 'view' &&

                    loading ? <Skeleton animation='linear' className={classes.editSkeleton} /> :
                    <>
                      {
                        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true))) ?
                          status === 'In Active' ? '' :
                            <IconButton onClick={editActionInvoice} >
                              <img src={EditIcon} alt='Edit' />
                            </IconButton>
                          : ""
                      }
                    </>
                }

              </Box>

            </Grid>
            {
              loading ?
                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                  <LoadingScreen />
                </Grid>
                :
                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                  <Grid item lg={12} md={6} sm={6} xs={12}>

                    <Box py={1} >
                      <Text mediumGreyHeader>Payment Terms</Text>
                      <Text pt='3px' mediumBlack14 noWrap>{invoiceState.net_pay_term_days === '' || invoiceState.net_pay_term_days === null || invoiceState.net_pay_term_days === undefined ? '--' : `Net-${invoiceState.net_pay_term_days}`}</Text>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={6} sm={6} xs={12}>
                    <Text largeBlue> Client Discount </Text>
                    <Box py={1} pt={2}>
                      <Text mediumGreyHeader>Discount</Text>
                      <Text pt='3px' mediumBlack14 noWrap>
                        {invoiceState.discount_value && invoiceState.discount_type ? (
                          <Box className={classes.percentileIcon}>
                            {invoiceState.discount_value}
                            {invoiceState.discount_type == 1 ? (
                              <img src={percentile} alt='percentile' />
                            ) : (
                              <img src={dollarIcon} alt='dollarIcon' />
                            )}
                          </Box>
                        ) : (
                          '--'
                        )}
                      </Text>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={6} sm={6} xs={12}>
                    <Text largeBlue>Invoice Approval Configuration</Text>
                    <Box px={2} py={1}>
                      {
                        invoiceState.approval_levels && invoiceState.approval_levels.length > 0 && (invoiceState.approval_levels[0].approval_users.length != 0) ?
                          <>
                            {
                              invoiceState.approval_levels.map((item, index) => (
                                <Grid item lg={10} md={10} sm={10} py='10px' key={item.id}>
                                  <Text greysmallLabel>Level {index + 1} Approvers</Text>
                                  <Grid item container lg={12} md={12} sm={12}>
                                    {item.approval_users.map((i) => (
                                      <Box pt={1}>
                                        <Chip
                                          label={i.employee_name}
                                          key={i.employee_id}
                                          sx={{ marginRight: 1, marginBottom: 1, fontSize: '11px !important', color: '#181818', fontWeight: `${600} !important`, background: '#EEEEEE !important', }}
                                          size='small'
                                        />
                                      </Box>
                                    ))}
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </> :
                          <Box m='0px 0px 0px -17px'>
                            --
                          </Box>
                      }
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={6} sm={6} xs={12} >
                    <Text largeBlue> Terms & Conditions (Optional) </Text>
                    <Box my={1} className={classes.termsConditions}>
                      <Text pt='3px' mediumBlack14>
                        {invoiceState.terms_and_conditions ? invoiceState.terms_and_conditions : '--'}
                      </Text>
                    </Box>

                  </Grid>
                  <Grid item lg={12} md={6} sm={6} xs={12}>
                    <Text largeBlue> Advanced Options </Text>
                    <Box py={1} pt={2}>
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '16px 0px 16px 0px' }}>
                          <Text black400>Attach timesheet to invoice</Text>
                          <ToggleSwitch
                            isActive={invoiceState.attach_timesheet}
                            disable={true}
                          // switchChange={(e) => handleChecklist(e, 'attach_timesheet')}
                          />
                        </Box>
                        <Divider sx={{ margin: '4px 0px', border: '1px solid #EAECF0' }} />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '16px 0px 16px 0px' }}>
                          <Text black400>Send invoice to client via E-mail</Text>
                          <ToggleSwitch
                            isActive={invoiceState.send_invoice_email}
                            disable={true}
                          // switchChange={(e) => handleChecklist(e, 'send_invoice_email')}
                          />
                        </Box>
                        <Divider sx={{ border: '1px solid #EAECF0' }} />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '16px 0px 16px 0px' }}>
                          <Text black400>Send reminder to client via E-mail</Text>
                          <ToggleSwitch
                            isActive={invoiceState.send_reminder_email}
                            disable={true}
                          // switchChange={(e) => handleChecklist(e, 'send_reminder_email')}
                          />
                        </Box>
                        <Divider sx={{ border: '1px solid #EAECF0' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '16px 0px 16px 0px' }}>
                          <Text black400>Send invoice to Quickbooks</Text>
                          {
                            connectedAppsList.isQuickBookIntegrated ?
                              <ToggleSwitch name='send_quick_books_invoice' isActive={invoiceState.send_quick_books_invoice} disable={true} />
                              :
                              <HtmlTooltip
                                placement="top"
                                arrow
                                sx={{ fontSize: '14px !important' }}
                                title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                              >
                                <button className={classes.disabledBorderSmall} disabled={true} type="button" >Set up</button>
                              </HtmlTooltip>
                          }
                        </Box>
                        <Divider sx={{ border: '1px solid #EAECF0' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '16px 0px 16px 0px' }}>
                          <Text black400>Send invoice to Zohobooks</Text>
                          {
                            connectedAppsList.isZohoIntegrated ?
                              <ToggleSwitch name='send_zoho_books_invoice' isActive={invoiceState.send_zoho_books_invoice} disable={true} />
                              :
                              <HtmlTooltip
                                placement="top"
                                arrow
                                sx={{ fontSize: '14px !important' }}
                                title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                              >
                                <button className={classes.disabledBorderSmall} disabled={true} type="button" >Set up</button>
                              </HtmlTooltip>
                          }
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
            }
          </Box>
          :
          <>
            {
              loading ?
                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                  <LoadingScreen />
                </Grid>
                :
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box>
                        <Text headerBlack>Invoice Configuration</Text>
                      </Box>
                      <Box py={1} pt={2} ref={boxRef}>
                        <Search
                          name='net_pay_terms_id'
                          value={invoiceState.net_pay_terms_id}
                          options={paymentTermsOptions}
                          label='Payment Terms'
                          onChange={(e) => changeHandler(e)}
                          buttonName='New Payment Terms'
                          error={error.net_pay_terms_id}
                          buttonHandler={
                            handleClick
                          }
                          showButton
                        />
                      </Box>
                      <Popover
                        id={paymentTermsId}
                        open={openPaymentTerms}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}

                      >
                        <Box sx={{ width: '300px !important', minHeight: '176px !important', padding: '12px !important', boxShadow: '0px 0px 15px 0px #0000001F !important', borderRadius: '8px !important' }}>
                          <Grid container flexDirection='column' spacing={2} >
                            <Grid item xs={12}><Text headerBlack>Add Net PayTerms</Text></Grid>
                            <Grid item>
                              <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                <Box width='15%'>
                                  <Text headerText14>Net - </Text>
                                </Box>
                                <Box width='85%'>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'days',
                                      value: days || '',
                                      inputProps: { maxLength: 3 }

                                    }}
                                    handleChange={handleDayChange}
                                    onKeyPress={onNumberOnlyChange}
                                    disabled={false}
                                    clientInput
                                    error={error.days}
                                    labelText='Days'
                                  />
                                  {
                                    error.days ?
                                      <Text red>{error.days ? error.days : ''}</Text> : ''
                                  }
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item container justifyContent='end' gap={2}>
                              <Grid item >
                                <Button smallBlueOutline sx={{ height: '40px !important' }} onClick={handleClose}>Cancel</Button>
                              </Grid>
                              <Grid item >
                                <LoaderButton loading={buttonLoaderDaysSave} saveVerySmall sx={{ height: '40px !important' }} onClick={handleDaysPaymentTerms}>Save</LoaderButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Popover>
                      {
                        error.net_pay_terms_id ?
                          <Text red>{error.net_pay_terms_id ? error.net_pay_terms_id : ''}</Text> : ''
                      }
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box>
                        <Text headerBlack>Client Discount</Text>
                      </Box>
                      <Box display='flex' flexDirection='row' gap={2} width='100% !important' py={1} pt={2}>
                        <Box sx={{ width: '80% !important' }}>
                          <Input
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: 'discount_value',
                              value: invoiceState.discount_value,
                            }}
                            handleChange={changeHandler}
                            clientInput
                            onKeyPress={onNumberWithTwoDecimal}
                            error={error.discount_value}
                            labelText={invoiceState.discount_type == 1 ? 'Percentage' : 'Value'}
                          />
                          {
                            error.discount_value ?
                              <Text red>{error.discount_value ? error.discount_value : ''}</Text> : ''
                          }
                        </Box>
                        <Box className={classes.boxContainer}>
                          <Box className={classes.percentileIcon}>
                            {
                              invoiceState.discount_type == 1 ?
                                <img src={percentile} alt='percentile' /> : <img src={dollarIcon} alt='dollarIcon' />
                            }
                          </Box>
                          <Divider orientation="vertical" className={classes.verticalDivider} />
                          <Box className={classes.selectionIcon} onClick={handleStatusOpen} >
                            {
                              openStatusArrow ?
                                <img src={arrowUp} alt='arrowUp' className={classes.arrowImage} /> :
                                <img src={arrowDown} alt='arrowDown' className={classes.arrowImage} />
                            }
                          </Box>

                          <Menu
                            anchorEl={openStatusArrow}
                            id="status-menu"
                            open={openStatusArrow}
                            onClose={() => { setOpenStatusArrow(null) }}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                mt: 2.5,
                                width: '70px',
                              }
                            }}
                            sx={{ left: '-0.8% !important' }}
                            transformOrigin={{ horizontal: "center", vertical: "top" }}
                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                          >
                            <MenuItem className={classes.menuItem} onClick={() => handleStatus(1)}><Text smallBlack><img src={percentile} alt='percentile' /></Text></MenuItem>
                            <Divider className={classes.divider} />
                            <MenuItem className={classes.menuItem} onClick={() => handleStatus(2)}> <Text smallBlack>$</Text></MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' alignItems='center' p={'15px 8px 0px 15px'}>
                      <Text headerBlack>Invoice Approval Configuration</Text>
                      {
                        (invoiceState.approval_levels.length > 0 && invoiceState.approval_levels.length < 5 && invoiceState.approval_levels[invoiceState.approval_levels.length - 1].approval_users.length > 0) &&
                        <Button blueBorderOutlined onClick={handleAddNewLevel}>Add Level</Button>
                      }
                    </Box>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {
                        invoiceState.approval_levels.map((i, key) => (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{
                              width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", margin: '12px 0px'
                            }
                            } >
                              <div style={{ width: '100%', paddingTop: i.approval_users.length > 0 ? '16px' : '0px', minHeight: '66px', display: 'flex', alignItems: "center", }}>
                                <Autocomplete
                                  multiple
                                  limitTags={5}
                                  id="multiple-limit-tags"
                                  options={employees}
                                  getOptionLabel={(option) => option.value}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.uniqueIdentifier}>
                                      {option.value}
                                    </li>
                                  )}
                                  value={i.approval_users}
                                  renderInput={(params) => (
                                    <TextField {...params} label={`Level ${key + 1} Approvers (Optional)`} pt={2} />
                                  )}
                                  onChange={(e, newArr) => handleChangeLevels(e, newArr, key)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, keyId) => (
                                      <Chip
                                        {...getTagProps({ keyId })}
                                        key={keyId}
                                        label={option && option.employee_name}
                                        sx={{ gap: '6px', m: "4px 6px", p: "4px", font: "12px Quicksand", fontWeight: 500, }}
                                        onDelete={() => handleDeleteChipLevels(keyId, key)}
                                        deleteIcon={<DeleteIcon />}
                                      />
                                    ))
                                  }
                                  sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                      cursor: "pointer",
                                    },
                                    "& .MuiInputBase-input": {
                                      cursor: "pointer",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none"
                                    },
                                    "& .MuiAutocomplete-endAdornment": {
                                      display: "none"
                                    },
                                    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                      transform: "translate(10px, 16px) scale(1);"
                                    },
                                    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                      color: "#737373",
                                      fontSize: "14px",
                                      fontFamily: "Quicksand",
                                      fontWeight: 400,
                                    },
                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                      color: "#737373",
                                      fontSize: "15px",
                                      fontFamily: "Quicksand",
                                      fontWeight: 500,
                                    },
                                    "&.Mui-focused .MuiInputLabel-outlined": {
                                      color: "#737373",
                                      fontSize: "10px",
                                      fontFamily: "Quicksand",
                                      fontWeight: 400,
                                      transform: i.approval_users.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                    },
                                  }}
                                />
                              </div>
                              {
                                invoiceState.approval_levels.length > 1 ?
                                  <Box mx={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", }}>
                                    <RemoveSvg onClick={() => handleDeleteLevelOpen(key)} style={{ cursor: "pointer" }} />
                                  </Box> : null
                              }
                            </Box>
                          </Grid>
                        ))
                      }
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider sx={{ margin: '10px 0px', border: '1px solid #EAECF0' }} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box display='flex' flexDirection='row' gap={1}>
                        <Text headerBlack>Terms and Conditions (Optional)</Text>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Input
                        formControlProps={{
                          fullWidth: true
                        }}
                        multiline={true}
                        rows={3}
                        inputProps={{
                          name: 'terms_and_conditions',
                          value: invoiceState.terms_and_conditions,
                          // inputProps: { maxLength: 255 }
                        }}
                        handleChange={changeHandler}
                        descriptionFormControl1
                        descriptionInput
                        placeholder={'Type Here'}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1} mb={2}>
                      <Text headerBlack>Advanced Options</Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Attach timesheet to invoice</Text>
                        <ToggleSwitch
                          isActive={invoiceState.attach_timesheet}
                          switchChange={(e) => handleChecklist(e, 'attach_timesheet')}
                        />
                      </Box>
                      <Divider sx={{ border: '1px solid #EAECF0' }} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send invoice to client via E-mail</Text>
                        <ToggleSwitch
                          isActive={invoiceState.send_invoice_email}
                          switchChange={(e) => handleChecklist(e, 'send_invoice_email')}
                        />
                      </Box>
                      <Divider sx={{ border: '1px solid #EAECF0' }} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send reminder to client via E-mail</Text>
                        <ToggleSwitch
                          isActive={invoiceState.send_reminder_email}
                          switchChange={(e) => handleChecklist(e, 'send_reminder_email')}
                        />
                      </Box>
                      <Divider sx={{ border: '1px solid #EAECF0' }} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send invoice to Quickbooks</Text>
                        {
                          connectedAppsList.isQuickBookIntegrated ?
                            <ToggleSwitch name='send_quick_books_invoice' isActive={invoiceState.send_quick_books_invoice} switchChange={(e) => handleChecklist(e, 'send_quick_books_invoice')} />
                            :
                            <HtmlTooltip
                              placement="top"
                              arrow
                              sx={{ fontSize: '14px !important' }}
                              title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                            >
                              <button className={classes.bluebordersmall} type="button" onClick={() => {
                                props.setformEdit(false); setTimeout(() => {
                                  navigate('/app-integrations')
                                })
                              }}>Set up</button>
                            </HtmlTooltip>
                        }
                      </Box>
                      <Divider sx={{ border: '1px solid #EAECF0' }} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
                        <Text black400>Send invoice to Zohobooks</Text>
                        {
                          connectedAppsList.isZohoIntegrated ?
                            <ToggleSwitch name='send_zoho_books_invoice' isActive={invoiceState.send_zoho_books_invoice} switchChange={(e) => handleChecklist(e, 'send_zoho_books_invoice')} />
                            :
                            <HtmlTooltip
                              placement="top"
                              arrow
                              sx={{ fontSize: '14px !important' }}
                              title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                            >
                              <button className={classes.bluebordersmall} type="button" onClick={() => {
                                props.setformEdit(false); setTimeout(() => {
                                  navigate('/app-integrations')
                                })
                              }}>Set up</button>
                            </HtmlTooltip>
                        }
                      </Box>
                    </Grid>

                  </Grid>
                </Grid >
            }
          </>
        }
      </Grid>
      {
        !loading && (actionInvoice == 'update') &&
        <Grid item lg={12} md={12} sm={12} xs={12} p='10px 0px 5px 0px !important' >
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Button blackCancelBtn onClick={handleCancel}>Cancel</Button>
            <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit} >Save</LoaderButton>
          </Box>
        </Grid>
      }
    </>

  )
}
