import React, { useEffect, useState } from "react";
import {
  Grid,
  Avatar,
  Divider,
  Box,
  SwipeableDrawer,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import Text from "../../../components/customText/Text";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ClientDetails from "./ClientDetails";
import UnpaidInvoicesCard from "./UnpaidInvoicesCard";
import EmployeeCard from "./EmployeeCard";
import Input from "../../../components/input/Input";
import ReusablePopup from "../../../components/reuablePopup/ReusablePopup";
import Button from "../../../components/customButton/Button";
import { useZipcodeAPICallInvoice } from "../ZipcodeCommon";
import { address_validation, isValid, validate_city, validate_empty_address, validate_zipcode } from "../../../components/Validation";
import { addErrorMsg, addSuccessMsg, checkZipcode } from "../../../utils/utils";
import Nodata from '../../../assets/svg/nodataActivity.svg';
import { BlackToolTip } from '../../../utils/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import timelineIcon from '../../../assets/svg/timelineIcon.svg';
import Loader from '../../../assets/gif/japfuLoader.gif';
import InvoiceAPI from "../../../apis/admin/InvoiceAPI";
import ClientsApi from "../../../apis/admin/clients/ClientsApi";
import LocalStorage from "../../../utils/LocalStorage";
import { Buffer } from 'buffer';

const ClientCard = (props) => {

  const { clientDetailsAPI, state, setState, employeeDetails, open, setOpen, selectedTab, setSelectedTab, getClientDetails } = props

  const [editPopup, setEditPopup] = useState(false)
  const [activityData, setActivityData] = useState(false)
  const [clientState, setClientState] = useState({
    state_name: '',
    city: '',
    country_name: '',
    address_line_one: '',
    address_line_two: '',
    zip_code: '',
  })
  // eslint-disable-next-line
  const [error, setError] = useState({
    state_name: '',
    city: '',
    country_name: '',
    address_line_one: '',
    address_line_two: '',
    zip_code: '',
  })
  const [expand, setExpand] = useState('')// eslint-disable-next-line

  useEffect(() => {
    setClientState({
      ...clientDetailsAPI.client_details.address[0]
    })
  }, [clientDetailsAPI])

  const fetchZipcodeData = useZipcodeAPICallInvoice({ setClientState, setError });

  const handleChange = (e) => {
    if (e.target.name == "zip_code") {
      fetchZipcodeData(e.target.value);
      handleValidate(e);
    }
    setClientState({
      ...clientState,
      [e.target.name]: e.target.value
    }, handleValidate(e))
  }

  const handleValidate = (e) => {
    const input = e.target
    switch (input.name || input.tagName) {
      case 'address_line_one':
        error.address_line_one = address_validation(input.value)
        break
      case 'address_line_two':
        error.address_line_two = validate_empty_address(input.value)
        break
      case 'zip_code':
        error.zip_code = validate_zipcode(input.value, 'us');
        break
      case 'city':
        error.city = validate_city(input.value)
        break
      default:
        break
    }
    setError({ ...error })
  }

  const validateAll = async () => {
    const { address_line_one, zip_code, city, address_line_two } = clientState
    error.address_line_one = address_validation(address_line_one);
    error.address_line_two = validate_empty_address(address_line_two)
    error.zip_code = await checkZipcode(zip_code)
    error.city = validate_city(city)
    setError({ ...error })
    return error;
  }

  const submitForm = async () => {
    let errors = await validateAll();
    if (isValid(errors)) {
      let data = { id: state.client_id, name: clientDetailsAPI.client_details.name, encoded_name: Buffer.from(clientDetailsAPI.client_details.name).toString('base64'), address: [clientState], request_id: LocalStorage.uid() };
      ClientsApi.updateCompany(data, "client").then((response) => {
        if (response.data.statusCode == 1003) {
          addSuccessMsg(response.data.message);
          setEditPopup(false);
          getClientDetails(state.client_id)
        } else {
          addErrorMsg(response.data.message);
        }
      });
    }
  }

  useEffect(() => {
    if (state.client_id != "") {
      InvoiceAPI.getActivity({ client_id: state.client_id }).then((res) => {
        if (res.data.statusCode == 1003) {
          setActivityData(res.data.data)
        }
        else {
          setActivityData([])
        }
      })
    }

  }, [state.client_id])


  const returnPrimary = (item) => {
    if (item) {

      let find = item.find((i) => i?.is_primary == true)
      if (find) {
        return find
      } else {
        return {}
      }
    } else {
      return {}
    }

  }

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 3,
        backgroundColor: "#F9FAFA",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        {/* Left Section */}
        <Grid item md={1} sm={1.5} xs={12} p={1}>
          <Avatar
            src={clientDetailsAPI?.client_details?.logo_url ? clientDetailsAPI?.client_details?.logo_url : clientDetailsAPI?.client_details?.name[0]}
            alt={clientDetailsAPI?.client_details?.name[0]}
            sx={{
              width: 60,
              height: 60,
              border: "1px solid #FFD700",
            }}
          />
        </Grid>
        <Grid item md={8} sm={7.5} xs={12} p={1}>
          <Text headerBlack>
            {clientDetailsAPI?.client_details?.name}
          </Text>
          <Stack direction={'row'}>
            <Text greyLabel sx={{ paddingTop: '10px' }}>
              {clientDetailsAPI?.client_details?.reference_id}{" "}
            </Text>
            <Box
              pt={1.2} pl={1}
              onClick={() => { setOpen(true); setSelectedTab(2) }}
              underline="none"
              sx={{ color: "red", fontSize: '12px !important', fontWeight: 'bold', cursor: 'pointer' }}
            >
              {clientDetailsAPI?.unpaid_invoice_count} Unpaid Invoices
            </Box>
          </Stack>
        </Grid>

        {/* Right Section */}
        <Grid item container justifyContent={'flex-end'} sm={3} xs={12} p={1}>
          <Text
            onClick={() => { setSelectedTab(0); setOpen(true) }}
            sx={{
              color: "#0C75EB",
              fontWeight: "bold",
              fontSize: "0.875rem",
              cursor: 'pointer'
            }}
          >
            View Client Details &gt;
          </Text>
        </Grid>
      </Grid>

      {/* Divider */}
      <Box my={1}>
        <Divider />
      </Box>

      {/* Address Section */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sm={10} xs={12} p={1}>
          <Text black14>
            Client Address
          </Text>
          <Text greyLabel14 sx={{ paddingTop: '10px' }}>
            {clientDetailsAPI?.client_details.address[0].address_line_one} {clientDetailsAPI?.client_details.address[0].address_line_two}
            <br />
            {clientDetailsAPI?.client_details.address[0].city}, {clientDetailsAPI?.client_details.address[0].state_name}, {clientDetailsAPI?.client_details.address[0].country_name} - {clientDetailsAPI?.client_details.address[0].zip_code}
          </Text>
        </Grid>
        <Grid item container justifyContent={'flex-end'} sm={2} xs={12} p={1}>
          <Text
            sx={{
              color: "#0C75EB",
              fontSize: "0.775rem",
              fontWeight: "bold",
              cursor: "pointer"
            }}
            onClick={() => {
              setEditPopup(true); getClientDetails(state.client_id); setError({
                state_name: '',
                city: '',
                country_name: '',
                address_line_one: '',
                address_line_two: '',
                zip_code: '',
              })
            }}
          >
            Edit
          </Text>
        </Grid>
      </Grid>
      <ReusablePopup
        openPopup={editPopup}
        setOpenPopup={setEditPopup}
        onClose={() => { setEditPopup(false); getClientDetails(state.client_id) }}
        iconHide
        white
        fullWidth
      >
        <Grid item container spacing={3}>
          <Grid item lg={12} container pb={2}>
            <Text largeBlack>Edit Client Address</Text>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'address_line_one',
                value: clientState.address_line_one,
                inputProps: { maxLength: 225 },
              }}
              handleChange={handleChange}
              error={error.address_line_one}
              clientInput
              labelText='Address Line 1'
            />
            <Text errorText>{error.address_line_one}</Text>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'address_line_two',
                value: clientState.address_line_two,
                inputProps: { maxLength: 225 },
              }}
              handleChange={handleChange}
              error={error.address_line_two}
              clientInput
              labelText='Address Line 2 (Optional)'
            />
            <Text errorText>{error.address_line_two}</Text>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'zip_code',
                value: clientState.zip_code,
                inputProps: { maxLength: 5 }
              }}
              error={error.zip_code}
              handleChange={handleChange}
              clientInput
              labelText={"Zip Code"}
            />
            <Text errorText>{error.zip_code}</Text>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'city',
                value: clientState.city,
                inputProps: { maxLength: 50 },
              }}
              handleChange={handleChange}
              clientInput
              error={error.city}
              labelText='City'
            />
            <Text errorText>{error.city}</Text>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'state_name',
                value: clientState.state_name || '',
              }}
              disabled={true}
              clientInput
              labelText='State'
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Input
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: 'country_name',
                value: clientState.country_name || '',
              }}
              disabled={true}
              clientInput
              labelText='Country'
            />
          </Grid>
          <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'flex-end'} mt={2}>
            <Stack direction={'row'} gap={2}>
              <Button blueboldCancel onClick={() => { setEditPopup(false); getClientDetails(state.client_id) }}>
                Cancel
              </Button>
              <Button blueBtnSave onClick={submitForm}>
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </ReusablePopup>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={() => { setOpen(false); setState({ ...state, search: '' }) }}
        transitionDuration={300}
        sx={{
          ".MuiDrawer-paper ": {
            borderRadius: '8px 0px 0px 8px !important',
            width: { xs: '90% !important', sm: '65% !important', md: '49% !important' },
          },
          "& .MuiBackdrop-root.MuiModal-backdrop": {
            backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
          }
        }
        }
      >
        <Box sx={{ padding: 4 }}>
          {/* Top Section */}
          <Box
            sx={{
              padding: 3,
              borderRadius: 3,
              backgroundColor: "#F9FAFA",
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={3} md={1.5}>
                <Avatar
                  alt={clientDetailsAPI?.client_details.name[0]}
                  src={clientDetailsAPI.client_details.logo_url ? clientDetailsAPI.client_details.logo_url : clientDetailsAPI?.client_details?.name[0]}
                  sx={{ width: 60, height: 60 }}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={10}>
                <Text blackHeader600 sx={{ paddingBottom: '10px' }}>{clientDetailsAPI?.client_details.name}</Text>
                <Text largeLabel>
                  {clientDetailsAPI?.client_details.reference_id}
                </Text>
              </Grid>
              <Grid item container xs={12}>
                <Box my={1} sx={{ width: '100%' }}>
                  <Divider sx={{ background: '#EAECF0', width: '100%' }} />
                </Box>
              </Grid>
              <Grid item container md={12} sm={12} xs={12}>
                <Grid item md={6} sm={12} xs={12}>
                  <Box display="flex" alignItems="center">
                    <PermIdentityIcon sx={{ marginRight: 1 }} />
                    <Text largeLabel>
                      {returnPrimary(clientDetailsAPI?.client_details?.contacts)?.first_name ? returnPrimary(clientDetailsAPI?.client_details?.contacts)?.first_name : ""} {returnPrimary(clientDetailsAPI?.client_details?.contacts)?.last_name ? returnPrimary(clientDetailsAPI?.client_details?.contacts)?.last_name : ""}
                    </Text>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box display="flex" alignItems="center">
                    <MailOutlineIcon sx={{ marginRight: 1 }} />
                    <Text largeLabel>
                      {returnPrimary(clientDetailsAPI?.client_details?.contacts)?.email_id ? returnPrimary(clientDetailsAPI?.client_details?.contacts)?.email_id :""}
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Tabs */}
          <Box pt={3} sx={{
            width: '100%', overflow: 'scroll'
          }}
          >
            <Tabs sx={{ overflow: 'scroll', borderBottom: 1, borderColor: 'divider', }} variant="scrollable" scrollButtons="auto" value={selectedTab} onChange={(e, newValue) => { setSelectedTab(newValue) }}>
              <Tab label={<Text largeLabel sx={{ color: selectedTab == 0 ? '#0C75EB !important' : '#525252 !important', textTransform: "capitalize !important" }}>Details </Text>} />
              <Tab label={<Text largeLabel sx={{ color: selectedTab == 1 ? '#0C75EB !important' : '#525252 !important', textTransform: "capitalize !important" }}>Employees</Text>} />
              <Tab

                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Text largeLabel sx={{ color: selectedTab == 2 ? '#0C75EB !important' : '#525252 !important', textTransform: "capitalize !important" }}>Unpaid Invoices</Text>
                    <Box
                      component="span"
                      sx={{
                        marginLeft: 1,
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        paddingX: 1,
                        fontSize: 12,
                      }}
                    >
                      {clientDetailsAPI?.unpaid_invoice_count}
                    </Box>
                  </Box>
                }
              />
              <Tab label={<Text largeLabel sx={{ color: selectedTab == 3 ? '#0C75EB !important' : '#525252 !important', textTransform: "capitalize !important" }}>Activity Log</Text>} />
            </Tabs>
          </Box>
          {
            selectedTab == 0 && <ClientDetails clientDetailsAPI={clientDetailsAPI} />
          }
          {
            selectedTab == 1 && <EmployeeCard state={state} setState={setState} employeeDetails={employeeDetails} />
          }
          {
            selectedTab == 2 && <UnpaidInvoicesCard clientDetailsAPI={clientDetailsAPI} />
          }
          {
            selectedTab == 3 && <Grid item container lg={12} md={12} xs={12} pt={3} >
              {
                false ?
                  <Box width={"100%"} height={'76vh'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                  </Box>
                  :
                  <Box height={'76vh'} overflow={'scroll'} sx={{ width: '100% !important' }}>
                    {
                      activityData.length == 0 ?
                        <Grid item lg={12} md={12} xs={12} container justifyContent={'center'} alignContent={'center'} textAlign={'center'}>
                          <Grid item lg={12} md={12} xs={12} pl={5} container justifyContent={'center'} alignContent={'center'}>
                            <img src={Nodata} alt='nodata' />
                          </Grid>
                          <Grid item lg={12} md={12} xs={12} pl={5}>
                            <Text popupHead1>No Logs Found Yet!</Text>
                          </Grid>
                        </Grid>
                        :
                        activityData.length > 0 && activityData.map((item, index) => (
                          <Grid container lg={10} md={10} xs={12}>
                            <Grid item lg={2.75} md={2.75} xs={2.75}>
                              <Grid item>
                                <Box p={1} pt={1.9}>
                                  <Text smallBlue sx={{ fontWeight: '600 !important' }}>{item.created_at}</Text>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box pl={1}>
                                  <Text verySmallBlack sx={{ color: "#737373 !important" }}>{item.action_by}</Text>
                                </Box>
                              </Grid>
                            </Grid >
                            <Grid item lg={1} md={1} xs={1}>
                              <Box p={1}>
                                <img src={timelineIcon} alt='ic' />
                              </Box>
                            </Grid>
                            <Grid item lg={7} md={7} xs={7}>
                              <Grid item>
                                <Box p={1}>
                                  <Text mediumBlack>{item.activity_name}</Text>
                                </Box>
                              </Grid>
                              {/* {
                            expand !== index &&
                            <Grid item>
                                <Box pl={1}>
                                    <Text greysmallLabel>Label Name is updated from from value to To value</Text>
                                </Box>
                            </Grid>
                        } */}

                            </Grid>
                            <Grid item lg={1} md={1} xs={1}>
                              <Box p={1}>
                                {expand === index ?
                                  <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} /> :
                                  <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} />
                                }
                              </Box>
                            </Grid>
                            {
                              expand === index ?
                                <Grid item container pb={2}>
                                  <Grid item lg={3.43} md={3.43} xs={3.43}></Grid>
                                  <Grid item class="tree" >
                                    <ul>
                                      {
                                        item.change_log.map((i) => (
                                          <li>
                                            <Text greysmallLabel>
                                              {i.length > 70 ?

                                                <BlackToolTip arrow placement='top' title={
                                                  <Box p={1}>{i}</Box>
                                                }>
                                                  {i.slice(0, 70)} ...
                                                </BlackToolTip>

                                                : i}
                                            </Text>
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </Grid>
                                </Grid>
                                :

                                ((activityData.length - 1) == index) ? '' :
                                  <Grid item container pb={2}>
                                    <Grid item lg={3.15} md={3.15} xs={3.15}></Grid>
                                    <Grid item mt={-0.5} md={-0.5} xs={-0.5}>
                                      <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                    </Grid>
                                  </Grid>
                            }
                          </Grid>
                        ))}
                  </Box>
              }

            </Grid>
          }
        </Box>
      </SwipeableDrawer>
    </Box >
  );
};

export default ClientCard;
