import React, { useState, useEffect } from 'react';
import UserProfileStyles from '../UserProfileStyles';
import { Box, Stack, Divider, Grid } from '@mui/material';
// import { BlackToolTip } from "../../../../../utils/utils";
import Text from '../../../../../components/customText/Text';
import ReactApexChart from "react-apexcharts";
import ClientsApi from '../../../../../apis/admin/clients/ClientsApi';
import CustomSelect from '../../../../../components/customSelect/CustomSelect';
import moment from 'moment';
// import infoCircle from '../../../../../assets/images/info-circle.png';
import { PieChart } from '@mui/x-charts/PieChart';
import LocalStorage from '../../../../../utils/LocalStorage';
import CommonApi from '../../../../../apis/CommonApi';
import { BlackToolTip } from '../../../../../utils/utils';


export default function Overview({ id }) {

    const classes = UserProfileStyles();
    const [filterYear, setFilterYear] = useState(moment().year());

    const currentYear = moment().year()
    const YearArray = [];
    const filterData = []
    for (let year = currentYear; year <= 2024; year++) {
        YearArray.push(moment().year(year).format('YYYY'))
        for (let i = 0; i < YearArray.length; i++) {
            filterData.push({
                id: YearArray[i],
                value: YearArray[i]
            })
        }
    }

    const [years, setYears] = useState([]);

    const [visaType, setVisaType] = useState({
        employee_by_visa_types: {
            labels: [],
            series: []
        },
        financialData: {
            dates: [],
            labels: [],
            series: []
        },
        total_bill: '',
        total_pay: '',
        total_margin: ''
    })

    const areaOptions = {
        series: visaType.financialData.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#165DFF', '#14C9C9', '#F7BA1E'],
            width: 2,
            curve: 'straight'
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.4,
                opacityTo: 0.1,
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (val) {
                    return "$" + val;
                }
            }
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        }
    }

    const donutoptions = {
        legend: {
            show: false,
        },
        labels: visaType.employee_by_visa_types.labels,
        dataLabels: {
            enabled: false,
        },
        colors: ["#3772C6", "#FDC943", "#7FC9CA", "#7DBAFF", "#FF8C42"],
        states: {
            hover: false,
            active: false,
        },
        tooltip: { enabled: false },
        stroke: {
            width: 5,
        },
        chart: {
            type: 'donut',
            //sparkline: {
            //    enabled: false
            //}
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                roundedCorners: true,
                donut: {
                    size: "75%",
                    background: 'transparent',
                },
            }
        },
        noData: {
            text: "No Data Found",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
            }
        }
    }

    const donutSeries = visaType.employee_by_visa_types.series

    useEffect(() => {
        getOverView(filterYear);
        defaultYears();
        // eslint-disable-next-line
    }, []);

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }

    const getOverView = (args) => {
        ClientsApi.overView(id, args).then((res) => {
            if (res.data.statusCode == 1003) {
                setVisaType(res.data.data);
            }
        })
    }

    const handleSelectYear = (e) => {
        setFilterYear(e.target.value);
        getOverView(e.target.value);
    }

    return (
        <Box className={classes.contentScrollAnalytics} p={'10px'}>
            <Box mx={2} my={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                <Text profileTitle>
                    Employee Division By Visa Type
                </Text>
            </Box>

            <Box m={1}>
                <Grid container textAlign='center' alignItems='center'>
                    <Grid item lg={4} md={4} sm={4} xs={12} px={4}>
                        <PieChart
                            colors={donutoptions.colors} // Use palette
                            width={240}
                            height={240}
                            slotProps={{ legend: { hidden: true } }}
                            series={[
                                {
                                    data: visaType.employee_by_visa_types.series,
                                    innerRadius: 52,
                                    outerRadius: 70,
                                    paddingAngle: 1,
                                    cornerRadius: 6,
                                    startAngle: 0,
                                    endAngle: 360,
                                }]}
                        />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Grid container lg={12} md={12} sm={12} xs={12}>
                            {
                                donutSeries.map((series, key) => (
                                    <Grid item lg={4} md={4} sm={4} xs={6} py={{ lg: 2, md: 2, sm: 2, xs: 1 }} px={'2px'}>
                                        <Stack spacing={1} direction="row" alignItems='center'>
                                            <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                            <Stack spacing={2} direction={'row'} alignItems={'center'} >
                                                <Text mediumBoldGrey>
                                                    {series.label.length > 16 ?
                                                        <BlackToolTip title={series.label} placement="top" arrow>
                                                            {series.label.slice(0, 16) + (series.label.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        : series.label ? series.label : '--'
                                                    }
                                                </Text>
                                                <Text LargeBlack>{series.value}</Text>
                                            </Stack>
                                        </Stack>

                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    {/*<ReactApexChart
                        options={donutoptions}
                        type='donut'
                        series={donutSeries}
                        height={'200px'}
                    />*/}
                </Grid>
            </Box>
            <Box mt={2}>
                <Divider />
            </Box>
            <Box mt={2}>
                <Box px={{ lg: 2, md: 2, sm: 2, xs: 0 }} py={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} alignItems={"center"}>
                    <Text className={classes.BarChartTitle}>Client Margin&nbsp;<span className={classes.forConsultant} >(For consultants)</span></Text>
                    <Box sx={{ float: 'end', alignItems: 'center' }}>
                        <CustomSelect
                            name='filterYear'
                            value={filterYear}
                            onChange={handleSelectYear}
                            viewDrop1
                            options={years}
                        />
                    </Box>
                </Box>
                <Box px={{ lg: 2, md: 2, sm: 2, xs: 0 }} py={1} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Stack spacing={1} direction={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap={{ lg: '12px', md: '12px', xs: '3px', sm: '12px' }}>
                        <Stack spacing={1} direction="row" alignItems='center'>
                            <Box sx={{ height: "12px", width: "12px", backgroundColor: "#165DFF", borderRadius: "50%" }}></Box>
                            <Text infoText>Total Billable Amount <span style={{ fontWeight: '600' }}> {LocalStorage.getCurrencySymbol()}&nbsp;{visaType.total_bill}</span></Text>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems='center'>
                            <Box sx={{ height: "12px", width: "12px", backgroundColor: "#14C9C9", borderRadius: "50%" }}></Box>
                            <Text infoText>Total Payable Amount <span style={{ fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}&nbsp;{visaType.total_pay}</span></Text>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems='center'>
                            <Box sx={{ height: "12px", width: "12px", backgroundColor: "#F7BA1E", borderRadius: "50%" }}></Box>
                            <Text infoText>Margin <span style={{ fontWeight: '600' }}>{LocalStorage.getCurrencySymbol()}&nbsp;{visaType.total_margin}</span></Text>
                        </Stack>
                    </Stack>
                </Box>
                <Box px={{ lg: 2, md: 2, sm: 2, xs: 0 }}>
                    <ReactApexChart
                        options={areaOptions}
                        series={areaOptions.series}
                        type="area"
                        height='300px'
                    />
                </Box>
            </Box>
        </Box>
    );
}