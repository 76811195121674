import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, Menu, MenuItem, Divider, Autocomplete, TextField, Avatar, Skeleton, Grid, useTheme } from '@mui/material';
import NotificationsStyles from './NotificationsStyles';
import Text from '../../../components/customText/Text';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import letfBtnN from "../../../assets/svg/letfBtnN.svg"
import rightBtnN from "../../../assets/svg/rightBtnN.svg";
import dateleftarrow from '../../../assets/svg/dateleftarrow.svg';
import daterightarrow from '../../../assets/svg/daterightarrow.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '../../../components/customButton/Button';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';
import { ListLoadingIcon, NoDataFound, addErrorMsg, dateFormat, } from "../../../utils/utils";
import placementExpImg from '../../../assets/svg/placementNF.svg';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import empIcon from '../../../assets/notifications/notification-emp.svg';
import tsPending from '../../../assets/notifications/notification-ts-pending.svg';
import tsapproved from '../../../assets/notifications/notification-ts-approved.svg';
import tsdrafted from '../../../assets/notifications/notification-ts-drafted.svg';
import orgIcon from '../../../assets/notifications/notification-org.svg';
import clientIcon from '../../../assets/notifications/notification-client.svg';
import tsrejected from '../../../assets/notifications/notification-rejected.svg';
import tsdeleted from '../../../assets/svg/timesheet-deleted.svg';
import LocalStorage from '../../../utils/LocalStorage';
import tsSummary from '../../../assets/notifications/timesheet-summary.svg';
import everify from '../../../assets/notifications/notification-everified.svg';
import zoho from '../../../assets/notifications/notification-zoho.svg';
import invoice from '../../../assets/notifications/notification-invoice.svg';
import zohoError from '../../../assets/svg/zoho_error.svg';
import { domain } from '../../../config/Domain';
import CommonApi from '../../../apis/CommonApi';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import payrollIcon from '../../../assets/notifications/notification-payrollIcon.svg';

// Extend dayjs with the isoWeek plugin to handle weeks correctly
dayjs.extend(isoWeek);

export default function Notifications() {
    const moduleIcons = {
        "timesheet_approved": tsapproved,
        "timesheet_pending": tsPending,
        "timesheet_draft": tsdrafted,
        "timesheet_summary": tsSummary,
        "timesheet_rejected": tsrejected,
        "timesheet-deleted": tsdeleted,
        "timesheet-missing": '',
        "employee": empIcon,
        "organization": orgIcon,
        "company": clientIcon,
        "employee-profile": empIcon,
        "employee > internalemployees": empIcon,
        "employee > allemployees": empIcon,
        "myprofile-billing": empIcon,
        "placement": placementExpImg,
        'placements': placementExpImg,
        "organization > internal_employee": orgIcon,
        "employee > onboaders": empIcon,
        "employee > documents > passport": empIcon,
        "employee > documents > i94": empIcon,
        "employee > documents > driving license": empIcon,
        "employee > documents > state id": empIcon,
        "employee > documents > opt visa": empIcon,
        "employee > documents > cpt visa": empIcon,
        "employee > documents > green card visa": empIcon,
        "employee > documents > stem-opt visa": empIcon,
        "employee > documents > h-1b visa": empIcon,
        "employee > documents > w4 form": empIcon,
        "employee > documents > ssn": empIcon,
        "employee > documents > i-94": empIcon,
        "employee > onboaders > offer rejected": empIcon,
        "employee > verify": empIcon,
        "employee > onboarders > add": empIcon,
        "employee > user-profile": empIcon,
        "myprofile > manage subscription": empIcon,
        "employee > user-profile > e-verify": everify,
        "zoho_config": zoho,
        "zoho_error": zohoError,
        "client": clientIcon,
        "vendor": clientIcon,
        "end-client": clientIcon,
        "employee-password-changed": empIcon,
        "multi_timesheet_draft": tsdrafted,
        "no-redirect": empIcon,
        "payroll-dashborad": payrollIcon,
        "reimbursement": payrollIcon,
        "reimbursement > pending": payrollIcon,
        "payroll-summary": payrollIcon,
        "deduction": payrollIcon,
        "invoice > due": invoice,
        "invoice > draft": invoice,
        "invoice > overdue": invoice,
        "invoice > view": invoice,
    };

    const classes = NotificationsStyles();
    const theme = useTheme()
    const [value, setValue] = useState(dayjs());
    const [loading, setloading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [NotificationData, setNotificationData] = useState([]);
    const [open, setOpen] = useState(false);
    const [views, setViews] = useState('day');
    var startOfMonth = value.startOf('month').format(dateFormat());
    var endOfMonth = value.endOf('month').format(dateFormat());
    const [selectedDates, setSelectedDates] = useState([]);
    const day = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [selectedMonth, setSelectedMonth] = useState(months[dayjs().month()]);
    const currentYear = value.format('YYYY');
    const [selectedYear, setSelectedYear] = useState(value.format('YYYY'));
    const years = Array.from({ length: currentYear - 2021 }, (_, i) => i + 2022).reverse();
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        from_date: moment().startOf('month').format(dateFormat()),
        to_date: moment().endOf('month').format(dateFormat()),
    })
    // eslint-disable-next-line
    const [pagination, setPagination] = useState(
        {
            total: '',
            currentPage: 1,
            perPage: 10,
            totalPages: ''
        }
    );
    const [Notificationlength, setNotificationlength] = useState([]);
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [listLoading, setListLoading] = useState(false);
    const [selectValue, setSelectValue] = useState('');
    let selectedValue = selectValue === "Unread" ? false : selectValue === "Read" ? true : '';
    useEffect(() => {
        getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
        // eslint-disable-next-line  
    }, [])

    const getNotificationList = (fromdate, todate, limit, page, selectedValue, initialLoad = false) => {

        initialLoad ? setloading(true) : setListLoading(true);
        DashboardAPI.notificationBellList(fromdate, todate, limit, page, selectedValue ?? '', true).then((res) => {
            if (res.data.statusCode == 1003) {
                setloading(false);
                // removeLoader()
                setListLoading(false);
                setNotificationData(res.data.data[0]);
                setNotificationlength(Object.keys(res.data.data[0] || []));
                setPagination(res.data.pagination);
            }
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);

    };
    // eslint-disable-next-line
    const loadMore = () => {
        filterData.limit += 10
        setFilterData({ ...filterData, page: 1 });
        getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '')
    }

    const handleView = (viewValue) => {
        var dropView = viewValue;
        if (dropView !== views) {
            setViews(dropView);
        }
        setSelectedDates([]);
        if (dropView === 'day' || dropView === 'week') {
            if (!appliedFilter) {
                setSelectedMonth(months[dayjs().month()]);
            } else {
                setSelectedMonth(appliedFilter.selectedMonth);
            }
        }
    }

    const handleClose = () => {
        // setOpen(false);
        // setAnchorEl(null);
    }

    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
    }
    const handleYearChangeAdd = (change) => {
        const newYear = Number(selectedYear) + change;
        const currentYear = moment().year();

        if (newYear <= currentYear) {
            setSelectedYear(newYear);
        }
        // setSelectedYear(Number(selectedYear) + change);
    }
    const handleYearChangesub = (change) => {
        setSelectedYear(Number(selectedYear) - change);
    }

    const addFilter = () => {

        if (selectedDates.length === 0) {
            let newValue = dayjs().set('month', months.indexOf(selectedMonth)).set('year', Number(selectedYear));
            startOfMonth = newValue.startOf('month').format(dateFormat());
            endOfMonth = newValue.endOf('month').format(dateFormat());
            setValue(newValue);
            filterData.from_date = startOfMonth
            filterData.to_date = endOfMonth
            setFilterData({ ...filterData });
            getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
        } else if (selectedDates.length > 1) {
            let minDate = dayjs(Math.min(...selectedDates.map(date => dayjs(date))));
            let maxDate = dayjs(Math.max(...selectedDates.map(date => dayjs(date))));
            let newValue = dayjs().set('month', months.indexOf(selectedMonth)).set('year', Number(selectedYear));
            startOfMonth = minDate.format(dateFormat());
            endOfMonth = maxDate.format(dateFormat());
            setValue(newValue);
            filterData.from_date = startOfMonth
            filterData.to_date = endOfMonth
            setFilterData({ ...filterData });
            getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
        } else {
            let singleDate = dayjs(selectedDates[0]);
            let newValue = singleDate.set('month', months.indexOf(selectedMonth)).set('year', Number(selectedYear));
            startOfMonth = newValue.format(dateFormat());
            endOfMonth = newValue.format(dateFormat());
            setValue(newValue);
            filterData.from_date = startOfMonth
            filterData.to_date = endOfMonth
            setFilterData({ ...filterData });
            getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
        }
        setAppliedFilter({
            selectedDates,
            selectedMonth,
            selectedYear
        });
        setOpen(false);
        setAnchorEl(null);
    }

    const removeFilter = () => {
        // if (!appliedFilter) {
        //     // Clear the filter if no filter has been applied
        //     setSelectedDates([]);
        //     setSelectedMonth(months[dayjs().month()]);
        //     setSelectedYear(value.format('YYYY'));
        //     setViews('day')
        // } else {
        //     // Restore the applied filter
        //     setSelectedDates(appliedFilter?.selectedDates);
        //     setSelectedMonth(appliedFilter?.selectedMonth);
        //     setSelectedYear(appliedFilter?.selectedYear);
        //     if(appliedFilter?.selectedDates?.length>1){
        //         setViews('week')
        //     }else if(appliedFilter?.selectedDates?.length==0){
        //         setViews('year')
        //     }else{
        //         setViews('day')
        //     }
        // }
        setOpen(false);
        setAnchorEl(null);
    }


    // Generate the days for the calendar view
    let days = [];
    let values_start = dayjs().set('month', months.indexOf(selectedMonth)).set('year', selectedYear);
    let day_of = values_start.startOf('month').startOf('isoWeek');
    while (day_of.isBefore(values_start.endOf('month').endOf('isoWeek'))) {
        days.push(day_of);
        day_of = day_of.add(1, 'day');
    }

    const subMonth = () => {
        var date = value.subtract(1, 'month');
        setValue(date);
        startOfMonth = date.startOf('month').format(dateFormat());
        endOfMonth = date.endOf('month').format(dateFormat());
        setSelectedYear(date.format('YYYY'));
        filterData.from_date = startOfMonth;
        filterData.to_date = endOfMonth;
        filterData.limit = 10
        setFilterData({ ...filterData })
        setAppliedFilter({ selectedMonth: months[date.month()] });
        setSelectedMonth(months[date.month()])
        getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
    }

    function toggleSelection(day, isSelected) {
        const formattedDay = day.format('YYYY-MM-DD');
        let newDates = [];
        const startOfMonth = day.startOf('month');
        newDates = [formattedDay];
        for (let i = 1; i <= 6; i++) {
            const prevDay = day.subtract(i, 'day');
            if (prevDay.isSame(startOfMonth, 'month')) {
                newDates.push(prevDay.format('YYYY-MM-DD'));
            } else {
                break;
            }
        }
        newDates.sort((a, b) => new Date(a) - new Date(b));
        setSelectedDates(newDates);
    }

    const upgradePlan = () => {
        let dataSubmit = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        if (LocalStorage.getUserData().plan_name == 'Free Plan' || LocalStorage.getUserData().plan_name == 'free') {
            LocalStorage.setRenew(true);
            window.open('/billing', '_blank');
        }
        else {
            CommonApi.renewInitiation(dataSubmit).then(
                (res) => {
                    if (res.data.statusCode == 1003) {
                        window.open(res.data.data.url, '_blank');
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                }
            )
        }
    }

    const addMonth = () => {
        const currentDate = moment();
        var date = value.add(1, 'month');
        if (date.isAfter(currentDate, 'month')) {
            return; // Exit the function without updating
        }
        setValue(date);
        startOfMonth = date.startOf('month').format(dateFormat());
        endOfMonth = date.endOf('month').format(dateFormat());
        setSelectedYear(date.format('YYYY'));
        filterData.from_date = startOfMonth;
        filterData.to_date = endOfMonth;
        filterData.limit = 10
        setFilterData({ ...filterData })
        setAppliedFilter({ selectedMonth: months[date.month()] });
        setSelectedMonth(months[date.month()])
        getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '', true)
    }

    const clickHandler = (args) => {
        if (args.is_read == false) {
            updateNotification(args.id)
        }
        if (args.redirection_info.module == 'timesheet-deleted') {
            LocalStorage.setRedirectedModule('timesheet-deleted');
            window.open('/timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet-missing') {
            LocalStorage.setRedirectedModule('timesheet-missing');
            window.open('/timesheet', '_blank');
        } else if (args.redirection_info.module == 'placement' || args.redirection_info.module == 'placements') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            LocalStorage.setRedirectedModule('placement');
            window.open('/placements/view-placement', '_blank');
        } else if (args.redirection_info.module == 'client') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('client');
            window.open('/clients/clients-user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'vendor') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('vendor');
            window.open('/vendor/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'end-client') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('end-client');
            window.open('/clients/end-clients-user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > onboaders') {
            LocalStorage.setRedirectedModule('employee');
            LocalStorage.setFromPage('notification')
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee' || args.redirection_info.module == 'employee-profile') {
            LocalStorage.setRedirectedModule('employee');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'organization > internal_employee') {
            LocalStorage.setRedirectedModule('organization');
            LocalStorage.setFromPage('notification');
            window.open('/employees', '_blank');
        }
        //  else if (args.redirection_info.module == 'employee-profile') {
        //     window.open('/myprofile', '_blank')
        // } 
        else if (args.redirection_info.module == 'employee > documents > i94') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('i94');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > documents > passport') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('i94');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'employee > documents > driving license' || args.redirection_info.module == 'employee > documents > ssn' || args.redirection_info.module == 'employee > documents > state id' || args.redirection_info.module == 'employee > documents > opt visa' || args.redirection_info.module == 'employee > documents > cpt visa' || args.redirection_info.module == 'employee > documents > green card visa' || args.redirection_info.module == 'employee > documents > stem-opt visa' || args.redirection_info.module == 'employee > documents > h-1b visa' || args.redirection_info.module == 'employee > documents > i-94') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('visa');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank')
        } else if (args.redirection_info.module == 'employee > documents > w4 form') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setSubModule('bank');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        } else if (args.redirection_info.module == 'timesheet_approved') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_approved');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_pending') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_pending');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_draft') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_draft');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_rejected') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_rejected');
            window.open('/timesheet/edit-timesheet', '_blank');
        } else if (args.redirection_info.module == 'timesheet_summary') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('timesheet_summary');
            window.open('/timesheet/summary', '_blank');
        } else if (args.redirection_info.module == 'myprofile-billing') {
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setRedirectedModule('myprofile-billing');
            upgradePlan();
        } else if (args.redirection_info.module == 'employee > internalemployees') {
            LocalStorage.setRedirectedModule('employee > internalemployees');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > allemployees') {
            LocalStorage.setRedirectedModule('employee > allemployees');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > onboaders > offer rejected') {
            LocalStorage.setRedirectedModule('employee > onboaders > offer rejected');
            window.open('/employees', '_blank');
        } else if (args.redirection_info.module == 'employee > verify') {
            LocalStorage.setRedirectedModule('employee > verify');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/employee/verify', '_blank');
        } else if (args.redirection_info.module == 'employee > onboarders > add') {
            LocalStorage.setRedirectedModule('employee > onboarders > add');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/employees/onboard', '_blank');
        }
        else if (args.redirection_info.module == 'employee > user-profile') {
            LocalStorage.setRedirectedModule('employee > user-profile');
            LocalStorage.setNotificationEmpId(args.redirection_info.employee_id);
            window.open('/employees/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'employee > user-profile > e-verify') {
            LocalStorage.setRedirectedModule('employee_userprofile');
            LocalStorage.setNotificationEmpId(args.redirection_info.id);
            window.open('/employees/user-profile', '_blank');
        }
        else if (args.redirection_info.module == 'myprofile > manage subscription') {
            LocalStorage.setRedirectedModule('myprofile > manage subscription');
            window.open('/myprofile', '_blank');
        } else if (args.redirection_info.module == 'zoho_config') {
            LocalStorage.setRedirectedModule('zoho_config');
            window.open('/app-integrations', '_blank');
        } else if (args.redirection_info.module == 'employee-password-changed') {
            LocalStorage.setRedirectedModule('employee-password-changed');
            setAnchorEl(null);
        } else if (args.redirection_info.module == 'multi_timesheet_draft') {
            LocalStorage.setRedirectedModule('multi_timesheet_draft');
            window.open('/timesheet', '_blank');
            setAnchorEl(null);
        } else if (args.redirection_info.module == 'no-redirect') {
            LocalStorage.removeRedirectedModule();
            setAnchorEl(null);
        } else if (args.redirection_info.module == 'payroll-dashborad') {
            LocalStorage.setRedirectedModule('payroll-dashborad');
            if (args.redirection_info.id !== 'undefined' && args.redirection_info.id !== 'null' && args.redirection_info.id !== '') {
                LocalStorage.setNotificationId(args.redirection_info.id);
            }
            setAnchorEl(null);
            window.open('/payrollSummary', '_blank');
        } else if (args.redirection_info.module == 'reimbursement') {
            LocalStorage.setRedirectedModule('reimbursement');
            if (args.redirection_info.id !== 'undefined' && args.redirection_info.id !== 'null' && args.redirection_info.id !== '') {
                LocalStorage.setNotificationId(args.redirection_info.id);
            }
            setAnchorEl(null);
            window.open('/expenseDashboard', '_blank');
        } else if (args.redirection_info.module == 'reimbursement > pending') {
            LocalStorage.setRedirectedModule('reimbursement > pending');
            if (args.redirection_info.id !== 'undefined' && args.redirection_info.id !== 'null' && args.redirection_info.id !== '') {
                LocalStorage.setNotificationId(args.redirection_info.id);
            }
            setAnchorEl(null);
            window.open('/expenseDashboard', '_blank');
        } else if (args.redirection_info.module == 'payroll-summary') {
            LocalStorage.setRedirectedModule('payroll-summary');
            LocalStorage.setNotificationId(args.redirection_info.id);
            setAnchorEl(null);
            window.open('/payrollSummary', '_blank');
        } else if (args.redirection_info.module == 'deduction') {
            LocalStorage.setRedirectedModule('deduction');
            window.open('/expenseDashboard', '_blank');
        } else if (args.redirection_info.module == 'invoice > view') {
            LocalStorage.setRedirectedModule('invoice > view');
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setClientID(args.redirection_info.client_id)
            window.open('/invoiceIndex', '_blank');
        } else if (args.redirection_info.module == 'invoice > due') {
            LocalStorage.setRedirectedModule('invoice > due');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/invoice', '_blank');
        } else if (args.redirection_info.module == 'invoice > overdue') {
            LocalStorage.setRedirectedModule('invoice > overdue');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/invoice', '_blank');
        } else if (args.redirection_info.module == 'invoice > draft') {
            LocalStorage.setRedirectedModule('invoice > draft');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/invoice', '_blank');
        } else if (args.redirection_info.module == 'invoice') {
            LocalStorage.setRedirectedModule('invoice');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/invoice', '_blank');
        } else if (args.redirection_info.module == 'invoice > invoice_details') {
            LocalStorage.setRedirectedModule('invoice > invoice_details');
            LocalStorage.setNotificationId(args.redirection_info.id);
            LocalStorage.setClientID(args.redirection_info.client_id)
            window.open('/invoiceIndex', '_blank');
        } else if (args.redirection_info.module == 'invoice > pending') {
            LocalStorage.setRedirectedModule('invoice > pending');
            LocalStorage.setNotificationId(args.redirection_info.id);
            window.open('/invoice', '_blank');
        }
        else {
            window.location.reload()
        }
    }

    const updateNotification = (args) => {
        const data = {
            request_id: LocalStorage.uid()
        }
        DashboardAPI.notificationUpdate(args, data).then((res) => {
            if (res.data.statusCode == 1003) {
                getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, selectedValue ?? '');
            }
        })
    }

    const NotificationIcon = ({ data }) => {
        if (data.redirection_info !== '' && data.redirection_info?.module == 'zoho_config') {
            return <Avatar src={data.redirection_info.status !== 'Fail' ? moduleIcons[data.redirection_info.module] : moduleIcons['zoho_error']} alt='icons' className={classes.avatarSize} />
        }
        return <Avatar src={data.redirection_info !== '' ? moduleIcons[data.redirection_info.module] : moduleIcons[data.notification_slug]} alt='icons' className={classes.avatarSize} />
    }
    const scrollBox = useRef(null);
    const handleScroll = () => {
        const { current } = scrollBox;
        const { scrollTop, scrollHeight, clientHeight } = current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            if (filterData.limit < pagination.total) {
                loadMore()
            }
        }
    }


    const handleChange = (event, newValue) => {
        setSelectValue(newValue);
        getNotificationList(filterData.from_date, filterData.to_date, filterData.limit, filterData.page, newValue === "Unread" ? false : newValue === "Read" ? true : '', true)
        // eslint-disable-next-line  
    };

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center' pl={{ lg: 16, md: 10, sm: 0, xs: 0 }} p={{ sm: 2, xs: 1 }}>
            <Grid item container lg={10} md={12} sm={12} xs={12}>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                    <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                        {/* <LeftArrow onClick={() => navigate('/mainDashboard')} className={classes.cursor} /> */}
                        <Text violet600 sx={{
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '18px !important'
                            },
                        }}>Notifications</Text>
                        <Text className={classes.header10} sx={{
                            fontSize: '16px !important', marginTop: '2px',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '12px !important'
                            },
                        }}>
                            ({filterData?.from_date && filterData?.to_date && filterData?.from_date === filterData?.to_date
                                ? filterData?.from_date
                                : `${filterData?.from_date || ''}${filterData?.to_date ? ` - ${filterData?.to_date}` : ''}`})

                        </Text>
                    </Box>
                </Grid>
                <Grid item container lg={3} md={3} sm={3} xs={12} justifyContent='end' pt={{ xs: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box className={classes.calendarBox}>
                            <img onClick={() => { subMonth() }} src={letfBtnN} className={classes.cursor} alt="left navigate" />
                            <Text onClick={handleClick} className={classes.header10}>{value.format('MMMM YYYY')}</Text>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                keepMounted
                                onClose={handleClose}
                                style={{ left: "-52px", top: '20px' }}

                            >

                                <MenuItem
                                    disableRipple={true}
                                    sx={{ backgroundColor: 'transparent !important' }}>

                                    <Box height={'auto'} width={'300px'} padding={'10px'} sx={{ background: '#FFFFFF' }}>
                                        <Box display={'flex'} height={'10px'} mr={'20px'} ml={'20px'} mt={'10px'} justifyContent={'space-between'}>

                                            <Text onClick={() => handleView('day')} sx={{
                                                fontSize: '14px !important',
                                                cursor: 'pointer',
                                                fontWeight: views === 'day' ? '600 !important' : '500 !important',
                                                color: views === 'day' ? '#0C75EB  !important' : '#8F95B2 !important',
                                                borderBottom: views === 'day' ? '1.5px solid currentColor' : 'none',
                                                paddingBottom: views === 'day' ? '16px' : '0',
                                            }} smallBlack>
                                                Day
                                            </Text>
                                            <Text onClick={() => handleView('week')} sx={{
                                                fontSize: '14px !important',
                                                cursor: 'pointer',
                                                fontWeight: views === 'week' ? '600 !important' : '500 !important',
                                                color: views === 'week' ? '#0C75EB  !important' : '#8F95B2 !important',
                                                borderBottom: views === 'week' ? '1.5px solid currentColor' : 'none',
                                                paddingBottom: views === 'week' ? '16px' : '0',
                                            }} smallBlack>
                                                Week
                                            </Text>
                                            <Text onClick={() => handleView('year')} sx={{
                                                fontSize: '14px !important',
                                                cursor: 'pointer',
                                                fontWeight: views === 'year' ? '600 !important' : '500 !important',
                                                color: views === 'year' ? '#0C75EB  !important' : '#8F95B2 !important',
                                                borderBottom: views === 'year' ? '1.5px solid currentColor' : 'none',
                                                paddingBottom: views === 'year' ? '16px' : '0',
                                            }} smallBlack>
                                                Month
                                            </Text>
                                        </Box>
                                        <Divider
                                            sx={{ mr: '20px', ml: '20px', mt: '15px' }}
                                        ></Divider>
                                        <Box sx={{
                                            mt: '10px',
                                            padding: '5px',
                                            display: 'grid',
                                            gridTemplateColumns: views === 'year' ? '' : 'repeat(7, 1fr)'
                                        }}>

                                            {((views === 'day') || (views === 'week')) && day.map((weekday, index) => (
                                                <Box key={index} sx={{
                                                    textAlign: 'center',
                                                    color: "#8F95B2",
                                                }}>
                                                    <Text sx={{ padding: '5px', fontWeight: `${950} !important`, font: '10px Quicksand !important' }}>{weekday}</Text>
                                                </Box>
                                            ))}

                                            {(views === 'day') && days.map((day, index) => {
                                                let value_day = dayjs().set('month', months.indexOf(selectedMonth)).set('year', selectedYear);
                                                var isInCurrentMonth = day.isBefore(value_day, 'day') || day.isSame(value_day, 'day') || day.isBefore(dayjs().startOf('year'), 'day');
                                                var isSelected = selectedDates.includes(day.format('YYYY-MM-DD')); // Check if the day is selected

                                                return (
                                                    <Box key={index} sx={{ textAlign: 'center' }}>
                                                        <Text
                                                            onClick={() => {
                                                                if (isInCurrentMonth) {
                                                                    if (isSelected) {
                                                                        setSelectedDates(selectedDates.filter(date => date !== day.format('YYYY-MM-DD')));
                                                                    } else {
                                                                        setSelectedDates([day.format('YYYY-MM-DD')]);
                                                                    }
                                                                }
                                                            }}
                                                            sx={{
                                                                padding: '5px',
                                                                borderRadius: '5px',
                                                                m: '2px',
                                                                color: isInCurrentMonth ? (isSelected ? '#FFFFFF' : '#051B44') : '#8F95B2',
                                                                backgroundColor: isSelected ? '#0066FF' : 'transparent',
                                                                fontWeight: `${500} !important`,
                                                                font: '14px Quicksand !important'
                                                            }}
                                                        >
                                                            {day.date()}
                                                        </Text>
                                                    </Box>
                                                );
                                            })}

                                            {(views === 'week') && days.map((day, index) => {
                                                let value_day = dayjs().set('month', months.indexOf(selectedMonth)).set('year', selectedYear);
                                                var isInCurrentMonth = day.isBefore(value_day, 'day') || day.isSame(value_day, 'day') || day.isBefore(dayjs().startOf('year'), 'day');
                                                var isSelected = selectedDates.includes(day.format('YYYY-MM-DD')); // Check if the day is selected

                                                return (
                                                    <Box key={index} sx={{ textAlign: 'center' }}>
                                                        <Text
                                                            onClick={() => {
                                                                if (isInCurrentMonth) {
                                                                    toggleSelection(day, isSelected);
                                                                }
                                                            }}
                                                            sx={{
                                                                m: '2px',
                                                                padding: '5px',
                                                                color: isInCurrentMonth ? (isSelected ? '#FFFFFF' : '#051B44') : '#8F95B2', // Change the color based on the month
                                                                backgroundColor: isSelected ? '#0066FF' : 'transparent', // Change the background color based on the selection
                                                                fontWeight: `${500} !important`,
                                                                font: '14px Quicksand !important'
                                                            }}
                                                        >
                                                            {day.date()}
                                                        </Text>
                                                    </Box>
                                                );
                                            })}

                                            {(views === 'year') &&
                                                <>
                                                    <Box sx={{
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        paddingLeft: '15px',
                                                        paddingRight: '15px',
                                                        alignItems: 'center', // Align items vertically
                                                        width: '100%',
                                                    }}>
                                                        <img src={dateleftarrow} alt="dateleftarrow" onClick={() => handleYearChangesub(1)} />
                                                        <Text sx={{
                                                            fontWeight: "600 !important",
                                                            color: "#051B44", font: '14px Quicksand !important'
                                                        }}>{selectedMonth}</Text>
                                                        <Box sx={{ width: '35%', mt: '4px' }}> {/* Adjust width as needed */}
                                                            <Autocomplete
                                                                classes={{
                                                                    input: classes.inputYear
                                                                }}
                                                                disableClearable={true}
                                                                options={years}
                                                                value={selectedYear}
                                                                onChange={(event, newValue) => {
                                                                    setSelectedYear(newValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField fullWidth {...params} variant="standard" InputProps={{ ...params.InputProps, disableUnderline: true }} />
                                                                )}
                                                                popupIcon={<KeyboardArrowDownIcon sx={{ color: "#051B44 !important" }} />}
                                                            />
                                                        </Box>
                                                        <img src={daterightarrow} style={{ cursor: selectedYear == moment().year() && months.indexOf(selectedMonth) == moment().month() ? "no-drop" : "pointer" }} alt="daterightarrow" onClick={() => handleYearChangeAdd(1)} />
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(4, 1fr)',
                                                        mt: '10px'
                                                    }}>
                                                        {months.map((month, index) => {
                                                            const currentDate = moment();
                                                            const monthDate = moment().month(index);
                                                            const isCurrentYear = selectedYear == currentDate.year();
                                                            const isFutureYear = selectedYear > currentDate.year();
                                                            const isDisabled = isFutureYear || (isCurrentYear && monthDate.isAfter(currentDate, 'month'));
                                                            return (
                                                                <Box key={index} sx={{
                                                                    textAlign: 'center',
                                                                    color: selectedMonth === month ? '#0C75EB' : '#051B44',
                                                                    opacity: isDisabled ? 0.5 : 1
                                                                }} onClick={() => !isDisabled && handleMonthSelect(month)}>
                                                                    <Text sx={{ padding: '5px', my: '10.6px', fontWeight: '500 !important', font: '14px Quicksand !important', cursor: isDisabled ? "no-drop" : "pointer" }}>{month}</Text>
                                                                </Box>
                                                            );
                                                        })}
                                                        {/* {months.map((month, index) => {
                                                            const currentDate = moment();
                                                            const monthDate = moment().month(index);
                                                            const isCurrentYear = selectedYear == currentDate.year();
                                                            const isFutureYear = selectedYear > currentDate.year();
                                                            const isDisabled = isFutureYear || (isCurrentYear && monthDate.isAfter(currentDate, 'month'));
                                                            return (
                                                                <Box key={index} sx={{
                                                                    textAlign: 'center',
                                                                    color: selectedMonth === month ? '#0C75EB' : '#051B44',
                                                                    opacity: isDisabled ? 0.5 : 1,
                                                                }} onClick={() => !isDisabled && handleMonthSelect(month)}>
                                                                    <Text sx={{ padding: '5px', my: '10.6px', fontWeight: '500 !important', font: '14px Quicksand !important', cursor: isDisabled ? "no-drop" : "pointer" }}>{month}</Text>
                                                                </Box>
                                                            );
                                                        })} */}
                                                    </Box>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </MenuItem>
                                <Box sx={{ display: "flex", justifyContent: 'space-between', padding: '25px' }} >
                                    <Button sx={{
                                        border: "1px solid #D8DAE5 !important", borderRadius: "4px !important",
                                        '&:hover': {
                                            background: `none !important`,
                                            color: '#696F8C !important',
                                        },
                                        color: "#696F8C !important", fontSize: '15px !important', fontWeight: '600 !important', width: "108px !important", height: "40px !important",
                                    }} cancelSmall onClick={() => { removeFilter() }}>Cancel</Button>
                                    <Button sx={{
                                        background: '#0066FF !important',

                                        border: "none !important", borderRadius: "4px !important",
                                        color: "#FFFFFF !important", fontSize: '15px !important', fontWeight: '600 !important', width: "99px !important", height: "40px !important"
                                    }} saveSmall onClick={() => { addFilter() }}>Apply</Button>
                                </Box>
                            </Menu>
                            <img onClick={() => {
                                if (!value.isSame(moment(), 'day')) {
                                    addMonth();
                                }
                            }} src={rightBtnN} style={{ cursor: value.isSame(moment(), 'day') ? "no-drop" : "pointer" }} alt="rightArrow" />
                        </Box>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <TabContext value={selectValue} sx={{ padding: '0px !important' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '83%', padding: '0px !important' }}>
                    <TabList onChange={handleChange} >
                        <Tab label="All" value="" sx={{
                            color: value === "All" ? '#0C75EB !important' : '#000000 !important',
                            font: "13px Quicksand !important",
                            textTransform: 'capitalize',
                            fontWeight: `${600} !important`,

                        }} />
                        <Tab label="Unread" value="Unread" sx={{
                            color: value === "Unread" ? '#0C75EB !important' : '#000000 !important',
                            font: "12px Quicksand !important",
                            textTransform: 'capitalize',
                            fontWeight: `${600} !important`,

                        }} />
                        <Tab label="Read" value="Read" sx={{
                            color: value === "Read" ? '#0C75EB !important' : '#000000 !important',
                            font: "12px Quicksand !important",
                            textTransform: 'capitalize',
                            fontWeight: `${600} !important`,

                        }} />
                    </TabList>
                </Box>
                <TabPanel value={selectValue} sx={{
                    [theme.breakpoints.down('sm')]: {
                        padding: "0px"
                    },
                }}>
                    <Grid item container lg={10} xs={12} sx={{ padding: '0px !important' }}  >

                        <Box className={classes.listContianer} sx={{ padding: '0px !important' }}>
                            {
                                loading ? [1, 2, 3, 4, 5, 6, 7].map((index) => (
                                    <Stack className={classes.notificationCard} key={index}>
                                        <Stack className={classes.leftContent}>
                                            <Skeleton variant='circular' height={'53px'} width={'53px'} />
                                            <Stack gap={1}>
                                                <Skeleton variant='text' width={150} height={16} />
                                                <Skeleton variant='text' width={100} height={14} />
                                            </Stack>
                                        </Stack>
                                        <Stack gap={1} alignItems={'center'}>
                                            <Skeleton variant='text' width={50} height={16} />
                                            <Skeleton variant='text' width={70} height={14} />
                                        </Stack>
                                    </Stack>
                                )) :
                                    (NotificationData == undefined) ?
                                        <Box className={classes.NoDataFoundIcon}>
                                            {NoDataFound()}
                                        </Box>
                                        :
                                        !loading ?
                                            <Box sx={{
                                                height: '75vh', overflowY: 'scroll', width: '60vw', padding: '0px !important',
                                                [theme.breakpoints.down('lg')]: {
                                                    width: '70vw',
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    width: '80vw',
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    width: '100%',
                                                },
                                            }} ref={scrollBox} onScroll={handleScroll}>
                                                {(Notificationlength != undefined) && Notificationlength.map((obj1, key1) => (<>
                                                    {/* <Grid container justifyContent='center' alignItems='center'>
                                                        <Box className={classes.dayView}>
                                                            <Text smallBlack400>{obj1}</Text>
                                                        </Box>
                                                    </Grid> */}
                                                    {(NotificationData != undefined) && (NotificationData[obj1] != undefined) && NotificationData[obj1].map((obj, key) => (
                                                        <>
                                                            <Grid container lg={12} md={12} sm={12} xs={12} p={'8px 0px'} sx={{
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: "#F8F8F8",
                                                                    transition: "all .3s ease",
                                                                    boxShadow: '0px 0px 15px 1px rgba(12, 117, 235, 0.30) !important'
                                                                }
                                                            }}>
                                                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} alignItems='center' onClick={() => { clickHandler(obj) }}>
                                                                    <Grid item lg={1} md={1} sm={1.5} xs={2}>
                                                                        <NotificationIcon data={obj} />
                                                                    </Grid>
                                                                    <Grid item lg={8.5} md={8} sm={7.5} xs={8} textAlign='start'>
                                                                        {
                                                                            obj.is_read ? <Text notificationread>{obj.title ? obj.title : '-'}</Text> :
                                                                                <Text mediumBoldBlack2>{obj.title ? obj.title : '-'}</Text>
                                                                        }
                                                                        {
                                                                            obj.is_read ? <Text notificationreadSmall>{obj.content}</Text> : <Text greyLabel>{obj.content}</Text>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item lg={2.5} md={3} sm={3} xs={12} textAlign={{ xs: "end" }}>
                                                                        <Text smallGrey10>{obj.time}</Text>
                                                                        <Text greyLabel>{obj.date}</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider className={classes.notificationdivider} />
                                                        </>
                                                    ))
                                                    }
                                                </>))}
                                            </Box>
                                            : ''
                            }
                            {listLoading && (<ListLoadingIcon />)}
                        </Box>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Grid >
    )
}
