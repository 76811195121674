import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import Text from '../../../../components/customText/Text'
import { cancelRed } from '../../../../theme';
import { formatNumberWithCommas, getCurrencySymbol } from '../../../../utils/utils';

function CurrentBalancePopup(props) {
  const { balanceSheet, balanceSheetProp } = props;
  const [loading, setLoading] = useState(false);
  const currentBalance = balanceSheetProp[0]?.current_balance_data;
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [])
  console.log('currentBalance',  balanceSheetProp[0]?.current_balance_data)


  return (
    <div>
      {!balanceSheet && <Grid container>
        <Grid item md={12} xs={12} p={3}>
          <Text boldblack22>Current Balance Breakdown</Text>
        </Grid>
      </Grid>}
      {!balanceSheet && <Divider />}
      {balanceSheetProp[0]?.payment_mode == 1 ?
        <Box p={balanceSheet ? 2 : 3}>
          <Grid container spacing={3} pb={2}>
            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Opening Balance</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{getCurrencySymbol()} {formatNumberWithCommas(currentBalance?.opening_balance)}</Text>} </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={3} py={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14> Wages</Text></Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Earned Amount</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

              //  <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>
              //    {currentBalance?.earned_amount ? `+ $ ${formatNumberWithCommas(currentBalance?.earned_amount)}` : '-'}
              //    </Text>

              <Text blackHeader600
                sx={{
                  color:`#15803D !important`
                }}
                noWrap>
                {currentBalance?.earned_amount ?
                  ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalance?.earned_amount)}` : '-'}
              </Text>


            } </Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Pay Amount</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :


              //  <Text blackHeader600 sx={{ color: `{getCurrencySymbol()}{cancelRed} !important` }} noWrap>
              //   {currentBalance?.salary_amount ? `- $ ${formatNumberWithCommas(currentBalance?.salary_amount)}` : '-'}</Text>

              <Text blackHeader600
                sx={{
                  color:  `${cancelRed} !important`
                }}
                noWrap>
                {currentBalance?.salary_amount ?
                  ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalance?.salary_amount)}` : '-'}
              </Text>


            }</Grid>

            {/* <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Deductions</Text></Grid>

            {currentBalance?.deductions && currentBalance?.deductions?.map((eachItem) => <>
              <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>{eachItem?.name}</Text></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : 
              // <Text blackHeader600 sx={{ color: `${cancelRed} !important` }} noWrap>{eachItem?.raised_amount ? `- $ ${formatNumberWithCommas(eachItem?.raised_amount)}` : '-'}</Text>
              <Text black18px
                  sx={{
                      color: `${cancelRed} !important`
                  }}
                  noWrap>
                  {eachItem?.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(eachItem?.raised_amount)}` : '-'}</Text>
              
              } </Grid>

            </>)}



            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Reimbursements</Text></Grid>

            {currentBalance?.reimbursements.map((eachItem) => <> <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>{eachItem.name}</Text></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : 

              // <Text blackHeader600 sx={{ color: `#15803D !important` }} noWrap>{eachItem?.raised_amount ? `+ $ ${formatNumberWithCommas(eachItem?.raised_amount)}` : '-'}</Text>

              <Text black18px
                  sx={{
                    color: eachItem?.raised_amount == 0 ? ''
                    : eachItem?.raised_amount > 0 ? `#15803D !important` : `${cancelRed} !important` 
                  }}
                  noWrap>
                  {eachItem?.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(eachItem?.raised_amount)}` : '-'}</Text>

              } </Grid>
            </>
            )} */}


          </Grid>
          <Divider />
          <Grid container spacing={3} py={2}>
            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Balance Impact</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>
              {loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                <Text blackHeader600
                  sx={{
                    color: currentBalance?.balance_impact == 0 ? ''
                      : currentBalance?.balance_impact > 0 ? `#15803D !important` : `${cancelRed} !important`
                  }}
                  noWrap>
                  {currentBalance?.balance_impact ? ` $ ${formatNumberWithCommas(currentBalance?.balance_impact)}` : '-'}</Text>}
            </Grid>
          </Grid>
          {
            balanceSheet &&
            <>
              <Divider />
              <Grid container spacing={3} py={2}>
                <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Current Balance</Text></Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600 noWrap>{currentBalance?.current_balance ? `$ ${formatNumberWithCommas(currentBalance?.current_balance)}` : '-'}</Text>}</Grid>
              </Grid>
            </>
          }
        </Box> :
        <Box p={balanceSheet ? 2 : 3}>
          <Grid container spacing={3} pb={2}>
            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Opening Balance</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{currentBalance?.opening_balance ? `$ ${formatNumberWithCommas(currentBalance?.opening_balance)}` : '-'}</Text>} </Grid>
          </Grid>
          <Grid container spacing={3} pb={2}>
            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Deducted Amount</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{currentBalance?.balance_impact ? `$ ${formatNumberWithCommas(currentBalance?.balance_impact)}` : '-'}</Text>} </Grid>
          </Grid>
          <Grid container spacing={3} pb={2}>
            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Transaction ID</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{currentBalance?.transaction_id ? `$ ${formatNumberWithCommas(currentBalance?.transaction_id)}` : '-'}</Text>} </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={3} pb={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}><Text blackHeader600>Comments</Text></Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"start"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text smallBlack sx={{
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}>{currentBalance?.remarks}</Text>} </Grid>
          </Grid>

        </Box>

      }
      {!balanceSheet && <Box sx={{ background: "#D1E1FF", paddingTop: "2px" }}>
        <Grid container p={3} spacing={3}>
          <Grid item lg={5} md={5} sm={5} xs={5}><Text veryLargeLabel>Current Balance</Text></Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
          <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text boldblack22 noWrap>{currentBalance?.current_balance ? `$ ${formatNumberWithCommas(currentBalance?.current_balance)}` : '$ 0'}</Text>}</Grid>
        </Grid>
      </Box>}
    </div>
  )
}

export default CurrentBalancePopup
