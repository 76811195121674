import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import { addErrorMsg, addSuccessMsg, NoDataFound, ListLoadingIcon } from "../../../../../utils/utils";
import LocalStorage from '../../../../../utils/LocalStorage';
import CheckCircleIcon from '../../../../../assets/images/checked.png';
import TemplateApi from '../../../../../apis/configurations/templates/TemplateApi';
// import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
// import Button from '../../../../../components/customButton/Button';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import MainStyles from '../../MainStyles'
import GlobalTemplate from './GlobalTemplate';

function InvoiceTemplateTheme({ current }) {
    const classes = MainStyles()
    var colorsJson = require('../../../../../utils/jsons/Colors.json');
    const [colors, setColors] = useState(colorsJson)
    const [color, setColor] = useState('#0C75EB')
    const [color1, setColor1] = useState('#ECF5FF')
    // const [recentActivityShow, setrecentActivityShow] = useState(false)
    const recentSection = useRef(null);
    const [loading, setLoading] = useState(true);
    const [activityData, setActivityData] = useState([])
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })

    useEffect(() => {
        getAllColors();
        getActiveColor();
        getActivity(activityFilter);
        // eslint-disable-next-line
    }, [])

    const getAllColors = () => {
        TemplateApi.getAllColors().then((res) => {
            if (res.data.statusCode == 1003) {
                setColors(res.data.data)
            }
        })
    }

    const getActiveColor = () => {
        TemplateApi.getActiveColor().then((res) => {
            if (res.data.statusCode == 1003) {
                let newColor = colors.find((i)=>i.id == res.data.data.invoice_color_code_id)
                setColor(newColor.color)
                setColor1(newColor.sub_color)
            }
        })
    }

    const updateColor = (item) => {
        let data = {
            request_id: LocalStorage.uid(),
            id: item.id
        }
        TemplateApi.updateColor(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(res.data.message);
                getActivity(activityFilter);
                setColor(item.color); setColor1(item.sub_color)
            }
            else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const [activityTotal, setActivityTotal] = useState();
    const activityRef = useRef(null);
    const [listLoading, setListLoading] = useState(false);

    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(39, "", args).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setLoading(false)
                    setActivityData(response.data.data);
                    setActivityTotal(response.data.pagination.total);
                    if (popup) { }
                    // else {
                    //     setrecentActivityShow(true)
                    // }
                }
            }, 300)
            // removeLoader();
            setListLoading(false);
        });
    };

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // getCategory({...filter})
                if (activityTotal >= activityFilter.limit) {
                    // addLoader("loader");
                    setListLoading(true);
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    // const scrollDown = () => {
    //     window.scroll({
    //         top: recentSection.current.scrollIntoView(),
    //         behavior: 'smooth'
    //     })
    //     setrecentActivityShow(false)
    // }

    return (
        <Grid container>
                        {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :<>
            
            <Grid item container p={1} xl={1} lg={1} md={1} sm={1} xs={1} pt={3} display={'flex'} flexDirection={'column'}>
                {
                    colors.map((item) => (
                        item.color == color ?
                            <Box onClick={() => { updateColor(item) }} mb={1}
                                sx={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    backgroundColor: item.color,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: 'pointer'
                                }}
                            >
                                <img src={CheckCircleIcon} alt="check" sx={{ color: "white", fontSize: 15 }} />
                            </Box>
                            :
                            <Box onClick={() => { updateColor(item) }} mb={1}
                                key={item.id}
                                sx={{
                                    width: 30,
                                    height: { xl: 30, lg: 30, md: 30, sm: 27, xs: 27 },
                                    borderRadius: "50%",
                                    backgroundColor: item.color,
                                    cursor: 'pointer'
                                }}
                            ></Box>
                    ))
                }
            </Grid>
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Box sx={{
                    height: '70vh', overflow: 'auto', padding: '16px !important',
                    "@media (min-width: 100px) and (max-width: 499px)": {
                        padding: "10px 3px !important",
                    },
                }}>
                    <GlobalTemplate color={color} color1={color1} />
                </Box>
                <Box className={classes.activeItemBox} p={1.6} mt={4} mb={6} ref={recentSection}>
                    <Box mb={3}>
                        <Text RegularBlack1>Recent Activities</Text>
                    </Box>
                    <Box sx={{ height: '40vh', overflowY: 'auto' }}
                        ref={activityRef}
                        onScroll={activityHandleScroll}>
                        {loading ?
                            <>
                                {[1, 2, 3].map((item, index) => (
                                    <Grid container key={index} mt={3}>
                                        <Grid item lg={8} md={8} sm={8} xs={12}>
                                            <Skeleton animation="wave" width="200px" />
                                            <Skeleton animation="wave" width="200px" />

                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                            <Skeleton animation="wave" width="200px" />
                                            <Skeleton animation="wave" width="200px" />

                                        </Grid>
                                    </Grid>
                                ))}
                            </> :
                            <>
                                {activityData.length > 0 ?
                                    <>
                                        {activityData.map((value) => (
                                            <Box className={classes.descriptionBoxStyle} mb={2}>
                                                <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                    <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                        <Text mediumBlackColor>{value.user_name} {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                    </Grid>
                                                    <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                                        {
                                                            value.change_log.length > 0 && value.change_log.map((item) => (
                                                                <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </> :
                                    <>
                                        {NoDataFound("", "No Data Found", "50%", "35")}
                                    </>
                                }
                            </>
                        }
                        {listLoading && (
                            <Box >
                                <ListLoadingIcon />
                            </Box>
                        )}
                    </Box>
                </Box>
                {/* {recentActivityShow ?
                    <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                        <Button addButton
                            sx={{
                                position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                "@media (min-width: 300px) and (max-width: 1420px)": {
                                    font: "12px Quicksand !important",
                                    fontWeight: `${700} !important`
                                }
                            }}
                            onClick={scrollDown}
                            endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                    </Grid>
                    : ''} */}
            </Grid></>}

        </Grid>
    )
}

export default InvoiceTemplateTheme;
