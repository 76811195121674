
import { Avatar, Box, Divider, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import Text from "../../../components/customText/Text";
import DashboardStyles from './DasboardStyles';
import { BlackToolTip, NoDataFound } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import Verified from '../../../assets/svg/Component87.svg';
// import disableVerified from '../../../assets/client/disableVerified.svg';
import Pending from '../../../assets/employee/Orange-PendingIcon.svg';

function SideActions(props) {
    const classes = DashboardStyles();
    const navigate = useNavigate();
    const { loaderAction, Reminders, navigatePending, handleScroll, scrollBox, capitalizeAndAddSpace } = props;
    return (
        <Grid item container lg={4} md={4} sm={12} xs={12} p={'0px 10px 0px 20px'}>
            <Grid item lg={12} sm={12} xs={12} pt={2}>
                <Text mediumBoldBlack600>Actions</Text>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                <Box className={classes.cardContainer} height={{ lg: Reminders?.length < 5 ? "fit-content !important" : "72vh !important", md: Reminders?.length < 5 ? "fit-content !important" : "72vh !important", sm: "16vh !important", xs: "16px !important" }}>
                    {
                        loaderAction ?
                            [0, 1].map((_, i) => (
                                <Box key={i} className={classes.sidecard}>
                                    <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                                        <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                                            <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                                            <Stack gap='8px'>
                                                <Skeleton animation='wave' width='100px' />
                                                <Skeleton animation='wave' width='100px' />
                                            </Stack>
                                        </Stack>
                                        <Stack alignContent={'center'} alignItems={'center'}>
                                            <Skeleton animation='wave' width='50px' />
                                        </Stack>
                                    </Stack>
                                </Box>
                            ))
                            :
                            Reminders.length == 0 ?
                                NoDataFound('') :
                                <Grid item lg={12} xs={12} sx={{ height: Reminders.length < 5 ? "fit-content !important" : "72vh !important" }} className={classes.cardContainer} ref={scrollBox} onScroll={handleScroll}>
                                    {
                                        Reminders.length > 0 ? Reminders.map((item) => (
                                            <Grid item container ml={.5} lg={11} xs={12}
                                                onClick={() => navigatePending(item)}
                                                className={classes.sideCardbg}
                                            >
                                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} spacing={{ lg: 0, md: 1, sm: 0, xs: 1 }}>
                                                    <Grid item lg={2} md={3} sm={2} xs={4}>
                                                        <Avatar
                                                            src={item.profile_picture_url ? `${item.profile_picture_url}?w=40&h=40&fit=crop` : ''}
                                                            alt={capitalizeAndAddSpace(item.display_name[0])}
                                                            sx={{ height: '40px', width: '40px', borderRadius: '50%', background: item.profile_picture_url ? '' : '#e5b256' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={7} md={8} sm={7} xs={8}>
                                                        <Text dashboardblack14>
                                                            {
                                                                item.display_name ? item.display_name.length > 16 ?
                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.display_name)} placement="top" arrow>
                                                                        {capitalizeAndAddSpace(item.display_name.slice(0, 16)) + capitalizeAndAddSpace(item.display_name.length > 16 ? "..." : "")}
                                                                    </BlackToolTip>
                                                                    : capitalizeAndAddSpace(item.display_name) : '--'
                                                            }
                                                            {
                                                                item.employee_e_verified == 1 || item.employee_e_verified == 4 ?
                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>E-Verified</Text>}>
                                                                        <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} width='20' height='20' />
                                                                    </BlackToolTip>
                                                                    : item.employee_e_verified == 2 ?
                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>E-verification is pending</Text>}>
                                                                            <img src={Pending} alt='Verified' style={{ margin: '0px 0px -3px 5px' }} />
                                                                        </BlackToolTip> : ''
                                                            }

                                                        </Text>
                                                        {
                                                            (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                                <Text greysmallLabel>{item.reference_id}</Text> :
                                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                    <Text greysmallLabel>{item.placement_reference_id}</Text>
                                                                    <Box className={classes.greyDot} />
                                                                    <Text greysmallLabel>{item.reference_id ? item.reference_id : '-'}</Text>
                                                                </Box>
                                                        }
                                                    </Grid>
                                                    <Grid item lg={2.5} md={12} sm={3} xs={12} textAlign={"-webkit-right"}>
                                                        <Box className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' || item.status == 'Pending Approval' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : classes.drafted}>
                                                            <Text mediumWhite400>{item.status == 'Pending Approval' ? 'Pending' : item.status}</Text>
                                                        </Box>
                                                    </Grid>
                                                    <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                                    {
                                                        (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                            <>
                                                                <Grid item lg={7} md={6} sm={6} xs={6}>
                                                                    <Text greysmallLabel>Employee Type</Text>
                                                                    <Text dashboardblack14>{item.employee_type ? item.employee_type : '-'}</Text>
                                                                </Grid>
                                                                <Grid item container lg={5} md={6} sm={6} xs={6} className={classes.textCenter} alignItems='center' justifyContent='end'>
                                                                    <Text mediumBlue600>Profile Update</Text>
                                                                </Grid>
                                                            </> :
                                                            <>
                                                                <Grid item lg={6.5} md={6} sm={6} xs={8}>
                                                                    <Text greysmallLabel>Client Name</Text>
                                                                    <Text dashboardblack12>
                                                                        {
                                                                            item.client_name ? item.client_name.length > 16 ?
                                                                                <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                                    {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                : capitalizeAndAddSpace(item.client_name) : '--'
                                                                        }
                                                                    </Text>
                                                                </Grid>
                                                                <Grid item container lg={5.5} md={6} sm={6} xs={4} alignContent={'center'} justifyContent='end'>
                                                                    <Text mediumBlue600>Timesheet</Text>
                                                                </Grid>
                                                            </>
                                                    }
                                                </Grid>

                                            </Grid>
                                        )) : ''
                                    }
                                </Grid>

                    }
                </Box>
                {/* {
                                                    Reminders.length == 0 ? '' : */}
                <Grid item container lg={11} md={10} sm={10} xs={10} py={2} justifyContent='center'>
                    <Box minWidth='55px' textAlign='end'>
                        <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/pending-actions', { state: { from: 'viewAll', placementId: '' } })}>View All</Text>
                    </Box>
                </Grid>
                {/* } */}
            </Grid>
        </Grid>
    )
}
export default SideActions