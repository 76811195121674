import { Box, Divider, Grid, Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import Text from "../../../components/customText/Text";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { addErrorMsg, BlackToolTip, dateFormat } from "../../../utils/utils";
import billed from '../../../assets/svg/dashboard/billed.svg';
import pay from '../../../assets/svg/dashboard/pay.svg';
import margin from '../../../assets/svg/dashboard/margin.svg';
import ReactApexChart from 'react-apexcharts';
import Date from "../../../components/datePicker/Date";
import moment from "moment";
import Button from "../../../components/customButton/Button";
import closeIcon from '../../../assets/svg/payroll/close-Icon.svg';
import blueLoader from '../../../assets/gif/japfuLoader.gif';
import avgHoursMargin from '../../../assets/svg/dashboard/AvgHoursMargin.svg';

function FinancialPerformance(props) {
    const { financialOptions, currency, viewAnalytics, financial, yearDropdwn, years, classes, setYearDropdwn, getApi } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [enableDate, setEnableDates] = useState(false);
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        setYearDropdwn({
            ...yearDropdwn,
            [name]: date == '' ? '' : moment(date).format("YYYY-MM-DD")
        })
    }

    const dropDownChangeHandler = (e) => {
        let value = e.target.value
        yearDropdwn[e.target.name] = e.target.value
        setYearDropdwn({ ...yearDropdwn });
        if (value === 4) {
            setAnchorEl(true);
            setOpen(true);
        } else {
            switch (value) {
                case 1:
                    setLoad(true);
                    var start_date1f = moment().subtract(3, 'months').format("YYYY-MM-DD");
                    var end_date1f = moment().format("YYYY-MM-DD");
                    getApi(start_date1f, end_date1f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                case 2:
                    setLoad(true);
                    var start_date2f = moment().subtract(6, 'months').format("YYYY-MM-DD");
                    var end_date2f = moment().format("YYYY-MM-DD");
                    getApi(start_date2f, end_date2f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                case 3:
                    setLoad(true);
                    var start_date3f = moment().subtract(1, 'year').format("YYYY-MM-DD");
                    var end_date3f = moment().format("YYYY-MM-DD");
                    getApi(start_date3f, end_date3f);
                    setTimeout(() => {
                        setLoad(false);
                    }, 100)
                    break;
                default:
                    break;
            }
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        yearDropdwn.year = 3;
        yearDropdwn.year_start_date = '';
        yearDropdwn.year_end_date = '';
        setYearDropdwn({ ...yearDropdwn });
    };

    const applyFilter = () => {
        if (yearDropdwn.year_start_date !== '' && yearDropdwn.year_end_date !== '') {
            const startDate = moment(yearDropdwn.year_start_date);
            const endDate = moment(yearDropdwn.year_end_date);
            if (startDate.isSameOrBefore(endDate)) {
                setLoad(true);
                setEnableDates(true);
                getApi(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
                setAnchorEl(null);
                setOpen(false);
                setTimeout(() => {
                    setLoad(false);
                }, 100);
            } else {
                addErrorMsg('Please ensure that the start date is before the end date.');
            }
        } else {
            addErrorMsg('Please select Start and End Date');
        }
    }

    const clearDate = () => {
        setEnableDates(false);
        handleClose();
        getApi(moment().subtract(1, 'year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        setTimeout(() => {
            setLoad(false);
        }, 100)
    }

    return (
        <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} pt={3} id='Financialchart'>
            <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center'>
                <Grid item lg={enableDate ? 8 : 10} md={enableDate ? 8 : 9} sm={9} xs={12}>
                    <Text smallLabelblack16>Financial Performance</Text>
                </Grid>
                {
                    enableDate ?
                        <Grid item lg={enableDate ? 4 : 2} md={enableDate ? 4 : 3} sm={3} xs={12} textAlign={"-webkit-right"} >
                            <Box mt={{ xs: 1 }} className={classes.choosePeriod}>
                                <Text offBoardBodyFont>{moment(yearDropdwn.year_start_date, "YYYY-MM-DD").format(dateFormat())} - {moment(yearDropdwn.year_end_date, "YYYY-MM-DD").format(dateFormat())}</Text>
                                <img src={closeIcon} alt='close' style={{ cursor: 'pointer' }} onClick={() => { clearDate() }} />
                            </Box>
                        </Grid> :
                        <Grid item lg={2} md={3} sm={3} xs={8} textAlign={"end"}>
                            <CustomSelect
                                name='year'
                                value={yearDropdwn.year}
                                viewDrop
                                scrollTrue={true}
                                options={years}
                                onChange={(e) => dropDownChangeHandler(e)}
                                labelId={'simple-select-filled-label'}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                            />
                            <Menu
                                id="simple-select-filled-label"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => handleClose('financial')}
                                MenuListProps={{
                                    'aria-labelledby': 'simple-select-filled-label',
                                }}
                                style={{ width: '100%' }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem disableRipple={true} sx={{ backgroundColor: 'transparent !important' }}>
                                    <Grid item xs={12}>
                                        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
                                            <Box>
                                                <Date
                                                    labelText="Start Date"
                                                    name="year_start_date"
                                                    value={yearDropdwn.year_start_date}
                                                    onChange={(value) => dateChange(value, 'year_start_date')}
                                                    height="45px"
                                                />
                                            </Box>
                                            <Box>-</Box>
                                            <Box>
                                                <Date
                                                    labelText="End Date"
                                                    name="year_end_date"
                                                    value={yearDropdwn.year_end_date}
                                                    minDate={moment(yearDropdwn.year_start_date).add(1, 'days').format(dateFormat())}
                                                    onChange={(value) => dateChange(value, 'year_end_date')}
                                                    height="45px"
                                                />
                                            </Box>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Box my={2}>
                                                <Divider />
                                            </Box>
                                            <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
                                                <Button payrollHistoryCancel onClick={() => handleClose()}>Cancel</Button>
                                                <Button blueButton onClick={() => applyFilter()}>Apply Filter</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Menu>

                        </Grid>
                }
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} spacing={2} columnSpacing={2}>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Box alignItems='center' className={classes.bluebg}>
                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                            <Grid item lg={2} md={2} xs={2}>
                                <img loading="eager" src={billed} alt='clients' width='48' height='48' />
                            </Grid>
                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                <Text mediumBoldBlack>Total Billed</Text>
                                <Text boldblack22>{currency}&nbsp;
                                    {financial.total_billed ? financial.total_billed.length > 10 ?
                                        <BlackToolTip title={financial.total_billed}>
                                            {financial.total_billed.slice(0, 10) + financial.total_billed.length > 10 ? '...' : ''}
                                        </BlackToolTip> : financial.total_billed : financial.total_billed == 0 ? 0 : '--'
                                    }
                                </Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Box alignItems='center' className={classes.yellowbg}>
                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                            <Grid item lg={2} md={2} xs={2}>
                                <img loading="eager" src={pay} alt='clients' width='48' height='48' />
                            </Grid>
                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                <Text mediumBoldBlack>Total Pay</Text>
                                <Text boldblack22>{currency}&nbsp;
                                    {financial.total_pay ? financial.total_pay.length > 10 ?
                                        <BlackToolTip title={financial.total_pay}>
                                            {financial.total_pay.slice(0, 10) + financial.total_pay.length > 10 ? '...' : ''}
                                        </BlackToolTip> : financial.total_pay : financial.total_pay == 0 ? 0 : '--'
                                    }
                                </Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Box alignItems='center' className={classes.purplebg}>
                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                            <Grid item lg={2} md={2} xs={2}>
                                <img loading="eager" src={margin} alt='clients' width='48' height='48' />
                            </Grid>
                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                <Text mediumBoldBlack>Total Margin</Text>
                                <Text boldblack22>{currency}&nbsp;
                                    {financial.margin ? financial.margin.length > 10 ?
                                        <BlackToolTip title={financial.margin}>
                                            {financial.margin.slice(0, 10) + financial.margin.length > 10 ? '...' : ''}
                                        </BlackToolTip> : financial.margin : financial.margin == 0 ? 0 : '--'
                                    }
                                </Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                    <Box alignItems='center' className={classes.lightyellowbg}>
                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                            <Grid item lg={2} md={2} xs={2}>
                                <img loading="eager" src={avgHoursMargin} alt='clients' width='48' height='48' />
                            </Grid>
                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                <Text mediumBoldBlack>Avg Hourly Margin</Text>
                                <Text boldblack22>
                                    {currency}&nbsp;
                                    {financial.avg_hourly_margin ? financial.avg_hourly_margin.length > 10 ?
                                        <BlackToolTip title={financial.avg_hourly_margin}>
                                            {financial.avg_hourly_margin.slice(0, 10) + financial.avg_hourly_margin.length > 10 ? '...' : ''}
                                        </BlackToolTip> : financial.avg_hourly_margin : financial.avg_hourly_margin == 0 ? 0 : '--'
                                    }
                                </Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                {load ?
                    <Box sx={{
                        height: '30vh',
                        overflow: 'auto',
                        padding: '16px',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                            <img src={blueLoader} height={100} width={100} alt='loading' />
                        </Stack>
                    </Box>
                    : <ReactApexChart
                        key={JSON.stringify(financialOptions.series)}
                        options={financialOptions}
                        series={financialOptions.series}
                        type="area"
                        height='300px'
                    />
                }
            </Grid>
            <Grid item container lg={12} md={12} sm={10} xs={10} pt={2} justifyContent='end'>
                <Box minWidth='70px' textAlign='end'>
                    <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={viewAnalytics}>View More</Text>
                </Box>
            </Grid>
            <Divider sx={{ width: '100%', margin: '20px 0px 30px 0px', borderColor: '#F5F5F5' }} />
        </Grid>
    )
}
export default FinancialPerformance