import { makeStyles } from "@mui/styles";
import { blue } from "../../../../theme";
import { useTheme } from "@mui/material";


const VendorStyles = makeStyles(() => ({

    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
        [useTheme().breakpoints.down('md')]: {
            left: "0px",
            width: "calc(100% - 0px)",
        },
    },

    profileName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "22px !important",
        textAlign: "center"
    },
    profileId: {
        fontFamily: "Quicksand !important",
        color: "#737373 !important",
        fontSize: "14px !important",
        textAlign: "center",
    },
    eVerifyBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#4ABE43 !important",
        border: "1px solid #4ABE43 !important",
        height: "35px",
        width: "140px",
        padding: "0px 18px !important",
        borderRadius: "8px !important"
    },
    moreBtn: {
        fontFamily: "Quicksand !important",
        textTransform: "capitalize !important",
        color: "#9D9E9F !important",
        border: "1px solid #9D9E9F !important",
        height: "35px !important",
        minWidth: "35px !important",
        padding: "5px !important",
        borderRadius: "8px !important"
    },

    listItems: {
        font: "14px Quicksand !important",
        minHeight: "32px",
        margin: "6px 0px !important",
        color: '#092333',
        borderRadius: "4px !important",
        // transition: "all .3s ease",
        fontWeight: `${600} !important`,
        '&:hover': {
            backgroundColor: '#F5F6F6 !important',
            // color: '#fff !important'
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }
    },

    listItemsActive: {
        color: '#FFFFFF !important',
        backgroundColor: '#0C75EB !important',
        transition: "all .3s ease",
        font: '14px Quicksand !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: '#F5F6F6 !important',
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${600} !important`,
        }       
    },

    avatarBox: {
        display: "flex",
        borderRadius: "50%",
        border: "2px solid #037847",
    },
    avatar: {
        width: "90px !important",
        height: "90px !important",
        margin: "2px"
    },

    tabTitle: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        textTransform: "capitalize !important",
        fontSize: "15px !important",
        fontWeight: 500,
        margin: "0px 12px !important",
        padding: "20px !important"
    },

    activeTabTitle: {
        color: "#0C75EB !important",
    },

    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Quicksand !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },

    breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
        padding: '0px 5px'
    },

    sideTooltip: {
        height: "34px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },

    sideTooltipText: {
        fontSize: "14px",
        fontFamily: "Quicksand",
        fontWeight: "400",
        color: "#FFFFFF",
    },
    viewText: {
        font: '13px Quicksand !important',
        height: '35px !important',
        "&:hover": {
            background: 'none !important'
        }
    },
    inactive: {
        backgroundColor: "#ffffff !important",
        fontWeight: "500 !important",
        font: '12px Quicksand !important',
        minWidth: "100px !important",
        height: '44px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        padding: "4px 12px !important",
        "&:hover": {
            background: 'none !important'
        }
    },
    cardBg: {
        width: "100% !important",
        minHeight: "80vh !important",
        borderRadius: "8px !important"
    },
    cardContent: {
        width: "100% !important",
        minHeight: "40vh !important",
        maxHeight: "80vh !important",
        borderRadius: "8px !important",
    },
    moreIcon: {
        fontSize: "18px",
        color: "Black",
        height: '25px',
        width: '25px',
        cursor: 'pointer'
    },
    clientAvatarSection: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
    },

    uploadBtn: {      
        font: '12px Quicksand !important',
        fontWeight: '500 !important',
        textTransform: 'capitalize !important',
        color: '#0C75EB !important',
        backgroundColor: "#ffffff !important",
        padding: "2px 8px !important"
    },  
    clientAvatarBox: {
        height: "100px",
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "#F6F6F6",
        margin: "4px",
    },
    profileTooltipText: {
        fontSize: "14px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    helperTextError: {
        color: '#e72929 !important',
        font: '11px Quicksand !important',
    },
    cursor: {
        cursor: 'pointer !important'
    },
    skeleton: {
        height: '15px !important', width: '30px !important', background: '#F6F6F6 !important'
    },
    profileskeleton: {
        height: '100px !important', width: '70px !important', borderRadius: '50% !important', background: '#F6F6F6 !important'
    },
    textSkeleton: {
        height: '30px !important', width: '100px !important', background: '#F6F6F6 !important'
    },
    editSkeleton:{
        height: '40px !important', width: '30px !important', background: '#F6F6F6 !important'
    },
    blueText: {
        color: `${blue} !important`
    },
}))

export default VendorStyles;
