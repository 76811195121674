import { Typography } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import TextStyles from './TextStyles'

function Text(props) {
    const classes = TextStyles()
    const { children,
        smallLabel, mediumLabel, largeLabel, veryLargeLabel,
        smallBlack, mediumBlack, largeBlack,
        smallBoldBlack, mediumBoldBlack, mediumBoldPrimary, largeBoldBlack,
        smallRed, mediumRed, largeRed,
        mediumBoldRed,
        mediumGreen, largeGreen,
        smallYellow, largeYellow,
        smallBlue, mediumBlue, largeBlue,
        smallWhite, mediumWhite, largeWhite,
        veryLargeBlack, smallGrayLabel,
        smallLightBlue, mediumLightBlue, largeLightBlue,
        verySmallLabel,
        smallOrange, mediumOrange, largeOrange, className,
        smallBoldBlue, mediumBoldBlue, largeBoldBlue,
        smallBoldGreen, smallBoldOrange, verySmallBoldBlack,
        mediumLessBoldBlack, red, BrowmnMnStepperText,
        errorText, headerBlack,
        profileTitle, mediumGrey, largeBldBlack, largeGrey,
        checkboxlable, BlackExtraDark,
        overViewAmount,
        overViewLable, blackFont14, mediumOverView, mediumViewAmt, smallGrey, boldBlackfont600,
        largeGreyTxt, largeGrey16, mediumBlackColor, greyLabel,
        cardTitle,
        smallBlue1,
        boldBlackfont16, verySmallBlack,
        boldBlackfont22,
        blackHeader18, mediumBlack14,
        blackHeader, mediumBoldBlack2, lightGrey3, mediumGrey2, blackHeader1, infoText,
        popupHead1, popupHead2, mediumBoldWhite, largeBoldGreen, employeeAdded, defaultmediumText, lightPurpleMedium, greysmallLabel,
        smallBlackBold, mediumOrangeRehire, smallGreyFont, mediumBoldWhite400,
        offBoardHeadFont,
        primaryFlagFont,
        offBoardBodyFont,
        offBoardBodyGreen,
        offBoardHeadFontMedium,
        smallBlack14,
        veryLargeWhite, largeWhite400, mediumBlue400, largeLabel700,
        mediumWhite400,
        mediumBoldBlack400,
        largeHeaderText, largeGrey14, boldGrey14, mediumGreyHeader, blue14,
        LargeBlack, mediumBoldGrey, mediumGreyHeader1,
        blackHeader600, grey16, mediumLabelColor,
        boldblack600, boldblack22, largeBlack700, mediumGreybold, black14,
        smallLabelblack, mediumBoldBlack700, mediumGrey700, grey700,
        mediumGreen700, smallLabelblack16, largeblack40, smallBlack400,
        largeWhite18, mediumBoldBlack600, dashboardblack14, dashboardblack12, analyticsViewAll,
        mediumBlue600, largeBlack22,
        mediumBlack700, smallGrey10, black12, violet600,
        grey18px,
        black18px,
        headerText14,
        black400, largeLabel400,
        mediumGreyHeader600, notificationread, notificationreadSmall,
        largeBlack28, veryLargeBlue, smallnavyBlue,
        blackHeader2,
        largeWhite20, greyDark22,
        greensmall, smallGrey400,
        RegularBlack1,
        largeWhite1, largeOrange16, mediumBlack1, mediumBlack600, tourText,
        greyLabel14, blueText16, largeBlack20, greyColor16,
        mediumBoldWhite600, largeWhite16, largeOrange22,
        lightGrey10, smallblackLabel10,
        offBoardingText, mediumGrey3, filterText, mediumInactiveLabel, largeWhiteActive400,
        ...rest
    } = props

    /**
     * When you called this function just you have to pass defined props for styles for typogrophy
     * ----------
     * if prop is not avaliable create new class in CustomTextStyles file for styles and add that class in 
     * >props 
     * >btnClasses
     * >Text.propTypes
     * ------------
     * ------------
     * @parem children :- content of the Text excepted type `node`
     * */

    const btnClasses = classNames({
        [className]: className,
        [classes.smallLabel]: smallLabel,
        [classes.mediumLabel]: mediumLabel,
        [classes.largeLabel]: largeLabel,
        [classes.smallBlack]: smallBlack,
        [classes.mediumBlack]: mediumBlack,
        [classes.largeBlack]: largeBlack,
        [classes.smallBoldBlack]: smallBoldBlack,
        [classes.mediumBoldBlack]: mediumBoldBlack,
        [classes.mediumBoldPrimary]: mediumBoldPrimary,
        [classes.largeBoldBlack]: largeBoldBlack,
        [classes.smallRed]: smallRed,
        [classes.mediumRed]: mediumRed,
        [classes.largeRed]: largeRed,
        [classes.mediumGreen]: mediumGreen,
        [classes.largeGreen]: largeGreen,
        [classes.smallYellow]: smallYellow,
        [classes.largeYellow]: largeYellow,
        [classes.smallBlue]: smallBlue,
        [classes.mediumBlue]: mediumBlue,
        [classes.largeBlue]: largeBlue,
        [classes.smallWhite]: smallWhite,
        [classes.mediumWhite]: mediumWhite,
        [classes.largeWhite]: largeWhite,
        [classes.smallOrange]: smallOrange,
        [classes.mediumOrange]: mediumOrange,
        [classes.largeOrange]: largeOrange,
        [classes.veryLargeBlack]: veryLargeBlack,
        [classes.smallGrayLabel]: smallGrayLabel,
        [classes.smallLightBlue]: smallLightBlue,
        [classes.mediumLightBlue]: mediumLightBlue,
        [classes.largeLightBlue]: largeLightBlue,
        [classes.verySmallLabel]: verySmallLabel,
        [classes.smallBoldBlue]: smallBoldBlue,
        [classes.mediumBoldBlue]: mediumBoldBlue,
        [classes.largeBoldBlue]: largeBoldBlue,
        [classes.smallBoldGreen]: smallBoldGreen,
        [classes.smallBoldOrange]: smallBoldOrange,
        [classes.verySmallBoldBlack]: verySmallBoldBlack,
        [classes.mediumBoldRed]: mediumBoldRed,
        [classes.mediumLessBoldBlack]: mediumLessBoldBlack,
        [classes.veryLargeLabel]: veryLargeLabel,
        [classes.red]: red,
        [classes.BrowmnMnStepperText]: BrowmnMnStepperText,
        [classes.errorText]: errorText,
        [classes.headerBlack]: headerBlack,
        [classes.checkboxlable]: checkboxlable,
        [classes.BlackExtraDark]: BlackExtraDark,
        [classes.profileTitle]: profileTitle,
        [classes.mediumGrey]: mediumGrey,
        [classes.largeBldBlack]: largeBldBlack,
        [classes.largeGrey]: largeGrey,
        [classes.overViewLable]: overViewLable,
        [classes.overViewAmount]: overViewAmount,
        [classes.blackFont14]: blackFont14,
        [classes.mediumOverView]: mediumOverView,
        [classes.mediumViewAmt]: mediumViewAmt,
        [classes.smallGrey]: smallGrey,
        [classes.cardTitle]: cardTitle,
        [classes.smallBlue1]: smallBlue1,
        [classes.greyLabel]: greyLabel,
        [classes.boldBlackfont16]: boldBlackfont16,
        [classes.verySmallBlack]: verySmallBlack,
        [classes.largeGreyTxt]: largeGreyTxt,
        [classes.largeGrey16]: largeGrey16,
        [classes.mediumBlackColor]: mediumBlackColor,
        [classes.greyLabel]: greyLabel,
        [classes.boldBlackfont16]: boldBlackfont16,
        [classes.boldBlackfont22]: boldBlackfont22,
        [classes.popupHead1]: popupHead1,
        [classes.popupHead2]: popupHead2,
        [classes.blackHeader18]: blackHeader18,
        [classes.mediumBlack14]: mediumBlack14,
        [classes.verySmallBlack]: verySmallBlack,
        [classes.blackHeader]: blackHeader,
        [classes.mediumBoldBlack2]: mediumBoldBlack2,
        [classes.lightGrey3]: lightGrey3,
        [classes.mediumGrey2]: mediumGrey2,
        [classes.blackHeader1]: blackHeader1,
        [classes.infoText]: infoText,
        [classes.mediumBoldWhite]: mediumBoldWhite,
        [classes.largeBoldGreen]: largeBoldGreen,
        [classes.employeeAdded]: employeeAdded,
        [classes.defaultmediumText]: defaultmediumText,
        [classes.lightPurpleMedium]: lightPurpleMedium,
        [classes.greysmallLabel]: greysmallLabel,
        [classes.smallBlackBold]: smallBlackBold,
        [classes.mediumOrangeRehire]: mediumOrangeRehire,
        [classes.smallGreyFont]: smallGreyFont,
        [classes.mediumBoldWhite400]: mediumBoldWhite400,
        [classes.offBoardHeadFont]: offBoardHeadFont,
        [classes.primaryFlagFont]: primaryFlagFont,
        [classes.offBoardBodyFont]: offBoardBodyFont,
        [classes.offBoardBodyGreen]: offBoardBodyGreen,
        [classes.offBoardHeadFontMedium]: offBoardHeadFontMedium,
        [classes.smallBlack14]: smallBlack14,
        [classes.veryLargeWhite]: veryLargeWhite,
        [classes.largeWhite400]: largeWhite400,
        [classes.mediumBlue400]: mediumBlue400,
        [classes.largeLabel700]: largeLabel700,
        [classes.mediumWhite400]: mediumWhite400,
        [classes.mediumBoldBlack400]: mediumBoldBlack400,
        [classes.largeHeaderText]: largeHeaderText,
        [classes.largeGrey14]: largeGrey14,
        [classes.boldGrey14]: boldGrey14,
        [classes.mediumGreyHeader]: mediumGreyHeader,
        [classes.blue14]: blue14,
        [classes.LargeBlack]: LargeBlack,
        [classes.mediumBoldGrey]: mediumBoldGrey,
        [classes.mediumLabelColor]: mediumLabelColor,
        [classes.mediumGreyHeader1]: mediumGreyHeader1,
        [classes.grey16]: grey16,
        [classes.blackHeader600]: blackHeader600,
        [classes.boldblack600]: boldblack600,
        [classes.boldblack22]: boldblack22,
        [classes.largeBlack700]: largeBlack700,
        [classes.mediumGreybold]: mediumGreybold,
        [classes.black14]: black14,
        [classes.smallLabelblack]: smallLabelblack,
        [classes.mediumBoldBlack700]: mediumBoldBlack700,
        [classes.mediumGrey700]: mediumGrey700,
        [classes.grey700]: grey700,
        [classes.mediumGreen700]: mediumGreen700,
        [classes.smallLabelblack16]: smallLabelblack16,
        [classes.largeblack40]: largeblack40,
        [classes.smallBlack400]: smallBlack400,
        [classes.largeWhite18]: largeWhite18,
        [classes.mediumBoldBlack600]: mediumBoldBlack600,
        [classes.dashboardblack14]: dashboardblack14,
        [classes.dashboardblack12]: dashboardblack12,
        [classes.analyticsViewAll]: analyticsViewAll,
        [classes.mediumBlue600]: mediumBlue600,
        [classes.largeBlack22]: largeBlack22,
        [classes.mediumBlack700]: mediumBlack700,
        [classes.smallGrey10]: smallGrey10,
        [classes.black12]: black12,
        [classes.violet600]: violet600,
        [classes.grey18px]: grey18px,
        [classes.black18px]: black18px,
        [classes.headerText14]: headerText14,
        [classes.black400]: black400,
        [classes.largeLabel400]: largeLabel400,
        [classes.mediumGreyHeader600]: mediumGreyHeader600,
        [classes.notificationread]: notificationread,
        [classes.notificationreadSmall]: notificationreadSmall,
        [classes.largeBlack28]: largeBlack28,
        [classes.veryLargeBlue]: veryLargeBlue,
        [classes.smallnavyBlue]: smallnavyBlue,
        [classes.blackHeader2]: blackHeader2,
        [classes.largeWhite20]: largeWhite20,
        [classes.greyDark22]: greyDark22,
        [classes.greensmall]: greensmall,
        [classes.smallGrey400]: smallGrey400,
        [classes.RegularBlack1]: RegularBlack1,
        [classes.largeWhite1]: largeWhite1,
        [classes.largeOrange16]: largeOrange16,
        [classes.mediumBlack1]: mediumBlack1,
        [classes.mediumBlack600]: mediumBlack600,
        [classes.tourText]: tourText,
        [classes.greyLabel14]: greyLabel14,
        [classes.blueText16]: blueText16,
        [classes.largeBlack20]: largeBlack20,
        [classes.greyColor16]: greyColor16,
        [classes.mediumBoldWhite600]: mediumBoldWhite600,
        [classes.largeWhite16]: largeWhite16,
        [classes.largeOrange22]: largeOrange22,
        [classes.lightGrey10]: lightGrey10,
        [classes.smallblackLabel10]: smallblackLabel10,
        [classes.offBoardingText]: offBoardingText,
        [classes.mediumGrey3]: mediumGrey3,
        [classes.filterText]: filterText,
        [classes.largeWhiteActive400]: largeWhiteActive400,
        [classes.mediumInactiveLabel]: mediumInactiveLabel,
    })
    return (
        <React.Fragment>
            <Typography {...rest} className={btnClasses} component={'div'}>
                {children}
            </Typography>
        </React.Fragment>
    )
}

export default Text

Text.propTypes = {
    variant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'h7',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
    ]),
    className: PropTypes.string,
    children: PropTypes.node,
    smallLabel: PropTypes.bool,
    mediumLabel: PropTypes.bool,
    largeLabel: PropTypes.bool,
    smallBlack: PropTypes.bool,
    mediumBlack: PropTypes.bool,
    largeBlack: PropTypes.bool,
    smallBoldBlack: PropTypes.bool,
    mediumBoldBlack: PropTypes.bool,
    mediumBoldPrimary: PropTypes.bool,
    largeBoldBlack: PropTypes.bool,
    smallRed: PropTypes.bool,
    mediumRed: PropTypes.bool,
    largeRed: PropTypes.bool,
    mediumGreen: PropTypes.bool,
    largeGreen: PropTypes.bool,
    smallYellow: PropTypes.bool,
    largeYellow: PropTypes.bool,
    smallBlue: PropTypes.bool,
    mediumBlue: PropTypes.bool,
    largeBlue: PropTypes.bool,
    smallWhite: PropTypes.bool,
    mediumWhite: PropTypes.bool,
    largeWhite: PropTypes.bool,
    smallOrange: PropTypes.bool,
    mediumOrange: PropTypes.bool,
    largeOrange: PropTypes.bool,
    veryLargeBlack: PropTypes.bool,
    smallGrayLabel: PropTypes.bool,
    smallLightBlue: PropTypes.bool,
    mediumLightBlue: PropTypes.bool,
    largeLightBlue: PropTypes.bool,
    verySmallLabel: PropTypes.bool,
    smallBoldBlue: PropTypes.bool,
    mediumBoldBlue: PropTypes.bool,
    largeBoldBlue: PropTypes.bool,
    smallBoldGreen: PropTypes.bool,
    smallBoldOrange: PropTypes.bool,
    verySmallBoldBlack: PropTypes.bool,
    mediumBoldRed: PropTypes.bool,
    mediumLessBoldBlack: PropTypes.bool,
    veryLargeLabel: PropTypes.bool,
    red: PropTypes.bool,
    BrowmnMnStepperText: PropTypes.bool,
    errorText: PropTypes.bool,
    headerBlack: PropTypes.bool,
    checkboxlable: PropTypes.bool,
    BlackExtraDark: PropTypes.bool,
    profileTitle: PropTypes.bool,
    mediumGrey: PropTypes.bool,
    largeBldBlack: PropTypes.bool,
    largeGrey: PropTypes.bool,
    overViewLable: PropTypes.bool,
    overViewAmount: PropTypes.bool,
    blackFont14: PropTypes.bool,
    mediumOverView: PropTypes.bool,
    mediumViewAmt: PropTypes.bool,
    smallGrey: PropTypes.bool,
    cardTitle: PropTypes.bool,
    smallBlue1: PropTypes.bool,
    boldBlackfont600: PropTypes.bool,
    largeGreyTxt: PropTypes.bool,
    largeGrey16: PropTypes.bool,
    mediumBlackColor: PropTypes.bool,
    greyLabel: PropTypes.bool,
    boldBlackfont16: PropTypes.bool,
    verySmallBlack: PropTypes.bool,
    boldBlackfont22: PropTypes.bool,
    popupHead1: PropTypes.bool,
    popupHead2: PropTypes.bool,
    blackHeader18: PropTypes.bool,
    mediumBlack14: PropTypes.bool,
    blackHeader: PropTypes.bool,
    mediumBoldBlack2: PropTypes.bool,
    lightGrey3: PropTypes.bool,
    mediumGrey2: PropTypes.bool,
    blackHeader1: PropTypes.bool,
    blackHeader600: PropTypes.bool,
    infoText: PropTypes.bool,
    mediumBoldWhite: PropTypes.bool,
    largeBoldGreen: PropTypes.bool,
    employeeAdded: PropTypes.bool,
    defaultmediumText: PropTypes.bool,
    lightPurpleMedium: PropTypes.bool,
    greysmallLabel: PropTypes.bool,
    smallBlackBold: PropTypes.bool,
    mediumOrangeRehire: PropTypes.bool,
    smallGreyFont: PropTypes.bool,
    mediumBoldWhite400: PropTypes.bool,
    offBoardHeadFont: PropTypes.bool,
    primaryFlagFont: PropTypes.bool,
    offBoardBodyFont: PropTypes.bool,
    offBoardBodyGreen: PropTypes.bool,
    offBoardHeadFontMedium: PropTypes.bool,
    smallBlack14: PropTypes.bool,
    veryLargeWhite: PropTypes.bool,
    largeWhite400: PropTypes.bool,
    mediumBlue400: PropTypes.bool,
    largeLabel700: PropTypes.bool,
    mediumWhite400: PropTypes.bool,
    mediumBoldBlack400: PropTypes.bool,
    largeHeaderText: PropTypes.bool,
    largeGrey14: PropTypes.bool,
    boldGrey14: PropTypes.bool,
    mediumGreyHeader: PropTypes.bool,
    blue14: PropTypes.bool,
    LargeBlack: PropTypes.bool,
    mediumBoldGrey: PropTypes.bool,
    mediumLabelColor: PropTypes.bool,
    mediumGreyHeader1: PropTypes.bool,
    grey16: PropTypes.bool,
    boldblack600: PropTypes.bool,
    boldblack22: PropTypes.bool,
    largeBlack700: PropTypes.bool,
    mediumGreybold: PropTypes.bool,
    black14: PropTypes.bool,
    smallLabelblack: PropTypes.bool,
    mediumBoldBlack700: PropTypes.bool,
    mediumGrey700: PropTypes.bool,
    grey700: PropTypes.bool,
    mediumGreen700: PropTypes.bool,
    smallLabelblack16: PropTypes.bool,
    largeblack40: PropTypes.bool,
    smallBlack400: PropTypes.bool,
    largeWhite18: PropTypes.bool,
    mediumBoldBlack600: PropTypes.bool,
    dashboardblack14: PropTypes.bool,
    dashboardblack12: PropTypes.bool,
    analyticsViewAll: PropTypes.bool,
    mediumBlue600: PropTypes.bool,
    largeBlack22: PropTypes.bool,
    mediumBlack700: PropTypes.bool,
    smallGrey10: PropTypes.bool,
    black12: PropTypes.bool,
    violet600: PropTypes.bool,
    grey18px: PropTypes.bool,
    black18px: PropTypes.bool,
    headerText14: PropTypes.bool,
    black400: PropTypes.bool,
    largeLabel400: PropTypes.bool,
    mediumGreyHeader600: PropTypes.bool,
    notificationread: PropTypes.bool,
    notificationreadSmall: PropTypes.bool,
    largeBlack28: PropTypes.bool,
    veryLargeBlue: PropTypes.bool,
    smallnavyBlue: PropTypes.bool,
    blackHeader2: PropTypes.bool,
    largeWhite20: PropTypes.bool,
    greyDark22: PropTypes.bool,
    greensmall: PropTypes.bool,
    smallGrey400: PropTypes.bool,
    RegularBlack1: PropTypes.bool,
    largeWhite1: PropTypes.bool,
    largeOrange16: PropTypes.bool,
    mediumBlack1: PropTypes.bool,
    mediumBlack600: PropTypes.bool,
    tourText: PropTypes.bool,
    greyLabel14: PropTypes.bool,
    blueText16: PropTypes.bool,
    largeBlack20: PropTypes.bool,
    greyColor16: PropTypes.bool,
    mediumBoldWhite600: PropTypes.bool,
    largeWhite16: PropTypes.bool,
    largeOrange22: PropTypes.bool,
    lightGrey10: PropTypes.bool,
    smallblackLabel10: PropTypes.bool,
    offBoardingText: PropTypes.bool,
    mediumGrey3: PropTypes.bool,
    filterText: PropTypes.bool,
    mediumInactiveLabel: PropTypes.bool,
    largeWhiteActive400: PropTypes.bool,
}

