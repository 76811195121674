import { Box, Divider, Grid, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../components/customText/Text'
import { formatNumberWithCommas, capitalizeAndAddSpace, BlackToolTip } from '../../../../utils/utils';
import greenInfo from '../../../../assets/svg/greenInfo.svg'

function EarnedAmountPopup(props) {
  const { viewClientEarnedAmount } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    placement_id: "",
    client_id: "",
    client_name: "",
    employee_id: "",
    earned_amount: "",
    regular_rate: "",
    regular_amount: "",
    ot_pay_rate: "",
    ot_amount: "",
    total_hours_logged: "",
    total_ot_hours_logged: ""
  })

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setData(viewClientEarnedAmount)
    }, 500);
    // eslint-disable-next-line
  }, [])


  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12} p={3}>
          <Text boldblack22>Earned Amount Breakdown</Text>
        </Grid>
      </Grid>
      <Divider />
      {
        data.pay_type === 2 ?
        <Grid container p={3} spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box width={"100%"} display={"flex"} alignItems={"center"} gap="10px" sx={{background:"#E2FBEB",borderRadius:"8px",padding:"10px 16px"}}>
            <img src={greenInfo} alt="greenInfo" />
            <Text smallBlack sx={{fontWeight: `${400} !important`}}>This employee is categorized as a salaried employee. The earned amount displayed corresponds to the fixed salary configured during the employee's placement setup. You can edit this amount or enter a custom value by clicking on the “Pay Amount” field.</Text>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Regular Earnings</Text></Grid>

        <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Client Name</Text></Grid>
        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
          <Text blackHeader600>
            {data.client_name && data.client_name.length > 16 ?
              <BlackToolTip title={capitalizeAndAddSpace(data.client_name)} placement="top" arrow>
                {capitalizeAndAddSpace(data.client_name).slice(0, 16) + (capitalizeAndAddSpace(data.client_name.length > 16 ? "..." : ""))}
              </BlackToolTip>
              : capitalizeAndAddSpace(data.client_name)
            }
          </Text>}
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Earned Amount</Text></Grid>
        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.earned_amount ? `$ ${data?.earned_amount}` : '-'}</Text>} </Grid>

      </Grid>
          :
          <Grid container p={3} spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Regular Earnings</Text></Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Client Name</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
              <Text blackHeader600>
                {data.client_name && data.client_name.length > 16 ?
                  <BlackToolTip title={capitalizeAndAddSpace(data.client_name)} placement="top" arrow>
                    {capitalizeAndAddSpace(data.client_name).slice(0, 16) + (capitalizeAndAddSpace(data.client_name.length > 16 ? "..." : ""))}
                  </BlackToolTip>
                  : capitalizeAndAddSpace(data.client_name)
                }
              </Text>}
            </Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Hours</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.total_hours_logged ? data?.total_hours_logged : '-'}</Text>}</Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Pay Rate</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600> {data?.regular_rate ? `$ ${data?.regular_rate}` : '-'}</Text>}</Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Pay</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.regular_amount ? `$ ${formatNumberWithCommas(data?.regular_amount)}` : '-'}</Text>}</Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Overtime</Text></Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Hours</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.total_ot_hours_logged ? data?.total_ot_hours_logged : '-'}</Text>} </Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Pay Rate</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.ot_pay_rate ? `$ ${data?.ot_pay_rate}` : '-'}</Text>} </Grid>

            <Grid item lg={5} md={5} sm={5} xs={5}><Text blackHeader600>Pay</Text></Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text blackHeader600>{data?.ot_amount ? `$ ${data?.ot_amount}` : '-'}</Text>} </Grid>

          </Grid>
      }

      <Box sx={{ background: "#D1E1FF", paddingTop: "2px" }}>
        <Grid container p={3} spacing={3}>
          <Grid item lg={5} md={5} sm={5} xs={5}><Text boldblack22>Total Earnings</Text></Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
          <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text boldblack22>{data?.earned_amount ? `$ ${formatNumberWithCommas(data?.earned_amount)}` : '-'}</Text>}</Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default EarnedAmountPopup
