import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material"


const ViewInvoiceStyles = makeStyles(() => ({
    mainContainer: {
        left: "60px",
        width: "calc(100% - 60px)",
        position: "relative",
        transition: "all .3s ease",
        backgroundColor: "rgba(253, 253, 253, 1)",
        [useTheme().breakpoints.down('sm')]: {
            // width: '150% !important'
        },
        "@media (min-width: 100px) and (max-width: 900px)": {
            left: "0px",
            width:"100%"
        },
    },breadcrumbsLink: {
        fontFamily: "Quicksand !important",
        color: "#849199 !important",
        fontSize: "12px !important",
        fontWeight: '700 !important',
        textDecoration: "none !important",
        cursor: "pointer !important",
    },

    //record payment styles

    recordDetails:{
        minHeight:'144px',
        borderRadius:'12px',
        backgroundColor:'#FAFAFA',
        padding:'12px',
        display:'flex',
        flexDirection:'column',
        width:'100%',

    },

    dropZone: {
        background: '#F0F7FF',
        border: `1px dashed #0C75EB !important`,
        padding: '20px',
        borderRadius: '12px'
    },

    






}))

export default ViewInvoiceStyles