import React, { useState } from 'react'
import { Box, Grid, Divider, Tab, } from '@mui/material';
import Text from '../../../components/customText/Text';
import timelineIcon from '../../../assets/svg/timelineIcon.svg';
import Nodata from '../../../assets/svg/nodataActivity.svg';
import Loader from '../../../assets/gif/japfuLoader.gif';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BlackToolTip } from '../../../utils/utils';
import DashboardStyles from '../../../views/admin/DasboardStyles';
const InvoiceActivityLog = (props) => {
    const { activityData, paymentData, value, handleChange } = props;
    const [expand, setExpand] = useState('')// eslint-disable-next-line
    const classes = DashboardStyles();// eslint-disable-next-line
    const renderHistoryItem = (item, index) => (
        <>
            {

                <Grid container lg={12} md={12} sm={12} xs={12} alignItems={"center"}>
                    <Grid item lg={2.75} md={2.75} xs={2.75}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box p={1} pt={1.9}>
                                <Text smallBlue sx={{ fontWeight: '600 !important' }}>{item.created_at}</Text>
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box pl={1}>
                                <Text verySmallBlack sx={{ color: "#737373 !important" }}>{item.action_by}</Text>
                            </Box>
                        </Grid>
                    </Grid >
                    <Grid item lg={1} md={1} xs={1}>
                        <Box p={1}>
                            <img src={timelineIcon} alt='ic' className={classes.timelineIcon} />
                        </Box>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={7}>
                        <Grid item>
                            <Box p={1}>
                                <Text mediumBlack>{item.activity_name}</Text>
                            </Box>
                        </Grid>
                        {/* {
                                expand !== index &&
                                <Grid item>
                                    <Box pl={1}>
                                        <Text greysmallLabel>Label Name is updated from from value to To value</Text>
                                    </Box>
                                </Grid>
                            } */}

                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <Box p={1}>
                            {expand === index ?
                                <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} /> :
                                <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} />
                            }
                        </Box>
                    </Grid>
                    {
                        expand === index ?
                            <Grid item container pb={2}>
                                <Grid item lg={3.43} md={3.43} sm={3.43} xs={3.43}></Grid>
                                <Grid item class="tree" >
                                    <ul>
                                        {
                                            item.change_log.map((i) => (
                                                <li>
                                                    <Text greysmallLabel>
                                                        {i.length > 70 ?

                                                            <BlackToolTip arrow placement='top' title={
                                                                <Box p={1}>{i}</Box>
                                                            }>
                                                                {i.slice(0, 70)} ...
                                                            </BlackToolTip>

                                                            : i}
                                                    </Text>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                            :

                            ((activityData.length - 1 || paymentData.length - 1  ) == index) ? '' :
                                <Grid item container pb={2}>
                                    <Grid item lg={3.15} md={3.15} sm={3.15} xs={3.15}></Grid>
                                    <Grid item mt={-0.5} md={-0.5} xs={-0.5}>
                                        <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                    </Grid>
                                </Grid>
                    }
                </Grid>
            }
        </>
    );

    return (
        <Grid container lg={12} md={12} xs={12} sx={{
            width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "50vw" },
        }} p={4}>
            <Grid item lg={12} md={12} xs={12}>
                <Text largeBlack> Activities</Text>
            </Grid>
            <Grid item container lg={12} md={12} xs={12}>
                {false ? (
                    <Box
                        width={"100%"}
                        height={"76vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <img src={Loader} alt="Loader" width={"100px"} height={"100px"} />
                    </Box>
                ) : (
                    <Box sx={{ width: "100%" }} pt={1}>
                        <TabContext value={value}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    width: "100%",
                                    overflowX: "auto", // Allows scrolling for smaller screens
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <TabList
                                    onChange={handleChange}
                                    variant="scrollable" // Makes tabs scrollable on small screens
                                    scrollButtons="auto"
                                >
                                    <Tab
                                        label="All Activities"
                                        value=""
                                        sx={{
                                            minWidth: "auto", // Prevents tabs from becoming too wide
                                            color: value === "" ? "#0C75EB !important" : "#000000 !important",
                                            fontSize: { xs: "11px", sm: "12px", md: "13px" }, // Responsive font size
                                            textTransform: "capitalize",
                                            fontWeight: `${600} !important`,
                                        }}
                                    />
                                    <Tab
                                        label="Payments"
                                        value="2"
                                        sx={{
                                            minWidth: "auto",
                                            color: value == "2" ? "#0C75EB !important" : "#000000 !important",
                                            fontSize: { xs: "11px", sm: "12px", md: "13px" },
                                            textTransform: "capitalize",
                                            fontWeight: `${600} !important`,
                                        }}
                                    />
                                </TabList>
                            </Box>
                            <TabPanel
                                value={value}
                                sx={{
                                    padding: "0px",

                                }}
                            >
                                <Box
                                    height={"73vh"}
                                    overflow={"scroll"}
                                    sx={{
                                        width: "100% !important",
                                        "&::-webkit-scrollbar": { display: "none" },
                                        scrollbarWidth: "none",
                                    }}
                                >
                                    {(value == "" ? activityData : paymentData)?.length > 0 ? (
                                        (value == "" ? activityData : paymentData).map(renderHistoryItem)
                                    ) : (
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            container
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            textAlign={"center"}
                                        >
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                xs={12}
                                                pl={5}
                                                container
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                            >
                                                <img src={Nodata} alt="nodata" />
                                            </Grid>
                                            <Grid item lg={12} md={12} xs={12} pl={5}>
                                                <Text popupHead1>No Logs Found Yet!</Text>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}
export default InvoiceActivityLog