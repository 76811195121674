import { Box, Divider, Grid, Tooltip, tooltipClasses } from '@mui/material';
import React from 'react'
import ToggleSwitch from '../../../../components/toggle/CustomToggle';
import Text from '../../../../components/customText/Text';
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import ClientStyles from '../ClientStyles';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));
function Integrations(props) {
    const { IntegrationsState, setIntegrationsState, connectedAppsList, type } = props;
    const navigate = useNavigate();
    const classes = ClientStyles();

    const handleChecklist = (e) => {
        IntegrationsState[e.target.name] = e.target.checked
        setIntegrationsState({ ...IntegrationsState })
    }

    return (
        <Grid container>
            <Grid item xs={12} p='0px 8px'>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' margin='10px 0px 20px 0px'>
                    <Text mediumBlack1>Send {type === 'invoice' ? `invoice` : `bills`} to Quickbooks</Text>
                    {
                        connectedAppsList.isQuickBookIntegrated ?
                            <ToggleSwitch name='send_quick_books_invoice' isActive={IntegrationsState.send_quick_books_invoice} switchChange={(e) => handleChecklist(e, 'QB')} /> :
                            <HtmlTooltip
                                placement="top"
                                arrow
                                sx={{ fontSize: '14px !important' }}
                                title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                            >
                                <button className={classes.bluebordersmall} type="button" onClick={() => navigate('/app-integrations')}>Set up</button>
                            </HtmlTooltip>}
                </Box>
                <Divider sx={{ margin: '8px 0px', border: '1px solid #EAECF0' }} />
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' margin='20px 0px 5px 0px'>
                    <Text mediumBlack1>Send {type === 'invoice' ? `invoice` : `bills`} to Zohobooks</Text>
                    {
                        connectedAppsList.isZohoIntegrated ?
                            <ToggleSwitch name='send_zoho_books_invoice' isActive={IntegrationsState.send_zoho_books_invoice} switchChange={(e) => handleChecklist(e, 'zoho')} />
                            :
                            <HtmlTooltip
                                placement="top"
                                arrow
                                sx={{ fontSize: '14px !important' }}
                                title={<Text smallWhite>  Install the app to enable this feature.</Text>}
                            >
                                <button className={classes.bluebordersmall} type="button" onClick={() => navigate('/app-integrations')}>Set up</button>
                            </HtmlTooltip>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default Integrations