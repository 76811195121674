


import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, SwipeableDrawer, List, ListItem, ListItemText, Checkbox, FormControlLabel, Chip, Backdrop, Slider, Badge, Skeleton, Hidden, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import Text from '../../../../../components/customText/Text'
import Search from "../../../../../assets/svg/search1.svg";
import FilterListIcon from '@mui/icons-material/FilterList';
import cloud from '../../../../../assets/svg/download-cloud-blue.svg'
import Button from '../../../../../components/customButton/Button';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg'
import Date from "../../../../../components/datePicker/Date";
import Input from "../../../../../components/input/Input";
import { onNumberWithTwoDecimalOnlyChangeWithNegative, validate_emptyField } from "../../../../../components/Validation";
import LocalStorage from "../../../../../utils/LocalStorage";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/closeIcon.svg';
import { addErrorMsg, addLoader, BlackToolTip, dateFormat, downloadExcel, NoDataFound, NoPermission, removeLoader, getCurrencySymbol } from "../../../../../utils/utils";
import moment from "moment";

import nofilterplacement from '../../../../../assets/svg/nofilterplacement.svg'
import { Loader } from "rsuite";
import PlacementDashboardStyles from "../../../placements/newDashboard/PlacementDashboardStyles";
import CommonApi from "../../../../../apis/CommonApi";
import { ClearIcon } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";


function InvoiceHistory(props) {
    const { id } = props
    const classes = PlacementDashboardStyles();

    const navigate = useNavigate();
    // const classss = DashboardStyles();
    const [balanceData, setBalanceData] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [client, setClient] = useState(null);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        status: [],
        invoice_date_from: '',
        invoice_date_to: '',
        total_amount_from: '',
        total_amount_to: '',
        balance_amount_from: '',
        balance_amount_to: '',
    })
    const [filterAmount, setFilterAmount] = useState({
        total_amount: {
            max: 0,
            min: 0
        },
        balance_amount: {
            max: 0,
            min: 0
        }
    })
    const [filterState, setFilterState] = useState({
        status: [],
        invoice_date_from: '',
        invoice_date_to: '',
        total_amount_from: '',
        total_amount_to: '',
        balance_amount_from: '',
        balance_amount_to: '',
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        invoice_date_from: "",
        invoice_date_to: "",
        raised_amount_from: "",
        raised_amount_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    // eslint-disable-next-line
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const employmentType = [
        {
            id: 'All',
            value: 'All'
        },
        {
            id: 'Pending Approval',
            value: 'Pending Approval',
        },
        {
            id: 'Paid',
            value: 'Paid'
        },
        {
            id: 'Partially Paid',
            value: 'Partially Paid',
        },
        {
            id: 'Overdue',
            value: 'Overdue'
        },
        {
            id: 'Due',
            value: 'Due',
        },
        {
            id: 'Write Off',
            value: 'Write Off'
        },
        {
            id: 'Drafted',
            value: 'Drafted',
        },
        {
            id: 'Void',
            value: 'Void',
        },
        {
            id: 'Rejected',
            value: 'Rejected',
        }
    ];


    useEffect(() => {
        clientList(filterData)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        invoiceStatus();
        // eslint-disable-next-line
    }, [])

    const dataExport = () => {
        addLoader(true);
        filterData.request_id = LocalStorage.uid()

        CommonApi.InvoiceClientHistoryExport(filterData).then((response) => {
            removeLoader();
            console.log(response?.data instanceof Blob)
            if (response?.data instanceof Blob) {
                const blob = response.data;
                let filename = `InvoiceHistory.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }
        })
    }


    const invoiceStatus = () => {
        let data = {
            request_id: LocalStorage.uid(),
            company_id: id
        }
        CommonApi.InvoiceHistroyDetails(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setBalanceData(res.data.data);
            }
        })
    }

    const clientList = (filter) => {
        filter.company_id = id
        CommonApi.InvoiceClientHistoryListing(filter).then((response) => {
            removeLoader();

            if (response.data.statusCode == 1003) {
                setLoading(false);
                setClient(response.data.data); setLoading(false);
                setPagination({ total: response.data.pagination.total });
                setFilterAmount(response.data.filters)
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        })

    }

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
        clientList(filterData);

    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        clientList(filterData);

    }

    // const handleDeleteChip = (id, name) => {
    //     if (name === "invoice_date" || name === "total_amount" || name === "balance_amount") {
    //         const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

    //         // Update the start_date or end_date fields
    //         updatedFilterState[name + "_from"] = "";
    //         updatedFilterState[name + "_to"] = "";

    //         // Update the state with the modified filterState object
    //         setFilterState(updatedFilterState);
    //     } else {
    //         const newFilterState = { ...filterState };
    //         const updatedArray = newFilterState[name].filter(item => item !== id);
    //         newFilterState[name] = updatedArray;
    //         setFilterState(newFilterState);
    //     }
    // };

    const handleDeleteChip = (id, name) => {
        if (id === "All" && name === "status") {
            // If "All" is deleted, remove all status chips
            const updatedFilterState = { ...filterState };
            updatedFilterState[name] = []; // Clear all status chips
            setFilterState(updatedFilterState);
        } else {
            if (name === "invoice_date" || name === "total_amount" || name === "balance_amount") {
                const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

                // Update the start_date or end_date fields
                updatedFilterState[name + "_from"] = "";
                updatedFilterState[name + "_to"] = "";

                // Update the state with the modified filterState object
                setFilterState(updatedFilterState);
            } else {
                const newFilterState = { ...filterState };
                const updatedArray = newFilterState[name].filter(item => item !== id);
                newFilterState[name] = updatedArray;
                setFilterState(newFilterState);
            }
        }
    };




    const handleChangeCheckBox = (e) => {
        const { name, value } = e.target;
        let updatedArray = [...filterState[name]]; // Create a new array
        let finalValue = value;

        if (value === "All") {
            // If the "All" checkbox is clicked, either select or deselect all
            if (updatedArray.includes("All")) {
                // If "All" is checked, uncheck all checkboxes
                updatedArray = [];
            } else {
                // If "All" is unchecked, select all checkboxes
                updatedArray = employmentType.map(item => item.id);
            }
        } else {
            // Handle individual checkboxes
            if (e.target.checked) {
                updatedArray.push(finalValue);
                if (updatedArray.length === (employmentType.length - 1)) {
                    updatedArray.push("All"); // Mark "All" as checked
                }
            } else {
                const index = updatedArray.findIndex(item => item === finalValue);
                if (index !== -1) {
                    updatedArray.splice(index, 1);
                }
                const allIndex = updatedArray.indexOf("All");
                if (allIndex !== -1) {
                    updatedArray.splice(allIndex, 1); // Uncheck "All"
                }
            }
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    };

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        setFilterState(prevState => ({
            ...prevState,
            status: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            invoice_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            invoice_date_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            total_amount_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_amount_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_amount_to: '' // Update the state with the new array
        }));
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const handleApplyFilters = () => {
        if (filterState.invoice_date_from != "" && filterState.invoice_date_to == "") {
            datesError.invoice_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.invoice_date_from == "" && filterState.invoice_date_to != "") {
            datesError.invoice_date_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") {
            if (moment(filterState.invoice_date_from, dateFormat()).isSameOrBefore(moment(filterState.invoice_date_to, dateFormat()))) {
                filterData.invoice_date_from = filterState.invoice_date_from;
                filterData.invoice_date_to = filterState.invoice_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.invoice_date_from = '';
            filterData.invoice_date_to = '';
        }
        if (filterState.total_amount_from != "" && filterState.total_amount_to == "") {
            datesError.total_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.total_amount_from == "" && filterState.total_amount_from != 0) && filterState.total_amount_to != "") {
            datesError.total_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.total_amount_from !== "" && filterState.total_amount_to !== "") {
            if (filterState.total_amount_from < filterState.total_amount_to) {
                filterData.total_amount_from = filterState.total_amount_from;
                filterData.total_amount_to = filterState.total_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.total_amount_from = '';
            filterData.total_amount_to = '';
        }
        if (filterState.balance_amount_from != "" && filterState.balance_amount_to == "") {
            datesError.balance_amount_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.balance_amount_from == "" && filterState.balance_amount_from != 0) && filterState.balance_amount_to != "") {
            datesError.balance_amount_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") {
            if (filterState.balance_amount_from < filterState.balance_amount_to) {
                filterData.balance_amount_from = filterState.balance_amount_from;
                filterData.balance_amount_to = filterState.balance_amount_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.balance_amount_from = '';
            filterData.balance_amount_to = '';
        }
        filterData.dateChange = [filterState.invoice_date_from, filterState.invoice_date_to]
        filterData.status = filterState.status;
        filterData.total_amount = [filterState.total_amount_from, filterState.total_amount_to]
        filterData.balance_amount = [filterState.balance_amount_from, filterState.balance_amount_to]
        if (filterData.total_amount.length === 2 && filterData.total_amount[0] === "" && filterData.total_amount[1] === "") {
            filterData.total_amount = [0, 0]; // Change to an empty array
        }
        if (filterData.balance_amount.length === 2 && filterData.balance_amount[0] === "" && filterData.balance_amount[1] === "") {
            filterData.balance_amount = [0, 0]; // Change to an empty array
        }
        setFilterData({ ...filterData });
        handleFilterDrawerClose()
        addLoader(true)
        clientList({ ...filterData, limit: 10, page: 1 });
    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        clientList(filterData);

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") {
            filterState.invoice_date_from = filterData.invoice_date_from;
            filterState.invoice_date_to = filterData.invoice_date_to;
            setSelectedFilter(1);
        } else {
            filterState.invoice_date_from = '';
            filterState.invoice_date_to = '';
        }
        if (filterData.total_amount_from !== "" && filterData.total_amount_to !== "") {
            filterState.total_amount_from = filterData.total_amount_from;
            filterState.total_amount_to = filterData.total_amount_to;
            setSelectedFilter(2);
        } else {
            filterState.total_amount_from = '';
            filterState.total_amount_to = '';
        }
        if (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") {
            filterState.balance_amount_from = filterData.balance_amount_from;
            filterState.balance_amount_to = filterData.balance_amount_to;
            setSelectedFilter(3);
        } else {
            filterState.balance_amount_from = '';
            filterState.balance_amount_to = '';
        }
        if (filterData.status.length > 0) {
            setSelectedFilter(4);
        }
        filterState.status = filterData.status;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    // setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleSliderBalanceAmount = (event, newValue) => {
        filterState.balance_amount_from = newValue[0]
        filterState.balance_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleInvoiceAmount = (event, newValue) => {
        filterState.total_amount_from = newValue[0]
        filterState.total_amount_to = newValue[1]
        setFilterState({ ...filterState })
    };


    const handleInputChange = (index, value, name, type) => {
        let numericValue;

        const baseName = name ? name.replace(/_from|_to$/, '') : '';
        const limitValue = type === "min"
            ? filterAmount[baseName].min
            : filterAmount[baseName].max;

        const maxValue = filterAmount[baseName].max;

        if (value === '-' || value === '' || value.endsWith('.')) {
            numericValue = value;
        } else if (!isNaN(Number(value))) {
            numericValue = Number(value);

            if (type === "min" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "min" && numericValue < limitValue) {
                numericValue = Math.max(Math.floor(numericValue / 10), limitValue); // Ensure it stays above minValue
            }
            if (type === "max" && numericValue > maxValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), maxValue); // Ensure it stays above maxValue
            }
            else if (type === "max" && numericValue > limitValue) {
                numericValue = Math.min(Math.floor(numericValue / 10), limitValue); // Ensure it stays below maxValue
            }
        } else {
            // Fallback to empty string for invalid input
            numericValue = '';
        }

        filterState[name] = numericValue;
        setFilterState({ ...filterState });

    };


    const handleFilterDrawerClose = () => {
        filterState.status = []
        filterState.invoice_date_from = '';
        filterState.invoice_date_to = '';
        filterState.balance_amount_from = '';
        filterState.balance_amount_to = '';
        filterState.total_amount_from = '';
        filterState.total_amount_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const dateChange = (e, keyName) => {
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    return (
        <Grid container md={12} sm={12} xs={12} sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: { xs: '0px', sm: '0px', md: '30px' } }}>
            <Grid md={12} sm={12} xs={12} columnGap={4}>

                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' pb={3}>
                    <Text largeBlue> Invoice History </Text>
                </Box>
                <Grid item container lg={12} md={12} sm={12} xs={12} columnSpacing={4} rowSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{ pt: `0px !important`, pl: `0px !important` }}>

                    <Grid item lg={4} md={6} sm={6} xs={12}  >
                        <Box alignItems='center' display={"flex"} sx={{ 'height': '110px' }} className={classes.bluebg}>
                            <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2} >
                                <Grid item lg={12} md={10} xs={9} rowGap={5}>
                                    <Text mediumBoldBlack sx={{ "justifyContent": "flex-start", "paddingBottom": "20px" }} >Invoiced Amount</Text>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                                        <Text boldblack22>{getCurrencySymbol()}</Text>
                                        <Text boldblack22>{balanceData?.total_amount_sum ? balanceData?.total_amount_sum : '0.00'}</Text>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}  >
                        <Box alignItems='center' display={"flex"} sx={{ 'height': '110px' }} className={classes.yellowbg}>
                            <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                <Grid item lg={12} md={10} xs={9} rowGap={5}>
                                    <Text mediumBoldBlack sx={{ "justifyContent": "flex-start", "paddingBottom": "20px" }} >Payment Received</Text>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                                        <Text boldblack22>{getCurrencySymbol()}</Text>
                                        <Text boldblack22>{balanceData?.payment_amount_sum ? balanceData?.payment_amount_sum : '0.00'}</Text>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}  >
                        <Box alignItems='center' display={"flex"} sx={{ 'height': '110px' }} className={classes.purplebg}>
                            <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={2}>
                                <Grid item lg={12} md={10} xs={9} rowGap={5}>
                                    <Text mediumBoldBlack sx={{ "justifyContent": "flex-start", "paddingBottom": "20px" }} >Balance Due</Text>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                                        <Text boldblack22>{getCurrencySymbol()}</Text>
                                        <Text boldblack22>{balanceData?.balance_amount_sum ? balanceData?.balance_amount_sum : '0.00'}</Text>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>
                <Grid container lg={12} md={12} sm={12} xs={12} py={3} rowSpacing={{ xs: 2, sm: 2, md: 0 }} justifyContent="space-between" alignItems="center">

                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box
                            style={{
                                height: "44px",
                                border: "1.5px solid #E2E5E6",
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 15px", // Added padding for better internal spacing
                            }}
                        >
                            <input
                                className={classes.EmployeesSearchInput}
                                name="text"
                                onChange={handleSearch}
                                placeholder="Search by Invoice ID"
                                value={filterData.search}
                                style={{
                                    border: "none",
                                    outline: "none",
                                    flexGrow: 1, // Ensures the input takes the available space
                                    padding: "0 10px", // Space between the text and border
                                }}
                            />
                            <Box
                            //  sx={{ paddingRight: "15px !important" }}
                            >
                                {
                                    filterData.search !== "" ?
                                        <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                        :
                                        <img src={Search} alt="Search" />
                                }
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12} display="flex" justifyContent={{ xs: "start", sm: "flex-end" }} alignItems="center" gap={4}>
                        <Grid item>
                            <button title="Filter"
                                type="button"
                                className={classes.borderButton}
                                onClick={() => { handleOpenDrawer() }}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Badge badgeContent={filterData?.status?.length + ((filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") && 1) + ((filterData.total_amount_from !== "" && filterData.total_amount_to !== "") && 1) + ((filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") && 1)} color="error">
                                    <FilterListIcon sx={{ color: (filterData?.status?.length > 0 || (filterData.invoice_date_from !== "" && filterData.invoice_date_to !== "") || (filterData.balance_amount_from !== "" && filterData.balance_amount_to !== "") || (filterData.total_amount_from !== "" && filterData.total_amount_to !== "")) ? "#2BEA2B" : "" }} />
                                </Badge>

                            </button>
                        </Grid>

                        <Grid item >
                            <button title="Export" type="button" className={classes.borderButton} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={dataExport}>
                                <img src={cloud} alt="Userplus" />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden smDown>
                    <Grid item container lg={12} md={12} sm={12} xs={12} p={2} sx={{ overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' } }} >
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} alignItems="center">

                            <Grid item lg={2} md={2} sm={2} xs={2} container justifyContent="flex-start">
                                <Text largeLabel>Invoice ID</Text>
                            </Grid>
                            <Grid item lg={2} md={1.5} sm={2} xs={2} container>
                                <Text largeLabel>Hours</Text>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} container>
                                <Text largeLabel>Invoice Date</Text>
                            </Grid>
                            <Grid item lg={1} md={1.5} sm={1.5} xs={2} container justifyContent="flex-end">
                                <Text largeLabel>Status</Text>
                            </Grid>
                            <Grid item lg={3} md={2.5} sm={2.25} xs={2} container justifyContent="flex-end">
                                <Text largeLabel>Invoice Amount </Text>
                            </Grid>
                            <Grid item lg={2} md={2.5} sm={2.25} xs={2} container justifyContent="flex-end">
                                <Text largeLabel>Balance Amount</Text>
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%', margin: '16px 0px 0px 0px', borderColor: '#EAECF0' }} />
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item container lg={12} md={12} sm={12} xs={12} pr={'4px'} pl={1}
                        sx={{
                            maxHeight: '31.5vh',
                            overflowY: 'auto',
                            overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' }, // Apply horizontal scrolling on xs and sm
                            "&::-webkit-scrollbar": {
                                display: "scroll", // Hide scrollbar for WebKit browsers
                            },
                        }}
                        ref={scrollBox}
                        onScroll={handleScroll}
                    >
                        {loading ? (
                            [1, 2, 3, 4].map((item) => (
                                <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center" key={item} mt={item !== 1 ? 2 : 0} >
                                    <Grid item lg={2} md={2} sm={1} xs={1} container justifyContent="flex-start">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                    </Grid>
                                    <Grid item lg={2} md={4} sm={5} xs={2} container>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "6rem" }} />
                                    </Grid>
                                    <Grid item lg={1.5} md={4} sm={3} xs={3} container>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "6rem" }} />
                                    </Grid>
                                    <Grid item lg={2.5} md={2} sm={3} xs={2} container justifyContent="center">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "6rem" }} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={3} xs={2} container justifyContent="flex-end">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                    </Grid>
                                </Grid>
                            ))
                        ) : client && client.length > 0 ? (
                            client.map((invoice, index) => (
                                <Grid container item lg={12} md={12} sm={12} xs={12} pl={1} spacing={2} alignItems="center" key={index} mt={index !== 0 ? 2 : 0} >
                                    <Grid item lg={2} md={2} sm={2} xs={2} container justifyContent="flex-start">
                                        <Box minWidth='70px' textAlign='end' onClick={() => navigate(`/addInvoice`, { state: { invoiceId: invoice.id, companiesId: id } })}>
                                            <Text
                                                analyticsViewAll
                                                sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}
                                            >
                                                {invoice.reference_id}
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={2} md={1.5} sm={2} xs={2} container>
                                        <Text largeLabel>{invoice.total_hours}</Text>
                                    </Grid>
                                    <Grid item lg={1.5} md={2} sm={2} xs={2} container>
                                        <Text largeLabel>{invoice.invoice_date}</Text>
                                    </Grid>
                                    <Grid item lg={2.5} md={2} sm={1.5} xs={2} container justifyContent="center">
                                        <Box
                                            className={classes.activeBox}
                                            style={{ backgroundColor: invoice.color, borderRadius: '16px', padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: "center" }}
                                        >
                                            <Text mediumBoldWhite400>{invoice.invoice_status}</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2.25} container justifyContent="flex-end">
                                        <Text largeLabel>{getCurrencySymbol()}{invoice.total_amount}</Text>
                                    </Grid>
                                    <Grid item lg={2} md={2.5} sm={2} xs={2.25} container justifyContent="flex-end">
                                        <Text largeLabel>{getCurrencySymbol()}{invoice.balance_amount}</Text>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Grid container item justifyContent="center" height={'31.5vh'} >
                                {NoDataFound("We couldn't find any matching results. Please refine your search criteria and try again for more accurate results.", 'No Records Found')}
                            </Grid>
                        )}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid item container lg={12} md={12} sm={12} xs={12} p={2} >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Text largeLabel>Invoice ID</Text></TableCell>
                                        <TableCell align="right"><Text largeLabel>Hours</Text></TableCell>
                                        <TableCell align="right"><Text largeLabel>Invoice Date</Text></TableCell>
                                        <TableCell align="center"><Text largeLabel>Status</Text></TableCell>
                                        <TableCell align="right"><Text largeLabel>Invoice Amount</Text></TableCell>
                                        <TableCell align="right"><Text largeLabel>Balance Amount</Text></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {client?.map((row) => (
                                        <TableRow
                                            key={row.reference_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ border: 'none' }} >
                                                <Box minWidth='70px' textAlign='end' onClick={() => navigate(`/invoiceIndex`, { state: { invoiceId: row.id, companiesId: id } })}>
                                                    <Text
                                                        analyticsViewAll
                                                        sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }}
                                                    >
                                                        {row.reference_id}
                                                    </Text>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" sx={{ border: 'none' }}><Text largeLabel>{row.total_hours}</Text></TableCell>
                                            <TableCell align="right" sx={{ border: 'none' }}><Text largeLabel>{row.invoice_date}</Text></TableCell>
                                            <TableCell align="right" sx={{ border: 'none' }}>
                                                <Box
                                                    className={classes.activeBox}
                                                    style={{ backgroundColor: row.color, borderRadius: '16px', padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: "center" }}
                                                >
                                                    <Text mediumBoldWhite400>{row.invoice_status}</Text>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" sx={{ border: 'none' }}><Text largeLabel>{getCurrencySymbol()}{row.total_amount}</Text></TableCell>
                                            <TableCell align="right" sx={{ border: 'none' }}><Text largeLabel>{getCurrencySymbol()}{row.balance_amount}</Text></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Hidden>


            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
            <SwipeableDrawer
                anchor={'right'}
                open={drawer}
                onClose={() => handleFilterDrawerClose()}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                        width: { xs: '100% !important', sm: '65% !important', md: '55% !important' },
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                {
                    drawer === "filter" ?
                        <Box>
                            <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                <Text headerBlack>Filters</Text>
                            </Box>

                            <Box display={'flex'} height={'8vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>

                                    {
                                        filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "" &&
                                        <>
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={1}
                                                icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{filterState.invoice_date_from} - {filterState.invoice_date_to}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip('', "invoice_date")}
                                                deleteIcon={<CloseIcon />} />
                                        </>
                                    }
                                    {
                                        filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "" &&
                                        <>
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={1}
                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{filterState.balance_amount_from} - {filterState.balance_amount_to}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip('', "balance_amount")}
                                                deleteIcon={<CloseIcon />} />
                                        </>
                                    }
                                    {
                                        filterState.total_amount_from !== "" && filterState.total_amount_to !== "" &&
                                        <>
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={1}
                                                icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{filterState.total_amount_from} - {filterState.total_amount_to}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip('', "total_amount")}
                                                deleteIcon={<CloseIcon />} />
                                        </>
                                    }

                                    {
                                        employmentType.map((item, key) => (
                                            filterState.status.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={key}
                                                icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack nowrap>{
                                                    item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                        <BlackToolTip title={item.value} placement="right" arrow>
                                                            {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        item.value
                                                }</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "status")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                </Box>
                                {
                                    ((filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") || (filterState.total_amount_from !== "" && filterState.total_amount_to !== "") || filterState.status.length > 0 || (filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "")) ?
                                        <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                        :
                                        ''
                                }
                            </Box>

                            <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'70vh'} >
                                <Box display={'flex'} flexDirection={'column'} height={'100%'} sx={{ width: { xs: '40%', sm: "43%" } }} borderRight={'1px solid #EAECF0'} p={0} >
                                    <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                        <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={(filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.invoice_date_from !== "" && filterState.invoice_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Invoice Date</Text> : <Text ml={3} mediumBlack>Invoice Date</Text>} />
                                        </ListItem>
                                        <Divider />

                                        <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={filterState.status.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.status.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1> Status</Text> : <Text ml={3} mediumBlack> Status</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.total_amount_from !== "" && filterState.total_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.total_amount_from !== "" && filterState.total_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1> Invoice Amount</Text> : <Text ml={3} mediumBlack> Invoice Amount</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{(filterState.balance_amount_from !== "" && filterState.balance_amount_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Balance Amount</Text> : <Text ml={3} mediumBlack>Balance Amount</Text>} />
                                        </ListItem>

                                        <Divider />

                                    </List>
                                </Box>
                                <Box display={'flex'} sx={{ overflow: "auto", width: { xs: '50%', sm: "55%" } }} flexDirection={'column'} height={'70vh'} pl={'25px !important'} pt={2}>

                                    {
                                        selectedFilter == 1 ?
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={12} pb={2}>
                                                    <Text mediumBlack14 >
                                                        Select Date Range
                                                    </Text>
                                                </Grid>

                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`From`}
                                                        name='invoice_date_from'
                                                        value={filterState.invoice_date_from}
                                                        onChange={(value) => dateChange(value, 'invoice_date_from')}
                                                        height='56px'
                                                    />
                                                </Grid>
                                                <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                    &#8594;
                                                </Grid>
                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`To`}
                                                        name='invoice_date_to'
                                                        value={filterState.invoice_date_to}
                                                        onChange={(value) => dateChange(value, 'invoice_date_to')}
                                                        height='56px'
                                                        minDate={moment(filterState.invoice_date_from, dateFormat()).format(dateFormat())}
                                                        disabled={filterState.invoice_date_from == ""}
                                                        error={datesError.invoice_date_to}
                                                    />
                                                    <Box sx={{ height: "0px" }}>{datesError.invoice_date_to ? <Text red>{datesError.invoice_date_to ? datesError.invoice_date_to : ''}</Text> : ''}</Box>
                                                </Grid>
                                            </Grid>

                                            :
                                            selectedFilter == 2 ?
                                                <>
                                                    {
                                                        <Grid container alignItems={"center"} pb={2}>
                                                            <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                <Box sx={{ width: 300, margin: "auto" }}>
                                                                    <Text mediumBlack14 pb={1}>
                                                                        Select Amount Range
                                                                    </Text>
                                                                    <Slider
                                                                        value={[filterState.total_amount_from, filterState.total_amount_to]}
                                                                        onChange={handleInvoiceAmount}
                                                                        valueLabelDisplay="auto"
                                                                        min={filterAmount.total_amount.min}
                                                                        max={filterAmount.total_amount.max}

                                                                        sx={{ mb: 2 }}
                                                                    />
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'total_amount_from',
                                                                                value: filterState.total_amount_from,
                                                                                inputProps: { maxLength: 50 },
                                                                            }}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                            handleChange={(e) => handleInputChange(0, e.target.value, 'total_amount_from', 'min')}
                                                                            clientInput
                                                                            labelText={'$ Min'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'total_amount_to',
                                                                                value: filterState.total_amount_to,
                                                                            }}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                            handleChange={(e) => handleInputChange(1, e.target.value, 'total_amount_to', 'max')}

                                                                            clientInput
                                                                            labelText={'$ Max'}
                                                                            size="small"
                                                                            sx={{ width: "35%" }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                                :
                                                selectedFilter == 3 ?
                                                    <>
                                                        {
                                                            <Grid container alignItems={"center"} pb={2}>
                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                    <Box sx={{ width: 300, margin: "auto" }}>
                                                                        <Text mediumBlack14 pb={1}>
                                                                            Select Amount Range
                                                                        </Text>
                                                                        <Slider
                                                                            value={[filterState.balance_amount_from, filterState.balance_amount_to]}
                                                                            onChange={handleSliderBalanceAmount}
                                                                            valueLabelDisplay="auto"
                                                                            min={filterAmount.balance_amount.min}
                                                                            max={filterAmount.balance_amount.max}
                                                                            sx={{ mb: 2 }}
                                                                        />
                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'balance_amount_from',
                                                                                    value: filterState.balance_amount_from,
                                                                                    inputProps: { maxLength: 50 },
                                                                                }}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                handleChange={(e) => handleInputChange(0, e.target.value, 'balance_amount_from', 'min')}
                                                                                clientInput
                                                                                labelText={'$ Min'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                            <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'balance_amount_to',
                                                                                    value: filterState.balance_amount_to,
                                                                                }}
                                                                                onKeyPress={onNumberWithTwoDecimalOnlyChangeWithNegative}
                                                                                handleChange={(e) => handleInputChange(1, e.target.value, 'balance_amount_to', 'max')}
                                                                                clientInput
                                                                                labelText={'$ Max'}
                                                                                size="small"
                                                                                sx={{ width: "35%" }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                    :
                                                    selectedFilter == 4 ?
                                                        <>
                                                            {
                                                                employmentType.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2} key={index}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"status"}
                                                                                    value={item.id}
                                                                                    onChange={(e) => handleChangeCheckBox(e)}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={filterState.status.includes(item.id)}
                                                                                />
                                                                            }
                                                                            label={<Text mediumBlack nowrap>{item.value}</Text>}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }

                                                        </>
                                                        :
                                                        <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                            <Grid container>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                    <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                    <Text veryLargeLabel>Gear Up!</Text>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                    <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                        // nofilterplacement
                                    }
                                </Box>
                            </Box>

                            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                            </Box>
                        </Box >

                        : NoPermission()
                }

            </SwipeableDrawer>
        </Grid>

    )
}
export default InvoiceHistory