import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton, Slide, DialogContent, Dialog, Stack, Hidden } from '@mui/material';
import Button from '../../../../components/customButton/Button';
import Userplus from '../../../../assets/svg/user-plus.svg';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import placementPop from '../../../../assets/svg/placementPop.svg';
import ViewStyles from './ViewStyles';
import Text from '../../../../components/customText/Text';
import moment from 'moment';
import { addErrorMsg, addSuccessMsg, addWarningMsg, capitalizeAndAddSpace, dateFormat, getFormattedLabel } from '../../../../utils/utils';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select';
import Date from '../../../../components/datePicker/Date';// eslint-disable-next-line 
import { empty_fields, float_validation, isValid, onNumberFloatCurrency, onNumberWithTwoDecimalOnlyChangeCurrency, validate_emptyField, validates_handlechange_float, validate_input_fields } from '../../../../components/Validation';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import LocalStorage from '../../../../utils/LocalStorage';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import { styled } from "@mui/material/styles";
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import { useNavigate } from 'react-router-dom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ClientDetails(props) {

    const { HtmlTooltip, setformEdit, formEdit, employee, endDate } = props;
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = ViewStyles();
    const placementID = props.id ? props.id : "";
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [activeStep, setActiveStep] = useState({ step: "client" });
    const [edit, setEdit] = useState(false);
    const [currentBillEdit, setCurrentBillEdit] = useState(false);
    const [upBillEdit, setUpBillEdit] = useState(false);
    const [billAdd, setBillAdd] = useState(false);
    const [oldBillEdit, setOldBillEdit] = useState(null);
    const [error, setError] = useState({});
    const [clientContacts, setClientContacts] = useState([]);
    const [endClientContacts, setEndClientContacts] = useState([]);
    const [navigateOpen, setNavigateOpen] = useState(false);
    const [state, setState] = useState({
        client_name: "",
        client_id: "",
        client_reference_id: "",
        client_contact_id: '',
        client_contact_name: "",
        end_client_name: "",
        end_client_id: "",
        end_client_reference_id: "",
        end_client_contact_id: '',
        end_client_contact_name: ""
    })
    const [billError, setBillError] = useState({});
    const [bills, setBills] = useState({
        current_billing: [],
        previous_billing: [],
        upcoming_billing: []
    })
    const [billState, setBillState] = useState({
        bill_type: "",
        bill_rate: "",
        ot_bill_rate: "",
        effective_from: "",
        effective_to: ""
    });

    const clientDetails = props?.clientDetailsConfig
    const billingDetails = props?.billingDetailsConfig
    const navigate = useNavigate();
    useEffect(() => {
        getPlacementClientData(placementID)// eslint-disable-next-line
    }, []);

    const [employeeId, setEmployeeId] = useState("")

    const getPlacementClientData = (id) => {
        setLoading(true);
        PlacementApi.getPlacementIndex("client-details", id).then((res) => {
            if (res.data.statusCode === 1003) {
                setLoading(false);
                setEmployeeId(res.data.data.employee_details.employee_id);
                if (activeStep.step === 'client') {
                    if (res.data.data.client_details.client_id !== "" && res.data.data.client_details.client_id !== null) {
                        clientContactDropdown(res.data.data.client_details.client_id);
                    }
                    if (res.data.data.client_details.end_client_id !== "" && res.data.data.client_details.end_client_id !== null) {
                        endClientContactsDropdown(res.data.data.client_details.end_client_id);
                    }
                    setState({ ...res?.data?.data?.client_details })
                } else {
                    setBills({ ...res.data.data.billing_details });
                }

            } else {
                setLoading(false);
            }
        })
    }

    const clientContactDropdown = (id) => {
        ClientsApi.contactsDropdown('client', id).then((res) => {
            if (res.data.statusCode === 1003) {
                setClientContacts(res.data.data);
            }
        })
    }

    const endClientContactsDropdown = (id) => {
        ClientsApi.contactsDropdown('end-client', id).then((res) => {
            if (res.data.statusCode === 1003) {
                setEndClientContacts(res.data.data);
            }
        })
    }
    const [tab, setTab] = useState('');
    const handleTabChange = (param) => {
        if (formEdit) {
            setTab(param);
            setNavigateOpen(true);
        } else {
            setTab('');
            activeStep.step = param;
            setActiveStep({ ...activeStep });
            setCurrentBillEdit(false);
            setUpBillEdit(false);
            setEdit(false);
            setBillAdd(false);

            getPlacementClientData(placementID);
        }
    }

    const handleEdit = () => {
        setformEdit(true);
        setError({});
        setEdit(true);
        setCurrentBillEdit(false);
    }

    const handleBillEdit = (param, index) => {
        if (param == "upcoming") {
            setUpBillEdit(true);
            setError({});
            setBillState({ ...bills.upcoming_billing[0] });
        } else if (param == "current") {
            setError({});
            setBillState({ ...bills.current_billing[0] });
            setCurrentBillEdit(true);
        } else if (param == "old") {
            setError({});
            setBillState({ ...bills.previous_billing[index] });
            setOldBillEdit(index);
        }
        // setBillState({ ...bills.current_billing[0] });
        setBillError({});
        setformEdit(true);
        setEdit(false);
    }

    const handleBillAdd = () => {
        if (loading) {
            return false;
        }
        setformEdit(true);
        setCurrentBillEdit(false);

        // const [billState, setBillState] = useState({
        //     bill_type: "",
        //     bill_rate: "",
        //     ot_bill_rate: "",
        //     effective_from: "",
        //     effective_to: ""
        // });


        billState.bill_type = bills.current_billing[0].bill_type;
        billState.id = "";
        billState.bill_rate = "";
        billState.ot_bill_rate = "";
        billState.effective_from = "";
        billState.effective_to = "";
        setBillState({ ...billState });
        setBillError({});
        setBillAdd(true);
    }

    const changeHandler = (e) => {
        if (e.target.name === "lca_amount") {
            let value1 = e.target.value.trim();
            if (value1 == "$" || value1 == "") {
                state[e.target.name] = "";
                setState({ ...state });
                handleValidate(e)
                return;
            }
            value1 = value1.replace(currency, "");
            const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,2})$/;
            const numericValue = parseFloat(value1);
            if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
                state[e.target.name] = value1;
            } else {
                return false;
            }
            setState({ ...state });
            handleValidate(e)
        }


        else {
            state[e.target.name] = e.target.value;
            setState({ ...state });
            handleValidate(e)
        }
    }

    const handleBillChange = (e) => {
        if (e.target.name === "bill_rate" || e.target.name === "ot_bill_rate") {
            var value = e.target.value.trim();
            value = value.replace(LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$', "");
            if (value.length > 4) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    billState[e.target.name] = value;
                } else {
                    if (value.length === 5) {
                        if (value.slice(-1) === ".") {
                            billState[e.target.name] = value;
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                billState[e.target.name] = value;
            }
            setBillState({ ...billState });
            handleValidateCurrency(e.target.name, value)
        } else {
            billState[e.target.name] = e.target.value;
            setBillState({ ...billState });
            handleValidateBill(e)
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        billState[name] = moment(date).format(dateFormat());
        setBillState({ ...billState });
        handleValidateBill(event)
        if (name == 'effective_from') {
            if (endDate != "" && date !== "" && moment(date, dateFormat()).isAfter(moment(endDate, dateFormat()))) {
                billError.effective_from = "Billing start date should not go beyond placement end date";
                setBillError({ ...billError });
            }
        }
    }

    const handleClientSubmit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            setSaveLoading(true);
            const data = {
                request_id: LocalStorage.uid(),
                placement_id: placementID,
                employee_id: employeeId,
                client_details: state
            }
            PlacementApi.clientUpdate(data).then((res) => {
                if (res.data.statusCode === 1003) {
                    setformEdit(false);
                    setSaveLoading(false);
                    addSuccessMsg("Client details updated successfully");
                    getPlacementClientData(placementID);
                    setEdit(false);
                } else {
                    setSaveLoading(false);
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const [newStore, setNewStore] = useState(null);
    const [open, setOpen] = useState(false);

    const handleBillUpcomingSubmit = () => {
        if (billState.ot_bill_rate == "") {
            billState.ot_bill_rate = billState.bill_rate;
            setBills({ ...bills });
        }
        let errors = validateBill();
        if (isValid(errors)) {
            if (endDate !== "" && moment(billState.effective_from, dateFormat()).isAfter(moment(endDate, dateFormat()))) {
                billError.effective_from = "Billing start date should not go beyond placement end date";
                setBillError({ ...billError });
                return false;
            }
            if (bills.current_billing.length > 0) {
                if (moment(billState.effective_from, dateFormat()).isAfter(moment(), dateFormat())) {
                    const data = {
                        request_id: LocalStorage.uid(),
                        placement_id: placementID,
                        billing_details: billState
                    }
                    setNewStore(data);
                    if (bills.current_billing.length > 0 || bills.previous_billing.length > 0) {
                        setOpen(true);
                    }
                    else {
                        placementBillStoreUpdate(data)
                    }
                } else {
                    addErrorMsg("Start date must be future date ");
                }
            } else {
                const data = {
                    request_id: LocalStorage.uid(),
                    placement_id: placementID,
                    billing_details: billState
                }
                setNewStore(data);
                if (bills.current_billing.length > 0 || bills.previous_billing.length > 0) {
                    setOpen(true);
                }
                else {
                    placementBillStoreUpdate(data)
                }
            }
        } else {
            let s1 = { billError }
            s1 = errors
            setBillError(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const handleBillSubmit = () => {
        if (billState.ot_bill_rate == "") {
            billState.ot_bill_rate = billState.bill_rate;
            setBillState({ ...billState });
        }
        let errors = validateBill();
        if (isValid(errors)) {
            setSaveLoading(true);
            const data = {
                request_id: LocalStorage.uid(),
                placement_id: placementID,
                billing_details: billState
            }
            placementBillStoreUpdate(data);
        } else {
            let s1 = { billError }
            s1 = errors
            setBillError(s1);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const placementBillStoreUpdate = (data) => {
        setSaveLoading(true);
        PlacementApi.billUpdate(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setformEdit(false);
                setSaveLoading(false);
                setOpen(false);
                setUpBillEdit(false);
                setCurrentBillEdit(false);
                setOldBillEdit(null);
                // setBillState(false);
                if (billAdd) {
                    setBillAdd(false);
                    addSuccessMsg("Upcoming Billing details added successfully");
                } else {
                    addSuccessMsg("Billing details updated successfully");
                }
                getPlacementClientData(placementID);
                setCurrentBillEdit(false);
            } else {
                setSaveLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "client_contact_id":
                error.client_contact_id = validate_input_fields(input.value, clientDetails?.client_contact_id);
                break;
            case "end_client_contact_id":
                error.end_client_contact_id = validate_input_fields(input.value, clientDetails?.end_client_contact_id);
                break;
            case "lca_amount":
                error.lca_amount = validate_input_fields(input.value, clientDetails?.lca_amount);
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const handleValidateBill = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "effective_from":
                billError.effective_from = validate_input_fields(input.value, billingDetails?.effective_from);
                break;
            default:
                break;
        }
        setBillError({ ...billError });
    }

    const handleValidateCurrency = (name, value) => {
        switch (name) {
            case "bill_rate":
                billError.bill_rate = validate_input_fields(value, billingDetails?.bill_rate);
                if (billError.bill_rate == '' && parseInt(value) <= 0) {
                    billError.bill_rate = 'The rate should be greater than 0'
                }
                break
            case "ot_bill_rate":
                billError.ot_bill_rate =  validate_input_fields(value, billingDetails?.ot_bill_rate);
                if (billError.ot_bill_rate == "" && value !== "" && parseInt(value) <= 0) {
                    billError.ot_bill_rate = 'The rate should be greater than 0'
                }
                break
            default:
                break
        }
        setBillError({ ...billError })
    }

    const validateAll = () => {
        let { client_contact_id, end_client_contact_id, lca_amount } = state;
        let errors = {};
        errors['client_contact_id'] = validate_input_fields(client_contact_id, clientDetails?.client_contact_id);
        errors['end_client_contact_id'] = validate_input_fields(end_client_contact_id, clientDetails?.end_client_contact_id);
        errors['lca_amount'] = ['H-1B', 'H-1B1', 'E3'].includes(employee.visa_type) ? (lca_amount < clientDetails?.lca_amount?.minValue && (clientDetails?.lca_amount?.required && clientDetails?.lca_amount?.isVisible && !clientDetails?.lca_amount?.isDisabled)) ? clientDetails?.lca_amount?.minValueMsg : validate_input_fields(lca_amount, clientDetails?.lca_amount) : ''
        setError(errors);
        return errors;
    }

    const validateBill = () => {
        let { bill_rate, ot_bill_rate, effective_from } = billState;
        let errors = {};
        errors['effective_from'] = validate_input_fields(effective_from, billingDetails?.effective_from);
        errors['bill_rate'] = validate_input_fields(bill_rate, billingDetails?.bill_rate);
        if (errors.bill_rate == '' && parseInt(bill_rate) <= 0) {
            errors.bill_rate = 'The rate should be greater than 0'
        }
        errors['ot_bill_rate'] = validate_input_fields(ot_bill_rate, billingDetails?.ot_bill_rate);
        if (errors.ot_bill_rate == '' && ot_bill_rate !== "" && parseInt(ot_bill_rate) <= 0) {
            errors.ot_bill_rate = 'The rate should be greater than 0'
        }
        setError(errors);
        return errors;
    }

    const handelBillCancel = (param) => {
        if (param === "Active Billing Details") {
            setCurrentBillEdit(false)
        } else {
            setUpBillEdit(false)
        }
        setformEdit(false);
    }

    const billForm = (param) => {
        return (
            <Grid container columnSpacing={4} pt={4} p={{ lg: 3, md: 3, sm: 3, xs: 1.5 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text largeBlue >{param}</Text>
                </Grid>
                {billingDetails?.bill_type?.isVisible &&
                <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                    <Select
                        name='bill_type'
                        value={billState.bill_type}
                        // onChange={(e) => { changeHandler(e) }}
                        options={[{ id: 1, value: "Hourly" }, { id: 2, value: "Salary" }]}
                        label={billingDetails?.bill_type?.label}
                        disabled={true}
                    />
                </Grid>}
                {billingDetails?.bill_rate?.isVisible &&
                <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'bill_rate',
                            value: currency + '' + billState.bill_rate
                        }}
                        handleChange={handleBillChange}
                        onKeyPress={onNumberFloatCurrency}
                        clientInput
                        labelText={billingDetails?.bill_rate?.required ? billingDetails?.bill_rate?.label : billingDetails?.bill_rate?.label + ' (Optional)'}
                        placeholder={billingDetails?.bill_rate?.placeholder}
                        disabled={billingDetails?.bill_rate?.isDisabled}
                        error={billError.bill_rate}
                    />
                    {billError.bill_rate ? <Text red>{billError.bill_rate ? billError.bill_rate : ''}</Text> : ''}
                </Grid>}
                {billingDetails?.ot_bill_rate?.isVisible &&
                <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'ot_bill_rate',
                            value: currency + '' + billState.ot_bill_rate
                        }}
                        handleChange={handleBillChange}
                        onKeyPress={onNumberFloatCurrency}
                        clientInput
                        labelText={billingDetails?.ot_bill_rate?.required ? billingDetails?.ot_bill_rate?.label : billingDetails?.ot_bill_rate?.label + ' (Optional)'}
                        placeholder={billingDetails?.ot_bill_rate?.placeholder}
                        disabled={billingDetails?.ot_bill_rate?.isDisabled}
                        error={billError.ot_bill_rate}
                    />
                    {billError.ot_bill_rate ? <Text red>{billError.ot_bill_rate ? billError.ot_bill_rate : ''}</Text> : ''}
                    <Text greyLabel sx={{ font: "10px Quicksand !important" }} pt={1}>Incase OT Bill field is empty, bill rate will be considered for further calculation by default</Text>
                </Grid>}
                {billingDetails?.effective_from?.isVisible &&
                <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                    <Date
                        labelText={billingDetails?.effective_from?.required ? billingDetails?.effective_from?.label : billingDetails?.effective_from?.label + ' (Optional)'}
                        name='effective_from'
                        onChange={(value) => dateChange(value, 'effective_from')}
                        value={moment(billState.effective_from, dateFormat()).format(dateFormat())}
                        // minDate={(bills.current_billing.length > 0 && bills.current_billing[0].effective_to !== "") ? moment(bills.current_billing[0].effective_to).add(1, 'days').format(dateFormat()) : billState.effective_to == "" ? moment().format(dateFormat()) : moment(billState.effective_to).format(dateFormat())}
                        minDate={moment().add(1, 'days').format(dateFormat())}
                        height='56px'
                        error={billError.effective_from}
                        disabled={param === "Active Billing Details" ? true : false || billingDetails?.effective_from?.isDisabled}
                        maxDate={param !== "Active Billing Details" && endDate && endDate !== "" && endDate !== "Invalid Date" && moment(endDate, dateFormat())}
                    />
                    {billError.effective_from ? <Text red>{billError.effective_from ? billError.effective_from : ''}</Text> : ''}
                </Grid>}
                {
                    billState.effective_to !== "" && billingDetails?.end_date?.isVisible && <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                        <Date
                            labelText={billingDetails?.end_date?.label}
                            name='effective_from'
                            value={moment(billState.effective_to, dateFormat()).format(dateFormat())}
                            height='56px'
                            disabled={true}
                        />
                    </Grid>
                }
                <Grid container item lg={12} md={12} sm={12} xs={12} pt={4} justifyContent={"space-between"}>
                    <Button disabled={saveLoading} blackCancel1 onClick={() => { handelBillCancel(param) }}>Cancel</Button>
                    {
                        param === "Active Billing Details" ?
                            <LoadingButton saveBtn className={classes.smallBlueButton} loading={saveLoading} onClick={() => handleBillSubmit()}>Save</LoadingButton>
                            :
                            // <Button saveBtn onClick={() => { handleBillUpcomingSubmit() }}>Save</Button>
                            <LoadingButton saveBtn loading={saveLoading} onClick={() => handleBillUpcomingSubmit()}>Save</LoadingButton>
                    }
                </Grid>
            </Grid>
        )
    }


    const handleFinalCancel = (param) => {
        activeStep.step = param;
        setActiveStep({ ...activeStep });
        setCurrentBillEdit(false);
        setformEdit(false);
        setUpBillEdit(false);
        setEdit(false);
        setBillAdd(false);
        setNavigateOpen(false);

        getPlacementClientData(placementID);
    }

    return (
        <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={8}>
                <Box className={classes.btnBox}>
                    <Button onClick={() => handleTabChange("client")} saveBtn className={activeStep.step == "client" ? classes.activeButton : classes.inActiveButton}>Client Details</Button>
                    <Button onClick={() => handleTabChange("bank")} saveBtn className={activeStep.step == "bank" ? classes.activeButton : classes.inActiveButton} sx={{ marginLeft: "12px" }}>Billing Details</Button>
                </Box>
            </Grid>
            {
                activeStep.step == "bank" && bills.upcoming_billing.length <= 0 ?
                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign={"end"} pt={{ lg: 0, xs: 1 }}>
                        {
                            props.status !== "In Active" && !billAdd && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                                <>
                                    <Button onClick={() => handleBillAdd()} blueBtnSave startIcon={<img src={Userplus} alt="Userplus" style={{ margin: "0px 10px" }} />} className={classes.addBillBtn}>Add New Billing Details</Button>
                                </>
                                : ""
                        }
                    </Grid>
                    : ""}
            <Grid item lg={12} md={12} sm={12} xs={12} pt={5} p={0.1} mt={3}>
                {
                    activeStep.step == "client" ?
                        <Box mb={2} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "65vh", overflow: "auto !important" }}>
                            <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1.4 }} alignItems={"center"} >
                                <Grid item lg={6} md={6} sm={6} xs={9}>
                                    <Text largeBlue >Client Details</Text>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={3} textAlign={"end"}>
                                    {
                                        props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                                            <>
                                                {(!edit && !loading) ? <img onClick={() => { handleEdit() }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                            </>
                                            : ""
                                    }
                                </Grid>

                                {
                                    edit ?
                                        <Grid container columnSpacing={4} pt={4} spacing={{ xs: 2 }}>
                                            {clientDetails?.client_name?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'client_name',
                                                        value: state.client_name
                                                    }}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={clientDetails?.client_name?.label}
                                                />
                                            </Grid>}
                                            {clientDetails?.client_reference_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'client_reference_id',
                                                        value: state.client_reference_id
                                                    }}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={clientDetails?.client_reference_id?.label}
                                                />
                                            </Grid>}
                                            {clientDetails?.client_contact_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                                <Select
                                                    name='client_contact_id'
                                                    value={state.client_contact_id}
                                                    onChange={(e) => { changeHandler(e) }}
                                                    options={clientContacts}
                                                    label={clientDetails?.client_contact_id?.required ? clientDetails?.client_contact_id?.label : clientDetails?.client_contact_id?.label + ' (Optional)'}
                                                    placeholder={clientDetails?.client_contact_id?.placeholder}
                                                    disabled={clientDetails?.client_contact_id?.isDisabled}
                                                    buttonName='New Contact'
                                                    helperText={error.client_contact_id ? <Text red>{error.client_contact_id}</Text> : ""}
                                                    buttonHandler={() => {
                                                        navigate('/clients/clients-user-profile', { state: { flow: 'cilentDetails', data: { id: state.client_id } } })
                                                    }}
                                                    showButton={clientContacts.length < 5}
                                                />
                                            </Grid>}
                                        </Grid> :
                                        < Grid container columnSpacing={4} >
                                            {clientDetails?.client_name?.isVisible &&
                                            <Grid item lg={3} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            <Text mediumGreyHeader1 title={clientDetails?.client_name?.label.length > 45 ? clientDetails?.client_name?.label : ''}>{clientDetails?.client_name?.label.length > 45 ? clientDetails?.client_name?.label?.substring(0, 45) + "..." : clientDetails?.client_name?.label}</Text>
                                                            <Text mediumBlack14 pt={0.8} >
                                                                {state.client_name === "" ? "--" : capitalizeAndAddSpace(state.client_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(state.client_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(state.client_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(state.client_name)
                                                                }
                                                            </Text>
                                                        </>
                                                }
                                            </Grid>}
                                            {clientDetails?.client_reference_id?.isVisible &&
                                            <Grid item lg={3} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            <Text mediumGreyHeader1 title={clientDetails?.client_reference_id?.label.length > 45 ? clientDetails?.client_reference_id?.label : ''}>{clientDetails?.client_reference_id?.label.length > 45 ? clientDetails?.client_reference_id?.label?.substring(0, 45) + "..." : clientDetails?.client_reference_id?.label}</Text>
                                                            <Text mediumBlack14 pt={0.8} >{state.client_reference_id ? state.client_reference_id : "--"}</Text>
                                                        </>
                                                }
                                            </Grid>}
                                            {clientDetails?.client_contact_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            {getFormattedLabel(clientDetails?.client_contact_id)}
                                                            <Text mediumBlack14 pt={0.8} >
                                                                {state.client_contact_name === "" ? "--" : capitalizeAndAddSpace(state.client_contact_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(state.client_contact_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(state.client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state.client_contact_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(state.client_contact_name)
                                                                }
                                                            </Text>
                                                        </>
                                                }
                                            </Grid>}
                                        </Grid>
                                }
                                <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
                                    <Text largeBlue>End Client Details</Text>
                                </Grid>
                                {
                                    edit ?
                                        <Grid container columnSpacing={4} pt={4} spacing={{ xs: 2 }}>
                                            {clientDetails?.end_client_name?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'end_client_name',
                                                        value: state.end_client_name
                                                    }}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={clientDetails?.end_client_name?.label}
                                                />
                                            </Grid>}
                                            {clientDetails?.end_client_reference_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} >
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'end_client_reference_id',
                                                        value: state.end_client_reference_id
                                                    }}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={clientDetails?.end_client_reference_id?.label}
                                                />
                                            </Grid>}
                                            {clientDetails?.end_client_contact_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                                <Select
                                                    name='end_client_contact_id'
                                                    value={state.end_client_contact_id}
                                                    onChange={(e) => { changeHandler(e) }}
                                                    options={endClientContacts}
                                                    label={clientDetails?.end_client_contact_id?.required ? clientDetails?.end_client_contact_id?.label : clientDetails?.end_client_contact_id?.label + ' (Optional)'}
                                                    placeholder={clientDetails?.end_client_contact_id?.placeholder}
                                                    disabled={state.end_client_id == "" || clientDetails?.end_client_contact_id?.isDisabled}
                                                    helperText={error.end_client_contact_id ? <Text red>{error.end_client_contact_id}</Text> : ""}
                                                    buttonName='New Contact'
                                                    buttonHandler={() => {
                                                        navigate('/clients/end-clients-user-profile', { state: { flow: 'cilentDetails', id: state.end_client_id } })
                                                    }}
                                                    showButton={endClientContacts.length < 5}
                                                />
                                            </Grid>}
                                            {
                                                ['H-1B', 'H-1B1', 'E3'].includes(employee?.visa_type)
                                                    ?
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={5} pb={2}>
                                                            <Text largeBlue>Employee Details</Text>
                                                        </Grid>
                                                        {clientDetails?.lca_amount?.isVisible &&
                                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'lca_amount',
                                                                        value: currency + '' + state.lca_amount,
                                                                        inputProps: {
                                                                            maxLength: clientDetails?.lca_amount?.maxLength, // Maximum length for "9999999999.99"
                                                                            pattern: clientDetails?.lca_amount?.pattern, // Allow numbers with up to 2 decimals
                                                                        },
                                                                    }}
                                                                    handleChange={(e) => changeHandler(e)}
                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                    clientInput
                                                                    labelText={clientDetails?.lca_amount?.required ? clientDetails?.lca_amount?.label : clientDetails?.lca_amount?.label + ' (Optional)'}
                                                                    placeholder={clientDetails?.lca_amount?.placeholder}
                                                                    disabled={clientDetails?.lca_amount?.isDisabled}
                                                                    error={error.lca_amount}
                                                                />

                                                                {
                                                                    error.lca_amount ?
                                                                        <Text red>{error.lca_amount ? error.lca_amount : ''}</Text> : ''
                                                                }
                                                            </Box>
                                                            <a href='https://flag.dol.gov/wage-data/wage-search' rel="noreferrer" target='_blank'>
                                                                <Text smallBlue sx={{ textDecoration: 'underline', paddingTop: '10px', cursor: 'pointer' }}>Check minimum wage here</Text>
                                                            </a>
                                                        </Grid>}
                                                    </Grid>
                                                    : ''
                                            }
                                            <Grid container item lg={12} md={12} sm={12} pt={3} justifyContent={"space-between"}>
                                                <Button disabled={saveLoading} blackCancel1 onClick={() => { setEdit(false); setformEdit(false); getPlacementClientData(placementID) }}>Cancel</Button>
                                                <LoadingButton saveBtn loading={saveLoading} onClick={() => handleClientSubmit()}>Save</LoadingButton>
                                            </Grid>
                                        </Grid> :
                                        <Grid container columnSpacing={4}>
                                            {clientDetails?.end_client_name?.isVisible &&
                                            <Grid item lg={3} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            <Text mediumGreyHeader1 title={clientDetails?.end_client_name?.label.length > 45 ? clientDetails?.end_client_name?.label : ''}>{clientDetails?.end_client_name?.label.length > 45 ? clientDetails?.end_client_name?.label?.substring(0, 45) + "..." : clientDetails?.end_client_name?.label}</Text>
                                                            <Text mediumBlack14 pt={0.8} >
                                                                {state?.end_client_name === "" ? "--" : capitalizeAndAddSpace(state?.end_client_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(state?.end_client_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(state?.end_client_name).slice(0, 16) + (capitalizeAndAddSpace(state?.end_client_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(state?.end_client_name)
                                                                }
                                                            </Text>
                                                        </>
                                                }
                                            </Grid>}
                                            {clientDetails?.end_client_reference_id?.isVisible &&
                                            <Grid item lg={3} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            <Text mediumGreyHeader1 title={clientDetails?.end_client_reference_id?.label.length > 45 ? clientDetails?.end_client_reference_id?.label : ''}>{clientDetails?.end_client_reference_id?.label.length > 45 ? clientDetails?.end_client_reference_id?.label?.substring(0, 45) + "..." : clientDetails?.end_client_reference_id?.label}</Text>
                                                            <Text mediumBlack14 pt={0.8} >{state?.end_client_reference_id ? state?.end_client_reference_id : "--"}</Text>
                                                        </>
                                                }
                                            </Grid>}
                                            {clientDetails?.end_client_contact_id?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                {
                                                    loading ?
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                        :
                                                        <>
                                                            {getFormattedLabel(clientDetails?.end_client_contact_id)}
                                                            <Text mediumBlack14 pt={0.8} >
                                                                {state?.end_client_contact_name === "" ? "--" : capitalizeAndAddSpace(state?.end_client_contact_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(state?.end_client_contact_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(state?.end_client_contact_name).slice(0, 16) + (capitalizeAndAddSpace(state?.end_client_contact_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(state?.end_client_contact_name)
                                                                }
                                                            </Text>
                                                        </>
                                                }
                                            </Grid>}
                                            {
                                                ['H-1B', 'H-1B1', 'E3'].includes(employee?.visa_type)
                                                    ?
                                                    <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
                                                        <Text largeBlue>Employee Details</Text>
                                                    </Grid>
                                                    : ''}
                                            {
                                                ['H-1B', 'H-1B1', 'E3'].includes(employee?.visa_type)
                                                    ?
                                                    <>
                                                    {clientDetails?.lca_amount?.isVisible &&
                                                    <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                        {
                                                            loading ?
                                                                <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                                :
                                                                <>
                                                                    {getFormattedLabel(clientDetails?.lca_amount)}
                                                                    <Text mediumBlack14 pt={0.8} >
                                                                        {LocalStorage.getCurrencySymbol()} {state?.lca_amount ? state?.lca_amount : '-'}
                                                                    </Text>
                                                                </>
                                                        }
                                                    </Grid>}
                                                    </>
                                                    : ''
                                            }

                                        </Grid>
                                }
                            </Grid>
                        </Box>
                        :
                        <Grid container alignItems={"center"}>

                            {
                                billAdd ?
                                    <Box mb={2} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: "65vh", overflow: "auto !important", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                        <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1 }} columnSpacing={4} >
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Text largeBlue >Upcoming Billing Details</Text>
                                            </Grid>
                                            {billingDetails?.bill_type?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                <Select
                                                    name='bill_rate_type'
                                                    value={1}
                                                    // onChange={(e) => { changeHandler(e) }}
                                                    options={[{ id: 1, value: "Hourly" }, { id: 2, value: "Salary" }]}
                                                    label={billingDetails?.bill_type?.label}
                                                    disabled={true}
                                                />
                                            </Grid>}
                                            {billingDetails?.bill_rate?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'bill_rate',
                                                        value: currency + '' + billState.bill_rate
                                                    }}
                                                    handleChange={handleBillChange}
                                                    onKeyPress={onNumberFloatCurrency}
                                                    clientInput
                                                    error={billError.bill_rate}
                                                    labelText={billingDetails?.bill_rate?.required ? billingDetails?.bill_rate?.label : billingDetails?.bill_rate?.label + ' (Optional)'}
                                                    placeholder={billingDetails?.bill_rate?.placeholder}
                                                    disabled={billingDetails?.bill_rate?.isDisabled}
                                                />
                                                {billError.bill_rate ? <Text red>{billError.bill_rate ? billError.bill_rate : ''}</Text> : ''}
                                            </Grid>}
                                            {billingDetails?.effective_from?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                <Date
                                                    labelText={billingDetails?.effective_from?.required ? billingDetails?.effective_from?.label : billingDetails?.effective_from?.label + ' (Optional)'}
                                                    disabled={billingDetails?.effective_from?.isDisabled}
                                                    name='effective_from'
                                                    value={billState.effective_from}
                                                    onChange={(value) => dateChange(value, 'effective_from')}
                                                    minDate={moment().add(1, 'days').format(dateFormat())}
                                                    height='56px'
                                                    error={billError.effective_from}
                                                    maxDate={endDate && endDate !== "" && endDate !== "Invalid Date" && moment(endDate, dateFormat()).format(dateFormat())}
                                                />
                                                {billError.effective_from ? <Text red>{billError.effective_from ? billError.effective_from : ''}</Text> : ''}
                                                <Text greyLabel sx={{ font: "10px Quicksand !important" }} pt={1}>This date will automatically set as end date for the previous Billing Details</Text>
                                            </Grid>}
                                            {billingDetails?.ot_bill_rate?.isVisible &&
                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={1}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'ot_bill_rate',
                                                        value: currency + '' + billState.ot_bill_rate
                                                    }}
                                                    handleChange={handleBillChange}
                                                    onKeyPress={onNumberFloatCurrency}
                                                    clientInput
                                                    labelText={billingDetails?.ot_bill_rate?.required ? billingDetails?.ot_bill_rate?.label : billingDetails?.ot_bill_rate?.label + ' (Optional)'}
                                                    placeholder={billingDetails?.ot_bill_rate?.placeholder}
                                                    disabled={billingDetails?.ot_bill_rate?.isDisabled}
                                                    error={billError.ot_bill_rate}
                                                />
                                                {billError.ot_bill_rate ? <Text red>{billError.ot_bill_rate ? billError.ot_bill_rate : ''}</Text> : ''}
                                                <Text greyLabel sx={{ font: "10px Quicksand !important" }} pt={1}>Incase OT Bill field is empty, bill rate will be considered for further calculation by default</Text>
                                            </Grid>}

                                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={4} justifyContent={"space-between"}>
                                                <Button blackCancel1 disabled={saveLoading} onClick={() => { setBillAdd(false); setformEdit(false); }}>Cancel</Button>
                                                <LoadingButton saveBtn loading={saveLoading} onClick={() => handleBillUpcomingSubmit()}>Save</LoadingButton>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                                <Text largeBlue >Active Billing Details</Text>
                                            </Grid>
                                            {
                                                bills.current_billing.map((item, index) => (
                                                    <>
                                                       {billingDetails?.bill_type?.isVisible &&
                                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                            <Select
                                                                name='bill_type'
                                                                value={item.bill_type}
                                                                options={[{ id: 1, value: "Hourly" }, { id: 2, value: "Salary" }]}
                                                                label={ billingDetails?.bill_type?.label}
                                                                disabled={true}
                                                            />
                                                        </Grid>}
                                                        {billingDetails?.bill_rate?.isVisible &&
                                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'bill_rate',
                                                                    value: currency + '' + item.bill_rate
                                                                }}
                                                                disabled={true}
                                                                clientInput
                                                                labelText={billingDetails?.bill_rate?.required ? billingDetails?.bill_rate?.label : billingDetails?.bill_rate?.label + ' (Optional)'}
                                                                placeholder={billingDetails?.bill_rate?.placeholder}
                                                            />
                                                        </Grid>}
                                                        {billingDetails?.ot_bill_rate?.isVisible &&
                                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'ot_bill_rate',
                                                                    value: currency + '' + item.ot_bill_rate
                                                                }}
                                                                disabled={true}
                                                                clientInput
                                                                labelText={billingDetails?.ot_bill_rate?.required ? billingDetails?.ot_bill_rate?.label : billingDetails?.ot_bill_rate?.label + ' (Optional)'}
                                                                placeholder={billingDetails?.ot_bill_rate?.placeholder}
                                                            />
                                                            <Text greyLabel sx={{ font: "10px Quicksand !important" }} pt={1}>Incase OT Bill field is empty, bill rate will be considered for further calculation by default</Text>
                                                        </Grid>}
                                                        <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                            <Date
                                                                labelText={billingDetails?.effective_from?.required ? billingDetails?.effective_from?.label : billingDetails?.effective_from?.label + ' (Optional)'}
                                                                name='effective_from'
                                                                value={moment(item.effective_from, dateFormat()).format(dateFormat())}
                                                                height='56px'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </>
                                                ))
                                            }
                                        </Grid>
                                    </Box> :
                                    <>
                                        {
                                            loading ?
                                                <Grid container spacing={2}>
                                                    <Grid item lg={3} md={3} sm={3} xs={12} pt={2}>
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                    </Grid>
                                                    <Grid item lg={3} md={3} sm={3} xs={12} pt={2}>
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                    </Grid>
                                                    <Grid item lg={3} md={3} sm={3} xs={12} pt={2}>
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                    </Grid>
                                                    <Grid item lg={3} md={3} sm={3} xs={12} pt={2}>
                                                        <Skeleton variant='rounded' animation="wave" width={'100%'} height={40} />
                                                    </Grid>
                                                </Grid> :
                                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            bills.upcoming_billing.length > 0 ?
                                                                <Box width={"100%"} mb={2} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: upBillEdit ? "45vh" : "22vh", overflow: "auto !important", transition: "height 0.5s ease", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                                                    {
                                                                        upBillEdit ?
                                                                            <>
                                                                                {billForm("Upcoming Billing Details")}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {bills.upcoming_billing.map((item, index) => (
                                                                                    <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1 }} alignItems={"center"}>
                                                                                        <Grid item lg={6} md={6} sm={6} xs={10}>
                                                                                            <Text largeBlue >Upcoming Billing Details</Text>
                                                                                        </Grid>
                                                                                        <Grid item lg={6} md={6} sm={6} xs={2} textAlign={"end"}>
                                                                                            {
                                                                                                props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                                                                                                    <>
                                                                                                        {
                                                                                                            (!upBillEdit && !currentBillEdit && oldBillEdit == null) ? <img onClick={() => handleBillEdit('upcoming')} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""
                                                                                                        }
                                                                                                    </>
                                                                                                    : ""
                                                                                            }
                                                                                        </Grid>
                                                                                        {billingDetails?.bill_type?.isVisible &&
                                                                                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                            <Text mediumGreyHeader1 title={billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label : ''} >{billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label.substring(0,45) + "..." : billingDetails?.bill_type?.label}</Text>
                                                                                            <Text mediumBlack14 pt={0.8} >{item.bill_type ? item.bill_type == 1 ? "Hourly" : "Salary" : "--"}</Text>
                                                                                        </Grid>}
                                                                                        {billingDetails?.bill_rate?.isVisible &&
                                                                                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                            {getFormattedLabel(billingDetails?.bill_rate)}
                                                                                            <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.bill_rate ? item.bill_rate : "--"}</Text>
                                                                                        </Grid>}
                                                                                        {billingDetails?.ot_bill_rate?.isVisible &&
                                                                                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                            {getFormattedLabel(billingDetails?.ot_bill_rate)}
                                                                                            <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.ot_bill_rate ? item.ot_bill_rate : "--"}</Text>
                                                                                        </Grid>}
                                                                                        {billingDetails?.effective_from?.isVisible &&
                                                                                        <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                            {getFormattedLabel(billingDetails?.effective_from)}
                                                                                            <Text mediumBlack14 pt={0.8} >{moment(item.effective_from, dateFormat()).format(dateFormat())}{item.effective_to != "" ? ` - ${moment(item.effective_to, dateFormat()).format(dateFormat())}` : ""} </Text>
                                                                                        </Grid>}
                                                                                    </Grid>
                                                                                ))
                                                                                }
                                                                            </>
                                                                    }
                                                                </Box>
                                                                : ""
                                                        }
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            bills.current_billing.length > 0 ?
                                                                <Box mb={2} width={"100%"} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: currentBillEdit ? "45vh" : "22vh", overflow: "auto !important", transition: "height 0.5s ease", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                                                    {
                                                                        currentBillEdit ?
                                                                            <>
                                                                                {billForm("Active Billing Details")}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    bills.current_billing.map((item, index) => (
                                                                                        <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1 }} pb={4} alignItems={"center"}>
                                                                                            <Grid item lg={6} md={6} sm={6} xs={10}>
                                                                                                <Text largeBlue >Active Billing Details</Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={6} md={6} sm={6} xs={2} textAlign={"end"}>
                                                                                                {
                                                                                                    props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                                                                                                        <>
                                                                                                            {
                                                                                                                (!upBillEdit && !currentBillEdit && oldBillEdit == null) ? <img onClick={() => handleBillEdit('current')} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""
                                                                                                            }
                                                                                                        </>
                                                                                                        : ""
                                                                                                }
                                                                                            </Grid>
                                                                                            {billingDetails?.bill_type?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label : ''} >{billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label.substring(0,45) + "..." : billingDetails?.bill_type?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{item.bill_type ? item.bill_type == 1 ? "Hourly" : "Salary" : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {billingDetails?.bill_rate?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                {getFormattedLabel(billingDetails?.bill_rate)}
                                                                                                <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.bill_rate ? item.bill_rate : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {billingDetails?.ot_bill_rate?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                {getFormattedLabel(billingDetails?.ot_bill_rate)}
                                                                                                <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.ot_bill_rate ? item.ot_bill_rate : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {item.effective_to != "" ? 
                                                                                            <>
                                                                                            {billingDetails?.start_and_end_date?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.start_and_end_date?.label?.length > 45 ? billingDetails?.start_and_end_date?.label : ''} >{billingDetails?.start_and_end_date?.label?.length > 45 ? billingDetails?.start_and_end_date?.label.substring(0,45) + "..." : billingDetails?.start_and_end_date?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{moment(item.effective_from, dateFormat()).format(dateFormat())}{item.effective_to != "" ? ` - ${moment(item.effective_to, dateFormat()).format(dateFormat())}` : ""}</Text>
                                                                                             </Grid>}
                                                                                             </> :
                                                                                             <>
                                                                                            {billingDetails?.effective_from?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.effective_from?.label?.length > 45 ? billingDetails?.effective_from?.label : ''} >{billingDetails?.effective_from?.label?.length > 45 ? billingDetails?.effective_from?.label.substring(0,45) + "..." : billingDetails?.effective_from?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{moment(item.effective_from, dateFormat()).format(dateFormat())}{item.effective_to != "" ? ` - ${moment(item.effective_to, dateFormat()).format(dateFormat())}` : ""}</Text>
                                                                                            </Grid>}
                                                                                            </>}
                                                                                        </Grid>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                    }
                                                                </Box> : ""
                                                        }
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            bills.previous_billing.length > 0 ?
                                                                <>
                                                                    {
                                                                        bills.previous_billing.map((item, index) => (
                                                                            <Box mb={2} width={"100%"} sx={{ boxShadow: "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: oldBillEdit == index ? "45vh" : "22vh", overflow: "auto !important", transition: "height 0.5s ease", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                                                                {
                                                                                    oldBillEdit == index ?
                                                                                        <Grid container columnSpacing={4} pt={4} p={{ lg: 3, md: 3, sm: 3, xs: 1.5 }}>
                                                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                                                <Text largeBlue >Old Billing Details</Text>
                                                                                            </Grid>
                                                                                            {billingDetails?.bill_type?.isVisible &&
                                                                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                                                                <Select
                                                                                                    name='bill_type'
                                                                                                    value={billState.bill_type}
                                                                                                    // onChange={(e) => { changeHandler(e) }}
                                                                                                    options={[{ id: 1, value: "Hourly" }, { id: 2, value: "Salary" }]}
                                                                                                    label={billingDetails?.bill_type?.label}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </Grid>}
                                                                                            {billingDetails?.bill_rate?.isVisible &&
                                                                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'bill_rate',
                                                                                                        value: currency + '' + billState.bill_rate
                                                                                                    }}
                                                                                                    handleChange={handleBillChange}
                                                                                                    onKeyPress={onNumberFloatCurrency}
                                                                                                    clientInput
                                                                                                    labelText={billingDetails?.bill_rate?.required ? billingDetails?.bill_rate?.label : billingDetails?.bill_rate?.label + ' (Optional)'}
                                                                                                    placeholder={billingDetails?.bill_rate?.placeholder}
                                                                                                    disabled={billingDetails?.bill_rate?.isDisabled}
                                                                                                    error={billError.bill_rate}
                                                                                                />
                                                                                                {billError.bill_rate ? <Text red>{billError.bill_rate ? billError.bill_rate : ''}</Text> : ''}
                                                                                            </Grid>}
                                                                                            {billingDetails?.ot_bill_rate?.isVisible &&
                                                                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={4}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'ot_bill_rate',
                                                                                                        value: currency + '' + billState.ot_bill_rate
                                                                                                    }}
                                                                                                    handleChange={handleBillChange}
                                                                                                    onKeyPress={onNumberFloatCurrency}
                                                                                                    clientInput
                                                                                                    labelText={billingDetails?.ot_bill_rate?.required ? billingDetails?.ot_bill_rate?.label : billingDetails?.ot_bill_rate?.label + ' (Optional)'}
                                                                                                    placeholder={billingDetails?.ot_bill_rate?.placeholder}
                                                                                                    disabled={billingDetails?.ot_bill_rate?.isDisabled}
                                                                                                    error={billError.ot_bill_rate}
                                                                                                />
                                                                                                {billError.ot_bill_rate ? <Text red>{billError.ot_bill_rate ? billError.ot_bill_rate : ''}</Text> : ''}
                                                                                                <Text greyLabel sx={{ font: "10px Quicksand !important" }} pt={1}>Incase OT Bill field is empty, bill rate will be considered for further calculation by default</Text>
                                                                                            </Grid>}
                                                                                            {billingDetails?.effective_from?.isVisible &&
                                                                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                                                                <Date
                                                                                                    labelText={billingDetails?.effective_from?.required ? billingDetails?.effective_from?.label : billingDetails?.effective_from?.label + ' (Optional)'}
                                                                                                    name='effective_from'
                                                                                                    value={moment(billState.effective_from, dateFormat()).format(dateFormat())}
                                                                                                    height='56px'
                                                                                                    disabled={true || billingDetails?.effective_from?.isDisabled}
                                                                                                />
                                                                                            </Grid>}
                                                                                            {billingDetails?.end_date?.isVisible &&
                                                                                            <Grid item lg={4} md={4} sm={4} xs={12} pt={2}>
                                                                                                <Date
                                                                                                    labelText={billingDetails?.end_date?.label}
                                                                                                    name='effective_from'
                                                                                                    value={moment(billState.effective_to, dateFormat()).format(dateFormat())}
                                                                                                    height='56px'
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </Grid>}
                                                                                            <Grid container item lg={12} md={12} sm={12} pt={4} justifyContent={"space-between"}>
                                                                                                <Button disabled={saveLoading} blackCancel1 onClick={() => { setOldBillEdit(null); setformEdit(false); }}>Cancel</Button>
                                                                                                <LoadingButton saveBtn loading={saveLoading} onClick={() => handleBillSubmit()}>Save</LoadingButton>
                                                                                            </Grid>
                                                                                        </Grid> :
                                                                                        <Grid container p={{ lg: 3, md: 3, sm: 3, xs: 1.5 }}>
                                                                                            <Grid item lg={6} md={6} sm={6} xs={10}>
                                                                                                <Text largeBlue >Old Billing Details</Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={6} md={6} sm={6} xs={2} textAlign={"end"}>
                                                                                                {
                                                                                                    props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                                                                                                        <>
                                                                                                            {(oldBillEdit == null && !upBillEdit && !currentBillEdit) ? <img onClick={() => handleBillEdit('old', index)} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                                                                                        </>
                                                                                                        : ""
                                                                                                }
                                                                                            </Grid>
                                                                                            {billingDetails?.bill_type?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={4}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label : ''} >{billingDetails?.bill_type?.label?.length > 45 ? billingDetails?.bill_type?.label.substring(0,45) + "..." : billingDetails?.bill_type?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{item.bill_type ? item.bill_type == 1 ? "Hourly" : "Salary" : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {billingDetails?.bill_rate?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={4}>
                                                                                                {getFormattedLabel(billingDetails?.bill_rate)}
                                                                                                <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.bill_rate ? item.bill_rate : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {billingDetails?.ot_bill_rate?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={4}>
                                                                                                {getFormattedLabel(billingDetails?.ot_bill_rate)}
                                                                                                <Text mediumBlack14 pt={0.8} >{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.ot_bill_rate ? item.ot_bill_rate : "--"}</Text>
                                                                                            </Grid>}
                                                                                            {item.effective_to != "" ? 
                                                                                            <>
                                                                                            {billingDetails?.start_and_end_date?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.start_and_end_date?.label?.length > 45 ? billingDetails?.start_and_end_date?.label : ''} >{billingDetails?.start_and_end_date?.label?.length > 45 ? billingDetails?.start_and_end_date?.label.substring(0,45) + "..." : billingDetails?.start_and_end_date?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{moment(item.effective_from, dateFormat()).format(dateFormat())}{item.effective_to != "" ? ` - ${moment(item.effective_to, dateFormat()).format(dateFormat())}` : ""}</Text>
                                                                                             </Grid>}
                                                                                             </> :
                                                                                             <>
                                                                                            {billingDetails?.effective_from?.isVisible &&
                                                                                            <Grid item lg={3} md={3} sm={4} xs={12} pt={2}>
                                                                                                <Text mediumGreyHeader1 title={billingDetails?.effective_from?.label?.length > 45 ? billingDetails?.effective_from?.label : ''} >{billingDetails?.effective_from?.label?.length > 45 ? billingDetails?.effective_from?.label.substring(0,45) + "..." : billingDetails?.effective_from?.label}</Text>
                                                                                                <Text mediumBlack14 pt={0.8} >{moment(item.effective_from, dateFormat()).format(dateFormat())}{item.effective_to != "" ? ` - ${moment(item.effective_to, dateFormat()).format(dateFormat())}` : ""}</Text>
                                                                                            </Grid>}
                                                                                            </>}
                                                                                        </Grid>
                                                                                }
                                                                            </Box>
                                                                        ))
                                                                    }
                                                                </> : ""
                                                        }
                                                    </Grid>
                                                </Grid>
                                        }


                                    </>

                            }
                        </Grid>
                }

            </Grid>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => setOpen(false)}
            >
                <DialogContent >
                    <Grid container justifyContent={'center'} alignContent={'center'} p={{ lg: 4, md: 4, sm: 4, xs: 1 }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={placementPop} alt='placementPop' />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Are You Sure?</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                            <Hidden smDown>
                                <Text offBoardBodyFont>This change will be reflected as end date <br />for the previous billing details</Text>
                            </Hidden>
                            <Hidden smUp>
                                <Text offBoardBodyFont>This change will be reflected as end date for the previous billing details</Text>
                            </Hidden>
                        </Grid>
                        <Grid item lg={.5} md={1} sm={1} xs={1}></Grid>

                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                            <Stack direction={"row"} spacing={3}>
                                <Button outlineBlue className={classes.smallBlueButton} onClick={() => { setOpen(false); }} >No</Button>
                                <LoadingButton saveBtn className={classes.smallBlueButton} loading={saveLoading} onClick={() => placementBillStoreUpdate(newStore)}>Save</LoadingButton>
                                {/* <Button className={classes.smallBlueButton} smallBlue onClick={() => placementBillStoreUpdate(newStore)}>Yes</Button> */}
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={navigateOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent >
                    <Grid container py={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={1}>
                            <img src={offBoardSave} alt='noactive' />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'}>
                            <Text offBoardBodyFont>Your progress will not be saved</Text>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={'center'} gap="10px" textAlign={'center'} mt={3}>
                            <Button smallBlackOutline onClick={() => { setNavigateOpen(false) }} >
                                Cancel
                            </Button>
                            <Button smallBlue redBackground onClick={() => handleFinalCancel(tab)} >
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </Grid>
    )
}

export default ClientDetails