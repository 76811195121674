import APIURL from '../../../config/development';
import LocalStorage from '../../../utils/LocalStorage';
import BaseApi from '../../BaseApi';


class InvoiceListingAPI {

    listing(data) {
        return BaseApi.postWithToken(APIURL.client_URL + `invoice/listing`, data, LocalStorage.getAccessToken());
    }

    export(data) {
        return BaseApi.postDownloadExcel(APIURL.API_URL + `invoice/export`, data, LocalStorage.getAccessToken());
    }

    approvalLevels() {
        return BaseApi.getWithToken(APIURL.API_URL + `invoice/approval-levels?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    zohoIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/invoice/zoho_books`, data, LocalStorage.getAccessToken())
    }

    quickbookIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `zoho-sync/invoice/quick_books`, data, LocalStorage.getAccessToken())
    }

    zohoImportIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `invoice/zohobooks-sync`, data, LocalStorage.getAccessToken())
    }

    quickbookImportIntegration(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `invoice/quickbooks-sync`, data, LocalStorage.getAccessToken())
    }


}

export default new InvoiceListingAPI();