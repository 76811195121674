import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid, Skeleton, Stack } from '@mui/material'
import Text from '../../../../components/customText/Text'
import { cancelRed } from '../../../../theme';
import { formatNumberWithCommas, getCurrencySymbol } from '../../../../utils/utils';

function CurrentBalancePopup(props) {
    // eslint-disable-next-line
    const { currentBalanceRow, type } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [])

    return (
        <Grid container>
            <Grid container p={1}>
                <Grid item md={12} xs={12} p={1} pb={2} pt={2}>
                    <Text veryLargeLabel >{type == "current" ? 'Current Balance Breakdown' : 'Balance Impact Breakdown'}</Text>
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ width: "90%" }} />
            {
                type != 'current' ?
                    <Box p={2} sx={{ width: "100%" }}>
                        <Grid container spacing={3} py={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14> Wages</Text></Grid>

                            <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Earned Amount</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                                //  <Text mediumBlack sx={{ color: `#15803D !important` }} >+ {getCurrencySymbol()}
                                //   {formatNumberWithCommas(currentBalanceRow?.balance_impact_data.earned_amount ? 
                                //     currentBalanceRow?.balance_impact_data.earned_amount : 0)}</Text>


                                <Text mediumBlack
                                    sx={{
                                        color: `#15803D !important`
                                    }}
                                    >
                                    {currentBalanceRow?.balance_impact_data.earned_amount ?
                                        ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.balance_impact_data.earned_amount)}` : '-'}
                                </Text>


                            } </Grid>

                            <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Pay Amount</Text></Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

                                // <Text mediumBlack sx={{ color: `${cancelRed} !important` }} >- {getCurrencySymbol()}
                                //  {formatNumberWithCommas(currentBalanceRow?.balance_impact_data.salary_amount ?
                                //   currentBalanceRow?.balance_impact_data.salary_amount : 0)}</Text>


                                <Text mediumBlack
                                    sx={{
                                        color: `${cancelRed} !important`
                                    }}
                                    >
                                    {currentBalanceRow?.balance_impact_data.salary_amount ?
                                        ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.balance_impact_data.salary_amount)}` : '-'}
                                </Text>



                            }</Grid>
                            {/* {
                                currentBalanceRow?.balance_impact_data.deductions.length > 0 ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Deductions</Text></Grid> : ""
                            } */}
                            {/* {
                                currentBalanceRow?.balance_impact_data.deductions.length > 0 ?
                                    currentBalanceRow?.balance_impact_data.deductions.map((item) => (
                                        <>
                                            <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{item.name}</Text></Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

                                                // <Text mediumBlack sx={{ color: `${cancelRed} !important` }} >- {getCurrencySymbol()} {formatNumberWithCommas(item.raised_amount)}</Text>
                                                <Text black18px
                                                    sx={{
                                                        color: `${cancelRed} !important`
                                                    }}
                                                    >
                                                    {item.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(item.raised_amount)}` : '-'}</Text>


                                            } </Grid>
                                        </>
                                    ))

                                    : ''
                            } */}
                            {/* {
                                currentBalanceRow?.balance_impact_data.reimbursements.length > 0 ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Reimbursements</Text></Grid> : ""
                            } */}
                            {/* {
                                currentBalanceRow?.balance_impact_data.reimbursements.length > 0 ?
                                    currentBalanceRow?.balance_impact_data.reimbursements.map((item) => (
                                        <>
                                            <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{item.name}</Text></Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                            <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

                                                // <Text mediumBlack sx={{ color: `#15803D !important` }} >+ {getCurrencySymbol()} {formatNumberWithCommas(item.raised_amount)}</Text>
                                                <Text black18px
                                                    sx={{
                                                        color: item.raised_amount == 0 ? ''
                                                            : item.raised_amount > 0 ? `#15803D !important` : `${cancelRed} !important`
                                                    }}
                                                    >
                                                    {item.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(item.raised_amount)}` : '-'}</Text>



                                            } 
                                            </Grid>
                                        </>
                                    ))

                                    : ''
                            } */}


                        </Grid>

                    </Box>
                    :
                    currentBalanceRow.payment_mode == 1 ?
                        <Box p={2} sx={{ width: "100%" }}>
                            <Grid container spacing={3} pb={2}>
                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Opening Balance</Text></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack>{getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data.opening_balance)}</Text>} </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={3} py={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14> Wages</Text></Grid>

                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Earned Amount</Text></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                                    // <Text mediumBlack sx={{ color: `#15803D !important` }} >
                                    //     + {getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data.earned_amount)}</Text>

                                    <Text mediumBlack
                                        sx={{
                                            color: `#15803D !important`
                                        }}
                                        >
                                        {currentBalanceRow?.current_balance_data.earned_amount ?
                                            ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.current_balance_data.earned_amount)}` : '-'}
                                    </Text>


                                } </Grid>

                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Pay Amount</Text></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                                    // <Text mediumBlack sx={{ color: `${cancelRed} !important` }} >
                                    //     - {getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data.salary_amount)}</Text>

                                    <Text mediumBlack
                                        sx={{
                                            color: `${cancelRed} !important`
                                        }}
                                        >
                                        {currentBalanceRow?.current_balance_data.salary_amount ?
                                            ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.current_balance_data.salary_amount)}` : '-'}
                                    </Text>



                                }</Grid>

                                {/* {
                                    currentBalanceRow?.current_balance_data.deductions.length > 0 ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Deductions Affecting Balance Sheet</Text></Grid> : ""
                                }
                                {
                                    currentBalanceRow?.current_balance_data.deductions.length > 0 ?
                                        currentBalanceRow?.current_balance_data.deductions.map((item) => (
                                            <>
                                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{item.name}</Text></Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

                                                    //  <Text mediumBlack sx={{ color: `${cancelRed} !important` }} >- {getCurrencySymbol()} {formatNumberWithCommas(item.raised_amount)}</Text>

                                                    <Text mediumBlack
                                                        sx={{
                                                            color: `${cancelRed} !important`
                                                        }}
                                                        >
                                                        {item.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(item.raised_amount)}` : '-'}</Text>
                                                } </Grid>
                                            </>
                                        ))

                                        : ''
                                }
                                {
                                    currentBalanceRow?.current_balance_data.reimbursements.length > 0 ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14>Reimbursements Affecting Balance Sheet</Text></Grid> : ""
                                }
                                {
                                    currentBalanceRow?.current_balance_data.reimbursements.length > 0 ?
                                        currentBalanceRow?.current_balance_data.reimbursements.map((item) => (
                                            <>
                                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{item.name}</Text></Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :

                                                    // <Text mediumBlack sx={{ color: `#15803D !important` }} >+ {getCurrencySymbol()} {formatNumberWithCommas(item.raised_amount)}</Text>

                                                    <Text mediumBlack
                                                        sx={{
                                                            color: item?.raised_amount == 0 ? ''
                                                                : item?.raised_amount > 0 ? `#15803D !important` : `${cancelRed} !important`
                                                        }}
                                                        >
                                                        {item.raised_amount ? ` ${getCurrencySymbol()} ${formatNumberWithCommas(item.raised_amount)}` : '-'}</Text>


                                                } </Grid>
                                            </>
                                        ))

                                        : ''
                                } */}

                            </Grid>
                            <Divider />
                            <Grid container spacing={3} py={2}>
                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Balance Impact</Text></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                                    // <Text mediumBlack sx={{ color: `#15803D !important` }} >
                                    //     + {getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data.balance_impact)}</Text>

                                    <Text mediumBlack
                                        sx={{
                                            color: currentBalanceRow?.current_balance_data.balance_impact == 0 ? ''
                                                : currentBalanceRow?.current_balance_data.balance_impact > 0 ? `#15803D !important` : `${cancelRed} !important`
                                        }}
                                        >
                                        {currentBalanceRow?.current_balance_data.balance_impact ?
                                            ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.current_balance_data.balance_impact)}` : '-'}
                                    </Text>

                                } </Grid>
                            </Grid>

                        </Box>
                        :
                        <Box p={2}>
                            <Grid container spacing={3} pb={2}>
                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{currentBalanceRow?.payment_mode == 3 ? 'Current Balance' : 'Opening Balance'}</Text></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack>{getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data ? currentBalanceRow?.current_balance_data?.current_balance : currentBalanceRow?.current_balance_data?.current_balance)}</Text>} </Grid>
                                        :
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack>{getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data ? currentBalanceRow?.current_balance_data?.opening_balance : 0)}</Text>} </Grid>
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>New Current Balance</Text></Grid>
                                        : ''}
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                        : ''}
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack>{getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data ? currentBalanceRow?.current_balance_data?.new_current_balance : 0)}</Text>} </Grid>
                                        : ''}
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>{currentBalanceRow?.entity_type != 2 ? 'Reimbursed' : 'Deducted'} {currentBalanceRow?.current_balance_data.is_reversal ? 'Reversal' : 'add'}</Text></Grid>}
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>}
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack sx={{ color: currentBalanceRow?.current_balance_data?.balance_impact > 0 ? `#15803D !important` : `${cancelRed} !important` }}>{getCurrencySymbol()} {formatNumberWithCommas(currentBalanceRow?.current_balance_data?.balance_impact ? currentBalanceRow?.current_balance_data?.balance_impact : 0)}</Text>} </Grid>
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Transaction ID</Text></Grid>
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ? '' :
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack> {currentBalanceRow?.current_balance_data?.transaction_id ? currentBalanceRow?.current_balance_data?.transaction_id : '-'}</Text>} </Grid>
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Balance Impact</Text></Grid>
                                        : ''
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                        : ''
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack
                                            sx={{
                                                color: currentBalanceRow?.current_balance_data.balance_impact == 0 ? ''
                                                    : currentBalanceRow?.current_balance_data.balance_impact > 0 ? `#15803D !important` : `${cancelRed} !important`
                                            }}
                                            >
                                            {currentBalanceRow?.current_balance_data.balance_impact ?
                                                ` ${getCurrencySymbol()} ${formatNumberWithCommas(currentBalanceRow?.current_balance_data.balance_impact)}` : '-'}
                                        </Text>} </Grid>
                                        : ''
                                }
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Balance Modified by</Text></Grid>
                                        : ''}
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                                        : ''}
                                {
                                    currentBalanceRow?.payment_mode == 3 ?
                                        <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                                            <Stack direction={'column'}>
                                                <Text mediumBlack> {currentBalanceRow?.current_balance_data?.created_by ? currentBalanceRow?.current_balance_data?.created_by : '-'}</Text>
                                                <Text mediumGreyHeader> {currentBalanceRow?.current_balance_data?.employee_role_id ? currentBalanceRow?.current_balance_data?.employee_role_id : '-'}</Text>
                                            </Stack>

                                        } </Grid>
                                        : ''}
                            </Grid>
                            <Divider />
                            <Grid container spacing={3} py={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}><Text blue14> </Text></Grid>

                                <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack>Comments</Text></Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {
                                        currentBalanceRow?.payment_mode == 3 ?

                                            <Text smallBlack sx={{
                                                fontSize: '13px !important', fontWeight: '400 !important', wordBreak: 'break-all',
                                                whiteSpace: 'normal'
                                            }}>
                                                {currentBalanceRow?.current_balance_data?.reason_for_change ? currentBalanceRow?.current_balance_data.reason_for_change : "--"}
                                            </Text>
                                            :
                                            <Text smallBlack sx={{
                                                fontSize: '13px !important', fontWeight: '400 !important', wordBreak: 'break-all',
                                                whiteSpace: 'normal'
                                            }}>
                                                {currentBalanceRow?.current_balance_data?.remarks ? currentBalanceRow?.current_balance_data.remarks : "--"}
                                            </Text>
                                    }
                                </Grid>

                            </Grid>

                        </Box>

            }
            <Box m={0} width={'100%'} sx={{ background: "#D1E1FF", paddingTop: "2px" }}>
                <Grid container p={3} spacing={3}>
                    <Grid item lg={5} md={5} sm={5} xs={5}><Text mediumBlack600>{type == 'current' ? 'Current Balance' : 'Balance Impact'}</Text></Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"center"}>-</Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5} textAlign={"end"}>{loading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <Text mediumBlack600 >{getCurrencySymbol()} {formatNumberWithCommas(type == 'current' ? currentBalanceRow?.current_balance : currentBalanceRow?.balance_impact)}</Text>}</Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default CurrentBalancePopup