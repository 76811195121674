import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import LocalStorage from '../../../../utils/LocalStorage';
import { BlackToolTip } from '../../../../utils/utils';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Button from '../../../../components/customButton/Button';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg';
import { getFormattedLabel } from '../../../../utils/utils';
import MaskDataView from '../../../../components/input/MaskDataView';

export default function ViewGeneralDetails(props) {// eslint-disable-next-line
    const basicDetailsConfig = props.generalDetailsConfig?.basicDetails;
    const contactDetailsConfig = props.generalDetailsConfig?.contactDetails;
    const currentAddressDetailsConfig = props.generalDetailsConfig?.currentAddressDetails;
    const employmentDetailsConfig = props.generalDetailsConfig?.employmentDetails;
    const [employee, setEmployee] = useState(props.employee)
    const [clearPopup, setclearPopup] = useState(false);
    const currency = LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$';

    useEffect(() => {
        setEmployee(props.employee)
    }, [props])

    const editDetails = () => {
        if (props.employee.pending_requests.general_details) {
            setclearPopup(true)
        }
        else {
            props.setEdit(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container p={{lg:2,md:2,sm:2,xs:0.5}}>
            <Grid item lg={11} md={11} sm={11} xs={9}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            {
                employee.is_off_boarding_initiated === false &&
                <Grid item lg={1} md={1} sm={1} xs={3}>
                    <Box p={1} pr={0}>
                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                    </Box>
                </Grid>
            }
            {basicDetailsConfig?.first_name?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    {getFormattedLabel(basicDetailsConfig?.first_name)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.first_name && employee.first_name.length > 16 ?
                                <BlackToolTip title={employee.first_name} placement="top" arrow>
                                    {employee.first_name.slice(0, 16) + (employee.first_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.first_name
                        }

                    </Text>
                </Box>
            </Grid>}
            {basicDetailsConfig?.middle_name?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                {getFormattedLabel(basicDetailsConfig?.middle_name)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.middle_name && employee.middle_name.length > 16 ?
                                <BlackToolTip title={employee.middle_name} placement="top" arrow>
                                    {employee.middle_name.slice(0, 16) + (employee.middle_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.middle_name
                        }
                    </Text>
                </Box>
            </Grid>}
            {basicDetailsConfig?.last_name?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    {getFormattedLabel(basicDetailsConfig?.last_name)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.last_name && employee.last_name.length > 16 ?
                                <BlackToolTip title={employee.last_name} placement="top" arrow>
                                    {employee.last_name.slice(0, 16) + (employee.last_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.last_name
                        }
                    </Text>
                </Box>
            </Grid>}
            <Grid item lg={3} md={0} sm={0} xs={12}></Grid>
            {basicDetailsConfig?.dob?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    {getFormattedLabel(basicDetailsConfig?.dob)}
                    <Text pt={1} mediumBlack14>
                        {employee.dob}
                    </Text>
                </Box>
            </Grid>}
            {basicDetailsConfig?.gender?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    {getFormattedLabel(basicDetailsConfig?.gender)}
                    <Text pt={1} mediumBlack14>
                        {employee.gender}
                    </Text>
                </Box>
            </Grid>}
            {
                employee.gender == 'Prefer to self-describe' && basicDetailsConfig?.gender_description?.isVisible ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                        {getFormattedLabel(basicDetailsConfig?.gender_description)}
                            <Text pt={1} mediumBlack14 sx={{wordBreak: 'break-word',overflowWrap: 'break-word',whiteSpace: 'pre-wrap'}}>
                                {employee.gender_description?employee.gender_description:"-"}
                                {/* {
                                    employee.gender_description && employee.gender_description.length > 16 ?
                                        <BlackToolTip title={employee.gender_description} placement="top" arrow>
                                            {employee.gender_description.slice(0, 16) + (employee.gender_description.length > 16 ? "..." : "")}
                                        </BlackToolTip>
                                        : employee.gender_description
                                } */}
                            </Text>
                        </Box>
                    </Grid>
                    : ''
            }
            {basicDetailsConfig?.blood_group?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                 {getFormattedLabel(basicDetailsConfig?.blood_group)}
                    <Text pt={1} mediumBlack14>
                        {employee.blood_group ? employee.blood_group : '-'}

                    </Text>
                </Box>
            </Grid>}
            {basicDetailsConfig?.marital_status_id?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(basicDetailsConfig?.marital_status_id)}
                    <Text pt={1} mediumBlack14>
                        {employee.marital_status}
                    </Text>
                </Box>
            </Grid>}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            {contactDetailsConfig?.contact_number?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                {getFormattedLabel(contactDetailsConfig?.contact_number)}
                    <Text pt={1} mediumBlack14>
                        {employee.contact_number}

                    </Text>
                </Box>
            </Grid>}
            {contactDetailsConfig?.alternate_contact_number?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(contactDetailsConfig?.alternate_contact_number)}
                    <Text pt={1} mediumBlack14>
                        {employee.alternate_contact_number ? employee.alternate_contact_number : '-'}
                    </Text>
                </Box>
            </Grid>}
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            {contactDetailsConfig?.email_id?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                {getFormattedLabel(contactDetailsConfig?.email_id)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.email_id && employee.email_id.length > 16 ?
                                <BlackToolTip title={employee.email_id} placement="top" arrow>
                                    {employee.email_id.slice(0, 16) + (employee.email_id.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.email_id
                        }
                    </Text>
                </Box>
            </Grid>}
            {contactDetailsConfig?.alternate_email_id?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(contactDetailsConfig?.alternate_email_id)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.alternate_email_id && employee.alternate_email_id.length > 16 ?
                                <BlackToolTip title={employee.alternate_email_id} placement="top" arrow>
                                    {employee.alternate_email_id.slice(0, 16) + (employee.alternate_email_id.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.alternate_email_id ? employee.alternate_email_id : '-'
                        }
                    </Text>
                </Box>
            </Grid>}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Employee ID
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.reference_id}
                    </Text>
                </Box>
            </Grid>
            {employmentDetailsConfig?.date_of_joining?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                   {getFormattedLabel(employmentDetailsConfig?.date_of_joining)}
                    <Text pt={1} mediumBlack14>
                        {employee.date_of_joining}
                    </Text>
                </Box>
            </Grid>}
            {employmentDetailsConfig?.employment_type_id?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                   {getFormattedLabel(employmentDetailsConfig?.employment_type_id)}
                    <Text pt={1} mediumBlack14>
                        {employee.employment_type}
                    </Text>
                </Box>
            </Grid>}
            {
                employee.employment_type_id !== 1 && employmentDetailsConfig?.employment_category_id?.isVisible &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                    {getFormattedLabel(employmentDetailsConfig?.employment_category_id)}
                        <Text pt={1} mediumBlack14>
                            {employee.employee_category}
                        </Text>
                    </Box>
                </Grid>
            }
            {
                employee.employment_type_id == 3 && employmentDetailsConfig?.company_id?.isVisible && <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                    {getFormattedLabel(employmentDetailsConfig?.company_id)}
                        <Text pt={1} mediumBlack14>
                            {employee.company_name}
                        </Text>
                    </Box>
                </Grid>
            }
            {
                employee.employment_type_id == 1 &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel>
                            Role
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.role_name}
                        </Text>
                    </Box>
                </Grid>
            }
           {employmentDetailsConfig?.ssn?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    {getFormattedLabel(employmentDetailsConfig?.ssn)}
                    <MaskDataView value={employee.ssn} />
                    {/* <Text pt={1} mediumBlack14>
                        {employee.ssn ? employee.ssn : '-'}
                    </Text> */}
                </Box>
            </Grid>}
            {employmentDetailsConfig?.is_usc?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(employmentDetailsConfig?.is_usc)}
                    <Text pt={1} mediumBlack14>
                        {employee.is_usc == 0 ? 'No' : 'Yes'}
                    </Text>
                </Box>
            </Grid>}
            {employee.is_usc == 0 && employmentDetailsConfig?.visa_type_id?.isVisible  ?
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                       {getFormattedLabel(employmentDetailsConfig?.visa_type_id)}
                        <Text pt={1} mediumBlack14>
                        {employee.visa_type && employee.visa_type.length > 16 ?
                                <BlackToolTip title={employee.visa_type} placement="top" arrow>
                                    {employee.visa_type.slice(0, 16) + (employee.visa_type.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.visa_type ? employee.visa_type : '--'
                            }
                        </Text>
                    </Box>
                </Grid>
                : ''}
            {employmentDetailsConfig?.opening_balance?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                {getFormattedLabel(employmentDetailsConfig?.opening_balance)}
                    <Text pt={1} mediumBlack14>
                        {currency} {employee.opening_balance ? employee.opening_balance : 0}
                    </Text>
                </Box>
            </Grid>}
            {
                employee.enable_health_insurance ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Text mediumLabel>
                                Health Insurance
                            </Text>
                            <Text pt={1} mediumBlack14>
                                {employee.enable_health_insurance ? 'Enabled' : '-'}
                            </Text>
                        </Box>
                    </Grid> : ''
            }
            {
                employee.enable_health_insurance && employmentDetailsConfig?.health_insurance_amount?.isVisible ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.health_insurance_amount)}
                            <Text pt={1} mediumBlack14>
                                {currency} {employee.health_insurance_amount ? employee.health_insurance_amount : 0}
                            </Text>
                        </Box>
                    </Grid>
                    : ''}
            {
                ['H-1B', 'H-1B1', 'E3'].includes(employee.visa_type) && employmentDetailsConfig?.standard_pay_amount?.isVisible ?
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                        {getFormattedLabel(employmentDetailsConfig?.standard_pay_amount)}
                            <Text pt={1} mediumBlack14>
                                {currency} {employee.standard_pay_amount ? employee.standard_pay_amount : 0}
                            </Text>
                        </Box>
                    </Grid>
                    : ''
            }
            {
                ['H-1B', 'H-1B1', 'E3'].includes(employee.visa_type) ?
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box p={1} pt={2}>
                            <Text mediumLabel>
                                Make Standard Wage the Default Salary?
                            </Text>
                            <Text pt={1} mediumBlack14>
                                {employee.make_standard_pay_as_salary ? 'Enabled' : '-'}
                            </Text>
                        </Box>
                    </Grid>
                    : ''}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                    </Text>
                </Box>
            </Grid>
            {currentAddressDetailsConfig?.address_line_one?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(currentAddressDetailsConfig?.address_line_one)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.address_line_one && employee.address_line_one.length > 16 ?
                                <BlackToolTip title={employee.address_line_one} placement="top" arrow>
                                    {employee.address_line_one.slice(0, 16) + (employee.address_line_one.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.address_line_one
                        }
                    </Text>
                </Box>
            </Grid>}
            {currentAddressDetailsConfig?.address_line_two?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                  {getFormattedLabel(currentAddressDetailsConfig?.address_line_two)}
                    <Text pt={1} mediumBlack14>
                        {
                            employee.address_line_two && employee.address_line_two.length > 16 ?
                                <BlackToolTip title={employee.address_line_two} placement="top" arrow>
                                    {employee.address_line_two.slice(0, 16) + (employee.address_line_two.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.address_line_two ? employee.address_line_two : '-'
                        }
                    </Text>
                </Box>
            </Grid>}
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            {currentAddressDetailsConfig?.location?.country?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel title={currentAddressDetailsConfig?.location?.country?.label?.length > 28 ? currentAddressDetailsConfig?.location?.country?.label : ''}>
                    {currentAddressDetailsConfig?.location?.country?.label?.length > 28 ? currentAddressDetailsConfig?.location?.country?.label?.substring(0,28)+"...": currentAddressDetailsConfig?.location?.country?.label}
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.country_name}
                    </Text>
                </Box>
            </Grid>}
            {currentAddressDetailsConfig?.location?.state?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel title={currentAddressDetailsConfig?.location?.state?.label?.length > 28 ? currentAddressDetailsConfig?.location?.state?.label : ''}>
                    {currentAddressDetailsConfig?.location?.state?.label?.length > 28 ? currentAddressDetailsConfig?.location?.state?.label?.substring(0,28)+"...": currentAddressDetailsConfig?.location?.state?.label}
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.state_name}
                    </Text>
                </Box>
            </Grid>}
            {currentAddressDetailsConfig?.location?.city?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                   <Text mediumLabel title={currentAddressDetailsConfig?.location?.city?.label?.length > 28 ? currentAddressDetailsConfig?.location?.city?.label : ''}>
                    {currentAddressDetailsConfig?.location?.city?.label?.length > 28 ? currentAddressDetailsConfig?.location?.city?.label?.substring(0,28)+"...": currentAddressDetailsConfig?.location?.city?.label}
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.city && employee.city.length > 16 ?
                                <BlackToolTip title={employee.city} placement="top" arrow>
                                    {employee.city.slice(0, 16) + (employee.city.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.city
                        }
                    </Text>
                </Box>
            </Grid>}
            {currentAddressDetailsConfig?.location?.zip_code?.isVisible &&
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel title={currentAddressDetailsConfig?.location?.zip_code?.label?.length > 28 ? currentAddressDetailsConfig?.location?.zip_code?.label : ''}>
                    {currentAddressDetailsConfig?.location?.zip_code?.label?.length > 28 ? currentAddressDetailsConfig?.location?.zip_code?.label?.substring(0,28)+"...": currentAddressDetailsConfig?.location?.zip_code?.label}
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.zip_code}
                    </Text>
                </Box>
            </Grid>}
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, sm: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={"center"} alignContent={'center'} mt={3}>
                        <Button finishFilledQuicksand onClick={() => { setclearPopup(false) }} >
                            Okay
                        </Button>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}