import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Box, Typography, Tabs, Tab, Skeleton, Grid, ButtonGroup, Stack } from '@mui/material';
// eslint-disable-next-line
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi'; // eslint-disable-next-line
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, dateFormat, openDocumentInNewtab, removeLoader, getFormattedLabel } from '../../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../../components/customButton/Button';
import LocalStorage from '../../../../utils/LocalStorage';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg'
import noDataFound from '../../../../assets/svg/NoDataFoundIcon.svg'
import FileInput from '../../../../components/muiFileInput/FileInput';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select'
import Date from '../../../../components/datePicker/Date';
import moment from 'moment';
import CommonApi from '../../../../apis/CommonApi';
import { isValid, validate_input_fields } from '../../../../components/Validation';
import LoadingScreen from './LoadingScreen';
import OnBoardApi from '../../../../apis/admin/employees/OnBoardApi';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../assets/svg/placementPop.svg'
import Userplus from '../../../../assets/svg/plus.svg';
import changesSent from '../../../../assets/svg/changesSent.svg'
import { ErrorMessages } from '../../../../utils/ErrorMessages';
import MaskDataView from '../../../../components/input/MaskDataView';

export default function Driving(props) {
    const drivingLicenseConfig = props.drivingLicenseConfig;
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listi94, setListi94] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listWork, setListWork] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [error, setError] = useState({
        document_number: "",
        document_type_id: ""
    });
    const [state, setState] = useState(
        {
            document_type_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: ""
                }
            ],
            documents_deleted_ids: [],
            clear_all: false
        }
    );
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [clearPopup1, setclearPopup1] = useState(false);
    const [clearPopup2, setclearPopup2] = useState(false);
    const [clearPopup3, setclearPopup3] = useState(false);
    const [eye, setEye] = useState({ document_number: false });

    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition !== 'work' && props.documentPosition !== 'education') {
            if (props.formEdit != true) {
                getDriving();
            }
        }
        // eslint-disable-next-line
    }, [props.documentPosition])


    const getDriving = () => {
        setLoading(true)
        setError({
            ...error,
            document_number: '',
            document_type_id: ''
        })

        OnBoardApi.documentIndexConsultant(LocalStorage.uid(), props.employee.id, LocalStorage.getAccessToken()).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        let data = response.data.data
                        let b = data.find((i) => i.document_type_id == 1 || i.document_type_id == 3)
                        if (b !== undefined) {
                            setList([b]);
                            if (b.documents.length === 0) {
                                let data = b
                                data.documents = [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        document_name: ""
                                    }
                                ]
                                setState({ ...data })
                            }
                            else {
                                setState(b)
                            }
                        }
                        else {
                            setState({
                                document_type_id: "",
                                valid_from: "",
                                valid_till: "",
                                document_number: "",
                                documents: [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        document_name: ""
                                    }
                                ],
                                documents_deleted_ids: [],
                                id: '',
                                clear_all: false
                            })
                        }
                    }
                    else {
                        setState({
                            document_type_id: "",
                            valid_from: "",
                            valid_till: "",
                            document_number: "",
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    document_name: ""
                                }
                            ],
                            documents_deleted_ids: [],
                            id: '',
                            clear_all: false
                        })
                        setList({
                            document_type_id: "",
                            valid_from: "",
                            valid_till: "",
                            document_number: "",
                            documents_deleted_ids: [],
                            documents: [],
                        })
                    }
                } else {
                    addErrorMsg(response.data.message);
                    setLoading(false)
                }
            }, 400)
        });
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }
    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);

        CommonApi
            .documentUpload("personal-document", formData, LocalStorage.getAccessToken())
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    let docInfo = response.data.data
                    // const data = {
                    //     id: docInfo.id,
                    //     url: docInfo.document_url
                    // }
                    removeLoader()
                    // EmployeesApi.ocrDocumentUpload('dl-data', data).then((res) => {
                    //     if (res.data.statusCode == 1003) {
                    //         removeLoader()
                    //         document.body.addEventListener("mousemove", function (e) {
                    //         })
                    //         state.document_number = res.data.data.driving_license_number
                    //         state.valid_from = moment(res.data.data.issued_date).format(dateFormat())
                    //         state.valid_till = moment(res.data.data.expiry_date).format(dateFormat())
                    //         state.document_type_id = res.data.data.is_dl ? 1 : 3
                    //         state.documents[0].new_document_id = response.data.data.id
                    //         state.documents[0].document_url = response.data.data.document_url
                    //         state.documents[0].document_name = value.target.files[0].name
                    //         state.clear_all = false
                    //         setState({ ...state })
                    //         handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                    //     } else {
                    //         removeLoader()
                            state.documents[0].new_document_id = response.data.data.id
                            state.documents[0].document_url = response.data.data.document_url
                            state.documents[0].document_name = value.target.files[0].name
                            state.clear_all = false
                            setState({ ...state })
                            handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                    //         addErrorMsg(res.data.error.detail);
                    //     }
                    // })
                } else {
                    addErrorMsg(response.data.message);
                    removeLoader()
                }
            });
    };

    const handleChange = (e) => {
        state.clear_all = false
        setState({
            ...state, [e.target.name]: e.target.value
        })
        handleValidations(e.target);
    };

    const handleChangeDate = (e, name) => {
        state.clear_all = false
        let data = { name: name, value: e == null ? '' : moment(e.$d).format(dateFormat()) }
        if (data.name == 'valid_from') {
            state.valid_till = ''
        }
        setState({ ...state, [data.name]: data.value })
        handleValidations(data);
    };

    const handleValidations = (input) => {
        // let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "document_number":
                err.document_number = validate_input_fields(input.value, drivingLicenseConfig?.document_number);
                break;
            case "document_type_id":
                err.document_type_id = ''
                break;
            default:
                break;
        }
        setError(err);
    };

    const handleDocumentValidations = (input) => {
        let err = error;
        switch (input.name) {
            case "documents":
                err.documents = validate_input_fields(input.value, drivingLicenseConfig?.document_number);
                break;
            default:
                break;
        }
        setError(err);
    }

    const deleteDoc = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].document_name = ''
        state.clear_all = false
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    const clearAll = () => {
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({
            ...state,
            document_type_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: ""
                }
            ],
            clear_all: true
        })
        setError({
            ...error,
            document_type_id: '',
            document_number: ''
        })
        setclearPopup(false)
    }

    const options = [
        {
            id: 1,
            value: `Driving License`
        },
        {
            id: 3,
            value: `State ID`
        },
    ]

    const submitForm = (args) => {
        if (error.document_number !== '') {
            addErrorMsg(ErrorMessages.documentNumberDrivingAndStateID)
            return true
        }
        let data = {
            document_id: state.documents[0].new_document_id,
            document_number: state.document_number,
            issue: state.document_type_id,
            date_issue: state.valid_from,
            date_end: state.valid_till
        }
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid();
            state['employee_id'] = props.employee.id;
            if (isValid(data)) {
                state['clear_all'] = true;
            }
            // if (state['document_type_id'] == '') {
            //     state['document_type_id'] = 1
            // }
            if (state.clear_all == false && (state.document_number === '' || state.document_type_id === '')) {
                if (state.document_number === '') {
                    error.document_number = 'This field is required'
                }
                if (state.document_type_id === '') {
                    error.document_type_id = 'This field is required'
                }
                setError({ ...error })
            }
            else {
                state.entity = 'personal-documents'
                if (args) {
                    setclearPopup2(true)
                }
                else {
                    OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`Changes Saved`);
                            setclearPopup1(true)
                            setformOpen(false);
                            getDriving();
                            setEye({ document_number: false })
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            }
        }
        else {
            if (isValid(data)) {
                setformOpen(false);
                return true
            }
            else {
                if (state.document_number === '' || state.document_type_id === '') {
                    if (state.document_number === '') {
                        error.document_number = 'This field is required'
                    }
                    if (state.document_type_id === '') {
                        error.document_type_id = 'This field is required'
                    }
                    setError({ ...error })
                }
                else {
                    state['request_id'] = LocalStorage.uid()
                    state['employee_id'] = props.employee.id
                    state.entity = 'personal-documents'
                    // if (state['document_type_id'] == '') {
                    //     state['document_type_id'] = 1
                    // }
                    if (args) {
                        setclearPopup2(true)
                    }
                    else {
                        OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode === 1003) {
                                addSuccessMsg(`Details Added`);
                                setformOpen(false);
                                setclearPopup1(true)
                                getDriving();
                                setEye({ document_number: false })
                                props.setformEdit(false)
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                }
            }

        }
    }

    const editDetails = () => {
        if (props.employee.pending_requests.personal_documents) {
            setclearPopup3(true)
        }
        else {
            setformOpen(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container>
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={10}>
                            <Box p={1} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }} pt={2}>
                                <Text largeBlue>
                                    Driving License/State ID
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                (employee && employee.is_off_boarding_initiated === false && list.length > 0) ?
                                    <Grid item lg={1} md={1} sm={1} xs={2}>
                                        <Box p={1} pr={0}>
                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                list.length > 0 ?
                                    <Grid item container lg={12} md={12} sm={12} xs={12}>

                                        {drivingLicenseConfig?.document_type_id?.isVisible && <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                {getFormattedLabel(drivingLicenseConfig?.document_type_id)}
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].document_type_name ? list[0].document_type_name : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>}
                                        {drivingLicenseConfig?.document_number?.isVisible && <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                {getFormattedLabel(drivingLicenseConfig?.document_number)}
                                                {/* <Text pt={1} mediumBlack14>
                                                    {list[0].document_number ? list[0].document_number : '-'}
                                                </Text> */}
                                                <MaskDataView value={list[0].document_number} />
                                            </Box>
                                        </Grid>}
                                        <Grid item lg={6}></Grid>
                                        {drivingLicenseConfig?.valid_from?.isVisible && <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                {getFormattedLabel(drivingLicenseConfig?.valid_from)}
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_from ? list[0].valid_from : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>}
                                        {drivingLicenseConfig?.valid_till?.isVisible && <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                {getFormattedLabel(drivingLicenseConfig?.valid_till)}
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_till ? list[0].valid_till : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>}
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                                <Text mediumLabel>
                                                    Driving License/State ID
                                                </Text>
                                                {list[0].documents.length > 0 && list[0].documents[0].document_name !== '' ?
                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(list[0].documents[0], list[0].documents[0].document_url) }}>
                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBlue>
                                                            <BlackToolTip arrow placement='top' title={list[0].documents[0].document_name}>
                                                                {list[0].documents[0].document_name.slice(0, 16)} {list[0].documents[0].document_name.length > 16 ? '...' : ''}
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Stack>
                                                    : '-'
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container justifyContent={'center'} alignContent={'center'}>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={2}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload Driving License/State ID document for this </Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={1}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>employee by clicking on the below button.</Text>
                                        </Grid>
                                        {
                                            employee.is_off_boarding_initiated === false &&
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'center'} pt={3}>
                                                <Button finishFilledQuicksandLargeWidth onClick={() => { editDetails() }}> <img src={Userplus} alt='plus' /> Add ID Details</Button>
                                            </Grid>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={{ lg: 2, md: 2, sm: 2, xs: 0 }} pl={0}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                        <FileInput
                                            name={"Driving License"}
                                            FileName={state.documents[0].document_name}
                                            handleChange={uploadDocs}
                                            label={drivingLicenseConfig?.new_document_id?.label?.name}
                                            documentUrl={state.documents[0].document_url}
                                            handleDelete={() => deleteDoc()}
                                            actionState={state.documents[0].document_name ? 1 : ''}
                                            state={state.documents[0]}
                                            labelSize={58}
                                        />
                                    </Box>
                                </Grid>
                                {drivingLicenseConfig?.document_type_id?.isVisible &&
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                        <Select
                                            options={options}
                                            name='document_type_id'
                                            value={state.document_type_id}
                                            onChange={handleChange}
                                            label={drivingLicenseConfig?.document_type_id?.label}
                                            disabled={drivingLicenseConfig?.document_type_id?.isDisabled}
                                            error={error.document_type_id}
                                        />
                                        <Text red>{error.document_type_id}</Text>
                                    </Box>
                                </Grid>}
                                {drivingLicenseConfig?.document_number?.isVisible &&
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'document_number',
                                                value: state.document_number,
                                                type: eye.document_number ? 'text' : 'password',
                                                inputProps: { maxLength: 16 }
                                            }}
                                            handleChange={handleChange}
                                            // clientInput
                                            disabled={drivingLicenseConfig?.document_number?.isDisabled}
                                            labelText={drivingLicenseConfig?.document_number?.label}
                                            eyeIcon={eye.document_number}
                                            eyeCloseIcon={eye.document_number == false}
                                            eyeHandleChange={() => { setEye({ ...eye, document_number: !eye.document_number }) }}
                                            iconText
                                            error={error.document_number}
                                        />
                                        <Text red>{error.document_number}</Text>
                                    </Box>
                                </Grid>}

                                <Grid item lg={4}></Grid>
                                {drivingLicenseConfig?.valid_from?.isVisible &&
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                        <Date
                                            labelText={drivingLicenseConfig?.valid_from?.label}
                                            disabled={drivingLicenseConfig?.valid_from?.isDisabled}
                                            name={"valid_from"}
                                            maxDate={moment().format(dateFormat())}
                                            value={state.valid_from}
                                            onChange={(e) => handleChangeDate(e, "valid_from")}
                                        />
                                    </Box>
                                </Grid>}
                                {drivingLicenseConfig?.valid_till?.isVisible &&
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                        <Date
                                            labelText={drivingLicenseConfig?.valid_till?.label}
                                            name={"valid_till"}
                                            value={state.valid_till}
                                            minDate={state.valid_from}
                                            disabled={state.valid_from == '' || drivingLicenseConfig?.valid_till?.isDisabled}
                                            onChange={(e) => handleChangeDate(e, "valid_till")}
                                        />
                                    </Box>
                                </Grid>}
                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} pl={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineQuicksand onClick={() => { setformOpen(false); getDriving(); props.setformEdit(false); setEye({ document_number: false }) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'} >
                                            <Button clearAllRed onClick={() => { setclearPopup(true); setEye({ document_number: false }) }}>Clear all</Button>
                                            <Button smallBlue onClick={() => { submitForm('popup'); setEye({ document_number: false }) }}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }
                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                    </Grid>
            }
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup2} setOpenPopup={setclearPopup2} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to make this changes?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup2(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { submitForm(); setclearPopup2(false); }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup1} setOpenPopup={setclearPopup1} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changesSent} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Changes Sent For Approval</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>"Thank you for updating your details. Your changes</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>have been submitted for approval to your employer. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Once approved, the new details will be displayed here. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Click 'OK' to proceed."</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Button finishFilledQuicksand onClick={() => { setclearPopup1(false) }} >
                            Okay
                        </Button>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} mx={{ lg: 10, md: 10, s: 5, xs: 2 }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} textAlign={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container textAlign={'center'} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                            Okay
                        </Button>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>

    );
}