import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, styled, Skeleton, Dialog, DialogContent } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import Slide from '@mui/material/Slide';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import Input from '../../../../../components/input/Input';
import { isValid, validate_emptyField } from '../../../../../components/Validation';
import Button from '../../../../../components/customButton/Button';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import { BlackToolTip, ListLoadingIcon, NoDataFound, NoPermission, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalizeAndAddSpace, dateFormat, removeLoader } from '../../../../../utils/utils';
import CustomMenu from '../../configComponents/customMenu/Menu';
import LocalStorage from '../../../../../utils/LocalStorage';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import AnnouncementsApi from '../../../../../apis/configurations/employee/AnnouncementsApi';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import CommonApi from '../../../../../apis/CommonApi';
import moment from 'moment';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
// import circleIcon from '../../../../../assets/svg/crossIcon.svg'
import FileInput from '../../../../../components/muiFileInput/FileInput';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        // height: '350px',
        //   width: '692px',
        padding: '0px !important',
        borderRadius: "8px",
    },
    "& .MuiDialogContent-root": {
        padding: '0px !important',
    },
    "& .MuiDialogActions-root": {
        padding: '0px !important'
    },
    "& .MuiDialog-container": {
        background: 'rgba(0, 0, 0, 0.55) !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Announcements({ current }) {

    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles();
    const recentSection = useRef(null); // eslint-disable-next-line
    const [value, setValue] = useState('1');
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
        heading: "",
        sub_text: "",
        size: "",
        banner_url: "",
        document_name: "",
        banner_document_id: "",
        platform: "",
        publish_date: moment().format(dateFormat()),
        is_active: true
    })
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })// eslint-disable-next-line
    const [filter, setFilter] = useState({
        platform: "Website",
        limit: 5,
        page: 1,
        search: "",
    });
    const [activityTotal, setActivityTotal] = useState()
    const activityRef = useRef(null);
    const [activityData, setActivityData] = useState([])
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(false);
    const [getData, setGetData] = useState([]);
    const [view, setView] = useState(false);
    // const editorRef = useRef(null);
    const [recentActivityShow, setrecentActivityShow] = useState(false)
    const [listLoading, setListLoading] = useState(false);
    const [chromeAlert, setChromeAlert] = useState(false);

    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true)))) {
                getAnnouncementsListing(filter)
                getActivity(activityFilter)
            }
        }// eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (chromeAlert) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [chromeAlert]);


    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(29, "", args).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total);
                if (popup) { }
                else {
                    setrecentActivityShow(true)
                }
            }
            // removeLoader();
            setListLoading(false);

        });
    };

    const getAnnouncementsListing = (args) => {
        if (filter.limit <= 5) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        AnnouncementsApi.getAnnouncementsListing(args).then((response) => {
            setLoading(false);
            // removeLoader();
            setListLoading(false);
            if (response.data.statusCode == 1003) {
                setGetData(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };

    const storeAnnouncements = (data) => {
        data.request_id = LocalStorage.uid();
        data.platform = value === "1" ? "Website" : "Mobile";
        setLoading(true)
        AnnouncementsApi.StoreAnnouncements(data).then((response) => {
            setLoading(false);
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setError({})
                setForm(false);
                getAnnouncementsListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        });
    };

    const deleteAnnouncements = (dataId) => {
        let data = { request_id: LocalStorage.uid() };
        AnnouncementsApi.deleteAnnouncements(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                setOpen(false);
                getAnnouncementsListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };

    const handleDelete = () => {
        deleteAnnouncements(val.id)
    }

    const handleChangeInput = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        handleValidations(e);
    };

    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "heading":
                err.heading = validate_emptyField(input.value);
                break;
            case "sub_text":
                err.sub_text = validate_emptyField(input.value);
                break;
            case "banner_document_id":
                err.banner_document_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const validateAll = () => {
        let { heading, sub_text, banner_document_id } = state;
        let errors = {};
        errors.heading = validate_emptyField(heading);
        errors.sub_text = validate_emptyField(sub_text);
        errors.banner_document_id = validate_emptyField(banner_document_id);
        return errors;

    };

    const handleSave = () => {
        // if (editorRef.current) {
        //     const canvas = editorRef.current.getImageScaledToCanvas();
        //     const croppedImage = canvas.toDataURL(); // Get the cropped image as a data URL
        //     console.log("croppedImage = ", croppedImage);

        //     // Convert the data URL to a Blob
        //     const byteString = atob(croppedImage.split(',')[1]);
        //     const mimeString = croppedImage.split(',')[0].split(':')[1].split(';')[0];
        //     const arrayBuffer = new ArrayBuffer(byteString.length);
        //     const uintArray = new Uint8Array(arrayBuffer);
        //     for (let i = 0; i < byteString.length; i++) {
        //         uintArray[i] = byteString.charCodeAt(i);
        //     }
        //     const blob = new Blob([arrayBuffer], { type: mimeString });

        //     // Create a FormData object and append the blob as a file
        //     const formData = new FormData();
        //     formData.append('croppedImage', blob);
        // }
        let errors = validateAll();
        if (isValid(errors)) {
            storeAnnouncements(state);
        } else {
            setError(errors);
            setLoading(false)
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const handleViewClick = (item) => {
        setState(item);
        setView(true);
    };

    const uploadDocs = async (e) => {
        const formData = new FormData();
        formData.append("files", e.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        const fileSizeInMB = e.target.files[0].size / (1024 * 1024); // Convert bytes to MB
        const formattedSize = fileSizeInMB.toFixed(2);
        if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "application/jpeg" || e.target.files[0].type == "application/png" || e.target.files[0].type == "application/jpg") {
            if (formattedSize <= 25) {
                let requiredWidth, requiredHeight;
                if (value === '1') {
                    requiredWidth = 628;
                    requiredHeight = 140;
                } else {
                    requiredWidth = 327;
                    requiredHeight = 142;
                }
                try {
                    const image = await createImageBitmap(e.target.files[0]);
                    const width = image.width;
                    const height = image.height;
                    // Check dimensions
                    if (width > requiredWidth || height > requiredHeight) {
                        addErrorMsg(`Upload image must be in ${value === "1" ? "628x140" : "327x142"} pixels`);
                        return false;
                    }
                    addLoader(true);
                    CommonApi.documentUpload("announcement-documents", formData, LocalStorage.getAccessToken()).then((response) => {
                        removeLoader();
                        if (response.data.statusCode == 1003) {
                            state['banner_document_id'] = response.data.data.id;
                            state["document_name"] = response.data.data.document_name;
                            state["banner_url"] = response.data.data.document_url;
                            state.size = formattedSize;
                            setState({ ...state });
                            error['banner_document_id'] = "";
                            setError({ ...error });
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    });
                } catch (error) {
                    console.error("Error creating ImageBitmap:", error);
                }
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Upload document Format JPG/PNG");
        }
    }
    const [val, setVal] = useState(null);
    const [open, setOpen] = useState(false);

    const handleDeleteClick = (args) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_delete" && item.is_allowed == true)))) {
            setVal(args)
            setOpen(true);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleFormEnable = () => {
        state.heading = "";
        state.sub_text = "";
        state.banner_url = "";
        state.document_name = "";
        state.banner_document_id = "";
        state.size = "";
        state.is_active = true;
        state.publish_date = moment().format(dateFormat());
        setState({ ...state });
        setError({});
        setForm(true);
        setChromeAlert(true);
    }

    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleDeleteDoc = () => {
        state.banner_document_id = "";
        state.banner_url = "";
        state.document_name = "";
        state.size = "";
        setState({ ...state });
        error['banner_document_id'] = ErrorMessages.fieldRequired;
        setError({ ...error });
        setDeleteOpen(false);
    }

    // const clearUploadedImage = () => {
    //     setPicture({
    //         ...picture,
    //         cropperOpen: false,
    //         img: null,
    //         zoom: 2,
    //         croppedImg: ""
    //     });
    // }

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                if (Number(activityTotal) >= activityFilter.limit) {
                    // addLoader(true);
                    setListLoading(true);
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        });
        setrecentActivityShow(false)
    }

    const checkPreview = () => {
        return state.sub_text !== '' && state.heading !== '' && state.document_name !== '';
    }

    const HandleScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        if (scrollTop >= 106) {
            setrecentActivityShow(false);
        }
    };

    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_view" && item.is_allowed == true))) ?
                    <Box onScroll={HandleScroll} sx={{
                        height: '70vh', padding: '16px', overflow: "auto", /*'&::-webkit-scrollbar': { display: 'none', }*/
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px",
                        },
                    }}>
                        <Box className={classes.activeItemBox} >
                            <Box className={classes.activeBoxHeading}>
                                <Text profileTitle>Web Announcements</Text>
                            </Box>


                            {
                                form ?
                                    <Grid container>
                                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                    picture.cropperOpen ?
                                        <div style={{ position: "relative" }}>
                                            <AvatarEditor
                                                ref={editorRef}
                                                width={600}
                                                height={126}
                                                image={picture.img}
                                                borderRadius={1}
                                            />
                                            <CancelIcon onClick={() => { clearUploadedImage() }} sx={{
                                                color: "red !important",
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                cursor: 'pointer'
                                            }} />
                                        </div>
                                        :
                                            <Box className={classes.dropBox1}>
                                                <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                                        <section className={classes.dropZone}>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {
                                                                    isDragActive ?
                                                                        <Grid container spacing={1} justifyContent='center'>
                                                                            <Text greyLabel >Drop file here</Text>
                                                                        </Grid>
                                                                        :
                                                                        <Grid container spacing={1} justifyContent='center'>
                                                                            <Grid item lg={12} textAlign='center'>
                                                                                <Text largeBlack mt={2}>Drag & Drop Images here or here or <span style={{ color: '#0C75EB' }}>Browse</span> to Upload </Text>
                                                                            </Grid>
                                                                            <Grid item lg={12} textAlign='center'>
                                                                                <Text greyLabel >{getTextBasedOnValue()}</Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                }
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Box>
                                            } 

                                        </Grid> */}
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                                            <Box pt={'9px'}>
                                                <FileInput
                                                    name='banner_document_id'
                                                    handleChange={(e) => uploadDocs(e)}
                                                    FileName={state.document_name ? state.document_name : ''}
                                                    actionState={state.document_name ? 1 : ''}
                                                    handleDelete={() => setDeleteOpen(true)}
                                                    label={`Announcement Image ${state.document_name ? "" : (value === "1" ? "(628x140)" : "(327x142)")} ${state.document_name ? "" : "- 25MB"}`}
                                                    error={error ? error.banner_document_id : ''}
                                                    documentUrl={state.banner_url ? state.banner_url : ''}
                                                />
                                            </Box>
                                            {/* {
                                                state.banner_url !== "" ?
                                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                        <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#0C75EB !important" }}>Uploaded Document</Text>
                                                        <Grid item container lg={12} md={12} columnGap={1.5}>
                                                            <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                                <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important` }}>
                                                                    <a href={state.banner_url} style={{ color: "#0C75EB" }} target="_blank" rel="noreferrer">
                                                                        {state.document_name.length > 16 ?
                                                                            <BlackToolTip arrow placement='top' title={state.document_name}>
                                                                                {state.document_name.slice(0, 16)} {state.document_name.length > 16 ? '...' : ''}
                                                                            </BlackToolTip>
                                                                            :
                                                                            <>
                                                                                {state.document_name}
                                                                            </>}
                                                                    </a>
                                                                </Text>
                                                                <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { setDeleteOpen(true) }} />
                                                            </Box>
                                                        </Grid>
                                                    </Box> :
                                                    <Box sx={{ border: error && error.banner_document_id ? "1px solid red" : "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "15px 10px" }}>
                                                        <Text smallLabel sx={{ font: "14px Quicksand !important", color: error && error.banner_document_id ? "red !important" : "#707070 !important" }}>Uploaded Document</Text>
                                                        <Grid item container lg={12} columnGap={1.5}>
                                                        </Grid>
                                                    </Box>
                                            } */}
                                            <Text errorText> {error.banner_document_id ? error.banner_document_id : ""}</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'heading',
                                                    value: state.heading,
                                                    type: 'text',
                                                    inputProps: { maxLength: 70 },
                                                }}
                                                handleChange={handleChangeInput}
                                                clientInput
                                                labelText={'Heading'}
                                                error={error.heading}
                                            />
                                            <Text errorText> {error.heading ? error.heading : ""}</Text>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                // multiline={true}
                                                rows={3}
                                                inputProps={{
                                                    name: 'sub_text',
                                                    value: state.sub_text,
                                                    type: 'text',
                                                    inputProps: { maxLength: 100 },
                                                }}
                                                handleChange={handleChangeInput}
                                                clientInput
                                                labelText={'Sub Text'}
                                                // placeholder={'Type Something'}
                                                error={error.sub_text}
                                            />
                                            <Text errorText> {error.sub_text ? error.sub_text : ""}</Text>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={3} xs={12} pt={5} textAlign={{ lg: 'start', md: 'start', sm: 'start', xs: 'center' }}>
                                            <Button cancelBtnBorder disabled={loading} onClick={() => { setForm(false); setChromeAlert(false); }} sx={{ height: "42px !important", width: "114px !important" }}>Cancel</Button>
                                        </Grid>
                                        <Grid item container lg={6} md={8} sm={9} xs={12} pt={{ lg: 5, md: 5, sm: 5, xs: 1 }} gap="8px" justifyContent={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-end', xs: 'space-around' }} >
                                            {
                                                checkPreview() ?
                                                    <Button onClick={() => setView(true)} cancelBtnBorder sx={{ height: "42px !important", width: "114px !important" }}>Preview</Button>
                                                    : <Button disable={true} cancelBtnBorder sx={{ height: "42px !important", width: "114px !important" }}>Preview</Button>
                                            }
                                            <LoadingButton sx={{ width: "110px !important" }} saveBtn loading={loading} onClick={() => handleSave()}>Upload</LoadingButton>
                                        </Grid>
                                    </Grid>

                                    :
                                    <>
                                        <Box sx={{ height: '35vh', overflow: 'auto' }}>
                                            {
                                                loading ? <>
                                                    {[1, 2, 3].map((item, index) => (
                                                        <Grid spacing={2} container key={index} mt={3}>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}>
                                                                <Skeleton animation="wave" />
                                                                <Skeleton animation="wave" />
                                                            </Grid>
                                                            <Grid item lg={4} md={4} sm={4} xs={4}>
                                                                <Skeleton animation="wave" />
                                                                <Skeleton animation="wave" />
                                                            </Grid>
                                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                                <Skeleton animation="wave" height={40} />
                                                            </Grid>
                                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                                <Skeleton animation="wave" />
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </>
                                                    :
                                                    <>
                                                        {getData.length > 0 ?
                                                            <>
                                                                {getData.map((item, index) => (
                                                                    <Box className={classes.descriptionBoxStyle} key={index}>
                                                                        <Grid container alignItems="center" spacing={1}>
                                                                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                                                                <Text mediumBlackColor>
                                                                                    {
                                                                                        item.heading ? item.heading.length > 30 ?
                                                                                            <BlackToolTip title={capitalizeAndAddSpace(item.heading)} placement="top" arrow>
                                                                                                {capitalizeAndAddSpace(item.heading.slice(0, 30)) + capitalizeAndAddSpace(item.heading.length > 30 ? "..." : "")}
                                                                                            </BlackToolTip>
                                                                                            : capitalizeAndAddSpace(item.heading) : '--'
                                                                                    }
                                                                                </Text>
                                                                                <Text greyLabel mt={0.5}>{item.size ? `${item.size}mb` : "--"}</Text>
                                                                            </Grid>
                                                                            <Grid item lg={3} md={3} sm={3} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                                <Text mediumBlackColor>Publish Date</Text>
                                                                                <Text greyLabel mt={0.5}>{item.publish_date ? item.publish_date : "--"}</Text>
                                                                            </Grid>
                                                                            <Grid item lg={2} md={2} sm={2} xs={12} textAlign={'center'}>
                                                                                <CustomMenu
                                                                                    Icon={<MenuIcon />}
                                                                                    handleMenuClick={handleMenuClick}
                                                                                    anchorEl={anchorEl}
                                                                                    isOpen={Boolean(anchorEl)}
                                                                                    onClose={handleCloseMenu}
                                                                                    children={
                                                                                        LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_delete" && item.is_allowed == true)) ?
                                                                                            [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                                            { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) }
                                                                                            ]
                                                                                            :
                                                                                            [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                                            </>
                                                        }
                                                    </>
                                            }
                                            {listLoading && (
                                                <Box >
                                                    <ListLoadingIcon />
                                                </Box>
                                            )}
                                        </Box>
                                        <>
                                            {
                                                !loading && getData.length < 5 && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_details_create" && item.is_allowed == true))) ?
                                                    <Button addTitleBtn onClick={() => handleFormEnable()}>Add New Banner</Button> :
                                                    <Button addTitleBtnDisable>Add New Banner</Button>
                                            }
                                        </>
                                    </>
                            }

                        </Box>
                        {form ? "" :
                            <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                                <Box mb={3}>
                                    <Text RegularBlack1>Recent Activities</Text>
                                </Box>
                                <Box sx={{
                                    height: '40vh', overflowY: 'auto',
                                    //  '&::-webkit-scrollbar': {
                                    //   display: 'none',
                                    // },
                                }}
                                    ref={activityRef}
                                    onScroll={activityHandleScroll}>
                                    {loading ?
                                        <>
                                            {[1, 2, 3].map((item, index) => (
                                                <Grid container key={index} mt={3}>
                                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                                        <Skeleton animation="wave" width="200px" />
                                                        <Skeleton animation="wave" width="200px" />
                                                    </Grid>
                                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                                        <Skeleton animation="wave" width="200px" />
                                                        <Skeleton animation="wave" width="200px" />

                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </> :
                                        <>
                                            {activityData.length > 0 ?
                                                <>
                                                    {activityData.map((value) => (
                                                        <Box className={classes.descriptionBoxStyle} mb={2}>
                                                            <Grid container alignItems={"center"} spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                                <Grid item lg={4} md={4} sm={6} xs={12} >
                                                                    <Text mediumBlackColor>{
                                                                        value.user_name && value.user_name.length > 16 ?
                                                                            <BlackToolTip title={value.user_name} placement="top" arrow>
                                                                                {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : value.user_name
                                                                    } {value.action}</Text>
                                                                    <Text greyLabel mt={"5px"}>{value.created_at}</Text>
                                                                </Grid>
                                                                <Grid container item lg={8} md={8} sm={6} xs={12}>
                                                                    {
                                                                        value.change_log.length > 0 && value.change_log.map((item) => (
                                                                            <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    ))}
                                                </> :
                                                <>
                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                </>
                                            }
                                        </>
                                    }
                                </Box>
                            </Box>}
                        {form ? "" :
                            recentActivityShow ?
                                <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                                    <Button addButton
                                        sx={{ position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`, }}
                                        onClick={scrollDown}
                                        endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                                </Grid>
                                : ''}

                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }

            {open && <ReusableDelete newDelete={true} content={"Do you want to delete this Announcement?"} open={open} setOpen={setOpen} onClick={() => { handleDelete() }} />}
            {deleteOpen && <ReusableDelete newDelete={true} content={"Do you want to delete this Document?"} open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { handleDeleteDoc() }} />}

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { setView(false) }}
                aria-labelledby="customized-dialog-title"
                open={view}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent >
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6} p={{ lg: 4, md: 4, sm: 4, xs: 1 }}>
                            <Text largeBlack sx={{ font: "24px Quicksand !important", fontWeight: `${600} !important` }}>{state.heading ? state.heading : ""}</Text>
                            <Text mediumGrey2 pt={1}>{state.sub_text ? state.sub_text : ""}</Text>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Box position={"relative"} height="100%" width="100%">
                                {state.banner_url && <img src={state.banner_url} alt="banner" style={{ height: "-webkit-fill-available", width: "-webkit-fill-available" }} />}
                                {/* <img src={circleIcon} alt="icon" onClick={() => { setView(false) }} style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }} /> */}
                            </Box>
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default Announcements;