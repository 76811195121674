import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Skeleton, SwipeableDrawer, Stack } from '@mui/material';
import MainStyles from '../../MainStyles';
import ToggleSwitch from '../../../../../components/toggle/CustomToggle';
import CustomMenu from '../../configComponents/customMenu/Menu';
import Text from '../../../../../components/customText/Text';
import Button from '../../../../../components/customButton/Button';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg, addErrorMsg, addWarningMsg, NoDataFound, NoPermission, BlackToolTip, ListLoadingIcon, removeLoader, addLoader } from '../../../../../utils/utils';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import ReminderDrawer from './ReminderDrawer';
import Sure from '../../../../../assets/svg/placementPop.svg'
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import RemindersApi from '../../../../../apis/configurations/reminders/RemindersApi';

function InvoiceReminders({ current }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
        search: "",
    });

    const [total, setTotal] = useState();
    const [listLoading, setListLoading] = useState(false);
    const [recentActivityShow, setrecentActivityShow] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [remDrawer, setRemDrawer] = useState(false);
    const [currentCTA, setCurrentCTA] = useState('');
    const [deletePopup, setdeletePopup] = useState(false);
    const [deactivatePopUp, setDeactivatePopUp] = useState(false);
    const [activityTotal, setActivityTotal] = useState();
    const [activityData, setActivityData] = useState([]);
    const [selectedToggle, setSelectedToggle] = useState({});
    const [selectedReminderDelete, setSelectedReminderDelete] = useState({});
    const [clickedViewReminder, setClickedViewItem] = useState({});
    const [clickedEditReminder, setClickedEditReminder] = useState({});

    const [getData, setGetData] = useState(

        [
            
        ]
    );

    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })


    const recentSection = useRef(null);
    const activityRef = useRef(null);
    const innerBoxRef = useRef(null);

    const getActivity = (args, popup) => {
        let id = 38;
        ConfigApi.getActivity(id, "", args).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setActivityData(response.data.data);
                    setActivityTotal(response.data.pagination.total);
                    if (popup) { }
                    else {
                        setrecentActivityShow(true)
                    }
                }
            }, 300)
            removeLoader();
        });
    };



    const getInvoicesListing = (args) => {
        if (filter.limit < 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }

        RemindersApi.invoicesList(args).then(response => {
            setTimeout(() => {
                setLoading(false);
                setListLoading(false);

                if (response.data.statusCode == 1003) {

                    setGetData(response.data.data);
                    setTotal(response.data.pagination.total)
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 300)
        })
    }

    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)))) {
            setTimeout(() => {
                setLoading(true)
                getInvoicesListing(filter);
                getActivity(activityFilter)
            }, 300)

        }// eslint-disable-next-line
    }, [])

    const handleScroll = () => {
        const { current } = innerBoxRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;

            if (scrollTop + clientHeight >= scrollHeight - 5) {

                if (Number(total) >= filter.limit) {
                    filter.limit = Number(filter.limit) + 5;
                    setFilter({ ...filter });
                    getInvoicesListing(filter);
                }
            }
        }
    };
    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        })
        setrecentActivityShow(false)
    }
    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight) {

                if (activityTotal >= activityFilter.limit) {
                    addLoader(true);
                    setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
                    getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1, }, true);
                }

            }
        }
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditClick = (data) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true)))) {
            
            setRemDrawer(true);
            setCurrentCTA('edit');
            setClickedEditReminder(data);

        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    };

    const handleViewClick = (data) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true)))) {
            
            setRemDrawer(true);
            setCurrentCTA('view');
            setClickedViewItem(data);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const handleDeleteClick = (item) => {
        
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_delete" && item.is_allowed == true)))) {
            setdeletePopup(true)
            setSelectedReminderDelete(item)
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const handleDeleteReminder = () => {

        RemindersApi.deleteReminder(selectedReminderDelete.id).then(response => {
            if (response.data.statusCode == 1003) {
                addSuccessMsg(response.data.message);
                getInvoicesListing(filter);
                setdeletePopup(false);
                setSelectedReminderDelete({});

            } else {
                addErrorMsg(response.data.message)
            }
        })
    }

    const handleSetRemDrawer = () => {
        getInvoicesListing(filter);
        setRemDrawer(false)
    }

    const handleToggleSwitch = (item) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_edit" && item.is_allowed == true)))) {

        setSelectedToggle(item);
        setDeactivatePopUp(true);}
    }

    const deactivateReminder = () => {
        const data = {
            is_active: !selectedToggle.is_active,
            request_id: LocalStorage.uid()
        }

        RemindersApi.updateReminderToggle(selectedToggle.id, data).then(response => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    addSuccessMsg(response.data.message);
                    getInvoicesListing(filter);
                    setDeactivatePopUp(false);
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 300)
        })
    }

    const handleDeactivateCancel = () => {
        setDeactivatePopUp(false);
        setSelectedToggle({});
    }
    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_view" && item.is_allowed == true))) ?
                    <Box sx={{
                        height: '70vh', overflow: 'auto', padding: '16px !important',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px !important",
                        },
                    }}>
                        <Box className={classes.activeItemBox}>
                            <Box className={classes.activeBoxHeading}>
                                <Grid container>
                                    <Grid item lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                                        <Text profileTitle>{current}</Text>
                                    </Grid>

                                </Grid>

                            </Box>
                            <Box sx={{ height: '35vh', overflow: 'auto' }} ref={innerBoxRef} onScroll={handleScroll}>
                                {
                                    loading ?
                                        <>
                                            {[1, 2, 3].map((item, index) => (
                                                <Grid spacing={2} container key={index} mt={3}>
                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <Skeleton animation="wave" />
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <Skeleton animation="wave" />
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={12}>
                                                        <Skeleton animation="wave" height={40} />
                                                    </Grid>
                                                    <Grid item lg={1} md={1} sm={1} xs={12}>
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {getData?.length > 0 ?
                                                <>
                                                    {getData?.map((item, index) => (
                                                        <Box className={classes.descriptionBoxStyle} key={index}>
                                                            <Grid container alignItems="center" spacing={2}>
                                                                <Grid item lg={7} md={6} sm={6} xs={12}>
                                                                    <Text mediumBlackColor >
                                                                        <BlackToolTip arrow title={item.name}>{item.name ? item.name.length > 40 ? item.name.slice(0, 40) + '...' : item.name : "--"}</BlackToolTip></Text>
                                                                    
                                                                </Grid>
                                                                <Grid item lg={3} md={3} sm={3} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <Text mediumBlackColor>Created By</Text>
                                                                    <Text greyLabel mt="4px">
                                                                        {item.created_by && item.created_by.length > 16 ?
                                                                            <BlackToolTip title={item.created_by} placement="top" arrow>
                                                                                {item.created_by.slice(0, 16) + (item.created_by.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            : item.created_by ? item.created_by : '--'
                                                                        }</Text>
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <ToggleSwitch name='is_active' isActive={item.is_active}
                                                                        switchChange={() => handleToggleSwitch(item)}
                                                                        sx={{ height: '24px !important', cursor: 'pointer !important' }} />
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} textAlign={"center"} sx={{ cursor: 'pointer !important' }}>
                                                                    <CustomMenu
                                                                        Icon={<MenuIcon />}
                                                                        handleMenuClick={handleMenuClick}
                                                                        anchorEl={anchorEl}
                                                                        isOpen={Boolean(anchorEl)}
                                                                        onClose={handleClose}

                                                                        children=
                                                                        {

                                                                            [
                                                                                { color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                                { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },

                                                                                { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) }
                                                                            ]
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                </>
                                            }
                                        </>
                                }
                                {listLoading && (
                                    <Box >
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>
                            <>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "invoice_create" && item.is_allowed == true))) ?
                                        <Button addTitleBtn sx={{ cursor: 'pointer !important' }}
                                            onClick={() => { setRemDrawer(true); setCurrentCTA('add') }}
                                        >Add Reminder</Button> :
                                        <Button addTitleBtnDisable>Add Reminder</Button>
                                }
                            </>

                        </Box>
                        
                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text cardTitle>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto' }}
                                ref={activityRef}
                                onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8} md={8} sm={8} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                                                            <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                                <Text mediumBlackColor>{
                                                                    value.user_name && value.user_name.length > 16 ?
                                                                        <BlackToolTip title={value.user_name} placement="top" arrow>
                                                                            {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                                                        </BlackToolTip>
                                                                        : value.user_name
                                                                } {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                            </Grid>
                                                            <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                } {listLoading && (

                                    <Box >
                                        <ListLoadingIcon />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {recentActivityShow ?
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                                <Button addButton
                                    sx={{
                                        position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                        "@media (min-width: 300px) and (max-width: 1420px)": {
                                            font: "12px Quicksand !important",
                                            fontWeight: `${700} !important`
                                        }
                                    }}
                                    onClick={scrollDown}
                                    endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                            </Grid>
                            : ''}
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
            <SwipeableDrawer
                anchor={'right'}
                open={remDrawer}
                onClose={() => { setRemDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ReminderDrawer remDrawer={remDrawer} current={current} currentCTA={currentCTA} clickedViewReminder={clickedViewReminder} clickedEditReminder={clickedEditReminder} setRemDrawer={handleSetRemDrawer} getActivity={()=>getActivity(activityFilter)}/>
            </SwipeableDrawer>
            <ReusablePopup openPopup={deletePopup} setOpenPopup={setdeletePopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to delete.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>


                            <Button onClick={() => { setdeletePopup(false); setSelectedReminderDelete({}) }} outlineBlueMedium1 sx={{ height: "inherit !important", width: "80px !important", borderRadius: "8px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }}>No</Button>
                            <Button finishFilledQuicksand onClick={handleDeleteReminder} >
                                Yes, Delete
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={deactivatePopUp} setOpenPopup={setDeactivatePopUp} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you really wish to {selectedToggle.is_active ? 'deactivate' : 'activate'}.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button onClick={handleDeactivateCancel} outlineBlueMedium1 sx={{ height: "inherit !important", width: "80px !important", borderRadius: "8px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }}>No</Button>
                            <Button finishFilledQuicksand sx={{ width: '155px !important' }}
                                onClick={() => { deactivateReminder() }}
                            >
                                Yes, {selectedToggle.is_active ? 'Deactivate' : 'Activate'}
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </div>
    )
}

export default InvoiceReminders