import { makeStyles } from "@mui/styles";
import { blue, btnBgBlue, btnBgGrey, btnBgRed, btnStroke, red } from "../../../theme";
import { useTheme } from "@mui/material";

const ClientStyles = makeStyles(() => ({
    activeText: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        opacity: 1,
        fontWeight: `${500} !important`,
        textTransform: 'capitalize !important',
        padding: '0px 25px !important'
    },
    tabText: {
        font: "15px Quicksand !important",
        letterSpacing: '0px',
        color: `#707070 !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
        padding: '0px 25px !important'
    },
    viewText: {
        borderBottom: '1px solid #EAECF0 !important',
        font: '13px Quicksand !important',
        padding: '10px 0px 10px 15px !important',
        "&:hover": {
            background: 'white !important'
        }
    },

    inactive: {
        font: '13px Quicksand !important',
        padding: '10px 0px 4px 15px !important'
    },
    rejectText: {
        borderBottom: '1px solid #EAECF0 !important',
        font: '13px Quicksand !important',
        color: `${red} !important`,
        padding: '10px 0px 10px 15px !important',
        "&:hover": {
            background: 'white !important'
        }
    },
    deleteText: {
        font: '13px Quicksand !important',
        color: `${btnBgRed.shade2} !important`,
        padding: '10px 0px 3px 15px !important',
        "&:hover": {
            background: 'white !important'
        }
    },
    Paper: {
        display: "flex",
        alignItems: "center",
        width: '44px !important',
        height: '44px ! important',
        border: `1px solid ${btnBgGrey.shade4} !important`,
        boxShadow: "none !important",
        borderRadius: '8px !important',
        cursor: 'pointer',
        background: '#FFFFFF !important'
    },
    InputBase: {
        flex: 1,
        font: '13px Quicksand !important',
        color: "rgba(38, 38, 38, 1)",
        background: '#FFFFFF !important',
        opacity: 1,
        borderRadius: '5px !important',
        fontWeight: `${400} !important`,
        width: '100%',
        height: '30px ! important',
        textAlign: 'left !important',
        '& input': {
            textAlign: 'left !important',
            paddingLeft: '10px !important'
        },
    },

    /// Add Vendor
    browseButton: {
        background: `${btnBgBlue.shade3} !important`,
        color: `${btnStroke.blueMain} !important`,
        textAlign: "center",
        padding: "5px 5px 5px 0px !important",
        borderRadius: "6px !important",
        width: "100px",
        cursor: "pointer !important",
        height: "35px",
        font: '14px Quicksand !important',
        textTransform: "none !important",
        boxShadow: "none !important",
        fontWeight: '400 !important'
    },
    optional: {
        color: '#C7CCD3 !important',
        marginLeft: '4px !important',
        font: '15px Quicksand !important',
        fontWeight: `${400} !important`
    },
    paymentForm: {
        height: '293px !important',
        width: '243px !important',
        boxShadow: '0px 0px 20px 0px #0000000D !important',
        borderRadius: '8px !important',
        padding: '30px 32px 10px 32px  !important',
    },
    helperTextError: {
        color: '#e72929 !important',
        font: '11px Quicksand !important',
    },
    cursor: {
        cursor: 'pointer !important',
    },

    // New Screen Styles
    ActiveBg: {
        background: "#0C75EB", height: '35px', padding: "6px 15px", borderRadius: "8px", cursor: "pointer", display: "flex", alignItems: 'center', justifyContent: 'center'
    },
    inactiveBg: {
        background: "transparent", padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer"
    },
    tabBg: {
        display: "flex", background: "#F4F8FF", height: '51px', borderRadius: "12px !important", alignItems: 'center', textAlign: 'center', padding: "12px 10px !important", gap: 3, maxWidth: "max-content",
        [useTheme().breakpoints.up('md')]: {
            minWidth: '300px !important'
        }
    },
    addIcon: {
        height: '22px', width: '22px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        }
    },
    zohoIcon: {
        height: '24px', width: '24px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    zohoRefresh: {
        height: '24px', width: '24px', marginLeft: '7px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    syncedSpan: {
        display: 'flex',
        justifyContent: 'center',
    },
    searchIcon: {
        color: `${btnBgGrey.shade4} !important`, paddingRight: '10px !important'
    },
    menuItem: {
        height: "35px",
        "&:hover": {
            background: 'none !important'
        },
        "&:focus": {
            background: 'none !important'
        }
    },
    divider: {
        margin: "0px !important", color: '#E2E5E6 !important'
    },
    newCustomAccordion: {
        padding: "6px 12px !important",
        backgroundColor: "#ffffff !important",
        margin: "2px 0px !important",
        borderRadius: "20px !important",
        boxShadow: "5px 5px 10px 0px #0000000D !important",
        width: "100% !important",
        '&:before': { display: "none" },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        }
    },
    AccordionSummary: {
        backgroundColor: "#ffffff",
        height: "70px",
        [useTheme().breakpoints.down('md')]: {
            padding: "0px !important",
        }
    },
    AccordionSummaryBox: {
        width: "60%", display: "flex", alignItems: "center", gap: 2
    },
    AccordionDetails: {
        backgroundColor: "#f1f8ff",
        height: "80px",
        borderRadius: "16px",
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    activeStatus: {
        height: "25px", width: '100px !important', borderRadius: "20px", padding: "3px", background: '#16A34A', font: '14px Quicksand', fontWeight: `${600} !important`, color: '#fff'
    },
    inactiveStatus: {
        height: "25px", width: '100px !important', borderRadius: "20px", padding: "3px", background: '#494747', font: '14px Quicksand', fontWeight: `${600} !important`, color: '#fff'
    },
    infoIcon: {
        height: '16px', width: '16px', margin: '0px 0px -2px 4px'
    },
    statusGrid: {
        cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "end"
    },
    dividerContact: {
        width: '100% !important', color: '#C7CCD3 !important', margin: '10px !important'
    },
    scrollContent: {
        maxHeight: "64.8vh",
        overflowY: 'scroll !important',
        alignItems: 'start',
        justifyContent: 'start',
        paddingLeft: '5px',
        paddingRight: '10px',
        marginTop: '15px !important',
        "@media (min-height: 850px)": {
            maxHeight: '72.5vh'
        }
    },
    scrollContentClient: {
        maxHeight: "56.4vh",
        overflowY: 'scroll !important',
        alignItems: 'start',
        justifyContent: 'start',
        paddingLeft: '5px',
        paddingRight: '10px',
        marginTop: '15px !important',
        "@media (min-height: 850px)": {
            maxHeight: '72.5vh'
        }
    },
    padding: {
        padding: '20px 0px 0px 0px !important'
    },
    padding8: {
        paddingTop: '8px !important'
    },
    paddingTop: {
        padding: '20px 0px !important'
    },
    activeMenuItem: {
        font: "12px Quicksand !important",
        fontWeight: `${600} !important`,
        color: "#000000 !important",
        height: "35px !important",
        background: "#EAECF0 !important"
    },
    EmployeesSearchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        font: '12px Quicksand !important',
        fontWeight: `${400} !important`,
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    inputBox: {
        height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "350px"
    },
    borderButton: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "42px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important"
    },
    quickbooksbg: {
        background: '#DFFCE9 !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    arrowSize: {
        width: '20px !important',
        height: '20px !important',
        cursor: 'pointer !important'
    },
    zohobooksbg: {
        background: '#C8DBEC !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    verifiedIcon: {
        width: '32px !important',
        height: '32px !important'
    },
    qbverifiedBox: {
        background: '#15803D',
        borderRadius: '8px',
        padding: '20px 30px',
        marginTop: '20px',
        textAlign: 'center !important',
    },
    greyAvatar: {
        filter: 'grayscale(100%)',
        opacity: 0.2,
        width: '24px !important',
        height: '24px !important'
    },
    activeProfile: {
        width: '24px !important',
        height: '24px !important'
    },


    // Invoice Configuration
    boxContainer: {
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        width: "64px",
        height: "52px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        cursor: "pointer",
        textAlign: 'center'
    },
    percentileIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: "8px",
    },
    selectionIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    verticalDivider: {
        height: "100%",
        backgroundColor: "#ccc",
        // margin: "0 8px",
    },
    arrowImage: {
        width: '16px', height: '16px', marginLeft: '8px', cursor: 'pointer !important'
    },
    hzScrollGrid: {
        [useTheme().breakpoints.down('md')]: {
            minWidth: '680px'
        }
    },
    tableBox: {
        [useTheme().breakpoints.down('md')]: {
            overflowX: 'auto !important', width: '100% !important'
        }
    },
    card: {
        "&.MuiCard-root": {
            padding: "20px !important",
            boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05) !important",
            borderRadius: "12px !important",
        },
        [useTheme().breakpoints.down("sm")]: {
            "&.MuiCard-root": {
                padding: "0px !important",
            },
        },
    },

    cardContent: {
        padding: '20px 30px 30px 30px !important',
        [useTheme().breakpoints.down('lg')]: {
            padding: '10px !important',
        },
    },
    zohoInputBox:{
        height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: '45%',
        [useTheme().breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    bluebordersmall: {
        height: '33px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        width: '81px !important',
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "14px Quicksand !important",
        fontWeight: `${600} !important`,
        padding: '8px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Quicksand !important',
            fontWeight: `${500} !important`,
        }
    },
    blinking: {
        borderRadius: '8px',
        transition: "all .3s ease",
        boxShadow: '0px 0px 15px 1px #cce9ffeb !important',
        animation: 'blinking 1s infinite'
    },   

}))

export default ClientStyles;