import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, SwipeableDrawer } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import Input from '../../../../../components/input/Input';
import LocalStorage from '../../../../../utils/LocalStorage';
import TemplateApi from '../../../../../apis/configurations/templates/TemplateApi';
import { validate_emptyField, } from '../../../../../components/Validation';
import RichTextEditor from 'react-rte';
import { NoDataFound, NoPermission } from '../../../../../utils/utils';
import LoaderIcon from '../../../../../assets/gif/japfuLoader.gif';


function Templates() {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [btnloading, setBtnLoading] = useState(false);
    const [templateType, setTemplateType] = useState({
        templateType: '',
        templateText: "",
    });
    const [openView, setOpenView] = useState(false);
    const [templates, setTemplates] = useState({});
    const [state, setState] = useState({
        name: "external-emp-transfer-ownership-in-mail",
        subject: "",
        description: "",
        template: "",
    });
    // external-emp-transfer-ownership-in-mail
    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "email_templates_view" && item.is_allowed == true))) {
                getAllTemplates();

            }
        }
        // eslint-disable-next-line
    }, [])
    // eslint-disable-next-line
    const handleCancel = () => {
        setState({
            slug: "",
            name: "",
            subject: "",
            description: "",
            template: "",

        });
        setEditorValue(RichTextEditor.createValueFromString("", "html"))
        setTemplateType({
            templateText: '',
            templateType: ''
        })

    };// eslint-disable-next-line
    const [editorValue, setEditorValue] = useState(
        RichTextEditor.createValueFromString("", "html")
    );



    const getAllTemplates = () => {
        setLoading(true)
        setBtnLoading(true)
        TemplateApi.getAllTemplates().then((res) => {
            if (res.data.statusCode == 1003) {
                setTemplates(res.data.data[0]);
            }
            setLoading(false)
        })
    }
    // eslint-disable-next-line
    const handleSelectTemplate = (e) => {
        setTemplateType({ ...templateType, [e.target.name]: e.target.value })
    }// eslint-disable-next-line 
    const handleValidationsEditor = (value) => {
        let err = error;
        err.template = validate_emptyField(value);
        setError(err);
    }
    // eslint-disable-next-line
    const options = [
        { id: 1, value: 'Add to Subject' },
        { id: 2, value: 'Add to Template' },
    ];

    const getTemplateIndex = (id, value) => {
        TemplateApi.getTemplateIndex(LocalStorage.uid(), id, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode == 1003) {
                setState({
                    ...res.data.data[0],
                    template: res.data.data[0].template
                });
                setEditorValue(RichTextEditor.createValueFromString(`${res.data.data[0].template}`, "html"))
                setOpenView(true)
            }
        }
        );
    };

    // function onChangeEditor(value) {
    //     setState({ ...state, template: value })
    //     if (value.toString("html") == "<p><br></p>" || value.toString("html") == "<p></p>" || value.toString("html") == "<p></p><br>") {
    //         handleValidationsEditor("");
    //     } else {
    //         handleValidationsEditor(value.toString("html"));
    //     }
    // }

    const handleCloseView = () => {
        setOpenView(null);
    }

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "email_templates_view" && item.is_allowed == true))) ?
                    <Box sx={{
                        height: '70vh', padding: '16px', overflow: 'auto',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px !important",
                        },
                    }}>

                        <>
                            <Box className={classes.activeItemBox3}>
                                <Box className={classes.activeBoxHeading}><Text profileTitle >E-mail Template</Text></Box>

                                <Box sx={{ height: "49vh", overflow: "auto", padding: "15px 0px" }}>
                                    <Grid item container xl={12} pt={2}>
                                        <Text blue14 sx={{ cursor: 'pointer' }}>Onboarding</Text>
                                        {
                                            templates?.onboarding_templates?.map((item, index) => (
                                                <Box mt={2} key={index} sx={{ background: '#FBFBFB', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', height: '63px', padding: '10px', borderRadius: '8px' }}>
                                                    <Text mediumBlackColor>{item.name}</Text>
                                                    <Text blue14 sx={{ cursor: 'pointer' }} onClick={() => { getTemplateIndex(item.slug) }}>View Template</Text>
                                                </Box>
                                            ))
                                        }

                                    </Grid>
                                    <Grid item container xl={12} pt={2}>
                                        <Text blue14 sx={{ cursor: 'pointer' }}>Invoices</Text>
                                        {
                                            templates?.invoice_templates?.map((item, index) => (
                                                <Box mt={2} key={index} sx={{ background: '#FBFBFB', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', height: '63px', padding: '10px', borderRadius: '8px' }}>
                                                    <Text mediumBlackColor>{item.name}</Text>
                                                    <Text blue14 sx={{ cursor: 'pointer' }} onClick={() => { getTemplateIndex(item.slug) }}>View Template</Text>
                                                </Box>
                                            ))
                                        }

                                    </Grid>
                                </Box>
                            </Box>

                        </>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={openView}
                            onClose={() => handleCloseView()}
                            transitionDuration={300}
                            sx={{
                                ".MuiDrawer-paper ": {
                                    borderRadius: '8px 0px 0px 8px !important',
                                    width: { xs: '90% !important', sm: '65% !important', md: '55% !important' },
                                },
                                "& .MuiBackdrop-root.MuiModal-backdrop": {
                                    backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                                }
                            }
                            }
                        >
                            <Box p={3}>
                                <Box className={classes.activeBoxHeading}><Text profileTitle >{state.subject}</Text></Box>
                                <Box sx={{ overflow: "auto", padding: "15px 0px" }}>
                                    {loading ?
                                        <Box >
                                            <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                            </Stack>
                                        </Box>
                                        :
                                        <Grid container spacing={'32px'}>

                                            {state.name && <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'subject',
                                                        type: 'textarea',
                                                        value: state.subject,
                                                    }}
                                                    // handleChange={handleInputChange}
                                                    disabled={true}
                                                    clientInput
                                                    labelText={'Subject'}
                                                />
                                                <Text red>{error.subject ? error.subject : ''}</Text>
                                            </Grid>}
                                            {state.name &&
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box sx={{ padding: "10px", border: "1px solid #C7CCD3", borderRadius: "8px", zIndex: 1, background: "#FAFAFA" }}>
                                                        <Text sx={{ color: "#737373 !important", font: "12px Quicksand !important", paddingBottom: '10px' }}>Body</Text>
                                                        {/* <RichTextEditor
                                                            disabled={true}
                                                            // readOnly
                                                            onChange={onChangeEditor}
                                                            value={state.template}
                                                            editorClassName={classes.editorHeight}
                                                            toolbarClassName={classes.toolBar}
                                                            // placeholder={}
                                                            // labelText={'Template'}
                                                            className={`${classes.customEditorStyle} ${classes.disabledEditor}`}

                                                        /> */}
                                    <div
                                        style={{
                                            minHeight: '150px',
                                            overflowY: 'auto',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: state.template }}
                                    />
                                                    </Box>
                                                    {/* <Textarea className={classes.textarea} onChange={handleInputChange} type="text" name="template"  value= {state.template} aria-label="minimum height" minRows={5} placeholder="Template" /> */}
                                                    <Text red>{error.template ? error.template : ''}</Text>
                                                </Grid>}

                                        </Grid>
                                    }
                                </Box>

                            </Box>
                        </SwipeableDrawer>
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </Grid>
    )
}

export default Templates;
