import React, { useState } from 'react'
import FileInput from '../../../../../components/muiFileInput/FileInput';
import { Box, Grid } from '@mui/material';
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/customButton/Button';
import Text from '../../../../../components/customText/Text';
import ReusableClearAll from '../../../../../components/reuablePopup/ReusableClearAll';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import LoadingButton from '../../../../../components/customButton/LoadingButton';

function SsnForm(props) { // eslint-disable-next-line
    const { personalDocLoader, state, changeHandler, dateChange, edit, error, handleSubmit, setState, uploadDocs, handleClear, inviteData, handleCancelSsn, ssnConfig, ssnEye, setSsnEye } = props;

    const cancel = () => {
        handleCancelSsn();
    }

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const deleteDoc = (args) => {
        if (state.id !== '' && state.documents[args].id != "") {
            state['documents_deleted_ids'] = [state.documents[args].id];
        }
        if (inviteData != null) {
            state.documents[args].slug = '';
            state.documents[args].document_slug = '';
        }
        state.documents[args].new_document_id = ''
        state.documents[args].document_name = ''
        state.documents[args].document_url = ''
        state.documents[args].id = '';
        setState({ ...state });
        setDeleteOpen(false);
        error['document_number'] = "";
    }

    const clearAll = () => {
        handleClear();
        setOpen(false);
    }

    return (
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} columnSpacing={3} pt={2}>
            {
                state.documents.map((item, index) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} pb={1}>
                        <Box pt={'9px'}>
                            <FileInput
                                name='new_document_id'
                                handleChange={(e) => uploadDocs(e, index, 'ssn')}
                                FileName={item.document_name ? item.document_name : ''}
                                actionState={item.document_name ? 1 : ''}
                                handleDelete={() => setDeleteOpen(true)}
                                label={`${ssnConfig?.new_document_id?.label?.name} ${item.document_name ? "" : ssnConfig?.new_document_id?.label?.format}`}
                                isDisabled={edit}
                                disabled={edit}
                                // error={docError[index] ? docError[index].new_document_id : ''}
                                documentUrl={item.document_url}
                                state={item}
                                labelSize={58}
                            />
                        </Box>
                    </Grid>
                ))
            }
            {ssnConfig?.document_number?.isVisible &&
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'document_number',
                            value: state.document_number,
                            inputProps: { maxLength: ssnConfig?.document_number?.maxLength },
                            type: ssnEye.document_number ? 'text' : 'password',
                        }}
                        eyeIcon={ssnEye.document_number}
                        eyeCloseIcon={ssnEye.document_number == false}
                        eyeHandleChange={() => {
                            setSsnEye({
                                ...ssnEye,
                                document_number: !ssnEye.document_number
                            })
                        }}
                        disabled={ssnConfig?.document_number?.isDisabled || edit}
                        handleChange={changeHandler}
                        iconText
                        error={error.document_number}
                        labelText={`${edit == 'view' ? ssnConfig?.document_number?.label : ssnConfig?.document_number?.label}`}
                        placeholder={ssnConfig?.document_number?.placeholder}
                    />
                    <Text red>{error.document_number ? error.document_number : ''}</Text>
                </Grid>}
            <Grid item lg={4}></Grid>
            {
                !edit &&
                <Grid item container lg={12} md={12} sm={12} xs={12} m={'25px 0px 10px 0px'} gap={{ lg: 0, md: 0, sm: 0, xs: "10px" }}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button disabled={personalDocLoader} blackCancel onClick={cancel}>Cancel</Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                        <Button disabled={personalDocLoader} onClick={() => { setOpen(true) }} clearall sx={{ marginRight: '10px' }}>Clear all</Button>
                        <LoadingButton loading={personalDocLoader} brownMnSave onClick={() => handleSubmit()}>Save</LoadingButton>
                    </Grid>
                </Grid>
            }
            {open && <ReusableClearAll open={open} setOpen={setOpen} onClick={() => { clearAll() }} />}
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(0) }} />}
        </Grid >
    )
}

export default SsnForm