import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Hidden, Skeleton, Slide, Stack } from '@mui/material';
import MainStyles from '../MainStyles';
import ToggleSwitch from '../../../../components/toggle/CustomToggle';
import CustomMenu from '../configComponents/customMenu/Menu';
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../components/input/Input';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ReimbursementApi from '../../../../apis/configurations/payroll/DeductionApi';
import { isValid, validate_charWithSpace, onCharactersAndNumbersWithSpaceChange } from "../../../../components/Validation";
import LocalStorage from "../../../../utils/LocalStorage";
import { addSuccessMsg, addErrorMsg, addWarningMsg, addLoader, removeLoader, NoDataFound, NoPermission, BlackToolTip, capitalize } from '../../../../utils/utils';
import ConfigApi from '../../../../apis/configurations/ConfigApi';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import ReusableDelete from '../../../../components/reuablePopup/ReusableDelete';
import ReusableAlert from '../../../../components/reuablePopup/ReusableAlert';
import { ReactComponent as MenuIcon } from '../../../../assets/svg/MenuIcon.svg'
import EditIcon from '../../../../assets/svg/newEdit.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import { ReactComponent as CheckedDisabled } from '../../../../assets/svg/CheckedDisabled1.svg';
import { ReactComponent as CheckedBorderIconDisabled } from '../../../../assets/svg/CheckboxDisabled.svg';
import { ErrorMessages } from '../../../../utils/ErrorMessages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        // height: '420px',
        width: '692px',
        padding: '0px !important',
        borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
        padding: '0px !important',
    },
    "& .MuiDialogActions-root": {
        padding: '0px !important'
    },
    "& .MuiDialog-container": {
        background: 'rgba(0, 0, 0, 0.55) !important'
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});

function Reimbursements({ current }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [approversOpen, setApproversOpen] = React.useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [getData, setGetData] = useState([]);
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [error, setError] = useState({});
    const recentSection = useRef(null);
    const [loading, setLoading] = useState(true);
    const innerBoxRef = useRef(null);
    const [total, setTotal] = useState()
    const [inactiveOpen, setInactiveOpen] = useState(false);
    const [inActiveData, setInactiveData] = useState(null);
    const [recentActivityShow, setrecentActivityShow] = useState(false);
    const [editApprove, setEditApprove] = useState(false);
    const [state, setState] = useState({
        serial_no: '',
        id: '',
        name: '',
        is_active: true,
        description: '',
    });
    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
        search: "",
    });
    const [activityData, setActivityData] = useState([])
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })
    const [chromeAlert, setChromeAlert] = useState(false);

    useEffect(() => {
        if (!LocalStorage.getStartTour()?.orgconfig) {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
                getListing(filter);
                getIndexListing();
                getActivity(activityFilter);
            }
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (chromeAlert) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [chromeAlert]);

    const [activityTotal, setActivityTotal] = useState();
    const activityRef = useRef(null);

    const getIndexListing = () => {
        ReimbursementApi.getIndexOffPayroll().then((response) => {
            setLoading(false);
            removeLoader();
            if (response.data.statusCode == 1003) {
                let responseData = response.data.data
                if (responseData.initial_setup) {
                    responseData.roles = responseData.roles.map(item => ({
                        ...item,
                        is_active: false
                    }));
                    setData(responseData);
                }
                else {
                    setData(response.data.data);
                }

            }
        });
    }

    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(36, "", args).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setLoading(false)
                    setActivityData(response.data.data);
                    setActivityTotal(response.data.pagination.total);
                    if (popup) { }
                    else {
                        setrecentActivityShow(true)
                    }
                }
            }, 300)
            removeLoader();
        });
    };

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // getCategory({...filter})
                if (activityTotal >= activityFilter.limit) {
                    addLoader("loader");
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        })
        setrecentActivityShow(false)
    }
    const handleChange = (e) => {
        if (e.target.name == 'name') {
            setState({ ...state, [e.target.name]: capitalize(e.target.value) })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
        handleValidations(e);
    };
    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "name":
                err.name = validate_charWithSpace(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const handleClickOpen = () => {
        setOpen(true);
        setAlert(false);
        setState({
            serial_no: '',
            id: '',
            name: '',
            is_active: true,
            description: '',
        })
        setError({});
        setChromeAlert(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
        setApproversOpen(false)
        if (isEditable) {
            setIsEditable(isEditable)
        }
        setIsEditable(false);
        getIndexListing();
        setChromeAlert(false);
    };

    const handleEditClick = (data) => {
        // getIndex(data.id)
        setError({})
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(false);
            setState({ ...data });
            setChromeAlert(true);
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    };

    const handleViewClick = (data) => {
        // getIndex(data.id)
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(true);
            setState({ ...data });
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }
    const [val, setVal] = useState()
    const handleDeleteClick = (args) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_delete" && item.is_allowed == true)))) {
            setVal(args)
            setOpen(true);
            setAlert(true)
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }
    const validateAll = () => {
        let { name } = state;
        let errors = {};
        errors.name = validate_charWithSpace(name);
        return errors;

    };
    const handleDelete = () => {
        deleteTeam(val.id)
        setOpen(false);
    }

    // const handleSubmit = () => {
    //     let errors = validateAll();
    //     if (isValid(errors)) {

    //         handleDialogClose();
    //     } else {
    //         setError(errors);
    //     }
    // }
    const handleSaveAndEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            if (state.id != "") {
                updateApi(state.id)
            } else {
                storeApi(state)
            }

        } else {
            setError(errors);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleStatus = (event, items) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true)))) {
            if (event.target.checked) {
                items['is_active'] = event.target.checked
                updateStatus(items)
            } else {
                items['is_active'] = event.target.checked;
                setInactiveData(items);
                setInactiveOpen(true);
            }
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const reimbursement = (event, name) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true)))) {
            if (event.target.checked) {
                data[name] = event.target.checked
                updateStatusDeduct(data)
            } else {
                data[name] = event.target.checked;
                updateStatusDeduct(data)
            }
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const offPayrollStatus = (event, name) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true)))) {
            if (event.target.checked) {
                data[name] = event.target.checked
                updateStatusOffpayroll(data, name)
            } else {
                data[name] = event.target.checked;
                updateStatusOffpayroll(data, name)
            }
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const getListing = (args) => {
        if (filter.limit < 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        // setLoading(true)
        ReimbursementApi.getListingReimbursement(args).then((response) => {
            setLoading(false);
            removeLoader();
            if (response.data.statusCode == 1003) {
                setGetData(response.data.data);
                setTotal(response.data.pagination.total);
            }
        });
    };

    const storeApi = (data) => {
        data.request_id = LocalStorage.uid()
        data.expense_type = "reimbursement"
        setLoading(true)
        ReimbursementApi.storeApi(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                handleDialogClose();
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };

    const updateStatus = (args) => {
        let data = {
            request_id: LocalStorage.uid(),
            is_active: args.is_active
        }
        setLoading(true)
        ReimbursementApi.updateStatus(data, args.id).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setInactiveData(null);
                setInactiveOpen(false)
                addSuccessMsg(response.data.message);
                getListing(filter);
                getActivity(activityFilter);
            } else {
                setInactiveOpen(false)
                getListing(filter)
                getActivity(activityFilter);
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };

    const updateStatusDeduct = (val) => {
        let data = {
            request_id: LocalStorage.uid(),
            is_active: val.deduct_balance
        }
        setLoading(true)
        ReimbursementApi.deductBalance(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setInactiveOpen(false)
                addSuccessMsg(response.data.message);
                getIndexListing();
                getActivity(activityFilter);
            } else {
                setInactiveOpen(false)
                getIndexListing(filter)
                getActivity(activityFilter);
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };

    const updateStatusOffpayroll = (val, name) => {
        let data = {
            request_id: LocalStorage.uid(),
            is_active: name == 'reimbursement_approval_required' ? val.reimbursement_approval_required : val.off_cycle_reimbursement
        }
        setLoading(true)
        var apiCall = name == 'reimbursement_approval_required' ? ReimbursementApi.approvalStatus(data) : ReimbursementApi.offPayrollStatus(data)
        apiCall.then((response) => {
            if (response.data.statusCode == 1003) {
                setInactiveOpen(false)
                addSuccessMsg(response.data.message);
                getIndexListing();
                setLoading(false)
                getActivity(activityFilter);
            } else {
                setInactiveOpen(false)
                getIndexListing(filter)
                getActivity(activityFilter);
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };

    const updateApi = (dataId) => {
        let data = { ...state, request_id: LocalStorage.uid(), expense_type: "reimbursement" };
        setLoading(true)
        ReimbursementApi.updateApi(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)

                handleDialogClose();
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false)

                addErrorMsg(response.data.message);
            }
        });
    };

    const deleteTeam = (dataId) => {
        let data = { request_id: LocalStorage.uid() };
        ReimbursementApi.deleteApi(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };
    const handleScroll = () => {
        const { current } = innerBoxRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;

            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // getCategory({...filter})
                // if (total >= filter.limit) {
                //     setFilter({ ...filter, limit: filter.limit + 5, page: 1 })
                //     getListing({ ...filter, limit: filter.limit + 5, page: 1, });
                // }
                if (Number(total) >= filter.limit) {
                    addLoader(true);
                    loadMoreData();
                }
            } else {
                // console.log("else inside");
            }
        }
    };
    const loadMoreData = () => {
        filter.limit = Number(filter.limit) + 5;
        setFilter({ ...filter });
        getListing(filter);
    }

    const handleCloseInActive = () => {
        setInactiveData(null);
        setInactiveOpen(false);
        getListing(filter);
    }

    const SkeletonGridList = ({ items }) => (
        items.map((item, index) => (
            <Grid spacing={2} container key={index} mt={3}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={10}>
                    <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={6}>
                    <Skeleton animation="wave" />
                </Grid>
            </Grid>
        ))
    );
    const [approverSubmitLoad,setApproverSubmitLoad] =useState(false);
    const approverSubmit = () => {
        let requestData = { ...data, request_id: LocalStorage.uid() };
        setLoading(true)
        const activeIds = data.roles
            .filter(item => item.is_active)
            .map(item => item.id);
        requestData.roles = activeIds
        setApproverSubmitLoad(true);
        ReimbursementApi.offPayrollApprovalUpdate(requestData).then((response) => {
            setApproverSubmitLoad(false);
            if (response.data.statusCode == 1003) {
                setLoading(false)
                handleDialogClose();
                getListing(filter);
                getIndexListing()
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false)
                getIndexListing()
                addErrorMsg(response.data.message);
            }
        });
    }

    const displayApproverName = (value) => {
        if (value) {
            const filterData = value.filter(item => item.is_active);

            if (filterData.length === 1) {
                return filterData[0].value;
            } else if (filterData.length === 2) {
                return `${filterData[0].value} +1 other`;
            } else if (filterData.length > 2) {
                return `${filterData[0].value} +${filterData.length - 1} others`;
            }
        }
        else {
            return '-'
        }

    };

    var checkPermission = (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_edit" && item.is_allowed == true));

    const editAction = () => {
        if (checkPermission) {
            setApproversOpen(true); setEditApprove(false);
            setChromeAlert(true);
        }
        else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const checkEditPermission = () => {
        if (checkPermission) {
            setIsEditable(false); setError({});
        }
        else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const checkEditPermissionApprove = () => {
        if (checkPermission) {
            setEditApprove(false)
        }
        else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }

    const HandleScrolling = (e) => {
        const scrollTop = e.target.scrollTop;
        if (scrollTop >= 180) {
            setrecentActivityShow(false);
        }
    };

    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                    <Box onScroll={HandleScrolling} sx={{
                        height: '70vh', overflow: 'auto', padding: '16px !important',
                        "@media (min-width: 100px) and (max-width: 499px)": {
                            padding: "10px 3px !important",
                        },
                    }}>
                        <Box className={classes.activeItemBox}>
                            <Grid container py={1}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Text profileTitle>Off-Payroll Cycle</Text>
                                </Grid>
                            </Grid>
                            {
                                loading ?
                                    <>
                                        <SkeletonGridList items={[1]} />
                                    </>
                                    :
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item lg={7} md={6} sm={6} xs={12}>
                                            <Text mediumBlackColor >Reimbursement Approval</Text>
                                            <Text greyLabel>
                                                <BlackToolTip arrow title={'Set up approvals when approver raises a reimbursement'}>
                                                    Set up approvals when approver...
                                                </BlackToolTip>
                                            </Text>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                            <Text mediumBlackColor>Approvers</Text>
                                            <Text greyLabel>
                                                {
                                                    data?.initial_setup ? '' : displayApproverName(data?.roles)
                                                }
                                            </Text>
                                        </Grid>
                                        {data?.initial_setup ? '' :
                                            <Grid item lg={1} md={1.5} sm={1.5} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                <ToggleSwitch name='is_active' isActive={data?.reimbursement_approval_required} switchChange={(e) => offPayrollStatus(e, 'reimbursement_approval_required')} sx={{ height: '24px !important' }} />
                                            </Grid>
                                        }
                                        <Grid item lg={1} md={1.5} sm={1.5} xs={12} textAlign={'center'}>
                                            {data?.initial_setup ?
                                                <Button smallBlue onClick={() => { setApproversOpen(true) }}>Add</Button>
                                                :
                                                <CustomMenu
                                                    Icon={<MenuIcon />}
                                                    handleMenuClick={handleMenuClick1}
                                                    anchorEl={anchorEl1}
                                                    isOpen={Boolean(anchorEl1)}
                                                    onClose={handleClose}
                                                    children={
                                                        (LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                                                            [{ color: '#171717', label: "View", Click: () => { setApproversOpen(true); setEditApprove(true) } },
                                                            { color: '#171717', label: "Edit", Click: () => editAction() }
                                                            ]
                                                            :
                                                            [{ color: '#171717', label: "View", Click: () => { setApproversOpen(true); setEditApprove(true) } }]
                                                    }
                                                // children={
                                                //     [{ color: '#171717', label: "View", Click: () => { setApproversOpen(true); setEditApprove(true) } },
                                                //     { color: '#171717', label: "Edit", Click: () => {editAction()} }
                                                //     ]
                                                // }
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                            }
                            <Grid container py={2}>
                                <Grid item lg={7} md={7} sm={12} xs={12} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                                    <Text profileTitle>Advance options</Text>
                                </Grid>
                            </Grid>
                            {
                                loading ?
                                    <>
                                        <SkeletonGridList items={[1]} />
                                    </>
                                    :
                                    <Grid container alignItems="center">
                                        <Grid item lg={11} md={10} sm={10} xs={12} >
                                            <Text mediumBlackColor >Deduct From Balance</Text>
                                            <Hidden mdDown>
                                                <Text greyLabel>
                                                    Enabling this will allow adding reimbursements by deducting from <br />
                                                    employee’s balance
                                                </Text>
                                            </Hidden>
                                            <Hidden mdUp>
                                                <Text greyLabel>
                                                    Enabling this will allow adding reimbursements by deducting from employee’s balance
                                                </Text>
                                            </Hidden>
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={2} xs={12} container justifyContent={{ lg: "center", md: "center", sm: "end", xs: "start" }} alignItems={'center'} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                            <ToggleSwitch name='is_active' isActive={data?.deduct_balance} switchChange={(e) => reimbursement(e, 'deduct_balance')} sx={{ height: '24px !important' }} />
                                        </Grid>
                                    </Grid>
                            }

                            <Grid container py={2}>
                                <Grid item lg={7} md={12} sm={12} xs={12}>
                                    <Text profileTitle>Reimbursement Type</Text>
                                </Grid>
                            </Grid>
                            <Box sx={{ height: '20vh', overflow: 'auto' }} ref={innerBoxRef} onScroll={handleScroll}>
                                {
                                    loading ?
                                        <>
                                            <SkeletonGridList items={[1, 2, 3]} />
                                        </>
                                        :
                                        <>
                                            {getData.length > 0 ?
                                                <>
                                                    {getData.map((item, index) => (
                                                        <Box className={classes.descriptionBoxStyle} key={index}>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item lg={7} md={6} sm={6} xs={12}>
                                                                    <Text mediumBlackColor >
                                                                        <BlackToolTip arrow title={item.name}>{item.name ? item.name.length > 16 ? item.name.slice(0, 16) + '...' : item.name : "--"}
                                                                        </BlackToolTip>
                                                                    </Text>
                                                                    <Text greyLabel>
                                                                        <BlackToolTip arrow title={item.description}>{item.description ? item.description.length > 30 ? item.description.slice(0, 30) + '...' : item.description : "--"}
                                                                        </BlackToolTip>
                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={3} sm={3} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <Text mediumBlackColor>Modified By</Text>
                                                                    <Text greyLabel>
                                                                        {
                                                                            item.created_by && item.created_by.length > 16 ?
                                                                                <BlackToolTip title={item.created_by} placement="top" arrow>
                                                                                    {item.created_by.slice(0, 16) + (item.created_by.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                : item.created_by ? item.created_by : '--'
                                                                        }

                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                                                                    <ToggleSwitch name='is_active' isActive={item.is_active} switchChange={(e) => handleStatus(e, item)} sx={{ height: '24px !important' }} />
                                                                </Grid>
                                                                <Grid item lg={1} md={1.5} sm={1.5} xs={12} textAlign={'center'}>
                                                                    <CustomMenu
                                                                        Icon={<MenuIcon />}
                                                                        handleMenuClick={handleMenuClick}
                                                                        anchorEl={anchorEl}
                                                                        isOpen={Boolean(anchorEl)}
                                                                        onClose={handleClose}
                                                                        children={
                                                                            (LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true)))) ?
                                                                                [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                                { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },
                                                                                (LocalStorage.getUserData().super_admin || ((rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))))
                                                                                && { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) },
                                                                                ]
                                                                                :
                                                                                [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]
                                                                        }
                                                                    // children={item.is_editable ?
                                                                    //     [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                    //     { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },
                                                                    //     { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) }
                                                                    //     ]
                                                                    //     :
                                                                    //     [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>))}
                                                </>
                                                :
                                                <>
                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                </>
                                            }
                                        </>
                                }
                            </Box>
                            <>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_create" && item.is_allowed == true))) ?
                                        <Button addTitleBtn onClick={() => handleClickOpen()}>Add Reimbursement Type</Button> :
                                        <Button addTitleBtnDisable>Add Reimbursement Type</Button>
                                }
                            </>
                            {alert ? <ReusableDelete newDelete={true} content={"Do you want to delete this Reimbursement Type?"} open={open} setOpen={setOpen} onClick={() => { handleDelete() }} /> :
                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleDialogClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                >
                                    <DialogContent>
                                        {
                                            isEditable ?
                                                <Box padding={{ lg: '30px', md: "30px", sm: "25px", xs: "10px" }}>
                                                    <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                                                        <Grid item lg={6} md={6} sm={6} xs={8}>
                                                            <Text mediumViewAmt>Reimbursement Type</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={4} textAlign={"end"}>
                                                            {state.is_editable ? <img onClick={() => { checkEditPermission(); setChromeAlert(true); }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Name</Text>
                                                            <Text mediumBlack14 pt={0.8} >
                                                                <BlackToolTip arrow title={state.name}>{state.name ? state.name.length > 16 ? state.name.slice(0, 16) + '...' : state.name : "--"}
                                                                </BlackToolTip>
                                                            </Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Status</Text>
                                                            <Text mediumBlack14 pt={0.8} >{state.is_active ? "Active" : "In Active"}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Description</Text>
                                                            <Text mediumBlack14 pt={0.8} className={classes.descriptionText}>{state.description ? state.description : "--"}</Text>
                                                            {/* <BlackToolTip arrow title={state.description}>{state.description ? state.description.length > 30 ? state.description.slice(0, 30) + '...' : state.description : "--"}
                                                            </BlackToolTip> */}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                <Box padding={{ lg: '38px 30px 32px 30px', md: "38px 30px 32px 30px", sm: "30px", xs: "16px 12px" }}>
                                                    <Box mb={4}>
                                                        <Text mediumViewAmt>{state.id === "" ? "Add Reimbursement Type" : "Reimbursement Type"}</Text>
                                                    </Box>
                                                    <Grid container spacing={2} columnSpacing={'32px'}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12} >
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'name',
                                                                    value: state.name,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 50 },
                                                                }}
                                                                handleChange={handleChange}
                                                                clientInput
                                                                onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                                                labelText={'Name'}
                                                                error={error.name}
                                                            />
                                                            <Text errorText> {error.name ? error.name : ""}</Text>
                                                        </Grid>
                                                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Select
                                            name='is_active'
                                            value={state.is_active}
                                            onChange={handleChange}
                                            label={'Status'}
                                            options={statusList}
                                            disabled={isEditable}
                                            helperText={error.is_active ? <Text errorText> {error.is_active ? error.is_active : ""}</Text> : ""}
                                        />
                                    </Grid> */}
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                multiline={true}
                                                                rows={3}
                                                                inputProps={{
                                                                    name: 'description',
                                                                    value: state.description,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 255 },
                                                                }}
                                                                handleChange={handleChange}
                                                                descriptionFormControl1
                                                                descriptionInput
                                                                labelText={'Description'}
                                                                placeholder={'Type Something'}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                    <Box display={'flex'} justifyContent={'end'} gap={2} mt={5}>
                                                        <Button disabled={loading} sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose} >Cancel</Button>
                                                        <LoadingButton saveBtn loading={loading} onClick={() => handleSaveAndEdit()}>Save</LoadingButton>
                                                        {/* <Button popupSaveBlue onClick={handleSaveAndEdit} >{isEditable ? 'Edit' : 'Save'}</Button> */}
                                                    </Box>
                                                </Box>
                                        }
                                    </DialogContent>
                                </BootstrapDialog>}
                            <BootstrapDialog
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleDialogClose}
                                aria-labelledby="customized-dialog-title"
                                open={approversOpen}
                                fullWidth={true}
                                maxWidth={"md"}
                            >
                                <DialogContent>

                                    <Box padding={{ lg: '30px', md: "30px", sm: "25px", xs: "14px" }}>
                                        <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                                            <Grid item container alignItems="center">
                                                <Grid item lg={11} md={6} sm={6} xs={11} container direction={'column'} gap={1}>
                                                    <Text mediumViewAmt>Reimbursement Approval</Text>
                                                </Grid>
                                                <Grid item lg={1} md={6} sm={6} xs={1} container alignItems={'center'} justifyContent={"end"}>
                                                    {
                                                        !editApprove ? '' :
                                                            <img onClick={() => { checkEditPermissionApprove() }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} />
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item container alignItems="center" spacing={1}>
                                                <Grid item lg={11} md={11} sm={11} xs={12} container direction={'column'} gap={1}>
                                                    <Text greyLabel>
                                                        Enable Off-Cycle reimbursements for one time payments
                                                    </Text>
                                                </Grid>
                                                <Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'} pl={"15px !important"}>
                                                    <ToggleSwitch name='is_active' disable={editApprove} isActive={data?.off_cycle_reimbursement} switchChange={(e) => offPayrollStatus(e, 'off_cycle_reimbursement')} sx={{ height: '24px !important' }} />
                                                </Grid>
                                            </Grid>
                                            {
                                                data?.roles?.length > 0 ? data.roles.map((item, index) => (
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    disabled={editApprove}
                                                                    value={item.is_active}
                                                                    checked={item.is_active}
                                                                    onChange={(e) => { data.roles[index].is_active = e.target.checked; setData({ ...data }) }}
                                                                    icon={editApprove ? <CheckedBorderIconDisabled /> : <CheckBorderIcon />}
                                                                    checkedIcon={editApprove ? <CheckedDisabled /> : <CheckedIcon />}
                                                                />}
                                                            label={<Text mediumBlack nowrap>{item.value}</Text>}
                                                        />
                                                    </Grid>
                                                ))
                                                    : ''
                                            }
                                        </Grid>
                                        {
                                            editApprove ? '' :
                                                <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent={'flex-end'}>
                                                    <Stack direction={'row'} gap={2}>
                                                        <Button disabled={approverSubmitLoad} cancelOutlineRed onClick={() => { setApproversOpen(false); getIndexListing(); setChromeAlert(false) }} sx={{
                                                            "@media (min-width: 100px) and (max-width: 499px)": {
                                                                font: "12px Quicksand !important", width: "80px !important", height: "34px !important",
                                                            },
                                                        }}>Cancel</Button>
                                                        <LoadingButton finishFilledQuicksand loading={approverSubmitLoad} onClick={() => approverSubmit()}>Save</LoadingButton>
                                                    </Stack>
                                                </Grid>
                                        }

                                    </Box>
                                </DialogContent>
                            </BootstrapDialog>
                        </Box>
                        {
                            inactiveOpen && <ReusableAlert message={true} title={'reimbursement type'} open={inactiveOpen} handleClose={handleCloseInActive} onClick={() => { updateStatus(inActiveData) }} />
                        }
                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text RegularBlack1>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto' }}
                                ref={activityRef}
                                onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8} md={8} sm={8} xs={7}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={10}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} alignItems={"center"}>
                                                            <Grid item lg={4} md={4} sm={6} xs={12} >
                                                                <Text mediumBlackColor>{value.user_name} {value.action}</Text>
                                                                <Text greyLabel mt={"5px"}>{value.created_at}</Text>
                                                            </Grid>
                                                            <Grid container item lg={8} md={8} sm={6} xs={12}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                        </Box>
                        {recentActivityShow ?
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                                <Button addButton
                                    sx={{
                                        position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                        "@media (min-width: 300px) and (max-width: 1420px)": {
                                            font: "12px Quicksand !important",
                                            fontWeight: `${700} !important`
                                        }
                                    }}
                                    onClick={scrollDown}
                                    endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                            </Grid>
                            : ''}
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </div>
    )
}

export default Reimbursements