import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, SwipeableDrawer, } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Text from "../../../components/customText/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { BlackToolTip } from "../../../utils/utils";
import editIcon from '../../../assets/svg/invoive_edit.svg';
import EditRecordPaymentDrawer from "./EditRecordPaymentDrawer";


function InvoiceAccordian(props) {
    const { isOpen, setIsOpen, invoicePdfData, callRefetch } = props
    const [paymentDrawer, setPaymentDrawer] = useState(false);
    const [data, setData] = useState('')
    const handleOpenDrawer = (payment) => {
        setData(payment)
        setPaymentDrawer(true)
    }

    return (
        <>
            <Box>
                <Accordion expanded={isOpen} elevation={0} sx={{
                    "&:before": {
                        display: "none",

                    },
                }}>
                    <AccordionSummary
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "default",
                        }}
                    >
                        <Box display="flex" alignItems="center" flexGrow={1}>
                            <Box>
                                <Text mediumBlack14>Payment Received</Text>
                            </Box>
                            <Box
                                style={{
                                    backgroundColor: "#16A34A",
                                    height: "25px",
                                    borderRadius: "20px",
                                    padding: "3px 20px",
                                    display: "inline-block",
                                }}
                                ml={2}
                            >
                                <Text mediumBoldWhite> {invoicePdfData.payment_details && invoicePdfData.payment_details?.length > 0 ? invoicePdfData.payment_details?.length : '--'}</Text>
                            </Box>
                        </Box>
                        <Box
                            p={1}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                            }}
                            style={{ cursor: "pointer", }}
                        >
                            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Box>
                    </AccordionSummary>
                    <Grid sx={{
                        overflowY: "auto",
                        maxHeight: '100px',
                    }} >
                        {invoicePdfData.payment_details?.map((payment, index) => (
                            <AccordionDetails
                                sx={{
                                    // padding: "13px 60px",
                                    padding: { xs: 0, sm: "0", md: "13px 25px", lg: "13px 25px" },
                                    borderRadius: "8px",
                                    border: "1px solid #0C75EB",
                                    backgroundColor: "#f8fbfe",
                                    margin: '0px 20px',
                                    marginBottom: '10px'
                                }}

                            >
                                <Grid container alignItems="center" spacing={3} marginLeft={0}>

                                    <>
                                        <Grid item xs={12} sm={6} md={2} display="flex" flexDirection="column" alignItems="start">
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", opacity: 0.5 }}>Payment No</Text>
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", }} mt={1}> {payment.s_no}</Text>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2} display="flex" flexDirection="column" alignItems="start">
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", opacity: 0.5 }}>Payment Date</Text>
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", }} mt={1}> {payment.payment_date || '--'}</Text>
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={2} display="flex" flexDirection="column" alignItems="start">
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", opacity: 0.5 }}>Reference No.</Text>
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important" }} mt={1}> {
                                                payment?.payment_id ? payment?.payment_id.length > 16 ?
                                                    <BlackToolTip title={payment?.payment_id} placement="top" arrow>
                                                        {payment?.payment_id.slice(0, 16) + (payment?.payment_id.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    : payment?.payment_id : '--'}</Text>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={2} display="flex" flexDirection="column" alignItems="start">
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", opacity: 0.5 }}>Payment Mode</Text>
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important" }} mt={1}> {payment?.payment_mode || '--'}</Text>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2.5} display="flex" flexDirection="column" alignItems="start">
                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important", opacity: 0.5 }}>
                                                Amount Received
                                            </Text>

                                            <Text boldBlackfont22 sx={{ fontSize: "14px !important" }} mt={1}>
                                                $ {payment?.amount || '--'}
                                            </Text>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={1} display="flex" alignItems="center">
                                            <img
                                                src={editIcon}
                                                alt="editIcon"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { handleOpenDrawer(payment) }}
                                            />
                                        </Grid>
                                    </>

                                </Grid>
                            </AccordionDetails>
                        ))}
                    </Grid>

                </Accordion>

                <Divider sx={{ border: "1px solid #EAECF0 !important", marginTop: isOpen ? "25px" : "5px" }} />
            </Box>
            <SwipeableDrawer
                anchor={'right'}
                open={paymentDrawer}
                onClose={() => { setPaymentDrawer(false) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <EditRecordPaymentDrawer apix={invoicePdfData} data={data} setPaymentDrawer={() => { setPaymentDrawer(false) }} paymentDrawer={paymentDrawer} callRefetch={callRefetch} />
            </SwipeableDrawer>
        </>

    )
}

export default InvoiceAccordian